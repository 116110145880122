import { Fade, Popper, ClickAwayListener, Select, MenuItem, FormControl, Checkbox, Paper, Tooltip } from "@mui/material";
import Cookies from "js-cookie";
import styles from "./index.module.scss";
import { Link, NavLink, useNavigate, useLocation, json } from "react-router-dom";
// import { useTour } from "@reactour/tour";
import Input from "../../components/Input";
import React, { useState, useEffect, useContext, useRef } from "react";
import Button from "../../components/Button";
import ReactDOM from "react-dom";
import { LogoutRequest, logout, getCurrentUser } from "../../redux/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { ProfileviewList } from "../../redux/actions/profile";
import { GlobalSearchAction } from "../../redux/actions/search";
import { listOrganisationDropDown } from "../../redux/actions/organisation";
import {
    DashboarChartList,
    DashboardCategory,
    DashboardVandorList,
    DashboardVendors,
    DashboardTaskList,
    GetOnTimeProcessAction,
} from "../../redux/actions/dashboard";
import {
    GET_NOTIFICATION_LIST_RESET,
    GET_ORGLIST_DROP_DOWN_RESET,
    INVOICE_VENDOR_LIST_RESET,
    LOGIN_RESET,
    GLOBAL_SEARCH_RESET,
} from "../../redux/types/types";
import { RolePermissionDisplay } from "../../redux/actions/role";
import { UserDropDown, userView } from "../../redux/actions/user";
import { onMessageListener } from "../../components/Firebase";
import { GetPushNotification, UpdateNotification } from "../../redux/actions/notification";
import Loader from "../../components/Loader";
import { getInboxSettingList } from "../../redux/actions/inbox-setting";
import FreeTrail from "./TrialPage";
import ProductTourMain from "./productTour";
import { decryptId, encryptId, ThisWeek, ThisYear } from "../../utils/helpers";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { getReportsFavList } from "../../redux/actions/report";
import ReminderModal from "../../pages/Reminder/ReminderPopup";
import HelperVideoMain from "./helperVideo";
import LanguageSelector from "../../pages/Language/LanguageSelector";
const menuaItems = process.env?.REACT_APP_MENU_LIST;
const menuList = JSON.parse(menuaItems);
const SUPPORT_EMAIL = process.env?.REACT_APP_SUPPORT_EMAIL;
if (window.location.reload) {
    window.localStorage.removeItem("searchId");
    window.localStorage.removeItem("invSearch");
}
let isDefaultLogo;
const Header = () => {
    // const { setIsOpen } = useTour();
    const date = new Date();
    const orgData = useSelector((state) => state?.ListorganisationDropDownState);
    const dataList = orgData.viewData;
    const orgId = localStorage.getItem("orgId");
    const loaction = useLocation();
    const dispatch = useDispatch();
    const [currentTime, setCurrentTime] = useState(date.toLocaleTimeString());
    let currentUserDetails;
    const [anchorEl, setAnchorEl] = useState(null);
    const [popstate, setPopState] = useState({ id: null, anchorEl: false });
    const [placement, setPlacement] = useState();
    const [profileImage, setImage] = useState();
    const [invNo, getQueryStringVal] = useState("");
    const [currentProfile, setCurrentUser] = useState();
    const [isShowDahboard, setShowDashboard] = useState();
    const [trialOpen, setTrialOpen] = useState(false);
    const [reminderPopup, setReminderPopup] = useState(false);
    const [searchValue, handleSearchChange] = useState(window.localStorage.getItem("searchId"));
    const [btnAllState, setBtnAllState] = useState(true);
    const [userState, setUserState] = useState(false);
    const events = ["mousemove", "keypress", "load", "onchange", "onclick", "onmouseover", "onkeydown", "onload"];
    const [notification, setNotification] = useState({ title: "", body: "" });
    const [isNotification, setShowNotification] = useState(false);
    const DemoStatus = localStorage.getItem("DemoStatus");
    onMessageListener()
        .then((payload) => {
            setNotification({
                title: payload.notification.title,
                body: payload.notification.body,
            });
            setShowNotification(true);
        })
        .catch((err) => console.log("failed: ", err));

    //   if (isNotification) {
    //     alert(notification.body);
    //   }
    const handleUserState = () => {
        setUserState(userState ? false : true);
        setBtnAllState(false);
    };
    const UserEditData = useSelector((state) => state?.userViewState);
    const data = useSelector((state) => state?.inboxSettingListState);
    const checkMailConfig = data?.configData?.[0]?.isactive;
    const checkEmail = data?.length !== 0;
    const { viewDatas } = UserEditData;

    const [orgState, setOrgState] = useState(false);
    const handleAllState = () => {
        const isBtnAllClick = btnAllState ? false : true;
        setBtnAllState(isBtnAllClick);
        setOrgState(false);
        setInvState(false);
        setUserState(false);
        if (isBtnAllClick) {
            searchList = [];
            searchList.push(0);
            searchInGlobal();
        }
    };
    const orgLogo = localStorage.getItem("orgLogo");
    const getOrgLogo = dataList?.find((item) => item?.id == +orgId);
    useEffect(() => {
        if (getOrgLogo !== undefined && orgId) {
            localStorage.setItem("orgLogo", getOrgLogo?.logo);
            isDefaultLogo = getOrgLogo?.is_default;
        }
    }, [getOrgLogo]);
    let currentTimeCheck = 0;
    const handleOrgState = () => {
        setOrgState(orgState ? false : true);
        setBtnAllState(false);
    };
    const [invState, setInvState] = useState();
    const handleInvState = () => {
        setInvState(invState ? false : true);
        setBtnAllState(false);
    };
    const navigate = useNavigate();
    const { Data } = useSelector((state) => state?.searchState);
    const { data: Notificationdata, fetching, requestComplete } = useSelector((state) => state?.getpushNotificationState);
    const { data: Datas, page, isScroll, hasMore } = useSelector((state) => state?.getpushNotificationState);

    const currentUser = getCurrentUser();
    const Logindate = currentUser?.login_date;
    const LogitGetDate = localStorage.getItem("LoginDate");

    const handleClick = (newPlacement, id, event) => {
        setAnchorEl(event.currentTarget);
        setPopState({ id, anchorEl: event.currentTarget });
        setPlacement(newPlacement);
        if (location.pathname !== "/notification" && id == 3) {
            dispatch(GetPushNotification(location.pathname == "/notification" ? false : true, 1, 10, "normal"));
        }
    };
    const d = date.toLocaleDateString("en-GB");

    let hours = date.getHours();
    hours = (((hours + 11) % 12) + 1).toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const formattedTime = `${hours}:${minutes}`;
    const [toggle, setToggle] = useState(false);

    const resetInputField = () => {
        handleSearchChange("");
        localStorage.setItem("searchId", "");
        dispatch({ type: GLOBAL_SEARCH_RESET });
    };
    const _handleToggle = (e) => {
        // e.preventDefault();
        setToggle(true);
        const header = document.querySelector("header");
        const footer = document.querySelector("footer");
        const sidebar = document.querySelector(".sidebar");
        const main = document.querySelector(`.main`);
        toggle && header.classList.toggle("expand");
        toggle && footer.classList.toggle("expand");
        toggle && sidebar.classList.toggle("expand");
        toggle && main.classList.toggle("expand");
    };

    const handleClickAway = (e) => {
        setPopState({ id: null, anchorEl: false });
    };
    const [checkSession, setCheckSession] = useState(false);
    const profileData = useSelector((state) => state?.profileState);
    const { profile: viewData } = profileData;
    const location = useLocation();

    const menuName = menuList.find((item) => item.name == location.pathname);
    let searchList = [0];
    const [orgName, setOrgName] = useState(orgId);
    const [searchName, setSearchName] = useState("");
    useEffect(() => {
        if (DemoStatus == "true" && dataList?.length !== 0) {
            const filterDemoOrg = dataList?.filter((el) => el?.demo_org !== true);
            setOrgName(filterDemoOrg[0]?.id);
            localStorage.setItem("orgId", filterDemoOrg[0]?.id);
        }
    }, [DemoStatus]);

    const DisplayRole = useSelector((state) => state?.DisplayRolePermissionStateAction);
    const { data: DataRoleList } = DisplayRole;
    useEffect(() => {
        if (localStorage.getItem("themeMode") === "dark") {
            document.querySelector("body").style.backgroundColor = "#121212";
        } else {
            document.querySelector("body").style.backgroundColor = "#FAFBFC";
        }
    }, [localStorage.getItem("themeMode")]);

    // Given date

    const givenDate = new Date(currentUser?.expiring_date);
    const tenDaysBefore = new Date(givenDate);
    tenDaysBefore.setDate(givenDate.getDate() - 10);

    // Calculate 5 days after
    const fiveDaysAfter = new Date(givenDate);
    fiveDaysAfter.setDate(givenDate.getDate() - 5);

    // Calculate the final day (10 days after)
    const finalDay = new Date(givenDate);
    finalDay.setDate(givenDate.getDate());
    const Day5 = !isNaN(tenDaysBefore) ? tenDaysBefore?.toISOString() : "";
    const Day10 = !isNaN(fiveDaysAfter) ? fiveDaysAfter?.toISOString() : "";
    const FinalDay = !isNaN(finalDay) ? finalDay?.toISOString() : "";

    const Day5Split = Day5?.split("T")[0];

    const Day10Split = Day10?.split("T")[0];
    const FinalDaySplit = FinalDay?.split("T")[0];
    let DayCount = Day10Split == LogitGetDate || Day5Split == LogitGetDate || FinalDaySplit == LogitGetDate;

    useEffect(() => {
        if (DayCount && currentUser?.license_type == 2) {
            setReminderPopup(true);
        } else {
            setReminderPopup(false);
        }
    }, []);

    useEffect(() => {
        // if (location.pathname !== "/notification") {
        //     dispatch({ type: GET_NOTIFICATION_LIST_RESET });
        // }
        // const userObj = Users
        // ? country?.find((item) => item.country_id === parseInt(Users?.country))
        // : "";
        // dispatch(userView(currentUser?.id))

        if (location.pathname !== "/notification" && !requestComplete && Notificationdata?.length == 0) {
            dispatch(GetPushNotification(location.pathname == "/notification" ? false : true, 1, 10, "normal"));
        }
        const currentLoginUser = localStorage.getItem("user");
        setOrgName(localStorage.getItem("orgId") || orgName);

        if (currentLoginUser === undefined || currentLoginUser == null) {
            navigate("/");
            window.location.reload();
        } else {
            currentUserDetails = getCurrentUser();
            setCurrentUser(currentUserDetails);

            if (location.pathname === "/profile") {
                dispatch(ProfileviewList({ id: parseInt(currentUserDetails.id) }));
            }
            if (!btnAllState) {
                searchList = [];
                if (invState) {
                    searchList.push(3);
                }
                if (userState) {
                    searchList.push(1);
                }
                if (orgState) {
                    searchList.push(2);
                }
                searchInGlobal();
            }
        }
        if (dataList?.length === 0) {
            dispatch(listOrganisationDropDown());
        }

        if (!DataRoleList?.role_info) {
            dispatch(RolePermissionDisplay({ orgid: orgId }));
        }
    }, [dispatch, searchValue, invState, userState, btnAllState, orgState, orgName]);

    const searchInGlobal = () => {
        const reqObject = {
            user_id: currentUser?.id,
            organisation_id: 0 || +orgName,
            org_grp: currentUser?.organisation,
            transaction_type: searchList,
            search_text: localStorage.getItem("searchId"),
        };
        dispatch(GlobalSearchAction(reqObject));
    };
    const onBlurSearch = () => {
        if (searchValue) {
            // searchInGlobal();
        }
    };

    // const reqObject = {
    //   user_id: currentUser?.id,
    //   organisation_id: 0 || +orgName,
    //   org_grp: currentUser?.organisation,
    //   transaction_type: searchList,
    //   search_text: searchValue || localStorage.getItem("searchId"),
    // };
    const UserImage = localStorage.getItem("UserImage");
    const UserName = localStorage.getItem("Username");
    const dateFormat = dataList?.find((el) => el?.id == orgName)?.dateformat;
    localStorage.setItem("OrgDateFormat", dateFormat || "dd/mm/yyyy");
    const numberFormat = dataList?.find((el) => el?.id == orgName)?.numformat;
    let formatVal;
    if (numberFormat == "1.23.456,78") {
        formatVal = "2";
    } else if (numberFormat == "1 23 456.78") {
        formatVal = "3";
    } else {
        formatVal = "1";
    }
    localStorage.setItem("OrgNumFormat", formatVal);
    const handleSearchText = (value) => {
        handleSearchChange(value);
        window.localStorage.setItem("searchId", value);
        if (!btnAllState) {
            searchList = [];
            if (invState) {
                searchList.push(3);
            }
            if (userState) {
                searchList.push(1);
            }
            if (orgState) {
                searchList.push(2);
            }
            searchInGlobal();
        } else {
            searchList = [];
            searchList.push(0);
            searchInGlobal();
        }
        // (window.localStorage.getItem('searchId') || searchValue)
    };
    const pageType = location.pathname === "/invoice" ? 1 : 2;
    //logout
    const logoutsubmit = async () => {
        let myAdvanceItem = localStorage.getItem("advanceListOption");
        let myMiscItem = localStorage.getItem("MiscListOption");
        let myItem = localStorage.getItem("customOptions");
        let intervalItem = localStorage.getItem("PlayInterval");
        let newItem = localStorage.getItem("ageingOption");
        let folderAccess = localStorage.getItem("FolderAccess");
        let secret = localStorage.getItem("secretId");
        let user = localStorage.getItem("user");
        dispatch(LogoutRequest());
        Cookies.remove("jwt");
        localStorage.clear();
        localStorage.setItem("advanceListOption", myAdvanceItem);
        localStorage.setItem("MiscListOption", myMiscItem);
        localStorage.setItem("customOptions", myItem);
        localStorage.setItem("PlayInterval", intervalItem);
        localStorage.setItem("ageingOption", newItem);
        localStorage.setItem("FolderAccess", folderAccess);
        localStorage.setItem("secretId", secret);
        localStorage.setItem("user", user);
        dispatch({ type: GET_ORGLIST_DROP_DOWN_RESET });
        dispatch({ type: LOGIN_RESET });
        setTimeout(function () {
            navigate("/");
            localStorage.removeItem("user");
            toggleMode("light");
        }, 500);
    };
    const UserIds = localStorage.getItem("UserDropdownId");
    const LetuserId = currentProfile?.role_id == 1 ? currentProfile?.id : UserIds;
    const [changeOrg, setChangeOrg] = useState(0);
    const Orgname = localStorage.getItem("Orgdemo");
    const orgdemoset = dataList?.find((el) => el?.id == orgName)?.demo_org;
    localStorage.setItem("Orgdemo", orgdemoset);
    const orgnameset = dataList?.find((el) => el?.id == orgName)?.organisation_name;
    localStorage.setItem("OrganisationName", orgnameset);
    const onChangeOrganisation = (e, demo, orgname) => {
        setChangeOrg(1);
        localStorage.setItem("Orgdemo", demo);
        localStorage.setItem("ChangeOrg", 1);
        localStorage.setItem("OrganisationName", orgname);
        setTimeout(() => {
            localStorage.setItem("ChangeOrg", 0);
        }, 500);
        dispatch({ type: INVOICE_VENDOR_LIST_RESET });
        if (location.pathname === "/reports") {
            dispatch(getReportsFavList(e.target.value));
        }
        if (
            location.pathname === "/dashboard" ||
            loaction.pathname === "/reports/reports-ageing" ||
            location.pathname === "/reports/reports-detail" ||
            location.pathname === "/invoice" ||
            location.pathname === "/approve" ||
            location.pathname === "/exception" ||
            (location.pathname === "/processed" && e.target.value !== 0)
        ) {
            const data = {
                org_id: +e.target.value,
                status: 2,
            };
            dispatch(UserDropDown(data));
        }

        if (
            location.pathname === "/reports/reports-detail" ||
            loaction.pathname === "/reports/reports-ageing" ||
            location.pathname == "/reports/reports-accrual"
        ) {
            window.location.reload();
        }
        if (location.pathname === "/mobilization-request" || location.pathname === "/adhoc-advance-request") {
            navigate("/advance-list");
        }
        if (location.pathname === "/miscellaneous-request") {
            navigate("/miscellaneous-table");
        }
        if (e.target.value !== 0) {
            window.localStorage.setItem("orgId", e.target.value);
            dispatch(RolePermissionDisplay({ orgid: +e.target.value }));
            setOrgName(e.target.value);
            const pageName = location.pathname;
            switch (pageName) {
                case "/dashboard":
                    if (demo != undefined) {
                        dispatch(DashboarChartList(demo == true ? 3 : 1, e.target.value, demo == true ? ThisYear : ThisWeek, LetuserId));
                        dispatch(DashboardVandorList(demo == true ? 3 : 1, e.target.value, demo == true ? ThisYear : ThisWeek, LetuserId));
                        dispatch(DashboardVendors(demo == true ? 3 : 1, e.target.value, demo == true ? ThisYear : ThisWeek, LetuserId));
                        dispatch(GetOnTimeProcessAction(demo == true ? 3 : 1, e.target.value, demo == true ? ThisYear : ThisWeek, LetuserId));
                    }
                    [1, 2, 3, 4]?.map((el, i) => {
                        dispatch(DashboardCategory(e.target.value, LetuserId, el));
                    });
                    // dispatch(DashboardCategory(e.target.value, LetuserId, 1));
                    dispatch(DashboardTaskList(e.target.value, LetuserId, 0));
                    break;
                case "/invoice":
                case "/exception":
                case "/approve":
                    const reqObj = {
                        status_type:
                            location.pathname === "/invoice" ? 2 : location.pathname === "/exception" ? 1 : location.pathname === "/approve" ? 3 : "",
                        status_id: pageName === "/approve" ? [7] : [2],
                        // org_group: currentUser?.organisation,
                        vendor_id: "",
                        invoice_date_from: null,
                        invoice_date_to: null,
                        search_text: "",
                        order_column: "",
                        order_by: 1,
                        pg_no: 1,
                        rec_cnt: 10,
                        organisation: +e.target.value,
                        invoice_no: "",
                        alloc_user: LetuserId,
                        isflagged: false,
                    };
                    const reqObjCount = {
                        status_type:
                            location.pathname === "/invoice" ? 2 : location.pathname === "/exception" ? 1 : location.pathname === "/approve" ? 3 : "",
                        status_id: pageName === "/approve" ? [7] : [2],
                        vendor_id: 1,
                        invoice_date_from: null,
                        invoice_date_to: null,
                        search_text: "",
                        organisation: +e.target.value,
                        vendor_id: "",
                        invoice_no: "",
                        alloc_user: LetuserId,
                        isflagged: false,
                    };
                    // dispatch(invoiceCountTotal(reqObjCount));
                    // dispatch(InvoiceList(reqObj));
                    break;

                default:
                    break;
            }
        }
    };
    const [items, setItems] = useState([]);

    const calculateTimeRemaining = (targetDate) => {
        if (!targetDate) {
            return {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
            };
        }
        const [day, month, year] = targetDate?.split("/");
        const target = new Date(`${month}/${day}/${year}`);
        const now = new Date();

        // Calculate the time difference in milliseconds
        const timeDiff = target?.getTime() - now?.getTime();

        // Calculate the remaining days, hours, minutes, and seconds
        const seconds = Math?.floor(timeDiff / 1000) % 60;
        const minutes = Math?.floor(timeDiff / 1000 / 60) % 60;
        const hours = Math?.floor(timeDiff / 1000 / 60 / 60) % 24;
        const days = Math?.floor(timeDiff / 1000 / 60 / 60 / 24);

        return {
            days,
            hours,
            minutes,
            seconds,
        };
    };
    const dateTime = currentProfile?.expiring_date;

    const targetDateTime = new Date(currentProfile?.expiring_date);

    const calculateTimeLeft = () => {
        const now = new Date().getTime();
        const difference = targetDateTime - now;

        const daysLeft = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hoursLeft = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

        return {
            days: daysLeft,
            hours: hoursLeft,
        };
    };

    // useEffect(() => {
    //     const timer = setInterval(() => {
    //         setTimeLeft(calculateTimeLeft());
    //     }, 1000);

    //     return () => clearInterval(timer);
    // }, []);
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    const targetDateTimes = new Date(currentUser?.expiring_date);
    const now = new Date();

    // Calculate the time difference in milliseconds
    const differenceMs = targetDateTimes - now;

    // Calculate the number of days and hours left
    const daysLeft = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
    const hoursLeft = Math.floor((differenceMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const targetDate = currentUser?.expiration_date; // DD/MM/YYYY format
    // const timeRemaining = calculateTimeRemaining(targetDate);
    const timeRemaining = currentUser?.expiring_message == "Your trial ends in 0 days" ? "" : " and ";
    const TimeRemainingHour =
        currentUser?.expiration_date == d || hoursLeft == 0 ? "" : timeRemaining + hoursLeft + (hoursLeft == 1 ? " hour" : " hours");
    const CalcTime =
        currentUser?.expiring_message == "Your trial ends in 15 days" ||
        currentUser?.expiring_message == "Your trial expired" ||
        currentUser?.expiring_message == "Your trial ends today"
            ? ""
            : TimeRemainingHour;
    const ExpiringMessage = currentUser?.expiring_message == "Your trial ends in 0 days" ? "Your trail ends within " : currentUser?.expiring_message;
    const targetDateStr = currentUser?.expiring_date;
    const targetDates = new Date(targetDateStr);
    const currentDate = new Date();

    const matchTrialdate = targetDates < currentDate;
    const MatchDate = Notificationdata?.filter((el) => el?.created_date == d);
    const [openTourPop, setOpenTourPop] = useState(false);
    const [openHelperPop, setOpenHelperPop] = useState(false);
    const handleOpenProductTour = () => {
        setOpenTourPop(true);
        handleClickAway();
    };
    const handleOpenHelperPop = () => {
        setOpenHelperPop(true);
        handleClickAway();
    };
    const checkhandleChange = (event, Id) => {
        Notificationdata.forEach((element) => {
            if (Id == element?.id) {
                element.isread = event.target.value;
            }
        });
        dispatch(UpdateNotification({ id: +Id }));
        setTimeout(() => {
            dispatch(GetPushNotification(true, 1, 10, "normal"));
        }, 1000);
    };

    const ReadAll = () => {
        dispatch(UpdateNotification({ id: 0 }));
        setTimeout(() => {
            dispatch(GetPushNotification(true, 1, 10, "normal"));
        }, 1000);
    };
    const TrialPopup = () => {
        if (matchTrialdate && currentUser?.license_type == 2) {
            setTrialOpen(true);
        } else {
            if (currentUser?.role_id == 2) {
                navigate("/reports/reports-billing");
            }
        }
    };
    const containerRef = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    useEffect(() => {
        const handleScroll = () => {
            const position = window.pageYOffset;
            setScrollPosition(position);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    useEffect(() => {
        const loadMoreData = () => {
            if (containerRef.current && !isScroll && hasMore) {
                const container = containerRef.current;
                const { scrollTop, scrollHeight, clientHeight } = container;
                const isInViewport = scrollTop + clientHeight >= scrollHeight - 5;
                if (isInViewport) {
                    dispatch(GetPushNotification(false, page, 10, "scroll"));
                }
            }
        };

        containerRef.current?.addEventListener("scroll", loadMoreData);

        return () => {
            containerRef.current?.removeEventListener("scroll", loadMoreData);
        };
    }, [isScroll, page, fetching]);

    const MatchDateList = Notificationdata?.filter((el) => el?.created_date == d && el?.isread == true);
    const EarlierItem = Notificationdata?.filter((el) => el?.created_date !== d && el?.isread == true);

    const { currentMode, toggleMode } = useContext(ThemeContext);
    const showIcon = (!matchTrialdate && currentUser?.license_type == 2) || currentUser?.license_type == 1;
    const { data: DataList } = DisplayRole;
    const RoleList = DataList?.role_info;
    const RoleMenu = RoleList?.menu;
    const Roleorg = RoleMenu?.find((elem) => elem?.menu_id === 1);
    const RoleActOrg = Roleorg?.actions;
    const checkorg = RoleActOrg?.some((element) => element.isallowed === true);
    const RoleUser = RoleMenu?.find((elem) => elem?.menu_id === 6);
    const RoleActUser = RoleUser?.actions;
    const checkUser = RoleActUser?.some((element) => element.isallowed === true);
    const handleThemeChange = (mode) => {
        toggleMode(mode);
        handleClickAway();
    };
    const offsetValue = -50;
    const ThemeSelect = () => (
        <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={(e) => handleClickAway(e)}>
            <Popper
                className={`${styles?.Popper} bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-730 overflow-y-auto h-[auto] max-h-[600px] z-[3] Poppershadow`}
                open={4 === popstate.id}
                anchorEl={popstate.anchorEl}
                disablePortal={false}
                id="4"
                modifiers={[
                    {
                        name: "offset",
                        options: {
                            offset: [offsetValue, 0],
                        },
                    },
                ]}
                placement={placement}
            >
                <div className="justify-between py-2 items-center">
                    <ul className="p-0">
                        <li className="block">
                            <span
                                className={`block w-full hover:bg-${currentMode}-960 cursor-pointer px-5 py-[5px]`}
                                onClick={() => handleThemeChange("light")}
                            >
                                Light
                            </span>
                        </li>
                        <li className="block">
                            <span
                                className={`block w-full  hover:bg-${currentMode}-960 cursor-pointer px-5 py-[5px]`}
                                onClick={() => handleThemeChange("dark")}
                            >
                                Dark
                            </span>
                        </li>
                        <li className="block">
                            <span
                                className={`block w-full hover:bg-${currentMode}-960 cursor-pointer px-5 py-[5px]`}
                                onClick={() => handleThemeChange("classic")}
                            >
                                Classic
                            </span>
                        </li>
                    </ul>
                </div>
            </Popper>
        </ClickAwayListener>
    );
    // if (data?.configData?.length !== 0) {
    //     if (location?.pathname !== "/inbox-settings") {
    //         navigate('/inbox-compose')
    //     }
    // } else {
    //     navigate('/inbox-config');
    // }
    // if (checkMailConfig) {
    //     navigate("/inbox-compose");
    // }
    // useEffect(() => {
    //     if (data?.configData?.length !== 0) {
    //         // if (location?.pathname !== "/inbox-settings") {
    //         navigate('/inbox-compose')
    //         // }
    //     } else {
    //         navigate('/inbox-config');
    //     }
    // }, []);
    const _inboxSetting = async () => {
        dispatch(getInboxSettingList(navigate));
        if (checkMailConfig) {
            navigate("/inbox-compose");
        }
    };
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" || currentMode === "classic" ? "#ECECEC" : `#000`;
    const textColor = currentMode === "dark" ? "#ECECEC" : `#000`;
    document.addEventListener("keydown", function (event) {
        // Check if Command (Mac) or Ctrl (Windows/Linux) key is pressed
        if ((event.metaKey || event.ctrlKey) && event.key === "f") {
            const modalBackdrop = document.querySelectorAll(".Modal_backdrop__ZxrNt");
            const isModalOpen = Array.from(modalBackdrop).some((element) => element.classList.contains("Modal_Show__Xon06"));
            if (!isModalOpen) {
                // Focus on the input element with ID 'globalSearchId'
                document.getElementById("globalSearchId").focus();
                document.getElementById("globalSearchId").click();
                // Prevent the default browser find functionality
                event.preventDefault();
            } else {
                // Prevent the default browser find functionality
                event.preventDefault();
            }
        }
    });

    // Detect the user's operating system
    const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
    // Detect if the device is a tablet
    const isTablet = window.matchMedia("(max-width: 768px)").matches;
    const placeholderControl = isTablet ? "Search..." : isMac ? "Cmd + F to Search..." : "Ctrl + F to Search...";
    return (
        <>
            <header
                className={`bg-${currentMode}-base border-${currentMode}-690  border-b-[1px] py-[6.7px] lg:py-[18.5px] mb-[1.875rem] lg:pl-[115px] pl-[0] lg:pr-[35px] pr-4 flex justify-between items-center ${styles?.header}`}
            >
                <div className="flex items-center space-x-3 lg:space-x-0">
                    <NavLink
                        to="/"
                        className={`hidelogo border-r border-${currentMode}-690 w-[90px] bg-${currentMode}-base max-w-[90px] min-w-[90px] h-[80px]  lg:hidden`}
                    >
                        <img
                            className={`${"object-contain mt-1 mb-2 w-[60px] h-[60px] "}  ${
                                !isDefaultLogo && orgLogo
                                    ? ""
                                    : //  `rounded-full`

                                      ""
                            }`}
                            src={
                                !isDefaultLogo && orgLogo
                                    ? `data:image;base64,${orgLogo}`
                                    : currentMode === "dark" || currentMode == "classic"
                                    ? "/images/logo/logo-white.svg"
                                    : `/images/logo/MyiqFinal.svg`
                            }
                            alt="myiq-logo"
                        />
                    </NavLink>
                    <span className="lg:hidden cusor-pointer block w-[20px] h-[14px]" onClick={_handleToggle}>
                        <img className="w-[20px] h-[14px]" src="/images/header/hamburger.svg" alt="hamburger" />
                    </span>
                    <Button
                        onClick={(event) => handleClick("bottom-end", 1, event)}
                        tabIndex={1}
                        className={`searched ${
                            1 === popstate.id ? styles?.animateSearch : ``
                        } bg-${currentMode}-230 border-${currentMode}-590 border relative items-center hidden lg:flex justify-between py-[8px] px-[16px] xl:min-w-[250px] rounded-[8px]`}
                    >
                        <Input
                            placeholder={`${placeholderControl}`}
                            className={`border-[0] bg-transparent ${currentMode == "classic" ? "text-[#FFFFFF]" : `text-${currentMode}-copy`}`}
                            //disableUnderline={true}
                            disabled={dataList?.length === undefined ? true : false}
                            maxLength={250}
                            value={localStorage.getItem("searchId")}
                            name="searchId"
                            id="globalSearchId"
                            onChange={(e) => handleSearchText(e.target.value)}
                            autoComplete="off"
                            onFocus={() => onBlurSearch()}
                        />
                        {searchValue && searchValue.length !== 0 ? (
                            <img onClick={resetInputField} src="/images/common/closeBlk.svg" alt="close" className="convertWhite" />
                        ) : (
                            <img className="z-[-1]" src="/images/header/search.svg" alt="search" />
                        )}
                    </Button>
                    <Button
                        onClick={(event) => handleClick("bottom-end", 1, event)}
                        tabIndex={0}
                        className={` ${1 === popstate.id ? styles?.animateSearch : ``} flex lg:hidden items-center`}
                    >
                        <Input
                            placeholder={`${placeholderControl}`}
                            id="globalSearchId"
                            value={localStorage.getItem("searchId")}
                            name="searchId"
                            onChange={(e) => handleSearchText(e.target.value)}
                            className={`bg-${currentMode}-230 ${styles?.animatebar} flex justify-between py-[4px] px-[22px] xl:min-w-[250px] rounded-[8px]`}
                            disableUnderline={true}
                            onFocus={() => onBlurSearch()}
                        />
                        {searchValue && searchValue.length !== 0 ? (
                            <img onClick={resetInputField} className="ml-[-31px]" src="/images/common/closeBlk.svg" alt="close" />
                        ) : (
                            <img className="ml-[-31px] z-[-1]" src="/images/header/search.svg" alt="search" />
                        )}
                    </Button>
                    {currentUser?.license_type == 2 && (
                        <p
                            className={`text-${currentMode}-10 ${
                                popstate?.id === 1 ? styles?.hiding : ``
                            } pl-3 max-w-[8.9rem] xl:max-w-[inherit] xl:text-base text-sm xl:pl-[15px] relative cursor-pointer`}
                            onClick={TrialPopup}
                        >
                            {ExpiringMessage + CalcTime}
                        </p>
                    )}
                    {/* <LanguageSelector></LanguageSelector> */}
                    <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={(e) => handleClickAway(e)}>
                        <Popper
                            className={`${styles?.Popper} bg-${currentMode}-card border-${currentMode}-560 border-[1px] text-${currentMode}-copy  overflow-y-auto max-w-[420px] max-h-[500px] ${styles?.searchItem} Poppershadow`}
                            open={1 === popstate.id}
                            anchorEl={popstate.anchorEl}
                            id="1"
                            placement={placement}
                            disablePortal
                            tabIndex={1}
                            transition
                        >
                            {orgId ? (
                                <div className="p-6">
                                    <div className={`flex space-x-2 mb-5 sticky top-0 bg-${currentMode}-card`}>
                                        <Button
                                            onClick={() => handleAllState()}
                                            title="Search All"
                                            className={
                                                btnAllState
                                                    ? "stateBtn bg-light-20 px-5 py-1 text-white rounded-[8px]"
                                                    : `bg-transparent px-5 py-1 border-[1px] border-${currentMode}-730 rounded-[8px]`
                                            }
                                        >
                                            All
                                        </Button>
                                        <Button
                                            onClick={() => handleInvState()}
                                            title="Search in Invoice"
                                            className={
                                                invState
                                                    ? "bg-light-20 px-5 py-1 text-white rounded-[8px]"
                                                    : `bg-transparent px-5 py-1 border-[1px] border-${currentMode}-730 rounded-[8px]`
                                            }
                                        >
                                            Invoice
                                        </Button>
                                        <Button
                                            onClick={() => handleUserState()}
                                            title="Search in User"
                                            className={
                                                userState
                                                    ? "bg-light-20 px-5 py-1 text-white rounded-[8px]"
                                                    : `bg-transparent px-5 py-1 border-[1px] border-${currentMode}-730 rounded-[8px]`
                                            }
                                        >
                                            User
                                        </Button>
                                        <Button
                                            onClick={() => handleOrgState()}
                                            title="Search in Organization"
                                            className={
                                                orgState
                                                    ? "bg-light-20 px-5 py-1 text-white rounded-[8px]"
                                                    : `bg-transparent px-5 py-1 border-[1px] border-${currentMode}-730 rounded-[8px]`
                                            }
                                        >
                                            Organization
                                        </Button>
                                    </div>
                                    <div className="mx-[-24px]">
                                        <hr className={`border-${currentMode}-560`} />
                                    </div>
                                    <div className="text-[13px] space-y-4 mx-[-24px]">
                                        {Data && Data?.length !== 0 ? (
                                            Data?.map((elem) => {
                                                return (
                                                    <span>
                                                        <p key={elem?.transaction_id}>
                                                            {elem?.transaction_type === 3 ? (
                                                                <Link
                                                                    state={{ from: location.pathname }}
                                                                    to={`${"/invoice-view?"}${encryptId(
                                                                        `{"inv":${+elem?.transaction_id},"type":${pageType}}`
                                                                    )}
                                                                   `}
                                                                    className="font-interM flex px-[24px] justify-between hover:bg-[rgba(218,249,244,0.3)] w-full py-1"
                                                                >
                                                                    <div className="flex-1 break-words min-w-[10px] trunc-1">
                                                                        {elem?.transaction_text}
                                                                    </div>
                                                                    <span className="text-light-80 break-words">
                                                                        {elem?.transaction_type === 3 ? `Invoice` : ""}
                                                                    </span>
                                                                </Link>
                                                            ) : (
                                                                ""
                                                            )}
                                                            {elem?.transaction_type === 2 ? (
                                                                <Link
                                                                    to={`${"/organisations?org="}${elem?.transaction_id}`}
                                                                    className="font-interM flex px-[24px] justify-between hover:bg-[rgba(218,249,244,0.3)] w-full py-1"
                                                                >
                                                                    <div className="flex-1 break-words min-w-[10px] trunc-1">
                                                                        {elem?.transaction_text}
                                                                    </div>
                                                                    <span className="text-light-80">
                                                                        {elem?.transaction_type === 2 ? `Organization` : ""}
                                                                    </span>
                                                                </Link>
                                                            ) : (
                                                                ""
                                                            )}
                                                            {elem?.transaction_type === 1 ? (
                                                                <a
                                                                    href={`${"/users?userid="}${elem?.transaction_id}`}
                                                                    className="font-interM flex justify-between px-[24px]  hover:bg-[rgba(218,249,244,0.3)] w-full py-1"
                                                                >
                                                                    <div className="flex-1 break-words min-w-[10px] trunc-1">
                                                                        {elem?.transaction_text}
                                                                    </div>
                                                                    <span className="text-light-80">
                                                                        {elem?.transaction_type === 1 ? `User` : ""}
                                                                    </span>
                                                                </a>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </p>
                                                    </span>
                                                );
                                            })
                                        ) : (
                                            <div className="text-center">
                                                <div className="mx-auto my-5">
                                                    <img className="mx-auto" src="/images/common/delivery.png" alt="delivery" />
                                                </div>
                                                <h4 className="font-interSb">No search results found</h4>
                                            </div>
                                        )}

                                        {/* <p>
                    <span className="font-interM">Matt Rogers (Acme Inc.) Created:</span> 5 days ago
                    Pending for: a few seconds
                  </p>
                  <p>
                    <span className="font-interM"> Emily Garcia Agent responded:</span> 5 days ago Overdue
                    by: 3 days
                  </p> */}
                                    </div>
                                </div>
                            ) : (
                                <div className="p-6 min-w-[448px] grid place-items-center text-center place-content-center flex space-x-4 mb-5 sticky top-0 bg-white">
                                    <img className="mb-[2rem]" src="/images/common/delivery.png" alt="delivery" />
                                    <h5 className="font-interSb mb-1">No Organization is Selected</h5>
                                    <p className="text-xs">
                                        Go to Organization menu
                                        <br /> then add your Organization details
                                    </p>
                                </div>
                            )}
                            {/* </Fade>
              )} */}
                        </Popper>
                    </ClickAwayListener>
                </div>

                <div className={`${popstate?.id === 1 ? styles?.hiding : ``} duration-300 flex items-center space-x-2 lg:space-x-[10px]`}>
                    {menuName?.id == 11 ||
                    menuName?.id == 8 ||
                    menuName?.id == 9 ||
                    menuName?.id == 12 ||
                    menuName?.id == 10 ||
                    menuName?.id == 16 ||
                    menuName?.id == 20 ||
                    menuName?.id == 22 ||
                    menuName?.id == 24 ||
                    menuName?.id == 26 ||
                    menuName?.id == 27 ||
                    location.pathname == "/retention-release-list" ||
                    location.pathname == "/miscellaneous-request" ||
                    location.pathname == "/miscellaneous-table" ||
                    location.pathname === "/processed" ||
                    (loaction.pathname.includes("/reports") &&
                        location.pathname !== "/reports/reports-accrual" &&
                        location.pathname !== "/reports/reports-task" &&
                        location.pathname !== "/reports/reports-billing") ? (
                        <FormControl>
                            <Select
                                name="orgName"
                                value={orgName || "0"}
                                fullWidth
                                className="Org head"
                                inputProps={{ tabIndex: "1" }}
                                title={dataList?.find((el) => el?.id == orgName)?.organisation_name}
                                // defaultValue={orgName}
                                MenuProps={{
                                    sx: {
                                        "& .MuiMenu-paper": {
                                            backgroundColor: dropdownColor,
                                            color: textColor,
                                            maxWidth: "24rem",
                                            top: "60px !important",
                                            maxHeight: "37rem",
                                        },
                                    },
                                }}
                                IconComponent={() => (
                                    <img
                                        className="absolute opacity-[.5] right-[10px] pointer-events-none"
                                        src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                    />
                                )}
                                SelectDisplayProps={{
                                    style: {
                                        paddingTop: 1,
                                        width: "100%",
                                        maxWidth: 230,
                                        paddingBottom: 1,
                                        // color: dropdownText,
                                        paddingRight: "30px",
                                    },
                                }}
                                onChange={(e) =>
                                    onChangeOrganisation(
                                        e,
                                        dataList?.find((el) => el?.id == +e.target.value)?.demo_org,
                                        dataList?.find((el) => el?.id == +e.target.value)?.organisation_name
                                    )
                                }
                            >
                                {orgName == "0" ? (
                                    <MenuItem key={0} value={0} name="organisation_name">
                                        {"Select Organization"}
                                    </MenuItem>
                                ) : null}

                                {dataList &&
                                    dataList?.map((elem, i) => {
                                        return (
                                            <MenuItem
                                                name="organisation_name"
                                                key={i + 1}
                                                value={elem?.id}
                                                title={elem?.organisation_name}
                                                className="truncate trunc-1 block"
                                            >
                                                {elem?.organisation_name}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                    ) : (
                        ""
                    )}
                    {/* <Link className="flex" onClick={TourStart} to="/organisations">
            <img
              className="flex-none"
              src="/images/common/tour.svg"
              alt="tour"
            />
          </Link> */}
                    {/* <a href="#!" className="relative">
            <img src="/images/header/bell.svg" alt="bell" />{" "}
            <span className={styles?.notifi}></span>
          </a>  */}
                    <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={(e) => handleClickAway(e)}>
                        <Popper
                            className={`${styles?.Popper} before:hidden bg-${currentMode}-card  text-${currentMode}-copy border-${currentMode}-730 shadow-lg  h-[auto]  z-[3] Poppershadow max-w-[400px]`}
                            open={3 === popstate.id}
                            anchorEl={popstate.anchorEl}
                            id="3"
                            modifiers={[
                                {
                                    name: "offset",
                                    options: {
                                        offset: [-50, 0],
                                    },
                                },
                            ]}
                            placement={placement}
                        >
                            <div className={`px-[20px] pt-[20px] pb-2 flex justify-between items-center border-b-[1px] border-${currentMode}-730`}>
                                <h5 className="font-interM text-lg">Notification</h5>
                                <Link to={"/notification"} className="ml-auto">
                                    <img src="/images/main/ico-direct.svg" alt="ico-direct" />
                                </Link>
                            </div>
                            <div
                                ref={containerRef}
                                id="scrolling-popper"
                                className={`max-h-[600px] h-full overflow-y-auto`}
                                // sx={{
                                //     maxHeight: 600, // Set the max height for the container
                                //     overflowY: "auto", // Enable vertical scrolling
                                // }}
                            >
                                {Notificationdata?.length == 0 ? (
                                    <div className={`p-[20px] text-center text-${currentMode}-copy`}>No notifications are found</div>
                                ) : (
                                    <div>
                                        {fetching ? (
                                            <Loader className="h-[160px]" />
                                        ) : (
                                            <ul>
                                                <li>
                                                    <div
                                                        className={`flex pb-2 text-xs justify-between items-center border-b-[1px] border-${currentMode}-730 `}
                                                    >
                                                        <span className={`${currentMode == "dark" ? "text-[#ECECEC]" : "text-[#747474]"}`}>
                                                            Today ({MatchDate?.length})
                                                        </span>
                                                        <span
                                                            className={`flex cursor-pointer ${
                                                                currentMode == "dark" ? "text-[#ECECEC]" : "text-[#747474]"
                                                            }`}
                                                            onClick={ReadAll}
                                                        >
                                                            <img className="mr-1" src="/images/main/double-tick.svg" alt="double-tick" /> Mark all as
                                                            read
                                                        </span>
                                                    </div>
                                                    {/* <hr className={`my-5 border-${currentMode}-700 `} /> */}

                                                    {MatchDateList &&
                                                        MatchDateList?.map((elem, index) => {
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    className={`flex space-x-3 py-[15px] border-b-[1px] border-${currentMode}-730`}
                                                                >
                                                                    {/* <div className="flex-initial">
              <img src="/images/main/userNew.svg" alt="userNew" />
            </div> */}

                                                                    <div
                                                                        className={`flex-1 flex text-${currentMode}-copy items-start justify-between`}
                                                                    >
                                                                        <div>
                                                                            <span className="font-interM">
                                                                                {elem?.username} {elem?.title}
                                                                            </span>
                                                                            <p className="text-[13px] leading-[19px] mb-1">{elem?.message}</p>
                                                                            <span className={`text-${currentMode}-70 text-xs`}>
                                                                                {elem?.createddt}
                                                                            </span>
                                                                        </div>
                                                                        <Checkbox
                                                                            checked={elem?.isread}
                                                                            onChange={(event) => {
                                                                                checkhandleChange(
                                                                                    {
                                                                                        target: {
                                                                                            name: event.target.name,
                                                                                            value: event.target.checked,
                                                                                        },
                                                                                    },
                                                                                    elem?.id
                                                                                );
                                                                            }}
                                                                            icon={
                                                                                <img
                                                                                    src="/images/main/notifi-normal.svg"
                                                                                    alt="notifi-normal"
                                                                                    className="max-w-[55px]"
                                                                                />
                                                                            }
                                                                            checkedIcon={
                                                                                <img
                                                                                    src="/images/main/notifi-checked.svg"
                                                                                    alt="notifi-checked"
                                                                                    className="max-w-[55px]"
                                                                                />
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                </li>
                                                <li>
                                                    {EarlierItem?.length > 0 && (
                                                        <div
                                                            className={`flex ${
                                                                currentMode == "dark" ? "text-[#ECECEC]" : "text-[#747474]"
                                                            } pb-2 text-xs justify-between items-center  mt-[20px]`}
                                                        >
                                                            <span>Older ({EarlierItem?.length})</span>
                                                        </div>
                                                    )}

                                                    {EarlierItem &&
                                                        EarlierItem?.map((elem, index) => {
                                                            return (
                                                                <div className={`flex space-x-3 py-[15px] border-b-[1px] border-${currentMode}-730`}>
                                                                    {/* <div className="flex-initial">
              <img src="/images/main/userNew.svg" alt="userNew" />
            </div> */}

                                                                    <div
                                                                        className={`flex-1 flex text-${currentMode}-copy items-start justify-between`}
                                                                    >
                                                                        <div>
                                                                            <span className="font-interM">
                                                                                {elem?.username} {elem?.title}
                                                                            </span>
                                                                            <p className="text-[13px] leading-[19px] mb-1">{elem?.message}</p>
                                                                            <span className={`text-${currentMode}-70 text-xs`}>
                                                                                {elem?.createddt}
                                                                            </span>
                                                                        </div>
                                                                        <Checkbox
                                                                            checked={elem?.isread}
                                                                            onChange={(event) => {
                                                                                checkhandleChange(
                                                                                    {
                                                                                        target: {
                                                                                            name: event.target.name,
                                                                                            value: event.target.checked,
                                                                                        },
                                                                                    },
                                                                                    elem?.id
                                                                                );
                                                                            }}
                                                                            icon={
                                                                                <img
                                                                                    src="/images/main/notifi-normal.svg"
                                                                                    alt="notifi-normal"
                                                                                    className="max-w-[55px]"
                                                                                />
                                                                            }
                                                                            checkedIcon={
                                                                                <img
                                                                                    src="/images/main/notifi-checked.svg"
                                                                                    alt="notifi-checked"
                                                                                    className="max-w-[55px]"
                                                                                />
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                </li>
                                                {isScroll && <Loader className="h-[10px]" />}
                                                {!hasMore && (
                                                    <span className={` ${currentMode == "dark" ? "text-white" : "text-black"} flex justify-center`}>
                                                        No more notification available
                                                    </span>
                                                )}
                                            </ul>
                                        )}
                                    </div>
                                )}
                            </div>
                        </Popper>
                    </ClickAwayListener>
                    {/* {showIcon && DataRoleList?.role_id !== 0 && (
                        <Link
                            title="My Task List"
                            className="flex xl:pr-[2px] px-1"
                            tabIndex={1}
                            to="/my-task/list"
                        >
                            <img
                                src="/images/main/my-task-icon.svg"
                                alt="my-task"
                            />{" "}
                        </Link>
                    )}
                    {showIcon && (
                        <Button
                            title="Inbox"
                            className="flex ml-0 xl:pr-[5px] px-1 cursor-pointer"
                            tabIndex={1}
                            onClick={_inboxSetting}
                        >
                            <img src="/images/header/Icon.svg" alt="email" />{" "}
                        </Button>
                    )} */}
                    <Button onClick={(event) => handleClick("bottom", 4, event)} className="cursor-pointer h-[20px] mb-2" tabIndex={1}>
                        <img
                            src={
                                currentMode == "dark"
                                    ? `/images/MyiQ-icons/night.svg`
                                    : currentMode == "light"
                                    ? `/images/MyiQ-icons/light.svg`
                                    : `/images/MyiQ-icons/classic.svg`
                            }
                            title={`Theme`}
                            alt="Theme Mode"
                        />
                    </Button>
                    {4 === popstate.id && (
                        <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={(e) => handleClickAway(e)}>
                            <Popper
                                className={`${styles?.Popper} bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-730 overflow-y-auto h-[auto] max-h-[600px] z-[3] Poppershadow`}
                                open={4 === popstate.id}
                                anchorEl={popstate.anchorEl}
                                disablePortal
                                id="4"
                                modifiers={[
                                    {
                                        name: "offset",
                                        options: {
                                            offset: [offsetValue, 0],
                                        },
                                    },
                                ]}
                                placement={placement}
                            >
                                <div className="justify-between py-2 items-center">
                                    <ul className="p-0">
                                        <li className="block">
                                            <span
                                                className={`flex justify-between w-full hover:bg-${currentMode}-960 cursor-pointer px-5 py-[5px]`}
                                                onClick={() => handleThemeChange("light")}
                                            >
                                                Light
                                                {currentMode === "light" && <img src="/images/common/tick.svg" alt="tick" />}
                                            </span>
                                        </li>
                                        <li className="block">
                                            <span
                                                className={`flex justify-between w-full  hover:bg-${currentMode}-960 cursor-pointer px-5 py-[5px]`}
                                                onClick={() => handleThemeChange("dark")}
                                                title="Theme"
                                            >
                                                Dark
                                                {currentMode === "dark" && <img src="/images/common/tick.svg" alt="tick" />}
                                            </span>
                                        </li>
                                        <li className="block">
                                            <span
                                                className={`flex justify-between w-full hover:bg-${currentMode}-960 cursor-pointer px-5 py-[5px]`}
                                                onClick={() => handleThemeChange("classic")}
                                            >
                                                Classic
                                                {currentMode === "classic" && <img src="/images/common/tick.svg" alt="tick" />}
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </Popper>
                        </ClickAwayListener>
                    )}
                    <Button
                        title="Help"
                        className="flex ml-0 xl:pr-[5px] px-1 cursor-pointer relative"
                        tabIndex={2}
                        onClick={(event) => handleClick("bottom", 5, event)}
                    >
                        <img src="/images/main/main_help_logo.svg" alt="help" />{" "}
                    </Button>
                    {/* {5 === popstate.id && ( */}
                    <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClickAway}>
                        <Popper
                            className={`${styles?.Popper} bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-730 overflow-y-auto h-[auto] max-h-[600px] z-[3] Poppershadow`}
                            open={5 === popstate.id}
                            anchorEl={popstate.anchorEl}
                            // disablePortal
                            id="5"
                            // modifiers={[
                            //     {
                            //         name: "offset",
                            //         options: {
                            //             offset: [offsetValue, 0],
                            //         },
                            //     },
                            // ]}
                            // placement={placement}
                        >
                            <div className="justify-between py-2 items-center">
                                <ul className="p-0">
                                    <li className={`flex hover:bg-${currentMode}-960 cursor-pointer `}>
                                        <img
                                            className="ml-4"
                                            src={currentMode == "dark" ? "images/main/dark-product-tour.svg" : "/images/main/main_tour_logo.svg"}
                                        />
                                        <span className={`flex justify-between w-full px-2 py-[5px]`} onClick={() => handleOpenProductTour()}>
                                            Product Tour
                                        </span>
                                    </li>
                                    <li className={`flex hover:bg-${currentMode}-960 cursor-pointer `}>
                                        <img
                                            className="ml-4"
                                            src={currentMode == "dark" ? "/images/main/dark-Help-videos.svg" : "/images/main/main_help_video.svg"}
                                        />
                                        <span
                                            onClick={() => handleOpenHelperPop()}
                                            className={`flex justify-between w-full px-2 py-[5px]`}
                                            // onClick={() => handleThemeChange("dark")}
                                        >
                                            Help Videos
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </Popper>
                    </ClickAwayListener>
                    {loaction.pathname !== "/notification" && (
                        <Button
                            title="Notification"
                            tabIndex={1}
                            className={`relative cursor-pointer before:top-[35px] searched ${3 === popstate.id ? styles?.animateSearch : ``}`}
                            onClick={(event) => handleClick("bottom", 3, event)}
                        >
                            <img src="/images/MyiQ-icons/notification.svg" alt="bell" />
                            {Notificationdata?.length > 0 && <span className={styles?.notifi}></span>}
                        </Button>
                    )}
                    <Button
                        className="flex items-center xl:pl-4 cursor-pointer relative"
                        tabIndex={1}
                        onClick={(event) => handleClick("bottom-end", 2, event)}
                    >
                        {/* <img src="/images/header/avatar.svg" alt="avatar" /> */}

                        <img
                            className="rounded-[50%] object-cover xl:w-[40px] xl:h-[40px] w-[30px] h-[30px]"
                            src={
                                viewData?.user_image
                                    ? `data:image;base64,${viewData?.user_image}`
                                    : UserImage
                                    ? `data:image;base64,${UserImage}`
                                    : currentProfile?.photo
                                    ? `data:image;base64,${currentProfile?.photo} `
                                    : `/images/common/profileImg.png`
                            }
                        />
                        <span className={`ml-2 font-interSb flex text-${currentMode}-40 `}>
                            <p className="truncate max-w-[200px]">
                                {" "}
                                {viewData?.user_name
                                    ? `${viewData?.user_name}`
                                    : UserName == "" || UserName == null
                                    ? currentUser?.username
                                    : UserName}
                            </p>

                            <img
                                className="ml-2 opacity-[.5]"
                                src={currentMode === "light" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                alt="chevronDown"
                            />
                        </span>
                    </Button>
                    <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClickAway}>
                        <Popper
                            className={`${styles?.Popper} bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-730 Poppershadow`}
                            open={2 === popstate.id}
                            anchorEl={popstate.anchorEl}
                            id="2"
                        >
                            <div>
                                <ul>
                                    <li>
                                        <Link
                                            className={`flex hover:bg-${currentMode}-960 mx-[-20px] px-[20px]`}
                                            to="/profile"
                                            tabIndex="1"
                                            title="Profile"
                                        >
                                            <img className="flex-none mr-2 convertWhite" src="/images/header/user.svg" alt="user" />
                                            Profile
                                        </Link>
                                    </li>
                                    {/* <li>
                        <Link className="flex" to="#!">
                          <img
                            className="flex-none"
                            src="/images/header/help.png"
                            alt="help"
                          />
                          Help
                        </Link>
                      </li> */}
                                    <li>
                                        {currentUser?.role_id == 1 && !checkUser && !checkorg ? (
                                            ""
                                        ) : (
                                            <Link
                                                className={`flex hover:bg-${currentMode}-960 mx-[-20px] px-[20px]`}
                                                to="/admin-setting"
                                                tabIndex="12"
                                                title="Settings"
                                            >
                                                <img className="flex-none mr-2 convertWhite" src="/images/header/setting.svg" alt="setting" />
                                                Settings
                                            </Link>
                                        )}
                                    </li>{" "}
                                    {currentProfile?.role_id === 2 ? (
                                        <li>
                                            <Link
                                                className={`flex hover:bg-${currentMode}-960 mx-[-20px] px-[20px]`}
                                                to="/reports/reports-billing"
                                                tabIndex="1"
                                                title="Billing"
                                            >
                                                <img className="flex-none mr-2 convertWhite" src="/images/common/billing.svg" alt="Billing" />
                                                Billing
                                            </Link>
                                        </li>
                                    ) : (
                                        ""
                                    )}
                                    <li>
                                        <Tooltip
                                            title={SUPPORT_EMAIL}
                                            disablePortal
                                            placement="bottom"
                                            disableInteractive
                                            PopperProps={{
                                                sx: {
                                                    "& .MuiTooltip-tooltip": {
                                                        background: "#DAF9F4",
                                                        color: "#555555",
                                                        borderRadius: "5px",
                                                        marginTop: "0 !important",
                                                    },
                                                },
                                            }}
                                        >
                                            <a
                                                className={`flex hover:bg-${currentMode}-960 mx-[-20px] px-[20px]`}
                                                href={`mailto:${SUPPORT_EMAIL}`}
                                                tabIndex="1"
                                            >
                                                <img className="flex-none mr-2 convertWhite" src="/images/common/support.svg" alt="support" />
                                                <u>Support</u>
                                            </a>
                                        </Tooltip>
                                    </li>
                                </ul>
                                <Link
                                    className={`flex px-[20px] border-${currentMode}-730  hover:bg-${currentMode}-960 hover:rounded-b-[10px] border-t-[1px] py-[1rem]`}
                                    onClick={logoutsubmit}
                                    tabIndex="1"
                                    title="Logout"
                                >
                                    <img src="/images/header/logout.svg" className="mr-2 convertWhite" alt="setting" />
                                    Logout
                                </Link>
                            </div>
                        </Popper>
                    </ClickAwayListener>
                    {ReactDOM.createPortal(
                        <FreeTrail isOpened={trialOpen} handleClose={() => setTrialOpen(false)} className={trialOpen && "Show"} />,
                        document.getElementById("user-modal-root")
                    )}
                    {ReactDOM.createPortal(
                        <ProductTourMain isOpened={openTourPop} handleClose={() => setOpenTourPop(false)} className={openTourPop && "Show"} />,
                        document.getElementById("user-modal-root")
                    )}
                    {ReactDOM.createPortal(
                        <ReminderModal isOpened={reminderPopup} handleClose={() => setReminderPopup(false)} className={reminderPopup && "Show"} />,
                        document.getElementById("user-modal-root")
                    )}
                    {ReactDOM.createPortal(
                        <HelperVideoMain isOpened={openHelperPop} handleClose={() => setOpenHelperPop(false)} className={openHelperPop && "Show"} />,
                        document.getElementById("user-modal-root")
                    )}
                </div>
            </header>
        </>
    );
};

export default Header;
