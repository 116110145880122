import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Input from "../../../components/Input";
import Modal from "../../../components/UI/Modal/Modal";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import Button from "../../../components/Button";
import Swal from "sweetalert2";
export const CustomApiModal = (props) => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const serverResponse = useSelector((state) => state?.serverViewConfigState);
    const advanceSettingData = serverResponse?.data?.[0]?.advanced_settings;
    const checkEmptyArr = advanceSettingData?.filter((ele) => ele?.url == "");
    const [advanceSetting, setAdvanceSetting] = useState([]);
    const [updatedArr, setUpdatedArr] = useState([]);
    //onchange global function
    const handleChange = (e, index, label_name) => {
        switch (label_name) {
            case "NON - PO Based Invoice Parking Posting":
                advanceSetting?.forEach((name, i) => {
                    if (i == index && name?.description == label_name) {
                        name.url = e.target.value;
                        setUpdatedArr([...new Set(updatedArr), name]);
                    }
                });
                break;
            case "PO Based Invoice Parking Posting":
                advanceSetting?.forEach((name, i) => {
                    if (i == index && name?.description == label_name) {
                        name.url = e.target.value;
                        setUpdatedArr([...new Set(updatedArr), name]);
                    }
                });
                break;
            case "Vendor Master":
                advanceSetting?.forEach((name, i) => {
                    if (i == index && name?.method_name == label_name) {
                        name.url = e.target.value;
                        setUpdatedArr([...new Set(updatedArr), name]);
                    }
                });
                break;
            case "GL Master":
                advanceSetting?.forEach((name, i) => {
                    if (i == index && name?.method_name == label_name) {
                        name.url = e.target.value;
                        setUpdatedArr([...new Set(updatedArr), name]);
                    }
                });
                break;
            case "Cost Centre":
                advanceSetting?.forEach((name, i) => {
                    if (i == index && name?.method_name == label_name) {
                        name.url = e.target.value;
                        setUpdatedArr([...new Set(updatedArr), name]);
                    }
                });
                break;
            case "Internal Order Sync":
                advanceSetting?.forEach((name, i) => {
                    if (i == index && name?.method_name == label_name) {
                        name.url = e.target.value;
                        setUpdatedArr([...new Set(updatedArr), name]);
                    }
                });
                break;
            case "Payment Notification":
                advanceSetting?.forEach((name, i) => {
                    if (i == index && name?.method_name == label_name) {
                        name.url = e.target.value;
                        setUpdatedArr([...new Set(updatedArr), name]);
                    }
                });
                break;
            case "Tax Codes with Percentage":
                advanceSetting?.forEach((name, i) => {
                    if (i == index && name?.method_name == label_name) {
                        name.url = e.target.value;
                        setUpdatedArr([...new Set(updatedArr), name]);
                    }
                });
                break;
            case "Exchange Rate":
                advanceSetting?.forEach((name, i) => {
                    if (i == index && name?.method_name == label_name) {
                        name.url = e.target.value;
                        setUpdatedArr([...new Set(updatedArr), name]);
                    }
                });
                break;
            case "Accounting Period Status":
                advanceSetting?.forEach((name, i) => {
                    if (i == index && name?.method_name == label_name) {
                        name.url = e.target.value;
                        setUpdatedArr([...new Set(updatedArr), name]);
                    }
                });
                break;
            case "Material PO - Based":
                advanceSetting?.forEach((name, i) => {
                    if (i == index && name?.method_name == label_name) {
                        name.url = e.target.value;
                        setUpdatedArr([...new Set(updatedArr), name]);
                    }
                });
                break;
            case "Service PO - Based":
                advanceSetting?.forEach((name, i) => {
                    if (i == index && name?.method_name == label_name) {
                        name.url = e.target.value;
                        setUpdatedArr([...new Set(updatedArr), name]);
                    }
                });
                break;
            case "Posting Status":
                advanceSetting?.forEach((name, i) => {
                    if (i == index && name?.method_name == label_name) {
                        name.url = e.target.value;
                        setUpdatedArr([...new Set(updatedArr), name]);
                    }
                });
                break;
            case "Material PO":
                advanceSetting?.forEach((name, i) => {
                    if (i == index && name?.method_name == label_name) {
                        name.url = e.target.value;
                        setUpdatedArr([...new Set(updatedArr), name]);
                    }
                });
                break;
            case "Service PO":
                advanceSetting?.forEach((name, i) => {
                    if (i == index && name?.method_name == label_name) {
                        name.url = e.target.value;
                        setUpdatedArr([...new Set(updatedArr), name]);
                    }
                });
                break;
            case "GRN Information":
                advanceSetting?.forEach((name, i) => {
                    if (i == index && name?.method_name == label_name) {
                        name.url = e.target.value;
                        setUpdatedArr([...new Set(updatedArr), name]);
                    }
                });
                break;

            default:
                break;
        }
        setAdvanceSetting([...advanceSetting]);
    };
    //submit save function
    const handleSubmit = () => {
        if (checkEmptyArr?.length !== 0) {
            Swal.fire({
                text: `Fill in all the fields!`,
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else {
            props?.setCustomApiData(advanceSetting);
            props?.handleClose();
        }
    };
    //updated array render
    useEffect(() => {
        if (advanceSettingData !== undefined) {
            setAdvanceSetting(advanceSettingData);
        }
    }, [advanceSettingData]);
    return (
        <>
            <Modal upperLay={true} cartClose={props?.handleClose} center="center" className={`${props?.className}`}>
                <div
                    className={` xl:min-w-[916px] relative transition bg-${currentMode}-card border-[rgba(236,236,236,0.88)] ${
                        currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                    } rounded-[10px] duration-150 ease-out p-[30px]`}>
                    <div className={`max-h-[658px] bg-${currentMode}-card mx-[-30px] z-[9] py-[20px] px-[50px]`}>
                        <span className="cursor-pointer absolute right-[2rem] convertWhite top-[1.3rem]" onClick={props?.handleClose}>
                            <img src="/images/common/close-one.svg" alt="close-one" />
                        </span>
                        <div className="max-h-[511px] overflow-y-auto">
                            <table className="w-full rolemasterTable border rounded-[8px] lastBorder text-left ">
                                <thead>
                                    <tr>
                                        <th className="px-[10px]">API Type</th>
                                        <th className="">Method Name</th>
                                        <th>URL</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {advanceSettingData?.map((elem, index) => {
                                        return (
                                            <tr>
                                                <td className="px-[10px]">{elem?.api_type}</td>
                                                <td className="">{elem?.method_name}</td>
                                                <td className="flex">
                                                    <Input
                                                        value={elem?.url}
                                                        onChange={(e) => handleChange(e, index, elem?.method_name)}
                                                        className={`w-full bg-${currentMode}-810 border-${currentMode}-840 bg-${currentMode}-card`}
                                                        placeholder="sap/opu/odata/MYIQ/INVOICE_SRV/GLAccountSet"
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="space-x-2 mt-9 ml-5 flex">
                        <Button
                            type="submit"
                            title="To Save"
                            onClick={handleSubmit}
                            className={`bg-light-20 flex text-base font-interR py-[.5rem] px-[2.24rem] rounded-[8px] text-white`}>
                            Save
                        </Button>
                        <Button
                            type="cancel"
                            onClick={props?.handleClose}
                            title="To Cancel"
                            className="bg-light-240 flex text-base font-interR py-[.5rem] px-[1.9rem] rounded-[8px] text-light-40 closeModal">
                            Cancel
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};
