import {
    GET_ADVANCE_DETAILS_FOR_CREATING_REQUEST,
    GET_ADVANCE_DETAILS_FOR_CREATING_SUCCESS,
    GET_ADVANCE_DETAILS_FOR_CREATING_RESET,
    GET_ADVANCE_DETAILS_FOR_CREATING_FAILURE,
    GET_ADVANCE_HISTORY_LIST_REQUEST,
    GET_ADVANCE_HISTORY_LIST_SUCCESS,
    GET_ADVANCE_HISTORY_LIST_RESET,
    GET_ADVANCE_HISTORY_LIST_FAILURE,
    GET_DOCUMENT_FROM_S3_FOR_ADVANCE_REQUEST,
    GET_DOCUMENT_FROM_S3_FOR_ADVANCE_SUCCESS,
    GET_DOCUMENT_FROM_S3_FOR_ADVANCE_RESET,
    GET_DOCUMENT_FROM_S3_FOR_ADVANCE_FAILURE,
    ADD_UPDATE_ADVANCE_TYPE_REQUEST,
    ADD_UPDATE_ADVANCE_TYPE_RESET,
    ADD_UPDATE_ADVANCE_TYPE_SUCCESS,
    ADD_UPDATE_ADVANCE_TYPE_FAILURE,
    ADD_UPDATE_COMMENTS_REQUEST,
    ADD_UPDATE_COMMENTS_SUCCESS,
    ADD_UPDATE_COMMENTS_RESET,
    ADD_UPDATE_COMMENTS_FAILURE,
    GET_ADVANCE_STATUS_LIST_REQUEST,
    GET_ADVANCE_STATUS_LIST_SUCCESS,
    GET_ADVANCE_STATUS_LIST_RESET,
    GET_ADVANCE_STATUS_LIST_FAILURE,
    GET_PROFORMA_INVOICE_VIEW_REQUEST,
    GET_PROFORMA_INVOICE_VIEW_SUCCESS,
    GET_PROFORMA_INVOICE_VIEW_RESET,
    GET_PROFORMA_INVOICE_VIEW_FAILURE,
    GET_ADVANCE_REQUEST_VIEW_REQUEST,
    GET_ADVANCE_REQUEST_VIEW_SUCCESS,
    GET_ADVANCE_REQUEST_VIEW_RESET,
    GET_ADVANCE_REQUEST_VIEW_FAILURE,
    GET_ADVANCE_LIST_REQUEST,
    GET_ADVANCE_LIST_SUCCESS,
    GET_ADVANCE_LIST_RESET,
    GET_ADVANCE_LIST_FAILURE,
    EXPORT_ADVANCE_REQUEST_LIST_SUCCESS,
    GET_PO_AND_VENDOR_FOR_ADVANCE_REQUEST,
    GET_PO_AND_VENDOR_FOR_ADVANCE_SUCCESS,
    GET_PO_AND_VENDOR_FOR_ADVANCE_RESET,
    GET_PO_AND_VENDOR_FOR_ADVANCE_FAILURE,
    GET_USER_LIST_FOR_COMMENNTS_DROPDOWN_REQUEST,
    GET_USER_LIST_FOR_COMMENNTS_DROPDOWN_SUCCESS,
    GET_USER_LIST_FOR_COMMENNTS_DROPDOWN_RESET,
    GET_USER_LIST_FOR_COMMENNTS_DROPDOWN_FAILURE,
    GET_EXCHANGE_RATE_BY_DATE_REQUEST,
    GET_EXCHANGE_RATE_BY_DATE_SUCCESS,
    GET_EXCHANGE_RATE_BY_DATE_RESET,
    GET_EXCHANGE_RATE_BY_DATE_FAILURE,
    GET_GL_INDICATOR_LIST_REQUEST,
    GET_GL_INDICATOR_LIST_SUCCESS,
    GET_GL_INDICATOR_LIST_RESET,
    GET_GL_INDICATOR_LIST_FAILURE,
    UPDATE_STATUS_FOR_ADVANCE_REQUEST,
    UPDATE_STATUS_FOR_ADVANCE_SUCCESS,
    UPDATE_STATUS_FOR_ADVANCE_RESET,
    UPDATE_STATUS_FOR_ADVANCE_FAILURE,
    POST_PROFORMA_INVOICE_VIEW_REQUEST,
    POST_PROFORMA_INVOICE_VIEW_SUCCESS,
    POST_PROFORMA_INVOICE_VIEW_FAILURE,
    POST_PROFORMA_INVOICE_VIEW_RESET,
    INVOICE_UPDATE_REQUEST,
    INVOICE_UPDATE_SUCCESS,
    INVOICE_UPDATE_FAILURE,
    INVOICE_UPDATE_RESET,
    GET_PO_List_For_Advance_Request,
    GET_PO_List_For_Advance_SUCCESS,
    GET_PO_List_For_Advance_RESET,
    GET_PO_List_For_Advance_FAILURE,
} from "../types/types.js";

//Getadvance details for creation
const GetAdvanceDetailsforCreateState = {
    data: {},
    fetching: false,
    requestComplete: false,
    error: "",
};
export function GetAdvanceDetailsforCreate(state = GetAdvanceDetailsforCreateState, action) {
    switch (action.type) {
        case GET_ADVANCE_DETAILS_FOR_CREATING_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_ADVANCE_DETAILS_FOR_CREATING_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_ADVANCE_DETAILS_FOR_CREATING_RESET:
            return {
                data: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_ADVANCE_DETAILS_FOR_CREATING_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

//GetGeneralLedgerSyncList
const GetAdvanceHistoryState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};
export function GetAdvanceHistory(state = GetAdvanceHistoryState, action) {
    switch (action.type) {
        case GET_ADVANCE_HISTORY_LIST_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_ADVANCE_HISTORY_LIST_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_ADVANCE_HISTORY_LIST_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_ADVANCE_HISTORY_LIST_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

//get document from s3 for advance request
const AdvanceDocState = {
    dataList: [],
    fetching: false,
    requestComplete: false,
    error: "",
    imageId: "",
};

export function documentFromS3ForAdvance(state = AdvanceDocState, action) {
    switch (action.type) {
        case GET_DOCUMENT_FROM_S3_FOR_ADVANCE_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_DOCUMENT_FROM_S3_FOR_ADVANCE_SUCCESS:
            return {
                dataList: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
                imageId: action.id,
            };
        case GET_DOCUMENT_FROM_S3_FOR_ADVANCE_RESET:
            return {
                dataList: [],
                fetching: false,
                requestComplete: false,
                error: "",
                imageId: action.id,
            };
        case GET_DOCUMENT_FROM_S3_FOR_ADVANCE_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
                imageId: "",
            };
        default:
            return state;
    }
}
//ADD UPDATE ADVANCE REQUEST
const addUpdateAdvanceRequestState = {
    data: {},
    fetching: false,
    requestComplete: false,
    error: "",
};

export function addUpdateAdvanceRequest(state = addUpdateAdvanceRequestState, action) {
    switch (action.type) {
        case ADD_UPDATE_ADVANCE_TYPE_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case ADD_UPDATE_ADVANCE_TYPE_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case ADD_UPDATE_ADVANCE_TYPE_RESET:
            return {
                data: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case ADD_UPDATE_ADVANCE_TYPE_FAILURE:
            return {
                fetching: false,
                requestComplete: true,
                error: action.payload,
            };
        default:
            return state;
    }
}

//ADD UPDATE COMMENTS ADVANCE REQUEST
const addUpdateCommentsState = {
    data: {},
    fetching: false,
    requestComplete: false,
    error: "",
};

export function addupdateComments(state = addUpdateCommentsState, action) {
    switch (action.type) {
        case ADD_UPDATE_COMMENTS_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case ADD_UPDATE_COMMENTS_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case ADD_UPDATE_COMMENTS_RESET:
            return {
                data: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case ADD_UPDATE_COMMENTS_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

//GET ADVANCE STATUS LIST

const getAdvanceStatusListState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
    orgId: "",
};

export function getAdvanceStatusList(state = getAdvanceStatusListState, action) {
    switch (action.type) {
        case GET_ADVANCE_STATUS_LIST_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_ADVANCE_STATUS_LIST_SUCCESS:
            return {
                data: action.payload,
                orgId: action.orgId,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_ADVANCE_STATUS_LIST_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
                orgId: "",
            };
        case GET_ADVANCE_STATUS_LIST_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
                orgId: "",
            };
        default:
            return state;
    }
}

//GET PROFORMA INVOICE VIEW
const proformaInvoiceViewState = {
    data: {},
    fetching: false,
    requestComplete: false,
    error: "",
};
export function GetProformaInvoiceView(state = proformaInvoiceViewState, action) {
    let getData = action?.payload;
    if (action?.type == "INVOICE_GET_SUCCESS" || action?.type == "INVOICE_GET__SUCCESS") {
        getData.datalist = action.payload.datalist.map((item) => {
            return {
                ...item,
                cancelData: item?.matching_info,
            };
        });
    }
    switch (action.type) {
        case GET_PROFORMA_INVOICE_VIEW_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_PROFORMA_INVOICE_VIEW_SUCCESS:
            return {
                data: getData,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_PROFORMA_INVOICE_VIEW_RESET:
            return {
                data: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_PROFORMA_INVOICE_VIEW_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

//GET ADVANCE REQUEST VIEW

const getAdvanceRequestViewState = {
    data: {},
    fetching: false,
    requestComplete: false,
    error: "",
};

export function getAdvanceRequestView(state = getAdvanceRequestViewState, action) {
    switch (action.type) {
        case GET_ADVANCE_REQUEST_VIEW_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_ADVANCE_REQUEST_VIEW_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_ADVANCE_REQUEST_VIEW_RESET:
            return {
                data: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_ADVANCE_REQUEST_VIEW_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

//GET ADVANCE LIST WITH COUNT

const advanceRequestListState = {
    exportData: {},
    data: {},
    fetching: false,
    requestComplete: false,
    error: "",
};

export function advanceRequestListReduc(state = advanceRequestListState, action) {
    switch (action.type) {
        case GET_ADVANCE_LIST_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_ADVANCE_LIST_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case EXPORT_ADVANCE_REQUEST_LIST_SUCCESS:
            return {
                ...state,
                exportData: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_ADVANCE_LIST_RESET:
            return {
                data: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_ADVANCE_LIST_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

//GET PO & VENDOR FOR ADVANCE FILTER
const getAdvanceRequestPoAndVendorListState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function getAdvanceRequestPoAndVendorList(state = getAdvanceRequestPoAndVendorListState, action) {
    switch (action.type) {
        case GET_PO_AND_VENDOR_FOR_ADVANCE_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_PO_AND_VENDOR_FOR_ADVANCE_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_PO_AND_VENDOR_FOR_ADVANCE_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_PO_AND_VENDOR_FOR_ADVANCE_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

//GET USER LIST FOR COMMENTS
const getUserForCommentsListState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function getUserForCommentsList(state = getUserForCommentsListState, action) {
    switch (action.type) {
        case GET_USER_LIST_FOR_COMMENNTS_DROPDOWN_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_USER_LIST_FOR_COMMENNTS_DROPDOWN_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_USER_LIST_FOR_COMMENNTS_DROPDOWN_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_USER_LIST_FOR_COMMENNTS_DROPDOWN_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

//get exchange rate by date
const getExchangeRateByDateState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function getExchangeRateByDate(state = getExchangeRateByDateState, action) {
    switch (action.type) {
        case GET_EXCHANGE_RATE_BY_DATE_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_EXCHANGE_RATE_BY_DATE_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_EXCHANGE_RATE_BY_DATE_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_EXCHANGE_RATE_BY_DATE_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
//GET GL INDICATOR
//GET USER LIST FOR COMMENTS
const getGLIndicatorListState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function getGLIndicatorList(state = getGLIndicatorListState, action) {
    switch (action.type) {
        case GET_GL_INDICATOR_LIST_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_GL_INDICATOR_LIST_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_GL_INDICATOR_LIST_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_GL_INDICATOR_LIST_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const postStatusUpdateforAdvanceState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function updateStatusForAdvance(state = postStatusUpdateforAdvanceState, action) {
    switch (action.type) {
        case UPDATE_STATUS_FOR_ADVANCE_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case UPDATE_STATUS_FOR_ADVANCE_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case UPDATE_STATUS_FOR_ADVANCE_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case UPDATE_STATUS_FOR_ADVANCE_FAILURE:
            return {
                fetching: false,
                requestComplete: true,
                error: action.payload,
            };
        default:
            return state;
    }
}

// UPDATE PROFORMA INVOICE
const proformaInvoiceUpdateState = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function proformaUpdateInvoiceView(state = proformaInvoiceUpdateState, action) {
    switch (action.type) {
        case INVOICE_UPDATE_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case INVOICE_UPDATE_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case INVOICE_UPDATE_FAILURE:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "Something went wrong. Please contact the admin.",
            };
        case INVOICE_UPDATE_RESET:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

//GET PO & VENDOR FOR ADVANCE FILTER
const GetListAdvanceReqForPo = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function GetListAdvanceReqForPoData(state = GetListAdvanceReqForPo, action) {
    switch (action.type) {
        case GET_PO_List_For_Advance_Request:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_PO_List_For_Advance_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_PO_List_For_Advance_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_PO_List_For_Advance_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
