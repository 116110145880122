import _ from "lodash";
import { memo, useCallback, useMemo, useState } from "react";
import { useDrop } from "react-dnd";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Input from "../../components/Input";
import { Checkbox } from "@mui/material";
import Swal from "sweetalert2";

export const JsonTarget = memo(function Container({ data, currentMode, onDrop, areaName, index, handleChange, name, isDisable, mainData }) {
    const { jsonValues, jsonData } = useSelector((state) => state?.droppedState);
    const [dropped, setDropped] = useState([]);
    const resultArray = _.uniqBy(jsonValues, "column_name");
    useMemo(() => {
        setDropped(resultArray);
    }, [jsonValues]);
    const findCard = useCallback(
        (id) => {
            const card = dropped.filter((c) => `${c.id}` === id)[0];
            return {
                card,
                index: dropped.indexOf(card),
            };
        },
        [dropped]
    );
    const moveCard = useCallback(
        (id, atIndex) => {
            const { card, index } = findCard(id);
            dispatch({ type: "MOVE_THE_CARD", payload: { card, index, atIndex } }); //This action for after card swapped original array should be modified!!
        },
        [findCard, dropped, setDropped]
    );
    const [, drop] = useDrop(() => ({ accept: "card" }));
    const dispatch = useDispatch();
    const IsNormal = mainData?.parent_id == "" && mainData?.isChild == false;
    const CheckTracked = typeof mainData?.content == "object" ? true : false;
    const [{ isOver }, drag] = useDrop({
        accept: "DRAGGABLE_ITEM",
        drop: (item, i) => {
            onDrop(item?.value, areaName, index, mainData?.parent_id, IsNormal, CheckTracked);
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });

    const handleCheckChange = (e, id) => {
        dispatch({ type: "UPDATE_ISARRAY", payload: { id, isArray: e?.target?.checked } });
    };
    const _eRase = () => {
        Swal.fire({
            text: `Are you sure you want to erase the field value?`,
            icon: "warning",
            showCloseButton: true,
            showCancelButton: true,
            buttons: {
                cancel: "Cancel",
                confirm: "Confirm",
            },
        }).then((confirm) => {
            if (confirm?.isConfirmed) {
                // if()
                const getData = jsonData?.filter((el) => el?.id != mainData?.id && el?.parent_id == mainData?.parent_id);
                const allHaveMultipleObjects = getData?.some((item) => typeof item?.content == "object" && item?.content?.multiple_objects);
                handleChange(index, "content", "", mainData?.parent_id, allHaveMultipleObjects);
            }
        });
    };
    return (
        <div ref={drag}>
            <div ref={drop} className={`flex relative flex-wrap items-center w-full`}>
                {mainData?.isChild ? (
                    <div className={`px-[20px] flex items-center  py-[9px] ${mainData?.isDisable ? `cursor-not-allowed` : ``}`}>
                        {" "}
                        <Checkbox
                            sx={{
                                padding: 0,
                                marginRight: "10px",
                                color: "rgba(85,85,85,0.70)",
                                "&.Mui-checked": {
                                    color: "#028C78",
                                },
                            }}
                            disabled={mainData?.isDisable}
                            size="small"
                            checked={mainData?.isArray}
                            onChange={(e) => handleCheckChange(e, mainData?.id)}
                        />
                        <span className="truncate max-w-[155px]">Is Array</span>
                    </div>
                ) : (
                    <>
                        <Input
                            value={!mainData?.isChild ? data : ``}
                            disabled={isDisable}
                            className={`px-[20px]  ${isOver ? "bg-[#f0f0f0]" : `bg-${currentMode}-card`} ${
                                CheckTracked ? `pointer-events-none` : ``
                            }  py-[9px]  w-full`}
                            name={name}
                            onChange={(e) => handleChange(index, "content", e?.target?.value, mainData?.parent_id)}
                            placeholder={!mainData?.isChild ? `Type here / Drag and Drop` : ``}
                        />
                        {CheckTracked && (
                            <img
                                title="Click the clear formatting to remove the text."
                                className="absolute cursor-pointer right-[20px]"
                                onClick={_eRase}
                                src="/images/Output-manage/clear-format.svg"
                                alt="clear-format"
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    );
});
