import { Checkbox, FormControl, FormControlLabel, MenuItem, Select, TextField } from "@mui/material";
import { useEffect, useMemo, useState, useContext } from "react";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import Card from "../../../components/UI/Card";
import Main from "../../../components/UI/Main";
import Layout from "../../../Hoc/Layout";
import Footer from "../../../organisms/Footer";
import AllocationUserModal from "./UserModal";
import ReactDOM from "react-dom";
import UserDetailsModal from "./UserDetailsModal";
import { Link } from "react-router-dom";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { getRoleListAllocation, roleid, ViewRolePermission } from "../../../redux/actions/role";
import { UserAllocationDropDown, UserDropDown } from "../../../redux/actions/user";
import { GetRuleView } from "../../../redux/actions/rule-based";
import { GetRuleList, GetViewRuleList } from "../../../redux/actions/rule-based";
import { addInvoiceAllocation, getInvoiceAllocationList } from "../../../redux/actions/invoice";
import NotificationAllocationModal from "./notification";
import Swal from "sweetalert2";
import SuccessMessageBox from "../../../components/UI/Alert/sucess";
import MessageBox from "../../../components/UI/Alert";
import Loader from "../../../components/Loader/index";
import { ADD_INVOICE_ALLOCATION_RESET, GET_INVOICE_ALLOCATION_RESET } from "../../../redux/types/types";
import RoleDetails from "./RoleDetails";
import _ from "lodash";
import { async } from "@firebase/util";
import { encryptId } from "../../../utils/helpers";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import { getCurrentUser } from "../../../redux/actions/auth";
const InvoiceAllocation = () => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const errormsg = "Something went wrong please contact admin";
    let AssignedArray = [];
    const AllocListData = useSelector((state) => state?.getInvoiceAllocationListState);
    let invAllocList = AllocListData?.dataList;
    let AllocListDatas = invAllocList?.map((el) => el?.allocation);
    let allocationList = invAllocList?.[0]?.allocation;
    const EmptyArr = [];
    const [AddItems, setAddItems] = useState([]);
    const notificationListData = invAllocList?.[0];
    const dispatch = useDispatch();
    const [userId, setUserId] = useState();
    const [RoleId, setRoleId] = useState();
    const [globalState, setGlobalState] = useState({
        assign_roleid: 0,
        assign_ruleid: 0,
        // orgSelect: false,
        approve_roleid: "",
        approve_ruleid: "",
        email_enabled: false,
        viewCustomDefine: false,
        viewApprovalHead: false,
        assign_rule: false,
        approve_rule: false,
        assign_role: false,
        approve_role: false,
        viewEmailBcc: false,
        assign_roundrobbin: false,
        approve_roundrobbin: false,
        notification_title: "",
        notification_msg: "",
        email_cc: [""],
        email_bcc: [""],
        email_body: "",
        email_sub: "",
        assign_users: [],
        approve_users: [],
        notification_enabled: false,
        // organisation_id: orgName,
    });
    let InitialObj = {
        identity_id: 0,
        assign_roundrobbin: false,
        assign_role: false,
        assign_roleid: 0,
        assign_rule: false,
        assign_ruleid: 0,
        assign_open: false,
        approve_open: false,
        approve_role: false,
        approve_roleid: 0,
        approve_rule: false,
        approve_ruleid: 0,
        approve_roundrobbin: false,
        assign_users: [],
        assign_usersrole: [],
        approve_usersrole: [],
        assignedusers: [],
        approve_users: [],
        approvedusers: [],
        onclick: false,
        onclick2: false,
        seq: 0,
    };
    const [main, setMain] = useState([InitialObj]);
    const RoleAssignMatchresult = main?.every((item) => {
        if (item?.assign_roleid && item?.assign_usersrole) {
            return item?.assign_usersrole?.every((role) => role === item?.assign_roleid);
        }
        return true; // If no role or usersrole data, consider it a match
    });
    const RoleApproveMatchresult = main?.every((item) => {
        if (item?.approve_roleid && item?.approve_usersrole) {
            return item?.approve_usersrole?.every((role) => role === item?.approve_roleid);
        }
        return true; // If no role or usersrole data, consider it a match
    });
    useEffect(() => {
        dispatch(GetRuleList());
        dispatch(UserAllocationDropDown(orgName));
        dispatch(getRoleListAllocation(+orgName));
        dispatch(getInvoiceAllocationList(+orgName));
    }, [dispatch]);
    useEffect(() => {
        if (AllocListData?.length !== 0 || AllocListData !== null) {
            if (AllocListData?.dataList?.[0]?.allocation !== null) {
                setMain(allocationList);
            } else {
                setMain([InitialObj]);
            }
        }
        if (AllocListData?.dataList?.length == 0) {
            setMain([InitialObj]);
        }
    }, [AllocListData]);
    const { data: datas, requestComplete: reqComp, fetching, error } = useSelector((state) => state?.addInvoiceAllocationState);

    // This one Add function from to main array
    const addRow = () => {
        const filteredArray = main.filter((obj) => Object.keys(obj).length !== 0);
        setMain([
            ...filteredArray,
            {
                identity_id: 0,
                assign_roundrobbin: false,
                assign_role: false,
                assign_roleid: 0,
                assign_rule: false,
                assign_ruleid: 0,
                assign_open: false,
                approve_open: false,
                approve_role: false,
                approve_roleid: 0,
                approve_rule: false,
                approve_ruleid: 0,
                approve_roundrobbin: false,
                assign_users: [],
                assignedusers: [],
                approve_users: [],
                approvedusers: [],
                assign_usersrole: [],
                approve_usersrole: [],
                onclick: false,
                onclick2: false,
                seq: main.length++,
            },
        ]);
    };
    // const removeItem = (index) => {
    //     const updatedItems = [...items];
    //     updatedItems.splice(index, 1);

    //     // Update the id values of the remaining items
    //     const updatedItemsWithNewIds = updatedItems.map((item, i) => ({
    //         ...item,
    //         id: i + 1
    //     }));

    //     setItems(updatedItemsWithNewIds);
    // };
    // This one Remove function from main array
    const removeRow = (index) => {
        const updatedItems = [...main];
        updatedItems.splice(index, 1);
        const updatedItemsWithNewIds = updatedItems?.map((item, i) => ({
            ...item,
            seq: i,
        }));
        setMain(updatedItemsWithNewIds);
    };

    // This Function is Showing Checkbox dynamically
    const _handleView = (index, field, value) => {
        const Update = _.chain(main)
            .find({ seq: index })
            .merge(field == "assign" ? { onclick: true, assign_roundrobbin: true } : { onclick2: true, approve_roundrobbin: true })
            .value();
        const New = main?.map((el, i) => (el?.seq == Update?.seq ? Update : el));
        setMain(New);
    };

    // This one is approve Rule based checkox onchange dynamically
    const _newAssignRuleChange = (index, field, e) => {
        if (e?.target?.checked) {
            const Update = _.chain(main)
                .find({ seq: index })
                .merge(field == "assign" ? { assign_rule: e?.target?.checked } : { approve_rule: e?.target?.checked })
                .value();
            const New = main?.map((el, i) => (el?.seq == Update?.seq ? Update : el));
            setMain(New);
        } else if (!e?.target?.checked) {
            const Update = _.chain(main)
                .find({ seq: index })
                .merge(
                    field == "assign"
                        ? {
                              assign_ruleid: 0,
                              assign_rule: e?.target?.checked,
                              assignedusers: null,
                          }
                        : {
                              approve_ruleid: 0,
                              approve_rule: e?.target?.checked,
                              approvedusers: null,
                          }
                )
                .value();
            const New = main?.map((el, i) => (el?.seq == Update?.seq ? Update : el));
            setMain(New);
        }
    };

    // This one is approve Role based checkox onchange dynamically
    const _newRoleChange = (index, field, e) => {
        if (e?.target?.checked) {
            const Update = _.chain(main)
                .find({ seq: index })
                .merge(field == "assign" ? { assign_role: e?.target?.checked } : { approve_role: e?.target?.checked })
                .value();
            const New = main?.map((el, i) => (el?.seq == Update?.seq ? Update : el));
            setMain(New);
        } else if (!e?.target?.checked) {
            const Update = _.chain(main)
                .find({ seq: index })
                .merge(
                    field == "assign"
                        ? { assign_role: e?.target?.checked, assign_roleid: 0 }
                        : {
                              approve_role: e?.target?.checked,
                              approve_roleid: 0,
                          }
                )
                .value();
            const New = main?.map((el, i) => (el?.seq == Update?.seq ? Update : el));
            setMain(New);
        }
    };

    // This one is select dropdown handlechange for Rule based dynamically
    const _newAssignRuleIDChange = (index, field, e) => {
        const Update = _.chain(main)
            .find({ seq: index })
            .merge(field == "assign" ? { assign_ruleid: e?.target?.value } : { approve_ruleid: e?.target?.value })
            .value();
        const New = main?.map((el, i) => (el?.seq == Update?.seq ? Update : el));
        setMain(New);
    };

    // This one is select dropdown handlechange for Role based dynamically
    const _newRoleIdChange = (index, field, e) => {
        const Update = _.chain(main)
            .find({ seq: index })
            .merge(field == "assign" ? { assign_roleid: e?.target?.value } : { approve_roleid: e?.target?.value })
            .value();
        const New = main?.map((el, i) => (el?.seq == Update?.seq ? Update : el));
        setMain(New);
    };
    // This one is Add user modal Onclick function
    const _handleUserAdd = (index, field, value, users) => {
        setUserId(users);
        const Update = _.chain(main)
            .find({ seq: index })
            .merge(field == "assign" ? { assign_open: value } : { approve_open: value })
            .value();
        const New = main?.map((el, i) => (el?.seq == Update?.seq ? Update : el));
        setMain(New);
    };

    const _handleViewRuleIcon = (id) => {
        setUserView(true);
        dispatch(GetRuleView(+id));
    };
    const _handleSelectUser = (index, field, value, reason) => {
        if (reason === "selectOption") {
            const UserId = value?.map((el) => +el?.user_id);
            const RoleId = value?.map((el) => +el?.role_id);
            let SetArr;
            if (userId !== null && userId !== undefined) {
                SetArr = [...userId, ...UserId];
            } else {
                SetArr = [...UserId];
            }
            const Unique = [...new Set(SetArr)];
            setUserId(Unique);
            const Update = _.chain(main)
                .find({ seq: index })
                .merge(field == "assign" ? { assignedusers: UserId } : { approvedusers: UserId })
                .value();
            const New = main?.map((el, i) => (el?.seq == Update?.seq ? Update : el));
            setMain(New);
            let SetRoleArr;
            if (RoleId !== null && RoleId !== undefined) {
                SetRoleArr = [...RoleId, ...RoleId];
            } else {
                SetRoleArr = [...RoleId];
            }
            setRoleId(SetRoleArr);
            const UpdateValue = _.chain(main)
                .find({ seq: index })
                .merge(field == "assign" ? { assign_usersrole: RoleId } : { approve_usersrole: RoleId })
                .value();
            const NewValue = main?.map((el, i) => (el?.seq == UpdateValue?.seq ? UpdateValue : el));
            setMain(NewValue);
        } else if (reason === "removeOption" || reason === "clear") {
            const userReomove = value?.map((el) => +el?.user_id);
            const Exist = userId?.filter((el) => userReomove?.includes(el));
            const Arr = [...Exist];
            setUserId(Arr);

            const Update = _.set(_.find(main, { seq: index }), field == "assign" ? "assignedusers" : "approvedusers", Arr);
            const New = main?.map((el) => (el?.seq == Update?.seq ? Update : el));
            setMain(New);

            const RoleReomove = value?.map((el) => +el?.role_id);
            const ExistNew = RoleId?.filter((el) => RoleReomove?.includes(el));
            const Array = [...ExistNew];
            setRoleId(Array);

            const UpdateNew = _.set(_.find(main, { seq: index }), field == "assign" ? "assign_usersrole" : "approve_usersrole", Array);
            const NewValue = main?.map((el) => (el?.seq == UpdateNew?.seq ? UpdateNew : el));
            setMain(NewValue);
        }
    };
    const _handleUserClose = (index, field, value) => {
        const Update = _.chain(main)
            .find({ seq: index })
            .merge(field == "assign" ? { assign_open: false } : { approve_open: false })
            .value();
        const New = main?.map((el, i) => (el?.seq == Update?.seq ? Update : el));
        setMain(New);
    };

    const orgId = localStorage.getItem("orgId");
    const [orgName, setOrgName] = useState(orgId);
    const [approveAddUser, setApproveAddUser] = useState(false);
    const [userView, setUserView] = useState(false);
    const [roleView, setRoleView] = useState(false);
    const orgData = useSelector((state) => state?.ListorganisationDropDownState);
    const orgList = orgData?.viewData;
    const role = useSelector((state) => state?.RoleListAllocationState);
    const profileData = useSelector((state) => state?.userAllocationDropDownState);
    const Rule = useSelector((state) => state?.GetRuleListState);
    const { data: RuleListDatas, requestComplete } = Rule;
    const RuleListData = RuleListDatas?.filter((el) => el?.isactive == true);
    const { data: listData } = profileData;
    const { data: RoleList } = role;

    const [layoutItems, setLayoutItems] = useState([allocationList]);
    const [reset, setReset] = useState(false);
    const [customView, setCustomView] = useState(false);
    const [customApproval, setcustomApproval] = useState(false);
    const [assign_role, setassignrole] = useState(false);
    const [assign_roundrobbin, setassign_roundrobbin] = useState(false);
    const [assign_roleid, setassign_roleid] = useState(0);
    const [assign_rule, setassign_rule] = useState(false);
    const [assign_ruleid, setassign_ruleid] = useState(0);
    const [approve_role, setapprove_role] = useState(false);
    const [approve_roleid, setapprove_roleid] = useState(0);
    const [approve_rule, setapprove_rule] = useState(false);
    const [approve_ruleid, setapprove_ruleid] = useState(0);
    const [approve_roundrobbin, setapprove_roundrobbin] = useState(false);
    const [receivers, setReceivers] = useState([]);
    const emailcc = receivers?.map((i) => encryptId(i));
    const [bccreceivers, setBccRecievers] = useState([]);
    const emailbcc = bccreceivers?.map((i) => encryptId(i));
    let [assignusers, setassignusers] = useState(null);
    let [approve_users, setapprove_users] = useState(null);
    const ruleList = useSelector((state) => state?.GetViewRuleListState);
    const ruleDataList = ruleList?.data?.data;
    const { data: ViewRule } = useSelector((state) => state?.RuleViewState);
    const DataValue = ViewRule?.[0];
    const DataItems = DataValue?.rules_info;
    const [notificationenable, setnotificationEnable] = useState(false);
    const [Title, setTitle] = useState();
    const [message, setMessage] = useState("");
    const [Emailenable, setemailEnable] = useState();
    const [subject, setSubject] = useState("");
    const [emailMessage, setEmailmessage] = useState();
    if (allocationList == undefined) {
        allocationList = [];
    }
    const mapArr = allocationList?.length == 0 ? AddItems : allocationList;
    const AssignRoleValid = main?.filter((el) => el?.assign_roleid == 0 && el?.assign_role == true);
    const AssignRoundRobbinValid = main?.filter((el, i) => el?.assign_roundrobbin == false && el?.approve_roundrobbin == false);
    const ApproveRounRobbinValid = main?.filter((el, i) => el?.approve_roundrobbin == false && i == el?.seq);
    const ApproveRoleValid = main?.filter((el) => el?.approve_roleid == 0 && el?.approve_role == true);
    const AssignRuleValid = main?.filter((el) => el?.assign_ruleid == 0 && el?.assign_rule == true);
    const ApproveRuleValid = main?.filter((el) => el?.approve_ruleid == 0 && el?.approve_rule == true);
    const AssignAdduserValid = main?.filter(
        (el) =>
            el?.assign_ruleid !== 0 &&
            el?.assign_rule == true &&
            (el?.assignedusers == null || el?.assignedusers?.length == 0) &&
            el?.assign_role == false
    );
    const ApproveAdduserValid = main?.filter(
        (el) =>
            el?.approve_ruleid !== 0 &&
            el?.approve_rule == true &&
            (el?.approvedusers == null || el?.approvedusers.length == 0) &&
            el?.approve_role == false
    );
    const [appindexValue, setappIndexValue] = useState(null);

    AssignedArray = allocationList?.map((el) => el?.assign_users);
    let IntMix = [];
    const [isOrg, setIsOrg] = useState(false);
    const onChangeOrganisation = (e) => {
        dispatch({ type: GET_INVOICE_ALLOCATION_RESET });
        setMessage(null);
        setSubject(null);
        setIsOrg(true);
        setReceivers([]);
        setBccRecievers([]);
        setAddItems([]);
        setTitle("");
        setnotificationEnable(false);
        setemailEnable(false);
        setReset(true);
        setOrgName(+e.target.value);
        setGlobalState({ ...globalState, orgSelect: true });

        if (+e.target.value !== 0) {
            dispatch(getInvoiceAllocationList(+e.target.value));
            dispatch(UserAllocationDropDown(+e.target.value));
            dispatch(getRoleListAllocation(+e.target.value));
        }
        setCustomView(false);
        setcustomApproval(false);
        setassign_roleid(0);
        setassign_rule(false);
        setapprove_rule(false);
        setassignrole(false);
        setapprove_role(false);
        setassign_ruleid(0);
        setapprove_roleid(0);
        setapprove_ruleid(0);
        setassignusers(null);
        setapprove_users(null);
    };

    const handleSelectEmailCC = (event, index) => {
        invAllocList.forEach((element, ind) => {
            element.email_cc = index?.map((el) => el?.id);
        });
        setLayoutItems([...layoutItems]);
        // setGlobalState({ ...globalState, emailCC: value?.map((el) => el?.id) });
    };
    const handleSelectEmailBcc = (event, index) => {
        invAllocList.forEach((element, ind) => {
            element.email_bcc = index?.map((el) => el?.id);
        });
        setLayoutItems([...layoutItems]);
        // setGlobalState({ ...globalState, emailBcc: value?.map((el) => el?.id) });
    };

    const handleEmailBCC = (event) => {
        setGlobalState({ ...globalState, viewEmailBcc: true });
    };

    const checkChange = (e, value) => {
        const { checked } = e.target;
    };

    const handleRoleView = (id) => {
        setRoleView(true);
        dispatch(ViewRolePermission({ roleid: +id }));
    };

    const emailChange = emailMessage?.replace(/<\/?p>/g, "");

    const DuplicateField = main?.map((el) => el);
    const keys = [
        "assign_role",
        "assign_rule",
        "assign_ruleid",
        "assign_roleid",
        "assignedusers",
        "approve_role",
        "approve_rule",
        "approve_ruleid",
        "approve_roleid",
        "approvedusers",
    ];
    const hasDuplicates = (values) => new Set(values?.map((v) => JSON?.stringify(keys?.map((key) => v[key])))).size < values?.length;
    hasDuplicates(DuplicateField);

    const RoundRobbin = mapArr?.map((el) => el?.approve_roundrobbin == false);
    const OpenApprove = allocationList?.length == 1 && RoundRobbin?.length == 1;
    const newAssignRoundRobbinChange = (e) => {
        setassign_roundrobbin(e.target.checked);
    };
    const RoleNewAssignValid = assign_role == true && assign_roleid == 0 && customView;
    const RoleNewApproveValid = approve_role == true && approve_roleid == 0 && customApproval;
    const RuleNewAssignValid = assign_rule == true && assign_ruleid == 0 && customView;
    const RuleNewApproveValid = approve_rule == true && approve_ruleid == 0 && customApproval;
    const ViewData = allocationList?.[0];

    const handleAddInvAllocation = () => {
        const outputreciever = [...new Set(emailcc)];
        const outputBcc = [...new Set(emailbcc)];
        if (AssignRoundRobbinValid?.length > 0) {
            Swal.fire({
                text: "Please customize any field to save.",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else if (AssignRoleValid?.length > 0 || RoleNewAssignValid) {
            Swal.fire({
                text: "Select any role type.",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else if (ApproveRoleValid?.length > 0 || RoleNewApproveValid) {
            Swal.fire({
                text: "Select any role type.",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else if (AssignRuleValid?.length > 0 || RuleNewAssignValid) {
            Swal.fire({
                text: "Select any rule type.",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else if (ApproveRuleValid?.length > 0 || RuleNewApproveValid) {
            Swal.fire({
                text: "Select any rule type.",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else if (hasDuplicates(DuplicateField)) {
            Swal.fire({
                text: "Duplication of rows is not allowed.",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else if (AssignAdduserValid?.length > 0) {
            Swal.fire({
                text: "Please add users to the chosen rule.",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else if (ApproveAdduserValid?.length > 0) {
            Swal.fire({
                text: "Please add users to the chosen rule.",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else if (!RoleAssignMatchresult || !RoleApproveMatchresult) {
            Swal.fire({
                text: "The role you selected doesn't match the user's role.",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else {
            const data = {
                orgid: +orgName,
                json: {
                    headers: [
                        {
                            notificationenable: notificationenable,
                            notificationtitle: Title,
                            notificationmsg: message,
                            emailenabled: Emailenable,
                            emailcc: outputreciever,
                            // emailto: emailTo,
                            emailbcc: outputBcc,
                            email_sub: subject,
                            email_body: emailMessage,
                        },
                    ],
                    items: main,
                },
            };
            dispatch(addInvoiceAllocation(data));
        }
    };
    if (reqComp && datas?.status) {
        setTimeout(() => {
            dispatch({ type: ADD_INVOICE_ALLOCATION_RESET });
            dispatch(getInvoiceAllocationList(+orgName));
        }, 2000);
    }

    const RowRemove = (index) => {
        Swal.fire({
            text: "Are you sure you want to delete the details?",
            icon: "warning",
            closeOnClickOutside: false,
            showCancelButton: true,
            showCloseButton: true,
            buttons: {
                cancel: "Cancel",
                confirm: "Confirm",
            },
        }).then((confirm) => {
            if (confirm?.isConfirmed) {
                removeRow(index);
            }
        });
    };
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const currentUser = getCurrentUser();
    const ShowBusinessIcon = currentUser?.license_type !== 3;
    return (
        <>
            <Layout>
                {reqComp && <SuccessMessageBox success={datas?.message} />}
                {reqComp && !datas?.status && <MessageBox error={datas?.message} />}
                {error !== "" && <MessageBox error={errormsg} />}

                <Main>
                    <div className="mb-5">
                        <div>
                            <h4 className={`text-2xl font-interSb text-${currentMode}-copy`}>Invoice Allocation</h4>
                        </div>
                    </div>
                    <div className="xl:flex xl:space-x-3">
                        <Card
                            className={`basis-[65%] xl:p-[30px] p-4 border-[1px] border-b-[0] border-[rgba(223,225,226,0.56)] xl:h-[calc(100vh_-_17rem)] h-[calc(100vh_-_26rem)] overflow-y-auto xl:overflow-x-hidden rounded-b-[0] ${
                                currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                            }`}>
                            {AllocListData?.fetching ? (
                                <Loader className="h-[calc(100vh_-_17rem)]" />
                            ) : (
                                <>
                                    <div className="flex justify-between mb-3">
                                        <div className="flex items-center space-x-[2rem] mb-5">
                                            <span className="font-interM">Organization</span>
                                            <FormControl fullWidth>
                                                <Select
                                                    name="orgName"
                                                    value={orgName || "0"}
                                                    title={orgList?.find((el) => el?.id == orgName)?.organisation_name}
                                                    onChange={(e) => onChangeOrganisation(e)}
                                                    inputProps={{
                                                        "aria-label": "Without label",
                                                        tabIndex: "1",
                                                    }}
                                                    IconComponent={() => (
                                                        <img
                                                            className="absolute right-[15px] opacity-50 pointer-events-none"
                                                            src={
                                                                currentMode !== "dark"
                                                                    ? `/images/header/chevronDown.svg`
                                                                    : `/images/header/chevronDown-white.svg`
                                                            }
                                                            alt="chevronDown"
                                                        />
                                                    )}
                                                    MenuProps={{
                                                        sx: {
                                                            "& .MuiMenu-paper": {
                                                                backgroundColor: dropdownColor,
                                                                color: dropdownText,
                                                                maxWidth: "24rem !important",
                                                                top: "170px !important",
                                                                maxHeight: "30rem",
                                                            },
                                                            "& .MuiMenuItem-root:hover": {
                                                                backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                            },
                                                            "& .Mui-selected": {
                                                                backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                            },
                                                        },
                                                    }}
                                                    SelectDisplayProps={{
                                                        style: {
                                                            paddingTop: "9px",
                                                            paddingBottom: "9px",
                                                            color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                            minWidth: 200,
                                                            maxWidth: 150,
                                                            paddingRight: "30px",
                                                            border: `1px solid rgba(85,85,85,0.4)`,
                                                            borderRadius: "8px",
                                                        },
                                                    }}>
                                                    <MenuItem key={0} name="OrganisationList" value={0}>
                                                        {"Select Organization"}
                                                    </MenuItem>
                                                    {orgList &&
                                                        orgList?.map((elem, i) => {
                                                            return (
                                                                <MenuItem
                                                                    name="organisation_name"
                                                                    key={i + 1}
                                                                    tabIndex={0}
                                                                    value={elem?.id}
                                                                    title={elem?.organisation_name}
                                                                    className="truncate trunc-1 block">
                                                                    {elem?.organisation_name}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="space-x-2">
                                            {ShowBusinessIcon && (
                                                <Link
                                                    tabIndex={0}
                                                    to={"/invoice-rule"}
                                                    className={`font-interM px-[15px]  py-[8px] border-${currentMode}-840 border-[1px] rounded-[8px] bg-${currentMode}-card`}>
                                                    View Rule
                                                </Link>
                                            )}
                                            <Link
                                                tabIndex={0}
                                                to={"/role-master"}
                                                className={`font-interM px-[15px] bg-${currentMode}-card py-[8px] border-${currentMode}-840 border-[1px] rounded-[8px]`}>
                                                View Role
                                            </Link>
                                        </div>
                                    </div>

                                    <div
                                        className={`bg-${currentMode}-450 mx-[-1rem] xl:mx-[-30px] font-interM flex sticky xl:left-[-30px] right-0 z-[1] xl:top-[-30px] top-[-16px] backdrop-blur-sm accord_head`}>
                                        <div className="basis-1/2 px-4 xl:px-[30px]">Assign</div>
                                        <div className="basis-1/2 px-4 xl:px-[30px]">Approval</div>
                                    </div>
                                    {main?.map((el, i) => {
                                        return (
                                            <div className="flex relative mx-[-30px]" key={i}>
                                                <Button
                                                    tabIndex={0}
                                                    onClick={() => RowRemove(i)}
                                                    className="cursor-pointer absolute top-[20px] xl:right-[20px] right-[27px]">
                                                    <img src="/images/common/trash-ico.svg" alt="trash-ico" className="convertWhite" />
                                                </Button>
                                                <div
                                                    className={`basis-1/2 border-b-[1px] py-[20px] border-r-[1px] border-${currentMode}-600 pl-[30px] pr-4 xl:px-[30px]`}>
                                                    {!el?.onclick ? (
                                                        <Button
                                                            className="text-light-10 text-center py-[80px] block cursor-pointer"
                                                            onClick={() => _handleView(i, "assign", el?.onclick)}
                                                            disabled={orgName == 0}>
                                                            You can customize assign tab or leave it default
                                                        </Button>
                                                    ) : (
                                                        <div className="mb-2">
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        tabIndex={0}
                                                                        size="small"
                                                                        sx={{
                                                                            color: "rgba(85,85,85,0.7)",
                                                                            pointerEvents: "none",
                                                                            cursor: "default",
                                                                            "&.Mui-checked": {
                                                                                color: "#028C78",
                                                                            },
                                                                        }}
                                                                    />
                                                                }
                                                                label="Round Robin"
                                                                checked={el?.assign_roundrobbin}
                                                            />
                                                            {ShowBusinessIcon && (
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            tabIndex={0}
                                                                            sx={{
                                                                                color: "rgba(85,85,85,0.7)",
                                                                                "&.Mui-checked": {
                                                                                    color: "#028C78",
                                                                                },
                                                                            }}
                                                                            size="small"
                                                                        />
                                                                    }
                                                                    label="Rule Based"
                                                                    checked={el?.assign_rule}
                                                                    onChange={(e) => _newAssignRuleChange(i, "assign", e)}
                                                                />
                                                            )}
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        tabIndex={0}
                                                                        sx={{
                                                                            color: "rgba(85,85,85,0.7)",
                                                                            "&.Mui-checked": {
                                                                                color: "#028C78",
                                                                            },
                                                                        }}
                                                                        size="small"
                                                                    />
                                                                }
                                                                label="Role Based"
                                                                checked={el?.assign_role}
                                                                onChange={(e) => _newRoleChange(i, "assign", e)}
                                                            />
                                                            {el?.assign_rule && (
                                                                <div className="flex justify-between mb-4 items-center">
                                                                    <Select
                                                                        value={el?.assign_ruleid}
                                                                        onChange={(e) => _newAssignRuleIDChange(i, "assign", e)}
                                                                        name="Rulelist"
                                                                        inputProps={{
                                                                            "aria-label": "Without label",
                                                                            tabIndex: "1",
                                                                        }}
                                                                        IconComponent={() => (
                                                                            <img
                                                                                className="absolute right-[15px] opacity-50 pointer-events-none"
                                                                                src={
                                                                                    currentMode !== "dark"
                                                                                        ? `/images/header/chevronDown.svg`
                                                                                        : `/images/header/chevronDown-white.svg`
                                                                                }
                                                                                alt="chevronDown"
                                                                            />
                                                                        )}
                                                                        MenuProps={{
                                                                            sx: {
                                                                                "& .MuiMenu-paper": {
                                                                                    backgroundColor: dropdownColor,
                                                                                    color: dropdownText,
                                                                                    maxWidth: 154,
                                                                                },
                                                                                "& .MuiMenuItem-root:hover": {
                                                                                    backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                                                },
                                                                                "& .Mui-selected": {
                                                                                    backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                                                },
                                                                            },
                                                                        }}
                                                                        SelectDisplayProps={{
                                                                            style: {
                                                                                paddingTop: "9px",
                                                                                maxWidth: 154,
                                                                                minWidth: 154,
                                                                                paddingBottom: "9px",
                                                                                color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,

                                                                                border: "1px solid rgba(85, 85, 85, 0.4)",

                                                                                paddingRight: "30px",

                                                                                borderRadius: "8px",
                                                                            },
                                                                        }}>
                                                                        <MenuItem key={0} name="RuleList" value={0}>
                                                                            {"Select Rule"}
                                                                        </MenuItem>

                                                                        {RuleListData &&
                                                                            RuleListData?.map((el, i) => (
                                                                                <MenuItem
                                                                                    name="RuleList"
                                                                                    value={el?.id}
                                                                                    title={el?.rule_name}
                                                                                    className="truncate trunc-1 block">
                                                                                    {el?.rule_name}
                                                                                </MenuItem>
                                                                            ))}
                                                                    </Select>
                                                                    {el?.assign_ruleid !== 0 && (
                                                                        <>
                                                                            <Button
                                                                                tabIndex={0}
                                                                                className="flex space-x-2 items-center cursor-pointer"
                                                                                onClick={() => _handleUserAdd(i, "assign", true, el?.assignedusers)}>
                                                                                <div title="Add User">
                                                                                    <img
                                                                                        src="/images/main/plus-circle.svg"
                                                                                        alt="plus-circle"
                                                                                        className="convertWhite"
                                                                                    />
                                                                                </div>
                                                                                <span>
                                                                                    {el?.assignedusers?.length == undefined ||
                                                                                    el?.assignedusers?.length == 0 ||
                                                                                    el?.assignedusers == [0]
                                                                                        ? "Add User"
                                                                                        : el?.assignedusers?.length + " Users"}
                                                                                </span>
                                                                            </Button>
                                                                            <Button
                                                                                tabIndex={0}
                                                                                title="To View Rule"
                                                                                className="cursor-pointer"
                                                                                onClick={(e) => _handleViewRuleIcon(el?.assign_ruleid)}>
                                                                                <img
                                                                                    src="/images/common/eyeOpen.svg"
                                                                                    alt="eye-blk"
                                                                                    className="convertWhite"
                                                                                />
                                                                            </Button>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            )}
                                                            {el?.assign_role && (
                                                                <div className="flex justify-between mb-4 items-center">
                                                                    <Select
                                                                        value={el?.assign_roleid}
                                                                        onChange={(e) => _newRoleIdChange(i, "assign", e)}
                                                                        name="Rolelist"
                                                                        inputProps={{
                                                                            "aria-label": "Without label",
                                                                            tabIndex: "1",
                                                                        }}
                                                                        IconComponent={() => (
                                                                            <img
                                                                                className="absolute right-[15px] opacity-50 pointer-events-none"
                                                                                src={
                                                                                    currentMode !== "dark"
                                                                                        ? `/images/header/chevronDown.svg`
                                                                                        : `/images/header/chevronDown-white.svg`
                                                                                }
                                                                                alt="chevronDown"
                                                                            />
                                                                        )}
                                                                        MenuProps={{
                                                                            sx: {
                                                                                "& .MuiMenu-paper": {
                                                                                    backgroundColor: dropdownColor,
                                                                                    color: dropdownText,
                                                                                    maxWidth: 154,
                                                                                },
                                                                                "& .MuiMenuItem-root:hover": {
                                                                                    backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                                                },
                                                                                "& .Mui-selected": {
                                                                                    backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                                                },
                                                                            },
                                                                        }}
                                                                        SelectDisplayProps={{
                                                                            style: {
                                                                                paddingTop: "9px",
                                                                                paddingBottom: "9px",
                                                                                maxWidth: 154,
                                                                                minWidth: 154,
                                                                                color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                                                paddingRight: "30px",
                                                                                border: "1px solid rgba(85, 85, 85, 0.4)",
                                                                                borderRadius: "8px",
                                                                            },
                                                                        }}>
                                                                        <MenuItem key={0} name="RoleList" value={0}>
                                                                            {"Select Role"}
                                                                        </MenuItem>

                                                                        {RoleList &&
                                                                            RoleList?.map((el, i) => (
                                                                                <MenuItem
                                                                                    name="RoleList"
                                                                                    title={el?.name}
                                                                                    key={i + 1}
                                                                                    value={el?.role_id}
                                                                                    className={`truncate trunc-1 block ${
                                                                                        el?.processed == false || el?.exception == false
                                                                                            ? "hidden"
                                                                                            : ""
                                                                                    }`}>
                                                                                    {el?.name}
                                                                                </MenuItem>
                                                                            ))}
                                                                    </Select>
                                                                    {el?.assign_roleid !== 0 && (
                                                                        <Button
                                                                            tabIndex={0}
                                                                            title="To View Role"
                                                                            className="cursor-pointer"
                                                                            onClick={(e) => handleRoleView(el?.assign_roleid)}>
                                                                            <img
                                                                                src="/images/common/eyeOpen.svg"
                                                                                alt="eye-blk"
                                                                                className="convertWhite"
                                                                            />
                                                                        </Button>
                                                                    )}
                                                                </div>
                                                            )}
                                                            {ReactDOM.createPortal(
                                                                <AllocationUserModal
                                                                    tabIndex={0}
                                                                    isOpened={el?.assign_open}
                                                                    listData={listData?.filter((item) => item?.processed == true)}
                                                                    handleSelectUser={(e, value, reason) =>
                                                                        _handleSelectUser(i, "assign", value, reason)
                                                                    }
                                                                    defaultId={userId}
                                                                    handleClose={() => _handleUserClose(i, "assign", false)}
                                                                    className={el?.assign_open && "Show"}
                                                                />,
                                                                document.getElementById("user-modal-root")
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                                <div
                                                    className={`basis-1/2 border-b-[1px] py-[20px] border-r-[1px] border-${currentMode}-600 px-4 xl:px-[30px]`}>
                                                    {!el?.onclick2 ? (
                                                        <Button
                                                            tabIndex={0}
                                                            className="text-light-10 text-center py-[80px] block cursor-pointer"
                                                            onClick={() => _handleView(i, "approve", el?.onclick2)}
                                                            disabled={orgName == 0}>
                                                            You can customize your approval tab or leave it default
                                                        </Button>
                                                    ) : (
                                                        <div className="mb-2">
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        tabIndex={0}
                                                                        size="small"
                                                                        sx={{
                                                                            color: "rgba(85,85,85,0.7)",
                                                                            pointerEvents: "none",
                                                                            cursor: "default",

                                                                            "&.Mui-checked": {
                                                                                color: "#028C78",
                                                                            },
                                                                        }}
                                                                    />
                                                                }
                                                                label="Round Robin"
                                                                checked={el?.approve_roundrobbin}
                                                                onChange={(e) => newAssignRoundRobbinChange(e)}
                                                            />
                                                            {ShowBusinessIcon && (
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            tabIndex={0}
                                                                            sx={{
                                                                                color: "rgba(85,85,85,0.7)",
                                                                                "&.Mui-checked": {
                                                                                    color: "#028C78",
                                                                                },
                                                                            }}
                                                                            size="small"
                                                                        />
                                                                    }
                                                                    label="Rule Based"
                                                                    checked={el?.approve_rule}
                                                                    onChange={(e) => _newAssignRuleChange(i, "approve", e)}
                                                                />
                                                            )}
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        tabIndex={0}
                                                                        sx={{
                                                                            color: "rgba(85,85,85,0.7)",
                                                                            "&.Mui-checked": {
                                                                                color: "#028C78",
                                                                            },
                                                                        }}
                                                                        size="small"
                                                                    />
                                                                }
                                                                label="Role Based"
                                                                checked={el?.approve_role}
                                                                onChange={(e) => _newRoleChange(i, "approve", e)}
                                                            />
                                                            {el?.approve_rule && (
                                                                <div className="flex justify-between mb-4 items-center">
                                                                    <Select
                                                                        value={el?.approve_ruleid}
                                                                        onChange={(e) => _newAssignRuleIDChange(i, "approve", e)}
                                                                        name="Rulelist"
                                                                        inputProps={{
                                                                            "aria-label": "Without label",
                                                                            tabIndex: "1",
                                                                        }}
                                                                        IconComponent={() => (
                                                                            <img
                                                                                className="absolute right-[15px] opacity-50 pointer-events-none"
                                                                                src={
                                                                                    currentMode !== "dark"
                                                                                        ? `/images/header/chevronDown.svg`
                                                                                        : `/images/header/chevronDown-white.svg`
                                                                                }
                                                                                alt="chevronDown"
                                                                            />
                                                                        )}
                                                                        MenuProps={{
                                                                            sx: {
                                                                                "& .MuiMenu-paper": {
                                                                                    backgroundColor: dropdownColor,
                                                                                    color: dropdownText,
                                                                                    maxWidth: 154,
                                                                                },
                                                                                "& .MuiMenuItem-root:hover": {
                                                                                    backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                                                },
                                                                                "& .Mui-selected": {
                                                                                    backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                                                },
                                                                            },
                                                                        }}
                                                                        SelectDisplayProps={{
                                                                            style: {
                                                                                paddingTop: "9px",
                                                                                paddingBottom: "9px",
                                                                                maxWidth: 154,
                                                                                minWidth: 154,
                                                                                color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                                                paddingRight: "30px",
                                                                                border: "1px solid rgba(85, 85, 85, 0.3)",
                                                                                borderRadius: "8px",
                                                                            },
                                                                        }}>
                                                                        <MenuItem key={0} name="RuleList" value={0}>
                                                                            {"Select Rule"}
                                                                        </MenuItem>

                                                                        {RuleListData &&
                                                                            RuleListData?.map((el, i) => (
                                                                                <MenuItem
                                                                                    name="RuleList"
                                                                                    title={el?.rule_name}
                                                                                    value={el?.id}
                                                                                    className="truncate trunc-1 block">
                                                                                    {el?.rule_name}
                                                                                </MenuItem>
                                                                            ))}
                                                                    </Select>
                                                                    {el?.approve_ruleid !== 0 && (
                                                                        <>
                                                                            <Button
                                                                                tabIndex={0}
                                                                                className="flex space-x-2 items-center cursor-pointer"
                                                                                onClick={() => _handleUserAdd(i, "approve", true, el?.approvedusers)}>
                                                                                <div title="Add User">
                                                                                    <img
                                                                                        src="/images/main/plus-circle.svg"
                                                                                        alt="plus-circle"
                                                                                        className="convertWhite"
                                                                                    />
                                                                                </div>
                                                                                <span>
                                                                                    {el?.approvedusers?.length == undefined ||
                                                                                    el?.approvedusers?.length == 0 ||
                                                                                    el?.approvedusers == [0]
                                                                                        ? "Add Users"
                                                                                        : el?.approvedusers?.length + " Users"}
                                                                                </span>
                                                                            </Button>
                                                                            <Button
                                                                                tabIndex={0}
                                                                                title="To View Rule"
                                                                                className="cursor-pointer"
                                                                                onClick={(e) => _handleViewRuleIcon(el?.approve_ruleid)}>
                                                                                <img
                                                                                    src="/images/common/eyeOpen.svg"
                                                                                    alt="eye-blk"
                                                                                    className="convertWhite"
                                                                                />
                                                                            </Button>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            )}
                                                            {el?.approve_role && (
                                                                <div className="flex justify-between mb-4 items-center">
                                                                    <Select
                                                                        value={el?.approve_roleid}
                                                                        onChange={(e) => _newRoleIdChange(i, "approve", e)}
                                                                        name="Rolelist"
                                                                        inputProps={{
                                                                            "aria-label": "Without label",
                                                                            tabIndex: "1",
                                                                        }}
                                                                        IconComponent={() => (
                                                                            <img
                                                                                className="absolute right-[15px] opacity-50 pointer-events-none"
                                                                                src={
                                                                                    currentMode !== "dark"
                                                                                        ? `/images/header/chevronDown.svg`
                                                                                        : `/images/header/chevronDown-white.svg`
                                                                                }
                                                                                alt="chevronDown"
                                                                            />
                                                                        )}
                                                                        MenuProps={{
                                                                            sx: {
                                                                                "& .MuiMenu-paper": {
                                                                                    backgroundColor: dropdownColor,
                                                                                    color: dropdownText,
                                                                                    maxWidth: 154,
                                                                                },
                                                                                "& .MuiMenuItem-root:hover": {
                                                                                    backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                                                },
                                                                                "& .Mui-selected": {
                                                                                    backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                                                },
                                                                            },
                                                                        }}
                                                                        SelectDisplayProps={{
                                                                            style: {
                                                                                paddingTop: "9px",
                                                                                paddingBottom: "9px",
                                                                                maxWidth: 154,
                                                                                minWidth: 154,
                                                                                color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                                                paddingRight: "30px",
                                                                                border: "1px solid rgba(85, 85, 85, 0.3)",
                                                                                borderRadius: "8px",
                                                                            },
                                                                        }}>
                                                                        <MenuItem key={0} name="RoleList" value={0}>
                                                                            {"Select Role"}
                                                                        </MenuItem>

                                                                        {RoleList &&
                                                                            RoleList?.map((el, i) => (
                                                                                <MenuItem
                                                                                    name="RoleList"
                                                                                    title={el?.name}
                                                                                    key={i + 1}
                                                                                    value={el?.role_id}
                                                                                    className={`truncate trunc-1 block ${
                                                                                        el?.approval == false ? "hidden" : ""
                                                                                    }`}>
                                                                                    {el?.name}
                                                                                </MenuItem>
                                                                            ))}
                                                                    </Select>
                                                                    {el?.approve_roleid !== 0 && (
                                                                        <Button
                                                                            tabIndex={0}
                                                                            title="To View Role"
                                                                            className="cursor-pointer"
                                                                            onClick={(e) => handleRoleView(el?.approve_roleid)}>
                                                                            <img
                                                                                src="/images/common/eyeOpen.svg"
                                                                                alt="eye-blk"
                                                                                className="convertWhite"
                                                                            />
                                                                        </Button>
                                                                    )}
                                                                </div>
                                                            )}
                                                            {ReactDOM.createPortal(
                                                                <AllocationUserModal
                                                                    tabIndex={0}
                                                                    isOpened={el?.approve_open}
                                                                    listData={listData?.filter((item) => item?.approved == true)}
                                                                    handleSelectUser={(e, value, reason) =>
                                                                        _handleSelectUser(i, "approve", value, reason)
                                                                    }
                                                                    defaultId={userId}
                                                                    handleClose={() => _handleUserClose(i, "approve", false)}
                                                                    className={el?.approve_open && "Show"}
                                                                />,
                                                                document.getElementById("user-modal-root")
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}

                                    <Button tabIndex={0} className="mt-4 xl:mb-0 mb-10" onClick={addRow} disabled={orgName == 0}>
                                        <span className="cursor-pointer text-light-10">+ Add New</span>
                                    </Button>
                                </>
                            )}
                        </Card>
                        <>
                            {/* const [notificationenable, setnotificationEnable] = useState();
              const [Title, setTitle] = useState();
              const [message, setMessage] = useState();
              const [Emailenable, setemailEnable] = useState();
              const [subject, setSubject] = useState();
              const [emailMessage, setEmailmessage] = useState(); */}
                            <NotificationAllocationModal
                                // orgName={orgName}
                                // orgSelect={globalState?.orgSelect}
                                isOrg={isOrg}
                                receivers={receivers}
                                setReceivers={setReceivers}
                                bccreceivers={bccreceivers}
                                setBccRecievers={setBccRecievers}
                                email_enabled={Emailenable}
                                viewEmailBcc={globalState?.viewEmailBcc}
                                notification_enabled={notificationenable}
                                checkChange={checkChange}
                                notification_title={Title}
                                handleEmailSubject={setSubject}
                                handleEmailMessage={setEmailmessage}
                                email_sub={subject}
                                listData={listData}
                                notification_msg={message}
                                email_body={emailMessage}
                                emailCheckChange={setemailEnable}
                                handleNotificationTitle={setTitle}
                                handleSelectEmailBcc={handleSelectEmailBcc}
                                handleNotificationMessage={setMessage}
                                handleSelectEmailCC={handleSelectEmailCC}
                                handleEmailBCC={handleEmailBCC}
                                invAllocList={invAllocList}
                                notificationListData={notificationListData}
                                handleNotificationCheck={setnotificationEnable}
                                AllocListData={AllocListData}
                                reset={reset}
                            />
                        </>
                    </div>
                    <div
                        className={`bg-${currentMode}-card text-${currentMode}-copy mb-5 border-${currentMode}-700 py-[20px] space-x-2 px-[30px] sticky left-0 right-0 shadow-[0px_4px_20px_rgba(85,85,85,0.2)] border-[1px] border-[#dfe1e28f] rounded-b-[10px]`}>
                        <Button
                            tabIndex={0}
                            className="bg-light-20 text-white text-base rounded-[8px] py-2 px-7"
                            onClick={handleAddInvAllocation}
                            title={"Save"}
                            disabled={orgName == 0 || fetching}>
                            {"Save"}
                        </Button>
                        <Link
                            tabIndex={0}
                            to="/admin-setting"
                            title="Cancel"
                            className="bg-light-240 text-light-40 text-base rounded-[8px] py-2 px-7">
                            Cancel
                        </Link>
                    </div>
                </Main>
                <Footer />
            </Layout>
            {ReactDOM.createPortal(
                <UserDetailsModal
                    isOpened={userView}
                    ruleDataList={ruleDataList}
                    ruleList={ruleList}
                    DataValue={DataValue}
                    DataItems={DataItems}
                    handleClose={() => setUserView(false)}
                    className={userView && "Show"}
                />,
                document.getElementById("user-modal-root")
            )}
            {ReactDOM.createPortal(
                <RoleDetails
                    isOpened={roleView}
                    ruleDataList={ruleDataList}
                    DataValue={DataValue}
                    DataItems={DataItems}
                    handleClose={() => setRoleView(false)}
                    className={roleView && "Show"}
                />,
                document.getElementById("user-modal-root")
            )}
        </>
    );
};

export default InvoiceAllocation;
