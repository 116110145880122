import { Autocomplete, Checkbox, Chip, TextField, ClickAwayListener, ListItemText, Accordion, AccordionDetails, Paper } from "@mui/material";
import ReactQuill from "react-quill";
import { useState, useEffect, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { convertNewlinesToHtml, decryptId } from "../../../utils/helpers";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import Card from "../../../components/UI/Card";
import { getEmailTemplatePlaceHolderList } from "../../../redux/actions/emailtask";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import { ExceptionEmailGeneration } from "../../../redux/actions/exception-automation";
import TogglePopup from "../../organisations/Toggle";
import { EMAIL_GENERATE_EXCEPTION_RESET } from "../../../redux/types/types";
import Loader from "../../../components/Loader";
import styled from "styled-components";
const AcknowledgementCard = (props) => {
    // const { selectedId, setSelectedId, check, setCheck } = props;
    const ViewArray = props?.selectedId?.ack_email_json;
    const setCategoryArr = props?.setCategoryArr;
    const UsersOptions = props?.UsersOptions;
    const selectedId = 0;
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [check, setCheck] = useState([
        {
            ack_email_json: {
                to: [],
                cc: [],
                bcc: [],
                Sub: "",
                body: "",
            },
        },
    ]);

    const { currentMode } = useContext(ThemeContext);
    const [notificationVal, setNotificationVal] = useState();
    const [expandAcknow, setExpandAcknow] = useState("0");
    const setPropsExpand = props?.setExpandAck(expandAcknow);
    const CustomQuill = styled(ReactQuill)`
    .ql-toolbar.ql-snow {
        background-color: transparent !important;
    }
`;
    const handleChangeAcknow = (panel) => (event, newExpanded) => {
        setExpandAcknow(newExpanded ? panel : false);
        if (panel == "1") {
            // const selVal = document.getElementById("ViewCard");
            // if (selVal) {
            //     selVal.click();
            //     selVal.scrollIntoView({ behavior: "smooth", block: "top" });
            // }
            setTimeout(() => {
                var element = document.getElementById("ViewCard");
                element.scrollTop = element.scrollHeight + 100;
            }, 100);
        }
    };
    const AccordionSummary = props?.AccordionSummary;
    useEffect(() => {
        if (selectedId !== null) {
            check?.find((ele, i) => {
                if (i == selectedId) {
                    setNotificationVal(ele?.ack_email_json);
                    setViewEmailBcc(ele?.ack_email_json?.bcc?.length > 0 ? true : false);
                }
            });
        }
    }, [selectedId]);
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const dispatch = useDispatch();
    const [notificationenable, setnotificationEnable] = useState(notificationVal?.push_flag);
    const [error1, setError] = useState(false);
    const [message, setMessage] = useState(notificationVal?.push_message);
    const [Emailenable, setemailEnable] = useState(notificationVal?.email_flag);
    const [subject, setSubject] = useState("");
    const childCompRef = useRef();
    const [emailMessage, setEmailmessage] = useState(ViewArray?.body);
    const CC = ViewArray?.cc;
    const BCC = ViewArray?.bcc;
    const TO = ViewArray?.to;
    const [offset, setOffset] = useState(null);
    //  BCC?.map((i) => decryptId(i));
    const [emailTo, setEmailTo] = useState(TO);
    const [emailCC, setEmailCC] = useState(CC);
    const [emailBcc, setEmailbcc] = useState(BCC);
    const [ToInputvalue, setToInputValue] = useState("");
    const [inputValue, setInputValue] = useState("");
    const [inputValue2, setInputValue2] = useState("");
    const filterMenuList = [
        {
            text: "Make Shorter",
            value: 1,
            id: 1,
        },
        {
            text: "Make Longer",
            value: 2,
            id: 2,
        },
    ];
    const FindSelectName = props?.selectedId?.exception_name;
    const EmailGenerate = useSelector((state) => state?.EmailGenerateExceptionState);
    const EmailGenerateData = EmailGenerate?.data?.email?.mail_body;
    const EmailGenearteSub = EmailGenerate?.data?.email?.subject;
    const onFilterItems = (value) => {
        setIsFilter(true);
        switch (value) {
            case 1:
                dispatch(
                    ExceptionEmailGeneration({
                        exception: FindSelectName,
                        email_size: "small",
                        task: "1"
                    })
                );
                break;
            case 2:
                dispatch(
                    ExceptionEmailGeneration({
                        exception: FindSelectName,
                        email_size: "large",
                        task: "1"
                    })
                );
                break;
            default:
                break;
        }
    };
    const ModifyEmail = () => {
        setIsFilter(true);
        dispatch(
            ExceptionEmailGeneration({
                exception: FindSelectName,
                email_size: "small",
            })
        );
    };
    useEffect(() => {
        if (selectedId !== null) {
            check.find((ele, i) => {
                if (i == selectedId) {
                    setNotificationVal(ele?.ack_email_json);
                }
            });
        }
    }, [selectedId]);
    useEffect(() => {
        setEmailCC(ViewArray?.cc);
        setEmailTo(ViewArray?.to);
        setEmailbcc(ViewArray?.bcc);
        setSubject(ViewArray?.Sub == null ? "" : ViewArray?.Sub);
        setEmailmessage(ViewArray?.body);
        if (placeHolData?.length == 0 || !placeHolData) {
            dispatch(getEmailTemplatePlaceHolderList());
        }
        if (!props?.isView) {
            setExpandAcknow("0");
        }
    }, [ViewArray, dispatch, props?.isView]);

    const orgName = props?.orgName;
    const [viewEmailBcc, setViewEmailBcc] = useState(false);
    const messageRef = useRef(null);
    const ReactQuillRef = useRef(null);
    const modules = {
        toolbar: [["bold", "italic", "underline", "blockquote"], [{ list: "ordered" }, { list: "bullet" }], ["clean"]],
    };
    const formats = [
        // "header",
        "bold",
        "italic",
        "underline",
        // "strike",
        "blockquote",
        "list",
        "bullet",
        // "indent",
        "link",
        "image",
    ];
    const { data: placeHolData } = useSelector((state) => state?.emailTemplatePlaceholderListState);
    const invoicePlaceHolderList = placeHolData?.filter((el) => el?.placeholder_group_name == "Invoice");
    const [showing, setShowing] = useState(null);
    const [showing2, setShowing2] = useState(null);
    const [focused, setFocused] = useState("");

    const handleClick = (event) => {
        if (orgName !== 0 && notificationenable == true) {
            setShowing(true);
        }
    };
    const ReacthandleClick = (event) => {
        setShowing2(true);
    };
    const handleClickAway = (e) => {
        setShowing(false);
        setShowing2(false);
    };

    const [isFilter, setIsFilter] = useState(false);

    const handleEmailSubject = (e) => {
        setSubject(e.target.value);
        setNotificationVal((prevVal) => ({
            ...prevVal,
            Sub: e.target.value,
        }));

        props?.setSelectedId((prevVal) => ({
            ...prevVal,
            ack_email_json: {
                ...prevVal?.ack_email_json,
                Sub: e.target.value,
            },
        }));
        setCategoryArr((prevCheck) => {
            return prevCheck.map((ele) => {
                // Check and update the parent object
                if (props?.statustype == 1 && ele?.outside_id === props?.currentId) {
                    return {
                        ...ele,
                        ack_email_json: {
                            ...ele.ack_email_json,
                            Sub: e.target.value,
                        },
                    };
                }

                // Check and update the child exceptions
                if (ele?.child_exception) {
                    const updatedChildExceptions = ele?.child_exception?.map((child) => {
                        if (props?.statustype == 2 && child?.inside_id === props?.currentId) {
                            return {
                                ...child,
                                ack_email_json: {
                                    ...child.ack_email_json,
                                    Sub: e.target.value,
                                },
                            };
                        }
                        return child;
                    });

                    return {
                        ...ele,
                        child_exception: updatedChildExceptions,
                    };
                }

                return ele;
            });
        });
    };
    const handleMessageClick = (name) => {
        const percent = "%" + name + "%";
        const replacemessage = emailMessage?.replace(null, "");
        const NewMessage = (replacemessage == undefined ? "" : replacemessage) + percent;
        setEmailmessage(NewMessage);
        // ReactQuillRef?.current?.editor?.setData(NewMessage);
    };
    const ReactFocus = () => {
        setFocused("React");
    };
    // Example of a drop event handler
    const handleDrop = (event) => {
        event.preventDefault();
        const data = "%" + event.dataTransfer.getData("text/plain") + "%";
        const inputElement = event.target;
        const currentValue = inputElement.value;
        // Append the dropped data to the existing value, separated by a space
        const newValue = currentValue ? `${currentValue} ${data}` : data;
        // Update the state with the new value
        setMessage(newValue);
        setNotificationVal((prevVal) => ({
            ...prevVal,
            push_message: newValue,
        }));
        setCheck((prevCheck) => {
            // Assuming selectedId is the index where you want to update the user_set property
            const updatedCheck = prevCheck.map((ele, i) => {
                if (i === selectedId) {
                    return {
                        ...ele,
                        ack_email_json: {
                            ...ele?.ack_email_json,
                            push_message: newValue,
                        },
                    };
                }
                return ele;
            });
            return updatedCheck;
        });
    };
    const handleEmailTo = (newVal) => {
        setEmailTo(newVal);
        setNotificationVal((prevVal) => ({
            ...prevVal,
            to: newVal,
        }));
        props?.setSelectedId((prevVal) => ({
            ...prevVal,
            ack_email_json: {
                ...prevVal?.ack_email_json,
                to: newVal,
            },
        }));
        setCategoryArr((prevCheck) => {
            return prevCheck.map((ele) => {
                // Check and update the parent object
                if (props?.statustype == 1 && ele?.outside_id === props?.currentId) {
                    return {
                        ...ele,
                        ack_email_json: {
                            ...ele.ack_email_json,
                            to: newVal,
                        },
                    };
                }

                // Check and update the child exceptions
                if (ele?.child_exception) {
                    const updatedChildExceptions = ele?.child_exception?.map((child) => {
                        if (props?.statustype == 2 && child?.inside_id === props?.currentId) {
                            return {
                                ...child,
                                ack_email_json: {
                                    ...child.ack_email_json,
                                    to: newVal,
                                },
                            };
                        }
                        return child;
                    });

                    return {
                        ...ele,
                        child_exception: updatedChildExceptions,
                    };
                }

                return ele;
            });
        });
    };
    const handleEmailCC = (newVal) => {
        setEmailCC(newVal);
        setNotificationVal((prevVal) => ({
            ...prevVal,
            cc: newVal,
        }));
        props?.setSelectedId((prevVal) => ({
            ...prevVal,
            ack_email_json: {
                ...prevVal?.ack_email_json,
                cc: newVal,
            },
        }));
        setCategoryArr((prevCheck) => {
            return prevCheck.map((ele) => {
                // Check and update the parent object
                if (props?.statustype == 1 && ele?.outside_id === props?.currentId) {
                    return {
                        ...ele,
                        ack_email_json: {
                            ...ele.ack_email_json,
                            cc: newVal,
                        },
                    };
                }

                // Check and update the child exceptions
                if (ele?.child_exception) {
                    const updatedChildExceptions = ele?.child_exception?.map((child) => {
                        if (props?.statustype == 2 && child?.inside_id === props?.currentId) {
                            return {
                                ...child,
                                ack_email_json: {
                                    ...child.ack_email_json,
                                    cc: newVal,
                                },
                            };
                        }
                        return child;
                    });

                    return {
                        ...ele,
                        child_exception: updatedChildExceptions,
                    };
                }

                return ele;
            });
        });
    };
    const handleEmailBCC = (newVal) => {
        setEmailbcc(newVal);
        setNotificationVal((prevVal) => ({
            ...prevVal,
            bcc: newVal,
        }));
        props?.setSelectedId((prevVal) => ({
            ...prevVal,
            ack_email_json: {
                ...prevVal?.ack_email_json,
                bcc: newVal,
            },
        }));
        setCategoryArr((prevCheck) => {
            return prevCheck.map((ele) => {
                // Check and update the parent object
                if (props?.statustype == 1 && ele?.outside_id === props?.currentId) {
                    return {
                        ...ele,
                        ack_email_json: {
                            ...ele.ack_email_json,
                            bcc: newVal,
                        },
                    };
                }

                // Check and update the child exceptions
                if (ele?.child_exception) {
                    const updatedChildExceptions = ele?.child_exception?.map((child) => {
                        if (props?.statustype == 2 && child?.inside_id === props?.currentId) {
                            return {
                                ...child,
                                ack_email_json: {
                                    ...child.ack_email_json,
                                    bcc: newVal,
                                },
                            };
                        }
                        return child;
                    });

                    return {
                        ...ele,
                        child_exception: updatedChildExceptions,
                    };
                }

                return ele;
            });
        });
    };
    const handleOpenBcc = () => {
        setViewEmailBcc(true);
    };
    const handleEmailBody = (e) => {
        setEmailmessage(e);
        setNotificationVal((prevVal) => ({
            ...prevVal,
            body: e,
        }));
        props?.setSelectedId((prevVal) => ({
            ...prevVal,
            ack_email_json: {
                ...prevVal?.ack_email_json,
                body: e,
            },
        }));
        setCategoryArr((prevCheck) => {
            return prevCheck.map((ele) => {
                // Check and update the parent object
                if (props?.statustype == 1 && ele?.outside_id === props?.currentId) {
                    return {
                        ...ele,
                        ack_email_json: {
                            ...ele.ack_email_json,
                            body: e,
                        },
                    };
                }

                // Check and update the child exceptions
                if (ele?.child_exception) {
                    const updatedChildExceptions = ele?.child_exception?.map((child) => {
                        if (props?.statustype == 2 && child?.inside_id === props?.currentId) {
                            return {
                                ...child,
                                ack_email_json: {
                                    ...child.ack_email_json,
                                    body: e,
                                },
                            };
                        }
                        return child;
                    });

                    return {
                        ...ele,
                        child_exception: updatedChildExceptions,
                    };
                }

                return ele;
            });
        });
    };

    const handleBlur = () => {
        const editor = ReactQuillRef.current.getEditor();

        const selection = editor.getSelection();
        const cursorPosition = selection ? selection.index : -1; // -1 if no selection
        // Get the container text
        const containerText = editor.getText(cursorPosition);
        setOffset(cursorPosition);
    };

    const handleEmailDrop = (e) => {
        e.preventDefault();
        const data = "%" + e.dataTransfer.getData("text/plain") + "%";
        // Access the Quill instance using the quillRef
        const quill = ReactQuillRef.current.getEditor();
        const currentContent = quill.getText();
        const selection = quill.getSelection();
        const insertIndex = selection ? selection.index : currentContent.length;
        // Append the dropped data to the existing content
        const updatedContent = currentContent.slice(0, insertIndex) + data + currentContent.slice(insertIndex);
        quill.setText(updatedContent); // Update the content in Quill
        // Set the editorHtml state with the updated content
        setEmailmessage(updatedContent);
        setNotificationVal((prevVal) => ({
            ...prevVal,
            body: updatedContent,
        }));
        props?.setSelectedId((prevVal) => ({
            ...prevVal,
            ack_email_json: {
                ...prevVal?.ack_email_json,
                body: updatedContent,
            },
        }));
        setCategoryArr((prevCheck) => {
            return prevCheck.map((ele) => {
                // Check and update the parent object
                if (props?.statustype == 1 && ele?.outside_id === props?.currentId) {
                    return {
                        ...ele,
                        ack_email_json: {
                            ...ele.ack_email_json,
                            body: updatedContent,
                        },
                    };
                }

                // Check and update the child exceptions
                if (ele?.child_exception) {
                    const updatedChildExceptions = ele?.child_exception?.map((child) => {
                        if (props?.statustype == 2 && child?.inside_id === props?.currentId) {
                            return {
                                ...child,
                                ack_email_json: {
                                    ...child.ack_email_json,
                                    body: updatedContent,
                                },
                            };
                        }
                        return child;
                    });

                    return {
                        ...ele,
                        child_exception: updatedChildExceptions,
                    };
                }

                return ele;
            });
        });
    };
    const typeofEmail = typeof emailTo == "string";
    useEffect(() => {
        if (EmailGenerate?.requestComplete && EmailGenerateData && isFilter) {
            setEmailmessage(EmailGenerateData);
            setNotificationVal((prevVal) => ({
                ...prevVal,
                body: EmailGenerateData,
                Sub: EmailGenearteSub,
            }));
            props?.setSelectedId((prevVal) => ({
                ...prevVal,
                ack_email_json: {
                    ...prevVal?.ack_email_json,
                    body: EmailGenerateData,
                    Sub: EmailGenearteSub,
                },
            }));
            setCategoryArr((prevCheck) => {
                return prevCheck.map((ele) => {
                    // Check and update the parent object
                    if (props?.statustype == 1 && ele?.outside_id === props?.currentId) {
                        return {
                            ...ele,
                            ack_email_json: {
                                ...ele.ack_email_json,
                                body: EmailGenerateData,
                                Sub: EmailGenearteSub,
                            },
                        };
                    }

                    // Check and update the child exceptions
                    if (ele?.child_exception) {
                        const updatedChildExceptions = ele?.child_exception?.map((child) => {
                            if (props?.statustype == 2 && child?.inside_id === props?.currentId) {
                                return {
                                    ...child,
                                    ack_email_json: {
                                        ...child.ack_email_json,
                                        body: EmailGenerateData,
                                        Sub: EmailGenearteSub,
                                    },
                                };
                            }
                            return child;
                        });

                        return {
                            ...ele,
                            child_exception: updatedChildExceptions,
                        };
                    }

                    return ele;
                });
            });
            setTimeout(() => {
                dispatch({ type: EMAIL_GENERATE_EXCEPTION_RESET });
                setIsFilter(false);
            }, 3000);
        }
    }, [EmailGenerate?.requestComplete]);
    const formattedMessage = emailMessage ? convertNewlinesToHtml(emailMessage) : '';
    useEffect(() => {
        // Create a style element
        const style = document.createElement('style');
        style.innerHTML = `
            .custom-quill-toolbar .ql-toolbar.ql-snow {
                background-color: transparent !important;
            }
        `;
        // Append the style to the head of the document
        document.head.appendChild(style);

        // Clean up the style element when component unmounts
        return () => {
            document.head.removeChild(style);
        };
    }, []);
    return (
        <>
            {/* <Card
                tabIndex={0}
                className={`${orgName !== 0 ? `` : `opacity-75`
                    }  basis-[35%] p-4 xl:p-[20px] mt-3 border-[1px] border-t-[0] border-b-[0] border-${currentMode}-600 h-[calc(100vh_-_40rem)] xl:h-[calc(100vh_-_17rem)] overflow-y-auto rounded-t-[8px] rounded-b-[0] ${currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                    }`}
            > */}

            <div className={`${!props?.isView ? "cursor-not-allowed  " : ""} px-[20px] `}>
                <Accordion
                    sx={{
                        "&.MuiPaper-root": {
                            boxShadow: "none", // Your desired border color
                            border: `1px solid ${currentMode == "dark" ? "#333" : "rgba(223, 225, 226, 0.56)"} !important`,
                        },
                    }}
                    disabled={!props?.isView}
                    expanded={expandAcknow === "1"}
                    onChange={handleChangeAcknow("1")}
                >
                    <AccordionSummary
                        sx={{
                            "&.Mui-expanded": {
                                minHeight: "49px" /* Set your desired min-height here */
                            },
                            "& .MuiAccordionSummary-content.Mui-expanded": {
                                margin: "0px",
                            }
                        }}
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                        className={`${expandAcknow == 0 ? `bg-${currentMode}-card` : `bg-${currentMode}-1420`
                            } text-${currentMode}-copy  rounded-[8px] ${currentMode === "dark" ? "" : "shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)]"
                            } `}
                    >
                        <img src="/images/Exception-Automation/Acknowledge.svg" className="convertWhite"></img>{" "}
                        <span className="font-interM ml-3">Acknowledgement</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        {EmailGenerate?.fetching && isFilter && (
                            <div className="h-screen flex bg-[rgb(231,225,225,0.5)] absolute top-0 z-[12] left-0 right-0 justify-center items-center w-full">
                                {" "}
                                <img className="w-[7rem]" src="/images/gif/Loading-Gif.gif" alt="Loading-Gif" />
                            </div>
                        )}

                        <>
                            <div className={``}>
                                <div className={`flex w-full border-b-[1px] items-center border-${currentMode}-700 pt-[22px] justify-between mt-[-10px]`}>
                                    <span className="mr-3 block">To:</span>
                                    <Autocomplete
                                        tabIndex={0}
                                        multiple
                                        sx={{
                                            "&.MuiAutocomplete-root .MuiInputBase-root": {
                                                border: 0,
                                                marginLeft: "0",
                                            },
                                            ".MuiInputBase-input": {
                                                color: `${currentMode == "dark" ? "white" : "black"} !important`,
                                            },
                                            "&.MuiFormHelperText-root .MuiFormHelperText-root": {
                                                marginLeft: "35px",
                                            },
                                            "& .MuiChip-label": {
                                                color: `${currentMode == "dark" ? "white" : "black"}`,
                                            },
                                        }}
                                        id="tags-filled"
                                        value={emailTo == undefined || typeofEmail ? [] : emailTo}
                                        inputValue={ToInputvalue}
                                        options={filteredOptions}
                                        // disableCloseOnSelect
                                        //disableClearable
                                        // autoHighlight={true}
                                        PaperComponent={(props) => filteredOptions?.length ? <Paper {...props} /> : null}
                                        onInputChange={(e, newValue) => {
                                            setToInputValue(newValue);
                                            if (newValue) {
                                                setFilteredOptions(UsersOptions?.filter(option => option?.toLowerCase()?.includes(newValue?.toLowerCase())));
                                            } else {
                                                setFilteredOptions([]);
                                            }
                                        }}
                                        onChange={(e, newval, reason) => {
                                            const errorEmail = newval.find((email) => !regex.test(email));
                                            if (errorEmail) {
                                                // set value displayed in the textbox
                                                setToInputValue(errorEmail);
                                                setError(true);
                                            } else {
                                                setError(false);
                                            }
                                            handleEmailTo(newval?.filter((email) => regex.test(email)));

                                            //  (newval);
                                        }}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    checked={selected}
                                                    size="small"
                                                    sx={{
                                                        color: "#028C78",
                                                        "&.Mui-checked": {
                                                            color: "#028C78",
                                                        },
                                                    }}
                                                    style={{ marginRight: 8 }}
                                                />
                                                <ListItemText primary={option} style={{ color: `${currentMode === "dark" ? "white" : "black"}` }} />
                                            </li>
                                        )}
                                        // disableCloseOnSelect
                                        // autoHighlight={true}
                                        freeSolo
                                        style={{ width: "100%", border: 0 }}
                                        limitTags={1}
                                        renderTags={(value, getTagProps) =>
                                            value &&
                                            value?.map((option, index) => {
                                                // let ValueOption = encryptId(option);
                                                // setEmailCC(ValueOption)
                                                return (
                                                    <Chip
                                                        key={index}
                                                        variant="outlined"
                                                        label={option}
                                                        {...getTagProps({ index })}
                                                        onDelete={() => {
                                                            handleEmailTo(emailTo?.filter((e) => e !== option));
                                                        }}
                                                    />
                                                );
                                            })
                                        }
                                        renderInput={(params) => (
                                            <TextField fullWidth {...params} placeholder="Enter Email Address" />
                                        )}
                                    />
                                </div>
                                <div className={`flex w-full items-center border-b-[1px] border-${currentMode}-910 pt-[22px] justify-between mt-[-10px]`}>
                                    <span className="mr-3 block">Cc:</span>
                                    <Autocomplete
                                        multiple
                                        disablePortal
                                        sx={{
                                            "&.MuiAutocomplete-root .MuiInputBase-root": {
                                                border: 0,
                                                marginLeft: "0",
                                            },
                                            ".MuiInputBase-input": {
                                                color: `${currentMode == "dark" ? "white" : "black"} !important`,
                                            },
                                            "&.MuiFormHelperText-root .MuiFormHelperText-root": {
                                                marginLeft: "35px",
                                            },
                                            "& .MuiChip-label": {
                                                color: `${currentMode == "dark" ? "white" : "black"}`,
                                            },
                                        }}
                                        id="tags-filled"
                                        tabIndex={0}
                                        options={filteredOptions}
                                        value={emailCC == undefined || typeof emailCC == "string" ? [] : emailCC}
                                        inputValue={inputValue}
                                        // disableCloseOnSelect
                                        //disableClearable
                                        // autoHighlight={true}
                                        onInputChange={(e, newValue) => {
                                            setInputValue(newValue);
                                            if (newValue) {
                                                setFilteredOptions(UsersOptions?.filter(option => option?.toLowerCase()?.includes(newValue?.toLowerCase())));
                                            } else {
                                                setFilteredOptions([]);
                                            }
                                        }}
                                        PaperComponent={(props) => filteredOptions?.length ? <Paper {...props} /> : null}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    checked={selected}
                                                    size="small"
                                                    sx={{
                                                        color: "#028C78",
                                                        "&.Mui-checked": {
                                                            color: "#028C78",
                                                        },
                                                    }}
                                                    style={{ marginRight: 8 }}
                                                />
                                                <ListItemText primary={option} style={{ color: `${currentMode === "dark" ? "white" : "black"}` }} />
                                            </li>
                                        )}
                                        onChange={(e, newval, reason) => {
                                            const errorEmail = newval.find((email) => !regex.test(email));
                                            if (errorEmail) {
                                                // set value displayed in the textbox
                                                setInputValue(errorEmail);
                                                setError(true);
                                            } else {
                                                setError(false);
                                            }
                                            handleEmailCC(newval?.filter((email) => regex.test(email)));

                                            //  (newval);
                                        }}
                                        disableCloseOnSelect
                                        freeSolo
                                        style={{ width: "100%", border: 0 }}
                                        limitTags={1}
                                        renderTags={(value, getTagProps) =>
                                            value &&
                                            value?.map((option, index) => {
                                                // let ValueOption = encryptId(option);
                                                // setEmailCC(ValueOption)
                                                return (
                                                    <Chip
                                                        key={index}
                                                        variant="outlined"
                                                        label={option}
                                                        {...getTagProps({ index })}
                                                        onDelete={() => {
                                                            handleEmailCC(emailCC?.filter((e) => e !== option));
                                                        }}
                                                    />
                                                );
                                            })
                                        }
                                        renderInput={(params) => (
                                            <TextField fullWidth {...params} placeholder="Enter Email Address" />
                                        )}
                                    />
                                    {!viewEmailBcc ? (
                                        <Button className="p-0" onClick={handleOpenBcc}>
                                            <span className="block">Bcc</span>
                                        </Button>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                {viewEmailBcc ? (
                                    <div className={`flex w-full items-center border-b-[1px] border-${currentMode}-910 pt-[22px] justify-between mt-[-10px]`}>
                                        <span className="mr-3 block">Bcc:</span>
                                        <Autocomplete
                                            multiple
                                            disablePortal
                                            sx={{
                                                "&.MuiAutocomplete-root .MuiInputBase-root": {
                                                    border: 0,
                                                    marginLeft: "0",
                                                },
                                                ".MuiInputBase-input": {
                                                    color: `${currentMode == "dark" ? "white" : "black"} !important`,
                                                },
                                                "&.MuiFormHelperText-root .MuiFormHelperText-root": {
                                                    marginLeft: "35px",
                                                },
                                                "& .MuiChip-label": {
                                                    color: `${currentMode == "dark" ? "white" : "black"}`,
                                                },
                                            }}
                                            id="tags-filled"
                                            tabIndex={0}
                                            options={filteredOptions}
                                            value={emailBcc == undefined || typeof emailBcc == "string" ? [] : emailBcc}
                                            inputValue={inputValue2}
                                            // disableCloseOnSelect
                                            //disableClearable
                                            // autoHighlight={true}
                                            onInputChange={(e, newValue) => {
                                                setInputValue2(newValue);
                                                if (newValue) {
                                                    setFilteredOptions(UsersOptions?.filter(option => option?.toLowerCase()?.includes(newValue?.toLowerCase())));
                                                } else {
                                                    setFilteredOptions([]);
                                                }
                                            }}
                                            PaperComponent={(props) => filteredOptions.length ? <Paper {...props} /> : null}
                                            onChange={(e, newval, reason) => {
                                                const errorEmail = newval.find((email) => !regex.test(email));
                                                if (errorEmail) {
                                                    // set value displayed in the textbox
                                                    setInputValue2(errorEmail);
                                                    setError(true);
                                                } else {
                                                    setError(false);
                                                }
                                                handleEmailBCC(newval?.filter((email) => regex.test(email)));

                                                //  (newval);
                                            }}
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props}>
                                                    <Checkbox
                                                        checked={selected}
                                                        size="small"
                                                        sx={{
                                                            color: "#028C78",
                                                            "&.Mui-checked": {
                                                                color: "#028C78",
                                                            },
                                                        }}
                                                        style={{ marginRight: 8 }}
                                                    />
                                                    <ListItemText
                                                        primary={option}
                                                        style={{ color: `${currentMode === "dark" ? "white" : "black"}` }}
                                                    />
                                                </li>
                                            )}
                                            disableCloseOnSelect
                                            freeSolo
                                            style={{ width: "100%", border: 0 }}
                                            limitTags={1}
                                            renderTags={(value, getTagProps) =>
                                                value &&
                                                value?.map((option, index) => {
                                                    // let ValueOptions = encryptId(option);
                                                    // bccreceivers.push(ValueOptions);
                                                    return (
                                                        <Chip
                                                            key={index}
                                                            variant="outlined"
                                                            label={option}
                                                            {...getTagProps({
                                                                index,
                                                            })}
                                                            onDelete={() => {
                                                                handleEmailBCC(emailBcc?.filter((e) => e !== option));
                                                            }}
                                                        />
                                                    );
                                                })
                                            }
                                            renderInput={(params) => <TextField fullWidth {...params} placeholder="Enter Email Address" />}
                                        />
                                    </div>
                                ) : (
                                    ""
                                )}

                                <div className="py-[20px]">
                                    <div className="flex justify-between">
                                        <span className="block mb-2">Subject</span>
                                        <TogglePopup ref={childCompRef} menuList={filterMenuList} selectedItemChange={onFilterItems} />

                                        <Button onClick={(e) => childCompRef.current.handleClick("bottom-end", 1, e)}>
                                            {props?.selectedId?.ismail_auto_generate ? (
                                                <img src="/images/Exception-Automation/modify-email.svg"></img>
                                            ) : (
                                                <img src="/images/Exception-Automation/Email-generate.svg"></img>
                                            )}
                                        </Button>
                                    </div>
                                    <Input
                                        tabIndex={0}
                                        value={subject}
                                        onChange={(e) => handleEmailSubject(e)}
                                        className={`border-[1px] rounded-[8px] py-2 px-[12px] w-full border-${currentMode}-840 bg-${currentMode}-810`}
                                        placeholder="Enter your subject"
                                    />
                                </div>
                            </div>

                            <div className={` allocate-textarea relative `}>
                                <span className="block">Message</span>
                                <div className="ml-[45px]">
                                    <span
                                        onClick={ReacthandleClick}
                                        // aria-describedby={id}
                                        className={`flex absolute right-[5px] top-[1px] block   `}
                                    >
                                        Insert Placeholders{" "}
                                        <img className="ml-2 convertWhite" src="/images/common/chevron-grey.svg" alt="chevronDown" />
                                    </span>
                                    {showing2 && (
                                        <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={(e) => handleClickAway(e)}>
                                            <div
                                                className={`bg-${currentMode}-card absolute top-[-410px] right-0 border-${currentMode}-1020 border-[1px] z-[8] mt-[32px]  ${currentMode !== "dark" ? `shadow-[0px_4px_20px_rgba(85,85,85,0.2)]` : ``
                                                    }  rounded-[10px]`}
                                            >
                                                <div className="grid grid-cols-1 gap-4">
                                                    <div className={`py-[20px]`}>
                                                        <span draggable="false" className="font-interSb mb-2 block pointer-events-none px-[30px]">
                                                            Invoice
                                                        </span>
                                                        <ul className="cursor-pointer">
                                                            {invoicePlaceHolderList?.length !== 0 ? (
                                                                invoicePlaceHolderList?.map((el, i) => {
                                                                    return (
                                                                        <li
                                                                            onDragStart={(event) => {
                                                                                event.dataTransfer.setData("text/plain", el.placeholder_display_name);
                                                                            }}
                                                                            onDragEnd={(event) => {
                                                                                event.dataTransfer.clearData();
                                                                            }}
                                                                            className={`hover:bg-${currentMode}-960 px-[30px] userDrag py-2 w-full`}
                                                                            key={i}
                                                                            draggable="true"
                                                                            onClick={() => handleMessageClick(el.placeholder_display_name)}
                                                                        >
                                                                            {el.placeholder_display_name}
                                                                        </li>
                                                                    );
                                                                })
                                                            ) : (
                                                                <div className="px-[20px]">No data found</div>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </ClickAwayListener>
                                    )}
                                </div>

                                <div onDrop={handleEmailDrop}>
                                    <ReactQuill
                                        tabIndex={0}
                                        onDragOver={(event) => event.preventDefault()}
                                        theme="snow"
                                        onFocus={ReactFocus}
                                        className="custom-quill-toolbar"
                                        ref={ReactQuillRef}
                                        style={{ borderRadius: "8px", marginTop: "18px" }}
                                        value={formattedMessage}
                                        formats={formats}
                                        onBlur={(event) => {
                                            handleBlur();
                                        }}
                                        modules={modules}
                                        disabled={!Emailenable}
                                        onChange={(e) => handleEmailBody(e)}
                                        onSelectionChange={(selection) => {
                                            const cursorPosition = selection ? selection.index : -1;
                                        }}
                                    />
                                </div>
                            </div>
                        </>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    );
};

export default AcknowledgementCard;
