import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import Swal from "sweetalert2";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import Modal from "../../../components/UI/Modal/Modal";
import { NumericFormat } from "react-number-format";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import { FormControl, MenuItem, Select } from "@mui/material";
let GetName = [];
export const AddItemsModal = (props) => {
    const { costData } = props;
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const ListData = props?.nonPoCostListData;
    let { costListData, setCostListData } = props;
    const addItem = props?.isOpened;
    const costCenterData = props?.listData;
    GetName = costCenterData?.map((el) => {
        return {
            name: el?.costcentre_name,
            type: el?.type,
        };
    });
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [CCInput, setCCInput] = useState("");
    const [addrow, setAddrow] = useState([ListData]);
    const [newrow, setNewRow] = useState([]);
    const [New, setNew] = useState(false);
    const Manual = async () => {
        await setCostListData(GetName || []);
    };
    useEffect(() => {
        Manual();
        setFilteredOptions(GetName || []);
    }, [GetName?.length, New]);
    useEffect(() => {
        if (typeof CCInput == "string" && CCInput?.length > 0) {
            const filterArr = costListData?.filter((ele) => ele?.name?.toLowerCase()?.includes(CCInput));
            setFilteredOptions(filterArr);
        } else {
            setFilteredOptions(GetName);
        }
    }, [CCInput]);
    const tableListData = props?.tableListData;
    const getTotal = tableListData?.map((el) => el);
    const numFormat = localStorage.getItem("OrgNumFormat");
    const [value, setValue] = useState("");
    if (ListData?.length == 0 || ListData == undefined) {
        const data = {
            gl_account_code: "",
            ledger_type: 0,
            cost_center: "",
            amount: "",
        };
        ListData?.push(data);
    }
    const AddNew = ListData?.length == 0;
    const Row = ListData?.length !== 0;
    const addRow = () => {
        setNew(true);
        const data = {
            gl_account_code: "",
            ledger_type: 0,
            cost_center: "",
            amount: "",
        };
        ListData?.push(data);
        setAddrow([...newrow]);
        setTimeout(() => {
            var element = document.getElementById("myView2");
            element.scrollTop = element.scrollHeight;
        }, 100);
    };
    const addnewRow = () => {
        const data = {
            gl_account_code: "",
            ledger_type: 0,
            cost_center: "",
            amount: "",
        };
        newrow.push(data);
        setNewRow([...newrow]);
        setTimeout(() => {
            var element = document.getElementById("myView2");
            element.scrollTop = element.scrollHeight;
        }, 100);
    };
    const tableRowRemove = (id, inde) => {
        Swal.fire({
            text: "Are you sure you want to delete?",
            icon: "warning",
            showCloseButton: true,
            showCancelButton: true,
            buttons: {
                cancel: "Cancel",
                confirm: "Confirm",
            },
        }).then((confirm) => {
            if (confirm?.isConfirmed) {
                ListData.splice(id, 1);

                setAddrow([...addrow]);
            }
        });
    };
    const handleValueChange = (newVal, newType, inde, event) => {
        setFilteredOptions([]);
        setCCInput(event.target.value);
        ListData.forEach((element, index) => {
            if (inde === index) {
                element.cost_center = event.target.value ? event.target.value : newVal;
                // Update the ledger_type based on the selected cost center name type
                const selectedCostCenter = costCenterData.find((el) =>
                    el.costcentre_name === event.target.value ? event.target.value : newVal && el?.type == newType
                );
                if (selectedCostCenter) {
                    element.ledger_type = selectedCostCenter?.type;
                } else if ((newVal == "" || !newVal) && event.target.value == "") {
                    element.ledger_type = 0;
                }
            }
        });
        setValue(newVal);
        setAddrow([...addrow]);
    };
    const handleValueAmountChange = (event, inde) => {
        if (event?.target?.value?.includes("-")) {
            event.preventDefault();
        }
        ListData.forEach((element, index) => {
            if (inde == index) {
                element.amount = event.target.value;
            }
        });
        setValue(event.target.value);
        setAddrow([...addrow]);
    };
    let totalVal = 0;
    const listData = [
        { name: "All", value: 0 },
        { name: "Cost Center", value: 1 },
        { name: "Internal Order", value: 2 },
        // { name: "Profit Center", value: 3 },
    ];
    const [ledgerType, setLedgerType] = useState(0);
    useEffect(() => {
        if (AddNew) {
            const data = {
                gl_account_code: "",
                ledger_type: 0,
                cost_center: "",
                amount: "",
            };
            const temp = newrow;
            temp.push(data);
            setNewRow([...newrow]);
        }
    }, []);
    if (numFormat == "3") {
        const totalAmount = ListData?.map((el) => parseFloat(el?.amount?.replaceAll(" ", "")));
        for (let i = 0; i < totalAmount?.length; i++) {
            totalVal += totalAmount[i] || 0;
        }
    } else if (numFormat == "1") {
        const totalAmount = ListData?.map((el) => parseFloat(el?.amount?.replaceAll(",", "")));
        for (let i = 0; i < totalAmount?.length; i++) {
            totalVal += totalAmount[i] || 0;
        }
    } else if (numFormat == "2") {
        const totalAmount = ListData?.map((el) => parseFloat(el?.amount?.replaceAll(".", "")?.replaceAll(",", ".")));
        for (let i = 0; i < totalAmount?.length; i++) {
            totalVal += totalAmount[i] || 0;
        }
    }
    if (totalVal) {
        props?.setTotalLedgerVal(totalVal);
    }
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const onChangeLedgerType = (e, index) => {
        const selectedLedgerType = +e.target.value;
        setLedgerType(selectedLedgerType);
        ListData[index].ledger_type = selectedLedgerType;
        if (selectedLedgerType == 1 || selectedLedgerType == 2) {
            const filterArr = costCenterData?.filter((el) => el?.type === selectedLedgerType);
            const costList =
                filterArr?.map((el) => {
                    return {
                        name: el?.costcentre_name,
                        type: el?.type,
                    };
                }) || [];
            setCostListData(costList);
        } else {
            setCostListData(GetName);
        }
        setValue(selectedLedgerType);
        setAddrow([...addrow]);
    };

    const handleCostList = (type) => {
        if (type == 1 || type == 2) {
            const filterArr = costCenterData?.filter((el) => el?.type === type);
            const getName = filterArr?.map((el) => {
                return {
                    name: el?.costcentre_name,
                    type: el?.type,
                };
            });
            setCostListData(getName);
            setFilteredOptions(getName);
        } else {
            // Handle the default case or setCostListData to an appropriate default value
            setCostListData(GetName);
            setFilteredOptions(GetName);
        }
    };
    const filterOptions = (options, { inputValue }) => {
        return options.filter((option) => option.name.toLowerCase().includes(inputValue.toLowerCase()));
    };

    return (
        <Modal overlap={true} cartClose={props?.handleClose} center="center" className={`${props?.className}`}>
            <div
                className={`max-w-[882px] mx-auto relative max-h-[700px] transition bg-${currentMode}-card border-[rgba(236,236,236,0.88)] ${
                    currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                } rounded-[10px] duration-150 ease-out p-[30px]`}>
                <div className={`bg-${currentMode}-card z-[9] mx-[-30px] py-[20px] px-[50px]`}>
                    <span
                        className="cursor-pointer absolute right-[2rem] convertWhite top-[1.3rem]"
                        onClick={() => props?.handleCloseCostCenter(props?.oldData)}
                        title="To close">
                        <img src="/images/common/close-one.svg" alt="close-one" />
                    </span>
                    {/* <div className="mb-5 space-x-[20px]">
                        <span className={`text-${currentMode}-10`}>
                            #:<span className="font-interSb"> {costData?.si || "-"}</span>
                        </span>
                        <span className={`text-${currentMode}-10`}>
                            Item Code:<span className="font-interSb"> {costData?.itemCode || "-"}</span>
                        </span>
                        <span className={`text-${currentMode}-10`}>
                            Total:<span className="font-interSb"> {costData?.total || "-"}</span>
                        </span>
                    </div> */}
                    <div>
                        <table className="w-full ledgerTbl text-left">
                            <div className="w-full grid grid-cols-4 mb-3">
                                <div className="text-left">
                                    <span>General Ledger Code</span>
                                </div>
                                <div className="text-left ml-2">
                                    <span>Ledger Type</span>
                                </div>
                                <div className="text-left ml-2">
                                    <span>Cost Center/Internal Order</span>
                                </div>
                                <div className="text-left ml-3">
                                    <span>Amount</span>
                                </div>
                            </div>
                            <div id="myView2" className="overflow-y-auto max-h-[20rem] ">
                                <tbody>
                                    {ListData?.map((listelem, index) => {
                                        return (
                                            <div key={index} className="grid-cols-4 grid gap-x-4 mb-4">
                                                <div>
                                                    <Input
                                                        className={`flex-1 trunc-1 border-${currentMode}-840 max-w-[191px] px-4 py-2 rounded-[8px] border-[1px] bg-transparent`}
                                                        placeholder="Select General Ledger"
                                                        value={listelem?.gl_account_code}
                                                        onClick={() => props?.handleOpenLedger(index)}
                                                    />
                                                </div>
                                                <div>
                                                    <FormControl
                                                        sx={{
                                                            width: "191px",
                                                            marginBottom: 3,
                                                        }}>
                                                        <Select
                                                            name="ledger_type"
                                                            value={listelem?.ledger_type}
                                                            MenuProps={{
                                                                sx: {
                                                                    "& .MuiMenu-paper": {
                                                                        backgroundColor: dropdownColor,
                                                                        color: dropdownText,
                                                                    },

                                                                    "& .MuiMenuItem-root:hover": {
                                                                        backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                                    },

                                                                    "& .Mui-selected": {
                                                                        backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                                    },
                                                                },
                                                            }}
                                                            IconComponent={() => (
                                                                <img
                                                                    className="absolute right-[15px] opacity-[.5] pointer-events-none"
                                                                    src={
                                                                        currentMode !== "dark"
                                                                            ? `/images/header/chevronDown.svg`
                                                                            : `/images/header/chevronDown-white.svg`
                                                                    }
                                                                    alt="chevronDown"
                                                                />
                                                            )}
                                                            SelectDisplayProps={{
                                                                style: {
                                                                    paddingTop: "",
                                                                    color: dropdownText,
                                                                    paddingBottom: "",
                                                                    padding: "13px 30px 13px 15px",
                                                                    borderRadius: "8px",
                                                                },
                                                            }}
                                                            onChange={(e) => onChangeLedgerType(e, index)}>
                                                            {listData &&
                                                                listData?.map((elem, i) => {
                                                                    return (
                                                                        <MenuItem key={i + 1} name="ledger_type" value={elem?.value}>
                                                                            {elem?.name}
                                                                        </MenuItem>
                                                                    );
                                                                })}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                <div>
                                                    <Autocomplete
                                                        id={`combo-box-demo-${index}`}
                                                        // id={`combo-box-hint-demo-${index}`}
                                                        // autoSelect
                                                        fullWidth
                                                        sx={{
                                                            zIndex: 9999,
                                                            ".MuiOutlinedInput-root": {
                                                                padding: "9px 15px !important",
                                                                maxWidth: "191px",
                                                            },
                                                        }}
                                                        value={listelem?.cost_center == "" || !listelem?.cost_center ? "" : listelem?.cost_center}
                                                        onChange={(event, newval) => {
                                                            handleValueChange(newval?.name, newval?.type, index, event);
                                                        }}
                                                        options={
                                                            listelem?.cost_center === "" && filteredOptions.length === 0
                                                                ? costListData
                                                                : filteredOptions || []
                                                        }
                                                        getOptionLabel={(option) => option?.name || option}
                                                        onOpen={() => handleCostList(listelem.ledger_type)}
                                                        filterOptions={filterOptions} // Add this line
                                                        renderInput={(params) => (
                                                            <TextField
                                                                sx={{
                                                                    "& .MuiInputBase-input": {
                                                                        padding: "0 !important",
                                                                        border: "0 !important",
                                                                        color: `${currentMode === "dark" ? "white" : "black"} !important`,
                                                                    },
                                                                }}
                                                                onChange={(event, newval) => {
                                                                    handleValueChange(newval?.name, newval?.type, index, event);
                                                                }}
                                                                {...params}
                                                                placeholder="Select cost center"
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    autoComplete: "new-password", // disable autocomplete and autofill
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className="flex">
                                                    <div className="flex-1 mr-3 max-w-[191px]">
                                                        <NumericFormat
                                                            allowNegative={false}
                                                            className={`flex-1 trunc-1 w-full px-3 py-2 rounded-[8px] border-${currentMode}-840 border-[1px] bg-transparent`}
                                                            placeholder="Enter amount"
                                                            decimalScale={2}
                                                            maxLength={20}
                                                            value={listelem?.amount}
                                                            displayType="input"
                                                            thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                                                            decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                                                            onChange={(event) => {
                                                                handleValueAmountChange(
                                                                    {
                                                                        target: {
                                                                            name: event.target.name,
                                                                            value: event.target.value,
                                                                        },
                                                                    },
                                                                    index
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="flex flex-initial space-x-2">
                                                        <Button
                                                            className="mb-6"
                                                            title="To delete the row"
                                                            disabled={ListData?.length > 1 ? false : true}
                                                            onClick={() => tableRowRemove(index)}>
                                                            <img src="/images/common/remove-icon.svg" alt="remove-icon" />
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </tbody>
                            </div>
                        </table>
                    </div>
                    <div className="">
                        <div className={`bg-${currentMode}-1040 grid grid-cols-2 border-${currentMode}-840 mt-3 mb-8 rounded-[8px]`}>
                            <span className="py-3 font-interM pl-5">Total</span>
                            <span className="py-3 text-center pl-10 pr-5 font-interM">
                                <NumericFormat
                                    allowNegative={false}
                                    className={`${totalVal > 999 ? `pl-[170px]` : totalVal > 9 ? `pl-[250px]` : `pl-[300px]`}`}
                                    title={totalVal}
                                    value={numFormat !== "2" ? totalVal.toFixed(3) : totalVal}
                                    displayType="text"
                                    decimalScale={2}
                                    thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                                    decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                                />
                            </span>
                        </div>
                        {AddNew && (
                            <Button onClick={() => addnewRow()} className="text-light-20" title="To add new row">
                                <div className="mt-[10px] mb-[20px] mr-[220px]">+ Add New</div>
                            </Button>
                        )}
                        {Row && (
                            <Button onClick={() => addRow()} className="text-light-20" title="To add new row">
                                <div className="mt-[10px] mb-[20px] mr-[220px]">+ Add New</div>
                            </Button>
                        )}
                        <div className="space-x-2 flex">
                            <Button
                                type="submit"
                                title="To Save"
                                onClick={() => props?.handleCostCenter(newrow, ListData)}
                                className={`bg-light-20 flex text-base font-interR py-[.5rem] px-[2.24rem] rounded-[8px] text-white`}>
                                Save
                            </Button>
                            <Button
                                type="cancel"
                                title="To Cancel"
                                onClick={() => props?.handleCloseCostCenter(props?.oldData)}
                                className="bg-light-240 flex text-base font-interR py-[.5rem] px-[1.9rem] rounded-[8px] text-light-40 closeModal">
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
