import { FormControl, MenuItem, Select } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../../../components/Button";
import Loader from "../../../components/Loader";
import TriggerSwitch from "../../../components/TriggerSwitch";
import Modal from "../../../components/UI/Modal/Modal";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import { getCurrentUser } from "../../../redux/actions/auth";
import { folderconfigAdd, folderConfigStatusMode, folderconfigUpdate, getFolderConfig } from "../../../redux/actions/folderconfig";
import { GET_FOLDER_CONFIG_RESET, UPDATE_FOLDER_CONFIG_RESET } from "../../../redux/types/types";
import { decryptId } from "../../../utils/helpers";

const ViewFolderConfigs = (props) => {
    const orgId = props?.orgId;
    const orgStatus = props?.orgStatus;
    const { configData, requestComplete: reqComp } = useSelector((state) => state?.folderConfigState);
    const [invoiceType, setInvoiceType] = useState(0);
    const { data, requestComplete } = useSelector((state) => state?.folderConfigStatusState);
    const { data: updateData, requestComplete: reqComplete } = useSelector((state) => state?.folderConfigUpdateState);

    const onChangeInvoiceType = (id, invoice_type, e, username, foldId, locationurl) => {
        Swal.fire({
            text: `Are you sure you want to change the invoice type?`,
            icon: "warning",
            closeOnClickOutside: false,
            showCancelButton: true,
            showCloseButton: true,
            buttons: {
                cancel: "Cancel",
                confirm: "Confirm",
            },
        }).then((confirm) => {
            if (confirm?.isConfirmed) {
                configDataList.forEach((item) => {
                    if (item.id === id) {
                        item.invoice_type = +e.target.value;
                    }
                    setViewConfigData([...configDataList]);
                    dispatch(
                        folderconfigUpdate({
                            id: id,
                            emailaddress: username,
                            invoice_type: +e.target.value,
                            foldertype: foldId,
                            locationurl: locationurl,
                        })
                    );
                });
            }
        });
        localStorage.setItem("UpdateOrgId", props?.orgId);
    };
    if (reqComplete && updateData?.status) {
        Swal.fire({
            text: "Updated successfully!",
            icon: "success",
            closeOnClickOutside: false,
            showCloseButton: true,
            button: "Ok",
        }).then((confirm) => {
            if (confirm) {
                dispatch(getFolderConfig({ id: orgId }));
                dispatch({ type: UPDATE_FOLDER_CONFIG_RESET });
            }
        });
    }
    const [configDataList, setViewConfigData] = useState(configData);
    if (configData && configData !== configDataList) {
        setViewConfigData(configData);
    }
    const dispatch = useDispatch();
    const handleChange = (id, status) => {
        Swal.fire({
            text: `Are you sure you want to ${status ? "inactivate" : "activate"} the folder?`,
            icon: "warning",
            closeOnClickOutside: false,
            showCloseButton: true,
            showCancelButton: true,
            buttons: {
                cancel: "Cancel",
                confirm: "Confirm",
            },
        }).then((confirm) => {
            if (confirm?.isConfirmed) {
                configDataList.forEach((item) => {
                    if (item.id === id) {
                        item.isactive = status ? false : true;
                    }
                });
                setViewConfigData([...configDataList]);
                dispatch(
                    folderConfigStatusMode({
                        id: id,
                        isactive: status ? false : true,
                    })
                );
            }
            if (confirm?.isConfirmed) {
                if (requestComplete && data.status) {
                    Swal.fire({
                        text: "The status has been updated successfully.",
                        icon: "success",
                        closeOnClickOutside: false,
                        showCloseButton: true,
                        button: "Ok",
                    }).then((confirm) => {
                        if (confirm) {
                            dispatch(getFolderConfig({ id: orgId }));
                        }
                    });
                }
            }
        });
    };
    const addFolderConfig = () => {
        dispatch(
            folderconfigAdd({
                foldertype: "",
                // access_token: profile?.tokenInfo?.access_token,
                // refresh_token: profile?.tokenInfo?.refresh_token,
                emailaddress: "",
                id: "",
                organisation_id: props?.orgId,
                invoice_type: invoiceType,
                locationurl: "",
            })
        );
        localStorage.setItem("UpdateOrgId", props?.orgId);
    };
    const handleClose = () => {
        props?.handleClose();
        window.location.reload();
        // const reqObj = {
        //   status: 2,
        //   search_text: "",
        //    organsiation: orgId,
        // };
        // dispatch(getOrganisation(reqObj));
    };
    const closeCart = () => {
        props?.handleClose();
        dispatch({ type: GET_FOLDER_CONFIG_RESET });
    };
    useEffect(() => {
        //  setViewConfigData(configData);
    }, [configDataList]);
    const DisplayRole = useSelector((state) => state?.DisplayRolePermissionStateAction);
    const { data: DataList } = DisplayRole;
    const RoleList = DataList?.role_info;
    const RoleMenu = RoleList?.menu;
    const RoleEmail = RoleMenu?.find((elem) => elem?.menu_id === 3);
    const RoleEmailAction = RoleEmail?.actions;
    const RoleActFolderActive = RoleEmailAction?.find((element) => element.id === 4);
    const RoleActFolderDeactive = RoleEmailAction?.find((element) => element.id === 5);
    const { data: listData } = useSelector((state) => state?.getDocumentTypeListState);
    const { currentMode } = useContext(ThemeContext);
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    return (
        <>
            <Modal cartClose={props?.handleClose} className={`${props?.className}`}>
                <Link onClick={requestComplete && data.status ? handleClose : closeCart} className="p-[6.5px] absolute right-[595px] bg-light-10">
                    <img src="/images/common/whiteClose.svg" alt="whiteClose" />
                </Link>
                <div
                    className={`w-[595px] bg-${currentMode}-card transition duration-150 ease-out flex-col ml-auto flex h-screen max-h-[-webkit-fill-available] overflow-y-auto`}>
                    <div className={` px-[30px] py-[24px] border-b-[1px] border-${currentMode}-700 mb-[1.8rem] relative`}>
                        <h4 className="font-interSb text-lg">Folder Configuration</h4>
                    </div>
                    {configDataList?.map((elem) => {
                        return (
                            <div>
                                <div className="px-[30px] flex items-center justify-between">
                                    <div key={elem?.id}>
                                        <span className="font-interM flex mb-2">
                                            <img
                                                className="mr-2"
                                                src={elem?.foldertype?.id === 1 ? `/images/common/googledrive.svg` : `/images/common/onedrive.svg`}
                                                alt="googleIcon"
                                            />
                                            <p>{decryptId(elem?.username)}</p>
                                        </span>
                                        <span className="flex text620 mb-2">{elem?.locationurl}</span>
                                    </div>
                                    <FormControl
                                        sx={{
                                            minWidth: "150px",
                                            marginBottom: 3,
                                        }}>
                                        <Select
                                            name="orgName"
                                            value={elem?.invoice_type}
                                            className=" w-full "
                                            disabled={orgStatus && RoleActFolderActive?.isallowed && elem?.isactive == true ? false : true}
                                            IconComponent={() => (
                                                <img
                                                    className="absolute right-[10px] opacity-50 pointer-events-none"
                                                    src={
                                                        currentMode !== "dark"
                                                            ? `/images/header/chevronDown.svg`
                                                            : `/images/header/chevronDown-white.svg`
                                                    }
                                                    alt="chevronDown"
                                                />
                                            )}
                                            MenuProps={{
                                                sx: {
                                                    "& .MuiMenu-paper": {
                                                        backgroundColor: dropdownColor,
                                                        color: dropdownText,
                                                        //   maxWidth: "30rem !important",
                                                    },
                                                    "& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                    },
                                                    "& .Mui-selected": {
                                                        backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                    },
                                                },
                                            }}
                                            SelectDisplayProps={{
                                                style: {
                                                    paddingTop: "15px",
                                                    paddingBottom: "15px",
                                                    color: dropdownText,
                                                    paddingRight: "30px",
                                                    border: "0",
                                                    borderRadius: "8px",
                                                },
                                            }}
                                            onChange={(e) =>
                                                onChangeInvoiceType(
                                                    elem?.id,
                                                    elem?.invoice_type,
                                                    e,
                                                    elem?.username,
                                                    elem?.foldertype?.id,
                                                    elem?.locationurl
                                                )
                                            }>
                                            <MenuItem key={0} value={0} name="invoice_type">
                                                {"Select"}
                                            </MenuItem>
                                            {listData &&
                                                listData?.map((listelem, i) => {
                                                    return (
                                                        <MenuItem key={i + 1} name="invoice_type" value={listelem?.id}>
                                                            {listelem?.invoice_type}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </FormControl>
                                    <div>
                                        {/* {
               RoleActFolderActive?.isallowed && !elem.isactive ? 
              <TriggerSwitch  disabled={false}   sx={{ m: 1 }} checked={elem.isactive}  onChange={(e)=>handleChange(elem.id,elem.isactive)}  inputProps={{ 'aria-label': 'controlled' }}/>
              : RoleActFolderDeactive?.isallowed && elem.isactive ?
              <TriggerSwitch  disabled={false}  sx={{ m: 1 }} checked={elem.isactive}  onChange={(e)=>handleChange(elem.id,elem.isactive)}  inputProps={{ 'aria-label': 'controlled' }}/>
              :
              <TriggerSwitch  disabled={true} onChange={(e)=>handleChange(elem.id,elem.isactive)}  sx={{ m: 1 }} checked={elem.isactive}  inputProps={{ 'aria-label': 'controlled' }}/>
           
              } */}
                                        <TriggerSwitch
                                            disabled={orgStatus && RoleActFolderActive?.isallowed ? false : true}
                                            sx={{ m: 1 }}
                                            checked={elem.isactive}
                                            onChange={(e) => handleChange(elem.id, elem.isactive)}
                                            inputProps={{
                                                "aria-label": "controlled",
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className=" my-4">
                                    <hr className={`border-${currentMode}-600`} />
                                </div>
                            </div>
                        );
                    })}
                    {!configDataList || (reqComp && configDataList?.length == 0) ? (
                        <div className="px-[30px] h-full grid place-items-center place-content-center">
                            {" "}
                            <img className="mb-3" src="/images/common/delivery.png" alt="searching" />
                            <h4 className="font-interSb block">No Data found</h4>
                        </div>
                    ) : (
                        ""
                    )}
                    {!reqComp && configDataList?.length === 0 ? <Loader /> : ""}
                </div>
            </Modal>
        </>
    );
};

export default ViewFolderConfigs;
