import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import Button from "../../components/Button";
import Modal from "../../components/UI/Modal/Modal";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { sendBillingEmail } from "../../redux/actions/report";
import { SEND_BILLING_MAIL_RESET } from "../../redux/types/types";
import { encryptId } from "../../utils/helpers";
const UploadLimit = (props) => {
    const { currentMode } = useContext(ThemeContext);
    const dispatch = useDispatch();
    const emailStatus = useSelector((state) => state?.sendBillingEmailState);

    const handleEMailSend = () => {
        props?.handleClose();
        const user = JSON.parse(localStorage.getItem("user"));
        const reqObj = {
            customerName: user?.username,
            customerEmail: user?.emailaddress,
            phone: user?.mobilenumber,
            toEmail: encryptId(process.env?.REACT_APP_SALES_EMAIL),
        };
        dispatch(sendBillingEmail(reqObj));
        Swal.fire({
            title: "Thank you for using our free trial version!",
            text: "MyiQ.Ai has received your upgrade request. Our team will reach out to you shortly.",
            closeOnClickOutside: false,
            showCloseButton: true,
            icon: "success",
            button: "Ok",
        });
    };
    if (emailStatus?.requestComplete) {
        dispatch({ type: SEND_BILLING_MAIL_RESET });
    }
    return (
        <>
            <Modal cartClose={props?.handleClose} center="center" className={`${props?.className}`}>
                <div
                    className={`max-w-[600px] mx-auto relative bg-${currentMode}-card border-[rgba(223,225,226,0.56)] px-[90px] py-[48px]  rounded-[10px] ${
                        currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                    } `}>
                    <span onClick={props?.handleClose} className="cursor-pointer absolute right-[20px] top-[20px] convertWhite">
                        <img src="/images/common/closeBlk.svg" alt="closeBlk" />
                    </span>
                    {/* <div className="flex justify-center ">
                        <img
                            className="bg-message rounded-[50%] w-[50px] h-[50px] mb-8"
                            src="/images/common/limit-alert.svg"
                            alt="alert"
                        />
                    </div> */}
                    <span className="font-interSb block mb-4">You reached the limits of your Plan!</span>
                    <p className={`mb-4 text-${currentMode}-40 font-inter text-base font-normal`}>
                        Your existing license package limit has exceeded. Upgrade your plan now to upload any additional invoices.
                    </p>
                    <div className="flex mt-10 space-x-3">
                        <Button
                            className="bg-light-20 px-[30px] py-[9px] text-white rounded-[8px]"
                            type="submit"
                            onClick={handleEMailSend}
                            // disabled={fetching}
                        >
                            Upgrade
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default UploadLimit;
