import { React, useContext, useState, useEffect } from "react";
import { Accordion } from "@mui/material";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import { GoogleLogin } from "react-google-login";
import ReactLoginMS from "react-ms-login";
import axios from "axios";
import UserInputTrigger from "./UserInput";
import authHeader from "../../../redux/actions/auth-header";
import { decryptId } from "../../../utils/helpers.js";

const EmailSettings = (props) => {
    const businessSetting = props?.businessSetting;
    const setBusinessSetting = props?.setBusinessSetting;
    const setExcepByOrg = props?.setExcepByOrg;
    const excepByOrg = props?.excepByOrg;
    const [isFeaching, setIsFeaching] = useState(true);
    const clientId = process.env?.REACT_APP_GOOGLE_CLIENT_ID;
    const msClientId = process.env?.REACT_APP_MS_APP_ID;
    const { currentMode } = useContext(ThemeContext);
    const [selectedNumber, setSelectedNumber] = useState();
    const [validEmail, checkValidEmail] = useState(false);
    const [email, setEmail] = useState("");
    useEffect(() => {
        if (businessSetting) {
            setEmail(businessSetting?.email_address);
        }
    }, [businessSetting?.email_address]);
    const profile = props?.profile;
    const setProfile = props?.setProfile;
    const expandEmailSet = props?.expandEmailSet;
    const handleChangeEmailSet = props?.handleChangeEmailSet;
    const AccordionSummary = props?.AccordionSummary;
    const AccordionDetails = props?.AccordionDetails;
    const [scopes, setScopes] = useState("https://www.googleapis.com/auth/gmail.readonly");
    const orgId = localStorage.getItem("orgId") || 0;
    const onFailure = (err) => {};
    const emailOnchange = (e) => {
        const { name, value } = e.target;
        setBusinessSetting((prevData) => ({
            ...prevData,
            email_address: value,
        }));
        setExcepByOrg((prevVal) => ({
            ...prevVal,
            bussiness_settings: {
                ...prevVal?.bussiness_settings,
                email_address: value,
            },
        }));
        setEmail(e.target.value);
    };
    const loginMicrosoftHandler = (msData) => {
        const data = {
            code: msData?.code,
            type: 1,
            org_id: +orgId,
        };
        setIsFeaching(false);
        axios
            .post(process.env.REACT_APP_NODE_API_URL + "getMSTokenExAutomation", data, {
                headers: authHeader(),
            })
            .then((response) => {
                if (response.status == 200) {
                    setBusinessSetting((prevData) => ({
                        ...prevData,
                        email_type: 2,
                    }));
                    setExcepByOrg((prevVal) => ({
                        ...prevVal,
                        bussiness_settings: {
                            ...prevVal?.bussiness_settings,
                            email_type: 2,
                        },
                    }));
                    setIsFeaching(true);
                    const tokenInfoData = {
                        access_token: response?.data?.access_token,
                        refresh_token: response?.data?.refresh_token,
                        expire_date: response?.data?.expirationDateTime,
                        subscription_id: response?.data?.subscription,
                        isSync: true,
                    };
                    setProfile({ tokenInfo: tokenInfoData });
                    setSelectedNumber(2);
                    if (response?.data?.account?.username) {
                        checkValidEmail(true);
                        setEmail(response?.data?.account?.username);
                        setBusinessSetting((prevData) => ({
                            ...prevData,
                            email_address: response?.data?.account?.username,
                        }));
                        setExcepByOrg((prevVal) => ({
                            ...prevVal,
                            bussiness_settings: {
                                ...prevVal?.bussiness_settings,
                                email_address: response?.data?.account?.username,
                            },
                        }));
                    }
                }
            });
    };
    const btnContent = () => {
        return (
            <span className={`flex text-${currentMode}-copy`}>
                <img className="mr-2" src="/images/common/microsoftIcon.svg" alt="plus" /> Microsoft
            </span>
        );
    };
    const onSuccess = (res) => {
        const data = {
            code: res?.code,
            org_id: +orgId,
        };
        setIsFeaching(false);
        axios
            .post(process.env.REACT_APP_NODE_API_URL + "getGoogleTokenExAutomation", data, {
                headers: authHeader(),
            })
            .then((response) => {
                if (response.status == 200) {
                    setBusinessSetting((prevData) => ({
                        ...prevData,
                        email_type: 1,
                    }));
                    setExcepByOrg((prevVal) => ({
                        ...prevVal,
                        bussiness_settings: {
                            ...prevVal?.bussiness_settings,
                            email_type: 1,
                        },
                    }));
                    setIsFeaching(true);
                    const tokenInfoData = {
                        access_token: response?.data?.access_token,
                        refresh_token: response?.data?.refresh_token,
                        isSync: true,
                    };
                    setProfile({ tokenInfo: tokenInfoData });
                    setSelectedNumber(1);
                    if (response?.data?.emailaddress) {
                        // if(!/^(?!.+@(gmail|google|yahoo|outlook|hotmail|msn)\..+)(.+@.+\..+)$/.test(response?.data?.email)){
                        //   checkValidEmail(false);
                        // } else {
                        //   checkValidEmail(true);
                        // }
                        checkValidEmail(true);
                        setEmail(decryptId(response?.data?.emailaddress));
                        setBusinessSetting((prevData) => ({
                            ...prevData,
                            email_address: decryptId(response?.data?.emailaddress),
                        }));
                        setExcepByOrg((prevVal) => ({
                            ...prevVal,
                            bussiness_settings: {
                                ...prevVal?.bussiness_settings,
                                email_address: decryptId(response?.data?.emailaddress),
                            },
                        }));
                    }
                }
            });
    };
    return (
        <div className=" px-[20px]">
            {" "}
            <Accordion
                sx={{
                    "&.MuiPaper-root": {
                        boxShadow: currentMode == "dark" ? "" : "0px 4px 10px -3px rgb(140 144 151 / 50%) !important",
                        border: `1px solid ${currentMode == "dark" ? "#333" : "rgba(223, 225, 226, 0.56)"} !important`,
                    },
                }}
                expanded={expandEmailSet === "1"}
                onChange={handleChangeEmailSet("1")}
                className="border-[#DCFCE7]"
            >
                <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    className={`${expandEmailSet == 0 ? `bg-${currentMode}-card` : `bg-${currentMode}-1420`} rounded-[8px] text-${currentMode}-copy `}
                >
                    <img src="/images/Exception-Automation/email-settings.svg" className="convertWhite"></img>{" "}
                    <span className="font-interM ml-3 ">Email Settings</span>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="text-justify ml-[20px]">
                        <div className="grid grid-cols-1 gap-4">
                            <p className=" text-xs mt-[20px]">Select your webmail provider to send reminders.</p>
                        </div>
                        <div className="flex  space-x-4 mt-[20px]">
                            <GoogleLogin
                                clientId={clientId}
                                render={(renderProps) => (
                                    <div onClick={() => localStorage.setItem("configClick", true)}>
                                        <Button
                                            onClick={renderProps.onClick}
                                            className={` ${
                                                businessSetting?.email_type == 1
                                                    ? `bg-${currentMode}-1420 border ${
                                                          currentMode == "dark" ? "border-${currentMode}-1350" : "border-${currentMode}-1440"
                                                      } border-${currentMode}-1440`
                                                    : `border-${currentMode}-840`
                                            } px-7 py-[9px] rounded-[8px] font-interSb text-sm flex items-center border-[1px]  text-${currentMode}-copy`}
                                        >
                                            <img className="mr-2" src="/images/common/googleIcon.svg" alt="plus" />
                                            Google
                                        </Button>
                                    </div>
                                )}
                                onSuccess={onSuccess}
                                redirectUri={process.env?.REACT_APP_MS_REDIRECT_URL}
                                onFailure={onFailure}
                                cookiePolicy={"single_host_origin"}
                                accessType={"offline"}
                                prompt={"consent"}
                                responseType={"code"}
                                scope={
                                    "email profile openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/gmail.labels https://www.googleapis.com/auth/contacts.readonly https://mail.google.com/ https://www.googleapis.com/auth/pubsub"
                                    //"email profile https://mail.google.com/ https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/gmail.labels https://www.googleapis.com/auth/contacts.readonly"
                                }
                            />
                            <ReactLoginMS
                                clientId={msClientId}
                                redirectUri={process.env?.REACT_APP_MS_REDIRECT_URL + "/msAuthComplete.html"}
                                scopes={["Mail.ReadWrite", "MailboxSettings.ReadWrite", "Contacts.ReadWrite", "Mail.Send", "User.Read", "Mail.Read"]}
                                responseType="code"
                                cssClass={` ${
                                    businessSetting?.email_type == 2 ? `bg-[#EDFCF9] border border-${currentMode}-1440` : `border-${currentMode}-840`
                                } px-5 py-[9px] rounded-[8px] font-interSb text-sm flex items-center border-[1px] text-light-40`}
                                btnContent={btnContent}
                                handleLogin={(data) => loginMicrosoftHandler(data)}
                            />
                        </div>
                        <div className="flex mt-[20px] mb-[21px] space-x-2 viewStyles w-full">
                            <div className="basis-1/2">
                                <label className="block text-sm pb-3">Enter Name:</label>
                                <UserInputTrigger
                                    businessSetting={businessSetting}
                                    setBusinessSetting={setBusinessSetting}
                                    setExcepByOrg={setExcepByOrg}
                                />
                            </div>
                            <div className="basis-1/2">
                                <label className="block text-sm pb-3">Email:</label>
                                <Input
                                    disabled
                                    value={email}
                                    onChange={(e) => emailOnchange(e)}
                                    placeholder="Email Id"
                                    name="email_address"
                                    className={`border-[1px] w-[95%] border-${currentMode}-840 rounded-[8px]  py-[12px] px-[10px] bg-${currentMode}-810`}
                                />
                            </div>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default EmailSettings;
