import { Checkbox } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import Input from "../../../components/Input";
import Card from "../../../components/UI/Card";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import DraggableItem from "../../../organisms/DraggableItem";
import { useDispatch } from "react-redux";
import { setDroppedValues } from "../../../redux/actions/filter";
import _ from "lodash";
import { useSelector } from "react-redux";
import { showSwal } from "../../../utils/helpers";
import { LightTooltip } from "../../../components/LightTooltip";
import Loader from "../../../components/Loader";
import { GetColumnsList } from "../../../redux/actions/output-management";
import { ChartTble } from "./ChartTble";
import { Tabular } from "./Tabular";
import { MatrixTble } from "./MatrixTble";
export const ColumnTble = ({ formik, type }) => {
    const dispatch = useDispatch();
    const { currentMode } = useContext(ThemeContext); //Dark and light mode Custom Hook fetch details!
    const { columns, requestComplete, opened } = useSelector((state) => state?.getColumnState);
    const { droppedValues, subTtalValues, sortingValues, morefilterValues, fieldValues, labelValues, matrixColValues, rowsValues } = useSelector(
        (state) => state?.droppedState
    );
    const { preview } = useSelector((state) => state?.getPreviewState);
    const params = new URLSearchParams(window.location.search);
    const prevType = params.get("prev");
    const Datas = () => {
        if (prevType == 2) {
            return [...matrixColValues, ...rowsValues];
        } else if (prevType == 3) {
            return [...fieldValues, ...labelValues];
        } else {
            return droppedValues;
        }
    };
    useEffect(() => {
        const OrgId = localStorage.getItem("orgId");
        dispatch(GetColumnsList({ type: +type, orgid: +OrgId, droppedValues: Datas(), outputType: prevType }));
    }, [dispatch]); // This side effect columnlist api call and send dropped values on first load in edit page droppedValues....(note: dropped values sending purpose on first render checked checkbox default true...)
    const handleParentChange = (parentId, data) => (event) => {
        event?.stopPropagation();
        const isChecked = event.target.checked;
        // Dispatch your action here if needed
        if (prevType == 1) {
            dispatch(setDroppedValues(data, isChecked, "columns"));
        }
        dispatch(setDroppedValues(data, isChecked));
        // Update the content array with the new checked value for the specific parent
        setAccordionItems((prevItems) =>
            prevItems.map((item) =>
                item.id === parentId
                    ? {
                        ...item,
                        data: item?.data?.map((child) => ({
                            ...child,
                            checked: isChecked,
                        })),
                    }
                    : item
            )
        );
        // dispatch({ type: GET_COLUMN_UPDATE, payload: { data, isChecked } });

        setFilteredAccordionItems((prevItems) =>
            prevItems.map((item) =>
                item.id === parentId
                    ? {
                        ...item,
                        data: item?.data?.map((child) => ({
                            ...child,
                            checked: isChecked,
                        })),
                    }
                    : item
            )
        );
    };

    const handleChildChange = (parentId, childId, data) => (event) => {
        event.stopPropagation();
        const isChecked = event.target.checked;
        // Dispatch your action here if needed
        if (!isChecked) {
            dispatch({
                type: "DELETE_CHECKBOX_COLUMN",
                payload: data?.column_name,
            });
            dispatch({
                type: "DELETE_CHECKBOX_SUBTOTAL",
                payload: data?.column_name,
            });
            dispatch({
                type: "DELETE_CHECKBOX_SORTING",
                payload: data?.column_name,
            });
        }
        if (prevType == 2 && isChecked) {
            if (matrixColValues?.length < 1) {
                dispatch(setDroppedValues(data, isChecked, "matrixcolumns"));
            } else {
                dispatch(setDroppedValues(data, isChecked, "rows"));
                if (matrixColValues?.length < 1) {
                    showSwal(`Field should be allowed only once.`);
                    return false;
                }
            }
        } else {
            if (prevType == 2) {
                dispatch(setDroppedValues(data, isChecked, "matrixcolumns"));
                dispatch(setDroppedValues(data, isChecked, "rows"));
            }
        } // Above condition are used for matrix type restriction!...
        if (prevType == 3 && isChecked) {
            if (fieldValues?.length < 1) {
                if (data?.data_type == "n" || data?.data_type == "m" || data?.data_type == "p") {
                    dispatch(setDroppedValues(data, isChecked, "field"));
                } else {
                    showSwal(`Field should be a number type!`);
                    return false;
                }
            } else if (labelValues?.length < 1) {
                dispatch(setDroppedValues(data, isChecked, "label"));
                if (labelValues?.length > 1) {
                    showSwal(`Field should be allowed only once.`);
                    return false;
                }
            } else {
                showSwal(`Field should be allowed only once.`);
                return false;
            }
        } else {
            if (prevType == 3) {
                dispatch(setDroppedValues(data, isChecked, "field"));
                dispatch(setDroppedValues(data, isChecked, "label"));
            }
        } // Above condition are used for chart type restriction!...
        // dispatch(setDroppedValues(data, isChecked, "label"));
        if (prevType == 1) {
            dispatch(setDroppedValues(data, isChecked, "columns"));
        }
        setAccordionItems((prevItems) =>
            prevItems.map((item) =>
                item?.id === parentId
                    ? {
                        ...item,
                        data: item?.data?.map((child) => (child?.id === childId ? { ...child, checked: isChecked } : child)),
                    }
                    : item
            )
        );
        setFilteredAccordionItems((prevItems) =>
            prevItems.map((item) =>
                item?.id === parentId
                    ? {
                        ...item,
                        data: item?.data?.map((child) => (child?.id === childId ? { ...child, checked: isChecked } : child)),
                    }
                    : item
            )
        );
    };

    const [addItems, setAddItems] = useState({
        sorting: sortingValues?.length !== 0 ? true : false,
        filters: morefilterValues?.length !== 0 ? true : false,
        subtotal: subTtalValues?.length !== 0 ? true : false,
        preview: Object?.values(preview || {})?.length !== 0 ? true : false,
    }); //Add Item or filters based on click button defualt state

    const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
        border: "0 !important",
        background: "transparent",
        boxShadow: "none !important",
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
    }));
    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary
            expandIcon={
                <img
                    onClick={props?.onClick}
                    src={`${`${currentMode == "dark" ? "/images/header/chevronDown-white.svg" : "/images/invoice/chevron-down.svg"}`}`}
                />
            }
            {...props}
        />
    ))((data) => ({
        padding: "0 0 11px",
        minHeight: "35px",
        margin: 0,
        flexDirection: "row-reverse",
        "& .MuiAccordionSummary-expandIconWrapper": {
            marginRight: 10,
            position: "absolute",
            left: "28px",
            transform: "rotate(-270deg)",
            filter: `${currentMode === "dark" ? "filter: brightness(0) invert(1)" : "brightness(0) invert(0)"}`,
        },
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(360deg)",
            filter: "none",
        },
        "& .MuiAccordionSummary-content": {
            alignItems: "center",
            margin: 0,
        },
    }));
    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: 0,
        border: 0,
    }));

    const CardShadows = currentMode === "dark" ? "shadow-[0px_4px_20px_0px_rgba(0,0,0,0.33)]" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"; //Card Shadow assign top level
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredAccordionItems, setFilteredAccordionItems] = useState([]);
    const [accordionItems, setAccordionItems] = useState(columns);

    useEffect(() => {
        const updatedAccordionItems = accordionItems?.map((parentObj) => {
            const updatedContent = parentObj?.data?.map((contentObj) => {
                const matchingDroppedValue = Datas()?.find((droppedObj) => droppedObj?.column_name === contentObj?.column_name);
                if (matchingDroppedValue) {
                    // Update the checked property in the matching content object
                    return { ...contentObj, checked: true };
                }
                return contentObj;
            });

            return { ...parentObj, data: updatedContent };
        });
        // Update the state with the new array
        setAccordionItems(updatedAccordionItems);

        const updatedFilterItems = filteredAccordionItems?.map((parentObj) => {
            const updatedContent = parentObj?.data?.map((contentObj) => {
                const matchingDroppedValue = Datas()?.find((droppedObj) => droppedObj?.column_name === contentObj?.column_name);
                if (matchingDroppedValue) {
                    // Update the checked property in the matching content object
                    return { ...contentObj, checked: true };
                }
                return contentObj;
            });

            return { ...parentObj, data: updatedContent };
        });
        // Update the state with the new array
        setFilteredAccordionItems(updatedFilterItems);
    }, [droppedValues, morefilterValues, fieldValues, labelValues, matrixColValues, rowsValues]); //useEffect for on dragging fields to check the checkbox in sidebar columnlists...

    useEffect(() => {
        setAccordionItems(columns);
    }, [columns]);

    const filterAccordionItems = (text) => {
        const filteredResults = accordionItems
            ?.map((parent) => {
                const filteredData = parent?.data?.filter((item) => {
                    // Customize the filtering logic based on your requirements
                    return item?.field_display_name?.toLowerCase()?.includes(text?.toLowerCase());
                });

                // If at least one item passes the filter, include the parent object
                if (filteredData?.length > 0 || text === "") {
                    return {
                        ...parent,
                        data: filteredData,
                    };
                }

                // If no items pass the filter, exclude the parent object
                return null;
            })
            .filter(Boolean); // Filter out null values

        setFilteredAccordionItems(filteredResults);
    };
    const handleChange = (e) => {
        setSearchTerm(e.target.value);
        filterAccordionItems(e.target.value);
    };
    const { requestComplete: req } = useSelector((state) => state?.getRespondAddTableState);
    const IsChart = prevType == 3 && formik?.values?.chart_type == 0;
    const AccordionContent = ({ item, index, expanded, handleChange }) => {
        return (
            <Accordion className="unborderAccord" expanded={expanded?.includes(`panel${index}`)} onChange={handleChange} key={index}>
                <AccordionSummary aria-controls={`panel${item?.id}-content`} id={`panel${item?.id}-header`}>
                    <Checkbox
                        disabled={prevType == 3 || prevType == 2}
                        sx={{
                            padding: 0,
                            color: "rgba(85,85,85,0.70)",
                            marginRight: "10px",
                            "&.Mui-checked": {
                                color: "#028C78",
                            },
                            "&.MuiCheckbox-indeterminate": {
                                color: "#028C78",
                            },
                        }}
                        className="absolute left-0"
                        size="small"
                        checked={item?.data?.every((child) => child?.checked)}
                        indeterminate={!item?.data?.every((child) => child?.checked) && item?.data?.some((child) => child?.checked)}
                        onChange={handleParentChange(item?.id, item?.data)}
                    />

                    <span className="ml-[47px] trunc-1 font-interM max-w-[190px]">{item?.label_name}</span>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="ml-[1.65rem]">
                        {item?.data?.map((checkbox, index) => (
                            <DraggableItem key={index} value={checkbox}>
                                <div className="flex items-center mb-[10px]">
                                    <Checkbox
                                        disabled={IsChart}
                                        sx={{
                                            padding: 0,
                                            marginRight: "10px",
                                            color: "rgba(85,85,85,0.70)",
                                            "&.Mui-checked": {
                                                color: "#028C78",
                                            },
                                        }}
                                        size="small"
                                        checked={checkbox?.checked}
                                        onChange={handleChildChange(item?.id, checkbox?.id, checkbox)}
                                    />
                                    <LightTooltip title={checkbox?.field_display_name}>
                                        <span className="truncate max-w-[155px]">{checkbox?.field_display_name}</span>
                                    </LightTooltip>
                                </div>
                            </DraggableItem>
                        ))}
                    </div>
                </AccordionDetails>
            </Accordion>
        );
    };
    useEffect(() => {
        setExpandedPanels(opened || []);
    }, [opened]); // default on open all accrodion data set from reducer..
    const [expandedPanels, setExpandedPanels] = useState();
    const accordhandleChange = (panel) => (event, isExpanded) => {
        // Update the state to reflect the expanded state of the panels
        setExpandedPanels((prevExpanded) => {
            const newExpanded = [...prevExpanded];
            const panelIndex = newExpanded.indexOf(panel);

            if (isExpanded && panelIndex === -1) {
                newExpanded.push(panel);
            } else if (!isExpanded && panelIndex !== -1) {
                newExpanded.splice(panelIndex, 1);
            }

            return newExpanded;
        });
    };

    return (
        <div className="xl:flex space-y-[20px] xl:space-y-0 xl:space-x-[20px]">
            <div className="basis-[27.8%] xl:max-w-[278px]">
                <Card className={`!rounded-r-[0] xl:border-r rounded-l-[0] xl:rounded-r-[10px] min-h-[700px]  border-x-[0] h-full ${CardShadows}`}>
                    <div className={`bg-${currentMode}-1210 py-[10px] xl:rounded-tr-[10px] px-[20px]`}>
                        <h5>Select/Drag and Drop the columns</h5>
                    </div>
                    <div className="px-[20px]">
                        <div
                            className={`bg-${currentMode}-230 flex border-${currentMode}-560 border items-center justify-between my-[20px] py-[8px] px-[16px] min-w-[205px] rounded-[8px]`}>
                            <div className="flex-auto">
                                <Input
                                    placeholder="Search..."
                                    name="search"
                                    className="border-0 max-w-[180px] bg-transparent"
                                    disableUnderline={true}
                                    maxLength={250}
                                    onChange={handleChange}
                                    value={searchTerm}
                                    autoComplete="off"
                                />
                            </div>
                            <div className="w-[16px]">
                                {searchTerm && searchTerm.length !== 0 ? (
                                    <img
                                        onClick={() => {
                                            setSearchTerm("");
                                            setFilteredAccordionItems([]);
                                        }}
                                        className="cursor-pointer"
                                        src="/images/common/closeBlk.svg"
                                        alt="close"
                                    />
                                ) : (
                                    <img className="w-[20px]" src="/images/header/search.svg" alt="search" />
                                )}
                            </div>
                        </div>
                    </div>

                    <div className={`overflow-y-auto max-h-[35.5rem] px-[20px] py-[15px] border-t border-${currentMode}-1240`}>
                        {requestComplete || req ? (
                            filteredAccordionItems?.length == 0 && searchTerm == "" ? (
                                accordionItems?.map((item, i) => (
                                    <AccordionContent
                                        key={i}
                                        item={item}
                                        index={i}
                                        data={accordionItems}
                                        expanded={expandedPanels}
                                        handleChange={accordhandleChange(`panel${i}`)}
                                    />
                                ))
                            ) : filteredAccordionItems?.length !== 0 ? (
                                filteredAccordionItems?.map((item, i) => (
                                    <AccordionContent
                                        key={i}
                                        item={item}
                                        data={filteredAccordionItems}
                                        expanded={expandedPanels}
                                        handleChange={accordhandleChange(`panel${i}`)}
                                        index={i}
                                    />
                                ))
                            ) : (
                                <p className="text-base text-center">No search results found!</p>
                            )
                        ) : (
                            <Loader className="!h-[15rem]" />
                        )}
                    </div>
                </Card>
            </div>
            <div className="basis-[100%] overflow-hidden">
                <Card
                    className={`!rounded-l-[0] xl:border-l max-h-[700px] overflow-y-auto rounded-r-[0] xl:rounded-l-[10px]  border-x-[0] h-full ${CardShadows}`}>
                    {requestComplete || req ? (
                        <>
                            {prevType == 3 && (
                                <ChartTble
                                    formik={formik}
                                    type={type}
                                    prevtype={prevType}
                                    currentMode={currentMode}
                                    addItems={addItems}
                                    setAddItems={setAddItems}
                                    filteredAccordionItems={filteredAccordionItems}
                                    accordionItems={accordionItems}
                                    setFilteredAccordionItems={setFilteredAccordionItems}
                                    setAccordionItems={setAccordionItems}
                                />
                            )}
                            {prevType == 2 && (
                                <MatrixTble
                                    formik={formik}
                                    type={type}
                                    prevtype={prevType}
                                    currentMode={currentMode}
                                    addItems={addItems}
                                    setAddItems={setAddItems}
                                    filteredAccordionItems={filteredAccordionItems}
                                    accordionItems={accordionItems}
                                    setFilteredAccordionItems={setFilteredAccordionItems}
                                    setAccordionItems={setAccordionItems}
                                />
                            )}
                            {prevType != 3 && prevType != 2 && (
                                <Tabular
                                    type={type}
                                    prevtype={prevType}
                                    addItems={addItems}
                                    accordionItems={accordionItems}
                                    setAccordionItems={setAccordionItems}
                                    setAddItems={setAddItems}
                                    currentMode={currentMode}
                                    setFilteredAccordionItems={setFilteredAccordionItems}
                                    filteredAccordionItems={filteredAccordionItems}
                                />
                            )}
                        </>
                    ) : (
                        <Loader />
                    )}
                </Card>
            </div>
        </div>
    );
};
