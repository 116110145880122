import _ from "lodash";
import update from "immutability-helper";
import { CART_ACTION, ORG_TOGGLE_ACTION, USER_TOGGLE_ACTION } from "../types/types";

export function cartToggleReducer(state = false, action) {
    switch (action.type) {
        case CART_ACTION:
            return action.payload;
        default:
            return state;
    }
}

export function addUserToggleReducer(state = false, action) {
    switch (action.type) {
        case USER_TOGGLE_ACTION:
            return action.payload;
        default:
            return state;
    }
}

export function addOrgToggleReducer(state = false, action) {
    switch (action.type) {
        case ORG_TOGGLE_ACTION:
            return action.payload;
        default:
            return state;
    }
}
const initState = {
    storeFilter: [],
};
export function checkFilterReducer(state = initState, action) {
    switch (action.type) {
        case "CHECK_FILTER_ADDED":
            return { storeFilter: [action.payload] };
        case "CHECK_FILTER_ADDED_RESET":
            return { storeFilter: [] };
        default:
            return state;
    }
}

//  Below func is remove common object set of two different arrays Logigs => (if user click the parent checkobox on toggle need to adda nd remove from Columns target Area )
function removeCommonObjects(arr1, arr2) {
    // Convert the arrays to Sets for efficient comparison based on the "label" property
    const set1 = new Set(arr1?.map((item) => item?.column_name));
    const set2 = new Set(arr2?.map((item) => item?.column_name));

    // Get the difference between the two sets
    const difference = new Set([...set1].filter((label) => !set2?.has(label)));

    // Convert the difference set back to an array
    const resultArray = arr1?.filter((item) => difference.has(item?.column_name));
    let reOrder = resultArray?.map((el, i) => ({ ...el, Order: i + 1 }));

    return reOrder;
}
const initialState = {
    droppedValues: [], //column
    subTtalValues: [], //subtotal
    sortingValues: [], //sorting
    morefilterValues: [], //more filter
    fieldValues: [], //field
    labelValues: [], //label
    matrixColValues: [], //matrix column
    rowsValues: [], //matrix column
    jsonValues: [], //json
    xmlValues: [], //xml
    jsonData: [
        {
            id: 1,
            label: "",
            content: "",
            isChild: false,
            isArray: false,
            parent_id: "",
            isDisable: false,
            indent: 1,
            Type: "json",
        },
    ],
    xmlData: [
        {
            id: 1,
            label: "",
            content: "",
            isChild: false,
            isElement: false,
            isAttribute: false,
            isText: false,
            Type: "xml",
            parent_id: "",
            indent: 1,
        },
    ],
    viewData: {},
};
// Use map to create a new array with the updated object
const updatedState = (state, action, Id, update) =>
    state?.map((obj) => {
        if (obj.id === Id) {
            // Use _.cloneDeep to create a deep copy and avoid mutating the original object
            const clonedObj = _.cloneDeep(obj);
            // Update the "Filter" array with the new values
            clonedObj.Filter = [action];
            if (update) {
                clonedObj.isFilter = update;
            } else {
                delete clonedObj.isFilter;
            }
            return clonedObj;
        }
        return obj;
    }); // This Func used for filter data updated and this one job is check the id  and deepclone the original array update the object particular id form paylod
const updatedData = (state, action, Id, col) =>
    _.map(state, (item) => {
        // Check if the current item's id matches the action's Id
        if (item.id === Id) {
            // If yes, update the Agg property with the payload value
            return { ...item, [col]: action };
        }
        // If not, return the original item unchanged
        return item;
    }); // This func update the original array when user Edit or update the Aggregate

// Below Reducer func is store the value after drag and drop after the target area!!
export function setDroppedReducer(state = initialState, action) {
    // Below func is global for all Columns Logics => (Store the values after dragged into the target based on the columns)
    const handleToggle = (stateKey, value, isChecked, type) => {
        const newState = { ...state };

        if (isChecked) {
            // Update the Type property inside the value object
            const updatedValue = { ...value, Type: type, checked: isChecked, Sorting: type == "sorting" ? "asc" : `` };
            const update = Array.isArray(value) && value?.map((el, i) => ({ ...el, Type: type, Order: i + 1, checked: isChecked }));
            const lastIndex = _.findLastIndex(newState[stateKey], (el) => el?.Order !== undefined);

            newState[stateKey] = [
                ...newState[stateKey],
                ...(Array.isArray(value) ? update : [updatedValue]?.map((el, i) => ({ ...el, Order: lastIndex + 1 + 1 }))),
            ];
        } else {
            // Remove the value if unchecked
            newState[stateKey] = removeCommonObjects(newState[stateKey], Array.isArray(value) ? value : [value]);
        }

        return newState;
    };

    switch (action.type) {
        case "TOGGLE_CHECKBOX_COLUMN":
            const { value: columnValue, isChecked: columnCheck, type } = action.payload;
            return handleToggle("droppedValues", columnValue, columnCheck, type);
        case "TOGGLE_CHECKBOX_MATRIX_ROWS":
            const { value: rowsVal, isChecked: rowsCheck, type: rowsType } = action.payload;
            return handleToggle("rowsValues", rowsVal, rowsCheck, rowsType);
        case "TOGGLE_CHECKBOX_MATRIX_COLUMN":
            const { value: matrixVal, isChecked: matrixCheck, type: matrixType } = action.payload;
            return handleToggle("matrixColValues", matrixVal, matrixCheck, matrixType);
        case "TOGGLE_CHECKBOX_FIELD":
            const { value: fieldVal, isChecked: fieldCheck, type: fieldType } = action.payload;
            return handleToggle("fieldValues", fieldVal, fieldCheck, fieldType);
        case "TOGGLE_CHECKBOX_LABEL":
            const { value: labelVal, isChecked: labelCheck, type: labelType } = action.payload;
            return handleToggle("labelValues", labelVal, labelCheck, labelType);
        case "TOGGLE_CHECKBOX_JSON":
            const { value: jsonValue, isChecked: jsonCheck, jsontype } = action.payload;
            return handleToggle("jsonValues", jsonValue, jsonCheck, jsontype);
        case "TOGGLE_CHECKBOX_XML":
            const { value: xmlValue, isChecked: xmlCheck, xmltype } = action.payload;
            return handleToggle("xmlValues", xmlValue, xmlCheck, xmltype);
        case "UPDATE_COLUMN_AGG":
            return {
                ...state,
                droppedValues: updatedData(state?.droppedValues, action?.payload, action?.Id, action?.label),
            };
        case "UPDATE_FIELD_AGG":
            return {
                ...state,
                fieldValues: updatedData(state?.fieldValues, action?.payload, action?.Id, action?.label),
            };
        case "UPDATE_LABEL_AGG":
            return {
                ...state,
                labelValues: updatedData(state?.labelValues, action?.payload, action?.Id, action?.label),
            };
        case "UPDATE_MATRIXCOL_AGG":
            return {
                ...state,
                matrixColValues: updatedData(state?.matrixColValues, action?.payload, action?.Id, action?.label),
            };
        case "UPDATE_ROWS_AGG":
            return {
                ...state,
                rowsValues: updatedData(state?.rowsValues, action?.payload, action?.Id, action?.label),
            };

        case "MOVE_THE_CARD":
            const { card, index, atIndex, name } = action.payload;
            // if (name == "columns" || ) {
            const reOrder = update(state?.[name], {
                $splice: [
                    [index, 1],
                    [atIndex, 0, card],
                ],
            });
            let addOrderIndex = reOrder?.map((el, i) => ({ ...el, Order: i + 1 }));
            return {
                ...state,
                [name]: addOrderIndex,
            };
        // }
        case "UPDATE_SORTING":
            return {
                ...state,
                sortingValues: updatedData(state?.sortingValues, action?.payload, action?.Id, action?.label),
                droppedValues: updatedData(state?.droppedValues, action?.payload, action?.Id, action?.label),
            };
        case "UPDATE_COLUMNS":
            return {
                ...state,
                droppedValues: updatedState(state?.droppedValues, action?.payload, action?.Id, action?.update),
            };
        case "UPDATE_FIELD":
            return {
                ...state,
                fieldValues: updatedState(state?.fieldValues, action?.payload, action?.Id, action?.update),
            };
        case "UPDATE_LABEL":
            return {
                ...state,
                labelValues: updatedState(state?.labelValues, action?.payload, action?.Id, action?.update),
            };

        case "UPDATE_MORE_FILTER":
            return {
                ...state,
                morefilterValues: updatedState(state?.morefilterValues, action?.payload, action?.Id, action?.update),
            };
        case "UPDATE_MATRIX_COLUMNS_FILTER":
            return {
                ...state,
                matrixColValues: updatedState(state?.matrixColValues, action?.payload, action?.Id, action?.update),
            };
        case "UPDATE_ROWS_FILTER":
            return {
                ...state,
                rowsValues: updatedState(state?.rowsValues, action?.payload, action?.Id, action?.update),
            };
        case "DELETE_CHECKBOX_COLUMN":
            return {
                ...state,
                droppedValues: state?.droppedValues?.filter((item) => item?.column_name !== action.payload),
            };
        case "DELETE_CHECKBOX_FIELD":
            return {
                ...state,
                fieldValues: state?.fieldValues?.filter((item) => item?.column_name !== action.payload),
            };
        case "DELETE_CHECKBOX_LABEL":
            return {
                ...state,
                labelValues: state?.labelValues?.filter((item) => item?.column_name !== action.payload),
            };
        case "DELETE_CHECKBOX_SUBTOTAL":
            return {
                ...state,
                subTtalValues: state?.subTtalValues?.filter((item) => item?.column_name !== action.payload),
            };
        case "DELETE_CHECKBOX_SORTING":
            return {
                ...state,
                sortingValues: state?.sortingValues?.filter((item) => item?.column_name !== action.payload),
            };
        case "DELETE_CHECKBOX_MATRIX_COLUMN":
            return {
                ...state,
                matrixColValues: state?.matrixColValues?.filter((item) => item?.column_name !== action.payload),
            };
        case "DELETE_CHECKBOX_MATRIX_ROWS":
            return {
                ...state,
                rowsValues: state?.rowsValues?.filter((item) => item?.column_name !== action.payload),
            };
        case "DELETE_CHECKBOX_MORE_FILTER":
            return {
                ...state,
                morefilterValues: state?.morefilterValues?.filter((item) => item?.column_name !== action.payload),
            };
        case "DELETE_JSON_ROW":
            return {
                ...state,
                jsonData: state?.jsonData?.filter((item) => item?.id != action.payload),
            };
        case "DELETE_XML_ROW":
            return {
                ...state,
                xmlData: state?.xmlData?.filter((item) => item?.id != action?.payload),
            };
        case "TOGGLE_CHECKBOX_SUBTOTAL":
            const { value: subTtlValue, isChecked: subTtlCheck, type: subTtlCol } = action.payload;
            return handleToggle("subTtalValues", subTtlValue, subTtlCheck, subTtlCol);

        case "TOGGLE_CHECKBOX_SORTING":
            const { value: sortingValue, isChecked: sortCheck, type: sortCol } = action.payload;
            return handleToggle("sortingValues", sortingValue, sortCheck, sortCol);

        case "TOGGLE_CHECKBOX_MORE_FILTER":
            const { value: morefilterValue, isChecked: filtCheck, type: filterCol } = action.payload;
            return handleToggle("morefilterValues", morefilterValue, filtCheck, filterCol);
        case "ADD_ROW":
            return {
                ...state,
                jsonData: [
                    ...state?.jsonData,
                    {
                        id: state?.jsonData?.length + 1,
                        label: "",
                        content: "",
                        isChild: false,
                        isArray: false,
                        isDisable: false,
                        parent_id: "",
                        indent: 1,
                        Type: "json",
                    },
                ],
            };
        case "ADD_XML_ROW":
            return {
                ...state,
                xmlData: [
                    ...state?.xmlData,
                    {
                        id: state?.xmlData?.length + 1,
                        label: "",
                        content: "",
                        isChild: false,
                        isElement: false,
                        isAttribute: false,
                        isText: false,
                        parent_id: "",
                        indent: 1,
                        Type: "xml",
                    },
                ],
            };
        case "INSERT_ROW":
            const parentId = action?.payload?.isIndex;
            const indexs = _.findIndex(state?.jsonData, { id: parentId });
            const parentIndexes = [];
            // Find all indexes of parent objects with the given parent_id
            state?.jsonData?.forEach((item, index) => {
                if (item?.parent_id === parentId) {
                    parentIndexes.push(index);
                }
            });
            const lastpIndex = parentIndexes[parentIndexes.length - 1];
            if (indexs !== -1) {
                // Clone the parent object and update the isChild property
                const updatedParent = { ...state?.jsonData[indexs], isChild: true };

                // Create a new array with the updated parent object
                const updatedDatas = [...state?.jsonData.slice(0, indexs), updatedParent, ...state?.jsonData?.slice(indexs + 1)];

                // Generate a new ID for the new object
                const newId = state?.jsonData?.length + 1;

                // Create the new object to insert
                const newContent = state?.jsonData[indexs]?.content; // Preserve the existing content and update ID
                const newObject = {
                    id: newId,
                    label: "",
                    content: "", // Use the updated content
                    parent_id: parentId,
                    isChild: false,
                    isDisable: false,
                    isArray: false,
                    Type: "json",
                    indent: action?.payload?.int + 1,
                };

                // Reorder the content based on the previous ID
                const reorderedContent = updatedDatas
                    .filter((item) => item.id <= newId) // Get items up to the new ID
                    .map((item) => item.content); // Get the content of each item
                // Insert the new object after the parent object
                updatedDatas.splice(lastpIndex != undefined ? lastpIndex + 1 : indexs + 1, 0, newObject); //lastpIndex != undefined ? lastpIndex + 1 : indexs + 1 this code is check parent_id have multiple child..

                // Insert the new content at the appropriate index
                reorderedContent.splice(lastpIndex != undefined ? lastpIndex + 1 : indexs + 1, 0, newContent);

                // Update the content of each item with the reordered content
                updatedDatas?.forEach((item, index) => {
                    item.content = reorderedContent[index]; // Update the content
                    item.id = index + 1; // Reorder the IDs
                    item.parent_id =
                        lastpIndex != undefined
                            ? item?.parent_id > lastpIndex
                                ? item.parent_id + 1
                                : item.parent_id
                            : item?.parent_id > parentId
                            ? item.parent_id + 1
                            : item.parent_id;
                });

                return {
                    ...state,
                    jsonData: updatedDatas,
                };
            }
            return state;
        case "MAKE_PARENT_JSON":
            const Addparent = action?.payload;
            const getindex = _.findIndex(state?.jsonData, { id: Addparent });
            const parentIndexe = [];
            // Find all indexes of parent objects with the given parent_id
            state?.jsonData?.forEach((item, index) => {
                if (item?.parent_id === Addparent) {
                    parentIndexe.push(index);
                }
            });
            const lastpInde = parentIndexe[parentIndexe.length - 1];
            if (getindex !== -1) {
                // Clone the parent object and update the isChild property
                const updatedParent = { ...state?.jsonData[getindex], isChild: true, content: "", isArray: true };
                const updatedDatas = [...state?.jsonData.slice(0, getindex), updatedParent, ...state?.jsonData.slice(getindex + 1)];
                // Reorder the IDs
                const reorderedData = updatedDatas?.map((item, index) => ({
                    ...item,
                    id: index + 1,
                    parent_id: item?.parent_id > lastpInde ? item.parent_id + 1 : item.parent_id,
                }));
                return {
                    ...state,
                    jsonData: reorderedData,
                };
            }
            return state;
        case "UPDATE_ISARRAY":
            // Extract payload from action
            const { id, isArray } = action?.payload;
            const UpdateArray = _.map(_.cloneDeep(state?.jsonData), (item) => {
                if (item?.id === id) {
                    return { ...item, isArray: isArray };
                }
                return item;
            });
            return {
                ...state,
                jsonData: UpdateArray,
            };

        case "ADD_NEW_PARENT_JSON":
            const parentIds = action?.payload?.isIndex;
            const isParent = action?.payload?.parent;
            const lastIndex = state?.jsonData.map((item) => item?.parent_id).lastIndexOf(parentIds); // Get the index of the last element with parent_id
            if (lastIndex !== -1) {
                // Generate a new ID for the new object
                const newId = state?.jsonData?.length + 1;

                // Create the new object to insert
                const newObject = {
                    id: newId,
                    label: "",
                    content: "",
                    parent_id: isParent == "" ? "" : isParent,
                    isChild: true,
                    isArray: true,
                    isDisable: false,
                    indent: isParent == "" ? 1 : action?.payload?.int,
                    Type: "json",
                };

                // Insert the new object after the last parent with parent_id
                const updatedDatas = [
                    ...state?.jsonData.slice(0, lastIndex + 1), // Add new object after the last parent with parent_id
                    newObject,
                    ...state?.jsonData.slice(lastIndex + 1),
                ];

                // Reorder the IDs
                const reorderedData = updatedDatas?.map((item, index) => ({
                    ...item,
                    id: index + 1,
                    parent_id: item?.parent_id > lastIndex ? item.parent_id + 1 : item.parent_id,
                }));

                return {
                    ...state,
                    jsonData: reorderedData,
                };
            } else {
                // If lastIndex is -1, it means there are no objects with the given parent_id.
                // Insert the new object below the parentIds object.
                const insertionIndex = state?.jsonData?.findIndex((item) => item?.id === parentIds);
                if (insertionIndex !== -1) {
                    // Generate a new ID for the new object
                    const newId = state?.jsonData?.length + 1;

                    // Create the new object to insert
                    const newObject = {
                        id: newId,
                        label: "",
                        content: "",
                        parent_id: isParent == "" ? "" : isParent, // Set parent_id to parentIds
                        isChild: true,
                        isArray: true,
                        isDisable: false,
                        indent: isParent == "" ? 1 : action?.payload?.int, // Indent as a child of parentIds
                        Type: "json",
                    };

                    const updatedDatas = [
                        ...state?.jsonData.slice(0, insertionIndex + 1), // Add new object below parentIds object
                        newObject,
                        ...state?.jsonData.slice(insertionIndex + 1),
                    ];

                    // Reorder the IDs
                    const reorderedData = updatedDatas?.map((item, index) => ({
                        ...item,
                        id: index + 1,
                        parent_id: item?.parent_id > insertionIndex ? item.parent_id + 1 : item.parent_id,
                    }));

                    return {
                        ...state,
                        jsonData: reorderedData,
                    };
                }
            }
            return state;
        case "ADD_NEW_ELEMENT_XML":
            const parent = action?.payload?.isIndex;
            const CheckFirst = action?.payload?.parent;
            const lastInd = _.findIndex(state?.xmlData, { id: parent });
            const parentIndex = [];
            // Find all indexes of parent objects with the given parent_id
            state?.xmlData?.forEach((item, index) => {
                if (item?.parent_id === parent) {
                    parentIndex.push(index);
                }
            });
            const lastp = parentIndex[parentIndex.length - 1];

            if (lastInd !== -1) {
                const updatedParents = { ...state?.xmlData[lastInd], isElement: true, isChild: true, content: "" };
                // Generate a new ID for the new object
                const newId = state?.xmlData?.length + 1;
                const updatedxml = [...state?.xmlData.slice(0, lastInd), updatedParents, ...state?.xmlData?.slice(lastInd + 1)];

                // Create the new object to insert
                const newObject = {
                    id: newId,
                    label: "",
                    content: "",
                    isChild: false,
                    isElement: false,
                    isAttribute: false,
                    isText: false,
                    parent_id: parent,
                    indent: action?.payload?.int + 1,
                    Type: "xml",
                };
                const CheckIs = state?.xmlData?.filter((el) => el?.parent_id == "");
                // Create the new object to insert
                const newContent = !CheckIs ? state?.xmlData[lastInd]?.content : updatedxml[lastInd]?.content; // Preserve the existing content and update ID

                // Reorder the content based on the previous ID
                const reorderedContent = updatedxml
                    .filter((item) => item.id <= newId) // Get items up to the new ID
                    .map((item) => item.content); // Get the content of each item
                // Insert the new object after the parent object
                if (CheckFirst != "") {
                    updatedxml.splice(lastp != undefined ? lastp + 1 : lastInd + 1, 0, newObject); //lastInd != undefined ? lastInd + 1 : lastInd + 1 this code is check parent_id have multiple child..

                    // Insert the new content at the appropriate index
                    reorderedContent.splice(lastp != undefined ? lastp + 1 : lastInd + 1, 0, newContent);
                } else {
                    updatedxml?.push(newObject); //lastInd != undefined ? lastInd + 1 : lastInd + 1 this code is check parent_id have multiple child..

                    // Insert the new content at the appropriate index
                    reorderedContent.push(newContent);
                }

                // Update the content of each item with the reordered content
                updatedxml?.forEach((item, index) => {
                    item.content = reorderedContent[index]; // Update the content
                    item.id = index + 1; // Reorder the IDs
                    item.parent_id =
                        lastp != undefined
                            ? item?.parent_id > lastp
                                ? item?.parent_id + 1
                                : item?.parent_id
                            : item?.parent_id > parent
                            ? item?.parent_id + 1
                            : item?.parent_id;
                });

                return {
                    ...state,
                    xmlData: updatedxml,
                };
            }

            return state;
        case "ADD_NEW_ATTRIBUTE_XML":
            const parentID = action?.payload?.isIndex;
            const checkAttrParent = action?.payload?.parent;
            const getIndex = _.findIndex(state?.xmlData, { id: parentID });
            const parentsIndexed = [];
            // Find all indexes of parent objects with the given parent_id
            state?.xmlData?.forEach((item, index) => {
                if (item?.parent_id === parentID) {
                    parentsIndexed.push(index);
                }
            });
            const lastIndexs = parentsIndexed[parentsIndexed.length - 1];
            if (getIndex !== -1) {
                const updatedParents = { ...state?.xmlData[getIndex], isChild: true };
                const updatedxml = [...state?.xmlData.slice(0, getIndex), updatedParents, ...state?.xmlData?.slice(getIndex + 1)];
                // Generate a new ID for the new object
                const newId = state?.xmlData?.length + 1;
                const CheckIs = state?.xmlData?.filter((el) => el?.parent_id == "");
                // Create the new object to insert
                const newContent = !CheckIs ? state?.xmlData[getIndex]?.content : updatedxml[getIndex]?.content; // Preserve the existing content and update ID
                const newObject = {
                    id: newId,
                    label: "",
                    content: "", // Use the updated content
                    parent_id: parentID,
                    isChild: false,
                    isElement: false,
                    isAttribute: true,
                    isText: false,
                    Type: "xml",
                    indent: action?.payload?.int + 1,
                };

                // Reorder the content based on the previous ID
                const reorderedxml = updatedxml
                    ?.filter((item) => item?.id <= newId) // Get items up to the new ID
                    ?.map((item) => item?.content); // Get the content of each item
                // Insert the new object after the parent object
                if (checkAttrParent != "") {
                    updatedxml?.splice(lastIndexs != undefined ? lastIndexs + 1 : getIndex + 1, 0, newObject); //lastIndexs != undefined ? lastIndexs + 1 : getIndex + 1 this code is check parent_id have multiple child..
                    // Insert the new content at the appropriate index
                    reorderedxml?.splice(lastIndexs != undefined ? lastIndexs + 1 : getIndex + 1, 0, newContent);
                } else {
                    updatedxml?.push(newObject); //lastIndexs != undefined ? lastIndexs + 1 : getIndex + 1 this code is check parent_id have multiple child..
                    // Insert the new content at the appropriate index
                    reorderedxml?.push(newContent);
                }

                // Update the content of each item with the reordered content
                updatedxml?.forEach((item, index) => {
                    item.content = reorderedxml[index]; // Update the content
                    item.id = index + 1; // Reorder the IDs
                    // item.parent_id =
                    //     lastIndexs != undefined
                    //         ? item?.parent_id > lastIndexs
                    //             ? item?.parent_id + 1
                    //             : item?.parent_id
                    //         : item?.parent_id > parentID
                    //         ? item?.parent_id + 1
                    //         : item?.parent_id;
                });
                // console.table(updatedxml, "attribute");

                return {
                    ...state,
                    xmlData: updatedxml,
                };
            }
            return state;
        case "ADD_NEW_TEXT_XML":
            const parent_ID = action?.payload?.isIndex;
            const checkParent = action?.payload?.parent;
            const getCurrentIndex = _.findIndex(state?.xmlData, { id: parent_ID });
            const parentsInd = [];
            // Find all indexes of parent objects with the given parent_id
            state?.xmlData?.forEach((item, index) => {
                if (item?.parent_id === parent_ID) {
                    parentsInd.push(index);
                }
            });
            const lastInde = parentsInd[parentsInd.length - 1];

            if (getCurrentIndex !== -1) {
                const updatedParents = { ...state?.xmlData[getCurrentIndex], isText: true, isChild: true, content: "" };
                const updatedxml = [...state?.xmlData.slice(0, getCurrentIndex), updatedParents, ...state?.xmlData?.slice(getCurrentIndex + 1)];
                // Generate a new ID for the new object
                const newId = state?.xmlData?.length + 1;
                const CheckIs = state?.xmlData?.filter((el) => el?.parent_id == "");
                // Create the new object to insert
                const newContent = !CheckIs ? state?.xmlData[getCurrentIndex]?.content : updatedxml[getCurrentIndex]?.content; // Preserve the existing content and update ID
                const newObject = {
                    id: newId,
                    label: "",
                    content: "", // Use the updated content
                    parent_id: parent_ID,
                    isChild: false,
                    isElement: false,
                    isAttribute: true,
                    isText: true,
                    Type: "xml",
                    indent: action?.payload?.int + 1,
                };

                // Reorder the content based on the previous ID
                const reorderedxml = updatedxml
                    ?.filter((item) => item?.id <= newId) // Get items up to the new ID
                    ?.map((item) => item?.content); // Get the content of each item
                // Insert the new object after the parent object
                if (checkParent != "") {
                    updatedxml?.splice(lastInde != undefined ? lastInde + 1 : getCurrentIndex + 1, 0, newObject); //lastInde != undefined ? lastInde + 1 : lastInde + 1 this code is check parent_id have multiple child..
                    // Insert the new content at the appropriate index
                    reorderedxml?.splice(lastInde != undefined ? lastInde + 1 : getCurrentIndex + 1, 0, newContent);
                } else {
                    updatedxml?.push(newObject); //lastInde != undefined ? lastInde + 1 : lastInde + 1 this code is check parent_id have multiple child..
                    // Insert the new content at the appropriate index
                    reorderedxml?.push(newContent);
                }

                // Update the content of each item with the reordered content
                updatedxml?.forEach((item, index) => {
                    item.content = reorderedxml[index]; // Update the content
                    item.id = index + 1; // Reorder the IDs
                    item.parent_id =
                        lastInde != undefined
                            ? item?.parent_id > lastInde
                                ? item?.parent_id + 1
                                : item?.parent_id
                            : item?.parent_id > parent_ID
                            ? item?.parent_id + 1
                            : item?.parent_id;
                });

                return {
                    ...state,
                    xmlData: updatedxml,
                };
            }
            break;
        case "UPDATE_ROW_CONTENT":
            const { itemValue, index: inde, id: isId } = action?.payload;
            const newData = [...state?.jsonData];
            newData[inde] = { ...newData[inde], content: itemValue };
            const ParentUpdate = newData?.map((item) => {
                if (itemValue?.multiple_objects == true && item?.id === isId) {
                    return {
                        ...item,
                        isDisable: itemValue?.multiple_objects,
                    };
                }
                return item;
            });
            return {
                ...state,
                jsonData: ParentUpdate,
            };
        case "UPDATE_XML_ROW_CONTENT":
            const { itemValue: itemValues, index: indexes } = action?.payload;
            const newDatas = [...state?.xmlData];
            newDatas[indexes] = { ...newDatas[indexes], content: itemValues };

            return {
                ...state,
                xmlData: newDatas,
            };

        case "UPDATE_JSON_ROWS":
            let updated = state?.jsonData?.map((item, index) =>
                index === action?.payload?.index ? { ...item, [action?.payload?.field]: action?.payload?.value } : item
            );
            const ParentUpdat = updated?.map((item) => {
                if (action?.payload?.value == "" && item?.id === action?.payload?.parentid) {
                    return {
                        ...item,
                        isDisable: action?.payload?.checkIsarray ? true : false,
                    };
                }
                return item;
            });

            return {
                ...state,
                jsonData: ParentUpdat,
            };
        case "UPDATE_XML_ROWS":
            let updating = state?.xmlData?.map((item, index) =>
                index === action?.payload?.index ? { ...item, [action?.payload?.field]: action?.payload?.value } : item
            );

            return {
                ...state,
                xmlData: updating,
            };
        case "TABLER_REPORTS_VIEW":
            let { payload } = action;
            // Separate array based on the "Type" value
            const separatedArrays = payload?.[0]?.field_info?.reduce((acc, obj) => {
                const type = obj.Type;
                // Create an array for the type if it doesn't exist
                if (!acc[type]) {
                    acc[type] = [];
                }
                // Push the object to the corresponding type array
                acc[type].push(obj);
                return acc;
            }, {});
            return {
                ...state,
                droppedValues: separatedArrays?.columns || [],
                subTtalValues: separatedArrays?.subtotal || [],
                sortingValues: separatedArrays?.sorting || [],
                morefilterValues: separatedArrays?.morefilter || [],
                labelValues: separatedArrays?.label || [],
                fieldValues: separatedArrays?.field || [],
                matrixColValues: separatedArrays?.matrixcolumns || [],
                rowsValues: separatedArrays?.rows || [],
                jsonData: separatedArrays?.json || [],
                viewData: payload?.[0] || {},
            };
        case "TOGGLE_CHECKBOX_RESET":
            return {
                ...state,
                droppedValues: [],
                subTtalValues: [],
                sortingValues: [],
                labelValues: [],
                fieldValues: [],
                morefilterValues: [],
                matrixColValues: [],
                rowsValues: [],
                jsonData: [
                    {
                        id: 1,
                        label: "",
                        content: "",
                        Type: "json",
                        isChild: false,
                        isArray: false,
                        isDisable: false,
                        parent_id: "",
                        indent: 1,
                    },
                ],
                xmlData: [
                    {
                        id: 1,
                        label: "",
                        content: "",
                        isChild: false,
                        isElement: false,
                        isAttribute: false,
                        isText: false,
                        Type: "xml",
                        parent_id: "",
                        indent: 1,
                    },
                ],
                viewData: {},
            };
        case "VIEW_DATA_RESET":
            return {
                ...state,
                viewData: {},
            };
        case "CHART_FIELD_LABEL_RESET":
            return {
                ...state,
                labelValues: [],
                fieldValues: [],
            };

        default:
            return state;
    }
}

export function storeTablerReportReducer(state = { Reprt: {} }, action) {
    switch (action.type) {
        case "STORE_REPORT_DATA":
            return { Reprt: action.payload };
        case "STORE_REPORT_DATA_CREATE":
            let { payload } = action;
            return { Reprt: { ...state?.Reprt, output_type: payload } };
        case "STORE_REPORT_DATA_RESET":
            return { Reprt: {} };
        default:
            return state;
    }
}

const invCalStore = {
    advRec: null,
    miscdb: null,
    creditnte: null,
    taxPer: null,
    taxDatas: [],
};
export function storeInvCalReducer(state = invCalStore, action) {
    switch (action.type) {
        case "STORE_ADV_RECOVERY":
            return { ...state, advRec: action.payload };
        case "STORE_ADV_RECOVERY_RESET":
            return { ...state, advRec: null };
        case "STORE_MISC_DEBIT":
            return { ...state, miscdb: action.payload };
        case "STORE_MISC_DEBIT_RESET":
            return { ...state, miscdb: null };
        case "STORE_CREDIT_NOTE":
            return { ...state, creditnte: action.payload };
        case "STORE_CREDIT_NOTE_RESET":
            return { ...state, creditnte: null };
        case "STORE_TAX_CODE":
            return { ...state, taxPer: action.payload };
        case "STORE_TAX_CODE_RESET":
            return { ...state, taxPer: null };
        case "STORE_TDS_TAX":
            return { ...state, taxDatas: action.payload };
        case "STORE_TDS_TAX_UPADTE":
            return { ...state, taxDatas: action.payload };
        case "STORE_TDS_TAX_RESET":
            return { ...state, taxDatas: [] };
        default:
            return state;
    }
}

const PropertiesState = {
    fields: [],
    properties: {},
};
export function vendorPropertiesReducer(state = PropertiesState, action) {
    switch (action.type) {
        case "GET_PROPERTIES":
            return { ...state, fields: action.payload };
        case "UPDATE_PROPERTIES":
            return { ...state, fields: action.payload };
        default:
            return state;
    }
}
