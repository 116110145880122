import { Checkbox, ClickAwayListener, FormControlLabel, MenuItem, Popper, Select, Tooltip } from "@mui/material";
import _, { concat, isEqual, remove } from "lodash";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/Button";
import Loader from "../../../components/Loader";
import Card from "../../../components/UI/Card";
import { getAdvanceReport } from "../../../redux/actions/report";
import ExportExcel from "../../ExportFiles/Excel";
import TogglePopup from "../../organisations/Toggle";
import { GET_ADVANCE_REPORTS_RESET, EXPORT_ADVANCE_REPORT_LIST_RESET } from "../../../redux/types/types";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import { CustomNumericFormat, encryptId } from "../../../utils/helpers";
import { Link, NavLink, useNavigate, useLocation, json } from "react-router-dom";
import { logDOM } from "@testing-library/react";
import StatusInfoComponent from "../../Invoice/Processed/StatusInfoComponent.jsx";
import ReactDOM from "react-dom";

const Table = (props) => {
    const { currentMode } = useContext(ThemeContext);
    let optionValue = ["Advance Id", "Advance Type", "PO Number", "Vendor Name", "Assigned To", "Document Number", "Payable Amount", "Status"];
    const [values, setValues] = useState(optionValue);
    const [isdownloadClick, onClickDownload] = useState(false);
    const [isMount, setIsMount] = useState(false);
    const location = useLocation();
    const [statusInfoAnchor, setStatusInfoAnchor] = useState(false);
    const [popstate, setPopState] = useState({
        value: null,
        anchorEl: null,
        id: null,
    });

    const [placement, setPlacement] = useState();
    const { data: overall, requestComplete, fetching, exportData } = useSelector((state) => state?.getAdvanceReportListState);
    const dataList = overall?.listData;
    const countData = overall?.listCount;

    const handleClickAway = () => {
        setPopState({ id: false, anchorEl: false, value: "" });
    };
    const handleClick = (newPlacement, value, event, id = null) => {
        setPopState({ value, anchorEl: event.currentTarget, id }, (prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    const checkChange = (e) => {
        const elem = e.target.value;
        if (!e.target.checked) {
            const Remove = values?.filter((el) => el !== elem);
            setValues(Remove);
            localStorage.setItem("AdvanceOption", JSON.stringify(values));
        } else {
            setValues([...values, elem]);
            localStorage.setItem("AdvanceOption", JSON.stringify(values));
        }
    };
    useEffect(() => {
        let Check = JSON.parse(localStorage.getItem("AdvanceOption"));
        if (Check !== undefined && Check !== null && Check !== "") {
            setValues(JSON.parse(localStorage.getItem("AdvanceOption")));
        } else {
            localStorage.setItem("AdvanceOption", JSON.stringify(optionValue));
            setValues(optionValue);
        }
    }, []);

    useEffect(() => {
        if (!localStorage.getItem("AdvanceOption")) {
            localStorage.setItem("AdvanceOption", JSON.stringify(values));
        }
        localStorage.setItem("AdvanceOption", JSON.stringify(values));
    }, [values]);

    const [refreshValue, handleRefreshValue] = useState({
        isStatus: [2],
        invNumber: "",
        isSupplier: "",
    });

    // const {
    //   fromDate: fromDate,
    //   toDate: toDate,
    // } = props;

    const [isAllSelected, setAllSelected] = useState(false);
    const [isIndeterminate, setIndeterminate] = useState(false);
    const [addedInvoice, setAddedInvoice] = useState([]);
    const [isSorting, setSorting] = useState(0);

    const pageNo = props?.currentPageNo;
    const entries = props?.currentPageItem;
    const pageCount = countData?.[0]?.func_reports_advance_count ? countData?.[0]?.func_reports_advance_count : 0;
    let start = (pageNo - 1) * entries + 1;
    const nextPageCount = Math.ceil(pageCount / props?.currentPageItem);
    const [sortColumn, columnSorting] = useState("");
    const [approveList, onApproveInvoice] = useState([]);
    const getAdvanceReports = (invList) => {
        onApproveInvoice(invList);
    };
    const onSelectAll = (event) => {
        setAllSelected(event.target.checked);
        setIndeterminate(false);
        dataList?.forEach((invoice) => {
            invoice.checked = event.target.checked;
        });
        setAddedInvoice(dataList);
        getAdvanceReports(addedInvoice);
    };
    const orgId = localStorage.getItem("orgId");
    const supplier = props?.isSupplier;
    const isPoId = props?.isPoId;
    // const invNo = props?.invNumber;
    const status = props?.isStatus;
    const fromDate = props?.fromDate;
    const toDate = props?.toDate;

    const reqObj = {
        status_id: status?.length === 0 ? [0] : status,
        org_id: +orgId,
        vendor_id: supplier?.length === 0 ? [0] : supplier,
        po_id: isPoId?.length === 0 ? 0 : isPoId,
        from_date: fromDate,
        to_date: toDate,
        // search_text: "",
        order_column: sortColumn,
        order_by: isSorting,
        pgno: props?.currentPageNo,
        reccnt: props?.currentPageItem,
        advance_type: props?.users,
    };
    // const reqObjCount = {
    //     status_type: 1,
    //     status_id: status?.length === 0 ? [0] : status,
    //     invoice_date_from: fromDate,
    //     invoice_date_to: toDate,
    //     // search_text: "",
    //     organisation: +orgId,
    //     vendor_id: supplier?.length === 0 ? null : supplier,
    //     invoice_no: invNo,
    //     order_column: sortColumn,
    //     order_by: isSorting,
    //     pg_no: props?.currentPageNo,
    //     rec_cnt: props?.currentPageItem,
    // };
    const CountCalc = pageCount / props?.currentPageItem;

    const Rounded = Math.ceil(CountCalc);

    const onPageNumberSelect = (event) => {
        let pageNumber = event.target.value;
        props?.changePageNo(parseInt(pageNumber));
    };
    const onPageItemSelect = (event) => {
        setAllSelected(false);
        let pageItemVal = event.target.value;
        props?.changePageItem(parseInt(pageItemVal));
        props?.changePageNo(1);
    };
    let EmptyArr = [];
    for (let i = 0; i < Rounded; i++) {
        EmptyArr.push(i);
    }
    const previousBtnClick = () => {
        if (isAllSelected === true) {
            setAllSelected(false);
        }
        if (isIndeterminate == true) {
            setIndeterminate(false);
        }
        props?.changePageNo(props?.currentPageNo - 1);
    };
    const nextBtnClick = () => {
        if (isAllSelected === true) {
            setAllSelected(false);
        }
        if (isIndeterminate == true) {
            setIndeterminate(false);
        }
        props?.changePageNo(props?.currentPageNo + 1);
    };
    const dispatch = useDispatch();

    const onSorting = (columnName) => {
        const sortBy = isSorting === 1 ? 2 : 1;
        if (sortBy === 1) {
            document.getElementById(columnName).classList.add("Up");
        } else {
            document.getElementById(columnName).classList.remove("Up");
        }

        setSorting(sortBy);
        columnSorting(columnName);
    };
    const onSortingIconChange = () => {
        if (requestComplete) {
            if (isSorting === 1) {
                document.getElementById(sortColumn).classList.add("Up");
            } else {
                document.getElementById(sortColumn).classList.remove("Up");
            }
        }
    };
    useEffect(() => {
        if (isMount) {
            async function fetchEmployees() {
                dispatch(getAdvanceReport(reqObj));
                onSortingIconChange();
            }
            fetchEmployees();
        } else {
            setIsMount(true);
            dispatch({ type: GET_ADVANCE_REPORTS_RESET });
        }
    }, [dispatch, props?.currentPageNo, props?.currentPageItem, sortColumn, isSorting]);

    let totalPageCount = dataList ? dataList.length * (props?.currentPageNo ? props?.currentPageNo : 1) : 0;
    const totalPageExceed = props?.currentPageNo * props?.currentPageItem;
    if (totalPageExceed >= pageCount) {
        totalPageCount = pageCount;
    }
    const childCompRef = useRef();
    const exportRef = useRef();
    const onExportItems = (value, id) => {};
    const ExportList = [
        {
            text: "Excel",
            title: "To allow Organisation Access",
            src: "/images/common/excel.svg",
            value: 0,
        },
        {
            text: "CSV",
            title: "To allow Organisation Access",
            src: "/images/common/excel.svg",
            value: 1,
        },
        {
            text: "PDF",
            title: "To View the Organisation Access",
            src: "/images/common/excel.svg",
            value: 2,
        },
        {
            text: "JPG",
            title: "To View the Organisation Access",
            src: "/images/common/excel.svg",
            value: 4,
        },
    ];

    const filterMenuList = [
        "Advance Id",
        "PO Number",
        "Advance Type",
        "Vendor Name",
        "Request Date",
        "Assigned To",
        "Approved Date",
        "Document Number",
        "Reference Number",
        "Reference Date ",
        "Exchange Rate",
        "Advance Amount",
        "Currency",
        "Payable Amount",
        "Tax Amount",
        "Created Date",
        "Modified Date",
        "Status",
    ];

    const CheckLineItems = JSON.parse(localStorage.getItem("AdvanceOption"));
    const downloadeExcel = (downloadInvoiceList) => {
        if (downloadInvoiceList) {
            const getAdvanceReports = [];
            downloadInvoiceList?.forEach((item) => {
                let invHeaders = {
                    [CheckLineItems?.includes("Advance Id") && "Advance Id"]: CheckLineItems?.includes("Advance Id") && item?.sequence_no,
                    [CheckLineItems?.includes("PO Number") && "PO Number"]: CheckLineItems?.includes("PO Number") && item?.po_no,
                    [CheckLineItems?.includes("Vendor Name") && "Vendor Name"]: CheckLineItems?.includes("Vendor Name") && item?.vendor_name,
                    [CheckLineItems?.includes("Advance Type") && "Advance Type"]:
                        CheckLineItems?.includes("Advance Type") && item?.adv_type == 1 ? "Mobilization" : "Adhoc",
                    [CheckLineItems?.includes("Request Date") && "Request Date"]: CheckLineItems?.includes("Request Date") && item?.request_date,
                    [CheckLineItems?.includes("Assigned To") && "Assigned To"]: CheckLineItems?.includes("Assigned To") && item?.assigned_to,
                    [CheckLineItems?.includes("Approved Date") && "Approved Date"]: CheckLineItems?.includes("Approved Date") && item?.approved_date,
                    [CheckLineItems?.includes("Document Number") && "Document Number"]:
                        CheckLineItems?.includes("Document Number") && item?.document_no,
                    [CheckLineItems?.includes("Reference Number") && "Reference Number"]:
                        CheckLineItems?.includes("Reference Number") && item?.reference_no,
                    [CheckLineItems?.includes("Reference Date ") && "Reference Date "]:
                        CheckLineItems?.includes("Reference Date ") && item?.reference_date,
                    [CheckLineItems?.includes("Exchange Rate") && "Exchange Rate"]: CheckLineItems?.includes("Exchange Rate") && item?.exchange_rate,
                    [CheckLineItems?.includes("Advance Amount") && "Advance Amount"]: CheckLineItems?.includes("Advance Amount") && item?.adv_amount,
                    [CheckLineItems?.includes("Currency") && "Currency"]: CheckLineItems?.includes("Currency") && item?.currency,
                    [CheckLineItems?.includes("Payable Amount") && "Payable Amount"]:
                        CheckLineItems?.includes("Payable Amount") && item?.payable_amount,
                    [CheckLineItems?.includes("Tax Amount") && "Tax Amount"]: CheckLineItems?.includes("Tax Amount") && item?.tax_amount,
                    [CheckLineItems?.includes("Created Date") && "Created Date"]: CheckLineItems?.includes("Created Date") && item?.created_date,
                    [CheckLineItems?.includes("Modified Date") && "Modified Date"]: CheckLineItems?.includes("Modified Date") && item?.modified_date,
                    [CheckLineItems?.includes("Status") && "Status"]: CheckLineItems?.includes("Status") && item?.status_name,
                };
                getAdvanceReports.push(_.pickBy(invHeaders));
            });
            ExportExcel({
                excelData: getAdvanceReports,
                fileName: "Advance Reports",
            });
            onClickDownload(false);
            dispatch({ type: EXPORT_ADVANCE_REPORT_LIST_RESET });
            setExportFile(false);
        }
    };

    const [exportFile, setExportFile] = useState(false);
    const onExportExcel = () => {
        setExportFile(true);
        const reqObj = {
            status_id: status?.length === 0 ? [0] : status,
            org_id: +orgId,
            vendor_id: supplier?.length === 0 ? [0] : supplier,
            po_id: isPoId?.length === 0 ? 0 : isPoId,
            from_date: fromDate,
            to_date: toDate,
            // search_text: "",
            // invoice_no: invNo,
            order_column: sortColumn,
            order_by: isSorting,
            pgno: 1,
            reccnt: pageCount,
            advance_type: 0,
        };
        dispatch(getAdvanceReport(reqObj, "download"));
    };
    useEffect(() => {
        if (exportData?.listData?.length > 0 && exportFile) {
            onClickDownload(true);
        }
    }, [exportData?.listData?.length > 0]);
    if (isdownloadClick) {
        let downloadInvoiceList = exportData?.listData;
        downloadeExcel(downloadInvoiceList);
    }
    // useEffect(() => {
    //     if (exportData?.length > 0 && exportFile) {
    //         onClickDownload(true);
    //     }
    // }, [exportData?.length > 0]);
    // if (isdownloadClick) {
    //     let downloadInvoiceList = exportData;
    //     downloadeExcel(downloadInvoiceList);
    // }
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const dropdownShadow = currentMode !== "dark" ? "0px 4px 20px rgba(238, 238, 238, 0.5)" : "";
    const dropdownBorder = currentMode !== "dark" ? "223, 225, 226, 0.56" : "";
    //   const IsInside = ;

    const DisplayRole = useSelector((state) => state?.DisplayRolePermissionStateAction);
    const { data: DataList } = DisplayRole;
    const RoleList = DataList?.role_info;
    const RoleMenu = RoleList?.menu;
    const RoleProcess = RoleMenu?.find((elem) => elem?.menu_id === 8);
    const RoleProcessAction = RoleProcess?.actions;
    const RoleActProcessView = RoleProcessAction?.find((element) => element.id === 3);
    const ViewOpen = RoleActProcessView?.isallowed;
    const userNumFormat = localStorage.getItem("userNumberFormat");
    return (
        <>
            <Card className={`mb-[4rem] bg-${currentMode}-card`}>
                <div className="flex justify-between">
                    {dataList && dataList.length !== 0 && (
                        <div className="flex my-auto px-[30px] py-[20px]">
                            <div className="">
                                <span>Show</span>
                                <Select
                                    name="entrycount"
                                    className={`mx-3 insideTble text-xs entries bg-${currentMode}-card`}
                                    value={props?.currentPageItem}
                                    onChange={(e) => onPageItemSelect(e)}
                                    IconComponent={() => (
                                        <img
                                            className="absolute opacity-50 right-[8px] pointer-events-none"
                                            src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                        />
                                    )}
                                    MenuProps={{
                                        sx: {
                                            "& .MuiMenu-paper": {
                                                backgroundColor: dropdownColor,
                                                color: dropdownText,
                                                boxShadow: dropdownShadow,
                                                border: dropdownBorder,
                                                borderRadius: "8px",
                                            },
                                        },
                                    }}
                                    SelectDisplayProps={{
                                        style: {
                                            paddingTop: "5px",
                                            display: "flex",
                                            paddingBottom: "5px",
                                            paddingLeft: "10px",
                                            border: 0,
                                            color: currentMode === "dark" ? `#ECECEC` : `#555555`,
                                            paddingRight: "25px",
                                        },
                                    }}>
                                    <MenuItem value={10}>{10}</MenuItem>
                                    <MenuItem value={15}>{15}</MenuItem>
                                    <MenuItem value={20}>{20}</MenuItem>
                                    <MenuItem value={25}>{25}</MenuItem>
                                    <MenuItem value={50}>{50}</MenuItem>
                                    <MenuItem value={75}>{75}</MenuItem>
                                    <MenuItem value={100}>{100}</MenuItem>
                                </Select>
                                <span>entries</span>
                            </div>
                        </div>
                    )}
                    {dataList && dataList.length !== 0 && (
                        <div className="flex items-center space-x-2 px-[30px] py-[20px]">
                            <div>
                                <Button
                                    title="To Export Advance Reports to Excel"
                                    // onClick={handleClick("top-start")}
                                    onClick={() => onExportExcel()}
                                    className={`border-20 export-step px-5 py-2 rounded-[8px] font-interM text-sm flex justify-center items-center border-[1px] text-${currentMode}-copy border-light-20`}>
                                    <img className="mr-[.4rem]" src="/images/common/download-excel.svg" alt="upload" /> Download
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
                <div className={`heightFix invoices relative report max-h-[initial] ${dataList?.length == 0 ? `rounded-t-[8px]` : ``}`}>
                    <table className="table-auto TblNormal invoice reports w-full">
                        <thead className="sticky top-0 z-[1] rounded-md">
                            <tr className={`bg-${currentMode}-450`}>
                                <>
                                    {values?.includes("Advance Id") ? <th>Advance Id </th> : ""}
                                    {values?.includes("PO Number") ? <th>PO Number </th> : ""}
                                    {values?.includes("Advance Type") ? <th>Advance Type </th> : ""}
                                    {values?.includes("Vendor Name") ? <th>Vendor Name </th> : ""}
                                    {values?.includes("Request Date") ? <th>Request Date </th> : ""}
                                    {values?.includes("Assigned To") ? <th>Assigned To </th> : ""}
                                    {values?.includes("Approved Date") ? <th>Approved Date </th> : ""}
                                    {values?.includes("Document Number") ? <th>Document Number </th> : ""}
                                    {values?.includes("Reference Number") ? <th>Reference Number </th> : ""}
                                    {values?.includes("Reference Date ") ? <th>Reference Date </th> : ""}
                                    {values?.includes("Exchange Rate") ? <th>Exchange Rate </th> : ""}
                                    {values?.includes("Advance Amount") ? <th>Advance Amount </th> : ""}
                                    {values?.includes("Currency") ? <th>Currency </th> : ""}
                                    {values?.includes("Payable Amount") ? <th>Payable Amount </th> : ""}
                                    {values?.includes("Tax Amount") ? <th>Tax Amount </th> : ""}
                                    {values?.includes("Created Date") ? <th>Created Date</th> : ""}
                                    {values?.includes("Modified Date") ? <th>Modified Date </th> : ""}
                                    {values?.includes("Status") ? <th>Status </th> : ""}
                                </>
                                <th>
                                    <img
                                        className="cursor-pointer max-w-[inherit] convertWhite"
                                        onClick={(e) => handleClick("bottom-end", 1, e)}
                                        src="/images/main/columns.svg"
                                        alt="columns"
                                    />
                                </th>
                            </tr>
                        </thead>
                        {fetching ? (
                            <Loader className="h-[calc(100vh_-_26rem)] absolute left-[50%] translate-x-[-50%]" />
                        ) : (
                            <>
                                <tbody>
                                    {dataList && dataList.length !== 0 ? (
                                        dataList?.map((elem, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className={`${!ViewOpen ? "pointer-events-none" : ""}`}>
                                                        {" "}
                                                        <Link
                                                            state={{ from: location.pathname }}
                                                            to={`${
                                                                elem?.adv_type == 1
                                                                    ? `/mobilization-request?reqId=${elem?.id}`
                                                                    : elem?.adv_type == 2 && elem?.payable_amount == null
                                                                    ? `${"/proforma-invoice-view?"}${encryptId(
                                                                          `{"inv":${+elem?.invoice_id},"type":${9},"seq":"${+elem?.sequence_no}"}`
                                                                      )}`
                                                                    : elem?.adv_type == 2 &&
                                                                      elem?.payable_amount !== null &&
                                                                      `/adhoc-advance-request?reqId=${elem?.id}`
                                                            }`}
                                                            style={{
                                                                textDecoration: "underline",
                                                            }}
                                                            target="_blank">
                                                            {elem?.sequence_no}{" "}
                                                        </Link>
                                                    </td>
                                                    <td className={`${!ViewOpen ? "pointer-events-none" : ""}`}>
                                                        {" "}
                                                        <Link
                                                            state={{ from: location.pathname }}
                                                            to={`${
                                                                elem?.adv_type == 1
                                                                    ? `/mobilization-request?reqId=${elem?.id}`
                                                                    : elem?.adv_type == 2 && elem?.payable_amount == null
                                                                    ? `${"/proforma-invoice-view?"}${encryptId(
                                                                          `{"inv":${+elem?.invoice_id},"type":${9},"seq":"${+elem?.sequence_no}"}`
                                                                      )}`
                                                                    : elem?.adv_type == 2 &&
                                                                      elem?.payable_amount !== null &&
                                                                      `/adhoc-advance-request?reqId=${elem?.req_id}`
                                                            }`}
                                                            style={{
                                                                textDecoration: "underline",
                                                            }}
                                                            target="_blank">
                                                            {elem?.po_no}{" "}
                                                        </Link>
                                                    </td>
                                                    {values?.includes("Advance Type") ? (
                                                        <td>{elem?.adv_type === 1 ? "Mobilization" : "Adhoc"}</td>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <td className="max-w-[50px] truncate">{elem?.vendor_name}</td>
                                                    {values?.includes("Request Date") ? <td>{elem?.request_date}</td> : ""}
                                                    {values?.includes("Assigned To") ? <td>{elem?.assigned_to}</td> : ""}
                                                    {values?.includes("Approved Date") ? <td>{elem?.approved_date}</td> : ""}
                                                    {values?.includes("Document Number") ? <td>{elem?.document_no}</td> : ""}
                                                    {values?.includes("Reference Number") ? <td>{elem?.reference_no}</td> : ""}
                                                    {values?.includes("Reference Date ") ? <td>{elem?.reference_date}</td> : ""}
                                                    {values?.includes("Exchange Rate") ? <td className="text-center">{elem?.exchange_rate}</td> : ""}
                                                    {values?.includes("Advance Amount") ? <td className="text-center">< CustomNumericFormat value =  {elem?.adv_amount} formatNum={userNumFormat} /></td> : ""}
                                                    {values?.includes("Currency") ? <td className="text-center">{elem?.currency}</td> : ""}
                                                    {values?.includes("Payable Amount") ? (
                                                        <td className="text-center">< CustomNumericFormat value = {elem?.payable_amount} formatNum={userNumFormat} /> </td>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {values?.includes("Tax Amount") ? <td className="text-center">< CustomNumericFormat value = {elem?.tax_amount} formatNum={userNumFormat} /></td> : ""}
                                                    {values?.includes("Created Date") ? <td>{elem?.created_date}</td> : ""}
                                                    {values?.includes("Modified Date") ? <td>{elem?.modified_date}</td> : ``}
                                                    <td className="flex items-center">
                                                        <div
                                                            className={`flex bg-[#F5A622] w-full max-w-[100px] truncate font-interR rounded-[5px] py-[2px] px-3 text-white`}
                                                            style={{
                                                                background: ``,
                                                            }}>
                                                            <span className="block truncate "> {elem?.status_name}</span>
                                                        </div>
                                                        {/* <Button>
                                                             className={`cursor-default ${elem?.status_name === "Processed"
                                                                ? `bg-light-480`
                                                                : elem?.status_name === "Approved"
                                                                    ? `bg-light-490`
                                                                    : elem?.status_name === "Rejected"
                                                                        ? `bg-light-180`
                                                                        : elem?.status_name === "Draft"
                                                                            ? `bg-light-500`
                                                                            : elem?.status_name === "Submitted"
                                                                                ? `bg-light-510`
                                                                                : elem?.status_name === "Posted"
                                                                                    ? `bg-light-510`
                                                                                    : elem?.status_name === "ForApproval"
                                                                                        ? `bg-light-520`
                                                                                        : ``
                                                                } w-full max-w-[100px] truncate font-interR rounded-[5px] py-[2px] px-3 text-black`}>
                                                            {elem?.status_name}
                                                        </Button> */}

                                                        <div className="flex items-center">
                                                            <Tooltip
                                                                title={elem?.status_id?.[0]?.status_name}
                                                                disablePortal
                                                                placement="bottom"
                                                                disableInteractive
                                                                PopperProps={{
                                                                    sx: {
                                                                        "& .MuiTooltip-tooltip": {
                                                                            fontSize: "0.9em",
                                                                            maxHeight: "120px",
                                                                            maxWidth: "250px",
                                                                            background: "#DAF9F4",
                                                                            color: "#555555",
                                                                            borderRadius: "5px",
                                                                            marginTop: "10px !important",
                                                                        },

                                                                        "& .MuiTooltip-arrow": {
                                                                            color: "#DAF9F4",
                                                                        },
                                                                    },
                                                                }}
                                                                arrow>
                                                                <div
                                                                    // className={`flex items-center justify-center min-w-[90px] min-h-[17px] max-w-[90px] max-h-[17px] rounded-[5px] cursor-pointer text-white`}
                                                                    style={{
                                                                        background: `${elem?.status_id?.[0]?.color_code}`,
                                                                    }}>
                                                                    <span className="block truncate  "> {elem?.status_id?.[0]?.status_name}</span>
                                                                </div>
                                                            </Tooltip>
                                                            <img
                                                                src={
                                                                    currentMode == "dark" && !elem?.message_category
                                                                        ? "/images/workflow-automation/api_call_dark.svg"
                                                                        : !elem?.message_category
                                                                        ? `/images/workflow-automation/statusApi.svg`
                                                                        : `/images/workflow-automation/StatusRed.svg`
                                                                }
                                                                alt="get-status"
                                                                onClick={() => {
                                                                    setStatusInfoAnchor({
                                                                        anchor: true,
                                                                        invoiceId: elem?.id,
                                                                        seqNo: elem?.sequence_no,
                                                                    });
                                                                }}
                                                                className="ml-2 max-h-[18px] cursor-pointer"
                                                            />
                                                        </div>
                                                    </td>
                                                    <td></td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <div className="absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]">
                                            <img src="/images/common/delivery.png" alt="delivery" />
                                            <h4 className="font-interSb text-center mt-3">No data found</h4>
                                        </div>
                                    )}
                                </tbody>
                            </>
                        )}
                    </table>
                </div>

                <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClickAway}>
                    <Popper
                        className={`bg-${currentMode}-card text-${currentMode}-copy  p-4 border-[1px] z-[12] border-${currentMode}-700  shadow-[0px_4px_20px_rgba(238,238,238,0.5)] rounded-[10px] reportsPopper `}
                        open={1 === popstate.value}
                        anchorEl={popstate.anchorEl}
                        id={1}
                        placement={placement}>
                        <ul>
                            {filterMenuList?.map((el) => {
                                return (
                                    <li className={`transition duration-300 ease-in-out mx-[-17px]  py-1 px-[20px] hover:bg-${currentMode}-960`}>
                                        <Button className={`flex ${1} items-center justify-between font-interR`}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        size="small"
                                                        value={el}
                                                        onChange={checkChange}
                                                        disabled={!Boolean(optionValue?.indexOf(el) === -1)}
                                                        defaultChecked={!Boolean(values?.indexOf(el) === -1)}
                                                        sx={{
                                                            padding: 0,
                                                            color: "#008785",

                                                            margin: "0 12px",
                                                            "&.Mui-checked": {
                                                                color: "#008785",
                                                            },
                                                        }}
                                                    />
                                                }
                                                label={el}
                                            />
                                        </Button>
                                    </li>
                                );
                            })}
                        </ul>
                    </Popper>
                </ClickAwayListener>
                <TogglePopup ref={exportRef} menuList={ExportList} selectedItemChange={onExportItems} />
                {dataList && dataList.length !== 0 && (
                    <div className={`bg-${currentMode}-460 flex justify-between items-center py-[10px] px-[30px]`}>
                        <div>
                            <div>
                                Showing <span>{`${start} to ${totalPageCount}`}</span> entries of <span>{pageCount}</span>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <span>The page you’re on</span>
                            <Select
                                name=""
                                className={`bg-${currentMode}-card insideTble mx-3 text-xs entries`}
                                id=""
                                value={props?.currentPageNo}
                                onChange={(e) => onPageNumberSelect(e)}
                                IconComponent={() => (
                                    <img
                                        className="absolute opacity-50 right-[8px] pointer-events-none"
                                        src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                    />
                                )}
                                SelectDisplayProps={{
                                    style: {
                                        paddingTop: "5px",
                                        display: "flex",
                                        paddingBottom: "5px",
                                        paddingLeft: "10px",
                                        border: 0,
                                        color: currentMode === "dark" ? `#ECECEC` : `#555555`,
                                        paddingRight: "25px",
                                    },
                                }}
                                MenuProps={{
                                    sx: {
                                        "& .MuiMenu-paper": {
                                            backgroundColor: dropdownColor,
                                            color: dropdownText,
                                            boxShadow: dropdownShadow,
                                            border: dropdownBorder,
                                            borderRadius: "8px",
                                        },
                                    },
                                }}>
                                {EmptyArr?.map((elem) => (
                                    <MenuItem value={elem + 1}>{elem + 1}</MenuItem>
                                ))}
                            </Select>
                            <div className={`flex space-x-2 border-l-[1px] pl-3 border-${currentMode}-830`}>
                                <Button
                                    title="Previous page"
                                    disabled={props?.currentPageNo > 1 ? false : true}
                                    onClick={() => previousBtnClick()}
                                    className={`border-${currentMode}-840 border-[1px] rounded-[5px] bg-${currentMode}-card px-2 py-3`}>
                                    <img className="rotate-180 convertWhite " src="/images/common/arrowRight.svg" alt="arrowRight" />
                                </Button>

                                <Button
                                    title="Next page"
                                    className={`border-${currentMode}-840 border-[1px] rounded-[5px] bg-${currentMode}-card px-2 py-3`}
                                    disabled={props?.currentPageNo >= nextPageCount ? true : false}
                                    onClick={() => nextBtnClick()}>
                                    <img className="convertWhite" src="/images/common/arrowRight.svg" alt="arrowRight" />
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
                {statusInfoAnchor?.anchor && (
                    <>
                        {ReactDOM.createPortal(
                            <StatusInfoComponent
                                isOpened={statusInfoAnchor?.anchor}
                                setStatusInfoAnchor={setStatusInfoAnchor}
                                currentMode={currentMode}
                                className={statusInfoAnchor?.anchor && "Show"}
                                orgId={orgId}
                                seqNo={statusInfoAnchor?.seqNo}
                                invoiceId={statusInfoAnchor?.invoiceId}
                            />,
                            document.getElementById("Modal-root")
                        )}
                    </>
                )}

                {/* )} */}
            </Card>
        </>
    );
};

export default Table;
