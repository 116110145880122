import { Autocomplete, Checkbox, ClickAwayListener, Fade, MenuItem, Paper, Popper, TextField, Box } from "@mui/material";
import { DatePicker, DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { json, Link } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Card from "../../components/UI/Card";
import Main from "../../components/UI/Main";
import Layout from "../../Hoc/Layout";
import Footer from "../../organisms/Footer";
import { invoiceVendorList } from "../../redux/actions/invoice";
import { getReportsAccrualList, sendBillingEmail } from "../../redux/actions/report";
import AccrualTbl from "./AccrualTbl";
import { GetAccrualPosting } from "../../redux/actions/accrual-accounting";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import BillingTable from "./BillingTable";
import { BillingChart } from "./BillingChart";
import { getLicenseSummaryList } from "../../redux/actions/report";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { encryptId } from "../../utils/helpers";
import { SEND_BILLING_MAIL_RESET } from "../../redux/types/types";

const BillingReport = () => {
    const { currentMode } = useContext(ThemeContext);
    const dispatch = useDispatch();
    const [userExpire, setUserExpire] = useState("");
    const [invoiceExpire, setInvoiceExpire] = useState("");
    const options = { year: "numeric", month: "short", day: "numeric" };
    useEffect(() => {
        dispatch(getLicenseSummaryList());
    }, [dispatch]);
    const emailStatus = useSelector((state) => state?.sendBillingEmailState);
    const { dataList, requestComplete, fetching } = useSelector((state) => state?.getLicenseSummaryState);
    function getMonthName(number) {
        const date = new Date();
        date.setMonth(number - 1); // Months are zero-based (0 - 11)

        const monthName = date.toLocaleString("default", { month: "short" });
        return monthName;
    }
    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        width: 400,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            background: "#F1F0F0",
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            background: "#00B399",
        },
    }));
    useEffect(() => {
        const getTo = moment(new Date(dataList?.expiring_date_user)).format("DD-MM-YYYY")?.split("-");
        const getFrom = moment(new Date(dataList?.expiring_date_invoice)).format("DD-MM-YYYY")?.split("-");
        const parse = getMonthName(getTo[1]) + " " + getTo[0] + ", " + getTo[2];
        setUserExpire(parse);
        setInvoiceExpire(getMonthName(getFrom[1]) + " " + getFrom[0] + ", " + getFrom[2]);
    }, [dataList]);
    const ReamaingUsers = dataList?.license_usercount - dataList?.user_count;
    const hasHyphen = /-/.test(ReamaingUsers);
    const RemaingUsersFilter = hasHyphen ? 0 : ReamaingUsers;
    const BalanceInvoices = dataList?.inv_license_count - dataList?.inv_count;
    const hasHyphenInv = /-/.test(BalanceInvoices);
    const BalanceInvoiceFilter = hasHyphenInv ? 0 : BalanceInvoices;
    const data = [
        { name: "Active Users", value: +dataList?.user_count },
        {
            name: "Remaining Users",
            value: RemaingUsersFilter,
        },
    ];
    const Invdata = [
        { name: "Used Invoices", value: +dataList?.inv_count },
        {
            name: "Balance Invoices",
            value: BalanceInvoiceFilter,
        },
    ];
    const COLORS = ["#00B399", "#A8E4DC"];
    const handleEMailSend = () => {
        const user = JSON.parse(localStorage.getItem("user"));
        const reqObj = {
            customerName: user?.username,
            customerEmail: user?.emailaddress,
            phone: user?.mobilenumber,
            toEmail: encryptId(process.env?.REACT_APP_SALES_EMAIL),
        };
        dispatch(sendBillingEmail(reqObj));
        Swal.fire({
            title: "Thank you for using our free trial version!",
            text: "MyiQ.Ai has received your upgrade request. Our team will reach out to you shortly.",
            closeOnClickOutside: false,
            showCloseButton: true,
            icon: "success",
            button: "Ok",
        });
    };
    if (emailStatus?.requestComplete) {
        dispatch({ type: SEND_BILLING_MAIL_RESET });
    }
    return (
        <>
            <Layout>
                <Main>
                    <div className={`flex justify-between items-center mb-6 text-${currentMode}-copy`}>
                        <div>
                            <h4 className="text-2xl font-interSb">Billing</h4>
                        </div>
                        <Link className="flex" to="/dashboard">
                            <img className="mr-2 convertWhite" src="/images/main/noun-back.svg" alt="noun-back" /> Back
                        </Link>
                    </div>
                    {requestComplete && (
                        <div className="mb-[20px] grid xl:grid-cols-3 md:grid-cols-1 lg:grid-cols-2 gap-5 h-full">
                            <Card className="p-[20px]">
                                <div className="space-y-[30px]">
                                    <div className="flex justify-between">
                                        <div>
                                            <span className="block mb-1">Plan</span>
                                            <span className={`text-${currentMode}-20 font-interSb`}>{dataList?.plan_name}</span>
                                        </div>
                                        <div>
                                            <Button
                                                title={"Upgrade request will be sent to the team"}
                                                onClick={handleEMailSend}
                                                disabled={emailStatus?.requestComplete}
                                                className="bg-light-20 py-[8px] text-base px-[2rem] rounded-[8px] text-white ">
                                                Upgrade
                                            </Button>
                                        </div>
                                    </div>
                                    <div>
                                        <span className="block mb-1">Description</span>
                                        <span>
                                            <span className={`text-${currentMode}-20 font-interSb`}>{dataList?.payment}</span>{" "}
                                        </span>
                                    </div>
                                    <div>
                                        <span className="font-interSb text-xs">Expires on {userExpire}</span>
                                    </div>
                                </div>
                                <div className="mt-[-20px]">
                                    <img className="ml-auto" src="/images/invoice/noun-billing.svg" alt="noun-billing" />
                                </div>
                            </Card>
                            <Card className="p-[20px]">
                                <div className="w-max mb-3">
                                    <span className="block mb-1">Users</span>
                                    <span className={`text-${currentMode}-20 block text-end font-interSb`}>{dataList?.license_usercount || 0}</span>
                                </div>
                                <div className="flex justify-between items-center">
                                    <PieChart width={180} height={180}>
                                        <Pie
                                            data={data}
                                            cx="50%"
                                            cy="50%"
                                            innerRadius={60}
                                            stroke={"transparent"}
                                            cornerRadius={6}
                                            outerRadius={85}
                                            fill="#8884d8"
                                            dataKey="value"
                                            paddingAngle={5}>
                                            {data.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                        </Pie>
                                    </PieChart>
                                    <div>
                                        <ul className="legend text-xs">
                                            {data.map((entry, index) => (
                                                <li key={`legend-${index}`}>
                                                    <span
                                                        className="legend-color"
                                                        style={{
                                                            backgroundColor: COLORS[index % COLORS.length],
                                                        }}
                                                    />
                                                    <span className={`text-[#999999]`}>
                                                        <b className={`font-interM mr-3 text-${currentMode}-10`}>{entry?.value || 0}</b>
                                                        {entry?.name}
                                                    </span>
                                                </li>
                                            ))}
                                        </ul>
                                        <span className="text-xs">Expires on {userExpire}</span>
                                    </div>
                                </div>
                            </Card>
                            <Card className="p-[20px]">
                                <div className="w-max mb-3">
                                    <span className="block mb-1">Invoice</span>
                                    <span className={`text-${currentMode}-20 block text-end font-interSb`}>{dataList?.inv_license_count || 0}</span>
                                </div>
                                <div className="flex justify-between items-center">
                                    <PieChart width={180} height={180}>
                                        <Pie
                                            data={Invdata}
                                            cx="50%"
                                            cy="50%"
                                            stroke={"transparent"}
                                            innerRadius={60}
                                            cornerRadius={6}
                                            outerRadius={85}
                                            fill="#8884d8"
                                            dataKey="value"
                                            paddingAngle={5}>
                                            {Invdata?.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                        </Pie>
                                    </PieChart>
                                    <div>
                                        <ul className="legend text-xs">
                                            {Invdata?.map((entry, index) => (
                                                <li key={`legend-${index}`}>
                                                    <span
                                                        className="legend-color"
                                                        style={{
                                                            backgroundColor: COLORS[index % COLORS.length],
                                                        }}
                                                    />
                                                    <span className={`text-[#999999]`}>
                                                        <b className={`font-interM mr-3 text-${currentMode}-10`}>{entry?.value || 0}</b>
                                                        {entry?.name}
                                                    </span>
                                                </li>
                                            ))}
                                        </ul>
                                        <span className="text-xs">Expires on {invoiceExpire}</span>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    )}
                    <BillingChart />
                    <BillingTable />
                </Main>
                <Footer />
            </Layout>
        </>
    );
};

export default BillingReport;
