import { Autocomplete, Checkbox, FormControl, MenuItem, Paper, Select, TextField } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../../Hooks/Themes/Themeprovider";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import Card from "../../../../components/UI/Card";
import Main from "../../../../components/UI/Main";
import Layout from "../../../../Hoc/Layout";
import { decryptId } from "../../../../utils/helpers";
import ReportTaskTable from "../../../ReportsTask/Table";
import { getReportsTaskList, getReportTaskCount, getReportsTaskUserList } from "../../../../redux/actions/report.js";
import { getCurrentUser } from "../../../../redux/actions/auth";
import { CustomReportsList, GetCustomLabelInfo } from "../../../../redux/actions/output-management";
import CustomReportsTable from "./Table";
import Loader from "../../../../components/Loader";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CustomChartsView from "./ChartView";
import { MatrixTble } from "../../ScheduledReports/MatrixTble";
import CustomMatrixTable from "./MatrixTable";
import { CUSTOM_REPORTS_LIST_RESET } from "../../../../redux/types/types";
import { date } from "yup";
import Footer from "../../../../organisms/Footer";
let extractedData = [];

const CustomReports = () => {
    const params = new URLSearchParams(window.location.search);
    const ReportId = params.get("id");
    const ReportName = params.get("name");
    const ReportType = params.get("report");
    const ChartType = params.get("chart");
    const dispatch = useDispatch();
    const [currentPageNo, changePageNo] = useState(1);
    const [stringValue, setStringValue] = useState("");
    const [taskType, setTaskType] = useState(0);

    const [dateValue, setDateValue] = useState();
    const [TodateValue, setToDateValue] = useState();
    const [isStatus, setStatusValue] = useState([]);
    const [taskStatus, setTaskStatus] = useState(0);
    const [dropDown, setDropdown] = useState(0);
    const lastDateOfYear = `12/31/${new Date().getFullYear()}`;
    const dataLabelInfo = useSelector((state) => state?.GetCustomLabelInfoState);
    const DataLabelList = dataLabelInfo?.data?.[0]?.filter_info;
    const FieldInfo = dataLabelInfo?.data?.[0]?.field_info;
    const handleDropDownChange = (e, i) => {
        setDropdown(e);
        DataLabelList?.forEach((element, index) => {

            if (i == index) {
                element.column_value = [e];
                element.Filter[0].val1 = [e]
            }
            if (i !== index && element.column_value == "") {
                element.column_value = null;
                element.Filter[0].val1 = ""
            }
            if (i == index && e == null) {
                element.column_value = null;
                element.Filter[0].val1 = ""
            }
            if (i == index && e == "") {
                element.column_value = null;
                element.Filter[0].val1 = ""
            }
        });
    };
    const handleAutoChange = (e, i, selected) => {
        // const value = selected?.map((option) => option?.value);

        DataLabelList?.forEach((element, index) => {
            const value =
                element?.column_name_c == "company_name"
                    ? selected?.map((option) => option?.value?.replace(/,/g, "*"))
                    : selected?.map((option) => option?.value);
            setStatusValue(value);
            const resultValue = [value?.join(",")];

            if (i == index) {
                element.column_value = resultValue;
                element.Filter[0].val1 = resultValue
            }
            if (i !== index && element.column_value == "") {
                element.column_value = null;
                element.Filter[0].val1 = ""
            }
            if (i == index && value == null) {
                element.column_value = null;
                element.Filter[0].val1 = ""
            }
            if (i == index && value == "") {
                element.column_value = null;
                element.Filter[0].val1 = ""
            }
        });
    };

    const handleTaskStatus = (e) => {
        setTaskStatus(e.target.value);
    };

    const handleDateValue = (newValue, i) => {
        setDateValue(newValue);
        const dateString = newValue;
        const date = new Date(dateString);

        const year = date.getFullYear();
        const month = date.getMonth() + 1; // Months are zero-based, so we add 1
        const day = date.getDate();

        const formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""}${day}`;

        DataLabelList?.forEach((element, index) => {

            const nullValue = element?.Filter?.[0]?.operator == "Between" ? "Na" : null
            if (i == index) {
                element.column_value = [formattedDate];
                element.Filter[0].val1 = [formattedDate]
            }
            if (i !== index && element.column_value == "") {
                element.column_value = nullValue;
                element.Filter[0].val1 = ""

            }
            if (i == index && newValue == null) {
                element.column_value = nullValue;
                element.Filter[0].val1 = ""
            }
            if (i == index && newValue == "") {
                element.column_value = nullValue;
                element.Filter[0].val1 = ""
            }
        });
    };
    const handleToDateValue = (newValue, i) => {
        setToDateValue(newValue);
        const dateString = newValue;
        const date = new Date(newValue);

        const year = date.getFullYear();
        const month = date.getMonth() + 1; // Months are zero-based, so we add 1
        const day = date.getDate();

        const formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""}${day}`;

        // console.log(formattedDate); // Output: 2024-02-06

        DataLabelList?.forEach((element, index) => {
            const nullValue = element?.Filter?.[0]?.operator == "Between" ? "Na" : null
            if (i == index) {
                element.column_value1 = [formattedDate];
                element.Filter[0].val2 = [formattedDate]
            }
            if (i !== index && element.column_value1 == "") {
                element.column_value1 = nullValue;
                element.Filter[0].val2 = ""
            }
            if (i == index && newValue == null) {
                element.column_value1 = nullValue;
                element.Filter[0].val2 = ""
            }
            if (i == index && newValue == "") {
                element.column_value1 = nullValue;
                element.Filter[0].val2 = ""
            }


        });
    };
    const CurrentUser = getCurrentUser();
    const FilterList = DataLabelList?.filter((el) => el?.column_value1 !== "Na" && el?.column_value !== "Na")
    const FilterNull = DataLabelList?.every((el) => el?.Filter?.[0]?.val1 == "" && el?.Filter?.[0]?.val2 == "")
    const Filterdatelist = DataLabelList?.filter((el) => el?.column_value1 == "Na" && el?.column_value == "Na")


    const DisableSearch = FilterNull

    const handleTaskType = (e) => {
        setTaskType(e.target.value);
    };
    const [currentPageItem, changePageItem] = useState(10);
    const { currentMode } = useContext(ThemeContext);
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;

    const { dataList: listData, fetching: fetch, requestComplete: reqComp } = useSelector((state) => state?.ReportsTaskListState);



    // const FilterExtractData = extractedData?.filter((el) => el?.column_value !== "");
    const HeaderList = dataLabelInfo?.data?.[0]?.header_info;

    const StringValueChange = (e, i, id) => {
        DataLabelList?.forEach((element, index) => {
            if (i == index) {
                element.column_value = [e];
                element.Filter[0].val1 = [e]
            }
            if (i !== index && element.column_value == "") {
                element.column_value = null;
                element.Filter[0].val1 = ""
            }
            if (i == index && e == null) {
                element.column_value = null;
                element.Filter[0].val1 = ""
            }
            if (i == index && e == "") {
                element.column_value = null;
                element.Filter[0].val1 = ""
            }
        });
        setStringValue(e);
    };
    const { data, fetchData } = useSelector((state) => state?.customReportsListState);

    const fetching = dataLabelInfo?.fetching;
    const dataHeader = [{ column: "invoice_no" }, { column: "Vendor_name" }];
    const orgId = localStorage.getItem("orgId");
    const [search, setSearch] = useState();
    extractedData = DataLabelList?.map(({ column_name_c, column_value, column_value1 }) => ({ column_name_c, column_value, column_value1 }));
    const reqBody = {
        json: extractedData,
        id: +ReportId,
        pgno: currentPageNo,
        reccnt: currentPageItem,
        output_type: +ReportType,
    };

    const handleSearchFilter = () => {
        dispatch(CustomReportsList(reqBody));
    };

    const AccessIcon = () => <img src="/images/invoice/calendar.svg" alt="calendar" className="convertWhite" />;
    useEffect(() => {
        dispatch(GetCustomLabelInfo(+ReportId, orgId));
    }, []);

    const UserDateFormat = CurrentUser?.user_date;
    const decodedReportname = decodeURIComponent(ReportName);

    const dropdownShadow = currentMode !== "dark" ? "0px 4px 20px rgba(238, 238, 238, 0.5)" : "";
    const dropdownBorder = currentMode !== "dark" ? "223, 225, 226, 0.56" : "";
    const CloseOption = () => {
        dispatch({ type: CUSTOM_REPORTS_LIST_RESET });
    };
    FieldInfo?.forEach((field) => {
        // Find the corresponding element in filter_info based on column_name_c
        const matchingFilter = DataLabelList?.find((filter) => filter?.column_name_c === field?.column_name);

        // If a matching element is found, insert the Filter array

        if (matchingFilter) {
            matchingFilter.Filter = field?.Filter;
            matchingFilter.fiscal_end = field?.fiscal_end;
            matchingFilter.fiscal_start = field?.fiscal_start;
        }
    });

    const FucntionCall = async () => {
        const modifiedJson = DataLabelList?.map((obj) => {
            // Add the new key "column_value1" with default value ""
            obj.column_value1 = null;
            return obj;
        });

        DataLabelList?.forEach((filter) => {
            if (filter?.Filter && filter?.Filter.length > 0) {
                filter?.Filter.forEach((filterItem) => {
                    if (Array?.isArray(filterItem?.val1)) {
                        filterItem.val1 = filterItem.val1.map(({ value }) => ({ value }));
                    }
                    if (
                        filter?.column_value == "" &&
                        (filter?.data_type == "s" || filter?.data_type == "n" || filter?.data_type == "m" || filter?.data_type == "p")
                    ) {
                        filter.column_value = filterItem.val1 == "" ? null : [filterItem.val1];
                        setStringValue(filterItem.val1);
                    }
                    if (filter?.column_value == "" && filter?.data_type == "dd") {
                        const value =
                            filter?.column_name_c == "company_name"
                                ? filterItem.val1.map((el) => el?.value?.replace(/,/g, "*"))
                                : filterItem.val1.map((el) => el?.value);

                        const resultValue = [value?.join(",")];
                        filter.column_value = resultValue;
                        setStatusValue(resultValue);
                    }
                    if (filter?.column_value == "" && filter?.data_type == "b") {
                        filter.column_value = filterItem.val1 == "" ? null : [filterItem?.val1];
                        setDropdown(filterItem?.val1 == "true" ? "True" : "False");
                    }

                    if (filter?.column_value == "" && filter?.data_type == "d") {
                        const NullValue = filterItem?.operator == "Between" ? "Na" : null
                        const dateValue1 = filterItem.date_value1 == "" ? NullValue : filterItem?.val1 == "FY Start Date" ? [filter?.fiscal_start] : filterItem?.val1 == "FY End Date" ? [filter?.fiscal_end] : [filterItem?.date_value1]
                        filter.column_value = dateValue1
                        setDateValue(dateValue1)
                    }
                    if ((filter?.column_value1 == "" || filter?.column_value1 == null) && filter?.data_type == "d") {
                        const NullValue = filterItem?.operator == "Between" ? "Na" : null
                        const dateValue2 = filterItem.date_value2 == "" ? NullValue : filterItem?.val2 == "FY Start Date" ? [filter?.fiscal_start] : filterItem?.val2 == "FY End Date" ? [filter?.fiscal_end] : [filterItem?.date_value2]
                        filter.column_value1 = dateValue2
                        setToDateValue(dateValue2)
                    }
                });
            }
        });
        extractedData = DataLabelList?.map(({ column_name_c, column_value, column_value1 }) => ({ column_name_c, column_value, column_value1 }));
        if (DataLabelList?.length > 0) {
            const reqBody = {
                json: extractedData,
                id: +ReportId,
                pgno: currentPageNo,
                reccnt: currentPageItem,
                output_type: +ReportType,
            };
            await dispatch(CustomReportsList(reqBody));
        }
    };
    useEffect(() => {
        FucntionCall();
    }, [DataLabelList]);

    return (
        <>
            <Layout>
                <Main>
                    {fetching ? (
                        <Card className="h-[calc(100vh_-_18rem)] py-[30px] xl:h-[calc(100vh_-_8rem)] card">
                            <Loader className="justify-center flex" />
                        </Card>
                    ) : (
                        <>
                            <div className={`flex justify-between items-center mb-6 text-${currentMode}-copy`}>
                                <div>
                                    <h4 className="text-2xl font-interSb">{decodedReportname}</h4>
                                </div>
                                <div onClick={CloseOption}>
                                    <Link className="flex" to="/reports">
                                        <img className="mr-2 convertWhite" src="/images/main/noun-back.svg" alt="noun-back" /> Back
                                    </Link>
                                </div>
                            </div>

                            <Card className="p-[20px] mb-6">
                                <div className="grid grid-cols-1 xl:grid-cols-5 gap-2">
                                    {DataLabelList &&
                                        DataLabelList?.map((el, i) => {
                                            return (
                                                <>
                                                    {el?.data_type == "b" && (
                                                        <div>
                                                            <span className="block mb-[10px]">{el?.display_name}</span>
                                                            <FormControl fullWidth>
                                                                <Select
                                                                    value={dropDown}
                                                                    name="UserList"
                                                                    onChange={(e) => handleDropDownChange(e.target.value, i)}
                                                                    inputProps={{
                                                                        "aria-label": "Without label",
                                                                    }}
                                                                    fullWidth
                                                                    IconComponent={() => (
                                                                        <img
                                                                            className="absolute opacity-50 right-[15px] pointer-events-none"
                                                                            src={
                                                                                currentMode !== "dark"
                                                                                    ? `/images/header/chevronDown.svg`
                                                                                    : `/images/header/chevronDown-white.svg`
                                                                            }
                                                                        />
                                                                    )}
                                                                    MenuProps={{
                                                                        sx: {
                                                                            "& .MuiMenu-paper": {
                                                                                backgroundColor: dropdownColor,
                                                                                color: dropdownText,
                                                                                maxWidth: "245px",
                                                                            },
                                                                            "& .MuiMenuItem-root:hover": {
                                                                                backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                                            },
                                                                            "& .Mui-selected": {
                                                                                backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                                            },
                                                                        },
                                                                    }}
                                                                    SelectDisplayProps={{
                                                                        style: {
                                                                            paddingTop: "9px",
                                                                            paddingBottom: "9px",
                                                                            paddingLeft: "15px",
                                                                            color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                                            paddingRight: "0px",
                                                                            border: "1px solid rgba(85, 85, 85, 0.4)",
                                                                            borderRadius: "8px",
                                                                        },
                                                                    }}>
                                                                    <MenuItem key={0} name="UserList" value={0}>
                                                                        {"Select"}
                                                                    </MenuItem>
                                                                    {el?.dd_json &&
                                                                        el?.dd_json?.map((listelem, i) => {
                                                                            return (
                                                                                <MenuItem key={i + 1} name="Value" value={listelem?.value}>
                                                                                    {listelem?.value}
                                                                                </MenuItem>
                                                                            );
                                                                        })}
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                    )}

                                                    {el?.data_type == "dd" && (
                                                        <div className="filter">
                                                            <span className="block mb-[10px]">{el?.display_name}</span>
                                                            <Autocomplete
                                                                multiple
                                                                clearOnBlur={false}
                                                                id="checkboxes-tags-demo"
                                                                options={el?.dd_json || []}
                                                                onChange={(e, selectedOptions) => handleAutoChange(e, i, selectedOptions)}
                                                                limitTags={1}
                                                                disableCloseOnSelect
                                                                PaperComponent={({ children }) => (
                                                                    <Paper
                                                                        style={{
                                                                            backgroundColor: dropdownColor,
                                                                            color: dropdownText,
                                                                            boxShadow: dropdownShadow,
                                                                            border: dropdownBorder,
                                                                        }}>
                                                                        {children}
                                                                    </Paper>
                                                                )}
                                                                popupIcon={
                                                                    <img className="m-0 pointer-events-none" src="/images/header/chevronDown.svg" />
                                                                }
                                                                getOptionLabel={(status) => status.value}
                                                                isOptionEqualToValue={(option, value) => option?.value === value?.value}
                                                                renderOption={(props, option, { selected }) => (
                                                                    <li {...props}>
                                                                        <Checkbox
                                                                            size="small"
                                                                            sx={{
                                                                                color: "rgba(85,85,85,0.7)",
                                                                                borderRadius: "3px !important",
                                                                                "&.Mui-checked": {
                                                                                    color: "#028C78",
                                                                                },
                                                                            }}
                                                                            value={option?.value}
                                                                            checked={selected}
                                                                        />
                                                                        {option?.value}
                                                                    </li>
                                                                )}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        sx={{
                                                                            "& .MuiInputBase-input": {
                                                                                color: `${currentMode == "dark" ? "white" : "black"} !important`,
                                                                            },
                                                                        }}
                                                                        {...params}
                                                                        fullWidth
                                                                        InputProps={{
                                                                            ...params.InputProps,
                                                                            endAdornment: null,
                                                                        }}
                                                                        placeholder={el?.display_name}
                                                                    />
                                                                )}
                                                                defaultValue={
                                                                    // Place the default value you want to be initially selected in this array
                                                                    el?.Filter?.[0]?.val1
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                    {(el?.data_type == "s" ||
                                                        el?.data_type == "n" ||
                                                        el?.data_type == "m" ||
                                                        el?.data_type == "p") && (
                                                            <div>
                                                                <span className="block mb-[10px]">{el?.display_name}</span>
                                                                <Input
                                                                    type="text"
                                                                    value={el?.column_value}
                                                                    onChange={(e) => StringValueChange(e.target.value, i, el?.id)}
                                                                    className={`border-[1px] w-full  py-2 px-4  rounded-[8px] border-${currentMode}-840 bg-transparent`}
                                                                    placeholder={` ${el?.display_name}`}
                                                                />
                                                            </div>
                                                        )}

                                                    {el?.data_type == "d" && (
                                                        <div className="datePic" title="Calendar">
                                                            <span className="block mb-[10px]">
                                                                {el?.display_name + (el?.Filter?.[0]?.operator == "Between" ? " From" : "")}
                                                            </span>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DesktopDatePicker
                                                                    selectsStart
                                                                    inputFormat={UserDateFormat?.toUpperCase()}
                                                                    disableFuture={true}
                                                                    className="border-0"
                                                                    slotProps={{
                                                                        textField: { fullWidth: true },
                                                                    }}
                                                                    components={{
                                                                        OpenPickerIcon: AccessIcon,
                                                                    }}
                                                                    value={el?.column_value}
                                                                    minDate={new Date(1999, 12)}
                                                                    maxDate={new Date(lastDateOfYear)}
                                                                    name="dateValue"
                                                                    onChange={(e) => handleDateValue(e, i)}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            value={el?.column_value}
                                                                            sx={{
                                                                                "& .MuiInputBase-input": {
                                                                                    color: `${currentMode == "dark" ? "white" : "black"} !important`,
                                                                                },
                                                                            }}
                                                                            fullWidth
                                                                            placeholder={params?.inputProps?.placeholder?.replace("From")}
                                                                            {...params}
                                                                            inputProps={{
                                                                                ...params.inputProps,
                                                                                readOnly: false,
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                            </LocalizationProvider>
                                                        </div>
                                                    )}
                                                    {el?.data_type == "d" && el?.Filter?.[0]?.operator == "Between" && (
                                                        <div className="datePic" title="Calendar">
                                                            <span className="block mb-[10px]">{el?.display_name + " To"}</span>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DesktopDatePicker
                                                                    selectsStart
                                                                    inputFormat={UserDateFormat?.toUpperCase()}
                                                                    disableFuture={true}
                                                                    className="border-0"
                                                                    slotProps={{
                                                                        textField: { fullWidth: true },
                                                                    }}
                                                                    components={{
                                                                        OpenPickerIcon: AccessIcon,
                                                                    }}
                                                                    value={el?.column_value1}
                                                                    minDate={new Date(1999, 12)}
                                                                    maxDate={new Date(lastDateOfYear)}
                                                                    name="TodateValue"
                                                                    onChange={(e) => handleToDateValue(e, i)}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            value={el?.column_value1}
                                                                            sx={{
                                                                                "& .MuiInputBase-input": {
                                                                                    color: `${currentMode == "dark" ? "white" : "black"} !important`,
                                                                                },
                                                                            }}
                                                                            fullWidth
                                                                            placeholder={params?.inputProps?.placeholder?.replace("To")}
                                                                            {...params}
                                                                            inputProps={{
                                                                                ...params.inputProps,
                                                                                readOnly: false,
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                            </LocalizationProvider>
                                                        </div>
                                                    )}
                                                </>
                                            );
                                        })}
                                    <div className="flex items-end">
                                        <Button
                                            disabled={DisableSearch}
                                            onClick={handleSearchFilter}
                                            className={`bg-light-20 btn-primary rounded-[8px] text-white`}>
                                            Search
                                        </Button>
                                    </div>
                                </div>
                            </Card>

                            {/* <DashboardTile report={true} /> */}
                            {/* <ReportChart /> */}
                            {ReportType == 1 && (
                                <CustomReportsTable
                                    dropDown={dropDown}
                                    reqBody={reqBody}
                                    dataLabelInfo={dataLabelInfo}
                                    HeaderList={HeaderList}
                                    dataHeader={dataHeader}
                                    taskStatus={taskStatus}
                                    taskType={taskType}
                                    currentPageNo={currentPageNo}
                                    changePageNo={changePageNo}
                                    handleSearchFilter={handleSearchFilter}
                                    search={search}
                                    setSearch={setSearch}
                                    currentPageItem={currentPageItem}
                                    changePageItem={changePageItem}
                                    // listCount={listCount}
                                    extractedData={extractedData}
                                    reportName={decodedReportname}

                                // isSupplier={isSupplier}
                                // selAging={selAging}
                                // isStatus={isStatus}
                                // fromDate={fromDate}
                                // toDate={toDate}
                                // ageing={ageing}
                                // page={page}
                                // clickTrack={searchClick}
                                />
                            )}
                            {ReportType == 3 && (
                                <CustomChartsView
                                    dropDown={dropDown}
                                    reqBody={reqBody}
                                    ChartType={ChartType}
                                    HeaderList={HeaderList}
                                    dataHeader={dataHeader}
                                    taskStatus={taskStatus}
                                    taskType={taskType}
                                    currentPageNo={currentPageNo}
                                    changePageNo={changePageNo}
                                    handleSearchFilter={handleSearchFilter}
                                    search={search}
                                    setSearch={setSearch}
                                    currentPageItem={currentPageItem}
                                    changePageItem={changePageItem}
                                    // listCount={listCount}
                                    extractedData={extractedData}
                                    reportName={decodedReportname}
                                />
                            )}
                            {ReportType == 2 && (
                                <CustomMatrixTable
                                    dropDown={dropDown}
                                    reqBody={reqBody}
                                    HeaderList={HeaderList}
                                    dataHeader={dataHeader}
                                    taskStatus={taskStatus}
                                    taskType={taskType}
                                    currentPageNo={currentPageNo}
                                    changePageNo={changePageNo}
                                    handleSearchFilter={handleSearchFilter}
                                    search={search}
                                    setSearch={setSearch}
                                    currentPageItem={currentPageItem}
                                    changePageItem={changePageItem}
                                    // listCount={listCount}
                                    extractedData={extractedData}
                                    reportName={decodedReportname}
                                />
                            )}
                        </>
                    )}
                </Main>
                <Footer />
            </Layout>
        </>
    );
};

export default CustomReports;
