import { useContext, useMemo } from "react";
import Main from "../../components/UI/Main";
import Layout from "../../Hoc/Layout";
import Card from "../../components/UI/Card";
import Footer from "../../organisms/Footer";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import Button from "../../components/Button";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import StatusPopper from "./statusPopper";
import { v4 as uuidv4 } from "uuid";
import CreateEditStatusName from "./CreateEditStatusName";
import ReactDOM from "react-dom";
import NotificationAllocation from "./WorkflowConfiguration/NotificationAllocation";
import { encryptId } from "../../utils/helpers";
import PermissionsModal from "./WorkflowConfiguration/PermissionsModal";
import UsersModal from "./WorkflowConfiguration/UsersModal";
import FournotFour from "../404";
import { ErrorBoundary } from "react-error-boundary";
import EditBox from "../../components/UserCard/EditBox";
import { useDispatch } from "react-redux";
import StatusInputField from "./StatusInputField";
import _, { cloneDeep } from "lodash";
import Swal from "sweetalert2";
import ColourSelector from "./ColourSelector";
import DynamicProfile from "./dynamicProfile";
import {
    getWorkFlowDataByOrg,
    postWorkFlowByOrg,
    DeleteWorkFlowStatus,
    getWorkFlowPermissionLayoutByOrg,
} from "../../redux/actions/workflow-automation";
import { useSelector } from "react-redux";
import { POST_WORKFLOW_AUTOMATION_BYORG_RESET, WORKFLOW_STATUS_DELETE_RESET } from "../../redux/types/types";
import Loader from "../../components/Loader/index.jsx";
import MessageBox from "../../components/UI/Alert/index.jsx";
import { MenuItem, Select } from "@mui/material";

let getId;
let defaultVal = "";
let createDisplayOrder;
let deleteID = null;

const ErrorFallback = ({ error }) => {
    return (
        <>
            <FournotFour />
        </>
    );
};
const WorkflowAutomation = () => {
    //* Variable Decleartion
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const outsideCreate = [
        {
            id: 1,
            src: "/images/workflow-automation/add-status.svg",
            name: "Add Status",
        },
        {
            id: 2,
            src: "/images/workflow-automation/add-conditions.svg",
            name: "Add Conditional Status",
        },
    ];
    const insideCreate = [
        {
            id: 2,
            src: "/images/workflow-automation/add-conditions.svg",
            name: "Add Conditional Status",
        },
    ];
    const mainStatusCreate = [
        {
            id: 1,
            src: "/images/workflow-automation/add-status.svg",
            name: "Add Status",
        },
    ];
    const [selectedId, setSelectedId] = useState(null);
    const dispatch = useDispatch();
    const { data: Data, requestComplete } = useSelector((state) => state?.getWorkFlowDataState);
    const { data: dataList } = useSelector((state) => state?.getWorkFlowPermissionByOrgState);
    const { fetching: postFetch, requestComplete: postReqComp, data: postData } = useSelector((state) => state?.postWorkFlowByOrgState);
    const { requestComplete: deleteReqComp, error: deleteError, data: deleteData } = useSelector((state) => state?.workFlowStatusDeleteState);
    const DisplayRole = useSelector((state) => state?.DisplayRolePermissionStateAction);
    const { data: DataList } = DisplayRole;
    const RoleList = DataList?.role_info;
    const RoleMenu = RoleList?.menu;
    const RoleAdvance = RoleMenu?.find((elem) => elem?.menu_id === 21);
    const RoleAdvanceAction = RoleAdvance?.actions;
    const RoleActAdvance = RoleAdvanceAction?.find((element) => element.id === 3)?.isallowed;
    const RoleMisc = RoleMenu?.find((elem) => elem?.menu_id === 22);
    const RoleMiscAction = RoleMisc?.actions;
    const RoleActMisc = RoleMiscAction?.find((element) => element.id === 3)?.isallowed;
    const RoleReten = RoleMenu?.find((elem) => elem?.menu_id === 23);
    const RoleRetenAction = RoleReten?.actions;
    const RoleActReten = RoleRetenAction?.find((element) => element.id === 3)?.isallowed;
    const [workflowData, setWorkFlowData] = useState();
    const [updatedPermissionData, setUpdatedPermissionData] = useState([]);
    const orgId = localStorage.getItem("orgId");
    const user = localStorage?.getItem("user");
    const licenseType = Data?.license_type || 3;
    const [documentGroup, setDcoumetGroup] = useState(1);
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    //get workflow data based on organisation
    useEffect(() => {
        if (orgId) {
            dispatch(getWorkFlowDataByOrg(orgId, documentGroup));
            setSelectedId(null);
            setActiveParams(false);
        }
    }, [orgId, documentGroup]);
    useEffect(() => {
        if (Data) {
            setWorkFlowData(Data);
            // Create a new array with modified objects
            const newArray = _.map(Data?.work_flow, (obj) => ({
                ...obj,
                inside_id: uuidv4(),
                outside_id: uuidv4(),
            }));

            const sortedWorkflow = _.sortBy(newArray, "disp_order");
            setCheck(sortedWorkflow);
        }
    }, [Data]);
    //* For donut chart
    const data = [
        { name: "Submitted", value: 400 },
        { name: "Managing Director Approval", value: 300 },
        { name: "Finance Manager Approval Finance approval", value: 300 },
        { name: "CFO Approval", value: 200 },
    ];
    //* Static Variable --> Change this state with reducer
    const sortedWorkflow = _.sortBy(workflowData?.work_flow, "disp_order");
    const cloneData = _.cloneDeep(sortedWorkflow);
    const [check, setCheck] = useState(sortedWorkflow);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [editAnchorEl, setEditAnchorEl] = useState(null);
    const [colourSelectorAnchor, setColourSelectorAnchor] = useState(null);
    const colorSelectorOpen = Boolean(colourSelectorAnchor);
    //* Variables for workflow Configuration
    const [params, setParams] = useState([
        {
            id: 1,
            name: "Users",
            isActive: false,
            message: "Users are part of the organization to fulfill specific workflow tasks. A user can hold multiple roles and add various rules.",
        },
        {
            id: 2,
            name: "Permissions",
            isActive: false,
            message: "Permissions dictate the level of access and actions for users or roles within an organization.",
        },
        {
            id: 3,
            name: "Notification",
            isActive: false,
            message:
                "Notifications are automated communications sent to individuals or groups to inform them about the status, progress, or specific events within a business process.",
        },
    ]);
    const filterParams = params?.filter((el) => el?.id !== 2);
    const MapParams = documentGroup == 1 || documentGroup == 2 ? params : filterParams;
    // const noActiveParams = selectedId !== null && params?.find((item) => item?.isActive == true);
    const [noActiveParams, setActiveParams] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState();
    useEffect(() => {
        if (selectedId !== null) {
            const getArr = check.find((el, i) => i == selectedId);
            setSelectedStatus(getArr);
            if (getArr?.master_refid == 3 || getArr?.master_refid == 4) {
                setActiveParams(false);
            } else {
                setActiveParams(params?.find((item) => item?.isActive == true));
            }
        }
    }, [selectedId, params]);

    const messageToShow = (
        params?.find((item) => item?.isActive == true) ?? {
            message: "Start your workflow configuration by adding multiple roles and rules before the approval process",
        }
    )?.message;

    //* Variables for Notification
    const [receivers, setReceivers] = useState([]);
    const [bccreceivers, setBccRecievers] = useState([]);
    const [notificationenable, setnotificationEnable] = useState(false);
    const [Title, setTitle] = useState();
    const [message, setMessage] = useState("");
    const [Emailenable, setemailEnable] = useState();
    const [subject, setSubject] = useState("");
    const [emailMessage, setEmailmessage] = useState();
    const [reset, setReset] = useState(false);
    const divBorder = currentMode == "dark" ? "#FFFFFF" : "#E4E4E4";

    //* Function Calls for tree structure
    const handleDivClick = (index) => {
        setCheck((prevCheck) =>
            prevCheck.map((item, checkIndex) => ({
                ...item,
                isActive: checkIndex === index,
            }))
        );
        setSelectedId(index);
        setParams((prevCheck) =>
            prevCheck.map((item, checkIndex) => ({
                ...item,
                isActive: item?.name == "Users",
            }))
        );
        setColourSelectorAnchor(false);
        if (index !== selectedId) {
            setIsStatusEdit(false);
        }
    };
    //to hide the popper
    const handleClickAway = () => {
        getId = null;
        setAnchorEl(false);
        createDisplayOrder = null;
    };
    const handleDocumentChange = (value) => {
        if (value !== 0) {
            setDcoumetGroup(value);
        }
    };
    const [isStatusEdit, setIsStatusEdit] = useState(false);
    const [createStatusType, setCreateStatusType] = useState(null);
    //click to enable edit field for status name
    const openEdit = (item, index, isEdit) => {
        defaultVal = item?.status_name;
        handleClickAway();
        if (isEdit === "edit") {
            setIsStatusEdit(true);
        } else {
            setEditAnchorEl(true);
            setIsStatusEdit(false);
            setCreateStatusType(isEdit);
            createDisplayOrder = index + 1;
        }
    };
    //update the status name by click confirm
    const updateUser = (value, id, ind) => {
        const isDuplicate = check?.filter((item, index) => index != ind)?.some((ele) => ele?.status_name?.toLowerCase() === value?.toLowerCase());
        if (isDuplicate || value == "" || value?.length > 30) {
            Swal.fire({
                text: `${
                    value?.length === 0
                        ? "Status Name cannot be empty"
                        : value?.length > 30
                        ? "Status Name should be minimum 3 to maximum 30 characters"
                        : "Status Name cannot be same"
                }`,
                icon: "warning",
                allowOutsideClick: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            }).then((confirm) => {
                if (confirm) {
                    check?.forEach((ele, index) => {
                        if (index === ind) {
                            ele.status_name = "";
                            setIsStatusEdit(true);
                        }
                    });
                }
            });
        } else {
            check.forEach((ele, index) => {
                if (index == ind) {
                    ele.status_name = value;
                }
            });
            setIsStatusEdit(false);
        }
    };
    //remove the status based on id index
    const handleDeleteStatus = (obj, ind) => {
        if (obj) {
            Swal.fire({
                text: "Are you sure want to delete the Status?",
                icon: "warning",
                allowOutsideClick: false,
                showCancelButton: true,
                showCloseButton: true,
                buttons: {
                    cancel: "Cancel",
                    confirm: "Confirm",
                },
            }).then((confirm) => {
                if (confirm?.isConfirmed) {
                    setActiveParams(false);
                    if (!obj?.is_default) {
                        if (obj?.id !== 0) {
                            dispatch(DeleteWorkFlowStatus(obj?.id, orgId));
                            deleteID = obj?.id;
                        } else if (obj?.id == 0) {
                            setCheck((prevCheck) => {
                                // Filter out the object with id 0 at the specified index
                                const filteredCheck = prevCheck.filter((ele, index) => index !== ind);
                                // Update the disp_order values based on the new array positions
                                const updatedCheck = filteredCheck.map((ele, index) => ({
                                    ...ele,
                                    disp_order: index + 1,
                                }));
                                return updatedCheck;
                            });
                        }
                    }
                }
            });
        }
    };
    //click to active the user , permission, notification module based on index
    const handleSelectModule = (index, id) => {
        setParams((prevCheck) =>
            prevCheck.map((item, checkIndex) => ({
                ...item,
                isActive: item?.id === id,
            }))
        );
        closeUpdate();
    };
    //discard the edited or open edited field for status name
    const closeUpdate = (value, id, i) => {
        check.forEach((ele) => {
            if (ele?.id == id) {
                ele.status_name = defaultVal;
            }
        });
        setIsStatusEdit(false);
    };
    // to colour picker open
    const colourSelectorClickAway = () => {
        setColourSelectorAnchor(false);
    };

    //to select the colour on picker based on id for specific status
    const changeColour = (id, bgColour) => {
        setCheck((prev) => {
            return prev.map((item, index) => {
                if (index == id) {
                    return { ...item, status_color: bgColour };
                } else {
                    return item;
                }
            });
        });
    };
    //check status length on every new creation
    const checkStatusDataLength = () => {
        Swal.fire({
            text: "unable to create a status more than twenty.",
            icon: "warning",
            allowOutsideClick: false,
            showCloseButton: true,
            buttons: {
                confirm: "Confirm",
            },
        });
    };
    //to open status create field
    const handleCreateId = (Id, val) => {
        getId = Id;
        if (check.length >= 20) {
            checkStatusDataLength();
        } else {
            if (dataList?.field_list?.length == undefined) {
                dispatch(getWorkFlowPermissionLayoutByOrg(orgId));
            }
            setAnchorEl(val);
        }
    };
    let isRuleFlagTrue = true;
    const oneRuleCondition = (status_name, status_type) => {
        const config = {
            icon: "warning",
            closeOnClickOutside: false,
            showCloseButton: true,
            button: "Ok",
            html: true, // Enable HTML rendering
        };
        if (status_type == 2) {
            config.html = `<p>At least one rule needs to be selected for the conditional status - ${status_name}.</p>`;
        } else if (status_type == 1) {
            updatedArr?.map((el) => {
                if (el?.notification) {
                    if (el?.notification?.push_flag && (!el?.notification?.push_title || !el?.notification?.push_message)) {
                        config.html = `<p>Fill in all the fields in the Push Notification for the <b>${el?.status_name}</b> status.</p>`;
                        // return true;
                    } else if (
                        el?.notification?.email_flag &&
                        (!el?.notification?.email_body || !el?.notification?.email_subject || el?.notification?.email_body == `<p><br></p>`)
                    ) {
                        config.html = `<p>Fill in the Subject and Body fields in the Email Notification for the <b>${el?.status_name}</b> status.</p>`;
                        // return true;
                    } else {
                        return false;
                    }
                }
            });
        }

        if (config.html !== true) {
            Swal.fire(config);
            return true;
        }
    };
    // Define a function to check conditions for a single user_set item
    const checkConditions = (item, mainObj, updatedArr) => {
        if (mainObj?.status_type == 2) {
            // Assuming mainObj.user_set is your array
            isRuleFlagTrue = _.some(mainObj?.user_set, { rule_flag: true });
        }
        if (!mainObj?.user_set && mainObj?.status_type != 2) return false;
        const config = {
            icon: "warning",
            closeOnClickOutside: false,
            showCloseButton: true,
            button: "Ok",
            html: true, // Enable HTML rendering
        };

        if (!item?.rule_flag && !item?.user_flag && !item?.role_flag && !item?.round_robin) {
            config.html = `<p>Select any one of the following rules checkbox in <strong class="font-interB">${
                mainObj.status_name
            }</strong> status and before clicking the <strong class="font-interB">Save</strong> button.</p>
            <br/>
            <p class="pb-[2px]"> - Round Robin</p> 
            ${licenseType != 3 ? "<p class='pb-[2px]'> - Rule Based</p>" : ""}
            <p class="pb-[2px]"> - Role Based</p>
            <p class="pb-[2px]"> - Select Users</p>`;
        } else if (mainObj?.status_type == 2 && !isRuleFlagTrue) {
            config.html = `<p>At least one rule needs to be selected in the conditional status where the ${mainObj.status_name}!</p>`;
        }
        //  else if (mainObj.status_type === 2 && !item.rule_flag && !item.user_flag) {
        //     config.html = `<p>Rule or Users must be selected for conditional status - ${mainObj.status_name}</p>`;
        // }
        else if (mainObj.status_type === 2 && !item.rule_flag) {
            config.html = `<p>Rule must be selected for conditional status - ${mainObj.status_name}</p>`;
        } else if (item.rule_flag && item.rule_id === 0) {
            config.html = `${
                mainObj.status_type === 2
                    ? `<p>A rule must be selected for the conditional status - ${mainObj.status_name}.</p>`
                    : `<p>Please select a rule for the status - ${mainObj.status_name}.</p>`
            }`;
        } else if (item.role_flag && item.role_id === 0) {
            config.html = `${
                mainObj.status_type === 2
                    ? `<p>Role must be selected for conditional status - ${mainObj.status_name}</p>`
                    : `<p>Please select a role for the status - ${mainObj.status_name}.</p>`
            }`;
        } else if (item.rule_flag && item.rule_id !== 0 && !item.role_flag && (!item.rule_user || item.rule_user.length === 0)) {
            config.html = `${
                mainObj.status_type === 2
                    ? `<p>A user must be selected based on the rule for the conditional status - ${mainObj.status_name}.</p>`
                    : `<p>Please select users based on the rule - ${mainObj.status_name}.</p>`
            }`;
        } else if (item.user_flag && (!item.user_list || item.user_list.length === 0)) {
            config.html = `${
                mainObj.status_type === 2
                    ? `<p>A user must be selected for the conditional status - ${mainObj.status_name}.</p>`
                    : `<p>Please select users - ${mainObj.status_name}.</p>`
            }`;
        } else {
            updatedArr?.map((el) => {
                if (el?.notification) {
                    if (el?.notification?.push_flag && (!el?.notification?.push_title || !el?.notification?.push_message)) {
                        config.html = `<p>Fill in all the fields in the Push Notification for the <b>${el?.status_name}</b> status.</p>`;
                        // return true;
                    } else if (
                        el?.notification?.email_flag &&
                        (!el?.notification?.email_body || !el?.notification?.email_subject || el?.notification?.email_body == `<p><br></p>`)
                    ) {
                        config.html = `<p>Fill in the Subject and Body fields in the Email Notification for the <b>${el?.status_name}</b> status.</p>`;
                        // return true;
                    } else {
                        return false;
                    }
                }
            });
        }
        // Check if there's an HTML message to display
        if (config.html !== true) {
            Swal.fire(config);
            return true;
        }
    };

    //add update post API call
    // Use _.map to iterate through each object and _.omit to remove properties
    const updatedArr = cloneDeep(_.map(check, (obj) => _.omit(obj, ["inside_id", "outside_id", "isActive"])));
    const handleSubmitWorkFlow = () => {
        // Use _.differenceWith to get the difference between updatedArr and clonedData for each main object
        const updatedArrWithoutDuplicates = updatedArr.map((mainObj, index) => {
            const clonedObj = cloneData[index];
            mainObj.notification.email_bcc =
                mainObj.notification.email_bcc?.length > 0 ? mainObj.notification.email_bcc?.map((i) => encryptId(i)) : [];
            mainObj.notification.email_cc = mainObj.notification.email_cc?.length > 0 ? mainObj.notification.email_cc?.map((i) => encryptId(i)) : [];
            if (clonedObj && clonedObj.permission.field_list) {
                // Use _.differenceWith to get the difference between field_list arrays
                const updatedFieldListWithoutDuplicates = _.differenceWith(mainObj.permission.field_list, clonedObj.permission.field_list, _.isEqual);
                // Clone the main object and update the field_list
                return {
                    ...mainObj,
                    permission: {
                        ...mainObj.permission,
                        field_list: updatedFieldListWithoutDuplicates,
                    },
                };
            }

            // If there is no cloned object or field_list, return the original main object
            return mainObj;
        });
        const reqObj = {
            orgid: +orgId,
            work_flow: updatedArrWithoutDuplicates,
            docgroup: +documentGroup,
        };
        // Use some to check if atleast one condition is true for atleast one object in any user_set array of all main objects
        const shouldPreventApiCall = updatedArr.some((mainObj) => {
            const config = {
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                button: "Ok",
                html: true, // Enable HTML rendering
            };
            if (config.html !== true) {
                Swal.fire(config);
                return true;
            }
            const Obj = mainObj?.master_refid !== 3 && mainObj?.master_refid !== 4 && mainObj;
            return !Obj?.user_set || Obj?.user_set?.length == 0
                ? // && mainObj?.status_type == 2
                  oneRuleCondition(mainObj?.status_name, mainObj?.status_type)
                : Obj?.user_set?.some((item) => checkConditions(item, mainObj, updatedArr));

            // Add more conditions as needed
        });
        if (!shouldPreventApiCall) {
            // If the condition is not met for any object, proceed with the API call
            dispatch(postWorkFlowByOrg(reqObj));
        }
    };
    //message display based on API response
    useEffect(() => {
        if (postReqComp) {
            if (postReqComp && postData?.status) {
                Swal.fire({
                    text: `${postData?.message}`,
                    icon: "success",
                    closeOnClickOutside: false,
                    showCloseButton: true,
                    button: "Ok",
                }).then((confirm) => {
                    if (confirm) {
                        dispatch({ type: POST_WORKFLOW_AUTOMATION_BYORG_RESET });
                        // window.location.reload();
                        setSelectedId(null);
                        dispatch(getWorkFlowDataByOrg(orgId, documentGroup));
                        setActiveParams(false);
                    }
                });
            }
            if (postReqComp && !postData?.status) {
                Swal.fire({
                    text: `${postData?.message}`,
                    icon: "warning",
                    closeOnClickOutside: false,
                    showCloseButton: true,
                    button: "Ok",
                }).then((confirm) => {
                    if (confirm) {
                        dispatch({ type: POST_WORKFLOW_AUTOMATION_BYORG_RESET });
                        // window.location.reload();
                        // dispatch(getWorkFlowDataByOrg(orgId));
                    }
                });
            }
        }
        if (deleteReqComp && deleteData?.status) {
            Swal.fire({
                text: `${deleteData?.message}`,
                icon: "success",
                closeOnClickOutside: false,
                showCloseButton: true,
                button: "Ok",
            }).then((confirm) => {
                if (confirm) {
                    dispatch({ type: WORKFLOW_STATUS_DELETE_RESET });
                    setCheck((prevCheck) => {
                        // Filter out the object with id 0 at the specified index
                        const filteredCheck = prevCheck?.filter((ele, index) => {
                            return ele?.id != deleteID;
                        });
                        // Update the disp_order values based on the new array positions
                        const updatedCheck = filteredCheck.map((ele, index) => ({
                            ...ele,
                            disp_order: index + 1,
                        }));
                        return updatedCheck;
                    });
                    setSelectedId(null);
                }
            });
        }
    }, [postReqComp, deleteReqComp, documentGroup]);
    useMemo(() => {
        if (deleteError) {
            Swal.fire({
                text: `${deleteError?.message}`,
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                button: "Ok",
            }).then((confirm) => {
                if (confirm) {
                    dispatch({ type: WORKFLOW_STATUS_DELETE_RESET });
                }
            });
        }
    }, [deleteError]);

    return (
        <>
            <Layout>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Main>
                        <div className="mb-5 flex justify-between">
                            <div>
                                <h4 className={`text-2xl font-interSb text-${currentMode}-copy`}>Workflow Automation</h4>
                            </div>

                            <div className=" flex text-right">
                                <Link
                                    tabIndex={0}
                                    to="/admin-setting"
                                    // onClick={}
                                    title="Cancel"
                                >
                                    {" "}
                                    <Button className="bg-light-240 text-light-40 text-base rounded-[8px] py-2 px-5">Cancel</Button>
                                </Link>
                                <Button
                                    tabIndex={0}
                                    className="bg-light-20 text-white text-base rounded-[8px] ml-2 py-2 px-7"
                                    onClick={handleSubmitWorkFlow}
                                    disabled={postFetch}
                                    title={"Save"}
                                >
                                    {"Save"}
                                </Button>
                            </div>
                        </div>
                        {check && (
                            <div className="xl:flex xl:space-x-3">
                                <Card
                                    className={` basis-[60%] bg-[linear-gradient(to_right,#80808012_0.1px,transparent_1px),linear-gradient(to_bottom,#80808012_0.1px,transparent_1px)] bg-[size:12px_12px] xl:p-[30px] p-4 border-[1px]  border-[rgba(223,225,226,0.56)] xl:h-[calc(100vh_-_13rem)] h-[calc(100vh_-_17rem)] overflow-y-auto xl:overflow-x-hidden  ${
                                        currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                                    }`}
                                    style={{
                                        backgroundColour: "#FCFCFC",
                                    }}
                                >
                                    <span className="Inter-Regular ">
                                        Workflow automation utilizes rule-based logic to streamline, automate, and optimize your organization's
                                        repetitive business processes and tasks.
                                    </span>
                                    <br className="mt-10"></br>
                                    <div className="mt-[30px]">
                                        <div className="flex items-center justify-center mb-5 mt-3">
                                            <div className="flex flex-col">
                                                <span className="font-inter mb-2 font-medium text-base">Document Type:</span>
                                                <Select
                                                    value={documentGroup || "0"}
                                                    name="documentGroup"
                                                    onChange={(e) => handleDocumentChange(e.target.value)}
                                                    inputProps={{
                                                        "aria-label": "Without label",
                                                    }}
                                                    className="relative"
                                                    fullWidth
                                                    IconComponent={() => (
                                                        <img
                                                            className="absolute right-[15px] top-[50%] translate-y-[-50%] opacity-50 pointer-events-none"
                                                            src={
                                                                currentMode !== "dark"
                                                                    ? `/images/header/chevronDown.svg`
                                                                    : `/images/header/chevronDown-white.svg`
                                                            }
                                                            alt="chevronDown"
                                                        />
                                                    )}
                                                    MenuProps={{
                                                        sx: {
                                                            "& .MuiMenu-paper": {
                                                                backgroundColor: dropdownColor,
                                                                color: dropdownText,
                                                            },
                                                            "& .MuiMenuItem-root:hover": {
                                                                backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                            },
                                                            "& .Mui-selected": {
                                                                backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                            },
                                                        },
                                                    }}
                                                    SelectDisplayProps={{
                                                        style: {
                                                            paddingTop: "9px",
                                                            paddingBottom: "9px",
                                                            color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                            paddingRight: "30px",
                                                            maxWidth: "343px",
                                                            minWidth: "343px",
                                                            border: `1px solid rgba(85,85,85,0.4)`,
                                                            borderRadius: "8px",
                                                        },
                                                    }}
                                                >
                                                    {/* {DocumentList &&
											DocumentList?.map((listelem, i) => {
												Title = listelem?.master_name + "(" + listelem?.master_preview + ")";
												return (
													<MenuItem
														key={i + 1}
														name="DocumentType"
														title={Title}
														value={listelem?.master_id}
														className="truncate trunc-1 block"
													>
														{listelem?.master_name}({listelem?.master_preview})
													</MenuItem>
												);
											})} */}
                                                    <MenuItem value={"1"} className="truncate trunc-1 block">
                                                        Invoice
                                                    </MenuItem>
                                                    {RoleActAdvance && (
                                                        <MenuItem value={"2"} className="truncate trunc-1 block">
                                                            Advance Request
                                                        </MenuItem>
                                                    )}
                                                    {RoleActMisc && (
                                                        <MenuItem value={"3"} className="truncate trunc-1 block">
                                                            Miscellaneous Payment Request
                                                        </MenuItem>
                                                    )}
                                                    {RoleActReten && (
                                                        <MenuItem value={"4"} className="truncate trunc-1 block">
                                                            Retention Release
                                                        </MenuItem>
                                                    )}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-col  flex mt-10 w-full items-center justify-center" key={"whole-chart"}>
                                        {requestComplete && check?.length !== 0 ? (
                                            check?.map((item, index) => (
                                                <>
                                                    <div title={item?.status_name} className="flex">
                                                        {item?.status_type == 2 && (
                                                            <img src="/images/workflow-automation/LongLine.svg" className="ml-[52px] " />
                                                        )}
                                                        <div
                                                            className={`flex items-center relative w-full ${
                                                                currentMode == "dark" ? "bg-black" : "bg-white"
                                                            } cursor-pointer min-w-[390px] max-w-[390px] Inter-Medium rounded-[8px] max-h-[78px]
                                                         ${
                                                             currentMode === "dark"
                                                                 ? "shadow-[0px_4px_4px_0px_rgba(0,0,0,0.10),0px_4px_4px_0px_rgba(0,0,0,0.06)]"
                                                                 : "shadow-[0px_4px_4px_0px_rgba(0,0,0,0.10),0px_4px_4px_0px_rgba(0,0,0,0.06)] "
                                                         }
                                                        ${item?.isActive ? `border-[3px] border-[${item?.status_color}]  ` : "border-[1px] "}
                                                        ${item?.status_type == 2 ? "ml-[20px]" : ""} `}
                                                            onClick={() => {
                                                                handleDivClick(index);
                                                            }}
                                                            key={item?.status_name}
                                                            style={{
                                                                border: item?.isActive
                                                                    ? `3px solid ${item?.status_color}`
                                                                    : `1px solid ${divBorder} `,
                                                            }}
                                                        >
                                                            <DynamicProfile backgroundColour={item?.status_color} masterId={item?.master_refid} />
                                                            {isStatusEdit ? (
                                                                <StatusInputField
                                                                    item={item}
                                                                    dynamicClass={`${
                                                                        currentMode == "dark" && "text-white bg-transparent"
                                                                    } status_name-${item?.id} max-w-[200px] block truncate px-[10px] pr-[10px] `}
                                                                    ind={index}
                                                                    EditBox={EditBox}
                                                                    updateUser={updateUser}
                                                                    closeUpdate={closeUpdate}
                                                                    isStatusEdit={isStatusEdit}
                                                                />
                                                            ) : (
                                                                <span title={item?.status_name} className={`max-w-[200px] block truncate px-[10px] `}>
                                                                    {item?.status_name}
                                                                </span>
                                                            )}
                                                            {item?.isActive && !isStatusEdit && (
                                                                <img
                                                                    title="To Edit Status Name"
                                                                    src="/images/workflow-automation/edit.svg"
                                                                    className={`z-10 cursor-pointer pr-[10px]`}
                                                                    onClick={(event) => {
                                                                        event.stopPropagation();
                                                                        openEdit(item, index, "edit");
                                                                    }}
                                                                />
                                                            )}
                                                            {item?.isActive && !isStatusEdit && (
                                                                <img
                                                                    title="To Select Colour Theme"
                                                                    src={
                                                                        !colourSelectorAnchor
                                                                            ? "/images/workflow-automation/colour-inactive.svg"
                                                                            : "/images/workflow-automation/colour-active.svg"
                                                                    }
                                                                    className={`z-10 cursor-pointer pr-[5px]`}
                                                                    onClick={(event) => {
                                                                        event.stopPropagation();
                                                                        setColourSelectorAnchor({
                                                                            anchor: event.currentTarget.parentNode,
                                                                            id: index,
                                                                        });
                                                                    }}
                                                                />
                                                            )}
                                                            {item?.isActive && !item?.is_default && (
                                                                <img
                                                                    src="/images/workflow-automation/delete.svg"
                                                                    className={`z-10 cursor-pointer absolute right-[0] px-[8px] py-[8px]`}
                                                                    style={{ alignSelf: "flex-start" }}
                                                                    onClick={(event) => {
                                                                        event.stopPropagation();
                                                                        handleDeleteStatus(item, index);
                                                                    }}
                                                                />
                                                            )}
                                                            {colourSelectorAnchor && colourSelectorAnchor?.id == index && (
                                                                <ColourSelector
                                                                    id={index}
                                                                    anchorEl={colourSelectorAnchor?.anchor}
                                                                    placement={"bottom"}
                                                                    open={colorSelectorOpen}
                                                                    handleClickAway={colourSelectorClickAway}
                                                                    currentMode={currentMode}
                                                                    type={item?.status_type}
                                                                    colour={item?.status_color}
                                                                    changeColour={changeColour}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>

                                                    {index != check?.length - 1 && (
                                                        <div className="flex ">
                                                            {!(check?.[index]?.status_type == 1 && check?.[index + 1]?.status_type == 1) && (
                                                                <div className="flex min-h-[70px] w-[40px] ">
                                                                    <div className="relative w-[40px]">
                                                                        <img src="/images/workflow-automation/Line.svg" />
                                                                        {!(
                                                                            check?.[index]?.status_type == 2 &&
                                                                            check?.[index + 1]?.status_type == 2 &&
                                                                            licenseType != 3
                                                                        ) && (
                                                                            <>
                                                                                <img
                                                                                    title="To Create Status"
                                                                                    name="plus_icon"
                                                                                    id={item?.outside_id}
                                                                                    src={`${
                                                                                        getId == item?.outside_id
                                                                                            ? "/images/workflow-automation/plus-active.svg"
                                                                                            : "/images/workflow-automation/plus-inactive.svg"
                                                                                    }`}
                                                                                    className="z-10 w-[22px] h-[22px] cursor-pointer absolute top-[50%] translate-y-[-50%] left-[-10px]"
                                                                                    onClick={(event) =>
                                                                                        handleCreateId(item?.outside_id, event.target)
                                                                                    }
                                                                                />
                                                                                {getId == item?.outside_id && (
                                                                                    <StatusPopper
                                                                                        id={index}
                                                                                        anchorEl={anchorEl}
                                                                                        placement={"bottom"}
                                                                                        open={open}
                                                                                        handleClickAway={handleClickAway}
                                                                                        currentMode={currentMode}
                                                                                        buttons={
                                                                                            !(
                                                                                                check?.[index]?.status_type == 1 &&
                                                                                                check?.[index + 1]?.status_type == 1
                                                                                            )
                                                                                                ? mainStatusCreate
                                                                                                : outsideCreate
                                                                                        }
                                                                                        openEdit={openEdit}
                                                                                        type={1}
                                                                                        itemData={item}
                                                                                    />
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <div
                                                                className={`flex min-h-[70px] w-[40px] ${
                                                                    !(check?.[index]?.status_type == 1 && check?.[index + 1]?.status_type == 1)
                                                                        ? "mr-[280px]"
                                                                        : "mr-[240px]"
                                                                }  `}
                                                            >
                                                                <div className="relative w-[40px]">
                                                                    <img src="/images/workflow-automation/Line.svg" />
                                                                    {!(index == check?.length - 2) && licenseType != 3 && (
                                                                        <div key={index}>
                                                                            <img
                                                                                id={item?.inside_id}
                                                                                title="To Create Status"
                                                                                name="plus_icon"
                                                                                src={`${
                                                                                    getId == item?.inside_id
                                                                                        ? "/images/workflow-automation/plus-active.svg"
                                                                                        : "/images/workflow-automation/plus-inactive.svg"
                                                                                }`}
                                                                                className="z-10 w-[22px] h-[22px] cursor-pointer absolute top-[50%] translate-y-[-50%] left-[-10px]"
                                                                                onClick={(event) => handleCreateId(item?.inside_id, event.target)}
                                                                            />
                                                                            {getId == item?.inside_id && (
                                                                                <StatusPopper
                                                                                    id={index}
                                                                                    anchorEl={anchorEl}
                                                                                    placement={"bottom"}
                                                                                    open={open}
                                                                                    handleClickAway={handleClickAway}
                                                                                    currentMode={currentMode}
                                                                                    buttons={
                                                                                        !(
                                                                                            check?.[index]?.status_type == 1 &&
                                                                                            check?.[index + 1]?.status_type == 1
                                                                                        )
                                                                                            ? insideCreate
                                                                                            : outsideCreate
                                                                                    }
                                                                                    openEdit={openEdit}
                                                                                    type={1}
                                                                                    itemData={item}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                            ))
                                        ) : (
                                            <Loader className="h-[calc(100vh_-5rem)]" />
                                        )}
                                    </div>
                                    {editAnchorEl && (
                                        <>
                                            {ReactDOM.createPortal(
                                                <CreateEditStatusName
                                                    workflowData={workflowData}
                                                    handleDivClick={handleDivClick}
                                                    StatusList={check}
                                                    setStatusList={setCheck}
                                                    isOpened={editAnchorEl}
                                                    setEditAnchorEl={setEditAnchorEl}
                                                    currentMode={currentMode}
                                                    className={editAnchorEl && "Show"}
                                                    createStatusType={createStatusType}
                                                    createDisplayOrder={createDisplayOrder}
                                                    permissionInitialdata={dataList}
                                                />,
                                                document.getElementById("Modal-root")
                                            )}
                                        </>
                                    )}
                                </Card>
                                <Card
                                    className={`basis-[40%] md:mt-5 mb-[3rem] xl:mb-0  xl:mt-0 border-[1px]  border-[rgba(223,225,226,0.56)] xl:h-[calc(100vh_-_13rem)] h-[calc(100vh_-_26rem)] ${
                                        noActiveParams?.id == 1 ? "" : "overflow-y-auto"
                                    }  xl:overflow-x-hidden ${currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"}`}
                                >
                                    <div
                                        className={` ${
                                            MapParams?.length == 3 ? "justify-between" : " justify-evenly"
                                        } xl:w-full overflow-x-auto font-interM flex sticky left-0 right-0 z-[2] top-0 ${
                                            currentMode == "dark" ? "bg-[#1F1F1F]" : "bg-[#DAF9F4]"
                                        } `}
                                    >
                                        {MapParams?.map((item, index) => (
                                            <span
                                                className={`block py-[16px] px-[20px] cursor-pointer mx-[10px] ${
                                                    item?.isActive ? `text-[#028C78] border-b-[2px] border-[#028C78] ` : ""
                                                } `}
                                                onClick={() => handleSelectModule(index, item?.id)}
                                            >
                                                <>{item?.name}</>
                                            </span>
                                        ))}
                                    </div>
                                    <div className="flex flex-col">
                                        <div
                                            className={`items-center xl:w-full px-[20px] py-[15px] border-b-[1px] min-h-[70px] border-${currentMode}-700 ${
                                                currentMode === "dark"
                                                    ? ""
                                                    : "shadow-[0px_4px_4px_0px_rgba(0,0,0,0.10),0px_4px_4px_0px_rgba(0,0,0,0.06)] "
                                            } ${noActiveParams?.id == 1 ? "sticky top-0 z-[1]" : ""} ${
                                                currentMode == "dark" ? "bg-[#1F1F1F]" : "bg-[#FFFFFF]"
                                            }`}
                                        >
                                            {messageToShow}
                                            {noActiveParams?.id == 1 && (
                                                <div className="mt-4 mb-1 flex text-right">
                                                    {" "}
                                                    <Link tabIndex={0} to="/invoice-rule" target="_blank" title="view rule" className=" ">
                                                        <Button className="bg-light-240 text-[#000000] font-interM hover:bg-light-20 hover:text-white rounded-[8px] py-2 px-5">
                                                            View Rule
                                                        </Button>
                                                    </Link>
                                                    <Link tabIndex={0} to="/role-master" target="_blank" title="view role" className="mx-3">
                                                        <Button className="bg-light-240 text-[#000000] font-interM  hover:bg-light-20 hover:text-white rounded-[8px] py-2 px-5">
                                                            View Role
                                                        </Button>
                                                    </Link>
                                                </div>
                                            )}
                                        </div>

                                        {!noActiveParams && (
                                            <div className={`flex flex-col overflow-y-auto items-center justify-center my-[150px] `}>
                                                <img className={`mx-[auto]`} src="/images/common/delivery.png" alt="delivery" />
                                                <h4 className="font-interM text-center mt-3">
                                                    Select one of the status to view the workflow configuration details.
                                                </h4>
                                            </div>
                                            // <div>
                                            //     <DonutChart data={data} currentMode={currentMode} Colors={COLORS} />
                                            // </div>
                                        )}
                                        {noActiveParams?.id == 3 && (
                                            <NotificationAllocation
                                                receivers={receivers}
                                                documentGroup={documentGroup}
                                                setReceivers={setReceivers}
                                                bccreceivers={bccreceivers}
                                                setBccRecievers={setBccRecievers}
                                                email_enabled={Emailenable}
                                                notification_enabled={notificationenable}
                                                notification_title={Title}
                                                handleEmailSubject={setSubject}
                                                handleEmailMessage={setEmailmessage}
                                                email_sub={subject}
                                                listData={[]}
                                                notification_msg={message}
                                                email_body={emailMessage}
                                                emailCheckChange={setemailEnable}
                                                // handleNotificationTitle={setTitle}
                                                handleNotificationMessage={setMessage}
                                                invAllocList={[]}
                                                notificationListData={[]}
                                                handleNotificationCheck={setnotificationEnable}
                                                AllocListData={[]}
                                                reset={reset}
                                                selectedId={selectedId}
                                                setSelectedId={setSelectedId}
                                                check={check}
                                                setCheck={setCheck}
                                                currentMode={currentMode}
                                            />
                                        )}
                                        {noActiveParams?.id == 2 && (
                                            <PermissionsModal
                                                documentGroup={documentGroup}
                                                workflowData={workflowData}
                                                selectedId={selectedId}
                                                currentMode={currentMode}
                                                setSelectedId={setSelectedId}
                                                check={check}
                                                setCheck={setCheck}
                                                setUpdatedPermissionData={setUpdatedPermissionData}
                                                updatedPermissionData={updatedPermissionData}
                                                cloneData={cloneData}
                                            />
                                        )}
                                        {noActiveParams?.id == 1 && (
                                            <UsersModal
                                                anchorEl={anchorEl}
                                                placement={"bottom"}
                                                open={open}
                                                documentGroup={documentGroup}
                                                selectedId={selectedId}
                                                createStatusType={createStatusType}
                                                handleClickAway={handleClickAway}
                                                currentMode={currentMode}
                                                buttons={outsideCreate}
                                                openEdit={openEdit}
                                                setSelectedId={setSelectedId}
                                                check={check}
                                                setCheck={setCheck}
                                                cloneData={cloneData}
                                                licenseType={licenseType}
                                            />
                                        )}
                                    </div>
                                </Card>
                            </div>
                        )}
                    </Main>
                    <Footer />
                </ErrorBoundary>
            </Layout>
        </>
    );
};

export default WorkflowAutomation;
