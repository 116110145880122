import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Modal from "../../components/UI/Modal/Modal";
import { cartToggleAction } from "../../redux/actions/filter";
import Button from "../../components/Button";
import Input from "../../components/Input";
import UseForm from "../../Hooks/useForm";
// import { invoiceCountTotal, InvoiceList, invoiceVendorList } from "../../redux/actions/invoice";
import {
    GetAdvanceStatusList,
    getAdvanceList,
    GetListUserForComments,
    GetListAdvanceReqForPoAndVendor,
} from "../../redux/actions/advance-request.js";
import { getCurrentUser } from "../../redux/actions/auth";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useState, useEffect, useContext } from "react";
import moment from "moment";
import { ClickAwayListener, Fade, Checkbox, MenuItem, FormControlLabel, Paper, Popper, Select, TextField } from "@mui/material";
import { useLocation } from "react-router-dom";
import { orgId, userId } from "../../Hooks/userId/Index";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { parse } from "date-fns";

const AdvanceFilterModal = (props) => {
    const filteredApproval = localStorage.getItem("filteredApproval");
    const { setIsOpen } = props;
    const { handleSubmit, handleChange, InitialState, values, setValues } = UseForm(submit);
    const dispatch = useDispatch();
    const searchValue = localStorage.getItem("invSearch");
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [valuecheck, handleValues] = useState([]);
    const [StatusValue, setStatusValue] = useState([0]);
    const [VendorValue, setVendorValue] = useState([]);
    const [CheckFilterValue, setCheckFilterValue] = useState([]);
    const [FilterValue, setFilterValue] = useState([]);
    const UniqueValue = [...new Set(CheckFilterValue)];

    const cartClose = (e) => {
        e.preventDefault();
        dispatch(cartToggleAction(false));
        setIsOpen(false);
    };
    const currentUser = getCurrentUser();
    const location = useLocation();
    const orgId = localStorage.getItem("orgId") || 1;
    let { data: workflowFilter } = useSelector((state) => state?.getAdvanceStatusListState);
    const parseId = +workflowFilter?.datalist?.find((item) => item?.master_refid == (location.pathname === "/approve" ? 7 : 2))?.id;
    // console.log("Filter:", options);
    const checkChange = (e) => {
        const { checked } = e.target;
        const { value, id } = e.target;

        handleValues({
            // ...valuecheck,
            name: e.target.value,
            isCheck: checked,
        });
        const parseValue = {
            name: e.target.value,
            isCheck: checked,
            id,
        };
        checkStatus(parseValue);
        workflowFilter.map((item) => {
            if (item.name == value) {
                item.isCheck = checked;
                return item;
            } else {
                return item;
            }
        });
    };
    const checkStatus = (parseValue) => {
        const { name, isCheck, id } = parseValue;
        if (isCheck) {
            CheckFilterValue.push(name);
            setStatusValue([...StatusValue, Number(id)]);
            FilterValue.push(`${name}+ `);
        } else {
            setFilterValue(FilterValue?.filter((el) => el != `${name}+ `));
            setCheckFilterValue(CheckFilterValue?.filter((el) => el != name));
            setStatusValue(StatusValue?.filter((item) => item != Number(id)));
        }
    };

    const [filterObj, setFilterObj] = useState(null);

    const statusOptions = workflowFilter?.map((el, i) => (
        <MenuItem key={i}>
            <FormControlLabel
                control={
                    <Checkbox
                        id={el?.id}
                        size="small"
                        onChange={checkChange}
                        value={el?.status_name}
                        checked={UniqueValue?.includes(el?.status_name)}
                        // checked={el?.isCheck}
                        sx={{
                            padding: 0,
                            color: "#008785",
                            margin: "0 12px",
                            "&.Mui-checked": {
                                color: "#008785",
                            },
                        }}
                    />
                }
                label={el?.status_name}
            />
        </MenuItem>
    ));

    const advanceType = [
        { name: "Mobilization Request", id: 1 },
        { name: "Adhoc Request", id: 2 },
    ];

    const [SelectedAdvanceType, setSelectedAdvanceType] = useState(0);
    const handleChanges = (event) => {
        setSelectedAdvanceType(event.target.value);
    };
    function submit() {
        props?.setfilterStatus(values?.status_id);
        const reqObj = {
            status_id: StatusValue || 0,
            po_id: "0",
            vendor_id: values?.vendor_name || "0",
            from_date: fromDate ? fromDate : null,
            to_date: toDate ? toDate : null,
            search_text: values?.invoice_no,
            order_column: "",
            adv_type: location.pathname === "/retention-release-list"
                ? 4
                : location.pathname === "/miscellaneous-table"
                    ? 3
                    : location.pathname === "/advance-list"
                        ? SelectedAdvanceType || 0
                        : '',
            alloc_user: 0,
            order_by: 0,
            pg_no: 1,
            rec_cnt: 10,
            org_id: +orgId,
        };
        if (fromDate && toDate) {
            reqObj.from_date = moment(new Date(fromDate)).format("YYYY-MM-DD");
            reqObj.to_date = moment(new Date(toDate)).format("YYYY-MM-DD");
        }
        props.filterSubmit(reqObj);
        dispatch(cartToggleAction(false));
        dispatch(getAdvanceList(reqObj));
        setIsOpen(false);
    }
    const handleClear = (e) => {
        e.preventDefault();
        props.filterSubmit(null);
        setValues(InitialState);
        setFromDate("");
        setSelectedAdvanceType(0);
        setToDate("");
        setCheckFilterValue([]);
        setStatusValue([0]);
    };
    const Onrefresh = props?.Onrefresh;
    useEffect(() => {
        props.filterSubmit(null);
        if (props?.Onrefresh) {
            props.filterSubmit(null);
            setValues(InitialState);
            setFromDate("");
            setSelectedAdvanceType(0);
            setToDate("");
            setTimeout(() => {
                props?.setOnrefresh(false);
            }, 500);
        }
        setValues(InitialState);
        setStatusValue([0]);
        setFromDate("");
        setToDate("");
    }, [location, props?.Onrefresh]);
    useEffect(() => {
        if (filteredApproval == "true") {
            values.status_id = [3];
            // setFromDate(new Date());
            // setToDate(new Date());
            localStorage.setItem("filteredApproval", false);
        } else {
            values.status_id = values.status_id;
        }
    }, []);
    const handleValue = (newValue) => {
        if (newValue) {
            setFromDate(newValue);
        }
    };
    const handleToDate = (toDate) => {
        if (toDate) {
            setToDate(toDate);
        }
    };
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState();
    const lastDateOfYear = `12/31/${new Date().getFullYear()}`;
    const handleClickAway = () => {
        setOpen(false);
    };

    const handleClick = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    const AccessIcon = () => <img src="/images/common/date-ico.svg" alt="date-ico" />;
    const { data: vebdorlist } = useSelector((state) => state?.getAdvanceRequestPoAndVendorListState);
    const { currentMode } = useContext(ThemeContext);
    // useEffect(() => {
    //     let bodys = document.querySelector("body").classList;
    //     location.pathname === "/profile" &&
    //         bodys.remove(`bg-${currentMode}-base`);
    // }, [dispatch]);
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const dropdownShadow = currentMode !== "dark" ? "0px 4px 20px rgba(238, 238, 238, 0.5)" : "";
    const dropdownBorder = currentMode !== "dark" ? "223, 225, 226, 0.56" : "";

    return (
        <Modal cartClose={cartClose} className={props?.className}>
            <span onClick={cartClose} className="p-[6.5px] cursor-pointer absolute right-[400px] bg-light-10">
                <img src="/images/common/whiteClose.svg" alt="whiteClose" />
            </span>
            <form onSubmit={handleSubmit}>
                <div
                    className={`max-w-[400px] ml-auto bg-${currentMode}-card overflow-y-auto transition duration-150 ease-out flex-col flex justify-between h-screen max-h-[-webkit-fill-available]`}
                >
                    <div>
                        <div className="flex justify-between px-[30px] py-[23px] items-center">
                            <h3 className="text-lg font-interSb">More Filters</h3>
                        </div>
                        {location.pathname === '/retention-release-list' || location.pathname === '/advance-list' ?
                            <div className={`flex flex-col justify-between px-[30px] py-[23px]  border-t-[1px] border-${currentMode}-600`}>
                                <span className="block text-light-60 mb-2">PO Number</span>
                                <Input
                                    value={values?.invoice_no}
                                    onChange={handleChange}
                                    name="invoice_no"
                                    type="search"
                                    className={`rounded-[8px] py-[7px] bg-light-230 border-${currentMode}-840 border
                                       px-4`}
                                    placeholder="Enter the Po Number"
                                />
                            </div>
                            : <div className="invisible"></div>}

                        <div className={`flex flex-col justify-between px-[30px] py-[23px]  border-t-[1px] border-${currentMode}-600`}>
                            <span className="block text-light-60">Vendor</span>
                            <Select
                                name="vendor_name"
                                value={values?.vendor_name === "" ? "default" : values?.vendor_name}
                                className="font-interR Org"
                                defaultValue="default"
                                onChange={handleChange}
                                MenuProps={{
                                    sx: {
                                        "& .MuiMenu-paper": {
                                            backgroundColor: dropdownColor,
                                            color: dropdownText,
                                            maxWidth: "200px",
                                        },
                                        "& .MuiMenuItem-root:hover": {
                                            backgroundColor: "rgba(218, 249, 244, 0.3)",
                                        },
                                        "& .Mui-selected": {
                                            backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                        },
                                    },
                                }}
                                SelectDisplayProps={{
                                    style: {
                                        paddingTop: 2,
                                        display: "flex",
                                        paddingBottom: 2,
                                        paddingLeft: "0 !important",
                                        border: 0,
                                        color: currentMode === "dark" ? `#ECECEC` : `#555555`,
                                        paddingRight: "30px",
                                        marginLeft: "-15px",
                                    },
                                }}
                                displayEmpty
                                IconComponent={() => (
                                    <img
                                        className="absolute right-[15px] opacity-[.5] pointer-events-none"
                                        src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                        alt="chevronDown"
                                    />
                                )}
                            >
                                <MenuItem value="default" sx={{ display: "none" }}>
                                    Select Vendor
                                </MenuItem>
                                {vebdorlist?.vendorListData?.length !== 0 &&
                                    vebdorlist?.vendorListData?.map((elem) => {
                                        return (
                                            <MenuItem value={elem?.id} title={elem?.vendor_name}>
                                                {elem?.vendor_name}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </div>

                        {location.pathname === '/advance-list' ?
                            <div className={`flex flex-col justify-between px-[30px] py-[23px]  border-t-[1px] border-${currentMode}-600`}>
                                <span className="block text-light-60">Advance Type</span>
                                <Select
                                    name="Adance type"
                                    value={values?.name === "" ? 0 : SelectedAdvanceType}
                                    className="font-interR Org"
                                    defaultValue="default"
                                    onChange={handleChanges}
                                    MenuProps={{
                                        sx: {
                                            "& .MuiMenu-paper": {
                                                backgroundColor: dropdownColor,
                                                color: dropdownText,
                                                maxWidth: "200px",
                                            },
                                            "& .MuiMenuItem-root:hover": {
                                                backgroundColor: "rgba(218, 249, 244, 0.3)",
                                            },
                                            "& .Mui-selected": {
                                                backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                            },
                                        },
                                    }}
                                    SelectDisplayProps={{
                                        style: {
                                            paddingTop: 2,
                                            display: "flex",
                                            paddingBottom: 2,
                                            paddingLeft: "0 !important",
                                            border: 0,
                                            color: currentMode === "dark" ? `#ECECEC` : `#555555`,
                                            paddingRight: "30px",
                                            marginLeft: "-15px",
                                        },
                                    }}
                                    displayEmpty
                                    IconComponent={() => (
                                        <img
                                            className="absolute right-[15px] opacity-[.5] pointer-events-none"
                                            src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                            alt="chevronDown"
                                        />
                                    )}
                                >
                                    <MenuItem value={0} sx={{ display: "none" }}>
                                        Select Advance Type
                                    </MenuItem>
                                    {advanceType?.length !== 0 &&
                                        advanceType?.map((elem) => {
                                            return (
                                                <MenuItem value={elem?.id} title={elem?.name}>
                                                    {elem?.name}
                                                </MenuItem>
                                            );
                                        })}

                                </Select>
                            </div>
                            : <div className="invisible"></div>}
                        <div className={`flex  border-t-[1px] border-${currentMode}-600 flex-col justify-between px-[30px] py-[23px]`}>
                            <span className="block text-light-60">Select Period</span>
                            <Button className="font-interR mr-auto" onClick={handleClick("bottom-start")}>
                                {fromDate && toDate
                                    ? moment(new Date(fromDate)).format("DD-MM-YYYY") + " to " + moment(new Date(toDate)).format("DD-MM-YYYY")
                                    : "Select date range"}
                            </Button>
                            <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClickAway}>
                                <Popper open={open} anchorEl={anchorEl} placement={placement} className="z-[111] filterPop" transition>
                                    {({ TransitionProps }) => (
                                        <Fade {...TransitionProps} timeout={350}>
                                            <Paper>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <ul className={`space-y-4 px-[15px] py-4 bg-${currentMode}-card  border-${currentMode}-700`}>
                                                        <li className="flex justify-between">
                                                            <DesktopDatePicker
                                                                selectsStart
                                                                disableFuture={true}
                                                                inputFormat="DD/MM/YYYY"
                                                                className="border-0"
                                                                components={{
                                                                    OpenPickerIcon: AccessIcon,
                                                                }}
                                                                in
                                                                value={fromDate}
                                                                label="From"
                                                                minDate={new Date(1999, 12)}
                                                                maxDate={new Date(lastDateOfYear)}
                                                                name="invoice_date_from"
                                                                onChange={handleValue}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        sx={{
                                                                            "& .MuiInputBase-input": {
                                                                                color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                                            },
                                                                        }}
                                                                        // placeholder={params.inputProps?.placeholder?.replace(
                                                                        //     "From"
                                                                        // )}
                                                                        {...params}
                                                                        inputProps={{
                                                                            ...params.inputProps,
                                                                            readOnly: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </li>
                                                        <li className="flex justify-between">
                                                            <DesktopDatePicker
                                                                selectsEnd
                                                                disableFuture={true}
                                                                className="border-0"
                                                                inputFormat="DD/MM/YYYY"
                                                                name="invoice_date_to"
                                                                components={{
                                                                    OpenPickerIcon: AccessIcon,
                                                                }}
                                                                value={toDate}
                                                                onChange={handleToDate}
                                                                label="To"
                                                                minDate={fromDate}
                                                                maxDate={new Date(lastDateOfYear)}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        sx={{
                                                                            "& .MuiInputBase-input": {
                                                                                color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                                            },
                                                                        }}
                                                                        placeholder={params.inputProps?.placeholder?.replace("To")}
                                                                        {...params}
                                                                        inputProps={{
                                                                            ...params.inputProps,
                                                                            readOnly: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                            {/* <img
                            src="/images/common/date-ico.svg"
                            alt="date-ico"
                          /> */}
                                                        </li>
                                                    </ul>
                                                </LocalizationProvider>
                                            </Paper>
                                        </Fade>
                                    )}
                                </Popper>
                            </ClickAwayListener>
                        </div>
                        <hr className={`border-${currentMode}-600`} />
                        {location.pathname !== "/exception" ? (
                            <div className="flex flex-col justify-between px-[30px] py-[23px]">
                                <span className="block text-light-60">Status</span>
                                <Select
                                    name="filter"
                                    title={UniqueValue}
                                    //  onChange={handleSelectedValue}
                                    className="insideFilter Org"
                                    defaultValue="default"
                                    fullWidth
                                    multiple={true}
                                    value={[]}
                                    displayEmpty
                                    renderValue={(value) => (CheckFilterValue?.length == 0 ? "Select" : FilterValue)}
                                    MenuProps={{
                                        sx: {
                                            "& .MuiMenu-paper": {
                                                backgroundColor: dropdownColor,
                                                color: dropdownText,
                                                boxShadow: dropdownShadow,
                                                border: dropdownBorder,
                                                borderRadius: "8px",
                                                maxWidth: "30rem",
                                                // height: selected == "invType" ? "5rem" : selected == "status" ? "11rem" : selected == "advancePeriod" ? "3rem" : "",
                                                // minHeight: selected == "supplier" && "10rem !important",
                                                top: "304rem",
                                                marginTop: "10px",
                                            },
                                            "& .MuiMenuItem-root:hover": {
                                                backgroundColor: "rgba(218, 249, 244, 0.3)",
                                            },
                                            "& .Mui-selected": {
                                                backgroundColor: "rgba(218, 249, 244, 0.3)",
                                            },
                                        },
                                    }}
                                    IconComponent={() => (
                                        <img
                                            className="absolute right-[5px] opacity-[.5] pointer-events-none"
                                            src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                            alt="chevronDown"
                                        />
                                    )}
                                    SelectDisplayProps={{
                                        style: {
                                            paddingTop: 1,
                                            paddingBottom: 1,
                                            width: "11rem",
                                            color: currentMode === "dark" ? `#ECECEC` : `#555555`,
                                            paddingLeft: "0",
                                            border: 0,

                                            paddingRight: "5px",
                                        },
                                    }}
                                >
                                    {statusOptions}
                                </Select>
                            </div>
                        ) : null}
                    </div>
                    <div className={`flex border-t-[1px] border-${currentMode}-600 space-x-3 items-center px-[30px] py-[23px]`}>
                        <Button
                            title="Done"
                            onClick={submit}
                            type="submit"
                            className="bg-light-20 flex text-base font-interR py-[.5rem] px-[1.24rem] rounded-[8px] text-white"
                        >
                            {`Done`}
                        </Button>
                        <Button
                            type="button"
                            title="Clear All"
                            onClick={handleClear}
                            className="bg-light-240 flex text-base font-interR py-[.5rem] px-[1.7rem] rounded-[8px] text-light-40 closeModal"
                        >
                            Clear All
                        </Button>
                        {/* <Button  className="text-xs rounded-[5px] bg-light-240 px-5 py-1">Clear All</Button> */}
                    </div>
                </div>
            </form>
        </Modal>
    );
};

export default AdvanceFilterModal;
