import { Autocomplete, Checkbox, Chip, ClickAwayListener, Fade, FormControl, MenuItem, Paper, Popper, Select, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Card from "../../components/UI/Card";
import Main from "../../components/UI/Main";
import Layout from "../../Hoc/Layout";
import { invoiceVendorList } from "../../redux/actions/invoice";
import { decryptId } from "../../utils/helpers";
import {
    getReportsAgeingList,
    getReportsAgeingSummaryList,
    getReportsList,
    reportsAgeingCountTotal,
    reportsCountTotal,
    getSummaryAgingList,
} from "../../redux/actions/report";
import DashboardTile from "../Home/DashboardTile";
import Table from "./Table";
import { UserDropDown } from "../../redux/actions/user";
import { getCurrentUser } from "../../redux/actions/auth";
import Swal from "sweetalert2";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { getWorkFlowFilter } from "../../redux/actions/workflow-automation";

const ReportsAgeing = () => {
    const { currentMode } = useContext(ThemeContext);
    const currentUser = getCurrentUser();
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [currentPageItem, changePageItem] = useState(10);
    const [anchorEl, setAnchorEl] = useState(null);
    const [page, setPage] = useState(false);
    const [placement, setPlacement] = useState();
    const [invNumber, setInvNumber] = useState("");
    const [isSupplier, setSupplierValue] = useState([]);
    const [isStatus, setStatusValue] = useState([]);
    const [val, setVal] = useState([]);
    const [valStatus, setValStatus] = useState([]);
    const [isadult, setIsAdult] = useState([]);
    const [isAdultStatus, setIsAdultStatus] = useState([]);
    const [ageing, setAgeingValue] = useState(1);
    const [selAging, setSelectAging] = useState(2);
    const [agingType, setAgingType] = useState(1);
    const [open, setOpen] = useState(false);
    const [currentPageNo, changePageNo] = useState(1);
    const [searchClick, setSearchClick] = useState(false);
    const lastDateOfYear = `12/31/${new Date().getFullYear()}`;
    const [DrillOpen, setDrillOpen] = useState(false);
    const profileData = useSelector((state) => state?.userListDropDownState);
    const { data: dataList } = profileData;
    const dataLists = dataList?.filter((el) => el?.isactivated !== false || el?.user_isactive !== false);
    const [user, setUser] = useState(0);
    const handleUserChange = (value) => {
        setUser(value);
    };
    const CurrentUser = getCurrentUser();
    const letuserId = CurrentUser?.role_id == 1 ? CurrentUser?.id : user;
    const dispatch = useDispatch();

    //* For workflow filter
    const { data: workflowFilter } = useSelector((state) => state?.getWorkflowFilterState);
    const statusReset = (filterList) => {
        return filterList?.map((item) => {
            return {
                ...item,
                isActive: false,
                value: item?.id,
            };
        });
    };
    const status = workflowFilter?.datalist ? statusReset(workflowFilter?.datalist) : [];

    const handleClick = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };
    const handleClickAway = () => {
        setOpen(false);
    };
    const handleValue = (newValue) => {
        if (newValue) {
            setFromDate(newValue);
        }
    };
    const handleToDate = (toDate) => {
        if (toDate) {
            setToDate(toDate);
        }
    };

    const handleCheckboxChange = (event, orgId) => {
        setIsAdult({
            ...isadult,
            [event.target.name]: event.target.value,
        });
        if (!event.target.value) {
            if (val.length > 0) {
                const isExistID = val.find((item) => item.id === orgId);
                if (isExistID) {
                    const removeIdList = val.filter((item) => item.id !== orgId);
                    setVal([...removeIdList]);
                }
            }
        }
    };
    const handleCheckboxStatusChange = (event, value) => {
        setIsAdultStatus({
            ...isAdultStatus,
            [event.target.name]: event.target.value,
        });
        if (!event.target.value) {
            if (valStatus?.length > 0) {
                const isExistID = valStatus.find((item) => item.value === value);
                // alert(isExistID);
                if (isExistID) {
                    const removeIdList = valStatus?.filter((item) => item.value !== value);
                    setValStatus([...removeIdList]);
                }
            }
        }
    };
    // const status = [
    //     { value: 2, name: "Submitted" },
    //     { value: 3, name: "Approved" },
    //     { value: 4, name: "Posted" },
    //     { value: 5, name: "Rejected" },
    //     { value: 7, name: "For Approval" },
    // ];
    const handleSelect = (event) => {
        setAgeingValue(event.target.value);
    };

    const handleSelectAging = (event) => {
        setSelectAging(event.target.value);
    };
    const handleAgingType = (event) => {
        setAgingType(event.target.value);
    };
    const AccessIcon = () => <img src="/images/invoice/calendar.svg" alt="calendar" className="convertWhite" />;
    const orgId = localStorage.getItem("orgId");
    const { vebdorlist } = useSelector((state) => state?.InvoiceVendorListState);
    const reqObj = {
        status_type: 0,
        status_id: isStatus?.length === 0 ? [0] : isStatus,
        organisation: +orgId,
        vendor_id: isSupplier?.length !== 0 ? isSupplier : null,
        invoice_date_from: fromDate !== null ? moment(new Date(fromDate)).format("YYYY-MM-DD") : fromDate,
        invoice_date_to: toDate !== null ? moment(new Date(toDate)).format("YYYY-MM-DD") : toDate,
        invoice_no: "",
        order_column: "",
        order_by: 0,
        pg_no: 1,
        rec_cnt: 10,
        type: ageing,
        alloc_id: +letuserId || 0,
        document_type: 1,
    };
    // const localDateString = fromDate.$d?.toLocaleString("en-US", { timeZone: "Asia/Kolkata" });
    const summaryObjReq = {
        status_type: 0,
        status_id: isStatus?.length === 0 ? [0] : isStatus,
        organisation: +orgId,
        vendor_id: isSupplier?.length !== 0 ? isSupplier : null,
        invoice_date_from: fromDate,
        invoice_date_to: toDate,
        invoice_no: "",
    };

    var date1 = new Date(fromDate);
    var date2 = new Date(toDate);
    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    const handleSearchFilter = () => {
        changePageItem(10);
        changePageNo(1);
        setPage(true);
        //dispatch(getReportsAgeingList(reqObj));
        if (Date.parse(fromDate) > Date.parse(toDate)) {
            Swal.fire({
                // title: "Invalid date range!",
                closeOnClickOutside: false,
                showCloseButton: true,
                text: "Start date cannot be after end date!",
                icon: "warning",
                button: "Ok",
            });
        }

        // else if (
        //     selAging == 1 &&
        //     ageing == 1 &&
        //     (fromDate !== null || toDate !== null) &&
        //     Difference_In_Days > 30
        // ) {
        //     Swal.fire({
        //         // title: "Invalid date range!",
        //         closeOnClickOutside: false,
        //         text: "please select valid date range",
        //         icon: "warning",
        //         button: "Ok",
        //     });
        // } else if (
        //     (selAging == 1 &&
        //         ageing == 2 &&
        //         Difference_In_Days < 30 &&
        //         (fromDate !== null || toDate !== null)) ||
        //     (selAging == 1 &&
        //         ageing == 2 &&
        //         Difference_In_Days > 60 &&
        //         (fromDate !== null || toDate !== null))
        // ) {
        //     Swal.fire({
        //         // title: "Invalid date range!",
        //         closeOnClickOutside: false,
        //         text: "please select valid date range",
        //         icon: "warning",
        //         button: "Ok",
        //     });
        // } else if (
        //     (selAging == 1 &&
        //         ageing == 3 &&
        //         Difference_In_Days < 60 &&
        //         (fromDate !== null || toDate !== null)) ||
        //     (selAging == 1 &&
        //         ageing == 3 &&
        //         Difference_In_Days > 90 &&
        //         (fromDate !== null || toDate !== null))
        // ) {
        //     Swal.fire({
        //         // title: "Invalid date range!",
        //         closeOnClickOutside: false,
        //         text: "please select valid date range",
        //         icon: "warning",
        //         button: "Ok",
        //     });
        // } else if (
        //     selAging == 1 &&
        //     ageing == 4 &&
        //     Difference_In_Days <= 90 &&
        //     (fromDate !== null || toDate !== null)
        // ) {
        //     Swal.fire({
        //         title: "Invalid date range!",
        //         closeOnClickOutside: false,
        //         text: "please select valid date range",
        //         icon: "warning",
        //         button: "Ok",
        //     });
        // }
        else if (selAging == 1) {
            // dispatch(getReportsAgeingList(reqObj));
            // dispatch(reportsAgeingCountTotal(reqObj));
            setSearchClick(!searchClick);
        } else if (selAging == 2) {
            dispatch(getSummaryAgingList(reqObj));
        }
    };

    useEffect(() => {
        const data = {
            org_id: orgId,
            status: 2,
        };
        dispatch(getWorkFlowFilter(orgId, 1));
        dispatch(UserDropDown(data));
        dispatch(invoiceVendorList(orgId));
        //   dispatch(getReportsAgeingSummaryList(+orgId));
    }, [dispatch]);

    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "white" : `black`;
    const dropdownShadow = currentMode !== "dark" ? "0px 4px 20px rgba(238, 238, 238, 0.5)" : "";
    const dropdownBorder = currentMode !== "dark" ? "rgba(85, 85, 85, 0.4)" : "";

    const UserDateFormat = localStorage.getItem("userDateFormat");

    return (
        <>
            <Layout>
                <Main>
                    <div className={`flex justify-between items-center mb-6 text-${currentMode}-copy`}>
                        <div>
                            <h4 className="text-2xl font-interSb">Ageing Reports</h4>
                        </div>
                        <Link className="flex" to="/reports">
                            <img className="mr-2 convertWhite" src="/images/main/noun-back.svg" alt="noun-back" /> Back
                        </Link>
                    </div>
                    <Card className="p-[20px] mb-6">
                        <div className="grid grid-cols-2 xl:grid-cols-4 gap-4">
                            <div className="">
                                <span className="block mb-[10px]">Ageing Type</span>
                                <FormControl fullWidth>
                                    <Select
                                        inputProps={{
                                            "aria-label": "Without label",
                                        }}
                                        onChange={handleSelectAging}
                                        value={selAging}
                                        IconComponent={() => (
                                            <img
                                                className="absolute opacity-50 right-[15px] pointer-events-none"
                                                src={
                                                    currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`
                                                }
                                            />
                                        )}
                                        MenuProps={{
                                            sx: {
                                                "& .MuiMenu-paper": {
                                                    backgroundColor: dropdownColor,
                                                    color: dropdownText,
                                                },
                                                "& .MuiMenuItem-root:hover": {
                                                    backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                },
                                                "& .Mui-selected": {
                                                    backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                },
                                            },
                                        }}
                                        displayEmpty
                                        SelectDisplayProps={{
                                            style: {
                                                paddingTop: "11px",
                                                paddingBottom: "11px",
                                                paddingLeft: "15px",
                                                color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,

                                                paddingRight: "30px",
                                                border: "1px solid rgba(85, 85, 85, 0.4)",
                                                borderRadius: "8px",
                                            },
                                        }}
                                    >
                                        <MenuItem value={1}>Details</MenuItem>
                                        <MenuItem value={2}>Summary</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="datePic" title="Calendar">
                                <span className="block mb-[10px]">From</span>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                        selectsStart
                                        inputFormat={UserDateFormat?.toUpperCase()}
                                        className="border-0"
                                        components={{
                                            OpenPickerIcon: AccessIcon,
                                        }}
                                        disableFuture={true}
                                        value={fromDate}
                                        minDate={new Date(1999, 12)}
                                        maxDate={new Date(lastDateOfYear)}
                                        name="invoice_date_from"
                                        onChange={handleValue}
                                        renderInput={(params) => (
                                            <TextField
                                                value={fromDate}
                                                sx={{
                                                    "& .MuiInputBase-input": {
                                                        color: `${currentMode == "dark" ? "white" : "black"} !important`,
                                                    },
                                                }}
                                                fullWidth
                                                placeholder={params.inputProps?.placeholder?.replace("From")}
                                                {...params}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    readOnly: true,
                                                }}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="datePic" title="Calendar">
                                <span className="block mb-[10px]">To</span>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                        selectsEnd
                                        className="border-0"
                                        inputFormat={UserDateFormat?.toUpperCase()}
                                        name="invoice_date_to"
                                        disableFuture={true}
                                        components={{
                                            OpenPickerIcon: AccessIcon,
                                        }}
                                        value={toDate}
                                        onChange={handleToDate}
                                        minDate={fromDate}
                                        maxDate={new Date(lastDateOfYear)}
                                        renderInput={(params) => (
                                            <TextField
                                                value={toDate}
                                                sx={{
                                                    "& .MuiInputBase-input": {
                                                        color: `${currentMode == "dark" ? "white" : "black"} !important`,
                                                    },
                                                }}
                                                fullWidth
                                                placeholder={params.inputProps?.placeholder?.replace("To")}
                                                {...params}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    readOnly: true,
                                                }}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </div>
                            {selAging === 1 && (
                                <div>
                                    <span className="block mb-[10px]">Ageing Days</span>
                                    <FormControl fullWidth>
                                        <Select
                                            inputProps={{
                                                "aria-label": "Without label",
                                            }}
                                            value={ageing}
                                            onChange={handleSelect}
                                            IconComponent={() => (
                                                <img
                                                    className="absolute opacity-50 right-[15px] pointer-events-none"
                                                    src={
                                                        currentMode !== "dark"
                                                            ? `/images/header/chevronDown.svg`
                                                            : `/images/header/chevronDown-white.svg`
                                                    }
                                                />
                                            )}
                                            MenuProps={{
                                                sx: {
                                                    "& .MuiMenu-paper": {
                                                        backgroundColor: dropdownColor,
                                                        color: dropdownText,
                                                    },
                                                    "& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                    },
                                                    "& .Mui-selected": {
                                                        backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                    },
                                                    "& .MuiChip-label": {
                                                        color: `${currentMode == "dark" ? "white" : "black"}`,
                                                    },
                                                },
                                            }}
                                            displayEmpty
                                            SelectDisplayProps={{
                                                style: {
                                                    paddingTop: "11px",
                                                    paddingBottom: "11px",
                                                    paddingLeft: "15px",
                                                    paddingRight: "30px",
                                                    border: "1px solid rgba(85, 85, 85, 0.4)",
                                                    borderRadius: "8px",
                                                    color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                },
                                            }}
                                        >
                                            <MenuItem value={1}>0 to 30 Days</MenuItem>
                                            <MenuItem value={2} name="OrganisationList">
                                                31 to 60 Days
                                            </MenuItem>
                                            <MenuItem value={3} name="OrganisationList">
                                                61 to 90 Days
                                            </MenuItem>
                                            <MenuItem value={4} name="OrganisationList">
                                                more than 90 Days
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            )}
                            <div className="filter">
                                <span className="block mb-[10px]">Vendor</span>
                                <Autocomplete
                                    multiple={true}
                                    clearOnBlur={false}
                                    id="checkboxes-tags-demo"
                                    options={vebdorlist || []}
                                    value={
                                        isSupplier?.length === 0
                                            ? []
                                            : isSupplier?.map((value) => vebdorlist?.find((user) => user?.vendor_name === value))
                                    }
                                    onChange={(event, value) => setSupplierValue(value?.map((el) => el?.vendor_name))}
                                    limitTags={1}
                                    disableCloseOnSelect
                                    PaperComponent={({ children }) => (
                                        <Paper
                                            style={{
                                                backgroundColor: dropdownColor,
                                                color: dropdownText,
                                                boxShadow: dropdownShadow,
                                                border: dropdownBorder,
                                            }}
                                        >
                                            {children}
                                        </Paper>
                                    )}
                                    popupIcon={<img className="m-0 pointer-events-none" src="/images/header/chevronDown.svg" />}
                                    sx={{
                                        "& .MuiAutocomplete-popper": {
                                            backgroundColor: dropdownColor,
                                            color: dropdownText,
                                            boxShadow: dropdownShadow,
                                            border: dropdownBorder,
                                        },
                                        "& .MuiMenuItem-root:hover": {
                                            backgroundColor: "rgba(218, 249, 244, 0.3)",
                                        },
                                        "& .Mui-selected": {
                                            backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                        },
                                        "& .MuiAutocomplete-popupIndicator": {
                                            padding: 0,
                                            marginTop: "5px",
                                            marginRight: "10px",
                                        },
                                        "& .MuiAutocomplete-popupIndicatorOpen": {
                                            padding: 0,
                                            marginRight: "10px",
                                        },
                                        "& .MuiChip-label": {
                                            color: `${currentMode == "dark" ? "white" : "black"}`,
                                        },
                                    }}
                                    getOptionLabel={(status) => status?.vendor_name}
                                    isOptionEqualToValue={(option, value) => option?.vendor_name === value?.vendor_name}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                size="small"
                                                sx={{
                                                    color: "rgba(85,85,85,0.7)",
                                                    borderRadius: "3px !important",
                                                    "&.Mui-checked": {
                                                        color: "#028C78",
                                                    },
                                                }}
                                                value={option?.org_id}
                                                checked={selected}
                                            />
                                            {option?.vendor_name}
                                        </li>
                                    )}
                                    style={{ padding: 0 }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            placeholder="Vendor"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: null,
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="filter">
                                <span className="block mb-[10px]">Status</span>
                                <Autocomplete
                                    multiple={true}
                                    clearOnBlur={false}
                                    id="checkboxes-tags-demo"
                                    options={status || []}
                                    onChange={(event, value) => setStatusValue(value?.map((el) => el?.value))}
                                    disableCloseOnSelect
                                    PaperComponent={({ children }) => (
                                        <Paper
                                            style={{
                                                backgroundColor: dropdownColor,
                                                color: dropdownText,
                                                boxShadow: dropdownShadow,
                                                border: dropdownBorder,
                                                // color: `${currentMode == "dark" ? "white" : "black"}`
                                            }}
                                        >
                                            {children}
                                        </Paper>
                                    )}
                                    popupIcon={<img className="m-0 pointer-events-none" src="/images/header/chevronDown.svg" />}
                                    sx={{
                                        "& .MuiAutocomplete-popper": {
                                            backgroundColor: "white",
                                            boxShadow: "0px 4px 20px rgba(238, 238, 238, 0.5)",
                                            border: "1px solid rgba(223, 225, 226, 0.56)",
                                            borderRadius: "8px",
                                        },
                                        "& .MuiMenuItem-root:hover": {
                                            backgroundColor: "rgba(218, 249, 244, 0.3)",
                                        },
                                        "& .Mui-selected": {
                                            backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                        },

                                        "& .MuiAutocomplete-popupIndicator": {
                                            padding: 0,
                                            marginTop: "5px",
                                            marginRight: "10px",
                                        },
                                        "& .MuiAutocomplete-popupIndicatorOpen": {
                                            padding: 0,
                                            marginRight: "10px",
                                        },
                                        "& .MuiChip-label": {
                                            color: `${currentMode == "dark" ? "white" : "black"}`,
                                        },
                                    }}
                                    getOptionLabel={(status) => status?.name}
                                    limitTags={1}
                                    isOptionEqualToValue={(option, value) => option?.value === value?.value}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                size="small"
                                                sx={{
                                                    color: "rgba(85,85,85,0.7)",
                                                    borderRadius: "3px !important",
                                                    "&.Mui-checked": {
                                                        color: "#028C78",
                                                    },
                                                }}
                                                // value={option?.id}
                                                checked={selected}
                                            />
                                            {option?.name}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            placeholder="Status"
                                            InputProps={{
                                                ...params?.InputProps,
                                                endAdornment: null,
                                            }}
                                        />
                                    )}
                                />
                            </div>

                            {CurrentUser?.role_id == 2 && (
                                <div>
                                    <span className="block mb-[10px]">Select User</span>
                                    <FormControl fullWidth sx={{ minWidth: "300px" }}>
                                        <Select
                                            value={user || "0"}
                                            name="UserList"
                                            onChange={(e) => handleUserChange(e.target.value)}
                                            inputProps={{
                                                "aria-label": "Without label",
                                            }}
                                            IconComponent={() => (
                                                <img
                                                    className="absolute opacity-50 right-[15px] pointer-events-none"
                                                    src={
                                                        currentMode !== "dark"
                                                            ? `/images/header/chevronDown.svg`
                                                            : `/images/header/chevronDown-white.svg`
                                                    }
                                                />
                                            )}
                                            MenuProps={{
                                                sx: {
                                                    "& .MuiMenu-paper": {
                                                        backgroundColor: dropdownColor,
                                                        color: dropdownText,
                                                    },
                                                    "& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                    },
                                                    "& .Mui-selected": {
                                                        backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                    },
                                                },
                                            }}
                                            SelectDisplayProps={{
                                                style: {
                                                    paddingTop: "11px",
                                                    paddingBottom: "11px",
                                                    paddingLeft: "15px",
                                                    color: `${currentMode !== "dark" ? `#000` : `#ECECEC`}`,

                                                    paddingRight: "30px",
                                                    border: "1px solid #8C9097",
                                                    borderRadius: "8px",
                                                },
                                            }}
                                        >
                                            <MenuItem key={0} name="UserList" value={0}>
                                                {"All Users"}
                                            </MenuItem>

                                            {dataLists &&
                                                dataLists?.map((listelem, i) => {
                                                    return (
                                                        <MenuItem key={i + 1} name="UserList" value={listelem?.id}>
                                                            {listelem?.user_name}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </FormControl>
                                </div>
                            )}
                            {selAging === 2 && (
                                <div className="">
                                    <span className="block mb-[10px]">Ageing By</span>
                                    <FormControl fullWidth>
                                        <Select
                                            inputProps={{
                                                "aria-label": "Without label",
                                            }}
                                            onChange={handleAgingType}
                                            value={agingType}
                                            IconComponent={() => (
                                                <img
                                                    className="absolute opacity-50 right-[15px] pointer-events-none"
                                                    src={
                                                        currentMode !== "dark"
                                                            ? `/images/header/chevronDown.svg`
                                                            : `/images/header/chevronDown-white.svg`
                                                    }
                                                />
                                            )}
                                            MenuProps={{
                                                sx: {
                                                    "& .MuiMenu-paper": {
                                                        backgroundColor: dropdownColor,
                                                        color: dropdownText,
                                                    },
                                                    "& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                    },
                                                    "& .Mui-selected": {
                                                        backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                    },
                                                },
                                            }}
                                            displayEmpty
                                            SelectDisplayProps={{
                                                style: {
                                                    paddingTop: "11px",
                                                    paddingBottom: "11px",
                                                    paddingLeft: "15px",
                                                    color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,

                                                    paddingRight: "30px",
                                                    border: "1px solid rgba(85, 85, 85, 0.4)",
                                                    borderRadius: "8px",
                                                },
                                            }}
                                        >
                                            <MenuItem value={1}>By Count</MenuItem>
                                            <MenuItem value={2}>By Value</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            )}

                            <div className="flex items-end">
                                <Button
                                    onClick={handleSearchFilter}
                                    disabled={
                                        (fromDate == null || fromDate?.$d.toString() === "Invalid Date") &&
                                        (toDate == null || toDate?.$d.toString() === "Invalid Date") &&
                                        isStatus?.length === 0 &&
                                        isSupplier?.length === 0 &&
                                        (+letuserId?.length === 0 || user === 0)
                                            ? true
                                            : false
                                    }
                                    className="bg-light-20 py-[9px] btn-primary rounded-[8px] text-white px-9"
                                >
                                    Search
                                </Button>
                            </div>
                        </div>
                    </Card>
                    {/* <DashboardTile report={true} /> */}
                    {/* <ReportChart /> */}
                    <Table
                        agingType={agingType}
                        changePageItem={changePageItem}
                        currentPageItem={currentPageItem}
                        setSelectAging={setSelectAging}
                        setAgeingValue={setAgeingValue}
                        changePageNo={changePageNo}
                        setDrillOpen={setDrillOpen}
                        currentPageNo={currentPageNo}
                        isSupplier={isSupplier}
                        setSupplierValue={setSupplierValue}
                        selAging={selAging}
                        invNumber={invNumber}
                        isStatus={isStatus}
                        fromDate={fromDate}
                        toDate={toDate}
                        ageing={ageing}
                        page={page}
                        clickTrack={searchClick}
                        user={user}
                    />
                </Main>
            </Layout>
        </>
    );
};

export default ReportsAgeing;
