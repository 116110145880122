import Card from "../../../components/UI/Card";
import { useContext } from "react";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import Button from "../../../components/Button";
import { useState, useEffect } from "react";
import POHeaderLineItemPopUp from "./POHeaderLineItemPopUp";
import ReactDOM from "react-dom";
import { useNavigate, useLocation } from "react-router-dom";
import _ from "lodash";
const POHeader = (props) => {
    const location = useLocation();
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const [isExpandHeader, setIsExpandHeader] = useState(false);
    const [isOpenLineItemTable, setIsLineItemTable] = useState(false);
    const POHeaderList = props?.createDataObject?.poHeadersArray?.filter((el) => el?.displayName !== "");
    const PoLineItems = props?.createDataObject?.poLineItems;
    const handleExpandHeader = () => {
        setIsExpandHeader((prevVal) => (prevVal ? false : true));
    };
    const openLineItemTable = () => {
        setIsLineItemTable(true);
    };
    // Chunk the array into chunks of 5 objects each
    const chunkedData = _.chunk(POHeaderList, 5);
    // Destructure the first chunk and the rest of the chunks
    const [firstChunk, ...remainingChunks] = chunkedData;
    return (
        <>
            <div className="xl:flex xl:space-x-3 ">
                <div
                    className={`!bg-[#EAF3FF] mb-3 shadow-md  border-[1px] border-[rgba(223,225,226,0.56)] min-h-[130px] max-h-[360px] overflow-y-auto xl:w-[976px] 2xl:w-full ${
                        currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                    } rounded-[10px] text-${currentMode}-copy`}
                >
                    <div className={`flex border-b-[1px] border-[#D1E4FD] w-full pt-[20px] pl-[30px] pb-[10px]`}>
                        <h4 className={`font-interM text-[16px] text-black`}>PO Header:</h4>
                        <div className="ml-auto flex">
                            {/* {location?.pathname == "/mobilization-request" && ( */}
                            <Button title="To view line item" onClick={openLineItemTable}>
                                {" "}
                                <img className="mt-1 mr-[22px]" src="/images/mobilization/noun-table.svg" alt="Table" />
                            </Button>
                            {/* )} */}
                            <Button title="To expand" onClick={handleExpandHeader} disabled={props?.DisableInput}>
                                <img
                                    className="h-[14px] mt-[6px] mr-[30px] w-[14px]"
                                    src={isExpandHeader ? `/images/mobilization/minus.svg` : `/images/mobilization/plus.svg`}
                                />
                            </Button>
                        </div>
                    </div>
                    {POHeaderList?.length > 0 ? (
                        <div className="text-black">
                            <div className="flex pl-[30px] border-b-[1px] !border-[#D1E4FD] pb-[15px] pt-[15px] text-left grid grid-cols-5">
                                {firstChunk?.map((el) => {
                                    return (
                                        <div className="">
                                            <p>{el?.displayName}</p>
                                            <p title={el?.value} className="max-w-[120px] truncate">
                                                {el?.value}
                                            </p>
                                        </div>
                                    );
                                })}
                            </div>
                            {isExpandHeader ? (
                                <>
                                    {remainingChunks?.map((ele) => {
                                        return (
                                            <div className="flex pl-[30px] border-b-[1px] border-[#D1E4FD] pb-[15px] pt-[15px] text-left grid grid-cols-5">
                                                {ele?.map((item) => {
                                                    return (
                                                        <div className="">
                                                            <p>{item?.displayName}</p>
                                                            <span>{item?.value}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        );
                                    })}
                                </>
                            ) : (
                                ""
                            )}
                        </div>
                    ) : (
                        <div className="flex justify-center mt-4 font-semibold"> No Data Found</div>
                    )}
                </div>
                {ReactDOM.createPortal(
                    <POHeaderLineItemPopUp
                        PoLineItems={PoLineItems}
                        isOpened={isOpenLineItemTable}
                        handleClose={() => setIsLineItemTable(false)}
                        className={isOpenLineItemTable && "Show"}
                    />,
                    document.getElementById("user-modal-root")
                )}
            </div>
        </>
    );
};

export default POHeader;
