import Button from "../../components/Button";
import Modal from "../../components/UI/Modal/Modal";
import { BackupDataApi, RemoveData } from "../../redux/actions/setting";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import MessageBox from "../../components/UI/Alert";
import { BACKUP_DATA_RESET, POST_ON_TIME_PROCESS_RESET } from "../../redux/types/types";
import { getCurrentUser } from "../../redux/actions/auth";
import { useState, useEffect } from "react";
import { useContext } from "react";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import Input from "../../components/Input";
import { GetOnTimeProcessAction, PostValidTimeAction } from "../../redux/actions/dashboard";
import Swal from "sweetalert2";
import { decryptId, ThisWeek, ThisYear } from "../../utils/helpers";

const ValidTime = (props) => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const { requestComplete, fetching, error, data } = useSelector((state) => state?.ValidTimeProcessState);
    const { data: dataList } = useSelector((state) => state?.OnTimeProcessState);
    const Orgname = localStorage.getItem("Orgdemo");
    const { data: timedataList } = useSelector((state) => state?.getTimeForOnTimeProcessingState);
    const DefaultValue = timedataList?.datalist?.[0]?.interval_time;
    const trimmedTime = DefaultValue?.split(":")?.[0];
    const ReplaceTime = trimmedTime?.replace("hours", "");
    const userId = localStorage.getItem("UserDropdownId");
    const [errormsg, seterrormsg] = useState({ message: "" });
    const [Hours, setHours] = useState(ReplaceTime);
    const dispatch = useDispatch();
    const CurrentUser = getCurrentUser();
    const emailaddress = decryptId(CurrentUser?.emailaddress);
    const navigate = useNavigate;
    const [success, setSuccess] = useState(false);
    const letuserId = CurrentUser?.role_id == 1 ? CurrentUser?.id : userId;
    const OrgId = localStorage.getItem("orgId");
    const CartClose = () => {
        props?.handleClose();
        seterrormsg({
            message: "",
        });
        setHours(ReplaceTime?.trim());
    };
    function submit() {
        const reqObj = {
            orgid: +OrgId,
            valid_time: Hours + " hours",
        };
        const regex = /^[0-9]+$/;
        if (Hours?.trim() == "" || !Hours) {
            seterrormsg({
                message: "Valid Time Required",
            });
        } else if (!regex.test(Hours)) {
            seterrormsg({
                message: "Only Number formats allowed",
            });
        } else if (Hours == "0" || Hours == "00" || Hours == "000") {
            seterrormsg({
                message: "Set any Valid Time",
            });
        } else {
            seterrormsg({
                message: "",
            });

            dispatch(PostValidTimeAction(reqObj));
        }
    }
    useEffect(() => {
        if (data?.status && requestComplete) {
            props?.handleClose();
            Swal.fire({
                icon: "success",
                allowOutsideClick: false,
                text: "Updated successfully!",
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            }).then((confirm) => {
                if (confirm) {
                    setTimeout(() => {
                        dispatch(GetOnTimeProcessAction(Orgname == "true" ? 3 : 1, OrgId, props?.DateForm, letuserId));
                        dispatch({ type: POST_ON_TIME_PROCESS_RESET });
                    }, 1000);
                }
            });
        } else if (!data?.status && requestComplete) {
            Swal.fire({
                icon: "warning",
                allowOutsideClick: false,
                text: "Update unsuccessful!",
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            }).then((confirm) => {
                if (confirm) {
                    dispatch({ type: POST_ON_TIME_PROCESS_RESET });
                }
            });
        }
    }, [success, data?.status, requestComplete]);
    useEffect(() => {
        setHours(ReplaceTime?.trim());
    }, [ReplaceTime]);

    return (
        <>
            {/* {requestComplete && !data?.status && <MessageBox error={errormsg} />} */}
            {error && !data?.status && <MessageBox error={errormsg} />}
            <Modal cartClose={props?.handleClose} center="center" className={`${props?.className}`}>
                {!success && (
                    <div
                        className={`max-w-[600px] mx-auto relative px-[90px] text-center py-[48px] rounded-[10px] ${
                            currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                        } bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700`}>
                        <Button
                            onClick={CartClose}
                            tabIndex="4"
                            title="Close"
                            className="cursor-pointer absolute right-[20px] top-[20px] convertWhite">
                            <img src="/images/common/closeBlk.svg" alt="closeBlk" />
                        </Button>

                        <span className="font-interM text-base block mb-4">On Time Processing</span>
                        <p className="mb-4">
                            Set a time in hours for viewing the on time approved <br></br> and delayed invoices
                        </p>
                        <div className="mb-[40px]">
                            <span> Valid time</span>
                            <Input
                                className={`w-[50px] h-[30px] mx-2 rounded-[5px] border border-gray-400 text-center font-inter text-base font-medium mr-3 bg-${currentMode}-card`}
                                maxLength={3}
                                value={Hours}
                                onChange={(e) => setHours(e.target.value?.trim())}
                            />
                            <span>Hrs</span>
                            {errormsg?.message && <p className="text-light-50 text-xs mt-3">{errormsg?.message}</p>}
                        </div>

                        <div className="flex justify-center items-center space-x-2">
                            <Button
                                className="bg-light-20 px-[25px] text-base py-2 text-white rounded-[8px]"
                                type="submit"
                                tabIndex="3"
                                title="Save"
                                onClick={submit}
                                disabled={fetching}>
                                Save
                            </Button>
                        </div>
                    </div>
                )}
            </Modal>
        </>
    );
};

export default ValidTime;
