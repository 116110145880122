import Button from "../../components/Button";
import Modal from "../../components/UI/Modal/Modal";
import { RemoveData } from "../../redux/actions/setting";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect, useContext } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import MessageBox from "../../components/UI/Alert";
import { REMOVE_DEMO_DATA_RESET } from "../../redux/types/types";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";

const RemoveModal = (props) => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const { requestComplete, fetching, error, data } = useSelector((state) => state?.RemoveDemoDatasState);
    const orgId = localStorage.getItem("orgId");
    const updateOrgid = localStorage.getItem("UpdateOrgId");
    const orgData = useSelector((state) => state?.ListorganisationDropDownState);
    const dataList = orgData?.viewData;
    const errormsg = "Something went wrong please contact admin";
    const dispatch = useDispatch();
    const navigate = useNavigate;
    const DemoStatus = localStorage.getItem("DemoStatus");
    function submit() {
        if (dataList?.length == 1 && dataList?.[0]?.demo_org == true) {
            Swal.fire({
                text: "Please add another organisation to remove the demo data.",
                closeOnClickOutside: false,
                showCloseButton: true,
                icon: "warning",
                button: "Ok",
            })
        }
        else {
            dispatch(RemoveData());
            localStorage.removeItem("orgId");
            props?.handleClose();
        }

    }

    if ((!fetching && requestComplete && data?.status) || (!fetching && requestComplete && !data?.status)) {
        if (data?.status) {
            localStorage.setItem("DemoStatus", true);
            Swal.fire({
                text: "The demo data will be removed!",
                closeOnClickOutside: false,
                showCloseButton: true,
                icon: "success",
                button: "Ok",
            }).then((confirm) => {
                if (confirm) {
                    dispatch({ type: REMOVE_DEMO_DATA_RESET });
                    setTimeout(function () {
                        // window.location.reload();
                        navigate("/admin-setting");
                    }, 1000);
                }
            });
        } else if (!data?.status) {
            Swal.fire({
                text: data?.message,
                closeOnClickOutside: false,
                showCloseButton: true,
                icon: "success",
                button: "Ok",
            }).then((confirm) => {
                if (confirm) {
                    dispatch({ type: REMOVE_DEMO_DATA_RESET });
                    setTimeout(function () {
                        // window.location.reload();
                        navigate("/admin-setting");
                    }, 1000);
                }
            });
        }
    }

    useEffect(() => {
        if (dataList?.length !== 0 && (orgId == "" || orgId == "undefined" || orgId == "0" || orgId == null)) {
            localStorage.setItem("orgId", dataList?.[0]?.id);
        } else if (dataList == null && orgId == "undefined") {
            localStorage.setItem("orgId", "0");
        } else if (updateOrgid == null) {
            localStorage.setItem("UpdateOrgId", "0");
        }
    }, [dataList]);
    return (
        <>
            {/* {requestComplete && !data?.status && <MessageBox error={errormsg} />} */}
            {error && !data?.status && <MessageBox error={errormsg} />}
            <Modal cartClose={props?.handleClose} center="center" className={`${props?.className}`}>
                <div
                    className={`max-w-[500px] mx-auto relative  border-[rgba(223,225,226,0.56)] px-[30px] py-[48px] rounded-[10px] ${currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                        }  bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700`}>
                    <div className="flex  space-x-4 ">
                        <img className="bg-message rounded-[50%] w-[50px] h-[50px] mb-8" src="/images/main/remove-data.svg" alt="remove-data" />
                        <span className="font-interSb text-base block  mb-4 mt-4">Remove Demo Data</span>
                    </div>

                    <Button onClick={props?.handleClose} tabIndex="3" className={`cursor-pointer absolute right-[20px] top-[20px] convertWhite `}>
                        <img src="/images/common/closeBlk.svg" alt="closeBlk" className="md:fill-cyan-700" />
                    </Button>

                    <p className="mb-4">All changes that you made to the demo data will be lost.</p>
                    <p className="mb-4"> Are you sure to remove the data?</p>
                    <div className=" mt-[70px] space-x-2">
                        <Button
                            title="Confirm"
                            className="bg-light-20 px-[25px] py-2 text-base text-white rounded-[8px]"
                            type="submit"
                            tabIndex="1"
                            onClick={submit}
                            disabled={fetching}>
                            Confirm
                        </Button>
                        <Button
                            title="Cancel"
                            className="bg-light-240 text-light-40 px-[28px] text-base py-2 rounded-[8px]"
                            onClick={props?.handleClose}
                            tabIndex="2">
                            Cancel
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default RemoveModal;
