import moment from "moment";
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import Loader from "../../components/Loader";
import Card from "../../components/UI/Card";
import Main from "../../components/UI/Main";
import Layout from "../../Hoc/Layout";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import Footer from "../../organisms/Footer";
import { GetAccrualPosting } from "../../redux/actions/accrual-accounting";
import { ADD_ACCRUAL_POSTING_RESET } from "../../redux/types/types";
import AccrualPost from "./Post";

const AccrualSecondary = () => {
    const { currentMode } = useContext(ThemeContext);
    const dispatch = useDispatch();
    const { data: dataList, requestComplete } = useSelector((state) => state?.GetAccrualPostingState);
    useEffect(() => {
        dispatch(GetAccrualPosting());
        dispatch({ type: ADD_ACCRUAL_POSTING_RESET });
    }, []);
    return (
        <>
            <Layout>
                <Main>
                    <div className="flex justify-between items-center mb-6">
                        <div>
                            <h4 className={`text-2xl font-interSb text-${currentMode}-copy`}>Accrual Accounting</h4>
                        </div>
                        {/* <div className="flex space-x-2">
              <Link to="/new-role">
                <Button
                  title="Add Role"
                  className="border-20 bg-light-20 px-5 py-2 rounded-[8px] font-interM text-sm flex items-center border-[1px] text-white"
                >
                  <img
                    className="mr-1"
                    src="/images/common/plus.svg"
                    alt="plus"
                  />{" "}
                  New Rule
                </Button>
              </Link>
            </div> */}
                    </div>
                    <Card
                        className={`xl:h-[calc(100vh_-_13rem)] h-[calc(100vh_-_18rem)] p-[30px] ${
                            currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                        } overflow-y-auto relative border-${currentMode}-700`}
                    >
                        <h5 className="font-interM text-lg mb-1">Organization list</h5>
                        <p className="text-xs mb-[30px]">Refer to the list below for the date of the most recent accrual accounting update.</p>
                        <table
                            className={`xl:w-[80%] w-full w-[max-content] overflow-x-auto border-${currentMode}-700 !border-[1px] rounded-[8px] mr-[30px] rolemasterTable text-left`}
                        >
                            <thead className={`bg-${currentMode}-450`}>
                                <tr>
                                    <th>Sl.No</th>
                                    <th>Organization</th>
                                    <th>Last Accrual Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <>
                                    {!requestComplete ? (
                                        <Loader
                                            className={`xl:h-[calc(100vh_-_13rem)] h-[calc(100vh_-_18rem)] absolute flex left-[-10%] top-[10%] items-center justify-center`}
                                        />
                                    ) : (
                                        <>
                                            {dataList?.length !== 0 ? (
                                                dataList?.map((elem, index) => {
                                                    return (
                                                        <tr key={index} name={(index + 1)?.toString()}>
                                                            <td className="text-left">{index + 1}</td>
                                                            <td className="text-left" name={(index + 1)?.toString()}>
                                                                <p
                                                                    id={index + 1}
                                                                    className="truncate w-[200px] cursor-default"
                                                                    title={elem?.org_name}
                                                                >
                                                                    {elem?.org_name}
                                                                </p>
                                                            </td>
                                                            <td className="text-left">{elem?.last_posted}</td>
                                                            <td>
                                                                <Link
                                                                    to={`/accrual-post?orgId=${elem?.org_id}&organisation=${elem?.org_name} &date=${elem?.user_date}`}
                                                                    className={`border-b-[1px] items-end text-light-10 border-10 border-${currentMode}-830`}
                                                                    //onClick={handleGetPostDate}
                                                                >
                                                                    Next{" "}
                                                                    <img
                                                                        src="/images/main/chevro-r-green.svg"
                                                                        alt="chevro-r-green"
                                                                        className="inline-block"
                                                                    />
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <div className="absolute top-[70%] left-[38%] translate-y-[-70%] translate-x-[-38%]">
                                                    <img src="/images/common/delivery.png" alt="delivery" />
                                                    <h4 className="font-interSb text-center mt-3">No data found</h4>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </>
                            </tbody>
                        </table>
                    </Card>
                </Main>
                <Footer />
            </Layout>
        </>
    );
};

export default AccrualSecondary;
