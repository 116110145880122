import { ClickAwayListener, Fade, FormControl, MenuItem, Popper, Select } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ReactDOM from "react-dom";
import _ from "lodash";
import Button from "../../../components/Button";
import Main from "../../../components/UI/Main";
import Layout from "../../../Hoc/Layout";
import Table from "./Table";
import Footer from "../../../organisms/Footer";
import { getInvApprove, invoiceCountTotal, InvoiceList } from "../../../redux/actions/invoice";
import { cartToggleAction } from "../../../redux/actions/filter";
import { useEffect, useState } from "react";
import { InvoiceStatusApproved, InvoiceStatusRejected, getInvoiceItems, InvoiceFlag } from "../../../redux/actions/invoice";
import ExportExcel from "../../ExportFiles/Excel";
import InvoiceProcessFilterModal from "../../../organisms/FilterModal/Invoiceprocessfilter";
import UseTour from "../../../Hooks/ProductTour";
import { getCurrentUser } from "../../../redux/actions/auth";
import { useContext } from "react";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import {
    INVOICE_APPROVED_LIST_RESET,
    INVOICE_REJECTED_LIST_RESET,
    INVOICE_FLAG_RESET,
    POST_INVOICE_APPROVER_RESET,
    INVOICE_GET_RESET,
    EXPORT_INVOICE_LIST_RESET,
} from "../../../redux/types/types";
import { UserDropDown } from "../../../redux/actions/user";
import SendToApprovePopup from "./SendToApprove";
import moment from "moment";
import { downloadeExcel } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";
import React from "react";

let temp;
let StatusValue = [];
let InvId = [];

const InvoiceListItem = () => {
    const location = useLocation();
    const [pageCountValue, setPageCount] = useState("");
    const orgId = localStorage.getItem("orgId") || 0;
    const date = new Date();
    const d = date.toLocaleDateString("en-GB");
    const CurrentUser = getCurrentUser();
    const locationArr = location.pathname === "/approve" ? [7] : [2];
    const selectedOrgId = localStorage.getItem("orgId");
    const headers = [];
    const currentUser = getCurrentUser();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [filterStatus, setFilterStatus] = useState("");
    const [user, setUser] = useState(0);
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState();
    const [approveList, onApproveInvoice] = useState([]);
    const [isApprove, onApprovenvDoc] = useState(false);
    const [isValidate, setisValidate] = useState(false);
    const [isFlaged, onFlag] = useState(false);
    const [isReject, onRejectedDoc] = useState(false);
    const [isdownloadClick, onClickDownload] = useState(false);
    const [filterObj, setFilterObj] = useState(null);
    const [isTourOpen, setIsTourOpen] = useState(false);
    const [OrderByValue, setOrderBy] = useState(null);
    const [columnSorting, setcolumnSorting] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [Onrefresh, setOnrefresh] = useState(false);
    const [resultstatus, setresultstatus] = useState(false);
    const [searchValue, handleSearchChange] = useState("");
    const [invoiceType, setInvoiceType] = useState(0);
    const [filterSupplierValue, setFilterSupplierValue] = useState("");
    const [filterStatusValue, setFilterStatusValue] = useState([2]);
    const [isAllSelected, setAllSelected] = useState(false);
    const [downloadAllData, setDownloadAllData] = useState(false);
    const [OpenApprove, setOpenApprove] = useState(false);
    // let OpenApprove = false
    let [statusOptionArray, setstatusOptionArray] = useState(location.pathname === "/approve" ? [7] : [2]);
    const getApproveInvList = (invList) => {
        onApproveInvoice(invList);
    };
    const [statusid, setStatus] = useState(filterObj?.status_id || "");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const { dataList: Lists, exportData } = useSelector((state) => state?.InvoiceListState);
    const IsAllocation = Lists?.[0]?.is_allocation;

    //* Translate Words:
    const { t } = useTranslation(["invoiceList"]);

    const invoiceList = useSelector((state) => state?.getInvoiceItemsState);
    let { data, error, fetching, requestComplete } = invoiceList;
    const dataList = data || [];
    const selectedInvoiceId = Lists?.filter((item) => item.checked === true);
    const checkTransferred = Lists?.filter((item) => item?.istransfer == true);
    const MessageTypeItem = Lists?.filter((item) => item?.checked === true && item?.message_type === "e");

    const IsTaskItem = Lists?.filter((item) => item?.checked === true && item?.istask === true);
    const onApproveOrReject = (process) => {
        if (Lists.length > 0) {
            const selectedInvoiceList = Lists.filter((item) => item.checked === true);
            const checkTransferred = selectedInvoiceList?.filter((item) => item?.istransfer == true);
            const checkRejectedStatus = selectedInvoiceList?.filter((item) => item?.ext_status_id == 5);
            const checkApproveStatusUpdt = selectedInvoiceList?.filter((item) => item?.ext_status_id !== 7);
            const selectedInvIds = selectedInvoiceList.map((item) => item.id);
            if (selectedInvIds?.length == 0) {
                Swal.fire({
                    text: "Select any one Invoice!",
                    icon: "warning",
                    closeOnClickOutside: false,
                    showCloseButton: true,
                    button: "Ok",
                });
            } else if (checkApproveStatusUpdt?.length !== 0 && process === "approve") {
                Swal.fire({
                    text: `Unable to ${process} the selected Request(s)!`,
                    icon: "warning",
                    closeOnClickOutside: false,
                    showCloseButton: true,
                    button: "Ok",
                });
            } else if (checkTransferred?.length !== 0) {
                Swal.fire({
                    text: `Unable to ${process} the selected Invoice(s)!`,
                    icon: "warning",
                    closeOnClickOutside: false,
                    showCloseButton: true,
                    button: "Ok",
                });
            } else if (checkRejectedStatus?.length !== 0) {
                Swal.fire({
                    text: `Unable to ${process} the rejected Invoice(s)!`,
                    icon: "warning",
                    closeOnClickOutside: false,
                    showCloseButton: true,
                    button: "Ok",
                });
            } else
                Swal.fire({
                    text: `Are you sure you want to ${process} selected Invoice(s) ?`,
                    icon: "warning",
                    closeOnClickOutside: false,
                    showCloseButton: true,
                    showCancelButton: true,
                    buttons: true,
                    dangerMode: true,
                }).then((result) => {
                    if (result.isConfirmed && process === "validate") {
                        dispatch(
                            InvoiceStatusApproved({
                                id: selectedInvIds,
                                status: 6,
                            })
                        );
                        setisValidate(true);
                    }
                    if (result.isConfirmed && process === "approve") {
                        dispatch(
                            InvoiceStatusApproved({
                                id: selectedInvIds,
                            })
                        );
                        onApprovenvDoc(true);
                    } else if (result.isConfirmed && process === "reject") {
                        dispatch(
                            InvoiceStatusRejected({
                                id: selectedInvIds,
                            })
                        );
                        onRejectedDoc(true);
                    }
                });
        } else {
            Swal.fire({
                text: "Please select any one Invoice to " + process + "!",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                button: "Ok",
            });
        }
    };
    const onFlagInvoice = () => {
        if (Lists.length > 0) {
            const selectedInvoiceList = Lists.filter((item) => item.checked === true);
            const checkTransferred = selectedInvoiceList?.filter((item) => item?.istransfer == true);
            const checkRejectedStatus = selectedInvoiceList?.filter((item) => item?.ext_status_id == 5);
            const selectedInvIds = selectedInvoiceList.map((item) => item.id);
            if (selectedInvIds?.length == 0) {
                Swal.fire({
                    text: "Please select any one Invoice to (Un)Flag!",
                    icon: "warning",
                    closeOnClickOutside: false,
                    showCloseButton: true,
                    button: "Ok",
                });
            } else if (checkTransferred?.length !== 0) {
                Swal.fire({
                    text: "Unable to (Un)Flag the Transferred Invoice(s)!",
                    icon: "warning",
                    closeOnClickOutside: false,
                    showCloseButton: true,
                    button: "Ok",
                });
            } else if (checkRejectedStatus?.length !== 0) {
                Swal.fire({
                    text: "Unable to (Un)Flag the Rejected Invoice(s)!",
                    icon: "warning",
                    closeOnClickOutside: false,
                    showCloseButton: true,
                    button: "Ok",
                });
            } else
                Swal.fire({
                    text: "Are you sure you want to (Un)Flag the selected Invoice(s)?",
                    className: "",
                    icon: "warning",
                    showCloseButton: true,
                    closeOnClickOutside: false,
                    showCancelButton: true,
                    buttons: {
                        cancel: "Cancel",
                        confirm: "Confirm",
                    },
                }).then((result) => {
                    if (result.isConfirmed) {
                        dispatch(
                            InvoiceFlag({
                                id: selectedInvIds,
                            })
                        );
                        onFlag(true);
                    }
                });
        } else {
            Swal.fire({
                text: "Select any one Invoice to (Un)Flag!",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                button: "Ok",
            });
        }
    };

    const showMessageBox = (response, message) => {
        const responseData = response?.data || response?.dataList;
        if (response.error !== "") {
            Swal.fire({
                text: response.error,
                icon: "error",
                closeOnClickOutside: false,
                showCancelButton: true,
                showCloseButton: true,
                button: "Ok",
            }).then((result) => {
                if (result.isConfirmed || result.isDismissed) {
                    dispatch({ type: INVOICE_REJECTED_LIST_RESET });
                    dispatch({ type: INVOICE_APPROVED_LIST_RESET });
                    dispatch({ type: INVOICE_FLAG_RESET });
                    onRejectedDoc(false);
                    onApprovenvDoc(false);
                    setisValidate(false);
                    setresultstatus(true);
                    setTimeout(() => {
                        setresultstatus(false);
                    }, 1000);
                }
            });
        } else if (responseData && response?.requestComplete) {
            if (!responseData.status) {
                Swal.fire({
                    text: responseData.message,
                    icon: "error",
                    showCloseButton: true,
                    closeOnClickOutside: false,
                    button: "Ok",
                }).then((result) => {
                    if (result.isConfirmed || result.isDismissed) {
                        dispatch({ type: INVOICE_REJECTED_LIST_RESET });
                        dispatch({ type: INVOICE_APPROVED_LIST_RESET });
                        dispatch({ type: INVOICE_FLAG_RESET });
                        onRejectedDoc(false);
                        onApprovenvDoc(false);
                        setisValidate(false);
                        setresultstatus(true);
                        setTimeout(() => {
                            setresultstatus(false);
                        }, 1000);
                    }
                });
            } else {
                response.requestComplete = false;
                Swal.fire({
                    text: message,
                    icon: "success",
                    closeOnClickOutside: false,
                    showCloseButton: true,
                    showCloseButton: true,
                    button: "Ok",
                }).then((result) => {
                    if (
                        (isApprove && (result.isConfirmed || result.isDismissed)) ||
                        (isReject && (result.isConfirmed || result.isDismissed)) ||
                        (isFlaged && (result.isConfirmed || result.isDismissed)) ||
                        (isValidate && (result.isConfirmed || result.isDismissed))
                    ) {
                        dispatch({ type: INVOICE_REJECTED_LIST_RESET });
                        dispatch({ type: INVOICE_APPROVED_LIST_RESET });
                        dispatch({ type: INVOICE_FLAG_RESET });
                        onRejectedDoc(false);
                        onApprovenvDoc(false);
                        setisValidate(false);
                        navigate(location.pathname);
                        setresultstatus(true);
                        setRefresh(true);
                        setTimeout(() => {
                            setRefresh(false);
                            setresultstatus(false);
                        }, 1000);
                    }
                });
            }
        }
    };
    const approveResponse = useSelector((state) => state?.InvoiceApprovedState);
    const rejectResponse = useSelector((state) => state?.InvoiceRejectedState);
    const flagedResponse = useSelector((state) => state?.InvoiceFlagState);
    if (isApprove) {
        showMessageBox(approveResponse, "Your invoice is approved successfully!");
    } else if (isValidate) {
        showMessageBox(approveResponse, approveResponse?.dataList?.message);
    } else if (isReject) {
        showMessageBox(rejectResponse, "Your invoice is Rejected successfully!");
    } else if (isFlaged) {
        showMessageBox(flagedResponse, "Invoice is (Un)Flagged successfully!");
    }

    const handleClick = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    const filterSubmit = (reqParams) => {
        setFilterObj(reqParams);
    };

    const OrderBy = (reqPara) => {
        setOrderBy(reqPara);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    const [exportFile, setExportFile] = useState(false);
    if (isdownloadClick) {
        setTimeout(() => {
            setExportFile(false);
        }, 3000);
    }
    const pageData = useSelector((state) => state?.InvoiceCountState);
    const pageCount = pageData && pageData.data && pageData.data?.data ? pageData.data.data.data.rec_cnt : 0;
    const Fromdate = moment(new Date(fromDate)).format("YYYY-MM-DD") == "Invalid date" ? "" : moment(new Date(fromDate)).format("YYYY-MM-DD");
    const Todate = moment(new Date(toDate)).format("YYYY-MM-DD") == "Invalid date" ? "" : moment(new Date(toDate)).format("YYYY-MM-DD");

    // const onExportExcel = () => {
    //     onClickDownload(true);
    //     setExportFile(true);
    // };

    if (error) {
        Swal.fire({
            title: error.message,
            text: "Something went wrong. Please contact the admin.",
            icon: "error",
            closeOnClickOutside: false,
            showCloseButton: true,
            button: "Ok",
        }).then((confirm) => {
            if (confirm) {
                dispatch({ type: INVOICE_GET_RESET });
            }
        });
        error = "";
    }
    const downloadExcel = (downloadInvoiceList, name) => {
        let downloadListData = downloadInvoiceList;
        if (downloadListData) {
            const invoiceExcelData = [];
            downloadListData?.forEach((item) => {
                let invHeaders = {
                    "Invoice id": item?.inv_seqno,
                    "Invoice Number": item?.invoice_no,
                    "Invoice Type": item?.document_type,
                    "Vendor Name": item?.vendor,
                    "Invoice Date": item?.invoice_date,
                    "Net Amount": item?.net_amount,
                    Currency: item?.currency,
                    // "Created Date": item?.createddate
                    //     ? moment(item?.createddate).format("MM/DD/YYYY")
                    //     : "",
                    "Created Date": item?.createddate_formated,
                    // "Modified Date": item?.modifieddate
                    //     ? moment(item?.modifieddate).format("MM/DD/YYYY")
                    //     : "",
                    "Modified Date": item?.modifieddate_formated,
                    ...(location.pathname == "/invoice" ? { Status: item?.ext_status } : ""),
                };

                // let invoiceFields = _.map(item.invoice_items, (object) => {
                //     return _.pick(object, ["label_name", "label_value"]);
                // });

                // invoiceFields.forEach((item) => {
                //     invHeaders[_.capitalize(item.label_name.replace("_", " "))] =
                //         item.label_value;
                // });
                invoiceExcelData.push(invHeaders);
            });
            const TrialMessage = currentUser?.license_type == 2 ? "Upgrade your plan to Download all your Invoices at once" : "";
            invoiceExcelData.push([TrialMessage]);
            ExportExcel({ excelData: invoiceExcelData, fileName: "MyiQ.Ai" });
            onApproveInvoice([]);
            onClickDownload(false);
            setAllSelected(false);
            setDownloadAllData(false);
        }
    };
    const onExportExcel = () => {
        const reqObj = {
            status_type:
                location.pathname === "/invoice"
                    ? 4
                    : location.pathname === "/exception"
                        ? 1
                        : location.pathname === "/processed"
                            ? 2
                            : location.pathname === "/approve"
                                ? 3
                                : "",
            status_id: statusOptionArray,
            org_group: currentUser?.organisation,
            vendor_id: filterObj?.vendor_id ? filterObj?.vendor_id : filterSupplierValue,
            invoice_date_from: fromDate,
            invoice_date_to: toDate,
            search_text: searchValue || "",
            order_column: "",
            order_by: 0,
            pg_no: 1,
            rec_cnt: pageCount,
            organisation: +orgId,
            invoice_no: "",
            document_type: invoiceType?.value,
        };
        if (Lists !== null) {
            dispatch(InvoiceList(reqObj, "download"));
            setDownloadAllData(true);
        }
        if (exportData?.length > 0) {
            onClickDownload(true);
            const selectedInvIds = exportData.map((item) => item);
            const SlicedArray = selectedInvIds?.slice(0, 5);
            const SelectedValueId = currentUser?.license_type == 2 && selectedInvIds?.length > 5 ? SlicedArray : selectedInvIds;
            const reqObj = {
                id: SelectedValueId, //selectedInvIds
            };
            // dispatch(getInvoiceItems(reqObj));
            setExportFile(true);
            // if (dataList?.length !== 0) {
            // }
            // setTimeout(() => {
            //     if (data?.status) {
            //         // window.location.reload();
            //         onRefreshPage();
            //     }
            // }, 5000);
            if (selectedInvIds?.length == 0) {
                Swal.fire({
                    text: "Select any one Invoice to (Un)Flag!",
                    icon: "warning",
                    closeOnClickOutside: false,
                    showCloseButton: true,
                    button: "Ok",
                });
            }
        }
        // else {
        //     Swal.fire({
        //         text: "Please select any one Invoice to download",
        //         icon: "warning",
        //         closeOnClickOutside: false,
        //         button: "Ok",
        //     });
        // }
    };

    useEffect(() => {
        if (exportData?.length > 0 && downloadAllData) {
            onClickDownload(true);
            const selectedInvIds = exportData?.map((item) => item);
            const SlicedArray = selectedInvIds?.slice(0, 5);
            downloadExcel(currentUser?.license_type == 2 && exportData?.length > 5 ? SlicedArray : selectedInvIds);

            // const SelectedValueId = currentUser?.license_type == 2 && selectedInvIds?.length > 5 ? SlicedArray : selectedInvIds;
            // const reqObj = {
            // id: SelectedValueId, //selectedInvIds
            // };
            // dispatch(getInvoiceItems(reqObj));
            setExportFile(true);
        }
        const data = {
            org_id: +orgId,
            status: 2,
        };
        dispatch(UserDropDown(data));
    }, [exportData]);

    // if (isdownloadClick) {
    //     console.log("Inside");
    //     let downloadInvoiceList = dataList;
    //     downloadExcel(downloadInvoiceList, "oneItem");
    // }
    // currentUser?.license_type == 2 && selectedInvIds?.length > 5 ? SlicedArray :
    const downloadInvoice = (invId) => {
        if (invId) {
            onClickDownload(true);
            const selectedInvIds = [invId];
            const reqObj = {
                id: selectedInvIds, //selectedInvIds
                checkViewInv: false,
            };
            dispatch(getInvoiceItems(reqObj));
        }
    };
    if (isdownloadClick && requestComplete && data) {
        downloadeExcel(invoiceList?.data, onClickDownload);
    }
    const { currentMode } = useContext(ThemeContext);

    // useEffect(() => {
    //     let bodys = document.querySelector("body").classList;
    //     location.pathname === "/invoice" &&
    //         bodys.remove(`bg-${currentMode}-base`);
    // }, [location.pathname]);

    const isOpen = useSelector((state) => state?.cartToggleState);
    const cartOpen = (e) => {
        e.preventDefault();
        dispatch(cartToggleAction(true));
    };
    let showActionBtn = true;
    if (filterObj?.status_id[0] === 3 || filterObj?.status_id[0] === 4 || filterObj?.status_id[0] == 5) {
        showActionBtn = false;
    }

    const DisplayRole = useSelector((state) => state?.DisplayRolePermissionStateAction);
    const { data: DataList } = DisplayRole;
    const RoleList = DataList?.role_info;
    const RoleMenu = RoleList?.menu;
    const RoleProcess = RoleMenu?.find((elem) => elem?.menu_id === 8);
    const RoleProcessAction = RoleProcess?.actions;
    const RoleActProcessApprove = RoleProcessAction?.find((element) => element.id === 6);
    const RoleActProcessReject = RoleProcessAction?.find((element) => element.id === 9);
    const RoleActProcessFlag = RoleProcessAction?.find((element) => element.id === 18);
    const RoleApproval = RoleMenu?.find((elem) => elem?.menu_id === 16);
    const RoleApprovalAction = RoleApproval?.actions;
    const RoleActApprove = RoleApprovalAction?.find((element) => element.id === 6);
    const RoleActApproveReject = RoleApprovalAction?.find((element) => element.id === 9);
    const RoleActApproveFlag = RoleApprovalAction?.find((element) => element.id === 18);

    const targetDateStr = currentUser?.expiring_date;
    const targetDates = new Date(targetDateStr);
    const currentDate = new Date();
    const matchdate = targetDates < currentDate;
    const showIcon =
        (!matchdate && CurrentUser?.license_type == 2) ||
        CurrentUser?.license_type == 1 ||
        CurrentUser?.license_type == 3 ||
        CurrentUser?.license_type == 4;
    const ifValidate = Lists !== undefined && Lists !== null && Lists[0]?.targetvalue === "SAP" && showActionBtn;
    const Validate = ifValidate && {
        selector: ".validate-step",
        content: "Click on this Validate button to validate the invoices",
        action: (node) => {
            node?.scrollIntoView({ behavior: "smooth" });
        },
    };

    const Approve = showActionBtn &&
        location.pathname == "/invoice" &&
        RoleActProcessApprove?.isallowed == true && {
        selector: ".approve-step",
        content: "Click on this Approve button to approve the invoices",
        action: (node) => {
            node?.scrollIntoView({ behavior: "smooth" });
        },
    };

    const tourConfig = [
        {
            selector: ".export-step",
            content: "Click on this Export to Excel button to  download the invoices",
            action: (node) => {
                node?.scrollIntoView({ behavior: "smooth" });
            },
        },
        {
            selector: ".flag-step",
            content: "Click on this (Un)Flag button to flag or unflag the invoices",
            action: (node) => {
                node?.scrollIntoView({ behavior: "smooth" });
            },
        },
        {
            selector: ".reject-step",
            content: "Click on this Reject button to reject the invoices",
            action: (node) => {
                node?.scrollIntoView({ behavior: "smooth" });
            },
        },
        Validate,
        Approve,
        {
            selector: ".search-step",
            content: "Enter the Search text to search the invoices",
            action: (node) => {
                node?.scrollIntoView({ behavior: "smooth" });
            },
        },
        {
            selector: ".filter-step",
            content: "Click on this Filter button to view more filter options",
            action: (node) => {
                node?.scrollIntoView({ behavior: "smooth" });
            },
        },
    ];
    const NewSteps = tourConfig?.filter((el) => el !== false);
    const profileData = useSelector((state) => state?.userListDropDownState);
    const { data: dataListItem } = profileData;
    const dataLists = dataListItem?.filter((el) => el?.isactivated !== false || el?.user_isactive !== false);
    const ApprovalList = dataLists?.filter((el) => el?.is_approval == true);
    const ProcessedList = dataLists?.filter((el) => el?.is_processed == true);
    const UsersDataList = location.pathname === "/approve" ? ApprovalList : ProcessedList;
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const handleUserChange = (value) => {
        setUser(value);
        const reqObjCount = {
            status_type:
                location.pathname === "/invoice"
                    ? 4
                    : location.pathname === "/exception"
                        ? 1
                        : location.pathname === "/processed"
                            ? 2
                            : location.pathname === "/approve"
                                ? 3
                                : "",
            status_id: statusOptionArray,
            vendor_id: filterObj?.vendor_id ? filterObj?.vendor_id : filterSupplierValue,
            invoice_date_from: moment(new Date(fromDate))?.format("YYYY-MM-DD") || "",
            invoice_date_to: moment(new Date(toDate))?.format("YYYY-MM-DD") || "",
            search_text: searchValue || "",
            organisation: +orgId,
            invoice_no: "",
            document_type: 0,
            alloc_user: value,
        };
        const reqObj = {
            status_type:
                location.pathname === "/invoice"
                    ? 4
                    : location.pathname === "/exception"
                        ? 1
                        : location.pathname === "/processed"
                            ? 2
                            : location.pathname === "/approve"
                                ? 3
                                : "",
            status_id: statusOptionArray,
            org_group: currentUser?.organisation,
            vendor_id: filterObj?.vendor_id ? filterObj?.vendor_id : filterSupplierValue,
            invoice_date_from: moment(new Date(fromDate))?.format("YYYY-MM-DD") || "",
            invoice_date_to: moment(new Date(toDate))?.format("YYYY-MM-DD") || "",
            search_text: searchValue || "",
            order_column: "",
            order_by: 0,
            pg_no: 1,
            rec_cnt: 10,
            organisation: +orgId,
            invoice_no: "",
            document_type: invoiceType?.value,
            alloc_user: value,
        };
        // dispatch(invoiceCountTotal(reqObjCount));
        // dispatch(InvoiceList(reqObj));
    };
    const sendToapprove = useSelector((state) => state?.SendToApproverState);
    const [index, setIndex] = useState(0);
    const [sync, setSync] = useState(false);
    const [showInvName, setShowInvName] = useState("");
    const [ShowSeqName, setShowSeqName] = useState("");

    const [progress, setProgress] = useState(0);
    const selectedInvoiceIdItem = selectedInvoiceId?.map((el) => el?.id);
    const selectedInvSeqNo = selectedInvoiceId?.map((el) => el?.inv_seqno);

    const ClickSendToApprover = () => {
        setSync(true);
    };

    const StatusTrueCount = StatusValue?.filter((el) => el == true);
    const StatusFalseCount = StatusValue?.filter((el) => el == false);
    const FilterFailedInv = InvId?.filter((el) => el?.status == false && el?.InvId !== undefined);

    const Swals = (message) => {
        Swal.fire({
            text: message,
            closeOnClickOutside: false,
            showCloseButton: true,
            button: "Ok",
            icon: "warning", // Define a CSS class for the custom icon
            customClass: {
                closeButton: "close-button", // Define a CSS class for the close button
            },
        }).then((result) => {
            if (result.isConfirmed) {
                setSync(false);
            }
            if (result.isDismissed) {
                setSync(false);
            }
        });
    };

    useEffect(() => {
        if (selectedInvoiceIdItem?.length == 0 && sync) {
            Swals("Select any one Invoice to Process!");
        } else if (MessageTypeItem?.length !== 0 && sync) {
            Swals("The system is not able to send the Invoice(s) to approver with exceptions!");
        } else if (checkTransferred?.length !== 0 && sync) {
            Swals("Unable to send the transferred Invoice(s) to approver!");
        } else if (IsTaskItem?.length !== 0 && sync) {
            Swals("Invoice(s) with  pending tasks  cannot be sent to approver!");
        } else if (sendToapprove?.requestComplete == false && selectedInvoiceId?.length > index) {
            if (sync) {
                dispatch(getInvApprove(orgId, selectedInvoiceIdItem[index], 2));
                temp = index + 1;
                setIndex(temp);
                if (selectedInvoiceIdItem?.length == temp) {
                    setSync(false);
                }
            }
            setShowInvName(selectedInvoiceIdItem[index]);
            setShowSeqName(selectedInvSeqNo[index]);
        } else if (sendToapprove?.requestComplete) {
            const ErrorStatus = sendToapprove?.error?.response?.data?.status;
            const status = sendToapprove?.data?.status == undefined ? ErrorStatus : sendToapprove?.data?.status;
            const myObject = {
                InvId: ShowSeqName,
                status: status,
            };
            InvId?.push(myObject);
            StatusValue?.push(status);

            dispatch({ type: POST_INVOICE_APPROVER_RESET });

            setProgress((index * 100) / selectedInvoiceIdItem?.length);
        }
        if (sendToapprove?.fetching || sendToapprove?.requestComplete) {
            setOpenApprove(true);
        } else {
            setInterval(() => {
                setProgress(100);
            }, 1800);
        }
    }, [sendToapprove?.requestComplete, sync]);
    const CartClose = () => {
        setOpenApprove(false);
        window.location.reload();
    };
    return (
        <>
            <React.Suspense fallback="loading...">
                <Layout>
                    <Main>
                        {selectedOrgId ? (
                            <>
                                <div className="lg:flex justify-between mb-[10px] space-y-4 xl:space-y-0 items-center">
                                    <div className="flex items-center space-x-2">
                                        <h4 className={`text-2xl text-${currentMode}-copy lg:mb-0 font-interSb`}>
                                            {" "}
                                            {location.pathname === "/approve" ? "Approval Invoice List" : "Invoice List"}
                                        </h4>
                                        {/* <img
                                        title={
                                            location.pathname == "/invoice"
                                                ? "Processed Invoice Tour"
                                                : "Exception Invoice Tour"
                                        }
                                        className="cursor-pointer"
                                        onClick={openTour}
                                        src="/images/common/tour.svg"
                                        alt="tour"
                                    /> */}
                                    </div>

                                    <div className="flex flex-wrap xl:space-x-2 md:space-x-2">
                                        {currentUser?.role_id === 2 ? (
                                            <div>
                                                <FormControl
                                                    sx={{
                                                        minWidth: "125px",
                                                    }}>
                                                    <Select
                                                        value={user || "0"}
                                                        name="UserList"
                                                        onChange={(e) => handleUserChange(e.target.value)}
                                                        inputProps={{
                                                            "aria-label": "Without label",
                                                        }}
                                                        IconComponent={() => (
                                                            <img
                                                                className="absolute opacity-50 right-[15px] pointer-events-none"
                                                                src={
                                                                    currentMode !== "dark"
                                                                        ? `/images/header/chevronDown.svg`
                                                                        : `/images/header/chevronDown-white.svg`
                                                                }
                                                            />
                                                        )}
                                                        MenuProps={{
                                                            sx: {
                                                                "& .MuiMenu-paper": {
                                                                    backgroundColor: dropdownColor,
                                                                    color: dropdownText,
                                                                },
                                                                "& .MuiMenuItem-root:hover": {
                                                                    backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                                },
                                                                "& .Mui-selected": {
                                                                    backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                                },
                                                            },
                                                        }}
                                                        SelectDisplayProps={{
                                                            style: {
                                                                paddingTop: "9px",
                                                                paddingBottom: "9px",
                                                                color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                                paddingRight: "30px",
                                                                border: "1px solid rgba(85, 85, 85, 0.40)",
                                                                borderRadius: "8px",
                                                            },
                                                        }}>
                                                        <MenuItem key={0} name="UserList" value={0}>
                                                            {"All Users"}
                                                        </MenuItem>
                                                        {dataLists &&
                                                            dataLists?.map((listelem, i) => {
                                                                return (
                                                                    <MenuItem key={i + 1} name="UserList" value={listelem?.id}>
                                                                        {listelem?.user_name}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        ) : (
                                            ""
                                        )}{" "}
                                        <Button
                                            title="To Export Invoice to Excel"
                                            onClick={() => onExportExcel()}
                                            className={`border-light-20 mr-2 xl:mr-0 mb-2 export-step px-[15px] py-2 rounded-[8px] font-interM text-sm flex justify-center items-center border-[1px] text-${currentMode}-copy`}>
                                            <img className="mr-[.4rem]" src="/images/common/download-excel.svg" alt="upload" /> {t("Download")}
                                        </Button>
                                        {/* {location.pathname !== "/approve" && IsAllocation && showIcon && (
                                        <Button
                                            title="Send To Approver"
                                            type="submit"
                                            onClick={() => ClickSendToApprover()}
                                            className=" mr-2 mb-2 reject-step border-[#30B4CD] w-[180px] bg-[#30B4CD] px-[15px] py-2 rounded-[8px] font-interM text-sm flex justify-center items-center border-[1px] text-white"
                                        >
                                            <img className="mr-[.4rem]" src="/images/invoice/send-to-approver.svg" alt="upload" /> Send To Approver
                                        </Button>
                                    )} */}
                                        {(showActionBtn && location.pathname == "/invoice" && RoleActProcessFlag?.isallowed == true) ||
                                            (location.pathname == "/exception" && RoleActProcessFlag?.isallowed == true) ||
                                            (location.pathname == "/processed" && RoleActProcessFlag?.isallowed == true) ||
                                            (location.pathname == "/approve" && RoleActApproveFlag?.isallowed == true)
                                            ? filterObj?.status_id[0] !== 8 && (
                                                <Button
                                                    title="To Flag/Unflag Invoices"
                                                    onClick={() => onFlagInvoice()}
                                                    className=" mr-2 mb-2 flag-step border-light-100 w-[120px] bg-light-100 px-[15px] py-2 rounded-[8px] font-interM text-sm flex justify-center items-center border-[1px] text-white">
                                                    <img className="mr-[.4rem]" src="/images/invoice/flagwhite.svg" alt="upload" /> {t("Flag")}
                                                </Button>
                                            )
                                            : null}
                                        {/* <Button
             
                className="border-430 w-[120px] bg-light-430 px-7 py-2 rounded-[8px] font-interM text-sm flex justify-center items-center border-[1px] text-white"
              >
                <img
                  className="mr-[.4rem]"
                  src="/images/invoice/userwhite.svg"
                  alt="upload"
                />{" "}
                Assign
              </Button> */}
                                        {showActionBtn && location.pathname !== "/approve" && RoleActProcessReject?.isallowed == true
                                            ? filterObj?.status_id[0] !== 8 && (
                                                <Button
                                                    title="To Reject Invoices"
                                                    type="submit"
                                                    onClick={() => onApproveOrReject("reject")}
                                                    className=" mr-2 mb-2 reject-step border-[#F04438] w-[120px] bg-[#F04438] px-[15px] py-2 rounded-[8px] font-interM text-sm flex justify-center items-center border-[1px] text-white">
                                                    <img className="mr-[.4rem]" src="/images/Invoice-Status/reject-new-button.svg" alt="upload" />{" "}
                                                    {t("Reject")}
                                                </Button>
                                            )
                                            : null}
                                        {/* {Lists !== undefined &&
                                        Lists !== null &&
                                        Lists[0]?.targetvalue === "SAP" &&
                                        location.pathname !== "/approve" &&
                                        showIcon &&
                                        showActionBtn
                                        ? filterObj?.status_id[0] !== 8 && (
                                            <Button
                                                title="To Validate Invoices"
                                                type="submit"
                                                onClick={() =>
                                                    onApproveOrReject(
                                                        "validate"
                                                    )
                                                }
                                                className="border-light-280 mb-2 validate-step w-[120px] mr-2 bg-light-280 px-7 py-2 rounded-[8px] font-interM text-sm flex justify-center items-center border-[1px] text-white"
                                            >
                                                <img
                                                    className="mr-[.4rem]"
                                                    src="/images/common/valid-checking.svg"
                                                    alt="upload"
                                                />{" "}
                                                Validate
                                            </Button>
                                        )
                                        : null} */}
                                        {/* {showActionBtn && location.pathname=='/invoice'&&RoleActProcessApprove?.isallowed==true ||location.pathname=="/exception" &&RoleActExceptionApprove?.isallowed==true? */}
                                        {((showActionBtn && location.pathname == "/approve") ||
                                            (location.pathname !== "/approve" && !IsAllocation)) &&
                                            RoleActApprove?.isallowed == true
                                            ? filterObj?.status_id[0] !== 8 && (
                                                <Button
                                                    title="To Approve Invoices"
                                                    type="submit"
                                                    onClick={() => onApproveOrReject("approve")}
                                                    className=" mb-2 border-transparent approve-step border-440 w-[120px] bg-light-440 px-7 py-2 rounded-[8px] font-interM text-sm flex justify-center items-center border-[1px] text-white">
                                                    <img className="mr-[.4rem]" src="/images/common/tickWhite.svg" alt="upload" /> {t("Approve")}
                                                </Button>
                                            )
                                            : null}
                                        {/* <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                onClickAway={handleClickAway}
              >
                <Popper
                  className={`bg-white px-3 py-3 border-[1px] border-[rgba(223,225,226,0.56)] shadow-[0px_4px_20px_rgba(238,238,238,0.5)] rounded-[10px]`}
                  open={open}
                  sx={{ minWidth: 120 }}
                  anchorEl={anchorEl}
                  placement={placement}
                  transition
                >
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                      <div>
                        <ul className="space-y-3">
                          <li>
                            <Link className="flex" onClick={()=>onExportExcel()}>
                              <img
                                className="flex-none pr-2"
                                src="/images/common/excel.svg"
                                alt="user"
                              />
                              Excel
                            </Link>
                          </li>
                          <li>
                            <Link className="flex" to="#!">
                              <img
                                className="flex-none pr-2"
                                src="/images/common/excel.svg"
                                alt="help"
                              />
                              CSV
                            </Link>
                          </li>

                          <li>
                            <Link className="flex" to="#!">
                              <img
                                className="flex-none pr-2"
                                src="/images/common/pdf.svg"
                                alt="log-in"
                              />
                              PDF
                            </Link>
                          </li>
                          <li>
                            <Link className="flex" to="#!">
                              <img
                                className="flex-none pr-2"
                                src="/images/common/jpg.svg"
                                alt="log-in"
                              />
                              JPG
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </Fade>
                  )}
                </Popper>
              </ClickAwayListener> */}
                                    </div>
                                </div>
                                <Table
                                    onApproveList={getApproveInvList}
                                    filterObj={filterObj}
                                    fetching={fetching}
                                    locationArr={locationArr}
                                    refresh={refresh}
                                    Onrefresh={Onrefresh}
                                    resultstatus={resultstatus}
                                    setOnrefresh={setOnrefresh}
                                    CartOpen={cartOpen}
                                    exportFile={exportFile}
                                    downloadInvoice={downloadInvoice}
                                    setFromDate={setFromDate}
                                    setToDate={setToDate}
                                    fromDate={fromDate}
                                    toDate={toDate}
                                    TotalInv={setPageCount}
                                    handleSearchChange={handleSearchChange}
                                    searchValue={searchValue}
                                    setInvoiceType={setInvoiceType}
                                    invoiceType={invoiceType}
                                    setFilterSupplierValue={setFilterSupplierValue}
                                    filterSupplierValue={filterSupplierValue}
                                    setFilterStatusValue={setFilterStatusValue}
                                    filterStatusValue={filterStatusValue}
                                    setStatus={setStatus}
                                    statusid={statusid}
                                    setstatusOptionArray={setstatusOptionArray}
                                    statusOptionArray={statusOptionArray}
                                    exportData={exportData}
                                    setAllSelected={setAllSelected}
                                    isAllSelected={isAllSelected}
                                    userId={user}
                                    filterStatus={filterStatus}
                                />
                                {ReactDOM.createPortal(
                                    <InvoiceProcessFilterModal
                                        isOpened={isOpen}
                                        setfilterStatus={setFilterStatus}
                                        Onrefresh={Onrefresh}
                                        setOnrefresh={setOnrefresh}
                                        filterSubmit={filterSubmit}
                                        className={isOpen && "Show"}
                                    />,
                                    document.getElementById("user-modal-root")
                                )}
                                {ReactDOM.createPortal(
                                    <SendToApprovePopup
                                        isOpened={OpenApprove}
                                        progress={progress}
                                        StatusTrueCount={StatusTrueCount}
                                        StatusFalseCount={StatusFalseCount}
                                        index={index}
                                        setOnrefresh={setOnrefresh}
                                        FilterFailedInv={FilterFailedInv}
                                        showInvName={showInvName}
                                        pageCountValue={pageCountValue}
                                        SelectedLength={selectedInvoiceId?.length}
                                        selectedInvoiceId={selectedInvoiceId}
                                        InvId={selectedInvoiceId}
                                        handleClose={() => CartClose()}
                                        className={OpenApprove && "Show"}
                                    />,
                                    document.getElementById("user-modal-root")
                                )}
                            </>
                        ) : (
                            <div className="grid place-items-center text-center place-content-center h-[calc(100vh_-_37vh)]">
                                <img className="mb-[2rem]" src="/images/common/delivery.png" alt="delivery" />
                                <h5 className="font-interSb mb-1">No Organization Selected</h5>
                                <p className="text-xs">
                                    Go to Organization menu
                                    <br /> then add your Organization details
                                </p>
                            </div>
                        )}
                    </Main>
                    <Footer />
                </Layout>
            </React.Suspense>
        </>
    );
};

export default InvoiceListItem;
