import { styled } from "@mui/material/styles";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import { useSelector } from "react-redux";
import { useCallback, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useRef } from "react";
import { useEffect } from "react";
import Loader from "../../../../components/Loader";
import TogglePopup from "../../../organisations/Toggle";
import { add } from "lodash";
import ReactDOM from "react-dom";
import {
    POST_COMMENT_RESET,
    POST_COMPLETE_TASK_RESET,
    Add_INVOICE_TODO_CREATE_TASK_RESET,
    GET_VIEW_TASK_ATTACHMENT_RESET,
} from "../../../../redux/types/types";
import { getViewTaskAttachment } from "../../../../redux/actions/my-task.js";
import { getTaskView, postTaskComment, postTaskComplete, getInvoiceTaskList } from "../../../../redux/actions/my-task.js";
import Swal from "sweetalert2";
import Modal from "../../../../components/UI/Modal/Modal";
import Button from "../../../../components/Button";
import MessageBox from "../../../../components/UI/Alert";
import SuccessMessageBox from "../../../../components/UI/Alert/sucess";
import InvoiceTodoTask from "../AddTask/InvoiceTodoTask";
import InvoiceCreateTask from "../AddTask/InvoiceCreateTask";
import { decryptId, encryptId } from "../../../../utils/helpers";
import MailChipComponent from "../../../../components/MailAutoComplete/MailChipComponent";
import ReactQuill, { displayName } from "react-quill";
import { addToDoTask } from "../../../../redux/actions/my-task.js";
import { GetMicrosoftContactList } from "../../../../redux/actions/contacts.js";
import { getInboxSettingList } from "../../../../redux/actions/inbox-setting";
import { useLocation } from "react-router-dom";
import { IconButton, Chip } from "@mui/material";
import { list } from "suneditor/src/plugins";
import { ThemeContext } from "../../../../Hooks/Themes/Themeprovider";
import { CKEditor } from "ckeditor4-react";
import EmailContent from "../../../MyTask/MyTaskList/EmailContent";
import { fileGroup, fileSizeMB, getFilesSizeInMB } from "../../../../utils/helpers";
let uploadedFiles = [];
let fileStatusList = [];
let attachmentType = "";
let attachmentName = "";
export const InvoiceViewTask = (props) => {
    const modules = {
        toolbar: [
            ["bold", "italic", "underline", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
            ["link", "image"],
            ["clean"],
        ],
    };
    const getUserId = JSON.parse(localStorage.getItem("user"));
    const getRoleId = getUserId?.role_id;
    const dispatch = useDispatch();
    const childCompRef = useRef();
    const taskMenuList = [
        { text: "Email", src: "/images/main/Email-task.svg", value: 2 },
        { text: "To Do", src: "/images/main/To-do.svg", value: 1 },
    ];
    const location = useLocation();
    const getTaskConfig = useSelector((state) => state?.getTaskState);
    const taskList = getTaskConfig?.dataList;
    const { fetching, requestComplete, error, data } = useSelector((state) => state?.postTaskCommentState);
    const params = new URLSearchParams(window.location.search);
    const urlData = location?.search.slice(1)?.replace(/\n\s+/g, "\n")?.trim();

    const urlObj = JSON?.parse(decryptId(urlData));
    let invId = urlObj?.inv;
    const {
        fetching: fetchingPostTask,
        requestComplete: requestCompTask,
        error: errorTask,
        data: postTask,
    } = useSelector((state) => state?.postCompleteTaskState);
    const [seemore, setSeemore] = useState(false);
    const [upperBody, setUpperBody] = useState([]);
    const [lowerBody, setLowerBody] = useState([]);
    const [task, setTask] = useState(false);
    const [addTask, setAddTask] = useState(false);
    const [addTodoTask, setAddTodoTask] = useState(false);
    const [addComments, setAddComments] = useState("");
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [taskId, setTaskId] = useState("");
    const [emailInfo, setEmailInfo] = useState([]);
    const [commentsInfo, setCommentsInfo] = useState([]);
    const [progress, setProgress] = useState(0);
    const [commentId, setCommentId] = useState(0);
    const [completeError, setCompleteError] = useState(0);
    const [replyEnable, setReplyEnable] = useState(false);
    const [toEmail, setToEmail] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [emailType, setEmailType] = useState(0);
    const [ccEmail, setCcEmail] = useState([]);
    const [ccInputValue, setCcInputValue] = useState("");
    const [ccEnable, setCcEnable] = useState(false);
    const [bccEnable, setBccEnable] = useState(false);
    const [subject, setSubject] = useState("");
    const [content, setContent] = useState("");
    const [fileList, setFileStatusList] = useState([]);
    const [errorTo, setErrorTo] = useState(false);
    const [status, setStatus] = useState("");
    const [bccEmail, setBccEmail] = useState([]);
    const [bccInputValue, setBccInputValue] = useState("");
    const [torender, setRender] = useState(false);
    const [errorBcc, setErrorBcc] = useState(false);
    const editorRef = useRef();
    const headContent = `<head><style>table, th, td {border: 1px solid black;border-collapse: collapse;}table{width:100%}</style></head>`;

    const fileTypes = [
        "text/plain", // Plain text files
        "application/rtf", // Rich Text Format files
        "text/markdown", // Markdown files
        "application/msword", // Microsoft Word documents
        "application/vnd.ms-excel", // Microsoft Excel spreadsheets
        "application/vnd.ms-powerpoint", // Microsoft PowerPoint presentations
        "image/jpeg", // JPEG images
        "image/png", // PNG images
        "image/gif", // GIF images
        "image/bmp", // BMP images
        "application/pdf", // PDF files
        "audio/mpeg", // MP3 audio files
        "audio/wav", // WAV audio files
        "audio/aac", // AAC audio files
        "video/mp4", // MP4 videos
        "video/avi", // AVI videos
        "video/x-matroska", // MKV videos
        "application/zip", // Zip archives
        "image/svg+xml", //SVG images
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", //excel file
    ];

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            background: "#F1F0F0",
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            background: "linear-gradient(90deg, #028C78 0.93%, #FD8834 100%)",
        },
    }));
    const {
        fetching: fetchingReply,
        requestComplete: requestReply,
        error: errorReply,
        data: replyData,
    } = useSelector((state) => state?.addMyTaskCreateToDoState);
    const succesMsg = "Updated successfully";
    const errormsg = "Something went wrong please contact admin";
    let totalSubCompleted = null;
    const replyReset = useCallback(() => {
        setReplyEnable(false);
        setToEmail([]);
        setBccEmail([]);
        setInputValue("");
        setCcInputValue("");
        setBccInputValue("");
        setSubject("");
        setContent("");
        setCcEnable(false);
        setBccEnable(false);
        uploadedFiles = [];
        setSelectedFiles([]);
    }, [replyEnable]);
    const postComments = (e) => {
        const parseObject = {
            taskid: taskId,
            id: commentId,
            comments: addComments,
        };
        dispatch(postTaskComment(parseObject));
    };
    const Listdata = useSelector((state) => state?.GetMicrosoftContactListReduState);
    const commentRegex = /^\s*[á-źÁ-Źa-zA-Z0-9!~@#$%^&*(){}":,.<>";?][!~@#$%^&*(){}":,.<>";?0-9á-źÁ-Źa-zA-Z '-]*$/;
    const validateaddComments = commentRegex.test(addComments);
    const msContactListData = Listdata?.Listdata?.data?.value?.map((el) => el?.emailAddresses);
    const filterArr = msContactListData?.map((el) => [...el]).flat();
    const overallOptions = filterArr?.map((el) => el?.address);
    let listData = useSelector((state) => state?.inboxSettingListState);
    const {
        fetching: fetchingAttachment,
        requestComplete: attachmentRequest,
        error: attachmentError,
        dataList: attachmentByte,
    } = useSelector((state) => state?.getViewTaskAttachmentState);
    if (attachmentRequest) {
        if (attachmentByte?.status) {
            const linkSource = `data:${attachmentType};base64,${attachmentByte?.datalist}`;
            const downloadLink = document.createElement("a");
            const fileName = attachmentName;

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        }
        dispatch({ type: GET_VIEW_TASK_ATTACHMENT_RESET });
    }
    // For attachments
    const dynamicImage = (item) => {
        switch (item) {
            case "image/jpeg" || "image/png":
                return "/images/common/jpeg-img.svg";
            case "application/octet-stream":
                return "/images/common/jpeg-img.svg";
            case "application/pdf":
                return "/images/common/pdf.svg";
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                return "/images/common/xls.svg";
            case "text/csv":
                return "/images/common/csv.svg";
            default:
                return "/images/common/jpeg-img.svg";
        }
    };

    const handleFileClick = (url, name, type) => {
        attachmentName = name;
        attachmentType = type;
        dispatch(getViewTaskAttachment(url));
    };
    // format html
    function convertToHTMLFormat(text) {
        const textWithLineBreaks = text?.replace(/\r?\n/g, "<br>");

        const textWithoutExtraSpaces = textWithLineBreaks?.replace(/ +/g, " ");
        const textTrimmed = textWithoutExtraSpaces?.trim();
        return textTrimmed;
    }
    useEffect(() => {
        const getImage = getTaskConfig?.dataimg;
        setReplyEnable(false);
        if (taskList?.length > 0 && !getTaskConfig?.fetching) {
            if (taskList[0]?.mastertask) {
                setUpperBody(taskList[0]);
                setEmailInfo(taskList[0]?.task_email);

                const commentParse = taskList[0]?.comments_info?.map((item) => {
                    const findImage = getImage?.find((elem) => elem?.id == item?.user_id);
                    const finalComment = {
                        ...item,
                        data_image: findImage?.user_image,
                    };
                    return finalComment;
                });
                setCommentsInfo(commentParse);
                setSeemore(false);
                setTaskId(taskList[0]?.task_id);
                setLowerBody(taskList[0]?.subtask);
                setStatus(
                    taskList[0]?.task_status == 1
                        ? "Inprogress"
                        : taskList[0]?.task_status == 2
                            ? "Completed"
                            : taskList[0]?.task_status == 4
                                ? "Assign"
                                : "Done"
                );
                const temp = [];
                taskList[0]?.subtask?.map((item) => {
                    if (item?.task_status == 2) {
                        temp.push(item);
                    }
                });
                const fill = parseInt((temp?.length * 100) / taskList[0]?.subtask?.length);
                setProgress(fill);

                setTask(temp.length);
            } else if (taskList[0]?.mastertask == false) {
                setSeemore(false);
                setUpperBody(taskList[0]?.subtask[0]);
                setTaskId(taskList[0]?.subtask[0]?.task_id);
                // setEmailInfo(taskList[0]?.subtask[0]?.subtask_emails);
                setEmailInfo(taskList[0]?.task_email);
                const commentParse = taskList[0]?.subtask[0]?.subtask_comments?.map((item) => {
                    const findImage = getImage?.find((elem) => elem?.id == item?.user_id);
                    const finalComment = {
                        ...item,
                        user_image: findImage?.user_image,
                    };
                    return finalComment;
                });
                setStatus(
                    taskList[0]?.subtask[0]?.task_status == 1
                        ? "Inprogress"
                        : taskList[0]?.subtask[0]?.task_status == 2
                            ? "Completed"
                            : taskList[0]?.subtask[0]?.task_status == 4
                                ? "Assign"
                                : "Done"
                );
                setCommentsInfo(commentParse);
                setLowerBody(taskList);
                setTask(taskList[0]?.taskStatus == 2 ? 1 : 0);
                setProgress(taskList[0]?.taskStatus == 2 ? 100 : 0);
            }
        }
        setAddComments("");
        setCommentId(0);
        setCheckStatus(false);
    }, [getTaskConfig]);
    const sendReply = () => {
        if (inputValue.length != 0) {
            if (regex.test(inputValue)) {
                setToEmail(toEmail.concat(inputValue));
                setInputValue("");
            } else {
                setErrorTo(true);
                return false;
            }
        }
        if (ccInputValue?.length != 0) {
            if (regex.test(ccInputValue)) {
                setCcEmail(ccEmail.concat(ccInputValue));
                setCcInputValue("");
            } else {
                return false;
            }
        }
        if (bccInputValue?.length != 0) {
            if (regex.test(bccInputValue)) {
                setBccEmail(bccEmail.concat(bccInputValue));
                setBccInputValue("");
            } else {
                setErrorBcc(true);
                return false;
            }
        }

        // const getCc = ccEmail?.length > 0 ? encryptId(ccEmail.join(";")) : null;
        // const getTo = toEmail?.length > 0 ? encryptId(toEmail.join(";")) : null;
        const getCc =
            ccEmail?.length > 0 || ccInputValue?.length > 0
                ? encryptId(
                    ccInputValue?.length > 0 ? (ccEmail?.length > 0 ? ccEmail.concat(ccInputValue).join(";") : ccInputValue) : ccEmail.join(";")
                )
                : null;

        const getTo =
            toEmail?.length > 0 || inputValue?.length > 0
                ? encryptId(inputValue?.length > 0 ? (toEmail?.length > 0 ? toEmail.concat(inputValue).join(";") : ccInputValue) : toEmail.join(";"))
                : null;
        const getBcc =
            bccEmail?.length > 0 || bccInputValue?.length > 0
                ? encryptId(
                    bccInputValue?.length > 0
                        ? bccEmail?.length > 0
                            ? bccEmail.concat(bccInputValue).join(";")
                            : bccInputValue
                        : bccEmail.join(";")
                )
                : null;
        const reqObj = {
            json: [
                {
                    task_type: 2,
                    email_tempid: null,
                    task_subject: subject,
                    task_body: content,
                    task_to: getTo,
                    task_cc: getCc,
                    task_bcc: getBcc,
                    task_priority: null,
                    due_date: null,
                    parent_id: null,
                    sender: getUserId?.id,
                    receiver: getTo,
                    task_id: +upperBody?.task_id,
                    email_type: emailType,
                    message_id: emailInfo?.[0]?.message_id,
                },
            ],
            attachment: uploadedFiles,
            invid: +upperBody?.invoice_id,
        };
        dispatch(addToDoTask(reqObj));
    };
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const formats = [
        // "header",
        "bold",
        "italic",
        "underline",
        // "strike",
        "blockquote",
        "list",
        "bullet",
        // "indent",
        "link",
        "image",
    ];
    useEffect(() => {
        uploadedFiles = [];
        if (props?.idParse != "" && props?.isOpened == true) {
            dispatch(getTaskView(props?.idParse));
        }
        if (listData == undefined && props?.isOpened) {
            dispatch(getInboxSettingList());
        }
    }, [props?.isOpened]);
    const onFilterItems = (value) => {
        if (value == 1) {
            setAddTodoTask(true);
        }
        if (value == 2) {
            setAddTask(true);
        }
    };
    const upDateTo = useCallback(
        (input, email, id) => {
            if (id == "to") {
                setInputValue(input);
                setToEmail(email);
            } else if (id == "cc") {
                setCcEmail(email);
                setCcInputValue(input);
            } else if ((id = "bcc")) {
                setBccEmail(email);
                setBccInputValue(input);
            }
        },
        [toEmail]
    );
    const updateInput = useCallback(
        (input, id) => {
            if (id == "to") {
                setInputValue(input);
            } else if (id == "cc") {
                setCcInputValue(input);
            } else if (id == "bcc") {
                setBccInputValue(input);
            }
        },
        [inputValue]
    );

    // Handle File Attachments by convert them to base 64

    const handleFileChange = async (event) => {
        const files = event.target.files;
        const selectedFilesArray = Array.from(files);
        const fileOverallSize = selectedFiles?.length + files?.length;

        const overallSizeInMb = (selectedFiles?.length == 0 ? 0 : getFilesSizeInMB(selectedFiles)) + getFilesSizeInMB(selectedFilesArray);
        if (overallSizeInMb > fileGroup) {
            Swal.fire({
                text: `Only ${fileGroup} MB of attachments are allowed overall.`,
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            }).then((confirm) => {
                return false;
            });
            return false;
        }
        // setSelectedFiles((previousItem) => [
        //     ...selectedFilesArray,
        //     ...previousItem,
        // ]);
        let correctArray = [];
        let overSizeArray = [];
        if (files) {
            for (let i = 1; i <= files.length; i++) {
                const fileExt = fileTypes.find((item) => item == files[i - 1].type);
                if (files[i - 1]) {
                    const fileSize = (files[i - 1].size / (1024 * 1024)).toFixed(2); //mb conversion
                    if (fileSize > fileSizeMB) {
                        fileStatusList.push({
                            filename: files[i - 1].name,
                            error: "File size should be less than 10MB",
                            status: false,
                        });
                        overSizeArray?.push(files[i - 1].name);
                    } else if (!fileExt) {
                        fileStatusList.push({
                            filename: files[i - 1].name,
                            error: "File type is not supported",
                            status: false,
                        });
                    } else {
                        fileStatusList.push({
                            filename: files[i - 1].name,
                            error: "",
                            status: true,
                        });
                        await uploadWithJSON(files[i - 1], files[i - 1].name);
                        correctArray?.push(files[i - 1].name);
                    }
                }
            }
            const nonOverSizeFiles = selectedFilesArray?.filter((file) => {
                return correctArray.includes(file?.name);
            });
            setSelectedFiles((previousItem) => {
                const combinedArray = [...nonOverSizeFiles, ...previousItem];
                const uniqueSet = new Set(combinedArray);
                const uniqueArray = [...uniqueSet];
                return uniqueArray;
            });
            if (overSizeArray?.length > 0) {
                Swal.fire({
                    text: `Attachments: ${overSizeArray?.join(",")} exceed 1 MB in size!`,
                    icon: "warning",
                    showCloseButton: true,
                    closeOnClickOutside: false,
                    buttons: {
                        confirm: true,
                    },
                }).then((confirm) => {
                    overSizeArray = [];
                });
            }
            setFileStatusList([...fileStatusList]);
            event.target.value = "";
        }
    };
    async function uploadWithJSON(file, filename) {
        const reader = new FileReader();
        reader.onload = await _handleReaderLoaded(filename).bind(this);
        reader.readAsBinaryString(file);
    }

    const _handleReaderLoaded = (filename) => async (e) => {
        let binaryString = e.target.result;
        let imgObject = {
            filecontent: btoa(binaryString),
            filename: filename,
        };
        setRender(!torender);
        uploadedFiles.push(imgObject);
    };
    // Remove Files
    const handleRemoveFile = (file) => {
        const updatedFiles = selectedFiles.filter((f) => f?.name !== file?.name);
        uploadedFiles = uploadedFiles.filter((f) => f?.filename !== file?.name);
        setSelectedFiles(updatedFiles);
    };

    const addComment = (e) => {
        setAddComments(e.target.value);
    };
    if (requestComplete || (requestComplete && !data?.status) || (error && !data?.status)) {
        setTimeout(function () {
            dispatch({ type: POST_COMMENT_RESET });
            // dispatch(getTaskView(taskId));
        }, 2000);
    }
    const [checkStatus, setCheckStatus] = useState(false);
    useEffect(() => {
        if (requestCompTask == true && postTask?.status == true) {
            if (props?.isOpened) {
                setTimeout(() => {
                    dispatch(getInvoiceTaskList(invId));
                    dispatch(getTaskView(taskId));
                }, 2000);
            }
            setCheckStatus(true);
        }
        if (requestComplete) {
            const getImage = data?.dataimg;
            const commentParse = data?.datalist?.map((item) => {
                const findImage = getImage?.find((elem) => elem?.id == item?.user_id);
                const finalComment = {
                    ...item,
                    data_image: findImage?.user_image,
                };
                return finalComment;
            });
            setCommentsInfo(commentParse);
            setAddComments("");
            setCommentId(0);
        }
        if (requestReply && replyData?.status && props?.isOpened) {
            replyReset();
            setTimeout(() => {
                dispatch(getTaskView(taskId));
                dispatch({ type: POST_COMPLETE_TASK_RESET });
            }, 2100);
        }
    }, [postTask?.status, requestComplete, requestReply]);

    if (requestCompTask || (requestCompTask && !postTask?.status) || (errorTask && !postTask?.status)) {
        setTimeout(function () {
            dispatch({ type: POST_COMPLETE_TASK_RESET });
        }, 2000);
    }

    const handleCompleteTask = () => {
        if (
            !upperBody?.hasOwnProperty("mastertask") &&
            (upperBody?.task_type == 2 ? upperBody?.task_status == 1 : upperBody?.task_status == 3) &&
            (getRoleId == 2 || getUserId?.id == upperBody?.created_by)
        ) {
            const reqObj = {
                id: upperBody?.task_id,
                status: 2,
            };
            dispatch(postTaskComplete(reqObj));
        } else if (
            upperBody?.mastertask &&
            (upperBody?.task_type == 2 ? upperBody?.task_status == 1 : upperBody?.task_status == 3) &&
            (getRoleId == 2 || getUserId?.id == upperBody?.created_by)
        ) {
            let allow = [];
            lowerBody?.map((item) => {
                if (item?.task_status == 2) {
                    allow.push(item);
                }
            });
            if (allow?.length == lowerBody?.length || lowerBody == null) {
                const reqObj = {
                    id: upperBody?.task_id,
                    status: 2,
                };
                dispatch(postTaskComplete(reqObj));
            } else {
                setCompleteError(1);
            }
        } else if (upperBody?.task_type == 2 ? upperBody?.task_status != 1 : upperBody?.task_status != 3) {
            setCompleteError(2);
        } else if (getUserId?.id != upperBody?.created_by) {
            setCompleteError(3);
        } else {
            setCompleteError(2);
        }
    };
    const handleCompleteLowerTask = (type, status, id, created_by, event) => {
        event.stopPropagation();
        if ((type == 1 ? status == 3 : status == 1) && (getRoleId == 2 || getUserId?.id == created_by)) {
            const reqObj = {
                id: id,
                status: 2,
            };
            dispatch(postTaskComplete(reqObj));

            dispatch(getTaskView(upperBody?.task_id));
        }
        if (type == 1 ? status != 3 && status != 2 : false) {
            setCompleteError(2);
        }
    };
    const replyClick = () => {
        setCcEmail([]);
        setBccEmail([]);
        // setToEmail(emailInfo?.length > 0 ? [decryptId(emailInfo[0]?.sender)] : []);
        setToEmail(emailInfo?.length > 0 && !upperBody?.exception_category ? [decryptId(emailInfo[0]?.sender)] : emailInfo?.length > 0 && upperBody?.exception_category ? [(emailInfo[0]?.sender)] : []);
        setInputValue("");
        setCcInputValue("");
        setBccInputValue("");
        setSubject(emailInfo?.length > 0 ? emailInfo[0]?.email_subject : "");
        setCcEnable(false);
        setBccEnable(false);
        setReplyEnable(true);
        setContent("");
        setEmailType(2);
        if (overallOptions == undefined) {
            dispatch(GetMicrosoftContactList());
        }
    };
    const replyAllClick = () => {
        const getCc = emailInfo[0]?.email_cc == null ? "" : decryptId(emailInfo[0]?.email_cc).split(";");
        const getBcc = emailInfo[0]?.email_bcc == null ? "" : decryptId(emailInfo[0]?.email_bcc).split(";");
        setCcEmail(getCc == "" ? [] : getCc);
        setBccEmail(getBcc == "" ? [] : getBcc);
        // setToEmail(emailInfo?.length > 0 ? [decryptId(emailInfo[0]?.sender)] : []);
        setToEmail(emailInfo?.length > 0 && !upperBody?.exception_category ? [decryptId(emailInfo[0]?.sender)] : emailInfo?.length > 0 && upperBody?.exception_category ? [(emailInfo[0]?.sender)] : []);
        setInputValue("");
        setCcInputValue("");
        setBccInputValue("");
        setSubject(emailInfo?.length > 0 ? emailInfo[0]?.email_subject : "");
        setCcEnable(false);
        setBccEnable(false);
        setContent("");
        setReplyEnable(true);
        setEmailType(3);
        if (overallOptions == undefined) {
            dispatch(GetMicrosoftContactList());
        }
    };

    const forwardClick = () => {
        setCcEmail([]);
        setToEmail([]);
        setBccEmail([]);
        setInputValue("");
        setCcInputValue("");
        setBccInputValue("");
        setSubject(emailInfo?.length > 0 ? emailInfo[0]?.email_subject : "");
        setCcEnable(false);
        setBccEnable(false);
        setContent(emailInfo?.length > 0 ? emailInfo[0]?.email_message : "");
        setReplyEnable(true);
        setEmailType(4);
        if (overallOptions == undefined) {
            dispatch(GetMicrosoftContactList());
        }
    };

    const handleDiscardReply = () => {
        Swal.fire({
            text: `Are you sure you want to discard this draft?`,
            icon: "warning",
            closeOnClickOutside: false,
            showCloseButton: true,
            showCancelButton: true,
            buttons: {
                cancel: "Cancel",
                confirm: "Confirm",
            },
        }).then((confirm) => {
            if (confirm?.isConfirmed) {
                setReplyEnable(false);
                setToEmail([]);
                setInputValue("");
                setCcInputValue("");
                setSubject("");
                setContent("");
                setCcEnable(false);
                setSelectedFiles([]);
            }
        });
    };
    const handleStatus = (getStatus, getValue) => {
        if (getStatus == 3) {
            let allow = [];
            lowerBody?.map((item) => {
                if (item?.task_status == 2) {
                    allow.push(item);
                }
            });
            if (allow?.length == lowerBody?.length || lowerBody == null || !upperBody?.hasOwnProperty("mastertask")) {
                const reqObj = {
                    id: upperBody?.task_id,
                    status: getStatus,
                };
                dispatch(postTaskComplete(reqObj));
                setStatus(getValue);
            } else {
                setCompleteError(1);
            }
        } else {
            setStatus(getValue);
            const reqObj = {
                id: upperBody?.task_id,
                status: getStatus,
            };
            dispatch(postTaskComplete(reqObj));
        }
    };
    if (completeError != 0) {
        setTimeout(function () {
            setCompleteError(0);
        }, 2500);
    }

    //CkEditor funtions
    const config = {
        removeButtons: "Image",
    };
    const handleEditorReady = (editor) => {
        editorRef.current = editor;
    };
    const handleEditorChange = (event) => {
        const data = event.editor.getData();
        setContent(data);
    };
    const { currentMode } = useContext(ThemeContext);
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    return (
        <div>
            <Modal cartClose={props?.handleClose} highOverlay={true} className={`${props?.className}`}>
                <span
                    onClick={() => {
                        props?.handleClose();
                        setReplyEnable(false);
                        setToEmail([]);
                        setInputValue("");
                        setCcInputValue("");
                        setSubject("");
                        setContent("");
                        setCcEnable(false);
                    }}
                    className="p-[6.5px] cursor-pointer absolute right-[775px] bg-light-10"
                    title="Close">
                    <img src="/images/common/whiteClose.svg" title="Close" alt="whiteClose" />
                </span>
                {requestCompTask && postTask?.status && <SuccessMessageBox success={succesMsg} />}
                {requestReply && replyData?.status && <SuccessMessageBox success="Reply Sent" />}
                {requestReply && replyData?.status && emailType == 4 && <SuccessMessageBox success="Mail Sent" />}
                {((requestReply && !replyData?.status) || (errorReply && !replyData?.status)) && (
                    <MessageBox error="Reply not sent to the recipient, contact your admin." />
                )}
                {requestCompTask && !postTask?.status && <MessageBox error={postTask?.message} />}
                {errorTask && !postTask?.status && <MessageBox error={errormsg} />}
                {completeError != 0 && (
                    <MessageBox
                        error={
                            completeError == 1
                                ? "Complete all related subtasks to update the status of the primary task."
                                : completeError == 3
                                    ? "You are not authorized to complete this task."
                                    : "Update the task status to Done."
                        }
                    />
                )}
                <div
                    className={`w-[712px] bg-${currentMode}-card ml-auto  xl:w-[775px] relative overflow-y-auto transition duration-150 ease-out flex-col flex h-screen max-h-[-webkit-fill-available]`}>
                    <div className={`bg-${currentMode}-580 flex justify-between items-center p-[30px]`}>
                        <div>
                            <Button
                                onClick={handleCompleteTask}
                                //disabled={upperBody?.task_status == 2 || checkStatus}
                                className={`flex items-center font-interM ${upperBody?.task_status == 2 ? "pointer-events-none" : ""}`}>
                                {upperBody?.task_status == 2 ? (
                                    <img src="/images/main/task-complete.svg" className="mr-2" alt="circle" />
                                ) : (
                                    <img src="/images/main/circle.svg" className="mr-2 convertWhite" alt="circle" />
                                )}{" "}
                                Click to complete this task
                            </Button>
                        </div>
                        <div className="flex space-x-3">
                            {status != "Completed" &&
                                (getRoleId == 2 || getUserId?.id == upperBody?.created_by || upperBody?.assignee_id == getUserId?.id) &&
                                upperBody?.task_type == 1 && (
                                    <FormControl
                                        sx={{
                                            minWidth: 127,
                                        }}>
                                        <Select
                                            name="filter"
                                            fullWidth
                                            className="Org"
                                            onChange={(e) => {
                                                // setStatus(e.target.value);
                                                handleStatus(e.target.value == "Inprogress" ? 1 : e.target.value == "Done" ? 3 : 4, e.target.value);
                                            }}
                                            value={status}
                                            MenuProps={{
                                                sx: {
                                                    "& .MuiMenu-paper": {
                                                        backgroundColor: dropdownColor,
                                                        color: dropdownText,
                                                        boxShadow: `${currentMode === "dark" ? "" : "0px 4px 20px rgba(238, 238, 238, 0.5)"}`,
                                                        border: `${currentMode === "dark" ? "" : " 1px solid rgba(223, 225, 226, 0.56)"}`,
                                                        borderRadius: "8px",
                                                    },
                                                    "& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                    },
                                                    "& .Mui-selected": {
                                                        backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                    },
                                                },
                                            }}
                                            IconComponent={() => (
                                                <img
                                                    className="absolute opacity-50 right-[15px] pointer-events-none"
                                                    src={
                                                        currentMode !== "dark"
                                                            ? `/images/header/chevronDown.svg`
                                                            : `/images/header/chevronDown-white.svg`
                                                    }
                                                />
                                            )}
                                            SelectDisplayProps={{
                                                style: {
                                                    paddingTop: 1,
                                                    display: "flex",
                                                    paddingBottom: 1,
                                                    paddingLeft: "5px",
                                                    backgroundColor: dropdownColor,
                                                    border: `1px solid rgba(85,85,85,0.4)`,
                                                    borderRadius: "8px",
                                                    paddingRight: "30px",
                                                    color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                },
                                            }}
                                            displayEmpty>
                                            <MenuItem value="Assign">Assign</MenuItem>
                                            <MenuItem value="Inprogress">In Progress</MenuItem>
                                            <MenuItem value="Done">Done</MenuItem>
                                        </Select>
                                    </FormControl>
                                )}
                            <span
                                className={`mr-6 font-interM text-[12px] border-[1px] px-[15px] py-[8px] bg-${currentMode}-card rounded-[8px] border-${currentMode}-840 `}>
                                {upperBody?.task_no}{" "}
                            </span>
                            {/* <img
                className="cursor-pointer"
                src="/images/main/paperclip-lg.svg"
                alt="circle"
              /> */}
                            {/* <img
              className="cursor-pointer"
              src="/images/main/vertical-hamburger.svg"
              alt="circle"
            /> */}
                        </div>
                    </div>
                    {getTaskConfig?.fetching && upperBody != [] ? (
                        <Loader className="h-[calc(100vh_-_17rem)] w-[775px] " />
                    ) : (
                        <>
                            <div className={`flex justify-between items-center px-[30px] py-[14px] border-[1px] border-${currentMode}-700`}>
                                <span className="font-interSb">
                                    Invoice Number: {upperBody?.invoice_no} - {upperBody?.task_name}
                                </span>
                                {upperBody?.task_type == 2 && upperBody?.task_status == 1 && !replyEnable && (
                                    <div className="flex space-x-4 mb-1">
                                        <img
                                            className="cursor-pointer convertWhite"
                                            src="/images/inbox/reply-icon.svg"
                                            onClick={() => replyClick()}
                                            alt="reply-icon"
                                        />
                                        <img
                                            className="cursor-pointer convertWhite"
                                            src="/images/inbox/replyall-icon.svg"
                                            onClick={() => replyAllClick()}
                                            alt="reply-icon"
                                        />
                                        <img
                                            className="cursor-pointer convertWhite"
                                            src="/images/inbox/forward-icon.svg"
                                            onClick={() => forwardClick()}
                                            alt="reply-icon"
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="grid px-[30px] py-4 grid-cols-4 gap-4">
                                <div>
                                    <span className="font-interM">{upperBody?.task_type == 1 ? "Assigned To" : "Assigned By"}</span>
                                    <p className="truncate">
                                        {upperBody?.assignee_id == null ? decryptId(upperBody?.last_communication_from) : upperBody?.assignee_name}
                                    </p>
                                </div>
                                <div>
                                    <span className="font-interM flex">
                                        <img src="/images/invoice/calendar.svg" className="mr-2 convertWhite" alt="calendar" /> Due Date
                                    </span>
                                    <p className="ml-1">{upperBody?.due_date?.split(" ")?.[0]}</p>
                                </div>
                                <div>
                                    <span className="block font-interM">Priority</span>
                                    <Button
                                        className={`border-[0] font-interM rounded-[2px] text-[12px] px-2 text-white ${upperBody?.task_priority === "High"
                                            ? "bg-[rgba(255,114,94,1)]"
                                            : upperBody?.task_priority === "Low"
                                                ? "bg-[rgba(0,177,162,1)]"
                                                : upperBody?.task_priority === "Medium"
                                                    ? "bg-[rgba(251,189,3,1)]"
                                                    : upperBody?.task_priority === "Urgent"
                                                        ? "bg-[rgba(215,13,13,1)]"
                                                        : ""
                                            }`}>
                                        {upperBody?.task_priority}
                                    </Button>
                                </div>
                                <div>
                                    <span className="font-interM">Status</span>
                                    {/* <p>
                    {upperBody?.task_status == 1 || !checkStatus
                      ? "In Progress"
                      : "Completed"}
                  </p> */}
                                    <p>
                                        {upperBody?.task_status == 1
                                            ? "In Progress"
                                            : upperBody?.task_status == 2
                                                ? "Completed"
                                                : upperBody?.task_status == 3
                                                    ? "Done"
                                                    : "Assign"}
                                    </p>
                                </div>
                            </div>
                            <div className={`px-[30px] pt-[1rem] border-t-[1px] border-${currentMode}-700`}>
                                {replyEnable && (
                                    <>
                                        <div
                                            className={`border-[1px] border-[rgba(223,225,226,0.56)] ${currentMode == "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                                                } rounded-[8px] mb-[25px]`}>
                                            <div className={`flex px-[10px] item-center border-b-[1px] border-${currentMode}-700`}>
                                                <span className={`basis-[5%] ${currentMode == "dark" ? "text-white" : `text-[rgba(85,85,85,0.7)]`}`}>
                                                    To:
                                                </span>
                                                <MailChipComponent
                                                    id="to"
                                                    updateTo={upDateTo}
                                                    updateInput={updateInput}
                                                    email={toEmail}
                                                    overallOptions={overallOptions}
                                                    inputValue={inputValue}
                                                />
                                                {ccEnable == false && (
                                                    <span onClick={() => setCcEnable(true)} className="basis-[30%]  text-end pr-4 cursor-pointer">
                                                        Cc
                                                    </span>
                                                )}
                                                {bccEnable == false && (
                                                    <span onClick={() => setBccEnable(true)} className="basis-[30%]  text-end pr-4 cursor-pointer">
                                                        Bcc
                                                    </span>
                                                )}
                                            </div>
                                            {ccEnable == true && (
                                                <>
                                                    <div className={`flex px-[10px] item-center border-b-[1px]  border-${currentMode}-700`}>
                                                        <span
                                                            className={`basis-[5%] ${currentMode == "dark" ? "text-white" : `text-[rgba(85,85,85,0.7)]`
                                                                }`}>
                                                            Cc:
                                                        </span>
                                                        <MailChipComponent
                                                            id="cc"
                                                            updateTo={upDateTo}
                                                            updateInput={updateInput}
                                                            email={ccEmail}
                                                            overallOptions={overallOptions}
                                                            inputValue={ccInputValue}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                            {bccEnable == true && (
                                                <>
                                                    <div className={`flex px-[10px] item-center border-b-[1px] border-${currentMode}-700`}>
                                                        <span
                                                            className={`basis-[5%] ${currentMode == "dark" ? "text-white" : `text-[rgba(85,85,85,0.7)]`
                                                                }`}>
                                                            Bcc:
                                                        </span>
                                                        <MailChipComponent
                                                            id="bcc"
                                                            updateTo={upDateTo}
                                                            updateInput={updateInput}
                                                            email={bccEmail}
                                                            overallOptions={overallOptions}
                                                            inputValue={bccInputValue}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                            <div className={`flex p-[10px] border-b-[1px] border-${currentMode}-700`}>
                                                <span className={`basis-[5%] ${currentMode == "dark" ? "text-white" : `text-[rgba(85,85,85,0.7)]`}`}>
                                                    Subject:
                                                </span>
                                                <input
                                                    className="basis-[80%] truncate bg-transparent ml-5"
                                                    value={subject}
                                                    readOnly={true}
                                                    onChange={(event) => setSubject(event.target.value)}></input>
                                            </div>
                                            <div className="allocate-textarea reply relative">
                                                <span>
                                                    {selectedFiles?.map((file, index) => (
                                                        <Chip
                                                            key={index}
                                                            label={file.name}
                                                            onDelete={() => handleRemoveFile(file)}
                                                            style={{
                                                                margin: "0.5rem",
                                                                maxWidth: "170px",
                                                            }}
                                                        />
                                                    ))}
                                                </span>
                                                {/* <ReactQuill
                                                    theme="snow"
                                                    //   readOnly={!email_enabled}
                                                    style={{
                                                        borderRadius: "8px",
                                                    }}
                                                    value={content}
                                                    formats={formats}
                                                    modules={modules}
                                                    onChange={(e) =>
                                                        setContent(e)
                                                    }
                                                /> */}
                                                <div className="ckreply">
                                                    <CKEditor
                                                        data={content}
                                                        onChange={handleEditorChange}
                                                        initData={content}
                                                        onInstanceReady={handleEditorReady}
                                                        config={{
                                                            removeButtons: "Image,Paste,About,PasteText,PasteFromWord,Anchor",
                                                            removePlugins: "maximize",
                                                        }}
                                                    />
                                                </div>
                                                <div
                                                    className={`bg-${currentMode}-card relative z-[1] border-y-[1px] space-x-3 flex p-[25px] border-${currentMode}-870`}>
                                                    {/* <span
                                                        onClick={
                                                            handleDiscardReply
                                                        }
                                                        className="absolute cursor-pointer right-[6.5rem] top-[50%] translate-y-[-50%]"
                                                    >
                                                        <img
                                                            src="/images/common/trash-ico.svg"
                                                            alt="trash-ico"
                                                        />
                                                    </span> */}
                                                    <Button
                                                        onClick={handleDiscardReply}
                                                        //onClick={() => replyReset()}
                                                        className="cursor-pointer px-3 py-[2px] border-[1px] rounded-[5px] border-20 absolute cursor-pointer right-[6.5rem] top-[50%] translate-y-[-50%] ">
                                                        Discard
                                                    </Button>
                                                    <span className="absolute cursor-pointer right-[14.5rem] top-[50%] translate-y-[-50%]">
                                                        {/* <input
                                                            type="file"
                                                            multiple
                                                            onChange={
                                                                handleFileChange
                                                            }
                                                            id="file-input"
                                                            style={{
                                                                display: "none",
                                                            }}
                                                            accept={fileTypes.join(
                                                                ","
                                                            )}
                                                        />
                                                        <label htmlFor="file-input">
                                                            <IconButton component="span">
                                                                {/* <AttachFileIcon /> */}
                                                        {/* <img
                                                                    className="cursor-pointer"
                                                                    src="/images/main/paperclip-lg.svg"
                                                                    alt="circle"
                                                                />
                                                            </IconButton>
                                                        </label>  */}
                                                        <Button className="cursor-pointer" title="Attachment">
                                                            <input
                                                                type="file"
                                                                multiple
                                                                onChange={handleFileChange}
                                                                id="file-input"
                                                                style={{
                                                                    display: "none",
                                                                }}
                                                                accept={fileTypes.join(",")}
                                                            />
                                                            <label htmlFor="file-input" className="cursor-pointer">
                                                                {/* <IconButton component="span"> */}

                                                                {currentMode === "dark" ? (
                                                                    <img
                                                                        className={`mx-auto h-[30px]`}
                                                                        src="/images/common/attachment.svg"
                                                                        alt="circle"
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        className="mx-auto h-[30px] shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                                                                        src="/images/main/addfile-list.svg"
                                                                        alt="circle"
                                                                    />
                                                                )}

                                                                {/* </IconButton> */}
                                                            </label>
                                                        </Button>
                                                    </span>
                                                    <Button
                                                        disabled={fetchingReply || (toEmail?.length == 0 && inputValue?.length == 0)}
                                                        onClick={() => sendReply()}
                                                        className="bg-light-20 absolute right-[15px] rounded-[5px] px-5 py-[3px] text-white top-[50%] translate-y-[-50%]">
                                                        Send
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <ul className="text-xs mb-5">
                                                <li>From: {decryptId(emailInfo[0]?.sender)}</li>
                                                <li>Sent: {emailInfo[0]?.email_createdon}</li>
                                                <li>To: {"<" + toEmail?.join(";") + ">"}</li>
                                                <li>Subject: {emailInfo[0]?.email_subject}</li>
                                            </ul>
                                        </div>{" "}
                                    </>
                                )}
                                {upperBody?.task_type == 1 ? (
                                    <>
                                        <span className="font-interSb block mb-2">Description</span>
                                        <ul className="list-decimal pl-4 mb-10 space-y-4">
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: upperBody?.task_detail,
                                                }}
                                            />
                                        </ul>
                                    </>
                                ) : (
                                    <>
                                        <EmailContent item={emailInfo[0]} handleFileClick={handleFileClick} dynamicImage={dynamicImage} />
                                    </>
                                )}
                                {/* {upperBody?.task_type == 2 && emailInfo?.length > 0 && (
                  <>
                    <EmailContent item={emailInfo[0]} />
                  </>
                )} */}

                                <div>
                                    {seemore &&
                                        emailInfo?.length > 1 &&
                                        upperBody?.task_type == 2 &&
                                        emailInfo
                                            ?.slice(1)
                                            ?.map((item) => (
                                                <EmailContent item={item} handleFileClick={handleFileClick} dynamicImage={dynamicImage} />
                                            ))}
                                </div>
                            </div>
                            {emailInfo?.length > 1 && upperBody?.task_type == 2 && (
                                <div className="relative mt-7 mb-[3.5rem]">
                                    <hr className={`border-${currentMode}-700`} />
                                    <Button
                                        onClick={() => setSeemore(!seemore)}
                                        className="bg-light-20 text-xs absolute left-[50%] top-[50%] translate-y-[-50%] translate-x-[-50%] text-white rounded-[8px] py-2 px-4">
                                        {seemore ? "See Less" : "See More"}{" "}
                                        <img
                                            src={seemore ? "/images/header/chevron-up.svg" : "/images/main/chevron-down-white.svg"}
                                            alt="chevron-down-white"
                                            className="inline-block"
                                        />
                                    </Button>
                                </div>
                            )}
                            {lowerBody?.length > 0 && (
                                <>
                                    <div className={`bg-${currentMode}-850 border-y-[1px] border-${currentMode}-600 py-2 px-[30px]`}>
                                        <div className="flex justify-between">
                                            {upperBody.mastertask ? (
                                                <span className="flex font-interSb">
                                                    <img src="/images/main/subtask.svg" className="mr-2 convertWhite" alt="subtask" /> Subtasks
                                                </span>
                                            ) : (
                                                <span className="flex font-interSb"> Main Task</span>
                                            )}
                                        </div>
                                    </div>
                                    {upperBody.mastertask && (
                                        <div className="flex px-[30px] py-5 justify-between items-center">
                                            <span className="basis-[20%]">Progress</span>
                                            <div className="basis-[40%] flex items-center justify-end space-x-2 relative">
                                                <span>
                                                    {task}/{lowerBody?.length}
                                                </span>
                                                <Box sx={{ flexGrow: 1 }}>
                                                    <BorderLinearProgress
                                                        variant="determinate"
                                                        //  value={task == 0 ? 0 : (lowerBody?.length)*100/task}
                                                        value={progress}
                                                    />
                                                </Box>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                            {lowerBody?.length > 0 &&
                                lowerBody?.map((item) => (
                                    <>
                                        <div className={`space-y-2 last:border-b-0 ${upperBody?.mastertask ? "" : "mt-[20px]"}`}>
                                            <div
                                                className={`flex px-[30px] border-b-[1px] border-${currentMode}-700 pb-4 items-center justify-between  `}>
                                                <div className="flex-1">
                                                    <span
                                                        className="flex mb-2 cursor-pointer"
                                                        onClick={() => {
                                                            dispatch(getTaskView(item?.task_id));
                                                        }}>
                                                        <img
                                                            onClick={(event) =>
                                                                handleCompleteLowerTask(
                                                                    item?.task_type,
                                                                    item?.task_status,
                                                                    item?.task_id,
                                                                    item?.created_by,
                                                                    event
                                                                )
                                                            }
                                                            src={
                                                                item?.task_status == 2 ? "/images/main/task-complete.svg" : "/images/main/circle.svg"
                                                            }
                                                            className="mr-2"
                                                            alt="circle"
                                                        />
                                                        Invoice Number: {item?.invoice_no} - {item?.task_name}
                                                    </span>
                                                    <div className="flex space-x-4">
                                                        <Button
                                                            className={`border-[0] font-interM rounded-[2px] text-[12px] px-2 text-${currentMode}-copy ${item?.task_priority === "High"
                                                                ? "bg-[rgba(255,114,94,1)]"
                                                                : item?.task_priority === "Low"
                                                                    ? "bg-[rgba(0,177,162,1)]"
                                                                    : item?.task_priority === "Medium"
                                                                        ? "bg-[rgba(251,189,3,1)]"
                                                                        : item?.task_priority === "Urgent"
                                                                            ? "bg-[rgba(215,13,13,1)]"
                                                                            : ""
                                                                }`}>
                                                            {item?.task_priority}
                                                        </Button>
                                                        <span className="flex">
                                                            <img src="/images/invoice/calendar.svg" className="mr-2 convertWhite" alt="calendar" />
                                                            {item?.due_date?.split(" ")?.[0]}
                                                        </span>
                                                        <span
                                                            title={
                                                                item?.assignee_id == null
                                                                    ? decryptId(item?.last_communication_from)
                                                                    : item?.assignee_name
                                                            }
                                                            className={`border-[1px] ${currentMode == "dark" ? "bg-black" : "bg-[#EDFCFA]"
                                                                } rounded-[3px] max-w-[300px] truncate px-2 border-[#00B1A2]`}>
                                                            {item?.assignee_id == null
                                                                ? decryptId(item?.last_communication_from)
                                                                : item?.assignee_name}
                                                        </span>
                                                        <span className="ml-2">
                                                            {item?.task_status == 1
                                                                ? "In Progress"
                                                                : item?.task_status == 2
                                                                    ? "Completed"
                                                                    : item?.task_status == 3
                                                                        ? "Done"
                                                                        : "Assign"}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ))}
                            {upperBody?.mastertask &&
                                upperBody?.task_status != 2 &&
                                upperBody?.task_status != 3 &&
                                !checkStatus &&
                                props?.invStatus !== 5 && (
                                    <div className={`border-b-[1px] mt-2 flex space-x-2  px-[30px] py-2 border-${currentMode}-700 cursor-pointer`}>
                                        <Button
                                            className="flex items-center space-x-2 cursor-pointer"
                                            title={"to add new subtask"}
                                            onClick={(e) => childCompRef.current.handleClick("bottom-end", 1, e)}>
                                            <img src="/images/main/plus-blk.svg" alt="plus-blk" className="convertWhite" />
                                            <span>Add a new subtask...</span>
                                        </Button>
                                    </div>
                                )}
                            <TogglePopup portal="true" ref={childCompRef} menuList={taskMenuList} selectedItemChange={onFilterItems} />
                            {commentsInfo?.length > 0 && (
                                <>
                                    <div className={`bg-${currentMode}-850 border-y-[1px] border-${currentMode}-700 py-2 px-[30px] mt-10`}>
                                        <div className="flex justify-between">
                                            <span className="flex font-interSb">
                                                {currentMode === "dark" ? (
                                                    <img src="/images/common/noun-comment.svg" className="mr-2 convertWhite" alt="subtask" />
                                                ) : (
                                                    <img src="/images/main/comments.svg" className="mr-2 convertWhite" alt="subtask" />
                                                )}{" "}
                                                Comments
                                            </span>
                                        </div>
                                    </div>

                                    <div className={`flex bg-${currentMode}-card border-y-[1px] border-${currentMode}-700 py-2 px-[30px]`}>
                                        <div className="flex-row my-6  ">
                                            {commentsInfo.map((item) => (
                                                <>
                                                    <div className="flex mt-4">
                                                        <div className="flex-initial">
                                                            <img
                                                                className="rounded-full w-[40px] h-[40px]"
                                                                src={
                                                                    item?.data_image
                                                                        ? `data:image;base64,${item?.data_image}`
                                                                        : `/images/profile/user-ico.svg`
                                                                }
                                                                alt="user-new"
                                                            />
                                                        </div>
                                                        <div className="ml-4 flex-1">
                                                            <div className="mb-1 flex items-center">
                                                                <span title={item?.commented_by} className="font-interM max-w-[80px] truncate">
                                                                    {item?.commented_by}{" "}
                                                                </span>
                                                                <small
                                                                    className={`${item?.isedited == "true" ? " ml-[320px]" : " ml-[375px]"
                                                                        }  text-[12px]`}>
                                                                    {item?.commented_on}
                                                                </small>

                                                                {getUserId?.id == item?.user_id && (
                                                                    <span className="flex items-center">
                                                                        <img
                                                                            class="ml-2 cursor-pointer  w-[13px] h-[13px]"
                                                                            src={
                                                                                commentId == item?.comment_id
                                                                                    ? "/images/main/edit-green.svg"
                                                                                    : "/images/main/edit-black.svg"
                                                                            }
                                                                            title="To edit your comment details"
                                                                            alt="pencil-medium"
                                                                            onClick={() => {
                                                                                setAddComments(item?.comment);
                                                                                setCommentId(item?.comment_id);
                                                                            }}
                                                                        />
                                                                        <span className="ml-2 text-[10px]">
                                                                            {item?.isedited == "true" || item?.isedited == true ? "Edited" : ""}
                                                                        </span>
                                                                    </span>
                                                                )}
                                                            </div>
                                                            <p className={`text-[14px] max-w-[500px] break-words mb-1 text-${currentMode}-900`}>
                                                                {item?.comment}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            )}
                            <span className="font-interSb mt-4 ml-6 ">{commentId == 0 ? "Add a Comment " : "Edit a Comment"}</span>

                            <div
                                className={`flex mx-6 my-6 border-[1px] items-center space-3 rounded-[7px] justify-between border-${currentMode}-700`}>
                                {commentId == 0 ? (
                                    <div className="ml-2">
                                        <img src="/images/main/greenPlus.svg" className="mx-4  my-2 " alt="add-comments" />
                                    </div>
                                ) : (
                                    <div className="ml-2">
                                        <img src="/images/main/edit-green.svg" className="mx-4  my-2 " alt="add-comments" />
                                    </div>
                                )}
                                <div className={`flex items-center basis-[80%] border-l-[1px] border-${currentMode}-700 `}>
                                    <input
                                        placeholder="Post a comment..."
                                        className={`w-full ml-4 bg-transparent`}
                                        disabled={false}
                                        maxLength={250}
                                        value={addComments}
                                        onChange={addComment}
                                        onKeyDown={(e) => {
                                            if (e?.key == "Enter" && addComments.trim().length != 0) {
                                                postComments(e);
                                            }
                                        }}
                                    />
                                </div>
                                {commentId != 0 && (
                                    <img
                                        src="/images/common/closeBlk.svg"
                                        className="mx-4  my-2 cursor-pointer "
                                        alt="add-comments"
                                        onClick={() => {
                                            setCommentId(0);
                                            setAddComments("");
                                        }}
                                    />
                                )}
                                <Button
                                    className="border-[0] my-2 mx-2 rounded-[8px] flex items-center bg-[rgba(0,177,162,1)] cursor-pointer text-white"
                                    onClick={postComments}
                                    id={upperBody?.task_id}
                                    disabled={addComments.trim().length == 0 || !validateaddComments}>
                                    <img src="/images/main/add-comment.svg" className="my-2 mx-2 px-2" alt="post-comments" />
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </Modal>

            {ReactDOM.createPortal(
                <InvoiceCreateTask
                    isOpened={addTask}
                    overlaying={true}
                    handleClose={() => setAddTask(false)}
                    className={`${addTask && "Show"}`}
                    invoiceId={upperBody?.invoice_id}
                    parentId={upperBody?.task_id}
                />,
                document.getElementById("user-modal-root")
            )}
            {ReactDOM.createPortal(
                <InvoiceTodoTask
                    isOpened={addTodoTask}
                    handleClose={() => setAddTodoTask(false)}
                    orgId={localStorage.getItem("orgId")}
                    className={`${addTodoTask && "Show"}`}
                    invoiceId={upperBody?.invoice_id}
                    parentId={upperBody?.task_id}
                />,
                document.getElementById("user-modal-root")
            )}
        </div>
    );
};
