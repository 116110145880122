import {
	GET_LIST_OF_EXCEPTION_CATEGORY_FAILURE,
	GET_LIST_OF_EXCEPTION_CATEGORY_RESET,
	GET_LIST_OF_EXCEPTION_CATEGORY_SUCCESS,
	GET_LIST_OF_EXCEPTION_CATEGORY_REQUEST,
	DELETE_EXCEPTION_CATEGORY_REQUEST,
	DELETE_EXCEPTION_CATEGORY_SUCCESS,
	DELETE_EXCEPTION_CATEGORY_RESET,
	DELETE_EXCEPTION_CATEGORY_FAILURE,
	ADDUPDATE_EXCEPTION_CATEGORY_REQUEST,
	ADDUPDATE_EXCEPTION_CATEGORY_RESET,
	ADDUPDATE_EXCEPTION_CATEGORY_FAILURE,
	ADDUPDATE_EXCEPTION_CATEGORY_SUCCESS,
	CLONE_EXCEPTION_AUTOMATION_RESET,
	CLONE_EXCEPTION_AUTOMATION_SUCCESS,
	CLONE_EXCEPTION_AUTOMATION_FAILURE,
	EXCEPTION_AUTOMATION_VIEW_BYORG_REQUEST,
	EXCEPTION_AUTOMATION_VIEW_BYORG_SUCCESS,
	EXCEPTION_AUTOMATION_VIEW_BYORG_RESET,
	EXCEPTION_AUTOMATION_VIEW_BYORG_FAILURE,
	FROM_ORG_DROPDOWN_REQUEST,
	FROM_ORG_DROPDOWN_SUCCESS,
	FROM_ORG_DROPDOWN_RESET,
	FROM_ORG_DROPDOWN_FAILURE,
	EMAIL_GENERATE_EXCEPTION_REQUEST,
	EMAIL_GENERATE_EXCEPTION_SUCCESS,
	EMAIL_GENERATE_EXCEPTION_RESET,
	EMAIL_GENERATE_EXCEPTION_FAILURE,
	USER_EXCEPTION_DROPDOWN_REQUEST,
	USER_EXCEPTION_DROPDOWN_SUCCESS,
	USER_EXCEPTION_DROPDOWN_RESET,
	USER_EXCEPTION_DROPDOWN_FAILURE,
	DELETE_ESCALATION_REQUEST,
	DELETE_ESCALATION_SUCCESS,
	DELETE_ESCALATION_RESET,
	DELETE_ESCALATION_FALURE,
} from "../types/types";

//LIST EMAIL TEMPLATE
const listExceptionCategoryState = {
	data: [],
	fetching: false,
	requestComplete: false,
	error: "",
};

export function exceptionCategoryList(state = listExceptionCategoryState, action) {
	switch (action.type) {
		case GET_LIST_OF_EXCEPTION_CATEGORY_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case GET_LIST_OF_EXCEPTION_CATEGORY_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case GET_LIST_OF_EXCEPTION_CATEGORY_RESET:
			return {
				data: [],
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case GET_LIST_OF_EXCEPTION_CATEGORY_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}
const deleteExceptionCategoryState = {
	data: null,
	fetching: false,
	requestComplete: false,
	error: "",
};

export function deleteExceptionCategory(state = deleteExceptionCategoryState, action) {
	switch (action.type) {
		case DELETE_EXCEPTION_CATEGORY_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case DELETE_EXCEPTION_CATEGORY_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case DELETE_EXCEPTION_CATEGORY_RESET:
			return {
				data: null,
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case DELETE_EXCEPTION_CATEGORY_FAILURE:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: action.payload,
			};
		default:
			return state;
	}
}
const addUpdateExceptionCategoryState = {
	data: null,
	fetching: false,
	requestComplete: false,
	error: "",
};

export function postExceptionCategory(state = addUpdateExceptionCategoryState, action) {
	switch (action.type) {
		case ADDUPDATE_EXCEPTION_CATEGORY_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case ADDUPDATE_EXCEPTION_CATEGORY_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case ADDUPDATE_EXCEPTION_CATEGORY_RESET:
			return {
				data: null,
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case ADDUPDATE_EXCEPTION_CATEGORY_FAILURE:
			return {
				fetching: false,
				requestComplete: true,
				error: action.payload,
			};
		default:
			return state;
	}
}
const CloneExceptionState = {
	data: null,
	fetching: false,
	requestComplete: false,
	error: "",
};

export function CloneExceptionReducer(state = CloneExceptionState, action) {
	switch (action.type) {
		case CLONE_EXCEPTION_AUTOMATION_RESET:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case CLONE_EXCEPTION_AUTOMATION_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case CLONE_EXCEPTION_AUTOMATION_RESET:
			return {
				data: null,
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case CLONE_EXCEPTION_AUTOMATION_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}
//VIEW EXCEPRION BY ORG

const viewExceptionCategoryState = {
	data: {},
	fetching: false,
	requestComplete: false,
	error: "",
};

export function exceptionCategoryViewByOrg(state = viewExceptionCategoryState, action) {
	switch (action.type) {
		case EXCEPTION_AUTOMATION_VIEW_BYORG_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case EXCEPTION_AUTOMATION_VIEW_BYORG_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case EXCEPTION_AUTOMATION_VIEW_BYORG_RESET:
			return {
				data: {},
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case EXCEPTION_AUTOMATION_VIEW_BYORG_FAILURE:
			return {
				fetching: false,
				requestComplete: true,
				error: action.payload,
			};
		default:
			return state;
	}
}
const OrgDropdownState = {
	data: [],
	fetching: false,
	requestComplete: false,
	error: "",
};

export function ExceptionOrgDropdownReducer(state = OrgDropdownState, action) {
	switch (action.type) {
		case FROM_ORG_DROPDOWN_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case FROM_ORG_DROPDOWN_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case FROM_ORG_DROPDOWN_RESET:
			return {
				data: [],
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case FROM_ORG_DROPDOWN_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}
const EmailGenerateState = {
	data: [],
	fetching: false,
	requestComplete: false,
	error: "",
};

export function EmailGenerateExceptionReducer(state = EmailGenerateState, action) {
	switch (action.type) {
		case EMAIL_GENERATE_EXCEPTION_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case EMAIL_GENERATE_EXCEPTION_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case EMAIL_GENERATE_EXCEPTION_RESET:
			return {
				data: [],
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case EMAIL_GENERATE_EXCEPTION_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}
const UserDropdownState = {
	data: [],
	fetching: false,
	requestComplete: false,
	error: "",
};

export function ExceptionUserDropdownReducer(state = UserDropdownState, action) {
	switch (action.type) {
		case USER_EXCEPTION_DROPDOWN_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case USER_EXCEPTION_DROPDOWN_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case USER_EXCEPTION_DROPDOWN_RESET:
			return {
				data: [],
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case USER_EXCEPTION_DROPDOWN_FAILURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}
const DeletState = {
	data: [],
	fetching: false,
	requestComplete: false,
	error: "",
};

export function DeleteEscalationReducer(state = DeletState, action) {
	switch (action.type) {
		case DELETE_ESCALATION_REQUEST:
			return {
				...state,
				fetching: true,
				requestComplete: false,
				error: "",
			};
		case DELETE_ESCALATION_SUCCESS:
			return {
				data: action.payload,
				fetching: false,
				requestComplete: true,
				error: "",
			};
		case DELETE_ESCALATION_RESET:
			return {
				data: [],
				fetching: false,
				requestComplete: false,
				error: "",
			};
		case DELETE_ESCALATION_FALURE:
			return {
				fetching: false,
				requestComplete: false,
				error: action.payload,
			};
		default:
			return state;
	}
}
