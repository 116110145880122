import { useState, useContext, useEffect } from "react";
import Card from "../../components/UI/Card";
import Layout from "../../Hoc/Layout";
import Footer from "../../organisms/Footer";
import { ErrorBoundary } from "react-error-boundary";
import FournotFour from "../404";
import Main from "../../components/UI/Main";
import { FormControl, MenuItem, Select } from "@mui/material";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import Button from "../../components/Button";
import AddCurrency from "./AddCurrency";
import EditModal from "./EditModal";
import UploadModal from "./UploadModal";
import HistoryModal from "./HistoryModal";
import Swal from "sweetalert2";
import ReactDOM from "react-dom";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Popper from "@mui/material/Popper";
import moment from "moment";
import { getCurrentUser } from "../../redux/actions/auth";
import React from "react";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
    DeleteExchangeRateAction,
    ExchangeRateListAction,
    GetCurrencyListExchangeRate,
    GetCurrencyListExchangeRateByOrg,
    HistoryExchangeRateAction,
} from "../../redux/actions/exchange-rate";
import Loader from "../../components/Loader";
import { CurrencyList } from "../../utils/helpers";
import { DELETE_EXCHANGE_RATE_RESET } from "../../redux/types/types";

const ExchangeRate = () => {
    const { currentMode } = useContext(ThemeContext);
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState({
        add: false,
        edit: false,
        upload: false,
        history: false,
    });
    const [tableData, setTableData] = useState([]);
    const [editingIndex, setEditingIndex] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [popstate, setPopState] = useState({ id: null, anchorEl: false });
    const [placement, setPlacement] = useState();
    const [ValidFrom, setValidFrom] = useState("");
    const [exchangeRate, setExchangerate] = useState("");
    const userDateFormat = localStorage?.getItem("userDateFormat");
    const [editingCurrencyCode, setEditingCurrencyCode] = useState("");
    const getUser = getCurrentUser();
    const [jsonData, setJsonData] = useState(null);
    const [isSubmit, setIsSubmit] = useState(false);
    const [EditOpen, setEditOpen] = useState({
        ValidFrom: "",
        exchangeRate: "",
        ToCurrency: "",
        Id: "",
        fromCurrency: "",
    });

    // if (isSubmit) {
    //     const newData = jsonData?.map(item => ({
    //         code: item?.Currency,
    //         symbol: item?.Symbol,
    //         description: item?.Description,
    //         ValidFrom: item["Valid_From"],
    //         exchangeRate: item["Exchange_Rate"],
    //         Type: "Upload"
    //     }));
    //     tableData?.push(...newData)
    // }
    const FilterTable = [...new Set(tableData)];
    const uniqueCodes = [...new Set(FilterTable?.map((item) => item?.code))];
    const filteredData = uniqueCodes?.map((code) => {
        const filteredByCode = FilterTable?.filter((item) => item.code === code);
        const uploadType = filteredByCode?.find((item) => item.Type === "Upload");

        return uploadType || filteredByCode[0];
    });
    const ExchangeRateData = useSelector((state) => state?.ExchangeRateListState);
    const ListData = ExchangeRateData?.dataList;
    const CurrencyData = useSelector((state) => state?.currencydropdownByorgState);
    const currencies = CurrencyData?.data;
    const DeleteData = useSelector((state) => state?.deleteExchangeRateState);
    const [currency, setCurrency] = useState(0);
    const ErrorFallback = ({ error }) => {
        return (
            <>
                <FournotFour />
            </>
        );
    };
    useEffect(() => {
        // if (currency !== 0) {
        dispatch(ExchangeRateListAction(currency));

        dispatch(GetCurrencyListExchangeRateByOrg());
    }, [currency]);

    const handleModalOpen = (type, index = null, from, to, code, fromCurrency, Id) => {
        setModalOpen((prevState) => ({ ...prevState, [type]: true }));
        if (type == "add") {
            dispatch(GetCurrencyListExchangeRate());
        }
        if (type === "edit") {
            setEditingIndex(index);
            const currencyCode = ListData[index]?.currecny_code;
            setEditingCurrencyCode(currencyCode);
            // setValidFrom(from);
            // setExchangerate(to)
            setEditOpen({
                ValidFrom: from,
                exchangeRate: to,
                ToCurrency: code,
                fromCurrency: fromCurrency,
                Id: Id,
            });
        }
        if (type == "history") {
            dispatch(HistoryExchangeRateAction(from, to));
            setEditingCurrencyCode(code);
        }
    };

    const handleModalClose = (type) => {
        setModalOpen((prevState) => ({ ...prevState, [type]: false }));
    };

    const handleClick = (newPlacement, id) => (event) => {
        if (id === 2) {
            setAnchorEl(event.currentTarget);
            setPopState({ id, anchorEl: event.currentTarget });
            setPlacement(newPlacement);
        }
    };

    const handleClickAway = (e) => {
        setPopState({ id: null, anchorEl: false });
    };
    const currencyHandleChange = (value, name) => {
        setCurrency(value);
    };
    const FindCurrency = currencies?.filter((el) => el?.id == currency)?.[0]?.currency_code;
    const prepareDataForDownload = (data) => {
        return data?.map((row) => ({
            "From Currency": FindCurrency,
            "To Currency": row?.currecny_code,
            Symbol: row?.currency_symbol,
            Description: row?.currency_name,
            "Valid From": moment(row?.validfrom_date).format(userDateFormat?.toUpperCase()),
            "Exchange Rate": row?.exchange_rate,
            Display: `1 ${FindCurrency} = ${row?.exchange_rate} ${row?.currecny_code}`,
        }));
    };
    const convertToCSV = (dataArray) => {
        const header = Object.keys(dataArray[0]).join(",");
        const rows = dataArray.map((row) => Object.values(row).join(","));
        return [header, ...rows].join("\n");
    };
    const handleDownload = (type) => {
        const data = prepareDataForDownload(ListData);

        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "exchange_rate.xlsx");
        // } else if (type === "csv") {
        //     const csvData = convertToCSV(data);
        //     const blob = new Blob([csvData], { type: 'text/csv' });
        //     const url = window.URL.createObjectURL(blob);
        //     const link = document.createElement('a');
        //     link.href = url;
        //     link.setAttribute('download', 'exchange_rate.csv');
        //     document.body.appendChild(link);
        //     link.click();
        //     document.body.removeChild(link);
        // }
    };

    const handleAddCurrency = (newCurrency) => {
        setTableData([...tableData, newCurrency]);
    };

    const handleEditCurrency = (id, updatedCurrency) => {
        setTableData(
            tableData?.map((item, index) => {
                if (id === index) {
                    item.validFrom = updatedCurrency?.validFrom ? updatedCurrency?.validFrom : "";
                    item.exchangeRate = updatedCurrency?.exchangeRate || "";
                }
                return item;
            })
        );
    };

    const handleDelete = (id) => {
        const updatedTableData = tableData.filter((row, index) => index !== id);
        setTableData(updatedTableData);
    };

    const handleDeleteClick = (id) => {
        Swal.fire({
            text: "Are you sure you want to delete these currency details?",
            icon: "warning",
            showCloseButton: true,
            showCancelButton: true,
            buttons: {
                cancel: "Cancel",
                confirm: "Confirm",
            },
        }).then((confirm) => {
            if (confirm?.isConfirmed) {
                const reqbody = {
                    exchangerateid: +id,
                };
                dispatch(DeleteExchangeRateAction(reqbody));
            }
        });
    };

    const handleSuccess = () => {
        Swal.fire({
            text: "Deleted successfully!",
            icon: "success",
            closeOnClickOutside: false,
            showCloseButton: true,
            button: "Ok",
        }).then((confirm) => {
            if (confirm) {
                dispatch(ExchangeRateListAction(currency));
                dispatch({ type: DELETE_EXCHANGE_RATE_RESET });
            }
        });
    };
    const HandleError = () => {
        Swal.fire({
            text: "Something went wrong. Please contact the admin.",
            closeOnClickOutside: false,
            showCloseButton: true,
            icon: "error",
            button: "Ok",
        });
    };
    if (DeleteData?.requestComplete) {
        handleSuccess();
    } else if (DeleteData?.error) {
        HandleError();
        dispatch({ type: DELETE_EXCHANGE_RATE_RESET });
    }
    const userDate = getUser?.user_date;
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const dropdownShadow = currentMode !== "dark" ? "0px 4px 20px rgba(238, 238, 238, 0.5)" : "";
    const dropdownBorder = currentMode !== "dark" ? "223, 225, 226, 0.56" : "";

    return (
        <>
            <Layout>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Main>
                        <div className="mb-2 flex justify-between items-center ">
                            <h4 className={`text-2xl font-interSb text-${currentMode}-copy`}>Exchange Rate</h4>
                            {currency !== 0 && (
                                <div className="flex space-x-2 mb-2">
                                    <Button
                                        title="Add Currency"
                                        type="Button"
                                        onClick={() => handleModalOpen("add")}
                                        className="border-transparent py-5 approve-step border-440 w-[150px] h-[40px] bg-light-20 rounded-[8px] font-interM text-base font-semibold flex justify-center items-center border-[1px] text-white"
                                    >
                                        <span className="">+ Add Currency</span>
                                    </Button>
                                    <Button
                                        title="Upload"
                                        type="submit"
                                        onClick={() => handleModalOpen("upload")}
                                        className=" border-transparent approve-step border-440 !font-interR w-[103px] h-[40px] p-5 bg-gray-200 rounded-[8px] text-base flex justify-center items-center border-[1px] text-black"
                                    >
                                        Upload
                                    </Button>
                                    <Button
                                        title="Download"
                                        type="submit"
                                        className="border-transparent approve-step border-440 !font-interR  w-[103px] h-[40px] p-5 bg-gray-200  rounded-[8px] font-inter text-base font-normal flex justify-center items-center border-[1px] text-black cursor-pointer relative"
                                        tabIndex={1}
                                        onClick={handleDownload}
                                    >
                                        Download
                                    </Button>
                                    <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClickAway}>
                                        <Popper
                                            className={`bg-${currentMode}-card min-w-[100px] px-[20px] py-[10px] text-${currentMode}-copy border rounded-[8px] border-${currentMode}-730 `}
                                            open={2 === popstate.id}
                                            anchorEl={popstate.anchorEl}
                                            id="2"
                                        >
                                            <div>
                                                <ul>
                                                    <li>
                                                        <span
                                                            className={`flex hover:bg-${currentMode}-960 mx-[-20px] py-2 px-[20px] cursor-pointer`}
                                                            tabIndex="1"
                                                            title="Exceldownload"
                                                            onClick={() => handleDownload("excel")}
                                                        >
                                                            Excel
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span
                                                            className={`flex hover:bg-${currentMode}-960 mx-[-20px] py-2 px-[20px] cursor-pointer`}
                                                            tabIndex="2"
                                                            title="Csvdownload"
                                                            onClick={() => handleDownload("csv")}
                                                        >
                                                            {/* <CSVLink data={prepareDataForDownload(tableData)} filename={"exchange_rate.csv"}> */}
                                                            CSV
                                                            {/* </CSVLink> */}
                                                        </span>
                                                    </li>{" "}
                                                </ul>
                                            </div>
                                        </Popper>
                                    </ClickAwayListener>
                                </div>
                            )}
                        </div>

                        <Card className="xl:h-[calc(100vh_-_13rem)] h-[calc(100vh_-_18rem)]">
                            <div className="flex flex-wrap">
                                <h4 className={`p-6 pt-8  text-lg items-center font-interM text-${currentMode}-copy`}>Currencies</h4>
                                <div className="p-4 py-6">
                                    <FormControl
                                        sx={{
                                            width: "226px",
                                        }}
                                    >
                                        <Select
                                            className={` w-[226px] h-[40px] bg-${currentMode}-card mx-3 text-xs rounded-[5px] border-[#8C9097]`}
                                            value={currency || 0}
                                            onChange={(e) => currencyHandleChange(e?.target?.value, e?.target)}
                                            IconComponent={() => (
                                                <img
                                                    className="absolute opacity-50 right-[16px] pointer-events-none"
                                                    src={
                                                        currentMode !== "dark"
                                                            ? `/images/header/chevronDown.svg`
                                                            : `/images/header/chevronDown-white.svg`
                                                    }
                                                    alt=""
                                                />
                                            )}
                                            MenuProps={{
                                                sx: {
                                                    "& .MuiMenu-paper": {
                                                        maxHeight: 200,
                                                        width: 226,
                                                        left: 274,
                                                        backgroundColor: dropdownColor,
                                                        color: dropdownText,
                                                        boxShadow: dropdownShadow,
                                                        // border: dropdownBorder,
                                                        // border: "rgba(223, 225, 226, 0.56)",
                                                        borderRadius: "8px",
                                                    },
                                                },
                                            }}
                                            SelectDisplayProps={{
                                                style: {
                                                    // display: "flex",
                                                    // border: 0,
                                                    // color: currentMode === "dark" ? `#D3FAF4` : `#555555`,
                                                    // paddingRight: "20px",
                                                    paddingTop: 1,
                                                    width: "100%",
                                                    maxWidth: 230,
                                                    paddingBottom: 1,
                                                    // color: dropdownText,
                                                    paddingRight: "30px",
                                                },
                                            }}
                                        >
                                            <MenuItem value={0}>Select</MenuItem>

                                            {currencies?.map((currency) => (
                                                <MenuItem
                                                    key={currency.id}
                                                    value={currency.id}
                                                    title={currency.currency_code + "-" + currency.currency_name}
                                                    className="truncate trunc-1 block"
                                                >
                                                    {currency.currency_code} - {currency.currency_name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="heightFix  relative !rounded-t-[0] report max-h-[initial]">
                                {ExchangeRateData?.fetching ? (
                                    <Loader className="h-[calc(100vh_-_17rem)]" />
                                ) : (
                                    <>
                                        <table className="w-full relative overflow-auto rolemasterTable  lastBorder">
                                            <thead className="sticky top-0 z-[2] ">
                                                <tr>
                                                    <th className="!rounded-tl-[0] text-left pl-10">Currency</th>
                                                    <th>Symbol</th>
                                                    <th className="px-4 py-3 pl-0">Description</th>
                                                    <th>Valid From</th>
                                                    <th>Exchange Rate</th>
                                                    <th>Display</th>
                                                    <th className="!rounded-tr-[0]">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody className="border border-gray-300 text-center">
                                                {ListData?.length > 0 ? (
                                                    ListData?.map((row, id) => {
                                                        return (
                                                            <tr key={id}>
                                                                <td className="text-left px-4 py-3 pl-10">{row?.currecny_code}</td>
                                                                <td className="px-4 py-3 pl-4">{row?.currency_symbol}</td>
                                                                <td className="text-left px-4 py-3 pl-10">{row?.currency_name}</td>
                                                                <td className="px-4 py-3">
                                                                    {moment(row?.validfrom_date).isValid()
                                                                        ? moment(row?.validfrom_date).format(userDateFormat?.toUpperCase())
                                                                        : ""}
                                                                </td>
                                                                <td className="px-4 py-3">{row?.exchange_rate}</td>
                                                                <td className="text-left px-4 py-3 pl-16">
                                                                    1 {FindCurrency} = {row?.exchange_rate} {row?.currecny_code}
                                                                </td>
                                                                <td>
                                                                    <div className="flex space-x-4 justify-center">
                                                                        <Button
                                                                            title="Edit Currency"
                                                                            id={id}
                                                                            type="Button"
                                                                            className="w-[18px] h-[18px]"
                                                                            onClick={() => {
                                                                                handleModalOpen(
                                                                                    "edit",
                                                                                    id,
                                                                                    row?.validfrom_date,
                                                                                    row?.exchange_rate,
                                                                                    row?.to_currency_id,
                                                                                    row?.from_currency_id,
                                                                                    row?.id
                                                                                );
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src="/images/common/pencil-green.svg"
                                                                                className=""
                                                                                title="Edit"
                                                                                alt="edit"
                                                                            />
                                                                        </Button>
                                                                        <Button
                                                                            className="w-[18px] h-[18px]"
                                                                            onClick={() => handleDeleteClick(row?.id)}
                                                                        >
                                                                            <img
                                                                                src="/images/Output-manage/delete-icon.svg"
                                                                                title="Delete"
                                                                                alt="delete"
                                                                            />
                                                                        </Button>

                                                                        <Button
                                                                            title="View Currency"
                                                                            type="Button"
                                                                            className="w-[18px] h-[18px]"
                                                                            onClick={() =>
                                                                                handleModalOpen(
                                                                                    "history",
                                                                                    id,
                                                                                    row?.from_currency_id,
                                                                                    row?.to_currency_id,
                                                                                    row?.currecny_code
                                                                                )
                                                                            }
                                                                        >
                                                                            <img
                                                                                src="/images/Exchange-Rate/history.svg"
                                                                                title="History"
                                                                                alt="history"
                                                                            />
                                                                        </Button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                ) : (
                                                    <tbody>
                                                        <div className="bg-white">
                                                            <div className="text-center absolute left-[50%] translate-x-[-50%]">
                                                                <div className="mx-auto my-10  mt-20 mb-4">
                                                                    <img
                                                                        className="mx-auto w-[193px] h-[155px]"
                                                                        src="/images/common/delivery.png"
                                                                        alt="delivery"
                                                                    />
                                                                </div>
                                                                <h4 className="font-inter ">No Data found</h4>
                                                            </div>
                                                        </div>
                                                    </tbody>
                                                )}
                                            </tbody>
                                        </table>
                                    </>
                                )}
                            </div>
                        </Card>
                    </Main>
                    <Footer />
                </ErrorBoundary>
            </Layout>
            {ReactDOM.createPortal(
                <AddCurrency
                    addModalOpen={modalOpen.add}
                    addModalClose={() => handleModalClose("add")}
                    handleAddCurrency={handleAddCurrency} // Pass the function to AddCurrency
                    className={modalOpen.add && "Show"}
                    userDateFormat={userDateFormat}
                    currency={currency}
                />,
                document.getElementById("user-modal-root")
            )}
            {ReactDOM.createPortal(
                <EditModal
                    editModalOpen={modalOpen.edit}
                    className={modalOpen.edit && "Show"}
                    editModalClose={() => handleModalClose("edit")}
                    handleEditCurrency={handleEditCurrency}
                    tableData={tableData}
                    userDateFormat={userDateFormat}
                    setTableData={setTableData}
                    id={editingIndex}
                    currencyCode={editingCurrencyCode}
                    currency={currency}
                    EditOpen={EditOpen}
                    FindCurrency={FindCurrency}
                />,

                document.getElementById("user-modal-root")
            )}
            {ReactDOM.createPortal(
                <UploadModal
                    uploadModalOpen={modalOpen.upload}
                    className={modalOpen.upload && "Show"}
                    uploadModalClose={() => handleModalClose("upload")}
                    setJsonData={setJsonData}
                    setIsSubmit={setIsSubmit}
                    currency={currency}
                    currencyList={currencies}
                />,
                document.getElementById("user-modal-root")
            )}
            {ReactDOM.createPortal(
                <HistoryModal
                    historyModalOpen={modalOpen.history}
                    className={modalOpen.history && "Show"}
                    historyModalClose={() => handleModalClose("history")}
                    currencyCode={editingCurrencyCode}
                />,
                document.getElementById("user-modal-root")
            )}
        </>
    );
};

export default ExchangeRate;
