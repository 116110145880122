import Button from "../../../components/Button";
import Input from "../../../components/Input";
import _ from "lodash";
import { NumericFormat } from "react-number-format";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import { decSeperator, invoiceDataFetch, formatNumber, thousandSeperator, generateFormattedText } from "../../../utils/helpers";
import { useState } from "react";
import { MenuItem, Select, FormControl } from "@mui/material";
import Swal from "sweetalert2";
let deleteArr = [];
let errArr = [];
const TaxTableLineItem = (props) => {
    const headerMapping = {};
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const invoiceList = useSelector((state) => state?.getInvoiceItemsState);
    const taxHeader = invoiceList?.data?.datalist[0]?.invoice_taxitems;
    const masterRefId = invoiceList?.data?.datalist[0]?.curr_status?.[0]?.master_refid;
    const isReadOnly = [3, 4, 5]?.includes(masterRefId);
    taxHeader?.forEach((header) => {
        headerMapping[header.label_name] = header?.display_name;
    });
    // Table header (th) mapping!!...
    const tableHeaders = taxHeader?.map((headerItem) => <th key={headerItem?.label_name}>{headerItem?.display_name}</th>);
    let TblData = props?.tableTaxItem;
    const [Data, setData] = useState(TblData || {});
    const userNumFormat = localStorage.getItem("userNumberFormat");
    const _handleChange = (e, index, labelName, item) => {
        // Use lodash to update the label_value!!....
        let changed = _.set(_.cloneDeep(Data), `${index}.${labelName}.label_value`, e?.target?.value);
        // If label_name is "tax_code," update the tax_percentage
        if (labelName === "tax_code") {
            const selectedTaxCode = e.target.value;
            const taxDetails = taxCodeData.find((code) => code.tax_code === selectedTaxCode); // You need to implement this function
            if (taxDetails) {
                changed = _.set(changed, `${index}.tax_percentage.label_value`, taxDetails.tax_percentage);
            }
        }
        setData(changed);
    };
    //* select the field on line-item
    const handleOnItemClick = (itemName, value) => {
        if (value !== "") {
            props.setShowToolTip(itemName);
        }
    };
    // add new row func!!!...
    const addNewRow = () => {
        // Clone the existing data
        const clonedData = _.cloneDeep(Data);
        const newRowId = Object.keys(clonedData).length;
        // Generate a new row based on the initialRowStructure
        const newRow = {};
        taxHeader?.forEach((field) => {
            newRow[field?.label_name] = {
                label_value: "",
                label_name: field?.label_name,
                label_displayname: field?.display_name,
                isrequired: true,
                ismandatory: false,
                status: 1,
                data_type: field?.data_type,
                id: 0, // You can generate a unique ID here
                maxlength: 0,
                iseditable: true,
                item_no: newRowId + 1,
                is_item: field?.isitem,
            };
        });
        clonedData[newRowId] = newRow;
        setData(clonedData);
    };
    //function for delete the row
    const deleteRow = (rowId) => {
        Swal.fire({
            text: `Are you sure you want to delete this row?`,
            icon: "warning",
            closeOnClickOutside: false,
            showCloseButton: true,
            showCancelButton: true,
            buttons: {
                confirm: "Confirm",
                cancel: "Cancel",
            },
        }).then((confirm) => {
            if (confirm?.isConfirmed) {
                // Clone the existing data
                const clonedData = _.cloneDeep(Data);
                // Get the deleted row
                const deletedRow = clonedData[rowId];
                const result = _.map(_.values(deletedRow), (innerObj) => {
                    if (innerObj.hasOwnProperty("status")) {
                        return {
                            ...innerObj,
                            status: 3,
                        };
                    }
                    return innerObj;
                });
                deleteArr.push(result);
                // Delete the row with the specified ID
                delete clonedData[rowId];
                // Reorder the index and update item_no inside the object
                const newData = {};
                let newIndex = 0;
                for (const key in clonedData) {
                    if (clonedData.hasOwnProperty(key)) {
                        const rowData = clonedData[key];
                        rowData.item_no = newIndex + 1; // Update item_no
                        newData[newIndex] = rowData;
                        newIndex++;
                    }
                }
                // Update the state with the new data
                setData(newData);
            }
        });
    };
    const isNonPO = invoiceList?.data?.datalist?.[0]?.document_id == 2 || invoiceList?.data?.datalist?.[0]?.document_id == 4;
    const { data: taxCodeData, requestComplete: reqCom } = useSelector((state) => state?.taxCodeWithPercentageListState);

    const Tablerow = Object?.keys(Data)?.map((key, i) => {
        const item = Data[key];
        const tableCells = Object?.keys(headerMapping)?.map((labelName, index) => {
            const Numeric = item[labelName]?.data_type == "m" || item[labelName]?.data_type == "p" || item[labelName]?.data_type == "n";
            const taxCode = labelName == "tax_code" && isNonPO;
            return (
                <td>
                    {taxCode ? (
                        <Select
                            onChange={(e) => _handleChange(e, key, labelName, item[labelName], item)}
                            name="tax_code"
                            value={!item[labelName]?.label_value ? 0 : item[labelName]?.label_value}
                            className={`min-w-[236px]  px-[8px] txcode`}
                            disabled={isReadOnly}
                            MenuProps={{
                                sx: {
                                    "& .MuiMenu-paper": {
                                        backgroundColor: "#333333",
                                        color: "#ECECEC !important",
                                        maxWidth: "50rem !important",
                                    },
                                    "& .MuiMenu-paper .MuiButtonBase-root.MuiMenuItem-root:hover": {
                                        backgroundColor: "#000000 !important",
                                    },
                                    "& .Mui-selected": {
                                        backgroundColor: "rgba(148, 148, 148, 0.07) !important",
                                    },
                                },
                            }}
                            IconComponent={() => (
                                <img
                                    className="absolute right-[15px] opacity-[.5] pointer-events-none"
                                    src={`/images/header/chevronDown-white.svg`}
                                    alt="chevronDown"
                                />
                            )}
                            SelectDisplayProps={{
                                style: {
                                    backgroundColor: "#1A1A1A",
                                    paddingTop: "9px",
                                    paddingBottom: "9px",
                                    color: "#ECECEC",
                                    paddingRight: "30px",
                                    border: "1px solid rgba(85, 85, 85, 0.3)",
                                    borderRadius: "8px",
                                },
                            }}>
                            <MenuItem selected value={0} name="tax_code">
                                {"Select"}
                            </MenuItem>
                            {taxCodeData?.map((elem, i) => {
                                return (
                                    <MenuItem key={i + 1} name="tax_code" value={elem?.tax_code}>
                                        {elem?.tax_description}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    ) : Numeric ? (
                        <NumericFormat
                            maxLength={item[labelName]?.maxlength == 0 ? 20 : null}
                            autoComplete="off"
                            allowNegative={false}
                            readOnly={item[labelName]?.iseditable && !isReadOnly ? false : true}
                            decimalScale={2}
                            displayType="input"
                            thousandSeparator={thousandSeperator}
                            decimalSeparator={decSeperator}
                            // disabled={status == "7" || status == "4"}
                            className={`w-full fieldId-${item?.id} ${(item[labelName]?.label_value === null || item[labelName]?.label_value === "") && item?.ismandatory
                                    ? `border-[red]`
                                    : ``
                                } bg-transparent`}
                            value={item[labelName]?.label_value}
                            name={labelName}
                            onClick={() => {
                                handleOnItemClick(
                                    {
                                        name: labelName,
                                        id: item[labelName]?.id,
                                    },
                                    item[labelName]?.label_value
                                );
                            }}
                            onChange={(e) => _handleChange(e, key, labelName, item[labelName], item)}
                        />
                    ) : (
                        <Input
                            // readOnly={item?.iseditable ? false : true}
                            // disabled={
                            //     status == "7" ||
                            //     status == "4"
                            // }
                            readOnly={isReadOnly}
                            className={`w-full fieldId-${key} ${(item[labelName]?.label_value === null || item[labelName]?.label_value === "") && item[labelName]?.ismandatory
                                    ? `border-[red]`
                                    : ``
                                } bg-transparent`}
                            value={item[labelName]?.label_value}
                            name={labelName}
                            onChange={(e) => _handleChange(e, key, labelName, item[labelName], item)}
                            onClick={() => {
                                handleOnItemClick(
                                    {
                                        name: labelName,
                                        id: item[labelName]?.id,
                                    },
                                    item[labelName]?.label_value
                                );
                            }}
                        />
                    )}
                </td>
            );
        });
        // Return a table row with the table cells
        return (
            <tr key={key}>
                <td className="sticky left-0 bg-black z-[1]">{++i}</td>
                {tableCells}
                <td className="inline-table border-0">
                    <Button
                        // disabled={status == "7" || status == "4"}
                        disabled={isReadOnly}
                        onClick={() => deleteRow(key)}
                        className="bg-light-540  delIco rounded-[50%] justify-center items-center fixed 2xl:right-[calc(57.44%_-_31%)] right-[28rem] w-[18px] h-[16px]">
                        <img src="/images/invoice/minus-white.svg" alt="minus-white" />
                    </Button>
                </td>
            </tr>
        );
    });
    let taxTotal = 0;
    //parse the value to sum for autocalculate
    const formatNumber = (value) => {
        const val =
            userNumFormat == "1"
                ? value?.toString()?.replaceAll(",", "")
                : userNumFormat == "2"
                    ? value?.toString()?.replaceAll(".", "")?.replaceAll(",", ".")
                    : value?.toString()?.replaceAll(" ", "")?.replaceAll(",", "");
        const formatedVal = (+val)?.toFixed(2);
        return val !== "" ? (+val == null ? "" : +formatedVal) : "";
    };
    if (Object.keys(Data).length > 0) {
        _.mapValues(Data, (item, key) => {
            const parse = isNaN(parseFloat(item?.total_tax?.label_value)) ? 0 : parseFloat(formatNumber(item?.total_tax?.label_value));
            taxTotal += parse;
            props?.setTotalTax(taxTotal);
        });
    } else {
        props?.setTotalTax("0");
    }

    const onSaveLineItems = () => {
        const deletePayload = _.flatten(deleteArr);
        // Assuming your object is named 'data'
        const validateTaxCode = _.forEach(Data, (innerObj) => {
            _.forEach(innerObj, (value, key) => {
                // Check if the label_value for item_tax_amount is empty
                if (
                    ((key === "tax_percentage" && value.label_value === "") || (key === "tax_percentage" && +value.label_value === 0)) &&
                    innerObj?.hasOwnProperty("tax_code")
                ) {
                    // If so, set the label_value for item_tax_code to an empty string
                    innerObj.tax_code.label_value = "";
                }
            });
        });
        const dataArray = Object.values(validateTaxCode).map((item) => {
            return Object.values(item);
        });
        const mergedArr = [].concat(...dataArray).concat(...deletePayload); // to merged the two arrays
        //To check the mandatory and empty fields
        const mandatoryErr = [];
        const maxLengthError = [];
        const dataTypeError = [];
        _.mapValues(validateTaxCode, (item, key) => {
            const itemKeys = Object.keys(item);
            itemKeys?.map((ele) => {
                //Check if field is mandatory
                const checkMandatory = item[ele]?.ismandatory && !item[ele]?.label_value;
                //Check max-length is appropriate as in invoice layout
                const checkMaxLength =
                    item[ele]?.ismandatory && item[ele]?.maxlength > 0 ? item[ele]?.label_value?.length > item[ele]?.maxlength : false;
                //Check the value is correct as per datatype
                const checkDataType = item[ele]?.data_type === "p" ? formatNumber(item[ele]?.label_value) > 100 : false;
                if (checkMandatory) {
                    const mandatory = item[ele]?.label_displayname;
                    mandatoryErr?.push(mandatory);
                    errArr?.push(mandatory);
                } else if (checkMaxLength) {
                    const maxLength = item[ele]?.label_displayname;
                    maxLengthError?.push(maxLength);
                    errArr?.push(maxLength);
                } else if (checkDataType) {
                    const typeData = item[ele]?.label_displayname;
                    dataTypeError?.push(typeData);
                    errArr?.push(typeData);
                }
            });
        });
        if (mandatoryErr?.length > 0 || maxLengthError?.length > 0 || dataTypeError?.length > 0) {
            const removeDublicate = [...new Set(errArr)];
            const MandotaryList = generateFormattedText(
                removeDublicate,
                mandatoryErr?.length > 0
                    ? "The following line items are required:!<br/><br/>"
                    : maxLengthError?.length > 0
                        ? "Reached the maximum length!<br/><br/>"
                        : dataTypeError?.length > 0
                            ? "Percentage value ought to be lower than 100%!<br/><br/>"
                            : ""
            );
            Swal?.fire({
                html: MandotaryList,
                icon: "warning",
                closeOnClickOutside: false,
                showCancelButton: true,
                showCloseButton: true,
                button: {
                    confirm: "Confirm",
                    cancel: "Cancel",
                },
                cancelButtonText: "No, cancel it!",
                confirmButtonText: "Yes, I am sure!",
                dangerMode: true,
            }).then((confirm) => {
                if (confirm?.isConfirmed) {
                    props?.setTableTaxItem(validateTaxCode);
                    const convertPayload = mergedArr.map((obj) => {
                        if (obj.hasOwnProperty("status")) {
                            return {
                                ...obj,
                                status: obj?.id !== 0 && obj?.status == 0 ? 2 : obj?.id == 0 ? 1 : 3,
                            };
                        }
                        return obj;
                    });
                    props?.setClickTaxOpen(false);
                    const filterArr = convertPayload?.filter((ite) => typeof ite == "object");

                    props?.setUpdatedTaxData(filterArr);
                    props?.handleValidateChanges(filterArr);
                    tableClose(false);
                    document.querySelector(".lineItem").classList.add("approve");
                    errArr = [];
                    // onSaveInvDoc(true);
                } else {
                    errArr = [];
                    props?.setClickTaxOpen(true);
                    return false;
                }
            });
        } else {
            props?.setTableTaxItem(validateTaxCode);
            document?.querySelector(".lineItem")?.classList?.add("approve");
            const convertPayload = mergedArr.map((obj) => {
                if (obj.hasOwnProperty("status")) {
                    return {
                        ...obj,
                        status: obj?.id !== 0 && obj?.status == 0 ? 2 : obj?.id == 0 ? 1 : 3,
                    };
                }

                return obj;
            });
            props?.setClickTaxOpen(false);
            const filterArr = convertPayload?.filter((ite) => typeof ite == "object");

            props?.setUpdatedTaxData(filterArr);
            props?.handleValidateChanges(filterArr);
            tableClose(false);
        }
    };
    const tableClose = () => {
        props?.setClickTaxOpen(false);
    };
    return (
        <div
            className={` bg-black w-full xl:w-[70%] 2xl:w-[74.44%] fixed z-[11] wrapTable bottom-[0] top-[inherit] left-[0] text-white border border-${currentMode}-560 shadow-[0px_4px_20px] shadow-${currentMode}-570 rounded-[8px]`}>
            <div className={`flex justify-between items-center py-4 px-7 z-[2] sticky top-0  bg-black left-0 right-0`}>
                <div className="flex space-x-7">
                    <div className="flex space-x-2">
                        <Button
                            onClick={() => onSaveLineItems()}
                            className="bg-light-20 px-[15px] py-[4px] text-white rounded-[5px] flex items-center">
                            <img className="mr-2" src="/images/common/tickWhite.svg" alt="tickWhite" /> Confirm
                        </Button>
                        <Button
                            onClick={() => tableClose(false)}
                            className="bg-light-1070 px-[17px] py-[4px] text-white rounded-[5px] flex items-center">
                            <img className="mr-2" src="/images/common/whiteClose.svg" alt="whiteClose" />
                            Cancel
                        </Button>
                    </div>
                </div>
                <strong className="font-interB">
                    Total:
                    <NumericFormat
                        value={taxTotal?.toFixed(2)}
                        allowNegative={false}
                        displayType="text"
                        decimalScale={2}
                        thousandSeparator={userNumFormat == "1" ? "," : userNumFormat == "2" ? "." : " "}
                        decimalSeparator={userNumFormat == "1" ? "." : userNumFormat == "2" ? "," : "."}
                    />
                </strong>
                <Button disabled={isReadOnly} className="flex items-center" onClick={() => addNewRow()}>
                    <img className="mr-2" src="/images/invoice/plus-green.svg" alt="plus-green" /> Add a Row
                </Button>
            </div>
            <div>
                <table
                    className={`${Object.keys(Data)?.length == 0 ? "w-full" : "w-full"}  ${Object.keys(Data)?.length > 7 ? `h-[400px]` : ``
                        } tblLineItem blk-tble`}>
                    <thead className={`bg-${currentMode}-1180 sticky top-[61px] z-[2]`}>
                        <tr>
                            <th className={`sticky left-0 z-[1] bg-${currentMode}-1180`}>#</th>
                            {tableHeaders}
                        </tr>
                    </thead>
                    <tbody className="">
                        {Object?.keys(Data)?.length === 0 ? <p className="text-white absolute w-full top-[10px]">No items found</p> : Tablerow}
                    </tbody>
                </table>
            </div>
        </div>
        // </Resizable>
    );
};

export default TaxTableLineItem;
