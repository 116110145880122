import Button from "../../../components/Button";
import Input from "../../../components/Input";
import Loader from "../../../components/Loader";
import { useState, useEffect, useContext } from "react";
import { ADD_SERVER_CONFIG_RESET, TEST_SERVER_CONFIG_RESET, VIEW_SERVER_CONFIG_RESET } from "../../../redux/types/types";
import { useFormik } from "formik";
import { serverValidationSchema } from "../../../Hooks/validateschema";
import { useSelector, useDispatch } from "react-redux";
import { serverconfigAdd, serverConfigTest, serverconfigView } from "../../../redux/actions/serverconfig";
import MessageBox from "../../../components/UI/Alert";
import SuccessMessageBox from "../../../components/UI/Alert/sucess";
import { Link } from "react-router-dom";
import { decryptId, encryptId } from "../../../utils/helpers";
import Swal from "sweetalert2";
import { FormControl, MenuItem, Select } from "@mui/material";
import { getSapFinancialList } from "../../../redux/actions/organisation.js";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import ReactDOM from "react-dom";
import { CustomApiModal } from "./customApiModal";
import { ApiTestingModal } from "./apiTestingModal";
const successMsg = "Server configuration is updated successfully.";
const ServerConfigModal = (props) => {
    //var
    const { currentMode } = useContext(ThemeContext);
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [configValues, setConfigValues] = useState();
    const [isResetform, setResetForm] = useState(false);
    const [testedArray, setTestedArray] = useState(false);
    let serverResponse = useSelector((state) => state?.serverViewConfigState);
    const targetViewValues = serverResponse?.data?.[0];
    const dispatch = useDispatch();
    const [openCustomApi, setOpenCustomApi] = useState(false);
    const [targetType, setTargetType] = useState(0);
    const [authenticateType, setauthenticateType] = useState(1);
    const [customApiData, setCustomApiData] = useState([]);
    const [openTestingModal, setOpenTestingModal] = useState(false);
    const advanceSettingData = serverResponse?.data?.[0]?.advanced_settings;
    const checkEmptyArr = advanceSettingData?.filter((ele) => ele?.url == "");
    //get data render data
    useEffect(() => {
        if (targetViewValues !== undefined) {
            setauthenticateType(targetViewValues?.auth_type !== null ? targetViewValues?.auth_type : 1);
            setValidAuthType(targetViewValues?.auth_type !== null ? targetViewValues?.auth_type : 1);
            formik.setFieldValue("erp_type", targetViewValues?.erp_type !== null && targetViewValues?.erp_type);
        }
    }, [targetViewValues]);
    //open modal popup
    const handleOpenACustomApi = () => {
        setOpenCustomApi(true);
    };
    const getTargetOrgID = localStorage.getItem("selectedOrgId");
    const orgStatus = props?.orgStatus;
    const { requestComplete, profile, fetching } = useSelector((state) => state?.serverAddConfigState);
    const [validAuthType, setValidAuthType] = useState(1);
    const getAdvancedSetting = targetViewValues?.advanced_settings;
    //formik func on var
    const formik = useFormik({
        initialValues: {
            organisation_id: props?.orgId,
            serverurl: authenticateType !== targetViewValues?.auth_type ? "" : targetViewValues?.serverurl || "",
            password: authenticateType !== targetViewValues?.auth_type ? "" : decryptId(targetViewValues?.password) || "",
            username: authenticateType !== targetViewValues?.auth_type ? "" : decryptId(targetViewValues?.username) || "",
            isactive: targetViewValues?.isactive,
            queryParameters: targetViewValues?.params_type || 0,
            erp_type: targetViewValues?.erp_type || 0,
            auth_type: targetViewValues?.auth_type || 1,
        },
        enableReinitialize: true,
        validationSchema: serverValidationSchema(validAuthType),
        onSubmit: (values) => {
            const check = formik?.values?.erp_type !== 1 ? checkEmptyArr?.length == 0 : true;
            // if (check && !openTestingModal) {
            if (!openTestingModal) {
                dispatch(
                    serverconfigAdd({
                        organisation_id: props?.orgId,
                        id: targetViewValues?.id,
                        serverurl: values?.serverurl,
                        username: validAuthType !== 2 && validAuthType !== 4 ? "" : encryptId(values?.username),
                        password: validAuthType == 1 ? "" : encryptId(values?.password),
                        authtype: validAuthType,
                        isactive: values?.isactive,
                        queryParameters: validAuthType == 4 && values?.queryParameters == 2 ? true : false,
                        advanced_settings: targetType !== 1 ? (customApiData?.length !== 0 ? customApiData : getAdvancedSetting) : null,
                        erp_type: values?.erp_type,
                    })
                );
            }
        },
    });

    //obj for test button payload
    const reqObj = {
        reqUrl: formik?.values?.serverurl,
        authType: validAuthType,
        authMethod: {
            userName: validAuthType !== 2 && validAuthType !== 4 ? "" : encryptId(formik?.values?.username),
            userPassword: validAuthType == 1 ? "" : encryptId(formik?.values?.password),
            queryParameters: validAuthType == 4 && formik?.values?.queryParameters == 2 ? true : false,
        },
    };
    // useEffect(() => {
    //     if (formik.values.erp_type) {
    //         // formik.handleReset();
    //         formik.values.password = "";
    //         formik.values.username = "";
    //         formik.values.serverurl = "";
    //     }
    // }, [formik.values.erp_type]);
    const { data, requestComplete: reqComp, fetching: fetch, error } = useSelector((state) => state?.serverConfigTestState);
    const [checkTestButton, setCheckTestButton] = useState(false);
    useEffect(() => {
        if (data?.status) {
            // setListData(data?.datalist);
            setCheckTestButton(true);
        }
    }, [data]);
    //test button function
    const handleTestConfig = async () => {
        if (
            (formik.values?.auth_type == 1 && formik.values.serverurl !== "" && formik.values.username == "" && formik.values.password == "") ||
            (formik.values?.auth_type == 2 && formik.values.serverurl !== "" && formik.values.username !== "" && formik.values.password !== "") ||
            (formik.values?.auth_type == 3 && formik.values.serverurl !== "" && formik.values.username == "" && formik.values.password !== "") ||
            (formik.values?.auth_type == 4 && formik.values.serverurl !== "" && formik.values.username !== "" && formik.values.password !== "")
        ) {
            if (checkEmptyArr?.length !== 0 && formik?.values?.erp_type !== 1) {
                await Swal.fire({
                    text: `All fields must be filled.`,
                    icon: "warning",
                    closeOnClickOutside: false,
                    buttons: {
                        confirm: true,
                    },
                }).then((confirm) => {
                    if (confirm) {
                        dispatch({ type: TEST_SERVER_CONFIG_RESET });
                    }
                });
            } else {
                if (formik.values.erp_type !== 1) {
                    // const reqObj = {
                    //     reqUrl: formik?.values?.serverurl,
                    //     authType: validAuthType,
                    //     method: "get",
                    //     authMethod: {
                    //         userName:
                    //             validAuthType !== 2 && validAuthType !== 4
                    //                 ? ""
                    //                 : encryptId(formik?.values?.username),
                    //         userPassword:
                    //             validAuthType == 1
                    //                 ? ""
                    //                 : encryptId(formik?.values?.password),
                    //         queryParameters:
                    //             validAuthType == 4 &&
                    //             formik?.values?.queryParameters == 2
                    //                 ? true
                    //                 : false,
                    //     },
                    // };
                    setOpenTestingModal(true);
                    // dispatch(serverConfigTest(reqObj));
                } else {
                    dispatch(serverConfigTest(reqObj));
                }
            }
        }
    };
    //tested result on every test call
    if ((formik.values.erp_type == 1 && reqComp && data) || (formik.values.erp_type == 1 && !fetch && error)) {
        Swal.fire({
            text: `Server is ${error ? error?.response?.data?.message : data?.message}`,
            icon: `${data?.status ? "success" : "warning"}`,
            closeOnClickOutside: false,
            buttons: {
                confirm: true,
            },
        }).then((confirm) => {
            if (confirm) {
                dispatch({ type: TEST_SERVER_CONFIG_RESET });
            }
        });
    }

    if (requestComplete && profile?.status) {
        setTimeout(() => {
            cartClose();
            dispatch({ type: ADD_SERVER_CONFIG_RESET });
            window.location.reload();
        }, 2000);
    }

    // cart close function
    const cartClose = () => {
        props?.handleClose();
        formik.handleReset();
        dispatch({ type: TEST_SERVER_CONFIG_RESET });
        dispatch({ type: ADD_SERVER_CONFIG_RESET });
        dispatch({ type: VIEW_SERVER_CONFIG_RESET });
        document.querySelector("[data-id='8']").classList.remove("isActive");
    };
    const handleResetForm = () => {
        setConfigValues(null);
        setResetForm(true);
        formik.handleReset();
        dispatch({ type: ADD_SERVER_CONFIG_RESET });
        dispatch(serverconfigView());
    };
    const [checkFocus, setCheckFocus] = useState(false);
    useEffect(() => {
        if (formik.values.auth_type || formik.values.erp_type) {
            if (formik.values.erp_type == false) {
                formik.values.erp_type = 0;
            }
            formik.setTouched({});
        }
    }, [formik.values.auth_type, formik.values.erp_type]);
    //formik handlechange
    const handleChange = async (e) => {
        formik?.handleChange(e);
        if (e.target.name == "erp_type" && e.target.value == 1) {
            setauthenticateType(2);
            setValidAuthType(2);
            formik.setFieldValue("auth_type", 2);
        }
        if (e.target.name !== "company_code") {
            if (e.target.name == "auth_type") {
                // formik.setTouched({});
                setValidAuthType(e.target.value);
            }
            dispatch({ type: TEST_SERVER_CONFIG_RESET });
            setCheckTestButton(false);
            setCheckFocus(true);
        } else {
            const reqObj = {
                serverurl: formik?.values?.serverurl,
                username: encryptId(formik?.values?.username),
                password: encryptId(formik?.values?.password),
                org_code: e.target.value,
            };
            dispatch(getSapFinancialList(reqObj));
        }
    };
    useEffect(() => {
        if (formik.initialValues.erp_type !== formik.values.erp_type || formik.initialValues.auth_type !== formik.values.auth_type) {
            formik.values.username = "";
            formik.values.password = "";
            // formik.values.serverurl = "";
        } else {
            formik.values.username = formik.initialValues.username;
            formik.values.password = formik.initialValues.password;
            formik.values.serverurl = formik.initialValues.serverurl;
        }
    }, [formik.values.erp_type, formik.values.auth_type]);
    //view
    useEffect(() => {
        if (serverResponse) {
            setConfigValues(serverResponse?.data);
        }
    }, [configValues]);
    const DisplayRole = useSelector((state) => state?.DisplayRolePermissionStateAction);
    const { data: DataList } = DisplayRole;
    const RoleList = DataList?.role_info;
    const RoleMenu = RoleList?.menu;
    const RoleTarget = RoleMenu?.find((elem) => elem?.menu_id === 4);
    const RoleTargetAction = RoleTarget?.actions;
    const RoleActTargetView = RoleTargetAction?.find((element) => element.id === 3);
    const RoleActTargetAdd = RoleTargetAction?.find((element) => element.id === 1);
    const RoleActTargetEdit = RoleTargetAction?.find((element) => element.id === 2);
    if (formik.values.serverurl == "" && formik.values.username == "" && formik.values.password == "" && props?.isOpened) {
        var textarea = document?.getElementById("serverurlId");
        textarea?.focus();
    }
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;

    return (
        <>
            {requestComplete && profile?.status && <SuccessMessageBox success={successMsg} />}
            {requestComplete && !profile?.status && <MessageBox error={profile?.message} />}
            {serverResponse?.fetching ? (
                <Loader className="h-[calc(100vh_-_26rem)]" />
            ) : (
                <>
                    <span onClick={cartClose} className="p-[6.5px] absolute right-[561px] bg-light-10 cursor-pointer">
                        <img src="/images/common/whiteClose.svg" alt="whiteClose" />
                    </span>
                    <div
                        className={`w-[561px] bg-${currentMode}-card transition duration-150 ease-out flex-col flex ml-auto h-screen max-h-[-webkit-fill-available] overflow-y-auto`}>
                        <div className={` px-[30px] py-[24px] border-b-[1px] border-${currentMode}-700 mb-[1.8rem] relative`}>
                            <h4 className={`font-interSb text-lg text-${currentMode}-copy`}>Server Configuration</h4>
                        </div>
                        <div className="px-[35px]">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="w-[500px] h-[calc(100vh_-_211px)]">
                                    <form action="">
                                        <div className="from-group mb-5">
                                            <label htmlFor="" className="mb-1 block">
                                                ERP
                                            </label>
                                            <FormControl
                                                sx={{
                                                    minWidth: "500px",
                                                    // marginBottom: 1,
                                                }}>
                                                <Select
                                                    className=" w-full "
                                                    value={formik.values.erp_type}
                                                    name="erp_type"
                                                    id="erp_type"
                                                    autoFocus
                                                    onChange={(e) => handleChange(e)}
                                                    placeholder="Select ERP"
                                                    MenuProps={{
                                                        sx: {
                                                            "& .MuiMenu-paper": {
                                                                backgroundColor: dropdownColor,
                                                                color: dropdownText,
                                                                //   maxWidth: "30rem !important",
                                                            },
                                                            "& .MuiMenuItem-root:hover": {
                                                                backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                            },
                                                            "& .Mui-selected": {
                                                                backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                            },
                                                        },
                                                    }}
                                                    IconComponent={() => (
                                                        <img
                                                            className="absolute right-[15px] opacity-[.5] pointer-events-none"
                                                            src={
                                                                currentMode !== "dark"
                                                                    ? `/images/header/chevronDown.svg`
                                                                    : `/images/header/chevronDown-white.svg`
                                                            }
                                                            alt="chevronDown"
                                                        />
                                                    )}
                                                    SelectDisplayProps={{
                                                        style: {
                                                            paddingTop: "13px",
                                                            paddingBottom: "13px",
                                                            color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                            paddingRight: "30px",
                                                            paddingLeft: "15px",
                                                            border: "1px solid rgba(85, 85, 85, 0.4)",
                                                            borderRadius: "8px",
                                                        },
                                                    }}>
                                                    <MenuItem value={0} style={{ display: "none" }} name="erp_type">
                                                        {"Select"}
                                                    </MenuItem>
                                                    <MenuItem value={1} name="erp_type">
                                                        {"SAP"}
                                                    </MenuItem>
                                                    <MenuItem value={2} name="erp_type">
                                                        {"Oracle"}
                                                    </MenuItem>
                                                    <MenuItem value={3} name="erp_type">
                                                        {"Sage"}
                                                    </MenuItem>
                                                    <MenuItem value={4} name="erp_type">
                                                        {"Microsoft Dynamics 365"}
                                                    </MenuItem>
                                                    <MenuItem value={5} name="erp_type">
                                                        {"Others"}
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                            {formik.errors.erp_type && formik.touched.erp_type ? (
                                                <p className="text-light-50 text-xs">{formik.errors?.erp_type}</p>
                                            ) : null}
                                        </div>
                                        <div className="from-group mb-5">
                                            <label htmlFor="" className="mb-1 block">
                                                URL
                                            </label>
                                            <Input
                                                disabled={!orgStatus || RoleActTargetEdit?.isallowed == false ? true : false}
                                                onChange={(e) => handleChange(e)}
                                                value={RoleActTargetView?.isallowed == false ? "" : formik.values.serverurl}
                                                name="serverurl"
                                                autoComplete="off"
                                                placeholder="https://www.example.com"
                                                className={`w-full py-2 rounded-[8px] px-5  border-[1px] bg-${currentMode}-810 border-${currentMode}-840`}
                                            />
                                            {formik.errors.serverurl && formik.touched.serverurl ? (
                                                <p className="text-light-50 text-xs">{formik.errors?.serverurl}</p>
                                            ) : null}
                                        </div>
                                        <div className="from-group mb-5">
                                            <label htmlFor="" className="mb-1 block">
                                                Authentication
                                            </label>
                                            <FormControl
                                                sx={{
                                                    minWidth: "500px",
                                                    // marginBottom: 1,
                                                }}>
                                                <Select
                                                    onChange={(e) => handleChange(e)}
                                                    className=" w-full "
                                                    name="auth_type"
                                                    value={formik.values.auth_type}
                                                    disabled={formik.values.erp_type == 1}
                                                    MenuProps={{
                                                        sx: {
                                                            "& .MuiMenu-paper": {
                                                                backgroundColor: dropdownColor,
                                                                color: dropdownText,
                                                                //   maxWidth: "30rem !important",
                                                            },
                                                            "& .MuiMenuItem-root:hover": {
                                                                backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                            },
                                                            "& .Mui-selected": {
                                                                backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                            },
                                                        },
                                                    }}
                                                    IconComponent={() => (
                                                        <img
                                                            className="absolute right-[15px] opacity-[.5] pointer-events-none"
                                                            src={
                                                                currentMode !== "dark"
                                                                    ? `/images/header/chevronDown.svg`
                                                                    : `/images/header/chevronDown-white.svg`
                                                            }
                                                            alt="chevronDown"
                                                        />
                                                    )}
                                                    SelectDisplayProps={{
                                                        style: {
                                                            paddingTop: "13px",
                                                            paddingBottom: "13px",
                                                            color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                            paddingRight: "30px",
                                                            paddingLeft: "15px",
                                                            border: "1px solid rgba(85, 85, 85, 0.4)",
                                                            borderRadius: "8px",
                                                        },
                                                    }}>
                                                    <MenuItem value={1} name="auth_type">
                                                        {"No Authentication"}
                                                    </MenuItem>
                                                    <MenuItem value={2} name="auth_type">
                                                        {"Basic Authentication"}
                                                    </MenuItem>
                                                    {/* <MenuItem
                                                value={3}
                                                name="invoice_type"
                                            >
                                                {"Token Authentication"}
                                            </MenuItem> */}
                                                    <MenuItem value={4} name="auth_type">
                                                        {"API Key"}
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        {(formik.values.auth_type == 2 || formik.values.auth_type == 4) && (
                                            <div className="from-group mb-5">
                                                <label htmlFor="" className="mb-1 block">
                                                    {formik.values.auth_type == 4 ? "Key" : "Username"}
                                                </label>
                                                <Input
                                                    onChange={(e) => handleChange(e)}
                                                    disabled={!orgStatus || RoleActTargetEdit?.isallowed == false ? true : false}
                                                    value={RoleActTargetView?.isallowed == false ? "" : formik.values.username}
                                                    maxLength={51}
                                                    name="username"
                                                    id="email-id"
                                                    placeholder="Enter the Username"
                                                    autoComplete="email-id"
                                                    className={`w-full py-2 rounded-[8px] px-5  border-[1px] bg-${currentMode}-810 border-${currentMode}-840`}
                                                />
                                                {formik.errors.username && formik.touched.username ? (
                                                    <p className="text-light-50 text-xs">{formik.errors.username}</p>
                                                ) : null}
                                                {/* {errors && (
                      <p className="text-light-50 text-xs">{errors?.username}</p>
                    )} */}
                                            </div>
                                        )}
                                        {(formik.values.auth_type == 2 || formik.values.auth_type == 4 || formik.values.auth_type == 3) && (
                                            <div className="from-group mb-5">
                                                <label htmlFor="" className="mb-1 block">
                                                    {formik.values.auth_type == 4 ? "Value" : formik.values.auth_type == 2 ? "Password" : "Token"}
                                                </label>
                                                <div className="relative">
                                                    <Input
                                                        onChange={(e) => handleChange(e)}
                                                        disabled={!orgStatus || RoleActTargetEdit?.isallowed == false ? true : false}
                                                        value={RoleActTargetView?.isallowed == false ? "" : formik.values.password}
                                                        type={formik.values.auth_type == 2 ? (isRevealPwd ? "text" : "password") : "text"}
                                                        name="password"
                                                        id="new-password"
                                                        placeholder={
                                                            formik.values.auth_type == 2
                                                                ? "Enter a Password"
                                                                : formik.values.auth_type == 3
                                                                ? "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9"
                                                                : "{{my-username}}"
                                                        }
                                                        className={`w-full py-2 rounded-[8px] pl-5 pr-[55px]  border-[1px] bg-${currentMode}-810 border-${currentMode}-840`}
                                                        autoComplete="new-password"
                                                    />
                                                    {formik.values.auth_type == 2 && (
                                                        <Link
                                                            onClick={() => setIsRevealPwd((prevState) => !prevState)}
                                                            className={`absolute cursor-pointer top-[57%] translate-y-[-57%] right-[26px] `}>
                                                            <img
                                                                title={isRevealPwd ? "Hide password" : "Show password"}
                                                                src={isRevealPwd ? `/images/common/eyeOpen.svg` : `/images/common/eyeClose.svg`}
                                                            />
                                                        </Link>
                                                    )}
                                                </div>
                                                {formik.errors.password && formik.touched.password ? (
                                                    <p className="text-light-50 text-xs">{formik.errors?.password}</p>
                                                ) : null}
                                            </div>
                                        )}
                                        {formik.values.auth_type == 4 && (
                                            <div className="from-group mb-5">
                                                <label htmlFor="" className="mb-1 block">
                                                    Add to
                                                </label>
                                                <FormControl
                                                    sx={{
                                                        minWidth: "500px",
                                                        // marginBottom: 1,
                                                    }}>
                                                    <Select
                                                        className=" w-full "
                                                        onChange={(e) => handleChange(e)}
                                                        value={RoleActTargetView?.isallowed == false ? "" : formik?.values?.queryParameters}
                                                        name="queryParameters"
                                                        MenuProps={{
                                                            sx: {
                                                                "& .MuiMenu-paper": {
                                                                    backgroundColor: dropdownColor,
                                                                    color: dropdownText,
                                                                    //   maxWidth: "30rem !important",
                                                                },
                                                                "& .MuiMenuItem-root:hover": {
                                                                    backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                                },
                                                                "& .Mui-selected": {
                                                                    backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                                },
                                                            },
                                                        }}
                                                        IconComponent={() => (
                                                            <img
                                                                className="absolute right-[15px] opacity-[.5] pointer-events-none"
                                                                src={
                                                                    currentMode !== "dark"
                                                                        ? `/images/header/chevronDown.svg`
                                                                        : `/images/header/chevronDown-white.svg`
                                                                }
                                                                alt="chevronDown"
                                                            />
                                                        )}
                                                        SelectDisplayProps={{
                                                            style: {
                                                                paddingTop: "13px",
                                                                paddingBottom: "13px",
                                                                color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                                paddingRight: "30px",
                                                                paddingLeft: "15px",
                                                                border: "1px solid rgba(85, 85, 85, 0.4)",
                                                                borderRadius: "8px",
                                                            },
                                                        }}>
                                                        <MenuItem value={0} name="invoice_type">
                                                            {"Select"}
                                                        </MenuItem>
                                                        <MenuItem value={1} name="invoice_type">
                                                            {"Header"}
                                                        </MenuItem>
                                                        <MenuItem value={2} name="invoice_type">
                                                            {"Query Params"}
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                                {formik.errors.queryParameters && formik.touched.queryParameters ? (
                                                    <p className="text-light-50 text-xs">{formik.errors?.queryParameters}</p>
                                                ) : null}
                                            </div>
                                        )}
                                    </form>
                                </div>
                                {/* {formik?.values?.erp_type !== 1 && (
                            <Button
                                type="button"
                                onClick={handleOpenACustomApi}
                                title="Add New"
                                className="text-light-20"
                            >
                                <div className="mt-[30px] mr-[220px]">
                                    + Advanced Setting
                                </div>
                            </Button>
                        )} */}
                                <div className={`border-t-[1px] bg-${currentMode}-card  py-5 border-${currentMode}-590 mx-[-35px] mt-6`}>
                                    <div className="px-[35px] space-x-2 flex">
                                        {/* <Button
                                    title="To Test Connection"
                                    disabled={
                                        checkTestButton == true
                                            ? true
                                            : false || fetch
                                    }
                                    onClick={handleTestConfig}
                                    className="bg-light-240 flex text-base font-interR py-[.5rem] px-[2.24rem] rounded-[8px] text-light-40 closeModal"
                                >
                                    Test
                                </Button> */}
                                        {RoleActTargetAdd?.isallowed === true ? (
                                            <Button
                                                // disabled={
                                                //     fetching == true ||
                                                //     (formik.values.erp_type !== 1 &&
                                                //         testedArray == true)
                                                //         ? true
                                                //         : false ||
                                                //           checkTestButton == true
                                                //         ? false
                                                //         : true
                                                // }
                                                disabled={fetching == true}
                                                // type="submit"
                                                title="Save"
                                                className={`bg-light-20 flex text-base font-interR py-[.5rem] px-[2.24rem] rounded-[8px] text-white`}>
                                                Save
                                            </Button>
                                        ) : (
                                            ""
                                        )}
                                        <Button
                                            type="button"
                                            title="To Cancel"
                                            onClick={cartClose}
                                            className="bg-light-240 flex text-base font-interR py-[.5rem] px-[2.24rem] rounded-[8px] text-light-40 closeModal">
                                            {RoleActTargetAdd?.isallowed === true ? "Cancel" : "Close"}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            )}
            {ReactDOM.createPortal(
                <CustomApiModal
                    setCustomApiData={setCustomApiData}
                    isOpened={openCustomApi}
                    handleClose={() => setOpenCustomApi(false)}
                    className={`${openCustomApi && "Show"}`}
                />,
                document.getElementById("user-modal-root")
            )}
            {ReactDOM.createPortal(
                <ApiTestingModal
                    reqObj={reqObj}
                    testedArray={testedArray}
                    setTestedArray={setTestedArray}
                    setOpenTestingModal={setOpenTestingModal}
                    isOpened={openTestingModal}
                    handleClose={() => setOpenTestingModal(false)}
                    className={`${openTestingModal && "Show"}`}
                />,
                document.getElementById("user-modal-root")
            )}
        </>
    );
};
export default ServerConfigModal;
