import { Autocomplete, Checkbox, TextField, Paper, MenuItem, Select, FormControl } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import Button from "../../components/Button";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import Modal from "../../components/UI/Modal/Modal";
import { useDispatch } from "react-redux";
import { CloneExceptionAction, GetFromOrgDropdownAct } from "../../redux/actions/exception-automation";
import Swal from "sweetalert2";
import { CLONE_EXCEPTION_AUTOMATION_RESET } from "../../redux/types/types";

const CloneConfig = (props) => {
    const { currentMode } = useContext(ThemeContext);
    const orgId = localStorage.getItem("orgId") || 0;
    const orgData = useSelector((state) => state?.ListorganisationDropDownState);
    const orgFromData = useSelector((state) => state?.ExceptionOrgDropdownState);
    const Clonedata = useSelector((state) => state?.CloneExceptionState);
    const Errormsg = Clonedata?.error?.response?.data?.message;
    const FromOrgList = orgFromData?.data?.filter((el) => el?.isconfig == true);
    const ToOrgList = orgFromData?.data?.filter((el) => el?.isconfig == false);

    const dataList = orgData?.viewData;
    const [toOrgs, setToOrg] = useState([]);
    const dispatch = useDispatch();

    // const [from_org, setFromOrg] = useState([]);
    const [CloneState, setCloneState] = useState({
        from_org: +orgId,
        to_org: [],
        ischild: false,
        ispreferences: true,
        isemailsettings: true,
        isemailcommunication: true,
        isescalationandreminder: true,
    });

    const [IncludeOptions, setIncludeOptions] = useState([
        {
            id: 1,
            value: true,
            name: "Preferences",
            name1: "Preference",
        },
        {
            id: 2,
            value: true,
            name: "Email Settings",
            name1: "EmailSetting",
        },
        {
            id: 3,
            value: true,
            name: "Email Communication",
            name1: "EmailCom",
        },
        {
            id: 4,
            value: true,
            name: "Escalation and Reminder",
            name1: "Esc",
        },
        {
            id: 5,
            value: true,
            name: "Acknowledgement",
            name1: "Acknowledgement",
        },
    ]);

    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" || currentMode === "classic" ? "#ECECEC" : `#000`;
    const textColor = currentMode === "dark" ? "#ECECEC" : `#000`;
    const dropdownShadow = currentMode !== "dark" ? "0px 4px 20px rgba(238, 238, 238, 0.5)" : "";
    const dropdownBorder = currentMode !== "dark" ? "223, 225, 226, 0.56" : "";
    const statusReset = (filterList) => {
        return filterList?.map((item) => {
            return {
                ...item,
                isActive: false,
                value: item?.org_id,
            };
        });
    };
    const OrgList = dataList ? statusReset(dataList) : [];
    const FilterOrg = ToOrgList?.filter((el) => el?.org_id !== +CloneState?.from_org);
    const ToOrglist = FilterOrg ? statusReset(ToOrgList) : [];
    const FromOrgMap = FromOrgList?.map((el) => el?.org_id);
    const ToFilterOrgList = ToOrglist?.filter((el) => el?.id !== FromOrgMap);

    const handleChange = (value, naming, check) => {
        setCloneState((prevVal) => ({
            ...prevVal,
            [naming]: value,
        }));
    };
    const TohandleChange = (event, newValue) => {
        setToOrg(newValue);
        setCloneState((prevVal) => ({
            ...prevVal,
            ["to_org"]: newValue?.map((el) => el?.org_id),
        }));
    };
    const Submit = () => {
        if (CloneState?.to_org?.length == 0) {
            Swal.fire({
                text: "Select an organization to clone with.",
                icon: "error",
                closeOnClickOutside: false,
                showCloseButton: true,
                button: "Ok",
            });
        } else {
            dispatch(CloneExceptionAction(CloneState));
        }
    };
    const handleClose = () => {
        props?.handleClose();
        setToOrg([]);
        setCloneState({
            from_org: +orgId,
            to_org: [],
            ischild: false,
            ispreferences: true,
            isemailsettings: true,
            isemailcommunication: true,
            isescalationandreminder: true,
        });
    };
    useEffect(() => {
        dispatch(GetFromOrgDropdownAct());
    }, [dispatch]);
    if (Clonedata?.requestComplete && Clonedata?.data?.status) {
        Swal.fire({
            closeOnClickOutside: false,
            showCloseButton: true,
            text: "Cloned successfully!",
            icon: "success",
            buttons: {
                confirm: "Confirm",
            },
        }).then((confirm) => {
            if (confirm) {
                dispatch({ type: CLONE_EXCEPTION_AUTOMATION_RESET });
                dispatch(GetFromOrgDropdownAct());
                handleClose();
            }
        });
    } else if (Errormsg) {
        Swal.fire({
            closeOnClickOutside: false,
            showCloseButton: true,
            text: `${Errormsg}`,
            icon: "warning",
            buttons: {
                confirm: "Confirm",
            },
        }).then((confirm) => {
            if (confirm) {
                dispatch({ type: CLONE_EXCEPTION_AUTOMATION_RESET });
            }
        });
    }

    return (
        <>
            <Modal cartClose={handleClose} center="center" overlap={true} className={`${props?.className}`}>
                <div
                    className={`w-[490px] mx-auto relative bg-${currentMode}-card  border-${currentMode}-700 text-${currentMode}-copy  mx-auto rounded-[8px] ${
                        currentMode !== "dark" ? " shadow-[0px_4px_20px_rgba(238,238,238,0.5)]" : ""
                    }`}
                >
                    <div className={`w-full bg-${currentMode}-1420 relative h-[49px] text-${currentMode}-copy  mx-auto`}>
                        <span className="flex px-[19px] py-[15px]">
                            <span className=" font-medium text-base	">Clone Exception</span>
                        </span>
                        <span onClick={handleClose} title="Close" className="cursor-pointer absolute right-[20px] top-[20px]">
                            <img src="/images/common/closeBlk.svg" className="convertWhite" alt="closeBlk" />
                        </span>
                    </div>
                    <div className="p-[20px]">
                        <div className="flex  mb-[21px] space-x-2 viewStyles">
                            <div className="basis-1/2">
                                <label className="block text-sm pb-3 font-medium font-interM">
                                    <span className="text-[red] mr-1 ">*</span>From Organization:
                                </label>
                                <FormControl>
                                    <Select
                                        value={CloneState?.from_org || 0}
                                        onChange={(e) => handleChange(e?.target?.value, "from_org")}
                                        MenuProps={{
                                            sx: {
                                                "& .MuiMenu-paper": {
                                                    backgroundColor: dropdownColor,
                                                    color: textColor,
                                                    maxWidth: "24rem",
                                                    // top: "60px !important",
                                                    // maxHeight: "37rem",
                                                },
                                            },
                                        }}
                                        IconComponent={() => (
                                            <img
                                                className="absolute opacity-[.5] right-[10px] pointer-events-none"
                                                src={
                                                    currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`
                                                }
                                            />
                                        )}
                                        SelectDisplayProps={{
                                            style: {
                                                paddingTop: 1,
                                                width: "100%",
                                                maxWidth: 230,
                                                paddingBottom: 1,
                                                // color: dropdownText,
                                                paddingRight: "30px",
                                            },
                                        }}
                                    >
                                        <MenuItem value={0}>Select an Organization</MenuItem>
                                        {FromOrgList &&
                                            FromOrgList?.map((elem, i) => {
                                                return <MenuItem value={elem?.org_id}>{elem?.org_name}</MenuItem>;
                                            })}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="basis-1/2">
                                <label className="block text-sm pb-3 font-medium font-interM">
                                    <span className="text-[red] mr-1">*</span> To Organization:
                                </label>
                                <div className="filter">
                                    <Autocomplete
                                        multiple
                                        disabled={CloneState?.from_org == 0}
                                        clearOnBlur={false}
                                        id="checkboxes-tags-demo"
                                        options={ToOrglist || []}
                                        limitTags={1}
                                        disableCloseOnSelect
                                        PaperComponent={({ children }) => (
                                            <Paper
                                                style={{
                                                    backgroundColor: dropdownColor,
                                                    color: dropdownText,
                                                    boxShadow: dropdownShadow,
                                                    border: dropdownBorder,
                                                }}
                                            >
                                                {children}
                                            </Paper>
                                        )}
                                        popupIcon={<img className="m-0 pointer-events-none" src="/images/header/chevronDown.svg" />}
                                        sx={{
                                            "& .MuiAutocomplete-popper": {
                                                backgroundColor: "white",
                                                boxShadow: dropdownShadow,
                                                border: "1px solid rgba(223, 225, 226, 0.56)",
                                                borderRadius: "8px",
                                            },
                                            "& .MuiMenuItem-root:hover": {
                                                backgroundColor: "rgba(218, 249, 244, 0.3)",
                                            },
                                            "& .Mui-selected": {
                                                backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                            },
                                            "& .MuiAutocomplete-popupIndicator": {
                                                padding: 0,
                                                marginTop: "5px",
                                                marginRight: "10px",
                                            },
                                            "& .MuiAutocomplete-popupIndicatorOpen": {
                                                padding: 0,
                                                marginRight: "10px",
                                            },
                                            "& .MuiChip-label": {
                                                color: `${currentMode === "dark" ? "white" : "black"}`,
                                            },
                                        }}
                                        getOptionLabel={(status) => status?.org_name}
                                        isOptionEqualToValue={(option, value) => option?.org_id === value?.org_id}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    size="small"
                                                    sx={{
                                                        color: "rgba(85,85,85,0.7)",
                                                        borderRadius: "3px !important",
                                                        "&.Mui-checked": {
                                                            color: "#028C78",
                                                        },
                                                    }}
                                                    value={option?.org_id}
                                                    checked={selected}
                                                />
                                                {option?.org_name}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                sx={{
                                                    "& .MuiInputBase-input": {
                                                        color: `${currentMode === "dark" ? "white" : "black"} !important`,
                                                    },
                                                }}
                                                {...params}
                                                fullWidth
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: null,
                                                }}
                                                placeholder="Select an Organization"
                                            />
                                        )}
                                        onChange={TohandleChange}
                                        value={toOrgs}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="basis-1/2 font-interM">
                            <>
                                <Checkbox
                                    size="small"
                                    checked={CloneState?.ischild}
                                    onChange={(e) => handleChange(e?.target?.checked, "ischild")}
                                    sx={{
                                        color: "rgba(85,85,85,0.7)",
                                        "&.Mui-checked": {
                                            color: "#028C78",
                                        },
                                    }}
                                />
                                {"Clone with Child"}
                            </>
                        </div>
                        <div className="basis-1/2">
                            <label className="block text-sm pb-3 font-medium font-interM">
                                <span className="text-[red] mr-1">*</span> Include Options:
                            </label>
                            <hr style={{ borderColor: "#ECECEC" }} className="mt-[-5px]"></hr>

                            <div>
                                <Checkbox
                                    checked={CloneState?.ispreferences}
                                    disabled={true}
                                    onChange={(e) => handleChange(e?.target?.checked, "ispreferences")}
                                    size="small"
                                    sx={{
                                        color: "rgba(85,85,85,0.7)",
                                        "&.Mui-checked": {
                                            color: "#028C78",
                                        },
                                    }}
                                />
                                {"Preference"}
                            </div>
                            <div>
                                <Checkbox
                                    checked={CloneState?.isemailsettings}
                                    disabled={true}
                                    onChange={(e) => handleChange(e?.target?.checked, "isemailsettings")}
                                    size="small"
                                    sx={{
                                        color: "rgba(85,85,85,0.7)",
                                        "&.Mui-checked": {
                                            color: "#028C78",
                                        },
                                    }}
                                />
                                {"Email Setting"}
                            </div>
                            <div>
                                <Checkbox
                                    checked={CloneState?.isemailcommunication}
                                    onChange={(e) => handleChange(e?.target?.checked, "isemailcommunication")}
                                    size="small"
                                    sx={{
                                        color: "rgba(85,85,85,0.7)",
                                        "&.Mui-checked": {
                                            color: "#028C78",
                                        },
                                    }}
                                />
                                {"Email Communication"}
                            </div>
                            <div>
                                <Checkbox
                                    checked={CloneState?.isescalationandreminder}
                                    onChange={(e) => handleChange(e?.target?.checked, "isescalationandreminder")}
                                    size="small"
                                    sx={{
                                        color: "rgba(85,85,85,0.7)",
                                        "&.Mui-checked": {
                                            color: "#028C78",
                                        },
                                    }}
                                />
                                {"Escalation and Reminder"}
                            </div>
                        </div>
                        <div className="flex  mt-[40px] space-x-2">
                            <Button
                                title="Save"
                                className="bg-light-20 px-[25px] text-base py-2 text-white rounded-[8px]"
                                type="submit"
                                onClick={Submit}
                                tabIndex="3"
                                disabled={CloneState?.from_org == 0}
                            >
                                Clone
                            </Button>
                            <Button
                                title="Cancel"
                                className="bg-light-240  text-light-40 px-[28px] text-base py-2 rounded-[8px]"
                                onClick={handleClose}
                                tabIndex="3"
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default CloneConfig;
