import { Checkbox, ClickAwayListener, FormControlLabel, MenuItem, Popper, Select } from "@mui/material";
import _, { remove, upperCase } from "lodash";
import { useContext, useEffect, useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/Button";
import Loader from "../../components/Loader";
import Card from "../../components/UI/Card";
import ExportExcel from "../ExportFiles/Excel";
import TogglePopup from "../organisations/Toggle";
import { decryptId, encryptId } from "../../utils/helpers";
import { getCurrentUser } from "../../redux/actions/auth";
import swal from "sweetalert";

import { getReportsTaskList, getReportTaskCount } from "../../redux/actions/report.js";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { Link, useLocation } from "react-router-dom";
let exportArr = [];
const ReportTaskTable = (props) => {
    const { currentMode } = useContext(ThemeContext);
    let optionval = ["Task Id", "Task Name", "Invoice No.", "Created By", "Assignees", "Due date", "Status"];
    const [addedInvoice, setAddedInvoice] = useState([]);
    const [values, setValues] = useState(optionval);
    const [showOptions, setShowOptions] = useState("");
    const [isdownloadClick, onClickDownload] = useState(false);
    const [exportFile, setExportFile] = useState(false);
    const selAging = props?.selAging;
    const location = useLocation();
    const [popstate, setPopState] = useState({
        value: null,
        anchorEl: null,
        id: null,
    });
    const [placement, setPlacement] = useState();
    const { dataList: data, fetching, requestComplete, exportData } = useSelector((state) => state?.ReportsTaskListState);
    const handleClickAway = () => {
        setPopState({ id: false, anchorEl: false, value: "" });
    };
    const handleClick = (newPlacement, value, event, id = null) => {
        setPopState({ value, anchorEl: event.currentTarget, id }, (prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };
    const checkChange = (e) => {
        const elem = e.target.value;
        if (!e.target.checked) {
            const Remove = values?.filter((el) => el !== elem);
            setValues(Remove);
            localStorage.setItem("taskReportOption", JSON.stringify(values));
        } else {
            setValues([...values, elem]);
            localStorage.setItem("taskReportOption", JSON.stringify(values));
        }
    };

    useEffect(() => {
        let Check = JSON.parse(localStorage.getItem("taskReportOption"));
        if (Check !== null && Check !== undefined && Check !== "") {
            setValues(JSON.parse(localStorage.getItem("taskReportOption")));
        } else {
            localStorage.setItem("taskReportOption", JSON.stringify(optionval));
            setValues(optionval);
        }
    }, []);
    useEffect(() => {
        if (!localStorage.getItem("taskReportOption")) {
            localStorage.setItem("taskReportOption", JSON.stringify(values));
        }
        localStorage.setItem("taskReportOption", JSON.stringify(values));
    }, [values]);

    const [isAllSelected, setAllSelected] = useState(false);
    const [isIndeterminate, setIndeterminate] = useState(false);
    const [isSorting, setSorting] = useState(0);
    const allocid = localStorage.getItem("UserDropdownId");
    const pageNo = props?.currentPageNo;
    const entries = props?.currentPageItem;
    const pageCount = props?.listCount && props?.listCount ? props?.listCount?.func_get_reports_taskcount : 0;
    let start = (pageNo - 1) * entries + 1;

    const nextPageCount = Math.ceil(pageCount / props?.currentPageItem);

    const dispatch = useDispatch();

    const exportRef = useRef();
    const onExportItems = (value, id) => {};
    const ExportList = [
        {
            text: "Excel",
            title: "To allow Organization Access",
            src: "/images/common/excel.svg",
            value: 0,
        },
        {
            text: "CSV",
            title: "To allow Organization Access",
            src: "/images/common/excel.svg",
            value: 1,
        },
        {
            text: "PDF",
            title: "To View the Organization Access",
            src: "/images/common/excel.svg",
            value: 2,
        },
        {
            text: "JPG",
            title: "To View the Organization Access",
            src: "/images/common/excel.svg",
            value: 4,
        },
    ];

    const onFilterItems = (value, id) => {
        setShowOptions(value);
    };
    const filterMenuList = ["Task Id", "Task Name", "Invoice No.", "Assignees", "Due date", "Created By", "Status", "Priority"];

    const CheckLineItems = JSON.parse(localStorage.getItem("taskReportOption"));
    const selectedRow = data?.filter((el) => el?.checked == true);
    const downloadeExcel = () => {
        if (exportData) {
            exportData?.forEach((invoice) => {
                invoice.checked = true;
            });
            const invoiceExcelData = [];
            exportData
                ?.filter((el) => el?.checked == true)
                ?.forEach((item) => {
                    let invHeaders = {
                        [CheckLineItems.includes("Task Id") && "Task Id"]: CheckLineItems.includes("Task Id") && item?.task_no,
                        [CheckLineItems.includes("Task Name") && "Task Name"]: CheckLineItems.includes("Task Name") && item?.task_name,
                        [CheckLineItems.includes("Invoice No.") && "Invoice No."]: CheckLineItems.includes("Invoice No.") && item?.invoice_no,
                        [CheckLineItems.includes("Created By") && "Created By"]: CheckLineItems.includes("Created By") && item?.created_user,
                        [CheckLineItems.includes("Assignees") && "Assignees"]: CheckLineItems.includes("Assignees") && item?.assignee_name,
                        [CheckLineItems.includes("Due date") && "Due date"]: CheckLineItems.includes("Due date") && item?.due_date,
                        [CheckLineItems.includes("Priority") && "Priority"]: CheckLineItems.includes("Priority") && item?.task_priority,
                        [CheckLineItems.includes("Status") ? "Status" : ""]: CheckLineItems.includes("Status")
                            ? item?.task_status == 1
                                ? "In Progress"
                                : item?.task_status == 2
                                ? "Completed"
                                : item?.task_status == 3
                                ? "Done"
                                : item?.task_status == 4
                                ? "Assign"
                                : ""
                            : "",
                    };
                    invoiceExcelData.push(_.pickBy(invHeaders));
                });
            ExportExcel({ excelData: invoiceExcelData, fileName: "Task Reports" });
            onClickDownload(false);
            setAllSelected(false);
            setIndeterminate(false);
            setExportFile(false);
            data?.forEach((item) => {
                return (item.checked = false);
            });
        }
    };
    const onExportExcel = () => {
        setExportFile(true);
        if (data !== null) {
            dispatch(getReportsTaskList(props?.user, props?.taskType, props?.taskStatus, 1, pageCount, props?.invNumber, "download"));
        }

        if (exportData?.length > 0 && exportFile) {
            onClickDownload(true);
        }
    };
    useEffect(() => {
        if (exportData?.length > 0 && exportFile) {
            onClickDownload(true);
        }
    }, [exportData?.length > 0]);
    if (isdownloadClick) {
        let downloadInvoiceList = exportData;
        downloadeExcel(downloadInvoiceList);
    } else if (isdownloadClick) {
        let downloadInvoiceList = exportData;
        downloadeExcel(downloadInvoiceList);
    }
    const onSelect = (event) => {
        const index = data.findIndex((item) => item.task_id === event.target.name);
        const updatedArray = addedInvoice;
        let getListObjIndex = updatedArray[index];
        getListObjIndex.checked = event.target.checked;

        let newArr = data.map(function (a, _index) {
            return _index === index ? getListObjIndex : a;
        });
        setAddedInvoice(newArr);
        // change all select checkbox
        if (data.every((invoice) => invoice.checked)) {
            setAllSelected(true);
            setIndeterminate(false);
        } else if (data.every((invoice) => !invoice.checked)) {
            setAllSelected(false);
            setIndeterminate(false);
        } else {
            setIndeterminate(true);
        }
    };
    const ExportToExcelAgeing = () => {
        if (data?.length > 0) {
            onClickDownload(true);
        }
    };
    const CountCalc = pageCount / props?.currentPageItem;
    const Rounded = Math.ceil(CountCalc);
    const [sortColumn, columnSorting] = useState("");
    const onPageNumberSelect = (event) => {
        let pageNumber = event.target.value;
        props?.changePageNo(parseInt(pageNumber));
    };

    const onSelectAll = (event) => {
        setAllSelected(event.target.checked);
        setIndeterminate(false);

        setAddedInvoice(data);
    };

    const onPageItemSelect = (event) => {
        setAllSelected(false);
        let pageItemVal = event.target.value;
        props?.changePageItem(pageItemVal);
        props?.changePageNo(1);
        dispatch(getReportsTaskList(props?.user, props?.taskType, props?.taskStatus, 1, pageItemVal, props?.invNumber));
        dispatch(getReportTaskCount(props?.user, props?.taskType, props?.taskStatus, props?.invNumber));
    };

    useEffect(() => {
        if (!fetching && requestComplete) {
            data?.forEach((item) => {
                return (item.checked = false);
            });
            setAddedInvoice(data);
            // fetching = true;
        }
    }, [fetching]);
    let EmptyArr = [];
    for (let i = 0; i < Rounded; i++) {
        EmptyArr.push(i);
    }
    const previousBtnClick = () => {
        if (isAllSelected === true) {
            setAllSelected(false);
        }
        if (isIndeterminate == true) {
            setIndeterminate(false);
        }
        props?.changePageNo(props?.currentPageNo - 1);
    };
    const nextBtnClick = () => {
        if (isAllSelected === true) {
            setAllSelected(false);
        }
        if (isIndeterminate == true) {
            setIndeterminate(false);
        }
        props?.changePageNo(props?.currentPageNo + 1);
    };
    useEffect(() => {
        dispatch(getReportsTaskList(props?.user, props?.taskType, props?.taskStatus, props?.currentPageNo, props?.currentPageItem, props?.invNumber));
        dispatch(getReportTaskCount(props?.user, props?.taskType, props?.taskStatus, props?.invNumber));
    }, [props?.currentPageNo]);
    const onSortingIconChange = () => {
        if (requestComplete) {
            if (isSorting === 1) {
                document.getElementById(sortColumn).classList.add("Up");
            } else {
                document.getElementById(sortColumn).classList.remove("Up");
            }
        }
    };

    let totalPageCount = data ? data.length * (props?.currentPageNo ? props?.currentPageNo : 1) : 0;
    const totalPageExceed = props?.currentPageNo * props?.currentPageItem;
    if (totalPageExceed >= pageCount) {
        totalPageCount = pageCount;
    }
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const dropdownShadow = currentMode !== "dark" ? "0px 4px 20px rgba(238, 238, 238, 0.5)" : "";
    const dropdownBorder = currentMode !== "dark" ? "223, 225, 226, 0.56" : "";
    const DisplayRole = useSelector((state) => state?.DisplayRolePermissionStateAction);
    const { data: DataList } = DisplayRole;
    const RoleList = DataList?.role_info;
    const RoleMenu = RoleList?.menu;
    const RoleProcess = RoleMenu?.find((elem) => elem?.menu_id === 8);
    const RoleProcessAction = RoleProcess?.actions;
    const RoleActProcessView = RoleProcessAction?.find((element) => element.id === 3);
    const ViewOpen = RoleActProcessView?.isallowed;
    return (
        <>
            <Card className="mb-[4rem] ">
                <div className="flex justify-between px-[30px] py-[20px]">
                    <div className="flex my-auto">
                        <div className="">
                            <span>Show</span>
                            <Select
                                name="entrycount"
                                className={`insideTble mx-3 text-xs entries bg-${currentMode}-card`}
                                value={props?.currentPageItem}
                                onChange={(e) => onPageItemSelect(e)}
                                IconComponent={() => (
                                    <img
                                        className="absolute right-[8px] opacity-50 pointer-events-none"
                                        src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                        alt="chevronDown"
                                    />
                                )}
                                SelectDisplayProps={{
                                    style: {
                                        paddingTop: "5px",
                                        display: "flex",
                                        paddingBottom: "5px",
                                        paddingLeft: "10px",
                                        border: 0,
                                        color: currentMode === "dark" ? `#ECECEC` : `#555555`,
                                        paddingRight: "25px",
                                    },
                                }}
                                MenuProps={{
                                    sx: {
                                        "& .MuiMenu-paper": {
                                            backgroundColor: dropdownColor,
                                            color: dropdownText,
                                            boxShadow: dropdownShadow,
                                            border: dropdownBorder,
                                            borderRadius: "8px",
                                        },
                                    },
                                }}>
                                <MenuItem value={10}>{10}</MenuItem>
                                <MenuItem value={15}>{15}</MenuItem>
                                <MenuItem value={20}>{20}</MenuItem>
                                <MenuItem value={25}>{25}</MenuItem>
                                <MenuItem value={50}>{50}</MenuItem>
                                <MenuItem value={75}>{75}</MenuItem>
                                <MenuItem value={100}>{100}</MenuItem>
                            </Select>
                            <span>entries</span>
                        </div>
                    </div>
                    {data?.length !== 0 && (
                        <div className="flex items-center space-x-2">
                            <div>
                                <Button
                                    title="To Export Invoice to Excel"
                                    onClick={selAging == 1 ? () => ExportToExcelAgeing() : () => onExportExcel()}
                                    className={`border-20 export-step px-5 py-2 rounded-[8px] font-interM text-sm flex justify-center items-center border-[1px] border-20 export-step px-5 py-2 rounded-[8px] font-interM text-sm flex justify-center items-center border-[1px] text-${currentMode}-copy border-light-20`}>
                                    <img className="mr-[.4rem]" src="/images/common/download-excel.svg" alt="upload" /> Download
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
                <div className="heightFix invoices relative report">
                    <table className={`table-auto TblNormal invoice reports overflow-y-auto w-full`}>
                        <thead className="sticky top-0 z-[1]">
                            <tr className={`bg-${currentMode}-450`}>
                                {values?.includes("Task Id") ? <th>Task Id </th> : ""}
                                {values?.includes("Task Name") ? <th>Task Name </th> : ""}
                                <th></th>
                                {values?.includes("Invoice No.") ? (
                                    <th>
                                        <span className="font-interM flex">
                                            {/* <img
                                                src="/images/invoice/calendar.svg"
                                                className="mr-2"
                                                alt="calendar"
                                            />{" "} */}
                                            Invoice Number
                                        </span>{" "}
                                    </th>
                                ) : (
                                    ""
                                )}
                                {values?.includes("Created By") ? (
                                    <th>
                                        {" "}
                                        <span className="font-interM flex">
                                            <img className="mr-2 convertWhite" src="/images/invoice/user-plusblk.svg" alt="user-plusblk" />
                                            Created By{" "}
                                        </span>
                                    </th>
                                ) : (
                                    ""
                                )}
                                {values?.includes("Assignees") ? (
                                    <th>
                                        {" "}
                                        <span className="font-interM flex">
                                            <img className="mr-2 convertWhite" src="/images/invoice/user-plusblk.svg" alt="user-plusblk" />
                                            Assignees
                                        </span>
                                    </th>
                                ) : (
                                    ""
                                )}
                                {values?.includes("Due date") ? (
                                    <th>
                                        <span className="font-interM flex">
                                            <img src="/images/invoice/calendar.svg" className="mr-2 convertWhite" alt="calendar" /> Due Date
                                        </span>
                                    </th>
                                ) : (
                                    ""
                                )}
                                {values?.includes("Priority") ? (
                                    <th>
                                        {" "}
                                        <span className="font-interM flex">
                                            <img className="mr-2 convertWhite" src="/images/main/priority-icon.svg" alt="user-plusblk" />
                                            Priority{" "}
                                        </span>{" "}
                                    </th>
                                ) : (
                                    ""
                                )}
                                {values?.includes("Status") ? <th>Status </th> : ""}

                                <th>
                                    <img
                                        className="cursor-pointer max-w-[inherit] convertWhite"
                                        onClick={(e) => handleClick("bottom-end", 1, e)}
                                        src="/images/main/columns.svg"
                                        alt="columns"
                                    />
                                </th>
                            </tr>
                        </thead>
                        {fetching ? (
                            <div className="grid place-items-center absolute left-[50%] translate-x-[-50%]">
                                <Loader className="h-[calc(100vh_-_26rem)] w-full" />
                            </div>
                        ) : (
                            <>
                                <tbody>
                                    {data?.length !== 0 ? (
                                        addedInvoice?.map((elem, index) => {
                                            return (
                                                <tr key={index} className={`h-14 border-b-[1px] border-${currentMode}-700`}>
                                                    <td>{elem.task_no}</td>
                                                    <td>
                                                        <a className="truncate w-[150px] cursor-default" title={elem?.task_name}>
                                                            {elem?.task_name}
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <Button
                                                            className={`border-[1px]  whitespace-nowrap cursor-default  px-2 rounded-[5px] my-auto mx-auto text-[12px] border-transparent text-white ${
                                                                elem?.task_type === 1 ? "bg-[rgba(94,101,174,1)]" : "bg-[rgba(1,148,186,1)]"
                                                            }`}>
                                                            {elem?.task_type === 1 ? "Internal" : "External"}
                                                        </Button>
                                                    </td>

                                                    <td className={`${!ViewOpen ? "pointer-events-none" : ""}`}>
                                                        <Link
                                                            state={{ from: location.pathname }}
                                                            to={`${"/invoice-view?"}${encryptId(
                                                                `{"inv":${+elem?.invoice_id},"type":${7},"seq":"${+elem?.seq_no}","status":${
                                                                    elem?.ext_status
                                                                }}`
                                                            )}
                                                           `}
                                                            style={{
                                                                textDecoration: "underline",
                                                            }}
                                                            target="_blank"
                                                            rel="noopener noreferrer">
                                                            {elem?.invoice_no}
                                                        </Link>
                                                    </td>

                                                    <td>{elem?.created_user}</td>

                                                    <td>{elem?.assignee_id == null ? decryptId(elem?.assignee_name) : elem?.assignee_name}</td>
                                                    <td>{elem?.due_date}</td>
                                                    {values?.includes("Priority") ? (
                                                        <td>
                                                            <Button
                                                                className={`border-[0] font-interM rounded-[2px] text-[12px] px-2 text-white ${
                                                                    elem?.task_priority === "High"
                                                                        ? "bg-[rgba(255,114,94,1)]"
                                                                        : elem?.task_priority === "Low"
                                                                        ? "bg-[rgba(0,177,162,1)]"
                                                                        : elem?.task_priority === "Medium"
                                                                        ? "bg-[rgba(251,189,3,1)]"
                                                                        : elem?.task_priority === "Urgent"
                                                                        ? "bg-[rgba(215,13,13,1)]"
                                                                        : ""
                                                                }`}>
                                                                {elem?.task_priority}
                                                            </Button>
                                                        </td>
                                                    ) : (
                                                        ``
                                                    )}

                                                    {values?.includes("Status") ? (
                                                        <td>
                                                            <p>
                                                                {elem?.task_status == 1
                                                                    ? "In Progress"
                                                                    : elem?.task_status == 2
                                                                    ? "Completed"
                                                                    : elem?.task_status == 3
                                                                    ? "Done"
                                                                    : "Assign"}
                                                            </p>
                                                        </td>
                                                    ) : (
                                                        ""
                                                    )}

                                                    <td></td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <div className="absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]">
                                            <img src="/images/common/delivery.png" alt="delivery" />
                                            <h4 className="font-interSb text-center mt-3">No data found</h4>
                                        </div>
                                    )}
                                </tbody>
                            </>
                        )}
                    </table>
                </div>

                {/* {addedInvoice?.length !== 0 && ProcessInv?.dataList !== null && ( */}
                <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClickAway}>
                    <Popper
                        className={`bg-${currentMode}-card text-${currentMode}-copy  p-4 border-[1px] z-[12] border-${currentMode}-700  shadow-[0px_4px_20px_rgba(238,238,238,0.5)] rounded-[10px] reportsPopper `}
                        open={1 === popstate.value}
                        anchorEl={popstate.anchorEl}
                        id={1}
                        placement={placement}>
                        <ul>
                            {filterMenuList?.map((el, i) => {
                                return (
                                    <li
                                        key={i}
                                        className={`transition duration-300 ease-in-out mx-[-17px]  py-1 px-[20px] hover:bg-${currentMode}-960`}>
                                        <Button className={`flex ${1} items-center justify-between font-interR`}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        size="small"
                                                        value={el}
                                                        onChange={checkChange}
                                                        disabled={!Boolean(optionval?.indexOf(el) === -1)}
                                                        defaultChecked={!Boolean(values?.indexOf(el) === -1)}
                                                        sx={{
                                                            padding: 0,
                                                            color: "#008785",
                                                            margin: "0 12px",
                                                            "&.Mui-checked": {
                                                                color: "#008785",
                                                            },
                                                        }}
                                                    />
                                                }
                                                label={el}
                                            />
                                        </Button>
                                    </li>
                                );
                            })}
                        </ul>
                    </Popper>
                </ClickAwayListener>
                <TogglePopup ref={exportRef} menuList={ExportList} selectedItemChange={onExportItems} />
                {data?.length !== 0 && (
                    <div className={`bg-${currentMode}-460 flex justify-between items-center py-[10px] px-[30px]`}>
                        <div>
                            <div>
                                Showing <span>{`${start} to ${totalPageCount}`}</span> entries of <span>{pageCount}</span>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <span>The page you’re on</span>
                            <Select
                                name=""
                                className={`insideTble  bg-${currentMode}-card mx-3 text-xs entries`}
                                id=""
                                value={props?.currentPageNo}
                                onChange={(e) => onPageNumberSelect(e)}
                                IconComponent={() => (
                                    <img
                                        className="absolute opacity-50 right-[8px] pointer-events-none"
                                        src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                        alt="chevronDown"
                                    />
                                )}
                                SelectDisplayProps={{
                                    style: {
                                        paddingTop: "5px",
                                        display: "flex",
                                        paddingBottom: "5px",
                                        paddingLeft: "10px",
                                        border: 0,
                                        color: currentMode === "dark" ? `#ECECEC` : `#555555`,
                                        paddingRight: "25px",
                                    },
                                }}
                                MenuProps={{
                                    sx: {
                                        "& .MuiMenu-paper": {
                                            backgroundColor: dropdownColor,
                                            color: dropdownText,
                                            boxShadow: dropdownShadow,
                                            border: dropdownBorder,
                                            borderRadius: "8px",
                                        },
                                    },
                                }}>
                                {EmptyArr?.map((elem) => (
                                    <MenuItem value={elem + 1}>{elem + 1}</MenuItem>
                                ))}
                            </Select>
                            <div className={`flex space-x-2 border-l-[1px] pl-3 border-${currentMode}-830`}>
                                <Button
                                    title="Previous page"
                                    disabled={props?.currentPageNo > 1 ? false : true}
                                    onClick={() => previousBtnClick()}
                                    className={`border-${currentMode}-840 border-[1px] rounded-[5px] bg-${currentMode}-card px-2 py-3`}>
                                    <img className="rotate-180 convertWhite" src="/images/common/arrowRight.svg" alt="arrowRight" />
                                </Button>

                                <Button
                                    title="Next page"
                                    className={`border-${currentMode}-840 border-[1px] rounded-[5px] bg-${currentMode}-card px-2 py-3`}
                                    disabled={props?.currentPageNo >= nextPageCount ? true : false}
                                    onClick={() => nextBtnClick()}
                                    value={props?.currentPageNo}>
                                    <img className="convertWhite" src="/images/common/arrowRight.svg" alt="arrowRight" />
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
                {/* )} */}
            </Card>
        </>
    );
};

export default ReportTaskTable;
