import { ClickAwayListener, Fade, FormControl, MenuItem, Popper, Select, TextField, Tooltip, Autocomplete, Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useContext } from "react";
import Swal from "sweetalert2";
import { Link, useLocation } from "react-router-dom";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import axios from "axios";
import _ from "lodash";
import Loader from "../../../components/Loader";
import authHeader from "../../../redux/actions/auth-header";
import { getPoNumberList, getSapVendorList, InvoiceGetDocumnet } from "../../../redux/actions/invoice";
import TaxTableLineItem from "../View/TaxTable";
import { useRef } from "react";
import Zoom from "@mui/material/Zoom";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { getCroppedImg } from "./imagePreview";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MessageBox from "../../../components/UI/Alert";
import moment from "moment";
import { getCurrentUser } from "../../../redux/actions/auth";
import { GET_INVOICE_TASK_LIST_RESET, INVOICE_GET_RESET } from "../../../redux/types/types";
import IconButton from "@mui/material/IconButton";
import { decryptId, invoiceDataFetch } from "../../../utils/helpers";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import { NumericFormat } from "react-number-format";
import { InvTable } from "../View/InvTable";
import { postThreeWayMatching } from "../../../redux/actions/invoice";
import InputTrigger from "./inputTrigger";
import { GetAdvanceDetailsforCreate } from "../../../redux/actions/advance-request";

let updatedInvoiceList = [];
let dragMaxlength;
let imageResponse;
let _scale = 1;
const GetImageContent = async (param) => {
    const response = await axios
        .post(`${process.env?.REACT_APP_NODE_API_URL}invocieFileUpload`, param, {
            headers: authHeader(),
        })
        .then((response) => {
            return response;
        });
    return response;
};
const Content = (props) => {
    updatedInvoiceList = props?.updatedInvoiceList;
    const inputRef = useRef(null);
    const totalAmountVal = props?.totalAmountVal;
    const setTotalAmountVal = props?.setTotalAmountVal;
    const DocInv = useSelector((state) => state?.getInvoiceDocumentState);
    const DocView = DocInv?.dataList;
    const imageId = DocInv?.imageId;
    const { currentMode } = useContext(ThemeContext);
    let CropObject = props?.dragObject;
    const location = useLocation();
    const CurrentUser = getCurrentUser();
    const [searchShow, setSearchShow] = useState(false);
    const OrgDateFormat = localStorage.getItem("OrgDateFormat");
    const userFormat = localStorage.getItem("userDateFormat")?.toUpperCase();
    const minZoomLevel = 1;
    const [zoomLevel, setZoomLevel] = useState(minZoomLevel);
    function useImageZoom(maxZoomLevel = 2) {
        const zoomIn = () => setZoomLevel((zoom) => (zoom < maxZoomLevel ? zoom + 0.1 : zoom));
        const zoomOut = () => setZoomLevel((zoom) => Math.max(minZoomLevel, zoom - 0.1));
        const resetZoom = () => setZoomLevel(minZoomLevel);
        const zoomStyles = {
            transform: `scale(${zoomLevel})`,
            transformOrigin: "0px 0px",
        };
        const handlers = {
            zoomIn,
            zoomOut,
            resetZoom,
        };
        return [zoomStyles, handlers, zoomLevel];
    }
    const AccessIcon = () => <img src="/images/common/date-ico.svg" alt="date-ico" />;
    let listData = props.invoiceList ? props.invoiceList?.invoice_items : [];
    const findTaxAmount = props?.invoiceList?.tax_amount;

    const notConfirmedList = listData?.filter(
        (el) => el?.is_item == "0" && el?.label_name !== "tax" && (el?.label_value == null || el?.label_value == "")
    );
    let currentPage = 1;
    let invImageUrl = props?.invoiceList?.invoice_url?.[0]?.url;
    const lineItemHeader = props.invoiceList?.items_name;
    const nonPOItemList = props?.nonPOItemList;
    const dispatch = useDispatch();
    const popref = props?.popref;
    const [zoomStyles, handlers] = useImageZoom();
    const [currentIndex, onChangeIndex] = useState(currentPage);
    const [tableListItem, setTableListItem] = useState([]);
    const [crop, setCrop] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState();
    const [isClickImg, onInvoiceClick] = useState(true);
    const [anchorImgEl, setImgAnchorEl] = useState(null);
    const [focusLineItem, setFocusLineItem] = useState(null);
    const [openCropImgContent, setImgContentOpen] = useState(false);
    const [cropImgtext, setCropImgText] = useState("");
    const setFieldType = props?.setFieldType;
    const selectedFieldId = props?.selectedFieldId;
    const selectedFieldText = props?.selectedFieldText;
    const setFieldText = props?.setFieldText;
    const [imageUrl, setImageUrl] = useState(invImageUrl);
    const [imageResult, setImageUpdate] = useState(imageResponse);
    const [getUpdatedObjList, setUpdatedObjList] = useState([]);
    const [checkErr, setCheckErr] = useState("");
    const [lineItems, setLineItems] = useState();
    const [errorcheck, setErrorCheck] = useState({
        message: "",
    });
    const [findEditable, setFindEditable] = useState();
    const findObj = notConfirmedList?.find((item) => item?.id == selectedFieldId);
    const playInterval = localStorage.getItem("PlayInterval");
    useEffect(() => {
        if (playInterval == "undefined" || !playInterval || playInterval == "null") {
            localStorage.setItem("PlayInterval", 2);
        } else {
            props?.setTimerValue(playInterval);
        }
    }, [playInterval]);
    useEffect(() => {
        if (notConfirmedList?.length !== 0 && selectedFieldId) {
            const findObj = listData?.find((item) => item?.id == selectedFieldId);
            setFindEditable(findObj?.iseditable);
            handleFieldType(findObj?.data_type);
        }
    }, [selectedFieldId]);
    const [valids, setValids] = useState(false);
    const [isField, setIsField] = useState(false);
    const [cropImgDate, setCropImgdate] = useState("");
    const [addRow, setAddRow] = useState(true);
    const [currentItem, setCurrentSelectedItem] = useState({
        label_name: null,
        label_value: null,
        id: null,
        data_type: null,
        iseditable: null,
        is_confirmed: null,
        maxlength: null,
    });
    const [checking, setChecking] = useState({
        mandatory: false,
        maxlength: null,
        data_type: null,
    });
    const docType = props.invoiceList?.document_type;
    const isPO = props.invoiceList?.document_id;
    const docNumber = props.invoiceList?.document_no;
    const InvNumber = props.invoiceList?.inv_seqno;
    const { data: parseData, error: ERR, requestComplete: reqComp, fetching: fetch } = useSelector((state) => state?.addServiceLineItemsState);
    const serviceFillUpdateData = parseData?.parseData;
    const servicePoItemData = parseData?.poItemCodeData;
    let filteredLineItem = _?.filter(listData, { is_item: "1" });
    let sidebarItem = _?.filter(listData, { is_item: "0" });
    const sanitizeValue = (value) => {
        if (!value) return undefined;
        return /[a-zA-Z]/.test(value) ? undefined : value;
    };
    useEffect(() => {
        if (
            (filteredLineItem.length > 0 && tableListItem.length === 0) ||
            (serviceFillUpdateData !== undefined && serviceFillUpdateData?.length !== 0)
        ) {
            filteredLineItem = _.groupBy(filteredLineItem, "item_no");
            filteredLineItem = _.values(filteredLineItem);
            let TblData = invoiceDataFetch(serviceFillUpdateData ? serviceFillUpdateData : filteredLineItem);
            setLineItems(TblData?.reducedLineItems);
            setTableListItem(serviceFillUpdateData ? serviceFillUpdateData : filteredLineItem);
        }
    }, [serviceFillUpdateData, tableListItem?.length == 0]);
    props?.setFilteredLineItem(tableListItem);
    const [tableTaxItem, setTableTaxItem] = useState();
    function invoiceDataFetchTax(invoiceData) {
        const reducedLineItems = _.mapValues(invoiceData, (itemGroup) => {
            return _.reduce(
                itemGroup,
                (result, item) => {
                    result[item.label_name] = {
                        label_value: item?.label_value,
                        isrequired: item?.isrequired,
                        ismandatory: item?.ismandatory,
                        status: item?.status,
                        data_type: item?.data_type,
                        id: item?.id,
                        maxlength: item?.maxlength,
                        iseditable: item?.iseditable,
                        item_no: item?.item_no,
                        label_name: item.label_name,
                        label_displayname: item?.label_displayname,
                        is_item: item?.is_item,
                    };
                    return result;
                },
                {}
            );
        });
        return {
            reducedLineItems: reducedLineItems,
        };
    }
    let filteredTaxItem = _?.filter(listData, { is_item: "2" });
    if (filteredTaxItem.length > 0 && tableTaxItem == undefined) {
        filteredTaxItem = _.groupBy(filteredTaxItem, "item_no");
        filteredTaxItem = _.values(filteredTaxItem);
        let TblData = invoiceDataFetchTax(filteredTaxItem);
        setTableTaxItem(TblData?.reducedLineItems);
    }
    const currentIndxValue = !currentIndex ? 1 : currentIndex;
    let listDataCurrentItems = listData?.filter((item) => (item.pg_no === 0 ? currentIndxValue : item.pg_no == currentIndxValue));
    const onImageChange = async (e, currentImgIndex) => {
        onChangeIndex(e.target.value);
        if (e.target.value && e.target.value !== "0" && e.target.value <= props.invoiceList?.invoice_url.length) {
            let myIndex = +e.target.value;
            const imageName = props.invoiceList?.invoice_url[myIndex - 1]?.url;
            onChangeIndex(myIndex);
            setImageUrl(imageName);
        } else {
            Swal.fire({
                text: "The page was not found!",
                closeOnClickOutside: false,
                showCloseButton: true,
                icon: "warning",
                button: "Ok",
            });
            onChangeIndex(currentImgIndex);
        }
    };
    if (props?.currentFieldSelectedType) {
        currentPage = props?.currentFieldSelectedType?.pg_no;
        if (currentPage) {
            if (currentPage !== currentIndex) {
                onChangeIndex(currentPage);
            }
            invImageUrl = props.invoiceList?.invoice_url[currentPage - 1]?.url;
            if (invImageUrl !== imageUrl) {
                setImageUrl(invImageUrl);
            }
        }
    }
    const increment = async () => {
        props?.setCurrentFieldType(null);
        const newCurrentIndex = currentIndex + 1;
        onChangeIndex(newCurrentIndex);
        if (newCurrentIndex <= props.invoiceList?.invoice_url.length) {
            const imageName = props.invoiceList?.invoice_url[newCurrentIndex - 1]?.url;
            setImageUrl(imageName);
        } else {
            Swal.fire({
                text: "The page was not found!",
                closeOnClickOutside: false,
                showCloseButton: true,
                icon: "warning",
                button: "Ok",
            });
            onChangeIndex(currentIndex);
        }
    };
    const decrement = () => {
        props?.setCurrentFieldType(null);
        const newCurrentIndex = currentIndex - 1;
        onChangeIndex(newCurrentIndex);
        if (newCurrentIndex > 0) {
            const invoiceLength = props.invoiceList?.invoice_url.length;
            const imageName = props.invoiceList?.invoice_url[newCurrentIndex - 1]?.url;
            if (newCurrentIndex <= invoiceLength) {
                setImageUrl(imageName);
            } else {
                Swal.fire({
                    text: "The page was not found!",
                    closeOnClickOutside: false,
                    icon: "warning",
                    showCloseButton: true,
                    button: "Ok",
                });
                onChangeIndex(currentIndex);
            }
        }
    };
    useEffect(() => {
        if (imageUrl != imageId) {
            dispatch(InvoiceGetDocumnet(imageUrl));
        }
        setImageUpdate(DocView);
    }, [imageUrl, DocView]);
    const CloseWindow = () => {
        dispatch({ type: INVOICE_GET_RESET });
        dispatch({ type: GET_INVOICE_TASK_LIST_RESET });
        if (pageType == 7) {
            window.close();
        }
    };
    const [checkStop, setCheckStop] = useState(false);
    const handleStop = () => {
        props?.handleStop();
        setCheckStop(true);
    };

    const timerValue = props?.timerValue;
    const setTimerValue = props?.setTimerValue;
    const TopModel = () => {
        return (
            <div
                className={`bg-${currentMode}-card text-${currentMode}-copy scroll-mt-[90px] px-6 shadow-[0px_4px_20px_rgba(85,85,85,0.2)] w-full nav-step z-[10] right-0 left-0 sticky top-0 py-[8px]`}
            >
                <div className="justify-between mx-auto w-[960px] flex items-center">
                    <div className="flex text-xs items-center">
                        <div className="mr-2 space-y-[3px]">
                            <p>Invoice ID</p>
                            <p>Document </p>
                            <p>Document Number</p>
                        </div>
                        <div className="space-y-[3px] mt-[-18px]">
                            <p className="flex w-[240px]">
                                :<span className="ml-2 text-light-20 block truncate"> {InvNumber}</span>
                            </p>
                            <p className="flex w-[240px]">
                                :<span className="ml-2 text-light-20 block truncate"> {docType}</span>
                            </p>
                            <p className="flex w-[240px]">
                                :<span className="ml-2 text-light-20 block truncate"> {docNumber}</span>
                            </p>
                        </div>
                    </div>
                    <div className="h-[36px] w-[162px] mt-[4px] border border-solid border-[#8C9097] rounded">
                        {/* <div className="flex px-[10px]  space-x-[9px] first:rounded-tl-[8px] my-auto mt-[3px] position-relative"> */}
                        <div className="flex">
                            <div className="space-x-[8px] my-auto mt-[4px] mx-[8px]">
                                {!props?.isPlaying ? (
                                    <Button title="Play" onClick={props?.handlePlay} className="leftSide">
                                        {" "}
                                        <img
                                            className=""
                                            src={currentMode == "dark" ? `/images/tour-helper/dark-play.svg` : `/images/tour-helper/play.svg`}
                                        />{" "}
                                    </Button>
                                ) : (
                                    <Button title="Pause" onClick={props?.handlePause} className="leftSide">
                                        {" "}
                                        <img
                                            src={currentMode == "dark" ? `/images/tour-helper/dark-pause.svg` : "/images/tour-helper/pause-btn.svg"}
                                        />
                                    </Button>
                                )}
                                <Button title="Stop" onClick={handleStop} className="leftSide">
                                    {" "}
                                    <img src={currentMode == "dark" ? `/images/tour-helper/dark-stop.svg` : `/images/tour-helper/stop.svg`} />
                                </Button>
                            </div>
                            <div className="border-l border-solid border-[#8C9097] h-[34px]">
                                <div className="space-x-[8px]">
                                    <InputTrigger timerValue={timerValue} setTimerValue={setTimerValue} />
                                    <span className="ml-[2px] font-normal text-[12px]">Sec</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-end space-x-3 basis-1/2">
                        <div className="flex space-x-2 items-center">
                            <span>Page</span>
                            <Input
                                onChange={(e) => onImageChange(e, currentIndex)}
                                disabled={true}
                                readOnly={props?.invoiceList?.invoice_url?.length === 1}
                                type="text"
                                id="txtImg"
                                className="border-[1px] bg-transparent txt-img  w-[40px] pl-[.8rem] rounded-[5px] h-[30px] border-[rgba(85,85,85,0.5)]"
                                value={currentIndex}
                            />
                            <span className="text-xl">/</span>
                            <label>{props.invoiceList?.invoice_url.length}</label>
                            <div className="flex flex-col space-y-1">
                                <Button className="w-[13px]" onClick={() => decrement()} disabled={currentIndex == 1}>
                                    <img
                                        className="w-[13px] object-contain h-[7px]"
                                        title="Previous page"
                                        src="/images/common/ar-up.svg"
                                        alt="ar-up"
                                    />
                                </Button>
                                <Button
                                    className="w-[13px]"
                                    onClick={() => increment()}
                                    disabled={currentIndex == props.invoiceList?.invoice_url.length ? true : false}
                                >
                                    <img
                                        className="w-[13px] object-contain h-[7px]"
                                        title="Next page"
                                        src="/images/common/ar-down.svg"
                                        alt="ar-down"
                                    />
                                </Button>
                            </div>
                        </div>
                        <div className="flex items-center space-x-5 border-l-[1px] border-[rgba(255,255,255,0.3)] px-6 border-r-[1px]">
                            <Button disabled={zoomLevel === 0.10000000000000014 ? true : false} onClick={handlers.zoomOut}>
                                <img title="Zoom out" src="/images/invoice/zMinus.svg" className="convertWhite" alt="zPlus" />
                            </Button>
                            <Button>
                                <img
                                    title="Fit to screen"
                                    src="/images/invoice/reset.svg"
                                    className="convertWhite"
                                    alt="reset"
                                    onClick={handlers.resetZoom}
                                />
                            </Button>
                            <Button disabled={zoomLevel === 2.000000000000001 ? true : false} onClick={handlers.zoomIn}>
                                <img className="convertWhite" title="Zoom in" src="/images/invoice/zPlus.svg" alt="zPlus" />
                            </Button>
                        </div>
                        <div className="flex items-center space-x-7">
                            <Button>
                                <Link
                                    to={`${
                                        props?.pageType
                                            ? props?.pageType == 1
                                                ? "/invoice"
                                                : props?.pageType == 2
                                                ? "/exception"
                                                : props?.pageType == 3
                                                ? "/approve"
                                                : props?.pageType == 4
                                                ? "/my-task/list"
                                                : props?.pageType == 5
                                                ? "/inbox-compose"
                                                : props?.pageType == 6
                                                ? "/processed"
                                                : props?.pageType == 8
                                                ? "/advance-list"
                                                : props?.pageType == 9
                                                ? `/retention-release${props?.ret_id == 0 ? "" : `?id=${props?.ret_id}`}`
                                                : ""
                                            : ""
                                    }`}
                                >
                                    <img
                                        onClick={CloseWindow}
                                        title="Close"
                                        src="/images/invoice/cls-blk.svg"
                                        alt="cls-blk"
                                        className="convertWhite"
                                    />
                                </Link>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    const urlData = location?.search?.slice(1)?.replace(/\n\s+/g, "\n")?.trim();
    const urlObj = JSON.parse(decryptId(urlData));
    let pageType = urlObj?.type;
    let status = urlObj?.status;
    const DisplayRole = useSelector((state) => state?.DisplayRolePermissionStateAction);
    const { data: DataList } = DisplayRole;
    const RoleList = DataList?.role_info;
    const RoleMenu = RoleList?.menu;
    const RoleProcess = RoleMenu?.find((elem) => elem?.menu_id === 8);
    const RoleProcessAction = RoleProcess?.actions;
    const RoleActProcessApprove = RoleProcessAction?.find((element) => element.id === 6);
    const RoleActProcessEdit = RoleProcessAction?.find((element) => element.id === 2);
    const RoleActProcessReject = RoleProcessAction?.find((element) => element.id === 9);
    const RoleActProcessFlag = RoleProcessAction?.find((element) => element.id === 18);
    const RoleException = RoleMenu?.find((elem) => elem?.menu_id === 9);
    const RoleExceptionAction = RoleException?.actions;
    const RoleActExceptionApprove = RoleExceptionAction?.find((element) => element.id === 6);
    const RoleActExceptionEdit = RoleExceptionAction?.find((element) => element.id === 2);
    const RoleActExceptionReject = RoleExceptionAction?.find((element) => element.id === 9);
    const RoleActExceptionFlag = RoleExceptionAction?.find((element) => element.id === 18);
    const [anchorEls, setAnchorEls] = useState(null);
    const [cropVendorName, setCropVendorName] = useState("");
    useEffect(() => {
        if (currentItem?.label_value?.length > 0) {
            const filterArr = vendorList?.filter((ele) => ele?.VendorName?.toLowerCase()?.includes(currentItem?.label_value?.toLowerCase()));
            setFilteredOptions(filterArr);
        } else if (cropVendorName?.length > 0) {
            const filterArr = vendorList?.filter((ele) => ele?.VendorName?.toLowerCase()?.includes(cropVendorName?.toLowerCase()));
            setFilteredOptions(filterArr);
        } else {
            setFilteredOptions(vendorList);
        }
    }, [currentItem?.label_name == "company_name" && currentItem?.label_value, cropVendorName]);
    const invoiceTxtChng = (event, labelName, labelVal, invId, data_type, iseditable, is_confirmed, check, label_displayname, VendorName) => {
        setFilteredOptions([]);
        setcheckChange(true);
        if (labelVal == "") {
            setSearchShow(false);
        } else {
            setSearchShow(true);
        }
        setCheckClick(check);
        if (check == "click") {
            setpopperOpen(false);
            // } else {
            //     // setCheckClick(false);
            //     if (filteredPoNoList?.length !== 0) {
            //         setpopperOpen(true);
            //     }
            // }
        }
        const vendorSelect = vendorList?.find((item) => item?.VendorName == labelVal);
        setVendorCode({
            label_name: "vendor_code",
            label_value: vendorSelect?.VendorNo,
        });
        setAnchorEls(event.currentTarget);
        setCurrentSelectedItem({
            label_name: labelName,
            label_value: labelVal,
            id: invId,
            data_type: data_type,
            iseditable: iseditable,
            is_confirmed: is_confirmed,
            label_displayname: label_displayname,
            VendorName: VendorName,
        });
    };
    const [days, months, years] =
        currentItem?.label_value && currentItem?.data_type == "d"
            ? String(currentItem?.label_value)?.split(
                  currentItem?.label_value?.includes("-") ? "-" : currentItem?.label_value?.includes(".") ? "." : "/"
              )
            : ["", "", ""];
    const { data: data, fetching, requestComplete } = useSelector((state) => state?.getPoNumberListState);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const { data: vendorList } = useSelector((state) => state?.getSapVendorListState);
    const poNumberList = data;
    const findVendorOnPoList = poNumberList?.find(
        (item) => item?.PoNumber == currentItem?.label_value && item?.VendorName == currentItem?.VendorName
    );
    const vendorObj = props?.vendorObj;
    const setVendorObj = props?.setVendorObj;
    useEffect(() => {
        if (findVendorOnPoList) {
            listData.find((item) => item?.label_name == "company_name").label_value = findVendorOnPoList?.VendorName;
            listData.find((item) => item?.label_name == "company_name").is_confirmed = true;
            setVendorCode({
                label_name: "vendor_code",
                label_value: findVendorOnPoList?.PoVendor,
            });
            setVendorObj(listData.find((item) => item?.label_name == "company_name"));
        }
    }, [findVendorOnPoList]);
    const datess = new Date(years, months - 1, days);
    const [selectedDate, setSelectedDate] = useState(datess);
    const invoiceDateChng = (labelName, labelVal, invId, data_type, iseditable, is_confirmed, label_displayname) => {
        setSelectedDate(labelVal);
        const FromDate = moment(new Date(labelVal)).format(userFormat?.toUpperCase());
        setCurrentSelectedItem({
            label_name: labelName,
            label_value: FromDate,
            id: invId,
            data_type: data_type,
            iseditable: iseditable,
            is_confirmed: is_confirmed,
            label_displayname: label_displayname,
        });
    };
    let setClick = props?.setisClick;
    useEffect(() => {
        currentPage = props?.currentPlayObj?.pg_no;
        if (props?.currentPlayObj) {
            document?.querySelectorAll("#Inv-" + props?.currentPlayObj?.id)[0]?.click();
        }
        if (!props?.isPlaying && props?.currentIndex + 1 === props?.notConfirmedList?.length) {
            props?.onShowLineItem(true);
            handleClickAway();
        }
        if (props?.isFocused) {
            props?.setIsPlaying(false);
            props?.onShowLineItem(false);
        }
        if (!props?.currentIndex) {
            const element = document.querySelectorAll("ul");
            element.forEach((item) => item.classList.remove("edit"));
        }
        if (currentItem?.data_type == "d") {
            setSelectedDate(datess);
        }
    }, [props?.currentPlayObj, props?.isPlaying, props?.isFocused, currentItem?.data_type]);
    useEffect(() => {
        currentPage = props?.currentPlayObj?.pg_no;
        invImageUrl = props.invoiceList?.invoice_url[currentPage - 1]?.url;
        if (currentPage) {
            if (currentPage !== currentIndex) {
                props?.handlePause();
                onChangeIndex(currentPage);
            }
            if (invImageUrl !== imageUrl) {
                setImageUrl(invImageUrl);
            }
        }
    }, [props?.currentPlayObj?.pg_no]);
    useEffect(() => {
        currentPage = props?.currentPlayObj?.pg_no;
        if (imageResult && imageUrl && !DocInv?.fetching && invImageUrl && props?.currentPlayObj && currentIndex === currentPage) {
            props?.handlePlay();
            // onChangeIndex(currentPage);
            // document?.querySelectorAll("#Inv-" + props?.currentPlayObj?.id)[0]?.click();
        }
    }, [imageResult]);
    const handleClick = (
        newPlacement,
        labelVal,
        itemVal,
        event,
        invID,
        isLineItem,
        mandatory,
        maxlength,
        data_type,
        iseditable,
        is_confirmed,
        label_displayname
    ) => {
        setClick(true);
        if (isLineItem == "0") {
            if (data_type == "d") {
                let dateString = itemVal == null ? "18/09/2022" : String(itemVal?.replaceAll(".", "/"));
                const momentObj = moment(dateString, userFormat?.toUpperCase());
                const momDate = momentObj?.toDate();
                setSelectedDate(momDate);
            }
            if ((props?.currentIndex || checkStop) && props?.currentPlayObj?.box_1x !== "0") {
                setOpen(true);
                setCheckStop(false);
            } else {
                if (props?.currentPlayObj?.box_1x !== "0") {
                    setOpen((prev) => placement !== newPlacement || !prev);
                } else {
                    setOpen(false);
                }
            }
            if (props?.isCompleted && !props?.isPlaying) {
                props?.setIsCompleted(false);
                props?.setIsPlaying(false);
                props?.onShowLineItem(true);
            } else {
                props?.onShowLineItem(false);
            }
            setAnchorEl(event.currentTarget);
            setPlacement(newPlacement);
            setImgContentOpen(false);
            setChecking({
                mandatory: mandatory,
                maxlength: maxlength,
                data_type: data_type,
            });
            const [day2, month2, year2] = String(itemVal)?.split(String(itemVal)?.includes("-") ? "-" : "/");
            const date2 = new Date(year2?.length == 2 ? "20" + year2 : year2, month2 - 1, day2);
            setCurrentSelectedItem({
                label_name: labelVal,
                // label_value: year2?.length == 2 && data_type == "d" ? date2?.toLocaleDateString() : itemVal,
                label_value:
                    currentItem?.data_type == "d" && currentItem?.label_value?.includes("-")
                        ? moment(currentItem?.label_value, "DD-MM-YYYY").isValid()
                            ? moment(currentItem?.label_value, "DD-MM-YYYY").format(userFormat?.toUpperCase())
                            : moment(new Date(currentItem?.label_value)).format(userFormat?.toUpperCase())
                        : itemVal,
                id: invID,
                data_type: data_type,
                iseditable: iseditable,
                is_confirmed: is_confirmed,
                label_displayname: label_displayname,
            });
            const element = document.querySelectorAll("ul");
            element.forEach((item) => item.classList.remove("edit"));
            document.querySelector(`${"#Inv-ul-"}${invID}`).classList.add("edit");
            const divElement = document.getElementById(`${"Inv-"}${invID}`);
            const scrollHeaderElement = document.getElementById(`${"Inv-ul-"}${invID}`);
            if (scrollHeaderElement) {
                setTimeout(() => {
                    scrollHeaderElement.scrollIntoView({ behavior: "smooth" });
                }, 500);
            }
            if (divElement) {
                divElement.scrollIntoView({ behavior: "smooth" });
                if (document.querySelector(".ReactCrop__child-wrapper")) {
                    document.querySelector(".ReactCrop__child-wrapper").classList.add("scroll-pt-[90px]");
                }
            }
            // Previous code...
            setTimeout(() => {
                let scrollPos = document?.querySelector(".popper-blk");
                const place = scrollPos?.getAttribute("data-popper-placement");
                place === "top" ? scrollPos.classList.add("top") : scrollPos.classList.remove("top");
                // Check if a match is found and the first value is "0"
            }, 10);
        }
        // else if (findTaxAmount && isLineItem == "2" && labelVal == "total_tax") {
        //     props?.setClickTaxOpen(true);
        // }
        else if (isLineItem == "1") {
            props?.onShowLineItem(true);
            setTimeout(() => {
                const divElement = document.querySelector(`.w-full.fieldId-${invID}`);
                if (divElement) {
                    divElement.scrollIntoView({ behavior: "smooth" });
                    divElement.focus();
                }
            }, 200);
        } else {
            props?.setClickTaxOpen(true);
        }
    };
    const handleClickAway = () => {
        setClick(false);
        props?.setisDrag(false);
        setOpen(false);
        setCheckErr("");
        setErrorCheck({
            message: "",
        });
    };
    const handleClickAwayImgContent = () => {
        setCheckErr("");
        setErrorCheck("");
        setClick(false);
        setImgContentOpen(false);
        setCrop(null);
        setCropImgText("");
        props?.setisDrag(false);
        // fieldtype reset when after click trash button
        setSelectTrue(false);
        setFieldType("");
        setFindTextType("");
        handleFieldType("");
        setmandotaryType("");
        setmaxlengthType("");
    };
    function validateDate(value) {
        return moment(value, OrgDateFormat?.toUpperCase(), true).isValid();
    }
    const dateFormat = OrgDateFormat?.toUpperCase();
    const invFieldUpdate = async (currentID, value, name) => {
        setClick(false);
        props?.setisDrag(false);
        let txtVal = value;
        props?.setIsFocused(false);
        if ((checking?.mandatory && txtVal?.length === 0) || txtVal == "") {
            setCheckErr(`This field is required`);
        } else if (checking?.mandatory && checking?.maxlength !== 0 && checking?.maxlength !== null && txtVal?.length > checking?.maxlength) {
            setCheckErr(`Field Length should be maximum ${checking?.maxlength} `);
        }
        // else if (checking?.mandatory && checking?.data_type == "p" && +txtVal?.replace(/[%.,\s]/g, "") > 100) {
        //     setCheckErr("This field only allow percentage");
        // }
        // else if ((checking?.mandatory && checking?.data_type == "n") || (checking?.data_type == "m" && isNaN(txtVal?.replace(/[.,\s]/g, "")))) {
        //     setCheckErr("This field is only allow number format");
        // }
        else {
            setCheckErr("");
            if (checkErr == "") {
                const getLabelName = listData.find((item) => item.id == currentID);
                let parse = txtVal;
                if (getLabelName?.data_type == "d") {
                    parse = txtVal;
                    // txtVal = moment(txtVal, "DD/MM/YYYY").format(userFormat?.toUpperCase());
                    parse = moment(txtVal, userFormat?.toUpperCase()).format(dateFormat?.toUpperCase());
                }
                document.querySelector(`${"#Inv-ul-"}${currentID}`).classList.add("approve");
                document.querySelector(`${"#Inv-li-"}${currentID}`).innerHTML = txtVal;
                listData.map((item, index) => {
                    if (item?.id == currentID) {
                        let findVendorByPo;
                        if (item?.label_name == "purchase_order_no") {
                            findVendorByPo = vendorList?.find(
                                (item) => item?.VendorName === poNumberList?.find((el) => el?.PoNumber === txtVal)?.VendorName
                            );
                        }
                        if (findVendorByPo && item?.label_name == "company_name") {
                            item.label_value = findVendorByPo;
                            item.is_confirmed = true;
                        }
                        item.label_value = txtVal;
                        item.is_confirmed = true;
                    }
                });
                const Iscropped = crop?.width !== 0 || crop?.height !== 0;
                const boxValues =
                    Iscropped && crop && crop?.x && crop?.y
                        ? {
                              box_1x: crop?.x,
                              box_1y: crop?.y,
                              box_2x: crop?.x,
                              box_2y: crop?.y + crop?.height,
                              box_3x: crop?.x + crop?.width,
                              box_3y: crop?.y + crop?.height,
                              box_4x: crop?.x + crop?.width,
                              box_4y: crop?.y,
                          }
                        : ``;
                const objInv = {
                    id: currentID,
                    label_name: getLabelName.label_name,
                    label_value: txtVal,
                    pg_no: currentIndex,
                    data_type: getLabelName?.data_type,
                    is_confirmed: true,
                    ...boxValues,
                };
                const updateObjInv = {
                    id: vendorObj?.id,
                    label_name: vendorObj.label_name,
                    label_value: vendorObj?.label_value,
                    data_type: getLabelName?.data_type,
                };
                if (updatedInvoiceList.length > 0) {
                    // Check if an object with the same id as currentID exists in the array
                    const indexById = _.findIndex(updatedInvoiceList, { id: currentID });
                    // Check if an object with the same label_name exists in the array
                    const indexByLabelName = _.findIndex(updatedInvoiceList, { label_name: objInv.label_name });
                    // If an object with the same id exists
                    if (indexById !== -1) {
                        const item = updatedInvoiceList[indexById];
                        // Check if box_1x is different from boxValues.box_1x
                        if (item.box_1x !== boxValues.box_1x) {
                            Object.assign(item, boxValues); // Update boxValues
                        }
                        item.label_value = txtVal; // Update label_value
                        item.is_confirmed = true; // Set is_confirmed to true
                    } else if (indexByLabelName !== -1) {
                        // If an object with the same label_name exists
                        updatedInvoiceList[indexByLabelName] = objInv; // Replace the existing object with the new one
                    } else {
                        // If no object with the same id or label_name exists, push objInv to the array
                        updatedInvoiceList.push(objInv);
                    }
                } else if (vendorCode?.label_value !== undefined) {
                    // If the array is empty and vendorCode.label_value is defined, push vendorCode, updateObjInv, and objInv to the array
                    updatedInvoiceList.push(vendorCode, updateObjInv, objInv);
                } else {
                    // If the array is empty and vendorCode.label_value is undefined, push objInv and updateObjInv to the array
                    updatedInvoiceList.push(objInv, updateObjInv);
                }

                props.onSaveDoc(updatedInvoiceList, objInv, updateObjInv);
                setUpdatedObjList(updatedInvoiceList);
                if (!props?.isPlaying && props?.currentIndex < 1) {
                    handleClickAway();
                }
                if ((props?.currentIndex === 0 || props?.currentIndex > 0) && !props?.manualPause) {
                    props?.setIsFocused(false);
                    props?.skipFocus();
                } else {
                    handleClickAway();
                }
                setCrop(null);
                setFieldType(null);
                setImgContentOpen(false);
                setCropImgText("");
                setCropImgdate("");
                // fieldtype reset when after click confirm button
                setSelectTrue(false);
                setFieldType("");
                setFindTextType("");
                handleFieldType("");
                setmandotaryType("");
                setmaxlengthType("");
            }
        }
        if (currentItem?.label_name == "purchase_order_no" && value?.trim()?.length > 0) {
            const reqObj = {
                numFormat: numFormat,
                type: 1,
                orgId: orgId,
                invoiceInfo: _.flatMap([...listData, ...filteredLineItem]),
            };
            dispatch(postThreeWayMatching(reqObj));
            dispatch(GetAdvanceDetailsforCreate(value, 1, orgId));
        }
    };
    const handleDeleteValue = (currentID, value) => {
        const txtVal = "";
        setCheckErr("");
        if (checkErr == "") {
            document.querySelector(`${"#Inv-ul-"}${currentID}`).classList.remove("approve");
            document.querySelector(`${"#Inv-li-"}${currentID}`).innerHTML = txtVal;
            listData.map((item, index) => {
                if (item?.id == currentID) {
                    item.label_value = txtVal;
                    item.box_1x = "0";
                    item.is_confirmed = false;
                }
            });
            const getLabelName = listData.find((item) => item.id == currentID);
            const Iscropped = crop?.width !== 0 || crop?.height !== 0;
            const boxValues =
                Iscropped && crop && crop?.x && crop?.y
                    ? {
                          box_1x: crop?.x,
                          box_1y: crop?.y,
                          box_2x: crop?.x,
                          box_2y: crop?.y + crop?.height,
                          box_3x: crop?.x + crop?.width,
                          box_3y: crop?.y + crop?.height,
                          box_4x: crop?.x + crop?.width,
                          box_4y: crop?.y,
                      }
                    : ``;
            const objInv = {
                id: currentID,
                label_name: getLabelName.label_name,
                label_value: txtVal,
                pg_no: currentIndex,
                is_confirmed: false,
                box_1x: "0",
                ...boxValues,
            };
            if (updatedInvoiceList.length > 0) {
                updatedInvoiceList.forEach((item) => {
                    if (item?.id == currentID) {
                        item.label_value = txtVal;
                    } else {
                        updatedInvoiceList.push(objInv);
                    }
                });
            } else {
                if (objInv) {
                    updatedInvoiceList.push(objInv);
                }
            }
            props.onSaveDoc(updatedInvoiceList, objInv);
            setUpdatedObjList(updatedInvoiceList);
            handleClickAway();
            setCrop(null);
            setFieldType(null);
            setImgContentOpen(false);
            setCropImgText("");
            setCropImgdate("");
        }
    };
    const updateInvoiceDocument = (lineItemList) => {
        props.onSaveDoc(lineItemList);
    };
    const getCompletedCorpImage = async (event) => {
        dragMaxlength = listData.map((item) => item.maxlength);
        props?.setisDrag(true);
        try {
            if (event && crop?.height > 0 && crop?.width > 0) {
                const selectedLabel = document.querySelector(`${"#Inv-li-item"}${props?.currentFieldSelectedType?.id}`);
                if (selectedLabel) {
                    setFieldText(selectedLabel.innerText);
                    let getSelectedLblId = listData.find((item) => item.label_name === props?.currentFieldSelectedType?.label_name);
                    if (getSelectedLblId) {
                        setFieldType(getSelectedLblId.id);
                    }
                }
                setOpen(false);
                const imgelement = document.getElementById("img-invoice-data");
                const pixelCrop = {
                    x: Math.round(imgelement.naturalWidth * (crop.x / 100)),
                    y: Math.round(imgelement.naturalHeight * (crop.y / 100)),
                    width: Math.round(imgelement.naturalWidth * (crop.width / 100)),
                    height: Math.round(imgelement.naturalHeight * (crop.height / 100)),
                };
                const croppedBase64Img = await getCroppedImg(imgelement, pixelCrop, "inv-croped.jpg", _scale);
                setCrop({ ...pixelCrop });
                props?.onShowLineItem(false);
                if (crop.height !== 0 && crop.width !== 0) {
                    uploadCropImg(croppedBase64Img);
                    //const cropedImg = document.querySelector(".ReactCrop__crop-selection");
                    //  if(document.getElementById("crop-image-uplod")){
                    //  document.getElementById("crop-image-uplod").style.top=cropedImg?.style?.top;
                    //  document.getElementById("crop-image-uplod").style.left=cropedImg?.style?.left;
                    //  }
                }
                //  setCompletedCrop(crop);
            } else {
                setImgContentOpen(false);
            }
        } catch (ex) {}
    };
    const numFormat = localStorage.getItem("userNumberFormat");
    const uploadCropImg = async (corpedMLImg) => {
        if (corpedMLImg) {
            setImgContentOpen(true);
            const cropedMLContent = await GetImageContent({
                image: corpedMLImg.replace(/^data:image\/[a-z]+;base64,/, ""),
            });
            setCropImgText(cropedMLContent?.data);
            setCropVendorName(cropedMLContent?.data);
            const [day2, month2, year2] = String(cropedMLContent?.data)?.split(String(cropedMLContent?.data)?.includes("-") ? "-" : "/");
            const date2 = new Date(year2?.length == 2 ? "20" + year2 : year2, month2 - 1, day2);
            setCropImgdate(date2);
            if (cropedMLContent?.data === "") {
                Swal.fire({
                    text: "Do not crop in the blank space!",
                    icon: "error",
                    closeOnClickOutside: false,
                    showCloseButton: true,
                    button: "Ok",
                }).then((confirm) => {
                    if (confirm) {
                        setCrop(null);
                        setClick(false);
                        props?.setisDrag(false);
                    }
                });
            }
        }
    };
    const [filteredPoNoList, setFilteredPoNoList] = useState([]);
    useEffect(() => {
        if (currentItem.label_name !== null || poNumberList) {
            const filteredFav = poNumberList?.filter((fav) => {
                return fav.PoNumber.includes(currentItem?.label_value);
            });
            setFilteredPoNoList(filteredFav);
        }
    }, [currentItem, poNumberList]);
    const onCompleteCrop = (crop, percentCrop) => {
        props?.setisDrag(true);
    };
    const [fieldType, handleFieldType] = useState("");
    const [mandatoryType, setmandotaryType] = useState("");
    const [maxlengthType, setmaxlengthType] = useState("");
    const [selectTrue, setSelectTrue] = useState(false);
    const [findTextType, setFindTextType] = useState("");
    const handleSelectField = (e) => {
        setSelectTrue(true);
        setFieldType(e.target.value);
        setErrorCheck({
            message: "",
        });
        const findType = notConfirmedList?.find((el) => el?.id === e.target.value);
        setFindTextType(findType?.label_name);
        handleFieldType(findType?.data_type);
        setmandotaryType(findType?.ismandatory);
        setmaxlengthType(findType?.maxlength);
    };
    var percentagePattern = /^-?\d+(\.\d+)?%$/;
    var numberRegex = /^\d+(\.\d+)?$/;
    const invFieldUpdateWithContent = () => {
        if (cropImgtext && !findTextType) {
            if ((CropObject?.mandatory && cropImgtext?.length === 0) || cropImgtext == "") {
                setErrorCheck({
                    message: "This field is required",
                });
                return false;
            }
            if (
                CropObject?.mandatory &&
                CropObject?.maxlength !== 0 &&
                CropObject?.maxlength !== null &&
                cropImgtext?.length > CropObject?.maxlength
            ) {
                setErrorCheck({
                    message: `Field Length should be maximum ${CropObject?.maxlength}`,
                });
                return false;
            } else if (CropObject?.mandatory && CropObject?.data_type == "p" && !numberRegex?.test(cropImgtext)) {
                setErrorCheck({
                    message: "This field allows only percentage",
                });
                return false;
            } else if (
                (CropObject?.mandatory && CropObject?.data_type == "n" && !numberRegex?.test(cropImgtext)) ||
                (CropObject?.data_type == "m" && !cropImgtext?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ","))
            ) {
                setErrorCheck({
                    message: "This field  allows only number format",
                });
                return false;
            } else if (CropObject?.mandatory && CropObject?.data_type == "d" && !validateDate(cropImgtext)) {
                setErrorCheck({
                    message: "This field allows only Date",
                });
                return false;
            } else {
                setErrorCheck("");
            }
        }
        if (selectTrue) {
            if ((mandatoryType && cropImgtext?.length === 0) || cropImgtext == "") {
                setErrorCheck({
                    message: "This field is required",
                });
                return false;
            }
            if (mandatoryType && maxlengthType !== 0 && maxlengthType !== null && cropImgtext?.length > maxlengthType) {
                setErrorCheck({
                    message: `Field Length should be maximum ${maxlengthType}`,
                });
                return false;
            } else if (mandatoryType && fieldType == "p" && !numberRegex?.test(cropImgtext)) {
                setErrorCheck({
                    message: "This field allows only percentage",
                });
                return false;
            } else if (
                (mandatoryType && (fieldType == "n" || fieldType == "p") && !numberRegex?.test(cropImgtext)) ||
                (fieldType == "m" && !cropImgtext?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ","))
            ) {
                setErrorCheck({
                    message: "This field allows only number format",
                });
                return false;
            } else if (mandatoryType && fieldType == "d" && !validateDate(cropImgDate)) {
                setErrorCheck({
                    message: "This field allows only Date format",
                });
                return false;
            } else {
                setErrorCheck("");
            }
        }
        props?.setisDrag(false);
        setClick(false);
        if (selectedFieldId) {
            invFieldUpdate(
                selectedFieldId,
                fieldType == "m" || fieldType == "n" || fieldType == "p" ? sanitizeValue(cropImgtext?.toString()) : cropImgtext?.toString(),
                cropImgDate
            );
        } else {
            setIsField(true);
            setTimeout(() => {
                setIsField(false);
            }, 2500);
        }
    };
    moment.updateLocale("en", {
        twoDigitYearMax: moment().year() + 10,
    });
    const [vendorCode, setVendorCode] = useState({
        label_name: "vendor_code",
        label_value: "",
    });
    const [vendorSelect, setVendorSelect] = useState();
    const invoiceImgCropedTxtChng = (event, value, newval, check) => {
        setFilteredOptions([]);
        setcheckChange(true);
        if (value == "") {
            setSearchShow(false);
        } else {
            setSearchShow(true);
        }
        const filteredFav = poNumberList?.filter((fav) => {
            return fav.PoNumber.includes(value);
        });
        setFilteredPoNoList(filteredFav);
        if (check == "click") {
            setpopperOpen(false);
            // setCheckClick(true)
            // } else {
            //     setCheckClick(false);
            //     setpopperOpen(true);
            // }
        }
        setAnchorEls(event.currentTarget);
        const findVendor = poNumberList?.find((item) => item?.PoNumber == value);
        const findVendorName = vendorList?.find((item) => item?.VendorName == findVendor?.VendorName);
        setVendorSelect(findVendorName);
        if (value) {
            setCropImgText(value);
        } else {
            setCropImgText(newval);
        }
        // else {
        //     setCropImgText(event.target.value);
        // }
        setVendorCode({
            label_name: "vendor_code",
            label_value: vendorSelect?.VendorNo,
        });
    };
    const resetInputField = (currentItem) => {
        setCropImgText("");
        // setErrorCheck("");
        setCurrentSelectedItem({
            data_type: currentItem.data_type,
            label_name: currentItem.label_name,
            label_value: "",
            id: currentItem.id,
            iseditable: currentItem?.iseditable,
            is_confirmed: currentItem?.is_confirmed,
            label_displayname: currentItem?.label_displayname,
        });
        setSearchShow(false);
    };
    useEffect(() => {
        if (vendorSelect?.vendorName !== undefined) {
            const vendorObject = listData.find((item) => item?.label_name == "company_name");
            vendorObject.label_value = vendorSelect?.VendorName;
            vendorObject.is_confirmed = true;
            const selectVendor = listData.find((item) => item?.label_name == "company_name");
            setVendorObj(selectVendor);
            const objInv = {
                label_name: "vendor_code",
                label_value: vendorSelect?.VendorNo,
            };
            setVendorCode(objInv);
            updatedInvoiceList.push(objInv);
            props.onSaveDoc(updatedInvoiceList);
        }
    }, [vendorSelect?.vendorName]);
    const [checkClick, setCheckClick] = useState(false);
    const [error, setError] = useState(false);
    const [popperOpen, setpopperOpen] = useState(false);
    const [checkChange, setcheckChange] = useState(false);
    useEffect(() => {
        // if (checkClick && popperOpen) {
        //     setpopperOpen(false);
        // }
        if (filteredPoNoList?.length == 0 || checkClick == "click") {
            setpopperOpen(false);
        }
        if (checkChange && filteredPoNoList?.length !== 0 && checkClick !== "click") {
            setpopperOpen(true);
        }
    }, [filteredPoNoList, checkClick]);
    const invoiceImgCropedDateChng = (value) => {
        if (value) {
            setCropImgdate(value?.$d);
            setCropImgText(value?.$d?.toLocaleDateString());
        } else {
            setCropImgdate(cropImgDate);
        }
        setError(!validateDate(value));
    };
    const orgId = localStorage.getItem("orgId");
    useEffect(() => {
        dispatch(getSapVendorList(orgId));
        if (updatedInvoiceList?.length !== 0) {
            updatedInvoiceList = [];
        }
    }, []);
    useEffect(() => {
        if (vendorList?.length > 0) {
            const findVendorName = vendorList?.find(
                (item) => item?.VendorName == sidebarItem?.find((el) => el?.label_name == "company_name")?.label_value
            );
            setVendorSelect(findVendorName);
        }
    }, [vendorList]);

    useEffect(() => {
        if ((fieldType == "s" && findTextType == "purchase_order_no") || currentItem?.label_name == "purchase_order_no") {
            if ((poNumberList?.length == 0 && (isPO == 1 || isPO == 3)) || (!poNumberList && (isPO == 1 || isPO == 3))) {
                dispatch(getPoNumberList(orgId));
            }
        } else if ((fieldType == "s" && findTextType == "company_name") || currentItem?.label_name == "company_name") {
            if (vendorList?.length == 0 || !vendorList) {
                dispatch(getSapVendorList(orgId));
            }
        }
    }, [
        findTextType == "purchase_order_no",
        currentItem?.label_name == "purchase_order_no",
        findTextType == "company_name",
        currentItem?.label_name == "company_name",
    ]);
    //* To show the tooltip in the invoice content image
    const setShowToolTip = (itemName) => {
        setFocusLineItem(itemName);
        const divElement = document.querySelector(".Inv-lineitem_" + itemName?.id);
        if (divElement) {
            divElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    };
    const exStatusId =
        props.invoiceList?.ext_status_id !== 3 &&
        props.invoiceList?.ext_status_id !== 4 &&
        props.invoiceList?.ext_status_id !== 5 &&
        props.invoiceList?.curr_status?.[0]?.master_refid !== 5;
    const InputDisable = !exStatusId || (currentItem?.iseditable === false && true) || status == "7";
    const cropTop = crop?.y + crop?.height + 70;
    const cropLeft = crop?.x - 90;
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const dropdownShadow = currentMode !== "dark" ? "0px 4px 20px rgba(238, 238, 238, 0.5)" : "";
    const dropdownBorder = currentMode !== "dark" ? "223, 225, 226, 0.56" : "";
    const targetDateStr = CurrentUser?.expiring_date;
    const targetDate = new Date(targetDateStr);
    const currentDate = new Date();
    const matchdate = targetDate < currentDate;
    const showIcon =
        (!matchdate && CurrentUser?.license_type == 2) ||
        CurrentUser?.license_type == 1 ||
        CurrentUser?.license_type == 3 ||
        CurrentUser?.license_type == 4;
    // Your existing options and renderInput
    const options = findTextType === "company_name" && vendorList?.length > 0 ? vendorList?.map((el) => el?.VendorName) : [];
    const filterOptions = (options, { inputValue }) => {
        if (!options) {
            return [];
        } else {
            setCropVendorName(inputValue);
            const filterArr = options?.filter((option) => option?.toLowerCase()?.includes(inputValue?.toLowerCase()));
            return filterArr;
        }
    };
    const MaxDate =
        location.pathname === "/proforma-invoice-view"
            ? new Date(props?.requestDate)
            : currentItem?.label_name == "delivery_date" || currentItem?.label_name == "due_date"
            ? ""
            : new Date();
    return (
        <>
            {isField && <MessageBox closeHide={true} error="Please select field type" />}
            <TopModel currentIndex={currentIndex} />
            <div className={`${props?.isClickedItem ? `h-[calc(100vh_-_230px)]` : `h-[calc(100vh_-_70px)]`} text-center relative overflow-y-auto`}>
                {!DocInv?.fetching ? (
                    <>
                        <ReactCrop
                            crop={crop}
                            style={{ ...zoomStyles }}
                            onComplete={(crop, percentCrop) => {
                                onCompleteCrop(crop, percentCrop);
                            }}
                            onChange={(_, c) => setCrop(c)}
                            disabled={status == "7" || (matchdate && CurrentUser?.license_type == 2)}
                            className="overflow-clip"
                            onDragEnd={(c) => {
                                getCompletedCorpImage(c);
                            }}
                        >
                            <div
                                id="Invocie-Img"
                                className={`relative w-[960px]
                 mx-auto h-[1280px] bg-no-repeat`}
                            >
                                <img id="img-invoice-data" src={`data:image;base64,${imageResult}`} style={{ zoomStyles }} />
                                {isClickImg
                                    ? listDataCurrentItems?.map((item, index) => {
                                          const isLineItemBox = getUpdatedObjList?.find((lineItem) => lineItem?.id == item?.id);
                                          if (isLineItemBox) {
                                              item.box = [
                                                  [isLineItemBox.box_1x, isLineItemBox.box_1y],
                                                  [isLineItemBox.box_2x, isLineItemBox.box_2y],
                                                  [isLineItemBox.box_3x, isLineItemBox.box_3y],
                                                  [isLineItemBox.box_4x, isLineItemBox.box_4y],
                                              ];
                                          } else {
                                              item.box = [
                                                  [item.box_1x, item.box_1y],
                                                  [item.box_2x, item.box_2y],
                                                  [item.box_3x, item.box_3y],
                                                  [item.box_4x, item.box_4y],
                                              ];
                                          }
                                          if (item.box) {
                                              let box = item.box.flat();
                                              let width = parseInt(box[4]) - parseInt(box[0]);
                                              let height = parseInt(box[5]) - parseInt(box[1]);
                                              let left = parseInt(box[0]);
                                              let top = parseInt(box[1]);
                                              const allZeros = box.every((value) => value === "0");
                                              return (
                                                  <Tooltip
                                                      open={
                                                          item?.label_name === focusLineItem?.name && item.id == focusLineItem?.id && !allZeros
                                                              ? true
                                                              : ""
                                                      }
                                                      id={`${item.is_item !== "0" ? "Inv-lineitem_" + item?.label_name : "Inv-" + item?.label_name}`}
                                                      PopperProps={{
                                                          sx: {
                                                              "& .MuiTooltip-tooltip": {
                                                                  background: "#F7900A",
                                                                  color: "#fff",
                                                                  borderRadius: "5px",
                                                                  "& .MuiTooltip-arrow": {
                                                                      color: "#F7900A",
                                                                  },
                                                              },
                                                          },
                                                      }}
                                                      disableFocusListener
                                                      disableHoverListener
                                                      disableTouchListener
                                                      arrow
                                                      TransitionComponent={Zoom}
                                                      placement="top"
                                                      title={item?.label_displayname}
                                                  >
                                                      <div
                                                          key={index}
                                                          id={`${item.is_item !== "0" ? "Inv-lineitem" : "Inv-" + item.id}`}
                                                          className={`${item?.label_name === "invoice_no" ? `tooltip-step` : ``} ${
                                                              item.is_item !== "0" ? "Inv-lineitem_" + item?.id : ""
                                                          } ${
                                                              item.label_value
                                                                  ? "absolute cursor-pointer line-items bg-[rgba(247,144,10,0.1)] rounded-[5px] border-[1px] z-10 border-[#F6900A]"
                                                                  : ""
                                                          }`}
                                                          onMouseEnter={() => {
                                                              setFocusLineItem({
                                                                  name: item?.label_name,
                                                                  id: item?.id,
                                                              });
                                                          }}
                                                          onMouseOut={() => {
                                                              setFocusLineItem({
                                                                  name: "",
                                                                  id: "",
                                                              });
                                                          }}
                                                          onClick={(event) =>
                                                              handleClick(
                                                                  "bottom-end",
                                                                  item.label_name,
                                                                  item.label_value,
                                                                  event,
                                                                  item.id,
                                                                  item.is_item,
                                                                  item?.ismandatory,
                                                                  item?.maxlength,
                                                                  item?.data_type,
                                                                  item?.iseditable,
                                                                  item?.is_confirmed,
                                                                  item?.label_displayname
                                                              )
                                                          }
                                                          style={{
                                                              top: `${top}px`,
                                                              left: `${left}px`,
                                                              width: `${width + 2}px`,
                                                              height: `${height + 3}px`,
                                                          }}
                                                      >
                                                          {}
                                                      </div>
                                                  </Tooltip>
                                              );
                                          }
                                      })
                                    : ""}
                            </div>
                        </ReactCrop>
                        <div></div>
                        {cropImgtext !== "" && cropImgDate !== "" && openCropImgContent ? (
                            <ClickAwayListener
                                mouseEvent="onMouseDown"
                                touchEvent="onTouchStart"
                                anchorEl={anchorImgEl}
                                onClickAway={() => handleClickAwayImgContent()}
                            >
                                <Popper
                                    ref={popref}
                                    disablePortal
                                    style={{
                                        top: `${crop?.y > 420 || crop?.x < 90 || crop?.x > 700 ? "40%" : `${cropTop}px`} `,
                                        left: `${crop?.y > 420 || crop?.x < 90 || crop?.x > 700 ? "30%" : `${cropLeft}px`}`,
                                    }}
                                    id="crop-image-uplod-content"
                                    className={`max-w-[400px] bg-${currentMode}-card shadow-[0px_4px_20px_rgba(85,85,85,0.2)] px-5 py-3 rounded-[10px] popper-blk`}
                                    open={true}
                                    transition
                                >
                                    {({ TransitionProps }) => (
                                        <Fade {...TransitionProps} timeout={350}>
                                            <div id="fieldBox" className={`flex flex-col bg-${currentMode}-card`}>
                                                <div
                                                    id="fieldBox"
                                                    className={`flex flex-col bg-${currentMode}-card  text-${currentMode}-copy text-left`}
                                                >
                                                    <FormControl
                                                        sx={{
                                                            maxWidth: 170,
                                                            // margin: "0auto",
                                                        }}
                                                    >
                                                        <Select
                                                            onChange={(e) => handleSelectField(e)}
                                                            sx={{
                                                                backgroundColor: "transparent",
                                                                color: dropdownText,
                                                                "& .MuiSelect-select": {
                                                                    background: "transparent",
                                                                    color: "#555555",
                                                                },
                                                            }}
                                                            className={`border-0`}
                                                            name="allfields"
                                                            displayEmpty
                                                            inputProps={{
                                                                "aria-label": "Without label",
                                                            }}
                                                            SelectDisplayProps={{
                                                                style: {
                                                                    paddingTop: 1,
                                                                    paddingBottom: 1,
                                                                    maxWidth: "150px",
                                                                    border: 0,
                                                                    paddingRight: "0 !important",
                                                                    color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                                },
                                                            }}
                                                            MenuProps={{
                                                                sx: {
                                                                    "& .MuiMenu-paper": {
                                                                        backgroundColor: dropdownColor,
                                                                        color: dropdownText,
                                                                        boxShadow: dropdownShadow,
                                                                        border: dropdownBorder,
                                                                        borderRadius: "8px",
                                                                    },
                                                                    "& .MuiMenuItem-root:hover": {
                                                                        backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                                    },
                                                                    "& .Mui-selected": {
                                                                        backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                                    },
                                                                },
                                                            }}
                                                            IconComponent={() => (
                                                                <img
                                                                    className="absolute opacity-50 right-[14px] pointer-events-none"
                                                                    src={
                                                                        currentMode !== "dark"
                                                                            ? `/images/header/chevronDown.svg`
                                                                            : `/images/header/chevronDown-white.svg`
                                                                    }
                                                                    alt="chevronDown"
                                                                />
                                                            )}
                                                        >
                                                            <IconButton
                                                                onClick={() => setFieldText(false)}
                                                                size="small"
                                                                className="absolute  cursor-pointer right-[1rem]"
                                                            >
                                                                <img src="/images/invoice/cls-sm.svg" alt="cls" className="convertWhite" />
                                                            </IconButton>
                                                            <span className={`flex ${currentMode !== "dark" ? "!text-white" : "!text-[#1F1F1F]"}`}>
                                                                {selectedFieldText || "Select Field Type"}
                                                            </span>
                                                            {notConfirmedList &&
                                                                notConfirmedList.map((item, index) => {
                                                                    return (
                                                                        <MenuItem key={index} value={item.id}>
                                                                            {item.label_displayname}
                                                                        </MenuItem>
                                                                    );
                                                                })}
                                                        </Select>
                                                    </FormControl>

                                                    <span
                                                        className="absolute top-[1rem] cursor-pointer right-[1.3rem]"
                                                        onClick={() => handleClickAwayImgContent()}
                                                    >
                                                        <img src="/images/invoice/cls-sm.svg" alt="cls" className="convertWhite" />
                                                    </span>
                                                    <label
                                                        htmlFor=""
                                                        className="text-[#fff]"
                                                        style={{
                                                            "text-transform": "capitalize",
                                                        }}
                                                    ></label>
                                                    {fieldType == "d" ? (
                                                        <div className="datePic mt-[5px] mx-auto mb-[20px] inv">
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DesktopDatePicker
                                                                    inputFormat={userFormat}
                                                                    OpenPickerButtonProps={{
                                                                        style: {
                                                                            color: "white",
                                                                        },
                                                                    }}
                                                                    disabled={!exStatusId || !findEditable}
                                                                    value={cropImgDate}
                                                                    components={{
                                                                        OpenPickerIcon: AccessIcon,
                                                                    }}
                                                                    className="text-white"
                                                                    disableFuture
                                                                    minDate={"2000"}
                                                                    maxDate={MaxDate}
                                                                    id={`${"Inv-txt-"}${selectedFieldId}`}
                                                                    onChange={(e) => invoiceImgCropedDateChng(e)}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            className="border-[rgba(85,85,85,0.5)] bg-transparent px-[20px] py-1 mt-2 border-[1px] mb-4 rounded-[5px]"
                                                                            value={cropImgDate}
                                                                            // error={error}
                                                                            // helperText={error ? 'Invalid date format' : ''}
                                                                            {...params}
                                                                            inputProps={{
                                                                                ...params.inputProps,
                                                                                readOnly: true,
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                            </LocalizationProvider>
                                                        </div>
                                                    ) : fieldType == "s" && findTextType == "purchase_order_no" ? (
                                                        <div className="solo mt-2 relative">
                                                            <Input
                                                                type="text"
                                                                disabled={InputDisable}
                                                                id={`${"Inv-txt-img"}`}
                                                                className={`${"max-w-[200px] border-[rgba(85,85,85,0.5)] bg-transparent px-[20px] py-1  border-[1px] rounded-[5px] mt-0 mb-4"}`}
                                                                onChange={(e) => invoiceImgCropedTxtChng(e, e.target.value)}
                                                                value={cropImgtext}
                                                            />
                                                            {cropImgtext && cropImgtext.length !== 0 ? (
                                                                <img
                                                                    className="absolute right-[32px] top-[40%] z-[2] cursor-pointer translate-y-[-62%]"
                                                                    onClick={() => resetInputField(currentItem)}
                                                                    src="/images/common/closeBlk.svg"
                                                                    alt="close"
                                                                />
                                                            ) : (
                                                                ""
                                                            )}
                                                            {popperOpen && searchShow && poNumberList?.length !== 0 && poNumberList !== undefined ? (
                                                                <Popper
                                                                    open={Boolean(poNumberList?.length !== 0)}
                                                                    anchorEl={anchorEls}
                                                                    disablePortal
                                                                    // transition
                                                                    className="popperTbl z-[22]"
                                                                >
                                                                    <div
                                                                        className={`max-h-[340px] overflow-y-auto bg-${currentMode}-card border rounded-[8px]`}
                                                                    >
                                                                        <table
                                                                            className={`w-[202px] max-h-[230px] overflow-y-auto bg-${currentMode}-card rolemasterTable  rounded-[8px] text-left`}
                                                                        >
                                                                            <thead className="sticky top-0">
                                                                                <tr className="">
                                                                                    <th className="pl-1 pr-1">PO. No.crop</th>
                                                                                    <th className="pl-1">Vendor</th>
                                                                                    <th>Vendor type</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className="">
                                                                                {cropImgtext !== "" &&
                                                                                cropImgtext !== null &&
                                                                                fieldType == "s" &&
                                                                                findTextType == "purchase_order_no" &&
                                                                                filteredPoNoList !== undefined &&
                                                                                filteredPoNoList?.length !== 0
                                                                                    ? filteredPoNoList?.map((elem) => {
                                                                                          return (
                                                                                              <tr
                                                                                                  className="cursor-pointer"
                                                                                                  onClick={(e) =>
                                                                                                      invoiceImgCropedTxtChng(
                                                                                                          e,
                                                                                                          elem?.PoNumber,
                                                                                                          undefined,
                                                                                                          "click"
                                                                                                      )
                                                                                                  }
                                                                                              >
                                                                                                  <td className="pl-1 pr-1">{elem?.PoNumber}</td>
                                                                                                  <td
                                                                                                      title={elem?.VendorName}
                                                                                                      className="max-w-[390px] pl-1 truncate"
                                                                                                  >
                                                                                                      {elem?.VendorName}
                                                                                                  </td>
                                                                                                  <td className="flex">{elem?.VendorType}</td>
                                                                                              </tr>
                                                                                          );
                                                                                      })
                                                                                    : poNumberList?.map((item) => {
                                                                                          return (
                                                                                              <tr
                                                                                                  className="cursor-pointer"
                                                                                                  onClick={(e) =>
                                                                                                      invoiceImgCropedTxtChng(
                                                                                                          e,
                                                                                                          item?.PoNumber,
                                                                                                          undefined,
                                                                                                          "click"
                                                                                                      )
                                                                                                  }
                                                                                              >
                                                                                                  <td className="pl-1 pr-1">{item?.PoNumber}</td>
                                                                                                  <td
                                                                                                      title={item?.VendorName}
                                                                                                      className="max-w-[390px] pl-1 truncate"
                                                                                                  >
                                                                                                      {item?.VendorName}
                                                                                                  </td>
                                                                                                  <td className="flex">{item?.VendorType}</td>
                                                                                              </tr>
                                                                                          );
                                                                                      })}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </Popper>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    ) : fieldType == "s" && findTextType == "company_name" ? (
                                                        <div className="solo mt-[12px] mb-[20px]">
                                                            <Autocomplete
                                                                freeSolo
                                                                id={`country-select-demo`}
                                                                // id="free-solo-demo"
                                                                autoSelect
                                                                // disablePortal
                                                                className="invSolo"
                                                                disabled={!exStatusId || !findEditable}
                                                                sx={{
                                                                    width: 218,
                                                                    zIndex: 9999, // Corrected from zIntex to zIndex
                                                                    color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                                }}
                                                                value={cropImgtext}
                                                                onInputChange={(e, newVal) => setCropImgText(newVal)}
                                                                onChange={(e, newVal) => {
                                                                    invoiceImgCropedTxtChng(e, e.target.textContent, newVal);
                                                                }}
                                                                options={
                                                                    // findTextType === "company_name" &&
                                                                    cropVendorName == "" && filteredOptions?.length == 0
                                                                        ? vendorList?.map((el) => el?.VendorName)
                                                                        : filteredOptions?.map((el) => el?.VendorName) || []
                                                                }
                                                                filterOptions={filterOptions}
                                                                getOptionLabel={(option) => option?.VendorName || option}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        onChange={(e, newVal) => {
                                                                            invoiceImgCropedTxtChng(e, e.target.textContent, newVal);
                                                                        }}
                                                                        sx={{
                                                                            "& .MuiInputBase-input": {
                                                                                color: `${currentMode === "dark" ? "white" : "black"} !important`,
                                                                            },
                                                                        }}
                                                                        {...params}
                                                                        placeholder="Select"
                                                                        endAdornment={null}
                                                                        inputProps={{
                                                                            ...params.inputProps,
                                                                            autoComplete: "new-password", // disable autocomplete and autofill
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    ) : fieldType == "m" || fieldType == "n" || fieldType == "p" ? (
                                                        <NumericFormat
                                                            maxlength={50}
                                                            allowNegative={false}
                                                            className={`border-[rgba(85,85,85,0.5)] bg-transparent px-[20px] py-1 mt-[5px] border-[1px] mb-[20px] rounded-[5px]`}
                                                            value={cropImgtext}
                                                            displayType="input"
                                                            decimalScale={2}
                                                            thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                                                            disabled={!exStatusId || !findEditable}
                                                            id={`${"Inv-txt-img"}`}
                                                            decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                                                            onChange={(e, newVal) => invoiceImgCropedTxtChng(e, e.target.value)}
                                                        />
                                                    ) : (
                                                        <textarea
                                                            style={{
                                                                height: "100px",
                                                            }}
                                                            disabled={InputDisable}
                                                            id={`${"Inv-txt-img"}`}
                                                            className={`${"border-[rgba(85,85,85,0.5)] resize-none bg-transparent px-[20px] py-1 mt-[5px] border-[1px] mb-[20px] rounded-[5px]"}`}
                                                            onChange={(e) => invoiceImgCropedTxtChng(e, e.target.value)}
                                                            value={cropImgtext}
                                                        />
                                                    )}
                                                    {errorcheck?.message && (
                                                        <p className="text-light-110 text-xs mt-[-5px] mb-2">{errorcheck?.message}</p>
                                                    )}
                                                    <div className="flex justify-between items-center space-x-3 ">
                                                        {status !== "7" && showIcon && (
                                                            <Button
                                                                className="cursor-pointer"
                                                                disabled={InputDisable}
                                                                onClick={() => handleClickAwayImgContent()}
                                                            >
                                                                <img src="/images/invoice/trash-red.svg" alt="trash-red" />
                                                            </Button>
                                                        )}
                                                        {exStatusId && showIcon ? (
                                                            (pageType !== 3 && RoleActProcessEdit?.isallowed == true) ||
                                                            ((findObj?.iseditable === true || currentItem?.iseditable === true) &&
                                                                props.invoiceList?.ext_status_id !== 3 &&
                                                                props.invoiceList?.ext_status_id !== 4 &&
                                                                props.invoiceList?.ext_status_id !== 5) ? (
                                                                <Button
                                                                    className="bg-light-20 px-[15px] py-[4px] text-white rounded-[5px] flex items-center"
                                                                    onClick={() => invFieldUpdateWithContent()}
                                                                    disabled={
                                                                        !exStatusId ||
                                                                        !findEditable ||
                                                                        InputDisable ||
                                                                        (cropImgtext !== "" &&
                                                                            cropImgDate !== "" &&
                                                                            openCropImgContent &&
                                                                            !findTextType)
                                                                    }
                                                                >
                                                                    <img className="mr-2" src="/images/common/tickWhite.svg" alt="tickWhite" />{" "}
                                                                    Confirm
                                                                </Button>
                                                            ) : null
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </Fade>
                                    )}
                                </Popper>
                            </ClickAwayListener>
                        ) : (
                            ""
                        )}
                        <ClickAwayListener
                            mouseEvent="onMouseDown"
                            touchEvent="onTouchStart"
                            onClickAway={props?.currentIndex ? "" : () => handleClickAway()}
                        >
                            <Popper
                                ref={popref}
                                className={`bg-${currentMode}-card px-5 py-6 rounded-[10px] shadow-[0px_4px_20px_rgba(85,85,85,0.2)] popper-blk`}
                                open={open}
                                anchorEl={anchorEl}
                                disablePortal
                                transition
                            >
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} timeout={350}>
                                        <div id="fieldBox" className={`flex flex-col bg-${currentMode}-card text-${currentMode}-copy text-left`}>
                                            <label
                                                htmlFor=""
                                                style={{
                                                    "text-transform": "capitalize",
                                                }}
                                            >
                                                {currentItem?.label_displayname}
                                            </label>
                                            <span className="absolute top-[1rem] cursor-pointer right-[1.3rem]" onClick={() => handleClickAway()}>
                                                <img src="/images/invoice/cls-sm.svg" alt="cls" className="convertWhite" />
                                            </span>
                                            {currentItem?.data_type === "d" ? (
                                                <div className="datePic mt-[12px] mb-[20px] inv">
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DesktopDatePicker
                                                            disabled={InputDisable}
                                                            selectsStart
                                                            minDate={"2000"}
                                                            maxDate={MaxDate}
                                                            inputFormat={userFormat}
                                                            // inputFormat={"DD/MM/YY"}
                                                            value={currentItem?.label_value}
                                                            components={{
                                                                OpenPickerIcon: AccessIcon,
                                                            }}
                                                            onFocus={props?.isPlaying ? () => props?.setIsFocused(true) : null}
                                                            className="!text-white"
                                                            id={`${"Inv-txt-"}${currentItem.id}`}
                                                            onChange={(e) =>
                                                                invoiceDateChng(
                                                                    currentItem.label_name,
                                                                    e,
                                                                    currentItem.id,
                                                                    currentItem?.data_type,
                                                                    currentItem?.iseditable,
                                                                    currentItem?.is_confirmed,
                                                                    currentItem?.label_displayname
                                                                )
                                                            }
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    sx={{
                                                                        "& .MuiInputBase-input": {
                                                                            color: `${currentMode == "dark" ? "white" : "black"} !important`,
                                                                        },
                                                                    }}
                                                                    onFocus={props?.isPlaying ? () => props?.setIsFocused(true) : null}
                                                                    disabled={status == "7"}
                                                                    className="border-[rgba(85,85,85,0.5)] bg-transparent px-[20px] py-1 border-[1px] rounded-[5px]"
                                                                    value={currentItem?.label_value}
                                                                    {...params}
                                                                    inputProps={{
                                                                        ...params.inputProps,
                                                                        readOnly: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </div>
                                            ) : currentItem?.data_type === "s" && currentItem?.label_name == "purchase_order_no" ? (
                                                <div className="solo mt-2">
                                                    <Input
                                                        type="text"
                                                        disabled={InputDisable}
                                                        onFocus={props?.isPlaying ? () => props?.setIsFocused(true) : null}
                                                        id={`${"Inv-txt-"}${currentItem.id}`}
                                                        className={`${"max-w-[200px] border-[rgba(85,85,85,0.5)] bg-transparent px-[20px] py-1  border-[1px] rounded-[5px] mt-2"}`}
                                                        onChange={(e, newVal) =>
                                                            invoiceTxtChng(
                                                                e,
                                                                currentItem.label_name,
                                                                e.target.value,
                                                                currentItem.id,
                                                                currentItem?.data_type,
                                                                currentItem?.iseditable,
                                                                currentItem?.is_confirmed,
                                                                undefined,
                                                                currentItem?.label_displayname
                                                            )
                                                        }
                                                        value={currentItem?.label_value}
                                                    />
                                                    {currentItem.label_value &&
                                                    currentItem.label_value.length !== 0 &&
                                                    !fetching &&
                                                    requestComplete ? (
                                                        <img
                                                            className="absolute right-[28px] top-[48%] z-[2] cursor-pointer translate-y-[-62%]"
                                                            onClick={() => resetInputField(currentItem)}
                                                            src="/images/common/closeBlk.svg"
                                                            alt="close"
                                                        />
                                                    ) : (
                                                        ""
                                                    )}
                                                    {popperOpen && searchShow && poNumberList?.length !== 0 && poNumberList !== undefined ? (
                                                        <Popper
                                                            open={Boolean(poNumberList?.length !== 0)}
                                                            anchorEl={anchorEls}
                                                            disablePortal
                                                            className="popperTbl z-[22]"
                                                        >
                                                            <div
                                                                className={`max-h-[340px] overflow-y-auto bg-${currentMode}-card border rounded-[8px]`}
                                                            >
                                                                <table
                                                                    className={`w-[202px] max-h-[230px] overflow-y-auto bg-${currentMode}-card rolemasterTable  rounded-[8px] text-left`}
                                                                >
                                                                    <thead className="sticky top-0">
                                                                        <tr className="">
                                                                            <th className="">PO.No.</th>
                                                                            <th className="">Vendor</th>
                                                                            <th>Vendor type</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="">
                                                                        {currentItem?.label_value !== "" &&
                                                                        currentItem?.label_value !== null &&
                                                                        filteredPoNoList !== undefined &&
                                                                        filteredPoNoList?.length !== 0
                                                                            ? filteredPoNoList?.map((elem) => {
                                                                                  return (
                                                                                      <tr
                                                                                          className="cursor-pointer"
                                                                                          onClick={(e) =>
                                                                                              invoiceTxtChng(
                                                                                                  e,
                                                                                                  currentItem.label_name,
                                                                                                  elem?.PoNumber,
                                                                                                  currentItem.id,
                                                                                                  currentItem?.data_type,
                                                                                                  currentItem?.iseditable,
                                                                                                  currentItem?.is_confirmed,
                                                                                                  "click",
                                                                                                  currentItem?.label_displayname,
                                                                                                  elem?.VendorName
                                                                                              )
                                                                                          }
                                                                                      >
                                                                                          <td className="pl-1 pr-1">{elem?.PoNumber}</td>
                                                                                          <td className="max-w-[390px] pl-1 truncate">
                                                                                              {elem?.VendorName}
                                                                                          </td>
                                                                                          <td className="flex">{elem?.VendorType}</td>
                                                                                      </tr>
                                                                                  );
                                                                              })
                                                                            : poNumberList?.map((item) => {
                                                                                  return (
                                                                                      <tr
                                                                                          className="cursor-pointer"
                                                                                          onClick={(e) =>
                                                                                              invoiceTxtChng(
                                                                                                  e,
                                                                                                  currentItem.label_name,
                                                                                                  item?.PoNumber,
                                                                                                  currentItem.id,
                                                                                                  currentItem?.data_type,
                                                                                                  currentItem?.iseditable,
                                                                                                  currentItem?.is_confirmed,
                                                                                                  "click",
                                                                                                  currentItem?.label_displayname
                                                                                              )
                                                                                          }
                                                                                      >
                                                                                          <td className="pl-1 pr-1">{item?.PoNumber}</td>
                                                                                          <td
                                                                                              title={item?.VendorName}
                                                                                              className="max-w-[390px] pl-1 truncate"
                                                                                          >
                                                                                              {item?.VendorName}
                                                                                          </td>
                                                                                          <td className="flex">{item?.VendorType}</td>
                                                                                      </tr>
                                                                                  );
                                                                              })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </Popper>
                                                    ) : fetching && !requestComplete ? (
                                                        <Loader className="h-[calc(100vh_-_17rem)]" />
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            ) : currentItem?.data_type === "s" && currentItem?.label_name == "company_name" ? (
                                                <div className="solo mt-[12px] mb-[20px]">
                                                    <Autocomplete
                                                        freeSolo
                                                        disabled={InputDisable}
                                                        sx={{
                                                            width: 218,
                                                            zIndex: 9999,
                                                            color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                        }}
                                                        // id={`free-solo-demo${"Inv-txt-"}${currentItem.id}`}
                                                        id={`country-select-demo-${"Inv-txt-"}${currentItem.id}`}
                                                        autoSelect
                                                        className="invSolo"
                                                        onInputChange={(e, newVal) => setCropImgText(newVal)}
                                                        value={currentItem?.label_value}
                                                        onFocus={props?.isPlaying ? () => props?.setIsFocused(true) : null}
                                                        onChange={(e, newVal) => {
                                                            invoiceTxtChng(
                                                                e,
                                                                currentItem.label_name,
                                                                e.target.textContent === "" ? e.target.value : e.target.textContent,
                                                                currentItem.id,
                                                                currentItem?.data_type,
                                                                currentItem?.iseditable,
                                                                currentItem?.is_confirmed,
                                                                undefined,
                                                                currentItem?.label_displayname
                                                            );
                                                        }}
                                                        options={
                                                            currentItem.label_value == "" && filteredOptions?.length == 0
                                                                ? vendorList?.map((el) => el?.VendorName)
                                                                : filteredOptions?.map((el) => el?.VendorName) || []
                                                        }
                                                        filterOptions={filterOptions}
                                                        getOptionLabel={(option) => option?.VendorName || option}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                onFocus={props?.isPlaying ? () => props?.setIsFocused(true) : null}
                                                                onChange={(e, newVal) => {
                                                                    invoiceTxtChng(
                                                                        e,
                                                                        currentItem.label_name,
                                                                        e.target.textContent === "" ? e.target.value : e.target.textContent,
                                                                        currentItem.id,
                                                                        currentItem?.data_type,
                                                                        currentItem?.iseditable,
                                                                        currentItem?.is_confirmed,
                                                                        undefined,
                                                                        currentItem?.label_displayname
                                                                    );
                                                                }}
                                                                {...params}
                                                                placeholder="Select"
                                                                endAdornment={null}
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    autoComplete: "new-password", // disable autocomplete and autofill
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            ) : currentItem?.data_type == "n" || currentItem?.data_type == "m" || currentItem?.data_type == "p" ? (
                                                <NumericFormat
                                                    maxlength={currentItem?.items?.maxlength == 0 ? 20 : 50}
                                                    allowNegative={false}
                                                    className={`border-[rgba(85,85,85,0.5)] bg-transparent px-[20px] py-1 border-[1px] mt-[12px] mb-[20px] rounded-[5px]`}
                                                    value={currentItem?.label_value}
                                                    displayType="input"
                                                    decimalScale={2}
                                                    thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                                                    decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                                                    disabled={InputDisable}
                                                    id={`${"Inv-txt-"}${currentItem.id}`}
                                                    onChange={(e, newVal) =>
                                                        invoiceTxtChng(
                                                            e,
                                                            currentItem.label_name,
                                                            e.target.value,
                                                            currentItem.id,
                                                            currentItem?.data_type,
                                                            currentItem?.iseditable,
                                                            currentItem?.is_confirmed,
                                                            undefined,
                                                            currentItem?.label_displayname
                                                        )
                                                    }
                                                    // onFocus={props?.isPlaying ? () => props?.setIsFocused(true) : null}
                                                />
                                            ) : (
                                                <Input
                                                    type={currentItem == "n" ? `number` : `text`}
                                                    disabled={InputDisable}
                                                    id={`${"Inv-txt-"}${currentItem.id}`}
                                                    pattern={currentItem?.data_type == "d" && `\d{4}-\d{1,2}-\d{1,2}`}
                                                    className={`${"border-[rgba(85,85,85,0.5)] bg-transparent px-[20px] py-1 mt-[12px] mb-[20px] border-[1px] rounded-[5px]"}`}
                                                    onChange={(e, newVal) =>
                                                        invoiceTxtChng(
                                                            e,
                                                            currentItem.label_name,
                                                            e.target.value,
                                                            currentItem.id,
                                                            currentItem?.data_type,
                                                            currentItem?.iseditable,
                                                            currentItem?.is_confirmed,
                                                            undefined,
                                                            currentItem?.label_displayname
                                                        )
                                                    }
                                                    value={currentItem?.label_value}
                                                    onFocus={props?.isPlaying ? () => props?.setIsFocused(true) : null}
                                                />
                                            )}
                                            {checkErr && <p className="text-light-110 text-xs mt-[-5px] mb-2">{checkErr}</p>}
                                            <div
                                                className={`flex items-center justify-between space-x-3  ${
                                                    currentItem?.label_name == "purchase_order_no" ? "mt-4" : ""
                                                } `}
                                            >
                                                {status !== "7" && showIcon && (
                                                    <Button
                                                        className="cursor-pointer"
                                                        disabled={InputDisable}
                                                        onClick={() => handleDeleteValue(currentItem?.id, currentItem?.label_value)}
                                                    >
                                                        <img src="/images/invoice/trash-red.svg" alt="trash-red" />
                                                    </Button>
                                                )}
                                                {exStatusId && showIcon ? (
                                                    (pageType !== 3 && RoleActProcessEdit?.isallowed == true) ||
                                                    (currentItem?.iseditable === true &&
                                                        props.invoiceList?.ext_status_id !== 3 &&
                                                        props.invoiceList?.ext_status_id !== 4 &&
                                                        props.invoiceList?.ext_status_id !== 5) ? (
                                                        <Button
                                                            className="bg-light-20 px-[15px] py-[4px] text-white rounded-[5px] flex items-center"
                                                            disabled={
                                                                fetching ||
                                                                InputDisable ||
                                                                (cropImgtext !== "" && cropImgDate !== "" && openCropImgContent && !findTextType)
                                                            }
                                                            onClick={() =>
                                                                invFieldUpdate(
                                                                    currentItem?.id,
                                                                    currentItem?.data_type == "m" ||
                                                                        currentItem?.data_type == "n" ||
                                                                        currentItem?.data_type == "p"
                                                                        ? sanitizeValue(currentItem?.label_value)
                                                                        : currentItem?.label_value,
                                                                    currentItem?.label_name,
                                                                    currentItem?.VendorName
                                                                )
                                                            }
                                                        >
                                                            <img className="mr-2" src="/images/common/tickWhite.svg" alt="tickWhite" /> Confirm
                                                        </Button>
                                                    ) : null
                                                ) : null}
                                            </div>
                                        </div>
                                    </Fade>
                                )}
                            </Popper>
                        </ClickAwayListener>
                    </>
                ) : (
                    <Loader className="h-screen max-h-[-webkit-fill-available]" />
                )}
                {/* Show line item Table */}
                {props?.isClickedItem && (
                    <InvTable
                        setCheckFreightByPo={props?.setCheckFreightByPo}
                        checkFreightByPo={props?.checkFreightByPo}
                        defaultData={props.invoiceList}
                        poNumberList={poNumberList}
                        totalRetentionAmount={props?.totalRetentionAmount}
                        setTotRetentionAmount={props?.setTotRetentionAmount}
                        updateDocList={props?.updateDocList}
                        checkArraysEqual={props?.checkArraysEqual}
                        setCheckArraysEqual={props?.setCheckArraysEqual}
                        setAreArraysEqual={props?.setAreArraysEqual}
                        areArraysEqual={props?.areArraysEqual}
                        isClickedItem={props?.isClickedItem}
                        removeCostData={props?.removeCostData}
                        setRemoveCostData={props?.setRemoveCostData}
                        validateChanges={props?.validateChanges}
                        handleValidateChanges={props?.handleValidateChanges}
                        validateErr={props?.validateErr}
                        setValidateErr={props?.setValidateErr}
                        updtTblListData={props?.updtTblListData}
                        setUpdtTblListData={props?.setUpdtTblListData}
                        removeLineItem={props?.removeLineItem}
                        setRemoveLineItem={props?.setRemoveLineItem}
                        costListData={props?.costListData}
                        setCostListData={props?.setCostListData}
                        setShowToolTip={setShowToolTip}
                        setTableList={props?.setTableList}
                        tblListData={props?.tblListData}
                        statusId={exStatusId}
                        setMandatorLineItems={props?.setMandatorLineItems}
                        mandatoryLineItems={props?.mandatoryLineItems}
                        Err={props?.Err}
                        totalAmountVal={totalAmountVal}
                        setTotalAmountVal={setTotalAmountVal}
                        nonPOItemList={nonPOItemList}
                        valids={valids}
                        setValids={setValids}
                        itemsHeader={lineItemHeader}
                        ListItems={lineItems}
                        setLineItems={setLineItems}
                        tableClose={props?.onShowLineItem}
                        updateInvoiceDocument={updateInvoiceDocument}
                        addRow={addRow}
                        setAddRow={setAddRow}
                        lineitemData={props?.lineitemData}
                        setLineItemData={props?.setLineItemData}
                        mainData={props?.invoiceList}
                        objectLength={props?.objectLength}
                        setLineItemObjectLength={props?.setLineItemObjectLength}
                        invNo={props?.invNo}
                        serviceFillUpdateData={serviceFillUpdateData}
                        fetch={fetch}
                        servicePoItemData={servicePoItemData}
                        setNonPoItemList={props?.setNonPoItemList}
                        setNonPOItemList={props?.setNonPOItemList}
                    />
                )}
                {props?.clickTaxOpen && (
                    <TaxTableLineItem
                        totalRetentionAmount={props?.totalRetentionAmount}
                        setTotRetentionAmount={props?.setTotRetentionAmount}
                        totalTax={props?.totalTax}
                        setTotalTax={props?.setTotalTax}
                        tableTaxItem={tableTaxItem}
                        setTableTaxItem={setTableTaxItem}
                        updatedTaxData={props?.updatedTaxData}
                        setUpdatedTaxData={props?.setUpdatedTaxData}
                        clickTaxOpen={props?.clickTaxOpen}
                        setClickTaxOpen={props?.setClickTaxOpen}
                        validateChanges={props?.validateChanges}
                        handleValidateChanges={props?.handleValidateChanges}
                        // setShowToolTip={setShowToolTip}
                    />
                )}
            </div>
        </>
    );
};

export default Content;
