import {
    ClickAwayListener,
    Fade,
    Checkbox,
    Popper,
    Box,
    LinearProgress,
    Typography,
    Switch,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
    Autocomplete,
    Tooltip,
    FormControl,
    Select,
    MenuItem,
} from "@mui/material";
import Swal from "sweetalert2";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
    UpdateInvoice,
    InvoiceStatusApproved,
    InvoiceStatusRejected,
    InvoiceFlag,
    getInvoiceItems,
    getInvApprove,
    getPoNumberList,
    getSapVendorList,
    getInvoiceCalc,
} from "../../../redux/actions/invoice";
import { RolePermissionDisplay, roleid } from "../../../redux/actions/role";
import ReactDOM from "react-dom";
import Button from "../../../components/Button";
import { useState, useEffect, useRef, useContext, useMemo } from "react";
import TogglePopup from "../../organisations/Toggle";
import Activity from "../../../organisms/ActivityModal";
import { ActivityHistory, getCurrentUser } from "../../../redux/actions/auth";
import ShowMoreModal from "./ShowMoreModal";
import moment from "moment/moment";
import Input from "../../../components/Input";
import ToReassign from "../Reassign";
import {
    GET_INVOICE_CALC_DETAIL_VIEW_RESET,
    GET_INVOICE_CALC_VIEW_RESET,
    GET_INVOICE_CALC_VIEW_UPDATE,
    INVOICE_APPROVED_LIST_RESET,
    INVOICE_REJECTED_LIST_RESET,
    POST_INVOICE_APPROVER_RESET,
} from "../../../redux/types/types";
import { MyTask } from "../Tasks/MyTask";
import { getInvoiceTaskList } from "../../../redux/actions/my-task";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
    decryptId,
    downloadeExcel,
    showSwal,
    showSwalWithConfirmation,
    generateFormattedText,
    SelectDropdwnImg,
    formatNumber,
    amountUpdate,
    autoCalculate,
    autoCalc,
} from "../../../utils/helpers";
import TransferInvoice from "../Processed/TransferInvoice";
import { listOrganisationDropDown } from "../../../redux/actions/organisation";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import { NumericFormat } from "react-number-format";
import { postThreeWayMatching, getTaxCodeList } from "../../../redux/actions/invoice";
import { getPoType } from "../../../redux/actions/invoice";
import DynamicImageComponent from "./dynamicImageComponent";
import CurrentAndNextStatus from "./CurrentAndNextStatus";
import SelectUserModal from "./SelectUserModel";
import { postChangeWorkflowStatus } from "../../../redux/actions/workflow-automation";
import { POST_CHANGE_WORKFLOW_STATUS_RESET } from "../../../redux/types/types";
import { CommentModal } from "./CommentModal";
import { userId } from "../../../Hooks/userId/Index";
import {
    GetAdvanceDetailsforCreate,
    GetListUserForComments,
    ProformaUpdateInvoice,
    getProformaInvoiceItems,
} from "../../../redux/actions/advance-request";
import { Link, useNavigate, useLocation } from "react-router-dom";

let dateString;
let excMsg;
let msgCategory;
let vendorNameChange;
let updatedInvoiceList = [];
const Sidebar = (props) => {
    const {
        convertingArray,
        advData,
        miscData,
        setConvertingArray,
        setAdvData,
        setMiscData,
        isInvCalPop,
        clicked,
        setClicked,
        creditData,
        setCreditData,
    } = props;
    const { currentMode } = useContext(ThemeContext);
    const nonPOItemList = [...new Set(props?.nonPOItemList)];
    const dispatch = useDispatch();
    const CurrentUser = getCurrentUser();
    const date = new Date();
    const d = date.toLocaleDateString("en-GB");
    const OrgDateFormat = localStorage.getItem("OrgDateFormat");
    const dateFormat = OrgDateFormat?.toUpperCase();
    const [fieldTypeItmes, setFilteredListItem] = useState([]);
    const orgId = localStorage.getItem("orgId");
    const location = useLocation();
    const docId = props.invoiceList?.document_id == 2 || props.invoiceList?.document_id == 2;

    const DisplayRole = useSelector((state) => state?.DisplayRolePermissionStateAction);
    const { data: taxListData, requestComplete: reqCom } = useSelector((state) => state?.taxCodeWithPercentageListState);

    const { data: DataList } = DisplayRole;

    const RoleList = DataList?.role_info;

    const RoleMenu = RoleList?.menu;
    const [popperOpen, setpopperOpen] = useState(false);
    const [checkChange, setcheckChange] = useState(false);
    const [isHovered, setIsHovered] = useState({
        invoiceCalculation: false,
        reject: false,
        prevStatus: false,
        nextStatus: false,
    });
    const [showStatusEqual, setShowStatusEqual] = useState(true);

    useEffect(() => {
        if (!RoleList) {
            dispatch(RolePermissionDisplay({ orgid: orgId }));
        }
    }, [dispatch]);

    const AccessIcon = () => <img src="/images/common/date-ico.svg" alt="date-ico" />;
    const { data: erpValidateArr, error: err } = useSelector((state) => state?.threeWayMatchingByErpState);

    const validateErr = err?.response?.data?.message;
    const matchingInfoUpdateArr = erpValidateArr?.overallData;
    const onlySelectedLineItem = _.map(_.filter(matchingInfoUpdateArr, { isLineItem: true }), (item) =>
        _.pick(item, [
            "poItemCode",
            "isLineItem",
            "grnMatchedInfo",
            "overall",
            "lineItemNo",
            "errorMessages",
            "retentionAmount",
            "retentionPercentage",
        ])
    );
    const notUpdateddefaultLineItem = erpValidateArr?.onlySelectedLineItem;

    const params = new URLSearchParams(window.location.search);

    const urlData = location?.search.slice(1)?.replace(/\n\s+/g, "\n")?.trim();
    const [handleDel, setHandleDel] = useState(false);

    const handleDeleteValue = (currentID, value, items) => {
        // setpopperOpen(true);
        setSelectedDate("");
        setHandleDel(true);
        if (value !== "") {
            let textVal = null;
            document.querySelector(`${"#Inv-ul-"}${currentID}`).classList.remove("approve");
            items.is_confirmed = false;
            document.querySelector(`${"#Inv-li-"}${currentID}`).innerHTML = textVal;
            fieldTypeItmes.map((item, index) => {
                const fieldVal = item;
                if (item.id == currentID) {
                    fieldVal.label_value = "";
                    fieldVal.text = "";
                    fieldVal.is_confirmed = false;
                    return fieldVal;
                }
            });
            const deletedItem = fieldTypeItmes.find((elem) => elem?.id == currentID);
            const objInv = {
                id: deletedItem?.id,
                label_name: deletedItem.label_name,
                label_value: "",
                pg_no: deletedItem?.pg_no,
                is_confirmed: false,
                box_1x: "0",
                // ...boxValues,
            };
            const update = props?.updateDocList?.filter((item) => item?.id != currentID);
            update.push(objInv);
            props?.onSaveInvoiceDoc(update);
            setFilteredListItem(fieldTypeItmes);
            handleClickAway();
        } else {
            handleClickAway();
        }
    };
    const urlObj = JSON?.parse(decryptId(urlData));
    let pageType = urlObj?.type;
    let status = urlObj?.status;
    let invId = urlObj?.inv;
    let setClick = props?.setisClick;
    const mandatoryLineItems = props?.mandatoryLineItems;
    const RoleProcess = RoleMenu?.find((elem) => elem?.menu_id === 8);
    const RoleProcessAction = RoleProcess?.actions;
    const RoleActProcessEdit = RoleProcessAction?.find((element) => element.id === 2);
    const RoleActProcessReject = RoleProcessAction?.find((element) => element.id === 9);
    const RoleActProcessFlag = RoleProcessAction?.find((element) => element.id === 18);
    const RoleActProcessActivity = RoleProcessAction?.find((element) => element.id === 13);
    const RoleApproval = RoleMenu?.find((elem) => elem?.menu_id === 16);
    const RoleApprovalAction = RoleApproval?.actions;
    const RoleActApprove = RoleApprovalAction?.find((element) => element.id === 6);
    const RoleActapproveReject = RoleApprovalAction?.find((element) => element.id === 9);
    const RoleActapproveFlag = RoleApprovalAction?.find((element) => element.id === 18);
    const RoleActapproveActivity = RoleApprovalAction?.find((element) => element.id === 13);
    const invoiceList = useSelector((state) => state?.getInvoiceItemsState);
    const { data, requestComplete } = invoiceList;
    const dataList = data || [];
    const getMatchingInfo = dataList?.datalist[0]?.matching_info;
    const getRecoverInfo = dataList?.datalist[0]?.recovery_info?.downpaymentInfo;
    const [retentionArr, setRetentionArr] = useState([]);
    const { data: parseData, error: ERR, requestComplete: reqComp, fetching: fetch } = useSelector((state) => state?.addServiceLineItemsState);
    const serviceFillPoItem = parseData?.poItemCodeData;
    const { data: ListData } = useSelector((state) => state?.getPoNumberListState);
    const { data: vendorList } = useSelector((state) => state?.getSapVendorListState);
    const userFormat = localStorage.getItem("userDateFormat")?.toUpperCase();
    const poNumberList = ListData;
    const [checkClick, setCheckClick] = useState(false);
    const navigate = useNavigate();
    let listData = props.invoiceList?.invoice_items;
    const overAllItems = props.invoiceList?.invoice_items;
    const PoNumber = listData?.find((el) => el?.label_name == "purchase_order_no")?.label_value;
    let filteredLineItem = _.filter(listData, {
        is_item: "1",
    });
    useEffect(() => {
        if (props?.currentPlayObj) {
            document?.querySelectorAll("#Inv-" + props?.currentPlayObj?.id)[0]?.click();
            if (props?.currentPlayObj?.box_1x == "0") {
                document.querySelector(`#${"Inv-ul-"}${props?.currentPlayObj?.id}`)?.click();
            }
        }
    }, [props?.currentPlayObj]);

    const IsFlagged = props?.invoiceList?.isflagged;
    const [isSaved, onSaveInvDoc] = useState(false);
    const [isApprove, onApprovenvDoc] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [placement, setPlacement] = useState();
    const [isActivity, setIsActivity] = useState(false);
    const [isreassign, setReassign] = useState(false);
    const [assign, setAssign] = useState(false);
    const [isShowmore, setIsShowmore] = useState(false);
    const [isRententionShow, setIsRententionShow] = useState(false);
    const [open, setOpen] = useState(false);
    const [isReject, onRejectedDoc] = useState(false);
    const [isdownloadClick, onClickDownload] = useState(false);
    const [isToggleClick, setIsToggleClick] = useState(false);
    const [isFlaged, onFlag] = useState(false);
    const [openTransfer, setOpenTransfer] = useState(false);
    const [isError, setIsError] = useState("");
    const [isClickOpen, setisClickOpen] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const childCompRef = useRef();
    const [invNo, getQueryStringVal] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const checkInvBase = props.invoiceList;
    const orgData = useSelector((state) => state?.ListorganisationDropDownState);
    const OrgListData = orgData?.viewData?.length == 1;
    const exStatusId =
        props.invoiceList?.ext_status_id !== 3 &&
        props.invoiceList?.ext_status_id !== 4 &&
        props.invoiceList?.ext_status_id !== 5 &&
        props.invoiceList?.curr_status?.[0]?.master_refid !== 5;
    const flag =
        (exStatusId && pageType !== 3 && pageType !== 4 && pageType !== 5 && RoleActProcessFlag?.isallowed == true) ||
        (pageType == 3 && RoleActapproveFlag?.isallowed == true) ||
        (pageType == 4 && RoleActapproveFlag?.isallowed == true) ||
        (pageType == 5 && RoleActapproveFlag?.isallowed == true)
            ? { text: "(Un)Flag", src: "/images/invoice/flag-new.svg", value: 2 }
            : ``;
    const reload = {
        text: "Reset",
        src: "/images/invoice/rotate-cw.svg",
        value: 3,
    };
    const [vendorCode, setVendorCode] = useState({
        label_name: "vendor_code",
        label_value: "",
    });
    const dateStr1 = CurrentUser?.expiration_date;
    const dateStr2 = d;
    const parseDate = (dateStr) => {
        const [day, month, year] = dateStr?.split("/");
        // Months in JavaScript Date are 0-based, so we need to subtract 1 from the month
        return new Date(`${year}-${month - 1}-${day}`);
    };

    const date1 = parseDate(dateStr1);
    const date2 = parseDate(dateStr2);
    const matchdate = date1 < date2;

    const showIcon =
        (!matchdate && CurrentUser?.license_type == 2) ||
        CurrentUser?.license_type == 1 ||
        CurrentUser?.license_type == 3 ||
        CurrentUser?.license_type == 4;

    const activity =
        (pageType !== 3 && pageType !== 4 && pageType !== 5 && RoleActProcessActivity?.isallowed) ||
        (pageType == 3 && RoleActapproveActivity?.isallowed == true) ||
        (pageType == 4 && RoleActapproveActivity?.isallowed == true) ||
        (pageType == 5 && RoleActapproveFlag?.isallowed == true)
            ? {
                  text: "Activity",
                  src: "/images/common/activity-grn.svg",
                  value: 4,
              }
            : ``;

    const Reassign = pageType == 3 &&
        props?.invoiceList?.ext_status_id == 7 &&
        showIcon && {
            text: "To reassign",
            src: "/images/common/Reassign.svg",
            value: 5,
        };

    const ShowProffesionIcon = (!matchdate && CurrentUser?.license_type == 2) || CurrentUser?.license_type == 1 || CurrentUser?.license_type == 3;
    //* For the and condition (props?.invoiceList?.ext_status_id == 2 || props?.invoiceList?.ext_status_id == 7)
    const Assign = ShowProffesionIcon && exStatusId ? { text: "Task", src: "/images/invoice/user-new.svg", value: 6 } : ``;

    const Transfer =
        pageType !== 3 && showIcon && status == 2 && !OrgListData
            ? {
                  text: "Transfer",

                  src: "/images/invoice/transfer.svg",

                  value: 7,
              }
            : "";

    const currentStatusInfo = dataList?.datalist[0]?.curr_status;
    const prevState = {
        text: "Move to prev status",
        src: "/images/invoice/move-to-pre-state.svg",
        value: 8,
    };

    const filterMenuList = [
        { text: "Download", src: "/images/invoice/download-new.svg", value: 0 },
        location.pathname !== "/proforma-invoice-view" && flag,
        reload,
        activity,
        location.pathname !== "/proforma-invoice-view" && Assign,
        location.pathname !== "/proforma-invoice-view" && Transfer,
        location.pathname !== "/proforma-invoice-view" && { text: "Comments", src: "/images/invoice/message-circle.svg", value: 9 },
    ];
    if (
        dataList?.datalist[0]?.prev_status && ![2, 3, 4, 5]?.includes(currentStatusInfo?.[0]?.master_refid || 0)
            ? dataList?.datalist[0]?.prev_status?.[0]?.prev_status
            : false
    ) {
        filterMenuList?.push(prevState);
    }

    const Activitydata = useSelector((state) => state?.ActivityHistoryState);

    const { data: actData } = Activitydata;

    const CompareData = useSelector((state) => state?.InvoiceCompareState);

    const { dataList: invTaskListData } = useSelector((state) => state?.getInvoiceTaskListState);
    const [totalAmountVal, setTotalAmountVal] = useState(0);

    const { data: compdata } = CompareData;

    const param = new URLSearchParams(window.location.search);

    moment.updateLocale("en", {
        twoDigitYearMax: moment().year() + 10,
    });
    const [searchShow, setSearchShow] = useState(false);
    let invNumber = urlObj?.inv;
    let Sequence = urlObj?.seq;

    const downloadInvoice = () => {
        onClickDownload(true);
    };

    if (requestComplete && isdownloadClick) {
        let downloadInvoiceList = dataList;
        downloadeExcel(downloadInvoiceList, onClickDownload);
    }

    const onFlagInvoice = () => {
        // if (approveList.length > 0) {

        Swal.fire({
            text: "Are you sure you want to (Un)Flag this Invoice?",
            icon: "warning",
            closeOnClickOutside: false,
            showCloseButton: true,
            showCancelButton: true,
            buttons: {
                cancel: "Cancel",
                confirm: "Confirm",
            },
        }).then((confirm) => {
            if (confirm?.isConfirmed) {
                dispatch(
                    InvoiceFlag({
                        id: [+invNumber],
                    })
                );

                onFlag(true);
            }
        });

        // } else {

        //   Swal.fire({

        //     text: "Please select any one Invoice to (Un)Flag",

        //     icon: "warning",

        //     button: "Ok",

        //   });

        // }
    };

    const updateInvoiceDocument = (lineItemList) => {
        props.onSaveDoc(lineItemList);
    };

    const [InvId, setInvId] = useState();
    const [selectUserAnchor, setSelectUserAnchor] = useState(false);
    const onFilterItems = (value, id) => {
        switch (value) {
            case 4:
                setopenNewItem(false);
                props?.onShowLineItem(false);
                setIsActivity(true);
                const urlData = location?.search.slice(1)?.replace(/\n\s+/g, "\n")?.trim();
                const urlObj = JSON?.parse(decryptId(urlData));
                let invNumber = urlObj?.inv;
                getQueryStringVal(invNumber);
                const reqObj = {
                    transaction_id: location.pathname == "/proforma-invoice-view" ? 19 : 3,
                    transaction_key: location.pathname == "/proforma-invoice-view" ? reqId : parseInt(invNumber),
                    action: 0,
                };
                dispatch(ActivityHistory(reqObj));
                break;
            case 2:
                onFlagInvoice();
                break;
            case 3:
                // window.location.reload();
                props?.setCheckViewInv(false);
                const reqInvObj = {
                    id: [invId],
                    organisation: 0,
                    checkViewInv: false,
                };
                if (location?.pathname === "/proforma-invoice-view") {
                    dispatch(getProformaInvoiceItems(reqInvObj));
                } else {
                    dispatch(getInvoiceItems(reqInvObj));
                }
                break;
            case 0:
                downloadInvoice();
                break;
            case 5:
                setReassign(true);
                break;
            case 6:
                setAssign(true);
                setopenNewItem(false);
                props?.onShowLineItem(false);
                if (invTaskListData?.length === 0 || !invTaskListData) {
                    dispatch(getInvoiceTaskList(invId));
                }
                break;
            case 7:
                setopenNewItem(false);
                props?.onShowLineItem(false);
                dispatch(listOrganisationDropDown());
                setOpenTransfer(true);
                setInvId(invId);
                break;
            case 8:
                // changeStatus({
                //     inv_id: +invId,
                //     org_id: +orgId,
                //     type: 2,
                //     status_id: dataList?.datalist[0]?.prev_status ? dataList?.datalist[0]?.prev_status?.[0]?.prev_status : null,
                //     user_id: null,
                // });
                setReassign(true);
                break;
            case 9:
                setShowComment(true);
                dispatch(GetListUserForComments(1, orgId));
            default:
                break;
        }
    };

    filteredLineItem = filteredLineItem.filter((elem) => elem?.label_name !== "vendor_code");
    listData = _.filter(listData, { is_item: "0" });

    const [currentItem, setCurrentSelectedItem] = useState({
        label_name: null,
        label_value: null,
        id: null,
        items: null,
        iseditable: null,
        label_displayname: null,
        vendorName: null,
    });
    const handleClick = (newPlacement, currentItem, event) => {
        if (currentItem?.label_name == "tax") {
            props?.setClickTaxOpen(true);
            if (docId && taxListData?.length == 0) {
                dispatch(getTaxCodeList(orgId));
            }
        }
        // if (currentItem?.data_type == "d") {
        //     dateString = currentItem?.label_value == null ? "" : String(currentItem?.label_value);
        //     const momentObj = moment(dateString, userFormat?.toUpperCase());
        //     const momDate = momentObj?.toDate() == "Invalid Date" ? "" : momentObj?.toDate();
        //     setSelectedDate(momDate);
        // }
        setClick(false);
        setisClickOpen(true);
        if (currentItem.box_1x !== "0") {
            props.fieldTypeClick(currentItem);
            if (currentPage !== currentItem?.pg_no) {
                setTimeout(function () {
                    setCurrentPage(currentItem?.pg_no);
                    document?.querySelectorAll("#Inv-" + currentItem?.id)[0]?.click();
                }, 2000);
            } else {
                document?.querySelectorAll("#Inv-" + currentItem?.id)[0]?.click();
            }
        } else if (props?.currentPlayObj?.box_1x == "0") {
            setOpen((prev) => placement !== newPlacement || !prev);
            setAnchorEl(event.currentTarget);
            setPlacement(newPlacement);
            setCurrentSelectedItem({
                label_name: currentItem.label_name,
                data_type: currentItem?.data_type,
                label_value: !currentItem?.label_value
                    ? ""
                    : currentItem?.data_type == "d"
                    ? currentItem?.label_value?.includes("-") && moment(currentItem?.label_value, "DD-MM-YYYY", true).isValid()
                        ? moment(currentItem?.label_value, "DD-MM-YYYY").format(userFormat?.toUpperCase())
                        : moment(currentItem?.label_value, "DD/MM/YYYY").format(userFormat?.toUpperCase())
                    : currentItem?.label_value,
                id: currentItem.id,
                items: currentItem,
                iseditable: currentItem?.iseditable,
            });
            props?.setdragObject({
                mandatory: currentItem?.ismandatory,
                maxlength: currentItem?.maxlength,
                label_name: currentItem?.label_name,
                data_type: currentItem?.data_type,
            });
            const element = document.querySelectorAll("ul");
            element.forEach((item) => item.classList.remove("edit"));
            document.querySelector(`${"#Inv-ul-"}${currentItem.id}`).classList.add("edit");
            props.fieldTypeClick(currentItem);
        } else if (currentItem?.label_name !== "tax") {
            setOpen((prev) => placement !== newPlacement || !prev);
            setAnchorEl(event.currentTarget);
            setPlacement(newPlacement);
            setCurrentSelectedItem({
                label_name: currentItem.label_name,
                label_value: !currentItem?.label_value
                    ? ""
                    : currentItem?.data_type == "d"
                    ? currentItem?.label_value?.includes("-") && moment(currentItem?.label_value, "DD-MM-YYYY", true).isValid()
                        ? moment(currentItem?.label_value, "DD-MM-YYYY").format(userFormat?.toUpperCase())
                        : moment(currentItem?.label_value, "DD/MM/YYYY").format(userFormat?.toUpperCase())
                    : currentItem?.label_value,
                id: currentItem.id,
                items: currentItem,
                iseditable: currentItem?.iseditable,
            });
            props?.setdragObject({
                mandatory: currentItem?.ismandatory,
                maxlength: currentItem?.maxlength,
                label_name: currentItem?.label_name,
                data_type: currentItem?.data_type,
            });
            const element = document.querySelectorAll("ul");
            element.forEach((item) => item.classList.remove("edit"));
            document.querySelector(`${"#Inv-ul-"}${currentItem.id}`).classList.add("edit");
            props.fieldTypeClick(currentItem);
        }
    };

    dateString = currentItem?.label_value == null ? "18/09/2022" : String(currentItem?.label_value);
    const [days, months, years] =
        currentItem?.label_value && currentItem?.data_type == "d"
            ? String(currentItem?.label_value)?.split(
                  currentItem?.label_value?.includes("-") ? "-" : currentItem?.label_value?.includes(".") ? "." : "/"
              )
            : ["", "", ""];
    const datess = new Date(years, months - 1, days);
    const [selectedDate, setSelectedDate] = useState(datess);
    const filteredFav = poNumberList?.filter((fav) => {
        return fav.PoNumber.includes(currentItem?.label_value);
    });
    useEffect(() => {
        if (filteredFav?.length == 0 || checkClick == "click") {
            setpopperOpen(false);
        }
        if (checkChange && filteredFav?.length !== 0 && checkClick !== "click") {
            setpopperOpen(true);
        }
    }, [filteredFav, checkClick]);
    useEffect(() => {
        if (currentItem?.label_value?.length > 0) {
            const filterArr = vendorList?.filter((ele) => ele?.VendorName?.toLowerCase()?.includes(currentItem?.label_value?.toLowerCase()));
            setFilteredOptions(filterArr);
        } else {
            setFilteredOptions(vendorList);
        }
    }, [currentItem?.label_name == "company_name" && currentItem?.label_value]);
    const invoiceTxtChng = (labelName, labelVal, invId, items, iseditable, displayName, check, vendorName) => {
        setFilteredOptions([]);
        setcheckChange(true);
        if (labelVal == "") {
            setSearchShow(false);
        } else {
            setSearchShow(true);
        }
        setCheckClick(check);
        if (check == "click") {
            setpopperOpen(false);
            if (labelName == "purchase_order_no") {
                dispatch(GetAdvanceDetailsforCreate(labelVal, 1, orgId));
            }
        }
        const vendorSelect = vendorList?.find((item) => item?.VendorName == labelVal);
        if (items?.data_type == "d") {
            setSelectedDate(labelVal);
        }

        setCurrentSelectedItem({
            label_name: labelName,
            label_value: items?.data_type == "d" ? labelVal?.$d : labelVal,
            id: invId,
            items: items,
            iseditable: iseditable,
            label_displayname: displayName,
            vendorName: vendorName,
        });
        setVendorCode({
            label_name: "vendor_code",
            label_value: vendorSelect?.VendorNo,
        });
    };
    const [vendorObj, setVendorObj] = useState("");
    const [isEditRetention, setIsEditRetention] = useState(false);
    const findVendorOnPoList = poNumberList?.find((item) => {
        return item?.PoNumber == currentItem?.label_value && item?.VendorName == vendorNameChange;
    });
    useEffect(() => {
        if (findVendorOnPoList) {
            listData.find((item) => item?.label_name == "company_name").label_value = findVendorOnPoList?.VendorName;
            listData.find((item) => item?.label_name == "company_name").is_confirmed = true;
            setVendorCode({
                label_name: "vendor_code",
                label_value: findVendorOnPoList?.PoVendor,
            });
            setVendorObj(listData.find((item) => item?.label_name == "company_name"));
        }
    }, [findVendorOnPoList]);
    const validateAllFieldTypes = () => {
        let ValidsArr = [];
        ValidsArr = onValidateFilelds(fieldTypeItmes);
        const updateInv = [...new Set(props?.updateDocList)];
        const validateLineItem = [];
        if (updateInv.length > 0) {
            const getLineItems = updateInv?.filter((item) => item?.item_no);
            if (getLineItems && getLineItems?.length > 0) {
                filteredLineItem = _.groupBy(filteredLineItem, "item_no");
                filteredLineItem = _.values(filteredLineItem);
                getLineItems.forEach((lineItem) => {
                    const isExistLineItem = filteredLineItem.find((fitem) => fitem?.label_name === lineItem?.label_name);
                    if (!isExistLineItem) {
                        validateLineItem?.push(isExistLineItem);
                    }
                });
            }
        }
        if (validateLineItem.length > 0) {
            const getValidateArrayList = onValidateFilelds(validateLineItem);
            ValidsArr = [...ValidsArr, getValidateArrayList];
            if (ValidsArr[0].length == 0) {
                ValidsArr = [];
            } else {
                ValidsArr = ValidsArr.flat();
            }
        } else {
            filteredLineItem = filteredLineItem.flat();
            const getValidateArrayList = onValidateFilelds(filteredLineItem);
            ValidsArr = [...ValidsArr, getValidateArrayList];
            if (ValidsArr[0].length == 0) {
                ValidsArr = [];
            } else {
                ValidsArr = ValidsArr.flat();
            }
        }

        return ValidsArr;
    };
    function replaceStringInArray(arr, target, replacement) {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === target) {
                arr[i] = replacement;
            }
        }
    }
    const [glindicator, setGlindigator] = useState(dataList?.datalist[0]?.gl_indicator || 0);
    const { data: Podata } = useSelector((state) => state?.GetAdvanceDetailsforCreateState);
    const { invCalc, initial, requestComplete: req } = useSelector((state) => state?.getInvCaclviewState);
    const { advdata, micdata } = useSelector((state) => state?.getInvCalcDetailState);
    const taxUpdateInv = props?.updatedTaxData;
    const notConsiderVendorCode = props?.updateDocList?.filter((el) => el && el?.label_name !== "vendor_code");
    const recoveryInfo = !erpValidateArr?.recovery_info ? props?.invoiceList?.recovery_info : erpValidateArr?.recovery_info;
    const { retentionOnGross, totalRetentionAmount, retentionPercentage, retentionIndicator } = recoveryInfo || {};
    const getGrossAmtId = listData?.filter((el, i) => el?.label_name == "total");
    const pocNetId = listData?.filter((el, i) => el?.label_name == "total_net");
    const taxId = listData?.filter((el, i) => el?.label_name == "tax");
    let GrossAmt;
    let NetAmt;
    let TaxAmt;
    if (getGrossAmtId?.length > 0) {
        GrossAmt = document.querySelector(`#Inv-li-${getGrossAmtId?.[0]?.id}`);
    }
    if (pocNetId?.length > 0) {
        NetAmt = document.querySelector(`#Inv-li-${pocNetId?.[0]?.id}`);
    }
    if (taxId?.length > 0) {
        TaxAmt = document.querySelector(`#Inv-li-${taxId?.[0]?.id}`);
    }
    let Totalrete = (formatNumber(retentionOnGross ? GrossAmt?.textContent : NetAmt?.textContent) * formatNumber(retentionPercentage)) / 100;
    let RetenTtl = retentionIndicator == "I" ? props?.totalRetentionAmount : Totalrete;
    useEffect(() => {
        if (dataList?.datalist?.length != 0 && invCalc?.length == 0) {
            const Amounts = { NetAmt, GrossAmt, TaxAmt, RetenTtl };
            dispatch(getInvoiceCalc(invId, orgId, Amounts));
        }
    }, [dispatch, invCalc]);
    useMemo(() => {
        if (location.pathname != "/proforma-invoice-view") {
            const Amounts = { GrossAmt, NetAmt, TaxAmt, RetenTtl, retentionIndicator, retentionOnGross, retentionPercentage };
            amountUpdate(invCalc, dispatch, Amounts);
        }
    }, [GrossAmt, NetAmt, TaxAmt]);
    const { advRec, miscdb, taxPer, taxDatas } = useSelector((state) => state?.getInvCalcValState);
    const initialData = _.cloneDeep(initial);
    useMemo(() => {
        if (location.pathname !== "/proforma-invoice-view") {
            const poretenIndex = invCalc?.findIndex((item) => item.Name === "Po Retention");
            const updatedArrays = [...invCalc];
            if (req && retentionIndicator == "H") {
                RetenTtl = (formatNumber(GrossAmt?.textContent) * formatNumber(retentionPercentage)) / 100;
                updatedArrays[poretenIndex] = {
                    ...updatedArrays[poretenIndex],
                    billAmount: RetenTtl, // Convert to string
                    CumulativeAmount: RetenTtl
                        ? formatNumber(RetenTtl) + formatNumber(initialData[poretenIndex]?.PreviousBillAmount)
                        : initialData[poretenIndex]?.CumulativeAmount, // Convert to string
                };
                // autoCalc(updatedArrays, GrossAmt, dispatch);
                dispatch({ type: GET_INVOICE_CALC_VIEW_UPDATE, payload: updatedArrays });
            } else if (req && retentionIndicator == "I") {
                let Ttl = RetenTtl || initialData[poretenIndex]?.billAmount;
                updatedArrays[poretenIndex] = {
                    ...updatedArrays[poretenIndex],
                    billAmount: Ttl, // Convert to string
                    CumulativeAmount: Ttl
                        ? formatNumber(Ttl) + formatNumber(initialData[poretenIndex]?.PreviousBillAmount)
                        : initialData[poretenIndex]?.CumulativeAmount, // Convert to string
                };
                autoCalc(updatedArrays, GrossAmt, dispatch);
                // setConvertingArray(updatedArrays);
                dispatch({ type: GET_INVOICE_CALC_VIEW_UPDATE, payload: updatedArrays });
            }
        }
    }, [req, RetenTtl]);
    const ifEdit = _.isEqual(invCalc, initial);
    const checkIsEditeditems =
        (props?.lineitemData && props?.lineitemData?.length > 0) ||
        notConsiderVendorCode?.length !== 0 ||
        props?.validateChanges?.length !== 0 ||
        taxUpdateInv?.length !== 0 ||
        retentionArr?.length !== 0 ||
        isEditRetention ||
        clicked;
    const vendorAccountNo = fieldTypeItmes?.find((el) => el?.label_name == "account_number");
    const vendorVatNo = fieldTypeItmes?.find((el) => el?.label_name == "company_vat_no");
    // let validateInv = props?.invoiceList?.targetvalue === "SAP" && props?.invoiceList?.message_type == "e";
    // const CursorClass =
    //     props?.invoiceList?.validatestatus === 1 && props?.invoiceList?.targetvalue === "SAP"
    //         ? `cursor-default pointer-events-none`
    //         : validateInv
    //         ? "pointer-events-none"
    //         : `cursor-pointer`;

    // const totalNet = formatNumber(dataList?.datalist[0]?.invoice_items?.find((ite) => ite?.label_name == "total_net")?.label_value);
    // const retentionItemLevelInfo =
    //     erpValidateArr?.recovery_info?.retentionIndicator?.toLowerCase() === "i"
    //         ? onlySelectedLineItem?.map((item) => {
    //               return {
    //                   poItemCode: item?.poItemCode,
    //                   lineItemNo: item?.lineItemNo,
    //                   retentionAmount: item?.retentionAmount,
    //                   retentionPercentage: item?.retentionPercentage,
    //               };
    //           })
    //         : null;
    const checkProforma = location.pathname === "/proforma-invoice-view";
    function saveInvoice(invoiceData) {
        // Simplify the formatting variables
        const userThousand = numFormat === "1" ? "," : numFormat === "2" ? "." : " ";
        const userDecimal = numFormat === "1" ? "." : numFormat === "2" ? "," : ".";
        const orgThousand = OrgNumFormat === "1" ? "," : OrgNumFormat === "2" ? "." : " ";
        const orgDecimal = OrgNumFormat === "1" ? "." : OrgNumFormat === "2" ? "," : ".";
        // Conditionally remove properties from the payload
        const payload = {
            // recovery_info: {
            //     retentionIndicator:
            //         erpValidateArr?.recovery_info?.retentionIndicator !== undefined
            //             ? erpValidateArr?.recovery_info?.retentionIndicator
            //             : dataList?.datalist[0]?.recovery_info?.retentionIndicator,
            //     totalRetentionAmount,
            //     retentionPercentage:
            //         erpValidateArr?.recovery_info?.retentionPercentage !== undefined
            //             ? erpValidateArr?.recovery_info?.retentionPercentage
            //             : dataList?.datalist[0]?.recovery_info?.retentionPercentage,
            //     retentionItemLevelInfo,
            //     retentionOnGross:
            //         erpValidateArr?.recovery_info?.retentionOnGross !== undefined
            //             ? erpValidateArr?.recovery_info?.retentionOnGross
            //             : dataList?.datalist[0]?.recovery_info?.retentionOnGross,
            //     retentionItemLevelInfo,
            //     advanceTotal:
            //         erpValidateArr?.recovery_info?.advanceTotal !== undefined
            //             ? erpValidateArr?.recovery_info?.advanceTotal
            //             : dataList?.datalist[0]?.recovery_info?.advanceTotal,
            //     retentionItemLevelInfo,
            //     downpaymentInfo:
            //         retentionArr?.length !== 0
            //             ? retentionArr
            //             : erpValidateArr?.recovery_info?.downpaymentInfo?.length > 0
            //             ? erpValidateArr?.recovery_info?.downpaymentInfo
            //             : getRecoverInfo,
            // },
            matching_info: !props?.areArraysEqual
                ? onlySelectedLineItem
                : notUpdateddefaultLineItem !== undefined
                ? notUpdateddefaultLineItem
                : serviceFillPoItem !== undefined
                ? serviceFillPoItem
                : getMatchingInfo == null
                ? []
                : getMatchingInfo,
            isedited: true,
            invoice_taxitems: props?.updatedTaxData?.length !== 0 ? props?.updatedTaxData : [],
            cost_center:
                props?.removeCostData?.length !== 0 ? props?.removeCostData : props?.newCostArr?.length !== 0 ? props?.newCostArr : nonPOItemList,
            invoice_items: props?.lineitemData?.length !== 0 ? props?.lineitemData : [],
            invoice_list: [...new Set(invoiceData)]?.map((item) => {
                if (item?.data_type && item?.data_type == "d" && item?.label_value != null) {
                    item.label_value = moment(item?.label_value, userFormat).format(dateFormat?.toUpperCase());
                }
                if (
                    item?.data_type == "m" &&
                    item?.label_value !== null &&
                    (item?.label_name == "total_net" || item?.label_name == "total_tax" || item?.label_name == "total")
                ) {
                    item.label_value = item?.label_value
                        ?.replaceAll(userDecimal, "$")
                        .replaceAll(userThousand, orgThousand)
                        .replaceAll("$", orgDecimal);
                }
                if (item?.label_name == "vendor_code") {
                    item.label_value = item?.label_value;
                }
                return item;
            }),
            invoice_id: parseInt(props?.invoiceId),
            gl_indicator: glindicator == 0 ? null : glindicator,
            invoice_calcinfo: {
                header_info: invCalc,
                advance_recovery: advData,
                miscellaneous: miscData,
                credit_note: creditData,
                po_info: Podata
                    ? {
                          poHeaders: Podata?.data?.poHeadersArray.reduce((acc, item) => {
                              acc[item.fieldName] = item.value;
                              return acc;
                          }, {}),
                          poLineItems: Podata?.data?.poLineItems,
                          vendorMaster: Podata?.data?.vendorMaster,
                      }
                    : null,
                tax_deduction: taxDatas,
            },
        };
        if (checkProforma) {
            delete payload.recovery_info;
            delete payload.matching_info;
            delete payload.cost_center;
            dispatch(ProformaUpdateInvoice(payload));
        } else {
            dispatch(UpdateInvoice(payload));
        }

        dispatch({ type: GET_INVOICE_CALC_VIEW_RESET }); //line no 780 to 784 is inv calculation reset the local and redux state
        dispatch({ type: GET_INVOICE_CALC_DETAIL_VIEW_RESET });
        dispatch({ type: "STORE_ADV_RECOVERY_RESET" });
        dispatch({ type: "STORE_MISC_DEBIT_RESET" });
        setConvertingArray([]);
        setAdvData([]);
        setMiscData([]);
        // Call the onSaveInvDoc function
        onSaveInvDoc(true);
    }
    const netPayable = _.find(invCalc, { Name: "Net Payable" });
    const intAdjAmt = formatNumber(dataList?.datalist?.[0]?.adjusted_misc) + formatNumber(dataList?.datalist?.[0]?.adjusted_advance);
    let MasterAmt;
    let unAdjAmt;
    let finalVal;
    let isamt;
    let lastVal;
    let store;
    const NeedCalc = (unadv, unmisc, OnRender, amt, isStatus) => {
        let PoVal = Math.abs(formatNumber(dataList?.datalist?.[0]?.po_value) - formatNumber(dataList?.datalist?.[0]?.invoices_tilldate));
        unAdjAmt = Math.abs(formatNumber(unadv) + formatNumber(unmisc));
        finalVal = Math.abs(PoVal) - formatNumber(NetAmt?.textContent);
        const editAdj = Math.abs(formatNumber(amt?.adv) + formatNumber(amt?.misc));
        // const Adj = Math.abs(editAdj - intAdjAmt);
        isamt = amt;
        MasterAmt = Math.abs(finalVal - unAdjAmt); //This one for restriction future user for masteref_id == 3
        lastVal = Math.abs(MasterAmt + intAdjAmt) - Math.abs(editAdj || 0); //changes as -
        store = amt ? Math.abs(lastVal) : MasterAmt;
        if (!isStatus && store > finalVal && PoNumber) {
            Swal.fire({
                text: `Validate the advance and miscellaneous unadjusted amounts against the PO base value ${amt ? Math.abs(lastVal) : MasterAmt}.`,
                icon: "warning", //error
                closeOnClickOutside: false,
                showCancelButton: true,
                showCloseButton: true,
                icon: "warning",
                buttons: {
                    cancel: "Cancel",
                    confirm: "Confirm",
                },
            }).then((confirm) => {
                if (confirm?.isConfirmed) {
                    OnRender();
                }
            });
        } else {
            OnRender();
        }
    };
    const onSaveDocument = () => {
        if (formatNumber(netPayable?.billAmount) < 0) {
            showSwal("Net Payable amount should not be negative!");
            return;
        }
        if (advRec != null || miscdb != null) {
            const totalMiscAdjustmentAmount =
                miscData &&
                miscData?.reduce((total, item) => {
                    // Convert to number and handle null values by defaulting to 0
                    const adjustmentAmount = formatNumber(item.adjustment_amount) || 0;
                    return total + adjustmentAmount;
                }, 0);
            const totalAdvAdjustmentAmount =
                advData &&
                advData?.reduce((total, item) => {
                    // Convert to number and handle null values by defaulting to 0
                    const adjustmentAmount = formatNumber(item.adjustment_amount) || 0;
                    return total + adjustmentAmount;
                }, 0);
            const Amt = { adv: totalAdvAdjustmentAmount, misc: totalMiscAdjustmentAmount };
            NeedCalc(dataList?.datalist?.[0]?.unadjusted_advance, dataList?.datalist?.[0]?.unadjusted_misc, OnRender, Amt, false);
        }
        if (formatNumber(dataList?.datalist?.[0]?.unadjusted_misc) > 0 || formatNumber(dataList?.datalist?.[0]?.unadjusted_advance) > 0) {
            if (advRec == null && miscdb == null) {
                NeedCalc(dataList?.datalist?.[0]?.unadjusted_advance, dataList?.datalist?.[0]?.unadjusted_misc, OnRender, "", false);
            }
            return;
        }
        if (!lastVal && !MasterAmt) {
            OnRender();
        }
        function OnRender() {
            // Filter and set operations simplified
            const filterDuplicateArr = [
                ...new Set(props?.updateDocList.filter((obj) => obj && obj.label_name !== undefined && Object.keys(obj).length !== 0)),
            ];
            const updateInvValue = filterDuplicateArr.filter((obj) => obj !== undefined && obj !== null);
            const updateInv = [...new Set(props?.updateDocList)];
            // Validate all field types
            let ValidsArr = validateAllFieldTypes();

            // Check if there are elements in updateInv, updatedTaxData, retentionArr, or lineitemData
            if (
                isEditRetention ||
                updateInv.length > 0 ||
                props?.updatedTaxData?.length > 0 ||
                retentionArr?.length > 0 ||
                props?.lineitemData?.length > 0 ||
                props?.validateChanges?.length == 0
            ) {
                // Check for validation errors
                if (ValidsArr.length !== 0) {
                    // Find mandatory fields and add them to Rdublicate
                    const filterVal = [...new Set(ValidsArr)];
                    const New = updateInv.map((el) => el?.label_displayname);
                    let Rdublicate = filterVal.filter((el) => New.indexOf(el) === -1);
                    if (mandatoryLineItems.length !== 0) {
                        Rdublicate = [...Rdublicate, ...mandatoryLineItems];
                    }
                    replaceStringInArray(Rdublicate, "Cost center", "General Ledger");
                    // Show a confirmation swal if mandatory fields are missing
                    if (Rdublicate.length !== 0) {
                        const duplicateArr = generateFormattedText(
                            Rdublicate,
                            "Are you sure you want to save the invoice without the mandatory fields listed below?<br/> <br/>"
                        );
                        showSwalWithConfirmation(duplicateArr, props?.updateDocList, saveInvoice);
                        return false;
                    }
                }
                // Check for mandatory fields in fieldTypeItmes
                const mandatoryFile = fieldTypeItmes.filter((item) => {
                    const maxlength = item?.label_value?.length > item?.maxlenth;
                    const idIDexist = updateInv.find((selectedItem) => selectedItem?.id === item?.id);
                    return maxlength && !idIDexist && item.ismandatory && item.label_value === "";
                });
                if (mandatoryFile.length === 0) {
                    // Create an object for vendorObj
                    const updateObjInv = {
                        id: vendorObj?.id,
                        label_name: vendorObj.label_name,
                        label_value: vendorObj?.label_value,
                    };
                    if (vendorCode?.label_value !== "" && vendorCode?.label_value !== undefined) {
                        updateInvValue.push(vendorCode);
                    }
                    if (updateObjInv?.label_value !== undefined && updateObjInv?.label_value !== "") {
                        updateInvValue.push(updateObjInv);
                    }
                    // Call the saveInvoice function to save the data
                    saveInvoice(updateInvValue);
                } else if (ValidsArr.length !== 0) {
                    // Show a confirmation swal if mandatory fields are missing
                    const duplicateArr = generateFormattedText(
                        mandatoryFile,
                        "Are you sure you want to save the invoice without the mandatory fields listed below?<br/> <br/>"
                    );
                    showSwalWithConfirmation(duplicateArr, props?.updateDocList, saveInvoice);
                }
            } else {
                if (props?.validateErr?.length !== 0) {
                    // Show a confirmation swal for required line items
                    const Err = [...new Set(props?.validateErr)];
                    const duplicateArr = generateFormattedText(Err, "The following line items are required:<br/> <br/>");
                    showSwalWithConfirmation(duplicateArr, props?.updateDocList, saveInvoice);
                    return false;
                }
                // else if (props?.validateChanges?.length === 0) {
                // Show a warning swal if no changes were made
                // showSwal("Please edit any one field to Save your document.");
                // saveInvoice();
                // }
            }
        }
    };

    const onValidateFilelds = (validateListItems) => {
        const validateArray = [];
        validateListItems.forEach((item) => {
            if (item?.ismandatory && item?.label_value === null) {
                validateArray.push(item.is_item == "1" ? item?.label_displayname + " (Line Item)" : item?.label_displayname);
            }
            const Percentage =
                item?.data_type === "p" && item?.label_value && typeof item?.label_value == "string" ? item?.label_value?.replace(`%`, ``) : 0;

            if (item?.ismandatory && item?.label_value?.length > item?.maxlenth) {
                validateArray.push(item.is_item == "1" ? item?.label_displayname + " (Line Item)" : item?.label_displayname);
            } else if (item?.ismandatory && item?.data_type === "p" && +Percentage > 100) {
                validateArray.push(item.is_item == "1" ? item?.label_displayname + " (Line Item)" : item?.label_displayname);
            } else if (item?.ismandatory && item?.data_type === "n" && isNaN(+item?.label_value)) {
                validateArray.push(item.is_item == "1" ? item?.label_displayname + " (Line Item)" : item?.label_displayname);
            } else if (item?.ismandatory && item?.data_type === "m" && isNaN(+item?.label_value?.replace(/[,.-]/g, ``))) {
                validateArray.push(item.is_item == "1" ? item?.label_displayname + " (Line Item)" : item?.label_displayname);
            } else if (item?.ismandatory && (item?.label_value == "" || !item?.label_value)) {
                validateArray.push(item.label_displayname);
            }
            // if (
            //     item?.ismandatory == false &&
            //     item?.label_name == "company_name" &&
            //     (docId == 2 || 4) &&
            //     (item?.label_value == "" || item?.label_value == null)
            // ) {
            //     validateArray.push(item?.label_displayname);
            // }
        });
        const glValidateVal = nonPOItemList?.filter((el) => el?.gl_account_code == "");
        const costCentreVal = nonPOItemList?.filter((el) => el?.cost_center?.length < 1);
        if (checkInvBase?.document_id == 2 || checkInvBase?.document_id == 4) {
            if (glValidateVal?.length !== 0) {
                validateArray.push("General Ledger");
            }
            if (costCentreVal?.length > 0) {
                validateArray.push("Cost center");
            }
        }
        return validateArray;
    };

    const handleClickAway = () => {
        setOpen(false);
        setIsError("");
        setisClickOpen(false);
    };

    useEffect(() => {
        if (props?.isClick && !props?.isFocused) {
            handleClickAway();
        } else if (props?.cropValue?.height !== 0 && props?.cropValue?.width !== 0 && props?.cropValue !== undefined && props?.isDrag) {
            handleClickAway();
        }
    }, [open, isClickOpen, props?.isClick, props?.cropValue?.height, props?.cropValue?.width, props?.isDrag]);
    const invFieldUpdate = (currentItem, name, value, items, dataType, vendorName) => {
        props?.setIsFocused(false);
        vendorNameChange = vendorName ? vendorName : null;
        let txtVal = value?.trim();
        const momentObject = value?.trim();
        // moment(txtVal, "DD/MM/YYYY").format(userFormat?.toUpperCase());
        const Percentage = items?.data_type === "p" && txtVal && typeof txtVal == "string" ? txtVal?.replace(/[%,\s]/g, "") : 0;
        let parse = txtVal;
        if (dataType == "d") {
            // parse = moment(txtVal, "DD/MM/YYYY").format(dateFormat?.toUpperCase());
            parse = momentObject;
        }
        if (
            items?.ismandatory &&
            (txtVal == "" ||
                (items?.maxlength !== 0 && txtVal?.length > items?.maxlength) ||
                (items?.data_type === "p" && +Percentage > 100) ||
                ((items?.data_type === "n" || items?.data_type === "m") && isNaN(+txtVal?.replace(/[.,\s]/g, ""))))
        ) {
            if (txtVal == "") {
                setIsError("This field is required");
            } else if (items?.maxlength !== 0 && txtVal?.length > items?.maxlength) {
                setIsError(`Field Length should be maximum ${items?.maxlength}`);
            } else if (items?.data_type === "p" && +Percentage > 100) {
                setIsError("This field only allows percentage");
            } else if ((items?.data_type === "n" || items?.data_type === "m") && isNaN(+txtVal?.replace(/[.,\s]/g, ""))) {
                setIsError("This field is only allow number format");
            }
        } else if (dataType == "d" && txtVal == "") {
            setIsError("This field is required");
        } else {
            document.querySelector(`#Inv-ul-${currentItem}`).classList.add("approve");
            document.querySelector(`#Inv-li-${currentItem}`).innerHTML = dataType == "d" ? momentObject : txtVal;
            listData.forEach((item, index) => {
                if (item.id === currentItem) {
                    item.text = dataType == "d" ? momentObject : txtVal;
                    item.label_value = dataType == "d" ? momentObject : txtVal;
                    item.is_confirmed = true;
                }
            });
            const getLabelName = listData.find((item) => item.id == currentItem);
            const objInv = {
                id: currentItem,
                label_name: getLabelName.label_name,
                label_displayname: getLabelName?.label_displayname,
                label_value: dataType == "d" ? momentObject : txtVal,
                pg_no: currentPage,
                is_confirmed: true,
                data_type: dataType,
            };
            let addedInvoiceList = props?.updateDocList || [];

            //* If the object already exists in the payload replace it
            const index = addedInvoiceList?.findIndex((item) => item?.id === currentItem);
            if (index !== -1) {
                addedInvoiceList[index] = objInv;
            } else {
                addedInvoiceList.push(objInv);
            }

            if (items?.label_name === "purchase_order_no" && value?.trim()?.length > 0) {
                const reqObj = {
                    numFormat: numFormat,
                    type: 1,
                    orgId: orgId,
                    invoiceInfo: _.flatMap([...listData, ...filteredLineItem]),
                };
                dispatch(postThreeWayMatching(reqObj));
            }
            props.onSaveDoc(addedInvoiceList, objInv);
            if (!props?.isPlaying && props?.currentIndex < 1) {
                handleClickAway();
            }
            if (props?.currentIndex > 0 && !props?.manualPause) {
                props?.setIsFocused(false);
                props?.skipFocus();
                handleClickAway();
            } else {
                handleClickAway();
            }
        }
    };
    const reqObj = {
        id: [invNumber], //selectedInvIds
        checkViewInv: false,
    };
    //Showing Message Box -- need to move common component
    const showMessageBox = (response, message) => {
        const responseData = response?.data || response?.dataList;
        if (response.error !== "") {
            Swal.fire({
                text: response.error,
                icon: "error",
                closeOnClickOutside: false,
                showCloseButton: true,
                button: "Ok",
            }).then((confirm) => {
                if (confirm) {
                    updatedInvoiceList = [];
                    props?.setFieldText("");
                    document.querySelector(`${"#Inv-li-item"}${null}`);
                    props?.setCurrentFieldType(null);
                    props?.onSaveInvoiceDoc([]);
                    props?.setCheckFreightByPo(false);
                    props?.setFieldType("");
                    setClicked(false);
                    props?.setLineItemData([]);
                    props?.setUpdatedTaxData([]);
                    props?.handleValidateChanges([]);
                    const reqInvObj = {
                        id: [invId],
                        organisation: 0,
                        checkViewInv: false,
                    };
                    if (location?.pathname === "/proforma-invoice-view") {
                        dispatch(getProformaInvoiceItems(reqInvObj));
                    } else {
                        dispatch(getInvoiceItems(reqInvObj));
                    }
                }
            });
        } else if (responseData && response?.requestComplete) {
            if (responseData.status) {
                Swal.fire({
                    text: message,
                    icon: "success", //error
                    closeOnClickOutside: false,
                    showCloseButton: true,
                    button: "Ok",
                }).then((confirm) => {
                    updatedInvoiceList = [];
                    props?.setFieldType("");
                    props?.setFieldText("");
                    document.querySelector(`${"#Inv-li-item"}${null}`);
                    props?.setCurrentFieldType(null);
                    props?.onSaveInvoiceDoc([]);
                    props?.setCheckFreightByPo(false);
                    setClicked(false);
                    props?.setLineItemData([]);
                    props?.setUpdatedTaxData([]);
                    props?.handleValidateChanges([]);
                    if (confirm && message == "Invoice is approved successfully!") {
                        navigate(pageType == 1 ? "/invoice" : pageType == 3 ? "/approve" : "/invoice");
                        dispatch({ type: INVOICE_APPROVED_LIST_RESET });
                    } else if (confirm && message !== "Invoice is approved successfully!") {
                        props?.setCheckViewInv(false);
                        const reqInvObj = {
                            id: [invId],
                            organisation: 0,
                            checkViewInv: false,
                        };
                        if (location?.pathname === "/proforma-invoice-view") {
                            dispatch(getProformaInvoiceItems(reqInvObj));
                        } else {
                            dispatch(getInvoiceItems(reqInvObj));
                        }
                    }
                });
            } else if (responseData && response?.requestComplete) {
                if (responseData.status && responseData?.datalist === null) {
                    Swal.fire({
                        text: responseData.message,
                        icon: "error",
                        closeOnClickOutside: false,
                        showCloseButton: true,
                        button: "Ok",
                    }).then((confirm) => {
                        if (confirm) {
                            updatedInvoiceList = [];
                            props?.setFieldType("");
                            props?.setFieldText("");
                            document.querySelector(`${"#Inv-li-item"}${null}`);
                            props?.setCurrentFieldType(null);
                            props?.onSaveInvoiceDoc([]);
                            props?.setCheckFreightByPo(false);
                            setClicked(false);
                            props?.setLineItemData([]);
                            props?.setUpdatedTaxData([]);
                            props?.handleValidateChanges([]);
                            onApprovenvDoc(false);
                            if (location?.pathname === "/proforma-invoice-view") {
                                dispatch(getProformaInvoiceItems(reqObj));
                            } else {
                                dispatch(getInvoiceItems(reqObj));
                            }
                            if (dispatch) {
                                updatedInvoiceList = [];
                                props?.setFieldType("");
                                props?.setFieldText("");
                                document.querySelector(`${"#Inv-li-item"}${null}`);
                                props?.setCurrentFieldType(null);
                                props?.onSaveInvoiceDoc([]);
                                props?.setCheckFreightByPo(false);
                                setClicked(false);
                                onSaveInvDoc(true);
                            }
                        }
                    });
                }
            } else {
                response.requestComplete = false;
                Swal.fire({
                    text: message,
                    icon: "success",
                    closeOnClickOutside: false,
                    showCloseButton: true,
                    button: "Ok",
                }).then((confirm) => {
                    if (confirm) {
                        if (isApprove || isReject) {
                            navigate("/invoice");
                            props?.setCheckViewInv(false);
                            const reqInvObj = {
                                id: [invId],
                                organisation: 0,
                                checkViewInv: false,
                            };
                            if (location?.pathname === "/proforma-invoice-view") {
                                dispatch(getProformaInvoiceItems(reqInvObj));
                            } else {
                                dispatch(getInvoiceItems(reqInvObj));
                            }
                            // window.location.reload();
                        } else {
                            props?.setCheckViewInv(false);
                            props.resetPage();
                        }
                    }
                });
            }
        }
    };
    const numFormat = localStorage.getItem("userNumberFormat");
    const userRoleId = JSON.parse(localStorage.getItem("user"));
    const OrgNumFormat = localStorage.getItem("OrgNumFormat");
    const response = useSelector((state) => state?.updateInvoiceState);
    const approveResponse = useSelector((state) => state?.InvoiceApprovedState);
    const { requestComplete: ApproveReq, data: Approvedata } = useSelector((state) => state?.InvoiceApprovedState);
    const rejectResponse = useSelector((state) => state?.InvoiceRejectedState);
    const flagedResponse = useSelector((state) => state?.InvoiceFlagState);
    const sendToapprove = useSelector((state) => state?.SendToApproverState);
    const { data: getPoTypeSideBar } = useSelector((state) => state?.getPoTypeState);
    const [userEmail, setUserEmail] = useState([]);

    if (isSaved && checkIsEditeditems) {
        showMessageBox(response, "Invoice is Updated successfully!");
        // return false;
    } else if (isApprove) {
        showMessageBox(approveResponse, "Invoice is approved successfully!");
    } else if (isSaved && !checkIsEditeditems) {
        showMessageBox(response, "Invoice is validated successfully!");
    } else if (isFlaged) {
        showMessageBox(flagedResponse, "Invoice is (Un)Flagged successfully!");
    } else if (isReject) {
        showMessageBox(rejectResponse, "Invoice is rejected successfully!");
    }
    const alertMessageBox = (message, type) => {
        Swal.fire({
            text: message,
            icon: type,
            closeOnClickOutside: false,
            showCloseButton: true,
            button: "Ok",
        });
    };
    const matchingInfo = invoiceList?.data?.datalist[0]?.matching_info;
    let statuscomplete = sendToapprove?.data?.status && sendToapprove?.requestComplete;
    let errorMsg = sendToapprove?.error?.message;
    const SendToApproveError = sendToapprove?.error?.response?.data?.message;
    // const totalRetentionAmount = props?.totalRetentionAmount;
    // -----------------------------------------------   WORKFLOW STATUS CHANGE IMPACTS VARIABLES AND FUNCTIONS ------------------------------------------------
    //* Workflow impacts variables and functions:
    const [selectedUserId, setSelectedUserId] = useState(null);
    const nextUserList = dataList?.datalist[0]?.wfuser_list;
    const nextStatusInfo = dataList?.datalist[0]?.next_status;
    const currentStatusId = currentStatusInfo?.[0]?.status_id;
    const nextStatusId = nextStatusInfo?.[0]?.next_status;
    const isParking = dataList?.datalist[0]?.is_parking;
    const erpErrors = dataList?.datalist[0]?.erp_message
        ? dataList?.datalist[0]?.erp_message
              ?.split("* ")
              ?.filter(Boolean)
              ?.filter((item) => item?.trim()?.length > 0)
        : [];
    const overallErrors = dataList?.datalist[0]?.exc_message
        ? dataList?.datalist[0]?.exc_message
              ?.split("* ")
              ?.filter(Boolean)
              ?.filter((item) => item?.trim()?.length > 0)
        : [];
    const reqId = props?.invoiceList?.req_id;
    const checkPOVendorId = props?.invoiceList?.po_id && props?.invoiceList?.vendor_id;
    const statusReqObj = {
        inv_id: location.pathname == "/proforma-invoice-view" ? +reqId : +invId,
        org_id: +orgId,
        type: 1,
        status_id: nextStatusInfo?.[0]?.next_status,
        groupType: location.pathname == "/proforma-invoice-view" ? 2 : 1,
        masterref_id: null,
        round_robin: nextStatusInfo?.[0]?.round_robin,
        user_array: nextStatusInfo?.[0]?.user_array,
    };
    const {
        requestComplete: postStatusChangeReqCom,
        error: postStatusChangeError,
        data: postStatusData,
        fetching: postStatusChangeFetch,
    } = useSelector((state) => state?.postWorkFlowStatusChangeState);

    const showStatusEqualSwal = () => {
        Swal.fire({
            text: "The current status and the next status are the same. Please reconfigure the workflow.",
            closeOnClickOutside: false,
            showCancelButton: true,
            showCloseButton: true,
            icon: "warning",
            buttons: {
                cancel: "Cancel",
                confirm: "Confirm",
            },
            dangerMode: true,
        }).then((confirm) => {
            if (confirm?.isConfirmed) {
                setShowStatusEqual(false);
            }
        });
    };
    if (
        nextStatusInfo?.[0]?.next_status &&
        currentStatusInfo?.[0]?.status_id &&
        nextStatusInfo?.[0]?.next_status == currentStatusInfo?.[0]?.status_id &&
        showStatusEqual
    ) {
        showStatusEqualSwal();
        setShowStatusEqual(false);
    }

    //* Function to show the warning swal according to the status 3 way matching and parking flags
    const statusChangeWarning = (type, erpError) => {
        const erpErrorMessage =
            type == 2
                ? generateFormattedText(erpError, "Resolve the following ERP error(s) before proceeding to the next stage:<br/> <br/>")
                : type == 4
                ? generateFormattedText(erpError, "Resolve the following ERP error(s) before proceeding to the next stage::<br/> <br/>")
                : type == 5
                ? generateFormattedText(erpError, "Resolve the following error(s) before proceeding to the next stage:<br/> <br/>")
                : "";
        const showMessage =
            type == 1
                ? "Values have changed. Please save and validate before updating the status."
                : type == 2 || type == 4
                ? ""
                : type == 3
                ? "The invoice should be parked before proceeding to the next stage."
                : "";
        if (type == 5) {
            setIsShowmore(true);
            setopenNewItem(false);
            props?.onShowLineItem(false);
        } else {
            Swal.fire({
                html: erpErrorMessage,
                text: showMessage,
                allowOutsideClick: false,
                showCloseButton: true,
                icon: "warning",
            }).then((confirm) => {});
        }
    };
    //* Function to show the swal according to the response from change status API
    const showStatusChangeMessage = () => {
        let showIcon = "success";
        let type = 1;
        let showtext = "Status changed successfully..";
        let showButtons = {
            confirm: "OK",
        };
        if (postStatusChangeReqCom && postStatusChangeError) {
            showIcon = "error";
            type = 2;
            showtext = postStatusChangeError?.message;
        }
        Swal.fire({
            text: showtext,
            allowOutsideClick: false,
            showCancelButton: false,
            showCloseButton: true,
            icon: showIcon,
            confirmButtonText: showButtons.confirm,
        }).then((confirm) => {
            dispatch({ type: POST_CHANGE_WORKFLOW_STATUS_RESET });
            if (type == 1) {
                // if (selectedUserId == null || userRoleId?.role_id == 2 || selectedUserId == userRoleId?.id) {
                //     const reqObj = {
                //         id: [invId],
                //         organisation: 0,
                //         checkViewInv: false,
                //     };
                //     if (location?.pathname === "/proforma-invoice-view") {
                //         dispatch(getProformaInvoiceItems(reqObj));
                //     } else {
                //         dispatch(getInvoiceItems(reqObj));
                //     }
                //     props?.setCheckViewInv(false);
                // } else {
                //     navigate(pageType == 1 ? "/invoice" : pageType == 3 ? "/approve" : "/invoice");
                // }
                navigate(location?.state?.from);
            } else {
                props?.setCheckViewInv(false);
                const reqInvObj = {
                    id: [invId],
                    organisation: 0,
                    checkViewInv: false,
                };
                if (location?.pathname === "/proforma-invoice-view") {
                    dispatch(getProformaInvoiceItems(reqInvObj));
                } else {
                    dispatch(getInvoiceItems(reqInvObj));
                }
                // window.location.reload();
            }
        });
    };
    //* Function to call the change the status of the invoice
    function changeStatus(data) {
        if (currentStatusId == nextStatusId) {
            showStatusEqualSwal();
            return;
        }
        const proformaStatusObj = { ...data, inv_id: reqId, masterref_id: null, groupType: 2 };
        const InvoiceStatusObj = { ...data, groupType: 1 };
        if (data?.type == 1) {
            setSelectedUserId(data?.user_id);
        } else {
            setSelectedUserId(dataList?.datalist[0]?.prev_status?.[0]?.user_id);
        }
        dispatch(postChangeWorkflowStatus({ upd_status: [location.pathname === "/proforma-invoice-view" ? proformaStatusObj : InvoiceStatusObj] }));
    }
    if (postStatusChangeReqCom) {
        showStatusChangeMessage();
    }
    // ----------------------------------------------- X   -----  X  -------------------------------------------------------------
    const confirmApprove = (process) => {
        const validationMsg =
            process === "approve"
                ? `Are you sure you want to send this invoice ${Sequence} to the approver?`
                : `Are you sure you want to reject this invoice ${Sequence}?`;
        Swal.fire({
            text: validationMsg,
            closeOnClickOutside: false,
            showCancelButton: true,
            showCloseButton: true,
            icon: "warning",
            buttons: {
                cancel: "Cancel",
                confirm: "Confirm",
            },
            dangerMode: true,
        }).then((confirm) => {
            if (confirm?.isConfirmed) {
                if (process === "approve") {
                    dispatch(getInvApprove(orgId, invNumber, 2));
                } else if (process === "reject") {
                    const statusReqObjArray = {
                        inv_id: reqId,
                        org_id: +orgId,
                        type: 1,
                        groupType: 2,
                        status_id: process === "reject" && null,
                        masterref_id: process === "reject" ? 5 : null,
                    };
                    if (location.pathname === "/proforma-invoice-view") {
                        dispatch(postChangeWorkflowStatus({ upd_status: [statusReqObjArray] }));
                    } else {
                        dispatch(
                            InvoiceStatusRejected({
                                id: [parseInt(props.invoiceId)],
                            })
                        );
                    }

                    onRejectedDoc(true);
                }
            }
        });
    };
    if (!rejectResponse?.dataList?.status && rejectResponse?.requestComplete) {
        Swal.fire({
            text: rejectResponse?.dataList?.message,
            icon: "warning",
            closeOnClickOutside: false,
            showCloseButton: true,
            confirm: true,
        }).then((confirm) => {
            if (confirm) {
                dispatch({ type: INVOICE_REJECTED_LIST_RESET });
            }
        });
    }

    if (Approvedata?.data?.status == true && ApproveReq) {
        Swal.fire({
            text: "Invoice is approved successfully!",
            icon: "success",
            closeOnClickOutside: false,
            showCloseButton: true,
            confirm: true,
        }).then((confirm) => {
            if (confirm) {
                dispatch({ type: INVOICE_APPROVED_LIST_RESET });
                navigate(pageType == 1 ? "/invoice" : pageType == 2 ? "/exception" : pageType == 3 ? "/approve" : pageType == 6 ? "/processed" : "");
            }
        });
    }
    if (Approvedata?.status == false && ApproveReq) {
        Swal.fire({
            text: Approvedata?.message,
            icon: "warning",
            closeOnClickOutside: false,
            showCloseButton: true,
            confirm: true,
        }).then((confirm) => {
            if (confirm) {
                dispatch({ type: INVOICE_APPROVED_LIST_RESET });
                navigate(pageType == 1 ? "/invoice" : pageType == 2 ? "/exception" : pageType == 3 ? "/approve" : pageType == 6 ? "/processed" : "");
            }
        });
    }

    if (Approvedata?.status == false && ApproveReq) {
        Swal.fire({
            text: Approvedata?.message,
            icon: "warning",
            closeOnClickOutside: false,
            showCloseButton: true,
            confirm: true,
        }).then((confirm) => {
            if (confirm) {
                dispatch({ type: INVOICE_APPROVED_LIST_RESET });
            }
        });
    }

    if (statuscomplete) {
        Swal.fire({
            text: "The invoice has been sent to the approver successfully.",
            icon: "success",
            closeOnClickOutside: false,
            showCloseButton: true,
            confirm: true,
        }).then(async (confirm) => {
            await dispatch({ type: POST_INVOICE_APPROVER_RESET });
            if (confirm) {
                navigate(pageType == 1 ? "/invoice" : pageType == 2 ? "/exception" : pageType == 3 ? "/approve" : pageType == 6 ? "/processed" : "");
            }
        });
    }
    if (errorMsg !== "" && errorMsg !== undefined && errorMsg !== null && sendToapprove?.requestComplete) {
        Swal.fire({
            text: SendToApproveError == undefined || SendToApproveError == "" ? "Unable to send Invoice" : SendToApproveError,
            icon: "warning",
            closeOnClickOutside: false,
            showCloseButton: true,
            confirm: true,
        }).then((confirm) => {
            if (confirm) {
                dispatch({ type: POST_INVOICE_APPROVER_RESET });
            }
        });
    }
    const ApproveInvoice = () => {
        if (props?.invoiceList?.message_type == "e") {
            //  warning swall
            showSwal("Unable to approve the invoice with exceptions.");
        } else {
            Swal.fire({
                text: `Are you sure you want to approve this invoice ${Sequence}?`,
                icon: "warning",
                closeOnClickOutside: false,
                buttons: true,
                showCancelButton: true,
                showCloseButton: true,
                dangerMode: true,
            }).then((confirm) => {
                if (confirm?.isConfirmed) {
                    dispatch(
                        InvoiceStatusApproved({
                            id: [parseInt(props.invoiceId)],
                            status: props?.invoiceList?.validatestatus === 0 && props?.invoiceList?.targetvalue !== "IQAI" ? 3 : 3,
                        })
                    );
                }
            });
        }

        onApprovenvDoc(true);
    };
    const onSendToApproveInvoice = () => {
        const updateInv = props?.updateDocList;
        if (props?.invoiceList?.istask == true) {
            showSwal("Unable to send this invoice to the approver with tasks pending.");
        } else if (updateInv && updateInv.length > 0) {
            if (!isSaved) {
                alertMessageBox("Save your document changes before approving!", "warning");
            }
        } else {
            confirmApprove("approve");
        }
    };
    const onRejectedInvoice = () => {
        const updateInv = props?.updateDocList?.filter((el) => el?.label_name !== "vendor_code");
        if (updateInv && updateInv.length > 0) {
            if (isSaved) {
                alertMessageBox("Save your document changes before rejecting!", "warning");
            }
        } else {
            confirmApprove("reject");
        }
    };
    const [openNewItem, setopenNewItem] = useState(false);
    const showLineItems = (invID) => {
        //* if u want to restrict api call for potype add --> !getPoTypeSideBar in condition
        //* (note: cant get if the changed type cant get because of empty line items)
        if (
            invoiceList?.data?.datalist[0]?.matching_info?.length == 0 &&
            !matchingInfoUpdateArr &&
            filteredLineItem?.length == 0 &&
            getPoTypeSideBar?.length == 0 &&
            !docId
        ) {
            dispatch(getPoType({ orgId: orgId, poNumber: PoNumber }));
        } else if (docId && taxListData && taxListData?.length == 0) {
            dispatch(getTaxCodeList(orgId));
        }
        var nodeList = document.querySelectorAll("#Inv-lineitem");
        nodeList[0]?.click();
        props?.setClickTaxOpen(false);
        props?.onShowLineItem(true);
        setClick(false);
    };
    const resetInputField = (currentItem) => {
        setCurrentSelectedItem({
            label_name: currentItem.label_name,
            label_value: "",
            id: currentItem.id,
            items: currentItem?.items,
            iseditable: currentItem?.iseditable,
            label_displayname: currentItem?.items?.label_displayname,
        });
        setSearchShow(false);
    };
    // On click func for Show Picked and picked
    const setClickedToggle = (e) => {
        const selectedValue = e.target.value;
        const filteredListData = listData.filter((item) => {
            if (selectedValue === "Un-picked") {
                return !item.is_confirmed;
            }
            if (selectedValue === "Picked") {
                return item.is_confirmed;
            }
            return true; // "All" case
        });
        setIsToggleClick(e.target.checked);
        setFilteredListItem([...filteredListData]);
    };
    useEffect(() => {
        setFilteredListItem(listData);
    }, [handleDel, props?.vendorObj]);
    useEffect(() => {
        if (currentItem.label_name == "purchase_order_no") {
            if (poNumberList?.length == 0 || poNumberList == undefined) {
                dispatch(getPoNumberList(orgId));
            }
        }
        if (currentItem.label_name == "company_name") {
            if (vendorList?.length == 0) {
                dispatch(getSapVendorList(orgId));
            }
        }
    }, [currentItem.label_name == "purchase_order_no", currentItem.label_name == "company_name"]);
    useEffect(() => {}, [isToggleClick]);
    if (filteredLineItem.length > 0) {
        filteredLineItem = _.groupBy(filteredLineItem, "item_no");
        filteredLineItem = _.values(filteredLineItem);
    }

    const InputDisable = !exStatusId || (currentItem?.iseditable === false && true) || status == "7";
    const MessageType = props?.invoiceList?.message_type == "e";
    const [radioState, setRadioState] = useState("All");
    const handleRadioChange = (e) => {
        setClickedToggle(e);
        setRadioState(e.target.value);
    };
    const processArr = [
        { value: "All", label: "All" },
        { value: "Picked", label: "Extracted" },
        { value: "Un-picked", label: "Not Extracted" },
    ];
    // Determine conditions
    // const isErpValidateRecoveryInfo = erpValidateRecoveryInfo !== undefined;
    // const isRetentionRecovery = isErpValidateRecoveryInfo
    //     ? erpValidateArr?.recovery_info?.retentionIndicator?.toLowerCase() === "h" ||
    //       erpValidateArr?.recovery_info?.retentionIndicator?.toLowerCase() === "i"
    //     : dataList?.datalist[0]?.recovery_info?.retentionIndicator?.toLowerCase() === "h" ||
    //       dataList?.datalist[0]?.recovery_info?.retentionIndicator?.toLowerCase() === "i";
    // const isAdvanceRecovery = dataList?.datalist[0]?.advance_recovery;
    // const isDownpaymentInfoAvailable = isErpValidateRecoveryInfo
    //     ? erpValidateArr?.recovery_info?.downpaymentInfo?.length !== 0 && erpValidateArr?.recovery_info?.length !== 0
    //     : dataList?.datalist[0]?.recovery_info?.downpaymentInfo?.length !== undefined &&
    //       dataList?.datalist[0]?.recovery_info?.downpaymentInfo?.length !== 0 &&
    //       dataList?.datalist[0]?.recovery_info !== undefined &&
    //       Object.keys(dataList?.datalist[0]?.recovery_info)?.length !== 0;

    // Determine whether to show the content
    // const isToShow = isRetentionRecovery || isErpValidateRecoveryInfo || (isAdvanceRecovery && isDownpaymentInfoAvailable);
    //select user for rule based
    const _handleSelectUser = (index, value, reason, type) => {
        if (reason == "selectOption") {
            setUserEmail([value?.[value?.length - 1]]);
        } else setUserEmail([]);
    };
    const filterOptions = (options, { inputValue }) => {
        const filterArr = options?.filter((option) => option?.toLowerCase()?.includes(inputValue?.toLowerCase()));
        return filterArr;
    };
    const onInvCalOpen = () => {
        props?.setOpenInvCal(true);
    };
    const [showComment, setShowComment] = useState(false);
    const docType = props?.invoiceList?.document_id;
    const findNewAdhoc = props?.invoiceList?.advance_amount;
    useEffect(() => {
        dispatch({ type: GET_INVOICE_CALC_VIEW_RESET });
        dispatch({ type: GET_INVOICE_CALC_DETAIL_VIEW_RESET });
    }, []);
    const purchaseOrderNo = _.find(fieldTypeItmes, { label_name: "purchase_order_no" });
    const companyName = _.find(fieldTypeItmes, { label_name: "company_name" });
    const handleNavigateAdhoc = () => {
        if (!purchaseOrderNo?.label_value || !companyName?.label_value) {
            Swal.fire({
                text: "Either the purchase order number or the vendor name needs to be filled in and saved.",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: "OK",
                },
            });
        }
    };
    const gldata =
        location?.pathname === "/invoice-view" && dataList?.datalist[0]?.gl_value && dataList?.datalist[0]?.gl_value[0]?.replace(/'/g, '"');
    const AddArr = `[${gldata}]`;
    const glparsed = gldata && JSON.parse(AddArr);
    const MaxDate =
        location.pathname === "/proforma-invoice-view"
            ? new Date(props?.requestDate)
            : currentItem?.label_name == "delivery_date" || currentItem?.label_name == "due_date"
            ? ""
            : new Date();

    return (
        <>
            <div
                className={`bg-${currentMode}-card ${
                    isInvCalPop ? "pointer-events-none" : ""
                } text-${currentMode}-copy shadow-[0px_4px_20px_rgba(85,85,85,0.2)] flex  min-w-[400px] flex-col h-[calc(100vh)] overflow-auto`}
            >
                {data?.datalist &&
                    data?.datalist?.map((item, i) => {
                        if (item.exc_message) {
                            excMsg = item.exc_message;
                            msgCategory = item.message_category;
                            return (
                                <div key={i}>
                                    <div className="flex mt-7 pl-[30px]">
                                        <div className="mr-3 flex-none">
                                            {props.invoiceList?.message_type !== "s" ? (
                                                <img src="images/common/alert-triangle.svg" alt="warning" />
                                            ) : (
                                                <img src="images/common/info.svg" alt="warning" />
                                            )}
                                        </div>
                                        {overallErrors?.length > 0 && (
                                            <span
                                                className="text-[#F04438] cursor-pointer"
                                                onClick={() => {
                                                    setIsShowmore(true);
                                                    setopenNewItem(false);
                                                    props?.onShowLineItem(false);
                                                }}
                                            >
                                                {" "}
                                                {`Found ${overallErrors?.length} exception(s) in this Invoice.`}
                                            </span>
                                        )}
                                        {location.pathname == "/proforma-invoice-view" && checkPOVendorId && (
                                            <Link
                                                to={`${
                                                    purchaseOrderNo?.label_value && companyName?.label_value
                                                        ? !findNewAdhoc
                                                            ? `/adhoc-advance-request?createReqId=${reqId}`
                                                            : `/adhoc-advance-request?reqId=${reqId}`
                                                        : `${location.pathname}${location.search}`
                                                }`}
                                                state={{ from: location.pathname, search: location.search }}
                                                className="ml-[70px] mt-[-10px]"
                                                onClick={handleNavigateAdhoc}
                                            >
                                                <img src="/images/mobilization/proforma-inv-icon.svg" alt="proforma-invoice" />
                                            </Link>
                                        )}
                                        {/* <div className="flex items-end space-x-2">
                                            <span className="flex-1 trunc-3">{item?.exc_message}</span>
 
                                            {item?.exc_message?.length > 70 && (
                                                <Button
                                                    onClick={() => {
                                                        setIsShowmore(true);
                                                        setopenNewItem(false);
                                                        props?.onShowLineItem(false);
                                                    }}
                                                    className="text-light-100"
                                                >
                                                    Show More
                                                </Button>
                                            )}
                                        </div> */}
                                    </div>
                                </div>
                            );
                        }
                    })}
                {/* Show picked and unpicked radio button  map return*/}
                <div className={`flex items-center px-[30px] border-b-[1px] border-${currentMode}-700`}>
                    <div className="font-interSb text-base block my-4">Show :</div>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="All"
                        name="radio-buttons-group"
                        className="flex flex-row ml-4"
                    >
                        {processArr.map((radioOption, index) => (
                            <FormControlLabel
                                key={index}
                                className={`show-${radioOption.value.toLowerCase()}`}
                                value={radioOption.value}
                                control={
                                    <Radio
                                        size="small"
                                        defaultChecked={index === 0 ? true : false}
                                        onClick={handleRadioChange}
                                        sx={{
                                            color: "#555555",
                                            padding: "5px 4px",
                                            "&.Mui-checked": {
                                                color: "#00B399",
                                            },
                                        }}
                                    />
                                }
                                label={radioOption.label}
                            />
                        ))}
                    </RadioGroup>
                    {IsFlagged && <img src="/images/invoice/flagorange.svg" alt="flag" />}
                </div>
                {/* show more modal both retention and error throw message  */}
                {ReactDOM.createPortal(
                    <ShowMoreModal
                        status={props?.status}
                        totalAmountVal={props?.totalAmountVal}
                        isEditRetention={isEditRetention}
                        setIsEditRetention={setIsEditRetention}
                        numFormat={numFormat}
                        totalRetentionAmount={props?.totalRetentionAmount}
                        setTotRetentionAmount={props?.setTotRetentionAmount}
                        retentionArr={retentionArr}
                        setRetentionArr={setRetentionArr}
                        headerItem={listData} //send header invoice header item to showmore modal
                        isShowmore={isShowmore}
                        isRententionShow={isRententionShow}
                        sapMessage={excMsg}
                        retentionMessage={msgCategory}
                        isOpened={isShowmore || isRententionShow}
                        handleClose={() => setIsShowmore(false) || setIsRententionShow(false)}
                        className={`${(isShowmore || isRententionShow) && "Show"}`}
                        overallErrors={overallErrors}
                    />,
                    document.getElementById("user-modal-root")
                )}
                {glparsed?.length > 0 && location.pathname === "/invoice-view" && (
                    <div className={`relative flex py-[10px] items-center border-b-[1px] border-${currentMode}-700 px-[30px]`}>
                        <span className="font-interSb mr-[20px]">Special G/L Indicator:</span>
                        {glparsed?.length == 1 ? (
                            <p className={`border-${currentMode}-840 border min-w-[200px] py-[4px] px-[10px] text-xs rounded-[5px]`}>
                                {glparsed?.[0]?.value}
                            </p>
                        ) : (
                            <FormControl sx={{ minWidth: 200 }}>
                                <Select
                                    value={glindicator || 0}
                                    fullWidth
                                    disabled={InputDisable}
                                    className="glSelect"
                                    onChange={(e) => setGlindigator(e?.target?.value)}
                                    inputProps={{
                                        "aria-label": "Without label",
                                        tabIndex: "1",
                                    }}
                                    IconComponent={() => (
                                        <img
                                            className="absolute right-[10px] opacity-50 pointer-events-none"
                                            src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                            alt="chevronDown"
                                        />
                                    )}
                                >
                                    <MenuItem key={0} name="OrganisationList" value={0}>
                                        {"Select "}
                                    </MenuItem>
                                    {glparsed?.map((el, i) => (
                                        <MenuItem key={el?.value} tabIndex={el?.value} value={el?.value} className="truncate trunc-1 block">
                                            {el?.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    </div>
                )}

                {/* {isToShow && location.pathname !== "/proforma-invoice-view" && (
                    <div className="relative flex py-[10px]  items-center border-b-[1px] b
                    order-gray-200">
                        <span className="flex flex-1 ml-7 font-interSb trunc-3">
                            {isRetentionRecovery ? (
                                // Show retention amount with numeric format
                                <p className="flex">
                                    Retention amount:
                                    <NumericFormat
                                        allowNegative={false}
                                        className={`flex-1 trunc-1 w-full rounded-[8px] border-${currentMode}-840 bg-transparent`}
                                        decimalScale={2}
                                        value={totalRetentionAmount}
                                        displayType="text"
                                        thousandSeparator={numFormat === "1" ? "," : numFormat === "2" ? "." : " "}
                                        decimalSeparator={numFormat === "1" ? "." : numFormat === "2" ? "," : "."}
                                    />
                                </p>
                            ) : (
                                ""
                            )}
                            {(dataList?.datalist[0]?.retention_recovery && isRetentionRecovery && isAdvanceRecovery && isDownpaymentInfoAvailable) ||
                            (dataList?.datalist[0]?.retention_recovery && isRetentionRecovery) ||
                            (isAdvanceRecovery && isDownpaymentInfoAvailable) ? (
                                <div className="relative">
                                    <Button
                                        onClick={() => {
                                            setIsRententionShow(true);
                                            setopenNewItem(false);
                                            props?.onShowLineItem(false);
                                        }}
                                        className="text-light-100 ml-1">
                                        {dataList?.datalist[0]?.retention_recovery &&
                                        isRetentionRecovery &&
                                        isAdvanceRecovery &&
                                        isDownpaymentInfoAvailable
                                            ? "Recovery/Retention"
                                            : dataList?.datalist[0]?.retention_recovery && isRetentionRecovery
                                            ? "Retention"
                                            : isAdvanceRecovery && isDownpaymentInfoAvailable
                                            ? "Recovery"
                                            : ""}
                                    </Button>
                                </div>
                            ) : (
                                ""
                            )}
                        </span>
                    </div>
                )} */}
                <div className={`bg-${currentMode}-card text-${currentMode}-copy flex h-full justify-between flex-col overflow-y-auto`}>
                    <div>
                        <div className="mb-5 px-[30px]">
                            <span className="font-interSb text-base flex space-x-3 my-4">
                                <img src="/images/common/ribbon-cut.svg" alt="ribbon-cut" /> <em className="not-italic">Invoice</em>
                            </span>
                            <div className="max-w-[375px]">
                                {fieldTypeItmes?.map((item, index) => {
                                    let TickOrDot = item.is_confirmed && item?.label_name !== "tax" && item?.label_value;
                                    let itTax = item?.label_name == "tax";
                                    const Istax =
                                        props?.totalTax !== 0
                                            ? props?.totalTax
                                            : dataList?.datalist[0]?.tax_amount == null
                                            ? ""
                                            : dataList?.datalist[0]?.tax_amount;
                                    if (item?.label_name == "tax" && Istax) {
                                        item.is_confirmed = true;
                                    }

                                    const dateObj =
                                        item?.data_type == "d" && item?.label_value
                                            ? moment(item?.label_value, "DD-MM-YYYY", true).isValid()
                                                ? moment(item?.label_value, "DD-MM-YYYY").format(userFormat?.toUpperCase())
                                                : moment(item?.label_value, "DD/MM/YYYY").format(userFormat?.toUpperCase())
                                            : "";
                                    return (
                                        <ul
                                            key={index}
                                            id={`${"Inv-ul-"}${item?.id}`}
                                            onClick={(event) => handleClick("bottom-end", item, event)}
                                            className={`${
                                                item?.label_name === `invoice_no` ? `labelitem-step` : ``
                                            } ${"flex pl-[1.5rem] cursor-pointer"} pt-1 pb-1 invoice-lbl ${
                                                (!item.is_confirmed && item?.ismandatory && item?.label_value == "") ||
                                                (item?.label_name == "account_number" && item?.label_value !== vendorVatNo?.label_value) ||
                                                (item?.label_name == "company_vat_no" && item?.label_value !== vendorAccountNo?.label_value)
                                                    ? "bg-[#FCBEC5] bg-opacity-20 border border-[#FCBEC566] mb-[2px] rounded"
                                                    : ""
                                            }`}
                                        >
                                            <li
                                                id={`${"Inv-li-item"}${item.id}`}
                                                className={`flex-1 flex text-${currentMode}-copy`}
                                                style={{
                                                    "text-transform": "capitalize",
                                                }}
                                            >
                                                <Tooltip
                                                    title={
                                                        <>
                                                            <ul className="font-interM space-y-[6px] p-2">
                                                                {/* <div className="flex">
                                                                <li>Accuracy % </li>
                                                                <span className="pl-[55px] pr-2 text-right">:</span>
                                                                {item?.threshold * 100}{" "}
                                                            </div> */}
                                                                <div className="flex">
                                                                    <li>Last modified by </li>
                                                                    <span className="pl-[22px] pr-2 text-right">:</span>
                                                                    <span className="max-w-[110px] truncate">{item?.modified_name}</span>
                                                                </div>
                                                                <div className="flex">
                                                                    <li>
                                                                        Last modified date <span className="pl-[6px] pr-2 text-right">:</span>
                                                                        {item?.modifieddate}
                                                                    </li>
                                                                </div>
                                                            </ul>
                                                        </>
                                                    }
                                                    disablePortal
                                                    placement="bottom"
                                                    disableInteractive
                                                    PopperProps={{
                                                        sx: {
                                                            "& .MuiTooltip-tooltip": {
                                                                fontSize: "1.0em",
                                                                maxHeight: "120px",
                                                                maxWidth: "300px",
                                                                background: "#DAF9F4",
                                                                color: "#555555",
                                                                borderRadius: "5px",
                                                                marginTop: "0 !important",
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <div
                                                        className={`${
                                                            !TickOrDot && !itTax ? `pr-[1.3rem]` : `pr-[1rem]`
                                                        } ${"cursor-pointer space-y-[10px]"}`}
                                                    >
                                                        <img
                                                            className={`invoice-lbl ${!itTax ? `mt-[7px]` : ``} `}
                                                            src={`${
                                                                TickOrDot
                                                                    ? `/images/invoice/tickGreen.svg`
                                                                    : itTax
                                                                    ? `/images/invoice/columns.svg`
                                                                    : `/images/invoice/Ellipse_gray.svg`
                                                            }`}
                                                        />
                                                    </div>
                                                </Tooltip>
                                                {item?.label_displayname}
                                            </li>
                                            <li className={`flex-1 trunc-2 text-${currentMode}-copy`}>
                                                <span id={`${"Inv-li-"}${item.id}`}>
                                                    {item?.label_name == "tax" ? (
                                                        <NumericFormat
                                                            allowNegative={false}
                                                            value={
                                                                props?.totalTax !== 0
                                                                    ? props?.totalTax
                                                                    : dataList?.datalist[0]?.tax_amount == null
                                                                    ? ""
                                                                    : dataList?.datalist[0]?.tax_amount
                                                            }
                                                            displayType="text"
                                                            decimalScale={2}
                                                            thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                                                            decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                                                        />
                                                    ) : item?.data_type === "d" ? (
                                                        dateObj
                                                    ) : (
                                                        item?.label_value
                                                    )}
                                                </span>
                                            </li>
                                        </ul>
                                    );
                                })}
                                <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClickAway}>
                                    <Popper
                                        className={`max-w-[400px] bg-${currentMode}-card px-5 py-6 rounded-[10px] shadow-[0px_4px_20px_rgba(85,85,85,0.2)] text-${currentMode}-copy popper-blk`}
                                        open={open}
                                        anchorEl={anchorEl}
                                        transition
                                    >
                                        {({ TransitionProps }) => (
                                            <Fade {...TransitionProps} timeout={350}>
                                                <div className={`flex flex-col bg-${currentMode}-card text-${currentMode}-copy`}>
                                                    <label htmlFor="" style={{ textTransform: "capitalize" }}>
                                                        <p>{currentItem?.items?.label_displayname}</p>
                                                    </label>
                                                    <span className="absolute top-[1.2rem] cursor-pointer right-[1.3rem]" onClick={handleClickAway}>
                                                        <img src="/images/invoice/cls-sm.svg" alt="cls" className="convertWhite" />
                                                    </span>

                                                    {currentItem?.items?.data_type === "d" ? (
                                                        // Date Input
                                                        <div className="datePic mt-2 inv">
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DesktopDatePicker
                                                                    inputFormat={userFormat}
                                                                    OpenPickerButtonProps={{
                                                                        style: {
                                                                            color: "white",
                                                                        },
                                                                    }}
                                                                    disabled={!exStatusId || InputDisable}
                                                                    value={currentItem?.label_value}
                                                                    minDate={new Date("2000")}
                                                                    maxDate={MaxDate}
                                                                    components={{
                                                                        OpenPickerIcon: AccessIcon,
                                                                    }}
                                                                    className="text-white"
                                                                    // disableFuture
                                                                    id={`free-solo-demo${"Inv-txt-"}${currentItem.id}`}
                                                                    onChange={(e) =>
                                                                        invoiceTxtChng(
                                                                            currentItem.label_name,
                                                                            e,
                                                                            currentItem.id,
                                                                            currentItem?.items,
                                                                            currentItem?.iseditable,
                                                                            currentItem?.is_confirmed
                                                                        )
                                                                    }
                                                                    onFocus={props?.isPlaying ? () => props?.setIsFocused(true) : null}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            sx={{
                                                                                "& .MuiInputBase-input": {
                                                                                    color: `${currentMode === "dark" ? "white" : "black"} !important`,
                                                                                },
                                                                            }}
                                                                            onFocus={props?.isPlaying ? () => props?.setIsFocused(true) : null}
                                                                            className="border-[rgba(85,85,85,0.5)] bg-transparent px-[20px] py-1 mt-2 border-[1px] mb-4 rounded-[5px]"
                                                                            value={currentItem?.label_value}
                                                                            {...params}
                                                                            inputProps={{
                                                                                ...params.inputProps,
                                                                                readOnly: true,
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                            </LocalizationProvider>
                                                        </div>
                                                    ) : currentItem.label_name === "purchase_order_no" ? (
                                                        // Purchase Order Number Input
                                                        <div className="solo mt-2 relative">
                                                            <Input
                                                                type="text"
                                                                disabled={InputDisable}
                                                                id={`${"Inv-txt-"}${currentItem.id}`}
                                                                className={`${"border-[rgba(85,85,85,0.5)] bg-transparent px-[20px] py-1  border-[1px] rounded-[5px] mt-2"}`}
                                                                onChange={(e) =>
                                                                    invoiceTxtChng(
                                                                        currentItem.label_name,
                                                                        e.target.value,
                                                                        currentItem.id,
                                                                        currentItem?.items,
                                                                        currentItem?.iseditable,
                                                                        currentItem.items?.label_displayname
                                                                    )
                                                                }
                                                                onFocus={props?.isPlaying ? () => props?.setIsFocused(true) : null}
                                                                value={currentItem.label_value}
                                                            />
                                                            {currentItem.label_value && currentItem.label_value.length !== 0 ? (
                                                                <img
                                                                    className="absolute right-[12px] top-[62%] z-[2] cursor-pointer translate-y-[-62%]"
                                                                    onClick={() => resetInputField(currentItem)}
                                                                    src="/images/common/closeBlk.svg"
                                                                    alt="close"
                                                                />
                                                            ) : null}

                                                            {popperOpen && searchShow && poNumberList !== undefined && poNumberList?.length !== 0 ? (
                                                                <div
                                                                    className={`max-h-[230px] overflow-y-auto absolute z-[5] right-0 bg-${currentMode}-card`}
                                                                >
                                                                    <table className="w-[436px] rolemasterTable border rounded-[8px] text-left">
                                                                        <thead className="sticky top-0">
                                                                            <tr className="">
                                                                                <th className="pl-1 pr-1">PO. No.</th>
                                                                                <th className="pl-1">Vendor</th>
                                                                                <th>Vendor type</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className="">
                                                                            {currentItem?.label_value !== "" &&
                                                                            currentItem?.label_value !== null &&
                                                                            filteredFav?.length !== 0 ? (
                                                                                filteredFav?.map((elem) => (
                                                                                    <tr
                                                                                        className="cursor-pointer"
                                                                                        onClick={() =>
                                                                                            invoiceTxtChng(
                                                                                                currentItem.label_name,
                                                                                                elem?.PoNumber,
                                                                                                currentItem.id,
                                                                                                currentItem?.items,
                                                                                                currentItem?.iseditable,
                                                                                                currentItem.items?.label_displayname,
                                                                                                "click",
                                                                                                elem?.VendorName
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <td className="pl-1 pr-1">{elem?.PoNumber}</td>
                                                                                        <td
                                                                                            title={elem?.VendorName}
                                                                                            className="max-w-[390px] pl-1 truncate"
                                                                                        >
                                                                                            {elem?.VendorName}
                                                                                        </td>
                                                                                        <td className="flex">{elem?.VendorType}</td>
                                                                                    </tr>
                                                                                ))
                                                                            ) : currentItem?.label_value !== "" &&
                                                                              currentItem?.label_value !== null &&
                                                                              filteredFav?.length === 0 ? (
                                                                                <div className="pl-[50px] place-items-center place-content-center">
                                                                                    <h4 className="font-interSb block">No Data found</h4>
                                                                                </div>
                                                                            ) : (
                                                                                poNumberList?.map((item) => (
                                                                                    <tr
                                                                                        className="cursor-pointer"
                                                                                        onClick={() =>
                                                                                            invoiceTxtChng(
                                                                                                currentItem.label_name,
                                                                                                item?.PoNumber,
                                                                                                currentItem.id,
                                                                                                currentItem?.items,
                                                                                                currentItem?.iseditable,
                                                                                                currentItem.items?.label_displayname,
                                                                                                "click"
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <td className="pl-1 pr-1">{item?.PoNumber}</td>
                                                                                        <td
                                                                                            title={item?.VendorName}
                                                                                            className="max-w-[390px] pl-1 truncate"
                                                                                        >
                                                                                            {item?.VendorName}
                                                                                        </td>
                                                                                        <td className="flex">{item?.VendorType}</td>
                                                                                    </tr>
                                                                                ))
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    ) : currentItem.label_name === "company_name" ? (
                                                        // Company Name Input (Autocomplete)
                                                        <Autocomplete
                                                            freeSolo
                                                            disabled={InputDisable}
                                                            sx={{
                                                                width: 218,
                                                                zIndex: 9999,
                                                                color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                            }}
                                                            id={`country-select-demo${"Inv-txt-"}${currentItem.id}`}
                                                            autoSelect
                                                            className="invSolo"
                                                            value={currentItem?.label_value}
                                                            onFocus={props?.isPlaying ? () => props?.setIsFocused(true) : null}
                                                            onChange={(e) =>
                                                                invoiceTxtChng(
                                                                    currentItem.label_name,
                                                                    e.target.textContent || e.target.value,
                                                                    currentItem.id,
                                                                    currentItem?.items,
                                                                    currentItem?.iseditable,
                                                                    currentItem?.is_confirmed
                                                                )
                                                            }
                                                            options={
                                                                currentItem?.label_value == "" && filteredOptions?.length == 0
                                                                    ? vendorList?.map((el) => el?.VendorName)
                                                                    : filteredOptions?.map((el) => el?.VendorName)
                                                            }
                                                            filterOptions={filterOptions}
                                                            getOptionLabel={(option) => option}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    onFocus={props?.isPlaying ? () => props?.setIsFocused(true) : null}
                                                                    onChange={(e) =>
                                                                        invoiceTxtChng(
                                                                            currentItem.label_name,
                                                                            e.target.textContent || e.target.value,
                                                                            currentItem.id,
                                                                            currentItem?.items,
                                                                            currentItem?.iseditable,
                                                                            currentItem?.is_confirmed
                                                                        )
                                                                    }
                                                                    {...params}
                                                                    placeholder="Select"
                                                                    endAdornment={null}
                                                                    inputProps={{
                                                                        ...params.inputProps,
                                                                        autoComplete: "new-password", // disable autocomplete and autofill
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    ) : currentItem?.items?.data_type == "m" ||
                                                      currentItem?.items?.data_type == "n" ||
                                                      currentItem?.items?.data_type == "p" ? (
                                                        <NumericFormat
                                                            maxlength={currentItem?.items?.maxlength == 0 ? 20 : 50}
                                                            allowNegative={false}
                                                            className={`border-[rgba(85,85,85,0.5)] bg-transparent px-[20px] py-1 mt-2 border-[1px] rounded-[5px]`}
                                                            value={currentItem?.label_value}
                                                            disabled={InputDisable}
                                                            id={`${"Inv-txt-"}${currentItem.id}`}
                                                            displayType="input"
                                                            decimalScale={2}
                                                            thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                                                            decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                                                            onChange={(e, newVal) =>
                                                                invoiceTxtChng(
                                                                    currentItem.label_name,
                                                                    e.target.value,
                                                                    currentItem.id,
                                                                    currentItem?.items,
                                                                    currentItem?.iseditable,
                                                                    currentItem.items?.label_displayname
                                                                )
                                                            }
                                                            // onFocus={props?.isPlaying ? () => props?.setIsFocused(true) : null}
                                                        />
                                                    ) : (
                                                        <Input
                                                            type="text"
                                                            disabled={InputDisable}
                                                            id={`${"Inv-txt-"}${currentItem.id}`}
                                                            className={`${"border-[rgba(85,85,85,0.5)] bg-transparent px-[20px] py-1 mt-2 border-[1px] rounded-[5px]"}`}
                                                            onChange={(e) =>
                                                                invoiceTxtChng(
                                                                    currentItem.label_name,
                                                                    e.target.value,
                                                                    currentItem.id,
                                                                    currentItem?.items,
                                                                    currentItem?.iseditable,
                                                                    currentItem.items?.label_displayname
                                                                )
                                                            }
                                                            value={currentItem.label_value}
                                                            onFocus={props?.isPlaying ? () => props?.setIsFocused(true) : null}
                                                        />
                                                    )}

                                                    {isError && <p className="text-xs text-light-130">{isError}</p>}

                                                    {status !== "7" && RoleActProcessEdit?.isallowed === true && (
                                                        <div className="flex justify-between space-x-3 mt-4">
                                                            <Button
                                                                className="cursor-pointer"
                                                                disabled={InputDisable}
                                                                onClick={() =>
                                                                    handleDeleteValue(currentItem?.id, currentItem?.label_value, currentItem.items)
                                                                }
                                                            >
                                                                <img src="/images/invoice/trash-red.svg" alt="trash-red" />
                                                            </Button>

                                                            <Button
                                                                className="bg-light-20 px-[15px] py-[4px] text-white rounded-[5px] flex items-center"
                                                                disabled={InputDisable}
                                                                onClick={() =>
                                                                    invFieldUpdate(
                                                                        currentItem.id,
                                                                        currentItem.label_name,
                                                                        currentItem.items?.data_type === "d"
                                                                            ? typeof currentItem?.label_value === "object"
                                                                                ? currentItem.label_value?.toLocaleDateString()
                                                                                : currentItem.label_value
                                                                            : currentItem.label_value,
                                                                        currentItem.items,
                                                                        currentItem.items?.data_type,
                                                                        currentItem?.vendorName
                                                                    )
                                                                }
                                                            >
                                                                <img className="mr-2" src="/images/common/tickWhite.svg" alt="tickWhite" /> Confirm
                                                            </Button>
                                                        </div>
                                                    )}
                                                </div>
                                            </Fade>
                                        )}
                                    </Popper>
                                </ClickAwayListener>
                            </div>
                        </div>
                        {radioState == "All" ||
                        (radioState == "Picked" && filteredLineItem?.length > 0) ||
                        (radioState == "Un-picked" && filteredLineItem?.length == 0) ? (
                            <div>
                                <span className="font-interSb text-base flex space-x-3 my-4 ml-[30px]">
                                    <img src="/images/common/notepad.svg" alt="notepad" />{" "}
                                    <span className={`not-italic cursor-pointer`} onClick={() => showLineItems(filteredLineItem[0]?.id)}>
                                        Line Items
                                    </span>
                                </span>

                                {filteredLineItem[0]?.label_name !== "vendor_code" && filteredLineItem.length > 0 ? (
                                    <>
                                        <ul className={`flex lineitem-step pl-[1.5rem] cursor-pointer lineItem`}>
                                            <img
                                                className="mb-2 ml-[30px]"
                                                src={` ${
                                                    filteredLineItem?.length > 0
                                                        ? `/images/invoice/tickGreen.svg`
                                                        : `/images/invoice/Ellipse_gray.svg`
                                                }`}
                                            />
                                            <li onClick={() => showLineItems(filteredLineItem[0]?.id)} className="ml-[15px]">
                                                <span className={`text-${currentMode}-copy block mb-2`}>
                                                    Line Items ({props?.objectLength !== null ? props?.objectLength : filteredLineItem?.length})
                                                </span>
                                            </li>
                                            <li></li>
                                        </ul>
                                    </>
                                ) : (
                                    ""
                                )}
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                    <div
                        className={`sticky ${
                            status == "7" ? "pointer-events-auto" : ``
                        } bottom-0 z-[4] bg-${currentMode}-card shadow-[0px_4px_20px_rgba(120,117,117,0.43)] text-${currentMode}-copy   pt-5 py-4 px-[30px]`}
                    >
                        <div
                            className={`flex ${
                                RoleActProcessEdit?.isallowed == false || props.invoiceList?.ext_status == "Rejected" || status == 7 || !exStatusId
                                    ? `justify-end`
                                    : `justify-between`
                            }  items-center`}
                        >
                            {exStatusId && (
                                <Button
                                    title={`${checkIsEditeditems ? "To Save Invoice Changes" : "To Validate Invoice"}`}
                                    type="button"
                                    disabled={response?.fetching}
                                    className={`bg-light-20 text-white save-step font-interSb px-[1.37rem] rounded-[8px] py-3 ${
                                        RoleActProcessEdit?.isallowed == false || props.invoiceList?.ext_status == "Rejected" || status == 7
                                            ? "hidden pointer-events-none"
                                            : ""
                                    } `}
                                    onClick={() => onSaveDocument()}
                                >
                                    {checkIsEditeditems ? "Save & Validate" : "Validate"}
                                </Button>
                            )}

                            <ul className={`flex items-center space-x-3 justify-end`}>
                                {docType == 1 &&
                                    invoiceList?.data?.datalist[0]?.targetvalue == "SAP" &&
                                    location.pathname !== "/proforma-invoice-view" && (
                                        <Tooltip
                                            title={"Invoice Calculation"}
                                            disablePortal
                                            placement="top"
                                            disableInteractive
                                            PopperProps={{
                                                sx: {
                                                    "& .MuiTooltip-tooltip": {
                                                        fontSize: "0.9em",
                                                        maxHeight: "120px",
                                                        maxWidth: "250px",
                                                        background: "#DAF9F4",
                                                        color: "#555555",
                                                        borderRadius: "5px",
                                                        marginTop: "0 !important",
                                                    },

                                                    "& .MuiTooltip-arrow": {
                                                        color: "#DAF9F4",
                                                    },
                                                },
                                            }}
                                            arrow
                                        >
                                            <li
                                                className="flex items-center cursor-pointer"
                                                onMouseEnter={() =>
                                                    setIsHovered({
                                                        ...isHovered,
                                                        invoiceCalculation: true,
                                                    })
                                                }
                                                onMouseLeave={() =>
                                                    setIsHovered({
                                                        ...isHovered,
                                                        invoiceCalculation: false,
                                                    })
                                                }
                                            >
                                                <div onClick={() => onInvCalOpen()} className={`remove-step cursor-pointer flex `}>
                                                    <CurrentAndNextStatus
                                                        backgroundColour={
                                                            isHovered?.invoiceCalculation ? "#00B399" : currentMode == "dark" ? "white" : "black"
                                                        }
                                                        masterId={4}
                                                    />
                                                </div>
                                            </li>
                                        </Tooltip>
                                    )}

                                {exStatusId && RoleActapproveReject?.isallowed == true ? (
                                    <Tooltip
                                        title={"Reject Invoice"}
                                        disablePortal
                                        placement="top"
                                        disableInteractive
                                        PopperProps={{
                                            sx: {
                                                "& .MuiTooltip-tooltip": {
                                                    fontSize: "0.9em",
                                                    maxHeight: "120px",
                                                    maxWidth: "250px",
                                                    background: "#DAF9F4",
                                                    color: "#555555",
                                                    borderRadius: "5px",
                                                    marginTop: "0 !important",
                                                },

                                                "& .MuiTooltip-arrow": {
                                                    color: "#DAF9F4",
                                                },
                                            },
                                        }}
                                        arrow
                                    >
                                        <li
                                            className="flex items-center cursor-pointer"
                                            onMouseEnter={() =>
                                                setIsHovered({
                                                    ...isHovered,
                                                    reject: true,
                                                })
                                            }
                                            onMouseLeave={() =>
                                                setIsHovered({
                                                    ...isHovered,
                                                    reject: false,
                                                })
                                            }
                                        >
                                            <div onClick={() => onRejectedInvoice()} className={`remove-step cursor-pointer flex `}>
                                                <CurrentAndNextStatus
                                                    backgroundColour={isHovered?.reject ? "red" : currentMode == "dark" ? "white" : "black"}
                                                    masterId={1}
                                                />
                                            </div>
                                        </li>
                                    </Tooltip>
                                ) : (
                                    ""
                                )}
                                {currentStatusInfo?.length > 0 && (
                                    <Tooltip
                                        title={
                                            <span>
                                                Current Status: <span style={{ color: "#00B399" }}>{currentStatusInfo[0]?.status_name || ""}</span>
                                            </span>
                                        }
                                        disablePortal
                                        placement="top"
                                        disableInteractive
                                        PopperProps={{
                                            sx: {
                                                "& .MuiTooltip-tooltip": {
                                                    fontSize: "0.9em",
                                                    maxHeight: "120px",
                                                    maxWidth: "250px",
                                                    background: "#DAF9F4",
                                                    color: "#555555",
                                                    borderRadius: "5px",
                                                    marginTop: "0 !important",
                                                },

                                                "& .MuiTooltip-arrow": {
                                                    color: "#DAF9F4",
                                                },
                                            },
                                        }}
                                        arrow
                                    >
                                        <li
                                            className="flex items-center cursor-pointer"
                                            onMouseEnter={() =>
                                                setIsHovered({
                                                    ...isHovered,
                                                    prevStatus: true,
                                                })
                                            }
                                            onMouseLeave={() =>
                                                setIsHovered({
                                                    ...isHovered,
                                                    prevStatus: false,
                                                })
                                            }
                                        >
                                            <CurrentAndNextStatus
                                                backgroundColour={isHovered?.prevStatus ? "#00B399" : currentMode == "dark" ? "white" : "black"}
                                                masterId={2}
                                            />
                                        </li>
                                    </Tooltip>
                                )}
                                {nextStatusInfo?.length > 0 && exStatusId && (
                                    <Tooltip
                                        title={
                                            <span>
                                                Next Status: <span style={{ color: "#00B399" }}>{nextStatusInfo[0]?.status_name || ""}</span>
                                            </span>
                                        }
                                        disablePortal
                                        placement="top"
                                        disableInteractive
                                        PopperProps={{
                                            sx: {
                                                "& .MuiTooltip-tooltip": {
                                                    fontSize: "0.9em",
                                                    maxHeight: "120px",
                                                    maxWidth: "250px",
                                                    background: "#DAF9F4",
                                                    color: "#555555",
                                                    borderRadius: "5px",
                                                    marginTop: "0 !important",
                                                },

                                                "& .MuiTooltip-arrow": {
                                                    color: "#DAF9F4",
                                                },
                                            },
                                        }}
                                        arrow
                                    >
                                        <li
                                            onClick={() => {
                                                if (
                                                    formatNumber(dataList?.datalist?.[0]?.unadjusted_misc) > 0 ||
                                                    formatNumber(dataList?.datalist?.[0]?.unadjusted_advance) > 0
                                                ) {
                                                    let PoVal = Math.abs(
                                                        formatNumber(dataList?.datalist?.[0]?.po_value) -
                                                            formatNumber(dataList?.datalist?.[0]?.invoices_tilldate)
                                                    );
                                                    unAdjAmt = Math.abs(
                                                        formatNumber(dataList?.datalist?.[0]?.unadjusted_advance) +
                                                            formatNumber(dataList?.datalist?.[0]?.unadjusted_misc)
                                                    );
                                                    finalVal = Math.abs(PoVal) - formatNumber(NetAmt?.textContent);
                                                    MasterAmt = Math.abs(finalVal - unAdjAmt); //This one for restriction future user for masteref_id == 3
                                                    store = MasterAmt;
                                                }
                                                if (props?.invoiceList?.next_status?.[0]?.master_refid == 3 && unAdjAmt > finalVal) {
                                                    showSwal(
                                                        `Approval is not allowed while the advance and miscellaneous unadjusted amounts are pending (${MasterAmt}).`
                                                    );
                                                    return;
                                                }
                                                if (checkIsEditeditems) {
                                                    statusChangeWarning(1);
                                                } else if (erpErrors?.length > 0 && nextStatusInfo[0]?.is_matching) {
                                                    statusChangeWarning(2, erpErrors);
                                                    // if (!isParking && nextStatusInfo[0]?.is_parking) {
                                                    //     statusChangeWarning(4, erpErrors);
                                                    // } else {
                                                    //     statusChangeWarning(2, erpErrors);
                                                    // }
                                                }
                                                // else if (!isParking && nextStatusInfo[0]?.is_parking) {
                                                //     statusChangeWarning(3);
                                                // }
                                                else if (overallErrors?.length > 0 && nextStatusInfo[0]?.master_refid == 3) {
                                                    statusChangeWarning(5, overallErrors);
                                                } else if (!nextUserList || nextStatusInfo?.[0]?.round_robin) {
                                                    changeStatus({ ...statusReqObj, user_id: null, remarks: null });
                                                } else if (nextUserList.length === 1) {
                                                    changeStatus({
                                                        ...statusReqObj,
                                                        user_id: nextUserList[0]?.id,
                                                        remarks: null,
                                                    });
                                                } else {
                                                    setSelectUserAnchor(true);
                                                }
                                            }}
                                            className={`flex items-center ${postStatusChangeFetch ? `pointer-events-none` : `cursor-pointer`}`}
                                            onMouseEnter={() =>
                                                setIsHovered({
                                                    ...isHovered,
                                                    nextStatus: true,
                                                })
                                            }
                                            onMouseLeave={() =>
                                                setIsHovered({
                                                    ...isHovered,
                                                    nextStatus: false,
                                                })
                                            }
                                        >
                                            <CurrentAndNextStatus
                                                backgroundColour={isHovered?.nextStatus ? "#00B399" : currentMode == "dark" ? "white" : "black"}
                                                masterId={3}
                                            />
                                        </li>
                                    </Tooltip>
                                )}

                                {/* {(exStatusId && showIcon && pageType == 3 && RoleActApprove?.isallowed == true) ||
                                (exStatusId && props?.invoiceList?.is_allocation == false) ? (
                                    <li>
                                        <span onClick={() => ApproveInvoice()} className={`remove-step cursor-pointer `}>
                                            <img
                                                src="/images/invoice/tickW.svg"
                                                title={"To Approve this Invoice"}
                                                alt="Approve "
                                                className={
                                                    currentMode === "dark"
                                                        ? ``
                                                        : ` invert-[90%] sepia-[0%] saturate-[1%] hue-rotate-[116deg] brightness-[98%] contrast-[86%]`
                                                }
                                            />
                                        </span>
                                    </li>
                                ) : (
                                    ""
                                )} */}

                                {/* {exStatusId && showIcon && pageType !== 3 ? (
                                    <li className={`valid-step ${props?.invoiceList?.is_allocation == false ? "hidden pointer-events-none" : ""}`}>
                                        <Button
                                            className={`opacity-[1]`}
                                            onClick={() => onSendToApproveInvoice()}
                                            disabled={props?.invoiceList?.message_type == "e"}
                                        >
                                            <img
                                                className={`opacity-[1] ${
                                                    currentMode === "dark"
                                                        ? ``
                                                        : ` invert-[90%] sepia-[0%] saturate-[1%] hue-rotate-[116deg] brightness-[98%] contrast-[86%] `
                                                }`}
                                                src={`${props?.invoiceList?.is_allocation == true ? `/images/invoice/approve-user.svg` : " "}`}
                                                title={` ${
                                                    MessageType
                                                        ? "Exceptions Found"
                                                        : props?.invoiceList?.is_allocation == true && !MessageType
                                                        ? `To send this Invoice to Approver`
                                                        : " "
                                                }`}
                                            />
                                        </Button>
                                    </li>
                                ) : (
                                    ``
                                )} */}

                                <li>
                                    <a
                                        href="javascript:void(0)"
                                        className="px-[10px] more-step relative"
                                        onClick={(e) => childCompRef.current.handleClick("bottom-end", 1, e)}
                                    >
                                        {(dataList?.datalist[0]?.comments_info?.length > 0 ||
                                            invTaskListData?.length > 0 ||
                                            dataList?.datalist[0]?.istask) && (
                                            <span class="absolute flex h-[8px] left-[-2px] top-[-3px] w-[8px]">
                                                <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-[#EB5757] opacity-75"></span>
                                                <span class="relative inline-flex rounded-full h-[8px] w-[8px] bg-[#EB5757] opacity-[.8]"></span>
                                            </span>
                                        )}

                                        <img
                                            className={
                                                currentMode === "dark"
                                                    ? ``
                                                    : ` invert-[90%] sepia-[0%] saturate-[1%] hue-rotate-[116deg] brightness-[98%] contrast-[86%]`
                                            }
                                            title="More Options"
                                            src="/images/common/threedots-white.svg"
                                            alt="threedots-white"
                                        />
                                    </a>
                                    <TogglePopup
                                        lowOverlay={true}
                                        invoice={dataList?.datalist[0]?.comments_info?.length > 0 ? true : false}
                                        task={dataList?.datalist[0]?.istask || invTaskListData?.length > 0 ? true : false}
                                        ref={childCompRef}
                                        menuList={filterMenuList}
                                        selectedItemChange={onFilterItems}
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* Recent Activity Modal  */}
                {ReactDOM.createPortal(
                    <Activity isOpened={isActivity} handleClose={() => setIsActivity(false)} className={`${isActivity && "Show"}`} />,
                    document.getElementById("user-modal-root")
                )}
                {/* To Reassign Modal  */}
                {ReactDOM.createPortal(
                    <ToReassign
                        isOpened={isreassign}
                        apiProps={{
                            inv_id: +invId,
                            org_id: +orgId,
                            type: 2,
                            status_id: dataList?.datalist[0]?.prev_status ? dataList?.datalist[0]?.prev_status?.[0]?.prev_status : null,
                            user_id: null,
                        }}
                        changeStatus={changeStatus}
                        statusName={dataList?.datalist[0]?.prev_status?.[0]?.status_name ? dataList?.datalist[0]?.prev_status?.[0]?.status_name : ""}
                        handleClose={() => setReassign(false)}
                        className={`${isreassign && "Show"}`}
                    />,
                    document.getElementById("user-modal-root")
                )}
                {/* MyTask Modal  */}
                {ReactDOM.createPortal(
                    <MyTask isOpened={assign} handleClose={() => setAssign(false)} className={`${assign && "Show"}`} />,
                    document.getElementById("user-modal-root")
                )}
                {/* Transfer invoice Modal  */}
                {ReactDOM.createPortal(
                    <TransferInvoice
                        isOpened={openTransfer}
                        InvId={InvId}
                        handleClose={() => setOpenTransfer(false)}
                        className={openTransfer && "Show"}
                    />,
                    document.getElementById("user-modal-root")
                )}
                {selectUserAnchor && (
                    <>
                        {ReactDOM.createPortal(
                            <SelectUserModal
                                id={1}
                                isOpened={selectUserAnchor}
                                setSelectUserAnchor={setSelectUserAnchor}
                                currentMode={currentMode}
                                className={selectUserAnchor && "Show"}
                                getEmail={userEmail}
                                userList={nextUserList}
                                setUserEmail={setUserEmail}
                                changeStatus={changeStatus}
                                statusReqObj={statusReqObj}
                                handleSelectUser={(e, value, reason) => _handleSelectUser(1, value, reason, "rule_id")}
                            />,
                            document.getElementById("Modal-root")
                        )}
                    </>
                )}
                {ReactDOM.createPortal(
                    <CommentModal
                        isEdit={InputDisable}
                        isOpened={showComment}
                        setShowComment={setShowComment}
                        currentMode={currentMode}
                        invID={invId}
                        className={showComment && "Show"}
                        handleClose={() => setShowComment(false)}
                    />,
                    document.getElementById("Modal-root")
                )}
            </div>
        </>
    );
};

export default Sidebar;