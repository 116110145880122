import _, { cloneDeep } from "lodash";
import ReactDOM from "react-dom";
import { useContext, useEffect, useState } from "react";
import { LedgerModal } from "./LedgerModal";
import { NumericFormat } from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import { MenuItem, Select, FormControl } from "@mui/material";
import { getCostCentreList, getGeneralLedgerList, postThreeWayMatching, UpdateServiceLineItems } from "../../../redux/actions/invoice";
import {
    autoCalculate,
    invoiceDataFetch,
    formatNumber,
    removeVendorCode,
    thousandSeperator,
    decSeperator,
    decryptId,
    generateFormattedText,
} from "../../../utils/helpers";
import { AddItemsModal } from "./AddItemsModal";
import { Tooltip, Checkbox } from "@mui/material";
import { ThreeWayMatchingModal } from "../Matching/index";
import Swal from "sweetalert2";
import { getCurrentUser } from "../../../redux/actions/auth";
import { useLocation } from "react-router-dom";
import { ERP_VALIDATIONBYMATCHING_UPDATE } from "../../../redux/types/types";
let deleteArr = [];
let errArr = [];
let Freight;
export const InvTable = (props) => {
    const dispatch = useDispatch();
    const CurrentUser = getCurrentUser();
    const orgId = localStorage.getItem("orgId");
    let { ListItems, setLineItems, itemsHeader, tableClose, mainData } = props;
    // let matching_info = cloneDeep(mainData?.matching_info);
    const [nonPOItemList, serNonPOItemList] = useState(props?.nonPOItemList ? props?.nonPOItemList : []);
    const [clonedNonPOItemList, setClonedNonPOItemList] = useState(_.cloneDeep(nonPOItemList));
    const InputDisable = mainData?.ext_status_id == 5 || mainData?.ext_status_id == 4 || mainData?.ext_status_id == 3;
    const invListData = mainData?.invoice_items;
    const poNumber = invListData?.find((ele) => ele?.label_name == "purchase_order_no");
    const grossAmnt = invListData?.find((ele) => ele?.label_name == "total_net");
    const serviceItemObj = mainData?.items_name;
    const { currentMode } = useContext(ThemeContext);
    const dropdownColor = "#333333";
    const dropdownText = "#ECECEC";
    const { data: taxListData, requestComplete: reqCom } = useSelector((state) => state?.taxCodeWithPercentageListState);
    let rmvVendor = removeVendorCode(ListItems);
    useEffect(() => {
        setData(rmvVendor);
    }, [ListItems]);
    useEffect(() => {
        serNonPOItemList(props?.nonPOItemList);
    }, [props?.nonPOItemList]);
    const [Data, setData] = useState(rmvVendor || {});
    const [costCenterList, setCostCenterList] = useState([]);
    const [oldData, setOldData] = useState([]);
    const [addItem, setAddItem] = useState(false);
    const [openLedger, setOpenLedger] = useState(false);
    const [freightCheckArr, setFreightCheckArr] = useState([]);
    const [costCenter, setCostCenter] = useState();
    const [index, setIndex] = useState("");
    const [totalLedgerVal, setTotalLedgerVal] = useState(0);
    const [openMatchingTable, setOpenMatchingTable] = useState(false);
    const [getIndexOfMatching, setIndexOfMatching] = useState(null);
    const headersData = _.filter(invListData, { is_item: "0" });
    const mergedArray = headersData.concat(props?.updateDocList);
    const filteredMergedArray = [...new Set(mergedArray)];
    let retention = 0;
    let PoItem = mainData?.document_id !== 2 && mainData?.document_id !== 4 && mainData?.targetvalue !== "IQAI" && mainData?.targetvalue !== null;
    const location = useLocation();
    const urlData = location?.search?.slice(1)?.replace(/\n\s+/g, "\n")?.trim();
    const urlObj = JSON?.parse(decryptId(urlData));
    //  This one after approve the invoice should not edit filed or line items //
    let status = urlObj?.status;
    // let nonPoCostListData = costCenterList?.cost_center;
    const [checkIndex, setCheckIndex] = useState(0);
    const [checkRowIndex, setCheckRowIndex] = useState(0);
    const headerMapping = {};
    itemsHeader?.forEach((header) => {
        headerMapping[header.label_name] = header?.display_name;
    });
    const findFreightPo = props?.poNumberList?.filter((el) => el?.PoNumber === poNumber?.label_value);
    const hasMaterial = _.some(findFreightPo, { VendorType: "MATERIAL" });
    const hasFreight = _.some(findFreightPo, { VendorType: "FREIGHT" });
    const checkFreightByPo = props?.checkFreightByPo;
    useEffect(() => {
        if (hasMaterial && hasFreight) {
            props?.setCheckFreightByPo(true);
        }
    }, [findFreightPo]);
    const defaultObj = {
        label_value: "",
        isrequired: true,
        ismandatory: false,
        status: 0,
        data_type: "s",
        id: 0,
        maxlength: 0,
        iseditable: true,
        item_no: 1,
        label_name: "is_freight",
        label_displayname: "Freight/Custom",
        is_item: 1,
    };
    const LineItemlength = Object.keys(Data).length;
    const defaultArray = Array.from({ length: LineItemlength }, (_, index) => ({
        ...defaultObj,
        item_no: index + 1,
    }));
    // Use lodash to map and extract item_code from each object
    const isFreightCheck =
        //  checkFreightByPo ? defaultArray :
        _.map(Data, (item) => _.get(item, "is_freight"));
    useEffect(() => {
        if (checkFreightByPo) {
            // Create a new data object with the defaultObj added to each item
            const newData = { ...Data };
            Object.entries(newData).forEach(([key, item], index) => {
                item.is_freight = defaultArray[index];
            });
            setData(newData);
            // Check if "is_freight" exists in itemsHeader, if not, add it
            const freightItem = itemsHeader.find((el) => el?.label_name === "is_freight");
            if (!freightItem || freightItem == undefined) {
                itemsHeader?.unshift(props?.defaultData?.freight_name?.[0]);
            }
        }
    }, [checkFreightByPo]);
    // Table header (th) mapping!!...
    const tableHeaders = itemsHeader?.map((headerItem) => <th key={headerItem?.label_name}>{headerItem?.display_name}</th>);
    // handleChange for input box in globally!!!....
    // !----- Ledger Modal Inside Functions and Save button validation -----!  Starting
    const numFormat = localStorage.getItem("OrgNumFormat");
    const findTotalValObj = _.get(Data?.[index], "item_total", null);
    let validateTotalAmount = "";
    const deleteRow = (rowId) => {
        Swal.fire({
            text: `Are you sure you want to delete this line item?`,
            icon: "warning",
            closeOnClickOutside: false,
            showCloseButton: true,
            showCancelButton: true,
            buttons: {
                cancel: "Cancel",
                confirm: "Confirm",
            },
        }).then((confirm) => {
            if (confirm?.isConfirmed) {
                mainData.matching_info = mainData?.matching_info?.filter((item) => {
                    if (item?.lineItemNo != Number(rowId) + 1) {
                        if (item?.lineItemNo > Number(rowId) + 1) {
                            item.lineItemNo = item?.lineItemNo - 1;
                        }
                        return item;
                    }
                });
                dispatch({ type: ERP_VALIDATIONBYMATCHING_UPDATE, opp: 1, erpValidateArr, cancelData, lineItemNo: Number(rowId) + 1 });
                // Clone the existing data
                const clonedData = _.cloneDeep(Data);
                const clonedGlData = _.cloneDeep(nonPOItemList);
                const deletedRow = clonedData[rowId];
                const result = _.map(_.values(deletedRow), (innerObj) => {
                    if (innerObj.hasOwnProperty("status")) {
                        return {
                            ...innerObj,
                            status: 3,
                        };
                    }
                    return innerObj;
                });
                deleteArr.push(result);
                // Delete the row with the specified ID
                delete clonedData[rowId];
                // delete clonedGlData[rowId];
                // Reorder the index and update item_no inside the object
                const newData = {};
                let newGlData = [];
                let newIndex = 0;

                for (const key in clonedData) {
                    if (clonedData.hasOwnProperty(key)) {
                        const rowData = clonedData[key];
                        rowData.item_no = newIndex + 1; // Update item_no
                        newData[newIndex] = rowData;
                        newIndex++;
                        if (nonPOItemList?.length > 0) {
                            const rowData = clonedGlData
                                .filter((el) => el?.line_no !== +rowId + 1)
                                .map((item, index) => ({ ...item, line_no: index + 1 }));
                            newGlData = rowData;
                        }
                    }
                }
                // Update the state with the new data
                props?.setNonPOItemList(newGlData);
                setData(newData);
            } else {
                return false;
            }
        });
    };
    const clonedCostCenterList = _.cloneDeep(props?.costListData);
    const handleCostCenter = (newrow, ListData) => {
        if (numFormat == "3") {
            validateTotalAmount = findTotalValObj?.label_value?.replaceAll(" ", "");
        } else if (numFormat == "1") {
            validateTotalAmount = findTotalValObj?.label_value.replaceAll(",", "");
        } else if (numFormat == "2") {
            validateTotalAmount = findTotalValObj?.label_value?.replaceAll(".", "");
        }
        let checkTotal = [];
        if (numFormat == "3") {
            checkTotal = nonPoCostListData?.map((el) => parseFloat(el?.amount?.replaceAll(" ", "")));
        } else if (numFormat == "1") {
            checkTotal = nonPoCostListData?.map((el) => parseFloat(el?.amount?.replaceAll(",", "")));
        } else if (numFormat == "2") {
            checkTotal = nonPoCostListData?.map((el) => parseFloat(el?.amount?.replaceAll(".", "")?.replaceAll(",", ".")));
        }
        nonPoCostListData = nonPoCostListData?.filter((el) => el?.cost_center !== "");
        const totalVal = checkTotal?.reduce((partialSum, a) => partialSum + a, 0);
        const findDuplicate = ListData?.map((el) => el?.cost_center);
        const validateArr = [...new Set(findDuplicate)];
        // to find the unfilled field on cost center
        if (ListData?.length !== 0) {
            const filterVal = ListData?.filter((el) => el?.cost_center == "");
            const checkGl = ListData?.filter((el) => el?.gl_account_code == "");
            // Check if any cost_center value is not present in the name property of objects in array2
            const missingCostCenters = findDuplicate
                ? findDuplicate.filter((costCenter) => !listData.some((obj) => obj?.costcentre_name === costCenter))
                : [];

            // Display an alert if there are missing cost_center values
            if (missingCostCenters.length > 0) {
                setAddItem(true);
                Swal.fire({
                    text: `Cost center/Internal order value not found!`,
                    icon: "warning",
                    closeOnClickOutside: false,
                    showCloseButton: true,
                    buttons: {
                        confirm: true,
                    },
                }).then((confirm) => {
                    if (confirm) {
                        return false;
                    }
                });
            } else if (filterVal?.length !== 0 || checkGl?.length !== 0) {
                setAddItem(true);
                Swal.fire({
                    text: `Remove the empty row !`,
                    icon: "warning",
                    closeOnClickOutside: false,
                    showCloseButton: true,
                    buttons: {
                        confirm: true,
                    },
                }).then((confirm) => {
                    if (confirm) {
                        return false;
                    }
                });
            }

            // to find the duplicates on cost center name on list
            else if (validateArr?.length !== findDuplicate?.length) {
                setAddItem(true);
                Swal.fire({
                    text: `Do not select the Cost centre/Internal order option more than one row  for the same line item!.`,
                    icon: "warning",
                    closeOnClickOutside: false,
                    showCloseButton: true,
                    buttons: {
                        confirm: true,
                    },
                }).then((confirm) => {
                    if (confirm) {
                        return false;
                    }
                });
            }
            // to find the total mismatch on cost center total and line item total.
            else if (numFormat == "1" || numFormat == "3") {
                if (parseFloat(totalVal?.toFixed(2)) !== +(+validateTotalAmount)?.toFixed(2)) {
                    Swal.fire({
                        text: `The total amount entered in GL is not matching with the line items total amount(${(+validateTotalAmount)?.toFixed(
                            2
                        )})`,
                        icon: "warning",
                        closeOnClickOutside: false,
                        showCloseButton: true,
                        buttons: {
                            confirm: true,
                        },
                    }).then((confirm) => {
                        if (confirm) {
                            return false;
                        }
                    });
                } else {
                    nonPOItemList?.forEach((ele) => {
                        if (ele?.line_no == +index + 1) {
                            ele.cost_center = ListData;
                            return ele;
                        }
                    });
                    setCostCenter(newrow);
                    setAddItem(false);
                }
            } else if (numFormat == "2") {
                const validateTotAmunt = validateTotalAmount?.replaceAll(",", ".");
                if (totalVal !== +(+validateTotAmunt)?.toFixed(2)) {
                    Swal.fire({
                        text: `The total amount entered in GL is not matching with the line items total amount(${(+validateTotalAmount)?.toFixed(
                            2
                        )})`,
                        icon: "warning",
                        closeOnClickOutside: false,
                        showCloseButton: true,
                        buttons: {
                            confirm: true,
                        },
                    }).then((confirm) => {
                        if (confirm) {
                            return false;
                        }
                    });
                } else {
                    setCostCenter(newrow);
                    setAddItem(false);
                    nonPOItemList?.forEach((ele) => {
                        if (ele?.line_no == +index + 1) {
                            ele.cost_center = ListData;
                            return ele;
                        }
                    });
                }
            }
        }
    };
    // !----- Ledger Modal Inside Functions and Save button validation -----!  END
    //to parse the value for sum
    const showNumber = (value) => {
        const val = userNumFormat == "2" ? value.replaceAll(".", ",") : value;
        return val;
    };
    const mappedData = _.map(Data, (innerObject, key) => {
        // You can perform any operation you want on innerObject here
        return {
            ...innerObject,
            id: key, // Adding a new property 'id' with the key
        };
    });
    const handleMatchingTable = (index) => {
        const updatedListItems = _.flatMap(mappedData, (item) => {
            return _.compact([
                item.is_freight,
                item.item_code,
                item.item_name,
                item.quantity,
                item.unit,
                item.item_price,
                item.item_tax_amount,
                item.item_tax_percentage,
                item.item_discount_amount,
                item.item_discount_percentage,
                item.item_cess_tax_amount,
                item.item_cess_tax_percentage,
                item.item_cgst_tax_amount,
                item.item_cgst_tax_percentage,
                item.item_igst_tax_amount,
                item.item_igst_tax_percentage,
                item.item_sgst_tax_amount,
                item.item_sgst_tax_percentage,
                item.item_ugst_tax_amount,
                item.item_ugst_tax_percentage,
                item.item_total,
            ]);
        });
        props.updateInvoiceDocument(updatedListItems);
        const isItemZeroArr = invListData?.filter((ele) => ele?.is_item == "0");
        const mergedInitialArr = isItemZeroArr.concat(updatedListItems);
        const reqObj = {
            numFormat: userNumFormat,
            type: 1,
            orgId: orgId,
            invoiceInfo: props?.checkArraysEqual && props?.areArraysEqual ? mergedInitialArr : filteredMergedArray,
        };
        if (
            (matchingInfoUpdateArr == undefined && error == "") ||
            (props?.validateChanges?.length !== 0 && error == "") ||
            (openMatchingTable && error == "")
        ) {
            dispatch(postThreeWayMatching(reqObj));
        }
        setOpenMatchingTable(true);
        setIndexOfMatching(index - 1);
    };
    const handleUpdateserviceLineItems = () => {
        // Simplify the formatting variables
        const userThousand = userNumFormat === "1" ? "," : userNumFormat === "2" ? "." : " ";
        const userDecimal = userNumFormat === "1" ? "." : userNumFormat === "2" ? "," : ".";
        const orgThousand = numFormat === "1" ? "," : numFormat === "2" ? "." : " ";
        const orgDecimal = numFormat === "1" ? "." : numFormat === "2" ? "," : ".";
        const clonedData = _.cloneDeep(Data);

        const result = _.mapValues(clonedData, (outerObj) => {
            return _.mapValues(outerObj, (innerObj) => {
                return {
                    ...innerObj,
                    status: 3,
                };
            });
        });
        const convertedAmt = grossAmnt?.label_value?.replaceAll(userDecimal, "$").replaceAll(userThousand, orgThousand).replaceAll("$", orgDecimal);
        const dataArray = _.flatMap(Object.values(result), (innerObjects) => Object.values(innerObjects));
        // 'dataArray' now contains an array of the updated objects.
        deleteArr.push(dataArray);
        const reqObj = {
            invId: props?.invNo,
            numType: numFormat,
            orgId: orgId,
            purchaseOrderNo: poNumber?.label_value,
            total: convertedAmt,
            itemsInfo: serviceItemObj,
        };

        dispatch(UpdateServiceLineItems(reqObj));
    };
    // Example tax details function (you should replace this with your actual data source)

    const _handleChange = (e, index, labelName, item, ele) => {
        // Use lodash to update the label_value!!....
        let changed = _.set(
            _.cloneDeep(Data),
            `${index}.${labelName}.label_value`,
            labelName == "is_freight" ? (e.target.checked ? "true" : "false") : e?.target?.value
        );
        // Update the freightCheckArr based on checkbox state
        if (labelName == "is_freight" && e.target.checked) {
            setFreightCheckArr((prevArr) => [...prevArr, +index]);
        } else if (labelName == "is_freight") {
            setFreightCheckArr((prevArr) => prevArr.filter((item) => item !== +index));
        }
        // If label_name is "tax_code," update the tax_percentage
        if (labelName === "item_tax_code") {
            const selectedTaxCode = e.target.value;
            const taxDetails = taxListData.find((code) => code.tax_code === selectedTaxCode); // You need to implement this function
            if (taxDetails) {
                changed = _.set(changed, `${index}.item_tax_percentage.label_value`, taxDetails.tax_percentage);
            }
        }
        autoCalculate(changed, index, labelName, Data);
        setData(changed);
        props?.handleValidateChanges([changed]);
    };
    const itemTotalLabelValues = _.map(Data, (item) => _.get(item, "item_total.label_value"));
    const reduceSum = itemTotalLabelValues?.map((value) => parseFloat(value?.replace(/,/g, ""))).filter((value) => !isNaN(value));

    // Calculate the sum of the numbers
    const totalSum = reduceSum?.reduce((total, num) => total + num, 0);
    props?.setTotalAmountVal(totalSum);
    // Function to update item_no based on the position of the inner array
    const updateItemNo = (arrayOfArrays) => {
        _.forEach(arrayOfArrays, (innerArray, index) => {
            const newItemNo = index + 1;
            _.forEach(innerArray, (item) => {
                // Update the item_no to have the same value based on the position of the inner array
                if (_.isObject(item)) {
                    item.item_no = newItemNo;
                }
            });
        });
        return arrayOfArrays; // Return the modified array
    };
    // Save line Items
    const onSaveLineItems = () => {
        // Assuming your object is named 'data'
        const validateTaxCode = _.forEach(Data, (innerObj) => {
            _.forEach(innerObj, (value, key) => {
                // Check if the label_value for item_tax_amount is empty
                if (
                    ((key === "item_tax_percentage" && value.label_value === "") || (key === "item_tax_percentage" && +value.label_value === 0)) &&
                    innerObj?.hasOwnProperty("item_tax_code")
                ) {
                    // If so, set the label_value for item_tax_code to an empty string
                    innerObj.item_tax_code.label_value = "";
                }
            });
        });
        props.setLineItemObjectLength(Object.keys(validateTaxCode).length);
        const deletePayload = _.flatten(deleteArr);
        const dataArray = Object.values(validateTaxCode).map((item) => {
            // Call the function to update item_no
            return Object.values(item);
        });
        const Arr = updateItemNo(dataArray);
        const filteredLineItemArrayForPayload = Arr?.map((innerArray) => {
            return innerArray?.filter((item) => {
                return typeof item == "object" && item?.label_name !== undefined;
            });
        });
        const mergedArr = [].concat(...filteredLineItemArrayForPayload).concat(...deletePayload); // to merged the two arrays
        const newArr = [];
        mergedArr?.forEach((item) => {
            if (item?.id == 0 && item?.status == 3) {
            } else {
                newArr?.push(item);
            }
        });
        const convertPayload = newArr?.map((obj) => {
            if (obj.hasOwnProperty("status")) {
                return {
                    ...obj,
                    status: obj?.id !== 0 && obj?.status == 0 ? 2 : obj?.id == 0 ? 1 : 3,
                };
            }
            return obj;
        });
        const filterArr = convertPayload?.filter((ite) => typeof ite == "object");
        // Use Lodash to remove objects with only 'label_value' property
        const filteredData = _.filter(filterArr, (item) => Object.keys(item).length > 1);
        props?.setLineItemData(filteredData);
        //To check the mandatory and empty fields
        const mandatoryErr = [];
        const maxLengthError = [];
        const dataTypeError = [];
        _.mapValues(Data, (item, key) => {
            const itemKeys = Object.keys(item);
            itemKeys?.map((ele) => {
                //Check if field is mandatory
                const checkMandatory = item[ele]?.ismandatory && !item[ele]?.label_value;
                //Check max-length is appropriate as in invoice layout
                const checkMaxLength = item[ele]?.maxlength != 0 ? item[ele]?.label_value?.length > item[ele]?.maxlength : false;
                //Check the value is correct as per datatype
                const checkDataType = item[ele]?.data_type === "p" ? formatNumber(item[ele]?.label_value) > 100 : false;
                if (checkMandatory) {
                    const mandatory = item[ele]?.label_displayname;
                    mandatoryErr?.push(mandatory);
                    errArr?.push(mandatory);
                } else if (checkMaxLength) {
                    const maxLength = item[ele]?.label_displayname;
                    maxLengthError?.push(maxLength);
                    errArr?.push(maxLength);
                } else if (checkDataType) {
                    const typeData = item[ele]?.label_displayname;
                    dataTypeError?.push(typeData);
                    errArr?.push(typeData);
                }
            });
        });
        const filteredmandatoryErr = generateFormattedText([...new Set(mandatoryErr)], "The following line items are required:!<br/><br/>");
        const filteredmaxLengthError = generateFormattedText([...new Set(maxLengthError)], "Reached the maximum length!<br/><br/>");
        const filtereddataTypeError = generateFormattedText([...new Set(dataTypeError)], "Percentage value ought to be lower than 100%!<br/><br/>");
        if (mandatoryErr?.length > 0 || maxLengthError?.length > 0 || dataTypeError?.length > 0) {
            Swal.fire({
                html:
                    mandatoryErr?.length > 0
                        ? filteredmandatoryErr
                        : maxLengthError?.length > 0
                        ? filteredmaxLengthError
                        : dataTypeError?.length > 0
                        ? filtereddataTypeError
                        : "",
                icon: "warning",
                closeOnClickOutside: false,
                confirmButtonText: "Yes, I am sure!", // Rename the confirm button
                cancelButtonText: "No, cancel it!",
                showCloseButton: true,
                showCancelButton: true,
                buttons: {
                    cancel: "Cancel",
                    confirm: "Confirm",
                },
                dangerMode: true,
            }).then((confirm) => {
                if (confirm?.isConfirmed) {
                    mainData.cancelData = mainData.matching_info;
                    dispatch({ type: ERP_VALIDATIONBYMATCHING_UPDATE, opp: 3, erpValidateArr, cancelData, lineItemNo: null });
                    setLineItems(Data);
                    tableClose(false);
                    document.querySelector(".lineItem").classList.add("approve");
                    errArr = [];
                    // onSaveInvDoc(true);
                } else {
                    return false;
                }
            });
        } else {
            mainData.cancelData = mainData.matching_info;
            dispatch({ type: ERP_VALIDATIONBYMATCHING_UPDATE, opp: 3, erpValidateArr, cancelData, lineItemNo: null });
            document?.querySelector(".lineItem")?.classList?.add("approve");
            setLineItems(Data);
            tableClose(false);
        }
        setTimeout(function () {
            document.querySelector(".Swal.fire-text").innerHTML += `<br/>`;
            const removeDublicate = [...new Set(errArr)];
            removeDublicate?.map((itemValue, index) => {
                document.querySelector(
                    ".Swal.fire-text"
                ).innerHTML += `<strong style="margin-top: 5px; display: block;">${++index}. ${itemValue}</strong>`;
            });
        }, 200);
    };

    // add new row func!!!...
    const addNewRow = () => {
        // Clone the existing data
        const clonedData = _.cloneDeep(Data || {});
        const newRowId = Object.keys(clonedData).length;
        // Generate a new row based on the initialRowStructure
        const newRow = {};
        itemsHeader?.forEach((field) => {
            newRow[field?.label_name] = {
                label_value: "",
                label_name: field?.label_name,
                label_displayname: field?.display_name,
                isrequired: true,
                ismandatory: false,
                status: 1,
                data_type: field?.data_type,
                id: 0, // You can generate a unique ID here
                maxlength: 0,
                iseditable: true,
                item_no: newRowId + 1,
                is_item: 1,
            };
        });
        const nonPOObj = { line_no: newRowId + 1, cost_center: [], ledger_type: 0, isactive: true };
        if (isNonPO) {
            const Arr = nonPOItemList;
            Arr?.push(nonPOObj);
            serNonPOItemList(Arr);
        }
        clonedData[newRowId] = newRow;
        setData(clonedData);
    };
    let nonPoCostListData = costCenterList;
    const invoiceList = useSelector((state) => state?.getInvoiceItemsState);
    //* If the line item no length is 0 and no three way matchig is called, check for Service PO
    const { data: getPoType } = useSelector((state) => state?.getPoTypeState);
    let isServicePo = false;
    if (getPoType?.length > 0) {
        if (getPoType?.find((ele) => ele?.VendorType?.toLowerCase() == "service")) {
            isServicePo = true;
        }
    }

    const handleSelect = (account_code, index) => {
        nonPoCostListData?.forEach((element, inde) => {
            if (inde == checkRowIndex) {
                element.gl_account_code = account_code;
            }
        });
        setOpenLedger(false);
    };
    const isNonPO = invoiceList?.data?.datalist?.[0]?.document_id == 2 || invoiceList?.data?.datalist?.[0]?.document_id == 4;
    const userNumFormat = localStorage.getItem("userNumberFormat");

    //* select the field on line-item ---> trigger tooltip in invoice content preview
    const handleOnItemClick = (itemName, value) => {
        if (value !== "") {
            props.setShowToolTip(itemName);
        }
    };
    const data = useSelector((state) => state?.generalLedgerListState);
    const { data: listData } = useSelector((state) => state?.costCentreListState);

    // to open the ledger modal popper
    const handleOpenLedger = (index) => {
        setCheckRowIndex(index);
        setOpenLedger(true);
        if (data?.data?.length == 0) {
            dispatch(getGeneralLedgerList(orgId));
        }
    };
    const [costData, setCostData] = useState();
    //to open the cost center modal popper
    const handleOpenCostCenter = (indexId, Val, data) => {
        setCostData({ si: indexId + 1, itemCode: data?.item_code?.label_value, total: data?.item_total?.label_value });
        setIndex(Val);
        setCheckIndex(indexId);
        const costList = nonPOItemList?.find((elem) => elem?.line_no == +Val + 1);
        // Clone the array using lodash
        const clonedNonPOItemList = _.cloneDeep(costList?.cost_center);
        setCostCenterList(clonedNonPOItemList);
        setAddItem(true);
        if (listData?.length == 0) {
            dispatch(getCostCentreList(orgId));
        }
    };
    const _tbleCancel = () => {
        mainData.matching_info = mainData.cancelData;
        dispatch({ type: ERP_VALIDATIONBYMATCHING_UPDATE, opp: 2, erpValidateArr, cancelData, lineItemNo: null });
        nonPOItemList?.forEach((item1) => {
            const correspondingItem = clonedNonPOItemList.find((item2) => item2.line_no === item1.line_no);
            if (correspondingItem) {
                item1.cost_center = correspondingItem?.cost_center;
            }
        });
        tableClose(false);
    };
    //to close the cost center modal popper
    const handleCloseCostCenter = (data) => {
        setAddItem(false);
    };
    const date = new Date();
    const d = date?.toLocaleDateString("en-GB");
    const dateStr1 = CurrentUser?.expiration_date;
    const dateStr2 = d;
    const parseDate = (dateStr) => {
        const [day, month, year] = dateStr?.split("/");
        // Months in JavaScript Date are 0-based, so we need to subtract 1 from the month
        return new Date(`${year}-${month - 1}-${day}`);
    };
    const date1 = parseDate(dateStr1);
    const date2 = parseDate(dateStr2);
    const matchdate = date1 < date2;
    const showIcon =
        (!matchdate && CurrentUser?.license_type == 2) ||
        CurrentUser?.license_type == 1 ||
        CurrentUser?.license_type == 3 ||
        CurrentUser?.license_type == 4;
    const { data: erpValidateArr, fetching, cancelData, error, requestComplete } = useSelector((state) => state?.threeWayMatchingByErpState);
    const matchingInfoUpdateArr = erpValidateArr?.overallData;
    const recoveryInfo = !erpValidateArr?.recovery_info ? invoiceList?.data?.datalist[0]?.recovery_info : erpValidateArr?.recovery_info;
    const matchingInfo = invoiceList?.data?.datalist[0]?.matching_info;
    // po items filter isLine Item true
    const onlySelectedLineItem = _.map(_.filter(matchingInfoUpdateArr, { isLineItem: true }), (item) =>
        _.pick(item, ["poItemCode", "isLineItem", "grnMatchedInfo", "overall", "lineItemNo", "errorMessages", "retentionPercentage"])
    );
    const serviceInvoice =
        erpValidateArr?.overallData !== undefined
            ? erpValidateArr?.poType == "SERVICE"
            : mainData?.matching_info?.length > 0
            ? mainData?.matching_info?.[0]?.poType == "SERVICE"
            : Object.values(Data)?.length == 0
            ? isServicePo
            : false;
    // For retention
    if (recoveryInfo) {
        let matching = !onlySelectedLineItem || onlySelectedLineItem?.length == 0 ? matchingInfo : onlySelectedLineItem;
        let perc = recoveryInfo?.retentionPercentage ? parseFloat(recoveryInfo?.retentionPercentage) : 0;
        if (recoveryInfo?.retentionIndicator?.toLowerCase() === "h") {
            if (perc != 0 && recoveryInfo?.retentionOnGross) {
                _.mapValues(Data, (item, key) => {
                    const getLineItem = matching?.find((item) => item.lineItemNo == +key + 1);
                    const check = !getLineItem ? 0 : parseFloat(formatNumber(item?.item_total?.label_value));

                    retention = retention + isNaN(check) ? 0 : (check / 100) * perc;
                });
            } else if (perc != 0 && !recoveryInfo?.retentionOnGross) {
                _.mapValues(Data, (item, key) => {
                    const getLineItem = matching?.find((item) => item.lineItemNo == +key + 1);
                    const quantity = parseFloat(formatNumber(item?.quantity?.label_value));
                    const item_price = parseFloat(formatNumber(item?.item_price?.label_value));
                    const sumValue = isNaN(quantity) || isNaN(item_price) || !getLineItem ? 0 : ((quantity * item_price) / 100) * perc;

                    retention = retention + sumValue;
                });
            }
        }
        if (recoveryInfo?.retentionIndicator?.toLowerCase() === "i") {
            if (recoveryInfo?.retentionOnGross) {
                _.mapValues(Data, (item, key) => {
                    perc = matching?.find((item) => item.lineItemNo == +key + 1)?.retentionPercentage;
                    perc = isNaN(perc) ? 0 : parseFloat(perc);

                    const check = parseFloat(formatNumber(item?.item_total?.label_value));
                    const sumValue = isNaN(check) ? 0 : (check / 100) * perc;
                    retention = retention + sumValue;
                });
            } else if (!recoveryInfo?.retentionOnGross && invoiceList?.data?.datalist[0]?.retention_recovery) {
                _.mapValues(Data, (item, key) => {
                    perc = matching?.find((item) => item?.lineItemNo == +key + 1)?.retentionPercentage;
                    perc = isNaN(perc) ? 0 : parseFloat(perc);
                    const quantity = parseFloat(formatNumber(item?.quantity?.label_value));
                    const item_price = parseFloat(formatNumber(item?.item_price?.label_value));

                    const sumValue = isNaN(quantity) || isNaN(item_price) ? 0 : ((quantity * item_price) / 100) * perc;
                    retention = retention + sumValue;
                });
            }
        }
        props?.setTotRetentionAmount(retention?.toString());
    }
    // Table Data (td) mapping!!...
    const Tablerow = Object?.keys(Data)?.map((key, i) => {
        const item = Data?.[key];
        const tableCells = Object?.keys(headerMapping)?.map((labelName, index) => {
            const Numeric = item[labelName]?.data_type == "m" || item[labelName]?.data_type == "p" || item[labelName]?.data_type == "n";
            const taxCode = labelName == "item_tax_code" && isNonPO;
            const General = labelName == "general_ledger";
            Freight = labelName == "is_freight";
            const Total = item[labelName]?.label_name == "item_total";
            let ItemNo = item[labelName]?.item_no;
            let cost = nonPOItemList?.find((elem) => elem?.line_no == +key + 1);
            const allObjectsNotEmpty = cost?.cost_center?.every((item) => {
                return Object.values(item).every((value) => value !== "");
            });
            return (
                <td>
                    {General ? (
                        !allObjectsNotEmpty || cost?.cost_center?.length == 0 || cost?.cost_center == undefined ? (
                            <Button
                                className="flex items-center w-full justify-center"
                                disabled={status == "7" || status == "4"}
                                onClick={() => handleOpenCostCenter(item[labelName]?.item_no !== undefined ? item[labelName]?.item_no : 0, key, item)}
                            >
                                <img src="/images/main/plus-circle-grn.svg" alt="plus-circle" className="mr-2" /> add items
                            </Button>
                        ) : (
                            <ul>
                                {
                                    <li onClick={() => handleOpenCostCenter(item[labelName]?.item_no, key, item)}>
                                        <u>{`${cost?.cost_center?.length}  ${cost?.cost_center?.length == 1 ? "item" : "items"}`}</u>
                                    </li>
                                }
                            </ul>
                        )
                    ) : null}

                    {taxCode ? (
                        <Select
                            onChange={(e) => _handleChange(e, key, labelName, item[labelName], item)}
                            name="tax_code"
                            value={!item[labelName]?.label_value ? 0 : item[labelName]?.label_value}
                            className={`min-w-[236px] px-[8px] txcode`}
                            MenuProps={{
                                sx: {
                                    "& .MuiMenu-paper": {
                                        backgroundColor: "#333333",
                                        color: "#ECECEC !important",
                                        maxWidth: "50rem !important",
                                    },
                                    "& .MuiMenu-paper .MuiButtonBase-root.MuiMenuItem-root:hover": {
                                        backgroundColor: "#000000 !important",
                                    },
                                    "& .Mui-selected": {
                                        backgroundColor: "rgba(148, 148, 148, 0.07) !important",
                                    },
                                },
                            }}
                            IconComponent={() => (
                                <img
                                    className="absolute right-[15px] opacity-[.5] pointer-events-none"
                                    src={`/images/header/chevronDown-white.svg`}
                                    alt="chevronDown"
                                />
                            )}
                            SelectDisplayProps={{
                                style: {
                                    backgroundColor: "#1A1A1A",
                                    paddingTop: "9px",
                                    paddingBottom: "9px",
                                    color: "#ECECEC",
                                    paddingRight: "30px",
                                    border: "1px solid rgba(85, 85, 85, 0.3)",
                                    borderRadius: "8px",
                                    textAlign: "left",
                                },
                            }}
                        >
                            <MenuItem selected value={0} name="tax_code">
                                {"Select"}
                            </MenuItem>
                            {taxListData?.map((elem, i) => {
                                return (
                                    <MenuItem key={i + 1} name="tax_code" value={elem?.tax_code}>
                                        {elem?.tax_description}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    ) : Numeric ? (
                        <NumericFormat
                            autoComplete="off"
                            allowNegative={false}
                            readOnly={Total ? true : false || (item[labelName]?.iseditable && !InputDisable) ? false : true}
                            decimalScale={2}
                            maxLength={23}
                            displayType="input"
                            thousandSeparator={thousandSeperator}
                            decimalSeparator={decSeperator}
                            // disabled={status == "7" || status == "4" || InputDisable}
                            className={`w-full fieldId-${item?.id} ${
                                (item[labelName]?.label_value === null || item[labelName]?.label_value === "") && item?.ismandatory
                                    ? `border-[red]`
                                    : ``
                            } bg-transparent`}
                            value={item[labelName]?.label_value}
                            name={labelName}
                            onClick={(e) => {
                                e.target?.select();
                                handleOnItemClick(
                                    {
                                        name: labelName,
                                        id: item[labelName]?.id,
                                    },
                                    item[labelName]?.label_value
                                );
                            }}
                            onChange={(e) => _handleChange(e, key, labelName, item[labelName], item)}
                        />
                    ) : Freight ? (
                        <Checkbox
                            size="small"
                            tabIndex={0}
                            className={`w-full fieldId-${item?.id} bg-transparent mr-2`}
                            // readOnly={Total ? true : false || item[labelName]?.iseditable ? false : true}
                            disabled={status == "7" || status == "4"}
                            checked={item[labelName]?.label_value == "true" ? true : false}
                            name={labelName}
                            onChange={(e) => _handleChange(e, key, labelName, item[labelName], item)}
                            sx={{
                                padding: 0,
                                color: "rgba(85,85,85,0.7)",
                                "&.Mui-checked": {
                                    color: "#028C78",
                                },
                            }}
                        />
                    ) : (
                        !General && (
                            <Input
                                readOnly={item[labelName]?.iseditable && !InputDisable ? false : true}
                                // disabled={status == "7" || status == "4" || InputDisable}
                                className={`w-full fieldId-${key} ${
                                    (item[labelName]?.label_value === null || item[labelName]?.label_value === "") && item[labelName]?.ismandatory
                                        ? `border-[red]`
                                        : ``
                                } bg-transparent`}
                                value={item[labelName]?.label_value}
                                name={labelName}
                                onChange={(e) => _handleChange(e, key, labelName, item[labelName], item)}
                                onClick={() => {
                                    handleOnItemClick(
                                        {
                                            name: labelName,
                                            id: item[labelName]?.id,
                                        },
                                        item[labelName]?.label_value
                                    );
                                }}
                            />
                        )
                    )}
                </td>
            );
        });
        // Return a table row with the table cells
        const linecode = mainData?.matching_info?.filter((ele) => ele?.lineItemNo == +key + 1);
        const selectedLineItemcode = onlySelectedLineItem?.filter((el) => el?.lineItemNo == +key + 1);
        const servicePoItemData = props?.servicePoItemData?.filter((el) => el?.lineItemNo == +key + 1) || [];
        const freightCheckArr = isFreightCheck?.filter((el) => el?.item_no == +key + 1);
        return (
            <tr key={key}>
                <td className="sticky  left-0 bg-black z-[1]">{++i}</td>
                {PoItem && location.pathname !== "/proforma-invoice-view" && (
                    <Tooltip
                        title={"Click the PO item to compare the 2 and 3 Way Matching."}
                        disablePortal
                        placement="right"
                        disableInteractive
                        PopperProps={{
                            sx: {
                                "& .MuiTooltip-tooltip": {
                                    fontSize: "0.9em",
                                    maxHeight: "120px",
                                    maxWidth: "250px",
                                    background: "#DAF9F4",
                                    color: "#555555",
                                    borderRadius: "5px",
                                    marginTop: "0 !important",
                                },

                                "& .MuiTooltip-arrow": {
                                    color: "#DAF9F4",
                                },
                            },
                        }}
                        arrow
                    >
                        <td
                            className={`${
                                selectedLineItemcode[0]?.poItemCode !== undefined ||
                                linecode[0]?.poItemCode !== undefined ||
                                servicePoItemData[0]?.poItemCode !== undefined
                                    ? `underline hover:text-[#00B399]`
                                    : ""
                            } ${status == "7" || status == "4" ? "cursor-not-allowed pointer-events-none opacity-50 " : ""} `}
                            onClick={() => {
                                if (
                                    !item["is_freight"] ||
                                    (item["is_freight"]?.item_no == i && item["is_freight"]?.label_value == "false") ||
                                    (item["is_freight"]?.item_no == i && item["is_freight"]?.label_value == null) ||
                                    (item["is_freight"]?.item_no == i && item["is_freight"]?.label_value == "") ||
                                    (item["is_freight"]?.item_no == i && item["is_freight"]?.label_value == undefined)
                                ) {
                                    handleMatchingTable(i);
                                }
                            }}
                        >
                            {props?.areArraysEqual
                                ? freightCheckArr[0]?.label_value !== undefined && freightCheckArr[0]?.label_value == "true"
                                    ? "freight"
                                    : servicePoItemData[0]?.poItemCode !== undefined
                                    ? servicePoItemData[0]?.poItemCode
                                    : // : selectedLineItemcode[0]?.poItemCode !== undefined
                                    // ? selectedLineItemcode[0]?.poItemCode
                                    linecode[0]?.poItemCode !== undefined
                                    ? linecode[0]?.poItemCode
                                    : "-"
                                : freightCheckArr[0]?.label_value !== undefined && freightCheckArr[0]?.label_value == "true"
                                ? "freight"
                                : selectedLineItemcode[0]?.poItemCode !== undefined
                                ? selectedLineItemcode[0]?.poItemCode
                                : "-"}
                        </td>
                    </Tooltip>
                )}
                {tableCells}
                <td className="z-[1] sticky right-0 px-3 border-0 top-[50px]">
                    {showIcon && (
                        <Button
                            disabled={status == "7" || status == "4"}
                            onClick={() => deleteRow(key)}
                            className="bg-light-540  delIco rounded-[50%] justify-center items-center w-[18px] h-[18px]"
                        >
                            <img src="/images/invoice/minus-white.svg" alt="minus-white" />
                        </Button>
                    )}
                </td>
            </tr>
        );
    });
    return (
        <>
            <div
                className={` bg-black w-full xl:w-[70%] 2xl:w-[74.44%] fixed z-[11] wrapTable bottom-[0] top-[inherit] left-[0] text-white border border-${currentMode}-560 shadow-[0px_4px_20px] shadow-${currentMode}-570 rounded-[8px]`}
            >
                <div className={`flex justify-between items-center py-4 px-7 z-[2] sticky top-0  bg-black left-0 right-0`}>
                    <div className="flex space-x-7">
                        <div className="flex space-x-2">
                            {showIcon && (
                                <Button
                                    onClick={() => onSaveLineItems()}
                                    className="bg-light-20 px-[15px] py-[4px] text-white rounded-[5px] flex items-center"
                                >
                                    <img className="mr-2" src="/images/common/tickWhite.svg" alt="tickWhite" /> Confirm
                                </Button>
                            )}
                            <Button onClick={_tbleCancel} className="bg-light-1070 px-[17px] py-[4px] text-white rounded-[5px] flex items-center">
                                <img className="mr-2" src="/images/common/whiteClose.svg" alt="whiteClose" />
                                Cancel
                            </Button>
                            {PoItem && location.pathname !== "/proforma-invoice-view" && (
                                <Button
                                    onClick={() => {
                                        handleMatchingTable(1);
                                    }}
                                    className="bg-light-1070 px-[17px] py-[4px] text-white rounded-[5px] flex items-center"
                                >
                                    <img className="mr-2" src="/images/invoice/threeway_matching.svg" alt="whiteClose" />2 and 3 Way Matching
                                </Button>
                            )}
                            {serviceInvoice && !InputDisable && (
                                <Button
                                    disabled={props?.serviceFillUpdateData !== undefined || props?.fetch}
                                    onClick={() => {
                                        handleUpdateserviceLineItems();
                                    }}
                                    className="bg-light-1070 px-[17px] py-[4px] text-white rounded-[5px] flex items-center"
                                >
                                    <img className="mr-2" src="/images/invoice/fill.svg" alt="whiteClose" />
                                    Fill
                                </Button>
                            )}
                        </div>
                    </div>
                    <strong className="font-interB">
                        Total:{" "}
                        <NumericFormat
                            value={props.totalAmountVal}
                            allowNegative={false}
                            displayType="text"
                            decimalScale={2}
                            thousandSeparator={userNumFormat == "1" ? "," : userNumFormat == "2" ? "." : " "}
                            decimalSeparator={userNumFormat == "1" ? "." : userNumFormat == "2" ? "," : "."}
                        />
                    </strong>
                    {showIcon && (
                        <Button disabled={status == "7" || status == "4"} className="flex items-center" onClick={() => addNewRow()}>
                            <img className="mr-2" src="/images/invoice/plus-green.svg" alt="plus-green" /> Add a Row
                        </Button>
                    )}
                </div>
                <div>
                    <table
                        className={`${Object.keys(Data)?.length == 0 ? "w-full" : "w-max"}  ${
                            Object.keys(Data)?.length > 7 ? `h-[400px]` : ``
                        } tblLineItem blk-tble`}
                    >
                        <thead className={`bg-${currentMode}-1180 sticky top-[61px] z-[2]`}>
                            <tr>
                                <th className={`sticky left-0 z-[1] bg-${currentMode}-1180`}>#</th>
                                {PoItem && location.pathname !== "/proforma-invoice-view" && (
                                    <th className={`bg-${currentMode}-1180`}>PO Item Code</th>
                                )}
                                {tableHeaders}
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object?.keys(Data)?.length === 0 ? <p className="text-white absolute w-full top-[10px]">No items found</p> : Tablerow}
                        </tbody>
                    </table>
                </div>
            </div>
            {ReactDOM.createPortal(
                <LedgerModal
                    handleSelect={handleSelect}
                    isOpened={openLedger}
                    checkIndex={checkIndex}
                    nonPOItemList={nonPOItemList}
                    handleClose={() => setOpenLedger(false)}
                    className={`${openLedger && "Show"}`}
                />,
                document.getElementById("user-modal-root")
            )}
            {ReactDOM.createPortal(
                <AddItemsModal
                    costData={costData}
                    setTotalLedgerVal={setTotalLedgerVal}
                    costCenterList={costCenterList}
                    handleOpenLedger={handleOpenLedger}
                    costListData={props?.costListData}
                    setCostListData={props?.setCostListData}
                    isOpened={addItem}
                    listData={listData}
                    ListItems={ListItems}
                    nonPOItemList={nonPOItemList}
                    nonPoCostListData={nonPoCostListData}
                    handleCostCenter={handleCostCenter}
                    oldData={oldData}
                    handleCloseCostCenter={handleCloseCostCenter}
                    handleClose={() => setAddItem(false)}
                    className={`${addItem && "Show"}`}
                    setCostCenterList={setCostCenterList}
                    setOldData={setOldData}
                />,
                document.getElementById("user-modal-root")
            )}
            {ReactDOM.createPortal(
                <ThreeWayMatchingModal
                    setAreArraysEqual={props?.setAreArraysEqual}
                    areArraysEqual={props?.areArraysEqual}
                    getIndexOfMatching={getIndexOfMatching}
                    setIndexOfMatching={setIndexOfMatching}
                    tblListData={mappedData}
                    isOpened={openMatchingTable}
                    poNumber={poNumber}
                    handleClose={() => setOpenMatchingTable(false)}
                    className={`${openMatchingTable && error == "" && "Showleft"}`}
                    freightCheckArr={freightCheckArr}
                />,
                document.getElementById("user-modal-root")
            )}
        </>
    );
};
