import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "../../../components/Button";
import Modal from "../../../components/UI/Modal/Modal";
import ReactDOM from "react-dom";
import AddUserOrg from "./AddUserOrg";
import { useSelector, useDispatch } from "react-redux";
import { userByOrg, UserRoleDelete } from "../../../redux/actions/user";
import { GETALLUSER_BYORG__RESET, USER_ROLE_DELETE_RESET } from "../../../redux/types/types";
import moment from "moment/moment";
import MessageBox from "../../../components/UI/Alert";
import SuccessMessageBox from "../../../components/UI/Alert/sucess";
import Swal from "sweetalert2";
import Loader from "../../../components/Loader";
import { useContext } from "react";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
const successMsg = "Organisations role deleted successfully";

const UsersView = (props) => {
    const dispatch = useDispatch();
    const { currentMode } = useContext(ThemeContext);
    const [addOrg, setAddOrg] = useState(false);
    const [roleId, setRoleId] = useState("");
    const [userRoleList, setUserRoleList] = useState(props.userRoleList);
    let profileData = useSelector((state) => state?.userListState);
    useEffect(() => {
        if (props?.userId !== "") {
            const filterArr = props?.UsersData?.find((el) => el?.id == props?.userId);
            setUserRoleList(filterArr?.organisationRole_list ? filterArr?.organisationRole_list : props.userRoleList ? props.userRoleList : []);
        }
    }, [props?.userId]);
    const [delRoleList, setDelRoleList] = useState(null);
    const { requestComplete, data } = useSelector((state) => state?.UserRoleDeleteState);
    const result = useSelector((state) => state?.UserRoleDeleteState);
    const reqObj = {
        order_by: 1,
        order_column: "",
        pg_no: 1,
        rec_cnt: 20,
        role_id: 0,
        search_text: "",
        status: 2,
    };

    const handleClose = () => {
        props?.handleClose();
        dispatch({ type: GETALLUSER_BYORG__RESET });
        setTimeout(() => {
            dispatch(userByOrg(reqObj));
        }, 1000);
    };
    const onDeleteRole = (roleId, isallocation, istask) => {
        if (isallocation == "true") {
            Swal.fire({
                text: "Since the invoices are assigned, the user cannot be expelled from this organization.",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else if (istask == true) {
            Swal.fire({
                text: "Since the tasks are still open, the user cannot be expelled from this organization.",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else {
            Swal.fire({
                text: "Are you sure you want to delete this organization for the user?",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                showCancelButton: true,
                buttons: {
                    cancel: "Cancel",
                    confirm: "Confirm",
                },
            }).then((confirm) => {
                if (confirm?.isConfirmed) {
                    const deleteObj = {
                        id: roleId,
                    };
                    dispatch(UserRoleDelete(deleteObj));
                    setRoleId(roleId);
                }
            });
        }
    };
    if (requestComplete) {
        if (data?.status) {
            setTimeout(() => {
                const deletedRoleList = userRoleList.filter((item) => item.identity_id !== roleId);
                setUserRoleList([...deletedRoleList]);
                // dispatch(userByOrg(reqObj));
                dispatch({ type: USER_ROLE_DELETE_RESET });
                setDelRoleList(deletedRoleList);
            }, 2000);
        }
    }
    const handleUpdate = userRoleList?.length !== delRoleList?.length;
    const DisplayRole = useSelector((state) => state?.DisplayRolePermissionStateAction);
    const { data: DataList } = DisplayRole;
    const RoleList = DataList?.role_info;
    const RoleMenu = RoleList?.menu;
    const RoleOrgRole = RoleMenu?.find((elem) => elem?.menu_id === 7);
    const RoleOrgRoleAction = RoleOrgRole?.actions;
    const RoleActOrgRoleDelete = RoleOrgRoleAction?.find((element) => element.id === 12);
    const Content = (props) =>
        profileData?.data?.org_list
            ?.filter((el) => el?.id == props?.id)
            .map((elem) => {
                return (
                    <>
                        <div className="flex">
                            <div className="relative flex-auto">
                                <img
                                    src={elem?.logo ? `data:image;base64,${elem?.logo}` : "/images/common/orgLogo.png"}
                                    className="w-[37px] h-[37px] object-contain"
                                    alt="org-1"
                                />
                                {!elem?.organisation_isactive ? (
                                    <img
                                        className="absolute right-[-.3rem] bottom-[-.3rem]"
                                        src="/images/common/org-inactive.svg"
                                        alt="org-inactive"
                                    />
                                ) : null}
                            </div>
                            <div className="ml-4 flex-inherit">
                                <span className="block truncate w-[200px]" title={elem?.organisation_name}>
                                    {elem?.organisation_name}
                                </span>
                                <p className="block text-xs truncate w-[200px]">{elem?.url}</p>
                            </div>
                        </div>
                    </>
                );
            });
    return (
        <>
            {requestComplete && data?.status && <SuccessMessageBox success={successMsg} />}
            {requestComplete && !data?.status && <MessageBox error={data?.message} />}

            <Modal cartClose={props?.handleClose} className={`${props?.className}`}>
                <span
                    onClick={!handleUpdate ? handleClose : props?.handleClose}
                    className="p-[6.5px] absolute right-[680px] cursor-pointer bg-light-10"
                    title="Close">
                    <img src="/images/common/whiteClose.svg" alt="whiteClose" />
                </span>
                <div
                    className={`bg-${currentMode}-card text-${currentMode}-copy ml-auto border-${currentMode}-700 overflow-y-auto max-w-[680px] h-lvh flex-col justify-between flex max-h-[-webkit-fill-available]`}>
                    <div className={`px-[30px]  p-[24px] border-b-[1px] border-${currentMode}-590 relative`}>
                        <h4 className="font-interSb text-lg">Organisations & Roles</h4>
                    </div>
                    <div>
                        <div className="h-[calc(100vh_-_164px)] overflow-y-auto">
                            <table className={`table-auto w-full roleTable relative}`}>
                                <thead className={`bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700 text-left font-interM `}>
                                    <tr>
                                        <th className="xl:px-[auto]">Organization</th>
                                        <th className="xl:px-[auto]">Role</th>
                                        <th className="xl:px-[auto]">Date of Add</th>
                                        <th
                                            className={`xl:px-[auto] ${
                                                RoleActOrgRoleDelete?.isallowed == false && "opacity-0 pointer-events-none "
                                            }`}>
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(requestComplete && data.status) || profileData?.fetching ? (
                                        <Loader className="absolute h-[calc(100vh_-_15rem)]" />
                                    ) : (
                                        <>
                                            {userRoleList &&
                                                userRoleList?.map((elem) => {
                                                    return (
                                                        <tr key={elem?.identity_id}>
                                                            <td className={`border-${currentMode}-590 xl:px-[auto]`}>
                                                                <Content id={elem?.organisation_id} />
                                                            </td>
                                                            <td className={`border-${currentMode}-590  xl:px-[auto]`}>{elem?.role_name}</td>
                                                            <td className={`border-${currentMode}-590  xl:px-[auto]`}>{elem?.created_date}</td>

                                                            <td className={`border-${currentMode}-590  xl:px-[auto]`}>
                                                                {RoleActOrgRoleDelete?.isallowed == true && (
                                                                    <Button
                                                                        onClick={() =>
                                                                            onDeleteRole(elem?.identity_id, elem?.isallocation, elem?.istask)
                                                                        }>
                                                                        <img
                                                                            src="/images/common/trash-ico.svg"
                                                                            className="convertWhite"
                                                                            alt="trash-ico"
                                                                        />
                                                                    </Button>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                        </>
                                    )}
                                </tbody>
                            </table>
                            {!userRoleList || userRoleList?.length == 0 ? (
                                <div className="px-[30px] h-full grid place-items-center place-content-center">
                                    {" "}
                                    <img className="mb-3" src="/images/common/delivery.png" alt="searching" />
                                    <h4 className="font-interSb block">No Data found</h4>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="flex border-t-[1px] border-[rgba(223,225,226,0.56)] justify-between items-center px-[30px] py-[23px]">
                            <Button
                                type="button"
                                title="Cancel"
                                onClick={!handleUpdate ? handleClose : props?.handleClose}
                                className="bg-light-240 flex text-base font-interR py-[.5rem] px-[1.7rem] rounded-[8px] text-light-40 closeModal">
                                Close
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default UsersView;
