import { async } from "@firebase/util";
import { Checkbox, FormControlLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import Input from "../../components/Input";
import Loader from "../../components/Loader";
import Card from "../../components/UI/Card";
import Main from "../../components/UI/Main";
import Layout from "../../Hoc/Layout";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import Footer from "../../organisms/Footer";
import ReactDOM from "react-dom";
import { addReportsFavList, getReportsFavList } from "../../redux/actions/report";
import { ADD_REPORTS_FAV_RESET, CUSTOM_REPORTS_LIST_RESET, GET_CUSTOM_REPORTS_LABEL_RESET } from "../../redux/types/types";
import CreateReport from "./OutputManagement/CreateReports";
import { getCurrentUser } from "../../redux/actions/auth";

const ReportsHome = () => {
    let User = localStorage.getItem("user");
    let isLicenceUser = JSON.parse(User)?.license_type;
    const { currentMode } = useContext(ThemeContext);
    const OrgId = localStorage.getItem("orgId");
    const dispatch = useDispatch();
    const [values, setValues] = useState("");
    const [openCreateReport, setOpenCreateReport] = useState(false);
    const [searchShow, setSearchShow] = useState(false);
    const [searchField, setSearchField] = useState("");
    const checkChange = (e, id) => {
        const { checked } = e.target;
        setValues({
            ...values,
            isChecked: checked,
        });
        const reqObj = {
            menu: JSON.stringify([{ fav_id: id, favourites: checked }]),
        };
        dispatch(addReportsFavList(reqObj));
        setTimeout(() => {
            dispatch(getReportsFavList(OrgId));
            dispatch({ type: ADD_REPORTS_FAV_RESET });
        }, 500);
    };

    const { dataList, requestComplete } = useSelector((state) => state?.GetReportFavListState);

    let [CategoryMap] = dataList?.map((el) => el);

    const FavFilter = CategoryMap?.menu_id?.filter((el) => el?.favourites === true);
    const resetInputField = () => {
        setSearchField("");
        setSearchShow(false);
    };
    useEffect(() => {
        dispatch(getReportsFavList(OrgId));
        dispatch({ type: CUSTOM_REPORTS_LIST_RESET });
        dispatch({ type: GET_CUSTOM_REPORTS_LABEL_RESET });
    }, [dispatch]);

    // client search functions //
    const _handleChange = (e) => {
        setSearchField(e.target.value);
        if (e.target.value == "") {
            setSearchShow(false);
        } else {
            setSearchShow(true);
        }
    };
    const filteredFav = CategoryMap?.menu_id?.filter((fav) => {
        return fav.menu_name.toLowerCase().includes(searchField.toLowerCase());
    });

    // client search filtered content //
    const OpenReport = () => {
        setOpenCreateReport(true);
    };
    const FilteredContent =
        filteredFav?.length !== 0 ? (
            filteredFav?.map((el) => {
                return (
                    <li key={el?.fav_id} className={`flex border-${currentMode}-700 items-center border-b-[1px] pb-1`} id={el?.fav_id}>
                        <div className="flex-inherit">
                            <Checkbox
                                checked={el?.favourites}
                                icon={<img src="/images/main/star.svg" />}
                                checkedIcon={<img src="/images/main/star-active.svg" />}
                                onChange={(e) => checkChange(e, el?.fav_id)}
                            />
                        </div>
                        <Link
                            className="truncate flex-1"
                            to={
                                el?.menu_id === 13
                                    ? `/reports/reports-accrual`
                                    : el?.menu_id === 14
                                        ? `/reports/reports-detail`
                                        : el?.menu_id === 18
                                            ? `/reports/reports-task`
                                            : el?.menu_id === 15
                                                ? `/reports/reports-ageing`
                                                : el?.menu_id === 24 ? "advance-reports"
                                                    : el?.menu_id === 25 ? "miscellaneous-reports"
                                                        : el?.menu_id === 26 ? "retention-reports"
                                                            : el?.menu_id !== 13 ||
                                                                el?.menu_id !== 14 ||
                                                                el?.menu_id !== 15 ||
                                                                el?.menu_id !== 18 ||
                                                                el?.menu_id !== 19 ||
                                                                el?.menu_id !== 24 ||
                                                                el?.menu_id !== 25 ||
                                                                el?.menu_id !== 26
                                                                ? `/reports/custom-reports-list?id=${el?.menu_id}&&name=${el?.menu_name}&&report=${el?.output_type}&&chart=${el?.chart_type}`
                                                                : ""
                            }>
                            {el?.menu_name}
                        </Link>
                    </li>
                );
            })
        ) : (
            <div className="text-center">
                <div className="mx-auto my-5">
                    <img className="mx-auto" src="/images/common/delivery.png" alt="delivery" />
                </div>
                <h4 className="font-interSb">No search results found</h4>
            </div>
        );

    // favourite filter map

    const FavContent =
        FavFilter?.length !== 0
            ? FavFilter?.map((el) => {
                return (
                    <li key={el?.fav_id} className={`flex border-${currentMode}-700 items-center border-b-[1px] pb-1`} id={el?.fav_id}>
                        <div className="flex-inherit">
                            <Checkbox
                                checked={el?.favourites}
                                icon={<img src="/images/main/star.svg" />}
                                checkedIcon={<img src="/images/main/star-active.svg" />}
                                onChange={(e) => checkChange(e, el?.fav_id)}
                            />
                        </div>
                        <Link
                            className="truncate flex-1"
                            to={
                                el?.menu_id === 13
                                    ? `/reports/reports-accrual`
                                    : el?.menu_id === 14
                                        ? `/reports/reports-detail`
                                        : el?.menu_id === 18
                                            ? `/reports/reports-task`
                                            : el?.menu_id === 15
                                                ? `/reports/reports-ageing`
                                                : el?.menu_id === 24 ? "advance-reports"
                                                    : el?.menu_id === 25 ? "miscellaneous-reports"
                                                        : el?.menu_id === 26 ? "retention-reports"
                                                            : el?.menu_id !== 13 ||
                                                                el?.menu_id !== 14 ||
                                                                el?.menu_id !== 15 ||
                                                                el?.menu_id !== 18 ||
                                                                el?.menu_id !== 19 ||
                                                                el?.menu_id !== 24 ||
                                                                el?.menu_id !== 25 ||
                                                                el?.menu_id !== 26
                                                                ? `/reports/custom-reports-list?id=${el?.menu_id}&&name=${el?.menu_name}&&report=${el?.output_type}&&chart=${el?.chart_type}`
                                                                : ""
                            }>
                            {el?.menu_name}
                        </Link>
                    </li>
                );
            })
            : "No favorites here";
    // default show menus
    const NormalContent =
        CategoryMap?.menu_id?.length !== 0 && CategoryMap !== undefined
            ? CategoryMap?.menu_id?.map((el) => {
                return (
                    <>
                        <li key={el?.fav_id} className={`flex border-${currentMode}-700 items-center border-b-[1px] pb-1`} id={el?.fav_id}>
                            <div className="flex-inherit">
                                <Checkbox
                                    checked={el?.favourites}
                                    icon={<img src="/images/main/star.svg" />}
                                    checkedIcon={<img src="/images/main/star-active.svg" />}
                                    onChange={(e) => checkChange(e, el?.fav_id)}
                                />
                            </div>
                            <Link
                                className="truncate flex-1"
                                to={
                                    el?.menu_id === 13
                                        ? `/reports/reports-accrual`
                                        : el?.menu_id === 14
                                            ? `/reports/reports-detail`
                                            : el?.menu_id === 18
                                                ? `/reports/reports-task`
                                                : el?.menu_id === 15
                                                    ? `/reports/reports-ageing`
                                                    : el?.menu_id === 24 ? "advance-reports"
                                                        : el?.menu_id === 25 ? "miscellaneous-reports"
                                                            : el?.menu_id === 26 ? "retention-reports"
                                                                : el?.menu_id !== 13 ||
                                                                    el?.menu_id !== 14 ||
                                                                    el?.menu_id !== 15 ||
                                                                    el?.menu_id !== 18 ||
                                                                    el?.menu_id !== 19 ||
                                                                    el?.menu_id !== 24 ||
                                                                    el?.menu_id !== 25 ||
                                                                    el?.menu_id !== 26
                                                                    ? `/reports/custom-reports-list?id=${el?.menu_id}&&name=${el?.menu_name}&&report=${el?.output_type}&&chart=${el?.chart_type}`
                                                                    : ""
                                }>
                                {el?.menu_name}
                            </Link>

                        </li>
                        {/* <li
                    className={`flex border-${currentMode}-700 items-center border-b-[1px] pb-1`}
                >
                    <Checkbox
                        icon={<img src="/images/main/star.svg" />}
                        checkedIcon={<img src="/images/main/star-active.svg" />}
                    />
                    <Link
                        to={
                            "/reports/custom-reports-list"
                        }
                    >
                        {"Custom Report"}
                    </Link>

                </li> */}
                    </>
                );
            })
            : "No Overview found";
    const UserDetail = getCurrentUser();

    return (
        <>
            <Layout>
                <Main>
                    <div>
                        <h4 className={`text-2xl font-interSb mb-6 text-${currentMode}-copy`}>Reports</h4>
                    </div>
                    <Card className={`p-[30px] xl:h-[calc(100vh_-_13rem)] h-[calc(100vh_-_18rem)] overflow-y-auto relative`}>
                        {!requestComplete ? <Loader className={`h-[calc(100vh_-_9.5rem)] absolute bg-${currentMode}-card left-0 z-[1]`} /> : ``}
                        <div className="mb-[50px]">
                            <div
                                className={`relative flex justify-between border-[1px] w-full border-${currentMode}-840 py-2 px-[20px] rounded-[8px]`}>
                                <Input placeholder="Search" value={searchField} onChange={_handleChange} className={`w-full bg-transparent`} />
                                {searchField && searchField.length !== 0 ? (
                                    <img onClick={resetInputField} src="/images/common/closeBlk.svg" alt="close" />
                                ) : (
                                    <img src="/images/header/search.svg" alt="search" />
                                )}
                            </div>
                        </div>
                        {searchShow ? (
                            <div>
                                <ul className="space-y-1">{FilteredContent}</ul>
                            </div>
                        ) : (
                            <>
                                <div className="flex mb-10">
                                    <div className="xl:basis-[20%]">
                                        <span className="flex mb-[15px] font-interSb">
                                            <img
                                                className="mr-2"
                                                src={currentMode === "dark" ? "/images/common/bookmark.svg" : `/images/main/bookmark.svg`}
                                                alt="bookmark"
                                            />
                                            My Favorites
                                        </span>
                                        <ul className="space-y-1">{FavContent}</ul>
                                    </div>
                                </div>
                                <div className="grid xl:grid-cols-4 grid-cols-2 gap-[6rem]">
                                    <div>
                                        <span className="flex mb-[15px] font-interSb">
                                            <img
                                                className="mr-2 "
                                                src={currentMode === "dark" ? "/images/common/bisiness2.svg" : `/images/main/office.svg`}
                                                alt="bookmark"
                                            />
                                            Business Overview
                                        </span>
                                        <ul className="space-y-1">{NormalContent}</ul>
                                    </div>
                                    {UserDetail?.license_type !== 2 && (
                                        <div>
                                            <span className="flex mb-[15px]">
                                                {""}
                                                <br></br>
                                            </span>
                                            <ul className="space-y-1 underline cursor-pointer">
                                                <li className="mb-[20px]" onClick={OpenReport}>
                                                    Click here to create reports
                                                </li>
                                                <li>
                                                    <Link to={"/reports/reports-summary-list"} className="underline">
                                                        Click here to view reports summary
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </Card>
                </Main>
                <Footer />
            </Layout>
            {ReactDOM.createPortal(
                <CreateReport
                    isOpened={openCreateReport}
                    handleClose={() => setOpenCreateReport(false)}
                    className={openCreateReport && "Show"}
                    handleOpen={() => setOpenCreateReport(true)}
                />,
                document.getElementById("user-modal-root")
            )}
        </>
    );
};

export default ReportsHome;
