import Input from "../../components/Input";
import Loader from "../../components/Loader";
import Swal from "sweetalert2";
import Button from "../../components/Button";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { folderconfigAdd, folderConfigStatusMode, getFolderConfig } from "../../redux/actions/folderconfig";
import UseForm from "../../Hooks/useForm";
import { validateFolderConfig } from "../../Hooks/validate";
let isEnabled = null;
const SharedConfig = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isAction, setIsAction] = useState(false);
    const { handleChange, handleSubmit, values, errors } = UseForm(submit, validateFolderConfig);
    //LIST
    const { configData } = useSelector((state) => state?.folderConfigState);
    const dispatch = useDispatch();
    //ADD
    let responseMail = useSelector((state) => state?.folderAddConfigState);
    if (responseMail?.fetching) {
        Swal.fire({
            text: "Activation Link has been sent to your Email,Please authenticate to activate the configure",
            icon: "success",
            closeOnClickOutside: false,
            button: "Ok",
        }).then((confirm) => {
            if (confirm) {
                props.setIsClose(true);
                props.handleAddFolder(false);
                window.location.reload();
                responseMail.fetching = false;
            }
        });
    }
    function submit() {
        const isExist = configData.find((item) => {
            return item.username === values?.emailaddress;
        });
        if (isExist == undefined) {
            dispatch(
                folderconfigAdd({
                    foldertype: isDrive,
                    emailaddress: values?.emailaddress,
                    locationurl: values?.locationurl,
                })
            );

            if (Object.keys(errors).length !== 0) {
                props.setIsClose(false);
            }
        } else {
            Swal.fire({
                text: "Email already exists! ",
                closeOnClickOutside: false,
                icon: "warning",
                button: "Ok",
            });
        }
    }

    //SHARED FOLDER CONFIG
    const [isDrive, setIsDrive] = useState(2);
    const onChangeDrive = (option) => {
        setIsDrive(option);
    };
    //ACTIVE-IN-ACTIVE
    const response = useSelector((state) => state?.folderConfigStatusState);
    const handleActivateClick = (value, id) => {
        isEnabled = value;
        dispatch(
            folderConfigStatusMode({
                id: id,
                isactive: value,
            })
        );
    };
    if (response.requestComplete && response.data?.status) {
        response.requestComplete = false;
        Swal.fire("Are you sure you want to change the status?", {
            closeOnClickOutside: false,
            buttons: {
                cancel: true,
                confirm: true,
            },
        }).then((confirm) => {
            if (confirm) {
                Swal.fire({
                    text: "The status has been updated successfully.",
                    closeOnClickOutside: false,
                    icon: "success",
                    button: "Ok",
                }).then((confirm) => {
                    if (confirm) {
                        dispatch(getFolderConfig());
                    }
                });
            }
        });
    }
    useEffect(() => {
        dispatch(getFolderConfig());
    }, [dispatch, isDrive, response.requestComplete, isAction]);
    return (
        <>
            <div className="basis-3/4">
                {isLoading ? (
                    <div>
                        <Loader className="h-[calc(100vh_-_15rem)]">
                            <div className="text-center max-w-[430px]">
                                <h5 className="font-interM mb-2 text-2xl font-sm">Custom email connection successful!</h5>
                                <p>A email has been sent to your admin. Click the link and add key then your account will activate.</p>
                            </div>
                        </Loader>
                    </div>
                ) : isAction ? (
                    <div className="text-center relative flex flex-col justify-center items-center h-full">
                        <Button className="absolute top-[10px] right-[10px]" onClick={() => setIsAction(false)}>
                            <img src="/images/common/iconblkclose.svg" alt="iconblkclose" />
                        </Button>
                        <h4 className="text-3xl text-light-20 mb-5">Congratulations Jessica</h4>
                        <p className="text-base">Your email is {isEnabled ? "Activated" : "Deactivated"}</p>
                    </div>
                ) : (
                    <>
                        <>
                            <span className="text-sm font-interM mb-5 block">Create a customer email account</span>
                            <form onSubmit={handleSubmit}>
                                <div className="flex space-x-4 mb-7">
                                    {/* <Button type="button" onClick={() => onChangeDrive(1)} className={`${"border620 px-5 py-2 rounded-[8px] font-interSb text-sm flex items-center border-[1px] text-light-40"} ${isDrive == 1 ? "bg-light-390" : ''}`}>
                                        <img
                                            className="mr-2"
                                            src="/images/common/googledrive.svg"
                                            alt="plus"
                                        />
                                        GoogleDrive
                                    </Button> */}
                                    <Button
                                        type="button"
                                        onClick={() => onChangeDrive(2)}
                                        className={`${"border620 px-5 py-2 rounded-[8px] font-interSb text-sm flex items-center border-[1px] text-light-40"} ${
                                            isDrive == 2 ? "bg-light-390" : ""
                                        }`}>
                                        <img className="mr-2" src="/images/common/onedrive.svg" alt="plus" /> OneDrive
                                    </Button>
                                </div>
                                {props.isAddFolder && props.isClose && (
                                    <div className="max-w-[500px] ">
                                        <div className="form-group mb-5 ">
                                            <Input
                                                placeholder="jessica@gmail.com"
                                                value={values.emailaddress}
                                                onChange={handleChange}
                                                name="emailaddress"
                                                className="w-full py-3 rounded-[8px] px-5  border-[1px] border620"
                                            />
                                            {errors && <p className="text-light-50 text-xs">{errors?.emailaddress}</p>}
                                        </div>
                                        <div className="form-group mb-5 ">
                                            <Input
                                                value={values.locationurl}
                                                onChange={handleChange}
                                                name="locationurl"
                                                placeholder="folder name"
                                                className="w-full py-3 rounded-[8px] px-5  border-[1px] border620"
                                            />
                                            {errors && <p className="text-light-50 text-xs">{errors?.locationurl}</p>}
                                        </div>
                                        <div className="flex justify-end space-x-5 mb-7">
                                            <Button
                                                onClick={() => props.handleAddFolder(false)}
                                                className="bg-light-240 flex text-base font-interR py-[.5rem] px-[2.24rem] rounded-[8px] text-light-40">
                                                Cancel
                                            </Button>
                                            <Button
                                                type="submit"
                                                className="border-20 bg-light-20 px-5 py-2 rounded-[8px] font-interM text-sm flex items-center border-[1px] text-white">
                                                {"Save"}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </form>
                        </>

                        <div className="flex flex-col max-w-[800px] space-y-5">
                            {configData?.map((elem) => {
                                return (
                                    <div className="rounded-[10px] flex justify-between items-center pl-7 pr-4 py-4 border-[rgba(223,225,226,0.56)] border-[1px] bg-white shadow-[0px_4px_20px_rgba(238,238,238,0.5)]">
                                        <div key={elem?.id}>
                                            <span className="font-interM flex mb-2">
                                                <img
                                                    className="mr-2"
                                                    src={
                                                        elem?.foldertype?.id === 1 ? `/images/common/googledrive.svg` : `/images/common/onedrive.svg`
                                                    }
                                                    alt="googleIcon"
                                                />
                                                {elem?.username}
                                            </span>
                                            <span className="flex text620 mb-2">
                                                ...//{elem?.locationurl}
                                                {/* <img className="ml-2 cursor-pointer" src="/images/common/pencilEdit.svg" alt="pencilEdit" /> */}
                                            </span>
                                            <small className="block text-sm text620">{/* Last email read an hour ago. */}</small>
                                        </div>
                                        <div className="flex space-x-3">
                                            <Button
                                                title="Enable"
                                                type="button"
                                                onClick={() => handleActivateClick(true, elem?.id)}
                                                disabled={!elem.isactive ? false : true}
                                                className={`${" border-[1px]  font-interM border-400 text-sm rounded-[5px] px-[6.4px] py-[4px]"} ${
                                                    elem.isactive === true ? "opacity-100 text-light-40 bg-light-400" : ""
                                                }`}>
                                                Enable
                                            </Button>
                                            <Button
                                                title="Disable"
                                                type="button"
                                                onClick={() => handleActivateClick(false, elem?.id)}
                                                disabled={!elem.isactive ? true : false}
                                                className={`${"text-sm border-400 text-sm font-interM border-[1px] rounded-[5px] px-[6.4px] py-[4px]"} ${
                                                    elem.isactive === false ? "opacity-100 text-light-40 bg-light-400" : ""
                                                }`}>
                                                Disable
                                            </Button>
                                        </div>
                                    </div>
                                );
                            })}
                            {/* <div className="rounded-[10px] flex justify-between items-center pl-7 pr-4 py-4 border-[rgba(223,225,226,0.56)] border-[1px] bg-white shadow-[0px_4px_20px_rgba(238,238,238,0.5)]">
                <div>
                    <span className="font-interM flex mb-2">
                        <img
                            className="mr-2"
                            src="/images/common/onedrive.svg"
                            alt="googleIcon"
                        />
                        endrikrovan.accounts@gmail.com
                    </span>
                    <span className="flex text620 mb-2">...// iQ.ai_invoice process <img className="ml-2 cursor-pointer" src="/images/common/pencilEdit.svg" alt="pencilEdit" /></span>
                    <small className="block text-sm text620">
                        Last email read an hour ago.
                    </small>
                </div>
                <div className="flex space-x-3">
                    <Button className="bg-light-400 opacity-50 border-[1px]  font-interM border-400 text-sm rounded-[5px] px-[6.4px] py-[4px]">
                        Enable
                    </Button>
                    <Button className="text-sm border-400 text-sm font-interM border-[1px] rounded-[5px] bg-light-400 px-[6.4px] py-[4px]">
                        Disable
                    </Button>
                </div>
            </div> */}
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default SharedConfig;
