import { useState } from "react";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Card from "../../components/UI/Card";

export const PreviewRender = ({ config, currentMode }) => {
    const [formData, setFormData] = useState({});

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const renderField = (field) => {
        switch (field.type) {
            case "text":
            case "email":
                return (
                    <div className="flex justify-between items-center" key={field?.title}>
                        <label>
                            {field?.mandatory && <span className="text-[red] mr-1">*</span>} {field?.title}
                        </label>
                        <Input
                            className={`border w-full max-w-[300px] border-${currentMode}-840 rounded-[8px] py-[8px] px-[10px]`}
                            type={field?.type}
                            value={field?.initialValue}
                            name={field?.type}
                            onChange={handleChange}
                        />
                    </div>
                );
            case "textarea":
                return (
                    <div key={field.title}>
                        <label>{field.title}</label>
                        <textarea name={field.title} onChange={handleChange} />
                    </div>
                );
            case "select":
                return (
                    <div key={field.name}>
                        <label>{field.label}</label>
                        <select name={field.name} onChange={handleChange}>
                            {field.options.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>
                );
            case "address":
                return (
                    <>
                        <div className="flex justify-between items-center" key={field?.title}>
                            <label>
                                {field?.mandatory && <span className="text-[red] mr-1">*</span>} {`Address 1`}
                            </label>
                            <Input
                                className={`border w-full max-w-[300px] border-${currentMode}-840 rounded-[8px] py-[8px] px-[10px]`}
                                type={field?.type}
                                value={field?.address1}
                                name={field?.type}
                                onChange={handleChange}
                            />
                            {/* <Input
                            className={`border w-full max-w-[300px] border-${currentMode}-840 rounded-[8px] py-[8px] px-[10px]`}
                            type={field?.type}
                            value={field?.address1}
                            name={field?.type}
                            onChange={handleChange}
                        /> */}
                        </div>
                        <div className="flex justify-between items-center">
                            <label>
                                {field?.mandatory && <span className="text-[red] mr-1">*</span>} {`Address 2`}
                            </label>
                            <Input
                                className={`border w-full max-w-[300px] border-${currentMode}-840 rounded-[8px] py-[8px] px-[10px]`}
                                type={field?.type}
                                value={field?.address2}
                                name={field?.type}
                                onChange={handleChange}
                            />
                            {/* <Input
                        className={`border w-full max-w-[300px] border-${currentMode}-840 rounded-[8px] py-[8px] px-[10px]`}
                        type={field?.type}
                        value={field?.address1}
                        name={field?.type}
                        onChange={handleChange}
                    /> */}
                        </div>
                    </>
                );
            default:
                return null;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Form Data:", formData);
    };

    return (
        <>
            <div
                className={`bg-${currentMode}-card mb-[50px] flex justify-between itemse-center py-[25px] px-[30px] shadow-[0px_4px_15px_0px_rgba(0,0,0,0.06)]`}>
                <div>
                    <span className={`border border-${currentMode}-840 inline-block rounded-[5px] px-[10px] py-[11px]`}>kjhjkhkjhkjhjhkjhkjhkjh</span>
                </div>
                <Button className={`bg-light-20 text-white px-4 rounded-[5px] py-[10px]`}> Close Preview</Button>
            </div>
            <Card className={`max-w-[840px] mx-auto px-[50px] py-[30px]`}>
                <form onSubmit={handleSubmit}>
                    <div className="space-y-[20px]">{config?.map((field) => renderField(field))}</div>
                    <hr className={`my-[50px] bg-${currentMode}-1240`} />
                    <button type="submit">Submit</button>
                </form>
            </Card>
        </>
    );
};
