import {
    Checkbox,
    ClickAwayListener,
    Fade,
    FormControl,
    FormControlLabel,
    Input,
    MenuItem,
    Paper,
    Popper,
    Select,
    TextField,
    Autocomplete,
    Chip,
    CircularProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Modal from "../../../components/UI/Modal/Modal";
import { Link } from "react-router-dom";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { GetMicrosoftContactList } from "../../../redux/actions/contacts.js";
import Button from "../../../components/Button";
import ReactQuill from "react-quill";
import { getEmailTemplateList, getEmailTemplateListCreation } from "../../../redux/actions/emailtask";
import { getPriorityConfigList } from "../../../redux/actions/my-task";
import { set } from "lodash";
import Loader from "../../../components/Loader";
import { CKEditor } from "ckeditor4-react";
import SunEditor, { buttonList } from "suneditor-react";
import plugins from "suneditor/src/plugins";
import "suneditor/dist/css/suneditor.min.css";
import { getInvoiceItems } from "../../../redux/actions/invoice";
import Swal from "sweetalert2";
import { encryptId, decryptId, fileSizeMB } from "../../../utils/helpers";
import moment from "moment";
import { ADD_UPDATE_EMAIL_TEMPLATE_RESET } from "../../../redux/types/types";
import MessageBox from "../../../components/UI/Alert";
import SuccessMessageBox from "../../../components/UI/Alert/sucess";
import { emailTemplateAddUpdate } from "../../../redux/actions/emailtask";
import { addToDoTask } from "../../../redux/actions/my-task";
import { Add_INVOICE_TODO_CREATE_TASK_RESET } from "../../../redux/types/types";
import { useContext } from "react";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import { fileSize } from "../../../utils/helpers";
import { fileGroup } from "../../../utils/helpers";
import { getFilesSizeInMB } from "../../../utils/helpers";
import { useRef } from "react";

let uploadedFiles = [];

let fileStatusList = [];
const CreateEmailTask = (props) => {
    const { currentMode } = useContext(ThemeContext);
    const [selected, setSelected] = useState("");
    const [template, setTemplate] = useState("");
    const [subject, setSubject] = useState("");
    const [content, setContent] = useState("");
    const [forEmail, setForEmail] = useState([]);
    const [ccEmail, setCcEmail] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [forInput, setForInput] = useState("");
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorCc, setErrorCc] = useState(false);
    const currentDate = new Date();
    const [dueDate, setDueDate] = useState(currentDate);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [fileList, setFileStatusList] = useState([]);
    const [bccEmail, setBccEmail] = useState([]);
    const [bccInput, setBccInput] = useState("");
    const [errorBcc, setErrorBcc] = useState(false);
    const [torender, setRender] = useState(false);
    const dispatch = useDispatch();
    var emailReplacer = [];
    var replacer = {};
    let options = null;
    const editorRef = useRef();

    var bccMail = "";
    var templateId = null;
    const getUserId = JSON.parse(localStorage.getItem("user"));
    const data = useSelector((state) => state?.getEmailTemplateListState);
    const listData = data?.data?.datalist;
    const getConfig = useSelector((state) => state?.getPriorityConfigState);
    const priorityListData = getConfig?.dataList;
    const invoiceList = useSelector((state) => state?.getInvoiceItemsState);
    const invoiceItems = invoiceList?.data?.datalist?.[0].invoice_items || [];
    const Listdata = useSelector((state) => state?.GetMicrosoftContactListReduState);
    const msContactListData = Listdata?.Listdata?.data?.value?.map((el) => el?.emailAddresses);
    const filterArr = msContactListData?.map((el) => [...el]).flat();
    const overallOptions = filterArr?.map((el) => el?.address);
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const { fetching, requestComplete, error, data: postData } = useSelector((state) => state?.addMyTaskCreateToDoState);
    const succesMsg = "Task is created successfully";
    const errormsg = "Something went wrong please contact admin";
    const supportedFileFormats = [".jpg", ".jpeg", ".png", ".gif"];
    useEffect(() => {
        uploadedFiles = [];
        if (data?.requestComplete == false && props?.isOpened) {
            dispatch(getEmailTemplateListCreation());
        }
        if (props?.isOpened && overallOptions == undefined) {
            dispatch(GetMicrosoftContactList());
        }
        if (getConfig?.requestComplete == false && props?.isOpened) {
            dispatch(getPriorityConfigList());
        } else {
            const defaultVal = priorityListData?.find((el) => el?.isdefault == true);
            const parseVal = defaultVal == undefined ? priorityListData[0] : defaultVal;
            setSelected(parseVal?.task_priority);
            const defaultdate = addDays(new Date(), parseVal?.due_days);
            setDueDate(defaultdate);
        }
        if (props?.isOpened == false) {
            setTemplate("");
            setSubject("");
            setContent("");
            setForInput("");
            setInputValue("");
            setCcEmail([]);
            setForEmail([]);
            setBccEmail([]);
            setBccInput("");
            setSelectedFiles([]);
            fileStatusList = [];
        } else {
            const reqObj = {
                id: [parseInt(props?.invoiceId)],
                organisation: 0,
                checkViewInv: false,
            };
            dispatch(getInvoiceItems(reqObj));
        }
    }, [props?.isOpened]);

    const config = {
        height: 400,
        // Other CKEditor configuration options...
        removeButtons: "Image",
        // extraPlugins:
        //     "dialogui,dialog, a11yhelp,about,basicstyles,blockquote,notification, button,toolbar,clipboard,panel,floatpanel,menu,contextmenu,elementspath,indent,indentlist,list,enterkey,entities,popup,filetools,filebrowser,floatingspace,listblock,richcombo,format,horizontalrule,htmlwriter,fakeobjects,link,magicline,maximize,xml,ajax,pastetools,pastefromgdocs,pastefromlibreoffice, pastefromword,pastetext,removeformat,resize,menubutton,scayt,showborders,sourcearea,specialchar,stylescombo,tab,table,tabletools,tableselection, undo,ineutils,widgetselection,widget,notificationaggregator,uploadwidget,wysiwygarea",
    };
    useEffect(() => {
        const defaultVal = priorityListData?.find((el) => el?.isdefault == true);
        const parseVal = defaultVal == undefined ? priorityListData?.[0] : defaultVal;
        setSelected(parseVal?.task_priority);
        const defaultdate = addDays(new Date(), parseVal?.due_days);
        setDueDate(defaultdate);
    }, [getConfig]);
    useEffect(() => {
        if (template != "") {
            const tempFind = listData?.find((item) => item?.template_name == template);
            // if (subject?.length == 0 && (content?.length == 0 || content == "<p><br></p>") && ccEmail?.length == 0) {
            const getCc = tempFind?.email_cc == null ? "" : decryptId(tempFind?.email_cc)?.split(";");
            const getBcc = tempFind?.email_bcc == null ? "" : decryptId(tempFind?.email_bcc)?.split(";");
            setCcEmail(getCc == "" ? [] : getCc);
            setBccEmail(getBcc == "" ? [] : getBcc);

            const correctedSubject = autoCorrect(tempFind?.subject, replacer);
            setSubject(correctedSubject);

            const correctedText = autoCorrect(tempFind?.email_message, replacer);
            setContent(correctedText == undefined ? "" : correctedText);
            editorRef.current.editor.setData(correctedText == undefined ? "" : correctedText);
            // }
        }
    }, [template]);

    const changeConfirmation = (templateFind) => {
        Swal.fire({
            text: `Are you sure you want to change the template?`,
            icon: "warning",
            closeOnClickOutside: false,
            showCloseButton: true,
            showCancelButton: true,
            buttons: {
                cancel: "Cancel",
                confirm: "Confirm",
            },
        }).then((confirm) => {
            if (confirm?.isConfirmed) {
                setTemplate(templateFind);
            }
        });
    };

    function autoCorrect(text, correction) {
        const reg = new RegExp(Object.keys(correction).join("|"), "g");
        return text?.replace(reg, (matched) => correction[matched]);
    }

    function addDays(theDate, days) {
        return new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000);
    }

    if (data?.requestComplete) {
        options = listData?.map((el, i) => (
            <MenuItem key={i} value={el?.template_name}>
                {el?.template_name}
            </MenuItem>
        ));
    }
    const CustomPaper = (props) => {
        const check = { ...props };
        return (
            <Paper
                anchorEl={true}
                style={{
                    backgroundColor: "white",
                    boxShadow: "0px 4px 20px rgba(238, 238, 238, 0.5)",
                    border: "1px solid rgba(223, 225, 226, 0.56)",
                    width: "260px",
                    color: "black",
                    "&:hover": {
                        backgroundColor: "red",
                    },
                }}
                {...props}
            />
        );
    };
    const formats = [
        ["undo", "redo"],
        ["font", "fontSize", "formatBlock"],
        ["paragraphStyle", "blockquote"],
        ["bold", "underline", "italic", "strike", "subscript", "superscript"],
        ["fontColor", "hiliteColor", "textStyle"],
        ["removeFormat"],
        "/", // Line break
        ["outdent", "indent"],
        ["align", "horizontalRule", "list", "lineHeight"],
        ["table", "video", "audio" /** ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin.
        /** ['imageGallery'] */ // You must add the "imageGalleryUrl".
        ["fullScreen", "showBlocks", "codeView"],
        ["print"],
        ["save", "template"],
        /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
    ];

    const modules = {
        toolbar: [["bold", "italic", "underline", "input", "image", { list: "ordered" }, "link"]],
    };
    const changeTemplate = (event) => {
        if (!template) {
            setTemplate(event.target.value);
        } else {
            changeConfirmation(event.target.value);
        }

        //setTemplate(event.target.value);
    };
    const filterChange = (event) => {
        setSelected(event.target.value);
        const defaultVal = priorityListData?.find((el) => el?.task_priority == event.target.value);
        const temp = addDays(new Date(), defaultVal?.due_days);
        setDueDate(temp);
    };
    const handleValue = (newValue) => {
        if (newValue) {
            setDueDate(newValue?.$d);
        }
    };
    const AccessIcon = () => <img src="/images/invoice/calendar.svg" alt="calendar" className="convertWhite" />;
    const clickTrack = () => {
        if (inputValue?.length != 0) {
            if (regex.test(inputValue)) {
                setCcEmail(ccEmail?.concat(inputValue));
                setInputValue("");
            } else {
                setErrorCc(true);
                return false;
            }
        }
        if (forInput?.length != 0) {
            if (regex.test(forInput)) {
                setForEmail([forInput]);
                setForInput("");
            } else {
                setErrorEmail(true);
                return false;
            }
        }
        const getCc =
            ccEmail?.length > 0 || inputValue?.length > 0
                ? encryptId(inputValue?.length > 0 ? (ccEmail?.length > 0 ? ccEmail.concat(inputValue).join(";") : inputValue) : ccEmail.join(";"))
                : null;
        // For Bcc;
        if (bccInput?.length != 0) {
            if (regex.test(bccInput)) {
                const bccParse = bccEmail?.concat(bccInput);
                setBccEmail(bccParse);
                setBccInput("");
            } else {
                setErrorBcc(true);
                return false;
            }
        }
        const getBcc =
            bccEmail?.length > 0 || bccInput?.length > 0
                ? encryptId(bccInput?.length > 0 ? (bccEmail?.length > 0 ? bccEmail.concat(bccInput).join(";") : bccInput) : bccEmail.join(";"))
                : null;
        let contentParse = content;
        // if (content?.includes("<table>")) {
        const headContent = `<html><link rel="stylesheet" href="https://cdn.ckeditor.com/4.16.1/standard/ckeditor.css"><body>${contentParse}</body></html>`;
        contentParse = headContent;
        // }
        const reqObj = {
            json: [
                {
                    task_type: 2,
                    email_tempid: templateId,
                    task_subject: subject,
                    task_body: contentParse,
                    task_to: forInput?.length > 0 ? encryptId(forInput) : encryptId(forEmail[0]),
                    task_cc: getCc,
                    task_bcc: getBcc,
                    task_id: 0,
                    task_priority: selected,
                    due_date: moment(dueDate).format("YYYY-MM-DD").toString(),
                    parent_id: props?.parentId,
                    sender: getUserId?.id,
                    receiver: forInput?.length > 0 ? encryptId(forInput) : encryptId(forEmail[0]),
                    email_type: 1,
                },
            ],
            attachment: uploadedFiles,
            invid: +props?.invoiceId,
        };
        dispatch(addToDoTask(reqObj));
        //const getFor = mailBcc?.length > 0 ? mailBcc.join(";") : "";
    };
    if (invoiceItems?.length > 0) {
        let temp = [];
        invoiceItems?.map((item) => {
            let fieldName = "%" + item.label_displayname + "%";
            let fieldValue = item.label_displayname + " - " + item?.label_value;
            replacer[fieldName] = fieldValue;

            let info = { [fieldName]: item?.label_value };
            let addVal = temp?.find((item) => item?.fieldName);
            if (addVal == undefined) {
                temp.push(info);
            }
        });
        emailReplacer = temp;
    }
    if (template != "" || template?.length > 0) {
        const tempFind = listData?.find((item) => item?.template_name == template);
        templateId = tempFind?.id;
        bccMail = tempFind?.email_bcc;
    }

    if (requestComplete || (requestComplete && postData.status) || (error && !postData?.status)) {
        setTimeout(function () {
            dispatch({ type: Add_INVOICE_TODO_CREATE_TASK_RESET });
            if (postData?.status) {
                window.location.reload();
            }
        }, 1000);
    }

    // For the Attachment:
    const fileTypes = [
        "text/plain", // Plain text files
        "application/rtf", // Rich Text Format files
        "text/markdown", // Markdown files
        "application/msword", // Microsoft Word documents
        "application/vnd.ms-excel", // Microsoft Excel spreadsheets
        "application/vnd.ms-powerpoint", // Microsoft PowerPoint presentations
        "image/jpeg", // JPEG images
        "image/png", // PNG images
        "image/gif", // GIF images
        "image/bmp", // BMP images
        "image/svg+xml", //SVG images
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", //excel file
        "application/pdf", // PDF files
        "audio/mpeg", // MP3 audio files
        "audio/wav", // WAV audio files
        "audio/aac", // AAC audio files
        "video/mp4", // MP4 videos
        "video/avi", // AVI videos
        "video/x-matroska", // MKV videos
        "application/zip", // Zip archives
    ];

    const handleFileChange = async (event) => {
        const files = event.target.files;
        const selectedFilesArray = Array.from(files);
        const fileOverallSize = selectedFiles?.length + files?.length;

        const overallSizeInMb = (selectedFiles?.length == 0 ? 0 : getFilesSizeInMB(selectedFiles)) + getFilesSizeInMB(selectedFilesArray);
        if (overallSizeInMb > fileGroup) {
            Swal.fire({
                text: `Only ${fileGroup} MB attachments are allowed overall!`,
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            }).then((confirm) => {
                return false;
            });
            return false;
        }
        // setSelectedFiles((previousItem) => [
        //     ...selectedFilesArray,
        //     ...previousItem,
        // ]);
        let correctArray = [];
        let overSizeArray = [];
        if (files) {
            for (let i = 1; i <= files.length; i++) {
                const fileExt = fileTypes.find((item) => item == files[i - 1].type);
                if (files[i - 1]) {
                    const fileSize = (files[i - 1].size / (1024 * 1024)).toFixed(2); //mb conversion
                    if (fileSize > fileSizeMB) {
                        fileStatusList.push({
                            filename: files[i - 1].name,
                            error: "File size should be less than 10MB",
                            status: false,
                        });
                        overSizeArray?.push(files[i - 1].name);
                    } else if (!fileExt) {
                        fileStatusList.push({
                            filename: files[i - 1].name,
                            error: "File type is not supported",
                            status: false,
                        });
                    } else {
                        fileStatusList.push({
                            filename: files[i - 1].name,
                            error: "",
                            status: true,
                        });
                        await uploadWithJSON(files[i - 1], files[i - 1].name);
                        correctArray?.push(files[i - 1].name);
                    }
                }
            }
            const nonOverSizeFiles = selectedFilesArray?.filter((file) => {
                return correctArray.includes(file?.name);
            });
            setSelectedFiles((previousItem) => {
                const combinedArray = [...nonOverSizeFiles, ...previousItem];
                const uniqueSet = new Set(combinedArray);
                const uniqueArray = [...uniqueSet];
                return uniqueArray;
            });
            if (overSizeArray?.length > 0) {
                Swal.fire({
                    text: `Attachments: ${overSizeArray?.join(",")} exceed 1 MB!`,
                    icon: "warning",
                    closeOnClickOutside: false,
                    showCloseButton: true,
                    buttons: {
                        confirm: true,
                    },
                }).then((confirm) => {
                    overSizeArray = [];
                });
            }
            setFileStatusList([...fileStatusList]);
            event.target.value = "";
        }
    };
    async function uploadWithJSON(file, filename) {
        const reader = new FileReader();
        reader.onload = await _handleReaderLoaded(filename).bind(this);
        reader.readAsBinaryString(file);
    }

    const _handleReaderLoaded = (filename) => async (e) => {
        let binaryString = e.target.result;
        let imgObject = { filecontent: btoa(binaryString), filename: filename };
        setRender(!torender);
        uploadedFiles.push(imgObject);
    };

    const handleRemoveFile = (file) => {
        const updatedFiles = selectedFiles.filter((f) => f?.name !== file?.name);
        uploadedFiles = uploadedFiles.filter((f) => f?.filename !== file?.name);
        setSelectedFiles(updatedFiles);
    };

    //CkEditor funtions
    const handleEditorReady = (editor) => {
        editorRef.current = editor;
    };
    const handleEditorChange = (event) => {
        const data = event.editor.getData();
        setContent(data);
    };
    //Theme Colours
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const dropdownShadow = currentMode !== "dark" ? "0px 4px 20px rgba(238, 238, 238, 0.5)" : "";
    const dropdownBorder = currentMode !== "dark" ? "223, 225, 226, 0.56" : "";

    return (
        <Modal className={`${props?.className}`} highOverlay={true}>
            <span onClick={props?.handleClose} className="p-[6.5px] absolute right-[712px] ml-auto bg-light-10 cursor-pointer" title="Close">
                <img src="/images/common/whiteClose.svg" title="Close" alt="whiteClose" />
            </span>
            {requestComplete && postData?.status && props?.isOpened && <SuccessMessageBox success={succesMsg} />}
            {requestComplete && !postData?.status && <MessageBox error={errormsg} />}
            {error && !postData?.status && <MessageBox error={errormsg} />}
            {getConfig?.requestComplete && selected != undefined && props?.isOpened == true ? (
                <>
                    <div className={`flex max-w-[712px] ml-auto bg-${currentMode}-card justify-between flex-col max-h-[-webkit-fill-available]`}>
                        <div className="h-[calc(100vh_-_4rem)] overflow-y-auto max-h-[-webkit-fill-available]">
                            <div className={`flex py-[15px] px-[30px] justify-between items-center bg-${currentMode}-580`}>
                                <span className="block font-interSb text-[18px]">Create Task</span>
                                <div
                                    className={`flex items-center min-w-[200px] border-[1px] border-${currentMode}-840 pl-[10px] pr-[10px] py-[8.5px] rounded-[8px] `}>
                                    <FormControl
                                        sx={{
                                            minWidth: 200,
                                        }}>
                                        <Select
                                            onChange={changeTemplate}
                                            name="filter"
                                            className="insideFilter Org"
                                            defaultValue="default"
                                            value={template || `default`}
                                            MenuProps={{
                                                sx: {
                                                    "& .MuiMenu-paper": {
                                                        backgroundColor: dropdownColor,
                                                        color: dropdownText,
                                                        boxShadow: dropdownShadow,
                                                        border: dropdownBorder,
                                                        borderRadius: "8px",
                                                    },
                                                    "& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                    },
                                                    "& .Mui-selected": {
                                                        backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                    },
                                                },
                                            }}
                                            IconComponent={() => (
                                                <img
                                                    className="relative right-[10px] pointer-events-none"
                                                    src={
                                                        currentMode !== "dark"
                                                            ? `/images/header/chevronDown.svg`
                                                            : `/images/header/chevronDown-white.svg`
                                                    }
                                                    alt="chevronDown"
                                                />
                                            )}
                                            SelectDisplayProps={{
                                                style: {
                                                    paddingTop: 1,
                                                    display: "flex",
                                                    paddingBottom: 1,
                                                    paddingLeft: "5px",
                                                    border: 0,
                                                    paddingRight: "30px",
                                                    color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                },
                                            }}
                                            displayEmpty>
                                            <MenuItem
                                                //disabled
                                                // className="hidden"
                                                onClick={() => {
                                                    setTemplate("");
                                                    setInputValue("");
                                                    setSubject("");
                                                    setContent("");

                                                    editorRef.current.editor.setData("");
                                                    setForInput("");
                                                    setCcEmail([]);
                                                    setForEmail([]);
                                                    setBccEmail([]);
                                                    setBccInput("");
                                                }}
                                                value="default">
                                                Select Email Template
                                            </MenuItem>
                                            {options}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="flex py-[15px] px-[30px] items-center">
                                <span className="text-[16px]">Subject:</span>
                                <input
                                    placeholder=""
                                    className="w-full ml-6 bg-transparent"
                                    disabled={false}
                                    value={subject}
                                    onChange={(e) => setSubject(e.target.value)}
                                />
                            </div>
                            <div className={`flex items-center border-b-[1px] mx-[30px] mt-3 border-${currentMode}-830`}>
                                <div className="flex">
                                    <span className="text-[14px]">For:</span>
                                    <img className="ml-2 convertWhite" src="/images/invoice/user-plusblk.svg" alt="user-plusblk" />
                                </div>
                                <div className={`filter w-full ${overallOptions?.length > 0 ? `` : `solo`}`}>
                                    <Autocomplete
                                        multiple
                                        disablePortal
                                        sx={{
                                            "&.MuiAutocomplete-root .MuiInputBase-root": {
                                                border: 0,
                                                // marginLeft: "18px",
                                            },
                                            ".MuiInputBase-input": {
                                                color: `${currentMode == "dark" ? "white" : "black"} !important`,
                                            },

                                            "& .MuiChip-label": {
                                                color: `${currentMode == "dark" ? "white" : "black"}`,
                                            },
                                        }}
                                        PaperComponent={({ children }) => (
                                            <Paper
                                                style={{
                                                    backgroundColor: dropdownColor,
                                                    color: dropdownText,
                                                    boxShadow: dropdownShadow,
                                                    border: dropdownBorder,
                                                    borderRadius: "8px",
                                                }}>
                                                {children}
                                            </Paper>
                                        )}
                                        id="tags-filled"
                                        options={
                                            forEmail?.length > 0 || forEmail == undefined || forEmail == null
                                                ? []
                                                : overallOptions == undefined || overallOptions?.length == 0
                                                ? []
                                                : overallOptions
                                        }
                                        value={forEmail}
                                        inputValue={forInput}
                                        disableClearable
                                        onInputChange={(e, newValue) => {
                                            setForInput(newValue);
                                            setErrorEmail(false);
                                        }}
                                        freeSolo
                                        limitTags={1}
                                        onChange={(e, newval, reason) => {
                                            const errorEmail = newval.find((email) => !regex.test(email));
                                            if (errorEmail) {
                                                setForInput(errorEmail);
                                                setErrorEmail(true);
                                            } else {
                                                setErrorEmail(false);
                                            }
                                            setForEmail(newval?.filter((email) => regex.test(email)));
                                        }}
                                        renderTags={(value, getTagProps) =>
                                            value?.length > 0 &&
                                            value?.map((option, index) => {
                                                return (
                                                    <Chip
                                                        key={index}
                                                        variant="outlined"
                                                        disable={false}
                                                        label={option}
                                                        {...getTagProps({
                                                            index,
                                                        })}
                                                        onDelete={() => {
                                                            setForEmail(forEmail?.filter((e) => e !== option));
                                                        }}
                                                    />
                                                );
                                            })
                                        }
                                        renderInput={({ inputProps, ...params }) => (
                                            <>
                                                <TextField
                                                    fullWidth
                                                    {...params}
                                                    inputProps={{
                                                        ...inputProps,
                                                        readOnly: forEmail?.length > 0 ? true : false,
                                                        endAdornment: null,
                                                    }}
                                                />
                                                <p className="ml-[37px] text-[red] text-xs"> {errorEmail && "Enter a valid email address"}</p>
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className={`flex items-center border-b-[1px] mx-[30px] mt-3 border-${currentMode}-830`}>
                                <div className="flex ">
                                    <span className="text-[14px]">Cc:</span>
                                    <img className="ml-2 convertWhite" src="/images/invoice/user-plusblk.svg" alt="user-plusblk" />
                                </div>
                                <div className={`filter w-full ${overallOptions?.length > 0 ? `` : `solo`}`}>
                                    <Autocomplete
                                        multiple
                                        sx={{
                                            "&.MuiAutocomplete-root .MuiInputBase-root": {
                                                border: 0,
                                                // marginLeft: "18px",
                                                overflow: "hidden",
                                            },
                                            ".MuiInputBase-input": {
                                                color: `${currentMode == "dark" ? "white" : "black"} !important`,
                                            },

                                            "& .MuiChip-label": {
                                                color: `${currentMode == "dark" ? "white" : "black"}`,
                                            },
                                        }}
                                        PaperComponent={({ children }) => (
                                            <Paper
                                                style={{
                                                    backgroundColor: dropdownColor,
                                                    color: dropdownText,
                                                    boxShadow: dropdownShadow,
                                                    border: dropdownBorder,
                                                    borderRadius: "8px",
                                                }}>
                                                {children}
                                            </Paper>
                                        )}
                                        id="tags-filled"
                                        options={
                                            overallOptions == undefined || overallOptions == null || overallOptions?.length == 0
                                                ? []
                                                : overallOptions?.filter((item) => !ccEmail?.includes(item))
                                        }
                                        value={ccEmail}
                                        inputValue={inputValue}
                                        disablePortal
                                        disableClearable
                                        onInputChange={(e, newValue) => {
                                            setInputValue(newValue);
                                            setErrorCc(false);
                                        }}
                                        freeSolo
                                        limitTags={3}
                                        onChange={(e, newval, reason) => {
                                            const errorEmail = newval.find((email) => !regex.test(email));
                                            if (errorEmail) {
                                                setErrorCc(true);
                                                setInputValue(errorEmail);
                                            } else {
                                                setErrorCc(false);
                                            }
                                            setCcEmail(newval.filter((email) => regex.test(email)));
                                        }}
                                        renderTags={(value, getTagProps) =>
                                            value?.length > 0 &&
                                            value?.map((option, index) => {
                                                return (
                                                    <Chip
                                                        key={index}
                                                        variant="outlined"
                                                        disable={false}
                                                        label={option}
                                                        {...getTagProps({
                                                            index,
                                                        })}
                                                        onDelete={() => {
                                                            setCcEmail(ccEmail.filter((e) => e !== option));
                                                        }}
                                                    />
                                                );
                                            })
                                        }
                                        renderInput={(params) => (
                                            <>
                                                <TextField
                                                    fullWidth
                                                    {...params}
                                                    error={errorCc}
                                                    // helperText={
                                                    //     errorCc &&
                                                    //     "Enter a valid email address"
                                                    // }
                                                />
                                                <p className="ml-[37px] text-[red] text-xs"> {errorCc && "Enter a valid email address"}</p>
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className={`flex items-center border-b-[1px] mx-[30px] mt-3 border-${currentMode}-830`}>
                                <div className="flex ">
                                    <span className="text-[14px]">Bcc:</span>
                                    <img className="ml-2 convertWhite" src="/images/invoice/user-plusblk.svg" alt="user-plusblk" />
                                </div>
                                <div className={`filter w-full ${overallOptions?.length > 0 ? `` : `solo`}`}>
                                    <Autocomplete
                                        multiple
                                        disablePortal
                                        sx={{
                                            "&.MuiAutocomplete-root .MuiInputBase-root": {
                                                border: 0,
                                                // marginLeft: "18px",
                                                overflow: "hidden",
                                            },
                                            ".MuiInputBase-input": {
                                                color: `${currentMode == "dark" ? "white" : "black"} !important`,
                                            },

                                            "& .MuiChip-label": {
                                                color: `${currentMode == "dark" ? "white" : "black"}`,
                                            },
                                        }}
                                        PaperComponent={({ children }) => <Paper>{children}</Paper>}
                                        id="tags-filled"
                                        options={
                                            overallOptions == undefined || overallOptions?.length == 0 || overallOptions == null
                                                ? []
                                                : overallOptions?.filter((item) => !bccEmail?.includes(item))
                                        }
                                        value={bccEmail}
                                        inputValue={bccInput}
                                        disableClearable
                                        onInputChange={(e, newValue) => {
                                            setBccInput(newValue);
                                            setErrorBcc(false);
                                        }}
                                        freeSolo
                                        limitTags={3}
                                        onChange={(e, newval, reason) => {
                                            const errorEmail = newval.find((email) => !regex.test(email));
                                            if (errorEmail) {
                                                setErrorBcc(true);
                                                setBccInput(errorEmail);
                                            } else {
                                                setErrorBcc(false);
                                            }
                                            setBccEmail(newval.filter((email) => regex.test(email)));
                                        }}
                                        renderTags={(value, getTagProps) =>
                                            value?.length > 0 &&
                                            value?.map((option, index) => {
                                                return (
                                                    <Chip
                                                        key={index}
                                                        variant="outlined"
                                                        disable={false}
                                                        label={option}
                                                        {...getTagProps({
                                                            index,
                                                        })}
                                                        onDelete={() => {
                                                            setBccEmail(bccEmail.filter((e) => e !== option));
                                                        }}
                                                    />
                                                );
                                            })
                                        }
                                        renderInput={(params) => (
                                            <>
                                                <TextField
                                                    fullWidth
                                                    {...params}
                                                    error={errorBcc}
                                                    // helperText={
                                                    //     errorBcc &&
                                                    //     "Enter a valid email address"
                                                    // }
                                                />
                                                <p className="ml-[37px] text-[red] text-xs"> {errorBcc && "Enter a valid email address"}</p>
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center mx-[30px] justify-between">
                                <div className="">
                                    <FormControl fullWidth>
                                        <Select
                                            onChange={filterChange}
                                            name="filter"
                                            className="Org"
                                            value={selected}
                                            MenuProps={{
                                                sx: {
                                                    "& .MuiMenu-paper": {
                                                        backgroundColor: dropdownColor,
                                                        color: dropdownText,
                                                        boxShadow: dropdownShadow,
                                                        border: dropdownBorder,
                                                        borderRadius: "8px",
                                                    },
                                                    "& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                    },
                                                    "& .Mui-selected": {
                                                        backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                    },
                                                },
                                            }}
                                            SelectDisplayProps={{
                                                style: {
                                                    paddingTop: 1,
                                                    display: "flex",
                                                    paddingBottom: 1,
                                                    paddingLeft: "5px",
                                                    border: 0,
                                                    maxWidth: "127px",
                                                    marginLeft: "-15px",
                                                    paddingRight: "30px",
                                                    color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                },
                                            }}
                                            IconComponent={() => (
                                                <img
                                                    className="absolute right-[5px] pointer-events-none convertWhite"
                                                    src="/images/main/priority-icon.svg"
                                                    alt="user-plusblk"
                                                />
                                            )}
                                            fullWidth
                                            displayEmpty>
                                            <MenuItem value={4}>Urgent</MenuItem>
                                            <MenuItem value={3}>High</MenuItem>
                                            <MenuItem value={2}>Medium</MenuItem>
                                            <MenuItem value={1}>Low</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="ml-2 w-[120px]">
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DesktopDatePicker
                                            selectsStart
                                            inputFormat={localStorage.getItem("userDateFormat").toUpperCase()}
                                            components={{
                                                OpenPickerIcon: AccessIcon,
                                            }}
                                            disableFuture={false}
                                            disablePast
                                            value={dueDate}
                                            // minDate={new Date(1999, 12)}
                                            // maxDate={new Date(lastDateOfYear)}
                                            name="invoice_date_from"
                                            onChange={handleValue}
                                            renderInput={(params) => (
                                                <TextField
                                                    sx={{
                                                        "& .MuiOutlinedInput-root": {
                                                            paddingRight: 0,
                                                        },
                                                        "& .MuiInputAdornment-root": {
                                                            marginRight: 0,
                                                        },
                                                        "& .MuiIconButton-edgeEnd": {
                                                            padding: "0",
                                                            marginRight: 0,
                                                        },
                                                        "& .MuiInputBase-input": {
                                                            color: `${currentMode == "dark" ? "white" : "black"} !important`,
                                                        },
                                                    }}
                                                    {...params}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        placeholder: " ",
                                                        readOnly: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>

                            <div className={`allocate-textarea px-[30px] relative template my-5 bg-${currentMode}-card`}>
                                {/* <SunEditor
                                    setOptions={{
                                        plugins: plugins,
                                        buttonList: formats,
                                    }}
                                    setContents={content}
                                    onChange={setContent}
                                    height="400px"
                                    onPaste={handlePaste}
                                /> */}
                                <div className="ckreply cktask">
                                    <CKEditor
                                        data={content}
                                        onChange={handleEditorChange}
                                        initData={content}
                                        onInstanceReady={handleEditorReady}
                                        config={{
                                            removeButtons: "Image,Paste,About,PasteText,PasteFromWord,Anchor",
                                            removePlugins: "maximize",
                                        }}
                                    />
                                </div>
                            </div>
                            <span>
                                {selectedFiles?.map((file, index) => (
                                    <Chip
                                        key={index}
                                        label={file.name}
                                        onDelete={() => handleRemoveFile(file)}
                                        style={{
                                            margin: "0.5rem",
                                            maxWidth: "170px",
                                        }}
                                    />
                                ))}
                            </span>
                        </div>

                        <div className={`flex py-[15px] px-[30px] bg-${currentMode}-card border-t-[1px] sticky bottom-0 border-${currentMode}-700`}>
                            <Button
                                title="To Create Email Task"
                                onClick={clickTrack}
                                disabled={
                                    subject == "" ||
                                    (forInput == "" && forEmail?.length == 0) ||
                                    content == "" ||
                                    content == "<p><br></p>" ||
                                    fetching
                                }
                                className={`adduser-step users-page-step border-20 bg-light-20 px-5 py-2 rounded-[8px] font-interM text-sm flex items-center border-[1px] text-white border-transparent`}>
                                {fetching && <CircularProgress size={20} color="inherit" />}

                                <span className="ml-2">Create Task</span>
                            </Button>
                            <Button className={`ml-4 cursor-pointer boder-${currentMode}-700`} title="Attachment">
                                <input
                                    type="file"
                                    multiple
                                    onChange={handleFileChange}
                                    id="email-file-input"
                                    style={{
                                        display: "none",
                                    }}
                                    accept={fileTypes.join(",")}
                                />
                                <label htmlFor="email-file-input" className="cursor-pointer">
                                    {/* <IconButton component="span"> */}
                                    {currentMode === "dark" ? (
                                        <img className={`mx-auto`} src="/images/common/attachment.svg" alt="circle" />
                                    ) : (
                                        <img
                                            className={`mx-auto shadow-[0px_4px_20px_rgba(238,238,238,0.5)] `}
                                            src="/images/main/addfile-list.svg"
                                            alt="circle"
                                        />
                                    )}

                                    {/* </IconButton> */}
                                </label>
                            </Button>
                        </div>
                    </div>
                </>
            ) : (
                <div className={`flex min-w-[712px] bg-${currentMode}-card overflow-y-auto justify-between flex-col h-screen `}>
                    {" "}
                    <div className={`flex py-[15px] px-[30px] justify-between items-center bg-${currentMode}-580`}>
                        {" "}
                        <span className="block font-interSb text-[18px]">Create Task</span>{" "}
                    </div>
                    <Loader className="h-[calc(100vh_-_6rem)]" />{" "}
                </div>
            )}
        </Modal>
    );
};
export default CreateEmailTask;
