import { useDispatch, useSelector } from "react-redux";
import Card from "../../../components/UI/Card";
import Main from "../../../components/UI/Main";
import Layout from "../../../Hoc/Layout";
import Footer from "../../../organisms/Footer";
import Button from "../../../components/Button";
import { useState, useEffect, useContext } from "react";
import TriggerSwitch from "../../../components/TriggerSwitch";
import Swal from "sweetalert2";
import { decryptId, encryptId } from "../../../utils/helpers";
import { INBOX_PREVIEW_GET_RESET, INBOX_SETTINGS_DELETE_RESET } from "../../../redux/types/types";
import { getInboxSettingList, inboxSettingsDelete, inboxSettingsStatusMode } from "../../../redux/actions/inbox-setting";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GET_INBOX_SETTINGS_RESET } from "../../../redux/types/types";
import { WS_DISCONNECTED } from "../../../redux/types/types";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";

let isEnabled = null;

const InboxSettings = (props) => {
    const { currentMode } = useContext(ThemeContext);
    const navigate = useNavigate();
    const [inboxid, setInboxId] = useState("");
    const [inboxSettingsList, setInboxSettingsList] = useState(props.inboxSettingsList);
    const dispatch = useDispatch();
    let listData = useSelector((state) => state?.inboxSettingListState);
    const configData = listData?.configData;
    if (configData?.length > 0) {
        localStorage.setItem("mailSyncEmail", JSON.stringify(configData[0]?.isactive));
    } else {
        localStorage.setItem("mailSyncEmail", JSON.stringify(false));
    }
    //ACTIVE-IN-ACTIVE
    const response = useSelector((state) => state?.inboxSettingsStatusState);
    const { requestComplete, data } = useSelector((state) => state?.inboxSettingsDeleteState);

    const handleActivateClick = (id, e) => {
        Swal.fire({
            closeOnClickOutside: false,
            showCancelButton: true,
            showCloseButton: true,
            text: "Are you sure you want to change the status of the inbox settings?",
            icon: "warning",
            buttons: {
                cancel: "Cancel",
                confirm: "Confirm",
            },
        }).then((confirm) => {
            if (confirm?.isConfirmed) {
                localStorage.removeItem("folderId");
                dispatch(
                    inboxSettingsStatusMode({
                        inboxid: +id,
                        status: e?.target?.checked ? false : true,
                    })
                );
            }
        });
    };
    if (response.requestComplete && response.data?.status) {
        response.requestComplete = false;
        {
            Swal.fire({
                text: "Status updated successfully.",
                icon: "success",
                closeOnClickOutside: false,
                showCloseButton: true,
                button: "Ok",
            }).then((confirm) => {
                if (confirm) {
                    dispatch(getInboxSettingList());
                }
            });
        }
    }

    useEffect(() => {
        dispatch(getInboxSettingList());
    }, [dispatch]);

    const onDeleteInbox = (inboxid) => {
        Swal.fire({
            text: "Are you sure you want to delete this email account?",
            closeOnClickOutside: false,
            icon: "warning",
            showCancelButton: true,
            showCloseButton: true,
            icon: "warning",
            buttons: {
                cancel: "Cancel",
                confirm: "Confirm",
            },
        }).then((confirm) => {
            if (confirm?.isConfirmed) {
                const deleteObj = {
                    inboxid: inboxid,
                };
                localStorage.removeItem("folderId");
                dispatch(inboxSettingsDelete(deleteObj));
                setInboxId(inboxid);
                dispatch({ type: GET_INBOX_SETTINGS_RESET });
                dispatch({ type: INBOX_SETTINGS_DELETE_RESET });
                setTimeout(() => {
                    dispatch(getInboxSettingList());
                    navigate("/inbox-config");
                }, 1000);
            }
        });
    };

    if (requestComplete) {
        if (data?.status) {
            setTimeout(() => {
                const deletedInboxSettings = inboxSettingsList?.filter((item) => item.id !== inboxid);
                setInboxSettingsList([...deletedInboxSettings]);
                dispatch({ type: INBOX_SETTINGS_DELETE_RESET });
            }, 1000);
            if (requestComplete && inboxid?.length == 0) {
                props?.handleClose();
                window.location.reload();
            }
        }
    }
    return (
        <>
            <Layout>
                <Main>
                    <div className={`flex justify-between items-center mb-6 text-${currentMode}-copy`}>
                        <div>
                            <h4 className="text-2xl font-interSb">Inbox Settings</h4>
                        </div>
                    </div>
                    <Card className="p-[20px]  h-[calc(100vh_-_13rem)] relative overflow-y-auto">
                        <h5 className="font-interM text-lg mb-1">Email</h5>
                        <p className="text-xs mb-[30px] xl:max-w-none max-w-[30rem]">
                            Connect your personal email accounts to MyiQ.Ai to log, track, send, and receive emails in the MyiQ.Ai.
                        </p>

                        <table className={`xl:w-[50%] w-full border-${currentMode}-700 !border-[1px] rounded-[8px] rolemasterTable text-left `}>
                            <thead className={`bg-${currentMode}-450`}>
                                <tr>
                                    <th>Email</th>
                                    <th>Status</th>
                                    <th> </th>
                                </tr>
                            </thead>
                            <tbody>
                                {configData?.length !== 0 ? (
                                    configData?.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>
                                                    <div className="flex items-center">
                                                        <div className="flex justify-center items-center w-[60px] bg-light-420 rounded-full  h-[60px] ">
                                                            {item?.email_type == 1 ? (
                                                                <img className="h-[30px] " src="/images/main/gmail-icon.svg" alt="gmail-icon" />
                                                            ) : (
                                                                <img className="h-[30px] " src="/images/main/outlook-icon.svg" alt="outlook-icon" />
                                                            )}
                                                        </div>

                                                        <span className="pl-5">{decryptId(item?.emailaddress)}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <TriggerSwitch checked={item?.isactive} onChange={(e) => handleActivateClick(item?.id, e)} />
                                                </td>
                                                <td className="items-center">
                                                    <img
                                                        className="cursor-pointer mx-auto convertWhite"
                                                        src="/images/main/delete.svg"
                                                        tabIndex="0"
                                                        onClick={() => onDeleteInbox(item?.id)}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <p className="p-5 text-center">No Data Found</p>
                                )}
                            </tbody>
                        </table>
                        {localStorage.getItem("mailSyncEmail") == "true" && (
                            <Button
                                className="flex absolute cursor-pointer right-[20px] top-[20px]"
                                onClick={() => {
                                    if (configData[0]?.isactive) {
                                        navigate("/inbox-compose");
                                    }
                                    dispatch({ type: INBOX_PREVIEW_GET_RESET });
                                }}
                                tabIndex="0">
                                <img className="mr-2 convertWhite" src="/images/main/noun-back.svg" alt="noun-back" /> Back
                            </Button>
                        )}
                    </Card>
                </Main>
                <Footer />
            </Layout>
        </>
    );
};
export default InboxSettings;
