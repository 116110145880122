import { FormControl, MenuItem, Select, Checkbox, ClickAwayListener, Fade, FormControlLabel, Input, Paper, Popper, TextField } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import Card from "../../../components/UI/Card";
import Main from "../../../components/UI/Main";
import Button from "../../../components/Button";
import Layout from "../../../Hoc/Layout";
import Footer from "../../../organisms/Footer";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import TogglePopup from "../../organisations/Toggle";
import Kanban from "./kanban";
import ReactDOM from "react-dom";
import AddUser from "../../../organisms/UserModal/AddUser";
import CreateEmailTask from "../CreateTask/CreateEmailTask";
import CreateTodoTask from "../CreateTask/CreateTodoTask";
import ButtonGroup from "@mui/material/ButtonGroup";
import { useDispatch } from "react-redux";
import { getMyTaskList, getToDOTaskUserList } from "../../../redux/actions/my-task";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import moment from "moment";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { head } from "lodash";
import { ViewTask } from "./ViewTask";
import { useCallback } from "react";
import { useContext } from "react";
import { decryptId, encryptId } from "../../../utils/helpers";
import { date } from "yup/lib/locale";
import { get } from "lodash";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import { ErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../organisms/Sidebar";
import Header from "../../../organisms/Header";
import FournotFour from "../../404";
const ErrorFallback = ({ error }) => {
    return (
        <>
            <FournotFour />
        </>
    );
};

let dateFrom = "";
let dateTo = "";
const ListTable = () => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const [selected, setSelected] = useState("default");
    const [selectedValue, setSelectedValue] = useState("");
    const [expandMe, setExpandMe] = useState("1");
    const [expandTeam, setExpandTeam] = useState("2");
    const [expandAwait, setExpandAwait] = useState("3");
    const [expandSubtask, setExpandSubtask] = useState("1");
    const [viewType, setViewType] = useState("List");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [dateFormat, setDateFormat] = useState("");
    const [addTask, setAddTask] = useState(false);
    const [anchorElCalendar, setAnchorElCalendar] = useState(null);
    const [placementCalendar, setPlacementCalendar] = useState();
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState({
        pendingWithMe: [],
        pendingWithTeam: [],
        awaitingResponse: [],
    });
    const [valuecheck, handleValues] = useState([]);

    const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
        border: `0`,
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
    }));
    let check = false;
    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary expandIcon={<img className="" src="/images/common/accordion-active.svg" alt="accordion-active" />} {...props} />
    ))(({ theme }) => ({
        padding: "11px 30px",
        margin: 0,
        flexDirection: "row-reverse",
        "& .MuiAccordionSummary-expandIconWrapper": {
            marginRight: 10,
            transform: "rotate(270deg)",
            filter: `${currentMode === "dark" ? "filter: brightness(0) invert(1)" : "brightness(0) invert(0)"}`,
        },
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(360deg)",
            filter: "none",
        },
        "& .MuiAccordionSummary-content": {
            margin: 0,
        },
    }));

    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: 0,
        border: 0,
    }));

    const [addTodoTask, setAddTodoTask] = useState(false);
    const myTaskConfig = useSelector((state) => state?.myTaskListState);
    const taskList = myTaskConfig?.dataList;
    const [headerInfo, setHeaderInfo] = useState({
        todayTask: 0,
        overdue: 0,
    });
    const [myTaskList, setMyTaskList] = useState({
        pendingWithMe: [],
        pendingWithTeam: [],
        awaitingResponse: [],
    });

    const user = JSON.parse(localStorage.getItem("user"));
    const assignees = taskList?.map((item) => item?.assignee_name);
    useEffect(() => {
        if (taskList?.length == 0) {
            setMyTaskList({
                pendingWithMe: [],
                pendingWithTeam: [],
                awaitingResponse: [],
            });
            setPlacement({
                pendingWithMe: [],
                pendingWithTeam: [],
                awaitingResponse: [],
            });
        }
        if (taskList?.length != 0) {
            const pendingWithMe = [];
            const getAssignee = [];
            const pendingWithTeam = [];
            const awaitingResponse = [];

            const subtaskMe = taskList
                ?.filter((item) => {
                    return item?.parent_taskid != null && item?.last_communication_from === user?.id.toString();
                })
                ?.map((item) => item?.parent_taskid);
            let subTaskTeam = [];
            // New Method
            const NoSubtask = (item, doneSubtasks) => {
                const valueParse = item?.display_id == null ? decryptId(item?.display_name) : item?.display_name;
                if (
                    item?.task_type == 1
                        ? item?.task_status == 3
                            ? item?.created_by == user?.id.toString()
                            : item?.last_communication_from === user?.id.toString()
                        : item?.last_communication_from === user?.id.toString()
                ) {
                    item.display_name = valueParse;
                    getAssignee.push(valueParse);
                    if (doneSubtasks?.find((sub) => sub?.created_by != user?.id.toString()) != undefined) {
                        pendingWithTeam.push(item);
                    } else {
                        pendingWithMe.push(item);
                    }
                } else if (
                    item?.task_type == 1
                        ? item?.task_status == 3
                            ? item?.created_by != user?.id.toString()
                            : item?.last_communication_from === item?.assignee_id
                        : item?.last_communication_from === item?.assignee_id
                ) {
                    item.display_name = valueParse;
                    getAssignee?.push(valueParse);
                    if (doneSubtasks?.find((sub) => sub?.created_by == user?.id.toString()) != undefined) {
                        pendingWithMe.push(item);
                    } else {
                        pendingWithTeam.push(item);
                    }
                } else {
                    item.display_name = valueParse;
                    getAssignee.push(valueParse);
                    awaitingResponse.push(item);
                }
            };

            taskList?.map((item) => {
                if (item?.parent_taskid == null) {
                    const mainId = item?.task_id;
                    const subtaskList = taskList?.filter((ele) => ele?.parent_taskid == mainId && (ele?.task_status == 1 || ele?.task_status == 4));
                    const doneSubtasks = taskList?.filter((ele) => ele?.parent_taskid == mainId && ele?.task_status == 3);
                    if (subtaskList?.length == 0) {
                        NoSubtask(item, doneSubtasks);
                    } else {
                        const valueParse = item?.display_id == null ? decryptId(item?.display_name) : item?.display_name;
                        if (
                            subtaskList?.find(
                                (sub) => sub?.last_communication_from == user?.id.toString() && (sub?.task_status == 1 || sub?.task_status == 4)
                            ) != undefined
                        ) {
                            item.display_name = valueParse;
                            getAssignee.push(valueParse);
                            pendingWithMe.push(item);
                        } else if (
                            subtaskList?.find(
                                (sub) => sub?.last_communication_from === sub?.assignee_id && (sub?.task_status == 1 || sub?.task_status == 4)
                            ) != undefined
                        ) {
                            item.display_name = valueParse;
                            getAssignee?.push(valueParse);
                            pendingWithTeam.push(item);
                        } else {
                            item.display_name = valueParse;
                            getAssignee?.push(valueParse);
                            awaitingResponse?.push(item);
                        }
                    }
                }
            });
            setDateFormat(taskList?.[0]?.user_date?.toUpperCase());
            const temp = {
                pendingWithMe: pendingWithMe,
                pendingWithTeam: pendingWithTeam,
                awaitingResponse: awaitingResponse,
            };
            const today = new Date();
            let tTask = 0;
            let over = 0;
            pendingWithMe.map((item) => {
                let parseDate = moment(item?.due_date, taskList[0]?.user_date?.toUpperCase());

                let parseToday = moment(today, taskList[0]?.user_date?.toUpperCase());

                if (parseDate.toString().slice(0, -17) === parseToday.toString().slice(0, -17)) {
                    tTask = tTask + 1;
                } else if (Date.parse(parseDate) < Date.parse(parseToday)) {
                    over = over + 1;
                }
            });
            const headTemp = {
                todayTask: tTask,
                overdue: over,
            };
            setHeaderInfo(headTemp);
            setMyTaskList(temp);
            setPlacement(temp);

            const assign = [...new Set(getAssignee)];
            const dataToPush = [];

            assign?.map((item) => {
                dataToPush.push({
                    name: item,
                    isCheck: false,
                });
            });
            handleValues(dataToPush);
        }
    }, [myTaskConfig]);
    const childCompRef = useRef();
    const filterMenuList = [
        { text: "Email", src: "/images/main/Email-task.svg", value: 2 },
        { text: "To Do", src: "/images/main/To-do.svg", value: 1 },
    ];
    const ListData = useSelector((state) => state?.myTaskUserListState);
    const userListData = ListData?.dataList;
    const onFilterItems = (value) => {
        if (value == 1) {
            setAddTodoTask(true);
            if (userListData?.length == 0) {
                dispatch(getToDOTaskUserList());
            }
        }
        if (value == 2) {
            setAddTask(true);
        }
    };
    const AccessIcon = () => <img src="/images/common/date-ico.svg" alt="date-ico" />;
    const lastDateOfYear = `12/31/${new Date().getFullYear()}`;
    let options = null;
    let type = null;

    const assignee = ["Me", "Hari", "Sam"];
    const dueDate = ["20/01/2023", "04/05/2023"];

    if (selected === "Assignee") {
        type = assignee;
    } else if (selected === "Due Date") {
        type = dueDate;
        valuecheck.map((item) => {
            item.isCheck = false;
            return item;
        });
    }
    const changeTaskStatus = useCallback(() => {
        // myTaskList?.pendingWithMe.map((elem) => {
        //   if (elem?.task_id == id) {
        //     elem.task_status = 2;
        //     return elem;
        //   } else {
        //     return elem;
        //   }
        // });
        // myTaskList?.pendingWithTeam.map((elem) => {
        //   if (elem?.task_id == id) {
        //     elem.task_status = 2;
        //     return elem;
        //   } else {
        //     return elem;
        //   }
        // });
        // myTaskList?.awaitingResponse.map((elem) => {
        //   if (elem?.task_id == id) {
        //     elem.task_status = 2;
        //     return elem;
        //   } else {
        //     return elem;
        //   }
        // });
        setTimeout(() => {
            dispatch(getMyTaskList());
        }, 2000);
    }, [myTaskList]);
    const filterChange = (event) => {
        setSelected(event.target.value);
    };
    const handleSelectedValue = (event) => {
        setSelectedValue(event.target.value);
    };
    const changeView = (event) => {
        setViewType(event.target.value);
    };
    const changeCreateTask = (event) => {
        setViewType(event.target.value);
    };

    const handleChangeMe = (panel) => (event, newExpanded) => {
        setExpandMe(newExpanded ? panel : false);
    };
    const [getId, setId] = useState("");
    const [viewTask, setViewTask] = useState(false);
    const handleChangeTeam = (panel) => (event, newExpanded) => {
        setExpandTeam(newExpanded ? panel : false);
    };
    const handleChangeAwait = (panel) => (event, newExpanded) => {
        setExpandAwait(newExpanded ? panel : false);
    };
    const handleChangeSubtask = (panel) => (event, newExpanded) => {
        // demoMe[index].expand_me=demoMe[index]?.expand_me == true ? null:true
        setExpandSubtask(newExpanded ? panel : false);
    };
    let checkOne = null;
    if (type != null) {
        options = type.map((el, i) => (
            <MenuItem key={i} tabIndex="0" value={el} className="truncate trunc-1 block">
                {el}
            </MenuItem>
        ));
    }
    const checkChange = (e) => {
        const { value } = e.target;
        const { checked } = e.target;
        valuecheck.map((item) => {
            if (item?.name == value) {
                item.isCheck = checked;
                return item;
            } else {
                return item;
            }
        });
        const temp = [];
        valuecheck.map((item) => {
            if (item?.isCheck) {
                temp.push(item?.name);
            }
        });
        filterAssignee(temp);
    };
    if (selected === "Assignee") {
        options = valuecheck.map((el, i) => (
            <MenuItem key={i}>
                <FormControlLabel
                    control={
                        <Checkbox
                            size="small"
                            onChange={checkChange}
                            checked={el?.isCheck}
                            value={el?.name}
                            sx={{
                                padding: 0,
                                color: "#008785",
                                margin: "0 12px",
                                "&.Mui-checked": {
                                    color: "#008785",
                                },
                            }}
                        />
                    }
                    label={el?.name}
                />
            </MenuItem>
        ));
    }
    const getTaskView = useCallback(
        (status, id) => {
            setViewTask(status);
            setId(id);
        },
        [viewTask]
    );
    const handleClickCalendar = (newPlacement) => (event) => {
        setAnchorElCalendar(event.currentTarget);
        setOpen((prev) => placementCalendar !== newPlacement || !prev);
        setPlacementCalendar(newPlacement);
    };

    const handleValue = (newValue) => {
        if (newValue) {
            setFromDate(newValue);
        }
    };
    const handleToDate = (toDate) => {
        if (toDate) {
            setToDate(toDate);
        }
    };
    const filterAssignee = (filt) => {
        if (selected == "Assignee") {
            const pendingWithMe = [];
            const pendingWithTeam = [];
            const awaitingResponse = [];
            if (filt?.length == 0) {
                setMyTaskList(placement);
            } else {
                placement?.pendingWithMe?.map((item) => {
                    if (filt?.includes(item?.display_name)) {
                        pendingWithMe.push(item);
                    }
                });
                placement?.pendingWithTeam?.map((item) => {
                    if (filt?.includes(item?.display_name)) {
                        pendingWithTeam.push(item);
                    }
                });
                placement?.awaitingResponse?.map((item) => {
                    if (filt?.includes(item?.display_name)) {
                        awaitingResponse.push(item);
                    }
                });

                const temp = {
                    pendingWithMe: pendingWithMe,
                    pendingWithTeam: pendingWithTeam,
                    awaitingResponse: awaitingResponse,
                };

                setMyTaskList(temp);
            }
        }
    };

    const resetFilter = () => {
        setFromDate("");
        setToDate("");
        setSelected(undefined);
        valuecheck.map((item) => {
            item.isCheck = false;
            return item;
        });
        setMyTaskList(placement);
    };

    useEffect(() => {
        if (selected == "Assignee") {
            setFromDate("");
            setToDate("");
        }
        if ((fromDate != "" || toDate != "") && selected == "Due Date") {
            let pendingWithMe = placement?.pendingWithMe;
            let pendingWithTeam = placement?.pendingWithTeam;
            let awaitingResponse = placement?.awaitingResponse;

            if (fromDate != "" && toDate != "") {
                pendingWithMe = pendingWithMe.filter((item) => {
                    let parseDate = moment(item?.due_date, dateFormat);

                    return Date.parse(parseDate) >= Date.parse(fromDate) && Date.parse(parseDate) <= Date.parse(toDate);
                });
                pendingWithTeam = pendingWithTeam.filter((item) => {
                    let parseDate = moment(item?.due_date, dateFormat);

                    return Date.parse(parseDate) >= Date.parse(fromDate) && Date.parse(parseDate) <= Date.parse(toDate);
                });
                awaitingResponse = awaitingResponse.filter((item) => {
                    let parseDate = moment(item?.due_date, dateFormat);

                    return Date.parse(parseDate) >= Date.parse(fromDate) && Date.parse(parseDate) <= Date.parse(toDate);
                });
            } else if (toDate == "" && fromDate != "") {
                pendingWithMe = pendingWithMe.filter((item) => {
                    let parseDate = moment(item?.due_date, dateFormat);
                    return Date.parse(parseDate) >= Date.parse(fromDate);
                });
                pendingWithTeam = pendingWithTeam.filter((item) => {
                    let parseDate = moment(item?.due_date, dateFormat);
                    return Date.parse(parseDate) >= Date.parse(fromDate);
                });
                awaitingResponse = awaitingResponse.filter((item) => {
                    let parseDate = moment(item?.due_date, dateFormat);
                    return Date.parse(parseDate) >= Date.parse(fromDate);
                });
            } else {
                pendingWithMe = pendingWithMe.filter((item) => {
                    let parseDate = moment(item?.due_date, dateFormat);

                    return Date.parse(parseDate) <= Date.parse(toDate);
                });
                pendingWithTeam = pendingWithTeam.filter((item) => {
                    let parseDate = moment(item?.due_date, dateFormat);
                    return Date.parse(parseDate) <= Date.parse(toDate);
                });
                awaitingResponse = awaitingResponse.filter((item) => {
                    let parseDate = moment(item?.due_date, dateFormat);
                    return Date.parse(parseDate) <= Date.parse(toDate);
                });
            }
            const temp = {
                pendingWithMe: pendingWithMe,
                pendingWithTeam: pendingWithTeam,
                awaitingResponse: awaitingResponse,
            };
            setMyTaskList(temp);
        }
    }, [fromDate, toDate, selected]);

    useEffect(() => {
        dispatch(getMyTaskList());
    }, []);

    let demoMe = [
        {
            task_id: 1,
            invoice_no: 24312,
            header: "Invoice.no: 234567 - Processed Invoices List page with Filters",
            ticket: "MYIQ-1009",
            priority: "High",
            dueDate: "22-04-2023",
            status: "Inprogress",
            assignee: "Kavi Bharathi",
            subtask_id: null,
            parent_id: null,
            expand_me: "",
        },
        {
            task_id: 2,
            invoice_no: 24312,
            header: "Invoice.no: 234567 - Processed Invoices List page with Filters",
            ticket: "MYIQ-100",
            priority: "Medium",
            dueDate: "22-04-2023",
            status: "Inprogress",
            assignee: "Hariharan Govindaraj",
            subtasks: 0,
            parent_id: null,
            subtask_id: null,
            expand_me: "",
        },
        {
            task_id: 6,
            invoice_no: 24312,
            header: "Invoice.no: 234567 - Processed Invoices List page with Filters",
            ticket: "MYIQ-100",
            priority: "Low",
            dueDate: "22-04-2023",
            status: "Inprogress",
            assignee: "Venkateshwaran",
            parent_id: null,
            subtask_id: 2,
            expand_me: "",
        },
    ];

    const SubTaskAccordian = (props) => {
        return (
            <>
                <div className="ml-4">
                    <Accordion expanded={expandSubtask} onChange={handleChangeSubtask("1")}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            {/* <NormalTask elem={props?.elem} subTask={false}/> */}
                            <div className="flex accord_body inbox ">
                                <div className="flex space-x-2 main basis-[80%] justify-between ">
                                    <div className={`flex items-start ${props?.subTask ? "ml-6" : ""}`}>
                                        <img className="mt-1 convertWhite" src="/images/main/task-icon.svg" alt="task-icon" />
                                        <span className="ml-3">{props?.elem?.header}</span>
                                    </div>
                                    {props?.elem?.total_count != 0 && (
                                        <div className="flex items-center">
                                            <img className="convertWhite" src="/images/main/subtask.svg" alt="subtask-icon" />
                                            <span className="my-auto mx-auto">0/2</span>
                                        </div>
                                    )}
                                    <button
                                        className={`border-[1px] mr-[5px] truncate max-w-[60px] whitespace-nowrap px-1 bg-white rounded-[2px] my-auto mx-auto text-[12px]  text-black 
                                            `}
                                    >
                                        {props?.elem?.ticket}
                                    </button>
                                </div>
                                <div className="flex main basis-3/12 justify-center text-center">
                                    <button
                                        className={`border-[1px] bg-[rgba(255,114,94,1)] rounded-[5px] my-auto text-[12px]  px-2 h-[24px] text-white ${props?.elem?.priority === "High"
                                            ? "bg-[rgba(255,114,94,1)]"
                                            : props?.elem?.priority === "Low"
                                                ? "bg-[rgba(0,177,162,1)]"
                                                : "bg-[rgba(251,189,3,1)]"
                                            }`}
                                    >
                                        {props?.elem?.priority}
                                    </button>
                                </div>
                                <div className="flex main basis-3/12  item-center text-center">
                                    <span className="my-auto mx-auto ">{props?.elem?.dueDate}</span>
                                </div>
                                <div className="flex main basis-3/12 item-center ">
                                    <span className="my-auto mx-auto">{props?.elem?.status}</span>
                                </div>
                                <div className="flex main basis-5/12  items-center justify-start">
                                    {/* <div className="flex flex-initial ">
                        <img src="/images/main/userNew.svg" alt="userNew" />
                    </div> */}
                                    <span>{props?.elem?.assignee}</span>
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            {demoMe &&
                                demoMe?.map((element) => {
                                    return (
                                        <>
                                            <NormalTask elem={element} subTask={true} />
                                        </>
                                    );
                                })}
                        </AccordionDetails>
                    </Accordion>
                </div>
            </>
        );
    };
    const taskViewId = localStorage.getItem("taskViewId");

    useEffect(() => {
        if (taskViewId !== null) {
            setViewTask(true);
            setId(taskViewId);
        }
    }, [taskViewId]);
    const NormalTask = (props) => {
        return (
            <>
                <div
                    className={`flex accord_body w-[1400px] xl:w-full inbox hover:bg-${currentMode}-960 cursor-pointer`}
                    title="Click to view task"
                    onClick={() => {
                        setViewTask(true);
                        setId(props?.elem?.task_id);
                        localStorage.setItem("taskViewId", props?.elem?.task_id);
                    }}
                >
                    <div className="flex main basis-[80%] pr-0 pl-[1.4rem] justify-between  mr-0">
                        {/* <div className="mt-1">
                                <img
                                  className=" flex-initial"
                                  src="/images/main/task-icon.svg"
                                  alt="task-icon"
                                />
                              </div> */}
                        {/* <div className="flex flex-1 ">
                                <span>{elem?.header}</span>
                              </div> */}
                        <div className={`flex items-center pr-0 basis-[80%] ${props?.subTask ? "ml-6" : ""}`}>
                            <img
                                className="mt-1 convertWhite"
                                src={props?.elem?.task_status == 2 ? "/images/main/task-complete.svg" : "/images/main/circle.svg"}
                                alt="task-icon"
                            />
                            <span className="ml-3 trunc-3 p-0" title={props?.elem?.task_name}>
                                Invoice Number: {props?.elem?.invoice_no} - {props?.elem?.task_name}
                            </span>
                        </div>
                        <div className="flex items-center basis-[5%] px-2">
                            <button
                                className={`border-[1px] ml-[2px] whitespace-nowrap  px-2 rounded-[5px] my-auto mx-auto text-[12px] border-transparent text-white ${props?.elem?.task_type === 1 ? "bg-[rgba(94,101,174,1)]" : "bg-[rgba(1,148,186,1)]"
                                    }`}
                            >
                                {props?.elem?.task_type === 1 ? "Internal" : "External"}
                            </button>
                        </div>
                        <div className={`flex flex-col basis-[15%]  ${props?.elem?.total_count != 0 ? "" : "justify-center"} px-1`}>
                            <button
                                title={props?.elem?.task_no}
                                className={`border-[1px]  ${props?.elem?.total_count != 0 ? "mt-5" : ""}  w-[80px] ${props?.elem?.task_no?.length > 5 ? `truncate` : ``
                                    } px-2 bg-${currentMode}-card border-${currentMode}-700 text-${currentMode}-copy whitespace-nowrap rounded-[2px]  text-[12px]  text-black`}
                            >
                                {props?.elem?.task_no}
                            </button>
                            {props?.elem?.total_count != 0 && (
                                <span className="flex basis-[5%] items-center">
                                    <img className="convertWhite" src="/images/main/subtask.svg" alt="subtask-icon" />
                                    <span className="my-auto ml-1 mx-auto">
                                        {props?.elem?.total_count - props?.elem?.pending_count + "/" + props?.elem?.total_count}
                                    </span>
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="flex main basis-3/12  justify-center items-center text-center">
                        <div className="px-0">
                            <button
                                className={`border-[1px]  rounded-[5px] my-auto text-[12px] border-transparent px-2 h-[24px] text-white ${props?.elem?.task_priority === "High"
                                    ? "bg-[rgba(255,114,94,1)]"
                                    : props?.elem?.task_priority === "Low"
                                        ? "bg-[rgba(0,177,162,1)]"
                                        : props?.elem?.task_priority === "Medium"
                                            ? "bg-[rgba(251,189,3,1)]"
                                            : props?.elem?.task_priority === "Urgent"
                                                ? "bg-[rgba(215,13,13,1)]"
                                                : ""
                                    }`}
                            >
                                {props?.elem?.task_priority}
                            </button>
                        </div>
                    </div>
                    <div className="flex main basis-3/12  item-center text-center">
                        <span className="my-auto mx-auto ">{props?.elem?.due_date}</span>
                    </div>
                    <div className="flex main basis-3/12 item-center ">
                        <span className="my-auto mx-auto">
                            {props?.elem?.task_status == 1
                                ? "In Progress"
                                : props?.elem?.task_status == 2
                                    ? "Completed"
                                    : props?.elem?.task_status == 4
                                        ? "Assign"
                                        : "Done"}
                        </span>
                    </div>
                    <div className="flex main basis-3/12  items-center justify-start">
                        {/* <div className="flex flex-initial ">
              <img src="/images/main/userNew.svg" alt="userNew" />
            </div> */}
                        <span className={`max-w-[200px] block truncate`} title={props?.elem?.exception_category}>
                            {props?.elem?.exception_category}
                        </span>
                    </div>
                    <div className="flex main basis-3/12  items-center justify-start">
                        {/* <div className="flex flex-initial ">
              <img src="/images/main/userNew.svg" alt="userNew" />
            </div> */}
                        <span className={`max-w-[150px] block truncate`} title={props?.elem?.display_name}>
                            {props?.elem?.display_name}
                        </span>
                    </div>
                </div>
            </>
        );
    };
    const dispatch = useDispatch();

    dateFrom = moment(new Date(fromDate)).format(dateFormat) == "Invalid date" ? "" : moment(new Date(fromDate)).format(dateFormat);
    dateTo = moment(new Date(toDate)).format(dateFormat) == "Invalid date" ? "" : moment(new Date(toDate)).format(dateFormat);
    const handleClickAwayCalendar = () => {
        setOpen(false);
    };
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            setIsOpen(false); // Close the dropdown when "Tab" key is pressed
        }
    };

    return (
        <>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Layout>
                    <Main>
                        <div className="flex justify-between items-center xl:mb-0">
                            <div className={`flex space-x-2 mb-4 xl:mb-0 text-${currentMode}-copy`}>
                                <div>
                                    <h4 className={`text-2xl font-interSb mb-[13px]`}>My Task</h4>
                                    <p className={`text-xs xl:mb-[13px]`}>
                                        You have {headerInfo?.todayTask} tasks for today and {headerInfo?.overdue} tasks are overdue now.
                                    </p>
                                </div>
                            </div>

                            {/* <ButtonGroup>
              <Button
                title="To invite new Users"
                onClick={() => setAddTask(true)}
                className={`bg-light-20 rounded-l-[8px] px-3 py-2 font-interM text-sm flex items-center border-[0] text-white`}
              >
                <img
                  className="mr-1 "
                  src="/images/common/plus.svg"
                  alt="plus"
                />
                {"  "}
                Create Task
              </Button>
              <Button
                title="To invite new Users"
                onClick={(e) =>
                  childCompRef.current.handleClick("bottom-end", 1, e)
                }
                className={`border-l-[1px] border-l-530 bg-light-20 rounded-r-[8px] px-3 py-2 font-interM text-sm flex items-center justify-center border-0 text-white`}
              >
                <img
                  className="color-white py-auto"
                  src="/images/main/chevron-down-white.svg"
                />
              </Button>
            </ButtonGroup> */}

                            <TogglePopup ref={childCompRef} menuList={filterMenuList} selectedItemChange={onFilterItems} />
                        </div>
                        <Card
                            className={`h-[calc(100vh_-_13.5rem)] overflow-y-auto bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700`}
                        >
                            {myTaskConfig?.fetching ? (
                                <Loader className="h-[calc(100vh_-_17rem)] " />
                            ) : (
                                <>
                                    <div className="flex middleFlex py-[30px] px-[30px] items-center justify-between">
                                        <div className="flex items-center space-x-2">
                                            <div
                                                className={`flex items-center border-[1px] border-${currentMode}-840 pl-[15px] pr-[10] py-[8px] rounded-[8px]  `}
                                            >
                                                <img className="mr-2 convertWhite" src="/images/invoice/filter-grp.svg" alt="filter-grp" />
                                                <span>Filter by:</span>
                                                <FormControl
                                                    sx={{
                                                        minWidth: 127,
                                                    }}
                                                >
                                                    <Select
                                                        onChange={filterChange}
                                                        name="filter"
                                                        className="insideFilter Org"
                                                        defaultValue="default"
                                                        disabled={taskList?.length == 0}
                                                        // value={selected}
                                                        value={selected == undefined ? "default" : selected}
                                                        open={isOpen}
                                                        onClose={handleClose}
                                                        onOpen={handleOpen}
                                                        onKeyDown={handleKeyDown}
                                                        MenuProps={{
                                                            sx: {
                                                                "& .MuiMenu-paper": {
                                                                    backgroundColor: dropdownColor,
                                                                    color: dropdownText,
                                                                    boxShadow: ` ${currentMode !== "dark" ? "0px 4px 20px rgba(238, 238, 238, 0.5)" : ""
                                                                        }`,
                                                                    // border: "1px solid rgba(223, 225, 226, 0.56)",
                                                                    borderRadius: "8px",
                                                                    border: `1px solid ${currentMode == "dark" ? "rgba(85,85,85,0.4)" : "rgba(223, 225, 226, 0.56)"
                                                                        }`,
                                                                },
                                                                "& .MuiMenuItem-root:hover": {
                                                                    backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                                },
                                                                "& .Mui-selected": {
                                                                    backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                                },
                                                            },
                                                        }}
                                                        IconComponent={() => (
                                                            <img
                                                                className="absolute right-[15px] opacity-50 pointer-events-none"
                                                                src={
                                                                    currentMode !== "dark"
                                                                        ? `/images/header/chevronDown.svg`
                                                                        : `/images/header/chevronDown-white.svg`
                                                                }
                                                            />
                                                        )}
                                                        SelectDisplayProps={{
                                                            style: {
                                                                color: `${currentMode !== "dark" ? `#000` : `#ECECEC`}`,

                                                                paddingTop: 1,
                                                                display: "flex",
                                                                paddingBottom: 1,
                                                                paddingLeft: "5px",
                                                                border: 0,
                                                                paddingRight: "30px",
                                                            },
                                                        }}
                                                        displayEmpty
                                                    >
                                                        {/* <MenuItem value="">
                          <img
                            className="mr-2"
                            src="/images/invoice/user-plusblk.svg"
                            alt="user-plusblk"
                          />{" "}
                          Default
                        </MenuItem> */}
                                                        <MenuItem
                                                            value="default"
                                                        // sx={{
                                                        //     display: "none",
                                                        // }}
                                                        >
                                                            Select
                                                        </MenuItem>
                                                        <MenuItem value="Assignee">
                                                            <img
                                                                className="mr-2 convertWhite"
                                                                src="/images/invoice/user-plusblk.svg"
                                                                alt="user-plusblk"
                                                            />{" "}
                                                            Assignee
                                                        </MenuItem>
                                                        <MenuItem value="Due Date">
                                                            <img className="mr-2 convertWhite" src="/images/invoice/calendar.svg" alt="calendar" />{" "}
                                                            Due Date
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div
                                                className={`flex items-center border-[1px] border-${currentMode}-840 px-[15px] py-[9px] rounded-[8px]`}
                                            >
                                                <img className="mr-2 convertWhite" src="/images/invoice/3-layers.svg" alt="filter-grp" />
                                                {selected == "Due Date" ? (
                                                    <div className="basis-[32%] xl:basis-[20%]">
                                                        <Button
                                                            className={`font-interR truncate  text-${currentMode}-copy text-left  w-full`}
                                                            onClick={handleClickCalendar("bottom-start")}
                                                        >
                                                            {dateFrom == "" && dateTo == "" ? "Select a date range" : dateFrom + " to " + dateTo}
                                                        </Button>
                                                        <ClickAwayListener
                                                            mouseEvent="onMouseDown"
                                                            touchEvent="onTouchStart"
                                                            onClickAway={handleClickAwayCalendar}
                                                        >
                                                            <Popper
                                                                open={open}
                                                                anchorEl={anchorElCalendar}
                                                                placement={placementCalendar}
                                                                className="z-[1300] filterPop"
                                                            >
                                                                <Paper>
                                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                        <ul
                                                                            className={`space-y-4 px-[15px] py-4 bg-${currentMode}-card  border-${currentMode}-700 `}
                                                                        >
                                                                            <li className="flex justify-between">
                                                                                <DesktopDatePicker
                                                                                    selectsStart
                                                                                    inputFormat={dateFormat}
                                                                                    className="border-0"
                                                                                    components={{
                                                                                        OpenPickerIcon: AccessIcon,
                                                                                    }}
                                                                                    value={fromDate}
                                                                                    label="From"
                                                                                    minDate={new Date(1999, 12)}
                                                                                    maxDate={toDate}
                                                                                    //  maxDate={new Date(lastDateOfYear)}
                                                                                    name="invoice_date_from"
                                                                                    onChange={handleValue}
                                                                                    renderInput={(params) => (
                                                                                        <TextField
                                                                                            sx={{
                                                                                                "& .MuiInputBase-input": {
                                                                                                    color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`
                                                                                                        }`,
                                                                                                },
                                                                                            }}
                                                                                            placeholder={params.inputProps?.placeholder?.replace(
                                                                                                "From"
                                                                                            )}
                                                                                            {...params}
                                                                                        />
                                                                                    )}
                                                                                />
                                                                            </li>
                                                                            <li
                                                                                className={`flex justify-between bg-${currentMode}-card  border-${currentMode}-700 `}
                                                                            >
                                                                                <DesktopDatePicker
                                                                                    selectsEnd
                                                                                    className={`border-0 bg-${currentMode}-card `}
                                                                                    inputFormat={dateFormat}
                                                                                    name="invoice_date_to"
                                                                                    label="To"
                                                                                    components={{
                                                                                        OpenPickerIcon: AccessIcon,
                                                                                    }}
                                                                                    value={toDate}
                                                                                    onChange={handleToDate}
                                                                                    minDate={fromDate}
                                                                                    // maxDate={new Date(lastDateOfYear)}
                                                                                    renderInput={(params) => (
                                                                                        <TextField
                                                                                            sx={{
                                                                                                "& .MuiInputBase-input": {
                                                                                                    color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`
                                                                                                        }`,
                                                                                                },
                                                                                            }}
                                                                                            placeholder={params.inputProps?.placeholder?.replace(
                                                                                                "To"
                                                                                            )}
                                                                                            {...params}
                                                                                        />
                                                                                    )}
                                                                                />
                                                                            </li>
                                                                        </ul>
                                                                    </LocalizationProvider>
                                                                </Paper>
                                                            </Popper>
                                                        </ClickAwayListener>
                                                    </div>
                                                ) : (
                                                    <FormControl
                                                        sx={{
                                                            minWidth: 115,
                                                        }}
                                                    >
                                                        <Select
                                                            name="filter"
                                                            onChange={handleSelectedValue}
                                                            className="insideFilter Org"
                                                            value={[]}
                                                            defaultValue="default"
                                                            disabled={selected == "default" || selected == undefined}
                                                            multiple
                                                            MenuProps={{
                                                                sx: {
                                                                    "& .MuiMenu-paper": {
                                                                        backgroundColor: dropdownColor,
                                                                        color: dropdownText,
                                                                        boxShadow: ` ${currentMode !== "dark" ? "0px 4px 20px rgba(238, 238, 238, 0.5)" : ""
                                                                            }`,
                                                                        border: `1px solid ${currentMode == "dark" ? "rgba(85,85,85,0.4)" : "rgba(223, 225, 226, 0.56)"
                                                                            }`,
                                                                        borderRadius: "8px",
                                                                        maxWidth: "30rem !important",
                                                                    },
                                                                    "& .MuiMenuItem-root:hover": {
                                                                        backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                                    },
                                                                    "& .Mui-selected": {
                                                                        backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                                    },
                                                                },
                                                            }}
                                                            IconComponent={() => (
                                                                <img
                                                                    className="absolute right-[0px] opacity-50 pointer-events-none"
                                                                    src={
                                                                        currentMode !== "dark"
                                                                            ? `/images/header/chevronDown.svg`
                                                                            : `/images/header/chevronDown-white.svg`
                                                                    }
                                                                    alt="chevronDown"
                                                                />
                                                            )}
                                                            renderValue={(value) => {
                                                                if (selected == "Assignee") {
                                                                    const check = valuecheck?.find((item) => item?.isCheck == true);
                                                                    if (check == undefined) {
                                                                        return "Select";
                                                                    } else {
                                                                        let value = 0;
                                                                        valuecheck?.map((item) => {
                                                                            if (item?.isCheck) {
                                                                                value++;
                                                                            }
                                                                        });

                                                                        return "Selected : " + value;
                                                                    }
                                                                } else if (selected == "Due Date") {
                                                                    if (fromDate == "" && toDate == "") {
                                                                        return "Select";
                                                                    } else {
                                                                        return fromDate || toDate
                                                                            ? moment(new Date(fromDate)).format(dateFormat) +
                                                                            " to " +
                                                                            moment(new Date(toDate)).format(dateFormat)
                                                                            : "";
                                                                    }
                                                                } else if (selected == "default" || selected == undefined) {
                                                                    return "Select";
                                                                }
                                                            }}
                                                            SelectDisplayProps={{
                                                                style: {
                                                                    paddingTop: 1,
                                                                    display: "flex",
                                                                    paddingBottom: 1,
                                                                    width: "8rem",
                                                                    paddingLeft: "5px",
                                                                    border: 0,
                                                                    paddingRight: "10px",
                                                                    color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                                },
                                                            }}
                                                            displayEmpty
                                                        >
                                                            <MenuItem
                                                                value="default"
                                                                sx={{
                                                                    display: "none",
                                                                }}
                                                            >
                                                                Select
                                                            </MenuItem>
                                                            {selected == "Due Date" ? " " : options}
                                                        </Select>
                                                    </FormControl>
                                                )}
                                            </div>
                                            <div>
                                                <img
                                                    tabIndex="1"
                                                    onClick={resetFilter}
                                                    className="cursor-pointer"
                                                    src="/images/common/refresh.svg"
                                                    alt="close"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={`xl:max-w-none middleSpaceup max-w-[135px] border-${currentMode}-840 border-[1px] pl-[5px] pr-0 py-[8px] items-center rounded-[8px]`}
                                        >
                                            <FormControl
                                                sx={{
                                                    minWidth: 127,
                                                }}
                                            >
                                                <Select
                                                    onChange={changeView}
                                                    name="filter"
                                                    fullWidth
                                                    className="insideFilter Org"
                                                    value={viewType}
                                                    MenuProps={{
                                                        sx: {
                                                            "& .MuiMenu-paper": {
                                                                backgroundColor: dropdownColor,
                                                                color: dropdownText,
                                                                boxShadow: ` ${currentMode !== "dark" ? "0px 4px 20px rgba(238, 238, 238, 0.5)" : ""
                                                                    }`,
                                                                border: `1px solid ${currentMode == "dark" ? "rgba(85,85,85,0.4)" : "rgba(223, 225, 226, 0.56)"
                                                                    }`,
                                                                borderRadius: "8px",
                                                            },
                                                            "& .MuiMenuItem-root:hover": {
                                                                backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                            },
                                                            "& .Mui-selected": {
                                                                backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                            },
                                                        },
                                                    }}
                                                    IconComponent={() => (
                                                        <img
                                                            className="absolute right-[15px] opacity-50 pointer-events-none"
                                                            src={
                                                                currentMode !== "dark"
                                                                    ? `/images/header/chevronDown.svg`
                                                                    : `/images/header/chevronDown-white.svg`
                                                            }
                                                            alt="chevronDown"
                                                        />
                                                    )}
                                                    SelectDisplayProps={{
                                                        style: {
                                                            paddingTop: 1,
                                                            display: "flex",
                                                            paddingBottom: 1,
                                                            paddingLeft: "5px",
                                                            border: 0,
                                                            paddingRight: "30px",
                                                            color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                        },
                                                    }}
                                                    displayEmpty
                                                >
                                                    <MenuItem value="List">
                                                        <img className="mr-2 convertWhite" src="/images/common/list-blk.svg" alt="list-blk" /> List
                                                    </MenuItem>
                                                    <MenuItem value="Kanban">
                                                        <img className="mr-2 convertWhite" src="/images/common/kanban-blk.svg" alt="kanban-blk" />{" "}
                                                        Kanban
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    {viewType == "List" && (
                                        <div
                                            className={`bg-${currentMode}-450 w-[1400px] xl:w-full xl:overflow-x-auto xl:mx-[-30px] font-interM flex  z-[2] sticky left-0 right-0 top-0 accord_head`}
                                        >
                                            <div className="basis-[80%]">Task Name</div>
                                            <div className="flex basis-3/12 justify-center text-center">
                                                {/* <img className="mr-2 convertWhite" src="/images/main/priority-icon.svg" alt="user-plusblk" /> */}
                                                Priority
                                            </div>
                                            <div className="flex basis-3/12 justify-center text-center ">
                                                {/* <img className="mr-2 convertWhite" src="/images/invoice/calendar.svg" alt="calendar" /> */}
                                                Due Date
                                            </div>
                                            <div className="flex basis-3/12 justify-center text-center ">
                                                {/* <img
                                                    className="mr-2 convertWhite w-[18px] h-[18px]"
                                                    src="/images/inbox/status1.svg"
                                                    alt="user-plusblk"
                                                /> */}
                                                Status
                                            </div>
                                            <div className="flex basis-3/12 items-center  text-center">
                                                {/* <img className="mr-2 convertWhite" src="/images/invoice/user-plusblk.svg" alt="user-plusblk" /> */}
                                                Exception
                                            </div>
                                            <div className="flex basis-3/12 items-center  text-center">
                                                {/* <img className="mr-2 convertWhite" src="/images/invoice/user-plusblk.svg" alt="user-plusblk" /> */}
                                                Assigned To/By
                                            </div>
                                        </div>
                                    )}
                                    {myTaskList?.pendingWithMe?.length === 0 &&
                                        myTaskList?.pendingWithTeam?.length === 0 &&
                                        myTaskList?.awaitingResponse?.length === 0 ? (
                                        <div className=" text-center">
                                            <div className="mx-auto my-5">
                                                <img className="mt-[100px] mx-auto" src="/images/common/delivery.png" alt="delivery" />
                                            </div>
                                            <h4 className="font-interSb">No Data found</h4>
                                        </div>
                                    ) : (
                                        viewType == "List" && (
                                            <div className="w-[1400px] xl:w-full overflow-x-auto" id="ListView">
                                                {myTaskList?.pendingWithMe.length > 0 && (
                                                    <Accordion expanded={expandMe === "1"} onChange={handleChangeMe("1")}>
                                                        <AccordionSummary
                                                            aria-controls="panel1d-content"
                                                            id="panel1d-header"
                                                            className={`bg-${currentMode}-card text-${currentMode}-copy `}
                                                        >
                                                            <span className="font-interM">Pending with me</span>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <div className={`bg-${currentMode}-card ${currentMode == "dark" ? "text-white" : ""} `}>
                                                                {myTaskList?.pendingWithMe?.length !== 0 ? (
                                                                    myTaskList?.pendingWithMe?.map((element) => <NormalTask elem={element} />)
                                                                ) : (
                                                                    <div className="text-center">
                                                                        <div className="mx-auto my-5">
                                                                            <img
                                                                                className="mx-auto"
                                                                                src="/images/common/delivery.png"
                                                                                alt="delivery"
                                                                            />
                                                                        </div>
                                                                        <h4 className="font-interSb">No Data found</h4>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                )}
                                                {myTaskList?.pendingWithTeam.length > 0 && (
                                                    <Accordion expanded={expandTeam === "2"} onChange={handleChangeTeam("2")}>
                                                        <AccordionSummary
                                                            aria-controls="panel1d-content"
                                                            id="panel1d-header"
                                                            className={`bg-${currentMode}-card text-${currentMode}-copy `}
                                                        >
                                                            <span className="font-interM">Pending with team</span>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <div className={`bg-${currentMode}-card ${currentMode == "dark" ? "text-white" : ""} `}>
                                                                {myTaskList?.pendingWithTeam?.length !== 0 ? (
                                                                    myTaskList?.pendingWithTeam?.map((element) => <NormalTask elem={element} />)
                                                                ) : (
                                                                    <div className="text-center">
                                                                        <div className="mx-auto my-5">
                                                                            <img
                                                                                className="mx-auto"
                                                                                src="/images/common/delivery.png"
                                                                                alt="delivery"
                                                                            />
                                                                        </div>
                                                                        <h4 className="font-interSb">No Data found</h4>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                )}
                                                {myTaskList?.awaitingResponse.length > 0 && (
                                                    <Accordion expanded={expandAwait === "3"} onChange={handleChangeAwait("3")}>
                                                        <AccordionSummary
                                                            aria-controls="panel1d-content"
                                                            id="panel1d-header"
                                                            className={`bg-${currentMode}-card text-${currentMode}-copy `}
                                                        >
                                                            <span className="font-interM">Awaiting response</span>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <div className={`bg-${currentMode}-card ${currentMode == "dark" ? "text-white" : ""} `}>
                                                                {/* {demoMe &&
                      demoMe?.map((element) => {
                        return (
                          <>
                            {element?.subtask_id !== null ? (
                              <SubTaskAccordian
                                elem={element}
                                index={demoMe?.indexOf(element)}
                              />
                            ) : (
                              <NormalTask elem={element} subTask={false} />
                            )}
                          </>
                        );
                      })} */}
                                                                {myTaskList?.awaitingResponse?.length !== 0 ? (
                                                                    myTaskList?.awaitingResponse?.map((element) => <NormalTask elem={element} />)
                                                                ) : (
                                                                    <div className="text-center">
                                                                        <div className="mx-auto my-5">
                                                                            <img
                                                                                className="mx-auto"
                                                                                src="/images/common/delivery.png"
                                                                                alt="delivery"
                                                                            />
                                                                        </div>
                                                                        <h4 className="font-interSb">No Data found</h4>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                )}
                                            </div>
                                        )
                                    )}
                                    {viewType == "Kanban" && <Kanban elem={myTaskList} getTaskView={getTaskView} />}
                                </>
                            )}
                        </Card>
                        {viewTask && (
                            <>
                                {ReactDOM.createPortal(
                                    <ViewTask
                                        isOpened={viewTask}
                                        handleClose={() => setViewTask(false)}
                                        idParse={getId}
                                        className={viewTask && "Show"}
                                        changeTaskStatus={changeTaskStatus}
                                    />,
                                    document.getElementById("Modal-root")
                                )}
                            </>
                        )}
                    </Main>
                    <Footer />
                </Layout>
            </ErrorBoundary>
        </>
    );
};
export default ListTable;
