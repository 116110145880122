import Button from "../../components/Button";
import Input from "../../components/Input";
import { CHANGE_PASSWORD_RESET } from "../../redux/types/types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import UseForm from "../../Hooks/useForm";
import { Link, useNavigate } from "react-router-dom";
import { changepasswordRequest, getCurrentUser, LogoutRequest } from "../../redux/actions/auth";
import { validateChangepassword } from "../../Hooks/validate";
import MessageBox from "../../components/UI/Alert";
import SuccessMessageBox from "../../components/UI/Alert/sucess";
import { encryptId } from "../../utils/helpers";
import { Popper } from "@mui/material";
import Cookies from "js-cookie";
import { useContext } from "react";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";

const successMsg = "New Password is updated Successfully.";
const errormsg = "Something went wrong please contact admin";
export default function ChangePassword(props) {
    const { currentMode } = useContext(ThemeContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const [pswrdValue, setPswrdValue] = useState("");
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [isRevealPasswd, setIsRevealPasswd] = useState(false);
    const [isRevealPassword, setIsRevealPassword] = useState(false);
    const [lengthCheck, setLengthCheck] = useState(false);
    const [lowercase, checkLowerCase] = useState(false);
    const [upperCase, checkUpperCase] = useState(false);
    const [splCase, checkSplCase] = useState(false);
    const [numCase, checkNumCase] = useState(false);
    const navigate = useNavigate();
    const { handleChange, handleSubmit, values, errors } = UseForm(submit, validateChangepassword);
    const disableButton = (!lengthCheck || !lowercase || !upperCase || !splCase || !numCase) && open;
    function submit() {
        const currentUser = getCurrentUser();
        dispatch(
            changepasswordRequest({
                emailaddress: currentUser.emailaddress,
                oldpassword: encryptId(values?.oldpassword?.trim()),
                newpassword: encryptId(values?.newpassword?.trim()),
            })
        );
    }
    useEffect(() => {
        checkPass();
    }, [values?.newpassword]);
    const dispatch = useDispatch();
    const checkPass = () => {
        if (values?.newpassword?.length >= 8) {
            setLengthCheck(true);
        } else {
            setLengthCheck(false);
        }
        if (/[a-z]/.test(values?.newpassword)) {
            checkLowerCase(true);
        } else {
            checkLowerCase(false);
        }
        if (/[A-Z]/.test(values?.newpassword)) {
            checkUpperCase(true);
        } else {
            checkUpperCase(false);
        }
        if (/[@$!%*?&#,-.?<>/|'";:_+={}`~^()]/.test(values?.newpassword)) {
            checkSplCase(true);
        } else {
            checkSplCase(false);
        }
        if (/[0-9]/.test(values?.newpassword)) {
            checkNumCase(true);
        } else {
            checkNumCase(false);
        }
    };
    const response = useSelector((state) => state?.changepasswordState);
    const { data, fetching, error, requestComplete } = useSelector((state) => state?.changepasswordState);

    useEffect(() => {
        return () => {
            dispatch({ type: CHANGE_PASSWORD_RESET });
        };
    }, [dispatch, navigate]);
    if (requestComplete && data?.status) {
        setTimeout(function () {
            let myAdvanceItem = localStorage.getItem("advanceListOption");
            let myItem = localStorage.getItem("customOptions");
            let intervalItem = localStorage.getItem("PlayInterval");
            let newItem = localStorage.getItem("ageingOption");
            let secret = localStorage.getItem("secretid");
            let theme = localStorage.getItem("themeMode");
            localStorage.clear();
            Cookies.remove("jwt");
            localStorage.setItem("themeMode", theme);
            localStorage.setItem("advanceListOption", myAdvanceItem);
            localStorage.setItem("customOptions", myItem);
            localStorage.setItem("PlayInterval", intervalItem);
            localStorage.setItem("ageingOption", newItem);
            localStorage.setItem("secretid", secret);
            dispatch(LogoutRequest());
            navigate("/");
        }, 2000);
    }
    // if (props?.index == 2) {
    //     var textarea = document?.getElementById("chgepswrdId");
    //     textarea?.focus();
    // }
    return (
        <>
            {requestComplete && data?.status && <SuccessMessageBox success={successMsg} />}
            {requestComplete && !data?.status && <MessageBox error={data.message} />}
            {error && !data?.status && <MessageBox error={errormsg} />}
            <div className="w-3/4">
                <h3 className="text-2xl font-interM mb-5 text-light-20">Change Password</h3>
                <form action="" onSubmit={handleSubmit} noValidate autoComplete="off">
                    <div className="form-control">
                        <label htmlFor="" className="text-light-40 text-sm mb-2">
                            <span className="text-[red] mr-1">*</span> <span className={`text-${currentMode}-copy`}>Current Password</span>
                        </label>
                        <div className="relative 2xl:w-[461px]">
                            {
                                <Popper
                                    id={id}
                                    open={open}
                                    className={`bg-${currentMode}-card text-${currentMode}-copy rounded-[10px] p-[20px]  ${currentMode !== "dark" ? "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]" : ""
                                        }  border-[1px]  border-${currentMode}-700`}
                                    anchorEl={anchorEl}
                                    modifiers={[
                                        {
                                            name: "offset",
                                            options: {
                                                offset: [350, -50],
                                            },
                                        },
                                    ]}
                                >
                                    <ul className="font-interM">
                                        <li className="flex space-x-2 mb-1 items-center">
                                            <img
                                                src={`${lowercase ? `/images/common/password-tick-green.svg` : `/images/common/password-tick.svg`}`}
                                                alt="password-tick"
                                            />
                                            <span>One lowercase character</span>
                                        </li>
                                        <li className="flex space-x-2 mb-1 items-center">
                                            <img
                                                src={`${upperCase ? `/images/common/password-tick-green.svg` : `/images/common/password-tick.svg`}`}
                                                alt="password-tick"
                                            />
                                            <span>One uppercase character</span>
                                        </li>
                                        <li className="flex space-x-2 mb-1 items-center">
                                            <img
                                                src={`${splCase ? `/images/common/password-tick-green.svg` : `/images/common/password-tick.svg`}`}
                                                alt="password-tick"
                                            />
                                            <span>One special character</span>
                                        </li>
                                        <li className="flex space-x-2 mb-1 items-center">
                                            <img
                                                src={`${numCase ? `/images/common/password-tick-green.svg` : `/images/common/password-tick.svg`}`}
                                                alt="password-tick"
                                            />
                                            <span>One number</span>
                                        </li>
                                        <li className="flex space-x-2 mb-1 items-center">
                                            <img
                                                src={`${lengthCheck ? `/images/common/password-tick-green.svg` : `/images/common/password-tick.svg`}`}
                                                alt="password-tick"
                                            />
                                            <span>Minimum 8 characters</span>
                                        </li>
                                    </ul>
                                </Popper>
                            }
                            <Input
                                tabIndex="1"
                                id="chgepswrdId"
                                className={`border-[1px] border-${currentMode}-840 bg-${currentMode}-810 rounded-[8px] px-[20px] py-2 w-full`}
                                type={isRevealPasswd ? "text" : "password"}
                                name="oldpassword"
                                autoFocus
                                autoComplete="off"
                                onChange={handleChange}
                                value={values.oldpassword}
                                placeholder="&bull; &bull; &bull; &bull; &bull; &bull; &bull; &bull; &bull; &bull; &bull;"
                            />
                            <Link
                                tabindex="-1"
                                onClick={() => setIsRevealPasswd((prevState) => !prevState)}
                                className="absolute cursor-pointer top-[57%] translate-y-[-57%] right-[26px]"
                            >
                                <img
                                    title={isRevealPasswd ? "Hide password" : "Show password"}
                                    src={isRevealPasswd ? `/images/common/eyeOpen.svg` : `/images/common/eyeClose.svg`}
                                />
                            </Link>
                        </div>
                        {errors?.oldpassword && <p className="text-light-50 text-xs">{errors?.oldpassword}</p>}
                    </div>
                    <div className="form-control">
                        <label htmlFor="" className="text-light-40 text-sm mb-2">
                            <span className="text-[red] mr-1">*</span>
                            <span className={`text-${currentMode}-copy`}> New Password</span>
                        </label>
                        <div className="relative 2xl:w-[461px]">
                            <Input
                                tabIndex="1"
                                className={`border-[1px] border-${currentMode}-840 rounded-[8px] px-[20px] py-2 w-full bg-${currentMode}-810`}
                                type={isRevealPwd ? "text" : "password"}
                                name="newpassword"
                                autoComplete="off"
                                onFocus={handleClick}
                                placeholder="&bull; &bull; &bull; &bull; &bull; &bull; &bull; &bull; &bull; &bull; &bull;"
                                value={values?.newpassword}
                                onChange={handleChange}
                            />
                            <Link
                                tabindex="-1"
                                onClick={() => setIsRevealPwd((prevState) => !prevState)}
                                className="absolute cursor-pointer top-[57%] translate-y-[-57%] right-[26px]"
                            >
                                <img
                                    title={isRevealPwd ? "Hide password" : "Show password"}
                                    src={isRevealPwd ? `/images/common/eyeOpen.svg` : `/images/common/eyeClose.svg`}
                                />
                            </Link>
                        </div>

                        {errors?.newpassword && <p className="text-light-50 text-xs">{errors?.newpassword}</p>}
                    </div>
                    <div className="form-control">
                        <label htmlFor="" className="text-light-40 text-sm mb-2">
                            <span className="text-[red] mr-1">*</span>
                            <span className={`text-${currentMode}-copy`}> Confirm Password</span>
                        </label>
                        <div className="relative 2xl:w-[461px]">
                            <Input
                                tabIndex="1"
                                className={`border-[1px] border-${currentMode}-840 rounded-[8px] px-[20px] py-2 w-full bg-${currentMode}-810`}
                                type={isRevealPassword ? "text" : "password"}
                                name="confirmpassword"
                                autoComplete="off"
                                placeholder="&bull; &bull; &bull; &bull; &bull; &bull; &bull; &bull; &bull; &bull; &bull;"
                                onChange={handleChange}
                            />

                            <Link
                                tabindex="-1"
                                onClick={() => setIsRevealPassword((prevState) => !prevState)}
                                className="absolute cursor-pointer top-[57%] translate-y-[-57%] right-[26px]"
                            >
                                <img
                                    title={isRevealPassword ? "Hide password" : "Show password"}
                                    src={isRevealPassword ? `/images/common/eyeOpen.svg` : `/images/common/eyeClose.svg`}
                                />
                            </Link>
                        </div>
                        {errors?.confirmpassword && <p className="text-light-50 text-xs">{errors?.confirmpassword}</p>}
                    </div>
                    <Button
                        title="To Change the Password"
                        disabled={fetching || (requestComplete && data?.status) || disableButton}
                        type="submit"
                        className="bg-light-20 flex text-base font-interR py-[.5rem] px-[15px] rounded-[8px] text-white"
                    >
                        {`Change Password`}
                    </Button>
                </form>
            </div>
        </>
    );
}
