import { React, useContext, useEffect, useState } from "react";
import { Accordion, Checkbox, createTheme } from "@mui/material";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import { DaysRange } from "../../../utils/constants";
import TimePickerTrigger from "./TimePickerTrigger";

const Preference = (props) => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const businessSetting = props?.businessSetting;
    const setBusinessSetting = props?.setBusinessSetting;
    const handleChangePref = props?.handleChangePref;
    const expandPref = props?.expandPref;
    const AccordionSummary = props?.AccordionSummary;
    const AccordionDetails = props?.AccordionDetails;

    const handleCheckWorkingHours = (event, name) => {
        if (name === "isworking_hours") {
            setBusinessSetting((prevVal) => ({
                ...prevVal,
                isworking_hours: event.target.checked,
                display_endtime: "",
                display_starttime: "",
            }));
            props?.setExcepByOrg((prevVal) => ({
                ...prevVal,
                bussiness_settings: {
                    ...prevVal?.bussiness_settings,
                    isworking_hours: event.target.checked,
                    display_endtime: "",
                    display_starttime: "",
                },
            }));
        } else if (name === "isworking_days") {
            setBusinessSetting((prevVal) => ({
                ...prevVal,
                isworking_days: event.target.checked,
                working_days_array: [],
            }));
            props?.setExcepByOrg((prevVal) => ({
                ...prevVal,
                bussiness_settings: {
                    ...prevVal?.bussiness_settings,
                    isworking_days: event.target.checked,
                    working_days_array: [],
                },
            }));
        }
    };
    const theme = createTheme({
        palette: {
            primary: {
                main: "#028C78", // Change this to your desired primary color
            },
        },
    });
    const handleDays = (e, value, text) => {
        if (e?.target?.checked) {
            setBusinessSetting((prevVal) => {
                const updatedArray = [...prevVal.working_days_array, value].sort((a, b) => a - b);
                return {
                    ...prevVal,
                    working_days_array: updatedArray,
                };
            });
            props?.setExcepByOrg((prevVal) => {
                const updatedArray = [...prevVal.bussiness_settings.working_days_array, value].sort((a, b) => a - b);
                return {
                    ...prevVal,
                    bussiness_settings: {
                        ...prevVal?.bussiness_settings,
                        working_days_array: updatedArray,
                    },
                };
            });
        } else {
            setBusinessSetting((prevVal) => {
                const updatedArray = prevVal.working_days_array.filter((day) => day !== value).sort((a, b) => a - b);
                return {
                    ...prevVal,
                    working_days_array: updatedArray,
                };
            });
            props?.setExcepByOrg((prevVal) => {
                const updatedArray = prevVal.bussiness_settings.working_days_array.filter((day) => day !== value).sort((a, b) => a - b);
                return {
                    ...prevVal,
                    bussiness_settings: {
                        ...prevVal?.bussiness_settings,
                        working_days_array: updatedArray,
                    },
                };
            });
        }
    };

    return (
        <div className={`mt-[20px] px-[20px]`}>
            <Accordion
                sx={{
                    "&.MuiPaper-root": {
                        boxShadow: currentMode == "dark" ? "" : "0px 4px 10px -3px rgb(140 144 151 / 50%) !important",
                        border: `1px solid ${currentMode == "dark" ? "#333" : "#8C9097)"} !important`,
                        borderRadius: "8px",
                    },
                }}
                expanded={expandPref === "1"}
                onChange={handleChangePref("1")}
            >
                <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    className={`${expandPref == 0 ? `bg-${currentMode}-card` : `bg-${currentMode}-1420`} rounded-[8px] text-${currentMode}-copy `}
                >
                    <img src="/images/Exception-Automation/prefrences.svg" className="convertWhite"></img>{" "}
                    <span className="font-interM ml-3 ">Preferences</span>
                </AccordionSummary>
                <AccordionDetails>
                    <div className={`text-justify ml-[20px] text-${currentMode}-copy`}>
                        <div className="grid grid-cols-1 gap-4">
                            <p className="text-xs mt-[20px]">(Set business hours to send email reminders.)</p>
                        </div>
                        <div className="flex ">
                            {/* <Checkbox
                                checked={businessSetting?.isworking_hours}
                                onChange={(event) => {
                                    handleCheckWorkingHours(event, "isworking_hours");
                                }}
                                name="isworking_hours"
                                size="small"
                                sx={{
                                    color: "rgba(85,85,85,0.7)",
                                    "&.Mui-checked": {
                                        color: "#028C78",
                                    },
                                }}
                            /> */}
                            <p className="mt-[5px]">Working hours</p>
                        </div>
                        {businessSetting?.isworking_hours && (
                            <TimePickerTrigger
                                setExcepByOrg={props?.setExcepByOrg}
                                currentMode={currentMode}
                                theme={theme}
                                businessSetting={businessSetting}
                                setBusinessSetting={setBusinessSetting}
                            />
                        )}
                        <hr className={`mt-[10px] mb-[5px] border-${currentMode}-1240 w-[530px] mr-[10px]`} />
                        <div className="mb-[15px]">
                            <div className="flex">
                                {/* <Checkbox
                                    checked={businessSetting?.isworking_days}
                                    onChange={(event) => {
                                        handleCheckWorkingHours(event, "isworking_days");
                                    }}
                                    size="small"
                                    name="isworking_days"
                                    sx={{
                                        color: "rgba(85,85,85,0.7)",
                                        "&.Mui-checked": {
                                            color: "#028C78",
                                        },
                                    }}
                                /> */}
                                <p className="mt-[10px] ">Working Days</p>
                            </div>
                            {/* {businessSetting?.isworking_days && ( */}
                            <>
                                {DaysRange.map((el, i) => (
                                    <Checkbox
                                        key={el.value} // Add key prop here with a unique value
                                        checked={businessSetting?.working_days_array?.includes(el?.value)}
                                        onChange={(e) => handleDays(e, el?.value, el?.text)}
                                        className="p-[6px]"
                                        icon={<img src={el?.imgNormal} alt="tue-normal" />}
                                        checkedIcon={<img src={el?.imgActive} alt="tue-active" />}
                                    />
                                ))}

                                {businessSetting?.working_days_array && businessSetting?.working_days_array?.length > 0 && (
                                    <p className="mt-[20px] mr-[30px]">
                                        Reminders will be sent every{" "}
                                        {businessSetting?.working_days_array
                                            ?.map((dayValue) => DaysRange.find((day) => day.value === dayValue)?.text)
                                            .join(", ")}
                                        .
                                    </p>
                                )}
                            </>
                            {/* )} */}
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default Preference;
