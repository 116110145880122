import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { useSelector } from "react-redux";
import { LightTooltip } from "../../../components/LightTooltip";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Loader from "../../../components/Loader";
import Input from "../../../components/Input";
import DraggableItem from "../../../organisms/DraggableItem";

export const MapModal = ({ currentMode, setIsDragged }) => {
    const { columns, requestComplete, opened } = useSelector((state) => state?.getColumnState);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredAccordionItems, setFilteredAccordionItems] = useState([]);
    const [accordionItems, setAccordionItems] = useState(columns);
    const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
        border: "0 !important",
        background: "transparent",
        boxShadow: "none !important",
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
    }));
    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary
            expandIcon={
                <img
                    onClick={props?.onClick}
                    src={`${`${currentMode == "dark" ? "/images/header/chevronDown-white.svg" : "/images/invoice/chevron-down.svg"}`}`}
                />
            }
            {...props}
        />
    ))((data) => ({
        padding: "0 0 11px",
        minHeight: "35px",
        margin: 0,
        flexDirection: "row-reverse",
        "& .MuiAccordionSummary-expandIconWrapper": {
            marginRight: 10,
            position: "absolute",
            left: "0px",
            transform: "rotate(-270deg)",
            filter: `${currentMode === "dark" ? "filter: brightness(0) invert(1)" : "brightness(0) invert(0)"}`,
        },
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(360deg)",
            filter: "none",
        },
        "& .MuiAccordionSummary-content": {
            alignItems: "center",
            margin: 0,
        },
    }));
    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: 0,
        border: 0,
    }));

    const ifDragged = (isDragging) => {
        setIsDragged(isDragging);
    };

    const AccordionContent = ({ item, index, expanded, handleChange }) => {
        return (
            <Accordion className="unborderAccord" expanded={expanded?.includes(`panel${index}`)} onChange={handleChange} key={index}>
                <AccordionSummary aria-controls={`panel${item?.id}-content`} id={`panel${item?.id}-header`}>
                    <span className="ml-[17px] trunc-1 font-interM max-w-[190px]">{item?.label_name}</span>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="ml-[1.65rem]">
                        {item?.data?.map((checkbox, index) => (
                            <DraggableItem key={index} ifDragged={ifDragged} value={checkbox}>
                                <div className="flex items-center mb-[10px]">
                                    <LightTooltip title={checkbox?.field_display_name}>
                                        <span className="truncate max-w-[155px]">{checkbox?.field_display_name}</span>
                                    </LightTooltip>
                                </div>
                            </DraggableItem>
                        ))}
                    </div>
                </AccordionDetails>
            </Accordion>
        );
    };
    useEffect(() => {
        setExpandedPanels(opened || []);
    }, [opened]); // default on open all accrodion data set from reducer..
    const [expandedPanels, setExpandedPanels] = useState();
    const accordhandleChange = (panel) => (event, isExpanded) => {
        // Update the state to reflect the expanded state of the panels
        setExpandedPanels((prevExpanded) => {
            const newExpanded = [...prevExpanded];
            const panelIndex = newExpanded.indexOf(panel);

            if (isExpanded && panelIndex === -1) {
                newExpanded.push(panel);
            } else if (!isExpanded && panelIndex !== -1) {
                newExpanded.splice(panelIndex, 1);
            }

            return newExpanded;
        });
    };
    const filterAccordionItems = (text) => {
        const filteredResults = accordionItems
            ?.map((parent) => {
                const filteredData = parent?.data?.filter((item) => {
                    // Customize the filtering logic based on your requirements
                    return item?.field_display_name?.toLowerCase()?.includes(text?.toLowerCase());
                });

                // If at least one item passes the filter, include the parent object
                if (filteredData?.length > 0 || text === "") {
                    return {
                        ...parent,
                        data: filteredData,
                    };
                }

                // If no items pass the filter, exclude the parent object
                return null;
            })
            .filter(Boolean); // Filter out null values

        setFilteredAccordionItems(filteredResults);
    };
    const { requestComplete: req } = useSelector((state) => state?.getRespondAddTableState);
    const handleChange = (e) => {
        setSearchTerm(e.target.value);
        filterAccordionItems(e.target.value);
    };
    return (
        <>
            <div className={`bg-${currentMode}-1210 py-[9px] rounded-t-[10px] px-[20px]`}>
                <h5>Drag and Drop the field</h5>
            </div>
            <div className="px-[20px]">
                <div className={`bg-${currentMode}-230 flex  items-center justify-between my-[20px] py-[8px] px-[16px] min-w-[205px] rounded-[8px]`}>
                    <div className="flex-auto">
                        <Input
                            placeholder="Search..."
                            name="search"
                            className="border-0 w-full bg-transparent"
                            disableUnderline={true}
                            maxLength={250}
                            onChange={handleChange}
                            value={searchTerm}
                            autoComplete="off"
                        />
                    </div>
                    <div className="w-[16px]">
                        {searchTerm && searchTerm.length !== 0 ? (
                            <img
                                onClick={() => {
                                    setSearchTerm("");
                                    setFilteredAccordionItems([]);
                                }}
                                className="cursor-pointer"
                                src="/images/common/closeBlk.svg"
                                alt="close"
                            />
                        ) : (
                            <img className="w-[20px]" src="/images/header/search.svg" alt="search" />
                        )}
                    </div>
                </div>
            </div>
            <div className={`bg-${currentMode}-1210 py-[5px] px-[20px]`}>
                <span>Field</span>
            </div>

            <div className={`overflow-y-auto max-h-[40rem] px-[20px] py-[15px] border-t overflow-y-auto max-h-[500px] border-${currentMode}-1240`}>
                {requestComplete || req ? (
                    filteredAccordionItems?.length == 0 && searchTerm == "" ? (
                        accordionItems?.map((item, i) => (
                            <AccordionContent
                                key={i}
                                item={item}
                                index={i}
                                data={accordionItems}
                                expanded={expandedPanels}
                                handleChange={accordhandleChange(`panel${i}`)}
                            />
                        ))
                    ) : filteredAccordionItems?.length !== 0 ? (
                        filteredAccordionItems?.map((item, i) => (
                            <AccordionContent
                                key={i}
                                item={item}
                                data={filteredAccordionItems}
                                expanded={expandedPanels}
                                handleChange={accordhandleChange(`panel${i}`)}
                                index={i}
                            />
                        ))
                    ) : (
                        <p className="text-base text-center">No search results found!</p>
                    )
                ) : (
                    <Loader className="!h-[15rem]" />
                )}
            </div>
        </>
    );
};
