import Input from "../../../components/Input";
import { useState, useContext, useMemo, useEffect } from "react";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
const UserInputTrigger = (props) => {
    const { currentMode } = useContext(ThemeContext);
    const [userName, setUserName] = useState(props?.businessSetting?.username);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserName(value);
    };
    const handleBlur = () => {
        props?.setBusinessSetting((prevData) => ({
            ...prevData,
            username: userName,
        }));
        props?.setExcepByOrg((prevVal) => ({
            ...prevVal,
            bussiness_settings: {
                ...prevVal?.bussiness_settings,
                username: userName,
            },
        }));
    };

    return (
        <>
            <Input
                onChange={(e) => handleChange(e)}
                type="text"
                className={`border-[1px] w-[95%] border-${currentMode}-840 rounded-[8px]  py-[12px] px-[10px] bg-${currentMode}-810 `}
                value={userName}
                name="username"
                maxLength={50}
                placeholder="Enter name"
                onBlur={() => handleBlur()}
            />
        </>
    );
};

export default UserInputTrigger;
