import { MenuItem, Select } from "@mui/material";
import { SwitchToggle } from "../../components/UI/Switch/SwitchToggle";
import { useState } from "react";
import Input from "../../components/Input";

const PropertiesPanel = ({ element, currentMode, dispatch, data, current }) => {
    // const [Data, setData] = useState(data);
    const _handleChange = (id, key, e) => {
        const newVal = e.target.type === "checkbox" ? e.target.checked : e.target.value;
        // Create a new copy of the data array
        // Update the state immutably
        current((draft) => {
            const fieldIndex = draft.fields.findIndex((item) => item.id === id);
            if (fieldIndex !== -1) {
                draft.fields[fieldIndex][key] = newVal;
            }
        });
        const updatedData = data?.map((item) => {
            if (item.id === id) {
                return { ...item, [key]: newVal };
            }
            return item;
        });
        // // Update the local state
        // setData(updatedData);

        // Dispatch the updated data
        dispatch({ type: "UPDATE_PROPERTIES", payload: updatedData });
    };

    const Filter = data?.filter((el) => el.id === element);
    return (
        <div className="properties-panel">
            <div className={`flex justify-between bg-${currentMode}-1450 py-2 px-[20px]`}>
                <h4 className="font-interSb text-base">{element ? Filter?.[0]?.title : "Form"} Properties</h4>
                <img src="/images/vendor-onboard/setting.svg" alt="setting" />
            </div>
            <div className="m-[20px]">
                {element ? (
                    Filter?.map((el, i) => {
                        return (
                            <>
                                {el?.type === "name" && (
                                    <div className="space-y-[20px]">
                                        <div>
                                            <label className="block mb-1 font-interM">Field Label</label>
                                            <Input
                                                className={`bg-${currentMode}-1460 py-[9px] px-[10px] rounded-[5px] w-full`}
                                                name="title"
                                                type="text"
                                                onChange={(e) => _handleChange(el?.id, "title", e)}
                                                value={el?.title}
                                            />
                                        </div>
                                        <div>
                                            <label className="block mb-1 font-interM">Visibility</label>
                                            <Select
                                                value={el?.visibility || "0"}
                                                onChange={(e) => _handleChange(el?.id, "visibility", e)}
                                                IconComponent={() => (
                                                    <img
                                                        className="absolute right-[10px] opacity-50 pointer-events-none"
                                                        src={
                                                            currentMode !== "dark"
                                                                ? `/images/header/chevronDown.svg`
                                                                : `/images/header/chevronDown-white.svg`
                                                        }
                                                        alt="chevronDown"
                                                    />
                                                )}
                                                className={`bg-${currentMode}-1460 vendorSelect border-0 rounded-[5px] w-full`}>
                                                <MenuItem value="0">Everyone</MenuItem>
                                                <MenuItem value="1">Admin</MenuItem>
                                            </Select>
                                        </div>
                                        <div className="space-y-[5px]">
                                            <div className="flex justify-between items-center">
                                                <span className="font-interM">First Name</span>
                                                <SwitchToggle
                                                    onChange={(e) => _handleChange(el?.id, "isFirstname", e)}
                                                    name="isFirstname"
                                                    checked={el?.isFirstname}
                                                />
                                            </div>
                                            <div className="flex justify-between items-center">
                                                <span className="font-interM">Last Name</span>
                                                <SwitchToggle
                                                    onChange={(e) => _handleChange(el?.id, "isLastname", e)}
                                                    name="isLastname"
                                                    checked={el?.isLastname}
                                                />
                                            </div>
                                            <div className="flex justify-between items-center">
                                                <span className="font-interM">Prefix</span>
                                                <SwitchToggle
                                                    onChange={(e) => _handleChange(el?.id, "prefix", e)}
                                                    name="prefix"
                                                    checked={el?.prefix}
                                                />
                                            </div>
                                            <div className="flex justify-between items-center">
                                                <span className="font-interM">Suffix</span>
                                                <SwitchToggle
                                                    onChange={(e) => _handleChange(el?.id, "suffix", e)}
                                                    name="suffix"
                                                    checked={el?.suffix}
                                                />
                                            </div>
                                            <div className="flex justify-between items-center">
                                                <span className="font-interM">This field is Mandatory</span>
                                                <SwitchToggle
                                                    onChange={(e) => _handleChange(el?.id, "mandatory", e)}
                                                    name="mandatory"
                                                    checked={el?.mandatory}
                                                />
                                            </div>
                                            <div className="flex justify-between items-center">
                                                <span className="font-interM">Description</span>
                                                <SwitchToggle
                                                    onChange={(e) => _handleChange(el?.id, "description", e)}
                                                    name="description"
                                                    checked={el?.description}
                                                />
                                            </div>
                                            {el?.description && (
                                                <textarea className={`bg-${currentMode}-1460 py-[9px] px-[10px] rounded-[5px] w-full`} />
                                            )}
                                        </div>
                                    </div>
                                )}
                                {el?.type === "title" && (
                                    <div className="space-y-[20px]">
                                        <div>
                                            <label className="block mb-1 font-interM">Field Label</label>
                                            <Input
                                                className={`bg-${currentMode}-1460 py-[9px] px-[10px] rounded-[5px] w-full`}
                                                name="title"
                                                type="text"
                                                onChange={(e) => _handleChange(el?.id, "title", e)}
                                                value={el?.title}
                                            />
                                        </div>
                                        <div>
                                            <label className="block mb-1 font-interM">Visibility</label>
                                            <Select
                                                value={el?.visibility || "0"}
                                                onChange={(e) => _handleChange(el?.id, "visibility", e)}
                                                IconComponent={() => (
                                                    <img
                                                        className="absolute right-[10px] opacity-50 pointer-events-none"
                                                        src={
                                                            currentMode !== "dark"
                                                                ? `/images/header/chevronDown.svg`
                                                                : `/images/header/chevronDown-white.svg`
                                                        }
                                                        alt="chevronDown"
                                                    />
                                                )}
                                                className={`bg-${currentMode}-1460 vendorSelect border-0 rounded-[5px] w-full`}>
                                                <MenuItem value="0">Everyone</MenuItem>
                                                <MenuItem value="1">Admin</MenuItem>
                                            </Select>
                                        </div>
                                        <div>
                                            <label className="block mb-1 font-interM">Border Line</label>
                                            <Select
                                                value={el?.border || "0"}
                                                onChange={(e) => _handleChange(el?.id, "border", e)}
                                                IconComponent={() => (
                                                    <img
                                                        className="absolute right-[10px] opacity-50 pointer-events-none"
                                                        src={
                                                            currentMode !== "dark"
                                                                ? `/images/header/chevronDown.svg`
                                                                : `/images/header/chevronDown-white.svg`
                                                        }
                                                        alt="chevronDown"
                                                    />
                                                )}
                                                className={`bg-${currentMode}-1460 vendorSelect border-0 rounded-[5px] w-full`}>
                                                <MenuItem value="0">Select</MenuItem>
                                                <MenuItem value="1">Solid</MenuItem>
                                                <MenuItem value="2">Dash</MenuItem>
                                            </Select>
                                        </div>
                                        <div className="space-y-[5px]">
                                            <div className="flex justify-between items-center">
                                                <span className="font-interM">Sub heading</span>
                                                <SwitchToggle
                                                    onChange={(e) => _handleChange(el?.id, "subHeading", e)}
                                                    name="isFirstname"
                                                    checked={el?.subHeading}
                                                />
                                            </div>
                                            <div className="flex justify-between items-center">
                                                <span className="font-interM">Hide field</span>
                                                <SwitchToggle
                                                    onChange={(e) => _handleChange(el?.id, "hideField", e)}
                                                    name="isLastname"
                                                    checked={el?.hideField}
                                                />
                                            </div>
                                            <div className="flex justify-between items-center">
                                                <span className="font-interM">This field is Mandatory</span>
                                                <SwitchToggle
                                                    onChange={(e) => _handleChange(el?.id, "mandatory", e)}
                                                    name="mandatory"
                                                    checked={el?.mandatory}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {el?.type === "address" && (
                                    <div className="space-y-[20px]">
                                        <div>
                                            <label className="block mb-1 font-interM">Field Label</label>
                                            <Input
                                                className={`bg-${currentMode}-1460 py-[9px] px-[10px] rounded-[5px] w-full`}
                                                name="title"
                                                type="text"
                                                onChange={(e) => _handleChange(el?.id, "title", e)}
                                                value={el?.title}
                                            />
                                        </div>
                                        <div>
                                            <label className="block mb-1 font-interM">Visibility</label>
                                            <Select
                                                value={el?.visibility || "0"}
                                                onChange={(e) => _handleChange(el?.id, "visibility", e)}
                                                IconComponent={() => (
                                                    <img
                                                        className="absolute right-[10px] opacity-50 pointer-events-none"
                                                        src={
                                                            currentMode !== "dark"
                                                                ? `/images/header/chevronDown.svg`
                                                                : `/images/header/chevronDown-white.svg`
                                                        }
                                                        alt="chevronDown"
                                                    />
                                                )}
                                                className={`bg-${currentMode}-1460 vendorSelect border-0 rounded-[5px] w-full`}>
                                                <MenuItem value="0">Everyone</MenuItem>
                                                <MenuItem value="1">Admin</MenuItem>
                                            </Select>
                                        </div>
                                        <div>
                                            <label className="block mb-1 font-interM">Initial Value</label>
                                            <Input
                                                className={`bg-${currentMode}-1460 py-[9px] px-[10px] rounded-[5px] w-full`}
                                                name="initialValue"
                                                type="text"
                                                onChange={(e) => _handleChange(el?.id, "initialValue", e)}
                                                value={el?.initialValue}
                                            />
                                        </div>
                                        <div>
                                            <label className="block mb-1 font-interM">Address 1</label>
                                            <Input
                                                className={`bg-${currentMode}-1460 py-[9px] px-[10px] rounded-[5px] w-full`}
                                                name="address1"
                                                type="text"
                                                onChange={(e) => _handleChange(el?.id, "address1", e)}
                                                value={el?.address1}
                                            />
                                        </div>
                                        <div>
                                            <label className="block mb-1 font-interM">Address 2</label>
                                            <Input
                                                className={`bg-${currentMode}-1460 py-[9px] px-[10px] rounded-[5px] w-full`}
                                                name="address2"
                                                type="text"
                                                onChange={(e) => _handleChange(el?.id, "address2", e)}
                                                value={el?.address2}
                                            />
                                        </div>
                                        <div className="space-y-[5px]">
                                            <div className="flex justify-between items-center">
                                                <span className="font-interM">This field is Mandatory</span>
                                                <SwitchToggle
                                                    onChange={(e) => _handleChange(el?.id, "mandatory", e)}
                                                    name="mandatory"
                                                    checked={el?.mandatory}
                                                />
                                            </div>
                                            <div className="flex justify-between items-center">
                                                <span className="font-interM">Description</span>
                                                <SwitchToggle
                                                    onChange={(e) => _handleChange(el?.id, "description", e)}
                                                    name="description"
                                                    checked={el?.description}
                                                />
                                            </div>
                                            {el?.description && (
                                                <textarea className={`bg-${currentMode}-1460 py-[9px] px-[10px] rounded-[5px] w-full`} />
                                            )}
                                        </div>
                                    </div>
                                )}
                            </>
                        );
                    })
                ) : (
                    <div>
                        <span className="font-interSb block mb-1">Button</span>
                        <p>While adding record:</p>
                        <div className="flex justify-between my-2 items-center">
                            <span>Submit</span>
                            <SwitchToggle />
                        </div>
                        <div className="flex justify-between items-center">
                            <span>Reset</span>
                            <SwitchToggle />
                        </div>
                        <hr className="border-[rgba(140, 144, 151, 0.56)] my-[20px]" />
                        <p>While editing record:</p>
                        <div className="flex justify-between my-2 items-center">
                            <span>Update</span>
                            <SwitchToggle />
                        </div>
                        <div className="flex justify-between my-2 items-center">
                            <span>Cancel</span>
                            <SwitchToggle />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PropertiesPanel;
