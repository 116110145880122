import Card from "../../../components/UI/Card";
import { useContext } from "react";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import Button from "../../../components/Button";
import Modal from "../../../components/UI/Modal/Modal";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Input from "../../../components/Input";
import { NumericFormat } from "react-number-format";
import { formatNumber } from "../../../utils/helpers";
import Swal from "sweetalert2";
const POHeaderLineItemPopUp = (props) => {
    const { currentMode } = useContext(ThemeContext);
    const PoLineItems = props?.PoLineItems;
    const location = useLocation();
    const params = location?.pathname;
    const [date, setDate] = useState(new Date());
    let totalDebitAmount = 0;
    // const debitAmountSum = PoLineItems?.reduce((value, addit) => {
    //     return value + (addit?.debit_amount || 0);
    // }, 0);

    const updatePoTable = (index, ref_val) => {
        props?.setRows((prev) => {
            return prev?.map((ele, ind) => {
                if (ind == props?.index) {
                    ele.items_info[index].debit_amount = ref_val;
                }
                return ele;
            });
        });
    };

    const showPopup = (index, ref_val, allowedAmount) => {
        Swal.fire({
            text: `The entered amount should be less than or equal to ${allowedAmount}.`,
            icon: "success",
            allowOutsideClick: false,
            showCloseButton: true,
            button: "Ok",
        }).then((result) => {
            updatePoTable(index, allowedAmount);
            setDate(new Date());
        });
    };

    const handleChange = (index, value, max_value) => {
        const ref_val = formatNumber(value);
        const debitAmountSum = PoLineItems?.reduce((value, addit, yup) => {
            return yup == index ? value + 0 : value + (addit?.debit_amount || 0);
        }, 0);
        const allowedAmount = +props?.overAll - debitAmountSum > +max_value ? +max_value : +props?.overAll - debitAmountSum;

        if (allowedAmount < ref_val) {
            showPopup(index, ref_val, allowedAmount);
        } else {
            updatePoTable(index, ref_val);
        }
    };
    const userNumFormat = localStorage.getItem("userNumberFormat");
    let thousandSeperator = userNumFormat == "1" ? "," : userNumFormat == 2 ? "." : " ";
    let decSeperator = userNumFormat == "1" ? "." : userNumFormat == "2" ? "," : ".";
    const totalAmount = PoLineItems?.map((el) => (location?.pathname === "/miscellaneous-request" ? el?.debit_amount : +el?.amount));
    for (let i = 0; i < totalAmount?.length; i++) {
        totalDebitAmount += totalAmount[i] || 0;
    }
    const DummyData = [
        "S No.",
        "Description of Goods",
        "GST Rate",
        "Qty",
        "Unit",
        "Rate",
        location.pathname == "/mobilization-request" || (location.pathname == "/adhoc-advance-request" && "Down Payment Percentage"),
        location.pathname == "/mobilization-request" || (location.pathname == "/adhoc-advance-request" && "Down Payment Amount"),
        "Amount",
        params == "/miscellaneous-request" && "Debit amount",
    ];

    const DummyFilter = DummyData?.filter((el) => el !== false);
    return (
        <>
            <Modal cartClose={props?.handleClose} center="center" className={`${props?.className}`}>
                <div
                    className={`max-w-[986px] xl:mx-auto overflow-x-auto h-[310px] mx-auto relative bg-${currentMode}-card border-[rgba(223,225,226,0.56)] px-[30px]  py-[20px] rounded-[10px] ${currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                        }]`}>
                    <div className="top-[20px]">
                        <div className="flex justify-between">
                            <span className="font-medium text-[18px] font-interM sticky left-0">Line Item</span>
                            <span onClick={props?.handleClose} className="cursor-pointer fixed right-[50px] xl:right-[250px] us:right-[130px] convertWhite">
                                <img src="/images/common/closeBlk.svg" alt="closeBlk" />
                            </span>
                        </div>
                        <div className="max-h-[260px] mx-[-30px] mt-[10px] overflow-y-auto w-[154%]">
                            <table className="w-full border-0 rolemasterTable rounded-[0] lastBorder text-center ">
                                <thead>
                                    <tr className={`text-[14px]`}>
                                        <th className="">SI.No</th>
                                        <th className="">Description of Goods</th>
                                        <th className="">GST Rate</th>
                                        <th>Qty</th>
                                        <th>Unit</th>
                                        <th className="">Rate</th>
                                        {(location.pathname == "/mobilization-request" || location.pathname == "/adhoc-advance-request") && (
                                            <>
                                                <th className="">Down Payment Percentage</th>
                                                <th className="">Down Payment Amount</th>
                                            </>
                                        )}
                                        <th className="">Amount</th>
                                        {params == "/miscellaneous-request" && <th className="rounded-r-[0]">Debit Amount</th>}
                                    </tr>
                                </thead>
                                <tbody className="relative">
                                    {PoLineItems ? (
                                        PoLineItems?.map((el, i) => {
                                            return (
                                                <tr className={``} key={i}>
                                                    <td className="">{i + 1}</td>
                                                    <td className="">{el?.description}</td>
                                                    <td className="">{el?.tax_percentage}%</td>
                                                    <td className="">{el?.quantity}</td>
                                                    <td className="">{el?.unit}</td>
                                                    <td className="">{el?.rate}</td>
                                                    {(location.pathname == "/mobilization-request" ||
                                                        location.pathname == "/adhoc-advance-request") && (
                                                            <>
                                                                <td className="">{el?.DownPaymentPercentageItems}</td>
                                                                <td className="">{el?.DownPaymentAmountItems}</td>
                                                            </>
                                                        )}
                                                    <td className="">{el?.amount}</td>
                                                    {params == "/miscellaneous-request" && (
                                                        <td>
                                                            <NumericFormat
                                                                key={`${i}-${date}-row`}
                                                                id={2}
                                                                autoComplete="off"
                                                                allowNegative={false}
                                                                readOnly={false}
                                                                decimalScale={2}
                                                                maxLength={23}
                                                                displayType="input"
                                                                thousandSeparator={thousandSeperator}
                                                                decimalSeparator={decSeperator}
                                                                // disabled={status == "7" || status == "4" || InputDisable}
                                                                value={el?.debit_amount}
                                                                onChange={(e) => {
                                                                    handleChange(i, e.target.value || 0, el?.net_amount);
                                                                }}
                                                                placeholder="Enter Debit amount"
                                                                className={
                                                                    "border-[rgba(85,85,85,0.5)] w-[150px] h-[40px] bg-transparent px-[10px] py-1 border-[1px] rounded-[8px] "
                                                                }
                                                            />
                                                        </td>
                                                    )}
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <div className="bg-white">
                                            <div className="text-center">
                                                <div className="mx-auto my-5">
                                                    <img className="mx-auto" src="/images/common/delivery.png" alt="delivery" />
                                                </div>
                                                <h4 className="font-interSb">No Data found</h4>
                                            </div>
                                        </div>
                                    )}

                                    {/* <tr>
                                        <td colSpan={8} className="px-[10px] font-interSb text-right">
                                            Total
                                        </td>
                                        <td className="px-[10px] bg-[#D3FAF445]">
                                            <NumericFormat
                                                value={totalDebitAmount}
                                                decimalScale={2}
                                                thousandSeparator={thousandSeperator}
                                                decimalSeparator={decSeperator}
                                                className="bg-transparent"></NumericFormat></td>
                                    </tr> */}
                                    <tr>
                                        <td colSpan={DummyFilter?.length - 2} className="border-0"></td>
                                        <td className="font-interM !border-b text-center">Total</td>
                                        <td className={`font-interM !border-b bg-${currentMode}-1400`}>
                                            {" "}
                                            <NumericFormat
                                                value={totalDebitAmount}
                                                decimalScale={2}
                                                thousandSeparator={thousandSeperator}
                                                decimalSeparator={decSeperator}
                                                className="bg-transparent text-center"></NumericFormat>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default POHeaderLineItemPopUp;
