import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getInvoiceCalc, getInvoiceCalcDetail } from "../../../redux/actions/invoice";
import Input from "../../../components/Input";
import CalCulationModal from "./CalculationModal";
import { RoundNum, amountUpdate, autoCalc, formatNumber, showNumber, showSwal } from "../../../utils/helpers";
import Button from "../../../components/Button";
import { useFormik } from "formik";
import Loader from "../../../components/Loader";
import _ from "lodash";
import { GET_INVOICE_CALC_DETAIL_VIEW_RESET, GET_INVOICE_CALC_VIEW_RESET, GET_INVOICE_CALC_VIEW_UPDATE } from "../../../redux/types/types";
import Swal from "sweetalert2";
import { NumericFormat } from "react-number-format";
export const InvoiceCalculationTable = (props) => {
    const {
        convertingArray,
        setConvertingArray,
        advData,
        setAdvData,
        miscData,
        setMiscData,
        isEdit,
        lineItemtotalRetention,
        listItems,
        setClicked,
        creditData,
        setCreditData,
    } = props;
    const { data: erpValidateArr, error: err } = useSelector((state) => state?.threeWayMatchingByErpState);
    const recoveryInfo = erpValidateArr?.recovery_info;
    const { retentionOnGross, totalRetentionAmount, retentionPercentage, retentionIndicator } = recoveryInfo || {};
    let Indicator = !retentionIndicator ? listItems?.recovery_info?.retentionIndicator : retentionIndicator;
    const getGrossAmtId = listItems?.invoice_items?.filter((el, i) => el?.label_name == "total");
    const pocNetId = listItems?.invoice_items?.filter((el, i) => el?.label_name == "total_net");
    const taxId = listItems?.invoice_items?.filter((el, i) => el?.label_name == "tax");
    let GrossAmt;
    let NetAmt;
    let TaxAmt;
    if (getGrossAmtId?.length > 0) {
        GrossAmt = document.querySelector(`#Inv-li-${getGrossAmtId?.[0]?.id}`);
    }
    if (pocNetId?.length > 0) {
        NetAmt = document.querySelector(`#Inv-li-${pocNetId?.[0]?.id}`);
    }
    if (taxId?.length > 0) {
        TaxAmt = document.querySelector(`#Inv-li-${taxId?.[0]?.id}`);
    }
    let Totalrete = (formatNumber(retentionOnGross ? GrossAmt?.textContent : NetAmt?.textContent) * formatNumber(retentionPercentage)) / 100;
    let RetenTtl = retentionIndicator == "I" ? lineItemtotalRetention : Totalrete;
    const dispatch = useDispatch();
    const orgId = localStorage?.getItem("orgId");
    const { currentMode } = useContext(ThemeContext);
    const { invCalc, initial, fetching } = useSelector((state) => state?.getInvCaclviewState);
    const [openPopup, setopenPopup] = useState(false);

    const handleOpenPopUp = (type) => {
        const TypeChange = (types) => {
            switch (types) {
                case 0:
                    return 7;
                case 3:
                    return 2;
                case 5:
                    return 1;
                case 11:
                    return 3;
                case 12:
                    return 4;
                default:
                    break;
            }
        };
        setTypes(TypeChange(type));
        setopenPopup(true);
    };
    const { advRec, miscdb, creditnte, taxPer, taxDatas } = useSelector((state) => state?.getInvCalcValState);
    const { error, poData } = useSelector((state) => state?.getInvCalcDetailState);

    useMemo(() => {
        if (error?.status == "500") {
            setopenPopup(false);
            Swal.fire({
                text: `${error?.data?.message}`,
                icon: "warning",
                closeOnClickOutside: false,
                buttons: {
                    confirm: true,
                },
            }).then((confirm) => {
                if (confirm) {
                    dispatch({ type: GET_INVOICE_CALC_DETAIL_VIEW_RESET });
                }
            });
        }
    }, [error]);
    const initialData = _.cloneDeep(initial);
    const netPay = invCalc?.[invCalc?.length - 1]?.[`billAmount`];

    const [types, setTypes] = useState("");
    const [bill, setBill] = useState([]);

    useMemo(() => {
        const Amounts = { GrossAmt, NetAmt, TaxAmt, RetenTtl, retentionIndicator, retentionOnGross, retentionPercentage };
        amountUpdate(invCalc, dispatch, Amounts);
    }, [GrossAmt, NetAmt, TaxAmt]);
    const netValue = _.find(invCalc, { Name: "Poc Net" });
    const poretent = _.find(invCalc, { Name: "Po Retention" });
    const netpayValue = _.find(invCalc, { Name: "Net Payable" });

    useMemo(() => {
        // const ifEdit = _.isEqual(invCalc, initialData);
        const poretenIndex = invCalc?.findIndex((item) => item.Name === "Po Retention");
        const updatedArrays = [...invCalc];
        if (retentionIndicator == "H") {
            RetenTtl = (formatNumber(GrossAmt?.textContent) * formatNumber(retentionPercentage)) / 100;
            updatedArrays[poretenIndex] = {
                ...updatedArrays[poretenIndex],
                billAmount: RetenTtl, // Convert to string
                CumulativeAmount: RetenTtl
                    ? formatNumber(RetenTtl) + formatNumber(initialData[poretenIndex]?.PreviousBillAmount)
                    : initialData[poretenIndex]?.CumulativeAmount, // Convert to string
            };
            // setConvertingArray(updatedArrays);
            dispatch({ type: GET_INVOICE_CALC_VIEW_UPDATE, payload: updatedArrays });
        } else if (retentionIndicator == "I") {
            updatedArrays[poretenIndex] = {
                ...updatedArrays[poretenIndex],
                billAmount: RetenTtl, // Convert to string
                CumulativeAmount: RetenTtl
                    ? formatNumber(RetenTtl) + formatNumber(initialData[poretenIndex]?.PreviousBillAmount)
                    : initialData[poretenIndex]?.CumulativeAmount, // Convert to string
            };
            autoCalc(updatedArrays, GrossAmt, dispatch);
            // setConvertingArray(updatedArrays);
            dispatch({ type: GET_INVOICE_CALC_VIEW_UPDATE, payload: updatedArrays });
        }
    }, [recoveryInfo, RetenTtl]);
    useMemo(() => {
        // Find the index of the "Advance Recovery" object in convertingArray
        const advanceRecoveryIndex = invCalc?.findIndex((item) => item.Name === "Advance Recovery");
        const miscDebitIndex = invCalc?.findIndex((item) => item.Name === "Misc Debit");
        const creditNteIndex = invCalc?.findIndex((item) => item.Name === "Direct Credit Note");
        const tdsTaxIndex = invCalc?.findIndex((item) => item.Name === "Tax Deductions");
        if (advRec != null) {
            // Create a new copy of convertingArray
            const updatedArrays = [...invCalc];
            updatedArrays[advanceRecoveryIndex] = {
                ...updatedArrays[advanceRecoveryIndex],
                billAmount: RoundNum(+advRec), // Convert to string
                CumulativeAmount:
                    advRec != 0
                        ? RoundNum(formatNumber(advRec) + formatNumber(initialData[advanceRecoveryIndex]?.PreviousBillAmount))
                        : initialData[advanceRecoveryIndex]?.CumulativeAmount, // Convert to string
            };
            autoCalc(updatedArrays, GrossAmt, dispatch);
            // Update the state with the modified array
            dispatch({ type: GET_INVOICE_CALC_VIEW_UPDATE, payload: updatedArrays });
        }
        if (miscdb != null) {
            // Create a new copy of convertingArray
            const updatedArray = [...invCalc];
            updatedArray[miscDebitIndex] = {
                ...updatedArray[miscDebitIndex],
                billAmount: RoundNum(+miscdb), // Convert to string
                CumulativeAmount:
                    miscdb != 0
                        ? RoundNum(formatNumber(miscdb) + formatNumber(initialData[miscDebitIndex]?.PreviousBillAmount))
                        : initialData[miscDebitIndex]?.CumulativeAmount, // Convert to string
            };
            autoCalc(updatedArray, GrossAmt, dispatch);
            // Update the state with the modified array
            dispatch({ type: GET_INVOICE_CALC_VIEW_UPDATE, payload: updatedArray });
        }
        if (creditnte != null) {
            // Create a new copy of convertingArray
            const updatedArrays = [...invCalc];
            updatedArrays[creditNteIndex] = {
                ...updatedArrays[creditNteIndex],
                billAmount: RoundNum(+creditnte), // Convert to string
                CumulativeAmount:
                    creditnte != 0
                        ? RoundNum(formatNumber(creditnte) + formatNumber(initialData[creditNteIndex]?.PreviousBillAmount))
                        : initialData[creditNteIndex]?.CumulativeAmount, // Convert to string
            };
            autoCalc(updatedArrays, GrossAmt, dispatch);
            // Update the state with the modified array
            dispatch({ type: GET_INVOICE_CALC_VIEW_UPDATE, payload: updatedArrays });
        }
        if (taxPer != null) {
            const TaxTds = (formatNumber(netValue?.billAmount) * formatNumber(taxPer)) / 100;
            // const TxAm = formatNumber(GrossValue.billAmount) - TaxTds;
            const insertAmt = taxDatas?.map((el, i) => ({ ...el, actualAmt: TaxTds }));
            dispatch({ type: "STORE_TDS_TAX_UPADTE", payload: insertAmt });
            // Create a new copy of convertingArray
            const updatedArrays = [...invCalc];
            updatedArrays[tdsTaxIndex] = {
                ...updatedArrays[tdsTaxIndex],
                billAmount: RoundNum(+TaxTds), // Convert to string
                CumulativeAmount:
                    taxPer != 0
                        ? RoundNum(formatNumber(TaxTds) + formatNumber(initialData[tdsTaxIndex]?.PreviousBillAmount))
                        : initialData[tdsTaxIndex]?.CumulativeAmount, // Convert to string
            };
            autoCalc(updatedArrays, GrossAmt, dispatch);
            // Update the state with the modified array
            dispatch({ type: GET_INVOICE_CALC_VIEW_UPDATE, payload: updatedArrays });
        }
    }, [advRec, miscdb, creditnte, taxPer]);
    const indicesToMap = [7, 8, 9, 10];
    const initialObj = {
        billAmount: invCalc
            ?.filter((_, index) => indicesToMap.includes(index))
            .map((el) => ({
                billAmount: el["billAmount"] || 0,
            })),
    };
    const formik = useFormik({
        initialValues: initialObj,
        // validationSchema: DocumentValidateSchema,
        onSubmit: (values) => {},
    });
    const handleInputChange = (newValue, label, i, isedit) => {
        const updatedArray = [...invCalc];
        const netPayableIndex = updatedArray?.findIndex((item) => item.Name === "Net Payable");
        let originalValue;
        if (isedit) {
            // If editing, get the original value from the convertingArray
            originalValue = formatNumber(updatedArray[i]["billAmount"]);
        } else {
            // If not editing, get the original value directly from convertingArray without any calculation
            originalValue = formatNumber(updatedArray[i]["billAmount"]);
        }

        const netPayableValue = updatedArray[netPayableIndex]["billAmount"];

        let updatedValue = formatNumber(netPayableValue);

        // Check if newValue is empty or negative
        if (!newValue || newValue < 0) {
            newValue = 0; // Set newValue to 0 if it's empty or negative
        }

        if (label === "TCS On Purchase") {
            updatedValue += formatNumber(newValue) - originalValue;
        } else {
            updatedValue -= formatNumber(newValue) - originalValue; // Subtract difference from net payable value
        }
        // if (updatedValue < 0) {
        //     updatedValue = 0;
        // }
        updatedArray[i]["billAmount"] = newValue;
        updatedArray[i]["CumulativeAmount"] = RoundNum(
            formatNumber(updatedArray[i]["billAmount"]) + formatNumber(updatedArray[i]["PreviousBillAmount"])
        );

        // Update the "Net Payable" item with the recalculated value
        updatedArray[netPayableIndex]["billAmount"] = "" + RoundNum(updatedValue);
        updatedArray[netPayableIndex]["CumulativeAmount"] = "" + RoundNum(updatedValue + formatNumber(updatedArray[i]["PreviousBillAmount"]));
        // setConvertingArray(updatedArray);
        dispatch({ type: GET_INVOICE_CALC_VIEW_UPDATE, payload: updatedArray });
    };
    const handleSubmit = () => {
        if (formatNumber(netpayValue?.billAmount) < 0) {
            showSwal("Net Payable amount should not be negative!");
            return;
        }
        const ifEdit = _.isEqual(invCalc, initialData);
        if (!ifEdit) {
            setClicked(true);
        }
        props?.handleClose();
    };
    const _Cancel = () => {
        const ifEdit = _.isEqual(invCalc, initialData);
        if (!ifEdit) {
            Swal.fire({
                text: `All changes that you made will be lost?`,
                icon: "warning",
                showCloseButton: true,
                showCancelButton: true,
                buttons: {
                    cancel: "Cancel",
                    confirm: "Confirm",
                },
            }).then((confirm) => {
                if (confirm?.isConfirmed) {
                    dispatch({ type: GET_INVOICE_CALC_VIEW_RESET });
                    setConvertingArray([]);
                    props?.handleClose();
                }
            });
        } else {
            props?.handleClose();
        }
    };
    const userNumFormat = localStorage.getItem("userNumberFormat");
    let thousandSeperator = userNumFormat == "1" ? "," : userNumFormat == 2 ? "." : " ";
    let decSeperator = userNumFormat == "1" ? "." : userNumFormat == "2" ? "," : ".";
    return (
        <>
            <div
                className={`w-full max-w-[856px] mx-auto relative text-${currentMode}-main bg-${currentMode}-card border-[rgba(223,225,226,0.56)]  pt-[20px] rounded-[10px] ${
                    currentMode === "dark" ? "" : "shadow-[0px_4px_20px_0px_rgba(238,238,238,0.50)] "
                }]`}>
                <div className="flex items-center px-[20px] justify-between">
                    <span className={`text-${currentMode}-main text-[18px] font-interM`}>Invoice Calculations</span>
                    <span onClick={props?.handleClose} className="cursor-pointer convertWhite">
                        <img src="/images/common/closeBlk.svg" alt="closeBlk" />
                    </span>
                </div>
                <div className="mt-[20px]">
                    {fetching ? (
                        <Loader className="h-[calc(100vh_-_17rem)] " />
                    ) : (
                        <div>
                            <table className={`w-full border-b border-${currentMode}-1240 rolemasterTable  onTimeTbl !rounded-[0] text-left`}>
                                <thead>
                                    <tr className="text-center">
                                        <th className="w-[57px] py-[10px] text-center !rounded-[0]">#</th>
                                        <th className="text-left py-[10px]">Name</th>
                                        <th className="py-[10px]">Bill Amount</th>
                                        <th className="py-[10px]">Previous Bill Amount</th>
                                        <th className="!rounded-[0] py-[10px]">Cumulative Amount</th>
                                    </tr>
                                </thead>
                                <tbody className={`relative`}>
                                    {invCalc?.map?.((el, i) => (
                                        <tr>
                                            <td className="text-center py-[10px]">{i + 1}</td>
                                            <td className="w-[30%] py-[10px]">
                                                <div className="flex justify-between">
                                                    {`${el?.Name} ${el?.isPlus ? `(+)` : ``} ${el?.isPlus == false ? `(-)` : ``}`}
                                                    {el?.isChild && (
                                                        <img
                                                            className="cursor-pointer"
                                                            onClick={() => handleOpenPopUp(i)}
                                                            src="/images/invoice/eye-ico.svg"
                                                            alt="eye-ico"
                                                        />
                                                    )}
                                                </div>
                                            </td>
                                            <td className="text-right py-[10px]">
                                                {indicesToMap.includes(i) && el?.isEditable ? (
                                                    <NumericFormat
                                                        id={1}
                                                        autoComplete="off"
                                                        allowNegative={false}
                                                        readOnly={isEdit || (el?.Name == "Po Retention" && (!listItems?.is_retention || !Indicator))}
                                                        name={`billAmount[${i}].billAmount`}
                                                        decimalScale={2}
                                                        onClick={(e) => e?.target?.select()}
                                                        maxLength={20}
                                                        displayType="input"
                                                        thousandSeparator={thousandSeperator}
                                                        decimalSeparator={decSeperator}
                                                        value={
                                                            formik?.values?.billAmount[i]?.["billAmount"] || el?.[`billAmount`] != 0
                                                                ? el?.[`billAmount`]
                                                                : ""
                                                        }
                                                        onChange={(e) => {
                                                            const newValue = e.target.value;
                                                            if (i >= 7 && i <= 9) {
                                                                // Update the bill array with the new value at the corresponding index
                                                                const updatedBill = [...bill]; // Create a copy of the array
                                                                updatedBill[i - 7] = newValue; // Update the value at the correct index
                                                                setBill(updatedBill); // Set the updated array
                                                                // Update formik.values.billAmount for the current index
                                                                formik.setFieldValue(`billAmount[${i}].billAmount`, newValue);
                                                                handleInputChange(newValue, el?.Name, i, el?.isEditable);
                                                            } else if (i == 10) {
                                                                const stringValue = newValue.toString();
                                                                if (stringValue.length > 7) {
                                                                    showSwal("Input value should not exceed 7 digits.");
                                                                    return;
                                                                }
                                                                formik.setFieldValue(`billAmount[${i}].billAmount`, newValue);
                                                                handleInputChange(newValue, el?.Name, i, el?.isEditable);
                                                            }
                                                        }}
                                                        className={`border font-interR text-right max-w-[170px] rounded-[5px] my-[-10px] mx-[-15px] border-${currentMode}-840 bg-${currentMode}-card py-[3px] px-[10px]`}
                                                    />
                                                ) : (
                                                    <NumericFormat
                                                        id={2}
                                                        autoComplete="off"
                                                        decimalScale={2}
                                                        maxLength={20}
                                                        displayType="text"
                                                        thousandSeparator={thousandSeperator}
                                                        decimalSeparator={decSeperator}
                                                        value={el?.[`billAmount`]}
                                                        title={el?.[`billAmount`]}
                                                        className="truncate w-[150px] block"
                                                    />
                                                )}
                                            </td>
                                            <td className="text-right py-[10px]">
                                                <NumericFormat
                                                    id={3}
                                                    autoComplete="off"
                                                    decimalScale={2}
                                                    maxLength={20}
                                                    displayType="text"
                                                    thousandSeparator={thousandSeperator}
                                                    decimalSeparator={decSeperator}
                                                    value={el?.[`PreviousBillAmount`]}
                                                    title={el?.[`PreviousBillAmount`]}
                                                    className="truncate w-[150px] block"
                                                />
                                            </td>
                                            <td className="text-right py-[10px]">
                                                <NumericFormat
                                                    id={4}
                                                    autoComplete="off"
                                                    decimalScale={2}
                                                    maxLength={20}
                                                    displayType="text"
                                                    thousandSeparator={thousandSeperator}
                                                    decimalSeparator={decSeperator}
                                                    value={el?.[`CumulativeAmount`]}
                                                    title={el?.[`CumulativeAmount`]}
                                                    className="truncate w-[150px] block"
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>{" "}
                            </table>
                            <div className="space-x-2 py-[20px] flex justify-end pr-[30px]">
                                <Button onClick={handleSubmit} title={"Save"} className="bg-light-20 text-base text-white rounded-[8px] py-2 px-7">
                                    {"Save"}
                                </Button>
                                <Button title="Cancel" onClick={_Cancel} className={`bg-light-240 text-light-40 text-base rounded-[8px] py-2 px-7`}>
                                    Cancel
                                </Button>
                            </div>{" "}
                        </div>
                    )}
                </div>
                {ReactDOM.createPortal(
                    <CalCulationModal
                        isEdit={isEdit}
                        invid={props?.invId}
                        isOpened={openPopup}
                        advData={advData}
                        listItems={props?.listItems}
                        setAdvData={setAdvData}
                        netValue={netValue}
                        miscData={miscData}
                        setMiscData={setMiscData}
                        creditData={creditData}
                        setCreditData={setCreditData}
                        type={types}
                        handleClose={() => setopenPopup(false)}
                        className={openPopup && "Show"}
                    />,
                    document.getElementById("user-modal-root")
                )}
            </div>
        </>
    );
};
