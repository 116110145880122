import { Autocomplete, Checkbox, Chip, TextField, ClickAwayListener, ListItemText, Paper } from "@mui/material";
import ReactQuill from "react-quill";
import { useState, useEffect, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { convertNewlinesToHtml, decryptId } from "../../utils/helpers";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Card from "../../components/UI/Card";
import { getEmailTemplatePlaceHolderList } from "../../redux/actions/emailtask";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import Modal from "../../components/UI/Modal/Modal";
import Swal from "sweetalert2";
import { ExceptionEmailGeneration } from "../../redux/actions/exception-automation";
import Loader from "../../components/Loader";
import { EMAIL_GENERATE_EXCEPTION_RESET } from "../../redux/types/types";
import TogglePopup from "../organisations/Toggle";
const EmailConfig = (props) => {
    const { check, setCheck } = props;
    const setCategoryArr = props?.setCategoryArr;
    const { currentMode } = useContext(ThemeContext);
    const DefaultArr = props?.reminderArr;
    // const array = props?.reminderArr;
    const [array, setArray] = useState([]);
    const [firstArray, setFirstArray] = useState(DefaultArr);
    const ViewSubject = props?.selectedId?.email_json?.Sub;
    const [notificationVal, setNotificationVal] = useState(array);
    const selectedIndex = props?.index;
    const ViewArray = array?.filter((ele, i) => i == selectedIndex)?.[0];

    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const dispatch = useDispatch();
    const { data: placeHolData } = useSelector((state) => state?.emailTemplatePlaceholderListState);
    const EmailGenerate = useSelector((state) => state?.EmailGenerateExceptionState);
    const EmailGenerateData = EmailGenerate?.data?.email?.mail_body;
    const EmailGenearteSub = EmailGenerate?.data?.email?.subject;
    const invoicePlaceHolderList = placeHolData?.filter((el) => el?.placeholder_group_name == "Invoice");
    const [title, setTitle] = useState(ViewArray?.notification_title);
    const [notificationenable, setnotificationEnable] = useState(ViewArray?.push_flag);
    const [error1, setError] = useState(false);
    const [message, setMessage] = useState(ViewArray?.push_message);
    const [Emailenable, setemailEnable] = useState(ViewArray?.email_flag);
    const [subject, setSubject] = useState(ViewSubject);
    const childCompRef = useRef();
    const [emailMessage, setEmailmessage] = useState(ViewArray?.email_body);
    const CC = ViewArray?.email_cc;
    const BCC = ViewArray?.email_bcc;
    const TO = ViewArray?.email_to;
    const emailcc = CC?.map((i) => decryptId(i));
    const emailbcc = BCC?.map((i) => decryptId(i));
    const [offset, setOffset] = useState(null);
    //  BCC?.map((i) => decryptId(i));
    const [emailCC, setEmailCC] = useState(emailcc);
    const [emailBcc, setEmailbcc] = useState(emailbcc);
    const [emailTo, setEmailTo] = useState(TO);
    const [inputValue, setInputValue] = useState("");
    const [inputValue2, setInputValue2] = useState("");
    const [ToInputValue, setToInputValue] = useState("");
    const UsersOptions = props?.UsersOptions;
    const [filteredOptions, setFilteredOptions] = useState([]);

    const [viewEmailBcc, setViewEmailBcc] = useState(false);
    const ReactQuillRef = useRef(null);
    useEffect(() => {
        if (props?.reminderArr) {
            setArray(props?.reminderArr);
        }
    }, [props?.reminderArr]);

    const filterMenuList = [
        {
            text: "Make Shorter",
            value: 1,
            id: 1,
        },
        {
            text: "Make Longer",
            value: 2,
            id: 2,
        },
    ];
    const onFilterItems = (value) => {
        setIsFilter(true);
        switch (value) {
            case 1:
                dispatch(
                    ExceptionEmailGeneration({
                        exception: props?.FindSelectName,
                        email_size: "small",
                        task: "0"
                    })
                );
                break;
            case 2:
                dispatch(
                    ExceptionEmailGeneration({
                        exception: props?.FindSelectName,
                        email_size: "large",
                        task: "0"
                    })
                );
                break;
            default:
                break;
        }
    };
    useEffect(() => {
        setEmailTo(ViewArray?.email_to);
        setEmailCC(ViewArray?.email_cc);
        setEmailbcc(ViewArray?.email_bcc);
        setSubject(ViewSubject);
        setEmailmessage(ViewArray?.email_body);
        array?.forEach((element, i) => {
            if (i == selectedIndex) {
                element.email_sub = ViewSubject;
            }
        });
        setCategoryArr((prevCheck) => {
            return prevCheck.map((ele) => {
                // Check and update the parent object
                if (props?.statustype == 1 && ele?.outside_id === props?.currentId) {
                    return {
                        ...ele,
                        escalation_remainder: ele.escalation_remainder.map((reminder, i) => {
                            if (i === selectedIndex) {
                                return {
                                    ...reminder,
                                    email_sub: ViewSubject,
                                };
                            }
                            return reminder;
                        }),
                    };
                }

                // Check and update the child exceptions
                if (ele?.child_exception) {
                    const updatedChildExceptions = ele.child_exception.map((child) => {
                        if (props?.statustype == 2 && child?.inside_id === props?.currentId) {
                            return {
                                ...child,
                                escalation_remainder: child.escalation_remainder.map((reminder, i) => {
                                    if (i === selectedIndex) {
                                        return {
                                            ...reminder,
                                            email_sub: ViewSubject,
                                        };
                                    }
                                    return reminder;
                                }),
                            };
                        }
                        return child;
                    });

                    return {
                        ...ele,
                        child_exception: updatedChildExceptions,
                    };
                }

                return ele;
            });
        });

        // dispatch(getEmailTemplatePlaceHolderList());
    }, [ViewArray?.email_cc, ViewArray?.email_bcc, ViewSubject, ViewArray?.email_to]);

    const modules = {
        toolbar: [["bold", "italic", "underline", "blockquote"], [{ list: "ordered" }, { list: "bullet" }], ["clean"]],
    };
    const formats = [
        // "header",
        "bold",
        "italic",
        "underline",
        // "strike",
        "blockquote",
        "list",
        "bullet",
        // "indent",
        "link",
        "image",
    ];

    const [showing, setShowing] = useState(null);
    const [showing2, setShowing2] = useState(null);
    const [focused, setFocused] = useState("");

    const ReacthandleClick = (event) => {
        setShowing2(true);
    };
    const handleClickAway = (e) => {
        setShowing(false);
        setShowing2(false);
    };

    const handleEmailSubject = (e) => {
        setSubject(e.target.value);

        array?.forEach((element, i) => {
            if (i == selectedIndex) {
                element.email_sub = e.target.value;
            }
        });
        setCategoryArr((prevCheck) => {
            return prevCheck.map((ele) => {
                // Check and update the parent object
                if (props?.statustype == 1 && ele?.outside_id === props?.currentId) {
                    return {
                        ...ele,
                        escalation_remainder: ele.escalation_remainder.map((reminder, i) => {
                            if (i === selectedIndex) {
                                return {
                                    ...reminder,
                                    email_sub: e.target.value,
                                };
                            }
                            return reminder;
                        }),
                    };
                }

                // Check and update the child exceptions
                if (ele?.child_exception) {
                    const updatedChildExceptions = ele.child_exception.map((child) => {
                        if (props?.statustype == 2 && child?.inside_id === props?.currentId) {
                            return {
                                ...child,
                                escalation_remainder: child.escalation_remainder.map((reminder, i) => {
                                    if (i === selectedIndex) {
                                        return {
                                            ...reminder,
                                            email_sub: e.target.value,
                                        };
                                    }
                                    return reminder;
                                }),
                            };
                        }
                        return child;
                    });

                    return {
                        ...ele,
                        child_exception: updatedChildExceptions,
                    };
                }

                return ele;
            });
        });
    };
    const handleMessageClick = (name) => {
        const percent = "%" + name + "%";
        const replacemessage = emailMessage?.replace(null, "");
        const NewMessage = (replacemessage == undefined ? "" : replacemessage) + percent;
        setEmailmessage(NewMessage);
        // ReactQuillRef?.current?.editor?.setData(NewMessage);
    };
    //////////////////////////////////////////////

    const ReactFocus = () => {
        setFocused("React");
    };
    // Example of a drop event handler

    const handleEmailTO = (newVal) => {
        setEmailTo(newVal);
        array?.forEach((element, i) => {
            if (i == selectedIndex) {
                element.email_to = newVal;
            }
        });

        setCategoryArr((prevCheck) => {
            return prevCheck.map((ele) => {
                // Check and update the parent object
                if (props?.statustype == 1 && ele?.outside_id === props?.currentId) {
                    return {
                        ...ele,
                        escalation_remainder: ele.escalation_remainder.map((reminder, i) => {
                            if (i === selectedIndex) {
                                return {
                                    ...reminder,
                                    email_to: newVal,
                                };
                            }
                            return reminder;
                        }),
                    };
                }

                // Check and update the child exceptions
                if (ele?.child_exception) {
                    const updatedChildExceptions = ele.child_exception.map((child) => {
                        if (props?.statustype == 2 && child?.inside_id === props?.currentId) {
                            return {
                                ...child,
                                escalation_remainder: child.escalation_remainder.map((reminder, i) => {
                                    if (i === selectedIndex) {
                                        return {
                                            ...reminder,
                                            email_to: newVal,
                                        };
                                    }
                                    return reminder;
                                }),
                            };
                        }
                        return child;
                    });

                    return {
                        ...ele,
                        child_exception: updatedChildExceptions,
                    };
                }

                return ele;
            });
        });
    };

    const handleEmailCC = (newVal) => {
        setEmailCC(newVal);
        array?.forEach((element, i) => {
            if (i == selectedIndex) {
                element.email_cc = newVal;
            }
        });

        setCategoryArr((prevCheck) => {
            return prevCheck.map((ele) => {
                // Check and update the parent object
                if (props?.statustype == 1 && ele?.outside_id === props?.currentId) {
                    return {
                        ...ele,
                        escalation_remainder: ele.escalation_remainder.map((reminder, i) => {
                            if (i === selectedIndex) {
                                return {
                                    ...reminder,
                                    email_cc: newVal,
                                };
                            }
                            return reminder;
                        }),
                    };
                }

                // Check and update the child exceptions
                if (ele?.child_exception) {
                    const updatedChildExceptions = ele.child_exception.map((child) => {
                        if (props?.statustype == 2 && child?.inside_id === props?.currentId) {
                            return {
                                ...child,
                                escalation_remainder: child.escalation_remainder.map((reminder, i) => {
                                    if (i === selectedIndex) {
                                        return {
                                            ...reminder,
                                            email_cc: newVal,
                                        };
                                    }
                                    return reminder;
                                }),
                            };
                        }
                        return child;
                    });

                    return {
                        ...ele,
                        child_exception: updatedChildExceptions,
                    };
                }

                return ele;
            });
        });
    };
    const handleEmailBCC = (newVal) => {
        setEmailbcc(newVal);
        array?.forEach((element, i) => {
            if (i == selectedIndex) {
                element.email_bcc = newVal;
            }
        });

        setCategoryArr((prevCheck) => {
            return prevCheck.map((ele) => {
                // Check and update the parent object
                if (props?.statustype == 1 && ele?.outside_id === props?.currentId) {
                    return {
                        ...ele,
                        escalation_remainder: ele.escalation_remainder.map((reminder, i) => {
                            if (i === selectedIndex) {
                                return {
                                    ...reminder,
                                    email_bcc: newVal,
                                };
                            }
                            return reminder;
                        }),
                    };
                }

                // Check and update the child exceptions
                if (ele?.child_exception) {
                    const updatedChildExceptions = ele.child_exception.map((child) => {
                        if (props?.statustype == 2 && child?.inside_id === props?.currentId) {
                            return {
                                ...child,
                                escalation_remainder: child.escalation_remainder.map((reminder, i) => {
                                    if (i === selectedIndex) {
                                        return {
                                            ...reminder,
                                            email_bcc: newVal,
                                        };
                                    }
                                    return reminder;
                                }),
                            };
                        }
                        return child;
                    });

                    return {
                        ...ele,
                        child_exception: updatedChildExceptions,
                    };
                }

                return ele;
            });
        });
    };
    const handleOpenBcc = () => {
        setViewEmailBcc(true);
    };
    const handleEmailBody = (e) => {
        setEmailmessage(e);
        array?.forEach((element, i) => {
            if (i == selectedIndex) {
                element.email_body = e;
            }
        });
        setCategoryArr((prevCheck) => {
            return prevCheck.map((ele) => {
                // Check and update the parent object
                if (props?.statustype == 1 && ele?.outside_id === props?.currentId) {
                    return {
                        ...ele,
                        escalation_remainder: ele?.escalation_remainder?.map((reminder, i) => {
                            if (i === selectedIndex) {
                                return {
                                    ...reminder,
                                    email_body: e,
                                };
                            }
                            return reminder;
                        }),
                    };
                }

                // Check and update the child exceptions
                if (ele?.child_exception) {
                    const updatedChildExceptions = ele?.child_exception.map((child) => {
                        if (props?.statustype == 2 && child?.inside_id === props?.currentId) {
                            return {
                                ...child,
                                escalation_remainder: child?.escalation_remainder?.map((reminder, i) => {
                                    if (i === selectedIndex) {
                                        return {
                                            ...reminder,
                                            email_body: e,
                                        };
                                    }
                                    return reminder;
                                }),
                            };
                        }
                        return child;
                    });

                    return {
                        ...ele,
                        child_exception: updatedChildExceptions,
                    };
                }

                return ele;
            });
        });
    };

    const handleBlur = () => {
        const editor = ReactQuillRef.current.getEditor();

        const selection = editor.getSelection();
        const cursorPosition = selection ? selection.index : -1; // -1 if no selection
        // Get the container text
        const containerText = editor.getText(cursorPosition);
        setOffset(cursorPosition);
    };

    const handleEmailDrop = (e) => {
        e.preventDefault();
        const data = "%" + e.dataTransfer.getData("text/plain") + "%";
        // Access the Quill instance using the quillRef
        const quill = ReactQuillRef.current.getEditor();
        const currentContent = quill.getText();
        const selection = quill.getSelection();
        const insertIndex = selection ? selection.index : currentContent.length;
        // Append the dropped data to the existing content
        const updatedContent = currentContent.slice(0, insertIndex) + data + currentContent.slice(insertIndex);
        quill.setText(updatedContent); // Update the content in Quill
        // Set the editorHtml state with the updated content
        setEmailmessage(updatedContent);
        setNotificationVal((prevVal) => ({
            ...prevVal,
            email_body: updatedContent,
        }));
        setCheck((prevCheck) => {
            // Assuming selectedIndex is the index where you want to update the user_set property
            const updatedCheck = prevCheck.map((ele, i) => {
                if (i === selectedIndex) {
                    return {
                        ...ele,
                        notification: {
                            ...ele?.notification,
                            email_body: updatedContent,
                        },
                    };
                }
                return ele;
            });
            return updatedCheck;
        });
        setCategoryArr((prevCheck) => {
            return prevCheck.map((ele) => {
                // Check and update the parent object
                if (props?.statustype == 1 && ele?.outside_id === props?.currentId) {
                    return {
                        ...ele,
                        escalation_remainder: ele.escalation_remainder.map((reminder, i) => {
                            if (i === selectedIndex) {
                                return {
                                    ...reminder,
                                    email_body: updatedContent,
                                };
                            }
                            return reminder;
                        }),
                    };
                }

                // Check and update the child exceptions
                if (ele?.child_exception) {
                    const updatedChildExceptions = ele.child_exception.map((child) => {
                        if (props?.statustype == 2 && child?.inside_id === props?.currentId) {
                            return {
                                ...child,
                                escalation_remainder: child.escalation_remainder.map((reminder, i) => {
                                    if (i === selectedIndex) {
                                        return {
                                            ...reminder,
                                            email_body: updatedContent,
                                        };
                                    }
                                    return reminder;
                                }),
                            };
                        }
                        return child;
                    });

                    return {
                        ...ele,
                        child_exception: updatedChildExceptions,
                    };
                }

                return ele;
            });
        });
    };
    const Submit = () => {
        if (!subject) {
            Swal.fire({
                allowOutsideClick: false,
                showCancelButton: true,
                showCloseButton: true,
                text: "Configure email communication before setting up escalations and reminders.",
                icon: "warning",
                buttons: {
                    confirm: "Confirm",
                },
            });
        }
        else if (emailTo?.length == 0 || !emailTo) {
            Swal.fire({
                allowOutsideClick: false,
                showCancelButton: true,
                showCloseButton: true,
                text: "'To' field is required",
                icon: "warning",
                buttons: {
                    confirm: "Confirm",
                },
            });
        } else if (!emailMessage) {
            Swal.fire({
                allowOutsideClick: false,
                showCancelButton: true,
                showCloseButton: true,
                text: "Message is required",
                icon: "warning",
                buttons: {
                    confirm: "Confirm",
                },
            });
        } else {
            props?.handleClose();
        }
    };
    const handleClose = () => {
        // setArray(firstArray);
        props?.handleClose();
    };
    const [isFilter, setIsFilter] = useState(false);
    useEffect(() => {
        if (EmailGenerate?.requestComplete && EmailGenerateData && isFilter) {
            setEmailmessage(EmailGenerateData);
            array?.forEach((element, i) => {
                if (i == selectedIndex) {
                    element.email_body = EmailGenerateData;
                    // element.email_sub = EmailGenearteSub;
                }
            });
            setCategoryArr((prevCheck) => {
                return prevCheck.map((ele) => {
                    // Check and update the parent object
                    if (props?.statustype == 1 && ele?.outside_id === props?.currentId) {
                        return {
                            ...ele,
                            escalation_remainder: ele?.escalation_remainder?.map((reminder, i) => {
                                if (i === selectedIndex) {
                                    return {
                                        ...reminder,
                                        email_body: EmailGenerateData,
                                        // email_sub: EmailGenearteSub
                                    };
                                }
                                return reminder;
                            }),
                        };
                    }

                    // Check and update the child exceptions
                    if (ele?.child_exception) {
                        const updatedChildExceptions = ele?.child_exception.map((child) => {
                            if (props?.statustype == 2 && child?.inside_id === props?.currentId) {
                                return {
                                    ...child,
                                    escalation_remainder: child?.escalation_remainder?.map((reminder, i) => {
                                        if (i === selectedIndex) {
                                            return {
                                                ...reminder,
                                                email_body: EmailGenerateData,
                                                // email_sub: EmailGenearteSub
                                            };
                                        }
                                        return reminder;
                                    }),
                                };
                            }
                            return child;
                        });

                        return {
                            ...ele,
                            child_exception: updatedChildExceptions,
                        };
                    }

                    return ele;
                });
            });
        }
        setTimeout(() => {
            dispatch({ type: EMAIL_GENERATE_EXCEPTION_RESET });
            setIsFilter(false);
        }, 3000);
    }, [EmailGenerate?.requestComplete, isFilter]);
    const ModifyEmail = () => {
        setIsFilter(true);
        dispatch(
            ExceptionEmailGeneration({
                exception: props?.FindSelectName,
                email_size: "small",
                task: "0"
            })
        );
    };
    const formattedMessage = emailMessage ? convertNewlinesToHtml(emailMessage) : '';

    return (
        <>
            <Modal cartClose={handleClose} center="center" overlap={true} className={`${props?.className}`}>
                <div
                    className={`w-[590px] mx-auto relative bg-${currentMode}-card  boder-${currentMode}-700 text-${currentMode}-copy overflow-y-auto  mx-auto h-[653px] rounded-[10px] ${currentMode !== "dark" ? " shadow-[0px_4px_20px_rgba(238,238,238,0.5)]" : ""
                        }`}
                >
                    <div
                        className={`w-full  mx-auto relative bg-${currentMode}-1420 h-[49px] boder-${currentMode}-700 text-${currentMode}-copy  mx-auto  rounded-[10px] ${currentMode !== "dark" ? " shadow-[0px_4px_20px_rgba(238,238,238,0.5)]" : ""
                            }`}
                    >
                        <span className="flex px-[19px] py-[15px]">
                            <img src="/images/Exception-Automation/email.svg" className="convertWhite"></img>
                            <span className="ml-3">Email</span>
                        </span>
                        <span onClick={handleClose} title="Close" className="cursor-pointer absolute right-[20px] top-[20px]">
                            <img src="/images/common/closeBlk.svg" className="convertWhite" alt="closeBlk" />
                        </span>
                    </div>
                    {EmailGenerate?.fetching && (
                        <div className="h-screen flex bg-[rgb(231,225,225,0.5)] absolute top-0 z-[12] left-0 right-0 justify-center items-center w-full">
                            {" "}
                            <img className="w-[7rem]" src="/images/gif/Loading-Gif.gif" alt="Loading-Gif" />
                        </div>
                    )}

                    <div className="py-[20px] px-[30px]">
                        <div className={``}>
                            <div className={`flex w-full border-b-[1px] items-center border-${currentMode}-700 pt-[22px] justify-between mt-[-10px]`}>
                                <span className="mr-3 block">To:</span>
                                <Autocomplete
                                    tabIndex={0}
                                    multiple
                                    sx={{
                                        "&.MuiAutocomplete-root .MuiInputBase-root": {
                                            border: 0,
                                            marginLeft: "0",
                                        },
                                        ".MuiInputBase-input": {
                                            color: `${currentMode == "dark" ? "white" : "black"} !important`,
                                        },
                                        "&.MuiFormHelperText-root .MuiFormHelperText-root": {
                                            marginLeft: "35px",
                                        },
                                        "& .MuiChip-label": {
                                            color: `${currentMode == "dark" ? "white" : "black"}`,
                                        },
                                    }}
                                    id="tags-filled"
                                    options={filteredOptions}
                                    value={emailTo == undefined ? [] : emailTo}
                                    inputValue={ToInputValue}
                                    // disableCloseOnSelect
                                    //disableClearable
                                    // autoHighlight={true}
                                    PaperComponent={(props) => filteredOptions?.length ? <Paper {...props} /> : null}
                                    onInputChange={(e, newValue) => {
                                        setToInputValue(newValue);
                                        if (newValue) {
                                            setFilteredOptions(UsersOptions?.filter(option => option?.toLowerCase()?.includes(newValue?.toLowerCase())));
                                        } else {
                                            setFilteredOptions([]);
                                        }
                                    }}
                                    onChange={(e, newval, reason) => {
                                        const errorEmail = newval.find((email) => !regex.test(email));
                                        if (errorEmail) {
                                            // set value displayed in the textbox
                                            setToInputValue(errorEmail);
                                            setError(true);
                                        } else {
                                            setError(false);
                                        }
                                        handleEmailTO(newval?.filter((email) => regex.test(email)));

                                        //  (newval);
                                    }}
                                    disableCloseOnSelect
                                    autoHighlight={true}
                                    freeSolo
                                    style={{ width: "100%", border: 0 }}
                                    limitTags={1}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                checked={selected}
                                                size="small"
                                                sx={{
                                                    color: "#028C78",
                                                    "&.Mui-checked": {
                                                        color: "#028C78",
                                                    },
                                                }}
                                                style={{ marginRight: 8 }}
                                            />
                                            <ListItemText primary={option} style={{ color: `${currentMode === "dark" ? "white" : "black"}` }} />
                                        </li>
                                    )}
                                    renderTags={(value, getTagProps) =>
                                        value &&
                                        value?.map((option, index) => {
                                            // let ValueOption = encryptId(option);
                                            // setEmailCC(ValueOption)
                                            return (
                                                <Chip
                                                    key={index}
                                                    variant="outlined"
                                                    label={option}
                                                    {...getTagProps({ index })}
                                                    onDelete={() => {
                                                        handleEmailTO(emailCC?.filter((e) => e !== option));
                                                    }}
                                                />
                                            );
                                        })
                                    }
                                    renderInput={(params) => (
                                        <TextField fullWidth {...params} placeholder="Enter Email Address" />
                                    )}
                                />
                            </div>
                            <div className={`flex w-full border-b-[1px] items-center border-${currentMode}-700 pt-[22px] justify-between mt-[-10px]`}>
                                <span className="mr-3 block">Cc:</span>
                                <Autocomplete
                                    tabIndex={0}
                                    multiple
                                    sx={{
                                        "&.MuiAutocomplete-root .MuiInputBase-root": {
                                            border: 0,
                                            marginLeft: "0",
                                        },
                                        ".MuiInputBase-input": {
                                            color: `${currentMode == "dark" ? "white" : "black"} !important`,
                                        },
                                        "&.MuiFormHelperText-root .MuiFormHelperText-root": {
                                            marginLeft: "35px",
                                        },
                                        "& .MuiChip-label": {
                                            color: `${currentMode == "dark" ? "white" : "black"}`,
                                        },
                                    }}
                                    id="tags-filled"
                                    options={filteredOptions}
                                    value={emailCC == undefined ? [] : emailCC}
                                    inputValue={inputValue}
                                    // disableCloseOnSelect
                                    //disableClearable
                                    // autoHighlight={true}
                                    PaperComponent={(props) => filteredOptions?.length ? <Paper {...props} /> : null}
                                    onInputChange={(e, newValue) => {
                                        setInputValue(newValue);
                                        if (newValue) {
                                            setFilteredOptions(UsersOptions?.filter(option => option?.toLowerCase()?.includes(newValue?.toLowerCase())));
                                        } else {
                                            setFilteredOptions([]);
                                        }
                                    }}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                checked={selected}
                                                size="small"
                                                sx={{
                                                    color: "#028C78",
                                                    "&.Mui-checked": {
                                                        color: "#028C78",
                                                    },
                                                }}
                                                style={{ marginRight: 8 }}
                                            />
                                            <ListItemText primary={option} style={{ color: `${currentMode === "dark" ? "white" : "black"}` }} />
                                        </li>
                                    )}
                                    onChange={(e, newval, reason) => {
                                        const errorEmail = newval.find((email) => !regex.test(email));
                                        if (errorEmail) {
                                            // set value displayed in the textbox
                                            setInputValue(errorEmail);
                                            setError(true);
                                        } else {
                                            setError(false);
                                        }
                                        handleEmailCC(newval?.filter((email) => regex.test(email)));

                                        //  (newval);
                                    }}
                                    disableCloseOnSelect
                                    autoHighlight={true}
                                    freeSolo
                                    style={{ width: "100%", border: 0 }}
                                    limitTags={1}
                                    renderTags={(value, getTagProps) =>
                                        value &&
                                        value?.map((option, index) => {
                                            // let ValueOption = encryptId(option);
                                            // setEmailCC(ValueOption)
                                            return (
                                                <Chip
                                                    key={index}
                                                    variant="outlined"
                                                    label={option}
                                                    {...getTagProps({ index })}
                                                    onDelete={() => {
                                                        handleEmailCC(emailCC?.filter((e) => e !== option));
                                                    }}
                                                />
                                            );
                                        })
                                    }
                                    renderInput={(params) => (
                                        <TextField fullWidth {...params} placeholder="Enter Email Address" />
                                    )}
                                />
                                {!viewEmailBcc ? (
                                    <Button className="p-0" onClick={handleOpenBcc}>
                                        <span className="block">Bcc</span>
                                    </Button>
                                ) : (
                                    ""
                                )}
                            </div>
                            {viewEmailBcc ? (
                                <div className={`flex w-full items-center border-b-[1px] border-${currentMode}-700 pt-[22px] justify-between mt-[-10px]`}>
                                    <span className="mr-3 block">Bcc:</span>
                                    <Autocomplete
                                        multiple
                                        disablePortal
                                        sx={{
                                            "&.MuiAutocomplete-root .MuiInputBase-root": {
                                                border: 0,
                                                marginLeft: "0",
                                            },
                                            ".MuiInputBase-input": {
                                                color: `${currentMode == "dark" ? "white" : "black"} !important`,
                                            },
                                            "&.MuiFormHelperText-root .MuiFormHelperText-root": {
                                                marginLeft: "35px",
                                            },
                                            "& .MuiChip-label": {
                                                color: `${currentMode == "dark" ? "white" : "black"}`,
                                            },
                                        }}
                                        id="tags-filled"
                                        tabIndex={0}
                                        options={filteredOptions}
                                        value={emailBcc == undefined ? [] : emailBcc}
                                        inputValue={inputValue2}
                                        // disableCloseOnSelect
                                        //disableClearable
                                        // autoHighlight={true}
                                        PaperComponent={(props) => filteredOptions?.length ? <Paper {...props} /> : null}
                                        onInputChange={(e, newValue) => {
                                            setInputValue2(newValue);
                                            if (newValue) {
                                                setFilteredOptions(UsersOptions?.filter(option => option?.toLowerCase()?.includes(newValue?.toLowerCase())));
                                            } else {
                                                setFilteredOptions([]);
                                            }
                                        }}
                                        onChange={(e, newval, reason) => {
                                            const errorEmail = newval.find((email) => !regex.test(email));
                                            if (errorEmail) {
                                                // set value displayed in the textbox
                                                setInputValue2(errorEmail);
                                                setError(true);
                                            } else {
                                                setError(false);
                                            }
                                            handleEmailBCC(newval?.filter((email) => regex.test(email)));

                                            //  (newval);
                                        }}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    checked={selected}
                                                    size="small"
                                                    sx={{
                                                        color: "#028C78",
                                                        "&.Mui-checked": {
                                                            color: "#028C78",
                                                        },
                                                    }}
                                                    style={{ marginRight: 8 }}
                                                />
                                                <ListItemText primary={option} style={{ color: `${currentMode === "dark" ? "white" : "black"}` }} />
                                            </li>
                                        )}
                                        disableCloseOnSelect
                                        freeSolo
                                        style={{ width: "100%", border: 0 }}
                                        limitTags={1}
                                        renderTags={(value, getTagProps) =>
                                            value &&
                                            value?.map((option, index) => {
                                                // let ValueOptions = encryptId(option);
                                                // bccreceivers.push(ValueOptions);
                                                return (
                                                    <Chip
                                                        key={index}
                                                        variant="outlined"
                                                        label={option}
                                                        {...getTagProps({
                                                            index,
                                                        })}
                                                        onDelete={() => {
                                                            handleEmailBCC(emailBcc?.filter((e) => e !== option));
                                                        }}
                                                    />
                                                );
                                            })
                                        }
                                        renderInput={(params) => <TextField fullWidth {...params} placeholder="Enter Email Address" />}
                                    />
                                </div>
                            ) : (
                                ""
                            )}

                            <div className="py-[20px]">
                                <div className="flex justify-between">
                                    <span className="block mb-2">Subject</span>

                                    <TogglePopup ref={childCompRef} menuList={filterMenuList} selectedItemChange={onFilterItems} />

                                    <Button onClick={(e) => childCompRef.current.handleClick("bottom-end", 1, e)}>
                                        <img src="/images/Exception-Automation/Email-generate.svg"></img>
                                    </Button>
                                </div>
                                <Input
                                    tabIndex={0}
                                    value={subject}
                                    readOnly={true}
                                    onChange={(e) => handleEmailSubject(e)}
                                    className={`border-[1px] pointer-events-none rounded-[8px] py-2 px-[12px] w-full border-${currentMode}-840 bg-${currentMode}-810`}
                                    placeholder="Enter your subject"
                                />
                            </div>
                        </div>

                        <div className={` allocate-textarea relative `}>
                            <span className="block">Message</span>
                            <div className="ml-[45px]">
                                <span
                                    onClick={ReacthandleClick}
                                    // aria-describedby={id}
                                    className={`flex absolute right-[5px] top-[1px] block   `}
                                >
                                    Insert Placeholders <img className="ml-2 convertWhite" src="/images/common/chevron-grey.svg" alt="chevronDown" />
                                </span>
                                {showing2 && (
                                    <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={(e) => handleClickAway(e)}>
                                        <div
                                            className={`bg-${currentMode}-card absolute top-[-410px] right-0 border-${currentMode}-1020 border-[1px] z-[8] mt-[32px]  ${currentMode !== "dark" ? `shadow-[0px_4px_20px_rgba(85,85,85,0.2)]` : ``
                                                }  rounded-[10px]`}
                                        >
                                            <div className="grid grid-cols-1 gap-4">
                                                <div className={`py-[20px]`}>
                                                    <span draggable="false" className="font-interSb mb-2 block pointer-events-none px-[30px]">
                                                        Invoice
                                                    </span>
                                                    <ul className="cursor-pointer">
                                                        {invoicePlaceHolderList?.length !== 0 ? (
                                                            invoicePlaceHolderList?.map((el, i) => {
                                                                return (
                                                                    <li
                                                                        onDragStart={(event) => {
                                                                            event.dataTransfer.setData("text/plain", el.placeholder_display_name);
                                                                        }}
                                                                        onDragEnd={(event) => {
                                                                            event.dataTransfer.clearData();
                                                                        }}
                                                                        className={`hover:bg-${currentMode}-960 px-[30px] userDrag py-2 w-full`}
                                                                        key={i}
                                                                        draggable="true"
                                                                        onClick={() => handleMessageClick(el.placeholder_display_name)}
                                                                    >
                                                                        {el.placeholder_display_name}
                                                                    </li>
                                                                );
                                                            })
                                                        ) : (
                                                            <div className="px-[20px]">No data found</div>
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </ClickAwayListener>
                                )}
                            </div>

                            <div onDrop={handleEmailDrop}>
                                <ReactQuill
                                    tabIndex={0}
                                    onDragOver={(event) => event.preventDefault()}
                                    theme="snow"
                                    onFocus={ReactFocus}
                                    ref={ReactQuillRef}
                                    style={{ borderRadius: "8px", marginTop: "18px" }}
                                    value={formattedMessage}
                                    formats={formats}
                                    onBlur={(event) => {
                                        handleBlur();
                                    }}
                                    modules={modules}
                                    disabled={!Emailenable}
                                    onChange={(e) => handleEmailBody(e)}
                                    onSelectionChange={(selection) => {
                                        const cursorPosition = selection ? selection.index : -1;
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex  mt-[30px] space-x-2">
                            <Button
                                title="Save"
                                className="bg-light-20 px-[25px] text-base py-2 text-white rounded-[8px]"
                                type="submit"
                                tabIndex="3"
                                onClick={Submit}
                            >
                                Save
                            </Button>
                            <Button
                                title="Cancel"
                                className="bg-light-240  text-light-40 px-[28px] text-base py-2 rounded-[8px]"
                                onClick={handleClose}
                                tabIndex="3"
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default EmailConfig;
