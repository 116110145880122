import React from "react";
import Card from "../../components/UI/Card";
import Main from "../../components/UI/Main";
import Layout from "../../Hoc/Layout";
import Footer from "../../organisms/Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Modal from "../../components/UI/Modal/Modal";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, useContext } from "react";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import Stack from "@mui/joy/Stack";
import LinearProgress from "@mui/joy/LinearProgress";
import {
    GetGeneralLedgerAccountSync,
    GetCostCenterAccountSync,
    GetInternalOrderAccountSync,
    GetTaxMasterSync,
} from "../../redux/actions/account-sync.js";
import {
    GET_GL_ACCOUNT_SYNC_RESET,
    GET_COST_CENTER_ACCOUNT_SYNC_RESET,
    GET_INTERNAL_ORDER_ACCOUNT_SYNC_RESET,
    GET_TAX_MASTER_SYNC_RESET,
    GET_MISC_WAV_SYNC_RESET,
} from "../../redux/types/types.js";
import { GetGeneralLedgerSyncList } from "../../redux/actions/account-sync.js";
import { WbsVendorAssestSyncAction } from "../../redux/actions/miscellaneous-request";
import Loader from "../../components/Loader";
import ReactDOM from "react-dom";
import AccountSync from "../../pages/Settings/AccountSync";
let temp;
let InvId = [];
let StatusValue = [];
const AccountSynchronization = (props) => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const [open, setOpen] = useState(false);
    const orgData = useSelector((state) => state?.ListorganisationDropDownState);
    const orgListData = orgData?.viewData?.filter((ele) => ele?.istarget == true);
    const orgId = orgListData?.map((el) => el?.id);
    const [index, setIndex] = useState(0);
    const { fetching: GLFetch, requestComplete, error, glData } = useSelector((state) => state?.GetGLAccountSyncState);
    const { fetching: CCFetch, requestComplete: reqComp } = useSelector((state) => state?.GetCostCenterAccountSyncState);
    const { fetching: IoFetch, requestComplete: req } = useSelector((state) => state?.GetIOAccountSyncState);
    const { fetching: taxFetch, requestComplete: taxReqComp } = useSelector((state) => state?.GetTaxMasterSyncState);
    const { fetching: WAVFetch, requestComplete: WbsReqComp } = useSelector((state) => state?.getWavSyncState);
    const GLData = useSelector((state) => state?.GetGLAccountSyncState);
    const CCData = useSelector((state) => state?.GetCostCenterAccountSyncState);
    const IOData = useSelector((state) => state?.GetIOAccountSyncState);
    const TaxData = useSelector((state) => state?.GetTaxMasterSyncState);
    const WavData = useSelector((state) => state?.getWavSyncState);
    const [sync, setSync] = useState(false);
    const [syncType, setSyncType] = useState("");
    const [showOrgName, setShowOrgName] = useState("");
    const getOrgName = orgListData?.filter((el) => el?.id == showOrgName);
    const dispatch = useDispatch();
    const ProgressClose = () => {
        props?.handleOpen();
        setOpen(false);
        dispatch({ type: GET_MISC_WAV_SYNC_RESET });
        dispatch(GetGeneralLedgerSyncList());
        setProgress(0);
        setSyncType("");
    };
    const [progress, setProgress] = useState(0);
    const DisplayRole = useSelector((state) => state?.DisplayRolePermissionStateAction);
    const orgLogo = localStorage.getItem("orgLogo");
    const { data: DataList } = DisplayRole;
    const RoleList = DataList?.role_info;
    const RoleMenu = RoleList?.menu;
    const RoleMisc = RoleMenu?.find((elem) => elem?.menu_id === 22);
    const RoleActMisc = RoleMisc?.actions;
    const checkMisc = RoleActMisc?.some((element) => element.isallowed === true);
    const { syncData, requestComplete: listReqComp } = useSelector((state) => state?.GetGeneralLedgerSyncState);
    const FilterSyncData = checkMisc ? syncData : syncData?.filter((el) => el?.sync_type !== 5 && el?.sync_type !== 6 && el?.sync_type !== 7);
    const handleSyncAcc = async (sync_type) => {
        setsyncOpen(true);
        setOpen(true);
        setSync(true);
        if (sync_type == 1) {
            setIndex(0);
            setProgress(0);
            setSyncType("GL");
        } else if (sync_type == 2) {
            setIndex(0);
            setProgress(0);
            setSyncType("CC");
        } else if (sync_type == 3) {
            setIndex(0);
            setProgress(0);
            setSyncType("IO");
        } else if (sync_type == 4) {
            setIndex(0);
            setProgress(0);
            setSyncType("TM");
        } else if (sync_type == 5) {
            setIndex(0);
            setProgress(0);
            setSyncType("WBS");
        } else if (sync_type == 6) {
            setIndex(0);
            setProgress(0);
            setSyncType("AS");
        } else if (sync_type == 7) {
            setIndex(0);
            setProgress(0);
            setSyncType("VM");
        }
    };
    const [syncOpen, setsyncOpen] = useState(false);

    useEffect(() => {
        dispatch(GetGeneralLedgerSyncList());
    }, [dispatch]);
    useEffect(() => {
        if (requestComplete == false && reqComp == false && req == false && taxReqComp == false && WbsReqComp == false && orgId?.length > index) {
            if (sync) {
                if (syncType == "GL") {
                    dispatch(GetGeneralLedgerAccountSync(orgId[index]));
                } else if (syncType == "CC") {
                    dispatch(GetCostCenterAccountSync(orgId[index]));
                } else if (syncType == "IO") {
                    dispatch(GetInternalOrderAccountSync(orgId[index]));
                } else if (syncType == "TM") {
                    dispatch(GetTaxMasterSync(orgId[index]));
                } else if (syncType == "WBS" || syncType == "AS" || syncType == "VM") {
                    dispatch(WbsVendorAssestSyncAction(orgId[index], syncType == "WBS" ? 2 : syncType == "AS" ? 1 : 3));
                }
                temp = index + 1;
                setIndex(temp);
                if (orgId?.length == temp) {
                    setSync(false);
                }
            }
            setShowOrgName(orgId[index]);
        } else if (syncType == "GL" && requestComplete) {
            const ErrorStatus = GLData?.error?.error;
            const status = GLData?.glData?.status ? GLData?.glData?.status : ErrorStatus;
            const myObject = {
                orgId: orgId[index],
                status: status,
            };
            InvId?.push(myObject);
            StatusValue?.push(status);
            setProgress((index * 100) / orgId?.length);
            dispatch({ type: GET_GL_ACCOUNT_SYNC_RESET });
        } else if (syncType == "CC" && reqComp) {
            const ErrorStatus = CCData?.error?.error;
            const status = CCData?.ccData?.status ? CCData?.ccData?.status : ErrorStatus;
            const myObject = {
                orgId: orgId[index],
                status: status,
            };
            InvId?.push(myObject);
            StatusValue?.push(status);
            setProgress((index * 100) / orgId?.length);
            dispatch({ type: GET_COST_CENTER_ACCOUNT_SYNC_RESET });
        } else if (syncType == "IO" && req) {
            const ErrorStatus = IOData?.error?.error;
            const status = IOData?.Data?.status ? IOData?.Data?.status : ErrorStatus;
            const myObject = {
                orgId: orgId[index],
                status: status,
            };
            InvId?.push(myObject);
            StatusValue?.push(status);
            setProgress((index * 100) / orgId?.length);
            dispatch({ type: GET_INTERNAL_ORDER_ACCOUNT_SYNC_RESET });
        } else if (syncType == "TM" && taxReqComp) {

            const ErrorStatus = TaxData?.error?.error;
            const status = TaxData?.syncData?.status ? TaxData?.syncData?.status : ErrorStatus;
            const myObject = {
                orgId: orgId[index],
                status: status,
            };
            InvId?.push(myObject);
            StatusValue?.push(status);
            setProgress((index * 100) / orgId?.length);
            dispatch({ type: GET_TAX_MASTER_SYNC_RESET });
        } else if ((syncType == "WBS" || syncType == "AS" || syncType == "VM") && WbsReqComp) {
            // const ErrorStatus = WavError;
            // const status = WavData?.data?.status;
            // const myObject = {
            //     InvId: ShowSeqName,
            //     status: status,
            // };
            const ErrorStatus = WavData?.error?.error;
            const status = WavData?.data?.status ? WavData?.data?.status : ErrorStatus;
            const myObject = {
                orgId: orgId[index],
                status: status,
            };
            InvId?.push(myObject);
            StatusValue?.push(status);
            setProgress((index * 100) / orgId?.length);
            dispatch({ type: GET_MISC_WAV_SYNC_RESET });
        }
        //  else {
        //     setInterval(() => {
        //         setProgress(100);
        //     }, 1800);
        // }
    }, [requestComplete, sync, reqComp, req, taxReqComp, WbsReqComp]);
    const FilterSuccessInv = InvId?.filter((el) => el?.status == true);
    const FilterFailedInv = InvId?.filter((el) => el?.status !== true);
    const OrgLength = FilterSuccessInv?.length
    const FailedLength = FilterFailedInv?.length
    const handleClose = () => {
        InvId = [];
        StatusValue = []
        setsyncOpen(false)
    }
    return (
        <Layout>
            <Main>
                <div className={`text-${currentMode}-copy flex justify-between items-center mb-6`}>
                    <div>
                        <h4 className="text-2xl font-interSb">Account Synchronization</h4>
                    </div>
                    <div className=" flex text-right">
                        <Link tabIndex={0} to="/admin-setting" title="Back">
                            <Button
                                tabIndex={0}
                                className={`hover:bg-light-20 hover:text-white rounded-[6px]  ml-2 w-[80px] border-[1px] border-[#8D9097] hover:border-0  h-[30px]`}
                                title={"Back"}
                            >
                                {"Back"}
                            </Button>
                        </Link>
                    </div>
                </div>
                <Card
                    className={`xl:h-[calc(100vh_-_14rem)] p-[30px] rounded-t-[10px] rounded-b-[10] w-full overflow-y-auto${currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                        }  pb-0 relative bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700`}
                >
                    {/* <div className="flex">
                        <Button tabIndex={0} className="bg-light-20 text-white rounded-[8px] ml-2 w-[95px] h-[40px]" title={"Sync All"}>
                            {"Sync All"}
                        </Button>
                        <div className="ml-[10px] mt-[10px]">
                            <p>
                                Click <span className="font-interB">Sync All</span> to synchronize the following accounts related feature.
                            </p>
                        </div>
                    </div> */}
                    <div className="mt-6 flex space-x-[20px]">
                        {listReqComp ? (
                            FilterSyncData?.length !== 0 ? (
                                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 w-full">
                                    {FilterSyncData?.map((el, index) => {
                                        return (
                                            <Card
                                                key={index}
                                                className={`p-[20px] rounded-t-[10px] rounded-b-[10px] overflow-y-auto ${currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                                                    } relative bg-${currentMode}-card  text-${currentMode}-copy border-${currentMode}-700`}
                                            >
                                                <div className="flex items-start space-x-3 text-left">
                                                    <img
                                                        src={
                                                            el?.sync_type == 1
                                                                ? `/images/common/GAccount.svg`
                                                                : el?.sync_type == 2
                                                                    ? `/images/common/cost-center.svg`
                                                                    : el?.sync_type == 3
                                                                        ? `/images/settings/internal-order.svg`
                                                                        : el?.sync_type == 4
                                                                            ? `/images/settings/tax_sync.svg`
                                                                            : el?.sync_type == 5
                                                                                ? `/images/mobilization/wbs.svg`
                                                                                : el?.sync_type == 6
                                                                                    ? `/images/mobilization/assets.svg`
                                                                                    : el?.sync_type == 7
                                                                                        ? `/images/mobilization/vendor master.svg`
                                                                                        : ""
                                                        }
                                                    />
                                                    <p className={`${currentMode == "dark" ? "" : "text-light-40 font-interM mt-2"}`}>
                                                        {el?.sync_type == 1
                                                            ? "GL Account"
                                                            : el?.sync_type == 2
                                                                ? "Cost Center"
                                                                : el?.sync_type == 3
                                                                    ? "Internal Order"
                                                                    : el?.sync_type == 4
                                                                        ? "Tax Master"
                                                                        : el?.sync_type == 5
                                                                            ? "WBS Element"
                                                                            : el?.sync_type == 6
                                                                                ? "Asset"
                                                                                : el?.sync_type == 7
                                                                                    ? "Vendor Master"
                                                                                    : ""}
                                                    </p>
                                                </div>
                                                <div className="mt-[20px] mb-[20px]">
                                                    <span className="block">
                                                        {el?.sync_type == 1
                                                            ? "Configure to sync your GL account"
                                                            : el?.sync_type == 2
                                                                ? "Configure to sync your Cost Center"
                                                                : el?.sync_type == 3
                                                                    ? "Configure to sync your Internal Order"
                                                                    : el?.sync_type == 4
                                                                        ? "Configure to sync your Tax Master"
                                                                        : el?.sync_type == 5
                                                                            ? "Configure to sync your WBS Element"
                                                                            : el?.sync_type == 6
                                                                                ? "Configure to sync your Asset"
                                                                                : el?.sync_type == 7
                                                                                    ? "Configure to sync your Vendor Master"
                                                                                    : ""}
                                                    </span>
                                                    <p className={`${currentMode == "dark" ? "" : "text-light-160 text-[12px]"}`}>
                                                        Last sync: {el?.modified_date}
                                                    </p>
                                                </div>
                                                <div>
                                                    <Button
                                                        onClick={() => handleSyncAcc(el?.sync_type)}
                                                        tabIndex={0}
                                                        className="bg-[#00B399] w-[74px] h-[29px] text-white rounded-[8px]"
                                                    >
                                                        Sync
                                                    </Button>
                                                </div>
                                            </Card>
                                        );
                                    })}
                                </div>
                            ) : (
                                <div className="absolute top-[60%] left-[47%] translate-y-[-50%] translate-x-[-50%]">
                                    <img src="/images/common/delivery.png" alt="delivery" />
                                    <h4 className="font-interSb text-center mt-3">No data found</h4>
                                </div>
                            )
                        ) : (
                            <Loader className="h-[calc(100vh_-_15rem)]" />
                        )}
                    </div>
                </Card>
                {ReactDOM.createPortal(
                    <AccountSync
                        isOpened={syncOpen}
                        open={open}
                        setOpen={setOpen}
                        syncType={syncType}
                        handleClose={() => handleClose()}
                        className={syncOpen && "Show"}
                        handleOpen={() => setsyncOpen(true)}
                        progress={progress}
                        setProgress={setProgress}
                        setShowOrgName={setShowOrgName}
                        OrgLength={OrgLength}
                        FailedLength={FailedLength}
                        showOrgName={showOrgName}
                        getOrgName={getOrgName}
                    />,
                    document.getElementById("user-modal-root")
                )}
            </Main>
            <Footer />
        </Layout>
    );
};

export default AccountSynchronization;
