import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ReactDOM from "react-dom";
import Button from "../../components/Button";
import Card from "../../components/UI/Card";
import { getCurrentUser } from "../../redux/actions/auth";
import { decryptId } from "../../utils/helpers";
import Loader from "../../components/Loader";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import CreateEmailTask from "../MyTask/CreateTask/CreateEmailTask";
import CreateTodoTask from "../MyTask/CreateTask/CreateTodoTask";
import { DashboardTaskList } from "../../redux/actions/dashboard";
import { useRef } from "react";

const MyPrioritiesTask = (props) => {
    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }
    const userId = props?.userId;
    const [addTodoTask, setAddTodoTask] = useState(false);
    const [addTask, setAddTask] = useState(false);
    const currentUserDetails = getCurrentUser();
    const profileData = useSelector((state) => state?.userListDropDownState);
    const { data: dataList } = profileData;
    const Data = useSelector((state) => state?.getFreeTrialReducerState);
    const dataLists = dataList?.filter((el) => el?.isactivated !== false || el?.user_isactive !== false);
    const dispatch = useDispatch();
    const orgId = localStorage.getItem("orgId");
    const orgGrupId = localStorage.getItem("orgGroup");
    const { currentMode } = useContext(ThemeContext);
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    const handleTaskFilter = (val) => {
        props?.setFilterVal(val);
        dispatch(DashboardTaskList(orgId, props?.userId, val));
    };
    const CurrentUser = getCurrentUser();
    const letuserId = CurrentUser?.role_id == 1 ? CurrentUser?.id : userId;
    useEffect(() => {
        dispatch(DashboardTaskList(orgId, letuserId, props?.filterVal));
    }, []);
    useEffect(() => {
        props?.setFilterVal(0);
    }, [orgId]);
    const listData = useSelector((state) => state?.DashboardTaskListState);
    const taskListData = listData?.data?.datalist;
    return (
        <div className="basis-4/6 h-full">
            <Card className="h-full">
                <div className={`flex justify-between items-center px-[30px] py-[22px] mb-0 border-b-[1px] border-${currentMode}-710 items-center`}>
                    <h4 className="font-interM text-lg">My Priorities</h4>
                    <div className="basis-1 flex">
                        <div className="basis-1/2">
                            <div>
                                <FormControl
                                    sx={{
                                        minWidth: "169px",
                                        paddingTop: "0px",
                                    }}>
                                    <Select
                                        value={props?.filterVal || "0"}
                                        name="time"
                                        onChange={(e) => handleTaskFilter(e.target.value)}
                                        inputProps={{
                                            "aria-label": "Without label",
                                        }}
                                        IconComponent={() => (
                                            <img
                                                className="absolute right-[15px] opacity-50 pointer-events-none"
                                                src={
                                                    currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`
                                                }
                                            />
                                        )}
                                        MenuProps={{
                                            sx: {
                                                "& .MuiMenu-paper": {
                                                    backgroundColor: dropdownColor,
                                                    color: dropdownText,
                                                },
                                                "& .MuiMenuItem-root:hover": {
                                                    backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                },
                                                "& .Mui-selected": {
                                                    backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                },
                                            },
                                        }}
                                        SelectDisplayProps={{
                                            style: {
                                                color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                padding: "9px 30px 9px 15px",
                                                border: "1px solid rgba(85, 85, 85, 0.4)",
                                                borderRadius: "8px",
                                            },
                                        }}>
                                        <MenuItem name="time" value={0}>
                                            {"All"}
                                        </MenuItem>
                                        <MenuItem name="time" value={1}>
                                            {"Today's Task"}
                                        </MenuItem>
                                        <MenuItem name="time" value={2}>
                                            {"Due this week"}
                                        </MenuItem>
                                        <MenuItem name="time" value={3}>
                                            {"Overdue"}
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ${taskListData?.length == 0 && "min-h-[400px]"} 
                        ${listData?.fetching && "min-h-[400px]"} */}
                <div
                    className={`h-full max-h-[400px] overflow-y-auto relative report
                     
                        `}>
                    <table className="table-auto TblNormal invoice mb-0 reports">
                        <thead className={`sticky top-0 z-[1]`}>
                            <tr className={`bg-${currentMode}-450 h-[45px]`}>
                                <th>Task ID</th>
                                <th>Task Name</th>
                                <th>Invoice Id</th>
                                <th>Invoice Number</th>
                                <th></th>
                                <th>
                                    {/* <span className="font-interM flex"> */}
                                    {/* <img className="mr-2 convertWhite" src="/images/main/priority-icon.svg" alt="user-plusblk" /> */}
                                    Priority {/* </span> */}
                                </th>
                                <th>
                                    {/* <span className="font-interM flex">
                                        <img className="mr-2 convertWhite" src="/images/invoice/user-plusblk.svg" alt="user-plusblk" /> */}
                                    Assignees
                                    {/* </span> */}
                                </th>
                                <th>
                                    {" "}
                                    {/* <span className="font-interM flex"> */}
                                    {/* <img src="/images/invoice/calendar.svg" className="mr-2 convertWhite" alt="calendar" /> */}
                                    Due Date
                                    {/* </span> */}
                                </th>
                                <th>
                                    {/* <span className="font-interM flex"> */}
                                    {/* <img className="mr-2 convertWhite" src="/images/invoice/user-plusblk.svg" alt="user-plusblk " /> */}
                                    Created By {/* </span> */}
                                </th>
                                <th>
                                    {/* <span className="font-interM flex"> */}
                                    {/* <img
                                            className="mr-2"
                                            src="/images/invoice/user-plusblk.svg"
                                            alt="user-plusblk"
                                        /> */}
                                    Task Status {/* </span> */}
                                </th>
                            </tr>
                        </thead>
                        <>
                            {listData?.fetching ? (
                                <div className="grid place-items-center absolute top-[50%] left-[50%] translate-x-[-50%]">
                                    <Loader className="w-full" />
                                </div>
                            ) : (
                                <tbody>
                                    {taskListData !== null && taskListData?.length !== 0 ? (
                                        taskListData?.map((elem, index) => {
                                            return (
                                                <tr key={index} className={`border-b-[1px] border-${currentMode}-700 h-[50px]`}>
                                                    {" "}
                                                    {/* <img
                                                        src={
                                                            "/images/main/circle.svg"
                                                        }
                                                        className="ml-5 mt-4
                                                        h-[14px] w-[14px]"
                                                        alt="circle"
                                                    /> */}
                                                    <td>
                                                        <button
                                                            title={elem?.task_no}
                                                            className={`border-[1px] cursor-default ml-[2px] ${elem?.task_no?.length > 6 ? `truncate w-[100px]` : ``
                                                                }  whitespace-nowrap px-1 bg-${currentMode}-card rounded-[2px] my-auto mx-auto text-[12px] border-${currentMode}-700 text-${currentMode}-copy `}>
                                                            {elem?.task_no}
                                                        </button>
                                                    </td>
                                                    <td className="max-w-[180px] truncate" title={elem?.task_name}>
                                                        {elem?.task_name}
                                                    </td>
                                                    <td className="max-w-[180px] truncate" title={elem?.inv_seq}>
                                                        {elem?.inv_seq}
                                                    </td>
                                                    <td className="max-w-[180px] truncate" title={elem?.invoice_no}>
                                                        {elem?.invoice_no}
                                                    </td>
                                                    <td>
                                                        <Button
                                                            className={`border-[1px]  whitespace-nowrap cursor-default  px-2 rounded-[5px] my-auto mx-auto text-[12px] border-transparent text-white ${elem?.task_type === 1 ? "bg-[rgba(94,101,174,1)]" : "bg-[rgba(1,148,186,1)]"
                                                                }`}>
                                                            {elem?.task_type === 1 ? "Internal" : "External"}
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        <Button
                                                            className={`border-[0] font-interM cursor-default rounded-[2px] text-[12px] px-2  text-white ${elem?.task_priority === "High"
                                                                ? "bg-[rgba(255,114,94,1)]"
                                                                : elem?.task_priority === "Low"
                                                                    ? "bg-[rgba(0,177,162,1)]"
                                                                    : elem?.task_priority === "Medium"
                                                                        ? "bg-[rgba(251,189,3,1)]"
                                                                        : elem?.task_priority === "Urgent"
                                                                            ? "bg-[rgba(215,13,13,1)]"
                                                                            : ""
                                                                }`}>
                                                            {elem?.task_priority}
                                                        </Button>
                                                    </td>
                                                    <td className="truncate max-w-[10px]">{elem?.assignee_name}</td>
                                                    <td>{elem?.due_date}</td>
                                                    <td>{elem?.created_user}</td>
                                                    <td>
                                                        {" "}
                                                        {elem?.task_status == 1
                                                            ? "In Progress"
                                                            : elem?.task_status == 3
                                                                ? "Done"
                                                                : elem?.task_status == 4
                                                                    ? "Assign"
                                                                    : "Completed"}
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <div className="absolute  top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]">
                                            <img src="/images/common/delivery.png" alt="delivery" />
                                            <h4 className="font-interSb text-center mt-3">No data found</h4>
                                        </div>
                                    )}
                                </tbody>
                            )}
                        </>
                    </table>
                </div>
                {listData?.requestComplete && taskListData?.length !== 0 && taskListData !== undefined && (
                    <Link
                        to="/my-task/list"
                        className={`sticky bottom-0  font-interM text-[14px] text-[#028C78] items-center mt-4 pb-[20px] px-[30px] cursor-pointer justify-center`}>
                        Show more
                    </Link>
                )}
            </Card>
            {(addTodoTask || addTask) && (
                <>
                    {ReactDOM.createPortal(
                        <CreateEmailTask
                            isOpened={addTask}
                            handleClose={() => setAddTask(false)}
                            className={`${addTask && "Show"}`}
                        // invoiceId={upperBody?.invoice_id}
                        // parentId={upperBody?.task_id}
                        />,
                        document.getElementById("user-modal-root")
                    )}
                    {ReactDOM.createPortal(
                        <CreateTodoTask
                            isOpened={addTodoTask}
                            // userListData={userListData}
                            handleClose={() => setAddTodoTask(false)}
                            className={`${addTodoTask && "Show"}`}
                        // invoiceId={upperBody?.invoice_id}
                        // parentId={upperBody?.task_id}
                        />,
                        document.getElementById("user-modal-root")
                    )}
                </>
            )}
        </div>
    );
};

export default MyPrioritiesTask;
