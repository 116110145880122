import Modal from "../../../components/UI/Modal/Modal";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import { useContext, useState } from "react";
import Input from "../../../components/Input";
import Button from "../../../components/Button";

const CreateReminderModal = (props) => {
    const { currentMode } = useContext(ThemeContext);
    const reminderArr = props?.reminderArr;
    const setReminderArr = props?.setReminderArr;
    const setCategoryArr = props?.setCategoryArr;
    const [reminderName, setReminderName] = useState("");
    const [errorMsg, setErrorMsg] = useState("");

    const selectedId = props?.selectedId;
    const setSelectedId = props?.setSelectedId;
    if (props?.isOpened) {
        var textarea = document?.getElementById("Reminder");
        textarea?.focus();
    }
    const handleClose = () => {
        props?.handleClose();
        setReminderName("");
        setErrorMsg("");
    };
    const Submit = async () => {
        const ReminderObj = {
            reminder_id: 0,
            reminder_name: reminderName?.trim(),
            esc_from_date: "",
            esc_to_date: "",
            isfrequency: false,
            esc_frequency: 0,
            isExpand: "0",
            email_to: [],
            email_cc: [],
            email_bcc: [],
            email_sub: "",
            email_body: "",
        };
        if (reminderName?.trim() == "") {
            setErrorMsg("Escalation and Reminder Name is required");
        } else {
            setReminderArr((prevReminders) => [...prevReminders, ReminderObj]);
            setSelectedId((prevVal) => {
                // Initialize escalation_remainder array if it doesn't exist
                const updatedEscalationRemainder = [...(prevVal?.escalation_remainder ?? []), ReminderObj];

                return {
                    ...prevVal,
                    escalation_remainder: updatedEscalationRemainder,
                };
            });
            setCategoryArr((prevCheck) => {
                return prevCheck.map((ele) => {
                    // Check and update the parent object
                    if (props?.statustype == 1 && ele?.outside_id === props?.currentId) {
                        const updatedEscalationRemainder = [...(ele.escalation_remainder ?? []), ReminderObj];
                        return {
                            ...ele,
                            escalation_remainder: updatedEscalationRemainder,
                        };
                    }

                    // Check and update the child exceptions
                    if (ele?.child_exception) {
                        const updatedChildExceptions = ele.child_exception.map((child) => {
                            if (props?.statustype == 2 && child?.inside_id === props?.currentId) {
                                const updatedEscalationRemainder = [...(child.escalation_remainder ?? []), ReminderObj];
                                return {
                                    ...child,
                                    escalation_remainder: updatedEscalationRemainder,
                                };
                            }
                            return child;
                        });

                        return {
                            ...ele,
                            child_exception: updatedChildExceptions,
                        };
                    }

                    return ele;
                });
            });
            await handleClose();
        }
    };
    const handleChange = (event) => {
        setReminderName(event.target.value);
    };
    return (
        <>
            <Modal cartClose={handleClose} center="center" overlap={true} className={`${props?.className}`}>
                <div
                    className={`max-w-[500px] mx-auto relative bg-${currentMode}-card  boder-${currentMode}-700 text-${currentMode}-copy px-[90px] mx-auto  py-[48px] rounded-[10px] ${currentMode !== "dark" ? " shadow-[0px_4px_20px_rgba(238,238,238,0.5)]" : ""
                        }`}
                >
                    <span onClick={handleClose} title="Close" className="cursor-pointer absolute right-[20px] top-[20px]">
                        <img src="/images/common/closeBlk.svg" alt="closeBlk" />
                    </span>

                    <span className={`block text-sm pb-3`}>
                        <span className="text-[red] mr-1">*</span>
                        Escalation and Reminder Name:
                    </span>

                    <div id="myView" className="overflow-y-auto max-h-[20rem] pr-[20px] ">
                        <div className="flex items-center space-x-3 ">
                            <Input
                                id="Reminder"
                                tabIndex="0"
                                name="Reminder"
                                maxLength={100}
                                value={reminderName}
                                onChange={handleChange}
                                placeholder="Enter name"
                                className={`border-[1px] borderLeft border-${currentMode}-840 rounded-[8px] pl-[20px] py-2 w-[500px] bg-${currentMode}-810`}
                            />
                        </div>
                        {errorMsg && <p className="text-light-50 text-xs">{errorMsg}</p>}
                    </div>

                    <div className="flex items-center space-x-3 mt-[20px]">
                        <Button
                            className="bg-light-20 px-[30px] py-[9px] text-white rounded-[8px] w-[300px]"
                            type="submit"
                            title="Save"
                            onClick={Submit}
                        //   disabled={fetching}
                        >
                            Create Reminder
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};
export default CreateReminderModal;
