import { useSelector } from "react-redux";
import { PreviewRender } from "./PreviewRender";
import { useContext } from "react";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";

export const Preview = () => {
    const { fields } = useSelector((state) => state?.vendorPropertiesState);
    const { currentMode } = useContext(ThemeContext);
    return (
        <>
            <PreviewRender config={fields} currentMode={currentMode} />
        </>
    );
};
