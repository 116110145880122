import Button from "../../components/Button";
import Modal from "../../components/UI/Modal/Modal";
import { useSelector, useDispatch } from "react-redux";
import UseForm from "../../Hooks/useForm/index";
import { TransferOwner } from "../../redux/actions/auth";
import { decryptId } from "../../utils/helpers";
import Swal from "sweetalert2";
import { TRANSFER_OWNER_RESET } from "../../redux/types/types";
import { useNavigate } from "react-router-dom";
import { LogoutRequest } from "../../redux/actions/auth";
import { useState, useContext } from "react";
import SuccessMessageBox from "../../components/UI/Alert/sucess";
import MessageBox from "../../components/UI/Alert";
import Cookies from "js-cookie";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { MenuItem, Select } from "@mui/material";

const RoleSwitchModal = (props) => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const succesMsg = "Transfered the ownership successfully";
    const errormsg = "Something went wrong please contact admin";
    const [errormsgs, seterrormsg] = useState({
        message: "",
    });
    const dispatch = useDispatch();
    const navigate = useNavigate;
    const profileData = useSelector((state) => state?.userListDropDownState);
    const { data: listData } = profileData;
    const TransferOwnerState = useSelector((state) => state?.ChangeOwnerShipState);
    const { requestComplete, fetching, error, data } = useSelector((state) => state?.ChangeOwnerShipState);
    const { handleChange, values, setValues, InitialState } = UseForm();
    const [email, setEmail] = useState("");
    const handleClose = () => {
        props?.handleClose(true);
        seterrormsg(" ");
        setEmail("0");
    };
    const onChangeEmail = (e) => {
        setEmail(e);
    };
    function submit() {
        if (email == 0) {
            seterrormsg({
                message: "Please Select any one user",
            });
            return false;
        } else {
            seterrormsg({
                message: "",
            });
        }
        if (submit) {
            Swal.fire({
                text: `Are you sure you want to transfer the ownership?`,
                closeOnClickOutside: false,
                icon: "warning",
                showCloseButton: true,
                showCancelButton: true,
                buttons: {
                    cancel: "Cancel",
                    confirm: "Confirm",
                },
            }).then((confirm) => {
                if (confirm?.isConfirmed) {
                    dispatch(
                        TransferOwner({
                            touser: +email,
                        })
                    );
                }
            });
        }
    }
    if ((requestComplete && !data?.status) || (error && !data?.status) || (requestComplete && data?.status)) {
        dispatch({ type: TRANSFER_OWNER_RESET });
    }
    if (requestComplete && data?.status) {
        dispatch(LogoutRequest());
        let myAdvanceItem = localStorage.getItem("advanceListOption");
        let myItem = localStorage.getItem("customOptions");
        let newItem = localStorage.getItem("ageingOption");
        let folderAccess = localStorage.getItem("FolderAccess");
        let secret = localStorage.getItem("secretId");
        let theme = localStorage.getItem("themeMode");
        Cookies.remove("jwt");
        localStorage.clear();
        localStorage.setItem("themeMode", theme);
        localStorage.setItem("advanceListOption", myAdvanceItem);
        localStorage.setItem("customOptions", myItem);
        localStorage.setItem("ageingOption", newItem);
        localStorage.setItem("FolderAccess", folderAccess);
        localStorage.setItem("secretId", secret);
        setTimeout(function () {
            window.location.reload();
            navigate("/");
        }, 2000);
    }
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    return (
        <>
            {requestComplete && data !== undefined && data?.status !== undefined && data?.status && <SuccessMessageBox success={succesMsg} />}
            {requestComplete && data !== undefined && data?.status !== undefined && !data?.status && <MessageBox error={data.message} />}
            {error && !data?.status && <MessageBox error={errormsg} />}
            <Modal cartClose={props?.handleClose} center="center" className={`${props?.className}`}>
                <div
                    className={`w-[500px] px-[50px] py-[48px] mx-auto relative rounded-[10px] ${
                        currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)] "
                    } bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700`}>
                    <div className="flex space-x-2 ">
                        <img className="bg-message rounded-[50%] w-[50px] h-[50px] mb-8" src="/images/main/transfer.svg" alt="alert" />
                        <span className="font-interSb text-base block  mb-4 mt-3">Transfer the Owner</span>
                    </div>
                    <Button onClick={props?.handleClose} tabIndex="3" className={`cursor-pointer absolute right-[20px] top-[20px] convertWhite `}>
                        <img src="/images/common/closeBlk.svg" alt="closeBlk" className="md:fill-cyan-700" />
                    </Button>

                    <p className="mb-4 ">Select the organization new owner email address.</p>

                    <Select
                        onChange={(e) => onChangeEmail(e.target.value)}
                        value={email || "0"}
                        name="orgName"
                        inputProps={{ "aria-label": "Without label" }}
                        fullWidth
                        IconComponent={() => (
                            <img
                                className="absolute opacity-[.5] right-[15px] pointer-events-none"
                                src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                alt="chevronDown"
                            />
                        )}
                        MenuProps={{
                            sx: {
                                "& .MuiMenu-paper": {
                                    backgroundColor: dropdownColor,
                                    color: dropdownText,
                                    maxWidth: "30rem !important",
                                },
                                "& .MuiMenuItem-root:hover": {
                                    backgroundColor: "rgba(218, 249, 244, 0.3)",
                                },
                                "& .Mui-selected": {
                                    backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                },
                            },
                        }}
                        SelectDisplayProps={{
                            style: {
                                paddingTop: "9px",
                                paddingBottom: "9px",
                                paddingLeft: "15px",
                                color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                paddingRight: "30px",
                                border: "1px solid rgba(85, 85, 85, 0.4)",
                                borderRadius: "8px",
                            },
                        }}
                        // IconComponent={() => <img className="absolute right-[10px] pointer-events-none" src={currentMode !== 'dark' ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`} alt="chevronDown" />}

                        className="w-full">
                        <MenuItem key={0} name="orgName" value={0}>
                            {"Select"}
                        </MenuItem>

                        {listData &&
                            listData?.map((elem, i) => {
                                return (
                                    <MenuItem key={i + 1} name="orgName" value={elem?.id}>
                                        {decryptId(elem?.user_emailaddress)}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                    {errormsgs?.message && <p className="text-light-50 text-xs mb-1">{errormsgs?.message}</p>}
                    <div className="flex mt-7 space-x-2">
                        <Button title="Save" className="bg-light-20 px-[34px] py-2 text-base text-white rounded-[8px] " onClick={submit}>
                            Save
                        </Button>
                        <Button title="Cancel" className="bg-light-240 text-light-40 px-[28px] py-2 text-base rounded-[8px]" onClick={handleClose}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default RoleSwitchModal;
