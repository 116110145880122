import React, { useContext, useState } from "react";
import { useDroppable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";

export function Field(props) {
    const { field, overlay } = props;
    const { type, title } = field;
    let className = "canvas-field h-full";
    if (overlay) className += ` overlay`;

    return (
        <div className={className}>
            {type === "spacer" ? (
                <div className="spacer py-[20px]"></div>
            ) : (
                <div className="border px-[15px] h-[40px] py-[8px] rounded-[5px]">{title}</div> || <div>No renderer found for {type}</div>
            )}
        </div>
    );
}

const SortableField = (props) => {
    const { id, index, field, currentMode, handleElementClick, overlay, clickId } = props;
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id,
        data: {
            index,
            id,
            field,
        },
    });
    let className = "canvas-field h-full";
    if (overlay) className += ` overlay`;

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };
    const handleClick = () => {
        handleElementClick(id);
    };

    return (
        <div>
            <div onClick={handleClick} ref={setNodeRef} style={style} {...attributes} {...listeners}>
                <Field field={field} currentMode={currentMode} />
            </div>
        </div>
    );
};

export default function Canvas(props) {
    const { fields, handleElementClick, columns } = props;
    const { currentMode } = useContext(ThemeContext);

    const { attributes, listeners, setNodeRef, transform, transition } = useDroppable({
        id: "canvas_droppable",
        data: {
            parent: null,
            isContainer: true,
        },
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };
    const [click, setClick] = useState();

    const handleClicked = (id) => {
        setClick(id);
    };
    console.log(click, ";;;");

    return (
        <div
            ref={setNodeRef}
            className={`h-full space-y-[20px] border border-${currentMode}-700 rounded-[10px] max-h-[-webkit-fill-available] bg-${currentMode}-card basis-[52%] px-[50px] py-[30px] xl:h-[calc(100vh_-_13rem)] h-[calc(100vh_-_18rem)] overflow-y-auto`}
            style={style}
            {...attributes}
            {...listeners}>
            {fields?.length > 0 ? (
                <div className={`canvas-fields gap-2 grid ${columns == 2 ? "grid-cols-2" : ""}`}>
                    {fields.map((field, index) => (
                        <React.Fragment key={field.id}>
                            {field.type === "spacer" ? (
                                <div className="placeholder bg-[red]">Drop here</div>
                            ) : (
                                <div
                                    onClick={() => handleClicked(field?.id)}
                                    className={`border p-2 rounded-[5px] hover:border-[#00B399] ${
                                        click === field?.id ? "border-[#00B399]" : "border-[transparent]"
                                    }`}>
                                    <SortableField id={field.id} field={field} index={index} handleElementClick={handleElementClick} />
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            ) : (
                <div className="flex justify-center flex-col h-full items-center">
                    <img src="/images/vendor-onboard/file-drag.svg" alt="file-drag" />
                    <p className="mt-[20px]">This page is empty. Drag fields from the left panel and drop here!</p>
                </div>
            )}
        </div>
    );
}
