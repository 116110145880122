import { FormControl, MenuItem, Select, Tooltip } from "@mui/material";
import Button from "../../../components/Button";
import { SelectDropdwnImg, generateRandomColor, showSwal } from "../../../utils/helpers";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { GetPreviewData } from "../../../redux/actions/output-management";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { FieldTarget } from "../../../organisms/DropTarget/Field";
import _ from "lodash";
import { setDroppedValues } from "../../../redux/actions/filter";
import { MoreFilterTarget } from "../../../organisms/DropTarget/MoreFilter";
import { LabelTarget } from "../../../organisms/DropTarget/Label";
import PiechartPreview from "./Charts/PieChart";
import Linechart from "./Charts/LineChart";
import BarchartPreview from "./Charts/BarChart";
import Loader from "../../../components/Loader";
import { useEffect, useState } from "react";

export const ChartTble = ({
    formik,
    type,
    currentMode,
    filteredAccordionItems,
    addItems,
    setAddItems,
    accordionItems,
    setFilteredAccordionItems,
    setAccordionItems,
    prevtype,
}) => {
    const Charts = [
        { val: 1, name: "Pie Chart" },
        { val: 2, name: "Line Chart" },
        { val: 3, name: "Bar Chart" },
    ];
    const dispatch = useDispatch();
    const OrgId = localStorage.getItem("orgId");
    const { preview, fetching } = useSelector((state) => state?.getPreviewState);
    const { fieldValues, morefilterValues, labelValues } = useSelector((state) => state?.droppedState);

    const _handlePreview = () => {
        if (fieldValues?.length >= 1 && labelValues?.length >= 1 && formik?.values?.chart_type !== 0) {
            setAddItems({ ...addItems, preview: true });
            const ReqObj = {
                dynamicquery: [...fieldValues, ...labelValues, ...morefilterValues],
                orgid: +OrgId,
                dataset_id: +type,
                output_type: +prevtype,
            };
            dispatch(GetPreviewData(ReqObj));
        } else {
            if (formik?.values?.chart_type == 0) {
                showSwal(`Select any Chart Type to proceed!`);
            } else {
                showSwal(`Select at least one field/label to proceed!`);
            }
        }
    };
    const handleDrop = (itemValue, areaName) => {
        const conditions = {
            morefilter: !itemValue?.checked,
            field: true && (itemValue?.data_type == "n" || itemValue?.data_type == "m" || itemValue?.data_type == "p"),
            label: true,
        };
        if (conditions[areaName]) {
            if (fieldValues?.length < 1 && areaName == "field") {
                if (fieldValues?.length < 1 && (itemValue?.data_type == "n" || itemValue?.data_type == "m" || itemValue?.data_type == "p")) {
                    dispatch(setDroppedValues(itemValue, true, areaName));
                } else {
                    showSwal(`Select a valid numeric field!`);
                    return false;
                }
            } else if (labelValues?.length < 1 && areaName === "label") {
                dispatch(setDroppedValues(itemValue, true, areaName));
                if (labelValues?.length >= 1) {
                    showSwal(`Do not select more than one field/label!`);
                    return false;
                }
            } else {
                if (areaName == "morefilter") {
                    dispatch(setDroppedValues(itemValue, true, areaName));
                } else {
                    showSwal(`Do not select more than one field/label!`);
                    return false;
                }
            }
        } else {
            if (areaName === "field" && (itemValue?.data_type !== "n" || itemValue?.data_type !== "m")) {
                showSwal(`Select a valid numeric field!`);
            } else {
                showSwal(
                    `${_.startCase(areaName == "morefilter" ? "More filter" : areaName)} is allowed for the ${
                        areaName == "morefilter" ? "un" : ``
                    }selected fields/columns.`
                );
            }
        }
    };
    const [backgroundColor, setBackgroundColor] = useState();
    useEffect(() => {
        // Generate random colors when preview?.data changes
        const newRandomColors = Array.from({ length: preview?.data?.length }, generateRandomColor);
        setBackgroundColor(newRandomColors);
    }, [preview?.data]);
    return (
        <>
            {" "}
            <div className="p-[20px] flex justify-between">
                <div className="flex items-center">
                    <span className="font-interM mr-[20px]">Chart Type</span>
                    <FormControl sx={{ minWidth: 200 }}>
                        <Select
                            name="chart_type"
                            value={formik?.values?.chart_type || "0"}
                            fullWidth
                            onChange={formik?.handleChange}
                            inputProps={{
                                "aria-label": "Without label",
                                tabIndex: "1",
                            }}
                            IconComponent={() => SelectDropdwnImg(currentMode)}
                        >
                            <MenuItem key={0} name="OrganisationList" value={0}>
                                {"Select "}
                            </MenuItem>
                            {Charts?.map((el, i) => (
                                <MenuItem key={el?.val} tabIndex={el?.val} value={el?.val} className="truncate trunc-1 block">
                                    {el?.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className=" space-x-[10px]">
                    <Button
                        title="More Filter"
                        disabled={addItems?.filters}
                        onClick={() => setAddItems({ ...addItems, filters: true })}
                        className={`border-${currentMode}-840 border rounded-[8px] px-[15px] py-[3.5px]`}
                    >
                        More Filters
                    </Button>
                    <Button
                        title="Preview Data"
                        disabled={fetching}
                        onClick={_handlePreview}
                        className={`border-${currentMode}-20 bg-${currentMode}-20 text-white border rounded-[8px] px-[15px] py-[3.5px]`}
                    >
                        Preview Data
                    </Button>
                </div>
            </div>
            {formik?.values?.chart_type > 0 && (
                <div className="flex flex-wrap">
                    <div className={`basis-1/2 border-y border-${currentMode}-1240`}>
                        <div className={`py-[12px] flex px-[20px] w-full bg-${currentMode}-card border-b border-${currentMode}-1240 inline-block `}>
                            <span className={`font-interM block`}>Field</span>
                            <Tooltip
                                title={
                                    "Drag and drop or select a Column data field to specify which data attributes should be included in generating the chart reports."
                                }
                                disablePortal
                                placement="top"
                                disableInteractive
                                PopperProps={{
                                    sx: {
                                        "& .MuiTooltip-tooltip": {
                                            fontSize: "0.9em",
                                            maxHeight: "120px",
                                            maxWidth: "300px",
                                            background: "#DAF9F4",
                                            color: "#555555",
                                            borderRadius: "5px",
                                            paddingBottom: "10px",
                                            paddingTop: "10px",
                                            paddingLeft: "15px",
                                        },

                                        "& .MuiTooltip-arrow": {
                                            color: "#DAF9F4",
                                        },
                                    },
                                }}
                                arrow
                            >
                                <img className="ml-2 cursor-pointer" src="/images/main/noun-information.svg" alt="noun-information" />
                            </Tooltip>
                        </div>
                        <div className="overflow-y-auto max-h-[15rem]">
                            <DndProvider backend={HTML5Backend}>
                                <FieldTarget
                                    onDrop={handleDrop}
                                    setAccordionItems={setAccordionItems}
                                    accordionItems={accordionItems}
                                    setFilteredAccordionItems={setFilteredAccordionItems}
                                    filteredAccordionItems={filteredAccordionItems}
                                    areaName="field"
                                    type={type}
                                    currentMode={currentMode}
                                />
                            </DndProvider>
                        </div>
                    </div>
                    <div className={`basis-1/2  border-y border-l border-${currentMode}-1240`}>
                        <div className={`py-[12px] px-[20px] flex w-full bg-${currentMode}-card border-b border-${currentMode}-1240 inline-block `}>
                            <span className={`font-interM block`}>Label</span>
                            <Tooltip
                                title={
                                    "Drag and drop or select a Row data field to specify which data attributes should be included in generating the chart reports."
                                }
                                disablePortal
                                placement="top"
                                disableInteractive
                                PopperProps={{
                                    sx: {
                                        "& .MuiTooltip-tooltip": {
                                            fontSize: "0.9em",
                                            maxHeight: "120px",
                                            maxWidth: "300px",
                                            background: "#DAF9F4",
                                            color: "#555555",
                                            borderRadius: "5px",
                                            paddingBottom: "10px",
                                            paddingTop: "10px",
                                            paddingLeft: "15px",
                                        },

                                        "& .MuiTooltip-arrow": {
                                            color: "#DAF9F4",
                                        },
                                    },
                                }}
                                arrow
                            >
                                <img className="ml-2 cursor-pointer" src="/images/main/noun-information.svg" alt="noun-information" />
                            </Tooltip>
                        </div>
                        <div className="overflow-y-auto max-h-[15rem]">
                            <DndProvider backend={HTML5Backend}>
                                <LabelTarget
                                    onDrop={handleDrop}
                                    setAccordionItems={setAccordionItems}
                                    accordionItems={accordionItems}
                                    setFilteredAccordionItems={setFilteredAccordionItems}
                                    filteredAccordionItems={filteredAccordionItems}
                                    areaName="label"
                                    type={type}
                                    currentMode={currentMode}
                                />
                            </DndProvider>
                        </div>
                    </div>
                    {addItems?.filters && (
                        <div className={`basis-full border-b border-${currentMode}-680`}>
                            <div
                                className={`py-[12px] flex px-[20px] w-full bg-${currentMode}-card border-b border-${currentMode}-680 inline-block `}
                            >
                                <span className="font-interM">More Filters</span>
                                <Tooltip
                                    title={"Apply more filters to select and display only the relevant data rows in your chart reports."}
                                    disablePortal
                                    placement="top"
                                    disableInteractive
                                    PopperProps={{
                                        sx: {
                                            "& .MuiTooltip-tooltip": {
                                                fontSize: "0.9em",
                                                maxHeight: "120px",
                                                maxWidth: "300px",
                                                background: "#DAF9F4",
                                                color: "#555555",
                                                borderRadius: "5px",
                                                paddingBottom: "10px",
                                                paddingTop: "10px",
                                                paddingLeft: "15px",
                                            },

                                            "& .MuiTooltip-arrow": {
                                                color: "#DAF9F4",
                                            },
                                        },
                                    }}
                                    arrow
                                >
                                    <img className="ml-2 cursor-pointer" src="/images/main/noun-information.svg" alt="noun-information" />
                                </Tooltip>
                            </div>
                            <div className="overflow-y-auto max-h-[15rem]">
                                <DndProvider backend={HTML5Backend}>
                                    <MoreFilterTarget
                                        onDrop={handleDrop}
                                        areaName="morefilter"
                                        currentMode={currentMode}
                                        filterCheck={[...(fieldValues || []), ...(labelValues || [])]}
                                        setAccordionItems={setAccordionItems}
                                        accordionItems={accordionItems}
                                        type={type}
                                        setFilteredAccordionItems={setFilteredAccordionItems}
                                        filteredAccordionItems={filteredAccordionItems}
                                    />
                                </DndProvider>
                            </div>
                        </div>
                    )}
                </div>
            )}
            {formik?.values?.chart_type > 0 && addItems?.preview && (
                <div className="mt-7 overflow-auto">
                    {fetching ? (
                        <Loader className="flex items-center justify-center" />
                    ) : Object?.keys(preview || {})?.length !== 0 && preview?.data ? (
                        formik?.values?.chart_type == 1 ? (
                            <div className="flex justify-center items-center">
                                <PiechartPreview preview={preview} currentMode={currentMode} bgColor={backgroundColor} />
                            </div>
                        ) : formik?.values?.chart_type == 2 ? (
                            <div className="flex justify-center items-center">
                                <Linechart preview={preview} />
                            </div>
                        ) : (
                            <div className="flex justify-center items-center  mx-auto mt-10 h-[400px]">
                                <BarchartPreview preview={preview} currentMode={currentMode} />
                            </div>
                        )
                    ) : (
                        <b className="text-center block">Preview data not found</b>
                    )}
                </div>
            )}
        </>
    );
};
