import { Checkbox, ClickAwayListener } from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SuccessMessageBox from "../../../components/UI/Alert/sucess";
import MessageBox from "../../../components/UI/Alert";
import {
    deleteEmailAction,
    emailMarkasToggle,
    getInboxBodyView,
    getInboxChildView,
    getInboxFolder,
    getInboxPrevView,
    onClickRead,
    ToggleMarkasRead,
} from "../../../redux/actions/email-inbox";
import Loader from "../../../components/Loader";
import { deleteSendMsMail } from "../../../redux/actions/email-inbox.js";
import Button from "../../../components/Button/index";
import { DELETE_MICROSOFT_MAIL_RESET, INBOX_PREVIEW_GET_RESET } from "../../../redux/types/types.js";
import moment from "moment";
import { generateRandomDarkColor, getInitials, getUsernameColor } from "../../../utils/helpers";
import { convertUtcDate } from "../../../utils/helpers";
import localforage, { length } from "localforage";
import { fetchEmails } from "../../../utils/helpers";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import Swal from "sweetalert2";
let lastTime = null;
let Length = null;

export const PrevEmail = (props) => {
    const { currentMode } = useContext(ThemeContext);
    const { isDraft } = props;
    const { replyEnable } = props;
    const { openFolder, handleOpen, setOpenFolder, compose, setIsActive, isActive } = props;
    const handleClickAway = () => {
        setOpenFolder(false);
    };

    //   const [expanded, setExpanded] = useState("");
    const [isReads, setIsUnreads] = useState({
        index: "",
        isRead: "",
        type: "",
    });
    const [clicks, setIsClicks] = useState({
        index: "",
        isRead: "",
        type: "",
    });
    const [read, setRead] = useState({
        emailId: "",
        isReadFlag: "",
    });
    const [emailBody, setEmailBody] = useState([]);
    //   const handleChange = (panel) => (event, newExpanded) => {
    //     setExpanded(newExpanded ? panel : false);
    //   };
    const [emails, setEmails] = useState([]);
    const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
        border: `0`,
        borderRadius: "8px",
        background: "transparent",
        color: currentMode === "dark" ? "#fff" : "#555555",

        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
    }));
    const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(({ theme }) => ({
        padding: "20px",
        justifyContent: "start",
        margin: 0,
        minHeight: 0,
        padding: 0,
        "& .MuiAccordionSummary-content .arrowImg": {
            transform: "rotate(-90deg)",
        },
        "& .MuiAccordionSummary-content.Mui-expanded .arrowImg": {
            transform: "rotate(0deg)",
        },
        "& .MuiAccordionSummary-content": {
            margin: 0,
            display: "initial",
        },
        "& .MuiAccordionSummary-content.Mui-expanded .hided": {
            opacity: "1 !important",
            display: "flex !important",
            visibility: "visible !important",
        },
        "& .MuiAccordionSummary-content.Mui-expanded .opens": {
            display: "none",
        },
    }));

    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: "0",
        border: 0,
    }));
    //   const { emailPrevData, fetching, requestComplete } = useSelector(
    //     (state) => state?.inboxPrevState
    //   );

    const dispatch = useDispatch();
    const containerRef = useRef(null);

    // Fetch data when the page changes
    const getFolderId = localStorage.getItem("folderId");
    const { folderData, fetching, requestComplete } = useSelector((state) => state?.inboxFolderState);
    const { emailPrevChildData, fetching: childfetch } = useSelector((state) => state?.inboxchildPrevState);
    const { value } = folderData !== undefined && folderData !== null && folderData;
    const { data, isLoading, isLoadingScroll, hasMore, page, pageSize } = useSelector((state) => state?.inboxPrevState);
    const [isConversation, setIsConversation] = useState(false);

    useEffect(() => {
        const date = new Date();
        const utcDateTime = moment.utc(date).format();
        const firstTime = data?.length !== 0 && data?.[0]?.bodypreview?.receivedDateTime;
        setExpanded([]);

        if (getFolderId != undefined && getFolderId != "undefined" && getFolderId != null && getFolderId != "") {
            dispatch(getInboxPrevView(pageSize, getFolderId, utcDateTime, 2, "normal", data, hasMore));
            setEmailBody(fetchEmails(getFolderId));
            setIsActive("");
        }
    }, [getFolderId]);
    const handleMarkAsRead = () => {
        const readStatus = !data?.find((item) => item?.bodypreview?.id == isActive)?.bodypreview?.isRead;
        const readIndex = data?.indexOf(data?.find((item) => item?.bodypreview?.id == isActive));
        let readParse = {
            emailId: isActive,
            isReadFlag: readStatus,
        };

        if (readIndex == -1) {
            const conversation = emailPrevChildData?.find((el, i) => el?.id == isActive);
            const parentIndex = data?.indexOf(data?.find((item) => item?.bodypreview?.conversationId == conversation?.conversationId));
            emailPrevChildData[emailPrevChildData?.indexOf(conversation)].isRead = !conversation?.isRead;

            // data[parentIndex].bodypreview.unreadcount = conversation?.isRead
            //     ? data[parentIndex].bodypreview.unreadcount - 1
            //     : data[parentIndex].bodypreview.unreadcount + 1;

            readParse = {
                emailId: isActive,
                isReadFlag: !conversation?.isRead,
            };
            const readReducer = {
                index: readIndex,
                isRead: readStatus,
                type: "normal",
            };
        }

        const readReducer = {
            index: readIndex,
            isRead: readStatus,
            type: "normal",
        };
        dispatch(ToggleMarkasRead(readReducer));
        dispatch(emailMarkasToggle(readParse));
        values?.map((item) => {
            if (item?.id == getFolderId) {
                item.unreadItemCount = readStatus ? item?.unreadItemCount - 1 : item?.unreadItemCount + 1;
            }
            return item;
        });
        setRead({
            emailId: "",
            isReadFlag: "",
        });
        setIsUnreads({
            index: "",
            isRead: "",
        });
    };
    Length = data?.length;
    if (data?.length > 0) {
        lastTime = data?.[data?.length - 1]?.bodypreview?.receivedDateTime;
    } else {
        lastTime = "";
    }
    const Count = localStorage.getItem("count");
    useEffect(() => {
        const NextPage = localStorage.getItem("nextpage");
        if (NextPage == "true") {
            const fetchDataInViewport = () => {
                if (containerRef.current && !isLoadingScroll && !isLoading && data?.length != 0) {
                    const container = containerRef.current;
                    const { scrollTop, scrollHeight, clientHeight } = container;
                    const isInViewport = scrollTop + clientHeight >= scrollHeight;

                    if (getFolderId != undefined && isInViewport && getFolderId != "undefined" && getFolderId != "null" && getFolderId != null) {
                        dispatch(getInboxPrevView(pageSize, getFolderId, lastTime, 2, "scrollView", data, hasMore));
                    }
                }
            };

            containerRef?.current?.addEventListener("scroll", fetchDataInViewport);
            return () => {
                containerRef?.current?.removeEventListener("scroll", fetchDataInViewport);
            };
        }
    }, [isLoadingScroll, pageSize]);

    // checkbox functionalities dynamically start
    const [checkedItems, setCheckedItems] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const deleteId = localStorage.getItem("deleteId");
    const handleDelete = (id, i, normal, event) => {
        event.stopPropagation();
        const reqObj = {
            emailId: id,
            folderId: getFolderId === deleteId ? null : deleteId,
        };
        dispatch(deleteSendMsMail(reqObj));
        dispatch(deleteEmailAction(i, normal));
    };
    const delData = useSelector((state) => state?.deleteMsMailState);
    if (delData?.requestComplete && delData?.delData?.status) {
        setTimeout(() => {
            dispatch({ type: DELETE_MICROSOFT_MAIL_RESET });
            dispatch(getInboxFolder("Delete"));
        }, 2000);
    }
    const handleCheckboxChange = (index, id, check) => (event) => {
        const isChecked = event.target.checked;
        let updatedCheckedItems = [...checkedItems];
        updatedCheckedItems[index] = isChecked;
        setIsUnreads({
            index: index,
            isRead: !check,
            // type:"m"
        });
        setRead({
            emailId: id,
            isReadFlag: !check,
        });

        setCheckedItems(updatedCheckedItems);
        // setSelectAll(updatedCheckedItems.every((item) => item));
    };

    const { folderData: values, fetching: getting } = useSelector((state) => state?.inboxFolderState);

    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        const updatedCheckedItems = data?.map(() => isChecked);

        setCheckedItems(updatedCheckedItems);
        setSelectAll(isChecked);
    };
    // refresh function to api call
    const _refresh = () => {
        const date = new Date();
        const utcDateTime = moment.utc(date).format();
        const firstTime = data?.length !== 0 ? data?.[0]?.bodypreview?.receivedDateTime : utcDateTime;
        setCheckedItems([]);
        // if (data?.length !== 0 && data !== null) {
        //     dispatch({ type: INBOX_PREVIEW_GET_RESET });
        // }
        if (getFolderId != undefined && getFolderId != "undefined" && getFolderId != null && getFolderId != "")
            dispatch(getInboxPrevView(pageSize == 20 ? pageSize : 20, getFolderId, firstTime, data?.length !== 0 ? 1 : 2, "normal", data, hasMore));
    };

    // checkbox functionalities dynamically end
    const _emailBodyView = (id, conversationId, e) => {
        // if (expanded === "") {
        //   e.stopPropagation();
        // }
        dispatch(getInboxBodyView(id));
        if (conversationId != null) {
            dispatch(getInboxChildView(conversationId));
        } else {
            setExpanded([]);
        }
        props?.setCompose(false);
        props?.setIsDraft(false);
    };
    const InboxCard = (props) => {
        const { data, index, onClick } = props;

        const draftName = data?.bodypreview?.toRecipients?.map((item) => item?.emailAddress?.address)?.join(",");
        return (
            <>
                {delData?.delData?.status && delData?.requestComplete && <SuccessMessageBox success={"Deleted successfully"} />}
                {delData?.delData?.error ||
                    (delData?.delData?.status == false && delData?.requestComplete == false && <MessageBox error={"Failed to delete"} />)}
                <div
                    onClick={onClick}
                    className={`inboxCard relative cursor-pointer ${isActive == data?.bodypreview?.id ? `selected` : ``} 
                         ${props?.class} p-[13px]`}>
                    <div className="flex justify-between">
                        <div className="flex">
                            <span
                                className={`uppercase text-white block text-xs rounded-full w-[25px] flex items-center justify-center h-[25px]`}
                                style={{
                                    background: `${getUsernameColor(
                                        data?.bodypreview?.isDraft
                                            ? draftName?.length == 0
                                                ? ""
                                                : data?.bodypreview?.toRecipients[0]?.emailAddress?.address
                                            : data?.bodypreview?.sender?.emailAddress?.name
                                    )}`,
                                }}>
                                {getInitials(
                                    data?.bodypreview?.isDraft
                                        ? draftName?.length == 0
                                            ? ""
                                            : data?.bodypreview?.toRecipients[0]?.emailAddress?.address
                                        : data?.bodypreview?.sender?.emailAddress?.name
                                )}
                            </span>
                            {/* <span className="check absolute invisible opacity-0">
				<Checkbox
				  defaultChecked={checkedItems[index]}
				  disabled={
					read?.emailId !== ""
					  ? read?.emailId !== data?.bodypreview?.id
					  : false
				  }
				  onChange={handleCheckboxChange(
					index,
					data?.bodypreview?.id,
					data?.bodypreview?.isRead
				  )}
				  size="small"
				  sx={{
					padding: 0,
					color: "#008785",
					"&.Mui-checked": {
					  color: "#008785",
					},
				  }}
				/>{" "}
			  </span> */}
                            <div className="us:max-w-[14rem] max-w-[27rem]">
                                <span className={`${props?.class ? `font-interSb` : ``} block truncate pl-4`}>
                                    {" "}
                                    {data?.bodypreview?.isDraft
                                        ? draftName?.length == 0
                                            ? "(no recipient)"
                                            : draftName
                                        : data?.bodypreview?.sender?.emailAddress?.name}
                                </span>
                                <div className="flex pl-4 xl:max-w-[14rem] max-w-[17rem]">
                                    <h5 title={data?.bodypreview?.subject} className={`${props?.class ? `font-interSb` : ``} block mb-1 truncate`}>
                                        {data?.bodypreview?.subject == "" ? "(no subject)" : data?.bodypreview?.subject}
                                    </h5>
                                </div>
                                <p title={data?.bodypreview?.bodyPreview} className={`text-xs pl-4 text-${currentMode}-890 truncate trunc-1`}>
                                    {data?.bodypreview?.bodyPreview}
                                </p>
                            </div>
                        </div>
                        <div className="relative text-right">
                            <div className="flex justify-end items-center space-x-1">
                                <span className={`text-${currentMode}-900 del invisible opacity-0 flex-initial`}>
                                    <img
                                        onClick={(e) => handleDelete(data?.bodypreview?.id, index, "normal", e)}
                                        src="/images/inbox/trash-2.svg"
                                        className="ml-auto convertWhite"
                                        alt="trash-2"
                                    />
                                </span>
                                {data?.bodypreview?.hasAttachments && (
                                    <span className="group-hover:hidden block">
                                        <img className="paperclip ml-auto convertWhite" src="/images/inbox/paperclip.svg" alt="paperclip" />
                                    </span>
                                )}
                            </div>
                            <span className={`text-${currentMode}-900 mt-4 time block flex-initial trunc-1`}>
                                {/* {moment(new Date()).format("DD/MM/YYYY") ==
									moment(new Date(data?.bodypreview?.receivedDateTime)).format(
										"DD/MM/YYYY"
									)
									? data?.bodypreview?.receivedDateTime?.split("T")?.[1]
									: data?.bodypreview?.receivedDateTime?.split("T")?.[0]} */}
                                {convertUtcDate(data?.bodypreview?.receivedDateTime)}
                            </span>
                        </div>
                    </div>
                </div>
            </>
        );
    };
    const [expanded, setExpanded] = useState([]);

    const handleSummaryClick = (index) => {
        if (!expanded.includes(index)) {
            setExpanded((prevState) => [index]);
        }
    };

    const handleArrowClick = (index) => {
        setExpanded((prevState) => prevState.filter((item) => item !== index));
    };

    const isExpanded = (index) => expanded.includes(index);

    const [isDesktop, setDesktop] = useState(window.innerWidth > 1024);
    const updateMedia = () => {
        setDesktop(window.innerWidth > 1024);
    };

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });
    const AccordCard = (props) => {
        const { data, index, onClick } = props;
        return (
            <>
                {delData?.delData?.status && delData?.requestComplete && <SuccessMessageBox success={"Deleted successfully"} />}
                {delData?.delData?.error ||
                    (delData?.delData?.status == false && delData?.requestComplete == false && <MessageBox error={"Failed to delete"} />)}
                <div
                    onClick={onClick}
                    className={`bg-${currentMode}-card inboxCard py-[13px] relative cursor-pointer ${checkedItems[index] ? "selected" : ""}  ${
                        props?.class
                    } `}>
                    <Accordion
                        expanded={isExpanded(index)}
                        key={index}
                        // onChange={handleChange(index)}
                    >
                        <AccordionSummary
                            aria-controls={`accordion-content-${index}`}
                            id={`accordion-summary-${index}`}
                            onClick={() => {
                                const confirmPopup = () => {
                                    handleSummaryClick(index);
                                    setIsUnreads({
                                        index: index,
                                        isRead: !data?.bodypreview?.isRead,
                                        type: "normal",
                                    });
                                    if (isDesktop) {
                                        setIsActive(data?.bodypreview?.id);
                                    }
                                    setRead({
                                        emailId: data?.bodypreview?.id,
                                        isReadFlag: !data?.bodypreview?.isRead,
                                    });
                                    if (!data?.bodypreview?.isRead) {
                                        setIsClicks({
                                            index: index,
                                            isRead: true,
                                            type: "normal",
                                        });
                                        values?.map((item) => {
                                            if (item?.id == getFolderId) {
                                                item.unreadItemCount = item?.unreadItemCount - 1;
                                            }
                                            return item;
                                        });
                                        dispatch(
                                            onClickRead({
                                                index: index,
                                                isRead: true,
                                                type: "normal",
                                            })
                                        );
                                    }
                                    if (!isExpanded(index)) {
                                        _emailBodyView(data?.bodypreview?.id, data?.bodypreview?.conversationId);
                                    }
                                };

                                if (isDraft || compose || replyEnable) {
                                    Swal.fire({
                                        text: "Changes in the mailbox will be removed.",
                                        icon: "warning",
                                        closeOnClickOutside: false,
                                        buttons: {
                                            cancel: true,
                                            confirm: true,
                                        },
                                    }).then((confirm) => {
                                        if (confirm) {
                                            {
                                                confirmPopup();
                                            }
                                        }
                                    });
                                } else {
                                    confirmPopup();
                                }
                            }}>
                            <div className="flex justify-between mailTick p-[13px]">
                                <div className="flex">
                                    <span
                                        title={data?.bodypreview?.sender?.emailAddress?.name}
                                        className={`uppercase text-white block text-xs rounded-full w-[25px] flex items-center justify-center h-[25px]`}
                                        style={{
                                            background: `${getUsernameColor(data?.bodypreview?.sender?.emailAddress?.name)}`,
                                        }}>
                                        {/* <img
						                   className="flex-initial top-[5px] max-w-[inherit] star-l absolute"
						                   src="/images/inbox/inbox-star.svg"
						                   alt="inbox-star"
						                 /> */}
                                        {getInitials(data?.bodypreview?.sender?.emailAddress?.name)}
                                    </span>
                                    {/* <span className="check absolute invisible opacity-0">
						                   <Checkbox
						                     defaultChecked={checkedItems[index]}
						                     disabled={
						                       read?.emailId !== ""
						                         ? read?.emailId !== data?.bodypreview?.id
						                         : false
						                     }
						                     onChange={handleCheckboxChange(
						                       index,
						                       data?.bodypreview?.id,
						                       data?.bodypreview?.isRead
						                     )}
						                     size="small"
						                     sx={{
						                       padding: 0,
						                       color: "#008785",
						                       "&.Mui-checked": {
						                         color: "#008785",
						                       },
						                     }}
						                 </span> */}
                                    <div className="us:max-w-[14rem] max-w-[27rem]">
                                        <span
                                            title={data?.bodypreview?.sender?.emailAddress?.name}
                                            className={`${!data?.bodypreview?.isRead ? `font-interSb` : ``} block truncate pl-4`}>
                                            {data?.bodypreview?.sender?.emailAddress?.name}
                                        </span>
                                        <div className="flex pl-2 xl:max-w-[14rem] max-w-[17rem]">
                                            <img
                                                onClick={() => handleArrowClick(index)}
                                                className="mr-[.4rem] arrowImg convertWhite"
                                                src="/images/inbox/checron-dwn.svg"
                                                alt="checron-dwn"
                                            />
                                            <h5
                                                title={data?.bodypreview?.subject}
                                                className={`${!data?.bodypreview?.isRead ? `font-interSb` : ``} block mb-1 truncate`}>
                                                {data?.bodypreview?.subject}
                                            </h5>
                                        </div>
                                        <p title={data?.bodypreview?.bodyPreview} className={`text-xs pl-4 text-${currentMode}-890 truncate trunc-1`}>
                                            {data?.bodypreview?.bodyPreview}
                                        </p>
                                    </div>
                                </div>
                                <div className="relative text-right">
                                    <div className="flex justify-end items-center space-x-1">
                                        {/* <span className="text-[rgba(85,85,85,0.8)] del invisible opacity-0 flex-initial">
						                     <img
						                       onClick={() =>
						                         handleDelete(data?.bodypreview?.id, index)
						                       }
						                       src="/images/inbox/trash-2.svg"
						                       className="ml-auto"
						                       alt="trash-2"
						                     />
						                   </span> */}
                                        {data?.bodypreview?.hasAttachments && (
                                            <span className="group-hover:hidden block">
                                                <img className="paperclip ml-auto convertWhite" src="/images/inbox/paperclip.svg" alt="paperclip" />
                                            </span>
                                        )}
                                    </div>
                                    <span className={`text-${currentMode}-900 mt-4 time block flex-initial trunc-1`}>
                                        {/* {moment(new Date()).format("DD/MM/YYYY") ==
                                            moment(
                                                new Date(data?.bodypreview?.receivedDateTime)
                                            ).format("DD/MM/YYYY")
                                            ? data?.bodypreview?.receivedDateTime?.split("T")?.[1]
                                            : data?.bodypreview?.receivedDateTime?.split("T")?.[0]} */}
                                        {convertUtcDate(data?.bodypreview?.receivedDateTime)}
                                    </span>
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            {childfetch ? (
                                <Loader className={`h-[70px] bg-${currentMode}-card `} />
                            ) : (
                                <div className="mailTick">
                                    {emailPrevChildData?.map((el, i) => {
                                        return (
                                            <div
                                                key={i}
                                                onClick={() => {
                                                    const confirmPopup = () => {
                                                        if (!el?.isRead) {
                                                            setIsClicks({
                                                                index: i,
                                                                isRead: true,
                                                                type: "child",
                                                            });
                                                            dispatch(
                                                                onClickRead({
                                                                    index: i,
                                                                    isRead: true,
                                                                    type: "child",
                                                                })
                                                            );
                                                        }
                                                        if (el?.isRead) {
                                                            setRead({
                                                                emailId: el?.id,
                                                                isReadFlag: !el?.isRead,
                                                            });
                                                        }
                                                        dispatch(getInboxBodyView(el?.id));
                                                        setIsUnreads({
                                                            index: i,
                                                            isRead: !el?.isRead,
                                                            type: "child",
                                                        });
                                                        setIsActive(el?.id);
                                                    };
                                                    if (isDraft || compose || replyEnable) {
                                                        Swal.fire({
                                                            text: "Changes in the mailbox will be removed.",
                                                            icon: "warning",
                                                            closeOnClickOutside: false,
                                                            buttons: {
                                                                cancel: true,
                                                                confirm: true,
                                                            },
                                                        }).then((confirm) => {
                                                            if (confirm) {
                                                                {
                                                                    confirmPopup();
                                                                }
                                                            }
                                                        });
                                                    } else {
                                                        confirmPopup();
                                                    }
                                                }}
                                                className={`mailReply flex items-center px-3 justify-between ${
                                                    isActive == el?.id ? `selected` : ``
                                                }`}>
                                                <div className="py-2">
                                                    <div className="flex items-center xl:max-w-[14rem] max-w-[17rem]">
                                                        <div className="w-[10px] mr-[.8rem] flex-initial">
                                                            {" "}
                                                            <img className="ml-auto" src="/images/inbox/dot-checked.svg" alt="checron-dwn" />{" "}
                                                        </div>{" "}
                                                        {/* <span className="check absolute invisible opacity-0">
								                         <Checkbox
								                           defaultChecked={checkedItems[index]}
								                           disabled={
								                             read?.emailId !== ""
								                               ? read?.emailId !== el?.id
								                               : false
								                           }
								                           onChange={handleCheckboxChange(
								                             index,
								                             el?.id,
								                             el?.isRead
								                           )}
								                           size="small"
								                           sx={{
								                             padding: 0,
								                             color: "#008785",
								                             "&.Mui-checked": {
								                               color: "#008785",
								                             },
								                           }}
								                         />{" "}
								                      </span> */}
                                                        <span className={`${!el?.isRead ? `font-interSb` : ``} flex-1 block truncate`}>
                                                            {el?.sender?.emailAddress?.name}
                                                        </span>
                                                    </div>
                                                    <p
                                                        className={`text-xs pl-6 text-${currentMode}-890 xl:max-w-[14rem] max-w-[17rem] truncate trunc-1`}>
                                                        {el?.subject}
                                                    </p>
                                                </div>
                                                <div className="relative text-right">
                                                    <div className="flex justify-end items-center space-x-1">
                                                        <span className={`text-${currentMode}-900  flex-initial`}>
                                                            <img
                                                                onClick={(event) => {
                                                                    handleDelete(el?.id, i, el?.conversationId, event);
                                                                }}
                                                                src="/images/inbox/trash-2.svg"
                                                                className="ml-auto convertWhite"
                                                                alt="trash-2"
                                                            />
                                                        </span>
                                                    </div>
                                                    <span className={`text-${currentMode}-900 block flex-initial trunc-1`}>
                                                        {/* {moment(new Date()).format("DD/MM/YYYY") ==
                                                        moment(
                                                            new Date(data?.bodypreview?.receivedDateTime)
                                                        ).format("DD/MM/YYYY")
                                                        ? data?.bodypreview?.receivedDateTime?.split(
                                                            "T"
                                                        )?.[1]
                                                        : data?.bodypreview?.receivedDateTime?.split(
                                                            "T"
                                                        )?.[0]} */}
                                                        {convertUtcDate(el?.receivedDateTime)}
                                                    </span>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </AccordionDetails>
                    </Accordion>
                </div>
            </>
        );
    };
    return (
        <div
            ref={containerRef}
            className={` ${compose ? `composeview` : ``} ${
                isActive !== "" ? "preview" : ""
            } w-full us:max-w-[380px] us:ml-[40px] xl:min-w-[350px] py-[20px] `}
            // } w-full xl:min-w-[350px] xl:h-[calc(100vh_-_13rem)] h-[calc(100vh_-_18rem)] xl:max-w-[380px] xl:ml-[40px] py-[20px]`}
        >
            <div className={`bg-${currentMode}-410 flex items-center mb-3 justify-between py-[12px] px-[15px] rounded-[8px] sticky top-[0px] z-[1]`}>
                <div className="flex items-center space-x-4">
                    {/* <Checkbox
						title="Checkbox"
						size="small"
						checked={selectAll}
						indeterminate={checkedItems.some((item) => item) && !selectAll}
						onChange={handleSelectAllChange}
						sx={{
						padding: 0,
						color: "#008785",
						"&.Mui-checked": {
							color: "#008785",
						},
						"&.MuiCheckbox-indeterminate": {
							color: "#008785",
						},
						}}
					/> */}
                    <img className="xl:hidden cursor-pointer" onClick={handleOpen} src="/images/inbox/hamburger.svg" alt="hamburger" />
                    <img
                        title="Refresh"
                        onClick={_refresh}
                        className="cursor-pointer w-[20px] h-[20px] object-contain convertWhite"
                        src="/images/inbox/reload-grn.svg"
                        alt="reload-grn"
                    />
                    <img
                        title="Read/Unread"
                        onClick={handleMarkAsRead}
                        className="cursor-pointer w-[18px] h-[18px] object-contain convertWhite"
                        src={
                            data?.find((item) => item?.bodypreview?.id == isActive)?.bodypreview?.isRead ||
                            emailPrevChildData?.find((item) => item?.id == isActive)?.isRead
                                ? ` /images/inbox/mail-open.svg`
                                : `/images/inbox/envelope.svg`
                        }
                        alt="envelope"
                    />
                </div>
                {/* <span>
					<img src="/images/inbox/search-grn.svg" alt="reload-grn" />
				</span> */}
            </div>
            <div
                className={`xl:basis-[31%]  ${
                    openFolder ? `overflow-hidden` : `overflow-y-auto`
                } w-full xl:h-[calc(100vh_-_18rem)] h-[calc(100vh_-_18rem)]`}>
                <div className={`bg-${currentMode}-card `}>
                    {data?.length == 0 && isLoading && <Loader className="h-[10px]" />}
                    {(isLoading && data?.length == 0) || (isLoading == undefined && data?.length == 0)}
                    {data?.length !== 0 && data !== null
                        ? data?.map((el, i) =>
                              el?.messagescount > 1 ? (
                                  <AccordCard key={i} class={!el?.bodypreview?.isRead ? "unread" : ""} data={el} index={i} />
                              ) : (
                                  <InboxCard
                                      key={i}
                                      onClick={() => {
                                          const confirmPopup = () => {
                                              if (!el?.bodypreview?.isRead) {
                                                  setIsClicks({
                                                      index: i,
                                                      isRead: true,
                                                      type: "normal",
                                                  });
                                                  values?.map((item) => {
                                                      if (item?.id == getFolderId) {
                                                          item.unreadItemCount = item?.unreadItemCount - 1;
                                                      }
                                                      return item;
                                                  });
                                                  dispatch(
                                                      onClickRead({
                                                          index: i,
                                                          isRead: true,
                                                          type: "normal",
                                                      })
                                                  );
                                              }
                                              _emailBodyView(el?.bodypreview?.id, null);
                                              setIsActive(el?.bodypreview?.id);
                                              if (el?.bodypreview?.isRead) {
                                                  setRead({
                                                      emailId: el?.bodypreview?.id,
                                                      isReadFlag: !el?.bodypreview?.isRead,
                                                  });
                                              }

                                              setIsUnreads({
                                                  index: i,
                                                  isRead: !el?.bodypreview?.isRead,
                                                  type: "normal",
                                              });
                                          };

                                          if (isDraft || compose || replyEnable) {
                                              Swal.fire({
                                                  text: "Changes in the mailbox will be removed.",
                                                  icon: "warning",
                                                  closeOnClickOutside: false,
                                                  buttons: {
                                                      cancel: true,
                                                      confirm: true,
                                                  },
                                              }).then((confirm) => {
                                                  if (confirm) {
                                                      {
                                                          confirmPopup();
                                                      }
                                                  }
                                              });
                                          } else {
                                              confirmPopup();
                                          }
                                      }}
                                      class={!el?.bodypreview?.isRead ? "unread" : ""}
                                      data={el}
                                      index={i}
                                  />
                              )
                          )
                        : !isLoading && (
                              <div className="text-center h-[calc(100vh_-_20rem)] grid place-items-center place-content-center">
                                  <div className="mx-auto my-5">
                                      <img className="mx-auto" src="/images/common/delivery.png" alt="delivery" />
                                  </div>
                              </div>
                          )}
                </div>
            </div>
            {isLoadingScroll && (
                <div className="p-[20px] text-center">
                    <Loader />
                </div>
            )}
            {data?.length !== 0 && data !== null && !isLoading && !isLoadingScroll && !hasMore && (
                <div className="p-[20px] text-center">No more data available.</div>
            )}
        </div>
    );
};
