import Card from "../../../components/UI/Card";
import { useState, useEffect, useRef, useContext, useMemo } from "react";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import Button from "../../../components/Button";
import POHeaderLineItemPopUp from "./POHeaderLineItemPopUp";
import ReactDOM from "react-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { formatNumber, handleDecimal, showNumber } from "../../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader/index.jsx";
import { NumericFormat } from "react-number-format";
import _ from "lodash";
const AdvanceHistoryTable = (props) => {
    const location = useLocation();
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const { data: data, fetching, requestComplete, error } = useSelector((state) => state?.GetAdvanceHistoryState);
    const numFormat = localStorage.getItem("userNumberFormat");
    const reqId = props?.reqId;
    const createReqId = props?.createReqId;
    const viewData = props?.viewData?.data;
    useEffect(() => {
        if (data?.length !== 0) {
            if (data?.[0]?.mobilization_advance?.[0]) {
                props?.setMobilizationHistory([
                    _.mapValues(data?.[0]?.mobilization_advance?.[0], (value, key) => {
                        // Convert to integer if the key is not 'adv_type'
                        return key !== "adv_type"
                            ? value !== "" && value !== null && value !== undefined
                                ? formatNumber(value)?.toFixed(2)
                                : 0
                            : value;
                    }),
                ]);
            }
            if (data?.[0]?.adhoc_advance?.[0]) {
                props?.setAdhocHistory([
                    _.mapValues(data?.[0]?.adhoc_advance?.[0], (value, key) => {
                        // Convert to integer if the key is not 'adv_type'
                        return key !== "adv_type" ? (value !== "" ? formatNumber(value)?.toFixed(2) : 0) : value;
                    }),
                ]);
            }
        } else if (reqId || createReqId) {
            if (viewData?.mobilization_advance?.[0]?.length !== 0) {
                props?.setMobilizationHistory([
                    _.mapValues(viewData?.mobilization_advance?.[0], (value, key) => {
                        // Convert to integer if the key is not 'adv_type'
                        return key !== "adv_type"
                            ? value !== "" && value !== null && value !== undefined
                                ? formatNumber(value)?.toFixed(2)
                                : 0
                            : value;
                    }),
                ]);
            }
            if (viewData?.adhoc_advance?.[0]?.length !== 0) {
                props?.setAdhocHistory([
                    _.mapValues(viewData?.adhoc_advance?.[0], (value, key) => {
                        // Convert to integer if the key is not 'adv_type'
                        return key !== "adv_type" ? (value !== "" ? formatNumber(value)?.toFixed(2) : 0) : value;
                    }),
                ]);
            }
        }
    }, [data, viewData]);
    useEffect(() => {
        if (
            (data?.length !== 0 && props?.MobilizationHistory && props?.adhocHistory) ||
            (viewData && props?.MobilizationHistory && props?.adhocHistory)
        ) {
            const sumObjects = (objects) => {
                const total = {};
                // Initialize total object with 0 for each parameter
                for (const obj of objects) {
                    // Calculate tot_adv_inc if this_bill_amt and adv_till_date exist
                    if (obj.this_bill_amt && obj.adv_till_date) {
                        obj.tot_adv_inc = showNumber(handleDecimal(formatNumber(obj.this_bill_amt) + formatNumber(obj.adv_till_date))?.toString());
                    }
                    for (const key in obj) {
                        if (key !== "adv_type") {
                            // Check if the key exists in total
                            if (total.hasOwnProperty(key)) {
                                // For Mobilization, use total[key], for Adhoc, use obj[key]
                                total[key] = showNumber(handleDecimal((total[key] ?? 0) + formatNumber(obj[key]))?.toString());
                            } else {
                                // If the key doesn't exist in total, initialize it
                                total[key] = formatNumber(obj[key]);
                            }
                        }
                    }
                }
                return total;
            };
            const sortedTotArr = sumObjects([...props?.MobilizationHistory, ...props?.adhocHistory]);
            props?.setTotalHistory([
                _.mapValues(sortedTotArr, (value, key) => {
                    // Convert to formatted string if the key is not 'adv_type'
                    return key !== "adv_type" ? (
                        <NumericFormat
                            thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                            decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                            value={value}
                            allowNegative={false}
                            decimalScale={2}
                            displayType="text"
                        />
                    ) : (
                        value
                    );
                }),
            ]);
        }
    }, [data, props?.MobilizationHistory, props?.adhocHistory, viewData]);
    return (
        <>
            <div className="flex mt-[19px] ml-[20px] mb-[20px]">
                <span className="text-[18px] font-medium">
                    <h3>Advance History</h3>
                </span>
            </div>
            <div className="max-h-[175px] overflow-x-auto">
                <table className="w-max xl:w-full border-0  rolemasterTable rounded-[0] text-left">
                    <thead>
                        <tr className="text-[14px]">
                            <th className="px-[18px] rounded-l-[0]">Type of Advance</th>
                            <th className="">This Bill Amount</th>
                            <th className="">Adv. Till Date</th>
                            <th>Tot. Adv. Inc This Bill</th>
                            <th>Adv. Amount Adjusted</th>
                            <th className="rounded-r-[0]">Adv. Amount Unadjusted</th>
                            <th className="rounded-r-[0]">Invoice amt till date</th>
                        </tr>
                    </thead>
                    {fetching && !requestComplete ? (
                        <Loader className="flex h-[calc(100vh_-_17rem)]" />
                    ) : (
                        <>
                            {props?.MobilizationHistory?.length !== 0 && props?.adhocHistory?.length !== 0 ? (
                                <tbody className="relative">
                                    <>
                                        {props?.MobilizationHistory &&
                                            props?.MobilizationHistory.map((ele) => {
                                                return (
                                                    <tr key={""}>
                                                        <td className="px-[18px]">{ele?.adv_type}</td>
                                                        <td className="">
                                                            {" "}
                                                            <NumericFormat
                                                                thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                                                                decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                                                                value={ele?.this_bill_amt}
                                                                allowNegative={false}
                                                                decimalScale={2}
                                                                displayType="text"
                                                            />
                                                        </td>
                                                        <td className="">
                                                            {" "}
                                                            <NumericFormat
                                                                thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                                                                decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                                                                value={ele?.adv_till_date}
                                                                allowNegative={false}
                                                                decimalScale={2}
                                                                displayType="text"
                                                            />
                                                        </td>
                                                        <td className="">
                                                            {" "}
                                                            <NumericFormat
                                                                thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                                                                decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                                                                value={ele?.tot_adv_inc}
                                                                allowNegative={false}
                                                                decimalScale={2}
                                                                displayType="text"
                                                            />
                                                        </td>
                                                        <td className="">
                                                            {" "}
                                                            <NumericFormat
                                                                thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                                                                decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                                                                value={ele?.adv_amt_adjusted}
                                                                allowNegative={false}
                                                                decimalScale={2}
                                                                displayType="text"
                                                            />
                                                        </td>
                                                        <td className="">
                                                            {" "}
                                                            <NumericFormat
                                                                thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                                                                decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                                                                value={ele?.adv_amt_unadjusted}
                                                                allowNegative={false}
                                                                decimalScale={2}
                                                                displayType="text"
                                                            />
                                                        </td>
                                                        <td className="">
                                                            {" "}
                                                            <NumericFormat
                                                                thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                                                                decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                                                                value={ele?.inv_amt_till_date}
                                                                allowNegative={false}
                                                                decimalScale={2}
                                                                displayType="text"
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        {props?.adhocHistory &&
                                            props?.adhocHistory.map((item) => {
                                                return (
                                                    <tr key={""}>
                                                        <td className="px-[18px]">
                                                            {" "}
                                                            <NumericFormat
                                                                thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                                                                decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                                                                value={item?.adv_type}
                                                                allowNegative={false}
                                                                decimalScale={2}
                                                                displayType="text"
                                                            />
                                                        </td>
                                                        <td className="">
                                                            <NumericFormat
                                                                thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                                                                decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                                                                value={item?.this_bill_amt}
                                                                allowNegative={false}
                                                                decimalScale={2}
                                                                displayType="text"
                                                            />
                                                        </td>
                                                        <td className="">
                                                            {" "}
                                                            <NumericFormat
                                                                thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                                                                decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                                                                value={item?.adv_till_date}
                                                                allowNegative={false}
                                                                decimalScale={2}
                                                                displayType="text"
                                                            />
                                                        </td>
                                                        <td className="">
                                                            {" "}
                                                            <NumericFormat
                                                                thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                                                                decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                                                                value={item?.tot_adv_inc}
                                                                allowNegative={false}
                                                                decimalScale={2}
                                                                displayType="text"
                                                            />
                                                        </td>
                                                        <td className="">
                                                            {" "}
                                                            <NumericFormat
                                                                thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                                                                decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                                                                value={item?.adv_amt_adjusted}
                                                                allowNegative={false}
                                                                decimalScale={2}
                                                                displayType="text"
                                                            />
                                                        </td>
                                                        <td className="">
                                                            {" "}
                                                            <NumericFormat
                                                                thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                                                                decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                                                                value={item?.adv_amt_unadjusted}
                                                                allowNegative={false}
                                                                decimalScale={2}
                                                                displayType="text"
                                                            />
                                                        </td>
                                                        <td className="">
                                                            {" "}
                                                            <NumericFormat
                                                                thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                                                                decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                                                                value={item?.inv_amt_till_date}
                                                                allowNegative={false}
                                                                decimalScale={2}
                                                                displayType="text"
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        {props?.totalHistory &&
                                            props?.totalHistory.map((element) => {
                                                return (
                                                    <tr key={""} className="bg-[#D3FAF445]">
                                                        <td className="px-[18px] font-semibold">Total</td>
                                                        <td className="">{element?.this_bill_amt}</td>
                                                        <td className="">{element?.adv_till_date}</td>
                                                        <td className="">{element?.tot_adv_inc}</td>
                                                        <td className="">{element?.adv_amt_adjusted}</td>
                                                        <td className="">{element?.adv_amt_unadjusted}</td>
                                                        <td className="">{element?.inv_amt_till_date}</td>
                                                    </tr>
                                                );
                                            })}
                                    </>
                                </tbody>
                            ) : (
                                <div className="absolute left-[50%] translate-x-[-50%] mt-10">
                                    <h4 className="font-interSb text-center">No Data found</h4>
                                </div>
                            )}
                        </>
                    )}
                </table>
            </div>
        </>
    );
};

export default AdvanceHistoryTable;
