// These will be available from the sidebar
export const fields = [
    {
        type: "title",
        title: "Heading",
        visibility: 1,
        border: 0,
        mandatory: false,
        subHeading: false,
        hideField: false,
        img: "/images/vendor-onboard/title.svg",
    },
    {
        type: "name",
        title: "Name",
        isFirstname: false,
        isLastname: false,
        visibility: 1,
        mandatory: true,
        prefix: false,
        suffix: false,
        description: false,
        img: "/images/vendor-onboard/name.svg",
    },
    {
        type: "email",
        title: "Email",
        visibility: 1,
        initialValue: "",
        mandatory: false,
        description: false,
        img: "/images/vendor-onboard/mail.svg",
    },
    {
        type: "address",
        title: "Address",
        visibility: 1,
        initialValue: "",
        mandatory: false,
        description: false,
        address1: "",
        address2: "",
        img: "/images/vendor-onboard/address.svg",
    },
    {
        type: "phone",
        title: "Phone",
        visibility: 1,
        initialValue: "",
        mandatory: false,
        description: false,
        img: "/images/vendor-onboard/phone.svg",
    },
    {
        type: "singleline",
        title: "Single Line",
        visibility: 1,
        initialValue: "",
        mandatory: false,
        description: false,
        img: "/images/vendor-onboard/single-line.svg",
    },
    {
        type: "multiline",
        title: "Multi Line",
        visibility: 1,
        initialValue: "",
        mandatory: false,
        description: false,
        img: "/images/vendor-onboard/multi-line.svg",
    },
    {
        type: "date",
        title: "Date",
        visibility: 1,
        initialValue: "",
        mandatory: false,
        description: false,
        img: "/images/vendor-onboard/date.svg",
    },
    {
        type: "time",
        title: "Time",
        visibility: 1,
        initialValue: "",
        mandatory: false,
        description: false,
        img: "/images/vendor-onboard/time.svg",
    },
    {
        type: "number",
        title: "Number",
        visibility: 1,
        initialValue: "",
        mandatory: false,
        description: false,
        img: "/images/vendor-onboard/number.svg",
    },
    {
        type: "dropdown",
        title: "Drop Down",
        visibility: 1,
        initialValue: "",
        mandatory: false,
        description: false,
        img: "/images/vendor-onboard/drop-down.svg",
    },
    {
        type: "radio",
        title: "Radio",
        visibility: 1,
        initialValue: "",
        mandatory: false,
        description: false,
        img: "/images/vendor-onboard/radio.svg",
    },
    {
        type: "checkbox",
        title: "Checkbox",
        visibility: 1,
        initialValue: "",
        mandatory: false,
        description: false,
        img: "/images/vendor-onboard/checkbox.svg",
    },
    {
        type: "multiselect",
        title: "Multi Select",
        visibility: 1,
        initialValue: "",
        mandatory: false,
        description: false,
        img: "/images/vendor-onboard/multi-select.svg",
    },
    {
        type: "richtext",
        title: "Rich Text",
        visibility: 1,
        initialValue: "",
        mandatory: false,
        description: false,
        img: "/images/vendor-onboard/rich-text.svg",
    },
    {
        type: "upload",
        title: "Upload",
        visibility: 1,
        initialValue: "",
        mandatory: false,
        description: false,
        img: "/images/vendor-onboard/upload.svg",
    },
    {
        type: "table",
        title: "Table",
        visibility: 1,
        initialValue: "",
        mandatory: false,
        description: false,
        img: "/images/vendor-onboard/table.svg",
    },
];

export const renderers = {
    input: () => <input type="text" placeholder="This is a text input" />,
    textarea: () => <textarea rows="5" />,
    select: () => (
        <select>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
        </select>
    ),
    text: () => (
        <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever
            since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only
            five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the
            release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker
            including versions of Lorem Ipsum.
        </p>
    ),
    button: () => <button>Button</button>,
};
