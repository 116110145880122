import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactDOM from "react-dom";
import { Checkbox, FormControl, FormControlLabel, MenuItem, Select, TextField } from "@mui/material";
import Card from "../../../components/UI/Card";
import Button from "../../../components/Button";
import { GetRuleList, GetRuleView } from "../../../redux/actions/rule-based";
import { UserAllocationDropDown } from "../../../redux/actions/user";
import { getRoleListAllocation, ViewRolePermission } from "../../../redux/actions/role";
import RuleDetailsModal from "./UsersPortals/RuleDetailsModal";
import RoleDetailsModal from "./UsersPortals/RoleDetailsModal";
import AddUsersModal from "./UsersPortals/AddUsersModal";
import SelectUsersComponent from "./UsersPortals/SelectUsersComponent";
import _ from "lodash";
import Swal from "sweetalert2";
import Loader from "../../../components/Loader/index.jsx";
let getId = null;
const UsersModal = (props) => {
    const { currentMode, openEdit, selectedId, setSelectedId, check, setCheck, createStatusType, licenseType } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [userId, setUserId] = useState();
    const [RoleId, setRoleId] = useState();
    const orgId = localStorage.getItem("orgId");
    const [orgName, setOrgName] = useState(orgId);
    const dispatch = useDispatch();
    const [main, setMain] = useState([]);
    const [cloneData, setCloneData] = useState([]);
    //initial obj while add new one
    const InitialObj = {
        id: 0,
        round_robin: false,
        role_flag: false,
        role_id: 0,
        rule_flag: false,
        rule_id: 0,
        user_flag: false,
        rule_user: [],
        user_list: [],
        seq: main?.length,
    };
    const [statusType, setStatusType] = useState();
    //view the user list based on selected status id
    useEffect(() => {
        if (selectedId !== null) {
            check.filter((ele, i) => {
                const defauObj = check?.find((el, i) => i == selectedId);
                setStatusType(defauObj?.status_type);
                if (i == selectedId && ele?.user_set !== null) {
                    // Update the 'user_set' array with the 'seq' parameter
                    const updatedUserSet = ele.user_set.map((user, userIndex) => ({
                        ...user,
                        seq: userIndex,
                    }));
                    setMain(updatedUserSet);
                    // return { ...ele, user_set: updatedUserSet };
                } else if (i == selectedId) {
                    // ele.user_set =  [];
                    setMain([]);
                }
            });
            props?.cloneData.filter((ele, i) => {
                if (i == selectedId && ele?.user_set !== null) {
                    // Update the 'user_set' array with the 'seq' parameter
                    const updatedUserSet = ele.user_set.map((user, userIndex) => ({
                        ...user,
                        seq: userIndex,
                    }));
                    setCloneData(updatedUserSet);
                    // return { ...ele, user_set: updatedUserSet };
                } else if (i == selectedId) {
                    // ele.user_set =  [];
                    setCloneData([]);
                }
            });
        }
    }, [selectedId, check?.length]);
    //check the user data and restrict on less than 50 objects
    const findUserDataLength = main?.length >= 50;
    //function to create a new row
    const addNewRow = () => {
        if (!findUserDataLength) {
            // Updating the state to include the new object and retain the old data
            const filteredArray = main?.filter((obj) => Object.keys(obj)?.length !== 0);
            const arra = [...filteredArray, InitialObj];
            setMain(arra);
            // Assuming check is the state variable that holds the array
            setCheck((prevCheck) => {
                // Assuming selectedId is the index where you want to update the user_set property
                const updatedCheck = prevCheck.map((ele, i) => {
                    if (i === selectedId) {
                        return {
                            ...ele,
                            user_set: arra,
                        };
                    }
                    return ele;
                });
                return updatedCheck;
            });
        } else if (findUserDataLength) {
            Swal.fire({
                text: "Cannot create a rule with more than 50!",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: "Confirm",
                },
            }).then((confirm) => {
                if (confirm) {
                    return false;
                }
            });
        }
    };
    // function to remove the existing row based on id and sort the display order
    const deleteRow = (index, e) => {
        getId = index;
        setAnchorEl(e?.target);
        handleClickAway();
        Swal.fire({
            text: "Are you sure you want to delete the user rules and role privileges?",
            icon: "warning",
            closeOnClickOutside: false,
            showCancelButton: true,
            showCloseButton: true,
            buttons: {
                cancel: "Cancel",
                confirm: "Confirm",
            },
        }).then((confirm) => {
            if (confirm?.isConfirmed) {
                // Filtering out the object at the specified index
                const updatedArray = main.filter((_, i) => i !== index);
                // Updating the seq value based on the position of the index
                const updatedArrayWithSeq = updatedArray.map((obj, i) => ({
                    ...obj,
                    seq: i,
                }));
                setMain(updatedArrayWithSeq);
                setCheck((prevCheck) => {
                    // Assuming selectedId is the index where you want to update the user_set property
                    const updatedCheck = prevCheck.map((ele, i) => {
                        if (i === selectedId) {
                            return {
                                ...ele,
                                user_set: updatedArrayWithSeq,
                            };
                        }
                        return ele;
                    });
                    return updatedCheck;
                });
            }
        });
    };
    //* Reducer values for role and rule
    const Rule = useSelector((state) => state?.GetRuleListState);
    const role = useSelector((state) => state?.RoleListAllocationState);
    const profileData = useSelector((state) => state?.userAllocationDropDownState);
    const { data: RuleListDatas, requestComplete } = Rule;
    const { data: RoleList } = role;
    const { data: listData } = profileData;
    const RuleListData = RuleListDatas?.filter((el) => el?.isactive == true);
    //* Variables for rule/role view popup
    const [userView, setUserView] = useState(false);
    const [roleView, setRoleView] = useState(false);
    const ruleList = useSelector((state) => state?.GetViewRuleListState);
    const ruleDataList = ruleList?.data?.data;
    const { data: ViewRule } = useSelector((state) => state?.RuleViewState);
    const DataValue = ViewRule?.[0];
    const DataItems = DataValue?.rules_info;
    //* Values for the popover
    // const moreButton = [
    // {
    //     id: 1,
    //     src: "/images/workflow-automation/role.svg",
    //     name: "View Role",
    //     to: "/role-master",
    // },
    // {
    //     id: 2,
    //     src: "/images/workflow-automation/rule.svg",
    //     name: "View Rule",
    //     to: "/invoice-rule",
    // },
    // {
    //     id: 3,
    //     src: "/images/workflow-automation/list-delete.svg",
    //     name: "Delete",
    //     to: "/workflow-automation",
    // },
    // ];
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const [addUserAnchor, setAddUserAnchor] = useState(false);
    const addUserOpen = Boolean(addUserAnchor);

    //* Call the rule,role and users api for the dropdowns
    useEffect(() => {
        dispatch(GetRuleList(props?.documentGroup));
        dispatch(UserAllocationDropDown(orgName, props?.documentGroup));
        if (RoleList?.length == 0 || !RoleList) {
            dispatch(getRoleListAllocation(+orgName));
        }
    }, [dispatch]);

    //* Function to close the popover
    const handleClickAway = () => {
        getId = null;
        setAnchorEl(false);
    };
    //* THis one changes the round robin check
    const _handleRoundRobin = (index, e) => {
        const Update = _.chain(main).find({ seq: index }).merge({ round_robin: e?.target?.checked }).value();
        const New = main?.map((el, i) => (el?.seq == Update?.seq ? Update : el));
        setMain(New);
        setCheck((prevCheck) => {
            // Assuming selectedId is the index where you want to update the user_set property
            const updatedCheck = prevCheck.map((ele, ind) => {
                if (ind === selectedId) {
                    return {
                        ...ele,
                        user_set: New,
                    };
                }
                return ele;
            });
            return updatedCheck;
        });
    };
    //* This one is approve Rule based checkox onchange dynamically
    const _newAssignRuleChange = (index, e) => {
        if (e?.target?.checked) {
            const Update = _.chain(main)
                .find({ seq: index })
                .merge({ rule_flag: e?.target?.checked, user_flag: false, user_list: null, rule_user: null })
                .value();
            const New = main?.map((el, i) => (el?.seq == Update?.seq ? Update : el));
            setMain(New);
            setCheck((prevCheck) => {
                // Assuming selectedId is the index where you want to update the user_set property
                const updatedCheck = prevCheck.map((ele, ind) => {
                    if (ind === selectedId) {
                        return {
                            ...ele,
                            user_set: New,
                        };
                    }
                    return ele;
                });
                return updatedCheck;
            });
        } else if (!e?.target?.checked) {
            const Update = _.chain(main)
                .find({ seq: index })
                .merge({
                    rule_id: 0,
                    rule_flag: e?.target?.checked,
                    user_list: null,
                })
                .value();
            const New = main?.map((el, i) => (el?.seq == Update?.seq ? Update : el));
            setMain(New);
            setCheck((prevCheck) => {
                // Assuming selectedId is the index where you want to update the user_set property
                const updatedCheck = prevCheck.map((ele, ind) => {
                    if (ind === selectedId) {
                        return {
                            ...ele,
                            user_set: New,
                        };
                    }
                    return ele;
                });
                return updatedCheck;
            });
        }
    };
    //* This one is approve Role based checkox onchange dynamically
    const _newRoleChange = (index, e) => {
        if (e?.target?.checked) {
            const Update = _.chain(main)
                .find({ seq: index })
                .merge({ role_flag: e?.target?.checked, user_flag: false, user_list: null, rule_user: null })
                .value();
            const New = main?.map((el, i) => (el?.seq == Update?.seq ? Update : el));
            setMain(New);
            setCheck((prevCheck) => {
                // Assuming selectedId is the index where you want to update the user_set property
                const updatedCheck = prevCheck.map((ele, ind) => {
                    if (ind === selectedId) {
                        return {
                            ...ele,
                            user_set: New,
                        };
                    }
                    return ele;
                });
                return updatedCheck;
            });
        } else if (!e?.target?.checked) {
            const Update = _.chain(main).find({ seq: index }).merge({ role_flag: e?.target?.checked, role_id: 0 }).value();
            const New = main?.map((el, i) => (el?.seq == Update?.seq ? Update : el));
            setMain(New);
            setCheck((prevCheck) => {
                // Assuming selectedId is the index where you want to update the user_set property
                const updatedCheck = prevCheck.map((ele, ind) => {
                    if (ind === selectedId) {
                        return {
                            ...ele,
                            user_set: New,
                        };
                    }
                    return ele;
                });
                return updatedCheck;
            });
        }
    };

    //* Select Users check
    const selectUsersCheck = (index, user, e) => {
        setRoleId(user);
        if (e?.target?.checked) {
            const Update = _.chain(main)
                .find({ seq: index })
                .merge({ user_flag: e?.target?.checked, rule_flag: false, rule_id: 0, role_id: 0, role_flag: false, rule_user: null })
                .value();
            const New = main?.map((el, i) => (el?.seq == Update?.seq ? Update : el));
            setMain(New);
            setCheck((prevCheck) => {
                // Assuming selectedId is the index where you want to update the user_set property
                const updatedCheck = prevCheck.map((ele, ind) => {
                    if (ind === selectedId) {
                        return {
                            ...ele,
                            user_set: New,
                        };
                    }
                    return ele;
                });
                return updatedCheck;
            });
        } else if (!e?.target?.checked) {
            const Update = _.chain(main)
                .find({ seq: index })
                .merge({
                    user_flag: e?.target?.checked,
                    user_list: null,
                })
                .value();
            const New = main?.map((el, i) => (el?.seq == Update?.seq ? Update : el));
            setMain(New);
            setCheck((prevCheck) => {
                // Assuming selectedId is the index where you want to update the user_set property
                const updatedCheck = prevCheck.map((ele, ind) => {
                    if (ind === selectedId) {
                        return {
                            ...ele,
                            user_set: New,
                        };
                    }
                    return ele;
                });
                return updatedCheck;
            });
        }
    };
    //* Function for the three dots popper : index - index of main array, id - the user selected id in the popover
    const usersDotFunction = (index, id) => {
        handleClickAway();
    };
    //* This one is select dropdown handlechange for Rule based dynamically
    const _newAssignRuleIDChange = (index, e) => {
        const Update = _.chain(main).find({ seq: index }).merge({ rule_id: e?.target?.value }).value();
        const New = main?.map((el, i) => (el?.seq == Update?.seq ? Update : el));
        setMain(New);
        setCheck((prevCheck) => {
            // Assuming selectedId is the index where you want to update the user_set property
            const updatedCheck = prevCheck.map((ele, ind) => {
                if (ind === selectedId) {
                    return {
                        ...ele,
                        user_set: New,
                    };
                }
                return ele;
            });
            return updatedCheck;
        });
    };

    //* This one is select dropdown handlechange for Role based dynamically
    const _newRoleIdChange = (index, e) => {
        const Update = _.chain(main).find({ seq: index }).merge({ role_id: e?.target?.value }).value();
        const New = main?.map((el, i) => (el?.seq == Update?.seq ? Update : el));
        setMain(New);
        setCheck((prevCheck) => {
            // Assuming selectedId is the index where you want to update the user_set property
            const updatedCheck = prevCheck.map((ele, ind) => {
                if (ind === selectedId) {
                    return {
                        ...ele,
                        user_set: New,
                    };
                }
                return ele;
            });
            return updatedCheck;
        });
    };

    //* Call the api's respectively for role/rule view model
    const _handleViewRuleIcon = (id) => {
        setUserView(true);
        dispatch(GetRuleView(+id));
    };

    const handleRoleView = (id) => {
        setRoleView(true);
        dispatch(ViewRolePermission({ roleid: +id }));
    };

    //* This one is Add user modal Onclick function
    const _handleUserAdd = (index, value, users, event) => {
        setUserId(users);
        getId = index;
        setAddUserAnchor(event.target);
    };
    //select user for rule based
    const _handleSelectUser = (index, value, reason, type) => {
        if (reason === "selectOption") {
            const UserId = value?.map((el) => +el?.user_id);
            if (type == "rule_id") {
                let SetArr;
                if (userId !== null && userId !== undefined) {
                    SetArr = [...userId, ...UserId];
                } else {
                    SetArr = [...UserId];
                }
                const Unique = [...new Set(SetArr)];
                setUserId(Unique);
                const Update = _.chain(main).find({ seq: index }).merge({ rule_user: UserId }).value();
                const New = main?.map((el, i) => (el?.seq == Update?.seq ? Update : el));
                setMain(New);
                setCheck((prevCheck) => {
                    // Assuming selectedId is the index where you want to update the user_set property
                    const updatedCheck = prevCheck.map((ele, i) => {
                        if (i === selectedId) {
                            return {
                                ...ele,
                                user_set: New,
                            };
                        }
                        return ele;
                    });
                    return updatedCheck;
                });
            }
        } else if (reason === "removeOption" || reason === "clear") {
            if (type == "rule_id") {
                const userReomove = value?.map((el) => +el?.user_id);
                const Exist = userId?.filter((el) => userReomove?.includes(el));
                const Arr = [...Exist];
                setUserId(Arr);
                const Update = _.set(_.find(main, { seq: index }), "rule_user", Arr);
                const New = main?.map((el) => (el?.seq == Update?.seq ? Update : el));
                setMain(New);
                setCheck((prevCheck) => {
                    // Assuming selectedId is the index where you want to update the user_set property
                    const updatedCheck = prevCheck.map((ele, i) => {
                        if (i === selectedId) {
                            return {
                                ...ele,
                                user_set: New,
                            };
                        }
                        return ele;
                    });
                    return updatedCheck;
                });
            }
        }
    };
    //select user for Assgn users
    const handleAssignUser = (index, value, reason, e) => {
        const UserId = value?.map((el) => +el?.user_id);
        if (reason == "selectOption") {
            getId = index;
            let userList;
            const findUser = main?.find((item) => index == item?.seq)?.user_list;
            if (findUser) {
                userList = [...findUser, ...UserId];
            } else {
                userList = [...UserId];
            }
            const Unique = [...new Set(userList)];
            const UpdateValue = _.chain(main).find({ seq: index }).merge({ user_list: Unique }).value();
            const NewValue = main?.map((el, i) => {
                if (el?.seq == UpdateValue?.seq) {
                    return UpdateValue;
                } else {
                    return el;
                }
            });
            setMain(NewValue);
            setCheck((prevCheck) => {
                // Assuming selectedId is the index where you want to update the user_set property
                const updatedCheck = prevCheck.map((ele, i) => {
                    if (i === selectedId) {
                        return {
                            ...ele,
                            user_set: NewValue,
                        };
                    }
                    return ele;
                });
                return updatedCheck;
            });
        } else if (reason == "removeOption" || reason === "clear") {
            getId = index;
            const userRemove = value?.map((el) => +el?.user_id);
            const findUser = main?.find((item) => index == item?.seq)?.user_list;
            const ExistNew = findUser ? findUser?.filter((el) => userRemove?.includes(el)) : [];
            const Array = [...ExistNew];
            const UpdateNew = _.set(_.find(main, { seq: index }), "user_list", Array);
            const NewValue = main?.map((el, i) => {
                if (el?.seq == UpdateNew?.seq) {
                    return UpdateNew;
                } else {
                    return el;
                }
            });
            setMain(NewValue);
            setCheck((prevCheck) => {
                // Assuming selectedId is the index where you want to update the user_set property
                const updatedCheck = prevCheck.map((ele, i) => {
                    if (i === selectedId) {
                        return {
                            ...ele,
                            user_set: NewValue,
                        };
                    }
                    return ele;
                });
                return updatedCheck;
            });
        }
    };
    //close the rule user popper
    const _handleUserClose = (index, value, type) => {
        setAddUserAnchor(false);
    };
    const handleClose = (index, existingRuleUser) => {
        //  const existingRuleUser = _.chain(cloneData).find({ seq: index }).get("rule_user").value();
        const Update = _.chain(main)
            .find({ seq: index })
            .merge({ rule_user: existingRuleUser?.length > 0 ? existingRuleUser : null })
            .value();
        const New = main?.map((el, i) => (el?.seq == Update?.seq ? Update : el));
        setMain(New);
        setAddUserAnchor(false);
        setCheck((prevCheck) => {
            // Assuming selectedId is the index where you want to update the user_set property
            const updatedCheck = prevCheck.map((ele, i) => {
                if (i === selectedId) {
                    return {
                        ...ele,
                        user_set: New,
                    };
                }
                return ele;
            });
            return updatedCheck;
        });
    };
    const checkShowUser = Rule.requestComplete && role?.requestComplete && profileData?.requestComplete;
    return checkShowUser ? (
        <Card
            tabIndex={0}
            className={`basis-[35%]  mt-3  border-x-[0] border-y-[0] border-${currentMode}-600 h-[calc(100vh_-_40rem)] xl:h-[calc(100vh_-_17rem)] overflow-y-auto `}
        >
            <div className="mt-1">
                {main?.map((el, i) => {
                    return (
                        <div className={`flex flex-col px-5 w-full  border-b-[1px] py-5`}>
                            <div className="flex justify-between ">
                                <div className="basis-[85%] flex justify-between">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                tabIndex={0}
                                                size="small"
                                                sx={{
                                                    color: "rgba(85,85,85,0.7)",
                                                    pointerEvents: "none",
                                                    cursor: "default",
                                                    "&.Mui-checked": {
                                                        color: "#028C78",
                                                    },
                                                }}
                                            />
                                        }
                                        label="Round Robin"
                                        checked={el?.round_robin}
                                        onChange={(e) => _handleRoundRobin(i, e)}
                                    />
                                    {licenseType != 3 && (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    tabIndex={0}
                                                    sx={{
                                                        color: "rgba(85,85,85,0.7)",
                                                        "&.Mui-checked": {
                                                            color: "#028C78",
                                                        },
                                                    }}
                                                    size="small"
                                                />
                                            }
                                            label="Rule Based"
                                            checked={el?.rule_flag}
                                            onChange={(e) => _newAssignRuleChange(i, e)}
                                        />
                                    )}
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                tabIndex={0}
                                                sx={{
                                                    color: "rgba(85,85,85,0.7)",
                                                    "&.Mui-checked": {
                                                        color: "#028C78",
                                                    },
                                                }}
                                                size="small"
                                            />
                                        }
                                        label="Role Based"
                                        checked={el?.role_flag}
                                        onChange={(e) => _newRoleChange(i, e)}
                                    />
                                </div>
                                <Button title="To Delete" className="px-[5px] w-max" onClick={(e) => deleteRow(i, e)}>
                                    <img src="/images/workflow-automation/delete.svg" alt="Delete" />
                                </Button>
                            </div>
                            {statusType !== 2 && (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            tabIndex={0}
                                            sx={{
                                                color: "rgba(85,85,85,0.7)",
                                                "&.Mui-checked": {
                                                    color: "#028C78",
                                                },
                                            }}
                                            size="small"
                                        />
                                    }
                                    label="Select Users"
                                    checked={el?.user_flag}
                                    onChange={(e) => selectUsersCheck(i, el?.user_list, e)}
                                />
                            )}
                            {el?.rule_flag && (
                                <div className="flex mt-2 justify-between">
                                    <div className="flex">
                                        <img
                                            src={
                                                currentMode !== "dark"
                                                    ? `/images/workflow-automation/rule.svg`
                                                    : `/images/workflow-automation/rule_white.svg`
                                            }
                                            className="min-h-[23.04px] min-w-[28.47px] pr-2"
                                        />
                                        <Select
                                            value={el?.rule_id}
                                            onChange={(e) => _newAssignRuleIDChange(i, e)}
                                            name="Rulelist"
                                            inputProps={{
                                                "aria-label": "Without label",
                                                tabIndex: "1",
                                            }}
                                            IconComponent={() => (
                                                <img
                                                    className="absolute right-[15px] opacity-50 pointer-events-none"
                                                    src={
                                                        currentMode !== "dark"
                                                            ? `/images/header/chevronDown.svg`
                                                            : `/images/header/chevronDown-white.svg`
                                                    }
                                                    alt="chevronDown"
                                                />
                                            )}
                                            MenuProps={{
                                                sx: {
                                                    "& .MuiMenu-paper": {
                                                        backgroundColor: dropdownColor,
                                                        color: dropdownText,
                                                        maxWidth: 225,
                                                    },
                                                    "& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                    },
                                                    "& .Mui-selected": {
                                                        backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                    },
                                                },
                                            }}
                                            SelectDisplayProps={{
                                                style: {
                                                    paddingTop: "9px",
                                                    paddingBottom: "9px",
                                                    maxWidth: 225,
                                                    minWidth: 225,
                                                    color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                    paddingRight: "30px",
                                                    border: "1px solid rgba(85, 85, 85, 0.3)",
                                                    borderRadius: "8px",
                                                },
                                            }}
                                        >
                                            <MenuItem key={0} name="RuleList" value={0}>
                                                {"Select Rule"}
                                            </MenuItem>

                                            {RuleListData &&
                                                RuleListData?.map((el, i) => (
                                                    <MenuItem name="RuleList" title={el?.rule_name} value={el?.id} className="truncate trunc-1 block">
                                                        {el?.rule_name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </div>
                                    {el?.rule_id > 0 && !el?.role_flag && (
                                        <>
                                            <Button
                                                tabIndex={0}
                                                className="flex space-x-2 items-center cursor-pointer"
                                                onClick={(event) => _handleUserAdd(i, true, el?.rule_user, event)}
                                            >
                                                <div title="Add User">
                                                    <img src="/images/main/plus-circle.svg" alt="plus-circle" className="convertWhite" />
                                                </div>
                                                <span>
                                                    {el?.rule_user?.length == undefined || el?.rule_user?.length == 0 || el?.rule_user == [0]
                                                        ? "Add User"
                                                        : el?.rule_user?.length + " Users"}
                                                </span>
                                            </Button>
                                        </>
                                    )}
                                    <AddUsersModal
                                        cloneData={props?.cloneData}
                                        id={i}
                                        anchorEl={addUserAnchor}
                                        open={addUserOpen}
                                        placement={"bottom"}
                                        tabIndex={0}
                                        isOpened={addUserOpen}
                                        listData={listData?.filter((item) => item?.processed == true)}
                                        handleSelectUser={(e, value, reason) => _handleSelectUser(getId, value, reason, "rule_id")}
                                        defaultId={userId}
                                        submitClose={() => _handleUserClose(i, false, "rule_id")}
                                        handleClose={handleClose}
                                        className={addUserOpen && "Show"}
                                    />
                                    <Button
                                        tabIndex={0}
                                        title="To View Rule"
                                        className="cursor-pointer"
                                        onClick={(e) => _handleViewRuleIcon(el?.rule_id)}
                                    >
                                        <img src="/images/common/eyeOpen.svg" alt="eye-blk" className="convertWhite" />
                                    </Button>
                                </div>
                            )}
                            {el?.role_flag && (
                                <div className="flex mt-3 justify-between">
                                    <div className="flex">
                                        <img
                                            src={
                                                currentMode !== "dark"
                                                    ? `/images/workflow-automation/role.svg`
                                                    : `/images/workflow-automation/role-white.svg`
                                            }
                                            className="min-h-[23.04px] min-w-[28.47px] pr-3"
                                        />
                                        <Select
                                            value={el?.role_id}
                                            onChange={(e) => _newRoleIdChange(i, e)}
                                            name="Rolelist"
                                            inputProps={{
                                                "aria-label": "Without label",
                                                tabIndex: "1",
                                            }}
                                            IconComponent={() => (
                                                <img
                                                    className="absolute right-[15px] opacity-50 pointer-events-none"
                                                    src={
                                                        currentMode !== "dark"
                                                            ? `/images/header/chevronDown.svg`
                                                            : `/images/header/chevronDown-white.svg`
                                                    }
                                                    alt="chevronDown"
                                                />
                                            )}
                                            MenuProps={{
                                                sx: {
                                                    "& .MuiMenu-paper": {
                                                        backgroundColor: dropdownColor,
                                                        color: dropdownText,
                                                        maxWidth: 225,
                                                    },
                                                    "& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                    },
                                                    "& .Mui-selected": {
                                                        backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                    },
                                                },
                                            }}
                                            SelectDisplayProps={{
                                                style: {
                                                    paddingTop: "9px",
                                                    paddingBottom: "9px",
                                                    maxWidth: 225,
                                                    minWidth: 225,
                                                    color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                    paddingRight: "30px",
                                                    border: "1px solid rgba(85, 85, 85, 0.4)",
                                                    borderRadius: "8px",
                                                },
                                            }}
                                        >
                                            <MenuItem key={0} name="RoleList" value={0}>
                                                {"Select Role"}
                                            </MenuItem>

                                            {RoleList &&
                                                RoleList?.map((el, i) => (
                                                    <MenuItem
                                                        name="RoleList"
                                                        title={el?.name}
                                                        key={i + 1}
                                                        value={el?.role_id}
                                                        className={`truncate trunc-1 block ${
                                                            el?.processed == false || el?.exception == false ? "hidden" : ""
                                                        }`}
                                                    >
                                                        {el?.name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </div>
                                    {el?.role_id > 1 && (
                                        <Button
                                            tabIndex={0}
                                            title="To View Role"
                                            className="cursor-pointer"
                                            onClick={(e) => handleRoleView(el?.role_id)}
                                        >
                                            <img src="/images/common/eyeOpen.svg" alt="eye-blk" className="convertWhite" />
                                        </Button>
                                    )}
                                </div>
                            )}
                            {el?.user_flag && (
                                <div className="flex mt-3">
                                    <img
                                        src={
                                            currentMode !== "dark"
                                                ? `/images/workflow-automation/select-users.svg`
                                                : `/images/workflow-automation/select-user-white.svg`
                                        }
                                        className=" pr-2"
                                    />
                                    <SelectUsersComponent
                                        id={getId}
                                        defaultId={RoleId}
                                        check={check}
                                        element={el}
                                        listData={listData?.filter((item) => item?.processed == true || item?.approved == true)}
                                        handleSelectUser={(e, value, reason) => handleAssignUser(i, value, reason, e)}
                                        currentMode={currentMode}
                                    />
                                </div>
                            )}
                        </div>
                    );
                })}
                <Button title="Add new" tabIndex={0} className="mt-3 ml-5 xl:mb-0 mb-10" onClick={addNewRow} disabled={false}>
                    <span className="cursor-pointer text-light-10">+ Add New</span>
                </Button>
            </div>
            {ReactDOM.createPortal(
                <RuleDetailsModal
                    isOpened={userView}
                    ruleDataList={ruleDataList}
                    ruleList={ruleList}
                    DataValue={DataValue}
                    DataItems={DataItems}
                    handleClose={() => setUserView(false)}
                    className={userView && "Show"}
                />,
                document.getElementById("user-modal-root")
            )}
            {ReactDOM.createPortal(
                <RoleDetailsModal
                    isOpened={roleView}
                    ruleDataList={ruleDataList}
                    DataValue={DataValue}
                    DataItems={DataItems}
                    handleClose={() => setRoleView(false)}
                    className={roleView && "Show"}
                />,
                document.getElementById("user-modal-root")
            )}
        </Card>
    ) : (
        <Loader className="h-[calc(100vh_-_10rem)]" />
    );
};
export default UsersModal;
