import { Autocomplete, Checkbox, Chip, TextField, ClickAwayListener, ListItemText, Accordion, AccordionDetails, Paper } from "@mui/material";
import ReactQuill from "react-quill";
import { useState, useEffect, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { convertNewlinesToHtml, decryptId, extractTextFromHTML } from "../../../utils/helpers";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import { getEmailTemplatePlaceHolderList } from "../../../redux/actions/emailtask";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import { ExceptionEmailGeneration, ExceptionUserDropdownAct } from "../../../redux/actions/exception-automation";
import Loader from "../../../components/Loader";
import TogglePopup from "../../organisations/Toggle";
import { EMAIL_GENERATE_EXCEPTION_RESET } from "../../../redux/types/types";

import styled from "styled-components";
const EmailCategory = (props) => {
    const setCategoryArr = props?.setCategoryArr;
    const categoryArr = props?.categoryArr;
    const ViewArray = props?.selectedId?.email_json
    const UsersOptions = props?.UsersOptions;
    // const UsersOptions = ["rdanicka@iquatm.com", "epavithra@iquantm.com", "psushmi@iquantm.com", "spyntamil@iquantm.com", "svijay@iquantm.com"];
    const selectedId = 1;
    const AccordionSummary = props?.AccordionSummary;
    const { currentMode } = useContext(ThemeContext);
    const [notificationVal, setNotificationVal] = useState(ViewArray);
    const [expandEmail, setExpandEmail] = useState("0");
    const childCompRef = useRef();
    const handleChangeEmail = (panel) => (event, newExpanded) => {
        setExpandEmail(newExpanded ? panel : false);
    };
    // useEffect(() => {
    //     if (props?.selectedId && props?.currentId) {
    //         setViewArray(props?.selectedId?.email_json);
    //     }
    // }, [props?.selectedId]);

    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const dispatch = useDispatch();
    const EmailGenerate = useSelector((state) => state?.EmailGenerateExceptionState);
    const EmailGenerateData = EmailGenerate?.data?.email?.mail_body;
    const EmailGenearteSub = EmailGenerate?.data?.email?.subject;
    const [title, setTitle] = useState(ViewArray?.notification_title);
    const [notificationenable, setnotificationEnable] = useState(ViewArray?.push_flag);
    const [error1, setError] = useState(false);
    const [message, setMessage] = useState(ViewArray?.push_message);
    const [Emailenable, setemailEnable] = useState(ViewArray?.email_flag);
    const [subject, setSubject] = useState(ViewArray?.Sub);
    const [emailMessage, setEmailmessage] = useState(ViewArray?.body);
    const [offset, setOffset] = useState(null);
    const [emailTo, setEmailTo] = useState(ViewArray?.to);
    const [emailCC, setEmailCC] = useState(ViewArray?.cc);
    const [emailBcc, setEmailbcc] = useState(ViewArray?.bcc);
    const [inputValueTo, setInputValueTo] = useState("");
    const [inputValue, setInputValue] = useState("");
    const [inputValue2, setInputValue2] = useState("");
    const [filteredOptions, setFilteredOptions] = useState([]);
    const filterMenuList = [

        {
            text: "Make Shorter",
            value: 1,
            id: 1,
        },
        {
            text: "Make Longer",
            value: 2,
            id: 2,
        },
    ];
    useEffect(() => {
        setEmailTo(ViewArray?.to);
        setEmailCC(ViewArray?.cc);
        setEmailbcc(ViewArray?.bcc);
        setSubject(ViewArray?.Sub);
        setEmailmessage(ViewArray?.body);
        if (!props?.isView) {
            setExpandEmail("0");
        }
    }, [ViewArray, ViewArray?.Sub, ViewArray?.body, dispatch, props?.isView]);
    const CustomQuill = styled(ReactQuill)`
    .ql-toolbar.ql-snow {
        background-color: transparent !important;
    }
`;

    const orgName = props?.orgName;
    const [viewEmailBcc, setViewEmailBcc] = useState(false);
    const messageRef = useRef(null);
    const ReactQuillRef = useRef(null);
    const modules = {
        toolbar: [["bold", "italic", "underline", "blockquote"], [{ list: "ordered" }, { list: "bullet" }], ["clean"]],
    };


    const formats = [
        // "header",
        "bold",
        "italic",
        "underline",
        // "strike",
        "blockquote",
        "list",
        "bullet",
        // "indent",
        "link",
        "image",
    ];
    const { data: placeHolData } = useSelector((state) => state?.emailTemplatePlaceholderListState);
    const invoicePlaceHolderList = placeHolData?.filter((el) => el?.placeholder_group_name == "Invoice");
    const [showing, setShowing] = useState(null);
    const [showing2, setShowing2] = useState(null);
    const [focused, setFocused] = useState("");

    const ReacthandleClick = (event) => {
        setShowing2(true);
    };
    const handleClickAway = (e) => {
        setShowing(false);
        setShowing2(false);
    };
    console.log('====================================');
    console.log(props?.currentId, 'props?.currentId');
    console.log('====================================');
    const handleEmailSubject = (e) => {
        const newValue = e.target.value;
        setSubject(newValue);
        // Update the notification state
        setNotificationVal((prevVal) => ({
            ...prevVal,
            Sub: newValue,
        }));

        // Update the view array state
        // setViewArray((prevVal) => ({
        //     ...prevVal,
        //     Sub: newValue,
        // }));
        props?.setSelectedId((prevVal) => ({
            ...prevVal,
            email_json: {
                ...prevVal?.email_json,
                Sub: e.target.value,
            },
        }));
        // Update the category array state
        setCategoryArr((prevCheck) => {
            return prevCheck.map((ele) => {
                // Check and update the parent object
                if (props?.statustype == 1 && ele?.outside_id === props?.currentId) {
                    return {
                        ...ele,
                        email_json: {
                            ...ele.email_json,
                            Sub: newValue,
                        },
                    };
                }

                // Check and update the child exceptions
                console.log(ele?.child_exception, 'ele?.child_exception');
                if (ele?.child_exception) {
                    console.log('====================================');
                    console.log('hjhkjhk');
                    console.log('====================================');
                    const updatedChildExceptions = ele?.child_exception?.map((child) => {
                        console.log('====================================');
                        console.log(props?.statustype == 2, child?.inside_id, props?.currentId, 'wkkeoooi');
                        console.log('====================================');
                        if (props?.statustype == 2 && child?.inside_id === props?.currentId) {
                            return {
                                ...child,
                                email_json: {
                                    ...child.email_json,
                                    Sub: newValue,
                                },
                            };
                        }
                        return child;
                    });

                    return {
                        ...ele,
                        child_exception: updatedChildExceptions,
                    };
                }

                return ele;
            });
        });
    };

    const handleMessageClick = (name) => {
        const percent = "%" + name + "%";
        const replacemessage = emailMessage?.replace(null, "");
        const NewMessage = (replacemessage == undefined ? "" : replacemessage) + percent;
        setEmailmessage(NewMessage);
        // ReactQuillRef?.current?.editor?.setData(NewMessage);
    };

    const ReactFocus = () => {
        setFocused("React");
    };
    // Example of a drop event handler

    const handleEmailTo = (newVal, id) => {
        setEmailTo(newVal);
        setNotificationVal((prevVal) => ({
            ...prevVal,
            to: newVal,
        }));
        props?.setSelectedId((prevVal) => ({
            ...prevVal,
            email_json: {
                ...prevVal?.email_json,
                to: newVal,
            },
        }));
        setCategoryArr((prevCheck) => {
            return prevCheck.map((ele) => {
                // Check and update the parent object
                if (props?.statustype == 1 && ele?.outside_id === props?.currentId) {
                    return {
                        ...ele,
                        email_json: {
                            ...ele.email_json,
                            to: newVal,
                        },
                    };
                }

                // Check and update the child exceptions
                if (ele?.child_exception) {
                    const updatedChildExceptions = ele?.child_exception?.map((child) => {
                        if (props?.statustype == 2 && child?.inside_id === props?.currentId) {
                            return {
                                ...child,
                                email_json: {
                                    ...child.email_json,
                                    to: newVal,
                                },
                            };
                        }
                        return child;
                    });

                    return {
                        ...ele,
                        child_exception: updatedChildExceptions,
                    };
                }

                return ele;
            });
        });
    };
    const handleEmailCC = (newVal) => {
        setEmailCC(newVal);
        setNotificationVal((prevVal) => ({
            ...prevVal,
            cc: newVal,
        }));
        props?.setSelectedId((prevVal) => ({
            ...prevVal,
            email_json: {
                ...prevVal?.email_json,
                cc: newVal,
            },
        }));
        setCategoryArr((prevCheck) => {
            return prevCheck.map((ele) => {
                // Check and update the parent object
                if (props?.statustype == 1 && ele?.outside_id === props?.currentId) {
                    return {
                        ...ele,
                        email_json: {
                            ...ele.email_json,
                            cc: newVal,
                        },
                    };
                }

                // Check and update the child exceptions
                if (ele?.child_exception) {
                    const updatedChildExceptions = ele?.child_exception?.map((child) => {
                        if (props?.statustype == 2 && child?.inside_id === props?.currentId) {
                            return {
                                ...child,
                                email_json: {
                                    ...child.email_json,
                                    cc: newVal,
                                },
                            };
                        }
                        return child;
                    });

                    return {
                        ...ele,
                        child_exception: updatedChildExceptions,
                    };
                }

                return ele;
            });
        });
    };
    const handleEmailBCC = (newVal) => {
        setEmailbcc(newVal);
        setNotificationVal((prevVal) => ({
            ...prevVal,
            bcc: newVal,
        }));
        props?.setSelectedId((prevVal) => ({
            ...prevVal,
            email_json: {
                ...prevVal?.email_json,
                bcc: newVal,
            },
        }));
        setCategoryArr((prevCheck) => {
            return prevCheck.map((ele) => {
                // Check and update the parent object
                if (props?.statustype == 1 && ele?.outside_id === props?.currentId) {
                    return {
                        ...ele,
                        email_json: {
                            ...ele.email_json,
                            bcc: newVal,
                        },
                    };
                }

                // Check and update the child exceptions
                if (ele?.child_exception) {
                    const updatedChildExceptions = ele?.child_exception?.map((child) => {
                        if (props?.statustype == 2 && child?.inside_id === props?.currentId) {
                            return {
                                ...child,
                                email_json: {
                                    ...child.email_json,
                                    bcc: newVal,
                                },
                            };
                        }
                        return child;
                    });

                    return {
                        ...ele,
                        child_exception: updatedChildExceptions,
                    };
                }

                return ele;
            });
        });
    };
    const handleOpenBcc = () => {
        setViewEmailBcc(true);
    };
    const handleEmailBody = (e) => {
        setEmailmessage(e);
        // setNotificationVal((prevVal) => ({
        //     ...prevVal,
        //     body: e,
        // }));
        props?.setSelectedId((prevVal) => ({
            ...prevVal,
            email_json: {
                ...prevVal?.email_json,
                body: e,
            },
        }));
        setCategoryArr((prevCheck) => {
            return prevCheck.map((ele) => {
                // Check and update the parent object
                if (props?.statustype == 1 && ele?.outside_id === props?.currentId) {
                    return {
                        ...ele,
                        email_json: {
                            ...ele.email_json,
                            body: e,
                        },
                    };
                }

                // Check and update the child exceptions
                if (ele?.child_exception) {
                    const updatedChildExceptions = ele?.child_exception?.map((child) => {
                        if (props?.statustype == 2 && child?.inside_id === props?.currentId) {
                            return {
                                ...child,
                                email_json: {
                                    ...child.email_json,
                                    body: e,
                                },
                            };
                        }
                        return child;
                    });

                    return {
                        ...ele,
                        child_exception: updatedChildExceptions,
                    };
                }

                return ele;
            });
        });
    };

    const handleBlur = () => {
        const editor = ReactQuillRef.current.getEditor();

        const selection = editor.getSelection();
        const cursorPosition = selection ? selection.index : -1; // -1 if no selection
        // Get the container text
        const containerText = editor.getText(cursorPosition);
        setOffset(cursorPosition);
    };

    const handleEmailDrop = (e) => {
        e.preventDefault();
        const data = "%" + e.dataTransfer.getData("text/plain") + "%";
        // Access the Quill instance using the quillRef
        const quill = ReactQuillRef.current.getEditor();
        const currentContent = quill.getText();
        const selection = quill.getSelection();
        const insertIndex = selection ? selection.index : currentContent.length;
        // Append the dropped data to the existing content
        const updatedContent = currentContent.slice(0, insertIndex) + data + currentContent.slice(insertIndex);
        quill.setText(updatedContent); // Update the content in Quill
        // Set the editorHtml state with the updated content
        setEmailmessage(updatedContent);
        setNotificationVal((prevVal) => ({
            ...prevVal,
            body: updatedContent,
        }));
        props?.setSelectedId((prevVal) => ({
            ...prevVal,
            email_json: {
                ...prevVal?.email_json,
                body: updatedContent,
            },
        }));
        setCategoryArr((prevCheck) => {
            return prevCheck.map((ele) => {
                // Check and update the parent object
                if (props?.statustype == 1 && ele?.outside_id === props?.currentId) {
                    return {
                        ...ele,
                        email_json: {
                            ...ele.email_json,
                            body: updatedContent,
                        },
                    };
                }

                // Check and update the child exceptions
                if (ele?.child_exception) {
                    const updatedChildExceptions = ele?.child_exception?.map((child) => {
                        if (props?.statustype == 2 && child?.inside_id === props?.currentId) {
                            return {
                                ...child,
                                email_json: {
                                    ...child.email_json,
                                    body: updatedContent,
                                },
                            };
                        }
                        return child;
                    });

                    return {
                        ...ele,
                        child_exception: updatedChildExceptions,
                    };
                }

                return ele;
            });
        });
    };
    const [isFilter, setIsFilter] = useState(false);
    const onFilterItems = (value) => {
        setIsFilter(true);
        switch (value) {

            case 1:
                dispatch(
                    ExceptionEmailGeneration({
                        exception: FindSelectName,
                        email_size: "small",
                        task: "0"
                    })
                );
                break;
            case 2:
                dispatch(
                    ExceptionEmailGeneration({
                        exception: FindSelectName,
                        email_size: "large",
                        task: "0"
                    })
                );
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (EmailGenerate?.requestComplete && EmailGenerateData && isFilter) {
            setEmailmessage(EmailGenerateData);
            setNotificationVal((prevVal) => ({
                ...prevVal,
                body: EmailGenerateData,
                Sub: EmailGenearteSub
            }));
            props?.setSelectedId((prevVal) => ({
                ...prevVal,
                email_json: {
                    ...prevVal?.email_json,
                    body: extractTextFromHTML(EmailGenerateData),
                    Sub: EmailGenearteSub
                },
            }));
            setCategoryArr((prevCheck) => {
                return prevCheck.map((ele) => {
                    // Check and update the parent object
                    if (props?.statustype == 1 && ele?.outside_id === props?.currentId) {
                        return {
                            ...ele,
                            email_json: {
                                ...ele.email_json,
                                body: EmailGenerateData,
                                Sub: EmailGenearteSub
                            },
                        };
                    }

                    // Check and update the child exceptions
                    if (ele?.child_exception) {
                        const updatedChildExceptions = ele?.child_exception?.map((child) => {
                            if (props?.statustype == 2 && child?.inside_id === props?.currentId) {
                                return {
                                    ...child,
                                    email_json: {
                                        ...child.email_json,
                                        body: EmailGenerateData,
                                        Sub: EmailGenearteSub
                                    },
                                };
                            }
                            return child;
                        });

                        return {
                            ...ele,
                            child_exception: updatedChildExceptions,
                        };
                    }

                    return ele;
                });
            });
            setTimeout(() => {
                dispatch({ type: EMAIL_GENERATE_EXCEPTION_RESET });
                setIsFilter(false);
            }, 3000);

        }
    }, [EmailGenerate?.requestComplete, isFilter]);
    const FindSelectName = props?.selectedId?.exception_name;
    const ModifyEmail = () => {
        setIsFilter(true);
        dispatch(
            ExceptionEmailGeneration({
                exception: FindSelectName,
                email_size: "small",
            })
        );
    };
    const formattedMessage = emailMessage ? convertNewlinesToHtml(emailMessage) : '';
    useEffect(() => {
        // Create a style element
        const style = document.createElement('style');
        style.innerHTML = `
            .custom-quill-toolbar .ql-toolbar.ql-snow {
                background-color: transparent !important;
            }
        `;
        // Append the style to the head of the document
        document.head.appendChild(style);

        // Clean up the style element when component unmounts
        return () => {
            document.head.removeChild(style);
        };
    }, []);

    return (
        <>

            <>
                <div className={`${!props?.isView ? "cursor-not-allowed  " : ""} px-[20px] mt-[20px] min-w-[49px]`}>
                    <Accordion
                        sx={{
                            "&.MuiPaper-root": {
                                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.06)",
                                border: `1px solid ${currentMode == "dark" ? "#333" : "rgba(223, 225, 226, 0.56)"} !important`,
                            },

                        }}
                        disabled={!props?.isView}
                        expanded={expandEmail === "1"}
                        onChange={handleChangeEmail("1")}
                    >
                        <AccordionSummary
                            sx={{
                                "&.Mui-expanded": {
                                    minHeight: "49px" /* Set your desired min-height here */
                                },
                                "& .MuiAccordionSummary-content.Mui-expanded": {
                                    margin: "0px",
                                }
                            }}
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                            className={`${expandEmail == 0 ? `bg-${currentMode}-card` : `bg-${currentMode}-1420`} text-${currentMode}-copy rounded-[8px]  ${currentMode === "dark" ? "" : "shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)]"
                                } `}
                        >
                            <img src="/images/Exception-Automation/email.svg" className="convertWhite"></img>{" "}
                            <span className="font-interM ml-3">Email Communication</span>

                        </AccordionSummary>
                        <AccordionDetails>
                            {EmailGenerate?.fetching && isFilter &&
                                <div className="h-screen flex bg-[rgb(231,225,225,0.5)] absolute top-0 z-[12] left-0 right-0 justify-center items-center w-full">
                                    {" "}
                                    <img className="w-[7rem]" src="/images/gif/Loading-Gif.gif" alt="Loading-Gif" />
                                </div>
                            }
                            <>
                                <div className={``}>
                                    <div
                                        className={`flex w-full border-b-[1px] items-center border-${currentMode}-700 pt-[22px] justify-between mt-[-10px]`}
                                    >
                                        <span className="mr-3 block">
                                            <span className="text-[red] mr-1">*</span>To:
                                        </span>
                                        <Autocomplete
                                            multiple
                                            // disableCloseOnSelect
                                            freeSolo
                                            sx={{
                                                "&.MuiAutocomplete-root .MuiInputBase-root": {
                                                    border: 0,
                                                    marginLeft: "0",
                                                    // overflow: "hidden",
                                                },
                                                ".MuiInputBase-input": {
                                                    color: `${currentMode === "dark" ? "white" : "black"} !important`,
                                                },
                                                "&.MuiFormHelperText-root .MuiFormHelperText-root": {
                                                    marginLeft: "35px",
                                                },
                                                "& .MuiChip-label": {
                                                    color: `${currentMode === "dark" ? "white" : "black"}`,
                                                },
                                            }}
                                            id="tags-filled"
                                            options={filteredOptions}
                                            value={emailTo === undefined || typeof emailTo === "string" ? [] : emailTo}
                                            inputValue={inputValueTo}
                                            onInputChange={(e, newValue) => {
                                                setInputValueTo(newValue);
                                                if (newValue) {
                                                    setFilteredOptions(UsersOptions?.filter(option => option?.toLowerCase()?.includes(newValue?.toLowerCase())));
                                                } else {
                                                    setFilteredOptions([]);
                                                }
                                            }}
                                            onChange={(e, newVal) => {
                                                const errorEmail = newVal.find((email) => !regex.test(email));
                                                if (errorEmail) {
                                                    setInputValueTo(errorEmail);
                                                    setError(true);
                                                } else {
                                                    setError(false);
                                                }
                                                handleEmailTo(newVal.filter((email) => regex.test(email)));
                                            }}
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props}>
                                                    <Checkbox
                                                        checked={selected}
                                                        size="small"
                                                        sx={{
                                                            color: "#028C78",
                                                            "&.Mui-checked": {
                                                                color: "#028C78",
                                                            },
                                                        }}
                                                        style={{ marginRight: 8 }}
                                                    />
                                                    <ListItemText
                                                        primary={option}
                                                        style={{ color: `${currentMode === "dark" ? "white" : "black"}` }}
                                                    />
                                                </li>
                                            )}
                                            renderTags={(value, getTagProps) =>
                                                value?.map((option, index) => (
                                                    <Chip
                                                        key={index}
                                                        variant="outlined"
                                                        label={option}
                                                        {...getTagProps({ index })}
                                                        onDelete={() => {
                                                            handleEmailTo(emailTo.filter((e) => e !== option));
                                                        }}
                                                        sx={{ color: `${currentMode === "dark" ? "white" : "black"}` }}
                                                    />
                                                ))
                                            }
                                            PaperComponent={(props) => filteredOptions?.length ? <Paper {...props} /> : null}

                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Enter Email Address"
                                                    fullWidth
                                                    sx={{
                                                        "& .MuiInputBase-input": {
                                                            color: `${currentMode === "dark" ? "white" : "black"} !important`,
                                                        },
                                                    }}
                                                />
                                            )}
                                            style={{ width: "100%", border: 0 }}
                                            limitTags={1}
                                        />
                                    </div>
                                    <div
                                        className={`flex w-full border-b-[1px] items-center border-${currentMode}-700 pt-[22px] justify-between mt-[-10px]`}
                                    >
                                        <span className="mr-3 block">
                                            <span className="text-[red] mr-2"></span>Cc:</span>
                                        <Autocomplete
                                            tabIndex={0}
                                            multiple
                                            sx={{
                                                "&.MuiAutocomplete-root .MuiInputBase-root": {
                                                    border: 0,
                                                    marginLeft: "0",
                                                },
                                                ".MuiInputBase-input": {
                                                    color: `${currentMode == "dark" ? "white" : "black"} !important`,
                                                },
                                                "&.MuiFormHelperText-root .MuiFormHelperText-root": {
                                                    marginLeft: "35px",
                                                },
                                                "& .MuiChip-label": {
                                                    color: `${currentMode == "dark" ? "white" : "black"}`,
                                                },
                                            }}
                                            id="tags-filled"
                                            options={filteredOptions}
                                            value={emailCC == undefined || typeof emailCC == "string" ? [] : emailCC}
                                            inputValue={inputValue}
                                            // disableCloseOnSelect
                                            //disableClearable
                                            // autoHighlight={true}
                                            onInputChange={(e, newValue) => {
                                                setInputValue(newValue);
                                                if (newValue) {
                                                    setFilteredOptions(UsersOptions?.filter(option => option?.toLowerCase()?.includes(newValue?.toLowerCase())));
                                                } else {
                                                    setFilteredOptions([]);
                                                }
                                            }}
                                            onChange={(e, newval, reason) => {
                                                const errorEmail = newval.find((email) => !regex.test(email));
                                                if (errorEmail) {
                                                    // set value displayed in the textbox
                                                    setInputValue(errorEmail);
                                                    setError(true);
                                                } else {
                                                    setError(false);
                                                }
                                                handleEmailCC(newval?.filter((email) => regex.test(email)));

                                                //  (newval);
                                            }}
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props}>
                                                    <Checkbox
                                                        checked={selected}
                                                        size="small"
                                                        sx={{
                                                            color: "#028C78",
                                                            "&.Mui-checked": {
                                                                color: "#028C78",
                                                            },
                                                        }}
                                                        style={{ marginRight: 8 }}
                                                    />
                                                    <ListItemText
                                                        primary={option}
                                                        style={{ color: `${currentMode === "dark" ? "white" : "black"}` }}
                                                    />
                                                </li>
                                            )}
                                            disableCloseOnSelect
                                            autoHighlight={true}
                                            freeSolo
                                            PaperComponent={(props) => filteredOptions.length ? <Paper {...props} /> : null}
                                            style={{ width: "100%", border: 0 }}
                                            limitTags={1}
                                            renderTags={(value, getTagProps) =>
                                                value &&
                                                value?.map((option, index) => {
                                                    // let ValueOption = encryptId(option);
                                                    // setEmailCC(ValueOption)
                                                    return (
                                                        <Chip
                                                            key={index}
                                                            variant="outlined"
                                                            label={option}
                                                            {...getTagProps({ index })}
                                                            onDelete={() => {
                                                                handleEmailCC(emailCC?.filter((e) => e !== option));
                                                            }}
                                                        />
                                                    );
                                                })
                                            }

                                            renderInput={(params) => (
                                                <TextField fullWidth {...params} placeholder="Enter Email Address" />
                                            )}
                                        />
                                        {!viewEmailBcc ? (
                                            <Button className="p-0" onClick={handleOpenBcc}>
                                                <span className="block">Bcc</span>
                                            </Button>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    {viewEmailBcc ? (
                                        <div
                                            className={`flex w-full border-b-[1px] items-center border-${currentMode}-700 pt-[22px] justify-between mt-[-10px]`}
                                        >
                                            <span className="mr-3 block">
                                                <span className="text-[red] mr-2"></span>Bcc:</span>
                                            <Autocomplete
                                                multiple
                                                disablePortal
                                                sx={{
                                                    "&.MuiAutocomplete-root .MuiInputBase-root": {
                                                        border: 0,
                                                        marginLeft: "0",
                                                    },
                                                    ".MuiInputBase-input": {
                                                        color: `${currentMode == "dark" ? "white" : "black"} !important`,
                                                    },
                                                    "&.MuiFormHelperText-root .MuiFormHelperText-root": {
                                                        marginLeft: "35px",
                                                    },
                                                    "& .MuiChip-label": {
                                                        color: `${currentMode == "dark" ? "white" : "black"}`,
                                                    },
                                                }}
                                                id="tags-filled"
                                                tabIndex={0}
                                                options={filteredOptions}
                                                value={emailBcc == undefined || typeof emailBcc == "string" ? [] : emailBcc}
                                                inputValue={inputValue2}
                                                PaperComponent={(props) => filteredOptions.length ? <Paper {...props} /> : null}
                                                // disableCloseOnSelect
                                                //disableClearable
                                                // autoHighlight={true}
                                                onInputChange={(e, newValue) => {
                                                    setInputValue2(newValue);
                                                    if (newValue) {
                                                        setFilteredOptions(UsersOptions?.filter(option => option?.toLowerCase()?.includes(newValue?.toLowerCase())));
                                                    } else {
                                                        setFilteredOptions([]);
                                                    }
                                                }}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <Checkbox
                                                            checked={selected}
                                                            size="small"
                                                            sx={{
                                                                color: "#028C78",
                                                                "&.Mui-checked": {
                                                                    color: "#028C78",
                                                                },
                                                            }}
                                                            style={{ marginRight: 8 }}
                                                        />
                                                        <ListItemText
                                                            primary={option}
                                                            style={{ color: `${currentMode === "dark" ? "white" : "black"}` }}
                                                        />
                                                    </li>
                                                )}
                                                onChange={(e, newval, reason) => {
                                                    const errorEmail = newval.find((email) => !regex.test(email));
                                                    if (errorEmail) {
                                                        // set value displayed in the textbox
                                                        setInputValue2(errorEmail);
                                                        setError(true);
                                                    } else {
                                                        setError(false);
                                                    }
                                                    handleEmailBCC(newval?.filter((email) => regex.test(email)));

                                                    //  (newval);
                                                }}
                                                disableCloseOnSelect
                                                freeSolo
                                                style={{ width: "100%", border: 0 }}
                                                limitTags={1}
                                                renderTags={(value, getTagProps) =>
                                                    value &&
                                                    value?.map((option, index) => {
                                                        // let ValueOptions = encryptId(option);
                                                        // bccreceivers.push(ValueOptions);
                                                        return (
                                                            <Chip
                                                                key={index}
                                                                variant="outlined"
                                                                label={option}
                                                                {...getTagProps({
                                                                    index,
                                                                })}
                                                                onDelete={() => {
                                                                    handleEmailBCC(emailBcc?.filter((e) => e !== option));
                                                                }}
                                                            />
                                                        );
                                                    })
                                                }
                                                renderInput={(params) => <TextField fullWidth {...params} placeholder="Enter Email Address" />}
                                            />
                                        </div>
                                    ) : (
                                        ""
                                    )}

                                    <div className="py-[20px]">
                                        <div className="flex justify-between">
                                            <span className="block mb-2">
                                                <span className="text-[red] mr-1">*</span>Subject</span>
                                            <TogglePopup
                                                ref={childCompRef}
                                                menuList={filterMenuList}
                                                selectedItemChange={onFilterItems}
                                            />
                                            <Button onClick={(e) =>
                                                childCompRef.current.handleClick(
                                                    "bottom-end",
                                                    1,
                                                    e
                                                )
                                            }

                                            >
                                                {props?.selectedId?.ismail_auto_generate ? <img src="/images/Exception-Automation/modify-email.svg"></img> : <img src="/images/Exception-Automation/Email-generate.svg"></img>}
                                            </Button>
                                            {/* <Button onClick={ModifyEmail}

                                                        >
                                                            <img src="/images/Exception-Automation/Email-generate.svg"></img>
                                                        </Button>} */}
                                        </div>
                                        <Input
                                            tabIndex={0}
                                            value={subject ? subject : ''}
                                            onChange={(e) => handleEmailSubject(e)}
                                            className={`border-[1px] rounded-[8px] py-2 px-[12px] w-full border-${currentMode}-840 bg-transparent`}
                                            placeholder="Enter your subject"
                                        />
                                    </div>
                                </div>

                                <div className={` allocate-textarea relative `}>
                                    <span className="block">
                                        <span className="text-[red] mr-1">*</span>Message</span>
                                    <div className="ml-[45px]">
                                        <span
                                            onClick={ReacthandleClick}
                                            // aria-describedby={id}
                                            className={`flex absolute right-[5px] top-[1px] block   `}
                                        >
                                            Insert Placeholders{" "}
                                            <img className="ml-2 convertWhite" src="/images/common/chevron-grey.svg" alt="chevronDown" />
                                        </span>
                                        {showing2 && (
                                            <ClickAwayListener
                                                mouseEvent="onMouseDown"
                                                touchEvent="onTouchStart"
                                                onClickAway={(e) => handleClickAway(e)}
                                            >
                                                <div
                                                    className={`bg-${currentMode}-card absolute top-[-410px] right-0 border-${currentMode}-1020 border-[1px] z-[8] mt-[32px]  ${currentMode !== "dark" ? `shadow-[0px_4px_20px_rgba(85,85,85,0.2)]` : ``
                                                        }  rounded-[10px]`}
                                                >
                                                    <div className="grid grid-cols-1 gap-4">
                                                        <div className={`py-[20px]`}>
                                                            <span
                                                                draggable="false"
                                                                className="font-interSb mb-2 block pointer-events-none px-[30px]"
                                                            >
                                                                Invoice
                                                            </span>
                                                            <ul className="cursor-pointer">
                                                                {invoicePlaceHolderList?.length !== 0 ? (
                                                                    invoicePlaceHolderList?.map((el, i) => {
                                                                        return (
                                                                            <li
                                                                                onDragStart={(event) => {
                                                                                    event.dataTransfer.setData(
                                                                                        "text/plain",
                                                                                        el.placeholder_display_name
                                                                                    );
                                                                                }}
                                                                                onDragEnd={(event) => {
                                                                                    event.dataTransfer.clearData();
                                                                                }}
                                                                                className={`hover:bg-${currentMode}-960 px-[30px] userDrag py-2 w-full`}
                                                                                key={i}
                                                                                draggable="true"
                                                                                onClick={() =>
                                                                                    handleMessageClick(el.placeholder_display_name)
                                                                                }
                                                                            >
                                                                                {el.placeholder_display_name}
                                                                            </li>
                                                                        );
                                                                    })
                                                                ) : (
                                                                    <div className="px-[20px]">No data found</div>
                                                                )}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ClickAwayListener>
                                        )}
                                    </div>

                                    <div onDrop={handleEmailDrop}>
                                        <ReactQuill
                                            tabIndex={0}
                                            onDragOver={(event) => event.preventDefault()}
                                            theme="snow"
                                            onFocus={ReactFocus}
                                            // readOnly={!Emailenable}
                                            ref={ReactQuillRef}
                                            className="custom-quill-toolbar" // Apply the custom class here
                                            style={{ borderRadius: "8px", marginTop: "18px" }}
                                            value={formattedMessage}
                                            formats={formats}
                                            onBlur={(event) => {
                                                handleBlur();
                                            }}
                                            modules={modules}
                                            // disabled={!Emailenable}
                                            onChange={(e) => handleEmailBody(e)}
                                            onSelectionChange={(selection) => {
                                                const cursorPosition = selection ? selection.index : -1;
                                            }}
                                        />
                                    </div>
                                </div>
                            </>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </>

        </>
    );
};

export default EmailCategory;
