import Button from "../../components/Button";
import Modal from "../../components/UI/Modal/Modal";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import MessageBox from "../../components/UI/Alert";
import { AccountDeleteApi } from "../../redux/actions/auth";
import { useState } from "react";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { useContext } from "react";

const AccountDelete = (props) => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const { requestComplete, fetching, error, data } = useSelector((state) => state?.AccounDeleteState);
    const errormsgs = "Something went wrong please contact admin";
    const [errormsg, seterrormsg] = useState({ message: "" });
    const dispatch = useDispatch();
    const navigate = useNavigate;
    const [feedback, setfeedBack] = useState("");
    if (props?.isOpened) {
        var textarea = document?.getElementById("feedbackId");
        textarea?.focus();
    }
    function submit() {
        const reqObject = {
            remarks: feedback,
        };
        if (feedback == "") {
            seterrormsg({
                message: "Please write any Feedback",
            });
        } else if (/^\s*$/.test(feedback)) {
            seterrormsg({
                message: "Please write any Feedback",
            });
        } else {
            seterrormsg({
                message: "",
            });

            Swal.fire({
                text: "Are you sure you want to delete your account?",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                showCancelButton: true,
                buttons: {
                    cancel: "Cancel",
                    confirm: "Confirm",
                },
            }).then((confirm) => {
                if (confirm?.isConfirmed) {
                    dispatch(AccountDeleteApi(reqObject));
                }
            });
        }
    }
    const cartClose = () => {
        props?.handleClose();
        setfeedBack("");
        seterrormsg({
            message: "",
        });
    };
    if (requestComplete && data?.message == "Success") {
        Swal.fire({
            text: "Account deletion has started and will take 30 minutes to complete.",
            icon: "success",
            closeOnClickOutside: false,
            showCloseButton: true,
            button: "Ok",
        }).then((confirm) => {
            if (confirm) {
                window.localStorage.clear();
                window.location.reload();
            }
        });
    }

    return (
        <>
            {/* {requestComplete && !data?.status && <MessageBox error={errormsg} />} */}
            {error && !data?.status && <MessageBox error={errormsgs} />}
            <Modal cartClose={cartClose} center="center" className={`${props?.className}`}>
                <div
                    className={`max-w-[600px] mx-auto relative  px-[30px]  py-[30px] rounded-[10px] ${
                        currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                    } bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700`}>
                    <Button onClick={cartClose} tabIndex="4" className="cursor-pointer absolute right-[20px] top-[20px] convertWhite">
                        <img src="/images/common/closeBlk.svg" alt="closeBlk" />
                    </Button>
                    <div className="flex space-x-3">
                        <img className="bg-message  w-[24px] h-[21px] mb-8" src="/images/swal/caution-icon.svg" alt="alert" />
                        <span className="font-interSb block text-base mb-4">Are you sure you want to delete your account?</span>
                    </div>

                    <p className="mb-4">This action will delete your entire data permanently and your account will be locked and logged out.</p>
                    <p className="mb-4">
                        Ensure that you take data back up before deleting your account. Once you delete, your data is not recoverable.
                    </p>
                    <p className="mb-4 font-interSb ">We would love to hear your feedback:</p>
                    <textarea
                        id="feedbackId"
                        className={`border-[1px] focus-within:outline-0 px-4 w-[444px] h-[80px] py-[4px] bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700 rounded-[8px] ${
                            currentMode !== "dark" ? "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]" : ""
                        } resize-none`}
                        name="feedback"
                        placeholder="Enter your feedback here"
                        maxLength={250}
                        value={feedback}
                        onChange={(e) => setfeedBack(e.target.value)}
                    />
                    {errormsg?.message && <p className="text-light-50 text-xs">{errormsg?.message}</p>}
                    <div className="flex mt-7 space-x-2">
                        <Button
                            className="bg-light-20 px-[15px] py-[10px] text-base text-white rounded-[8px]"
                            type="submit"
                            tabIndex="3"
                            onClick={submit}
                            disabled={fetching}>
                            Confirm
                        </Button>
                        <Button className="bg-light-240 text-light-40 text-base px-[15px] py-[10px] rounded-[8px]" onClick={cartClose} tabIndex="3">
                            Cancel
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default AccountDelete;
