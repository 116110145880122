import { useState, useEffect, useContext, useRef, useMemo } from "react";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import Layout from "../../Hoc/Layout";
import XLSX from "sheetjs-style";
import Footer from "../../organisms/Footer";
import { ErrorBoundary } from "react-error-boundary";
import FournotFour from "../404";
import Main from "../../components/UI/Main";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Card from "../../components/UI/Card";
import { FormControl, InputAdornment, ListSubheader, MenuItem, Popper, Select } from "@mui/material";
import { getCostCentreList, getGeneralLedgerList, getPoNumberList, getTaxCodeList, invoiceVendorList } from "../../redux/actions/invoice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import ReactDOM from "react-dom";
import MiscPayment from "./MiscPayment";
import Input from "../../components/Input";
import TogglePopup from "../organisations/Toggle";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import Swal from "sweetalert2";
import SearchIcon from "@mui/icons-material/Search";
import ExportExcel from "../ExportFiles/Excel";
import POHeaderLineItemPopUp from "../AdavnceRequest/MobilizationRequest/POHeaderLineItemPopUp";
import StatusButton from "../../organisms/AdvanceModals/advanceStatusCard";
import AdvanceFileUpload from "../../organisms/AdvanceModals/fileUpload";
import CommentCard from "../../organisms/CommentsCard";
import {
    AddUpdateMiscApi,
    GetMiscVendorListAction,
    GetPoMiscAction,
    GetWbsListAction,
    TdsApiAction,
    ViewMiscApi,
} from "../../redux/actions/miscellaneous-request";
import { GetAdvanceDetailsforCreate, GetGLIndicatorList } from "../../redux/actions/advance-request";
import { formatNumber } from "../../utils/helpers";
import { ADD_UPDATE_COMMENTS_RESET, ADD_UPDATE_MISC_RESET, GET_MISC_PO_LIST_RESET } from "../../redux/types/types";
import { CheckBox } from "@mui/icons-material";
import { LedgerModal } from "../Invoice/View/LedgerModal";
import { GlTableItems } from "./MiscellaneousPaymentTable/glTable";
import { GlPOTableItems } from "./MiscellaneousPaymentTable/glPOTable";
import SuccessMessageBox from "../../components/UI/Alert/sucess";
import MessageBox from "../../components/UI/Alert";
import { type } from "@testing-library/user-event/dist/type";
import { async } from "@firebase/util";
import Activity from "../../organisms/ActivityModal";
import { ActivityHistory, getCurrentUser } from "../../redux/actions/auth";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import ToReassign from "../Invoice/Reassign";
import { postChangeWorkflowStatus } from "../../redux/actions/workflow-automation";
import SelectUserModal from "../Invoice/sidebar/SelectUserModel";

const ErrorFallback = ({ error }) => {
    return (
        <>
            <FournotFour />
        </>
    );
};

const MiscellaneousPaymentRequest = () => {
    let PayableAmount;

    let validateTotalAmount = "";
    let findCreateNewRequest = false;
    let totalDebitAmount = 0;

    const [totalGl, istotalGl] = useState(0);
    const numFormat = localStorage.getItem("userNumberFormat");
    const AccessIcon = () => <img src="/images/invoice/calendar.svg" tabIndex={0} alt="calendar" className="convertWhite" />;
    const childCompRef = useRef();
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const orgId = localStorage.getItem("orgId");
    const params = new URLSearchParams(window.location.search);
    const reqId = params.get("reqId") || null;
    const { data: poNumberList, fetching, requestComplete } = useSelector((state) => state?.getPoNumberListState);
    const ViewMisc = useSelector((state) => state?.ViewMiscState);
    const MiscView = ViewMisc?.data?.data?.[0];

    const AddMisc = useSelector((state) => state?.AddupdateMiscState);
    const StatusAdd = AddMisc?.dataList;
    const [fileContent, setFileContent] = useState("");
    const [fileUrl, setFileUrl] = useState("");
    const [checkClick, setCheckClick] = useState(false);
    const [popperOpen, setpopperOpen] = useState(false);
    const [tablePopperOpen, setTablePopperOpen] = useState(false);
    const [filteredPoNoList, setFilteredPoNoList] = useState([]);
    const [searchShow, setSearchShow] = useState(false);
    const [insertPoVal, setInsertPoVal] = useState("");
    const [insertVendorVal, setInsertVendorVal] = useState("");
    const [anchorEls, setAnchorEls] = useState(null);
    const [TableanchorEls, setTableAnchorEls] = useState(null);
    const [checkChange, setcheckChange] = useState(false);
    const [checkTableChange, setcheckTableChange] = useState(false);
    const [openLineItem, setOpenLineItem] = useState(false);
    const [index, setIndex] = useState(null);
    const [Autofill, steAutofill] = useState(1);
    const [checkIndex, setCheckIndex] = useState(0);
    const [costArr, setCostArr] = useState([]);
    const [costCenterList, setCostCenterList] = useState("");
    const [oldData, setOldData] = useState("");
    const [AddItems, setAddItems] = useState([]);
    const [addItem, setAddItem] = useState(false);
    const [openLedger, setOpenLedger] = useState(false);
    const [checkRowIndex, setCheckRowIndex] = useState(0);
    const [type, setType] = useState(1);
    const [totalLedgerVal, setTotalLedgerVal] = useState(0);
    const [costListData, setCostListData] = useState([]);
    const [selectUserAnchor, setSelectUserAnchor] = useState(false);
    const [userEmail, setUserEmail] = useState([]);

    const { data: listData } = useSelector((state) => state?.costCentreListState);
    const DisplayRole = useSelector((state) => state?.DisplayRolePermissionStateAction)?.data?.role_info?.menu?.find((item) => item?.menu_id == 22);
    const DisplayRoles = useSelector((state) => state?.DisplayRolePermissionStateAction);
    const { data: DataList } = DisplayRoles;
    const ViewPO = useSelector((state) => state?.getMiscPoListState);
    const RoleList = DataList?.role_info;
    const RoleMenu = RoleList?.menu;
    const RoleMisc = RoleMenu?.find((elem) => elem?.menu_id === 22);
    const RoleActMiscEdit = RoleMisc?.actions?.filter((el) => el?.id == 2)?.[0]?.isallowed;
    const nonPOItemList = costArr;

    const OrgDateFormat = localStorage.getItem("OrgDateFormat");

    const userDateFormat = localStorage?.getItem("userDateFormat")?.toUpperCase();
    const { data: dataList, requestComplete: GlIndReqComp } = useSelector((state) => state?.getGLIndicatorListState);
    const glIndicData = dataList?.[0]?.special_gl_indicator;
    const DefaultGl = glIndicData?.length == 1 ? glIndicData?.[0]?.value : "0";
    const {
        data: commentData,
        fetching: addCmtFetch,
        requestComplete: addCmtReqComp,
        error: addCmtErr,
    } = useSelector((state) => state?.addUpdateCommentsState);
    const { idDate, setIdDate } = useState(new Date());
    const [generalLed, setGeneralLed] = useState([
        {
            gl_account_code: "",
            ledger_type: 0,
            cost_center: "",
            amount: "",
        },
    ]);

    let nonPoCostListData = costCenterList?.cost_center;
    const findScreeSize = window.innerWidth;

    const [rows, setRows] = useState([
        {
            po_no: "",
            id: 0,
            header_id: reqId ? reqId : 0,
            vendor_name: "",
            POValue: "",
            POBalanceAmt: "",
            debit_amount: null,
            gl_info: [
                {
                    gl_account_code: "",
                    ledger_type: 0,
                    cost_center: "",
                    amount: "",
                },
            ],
        },
    ]);
    const [newrow, setNewRow] = useState([
        {
            gl_account_code: "",
            ledger_type: 0,
            cost_center: "",
            amount: "",
        },
    ]);
    const [glRow, setGlRow] = useState([
        {
            gl_account_code: "",
            ledger_type: 0,
            cost_center: "",
            amount: "",
        },
    ]);

    const tableListData = AddItems;

    const disablePo = poNumberList == undefined || poNumberList?.length == 0 ? true : false;

    const data = useSelector((state) => state?.generalLedgerListState);
    const WbsData = useSelector((state) => state?.getMiscWbsListState);
    const Wbsarr = WbsData?.data;
    const FindWbsId = Wbsarr?.filter((el) => el?.id == MiscView?.wbs_id)?.[0];
    const { data: taxListData } = useSelector((state) => state?.taxCodeWithPercentageListState);
    const { data: TdsData, requestComplete: Tdsreq, fetching: tdsfetching } = useSelector((state) => state?.TdsActionState);
    const [DtaxListData, setTaxListData] = useState([]);
    const FindTaxAddCode = taxListData?.filter((el) => el?.tax_id == MiscView?.tax_add_id)?.[0];
    const FindTaxDedCode = DtaxListData?.filter((el) => el?.tax_id == +MiscView?.tax_id)?.[0];
    const RoleActAdvActivity = RoleMisc?.actions?.filter((el) => el?.id == 13)?.[0]?.isallowed;
    const nextUserList = MiscView?.wfuser_list;
    const [Inputstate, setInputState] = useState({
        po_no: "",
        requestDate: new Date(),
        MiscAmount: "",
        TaxAmount: "",
        TaxAddId: 0,
        TaxAddPerc: 0,
        ExchangeRate: "",
        ReferenceNumber: "",
        ReferenceDate: null,
        Currency: 0,
        TaxCode: "",
        vendor_name: "Select",
        Remarks: "",
        WbsElem: reqId ? FindWbsId?.wbs_element : "",
        Plant: reqId ? FindWbsId?.plant : "",
        Project: reqId ? FindWbsId?.project : "",
        TaxAddCode: "",
        WbsId: 0,
        GLInd: glIndicData?.length == 1 ? glIndicData?.[0]?.value : "0",
        TaxDAmount: "",
        TaxDedCode: "",
        TaxDId: 0,
        TaxDPerc: 0,
    });
    useEffect(() => {
        setInputState({
            ...Inputstate,
            GLInd: glIndicData?.length == 1 ? glIndicData?.[0]?.value : "0",
        });
        if (commentData && addCmtReqComp) {
            setTimeout(() => {
                dispatch({ type: ADD_UPDATE_COMMENTS_RESET });
            }, 2000);
        }
    }, [glIndicData, addCmtReqComp]);
    const [errorMsg, seterrorMsg] = useState({
        Vendor: "",
        ReqDate: "",
        MiscAmount: "",
        WbsElem: "",
        Plant: "",
        Project: "",
        TaxAddCode: "",
        TaxAddAmount: "",
        Glmsg: "",
        ReferenceDate: "",
    });
    const navigate = useNavigate();
    if (AddMisc?.requestComplete) {
        setTimeout(() => {
            dispatch({ type: ADD_UPDATE_MISC_RESET });
        }, 2000);
    }
    if (AddMisc?.requestComplete && AddMisc?.dataList?.status) {
        Swal.fire({
            text: `${reqId ? "Updated successfully!" : "Created successfully!"}`,
            icon: "success",
            allowOutsideClick: false,
            showCloseButton: true,
            buttons: {
                confirm: "Confirm",
            },
        }).then((confirm) => {
            if (confirm) {
                navigate("/miscellaneous-table");
            }
        });
    }

    useEffect(() => {
        const refData = {
            po_no: "",
            vendor_name: "Select",
            POValue: "",
            POBalanceAmt: "",
            debit_amount: null,
            gl_info: [
                {
                    gl_account_code: "",
                    ledger_type: 0,
                    cost_center: "",
                    amount: "",
                },
            ],
        };
        if (reqId) {
            setInputState({
                po_no: "",
                requestDate: MiscView?.request_date,
                MiscAmount: MiscView?.misc_amount,
                TaxAmount: MiscView?.tax_add_amount,
                ReferenceNumber: MiscView?.reference_no,
                ReferenceDate: MiscView?.reference_date,
                TaxCode: FindTaxAddCode?.tax_code,
                vendor_name: +MiscView?.vendor_id,
                Remarks: MiscView?.remarks,
                WbsElem: FindWbsId?.wbs_element,
                Plant: FindWbsId?.plant,
                Project: FindWbsId?.project,
                TaxAddCode: MiscView?.tax_add_code,
                WbsId: MiscView?.wbs_id,
                GLInd: MiscView?.gl_indicator,
                TaxDAmount: MiscView?.tax_amount,
                TaxDedCode: FindTaxDedCode?.tax_code,
                TaxAddId: MiscView?.tax_add_id,
                TaxAddPerc: MiscView?.tax_add_per,
                TaxDPerc: MiscView?.tax_percent,
                TaxDId: MiscView?.tax_id,
            });
            setGlRow(MiscView?.gl_info);
            if (MiscView?.debit_info != null) {
                MiscView?.debit_info?.map((item, index) => {
                    if (item?.items_info?.length > 0) {
                        const value = item?.items_info?.reduce((overall, ele) => {
                            return (ele?.net_amount || 0) + overall;
                        }, 0);
                        MiscView.debit_info[index].max_value = value?.toFixed(2);
                    }
                });
                setRows(MiscView?.debit_info);
            }
            setUploadFile(MiscView?.file_name);
            setFileSize(MiscView?.file_size);
            setFileUrl(MiscView?.file_url);
        }
    }, [FindWbsId, MiscView, FindTaxDedCode, FindTaxAddCode]);
    const dispatch = useDispatch();
    const vebdorlist = useSelector((state) => state?.getMiscVendorListState);
    const supplier = vebdorlist?.data?.map((item) => item?.vendor_name);
    const SupplierList = vebdorlist?.data;
    const FindVedorName = SupplierList?.filter((el) => el?.id == Inputstate?.vendor_name)?.[0]?.vendor_name;
    const MiscAmount = Number(Inputstate?.MiscAmount?.replaceAll(",", ""));
    const TaxAmount = Number(Inputstate?.TaxAmount?.replaceAll(",", ""));
    const TaxDedAmount = Number(Inputstate?.TaxDAmount?.replaceAll(",", ""));

    PayableAmount = MiscAmount + TaxAmount - (!TaxDedAmount ? "" : TaxDedAmount);

    const location = useLocation();
    useEffect(() => {
        if (orgId) {
            dispatch(GetMiscVendorListAction(orgId));
            dispatch(getPoNumberList(orgId));
            dispatch(GetWbsListAction(orgId));
            dispatch(getTaxCodeList(orgId));
            dispatch(GetGLIndicatorList(5));
        }
        if (reqId) {
            dispatch(ViewMiscApi(reqId, orgId));
        }
        const reqBody = {
            org_id: orgId,
            vendor_name: FindVedorName,
            type: 1,
            ref_date: Inputstate?.ReferenceDate == null ? "" : moment(new Date(Inputstate?.ReferenceDate)).format("YYYY-MM-DD"),
            // ref_date: "2024-04-17"
        };
        if (Inputstate?.vendor_name !== "Select" && Inputstate?.vendor_name) {
            dispatch(TdsApiAction(reqBody));
        }
    }, [orgId, Inputstate?.vendor_name, !reqId ? Inputstate?.ReferenceDate : ""]);

    useEffect(() => {
        if (filteredPoNoList?.length == 0 || checkClick == "click") {
            setpopperOpen(false);
            setTablePopperOpen(false);
        }
        if (checkChange && filteredPoNoList?.length !== 0 && checkClick !== "click") {
            setpopperOpen(true);
        }
        if (checkTableChange && filteredPoNoList?.length !== 0 && checkClick !== "click") {
            setTablePopperOpen(true);
        }
    }, [filteredPoNoList, checkClick, checkTableChange]);
    //Total Debit amount calculation

    if (numFormat == "3") {
        const totalAmount = rows?.map((el) => parseFloat(el?.debit_amount?.replaceAll(" ", "")));
        for (let i = 0; i < totalAmount?.length; i++) {
            totalDebitAmount += totalAmount[i] || 0;
        }
    } else if (numFormat == "1") {
        const totalAmount = rows?.map((el) => parseFloat(el?.debit_amount?.replaceAll(",", "")));
        for (let i = 0; i < totalAmount?.length; i++) {
            totalDebitAmount += totalAmount[i] || 0;
        }
    } else if (numFormat == "2") {
        const totalAmount = rows?.map((el) => parseFloat(el?.debit_amount?.replaceAll(".", "")?.replaceAll(",", ".")));
        for (let i = 0; i < totalAmount?.length; i++) {
            totalDebitAmount += totalAmount[i] || 0;
        }
    }
    useEffect(() => {
        if (insertPoVal !== "" || poNumberList) {
            const filteredFav = poNumberList?.filter((fav) => {
                return fav.PoNumber.includes(insertPoVal);
            });
            setFilteredPoNoList(filteredFav);
        }
    }, [poNumberList, insertPoVal]);
    const _handleSelectUser = (index, value, reason, type) => {
        if (reason == "selectOption") {
            setUserEmail([value?.[value?.length - 1]]);
        } else setUserEmail([]);
    };
    const handleChange = (e) => {
        setInsertPoVal(e.target.value);
        setcheckChange(true);
        setAnchorEls(e.currentTarget);
    };
    const TableOnChange = (e, index, name) => {};
    const handleOpenLedger = (index) => {
        setCheckRowIndex(index);
        setOpenLedger(true);
        if (data?.data?.length == 0) {
            dispatch(getGeneralLedgerList(orgId));
            dispatch(getCostCentreList(orgId));
        }
    };
    const handleRow = (e, index, field) => {
        const { value } = e.target;
        const updatedRows = [...rows];
        updatedRows[index][field] = value;
        setRows(updatedRows);
    };
    const handleAddRow = () => {
        setRows([
            ...rows,
            {
                po_no: "",
                id: 0,
                header_id: reqId ? reqId : 0,
                vendor_name: "",
                POValue: "",
                POBalanceAmt: "",
                debit_amount: null,
                gl_info: [
                    {
                        gl_account_code: "",
                        ledger_type: 0,
                        cost_center: "",
                        amount: "",
                    },
                ],
            },
        ]);
    };
    // const filterMenuList = [
    //     { text: "Download", src: "/images/common/download-grn.svg", value: 0 },
    //     { text: "Reset", src: "/images/mobilization/rotate-resetsvg.svg", value: 1 },
    //     { text: "Activity", src: "/images/mobilization/activity_icon.svg", value: 2 },
    // ];

    const [costCenter, setCostCenter] = useState();
    const getTotal = tableListData?.map((el) => el);
    const totaList = getTotal?.map((el) => el);
    const totalListData = totaList?.map((el) => el?.total);
    const findTotalValObj = totalListData?.find((el) => el?.item_no === costCenterList?.line_no);
    const downloadeExcel = () => {
        const List = [Inputstate];
        const renamedKeysThirdJSON = glRow?.map((obj) => ({
            "General Ledger Code": obj["gl_account_code"],
            "Ledger Type": obj["ledger_type"] == 0 ? "All" : obj?.ledger_type == 1 ? "Cost Center" : "Internal Order",
            "Cost Center/Internal Order": obj["cost_center"],
            Amount: obj["amount"],
        }));
        const renamedKeysJSON = List?.map((obj) => ({
            Vendor: FindVedorName,
            "Request Date": obj?.requestDate,
            "Misc.Amount": obj["MiscAmount"],
            "Tax Code": obj["TaxCode"],
            "Tax Amount": obj["TaxAmount"],
            "Reference Number": obj["ReferenceNumber"],
            "Reference Date": obj?.ReferenceDate,
            Wbs: obj?.WbsElem,
            Plant: obj?.Plant,
            Project: obj?.Project,
            "Tax Deduction Code": obj?.TaxDedCode,
            "Tax Deduction Amount": obj?.TaxDAmount,
            "G/L Indicator:": obj?.GLInd,
        }));
        const renamedKeysSecondJSON = rows?.map((obj) => ({
            "PO Number": obj["po_no"],
            Vendor: obj["vendor_name"],
            "PO Value": obj["POValue"],
            "PO Balance Amt.": obj["POBalanceAmt"],
            "Debit Amt.": obj["debit_amount"],
            "General Ledger (GL)": obj["gl_info"]
                ?.map(
                    (item) =>
                        `GL Code: ${item.gl_account_code}, Ledger Type: ${
                            item.ledger_type == 0 ? "All" : item.ledger_type == 1 ? "Cost Center" : "Internal Order"
                        }, Cost Center: ${item.cost_center}, Amount: ${item.amount}`
                )
                .join(" | "),
        }));
        const wb = XLSX.utils.book_new();

        // Create sheet for combined JSON objects
        const wsTitle = "Miscellaneous";
        const ws = XLSX.utils.json_to_sheet(renamedKeysJSON);

        // Add two empty rows
        XLSX.utils.sheet_add_json(ws, [{}, {}], { skipHeader: true, origin: -1 });

        // Add title row above the third JSON data
        const title1Row = [{ A: "General Ledger (GL):" }];
        XLSX.utils.sheet_add_json(ws, title1Row, { skipHeader: true, origin: -1 });

        // Merge cells for the title row
        const merge1 = [
            {
                s: { r: ws["!ref"].split(":")[1].match(/\d+/)[0], c: 0 },
                e: { r: ws["!ref"].split(":")[1].match(/\d+/)[0], c: Object.keys(renamedKeysThirdJSON[0]).length - 1 },
            },
        ];
        ws["!merges"] = (ws["!merges"] || []).concat(merge1);

        // Add headers for third JSON
        XLSX.utils.sheet_add_json(ws, [Object.keys(renamedKeysThirdJSON[0])], { skipHeader: true, origin: -1 });

        // Add third JSON data
        XLSX.utils.sheet_add_json(ws, renamedKeysThirdJSON, { skipHeader: true, origin: -1 });

        // Add two empty rows
        XLSX.utils.sheet_add_json(ws, [{}, {}], { skipHeader: true, origin: -1 });

        // Add title row above the second JSON data
        const titleRow = [{ A: "PO Debit:" }];
        XLSX.utils.sheet_add_json(ws, titleRow, { skipHeader: true, origin: -1 });

        // Merge cells for the title row
        const merge = [
            {
                s: { r: ws["!ref"].split(":")[1].match(/\d+/)[0], c: 0 },
                e: { r: ws["!ref"].split(":")[1].match(/\d+/)[0], c: Object.keys(renamedKeysSecondJSON[0]).length - 1 },
            },
        ];
        ws["!merges"] = (ws["!merges"] || []).concat(merge);

        // Add headers for second JSON
        XLSX.utils.sheet_add_json(ws, [Object.keys(renamedKeysSecondJSON[0])], { skipHeader: true, origin: -1 });

        // Add second JSON data
        XLSX.utils.sheet_add_json(ws, renamedKeysSecondJSON, { skipHeader: true, origin: -1 });

        XLSX.utils.book_append_sheet(wb, ws, wsTitle);

        // Generate Excel file
        const fileName = "Miscellaneous-request.xlsx";
        XLSX.writeFile(wb, fileName);
    };

    const [isreassign, setReassign] = useState(false);
    const [isUserActivity, setIsUserActivity] = useState(false);
    const handleOpenActivity = () => {
        setIsUserActivity(true);
        const userReqObj = {
            transaction_id: 21,
            transaction_key: reqId,
            action: 0,
        };
        dispatch(ActivityHistory(userReqObj));
    };
    const onFilterItems = (value, id) => {
        switch (value) {
            case 0:
                downloadeExcel(Inputstate, rows);
                break;
            case 1:
                window.location.reload();
                break;
            case 2:
                handleOpenActivity();
                break;
            case 3:
                setReassign(true);
                break;
            default:
                break;
        }
    };
    const [FilterArray, setFilterArray] = useState(rows);
    const handleDeleteClick = (index) => {
        Swal.fire({
            text: "Are you sure you want to delete this data?",
            icon: "warning",
            showCloseButton: true,
            showCancelButton: true,
            buttons: {
                cancel: "Cancel",
                confirm: "Confirm",
            },
        }).then((confirm) => {
            if (confirm?.isConfirmed) {
                // handleSuccess();

                rows.splice(index, 1);

                setRows([...rows]);

                rows?.forEach((element, inde) => {
                    if (index == inde) {
                        const data = {
                            po_no: element.po_no,

                            vendor_name: element.vendor_name,

                            POValue: element.POValue,

                            POBalanceAmt: element.POBalanceAmt,

                            items_info: element.items_info,

                            type: 3,
                        };
                        FilterArray?.push(data);
                    }
                });
            }
        });
    };

    const handleSuccess = () => {
        Swal.fire({
            text: "Deleted successfully!",
            icon: "success",
            closeOnClickOutside: false,
            showCloseButton: true,
            button: "Ok",
        }).then((confirm) => {
            if (confirm) {
            }
        });
    };

    useEffect(() => {
        if (TdsData?.length !== 0) {
            const updatedListData = TdsData?.map((el) => {
                if (formatNumber(el?.threshold_limit) > 0) {
                    const calcPer = 100 - formatNumber(el?.exemption_percentage);
                    const goog = (calcPer * formatNumber(el?.tax_percentage)) / 100;
                    return { ...el, actual_tax_percentage: goog };
                }
                return el;
            });
            setTaxListData(updatedListData);
        }
    }, [TdsData]);

    const InputhandleChange = (name, value, item, Vendor) => {
        let ref_val = formatNumber(value || "0");
        const percentage = parseFloat(item?.tax_percentage);
        const actualPercentage = Number(item?.threshold_limit) > 0 ? parseFloat(item?.actual_tax_percentage) : percentage;
        const totalAmount = parseFloat(Inputstate?.MiscAmount?.replaceAll(/,/g, ""));
        const percentageAmount = (actualPercentage / 100) * totalAmount;

        setInputState((prevState) => {
            const updatedState = {
                ...prevState,
                [name]: value,
            };
            if (name == "MiscAmount" && value == "") {
                updatedState.TaxAddCode = "";
                updatedState.TaxCode = "";
                updatedState.TaxAddId = 0;
                updatedState.TaxAddPerc = 0;
                updatedState.TaxAmount = "";
                updatedState.TaxDId = 0;
                updatedState.TaxDPerc = 0;
                updatedState.TaxDAmount = "";
                updatedState.TaxDedCode = "";
            }
            if (name == "ReferenceDate") {
                updatedState.TaxDedCode = "";
                updatedState.TaxDId = 0;
                updatedState.TaxDPerc = 0;
                updatedState.TaxDAmount = "";
            }
            if (name == "TaxCode") {
                updatedState.TaxAddCode = item?.tax_code;
                updatedState.TaxAddId = item?.tax_id;
                updatedState.TaxAddPerc = item?.tax_percentage;
                updatedState.TaxAmount = String(percentageAmount);
            }
            if (name == "TaxDedCode") {
                updatedState.TaxDedCode = item?.tax_code;
                updatedState.TaxDId = item?.tax_id;
                updatedState.TaxDPerc = item?.tax_percentage;
                updatedState.TaxDAmount = String(percentageAmount);
            }

            // Calculate TaxAmount if TaxCode and MiscAmount are both valid numbers
            // if (!isNaN(parseFloat(updatedState.TaxCode)) && !isNaN(parseFloat(updatedState.MiscAmount))) {
            //     const percentage = (parseFloat(updatedState.TaxCode) / 100) * parseFloat(updatedState.MiscAmount.replace(",", ""));
            //     updatedState.TaxAmount = isNaN(percentage) ? "" : percentage.toFixed(2);
            // } else {
            //     updatedState.TaxAmount = ""; // Reset TaxAmount if TaxCode or MiscAmount is not a valid number
            // }

            return updatedState;
        });
    };
    const handleCostCenter = (newrow, index) => {
        if (type == 2) {
            rows[index].gl_info = newrow;
        } else {
            setGeneralLed(newrow);
        }
        setNewRow([]);
        setOldData("");
        setAddItem(false);
    };
    const filterMenuUpload = [
        { text: "Download", src: "/images/common/download-grn.svg", value: 0 },
        { text: "Delete", src: "/images/mobilization/delete.svg", value: 1 },
    ];
    const [uploadFile, setUploadFile] = useState("");
    const [fileSize, setFileSize] = useState("");
    const fileTypes = [
        "image/png",
        "image/jpg",
        "image/jpeg",
        "application/pdf",
        "application/zip",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/msword",
    ];
    const onFilterItemsUpload = (value, id) => {
        switch (value) {
            case 0:
                // downloadInvoice();
                break;
            case 1:
                setUploadFile("");
                setFileSize("");
                break;
            default:
                break;
        }
    };
    const onChange = (e) => {
        const file = e.target.files[0];
        const fileExt = fileTypes.find((item) => item == file.type);
        if (fileExt) {
            setUploadFile(file?.name);
            if (file) {
                const fileSizeInBytes = file.size;
                const fileSizeInKB = fileSizeInBytes / 1024;
                if (fileSizeInKB > 5120) {
                    // 5120 KB = 5 MB
                    // File size exceeds 5MB, display alert message
                    Swal.fire({
                        text: "File size should not exceed 5 MB.",
                        icon: "warning",
                        closeOnClickOutside: false,
                        showCloseButton: true,
                        buttons: {
                            confirm: true,
                        },
                    }).then((confirm) => {
                        if (confirm) {
                            // Optionally, you can reset the input to clear the selected file
                            setUploadFile("");
                            setFileSize("");
                        }
                    });
                    return;
                }
                let fileSizeString;
                if (fileSizeInKB < 1024) {
                    // Display in KB if below 1 MB
                    fileSizeString = `${fileSizeInKB.toFixed(2)} KB`;
                } else {
                    // Convert to MB if 1 MB or above
                    const fileSizeInMB = fileSizeInKB / 1024;
                    fileSizeString = `${fileSizeInMB.toFixed(2)} MB`;
                }
                setFileSize(fileSizeString);
            }
        } else {
            Swal.fire({
                text: "Unsupported file format!",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            }).then((confirm) => {
                if (confirm) {
                    // Optionally, you can reset the input to clear the selected file
                    setUploadFile("");
                    setFileSize("");
                }
            });
            return;
        }
    };
    const containsText = (text, searchText) => text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

    const allOptions = ["Option One", "Option Two", "Option Three", "Option Four"];

    const [selectedOption, setSelectedOption] = useState(supplier);

    const [searchText, setSearchText] = useState("");
    const displayedOptions = useMemo(() => supplier?.filter((option) => containsText(option, searchText)), [searchText]);
    const SupplierMenu = searchText !== "" ? displayedOptions : supplier;
    const {
        data: createdata,
        fetching: createFetch,
        requestComplete: createReqComp,
        error: createError,
    } = useSelector((state) => state?.GetAdvanceDetailsforCreateState);
    const [isChange, setChange] = useState(false);

    const handlePoNumberChange = (value, ind, e, vendor, type) => {
        setChange(true);
        // const { value } = e?.target;
        // Update the poNumber for the corresponding row
        const updatedRows = [...rows];
        setInsertPoVal(value);
        setcheckTableChange(true);
        setTableAnchorEls(e.currentTarget);
        const DebitAmount = updatedRows?.[0]?.poLineItems?.map((el) => parseFloat(el?.amount?.replaceAll(".", "")));
        if (type == "input") {
            setIndex(ind);
            updatedRows[ind].po_no = value;
            updatedRows[ind].debit_amount = "";
        }
        // if (value !== "") {
        //     updatedRows[ind].vendor_name = vendor;
        //     updatedRows[ind].POBalanceAmt = updatedRows?.[0]?.max_value;
        //     // updatedRows[index].POValue = updatedRows?.poHeaders?.basic_value;
        //     // for (let i = 0; i < DebitAmount?.length; i++) {
        //     //     updatedRows[index].POValue += DebitAmount[i] || 0;
        //     // }
        // }
        if (value && vendor) {
            updatedRows[index].vendor_name = vendor;
            updatedRows[index].po_no = value;
            // setTableAnchorEls(null)
            dispatch(GetPoMiscAction(value, orgId));
        }
        if (ViewPO?.error) {
            updatedRows[ind].po_no = "";
            Swal?.fire({
                // text: ` ${MandotaryList}  Maxlength should not be empty or 0 when Mandatory field is checked`,
                icon: "warning",
                text: "PO is not released.",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: "Confirm",
                },
            }).then((confirm) => {
                if (confirm?.isConfirmed) {
                    dispatch({ type: GET_MISC_PO_LIST_RESET });
                } else {
                    return false;
                }
            });
        }

        //dispatch(GetAdvanceDetailsforCreate(value, 3, orgId));
        setRows(updatedRows);
    };
    useEffect(() => {
        const updatedRows = [...rows];
        if (ViewPO?.error) {
            updatedRows[index].po_no = "";
            updatedRows[index].vendor_name = "";
            updatedRows[index].POBalanceAmt = "";
            updatedRows[index].POValue = "";
            updatedRows[index].items_info = "";

            Swal?.fire({
                // text: ` ${MandotaryList}  Maxlength should not be empty or 0 when Mandatory field is checked`,
                icon: "warning",
                text: "PO is not released.",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: "Confirm",
                },
            }).then((confirm) => {
                if (confirm?.isConfirmed) {
                    dispatch({ type: GET_MISC_PO_LIST_RESET });
                } else {
                    return false;
                }
            });
        }
    }, [ViewPO?.error]);

    // Update the debitAmount for the corresponding row
    // const updatedRows = [...rows];
    // updatedRows[index].debitAmount = value;
    // setRows(updatedRows);
    const handleCloseCostCenter = (data, index, type) => {
        if (oldData) {
            let checkValue = JSON?.parse(oldData);
            if (type == 2) {
                rows[index].gl_info = checkValue;
            } else {
                setGeneralLed(checkValue);
            }
        }
        setNewRow([]);
        setOldData("");
        setAddItem(false);
    };
    const handleVendorChange = (e, index) => {
        const { value } = e?.target;
        const vendorMaster = SupplierList?.find((item) => item?.vendor_name == value);

        // const vendorMaster = SupplierList

        // Update the debitAmount for the corresponding row
        const updatedRows = [...rows];
        updatedRows[index].vendor_name = value;
        updatedRows[index].poHeaders = null;
        updatedRows[index].vendorMaster = vendorMaster;
        updatedRows[index].gl_info = [
            {
                gl_account_code: "",
                ledger_type: 0,
                cost_center: "",
                amount: "",
            },
        ];
        updatedRows[index].poLineItems = null;
        updatedRows[index].items_info = null;
        updatedRows[index].max_value = null;
        updatedRows[index].debit_amount = null;

        setRows(updatedRows);
    };
    // const calculateTotalDebitAmount = (dataArray) => {
    //     let total = 0;
    //     dataArray.forEach((item) => {
    //         if (item.debitAmount) {
    //             total += parseFloat(item.debitAmount);
    //         }
    //     });
    //     return total.toFixed(2); // Return total with 2 decimal places
    // };

    // Calculate total sum of debitAmount
    //  const totalDebitAmount = calculateTotalDebitAmount(rows);
    const DisplayRows = rows?.map((el) => el?.debit_amount);

    const { fetching: getPoFetch, requestComplete: getPo, data: getPoData } = useSelector((state) => state?.getMiscPoListState);

    //const POData = ViewPO?.data?.data?.po_lineItems;
    const [POData, SetPoData] = useState([]);

    const showPopup = (msg) => {
        Swal.fire({
            text: msg,
            icon: "warning",
            closeOnClickOutside: false,
            showCloseButton: true,
            button: "Ok",
        }).then((result) => {});
    };

    const HandleOpen = (PoNo, index, amount) => {
        if (!amount) {
            showPopup("Debit amount is empty for the row.");
            return;
        }
        setOpenLineItem(true);
        setIndex(index);
        SetPoData(rows?.[index]?.items_info);
    };
    const setRowFunc = () => {
        const max_value = getPoData?.data?.po_lineItems?.reduce((overall, value) => {
            return (value?.net_amount || 0) + overall;
        }, 0);

        setRows((prev) => {
            return prev?.map((item, ind) => {
                if (index === ind) {
                    const max_value = getPoData?.data?.po_lineItems?.reduce((overall, value) => {
                        return (value?.net_amount || 0) + overall;
                    }, 0);

                    return {
                        ...item,
                        poHeaders: getPoData?.data?.poHeaders,
                        vendorMaster: getPoData?.data?.vendorMaster,
                        gl_info: null,
                        poLineItems: getPoData?.data?.genericLineItem,
                        items_info: getPoData?.data?.po_lineItems,
                        max_value: max_value?.toFixed(2),
                        // debit_amount: "",
                    };
                } else {
                    return item;
                }
            });
        });

        setChange(false);
        setIndex(null);
        dispatch({ type: GET_MISC_PO_LIST_RESET });
    };
    if (getPoData?.data) {
        setRowFunc();
    }
    // Function to replace comma with dot in a string

    const AutoFillOnChange = (value) => {
        steAutofill(value);
    };
    const updateLineItem = (value, index, type) => {
        let lineItem = rows?.[index]?.items_info;
        let remainingBalance = value;
        let startIndex = type == 1 ? 0 : lineItem?.length - 1;
        for (let i = type == 1 ? 0 : lineItem?.length - 1; type == 1 ? i < lineItem?.length : i >= 0; type == 1 ? i++ : i--) {
            const debit_amount = Math.min(remainingBalance, lineItem?.[i]?.net_amount);
            remainingBalance -= debit_amount;
            lineItem[i].debit_amount = debit_amount;
        }
        rows[index].items_info = lineItem;
    };
    const handleDebitAmountChange = (e, i, row) => {
        if (!row?.po_no && !row?.vendor_name) {
            showPopup("Please select a PO number or a vendor.");
            return;
        }
        const { value } = e?.target;
        let ref_value = formatNumber(value) || 0;
        if (row?.max_value && row?.max_value < ref_value) {
            showPopup(`The maximum value allowed for this PO is ${row?.max_value}.`);
            ref_value = row?.max_value;
        }
        updateLineItem(ref_value, i, Autofill);
        const updatedRows = [...rows];
        const ConvertValue = value?.replaceAll(",", "")?.replaceAll?.(".", "");
        updatedRows[i].debit_amount = ref_value?.toString();
        setRows(updatedRows);
    };

    const handleOpenCostCenter = (index) => {
        if (!rows[index]?.debit_amount) {
            showPopup("The debit amount is empty for the row.");
            return;
        }
        setCheckIndex(index);
        setType(2);
        const costList = rows[index]?.gl_info;
        const value = JSON.stringify(costList);
        setIndex(index);
        setOldData(value);
        setNewRow(costList);
        setCostCenterList(costList);
        setAddItem(true);
        if (listData?.length == 0) {
            dispatch(getCostCentreList(orgId));
        }
    };

    const handleSelect = (account_code, index) => {
        newrow?.forEach((element, inde) => {
            if (inde == checkRowIndex) {
                element.gl_account_code = account_code;
            }
        });
        setOpenLedger(false);
    };
    const successMsg = reqId ? "Updated Successfully" : "Created Successfully";
    const errorMsgs = "Something went wrong please contact admin!";
    const EditMisc = !RoleActMiscEdit && reqId;

    const currentStatusInfo = MiscView?.curr_status;
    const nextStatusInfo = MiscView?.next_status;
    const prevStatusInfo = MiscView?.prev_status;
    const nextStatusUsers = MiscView?.next_status_users;
    const VendorMand = Inputstate?.vendor_name == "Select" || !Inputstate?.vendor_name;
    const statusReqObj = {
        inv_id: reqId,
        org_id: +orgId,
        type: 1,
        groupType: 3,
        status_id: null,
        masterref_id: null,
        user_id: null,
        round_robin: nextStatusInfo?.[0]?.round_robin,
        user_array: nextStatusInfo?.[0]?.user_array,
    };
    // Filter the SupplierList based on searchText
    const filteredSupplierList = SupplierList?.filter((option) => option?.vendor_name?.toLowerCase()?.includes(searchText?.toLowerCase()));
    const filteredSupplierMenu = SupplierMenu?.filter((option) => option?.toLowerCase()?.includes(searchText?.toLowerCase()));
    const replaceCommaWithDot = (str) => {
        return str.replaceAll(/,/g, "");
    };

    // Create a new array with the updated values
    const updatedArray = rows.map((obj) => {
        return {
            ...obj,
            debit_amount: obj.debit_amount == null ? obj.debit_amount : obj.debit_amount == "" ? null : replaceCommaWithDot(obj.debit_amount),
        };
    });

    const reqBody = {
        id: reqId ? reqId : 0,
        org_id: orgId,
        vendor_id: Inputstate?.vendor_name,
        request_date: moment(new Date(Inputstate?.requestDate)).format("YYYY-MM-DD"),
        reference_no: Inputstate?.ReferenceNumber,
        reference_date: Inputstate?.ReferenceDate == null ? null : moment(new Date(Inputstate?.ReferenceDate)).format("YYYY-MM-DD"),
        misc_amout: formatNumber(Inputstate?.MiscAmount),
        wbs_id: Inputstate?.WbsId,
        payable_amount: formatNumber(PayableAmount),
        gl_info: glRow,
        tax_id: Inputstate?.TaxDId,
        tax_amount: formatNumber(Inputstate?.TaxDAmount),
        tax_percentage: Inputstate?.TaxDPerc,
        // tax_code: Inputstate?.TaxDedCode ? Inputstate?.TaxDedCode : '',
        excep_percentage: 0,
        tax_add_id: Inputstate?.TaxAddId,
        tax_add_amount: formatNumber(Inputstate?.TaxAmount),
        tax_add_percentage: Inputstate?.TaxAddPerc,
        // tax_add_code: Inputstate?.TaxAddCode,
        remarks: Inputstate?.Remarks,
        po_debit: updatedArray,
        file_type: "",
        file_name: uploadFile || "",
        file_url: fileUrl || "",
        file_size: fileSize || "",
        file_content: fileContent?.filecontent || "",
        gl_indicator: Inputstate?.GLInd,
        // fileContent: fileContent?.filecontent || "",
    };

    const replaceMisc = Inputstate?.MiscAmount?.replaceAll(",", "")?.replaceAll(".", "");
    // const isObjectEmpty = (obj) => {
    //     return Object.values(obj).every((value) => value === "");
    // };
    const isObjectEmpty = glRow?.filter((el) => el?.cost_center == "" && el?.gl_account_code == "" && el?.amount == "");
    const IsMisc = totalLedgerVal !== +replaceMisc && totalLedgerVal && Inputstate?.MiscAmount;

    const OnsubmitMisc = async () => {
        let count = 0;

        if (!Inputstate?.MiscAmount) {
            count++;
            seterrorMsg((prevData) => ({
                ...prevData, // Spread the previous state
                ["MiscAmount"]: "Misc. Amount is required!", // Update the specific key with the new value
            }));
        } else {
            count = 0;
            seterrorMsg((prevData) => ({
                ...prevData, // Spread the previous state
                ["MiscAmount"]: "", // Update the specific key with the new value
            }));
        }
        if (!Inputstate?.vendor_name || Inputstate?.vendor_name == "Select") {
            count++;
            seterrorMsg((prevData) => ({
                ...prevData, // Spread the previous state
                ["Vendor"]: "Vendor is required!", // Update the specific key with the new value
            }));
        } else {
            count = 0;
            seterrorMsg((prevData) => ({
                ...prevData, // Spread the previous state
                ["Vendor"]: "", // Update the specific key with the new value
            }));
        }
        if (!Inputstate?.requestDate) {
            count++;
            seterrorMsg((prevData) => ({
                ...prevData, // Spread the previous state
                ["ReqDate"]: "Request Date is required!", // Update the specific key with the new value
            }));
        } else {
            count = 0;
            seterrorMsg((prevData) => ({
                ...prevData, // Spread the previous state
                ["ReqDate"]: "", // Update the specific key with the new value
            }));
        }
        if (!Inputstate?.WbsElem) {
            count++;
            seterrorMsg((prevData) => ({
                ...prevData, // Spread the previous state
                ["WbsElem"]: "WBS Element  is required!", // Update the specific key with the new value
            }));
        } else {
            count = 0;
            seterrorMsg((prevData) => ({
                ...prevData, // Spread the previous state
                ["WbsElem"]: "", // Update the specific key with the new value
            }));
        }
        if (!Inputstate?.Plant) {
            count++;
            seterrorMsg((prevData) => ({
                ...prevData, // Spread the previous state
                ["Plant"]: "Plant  is required!", // Update the specific key with the new value
            }));
        } else {
            count = 0;
            seterrorMsg((prevData) => ({
                ...prevData, // Spread the previous state
                ["Plant"]: "", // Update the specific key with the new value
            }));
        }
        if (!Inputstate?.Project) {
            count++;
            seterrorMsg((prevData) => ({
                ...prevData, // Spread the previous state
                ["Project"]: "Project  is required!", // Update the specific key with the new value
            }));
        } else {
            count = 0;
            seterrorMsg((prevData) => ({
                ...prevData, // Spread the previous state
                ["Project"]: "", // Update the specific key with the new value
            }));
        }

        const dateObj1 = new Date(Inputstate?.ReferenceDate?.$d);
        const dateObj2 = new Date(Inputstate?.requestDate?.$d);

        // Compare the Date objects
        if (dateObj1 > dateObj2) {
            count++;
            seterrorMsg((prevData) => ({
                ...prevData, // Spread the previous state
                ["ReferenceDate"]: "Refrence Date should not be greater than Request Date.", // Update the specific key with the new value
            }));
        } else {
            count = 0;
            seterrorMsg((prevData) => ({
                ...prevData, // Spread the previous state
                ["ReferenceDate"]: "", // Update the specific key with the new value
            }));
        }

        const ReplaceAmount = Number(Inputstate?.MiscAmount?.replaceAll(",", "")?.replaceAll(".", ""));
        const MisCalc = !(totalDebitAmount > ReplaceAmount && totalDebitAmount && Inputstate?.MiscAmount);
        const PoDebitMand = rows?.filter((el) => (el?.vendor_name == "Select" || !el?.vendor_name) && !el?.po_no);
        const DateCalc = !Inputstate?.ReferenceDate?.$d > Inputstate?.requestDate?.$d;
        if (
            !Inputstate?.MiscAmount ||
            !Inputstate?.vendor_name ||
            Inputstate?.vendor_name == "Select" ||
            !Inputstate?.requestDate ||
            !Inputstate?.WbsElem ||
            !Inputstate?.Plant ||
            !Inputstate?.Project
        ) {
            count++;
            Swal.fire({
                text: `Mandatory fields are required!`,
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else if (isObjectEmpty?.length > 0) {
            count++;
            seterrorMsg((prevData) => ({
                ...prevData, // Spread the previous state
                ["Glmsg"]: "GL rows should not be empty.", // Update the specific key with the new value
            }));
            Swal.fire({
                text: `GL rows should not be empty.`,
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else if (totalDebitAmount > ReplaceAmount && totalDebitAmount && Inputstate?.MiscAmount) {
            count++;
            Swal.fire({
                text: `Miscellaneous amount should be >= Total debit amount value from PO Debit Table.`,
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else if (totalLedgerVal !== +replaceMisc) {
            count++;
            Swal.fire({
                text: `The total amount entered in GL does not match the miscellaneous amount.`,
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else if (PoDebitMand?.length > 0) {
            count++;
            Swal.fire({
                text: `Either a PO number or a vendor must be selected.`,
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else {
            count = 0;
        }
        if (count < 1 && Inputstate?.requestDate && Inputstate?.WbsElem) {
            await dispatch(AddUpdateMiscApi(reqBody));
        }
    };

    const filterMenuList = [
        { text: "Download", src: "/images/common/download-grn.svg", value: 0 },
        { text: "Reset", src: "/images/mobilization/rotate-resetsvg.svg", value: 1 },
        RoleActAdvActivity && { text: "Activity", src: "/images/mobilization/activity_icon.svg", value: 2 },

        prevStatusInfo &&
            ![3, 4, 2, 5]?.includes(currentStatusInfo?.[0]?.master_refid || 0) && {
                text: "Move to prev status",
                src: "/images/invoice/move-to-pre-state.svg",
                value: 3,
            },
    ].filter((item) => item !== null);

    const [selectedUserId, setSelectedUserId] = useState(null);
    function changeStatus(data) {
        const obj = {
            ...data,
            status_id: data?.type == 1 ? nextStatusInfo?.[0]?.next_status || null : prevStatusInfo?.[0]?.prev_status || null,
        };
        if (data?.type == 1) {
            setSelectedUserId(data?.user_id);
        } else {
            setSelectedUserId();
        }
        dispatch(postChangeWorkflowStatus({ upd_status: [obj] }));
    }
    const DisableInput = EditMisc || currentStatusInfo?.[0]?.master_refid == 3 || currentStatusInfo?.[0]?.master_refid == 5;
    const FindGlrow =
        newrow?.filter((el) => el?.amount == "" && el?.cost_center == "" && el?.gl_account_code == "" && el?.ledger_type == 0)?.length > 0;

    return (
        <>
            <Layout>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Main className={`overflow-x-hidden  `}>
                        {/* {AddMisc?.requestComplete && AddMisc?.dataList?.status && <SuccessMessageBox success={successMsg} />} */}
                        {addCmtReqComp && commentData?.status && <SuccessMessageBox success={"Comment Added Successfully"} />}
                        {addCmtReqComp && !commentData?.status && addCmtErr && <MessageBox error={commentData?.message} />}

                        {AddMisc?.error && !AddMisc?.dataList?.status && AddMisc?.requestComplete && <MessageBox error={errorMsgs} />}
                        <div className="mb-5 flex justify-between">
                            <div>
                                <h4 className={`text-2xl font-interSb text-${currentMode}-copy`}>Miscellaneous Payment Request</h4>
                            </div>
                            <div className=" flex text-right">
                                <Link to="/miscellaneous-table" tabIndex={0} title="Cancel">
                                    {" "}
                                    <Button className="bg-light-240 text-light-40 text-base rounded-[8px] py-2 px-5">Cancel</Button>
                                </Link>
                                <Button
                                    tabIndex={0}
                                    className="bg-light-20 text-white text-base rounded-[8px] ml-2 py-2 px-7"
                                    title={"Save"}
                                    onClick={OnsubmitMisc}
                                    disabled={
                                        AddMisc?.fetching ||
                                        VendorMand ||
                                        EditMisc ||
                                        currentStatusInfo?.[0]?.master_refid == 3 ||
                                        currentStatusInfo?.[0]?.master_refid == 5
                                    }
                                >
                                    {"Save"}
                                </Button>
                            </div>
                        </div>
                        {ViewMisc?.fetching && reqId ? (
                            <Loader />
                        ) : (
                            <div className={` overflow-y-auto `}>
                                <div className="xl:flex xl:space-x-[24px]">
                                    <div className="basis-[75%] space-y-2">
                                        {/* Tab view   */}
                                        {findScreeSize < 1180 && reqId && (
                                            <Card
                                                className={`
                                                     xl:mb-0 xl:mt-0 border-[1px] xl:w-[300px] xl:h-[62px] md:h-[84px] border-[rgba(223,225,226,0.56)] 2xl:w-full ${
                                                         currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                                                     }`}
                                            >
                                                <ul className="flex justify-between xl:py-5 xl:px-5 md:px-[62px] md:py-[30px]">
                                                    <StatusButton
                                                        reqId={reqId}
                                                        handleDownload={downloadeExcel}
                                                        handleOpenActivity={handleOpenActivity}
                                                        setSelectUserAnchor={setSelectUserAnchor}
                                                        currentStatusInfo={currentStatusInfo}
                                                        nextStatusInfo={nextStatusInfo}
                                                        createDataObject={MiscView}
                                                        setIsUserActivity={setIsUserActivity}
                                                        findScreeSize={findScreeSize}
                                                        childCompRef={childCompRef}
                                                        filterMenuList={filterMenuList}
                                                        onFilterItems={onFilterItems}
                                                        isMisc={true}
                                                        DisplayRole={DisplayRole}
                                                        nextStatusUsers={nextStatusUsers}
                                                        nextUserList={nextUserList}
                                                    />
                                                </ul>
                                            </Card>
                                        )}

                                        <Card
                                            className={`${
                                                DisableInput ? "pointer-events-none" : ""
                                            } xl:p-[30px] mb-4 mr-4 p-4 border-[1px] border-[rgba(223,225,226,0.56)] h-[224px] md:w-full 2xl:w-full ${
                                                currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                                            } `}
                                            style={{
                                                backgroundColour: "#FCFCFC",
                                            }}
                                        >
                                            <div className=" flex mb-5 space-x-7  viewStyles">
                                                <div className="basis-1/3">
                                                    <label className="block text-sm pb-3">
                                                        <span className="text-[red] mr-1">*</span>Vendor:
                                                    </label>
                                                    {Inputstate?.po_no == "" ? (
                                                        <>
                                                            <FormControl fullWidth>
                                                                <Select
                                                                    labelId="search-select-label"
                                                                    id="search-select"
                                                                    value={Inputstate?.vendor_name || 0}
                                                                    disabled={reqId}
                                                                    label="Options"
                                                                    onChange={(e) => InputhandleChange("vendor_name", e?.target?.value, e)}
                                                                    onClose={() => setSearchText("")}
                                                                    className={`w-[304px] 2xl:w-[416px] bg-${currentMode}-810 text-${currentMode}-copy`}
                                                                    MenuProps={{
                                                                        autoFocus: false,
                                                                        sx: {
                                                                            "& .MuiMenu-paper": {
                                                                                backgroundColor: dropdownColor,
                                                                                color: dropdownText,
                                                                            },
                                                                            "& .MuiMenuItem-root:hover": {
                                                                                backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                                            },
                                                                            "& .Mui-selected": {
                                                                                backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                                            },
                                                                        },
                                                                    }}
                                                                    SelectDisplayProps={{
                                                                        style: {
                                                                            color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                                        },
                                                                    }}
                                                                    IconComponent={() => (
                                                                        <img
                                                                            className="absolute right-[15px] opacity-[.5] pointer-events-none"
                                                                            src={
                                                                                currentMode !== "dark"
                                                                                    ? `/images/header/chevronDown.svg`
                                                                                    : `/images/header/chevronDown-white.svg`
                                                                            }
                                                                            alt="chevronDown"
                                                                        />
                                                                    )}
                                                                >
                                                                    <ListSubheader
                                                                        sx={{
                                                                            backgroundColor: currentMode == "dark" ? "#1F1F1F" : "#fff",
                                                                        }}
                                                                    >
                                                                        <TextField
                                                                            size="small"
                                                                            autoFocus
                                                                            placeholder="Type to search..."
                                                                            fullWidth
                                                                            InputProps={{
                                                                                startAdornment: (
                                                                                    <InputAdornment position="start">
                                                                                        <img src="/images/header/search.svg" alt="search" />
                                                                                    </InputAdornment>
                                                                                ),
                                                                                style: { color: currentMode == "dark" ? "white" : "black" },
                                                                            }}
                                                                            onChange={(e) => setSearchText(e.target.value)}
                                                                            onKeyDown={(e) => {
                                                                                if (e.key !== "Escape") {
                                                                                    e.stopPropagation();
                                                                                }
                                                                            }}
                                                                        />
                                                                    </ListSubheader>
                                                                    <MenuItem value={"Select"} key={0} name="SupplierList">
                                                                        {"Select"}
                                                                    </MenuItem>
                                                                    {filteredSupplierList?.map((option, i) => (
                                                                        <MenuItem key={i + 1} value={option?.id}>
                                                                            {option?.vendor_name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                            {errorMsg?.Vendor && <p className="text-light-50 text-xs">{errorMsg?.Vendor}</p>}
                                                        </>
                                                    ) : (
                                                        <Input
                                                            type="text"
                                                            maxLength={31}
                                                            placeholder="Enter Vendor"
                                                            // onChange={(e) => InputhandleChange("Vendor", e?.target?.value, e)}
                                                            disabled={true}
                                                            value={Inputstate?.vendor_name}
                                                            name="Vendor"
                                                            className={`border-[1px] border-${currentMode}-840 rounded-[8px] px-[18px] py-2 w-full bg-${currentMode}-810`}
                                                        />
                                                    )}
                                                </div>

                                                <div className="basis-1/3 datePic">
                                                    <label className="block text-sm pb-3">
                                                        <span className="text-[red] mr-1">*</span>Request Date:
                                                    </label>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DesktopDatePicker
                                                            selectsEnd
                                                            type="Date"
                                                            inputFormat={userDateFormat}
                                                            disableFuture={true}
                                                            components={{
                                                                OpenPickerIcon: AccessIcon,
                                                            }}
                                                            name="requestDate"
                                                            value={Inputstate?.requestDate}
                                                            maxDate={new Date()} // Set maxDate to the reference date
                                                            minDate={Inputstate?.ReferenceDate}
                                                            disabled={VendorMand}
                                                            onChange={(date) => InputhandleChange("requestDate", date)}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    value={""}
                                                                    fullWidth
                                                                    sx={{
                                                                        "& .MuiInputBase-input": {
                                                                            color: `${currentMode == "dark" ? "white" : "black"} !important`,
                                                                        },
                                                                    }}
                                                                    placeholder={params?.inputProps?.placeholder?.replace("To")}
                                                                    {...params}
                                                                    inputProps={{
                                                                        ...params.inputProps,
                                                                        readOnly: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                    {errorMsg?.ReqDate && <p className="text-light-50 text-xs">{errorMsg?.ReqDate}</p>}
                                                </div>
                                            </div>
                                            <div className=" flex mb-5 space-x-7  viewStyles">
                                                <div className="basis-1/3">
                                                    <label className="block text-sm pb-3">Reference Number:</label>
                                                    <Input
                                                        name="ReferenceNumber"
                                                        placeholder="Enter Reference number"
                                                        value={Inputstate?.ReferenceNumber}
                                                        onChange={(e) => InputhandleChange("ReferenceNumber", e?.target?.value)}
                                                        maxLength={50}
                                                        type="input"
                                                        disabled={VendorMand}
                                                        id="input2"
                                                        className={`border-[1px] border-${currentMode}-840 rounded-[8px] px-[18px] py-2 w-full bg-${currentMode}-810`}
                                                    />
                                                </div>

                                                <div className="basis-1/3 datePic">
                                                    <label className="block text-sm pb-3">Reference Date:</label>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DesktopDatePicker
                                                            selectsEnd
                                                            disabled={VendorMand}
                                                            type="Date"
                                                            disableFuture={true}
                                                            maxDate={Inputstate?.requestDate}
                                                            inputFormat={OrgDateFormat?.toUpperCase()}
                                                            components={{
                                                                OpenPickerIcon: AccessIcon,
                                                            }}
                                                            name="ReferenceDate"
                                                            value={Inputstate?.ReferenceDate ? Inputstate?.ReferenceDate : null}
                                                            onChange={(date) => InputhandleChange("ReferenceDate", date)}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    value={""}
                                                                    fullWidth
                                                                    sx={{
                                                                        "& .MuiInputBase-input": {
                                                                            color: `${currentMode == "dark" ? "white" : "black"} !important`,
                                                                        },
                                                                    }}
                                                                    placeholder={params?.inputProps?.placeholder?.replace("To")}
                                                                    {...params}
                                                                    inputProps={{
                                                                        ...params.inputProps,
                                                                        readOnly: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                    {errorMsg?.ReferenceDate && <p className="text-light-50 text-xs">{errorMsg?.ReferenceDate}</p>}
                                                </div>
                                            </div>
                                        </Card>
                                        <MiscPayment
                                            Inputstate={Inputstate}
                                            DisableInput={DisableInput}
                                            IsMisc={IsMisc}
                                            istotalGl={istotalGl}
                                            DtaxListData={DtaxListData}
                                            setInputState={setInputState}
                                            InputhandleChange={InputhandleChange}
                                            PayableAmount={PayableAmount}
                                            glRow={glRow}
                                            setGlRow={setGlRow}
                                            setTotalLedgerVal={setTotalLedgerVal}
                                            errorMsg={errorMsg}
                                        />
                                    </div>
                                    <div className="basis-[25%] space-y-2">
                                        {findScreeSize > 1180 && reqId && (
                                            <Card
                                                className={`xl:mb-0 xl:mt-0 border-[1px] shadow-md xl:w-[300px] xl:h-[62px] md:h-[84px] border-[rgba(223,225,226,0.56)] 2xl:w-full ${
                                                    currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                                                }`}
                                            >
                                                <ul className="flex justify-between xl:py-5 xl:px-5 md:px-[62px] md:py-[30px]">
                                                    <StatusButton
                                                        reqId={reqId}
                                                        currentStatusInfo={currentStatusInfo}
                                                        nextStatusInfo={nextStatusInfo}
                                                        setSelectUserAnchor={setSelectUserAnchor}
                                                        createDataObject={MiscView}
                                                        setIsUserActivity={setIsUserActivity}
                                                        findScreeSize={findScreeSize}
                                                        childCompRef={childCompRef}
                                                        filterMenuList={filterMenuList}
                                                        onFilterItems={onFilterItems}
                                                        isMisc={true}
                                                        DisplayRole={DisplayRole}
                                                        nextUserList={nextUserList}
                                                        nextStatusUsers={nextStatusUsers}
                                                    />
                                                </ul>
                                            </Card>
                                        )}
                                        <div
                                            className={`bg-[#FFF7F0] shadow-md text-${currentMode}-copy border-[1px] border-${currentMode}-700  rounded-[10px] mt-[20px] mb-[3rem]  xl:mt-0 border-[1px] h-[256px] border-[rgba(223,225,226,0.56)] xl:w-[300px] 2xl:w-full
                                            ${currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"}`}
                                        >
                                            <AdvanceFileUpload
                                                uploadFile={uploadFile}
                                                setUploadFile={setUploadFile}
                                                setFileSize={setFileSize}
                                                fileSize={fileSize}
                                                findCreateNewRequest={findCreateNewRequest}
                                                setFileContent={setFileContent}
                                                fileContent={fileContent}
                                                url={fileUrl}
                                                setFileUrl={setFileUrl}
                                                VendorMand={VendorMand}
                                            />
                                        </div>
                                        {reqId && (
                                            <div>
                                                <Card
                                                    className={`${
                                                        DisableInput ? " pointer-events-none" : ""
                                                    } border-[1px]  flex flex-col justify-between h-[345px] border-[rgba(223,225,226,0.56)] xl:w-[300px] 2xl:w-full ${
                                                        currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)] "
                                                    } ${findScreeSize < 1180 ? "my-5" : ""}`}
                                                >
                                                    <CommentCard reqId={reqId} type={3} createDataObject={MiscView} />
                                                </Card>
                                            </div>
                                        )}{" "}
                                    </div>
                                </div>

                                <div className="pb-20">
                                    <Card
                                        className={`${
                                            Inputstate?.vendor_name == "Select" || !Inputstate?.vendor_name ? "opacity-[.5] pointer-events-none" : ""
                                        }${
                                            DisableInput ? "pointer-events-none" : ""
                                        } mt-[30px] mb-2 mb-18  border-[1px] border-[rgba(223,225,226,0.56)] md:w-full 2xl:w-full ${
                                            currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)] "
                                        } overflow-y-auto`}
                                    >
                                        <div className="flex mt-[19px] ml-[20px] mb-[20px] justify-between">
                                            <span className="text-[18px] font-medium">
                                                <h3>PO Debit</h3>
                                            </span>
                                            <div className=" flex justify-between">
                                                <span className="mr-[20px] mt-[10px]">Autofill:</span>

                                                <div className="mr-[20px]">
                                                    <FormControl fullWidth>
                                                        <Select
                                                            labelId="search-select-label"
                                                            id="search-select"
                                                            value={Autofill}
                                                            label="Options"
                                                            onChange={(e) => AutoFillOnChange(e.target.value)}
                                                            // onClose={() => setSearchText("")}
                                                            // renderValue={() => row?.Vendor}
                                                            className={`w-[160px] bg-${currentMode}-810 text-${currentMode}-copy`}
                                                            MenuProps={{
                                                                autoFocus: false,
                                                                sx: {
                                                                    "& .MuiMenu-paper": {
                                                                        backgroundColor: dropdownColor,
                                                                        color: dropdownText,
                                                                    },
                                                                    "& .MuiMenuItem-root:hover": {
                                                                        backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                                    },
                                                                    "& .Mui-selected": {
                                                                        backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                                    },
                                                                },
                                                            }}
                                                            SelectDisplayProps={{
                                                                style: {
                                                                    color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                                },
                                                            }}
                                                            IconComponent={() => (
                                                                <img
                                                                    className="absolute right-[15px] opacity-[.5] pointer-events-none"
                                                                    src={
                                                                        currentMode !== "dark"
                                                                            ? `/images/header/chevronDown.svg`
                                                                            : `/images/header/chevronDown-white.svg`
                                                                    }
                                                                    alt="chevronDown"
                                                                />
                                                            )}
                                                        >
                                                            <MenuItem value={0}>{"Select"}</MenuItem>

                                                            <MenuItem value={1}>Fill from Top</MenuItem>
                                                            <MenuItem value={2}>Fill from Bottom</MenuItem>
                                                            {/* <MenuItem value={3}>Split Equal Items</MenuItem>
                                                            <MenuItem value={4}>
                                                                <CheckBox
                                                                    sx={{
                                                                        padding: 0,
                                                                        color: "#008785",
                                                                        margin: "0 12px",
                                                                        "&.Mui-checked": {
                                                                            color: "#008785",
                                                                        },
                                                                    }}
                                                                ></CheckBox>{" "}
                                                                Set as default
                                                            </MenuItem> */}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="max-h-[175px] overflow-y-auto ">
                                            <table className="w-max  xl:w-full overflow-x-auto border-0 rolemasterTable rounded-[0] text-left">
                                                <thead className="sticky top-0 z-[1]">
                                                    <tr className="text-[14px]">
                                                        <th className="w-20 whitespace-nowrap">Sl. No</th>
                                                        <th className="w-64">PO. Number</th>
                                                        <th className="w-64">Vendor</th>
                                                        <th className="w-40">PO Value</th>
                                                        <th className="w-44">PO Balance Amt.</th>
                                                        <th className="w-56">Debit Amt.</th>
                                                        <th className="w-56">General Ledger</th>
                                                        <th className="w-16"></th>
                                                    </tr>
                                                </thead>
                                                <tbody className="relative">
                                                    {rows?.map((row, index) => {
                                                        const FindGlrow =
                                                            row?.gl_info?.filter(
                                                                (el) =>
                                                                    el?.amount == "" &&
                                                                    el?.cost_center == "" &&
                                                                    el?.gl_account_code == "" &&
                                                                    (el?.ledger_type == 0 || el?.ledger_type == "")
                                                            )?.length > 0 || row?.gl_info == null;
                                                        return (
                                                            <tr key={index}>
                                                                <td className="text-center">{index + 1}</td>
                                                                <td>
                                                                    <input
                                                                        type="number"
                                                                        maxLength={31}
                                                                        // onChange={(e) => TableOnChange(e, index, "poNumber")}
                                                                        placeholder="PO. Number"
                                                                        disabled={disablePo || (reqId && row?.id !== 0)}
                                                                        onChange={(e) =>
                                                                            handlePoNumberChange(e?.target?.value, index, e, "", "input")
                                                                        }
                                                                        onBlur={() => {
                                                                            setTimeout(() => {
                                                                                setChange(false);
                                                                            }, 100);
                                                                        }}
                                                                        value={row.po_no}
                                                                        className="border-[rgba(85,85,85,0.5)] w-[200px] h-[35px] bg-transparent px-[10px] py-1 border-[1px] rounded-[8px]"
                                                                        onKeyDown={(e) => {
                                                                            if (e.key === "-" || e.key === "e" || e.key === "E") {
                                                                                e.preventDefault();
                                                                            }
                                                                        }}
                                                                    />
                                                                    {row.po_no !== "" &&
                                                                    poNumberList?.length !== 0 &&
                                                                    poNumberList !== undefined &&
                                                                    isChange ? (
                                                                        <Popper
                                                                            open={Boolean(
                                                                                row.po_no !== "" &&
                                                                                    poNumberList?.length !== 0 &&
                                                                                    poNumberList !== undefined &&
                                                                                    isChange
                                                                            )}
                                                                            anchorEl={TableanchorEls}
                                                                            // disablePortal
                                                                            className="popperTbl z-[22] "
                                                                            // onClickAway={() => handleClickAway()}
                                                                        >
                                                                            <div
                                                                                className={`max-h-[340px] overflow-y-auto ml-[26px] bg-${currentMode}-card border rounded-[8px] text-${currentMode}-copy`}
                                                                            >
                                                                                <table
                                                                                    className={`w-[514px] max-h-[230px] overflow-y-auto bg-${currentMode}-card rolemasterTable  rounded-[8px] text-left`}
                                                                                >
                                                                                    <thead className="sticky top-0">
                                                                                        <tr className="">
                                                                                            <th className="">PO.No.</th>
                                                                                            <th className="">Vendor</th>
                                                                                            <th>Vendor type</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody className="">
                                                                                        {row.po_no !== "" &&
                                                                                        filteredPoNoList !== undefined &&
                                                                                        filteredPoNoList?.length !== 0 &&
                                                                                        isChange
                                                                                            ? filteredPoNoList?.map((elem, ind) => {
                                                                                                  return (
                                                                                                      <tr
                                                                                                          className="cursor-pointer"
                                                                                                          onClick={(e) =>
                                                                                                              handlePoNumberChange(
                                                                                                                  elem?.PoNumber,
                                                                                                                  index,
                                                                                                                  e,
                                                                                                                  elem?.VendorName,
                                                                                                                  "onclick"
                                                                                                              )
                                                                                                          }
                                                                                                      >
                                                                                                          <td className="pl-1 pr-1">
                                                                                                              {elem?.PoNumber}
                                                                                                          </td>
                                                                                                          <td className="max-w-[390px] pl-1 truncate">
                                                                                                              {elem?.VendorName}
                                                                                                          </td>
                                                                                                          <td className="flex">{elem?.VendorType}</td>
                                                                                                      </tr>
                                                                                                  );
                                                                                              })
                                                                                            : poNumberList?.map((item) => {
                                                                                                  return (
                                                                                                      <tr
                                                                                                          className="cursor-pointer"
                                                                                                          //   onClick={(e) =>
                                                                                                          //       invoiceTxtChng(
                                                                                                          //           e,
                                                                                                          //           currentItem.label_name,
                                                                                                          //           item?.PoNumber,
                                                                                                          //           currentItem.id,
                                                                                                          //           currentItem?.data_type,
                                                                                                          //           currentItem?.iseditable,
                                                                                                          //           currentItem?.is_confirmed,
                                                                                                          //           "click",
                                                                                                          //           currentItem?.label_displayname
                                                                                                          //       )
                                                                                                          //   }
                                                                                                      >
                                                                                                          <td className="pl-1 pr-1">
                                                                                                              {item?.PoNumber}
                                                                                                          </td>
                                                                                                          <td
                                                                                                              title={item?.VendorName}
                                                                                                              className="max-w-[390px] pl-1 truncate"
                                                                                                          >
                                                                                                              {item?.VendorName}
                                                                                                          </td>
                                                                                                          <td className="flex">{item?.VendorType}</td>
                                                                                                      </tr>
                                                                                                  );
                                                                                              })}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </Popper>
                                                                    ) : fetching && !requestComplete && tablePopperOpen ? (
                                                                        <Loader className="h-[calc(100vh_-_17rem)]" />
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </td>
                                                                <td className="">
                                                                    {row?.po_no == "" ? (
                                                                        <>
                                                                            <FormControl fullWidth>
                                                                                <Select
                                                                                    labelId="search-select-label"
                                                                                    id="search-select"
                                                                                    value={row?.vendor_name || 0}
                                                                                    label="Options"
                                                                                    disabled={reqId && row?.id !== 0}
                                                                                    onChange={(e) => handleVendorChange(e, index)}
                                                                                    onClose={() => setSearchText("")}
                                                                                    className={`w-[200px] bg-${currentMode}-810 text-${currentMode}-copy`}
                                                                                    MenuProps={{
                                                                                        autoFocus: false,
                                                                                        sx: {
                                                                                            "& .MuiMenu-paper": {
                                                                                                backgroundColor: dropdownColor,
                                                                                                color: dropdownText,
                                                                                            },
                                                                                            "& .MuiMenuItem-root:hover": {
                                                                                                backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                                                            },
                                                                                            "& .Mui-selected": {
                                                                                                backgroundColor:
                                                                                                    "rgba(218, 249, 244, 0.3) !important",
                                                                                            },
                                                                                        },
                                                                                    }}
                                                                                    SelectDisplayProps={{
                                                                                        style: {
                                                                                            color: `${
                                                                                                currentMode !== "dark" ? `#555555` : `#ECECEC`
                                                                                            }`,
                                                                                        },
                                                                                    }}
                                                                                    IconComponent={() => (
                                                                                        <img
                                                                                            className="absolute right-[15px] opacity-[.5] pointer-events-none"
                                                                                            src={
                                                                                                currentMode !== "dark"
                                                                                                    ? `/images/header/chevronDown.svg`
                                                                                                    : `/images/header/chevronDown-white.svg`
                                                                                            }
                                                                                            alt="chevronDown"
                                                                                        />
                                                                                    )}
                                                                                >
                                                                                    <ListSubheader
                                                                                        sx={{
                                                                                            backgroundColor:
                                                                                                currentMode == "dark" ? "#1F1F1F" : "#fff",
                                                                                        }}
                                                                                    >
                                                                                        <TextField
                                                                                            size="small"
                                                                                            autoFocus
                                                                                            placeholder="Type to search..."
                                                                                            fullWidth
                                                                                            InputProps={{
                                                                                                startAdornment: (
                                                                                                    <InputAdornment position="start">
                                                                                                        <img
                                                                                                            src="/images/header/search.svg"
                                                                                                            alt="search"
                                                                                                        />
                                                                                                    </InputAdornment>
                                                                                                ),
                                                                                                style: {
                                                                                                    color: currentMode == "dark" ? "white" : "black",
                                                                                                },
                                                                                            }}
                                                                                            onChange={(e) => setSearchText(e.target.value)}
                                                                                            onKeyDown={(e) => {
                                                                                                if (e.key !== "Escape") {
                                                                                                    e.stopPropagation();
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    </ListSubheader>
                                                                                    <MenuItem value={0}>{"Select"}</MenuItem>
                                                                                    {filteredSupplierMenu.map((option, i) => (
                                                                                        <MenuItem key={i} value={option}>
                                                                                            {option}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Select>
                                                                            </FormControl>
                                                                        </>
                                                                    ) : (
                                                                        <span>{row?.vendor_name}</span>
                                                                    )}
                                                                </td>
                                                                <td className="">
                                                                    {/* {row?.items_info
                                                                        ? row?.items_info?.reduce((overall, ele) => {
                                                                            return (+ele?.amount || 0) + overall;
                                                                        }, 0)
                                                                        : ""} */}
                                                                    <NumericFormat
                                                                        key={`id-${index}`}
                                                                        decimalScale={2}
                                                                        value={
                                                                            row?.items_info
                                                                                ? row?.items_info?.reduce((overall, ele) => {
                                                                                      return (+ele?.amount || 0) + overall;
                                                                                  }, 0)
                                                                                : ""
                                                                        }
                                                                        thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                                                                        decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                                                                        className="bg-transparent"
                                                                    />
                                                                </td>
                                                                <td className="">
                                                                    <NumericFormat
                                                                        key={`id-${index}`}
                                                                        decimalScale={2}
                                                                        value={row?.max_value}
                                                                        thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                                                                        decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                                                                        className="bg-transparent"
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <NumericFormat
                                                                        key={`id-${index}`}
                                                                        decimalScale={2}
                                                                        onChange={(e) => handleDebitAmountChange(e, index, row)}
                                                                        value={row?.debit_amount}
                                                                        placeholder="Debit Amount"
                                                                        maxLength={11}
                                                                        thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                                                                        decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                                                                        className="border-[rgba(85,85,85,0.5)] w-[200px] h-[35px] bg-transparent px-[10px] py-1 border-[1px] rounded-[8px]"
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        maxLength={31}
                                                                        disabled={row.po_no || row.vendor_name == "Select"}
                                                                        placeholder="General Ledger"
                                                                        value={FindGlrow ? "" : "GL is Configured"}
                                                                        onClick={(e) => handleOpenCostCenter(index)}
                                                                        className="border-[rgba(85,85,85,0.5)] w-[200px] h-[35px] bg-transparent px-[10px] py-1 border-[1px] rounded-[8px]"
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <div className="flex ">
                                                                        <Button
                                                                            className="w-[18px] h-[18px] mr-[18px]"
                                                                            disabled={!row.po_no || !row?.items_info || row?.items_info?.length == 0}
                                                                        >
                                                                            <img
                                                                                src="/images/mobilization/Line-Item.svg"
                                                                                title="LineItem"
                                                                                alt="LineItem"
                                                                                className="convertWhite"
                                                                                onClick={() => HandleOpen(row.po_no, index, row?.debit_amount)}
                                                                            />
                                                                        </Button>
                                                                        <Button className="w-[18px] h-[18px]">
                                                                            <img
                                                                                src="/images/mobilization/divide-circle-misc.svg"
                                                                                title="delete"
                                                                                alt="delete"
                                                                                className="convertWhite"
                                                                                onClick={() => handleDeleteClick(index)}
                                                                            />
                                                                        </Button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                    <tr>
                                                        <td colSpan={5} className="px-[10px] font-interSb text-right">
                                                            Total
                                                        </td>
                                                        <td className="px-[10px] bg-[#D3FAF445]">
                                                            <NumericFormat
                                                                value={totalDebitAmount}
                                                                key={`id-${index}`}
                                                                decimalScale={2}
                                                                thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                                                                decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                                                                className="bg-transparent"
                                                            ></NumericFormat>
                                                        </td>
                                                        <td className="px-[10px]"></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Card>
                                    <div className="flex mt-0">
                                        <button
                                            className="text-[#028C78] font-bold py-4 px-2"
                                            onClick={handleAddRow}
                                            disabled={DisableInput || VendorMand}
                                        >
                                            + Add New
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                        {ReactDOM.createPortal(
                            <Activity isOpened={isUserActivity} handleClose={() => setIsUserActivity(false)} className={isUserActivity && "Show"} />,
                            document.getElementById("user-modal-root")
                        )}
                    </Main>
                    <Footer />
                </ErrorBoundary>
            </Layout>
            {ReactDOM.createPortal(
                <POHeaderLineItemPopUp
                    // PoLineItems={PoLineItems}
                    PoLineItems={POData}
                    isOpened={openLineItem}
                    handleClose={() => setOpenLineItem(false)}
                    className={openLineItem && "Show"}
                    setRows={setRows}
                    index={index}
                    overAll={rows?.[index]?.debit_amount || 0}
                />,
                document.getElementById("user-modal-root")
            )}
            {ReactDOM.createPortal(
                <LedgerModal
                    handleSelect={handleSelect}
                    isOpened={openLedger}
                    checkIndex={checkIndex}
                    nonPOItemList={nonPOItemList}
                    handleClose={() => setOpenLedger(false)}
                    className={`${openLedger && "Show"}`}
                />,
                document.getElementById("user-modal-root")
            )}
            {ReactDOM.createPortal(
                <ToReassign
                    isOpened={isreassign}
                    apiProps={{
                        inv_id: reqId,
                        org_id: +orgId,
                        type: 3,
                        status_id: prevStatusInfo?.[0]?.prev_status ? prevStatusInfo?.[0]?.prev_status : null,
                        user_id: null,
                        masterref_id: null,
                        groupType: 3,
                    }}
                    changeStatus={changeStatus}
                    statusName={prevStatusInfo?.[0]?.status_name ? prevStatusInfo?.[0]?.status_name : null}
                    handleClose={() => setReassign(false)}
                    className={`${isreassign && "Show"}`}
                />,
                document.getElementById("user-modal-root")
            )}
            {selectUserAnchor && (
                <>
                    {ReactDOM.createPortal(
                        <SelectUserModal
                            id={1}
                            isOpened={selectUserAnchor}
                            setSelectUserAnchor={setSelectUserAnchor}
                            currentMode={currentMode}
                            className={selectUserAnchor && "Show"}
                            getEmail={userEmail}
                            userList={nextUserList}
                            setUserEmail={setUserEmail}
                            changeStatus={changeStatus}
                            statusReqObj={statusReqObj}
                            handleSelectUser={(e, value, reason) => _handleSelectUser(1, value, reason, "rule_id")}
                        />,
                        document.getElementById("Modal-root")
                    )}
                </>
            )}
            {ReactDOM.createPortal(
                <GlPOTableItems
                    setTotalLedgerVal={setTotalLedgerVal}
                    generalLedger={costCenterList}
                    handleOpenLedger={handleOpenLedger}
                    costListData={costListData}
                    setCostListData={setCostListData}
                    isOpened={addItem}
                    listData={listData}
                    MiscView={MiscView}
                    newrow={newrow}
                    setNewRow={setNewRow}
                    tableListData={tableListData}
                    nonPOItemList={nonPOItemList}
                    nonPoCostListData={nonPoCostListData}
                    handleCostCenter={handleCostCenter}
                    oldData={oldData}
                    handleCloseCostCenter={handleCloseCostCenter}
                    handleClose={() => setAddItem(false)}
                    className={`${addItem && "Show"}`}
                    index={index}
                    type={type}
                    debitAmount={rows?.[index]?.debit_amount || 0}
                />,
                document.getElementById("user-modal-root")
            )}
        </>
    );
};

export default MiscellaneousPaymentRequest;
