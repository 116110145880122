import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Button from "../../components/Button";
import DonutChart from "./DonutChart";
import { useEffect, useMemo, useState } from "react";
import { getCurrentUser } from "../../redux/actions/auth";
import Loader from "../../components/Loader";
import { DashboardCategory } from "../../redux/actions/dashboard";
import { Button as Buttton, CircularProgress, Tab, Tabs, Tooltip } from "@mui/material";

export const NewTabsTile = (props) => {
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        const isActive = value === index;
        return (
            <div
                role="tabpanel"
                hidden={!isActive}
                style={{ opacity: isActive ? "1" : "0" }}
                className="basis-[65%] ease-in duration-300"
                id={`vertical-tabpanel-${index}`}
                aria-labelledby={`vertical-tab-${index}`}
                {...other}>
                <div style={{ opacity: isActive ? "1" : "0" }} className={`flex justify-between ease-in duration-300  h-full`}>
                    {children}
                </div>
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `vertical-tab-${index}`,
            "aria-controls": `vertical-tabpanel-${index}`,
        };
    }

    let orgId = localStorage.getItem("orgId") || 0;
    const { currentMode, userId } = props;
    const dispatch = useDispatch();
    const location = useLocation();
    const Currentuser = getCurrentUser();
    const letuserId = Currentuser?.role_id == 1 ? Currentuser?.id : userId;
    const VariantCard = (props) => {
        return (
            <>
                {props?.to ? (
                    <Link
                        to={props?.to}
                        className={`px-[18px] py-[15px] inline-block tileVariant hover:translate-y-[calc(-1.5rem_/_5)] duration-150 ease-in h-[100px] text-${currentMode}-copy bg-${currentMode}-card flex flex-col rounded-[8px] justify-between ${props?.className}`}>
                        {props?.children}
                    </Link>
                ) : (
                    <div
                        className={`px-[18px] py-[15px] inline-block tileVariant hover:translate-y-[calc(-1.5rem_/_5)] duration-150 ease-in h-[100px] text-${currentMode}-copy bg-${currentMode}-card flex flex-col rounded-[8px] justify-between ${props?.className}`}>
                        {props?.children}
                    </div>
                )}
            </>
        );
    };

    useEffect(() => {
        [1, 2, 3, 4]?.map((el, i) => {
            dispatch(DashboardCategory(orgId, letuserId, el));
        });
    }, [dispatch, location.pathname]);

    //* To check the role
    const { data: DataList } = useSelector((state) => state?.DisplayRolePermissionStateAction);
    const RoleProcess = DataList?.role_info?.menu?.find((elem) => elem?.menu_id === 8)?.actions;
    const RoleAdvance = DataList?.role_info?.menu?.find((elem) => elem?.menu_id === 21)?.actions;
    const RoleMisc = DataList?.role_info?.menu?.find((elem) => elem?.menu_id === 22)?.actions;
    const RoleRetention = DataList?.role_info?.menu?.find((elem) => elem?.menu_id === 23)?.actions;
    const checkProcess = RoleProcess?.some((element) => element.isallowed === true);
    const checkAdv = RoleAdvance?.some((element) => element.isallowed === true);
    const checkMisc = RoleMisc?.some((element) => element.isallowed === true);
    const checkRet = RoleRetention?.some((element) => element.isallowed === true);
    const [value, setValue] = useState(1);
    const [type, setType] = useState(1);
    useEffect(() => {
        if (checkProcess) {
            setValue(1);
            setType(1);
        } else if (checkAdv) {
            setValue(2);
            setType(2);
        } else if (checkMisc) {
            setValue(3);
            setType(3);
        } else if (checkRet) {
            setValue(4);
            setType(4);
        }
    }, [checkProcess]);

    //* For donut chart
    const { data: dashBoardData, overall, error, fetching, requestComplete } = useSelector((state) => state?.DasboardStatus);
    //* For the grid tiles
    const gridData = (datas, istype) => {
        let To;
        let isRole;
        switch (istype) {
            case 1:
                To = "/invoice";
                isRole = checkProcess;
                break;
            case 2:
                To = "/advance-list";
                isRole = checkAdv;
                break;
            case 3:
                To = "/miscellaneous-table";
                isRole = checkMisc;
                break;
            case 4:
                To = "/retention-release-list";
                isRole = checkRet;
            default:
                break;
        }

        return [
            // {
            //     id: 1,
            //     name: "In Queue",
            //     total_invoices: todayntotal?.in_queue_count || 0,
            //     value: null,
            //     img_src: "/images/dashboard/in-queue.svg",
            //     box_shadow: `border-[#F3E8FF]`,
            //     cursor: `cursor-default`,
            //     to: null,
            //     allowed: true,
            // },
            {
                id: 2,
                name: istype == 1 ? "Today Invoices" : "Today Requests",
                total_invoices: datas?.todayntotal[0]?.today_invoice_count || 0,
                value: datas?.todayntotal[0]?.today_invoice_value || 0,
                img_src: "/images/dashboard/Icon1.svg",
                box_shadow: `border-b-[#FFE3F3]`,
                cursor: `cursor-pointer`,
                to: To,
                allowed: isRole,
                adv: true,
            },
            {
                id: 3,
                name: "Today Processed",
                total_invoices: datas?.todayntotal[0]?.today_processed_invoices || 0,
                value: datas?.todayntotal[0]?.today_processed_invoice_value || 0,
                img_src: "/images/dashboard/processed.svg",
                box_shadow: `border-b-[#E4EEFE]`,
                cursor: `cursor-default`,
                to: null,
                allowed: isRole,
                adv: istype == 1 ? true : false,
            },
            {
                id: 4,
                name: "Today Exceptions",
                total_invoices: datas?.todayntotal[0]?.today_exception_invoice_count || 0,
                value: datas?.todayntotal[0]?.today_exception_invoice_value || 0,
                img_src: "/images/dashboard/exceptions.svg",
                box_shadow: `border-b-[#FFE2E5]`,
                cursor: `cursor-default`,
                to: null,
                allowed: isRole,
                adv: istype == 1 ? true : false,
            },
        ];
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    let dash;
    let Val;
    let Title;
    switch (type) {
        case 1:
            Val = gridData(overall[1]?.[0], 1);
            dash = overall[1]?.[0];
            Title = "Invoice by Status";
            break;
        case 2:
            Val = gridData(overall[2]?.[0], 2);
            dash = overall[2]?.[0];
            Title = "Advance Request Status";
            break;
        case 3:
            Val = gridData(overall[3]?.[0], 3);
            dash = overall[3]?.[0];
            Title = "Miscellaneous Payments Status";
            break;
        case 4:
            Val = gridData(overall[4]?.[0], 4);
            dash = overall[4]?.[0];
            Title = "Retention Release Status";
            break;
        default:
            Title = "Invoice by Status";
            break;
    }
    const inv_dashboard = dash?.inv_dashboard;
    const todayntotal = dash?.todayntotal;
    const totalValue = dash?.inv_totalnet;
    let totalCount = 0;
    const data = [];
    const colors = [];
    inv_dashboard?.forEach((item) => {
        totalCount = totalCount + (item?.inv_count || 0);
        data?.push({ name: item?.status_name, value: item?.inv_count });
        colors?.push(item?.status_colour);
    });

    const commonElem = (
        <div className="space-y-4 pl-[30px] flex flex-col py-4 w-full h-full justify-center">
            {Val?.filter?.((ele) => ele?.allowed && ele?.adv)?.map((item, index) => {
                return (
                    <div className={`relative border-${currentMode}-700  ${index == 2 || index == 3 ? "mb-0" : ""}`}>
                        <VariantCard
                            className={`border-b-[6px] ${item?.cursor} shadow-[0px_0px_5px_1px] shadow-${currentMode}-940 ${item?.box_shadow} rounded-b-[8px]`}
                            to={item?.to}>
                            <div className="flex h-full w-[full] items-center">
                                <img className="w-[60px] h-[60px] items-center scale-jumb px-0" src={item?.img_src} alt="queue" />
                                <div className="flex ml-5  flex-col">
                                    {fetching ? (
                                        <CircularProgress size={25} style={{ color: "#00B399" }} />
                                    ) : (
                                        <b className="font-interM mb-1  text-[20px]">{item?.total_invoices}</b>
                                    )}

                                    <span className="text-[16px] w-[full]">{item?.name}</span>
                                </div>
                            </div>
                        </VariantCard>
                        <div
                            className="mx-5 flex items-center"
                            style={{
                                position: "absolute",
                                top: "55%",
                                right: "0",
                                textAlign: "center",
                            }}>
                            {/* {!item?.value && ( */}
                            <>
                                <span className={`text-${currentMode}-copy`}>Value</span>
                                <div
                                    className={`${
                                        currentMode == "dark" ? "bg-[#1f1f1f]" : "bg-[#E9F8F4]"
                                    } text-${currentMode}-15 ml-2 rounded-[8px] p-[2px] text-xs`}>
                                    <span className={``}>{item?.value || "0k"}</span>
                                </div>
                            </>
                            {/* )} */}
                        </div>
                    </div>
                );
            })}
        </div>
    );
    return (
        <>
            {/* {fetching ? (
                <Loader />
            ) : ( */}
            {(checkProcess || checkAdv || checkMisc || checkRet) && (
                <div
                    className={`basis-[60%] mx-[12px] ${
                        currentMode == "dark" ? "bg-[#1f1f1f]" : "bg-white"
                    } cursor-default shadow-[0px_4px_4px_0px] mb-[10px] border border-${currentMode}-1370  shadow-[rgba(0,0,0,0.06)] xl:overflow-x-hidden rounded-[8px]
            `}>
                    <div className="flex md:flex-col xl:flex-row">
                        <div className="basis-[60%] xl:mr-[30px]">
                            <div className={`flex items-center border-b border-${currentMode}-700 w-full`}>
                                <h4 className={`font-interM text-lg py-[12px] pl-[30px] flex text-${currentMode}-copy`}>Quick Stats</h4>{" "}
                                <Tooltip
                                    title={"Dashboard stats provides an overview of KPIs, metrics related to invoice and vendors."}
                                    disablePortal
                                    placement="top"
                                    disableInteractive
                                    PopperProps={{
                                        sx: {
                                            "& .MuiTooltip-tooltip": {
                                                fontSize: "0.9em",
                                                maxHeight: "120px",
                                                maxWidth: "300px",
                                                background: "#DAF9F4",
                                                color: "#555555",
                                                borderRadius: "5px",
                                                paddingBottom: "10px",
                                                paddingTop: "10px",
                                                paddingLeft: "15px",
                                            },

                                            "& .MuiTooltip-arrow": {
                                                color: "#DAF9F4",
                                            },
                                        },
                                    }}
                                    arrow>
                                    <img className="ml-2 cursor-pointer" src="/images/main/noun-information.svg" alt="noun-information" />
                                </Tooltip>
                            </div>
                            <div className="flex">
                                <Tabs
                                    className={`DashboardTabs basis-[35%]`}
                                    value={value}
                                    aria-label="Vertical tabs example"
                                    orientation="vertical"
                                    onChange={handleChange}
                                    TabIndicatorProps={{ children: <span className={`indicator`} /> }}>
                                    <Tab
                                        label="Item One"
                                        component={() => (
                                            <Buttton
                                                className={`flex MuiTab-root pointer-events-none ${value == 0 ? "Mui-selected" : ""}`}
                                                onClick={() => {
                                                    setValue(0);
                                                    setType(1);
                                                }}>
                                                <div className="mr-[20px]">
                                                    <img className="w-[50px] h-[50px]" src="/images/dashboard/in-queue.svg" alt="in-queue" />
                                                </div>
                                                <div>
                                                    {fetching ? (
                                                        <CircularProgress size={25} style={{ color: "#00B399" }} />
                                                    ) : (
                                                        <span className="block text-xl font-interSb">
                                                            {overall[1]?.[0]?.todayntotal?.[0]?.in_queue_count || 0}
                                                        </span>
                                                    )}

                                                    <span className="block font-interM">In Queue</span>
                                                </div>
                                            </Buttton>
                                        )}
                                        className={`${currentMode == "dark" ? `text-${currentMode}-copy` : ""}`}
                                        {...a11yProps(0)}
                                    />
                                    {checkProcess && (
                                        <Tab
                                            label="Item two"
                                            component={() => (
                                                <Buttton
                                                    onClick={() => {
                                                        setValue(1);
                                                        setType(1);
                                                    }}
                                                    className={`flex MuiTab-root ${value == 1 ? "Mui-selected" : ""}`}>
                                                    <div className="mr-[20px]">
                                                        <img className="w-[50px] h-[50px]" src="/images/dashboard/Icon1.svg" alt="in-queue" />
                                                    </div>
                                                    <div>
                                                        {fetching ? (
                                                            <CircularProgress size={25} style={{ color: "#00B399" }} />
                                                        ) : (
                                                            <span className="block text-xl font-interSb">
                                                                {overall[1]?.[0]?.todayntotal?.[0]?.total_invoice_count || 0}
                                                            </span>
                                                        )}

                                                        <span className="block font-interM">Invoices</span>
                                                    </div>
                                                </Buttton>
                                            )}
                                            className={`${currentMode == "dark" ? `text-${currentMode}-copy` : ""}`}
                                            {...a11yProps(1)}
                                        />
                                    )}
                                    {checkAdv && (
                                        <Tab
                                            label="Item Three"
                                            className={`${currentMode == "dark" ? `text-${currentMode}-copy` : ""}`}
                                            component={() => (
                                                <Buttton
                                                    onClick={() => {
                                                        setValue(2);
                                                        setType(2);
                                                    }}
                                                    className={`flex MuiTab-root ${value == 2 ? "Mui-selected" : ""}`}>
                                                    <div className="mr-[20px]">
                                                        <img className="w-[50px] h-[50px]" src="/images/dashboard/advance.svg" alt="advance" />
                                                    </div>
                                                    <div>
                                                        {fetching ? (
                                                            <CircularProgress size={25} style={{ color: "#00B399" }} />
                                                        ) : (
                                                            <span className="block text-xl font-interSb">
                                                                {overall[2]?.[0]?.todayntotal?.[0]?.total_invoice_count || 0}
                                                            </span>
                                                        )}

                                                        <span className="block font-interM">Advances</span>
                                                    </div>
                                                    <span class="MuiTouchRipple-root "></span>
                                                </Buttton>
                                            )}
                                            {...a11yProps(2)}
                                        />
                                    )}
                                    {checkMisc && (
                                        <Tab
                                            label="Item Four"
                                            className={`${currentMode == "dark" ? `text-${currentMode}-copy` : ""}`}
                                            component={() => (
                                                <Buttton
                                                    onClick={() => {
                                                        setValue(3);
                                                        setType(3);
                                                    }}
                                                    className={`flex MuiTab-root ${value == 3 ? "Mui-selected" : ""}`}>
                                                    <div className="mr-[20px]">
                                                        <img className="w-[50px] h-[50px]" src="/images/dashboard/misc.svg" alt="misc" />
                                                    </div>
                                                    <div>
                                                        {fetching ? (
                                                            <CircularProgress size={25} style={{ color: "#00B399" }} />
                                                        ) : (
                                                            <span className="block text-xl font-interSb">
                                                                {overall[3]?.[0]?.todayntotal?.[0]?.total_invoice_count}
                                                            </span>
                                                        )}
                                                        <span className="block font-interM">Miscellaneous</span>
                                                    </div>
                                                </Buttton>
                                            )}
                                            {...a11yProps(3)}
                                        />
                                    )}
                                    {checkRet && (
                                        <Tab
                                            label="Item Five"
                                            className={`${currentMode == "dark" ? `text-${currentMode}-copy` : ""}`}
                                            component={() => (
                                                <Buttton
                                                    onClick={() => {
                                                        setValue(4);
                                                        setType(4);
                                                    }}
                                                    className={`flex MuiTab-root ${value == 4 ? "Mui-selected" : ""}`}>
                                                    <div className="mr-[20px]">
                                                        <img className="w-[50px] h-[50px]" src="/images/dashboard/retention.svg" alt="retention" />
                                                    </div>
                                                    <div>
                                                        {fetching ? (
                                                            <CircularProgress size={25} style={{ color: "#00B399" }} />
                                                        ) : (
                                                            <span className="block text-xl font-interSb">
                                                                {overall[4]?.[0]?.todayntotal?.[0]?.total_invoice_count || 0}
                                                            </span>
                                                        )}

                                                        <span className="block font-interM">Retention Release</span>
                                                    </div>
                                                </Buttton>
                                            )}
                                            {...a11yProps(4)}
                                        />
                                    )}
                                </Tabs>
                                <duv value={value} index={1} className={`tab-panel ease-in duration-300 basis-[65%] ${value === 1 ? "active" : ""}`}>
                                    {commonElem}
                                </duv>
                                <div value={value} index={2} className={`tab-panel ease-in duration-300 basis-[65%] ${value === 2 ? "active" : ""}`}>
                                    {commonElem}
                                </div>
                                <TabPanel value={value} index={3}>
                                    {commonElem}
                                </TabPanel>
                                <div value={value} index={4} className={`tab-panel ease-in duration-300 basis-[65%] ${value === 4 ? "active" : ""}`}>
                                    {commonElem}
                                </div>
                            </div>
                        </div>
                        <div className={`basis-[40%] xl:mr-[30px] mb-4`}>
                            <div className="flex flex-col h-full">
                                <div className={`flex justify-between items-center border-b border-${currentMode}-700 xl:ml-[30px] w-full`}>
                                    <h4 className={`font-interM text-lg py-[12px] md:px-4 xl:px-0 flex text-${currentMode}-copy`}>{Title}</h4>
                                    <Button
                                        title="Click to Refresh"
                                        onClick={() =>
                                            [1, 2, 3, 4]?.map((el, i) => {
                                                dispatch(DashboardCategory(orgId, letuserId, el));
                                            })
                                        }
                                        className="py-2 pr-4">
                                        <img src="/images/common/refresh.svg" alt="refresh" />
                                    </Button>
                                </div>
                                <div className={`p-5 xl:border-l border-${currentMode}-700 h-full`}>
                                    {fetching ? (
                                        <Loader />
                                    ) : data?.length > 0 ? (
                                        <DonutChart
                                            data={data}
                                            currentMode={currentMode}
                                            Colors={colors}
                                            totalValue={totalValue}
                                            totalCount={totalCount}
                                        />
                                    ) : (
                                        <div className="flex justify-center h-full m-auto items-ecnter">
                                            <div className="m-auto">
                                                <img src="/images/common/delivery.png" alt="delivery" />
                                                <h4 className={`font-interSb text-center text-${currentMode}-copy mt-3`}>No data found</h4>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className={`mx-3 bg-${currentMode}-card  pb-[20px]`}>
                        <div className={`bg-${currentMode}-450 flex justify-between items-center`}>
                            <Tabs
                                className={`DashboardTabs`}
                                variant="scrollable"
                                value={value}
                                onChange={handleChange}
                                TabIndicatorProps={{ children: <span className={`indicator`} /> }}>
                                <Tab
                                    label={`Invoices (40)`}
                                    className={`${currentMode == "dark" ? `text-${currentMode}-copy` : ""}`}
                                    {...a11yProps(0)}
                                />
                                <Tab
                                    label={`Advance Invoices (100)`}
                                    className={`${currentMode == "dark" ? `text-${currentMode}-copy` : ""}`}
                                    {...a11yProps(1)}
                                />
                                <Tab
                                    className={`${currentMode == "dark" ? `text-${currentMode}-copy` : ""}`}
                                    label={`Miscellaneous (10)`}
                                    {...a11yProps(2)}
                                />
                                <Tab
                                    className={`${currentMode == "dark" ? `text-${currentMode}-copy` : ""}`}
                                    label={`Retention Release (0)`}
                                    {...a11yProps(3)}
                                />
                            </Tabs>
                            <div>
                                <p className="pr-5">{`In Queue (5)`}</p>
                                <span>
                                    <img src="/public/images/" alt="" />
                                </span>
                            </div>
                        </div>
                        <div>
                            <TabPanel value={value} index={0}>
                                <div className="basis-[45%]">
                                    <div className="flex items-center">
                                        <div className="text-center basis-[30%]">
                                            <strong className="fonr-interSb text-[32px]">40</strong>
                                            <span className="text-lg font-interM block">Total Invoices</span>
                                        </div>
                                        <div className="basis-[70%]">
                                            {grid2
                                                ?.filter?.((ele) => ele?.allowed)
                                                ?.map((item, index) => {
                                                    return (
                                                        <div className={`relative border-${currentMode}-700  mb-[10px]`}>
                                                            <VariantCard
                                                                className={`border-b-[6px] space ${item?.cursor} shadow-[0px_0px_5px_1px] shadow-${currentMode}-940 ${item?.box_shadow}   rounded-b-[8px]`}
                                                                to={item?.to}>
                                                                <div className="flex h-full w-[full] items-center">
                                                                    <img
                                                                        className="w-[60px] h-[60px] items-center scale-jumb px-0"
                                                                        src={item?.img_src}
                                                                        alt="queue"
                                                                    />
                                                                    <div className="flex ml-5  flex-col">
                                                                        <span className="mb-1 text-[16px] w-[full]">{item?.name}</span>
                                                                        <b className="font-interB text-[20px]">{item?.total_invoices}</b>
                                                                    </div>
                                                                </div>
                                                            </VariantCard>
                                                            <div
                                                                className=" mx-5 flex items-center"
                                                                style={{
                                                                    position: "absolute",
                                                                    top: "55%",
                                                                    right: "0",
                                                                    textAlign: "center",
                                                                }}>
                                                                {item?.value && (
                                                                    <>
                                                                        {" "}
                                                                        <span className={`text-${currentMode}-copy`}>Value</span>
                                                                        <div
                                                                            className={`${
                                                                                currentMode == "dark" ? "bg-[#1f1f1f]" : "bg-[#E9F8F4]"
                                                                            } text-${currentMode}-10 ml-2 rounded-[8px] p-[2px] text-xs`}>
                                                                            <span className={``}>{item?.value}</span>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    </div>
                                </div>
                                <div className="basis-[50%]">
                                    <div className="p-5">
                                        {data?.length > 0 ? (
                                            <DonutChart
                                                data={data}
                                                currentMode={currentMode}
                                                Colors={colors}
                                                totalValue={totalValue}
                                                totalCount={totalCount}
                                            />
                                        ) : (
                                            <div className="flex justify-center">
                                                <span>
                                                    <img src="/images/common/delivery.png" alt="delivery" />
                                                    <h4 className={`font-interSb text-center text-${currentMode}-copy mt-3`}>No data found</h4>
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </TabPanel>
                        </div>
                    </div> */}
                </div>
            )}

            {/* )} */}
        </>
    );
};
