import { React, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField, ThemeProvider } from "@mui/material";
import { ClockIcon } from "../../../utils/helpers";
import moment from "moment";

const TimePickerTrigger = (props) => {
    const businessSetting = props?.businessSetting;
    const setBusinessSetting = props?.setBusinessSetting;
    const currentMode = props?.currentMode;
    const theme = props?.theme;
    const [startTime, setStartTime] = useState(businessSetting?.display_starttime);
    const [endTime, setEndTime] = useState(businessSetting?.display_endtime);
    const handleLocalChange = (name, value) => {
        const formattedDate = moment(value?.$d?.toString()).format("YYYY-MM-DDTHH:mm:ss");
        if (value) {
            if (name === "display_starttime") {
                setStartTime(formattedDate);
            } else if (name === "display_endtime") {
                setEndTime(formattedDate);
            }
        }
    };

    const handleAccept = (name, value) => {
        const formattedDate = moment(value?.$d?.toString()).format("YYYY-MM-DDTHH:mm:ss");

        if (name === "display_starttime") {
            setBusinessSetting((prevData) => ({
                ...prevData,
                display_starttime: formattedDate,
            }));
            props?.setExcepByOrg((prevVal) => ({
                ...prevVal,
                bussiness_settings: {
                    ...prevVal?.bussiness_settings,
                    display_starttime: formattedDate,
                },
            }));
        } else if (name === "display_endtime") {
            setBusinessSetting((prevData) => ({
                ...prevData,
                display_endtime: formattedDate,
            }));
            props?.setExcepByOrg((prevVal) => ({
                ...prevVal,
                bussiness_settings: {
                    ...prevVal?.bussiness_settings,
                    display_endtime: formattedDate,
                },
            }));
        }
    };

    const formattedTime = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date)) {
            return "Invalid Date";
        }
        return date.toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });
    };

    return (
        <div>
            <div className="flex mt-[15px]">
                <div>
                    <p>Start Time</p>
                    <div
                        className={`flex justify-between rounded-lg overflow-hidden w-[120px] mt-[10px] ${
                            currentMode === "dark" ? "bg-[#353535]" : ""
                        }`}
                    >
                        <div className={`flex items-center datePic `}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <ThemeProvider theme={theme}>
                                    <TimePicker
                                        className={`border-0 max-w-[200px]`}
                                        components={{
                                            OpenPickerIcon: ClockIcon,
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                sx={{
                                                    "& .MuiInputAdornment-root": {
                                                        marginLeft: "-17px !important",
                                                    },
                                                    "& .MuiInputBase-input": {
                                                        color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                    },
                                                }}
                                                {...params}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    readOnly: true,
                                                    placeholder: "HH:MM",
                                                }}
                                            />
                                        )}
                                        value={startTime}
                                        name="display_starttime"
                                        onChange={(value) => handleLocalChange("display_starttime", value)}
                                        onAccept={(value) => handleAccept("display_starttime", value)}
                                        referenceDate={new Date()}
                                    />
                                </ThemeProvider>
                            </LocalizationProvider>
                        </div>
                    </div>
                </div>
                <div className="ml-[10px]">
                    <p>End Time</p>
                    <div
                        className={`flex justify-between rounded-lg overflow-hidden w-[120px] mt-[10px] ${
                            currentMode === "dark" ? "bg-[#353535]" : ""
                        }`}
                    >
                        <div className={`flex items-center datePic `}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <ThemeProvider theme={theme}>
                                    <TimePicker
                                        className={`border-0 max-w-[200px]`}
                                        components={{
                                            OpenPickerIcon: ClockIcon,
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                sx={{
                                                    "& .MuiInputAdornment-root": {
                                                        marginLeft: "-17px !important",
                                                    },
                                                    "& .MuiInputBase-input": {
                                                        color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                    },
                                                }}
                                                {...params}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    readOnly: true,
                                                    placeholder: "HH:MM",
                                                }}
                                            />
                                        )}
                                        value={endTime}
                                        name="display_endtime"
                                        onChange={(value) => handleLocalChange("display_endtime", value)}
                                        onAccept={(value) => handleAccept("display_endtime", value)}
                                        referenceDate={new Date()}
                                    />
                                </ThemeProvider>
                            </LocalizationProvider>
                        </div>
                    </div>
                </div>
            </div>
            {businessSetting?.display_starttime || businessSetting?.display_endtime ? (
                <p className="mt-[11px]">
                    Reminders will be sent every{" "}
                    {`${businessSetting?.display_starttime ? formattedTime(businessSetting?.display_starttime) : "00:00"}`}{" "}
                    {`${businessSetting?.display_endtime ? `to  ${`${formattedTime(businessSetting?.display_endtime)}.`}` : ""}`}
                </p>
            ) : (
                ""
            )}
        </div>
    );
};

export default TimePickerTrigger;
