import { Chip, IconButton, Tooltip } from "@mui/material";
import { useContext, useState } from "react";
import ReactQuill from "react-quill";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import Card from "../../../components/UI/Card";
import Main from "../../../components/UI/Main";
import Layout from "../../../Hoc/Layout";
import Footer from "../../../organisms/Footer";
import { EMailShareLinks } from "../../../utils/constants";
import Zoom from "@mui/material/Zoom";
import { useDispatch, useSelector } from "react-redux";
import { FolderSidebar } from "./FolderSidebar";
import { useEffect, useCallback } from "react";
import Swal from "sweetalert2";
import SuccessMessageBox from "../../../components/UI/Alert/sucess";
import MessageBox from "../../../components/UI/Alert";
import { PrevEmail } from "./PrevEmail";
import moment from "moment";
import DOMPurify from "dompurify";
import { getInboxFolder } from "../../../redux/actions/email-inbox";
import EmailChipComponent from "../../../components/MailAutoComplete/EmailChipComponent";
import { CKEditor } from "ckeditor4-react";
import {
    encryptId,
    generateRandomDarkColor,
    // generateUniqueColor,
    getInitials,
    getUsernameColor,
    fileGroup,
    fileSizeMB,
    getFilesSizeInMB,
} from "../../../utils/helpers";
import { postSendMsMail } from "../../../redux/actions/email-inbox.js";
import { POST_SEND_MS_MAIL_RESET } from "../../../redux/types/types.js";
import { getAttachmentPreviewCall } from "../../../redux/actions/email-inbox";
import { GetMicrosoftContactList } from "../../../redux/actions/contacts.js";
import { downloadAttachment } from "../../../redux/actions/email-inbox";
import { DOWNLOAD_ATTACHMENT_RESET } from "../../../redux/types/types.js";
import { useNavigate } from "react-router-dom";
import { postTaskComplete } from "../../../redux/actions/my-task";
import { POST_COMPLETE_TASK_RESET } from "../../../redux/types/types";
import { convertUtcDate } from "../../../utils/helpers";
import WebSocketComponent from "./WebsocketComponent";
import { useTheme, useMediaQuery, ClickAwayListener } from "@mui/material";
import { useRef } from "react";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import { INBOX_BODY_VIEW_RESET, INBOX_FOLDER_GET_RESET, INBOX_PREVIEW_GET_RESET } from "../../../redux/types/types.js";

let uploadedFiles = [];

let fileStatusList = [];
export const InpoxCompose = () => {
    const { currentMode } = useContext(ThemeContext);
    const divRef = useRef(null);
    const [expanded, setExpanded] = useState("panel1");
    const [toEmail, setToEmail] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [ccEmail, setCcEmail] = useState([]);
    const [ccInputValue, setCcInputValue] = useState("");
    const [bccEmail, setBccEmail] = useState([]);
    const [bccInputValue, setBccInputValue] = useState("");
    const [ccEnable, setCcEnable] = useState(false);
    const [bccEnable, setBccEnable] = useState(false);
    const [subject, setSubject] = useState("");
    const [content, setContent] = useState("");
    const [fileList, setFileStatusList] = useState([]);
    const [divExpand, setDivExpand] = useState(true);
    const [replyEnable, setReplyEnable] = useState(false);
    const [emailType, setEmailType] = useState(0);
    const [errorTo, setErrorTo] = useState(false);
    const [errorCc, setErrorCc] = useState(false);
    const [errorBcc, setErrorBcc] = useState(false);
    const [openFolder, setOpenFolder] = useState(false);
    const [isActive, setIsActive] = useState("");
    const [torender, setRender] = useState(false);
    const [isDesktop, setDesktop] = useState(window.innerWidth < 1024);
    const handleOpen = () => {
        setOpenFolder(!openFolder);
    };
    const updateMedia = () => {
        setDesktop(window.innerWidth < 1024);
    };

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });
    const navigate = useNavigate();

    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const dispatch = useDispatch();
    const { emailbody, taskBody, fetching, requestComplete } = useSelector((state) => state?.inboxbodyViewState);

    // Regular expression to match 'color:rgb(0,0,0)' and 'color="#000000"'
    const regexs = /color\s*:\s*rgb\s*\(\s*0\s*,\s*0\s*,\s*0\s*\)|color="#000000"/gi;

    // New color value to replace the existing colors
    const newColorValue = "color:'#ECECEC'";

    // Replace the old colors with the new color using the replace function
    const emailReplace = currentMode == "dark" ? emailbody?.body?.content?.replace(regexs, newColorValue) : emailbody?.body?.content;

    const Listdata = useSelector((state) => state?.GetMicrosoftContactListReduState);
    const msContactListData = Listdata?.Listdata?.data?.value?.map((el) => el?.emailAddresses);
    const filterArr = msContactListData?.map((el) => [...el]).flat();
    const overallOptions = filterArr?.map((el) => el?.address);
    const delData = useSelector((state) => state?.deleteMsMailState);
    const markData = useSelector((state) => state?.emailMarksState);
    const getSync = localStorage.getItem("SyncMail");
    useEffect(() => {
        dispatch(getInboxFolder(getSync));
        if (overallOptions == undefined) {
            dispatch(GetMicrosoftContactList());
        }
        dispatch({ type: INBOX_BODY_VIEW_RESET });
        dispatch({ type: INBOX_FOLDER_GET_RESET });
        dispatch({ type: INBOX_PREVIEW_GET_RESET });
    }, [dispatch]);
    const Data = useSelector((state) => state?.inboxFolderState);
    const [isDraft, setIsDraft] = useState(false);

    const attachment = useSelector((state) => state?.getAttachmentPreviewState);
    const {
        dataList: downloadAttachmentData,
        fetching: downloadFetching,
        requestComplete: downloadRequest,
        error,
    } = useSelector((state) => state?.downloadAttachmentState);
    const {
        fetching: fetchingPostTask,
        requestComplete: requestCompTask,
        error: errorTask,
        data: postTask,
    } = useSelector((state) => state?.postCompleteTaskState);
    const getAttachments = attachment?.dataList?.data?.value;
    const getUserId = JSON.parse(localStorage.getItem("user"));
    const getRoleId = getUserId?.role_id;
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const [activeOption, setActiveOption] = useState("");
    const editorRef = useRef(null);
    const composeRef = useRef(null);
    const handleEditorReady = (editor) => {
        editorRef.current = editor;
    };
    const handleComposeReady = (editor) => {
        composeRef.current = editor;
    };
    const handleMoreOption = (hovertext, el) => {
        setActiveOption(hovertext);
        if (hovertext == "View Invoice") {
            const path = `${"/invoice-view?"}${encryptId(
                `{"inv":${+taskBody?.[0]?.invoice_id},"type":${5},"seq":"${taskBody?.[0]?.inv_seq}","status":${+taskBody?.[0]?.ext_status}}`
            )}
           `;
            navigate(path);
        }
        if (hovertext == "Complete Task") {
            if (taskBody[0]?.task_status == 1) {
                completeTask();
            }
        }
        if (hovertext == "Download") {
            let fileDwnld = document.body.appendChild(document.createElement("a"));
            fileDwnld.download = "export.html";
            fileDwnld.href = "data:text/html," + document.getElementById("content").innerHTML;
            fileDwnld.click();
        }
        if (hovertext == "Copy email link") {
            copyContent();
        }
    };

    const copyContent = async () => {
        try {
            await navigator.clipboard.writeText(emailbody?.webLink);
        } catch (err) {}
    };

    const upDateTo = useCallback(
        (input, email, id) => {
            if (id == "to") {
                setToEmail(email);
                let lastCharacter = input.charAt(input?.length - 1);
                if (lastCharacter === ";") {
                    setInputValue(input.slice(0, -1));
                } else {
                    setInputValue(input);
                }
            } else if (id == "cc") {
                setCcEmail(email);
                let lastCharacter = input.charAt(input?.length - 1);
                if (lastCharacter === ";") {
                    setCcInputValue(input.slice(0, -1));
                } else {
                    setCcInputValue(input);
                }
            } else if ((id = "bcc")) {
                setBccEmail(email);
                let lastCharacter = input.charAt(input?.length - 1);
                if (lastCharacter === ";") {
                    setBccInputValue(input.slice(0, -1));
                } else {
                    setBccInputValue(input);
                }
            }
        },
        [toEmail]
    );

    const updateInput = useCallback(
        (input, id) => {
            if (id == "to") {
                setInputValue(input);
                let lastCharacter = input.charAt(input?.length - 1);
                if (lastCharacter === ";") {
                    setInputValue(input.slice(0, -1));
                } else {
                    setInputValue(input);
                }
                setErrorTo(false);
            } else if (id == "cc") {
                let lastCharacter = input.charAt(input?.length - 1);
                if (lastCharacter === ";") {
                    setCcInputValue(input.slice(0, -1));
                } else {
                    setCcInputValue(input);
                }

                setErrorCc(false);
            } else if (id == "bcc") {
                let lastCharacter = input.charAt(input?.length - 1);
                if (lastCharacter === ";") {
                    setBccInputValue(input.slice(0, -1));
                } else {
                    setBccInputValue(input);
                }
                setErrorBcc(false);
            }
        },
        [inputValue]
    );

    async function uploadWithJSON(file, fileName) {
        const reader = new FileReader();
        reader.onload = await _handleReaderLoaded(fileName).bind(this);
        reader.readAsBinaryString(file);
    }

    const handleDiscardEmail = () => {
        Swal.fire({
            text: `Are you sure you want to discard?`,
            closeOnClickOutside: false,
            showCancelButton: true,
            showCloseButton: true,
            icon: "warning",
            buttons: {
                cancel: "Cancel",
                confirm: "Confirm",
            },
        }).then((confirm) => {
            if (confirm?.isConfirmed) {
                setToEmail([]);
                setCcEmail([]);
                setBccEmail([]);
                setInputValue("");
                setCompose(false);
                setCcInputValue("");
                setIsDraft(false);
                setSubject("");
                setContent("");
                setIsActive("");
                setCcEnable(false);
                setBccEnable(false);
                setSelectedFiles([]);
                dispatch({ type: INBOX_BODY_VIEW_RESET });
                setReplyEnable(false);
            }
        });
    };
    const handleDiscardReply = () => {
        Swal.fire({
            text: `Are you sure you want to discard this draft?`,
            icon: "warning",
            closeOnClickOutside: false,
            showCancelButton: true,
            showCloseButton: true,
            icon: "warning",
            buttons: {
                cancel: "Cancel",
                confirm: "Confirm",
            },
        }).then((confirm) => {
            if (confirm?.isConfirmed) {
                replyReset();
            }
        });
    };

    const [compose, setCompose] = useState(false);
    const icons = ReactQuill.Quill.import("ui/icons");
    const [isExpanded, setIsExpanded] = useState(false);
    const expandDiv = () => {
        setIsExpanded(!isExpanded);
    };
    const modules = {
        toolbar: [
            ["bold", "italic", "underline", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
            ["link"],
            ["clean"],
            ["expand"],
        ],
    };

    const formats = ["header", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link"];
    // For the Attachment:
    const fileTypes = [
        "text/plain", // Plain text files
        "application/rtf", // Rich Text Format files
        "text/markdown", // Markdown files
        "application/msword", // Microsoft Word documents
        "application/vnd.ms-excel", // Microsoft Excel spreadsheets
        "application/vnd.ms-powerpoint", // Microsoft PowerPoint presentations
        "image/jpeg", // JPEG images
        "image/png", // PNG images
        "image/gif", // GIF images
        "image/bmp", // BMP images
        "application/pdf", // PDF files
        "audio/mpeg", // MP3 audio files
        "audio/wav", // WAV audio files
        "audio/aac", // AAC audio files
        "video/mp4", // MP4 videos
        "video/avi", // AVI videos
        "video/x-matroska", // MKV videos
        "application/zip", // Zip archives
        "image/svg+xml", //SVG images
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", //excel file
    ];

    // Handle File Attachments by convert them to base 64

    const handleFileChange = async (event) => {
        const files = event.target.files;
        const selectedFilesArray = Array.from(files);
        const fileOverallSize = selectedFiles?.length + files?.length;

        const overallSizeInMb = (selectedFiles?.length == 0 ? 0 : getFilesSizeInMB(selectedFiles)) + getFilesSizeInMB(selectedFilesArray);
        if (overallSizeInMb > fileGroup) {
            Swal.fire({
                text: `Only ${fileGroup} MB attachments are allowed overall.`,
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            }).then((confirm) => {
                return false;
            });
            return false;
        }
        // setSelectedFiles((previousItem) => [
        //     ...selectedFilesArray,
        //     ...previousItem,
        // ]);
        let correctArray = [];
        let overSizeArray = [];
        if (files) {
            for (let i = 1; i <= files.length; i++) {
                const fileExt = fileTypes.find((item) => item == files[i - 1].type);
                if (files[i - 1]) {
                    const fileSize = (files[i - 1].size / (1024 * 1024)).toFixed(2); //mb conversion
                    if (fileSize > fileSizeMB) {
                        fileStatusList.push({
                            filename: files[i - 1].name,
                            error: "File size should be less than 10MB",
                            status: false,
                        });
                        overSizeArray?.push(files[i - 1].name);
                    } else if (!fileExt) {
                        fileStatusList.push({
                            filename: files[i - 1].name,
                            error: "File type is not supported",
                            status: false,
                        });
                    } else {
                        fileStatusList.push({
                            filename: files[i - 1].name,
                            error: "",
                            status: true,
                        });
                        await uploadWithJSON(files[i - 1], files[i - 1].name);
                        correctArray?.push(files[i - 1].name);
                    }
                }
            }
            const nonOverSizeFiles = selectedFilesArray?.filter((file) => {
                return correctArray.includes(file?.name);
            });
            setSelectedFiles((previousItem) => {
                const combinedArray = [...nonOverSizeFiles, ...previousItem];
                const uniqueSet = new Set(combinedArray);
                const uniqueArray = [...uniqueSet];
                return uniqueArray;
            });
            if (overSizeArray?.length > 0) {
                Swal.fire({
                    text: `Attachments: ${overSizeArray?.join(",")} exceed 1 MB.`,
                    icon: "warning",
                    closeOnClickOutside: false,
                    showCloseButton: true,
                    buttons: {
                        confirm: true,
                    },
                }).then((confirm) => {
                    overSizeArray = [];
                });
            }
            setFileStatusList([...fileStatusList]);
            event.target.value = "";
        }
    };
    async function uploadWithJSON(file, filename) {
        const reader = new FileReader();
        reader.onload = await _handleReaderLoaded(filename).bind(this);
        reader.readAsBinaryString(file);
    }

    const _handleReaderLoaded = (filename) => async (e) => {
        let binaryString = e.target.result;
        let imgObject = {
            filecontent: btoa(binaryString),
            filename: filename,
        };
        setRender(!torender);
        uploadedFiles.push(imgObject);
    };
    // Remove Files
    const handleRemoveFile = (file) => {
        const updatedFiles = selectedFiles.filter((f) => f?.name !== file?.name);
        uploadedFiles = uploadedFiles.filter((f) => f?.filename !== file?.name);
        setSelectedFiles(updatedFiles);
    };

    const data = useSelector((state) => state?.sendMsMailState);

    // for reply
    const replyReset = useCallback(() => {
        setReplyEnable(false);
        if (composeRef.current) {
            composeRef.current.editor.setData("");
        }
        setToEmail([]);
        setInputValue("");
        setCcInputValue("");
        setBccInputValue("");
        setSubject("");
        setContent("");
        setCcEnable(false);
        setBccEnable(false);
        setCcEmail([]);
        setBccEmail([]);
        setIsActive("");
        uploadedFiles = [];
        setSelectedFiles([]);
    }, [replyEnable]);

    const handleEditorChange = (event) => {
        const data = event.editor.getData();
        setContent(data);
    };

    const sendReply = (id) => {
        if (inputValue.length != 0) {
            if (regex.test(inputValue)) {
                setToEmail(toEmail.concat(inputValue));
                setInputValue("");
            } else {
                setErrorTo(true);
                return false;
            }
        }
        if (ccInputValue?.length != 0) {
            if (regex.test(ccInputValue)) {
                setCcEmail(ccEmail.concat(ccInputValue));
                setCcInputValue("");
            } else {
                setErrorCc(true);
                return false;
            }
        }

        if (bccInputValue?.length != 0) {
            if (regex.test(bccInputValue)) {
                setBccEmail(bccEmail.concat(bccInputValue));
                setBccInputValue("");
            } else {
                setErrorBcc(true);
                return false;
            }
        }

        // const getCc = ccEmail?.length > 0 ? encryptId(ccEmail.join(";")) : null;
        // const getTo = toEmail?.length > 0 ? encryptId(toEmail.join(";")) : null;
        // const getBcc = bccEmail?.length > 0 ? encryptId(bccEmail.join(";")) : null;

        const getCc =
            ccEmail?.length > 0 || ccInputValue?.length > 0
                ? encryptId(
                      ccInputValue?.length > 0 ? (ccEmail?.length > 0 ? ccEmail.concat(ccInputValue).join(";") : ccInputValue) : ccEmail.join(";")
                  )
                : null;

        const getTo =
            toEmail?.length > 0 || inputValue?.length > 0
                ? encryptId(inputValue?.length > 0 ? (toEmail?.length > 0 ? toEmail.concat(inputValue).join(";") : inputValue) : toEmail.join(";"))
                : null;

        const getBcc =
            bccEmail?.length > 0 || bccInputValue?.length > 0
                ? encryptId(
                      bccInputValue?.length > 0
                          ? bccEmail?.length > 0
                              ? bccEmail.concat(bccInputValue).join(";")
                              : bccInputValue
                          : bccEmail.join(";")
                  )
                : null;

        const reqObj = {
            json: [
                {
                    subject: subject,
                    body: content,
                    email_to: getTo,
                    email_cc: getCc,
                    email_bcc: getBcc,
                    email_type: id == "reply" ? emailType : 1,
                    message_id: id == "reply" ? emailbody?.id : null,
                },
            ],
            attachment: uploadedFiles,
        };
        dispatch(postSendMsMail(reqObj));
    };
    useEffect(() => {
        if (data?.dataList?.status && data?.requestComplete) {
            setCompose(false);
            setToEmail([]);
            setInputValue("");
            setCcInputValue("");
            setBccInputValue("");
            setSubject("");
            setContent("");
            setCcEnable(false);
            uploadedFiles = [];
            setSelectedFiles([]);
            setCcEmail([]);
            setBccEmail([]);
            setBccEnable(false);
            setReplyEnable(false);

            if (isDraft) {
                setIsActive("");
                dispatch({ type: INBOX_BODY_VIEW_RESET });
                setIsDraft(false);
            }
        }
    }, [data]);
    const replyClick = () => {
        setCcEmail([]);
        setToEmail([emailbody?.sender?.emailAddress?.address]);
        setBccEmail([]);
        setInputValue("");
        setCcInputValue("");
        setBccInputValue("");
        setSubject(emailbody?.subject);
        setCcEnable(false);
        setBccEnable(false);
        setReplyEnable(true);
        setContent("");
        setEmailType(2);
        if (overallOptions == undefined) {
            dispatch(GetMicrosoftContactList());
        }
    };

    if (data?.requestComplete || (!data?.datalist?.status && data?.error)) {
        setTimeout(() => {
            dispatch({ type: POST_SEND_MS_MAIL_RESET });
        }, 2000);
    }

    const replyAllClick = () => {
        // const getCc = decryptId(emailInfo[0]?.email_cc).split(";");
        setCcEmail(emailbody?.ccRecipients?.map((item) => item?.emailAddress?.address));
        setBccEmail(emailbody?.bccRecipients?.map((item) => item?.emailAddress?.address));
        setToEmail([emailbody?.sender?.emailAddress?.address]);
        setInputValue("");
        setCcInputValue("");
        setBccInputValue("");
        setSubject(emailbody?.subject);
        setCcEnable(false);
        setBccEnable(false);
        setReplyEnable(true);
        setContent("");
        setEmailType(3);
        if (overallOptions == undefined) {
            dispatch(GetMicrosoftContactList());
        }
    };

    const forwardClick = () => {
        // const getCc = decryptId(emailInfo[0]?.email_cc).split(";");
        setCcEmail([]);
        setToEmail([]);
        setBccEmail([]);
        setInputValue("");
        setCcInputValue("");
        setBccInputValue("");
        setSubject(emailbody?.subject);
        setCcEnable(false);
        setContent(emailbody?.body?.content);
        setReplyEnable(true);
        setBccEnable(false);
        if (overallOptions == undefined) {
            dispatch(GetMicrosoftContactList());
        }
        setEmailType(4);
    };
    // body view api data getting from useSelector

    const taskInfo = emailbody;
    const handleFileClick = (id) => {
        dispatch(downloadAttachment(id, emailbody?.id));
    };
    useEffect(() => {
        setReplyEnable(false);
        uploadedFiles = [];
        setSelectedFiles([]);
        if (emailbody?.isDraft) {
            setCcEmail(emailbody?.ccRecipients?.map((item) => item?.emailAddress?.address));
            setBccEmail(emailbody?.bccRecipients?.map((item) => item?.emailAddress?.address));
            setToEmail(emailbody?.toRecipients?.map((item) => item?.emailAddress?.address));
            setInputValue("");
            setCcInputValue("");
            setBccInputValue("");
            setSubject(emailbody?.subject);
            setContent(emailReplace);
            setIsDraft(true);
            setCompose(false);
            if (composeRef.current) {
                composeRef.current.editor.setData(emailReplace);
            }
        } else {
            setIsDraft(false);
        }
        if (emailbody?.hasAttachments) {
            dispatch(getAttachmentPreviewCall(emailbody?.id));
        }
        if (editorRef.current) {
            editorRef.current.editor.setData(emailReplace);
        }
    }, [emailbody]);

    if (downloadRequest) {
        if (downloadAttachmentData?.status) {
            const attachmentType = getAttachments?.find((item) => item?.id == downloadAttachmentData?.data?.id)?.contentType;
            const attachmentName = getAttachments?.find((item) => item?.id == downloadAttachmentData?.data?.id)?.name;

            const linkSource = `data:${attachmentType};base64,${downloadAttachmentData?.data?.contentBytes}`;
            const downloadLink = document.createElement("a");
            const fileName = attachmentName;

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        }
        dispatch({ type: DOWNLOAD_ATTACHMENT_RESET });
    }
    const dynamicImage = (item) => {
        switch (item) {
            case "image/jpeg" || "image/png":
                return "/images/common/jpeg-img.svg";
            case "application/octet-stream":
                return "/images/common/jpeg-img.svg";
            case "application/pdf":
                return "/images/common/pdf.svg";
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                return "/images/common/xls.svg";
            case "text/csv":
                return "/images/common/csv.svg";
            default:
                return "/images/common/jpeg-img.svg";
        }
    };
    const completeTask = () => {
        if (taskBody?.[0]?.task_status == 1 && (getRoleId == 2 || getUserId?.id == taskBody?.[0]?.created_by)) {
            const reqObj = {
                id: +taskBody?.[0]?.task_id,
                status: 2,
            };
            dispatch(postTaskComplete(reqObj));
        }
    };

    if (requestCompTask || (requestCompTask && !postTask?.status) || (errorTask && !postTask?.status)) {
        if (requestCompTask && postTask?.status) {
            taskBody[0].task_status = 2;
        }
        setTimeout(function () {
            dispatch({ type: POST_COMPLETE_TASK_RESET });
        }, 2000);
    }

    const EmailContent =
        compose || isDraft ? (
            <>
                <div className="">
                    <Card className="px-[10px] pt-[20px] xl:h-[calc(100vh_-_12rem)] h-[calc(100vh_-_12rem)] overflow-y-auto border-0">
                        <div className={`flex items-center border-b-[1px] mx-[-10px] px-[10px] border-${currentMode}-870`}>
                            <span className={`${currentMode == "dark" ? "text-[rgba(236,236,236)]" : "text-[rgba(85,85,85,0.6)]"} flex-1`} y>
                                To:
                            </span>
                            <EmailChipComponent
                                id="to"
                                updateTo={upDateTo}
                                updateInput={updateInput}
                                email={toEmail}
                                overallOptions={overallOptions}
                                inputValue={inputValue}
                            />
                            {ccEnable == false && (
                                <span
                                    onClick={() => setCcEnable(true)}
                                    className="text-[rgba(85,85,85,0.6)] mr-2 basis-[10%]  text-end  cursor-pointer">
                                    Cc
                                </span>
                            )}
                            {bccEnable == false && (
                                <span
                                    onClick={() => setBccEnable(true)}
                                    className="text-[rgba(85,85,85,0.6)] mr-2 basis-[7%]  text-end  cursor-pointer">
                                    Bcc
                                </span>
                            )}
                        </div>
                        {ccEnable == true && (
                            <>
                                <div className={`flex items-center border-b-[1px] mx-[-10px] px-[10px] border-${currentMode}-870`}>
                                    <span className={`${currentMode == "dark" ? "text-[rgba(236,236,236)]" : "text-[rgba(85,85,85,0.6)]"} flex-1`}>
                                        Cc:
                                    </span>
                                    <EmailChipComponent
                                        id="cc"
                                        updateTo={upDateTo}
                                        updateInput={updateInput}
                                        email={ccEmail}
                                        overallOptions={overallOptions}
                                        inputValue={ccInputValue}
                                    />
                                </div>
                            </>
                        )}
                        {bccEnable == true && (
                            <>
                                <div className={`flex items-center border-b-[1px] mx-[-10px] px-[10px] border-${currentMode}-870`}>
                                    <span className={`${currentMode == "dark" ? "text-[rgba(236,236,236)]" : "text-[rgba(85,85,85,0.6)]"} flex-1`}>
                                        Bcc:
                                    </span>
                                    <EmailChipComponent
                                        id="bcc"
                                        updateTo={upDateTo}
                                        updateInput={updateInput}
                                        email={bccEmail}
                                        overallOptions={overallOptions}
                                        inputValue={bccInputValue}
                                    />
                                </div>
                            </>
                        )}

                        <div className={`flex  border-b-[1px] mx-[-10px] px-[10px]  space-x-3 items-center py-2 border-${currentMode}-860 `}>
                            <span className={`${currentMode == "dark" ? "text-[rgba(236,236,236)]" : "text-[rgba(85,85,85,0.6)]"} flex-1`}>
                                Subject:
                            </span>
                            <Input
                                value={subject}
                                onChange={(event) => setSubject(event.target.value)}
                                className={`w-full flex-initial overflow-y-auto bg-transparent truncate`}
                            />
                        </div>
                        <span className="">
                            {selectedFiles?.map((file, index) => (
                                <Chip
                                    key={index}
                                    label={file.name}
                                    onDelete={() => handleRemoveFile(file)}
                                    style={{
                                        margin: "0.5rem",
                                        maxWidth: "170px",
                                    }}
                                />
                            ))}
                        </span>
                        <div className={`allocate-textarea flex mx-auto relative email mt-6 ${isExpanded ? "expandableDiv" : ``}`}>
                            <span
                                title={isExpanded ? `Click to Minimize` : `Click to Maximize`}
                                onClick={expandDiv}
                                className="absolute right-[20px] top-[13px] cursor-pointer">
                                <img
                                    src={isExpanded ? `/images/inbox/minimize-2.svg` : `/images/quill/maximize.svg`}
                                    alt="maximize"
                                    className="convertWhite"
                                />
                            </span>
                            {/* <ReactQuill
                        modules={modules}
                        value={content}
                        formats={formats}
                        style={{
                            height: isExpanded ? "750px" : "368px",
                        }}
                        onChange={(e) => setContent(e)}
                        theme="snow"
                        /> */}
                            <div className="w-full h-full ">
                                <div className="flex flex-col justify-between" style={{ height: "60vh" }}>
                                    <div className="ckreply">
                                        <CKEditor
                                            ref={composeRef}
                                            data={content}
                                            onChange={handleEditorChange}
                                            onInstanceReady={handleComposeReady}
                                            initData={content}
                                            // onInstanceReady={handleEditorReady}
                                            config={{
                                                removeButtons: "Image,Paste,About,PasteText,PasteFromWord,Anchor",
                                                removePlugins: "maximize",
                                                height: isExpanded ? "auto" : 325,
                                            }}

                                            //style={{ height: "600px" }}
                                        />
                                    </div>
                                    <div
                                        className={`bg-${currentMode}-card relative z-[1] border-y-[1px] flex space-x-3 p-[20px] border-${currentMode}-870`}>
                                        <Button className={`cursor-pointer`} title="Attachment">
                                            <input
                                                type="file"
                                                multiple
                                                onChange={handleFileChange}
                                                id="file-input"
                                                style={{
                                                    display: "none",
                                                }}
                                                accept={fileTypes.join(",")}
                                            />
                                            <label htmlFor="file-input" className="cursor-pointer">
                                                {/* <IconButton component="span"> */}
                                                {currentMode === "dark" ? (
                                                    <img className={`mx-auto h-[30px]`} src="/images/common/attachment.svg" alt="circle" />
                                                ) : (
                                                    <img
                                                        className={`mx-auto h-[30px] shadow-[0px_4px_20px_rgba(238,238,238,0.5)] `}
                                                        src="/images/main/addfile-list.svg"
                                                        alt="circle"
                                                    />
                                                )}
                                                {/* </IconButton> */}
                                            </label>
                                        </Button>

                                        <Button
                                            onClick={handleDiscardEmail}
                                            className={`cursor-pointer px-3 py-[2px] border-[1px] rounded-[5px] border-20 border-${currentMode}-700`}>
                                            Discard
                                        </Button>
                                        <Button
                                            disabled={(toEmail?.length == 0 && inputValue?.length == 0) || data?.fetching}
                                            onClick={() => sendReply("compose")}
                                            className={`bg-light-20 rounded-[5px] px-5 py-[2px] text-white`}>
                                            Send
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                    {/* <span className="">
                {selectedFiles?.map((file, index) => (
                    <Chip
                        key={index}
                        label={file.name}
                        onDelete={() => handleRemoveFile(file)}
                        style={{ margin: "0.5rem" }}
                    />
                ))}
            </span> */}
                </div>
            </>
        ) : (
            <div className="space-y-4">
                {emailbody?.length !== 0 ? (
                    <Card
                        className={`${currentMode !== "dark" ? "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]" : ""} email ${
                            isExpanded ? "expandableDiv" : ``
                        } ${isActive !== "" ? `mobSpace` : ``} ${divExpand ? "h-[calc(100vh_-_15.5rem)]" : ""} overflow-y-auto`}>
                        <div className={`flex justify-between pb-[20px] border-b-[1px] border-${currentMode}-700 p-[20px]`}>
                            <span className="font-interM">{emailbody?.subject}</span>
                            <div className="flex space-x-5 items-center">
                                <span>
                                    <img
                                        title={isExpanded ? `Click to Minimize` : `Click to Maximize`}
                                        onClick={expandDiv}
                                        className="cursor-pointer convertWhite"
                                        src={isExpanded ? `/images/inbox/minimize-2.svg` : `/images/quill/maximize.svg`}
                                        alt="maximize"
                                    />
                                </span>
                                <span className="xl:hidden">
                                    <img
                                        onClick={() => setIsActive("")}
                                        className="cursor-pointer convertWhite"
                                        src="/images/common/closeBlk.svg"
                                        alt="closeBlk"
                                    />
                                </span>
                            </div>
                        </div>
                        <div expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
                            <div className="cursor-pointer" onClick={() => setDivExpand(!divExpand)} id="content">
                                {replyEnable == false && (
                                    <div className="flex justify-between w-full items-center p-[13px]">
                                        <div className="flex">
                                            <div
                                                className="mt-[3px] flex-initial uppercase text-white block text-xs rounded-full w-[25px] flex items-center justify-center h-[25px]"
                                                style={{
                                                    background: `${getUsernameColor(emailbody?.sender?.emailAddress?.name)}`,
                                                }}>
                                                {getInitials(emailbody?.sender?.emailAddress?.name)}
                                            </div>
                                            <div className="ml-2 flex-1">
                                                <div className="mb-1 flex items-center">
                                                    <span className="font-interM"> {emailbody?.sender?.emailAddress?.name}</span>
                                                    <small className={`ml-1  ${divExpand ? "" : "hided"} text-xs`}>
                                                        {emailbody?.sender?.emailAddress?.address}
                                                    </small>
                                                </div>
                                                <p className={`text-xs ${divExpand ? "" : "hided"} mb-1 text-${currentMode}-900`}>
                                                    <span className="font-interSb">To:</span>{" "}
                                                    <span className="mr-2 inline-block">
                                                        {emailbody?.toRecipients?.map((el) => `${el?.emailAddress?.name}; `)}
                                                    </span>
                                                </p>
                                                <p className={`text-xs ${divExpand ? "" : "hided"} text-${currentMode}-900`}>
                                                    <span className="font-interSb">Subject:</span> {emailbody?.subject}
                                                </p>
                                                <p
                                                    className={`truncate opens text-${currentMode}-890  ${
                                                        divExpand ? "hided" : ""
                                                    } text-xs  w-[20rem] 2xl:w-[50rem]`}>
                                                    {emailbody?.bodyPreview}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="text-end">
                                            <div className={`flex space-x-4 mb-1 ${divExpand ? "" : "hided"}`}>
                                                <img
                                                    src="/images/inbox/reply-icon.svg"
                                                    title="Reply"
                                                    className="convertWhite"
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        replyClick();
                                                    }}
                                                    alt="reply-icon"
                                                />
                                                <img
                                                    src="/images/inbox/replyall-icon.svg"
                                                    title="Reply All"
                                                    className="convertWhite"
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        replyAllClick();
                                                    }}
                                                    alt="reply-icon"
                                                />
                                                <img
                                                    src="/images/inbox/forward-icon.svg"
                                                    title="Forward"
                                                    className="convertWhite"
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        forwardClick();
                                                    }}
                                                    alt="reply-icon"
                                                />
                                            </div>
                                            <span className={`text-xs text-${currentMode}-900`}>{convertUtcDate(emailbody?.receivedDateTime)}</span>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {divExpand && (
                                <>
                                    <div className="p-[15px]">
                                        {replyEnable && (
                                            <>
                                                <div
                                                    className={`border-[1px]  border-${currentMode}-600 ${
                                                        currentMode !== "dark" ? "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]" : ""
                                                    } rounded-[8px] mb-[25px]`}>
                                                    <div className={`flex p-[10px] border-b-[1px] border-${currentMode}-860`}>
                                                        <span
                                                            className={`basis-[5%] max-w-[65px] pt-3.5 ${
                                                                currentMode == "dark" ? "text-[rgba(236,236,236)]" : "text-[rgba(85,85,85,0.7)]"
                                                            }`}>
                                                            To:
                                                        </span>
                                                        <EmailChipComponent
                                                            id="to"
                                                            updateTo={upDateTo}
                                                            updateInput={updateInput}
                                                            email={toEmail}
                                                            overallOptions={overallOptions}
                                                            inputValue={inputValue}
                                                            error={errorTo}
                                                        />
                                                        {ccEnable == false && (
                                                            <span
                                                                onClick={() => setCcEnable(true)}
                                                                className="basis-[30%]  text-end pr-4 cursor-pointer">
                                                                Cc
                                                            </span>
                                                        )}
                                                        {bccEnable == false && (
                                                            <span
                                                                onClick={() => setBccEnable(true)}
                                                                className="mr-2 basis-[5%]  text-end  cursor-pointer">
                                                                Bcc
                                                            </span>
                                                        )}
                                                    </div>
                                                    {ccEnable == true && (
                                                        <>
                                                            <div className={`flex p-[10px] border-b-[1px] border-${currentMode}-860`}>
                                                                <span
                                                                    className={`basis-[5%] mt-3.5 max-w-[65px] ${
                                                                        currentMode == "dark"
                                                                            ? "text-[rgba(236,236,236)]"
                                                                            : "text-[rgba(85,85,85,0.7)]"
                                                                    }`}>
                                                                    Cc:
                                                                </span>
                                                                <EmailChipComponent
                                                                    id="cc"
                                                                    updateTo={upDateTo}
                                                                    updateInput={updateInput}
                                                                    email={ccEmail}
                                                                    overallOptions={overallOptions}
                                                                    inputValue={ccInputValue}
                                                                    error={errorCc}
                                                                />
                                                            </div>
                                                        </>
                                                    )}
                                                    {bccEnable == true && (
                                                        <>
                                                            <div className={`flex p-[10px] border-b-[1px] border-${currentMode}-860`}>
                                                                <span
                                                                    className={`basis-[5%] mt-3 flex-1 max-w-[65px] ${
                                                                        currentMode == "dark"
                                                                            ? "text-[rgba(236,236,236)]"
                                                                            : "text-[rgba(85,85,85,0.7)]"
                                                                    }`}>
                                                                    Bcc:
                                                                </span>
                                                                <EmailChipComponent
                                                                    id="bcc"
                                                                    updateTo={upDateTo}
                                                                    updateInput={updateInput}
                                                                    email={bccEmail}
                                                                    overallOptions={overallOptions}
                                                                    inputValue={bccInputValue}
                                                                />
                                                            </div>
                                                        </>
                                                    )}
                                                    <div className={`flex p-[10px] border-b-[1px] border-${currentMode}-860`}>
                                                        <span
                                                            className={`basis-[11%] ${
                                                                currentMode == "dark" ? "text-[rgba(236,236,236)]" : "text-[rgba(85,85,85,0.7)]"
                                                            }`}>
                                                            Subject:
                                                        </span>
                                                        <input
                                                            className={`basis-[80%] truncate bg-transparent`}
                                                            value={subject}
                                                            readOnly={true}
                                                            onChange={(event) => setSubject(event.target.value)}></input>
                                                    </div>
                                                    <div className="allocate-textarea reply relative">
                                                        <span>
                                                            {selectedFiles?.map((file, index) => (
                                                                <Chip
                                                                    key={index}
                                                                    label={file.name}
                                                                    onDelete={() => handleRemoveFile(file)}
                                                                    style={{
                                                                        margin: "0.5rem",
                                                                        maxWidth: "170px",
                                                                    }}
                                                                />
                                                            ))}
                                                        </span>
                                                        {/* <ReactQuill
                                                        theme="snow"
                                                        //   readOnly={!email_enabled}

                                                        style={{
                                                            borderRadius: "8px",
                                                            // minHeight: "500px",
                                                        }}
                                                        value={content}
                                                        formats={formats}
                                                        modules={modules}
                                                        onChange={(e) =>
                                                            setContent(e)
                                                        }
                                                    /> */}
                                                        <div className="ckreply">
                                                            <CKEditor
                                                                data={content}
                                                                onChange={handleEditorChange}
                                                                initData={content}
                                                                config={{
                                                                    removeButtons: "Image,Paste,About,PasteText,PasteFromWord,Anchor",
                                                                    removePlugins: "maximize",
                                                                    height: 325,
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={`relative border-y-[1px] space-x-3 flex rounded-b-[8px] p-[20px] border-${currentMode}-870`}>
                                                        {taskBody?.length === 0 ? (
                                                            <Button className="cursor-pointer" title="Attachment">
                                                                <input
                                                                    type="file"
                                                                    multiple
                                                                    onChange={handleFileChange}
                                                                    id="file-input"
                                                                    style={{
                                                                        display: "none",
                                                                    }}
                                                                    accept={fileTypes.join(",")}
                                                                />
                                                                <label htmlFor="file-input" className="cursor-pointer">
                                                                    {/* <IconButton component="span"> */}

                                                                    {currentMode === "dark" ? (
                                                                        <img
                                                                            className={`mx-auto h-[30px]`}
                                                                            src="/images/common/attachment.svg"
                                                                            alt="circle"
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            className="mx-auto h-[30px] shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                                                                            src="/images/main/addfile-list.svg"
                                                                            alt="circle"
                                                                        />
                                                                    )}

                                                                    {/* </IconButton> */}
                                                                </label>
                                                            </Button>
                                                        ) : null}

                                                        <Button
                                                            onClick={handleDiscardReply}
                                                            className={`cursor-pointer px-3 py-[2px] border-[1px] rounded-[5px] border-20 border-${currentMode}-700`}>
                                                            Discard
                                                        </Button>
                                                        <Button
                                                            disabled={(toEmail?.length == 0 && inputValue?.length == 0) || data?.fetching}
                                                            onClick={() => sendReply("reply")}
                                                            className="bg-light-20 rounded-[5px] px-5 py-[3px] text-white">
                                                            Send
                                                        </Button>
                                                    </div>
                                                </div>
                                                <div>
                                                    <ul className="text-xs mb-5 ml-[12px]">
                                                        <li>From: {emailbody?.sender?.emailAddress?.address}</li>
                                                        <li>
                                                            Sent:{" "}
                                                            {moment(new Date()).format("DD/MM/YYYY") ==
                                                            moment(new Date(emailbody?.receivedDateTime)).format("DD/MM/YYYY")
                                                                ? emailbody?.receivedDateTime?.split("T")?.[1]
                                                                : emailbody?.receivedDateTime?.split("T")?.[0]}
                                                        </li>
                                                        <li>To: {"<" + toEmail?.join(";") + ">"}</li>
                                                        <li>Subject: {emailbody?.subject}</li>
                                                    </ul>
                                                </div>
                                            </>
                                        )}
                                        <div className="grid grid-cols-3 gap-2">
                                            {emailbody?.hasAttachments &&
                                                getAttachments?.map((item, i) => {
                                                    return (
                                                        <div
                                                            key={i}
                                                            className="rounded-[5px] flex items-center px-2 py-1 border-[1px] max-w-[150px] cursor-pointer  border-[rgba(223,225,226,0.56)]"
                                                            onClick={() => handleFileClick(item?.id)}>
                                                            <img src={dynamicImage(item?.contentType)} alt="image" />
                                                            <p className={`ml-2 text-${currentMode}-900 truncate`} title={item?.name}>
                                                                {item?.name}
                                                            </p>
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                        {taskBody?.length == 0 ? (
                                            <div
                                                className={`p-[15px] break-normal text-justify md:break-all max-w-[700px] xl:max-w-none html-container`}
                                                dangerouslySetInnerHTML={{
                                                    __html: emailReplace,
                                                }}
                                            />
                                        ) : (
                                            // <iframe
                                            //     title="Rendered HTML"
                                            //     style={{
                                            //         width: "100%",
                                            //     }}
                                            //     srcDoc={emailReplace}
                                            //      scrolling="no"
                                            //     // onload={resizeIframe(this)}
                                            // />
                                            <div className="my-4 ckreply ">
                                                {emailReplace && (
                                                    <CKEditor
                                                        onInstanceReady={handleEditorReady}
                                                        initData={emailReplace}
                                                        config={{
                                                            removeButtons: "Image,Paste,About,PasteText,PasteFromWord,Anchor",
                                                            removePlugins: "maximize",
                                                            readOnly: true,
                                                            height: 200,
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        )}

                                        {/* {ReactHtmlParser(normalString)} */}
                                    </div>
                                </>
                            )}
                        </div>
                    </Card>
                ) : (
                    <div className={`text-center h-[calc(100vh_-_20rem)] grid place-items-center place-content-center`}>
                        <div className="mx-auto my-5">
                            <img className="mx-auto" src="/images/common/delivery.png" alt="delivery" />
                        </div>
                        <span className="text-[15px] font-interM text-light-20">No Conversation is selected</span>
                        <p className="text-xs">Select an item to read</p>
                    </div>
                )}
            </div>
        );

    return (
        <Layout>
            {/* <WebSocketComponent /> */}
            <Main>
                <h4 className={`text-2xl font-interSb mb-5 text-${currentMode}-copy`}>Email</h4>
                {data?.requestComplete && data?.dataList?.status && <SuccessMessageBox success={"Mail sent is successfully"} />}
                {data?.requestComplete && data?.datalist?.status == false && <MessageBox error={"Mail not sent"} />}
                {!data?.requestComplete && data?.error?.length > 0 && <MessageBox error={"Mail not sent"} />}
                {requestCompTask && !postTask?.status && <MessageBox error={postTask?.message} />}
                {errorTask && !postTask?.status && <MessageBox error={"Something went wrong, contact your admin"} />}
                {requestCompTask && postTask?.status && <SuccessMessageBox success={"Task updated is successfully"} />}
                <Card className="px-[20px] flex justify-between relative">
                    <div
                        className={`xl:basis-[20%] ${
                            openFolder ? `opened` : ``
                        } tabSidebar space-y-4  xl:flex min-w-[230px] xl:h-[calc(100vh_-_12rem)] h-[calc(100vh_-_18rem)] overflow-y-auto py-[30px] xl:py-[20px] border-r-[1px] flex justify-between flex-col border-${currentMode}-700 mx-[-20px] px-[20px]`}>
                        <FolderSidebar
                            setReplyEnable={setReplyEnable}
                            setCompose={setCompose}
                            setOpenFolder={setOpenFolder}
                            setIsDraft={setIsDraft}
                            replyReset={replyReset}
                            compose={compose}
                            emailbody={emailbody}
                            isDraft={isDraft}
                            replyEnable={replyEnable}
                        />
                    </div>
                    <PrevEmail
                        isActive={isActive}
                        setIsActive={setIsActive}
                        openFolder={openFolder}
                        handleOpen={handleOpen}
                        compose={compose}
                        setOpenFolder={setOpenFolder}
                        setCompose={setCompose}
                        setIsDraft={setIsDraft}
                        isDraft={isDraft}
                        replyEnable={replyEnable}
                    />
                    <div
                        className={`basis-full xl:basis-[49%] overflow-hidden mobemailview 2xl:basis-full ${
                            compose || isActive !== "" ? "opencompose" : ``
                        } ${emailbody?.length === 0 && `ml-5 mt-[-1px] lg:border-l border-${currentMode}-700`} ${
                            compose || emailbody?.length !== 0 ? `m-[20px] mr-0` : ``
                        } mt-5`}>
                        {EmailContent}
                    </div>
                    {emailbody?.length !== 0 && taskBody?.length > 0 && !compose && (
                        <div className={`w-[8%] mt-5 ${isActive !== "" ? "" : "preview"}`}>
                            <ul className="space-y-3">
                                {replyEnable && (
                                    <Tooltip
                                        TransitionComponent={Zoom}
                                        title={"Attachment"}
                                        PopperProps={{
                                            sx: {
                                                "& .MuiTooltip-tooltip": {
                                                    background: "#DAF9F4",
                                                    color: "#555555",
                                                    borderRadius: "5px",
                                                    "& .MuiTooltip-arrow": {
                                                        top: "50% !important",
                                                        transform: "translateY(-50%) !important",

                                                        "&::before": {
                                                            backgroundColor: "#DAF9F4",
                                                        },
                                                    },
                                                },
                                            },
                                        }}
                                        placement="left-start"
                                        arrow>
                                        <li className="cursor-pointer">
                                            <span>
                                                <input
                                                    type="file"
                                                    multiple
                                                    onChange={handleFileChange}
                                                    id="file-input"
                                                    style={{
                                                        display: "none",
                                                    }}
                                                    accept={fileTypes.join(",")}
                                                />
                                                <label htmlFor="file-input">
                                                    {/* <IconButton component="span"> */}
                                                    {currentMode === "dark" ? (
                                                        <img className={`mx-auto`} src="/images/common/attachment.svg" alt="circle" />
                                                    ) : (
                                                        <img
                                                            className={`mx-auto shadow-[0px_4px_20px_rgba(238,238,238,0.5)] `}
                                                            src="/images/main/addfile-list.svg"
                                                            alt="circle"
                                                        />
                                                    )}
                                                    {/* </IconButton> */}
                                                </label>
                                            </span>
                                        </li>
                                    </Tooltip>
                                )}
                                {EMailShareLinks?.map((el, i) => {
                                    return (
                                        <Tooltip
                                            TransitionComponent={Zoom}
                                            title={el?.hovertext}
                                            PopperProps={{
                                                sx: {
                                                    "& .MuiTooltip-tooltip": {
                                                        background: "#DAF9F4",
                                                        color: "#555555",
                                                        borderRadius: "5px",
                                                        "& .MuiTooltip-arrow": {
                                                            top: "50% !important",
                                                            transform: "translateY(-50%) !important",

                                                            "&::before": {
                                                                backgroundColor: "#DAF9F4",
                                                            },
                                                        },
                                                    },
                                                },
                                            }}
                                            placement="left-start"
                                            arrow>
                                            <li className="cursor-pointer">
                                                <img
                                                    onClick={() => handleMoreOption(el?.hovertext, el)}
                                                    className="mx-auto shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                                                    src={
                                                        el?.hovertext === "Complete Task"
                                                            ? taskBody[0].task_status == 2
                                                                ? "/images/inbox/tick.svg"
                                                                : el?.img
                                                            : el?.img
                                                    }
                                                    alt="link-list"
                                                />
                                            </li>
                                        </Tooltip>
                                    );
                                })}
                            </ul>
                        </div>
                    )}
                </Card>
            </Main>
            <Footer />
        </Layout>
    );
};
