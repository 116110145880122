import axios from "axios";
import authHeader from "./auth-header";
import { checkApiStatus } from "../../utils/helpers";
import {
	GET_LIST_OF_EXCEPTION_CATEGORY_FAILURE,
	GET_LIST_OF_EXCEPTION_CATEGORY_SUCCESS,
	GET_LIST_OF_EXCEPTION_CATEGORY_REQUEST,
	ADDUPDATE_EXCEPTION_CATEGORY_REQUEST,
	ADDUPDATE_EXCEPTION_CATEGORY_SUCCESS,
	ADDUPDATE_EXCEPTION_CATEGORY_FAILURE,
	DELETE_EXCEPTION_CATEGORY_REQUEST,
	DELETE_EXCEPTION_CATEGORY_SUCCESS,
	DELETE_EXCEPTION_CATEGORY_FAILURE,
	CLONE_EXCEPTION_AUTOMATION_REQUEST,
	CLONE_EXCEPTION_AUTOMATION_SUCCESS,
	CLONE_EXCEPTION_AUTOMATION_FAILURE,
	EXCEPTION_AUTOMATION_VIEW_BYORG_REQUEST,
	EXCEPTION_AUTOMATION_VIEW_BYORG_SUCCESS,
	EXCEPTION_AUTOMATION_VIEW_BYORG_RESET,
	EXCEPTION_AUTOMATION_VIEW_BYORG_FAILURE,
	FROM_ORG_DROPDOWN_REQUEST,
	FROM_ORG_DROPDOWN_SUCCESS,
	FROM_ORG_DROPDOWN_FAILURE,
	EMAIL_GENERATE_EXCEPTION_SUCCESS,
	EMAIL_GENERATE_EXCEPTION_FAILURE,
	EMAIL_GENERATE_EXCEPTION_REQUEST,
	USER_EXCEPTION_DROPDOWN_REQUEST,
	USER_EXCEPTION_DROPDOWN_SUCCESS,
	USER_EXCEPTION_DROPDOWN_FAILURE,
	DELETE_ESCALATION_REQUEST,
	DELETE_ESCALATION_SUCCESS,
	DELETE_ESCALATION_FALURE,
} from "../types/types";

const API = process.env?.REACT_APP_NODE_API_URL;

//listemail template
export const getExceptionCategoryList = (orgId) => async (dispatch) => {
	try {
		dispatch({ type: GET_LIST_OF_EXCEPTION_CATEGORY_REQUEST });
		axios
			.get(`${API}api/ExceptionAutomation/ExceptionCategoryDropDown?org_id=${orgId}`, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: GET_LIST_OF_EXCEPTION_CATEGORY_SUCCESS,
					payload: response?.data?.data,
				});
			});
	} catch (error) {
		if (error?.response?.status == 401) {
			checkApiStatus();
		}
		dispatch({
			type: GET_LIST_OF_EXCEPTION_CATEGORY_FAILURE,
		});
	}
};

export const postExceptionCategory = (data) => async (dispatch) => {
	try {
		dispatch({ type: ADDUPDATE_EXCEPTION_CATEGORY_REQUEST });

		axios
			.post(`${API}api/ExceptionAutomation/AddUpdateException`, data, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: ADDUPDATE_EXCEPTION_CATEGORY_SUCCESS,
					payload: response?.data,
				});
			})
			.catch((ex) => {
				if (ex?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					type: ADDUPDATE_EXCEPTION_CATEGORY_FAILURE,
					payload: ex?.response?.data,
				});
			});
	} catch (error) {
		dispatch({
			type: ADDUPDATE_EXCEPTION_CATEGORY_FAILURE,
		});
	}
};
export const deleteExceptionCategory = (data) => async (dispatch) => {
	try {
		dispatch({ type: DELETE_EXCEPTION_CATEGORY_REQUEST });

		axios
			.post(`${API}api/ExceptionAutomation/DeleteException`, data, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: DELETE_EXCEPTION_CATEGORY_SUCCESS,
					payload: response?.data,
				});
			})
			.catch((ex) => {
				if (ex?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					type: DELETE_EXCEPTION_CATEGORY_FAILURE,
					payload: ex?.response?.data,
				});
			});
	} catch (error) {
		dispatch({
			type: DELETE_EXCEPTION_CATEGORY_FAILURE,
		});
	}
};
export const CloneExceptionAction = (data) => async (dispatch) => {
	try {
		dispatch({ type: CLONE_EXCEPTION_AUTOMATION_REQUEST });

		axios
			.post(`${API}api/ExceptionAutomation/CloneException`, data, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: CLONE_EXCEPTION_AUTOMATION_SUCCESS,
					payload: response?.data,
				});
			})
			.catch((ex) => {
				if (ex?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					type: CLONE_EXCEPTION_AUTOMATION_FAILURE,
					payload: ex,
				});
			});
	} catch (error) {
		dispatch({
			type: CLONE_EXCEPTION_AUTOMATION_FAILURE,
		});
	}
};

// view exceptiuon by org

//listemail template
export const viewExceptionCategoryByOrg = (orgId) => async (dispatch) => {
	try {
		dispatch({ type: EXCEPTION_AUTOMATION_VIEW_BYORG_REQUEST });
		axios
			.get(`${API}api/ExceptionAutomation/GetExceptionView?org_id=${orgId}`, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: EXCEPTION_AUTOMATION_VIEW_BYORG_SUCCESS,
					payload: response?.data?.data,
				});
			})
			.catch((ex) => {
				if (ex?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					type: EXCEPTION_AUTOMATION_VIEW_BYORG_FAILURE,
					payload: ex?.response?.data,
				});
			});
	} catch (error) {
		if (error?.response?.status == 401) {
			checkApiStatus();
		}
		dispatch({
			type: EXCEPTION_AUTOMATION_VIEW_BYORG_FAILURE,
		});
	}
};
export const GetFromOrgDropdownAct = (orgId) => async (dispatch) => {
	try {
		dispatch({ type: FROM_ORG_DROPDOWN_REQUEST });
		axios
			.get(`${API}api/ExceptionAutomation/GetOrgWithException`, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: FROM_ORG_DROPDOWN_SUCCESS,
					payload: response?.data?.data,
				});
			});
	} catch (error) {
		if (error?.response?.status == 401) {
			checkApiStatus();
		}
		dispatch({
			type: FROM_ORG_DROPDOWN_FAILURE,
		});
	}
};
export const ExceptionEmailGeneration = (data) => async (dispatch) => {
	try {
		dispatch({ type: EMAIL_GENERATE_EXCEPTION_REQUEST });

		axios
			.post(`https://api.dev.myiq.ai:8000/email_generation`, data)
			.then((response) => {
				dispatch({
					type: EMAIL_GENERATE_EXCEPTION_SUCCESS,
					payload: response?.data,
				});
			})
			.catch((ex) => {
				if (ex?.response?.status == 401) {
					checkApiStatus();
				}
				dispatch({
					type: EMAIL_GENERATE_EXCEPTION_FAILURE,
					payload: ex,
				});
			});
	} catch (error) {
		dispatch({
			type: EMAIL_GENERATE_EXCEPTION_FAILURE,
		});
	}
};
export const ExceptionUserDropdownAct = (data) => async (dispatch) => {
	try {
		dispatch({ type: USER_EXCEPTION_DROPDOWN_REQUEST });
		axios
			.post(`${API}api/getMsUsers`, data, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: USER_EXCEPTION_DROPDOWN_SUCCESS,
					payload: response?.data,
				});
			});
	} catch (error) {
		if (error?.response?.status == 401) {
			checkApiStatus();
		}
		dispatch({
			type: USER_EXCEPTION_DROPDOWN_FAILURE,
		});
	}
};
export const DeleteEscalationAct = (data) => async (dispatch) => {
	try {
		dispatch({ type: DELETE_ESCALATION_REQUEST });
		axios
			.post(`${API}api/ExceptionAutomation/DeleteEscalation`, data, {
				headers: authHeader(),
			})
			.then((response) => {
				dispatch({
					type: DELETE_ESCALATION_SUCCESS,
					payload: response?.data,
				});
			});
	} catch (error) {
		if (error?.response?.status == 401) {
			checkApiStatus();
		}
		dispatch({
			type: DELETE_ESCALATION_FALURE,
		});
	}
};
