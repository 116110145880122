import { useContext, useRef, useState } from "react";
import { useImmer } from "use-immer";
import { DndContext, DragOverlay, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";

import Announcements from "./AnnounceMents";
import Canvas, { Field } from "./FormCanvas";
import Sidebar, { SidebarField } from "./Sidebar";
import Layout from "../../Hoc/Layout";
import Main from "../../components/UI/Main";
import Footer from "../../organisms/Footer";
import PropertiesPanel from "./PropertiesPanel";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Button from "../../components/Button";
import { useLocation, useNavigate } from "react-router-dom";

function getData(prop) {
    return prop?.data?.current ?? {};
}

function createSpacer({ id }) {
    return {
        id,
        type: "spacer",
        title: "",
    };
}

export default function VendorOnboarding() {
    const { currentMode } = useContext(ThemeContext);
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                delay: 200, // delay in milliseconds for long-press
            },
        })
    );
    const [sidebarFieldsRegenKey, setSidebarFieldsRegenKey] = useState(Date.now());
    const spacerInsertedRef = useRef();
    const currentDragFieldRef = useRef();
    const [activeSidebarField, setActiveSidebarField] = useState(); // only for fields from the sidebar
    const [activeField, setActiveField] = useState(); // only for fields that are in the form
    const [data, updateData] = useImmer({
        fields: [],
    });
    const dispatch = useDispatch();
    const [selectedField, setSelectedField] = useState(null); // State to manage selected field
    const [columns, setColumns] = useState(2); // State to manage column layout

    const cleanUp = () => {
        setActiveSidebarField(null);
        setActiveField(null);
        currentDragFieldRef.current = null;
        spacerInsertedRef.current = false;
    };

    const handleDragStart = (e) => {
        const { active } = e;
        const activeData = getData(active);

        if (activeData.fromSidebar) {
            const { field } = activeData;
            const { type } = field;
            setActiveSidebarField(field);
            currentDragFieldRef.current = {
                id: active.id,
                ...field,
                name: `${type}${fields.length + 1}`,
                parent: null,
            };
            return;
        }

        const { field, index } = activeData;

        setActiveField(field);
        currentDragFieldRef.current = field;
        updateData((draft) => {
            draft.fields.splice(index, 1, createSpacer({ id: active.id }));
        });
    };

    const handleDragOver = (e) => {
        const { active, over } = e;
        const activeData = getData(active);
        if (activeData.fromSidebar) {
            const overData = getData(over);
            if (!spacerInsertedRef.current) {
                const spacer = createSpacer({
                    id: active.id + "-spacer",
                });

                updateData((draft) => {
                    if (!draft.fields.length) {
                        draft.fields.push(spacer);
                    } else {
                        const nextIndex = overData.index > -1 ? overData.index : draft.fields.length;

                        draft.fields.splice(nextIndex, 0, spacer);
                    }
                    spacerInsertedRef.current = true;
                });
            } else if (!over) {
                updateData((draft) => {
                    draft.fields = draft.fields.filter((f) => f.type !== "spacer");
                });
                spacerInsertedRef.current = false;
            } else {
                updateData((draft) => {
                    const spacerIndex = draft.fields.findIndex((f) => f.id === active.id + "-spacer");
                    const nextIndex = overData.index > -1 ? overData.index : draft.fields.length - 1;
                    if (nextIndex === spacerIndex) {
                        return;
                    }
                    draft.fields = arrayMove(draft.fields, spacerIndex, overData.index);
                });
            }
        }
    };

    const handleDragEnd = (e) => {
        const { over } = e;

        if (!over) {
            cleanUp();
            updateData((draft) => {
                draft.fields = draft.fields.filter((f) => f.type !== "spacer");
            });
            return;
        }

        let nextField = currentDragFieldRef.current;

        if (nextField) {
            const overData = getData(over);
            updateData((draft) => {
                // Remove all spacers if any
                draft.fields = draft.fields.filter((f) => f.type !== "spacer");
                if (overData.index !== undefined) {
                    // Insert the new field at the specific position
                    draft.fields.splice(overData.index, 0, nextField);
                } else {
                    // Add the new field at the end of the array
                    draft.fields.push(nextField);
                }
                dispatch({ type: "GET_PROPERTIES", payload: draft.fields }); //send dropped items json to redux...
            });
        }
        setSidebarFieldsRegenKey(Date.now());
        cleanUp();
    };

    const handleElementClick = (field) => {
        setSelectedField(field);
    };

    const { fields } = useSelector((state) => state?.vendorPropertiesState);
    const navigate = useNavigate();

    return (
        <Layout>
            <Main>
                <div className="flex justify-between items-center mb-6">
                    <h4 className="text-2xl font-interSb text-light-copy">Vendor Onboarding</h4>
                    <div className="flex space-x-[10px]">
                        <Button
                            className={`flex border items-center rounded-[5px] border-${currentMode}-840 py-[7px] px-[12px]`}
                            onClick={() => navigate("/vendor-onboard/preview")}>
                            <img className="mr-1" src="/images/vendor-onboard/eye.svg" alt="eye" /> Preview
                        </Button>
                        <Button
                            className={`bg-light-20 flex text-base font-interR py-[7px] px-[25px] rounded-[5px] text-white`}
                            onClick={() => navigate("/vendor-onboard/preview")}>
                            Publish
                        </Button>
                    </div>
                </div>
                <div className="flex space-x-[20px]">
                    <DndContext sensors={sensors} onDragStart={handleDragStart} onDragOver={handleDragOver} onDragEnd={handleDragEnd} autoScroll>
                        <Announcements />
                        <Sidebar fieldsRegKey={sidebarFieldsRegenKey} />
                        <SortableContext strategy={verticalListSortingStrategy} items={fields.map((f) => f.id)}>
                            <Canvas fields={fields} columns={columns} handleElementClick={handleElementClick} />
                        </SortableContext>
                        <DragOverlay dropAnimation={true}>
                            {activeSidebarField ? <SidebarField overlay field={activeSidebarField} /> : null}
                            {activeField ? <Field overlay field={activeField} /> : null}
                        </DragOverlay>
                        <div
                            className={`h-full space-y-[20px] border border-${currentMode}-700 rounded-[10px] max-h-[-webkit-fill-available] bg-${currentMode}-card ${"basis-[24%]"} xl:h-[calc(100vh_-_13rem)] h-[calc(100vh_-_18rem)] overflow-y-auto`}>
                            <PropertiesPanel
                                element={selectedField}
                                currentMode={currentMode}
                                dispatch={dispatch}
                                data={fields}
                                current={updateData}
                            />
                        </div>
                    </DndContext>
                </div>
            </Main>
            <Footer />
        </Layout>
    );
}
