import { useContext } from "react";
import Main from "../../components/UI/Main";
import Layout from "../../Hoc/Layout";
import Card from "../../components/UI/Card";
import Footer from "../../organisms/Footer";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import Button from "../../components/Button";
import { Link } from "react-router-dom";
import TogglePopup from "../organisations/Toggle";
import { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import FournotFour from "../404";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader/index.jsx";
import AddExceptionCategory from "./ExceptionCategory";
import { Accordion, Checkbox } from "@mui/material";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import EmailCategory from "./EmailCommunication/index";
import Preference from "./Preferences/index";
import AcknowledgementCard from "./Acknowledgement/index";
import EmailSettings from "./EmailSettings";
import CloneConfig from "./Clone";
import _ from "lodash";
import { EscalationReminder } from "./EscalationAndReminder/Reminder";
import {
    viewExceptionCategoryByOrg,
    deleteExceptionCategory,
    postExceptionCategory,
    ExceptionEmailGeneration,
    ExceptionUserDropdownAct,
} from "../../redux/actions/exception-automation.js";
import {
    EXCEPTION_AUTOMATION_VIEW_BYORG_RESET,
    DELETE_EXCEPTION_CATEGORY_RESET,
    ADDUPDATE_EXCEPTION_CATEGORY_RESET,
    EMAIL_GENERATE_EXCEPTION_RESET,
} from "../../redux/types/types.js";
import Swal from "sweetalert2";
import SuccessMessageBox from "../../components/UI/Alert/sucess";
import MessageBox from "../../components/UI/Alert";
import { v4 as uuidv4 } from "uuid";
import { extractTextFromHTML } from "../../utils/helpers";
import moment from "moment";

const ErrorFallback = ({ error }) => {
    return (
        <>
            <FournotFour />
        </>
    );
};
const ExceptionAutomation = () => {
    const orgId = localStorage.getItem("orgId");
    const [elId, setId] = useState(null);
    const { data, fetching, requestComplete, error } = useSelector((state) => state?.exceptionCategoryViewByOrgState);
    const UsersData = useSelector((state) => state?.ExceptionUserDropdownState);
    const UsersList = UsersData?.data?.data;
    const UserListFilter = UsersList?.value?.length == 0 || !UsersList ? [] : UsersList?.filter((el) => el?.mail !== null);
    const UsersOptions = UserListFilter?.map((el) => el?.mail);
    const {
        data: postData,
        fetching: postFetching,
        requestComplete: postReqComp,
        error: postErr,
    } = useSelector((state) => state?.postExceptionCategoryState);
    const {
        data: delData,
        fetching: delFetch,
        requestComplete: delReqConp,
        error: delErr,
    } = useSelector((state) => state?.deleteExceptionCategoryState);
    const EmailGenerate = useSelector((state) => state?.EmailGenerateExceptionState);
    const EmailGenerateData = EmailGenerate?.data?.email?.mail_body;
    const EmailGenearteSub = EmailGenerate?.data?.email?.subject;
    useEffect(() => {
        if ((postData?.status && postReqComp) || (postErr && postReqComp)) {
            Swal.fire({
                allowOutsideClick: false,
                showCloseButton: true,
                text: postData?.status ? "Exception Saved Successfully !" : postErr?.message,
                icon: postData?.status ? "success" : "warning",
                button: "Okay",
            }).then((confirm) => {
                if (confirm) {
                    if (postData?.status) {
                        window.location.reload();
                    } else {
                        dispatch({ type: ADDUPDATE_EXCEPTION_CATEGORY_RESET });
                    }
                }
            });
        }
    }, [postReqComp]);
    const [excepByOrg, setExcepByOrg] = useState();
    const [profile, setProfile] = useState([]);
    const defaultObj = {
        email_address: "",
        email_type: 0,
        display_endtime: moment("Tue Jul 09 2024 18:00:00 GMT+0530 (India Standard Time)").format("YYYY-MM-DDTHH:mm:ss"),
        isworking_days: true,
        isworking_hours: true,
        // org_id: +orgId,
        display_starttime: moment("Tue Jul 09 2024 09:00:00 GMT+0530 (India Standard Time)").format("YYYY-MM-DDTHH:mm:ss"),
        username: "",
        working_days_array: [2, 3, 4, 5, 6],
    };
    const [businessSetting, setBusinessSetting] = useState(defaultObj);
    const [categoryArr, setCategoryArr] = useState([]);

    //* Variable Decleartion
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const [statustype, setStatusType] = useState(1);
    const [expandAck, setExpandAck] = useState("0");
    useEffect(() => {
        if (delReqConp && !delData?.status) {
            setTimeout(() => {
                dispatch({ type: DELETE_EXCEPTION_CATEGORY_RESET });
            }, 1200);
        } else if (delReqConp && delData?.status) {
            setTimeout(() => {
                window.location.reload();
            }, 1200);
        }
    }, [delReqConp]);
    const filterMenuList = [
        {
            text: "View",
            title: "View",
            src: currentMode == "dark" ? "/images/Exception-Automation/eye-exp-auto-dark.svg" : "/images/Exception-Automation/eye.svg",
            tabIndex: "0",
            className: "convertWhite",
            value: 1,
        },
        {
            text: "Add Child",
            title: "Add Child",
            src:
                currentMode == "dark" ? "/images/Exception-Automation/noun-plus-exp-auto-dark.svg" : "/images/Exception-Automation/plusiconblack.svg",
            tabIndex: "",
            className: "convertWhite",
            value: 2,
        },
        {
            text: "Delete",
            title: "Delete",
            src:
                currentMode == "dark" ? "/images/Exception-Automation/delete-exp-auto-dark.svg" : "/images/Exception-Automation/delete-exception.svg",
            tabIndex: "2",
            className: "convertWhite",
            value: 3,
        },
    ];
    const childMenuList = [
        {
            text: "View",
            title: "View",
            src: currentMode == "dark" ? "/images/Exception-Automation/eye-exp-auto-dark.svg" : "/images/Exception-Automation/eye.svg",
            tabIndex: "0",
            className: "convertWhite",
            value: 1,
        },
        {
            text: "Delete",
            title: "Delete",
            src:
                currentMode == "dark" ? "/images/Exception-Automation/delete-exp-auto-dark.svg" : "/images/Exception-Automation/delete-exception.svg",
            tabIndex: "2",
            className: "convertWhite",
            value: 3,
        },
    ];
    const [isView, setIsView] = useState(false);
    const [isFilter, setIsFilter] = useState(false);
    const onFilterItems = (value, id) => {
        const { id: exId, index, childIndex, type } = id;
        // const currentObjId = value === 1 ? id?.outside_id : id?.inside_id;
        const currentObjId = id?.outside_id ? id?.outside_id : id?.inside_id;
        setCurrentId(currentObjId);
        switch (value) {
            case 1:
                // Handle view and set the selected status type
                setIsView(true);
                setIsFilter(true);
                setStatusType(type);
                if (type === 1) {
                    const findObj = categoryArr?.find((ele) => ele?.outside_id === id?.outside_id);
                    if (findObj) {
                        setSelectedId(findObj);
                    }
                    const updatedCategoryArr = _.map(categoryArr, (category, i) => {
                        if (i === index) {
                            return {
                                ...category,
                                is_active: true,
                                child_exception: _.map(category.child_exception, (child) => ({ ...child, is_active: false })),
                            };
                        } else {
                            return {
                                ...category,
                                is_active: false,
                                child_exception: _.map(category.child_exception, (child) => ({ ...child, is_active: false })),
                            };
                        }
                    });
                    setCategoryArr(updatedCategoryArr);
                }
                // Handle the case for type 2
                else if (type === 2) {
                    const findObj = categoryArr?.find((ele, i) => +i === +index);
                    if (findObj) {
                        const currentObj = findObj?.child_exception?.find((item) => item?.inside_id === id?.inside_id);
                        setSelectedId(currentObj);
                    }
                    if (type === 2) {
                        const findObj = _.find(categoryArr, (ele, i) => +i === +index);
                        if (findObj) {
                            const currentObj = _.find(findObj.child_exception, (item) => item?.inside_id === id?.inside_id);
                            if (currentObj) {
                                setSelectedId(currentObj);
                            }
                            const updatedCategoryArr = _.map(categoryArr, (category, i) => {
                                if (+i === +index) {
                                    const updatedChildExceptions = _.map(category.child_exception, (child, ind) => {
                                        return { ...child, is_active: ind === +childIndex };
                                    });
                                    return { ...category, is_active: false, child_exception: updatedChildExceptions };
                                } else {
                                    return {
                                        ...category,
                                        is_active: false,
                                        child_exception: _.map(category.child_exception, (child) => ({ ...child, is_active: false })),
                                    };
                                }
                            });
                            setCategoryArr(updatedCategoryArr);
                        }
                    }
                }
                if (id?.el?.ismail_auto_generate) {
                    dispatch(
                        ExceptionEmailGeneration({
                            exception: id?.exception_name,
                            email_size: "small",
                            task: "0"
                        })
                    );
                } else {
                    dispatch({ type: EMAIL_GENERATE_EXCEPTION_RESET });
                }
                break;
            case 2:
                // Handle the creation of a new child exception
                const findObj = categoryArr?.find((ele, i) => +i === +index);
                if (findObj?.child_exception?.length >= 15) {
                    Swal.fire({
                        allowOutsideClick: false,
                        showCloseButton: true,
                        text: "Unable to create a child category greater than 15!",
                        icon: "warning",
                        button: "Okay",
                    });
                } else {
                    setStatusType(value);
                    handleOpenExceptionModal(value, currentObjId, +index);
                }
                break;
            case 3:
                // Handle the deletion of an exception category
                Swal.fire({
                    allowOutsideClick: false,
                    showCancelButton: true,
                    showCloseButton: true,
                    text: "Are you sure you want to delete the exception category?",
                    icon: "warning",
                    buttons: {
                        cancel: "Cancel",
                        confirm: "Confirm",
                    },
                }).then((confirm) => {
                    if (confirm?.isConfirmed) {
                        if (exId !== 0) {
                            const reqObj = {
                                exception_id: exId,
                                org_id: +orgId,
                            };
                            dispatch(deleteExceptionCategory(reqObj));
                        } else {
                            Swal.fire({
                                text: `Deleted Successfully!`,
                                closeOnClickOutside: false,
                                icon: "success",
                                showCloseButton: true,
                                buttons: {
                                    confirm: "Confirm",
                                },
                            })
                            if (type === 1) {
                                // Delete the main exception category
                                const filteredArr = categoryArr?.filter((item) => item?.outside_id !== id?.outside_id);
                                setCategoryArr(filteredArr);
                            } else if (type === 2) {
                                // Delete the child exception category
                                const findObj = categoryArr?.find((ele, i) => +i === +index);
                                if (findObj) {
                                    const filteredChildExceptions = findObj.child_exception?.filter((item) => item?.inside_id !== id?.inside_id);
                                    const updatedCategoryArr = categoryArr.map((category, i) => {
                                        if (+i === +index) {
                                            return {
                                                ...category,
                                                child_exception: filteredChildExceptions,
                                            };
                                        }
                                        return category;
                                    });
                                    setCategoryArr(updatedCategoryArr);
                                }
                            }
                        }
                    }
                });
                break;
            default:
                break;
        }
    };
    const addIsActive = (obj) => {
        obj.is_active = false;
        if (obj.child_exception) {
            obj.child_exception.forEach((child) => addIsActive(child));
        }
        return obj;
    };

    const [selectedId, setSelectedId] = useState(null);
    const [currentId, setCurrentId] = useState(null);
    const dispatch = useDispatch();
    const [workflowData, setWorkFlowData] = useState();
    const [openCategory, setOpenCategory] = useState(false);
    const [openClone, setOpenClone] = useState(false);
    const tokenIfo = profile?.tokenInfo;
    const accessToken = tokenIfo ? tokenIfo?.access_token : "";
    const refreshToken = tokenIfo ? tokenIfo?.refresh_token : "";

    const reqBody = {
        org_id: +orgId,
        emailtype: businessSetting?.email_type,
        accesstoken: accessToken,
        refreshtoken: refreshToken,
    };
    useEffect(() => {
        if (orgId) {
            dispatch({ type: EXCEPTION_AUTOMATION_VIEW_BYORG_RESET });
            setBusinessSetting(defaultObj);
            setExpandEmailSet("0");
            setExpandPref("0");
            setCategoryArr([]);
            setExcepByOrg({});
            setSelectedId({});
            setIsView(false);
            dispatch(ExceptionUserDropdownAct(reqBody));
            dispatch(viewExceptionCategoryByOrg(orgId));
        }
    }, [orgId]);
    useEffect(() => {
        if (businessSetting?.email_type > 0) {
            dispatch(ExceptionUserDropdownAct(reqBody));
        }
    }, [accessToken, refreshToken, businessSetting?.email_type]);
    useEffect(() => {
        if (data && requestComplete && orgId) {
            if (data?.exception_info && data?.exception_info?.length > 0) {
                const updatedArr = data?.exception_info.map((obj, index) => {
                    if (index === 0) {
                        setId(obj?.exception_id);
                    }
                    obj.is_active = false;
                    obj.outside_id = uuidv4();
                    if (obj.child_exception) {
                        obj.child_exception.forEach((child) => ((child.is_active = false), (child.inside_id = uuidv4())));
                    }
                    return obj;
                });
                setCategoryArr(updatedArr);
            } else {
                setCategoryArr([]);
            }
            if (data?.bussiness_settings) {
                setBusinessSetting(data?.bussiness_settings);
                setExcepByOrg(data);
            }
        } else if (orgId && !requestComplete) {
            setBusinessSetting(defaultObj);
            setExpandPref("0");
            setExpandEmailSet("0");
            const obj = { exception_info: [], bussiness_settings: businessSetting };
            setExcepByOrg(obj);
            setCategoryArr([]);
        }
    }, [data, orgId]);
    //* Static Variable --> Change this state with reducer
    const sortedWorkflow = _.sortBy(workflowData?.work_flow, "disp_order");
    const [check, setCheck] = useState(sortedWorkflow);
    const [noActiveParams, setActiveParams] = useState(false);

    //* Variables for Notification
    const [receivers, setReceivers] = useState([]);
    const [bccreceivers, setBccRecievers] = useState([]);
    const [notificationenable, setnotificationEnable] = useState(false);
    const [Title, setTitle] = useState();
    const [message, setMessage] = useState("");
    const [Emailenable, setemailEnable] = useState();
    const [subject, setSubject] = useState("");
    const [emailMessage, setEmailmessage] = useState();
    const [reset, setReset] = useState(false);

    const [expandMe, setExpandMe] = useState(1);
    const handleChangeMe = (panel) => (event, newExpanded) => {
        setExpandMe(newExpanded ? panel : false);
    };

    const [expandPref, setExpandPref] = useState("0");
    const handleChangePref = (panel) => (event, newExpanded) => {
        setExpandPref(newExpanded ? panel : false);
    };
    const [expandEmailSet, setExpandEmailSet] = useState("0");
    const handleChangeEmailSet = (panel) => (event, newExpanded) => {
        setExpandEmailSet(newExpanded ? panel : false);
    };
    const parentCompRef = useRef();
    const childCompRef = useRef();
    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary expandIcon={<img src="/images/header/chevronDown.svg" className="convertWhite" alt="accordion-active" />} {...props} />
    ))(({ theme }) => ({
        padding: "11px 30px",
        margin: 0,
        // flexDirection: "row-reverse",
        "& .MuiAccordionSummary-expandIconWrapper": {
            marginRight: 10,
            transform: "rotate(270deg)",
            filter: `${currentMode === "dark" ? "filter: brightness(0) invert(1)" : "brightness(0) invert(0)"}`,
        },
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(360deg)",
            filter: "none",
            minHeight: "0px",
            margin: "0px",
        },
        "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: 0,
        },
        "& .MuiAccordionSummary-content": {
            margin: 0,
        },
        "& .MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded": {
            minHeight: 0,
        },
    }));
    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: 0,
        border: 0,
    }));

    const [folders, setFolders] = useState([
        {
            id: 1,
            name: "Folder 1",
            children: [
                {
                    id: 2,
                    name: "Subfolder 1",
                    children: [
                        {
                            id: 4,
                            name: "File 1",
                        },
                        {
                            id: 5,
                            name: "File 2",
                        },
                    ],
                },
                {
                    id: 3,
                    name: "Subfolder 2",
                    children: [
                        {
                            id: 6,
                            name: "File 3",
                        },
                    ],
                },
            ],
        },
    ]);
    const toggleFolder = (id) => {
        setFolders((prevFolders) => prevFolders.map((folder) => (folder.id === id ? { ...folder, isOpen: !folder.isOpen } : folder)));
    };

    const renderFolder = (folder) => {
        return (
            <li key={folder.id}>
                <span className={`cursor-pointer ${folder.isOpen ? "rotate-90" : "rotate-0"} mr-2`} onClick={() => toggleFolder(folder.id)}>
                    &#9658;
                </span>
                <span className="folder">{folder.name}</span>
                {folder.isOpen && <ul>{folder.children.map((child) => (child.children ? renderFolder(child) : renderFile(child)))}</ul>}
            </li>
        );
    };
    const renderFile = (file) => {
        return (
            <li key={file.id}>
                <span className="file">{file.name}</span>
            </li>
        );
    };
    const [isChild, setIsChild] = useState(false);
    const TreeNode = ({ isActive, id, label, index, childIndex, statusType, inside_id, exception_name, el, children }) => {
        return (
            <>
                <li>
                    <div
                        onClick={(e) =>
                            parentCompRef.current.handleClick("bottom-end", 1, e, {
                                id: id,
                                index,
                                childIndex,
                                type: statusType,
                                inside_id: inside_id,
                                exception_name: exception_name,
                                el: el,
                            }, setIsChild(true))
                        }
                        className={`flex items-center cursor-pointer justify-between ${isActive ? `bg-${currentMode}-1430 border border-${currentMode}-1440` : `bg-transparent`
                            } w-[200px]`}
                    >
                        {label}

                        <Button
                            // onClick={(e) =>
                            //     childCompRef.current.handleClick("bottom-end", 1, e, {
                            //         id: id,
                            //         index: index,
                            //         childIndex: childIndex,
                            //         type: statusType,
                            //         inside_id: inside_id,
                            //     })
                            // }
                            onClick={(e) =>
                                parentCompRef.current.handleClick("bottom-end", 1, e, {
                                    id: id,
                                    index,
                                    childIndex,
                                    type: statusType,
                                    inside_id: inside_id,
                                    exception_name: exception_name,
                                    el: el,
                                }, setIsChild(true))
                            }
                            className={`ml-[33px]`}
                            tabIndex={0}
                        >
                            <img
                                src="/images/Exception-Automation/threedotsbalck.svg"
                                alt="threedots-black"
                                className="convertWhite mb-[-4px] absolute right-[15px] bottom-[37px]"
                            />
                        </Button>
                    </div>

                    {children && <ul>{children}</ul>}
                </li>
            </>
        );
    };
    const OpenCloneFunc = () => {
        setOpenClone(true);
    };
    const handleOpenExceptionModal = (type, currentObjId, index) => {
        if (type == 2) {
            const findObj = categoryArr?.find((ele, ind) => +ind === +index);
            setStatusType(2);
            if (findObj) {
                setSelectedId(findObj);
            }
            setOpenCategory(true);
        } else if (type == 1) {
            const findObj = categoryArr?.find((ele) => ele?.outside_id === currentObjId);
            setStatusType(1);
            if (findObj) {
                setSelectedId(findObj);
            }
            setOpenCategory(true);
        }
    };
    // const [additionalParameters, setAdditionalParameters] = useState();

    const FindEmptyEmailJson = categoryArr?.filter((el) => el?.email_json == "");

    let emptyEmailToReminders = [];
    let EscFromDate = [];
    let IsFrequency = [];

    categoryArr?.forEach((exception) => {
        exception?.escalation_remainder?.forEach((reminder) => {
            if (reminder?.email_to?.length === 0) {
                emptyEmailToReminders?.push(reminder);
            }
            if (!reminder?.esc_from_date || !reminder?.esc_to_date) {
                EscFromDate?.push(reminder);
            }
            if (reminder?.isfrequency == true && reminder?.esc_frequency == 0) {
                IsFrequency?.push(reminder);
            }
        });
    });
    const handleAddUpdate = () => {
        const tokenIfo = profile?.tokenInfo;
        const additionalParameters = {
            access_token: tokenIfo ? tokenIfo?.access_token : "",
            refresh_token: tokenIfo ? tokenIfo?.refresh_token : "",
            subscription_id: tokenIfo?.subscription_id ? tokenIfo?.subscription_id : "", //O-365
            expire_date: tokenIfo?.expire_date ? tokenIfo?.expire_date : "", //O-365
            isSync: profile?.isSync || false,
            // id: 0,
        };
        const updatedObject = { ...businessSetting, ...additionalParameters };

        updatedObject.working_days_array = JSON.stringify(updatedObject.working_days_array);
        const removeIsActive = (obj) => {
            const newObj = _.omit(obj, "is_active");
            if (newObj.child_exception && newObj.child_exception.length > 0) {
                newObj.child_exception = newObj.child_exception.map(removeIsActive);
            }
            return newObj;
        };

        const updatedExceptionObject = categoryArr.map(removeIsActive);

        const showAlert = (text) => {
            Swal.fire({
                allowOutsideClick: false,
                showCloseButton: true,
                text,
                icon: "warning",
                button: "Okay",
            }).then((confirm) => {
                if (confirm) {
                    return false;
                }
            });
        };
        if (updatedExceptionObject?.length > 0) {
            // if (!updatedObject?.isworking_days || !updatedObject?.isworking_hours) {
            //     return showAlert("You need to configure working days and hours in preferences");
            // }
            if (updatedObject?.isworking_hours && (!updatedObject?.display_starttime || !updatedObject?.display_endtime)) {
                return showAlert("You need to fill in the start and end times of working hours");
            }
            if (updatedObject?.isworking_hours && updatedObject?.display_starttime === updatedObject?.display_endtime) {
                return showAlert("Start and end time cannot be same!");
            }
            if (JSON.parse(updatedObject?.working_days_array)?.length === 0) {
                return showAlert("You need to select days on working days");
            }
            if (FindEmptyEmailJson?.length > 0) {
                return showAlert("You need to configure the email communication");
            }
            if (EscFromDate?.length > 0) {
                return showAlert(
                    `Fill in the Days  for the following ${EscFromDate?.length > 1 ? "Reminders" : "Reminder"} : ${EscFromDate?.map(
                        (el) => el?.reminder_name
                    )} `
                );
            }
            if (emptyEmailToReminders?.length > 0) {
                return showAlert(
                    `You need to configure the email configuration  for the following ${emptyEmailToReminders?.length > 1 ? "Reminders" : "Reminder"
                    } : ${emptyEmailToReminders?.map((el) => el?.reminder_name)}`
                );
            }
            if (IsFrequency?.length > 0) {
                return showAlert(
                    `You need to fill the frequency in hours for the following ${IsFrequency?.length > 1 ? "Reminders" : "Reminder"
                    } : ${IsFrequency?.map((el) => el?.reminder_name)} `
                );
            }

            // if (!updatedObject?.email_address && !updatedObject?.username) {
            //     return showAlert("Email settings configuration requires to fill!");
            // }
            // if (!updatedObject?.email_address ||  !updatedObject?.username) {
            //     return showAlert("Email settings configuration requires both username and email address");
            // }
            function updateAckEmailJson(data) {
                const shouldClearAckEmailJson = (ackEmailJson) => {
                    return (
                        (ackEmailJson?.to?.length === 0 &&
                            ackEmailJson?.cc?.length === 0 &&
                            ackEmailJson?.Sub === "" &&
                            ackEmailJson?.body === "<p><br></p>") ||
                        (ackEmailJson?.to?.length === 0 &&
                            ackEmailJson?.Sub === "" &&
                            ackEmailJson?.body === "<p><br></p>") ||
                        (!ackEmailJson?.to &&
                            !ackEmailJson?.Sub &&
                            ackEmailJson?.body === "<p><br></p>") ||
                        (ackEmailJson?.to == 0 && !ackEmailJson?.Sub && !ackEmailJson?.body) ||
                        (!ackEmailJson?.to &&
                            (ackEmailJson?.cc?.length == 0 || ackEmailJson?.bcc?.length == 0) &&
                            !ackEmailJson?.Sub &&
                            ackEmailJson?.body)
                    );
                };

                const processItem = (item) => {
                    if (shouldClearAckEmailJson(item.ack_email_json)) {
                        item.ack_email_json = "";
                    }
                    if (item.child_exception?.length > 0) {
                        item.child_exception.forEach(processItem);
                    }
                };

                data?.forEach(processItem);
                return data;
            }


            const updatedData = updateAckEmailJson(updatedExceptionObject);
            const reqObj = {
                org_id: +orgId,
                bussiness_settings: updatedObject,
                ExceptionHeaderAddUpdate: updatedData,
            };

            dispatch(postExceptionCategory(reqObj));
        }
    };
    const FindSelectName = selectedId?.exception_name;
    useEffect(() => {
        if (selectedId?.ismail_auto_generate == true && EmailGenerate?.requestComplete && EmailGenerateData && isFilter) {
            setSelectedId((prevVal) => ({

                ...prevVal,

                email_json: {
                    ...prevVal?.email_json,
                    body: (!prevVal?.email_json?.body || prevVal?.email_json?.body == "<p><br></p>") ? EmailGenerateData : prevVal?.email_json?.body,
                    Sub: !prevVal?.email_json?.Sub ? EmailGenearteSub : prevVal?.email_json?.Sub,
                },
                ack_email_json: {
                    ...prevVal?.ack_email_json,
                    body: (!prevVal?.ack_email_json?.body || prevVal?.ack_email_json?.body == "<p><br></p>") ? EmailGenerateData : prevVal?.ack_email_json?.body,
                    Sub: !prevVal?.ack_email_json?.Sub ? EmailGenearteSub : prevVal?.ack_email_json?.Sub,
                },
            }));
            setCategoryArr((prevCheck) => {
                return prevCheck.map((ele) => {
                    // Check and update the parent object
                    if (statustype == 1 && ele?.outside_id === currentId) {
                        return {
                            ...ele,
                            email_json: {
                                ...ele?.email_json,
                                body: (!ele?.email_json?.body || ele?.email_json?.body == "<p><br></p>") ? EmailGenerateData : ele?.email_json?.body,
                                Sub: !ele?.email_json?.Sub ? EmailGenearteSub : ele?.email_json?.Sub,
                            },
                            ack_email_json: {
                                ...ele?.ack_email_json,
                                body: (!ele?.ack_email_json?.body || ele?.ack_email_json?.body == "<p><br></p>") ? EmailGenerateData : ele?.ack_email_json?.body,
                                Sub: !ele?.ack_email_json?.Sub ? EmailGenearteSub : ele?.ack_email_json?.Sub,
                            },
                        };
                    }

                    // Check and update the child exceptions
                    if (ele?.child_exception) {
                        const updatedChildExceptions = ele?.child_exception?.map((child) => {
                            if (statustype == 2 && child?.inside_id === currentId) {
                                return {
                                    ...child,
                                    email_json: {
                                        ...child?.email_json,
                                        body: (!child?.email_json?.body || child?.email_json?.body == "<p><br></p>") ? EmailGenerateData : child?.email_json?.body,
                                        Sub: !child?.email_json?.Sub ? EmailGenearteSub : child?.email_json?.Sub,
                                    },
                                    ack_email_json: {
                                        ...child?.ack_email_json,
                                        body: (!child?.ack_email_json?.body || child?.ack_email_json?.body == "<p><br></p>") ? EmailGenerateData : child?.ack_email_json?.body,
                                        Sub: !child?.ack_email_json?.Sub ? EmailGenearteSub : child?.ack_email_json?.Sub,
                                    },
                                };
                            }
                            return child;
                        });

                        return {
                            ...ele,
                            child_exception: updatedChildExceptions,
                        };
                    }

                    return ele;
                });
            });

            setTimeout(() => {
                dispatch({ type: EMAIL_GENERATE_EXCEPTION_RESET });
                setIsFilter(false);
            }, 3000);
        }
    }, [EmailGenerate?.requestComplete, isFilter]);
    const detailsRef = useRef(null);
    console.log(parentCompRef.current, "parentCompRef.current.");
    const OpenToggle = (e, obj, bottom) => {
        if (bottom) {
            parentCompRef.current.handleClick(bottom, 1, e, obj);
        }
        setIsChild(false)
    }

    return (
        <>
            <Layout>
                {delData && delData.status && <SuccessMessageBox success={delData?.message} />}
                {delErr && !delData.status && <MessageBox error={delData?.message} />}
                {(fetching || delFetch || postFetching) && (
                    <div className="h-screen overflow-hidden flex bg-[rgb(231,225,225,0.5)] absolute top-0 z-[12] left-0 right-0 justify-center items-center w-full">
                        {" "}
                        <img className="w-[7rem]" src="/images/gif/Loading-Gif.gif" alt="Loading-Gif" />
                    </div>
                )}
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Main>
                        <div className="mb-5 flex justify-between">
                            <div>
                                <h4 className={`text-2xl font-interSb text-${currentMode}-copy`}>Exception Automations</h4>
                            </div>
                            <div className=" flex text-right space-x-3">
                                <Button onClick={OpenCloneFunc} className="bg-light-240 text-light-40 text-base rounded-[8px] py-[8px] px-[8px]">
                                    <img src="/images/Exception-Automation/clone.svg"></img>
                                </Button>
                                <Link tabIndex={0} to="/admin-setting" title="Cancel">
                                    {" "}
                                    <Button className="bg-light-240 text-light-40 text-base rounded-[8px]  py-2 px-5">Cancel</Button>
                                </Link>
                                <Button
                                    tabIndex={0}
                                    disabled={categoryArr?.length == 0}
                                    className="bg-light-20 text-white text-base rounded-[8px] py-2 px-7"
                                    onClick={handleAddUpdate}
                                    title={"Save"}
                                >
                                    {"Save"}
                                </Button>
                            </div>
                        </div>
                        {check && (
                            <div className="xl:flex xl:space-x-3">
                                <Card
                                    ref={detailsRef}
                                    className={` basis-[55%] bg-${currentMode}-card bg-[size:12px_12px] xl:p-[30px] p-4 border-[1px]  border-[rgba(223,225,226,0.56)] xl:h-[calc(100vh_-_13rem)] h-[calc(100vh_-_17rem)] overflow-y-auto xl:overflow-x-hidden  ${currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                                        }`}
                                    style={{
                                        backgroundColour: "#FCFCFC",
                                    }}
                                >
                                    <div className="flex-col flex">
                                        <span className=" font-interM text-base font-medium  text-left text-[18px] mb-2">Exception Categories</span>
                                        <span className="text-[12px]">
                                            You can select from the list of default exception category or create a new exception category applicable
                                            to your ERP systems.
                                        </span>
                                        <div className={`mt-2 space-y-4`}>
                                            {categoryArr?.map((item, index) => {
                                                return (
                                                    <>
                                                        <Accordion
                                                            key={index}
                                                            expanded={expandMe == index + 1}
                                                            onChange={handleChangeMe(index + 1)}
                                                            onClick={() => setId(item?.exception_id)}
                                                            sx={{
                                                                "&.MuiPaper-root": {
                                                                    boxShadow:
                                                                        currentMode == "dark"
                                                                            ? ""
                                                                            : "0px 4px 10px -3px rgb(140 144 151 / 50%) !important",

                                                                    border: `1px solid ${currentMode == "dark" ? "#333" : "rgba(223, 225, 226, 0.56)"
                                                                        } !important`,
                                                                },
                                                                "& .css-c89bj3-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded ": {
                                                                    minHeight: "0px !important",
                                                                },
                                                                "&.MuiAccordion-root:before": {
                                                                    display: "none !important",
                                                                },
                                                            }}
                                                        >
                                                            <AccordionSummary
                                                                aria-controls="panel1d-content"
                                                                id="panel1d-header"
                                                                className={`bg-${currentMode}-1420`}
                                                            >
                                                                <span
                                                                    title={item?.exception_name}
                                                                    className={`font-interM text-${currentMode}-copy max-w-[600px] truncate`}
                                                                >
                                                                    {item?.exception_name}
                                                                </span>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <div className="grid grid-cols-1 pl-[20px] ">
                                                                    <div
                                                                        onClick={(e) =>
                                                                            parentCompRef.current.handleClick("bottom-end", 1, e, {
                                                                                id: item?.id,
                                                                                index,
                                                                                index,
                                                                                type: item?.exception_type,
                                                                                outside_id: item?.outside_id,
                                                                                exception_name: item?.exception_name,
                                                                                el: item,
                                                                            }, setIsChild(false))
                                                                        }

                                                                        className={`w-[250px] h-search cursor-pointer rounded-lg ${item?.is_active
                                                                            ? `bg-${currentMode}-1430 border border-${currentMode}-1440`
                                                                            : "border border-[#8C9097]"
                                                                            }  py-[10px] px-[10px] flex justify-between mt-8`}
                                                                    >
                                                                        <p className="break-all w-[220px]">{item?.exception_name}</p>
                                                                        <Button
                                                                            // onClick={(e) =>
                                                                            //     parentCompRef.current.handleClick("bottom", 1, e, {
                                                                            //         id: item?.id,
                                                                            //         index,
                                                                            //         index,
                                                                            //         type: item?.exception_type,
                                                                            //         outside_id: item?.outside_id,
                                                                            //         exception_name: item?.exception_name,
                                                                            //         el: item,
                                                                            //     })
                                                                            // }
                                                                            onClick={(e) => OpenToggle(e, {
                                                                                id: item?.id,
                                                                                index,
                                                                                index,
                                                                                type: item?.exception_type,
                                                                                outside_id: item?.outside_id,
                                                                                exception_name: item?.exception_name,
                                                                                el: item,
                                                                            }, "bottom")}
                                                                            className={`px-2 userMore-step `}
                                                                        >
                                                                            <img
                                                                                src="/images/Exception-Automation/threedotsbalck.svg"
                                                                                alt="threedots-black"
                                                                                className="convertWhite"
                                                                            />
                                                                        </Button>
                                                                    </div>
                                                                    {item?.child_exception && item?.child_exception?.length !== 0 ? (
                                                                        <div className="flex">
                                                                            <div>
                                                                                <ul
                                                                                    className={`tree ${currentMode == "dark" ? "tree-dark" : ""} ${item?.child_exception?.length == 1 ? "tree-before" : ""
                                                                                        }  ${item?.child_exception?.length == 1 ? "tree-after" : ""}`}
                                                                                >
                                                                                    <li className="pt-0 firstLi">
                                                                                        {" "}
                                                                                        <div className="unsetstyle">
                                                                                            <img
                                                                                                className={`ml-[65px] max-w-[300px] ${item?.child_exception?.length == 1
                                                                                                    ? "w-[300px]"
                                                                                                    : "w-[247px]"
                                                                                                    }`}
                                                                                                src="/images/Exception-Automation/vector-arrow.svg"
                                                                                            ></img>
                                                                                        </div>
                                                                                        <ul
                                                                                            className={` ${item?.child_exception?.length == 1
                                                                                                ? "mt-[-30px]"
                                                                                                : item?.child_exception?.length == 2
                                                                                                    ? "mt-[-60px]"
                                                                                                    : "mt-[-100px]"
                                                                                                } `}
                                                                                        >
                                                                                            {item?.child_exception?.map((ele, i) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <TreeNode
                                                                                                            isActive={ele?.is_active}
                                                                                                            id={ele?.id}
                                                                                                            label={
                                                                                                                <p
                                                                                                                    className={`ml-2 text-[13px] py-3 leading-[20px] break-all text-${currentMode}-copy`}
                                                                                                                >
                                                                                                                    {ele?.exception_name}
                                                                                                                </p>
                                                                                                            }
                                                                                                            index={index}
                                                                                                            childIndex={i}
                                                                                                            statusType={ele?.exception_type}
                                                                                                            inside_id={ele?.inside_id}
                                                                                                            exception_name={ele?.exception_name}
                                                                                                            el={ele}
                                                                                                        />
                                                                                                    </>
                                                                                                );
                                                                                            })}
                                                                                        </ul>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                            <TogglePopup
                                                                                ref={parentCompRef}
                                                                                className={`bg-blue-500`}
                                                                                menuList={isChild ? childMenuList : filterMenuList}
                                                                                selectedItemChange={onFilterItems}
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                        <div className="mb-6"></div>
                                                                    )}
                                                                </div>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                        <TogglePopup
                                                            ref={parentCompRef}
                                                            className={`bg-blue-500`}
                                                            menuList={isChild ? childMenuList : filterMenuList}
                                                            selectedItemChange={onFilterItems}
                                                        />
                                                    </>
                                                );
                                            })}
                                        </div>
                                        <Button
                                            className="text-[#028C78] mt-3 text-left text-[14px]"
                                            onClick={() => handleOpenExceptionModal(1, null)}
                                        >
                                            {" "}
                                            + Create new exception category
                                        </Button>
                                    </div>

                                    <div className="flex-col  flex mt-10 w-full items-center justify-center" key={"whole-chart"}></div>
                                </Card>
                                <Card
                                    id="ViewCard"
                                    className={`${categoryArr?.length > 0 ? "" : "opacity-50 pointer-events-none"
                                        } basis-[45%] md:mt-5 mb-[3rem] xl:mb-0  xl:mt-0 border-[1px] border-[#8C9097] xl:h-[calc(100vh_-_13rem)] h-[calc(100vh_-_26rem)] ${noActiveParams?.id == 1 ? "" : "overflow-y-auto"
                                        }  xl:overflow-x-hidden ${currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"}`}
                                >
                                    <div className="flex flex-col">
                                        <div
                                            className={`items-center xl:w-full px-[20px] py-[14px] border-b-[1px] border-${currentMode}-700 ${currentMode == "dark" ? "bg-[#353535]" : "bg-[#F0F0F0]"
                                                }`}
                                        >
                                            <p className="font-medium text-lg">{"Business Settings"}</p>
                                        </div>
                                        <div className="space-y-2">
                                            <Preference
                                                excepByOrg={excepByOrg}
                                                setExcepByOrg={setExcepByOrg}
                                                orgId={orgId}
                                                businessSetting={businessSetting}
                                                setBusinessSetting={setBusinessSetting}
                                                categoryArr={categoryArr}
                                                setCategoryArr={setCategoryArr}
                                                handleChangePref={handleChangePref}
                                                setExpandPref={setExpandPref}
                                                expandPref={expandPref}
                                                AccordionSummary={AccordionSummary}
                                                AccordionDetails={AccordionDetails}
                                            />
                                            <EmailSettings
                                                setProfile={setProfile}
                                                profile={profile}
                                                businessSetting={businessSetting}
                                                setBusinessSetting={setBusinessSetting}
                                                categoryArr={categoryArr}
                                                setCategoryArr={setCategoryArr}
                                                excepByOrg={excepByOrg}
                                                setExcepByOrg={setExcepByOrg}
                                                expandEmailSet={expandEmailSet}
                                                setExpandEmailSet={setExpandEmailSet}
                                                handleChangeEmailSet={handleChangeEmailSet}
                                                AccordionSummary={AccordionSummary}
                                                AccordionDetails={AccordionDetails}
                                            />
                                        </div>
                                        <div
                                            className={`items-center mt-[20px] xl:w-full px-[20px] py-[14px] border-b-[1px] min-h-[70px]  border-${currentMode}-700 ${currentMode === "dark"
                                                ? ""
                                                : "shadow-[0px_4px_4px_0px_rgba(0,0,0,0.10),0px_4px_4px_0px_rgba(0,0,0,0.06)] "
                                                } ${noActiveParams?.id == 1 ? "sticky top-0 z-[1]" : ""} ${currentMode == "dark" ? "bg-[#353535]" : "bg-[#F0F0F0]"
                                                }`}
                                        >
                                            <p className="font-medium text-lg truncate w-[500px]" title={`${selectedId?.exception_name
                                                ? `Configuration for ${selectedId?.exception_name} exception`
                                                : "Configuration for exception"
                                                }`} >{`${selectedId?.exception_name
                                                    ? `Configuration for ${selectedId?.exception_name} exception`
                                                    : "Configuration for exception"
                                                    }`}</p>
                                        </div>
                                        <div className="space-y-2 mb-[40px]">
                                            <EmailCategory
                                                isView={isView}
                                                UsersOptions={UsersOptions}
                                                reqBody={reqBody}
                                                currentId={currentId}
                                                setCategoryArr={setCategoryArr}
                                                categoryArr={categoryArr}
                                                statustype={statustype}
                                                receivers={receivers}
                                                setReceivers={setReceivers}
                                                bccreceivers={bccreceivers}
                                                setBccRecievers={setBccRecievers}
                                                email_enabled={Emailenable}
                                                notification_enabled={notificationenable}
                                                AccordionSummary={AccordionSummary}
                                                notification_title={Title}
                                                handleEmailSubject={setSubject}
                                                handleEmailMessage={setEmailmessage}
                                                email_sub={subject}
                                                listData={[]}
                                                notification_msg={message}
                                                email_body={emailMessage}
                                                emailCheckChange={setemailEnable}
                                                // handleNotificationTitle={setTitle}
                                                handleNotificationMessage={setMessage}
                                                invAllocList={[]}
                                                notificationListData={[]}
                                                handleNotificationCheck={setnotificationEnable}
                                                AllocListData={[]}
                                                reset={reset}
                                                selectedId={selectedId}
                                                setSelectedId={setSelectedId}
                                                check={check}
                                                setCheck={setCheck}
                                                currentMode={currentMode}
                                            />

                                            <EscalationReminder
                                                isView={isView}
                                                UsersOptions={UsersOptions}
                                                FindSelectName={FindSelectName}
                                                AccordionSummary={AccordionSummary}
                                                currentId={currentId}
                                                orgId={orgId}
                                                selectedId={selectedId}
                                                setCategoryArr={setCategoryArr}
                                                categoryArr={categoryArr}
                                                statustype={statustype}
                                                setSelectedId={setSelectedId}
                                            />

                                            <AcknowledgementCard
                                                isView={isView}
                                                UsersOptions={UsersOptions}
                                                detailsRef={detailsRef}
                                                setExpandAck={setExpandAck}
                                                receivers={receivers}
                                                AccordionSummary={AccordionSummary}
                                                setReceivers={setReceivers}
                                                bccreceivers={bccreceivers}
                                                setBccRecievers={setBccRecievers}
                                                email_enabled={Emailenable}
                                                notification_enabled={notificationenable}
                                                notification_title={Title}
                                                handleEmailSubject={setSubject}
                                                handleEmailMessage={setEmailmessage}
                                                email_sub={subject}
                                                listData={[]}
                                                notification_msg={message}
                                                email_body={emailMessage}
                                                emailCheckChange={setemailEnable}
                                                // handleNotificationTitle={setTitle}
                                                handleNotificationMessage={setMessage}
                                                invAllocList={[]}
                                                notificationListData={[]}
                                                handleNotificationCheck={setnotificationEnable}
                                                AllocListData={[]}
                                                reset={reset}
                                                selectedId={selectedId}
                                                setSelectedId={setSelectedId}
                                                check={check}
                                                statustype={statustype}
                                                setCheck={setCheck}
                                                currentMode={currentMode}
                                                setCategoryArr={setCategoryArr}
                                                currentId={currentId}
                                            />
                                        </div>

                                        {ReactDOM.createPortal(
                                            <AddExceptionCategory
                                                setExpandMe={setExpandMe}
                                                setCurrentId={setCurrentId}
                                                setIsView={setIsView}
                                                setIsFilter={setIsFilter}
                                                selectedId={selectedId}
                                                setSelectedId={setSelectedId}
                                                statustype={statustype}
                                                setStatusType={setStatusType}
                                                categoryArr={categoryArr}
                                                setCategoryArr={setCategoryArr}
                                                isOpened={openCategory}
                                                handleClose={() => setOpenCategory(false)}
                                                className={openCategory && "Show"}
                                            />,

                                            document.getElementById("user-modal-root")
                                        )}

                                        {ReactDOM.createPortal(
                                            <CloneConfig
                                                isOpened={openClone}
                                                handleClose={() => setOpenClone(false)}
                                                className={openClone && "Show"}
                                            />,

                                            document.getElementById("user-modal-root")
                                        )}
                                    </div>
                                </Card>
                            </div>
                        )}
                    </Main>
                    <Footer />
                </ErrorBoundary>
            </Layout>
        </>
    );
};

export default ExceptionAutomation;