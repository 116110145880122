import { Checkbox, ClickAwayListener, FormControlLabel, MenuItem, Popper, Select, TextField } from "@mui/material";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/Button";
import Loader from "../../components/Loader";
import Card from "../../components/UI/Card";
import { getReportsLicenseList, getReportLicenseCount } from "../../redux/actions/report";
import ExportExcel from "../ExportFiles/Excel";
import TogglePopup from "../organisations/Toggle";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import _, { concat, isEqual, remove } from "lodash";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { encryptId } from "../../utils/helpers";
import { Link, NavLink, useNavigate, useLocation, json } from "react-router-dom";
const BillingTable = () => {
    const { currentMode } = useContext(ThemeContext);
    const location = useLocation();
    const [isdownloadClick, onClickDownload] = useState(false);
    const [isMount, setIsMount] = useState(false);
    const currentDate = new Date();
    const [fromDate, setFromDate] = useState(currentDate);
    function getMonthName(number) {
        const date = new Date();
        date.setMonth(number - 1); // Months are zero-based (0 - 11)

        const monthName = date.toLocaleString("default", { month: "short" });
        return monthName;
    }
    let getFrom = moment(currentDate).format("MM-YYYY")?.split("-");
    const [showFrom, setShowFrom] = useState(getMonthName(getFrom[0]) + "-" + getFrom[1]);
    const [currentPageNo, changePageNo] = useState(1);
    const dispatch = useDispatch();
    const [popstate, setPopState] = useState({
        value: null,
        anchorEl: null,
        id: null,
    });
    const { dataList, requestComplete, fetching, exportData } = useSelector((state) => state?.ReportsLicenseListState);
    const [placement, setPlacement] = useState();
    const [currentPageItem, changePageItem] = useState(10);
    const pageNo = currentPageNo;
    const entries = currentPageItem;

    const pageData = useSelector((state) => state?.reportsLicenseCountState);
    const pageCount = pageData && pageData.data && pageData.data?.data ? pageData?.data?.data?.datalist : 0;

    let start = (pageNo - 1) * entries + 1;
    let totalPageCount = dataList ? dataList.length * (currentPageNo ? currentPageNo : 1) : 0;
    const totalPageExceed = currentPageNo * currentPageItem;
    if (totalPageExceed >= pageCount) {
        totalPageCount = pageCount;
    }
    const nextPageCount = Math.ceil(pageCount / currentPageItem);
    const CountCalc = pageCount / currentPageItem;

    const Rounded = Math.ceil(CountCalc);

    const AccessIcon = () => <img src="/images/invoice/calendar.svg" alt="date-ico" className="convertWhite" />;
    useEffect(() => {
        const getEnd = moment(new Date(fromDate)).format("MM-YYYY")?.split("-");
        const endDate = new Date(getEnd[1], getEnd[0], 0);
        const lastDayOfMonth = endDate.getDate();
        const fromParse = moment(new Date(fromDate)).format("YYYY-MM").toString() + "-01";
        const toParse = moment(new Date(fromDate)).format("YYYY-MM").toString() + "-" + lastDayOfMonth;
        dispatch(getReportsLicenseList(fromParse, toParse, currentPageNo, currentPageItem));
        dispatch(getReportLicenseCount(fromParse, toParse));
    }, [fromDate, currentPageItem, currentPageNo]);
    const handleClickAway = () => {
        setPopState({ id: false, anchorEl: false, value: "" });
    };
    const handleClick = (newPlacement, value, event, id = null) => {
        setPopState({ value, anchorEl: event.currentTarget, id }, (prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    const onPageNumberSelect = (event) => {
        let pageNumber = event.target.value;
        changePageNo(parseInt(pageNumber));
    };

    const onPageItemSelect = (event) => {
        // setAllSelected(false);
        let pageItemVal = event.target.value;
        changePageItem(parseInt(pageItemVal));
        changePageNo(1);
    };
    //Download the file:
    const downloadeExcel = () => {
        const AccDetailData = exportData?.map((ele, index) => ele);
        const [Excel] = exportData?.map((el) => el);
        const invoiceExcelData = [];
        if (AccDetailData) {
            AccDetailData?.forEach((ele) => {
                let invHeaders = {
                    "Invoice Source": ele?.source_details,
                    "Extracted Date": moment(new Date(ele?.created_date.split("T")[0])).format(
                        localStorage?.getItem("userDateFormat") != null
                            ? localStorage?.getItem("userDateFormat").toUpperCase()
                            : JSON.parse(localStorage.getItem("user"))?.user_date?.toUpperCase()
                    ),
                    "Invoice Id": ele?.inv_seq,
                    "Invoice Number": ele?.invoice_no,
                    "Vendor Name": ele?.vendor_name,
                    Organization: ele?.organisation,
                    Status: ele?.description,
                };
                invoiceExcelData.push(invHeaders);
            });
            ExportExcel({ excelData: invoiceExcelData, fileName: "Billing" });
            onClickDownload(false);
            setExportFile(false);
        }
    };
    const [exportFile, setExportFile] = useState(false);
    const onExportExcel = () => {
        setExportFile(true);
        const getEnd = moment(new Date(fromDate)).format("MM-YYYY")?.split("-");
        const endDate = new Date(getEnd[1], getEnd[0], 0);
        const lastDayOfMonth = endDate.getDate();
        const fromParse = moment(new Date(fromDate)).format("YYYY-MM").toString() + "-01";
        const toParse = moment(new Date(fromDate)).format("YYYY-MM").toString() + "-" + lastDayOfMonth;
        dispatch(getReportsLicenseList(fromParse, toParse, 1, pageCount, "download"));
        if (exportData?.length > 0) {
            onClickDownload(true);
        }
    };
    useEffect(() => {
        if (exportData?.length > 0 && exportFile) {
            onClickDownload(true);
        }
    }, [exportData?.length > 0]);
    if (requestComplete && isdownloadClick) {
        let downloadInvoiceList = dataList;
        downloadeExcel(downloadInvoiceList);
    }

    let EmptyArr = [];
    for (let i = 0; i < Rounded; i++) {
        EmptyArr.push(i);
    }
    const previousBtnClick = () => {
        changePageNo(currentPageNo - 1);
    };
    const nextBtnClick = () => {
        changePageNo(currentPageNo + 1);
    };
    const handleValue = (newValue) => {
        if (newValue) {
            setFromDate(newValue);
        }
        getFrom = moment(new Date(newValue)).format("MM-YYYY")?.split("-");
        setShowFrom(getMonthName(getFrom[0]) + "-" + getFrom[1]);
        changePageNo(1);
    };
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const dropdownShadow = currentMode !== "dark" ? "0px 4px 20px rgba(238, 238, 238, 0.5)" : "";
    const dropdownBorder = currentMode !== "dark" ? "223, 225, 226, 0.56" : "";
    const dynamicClass = (status) => {
        switch (status) {
            case "Processed":
                return `bg-light-480`;
            case "Approved":
                return `bg-light-490`;
            case "Rejected":
                return `bg-light-180`;
            case "Draft":
                return `bg-light-500`;
            case "Submitted":
                return `bg-light-510`;
            case "Posted":
                return `bg-light-510`;
            case "Waiting For Approval":
                return `bg-light-520`;
            case "ForApproval":
                return `bg-light-520`;
            default:
                break;
        }
    };
    const DisplayRole = useSelector((state) => state?.DisplayRolePermissionStateAction);
    const { data: DataList } = DisplayRole;
    const RoleList = DataList?.role_info;
    const RoleMenu = RoleList?.menu;
    const RoleProcess = RoleMenu?.find((elem) => elem?.menu_id === 8);
    const RoleProcessAction = RoleProcess?.actions;
    const RoleActProcessView = RoleProcessAction?.find((element) => element.id === 3);
    const ViewOpen = RoleActProcessView?.isallowed;
    return (
        <>
            <Card className="mb-[4rem]">
                <div className="flex justify-between px-[30px] py-[25px]">
                    <div className="flex items-center">
                        <div className="flex items-center">
                            <span>Show</span>

                            <Select
                                name="entrycount"
                                className={`mx-3 text-xs entries insideTble bg-${currentMode}-card`}
                                value={currentPageItem}
                                onChange={(e) => onPageItemSelect(e)}
                                IconComponent={() => (
                                    <img
                                        className="absolute opacity-50 right-[8px] pointer-events-none"
                                        src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                        alt="chevronDown"
                                    />
                                )}
                                SelectDisplayProps={{
                                    style: {
                                        paddingTop: "5px",
                                        display: "flex",
                                        paddingBottom: "5px",
                                        paddingLeft: "10px",
                                        border: 0,
                                        color: currentMode === "dark" ? `#ECECEC` : `#555555`,
                                        paddingRight: "25px",
                                    },
                                }}
                                MenuProps={{
                                    sx: {
                                        "& .MuiMenu-paper": {
                                            backgroundColor: dropdownColor,
                                            color: dropdownText,
                                            boxShadow: dropdownShadow,
                                            border: dropdownBorder,
                                            borderRadius: "8px",
                                        },
                                    },
                                }}
                            >
                                <MenuItem value={10}>{10}</MenuItem>
                                <MenuItem value={15}>{15}</MenuItem>
                                <MenuItem value={20}>{20}</MenuItem>
                                <MenuItem value={25}>{25}</MenuItem>
                                <MenuItem value={50}>{50}</MenuItem>
                                <MenuItem value={75}>{75}</MenuItem>
                                <MenuItem value={100}>{100}</MenuItem>
                            </Select>
                            <span>entries</span>
                        </div>
                    </div>
                    <div className="flex items-center space-x-2">
                        <div className="flex">
                            <div
                                className={`flex items-center justify-end border-[1px] border-${currentMode}-840 py-[8px] pl-[15px] pr-[8px] rounded-[8px] mr-3 mb-0`}
                            >
                                <Button className="flex font-interR ">{showFrom}</Button>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                        inputFormat="MM-YYYY"
                                        components={{
                                            OpenPickerIcon: AccessIcon,
                                        }}
                                        disableFuture
                                        value={fromDate}
                                        name="invoice_date_from"
                                        views={["month", "year"]}
                                        onChange={handleValue}
                                        renderInput={(params) => (
                                            <TextField
                                                sx={{
                                                    "& .MuiInputBase-input": {
                                                        width: 0,
                                                        padding: 0,
                                                        visibility: "hidded",
                                                        // display: "none", // Set your height here.
                                                    },
                                                }}
                                                // value={dueDate}
                                                {...params}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    readOnly: true,
                                                }}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </div>
                            <Button
                                className={`border-[1px] flex items-center border-${currentMode}-840 py-[8px] px-[15px] rounded-[8px]`}
                                onClick={() => onExportExcel()}
                            >
                                <img className="mr-2 w-[16px] h-[16px] convertWhite" src="/images/common/download.svg" alt="download" />
                                Download
                            </Button>
                            {/* <img
                                className="mr-[.4rem] cursor-pointer"
                                src="/images/dashboard/Exports.svg"
                                alt="upload"
                            /> */}
                        </div>
                    </div>
                </div>
                <div className="heightFix invoices relative report max-h-[initial]">
                    <table className="table-auto TblNormal invoice reports w-full">
                        <thead className="sticky top-0 z-[1]">
                            <tr className={`bg-${currentMode}-450`}>
                                <>
                                    <th>Invoice Source</th>
                                    <th>Extracted Date</th>
                                    <th>Invoice Id</th>
                                    <th>Invoice Number</th>
                                    <th>Vendor Name</th>
                                    <th>Organization</th>
                                    <th>Status</th>
                                </>
                            </tr>
                        </thead>
                        {fetching ? (
                            <Loader className="h-[calc(100vh_-_26rem)] absolute left-[50%] translate-x-[-50%]" />
                        ) : (
                            <>
                                <tbody>
                                    {dataList?.length !== 0 ? (
                                        dataList?.map((elem, index) => {
                                            return (
                                                <tr key={index} className={`h-14 border-b-[1px] border-${currentMode}-700`}>
                                                    <td>
                                                        {elem?.source_details}
                                                        {elem?.source_type !== 3 && elem?.email_address !== null && (
                                                            <p className={`text-${currentMode}-890 h-[12px] w-[376px]`}>({elem?.email_address})</p>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {moment(new Date(elem?.created_date.split("T")[0])).format(
                                                            localStorage?.getItem("userDateFormat") != null
                                                                ? localStorage?.getItem("userDateFormat").toUpperCase()
                                                                : JSON.parse(localStorage.getItem("user"))?.user_date?.toUpperCase()
                                                        )}
                                                    </td>
                                                    <td className={`${!ViewOpen ? "pointer-events-none" : ""}`}>
                                                        <Link
                                                            state={{ from: location.pathname }}
                                                            to={`${"/invoice-view?"}${encryptId(
                                                                `{"inv":${+elem?.invoice_id},"type":${7},"seq":"${+elem?.inv_seq}","status":${
                                                                    elem?.ext_status
                                                                }}`
                                                            )}
                                                           `}
                                                            style={{
                                                                textDecoration: "underline",
                                                            }}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {elem?.inv_seq}
                                                        </Link>
                                                    </td>
                                                    <td className={`${!ViewOpen ? "pointer-events-none" : ""} cursor-pointer`}>
                                                        {" "}
                                                        <Link
                                                            state={{ from: location.pathname }}
                                                            to={`${"/invoice-view?"}${encryptId(
                                                                `{"inv":${+elem?.invoice_id},"type":${7},"seq":"${+elem?.inv_seq}","status":${
                                                                    elem?.ext_status
                                                                }}`
                                                            )}
                                                           `}
                                                            style={{
                                                                textDecoration: "underline",
                                                            }}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {elem?.invoice_no}
                                                        </Link>
                                                    </td>
                                                    <td className="max-w-[10px] truncate">{elem?.vendor_name}</td>
                                                    <td>{elem?.organisation}</td>
                                                    <td>
                                                        <Button
                                                            className={`cursor-default ${
                                                                currentMode === "dark" ? `text-[#555555]` : ``
                                                            } ${dynamicClass(
                                                                elem?.description
                                                            )} w-full max-w-[100px] font-interR rounded-[5px] py-[2px] px-3`}
                                                        >
                                                            {elem?.description}
                                                        </Button>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <div className="absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]">
                                            <img src="/images/common/delivery.png" alt="delivery" />
                                            <h4 className="font-interSb text-center mt-3">No data found</h4>
                                        </div>
                                    )}
                                </tbody>
                            </>
                        )}
                    </table>
                </div>
                {dataList?.length !== 0 && (
                    <div className={`bg-${currentMode}-460 flex justify-between items-center py-[10px] px-[30px]`}>
                        <div>
                            <div>
                                <div>
                                    Showing <span>{`${start} to ${totalPageCount}`}</span> entries of <span>{pageCount}</span>
                                </div>
                            </div>
                        </div>

                        <div className="flex items-center">
                            <span>The page you’re on</span>

                            <Select
                                name=""
                                className={`insideTble bg-${currentMode}-card mx-3 text-xs entries`}
                                id=""
                                value={currentPageNo}
                                onChange={(e) => onPageNumberSelect(e)}
                                IconComponent={() => (
                                    <img
                                        className="absolute opacity-50 right-[8px] pointer-events-none"
                                        src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                        alt="chevronDown"
                                    />
                                )}
                                SelectDisplayProps={{
                                    style: {
                                        display: "flex",
                                        border: 0,
                                        color: currentMode === "dark" ? `#ECECEC` : `#555555`,
                                    },
                                }}
                                MenuProps={{
                                    sx: {
                                        "& .MuiMenu-paper": {
                                            backgroundColor: dropdownColor,
                                            color: dropdownText,
                                            boxShadow: dropdownShadow,
                                            border: dropdownBorder,
                                            borderRadius: "8px",
                                        },
                                    },
                                }}
                            >
                                {EmptyArr?.map((elem) => (
                                    <MenuItem value={elem + 1}>{elem + 1}</MenuItem>
                                ))}
                            </Select>
                            <div className={`flex space-x-2 border-l-[1px] pl-3 border-${currentMode}-700`}>
                                <Button
                                    title="Previous page"
                                    disabled={currentPageNo > 1 ? false : true}
                                    onClick={() => previousBtnClick()}
                                    className={`border-${currentMode}-840 border-[1px] rounded-[5px] bg-${currentMode}-card px-2 py-3`}
                                >
                                    <img className="rotate-180 convertWhite" src="/images/common/arrowRight.svg" alt="arrowRight" />
                                </Button>

                                <Button
                                    title="Next page"
                                    className={`border-${currentMode}-840 border-[1px] rounded-[5px] bg-${currentMode}-card px-2 py-3`}
                                    disabled={currentPageNo >= nextPageCount ? true : false}
                                    onClick={() => nextBtnClick()}
                                >
                                    <img src="/images/common/arrowRight.svg" alt="arrowRight" className="convertWhite" />
                                </Button>
                            </div>
                        </div>
                    </div>
                )}

                {/* )} */}
            </Card>
        </>
    );
};

export default BillingTable;
