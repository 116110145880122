import { FormControl, MenuItem, Select, TextField, Tooltip as ToolTips } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { DashboardVendors, GetOnTimeProcessAction } from "../../redux/actions/dashboard";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import Card from "../../components/UI/Card";
import moment from "moment/moment";
import { Barchart } from "../../organisms/BarChart";
import Filter from "./Filter";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { getCurrentUser } from "../../redux/actions/auth";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { encryptId, ThisWeek, ThisYear } from "../../utils/helpers";
import { GET_TIME_FOR_TIMEPROCESSING_BYORG_RESET } from "../../redux/types/types.js";
const Orgname = localStorage.getItem("Orgdemo");

const OnTimeProcessing = (props) => {
    const userId = props?.userId;
    const Title = "On Time Processing";
    const [age, setAge] = useState("");
    const dispatch = useDispatch();
    const location = useLocation();

    const [dates, setDates] = useState();

    let organisationId = 0;

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const CurrentUser = getCurrentUser();

    const letuserId = CurrentUser?.role_id == 1 ? CurrentUser?.id : userId;

    const getDataItems = (type, year) => {
        organisationId = localStorage.getItem("orgId") || 0;

        dispatch(GetOnTimeProcessAction(type, organisationId, year, letuserId));
    };

    const { data, error, fetching, requestComplete } = useSelector((state) => state?.OnTimeProcessState);
    const Datalist = data?.datalist;

    const { currentMode } = useContext(ThemeContext);
    const textColor = currentMode === "dark" ? `#ECECEC` : `#000`;
    const CustomizedAxisTick = ({ x, y, payload }) => {
        const maxLength = props?.data?.length > 5 ? 2 : 10; // Maximum length for label
        const text = payload.value.length > maxLength ? payload.value.substring(0, maxLength) + ".." : payload.value;

        return (
            <g transform={`translate(${x},${y})`}>
                <text
                    x={0}
                    y={0}
                    dy={16}
                    style={{ fontSize: "13px" }}
                    textAnchor="middle"
                    fill={textColor}
                    // transform="rotate(-45)"
                    className="xAxisLabel"
                >
                    {text}
                </text>
            </g>
        );
    };
    useEffect(() => {
        organisationId = localStorage.getItem("orgId") || 0;

        // if (Orgname !== "undefined" && Orgname !== undefined) {
        setTimeout(() => {
            dispatch(GetOnTimeProcessAction(Orgname == "true" ? 3 : 1, organisationId, Orgname == "true" ? ThisYear : ThisWeek, letuserId));
        }, 500);
    }, [dispatch, location.pathname, Orgname]);

    const modifiedData = Datalist?.map((item) => ({
        "Ontime Processed": item?.within_24hrs,
        "Delayed Invoices": item?.after_24hrs,
        invoice_month: item?.invoice_month,
        invdata: item?.invdata,
    }));
    const Datalength = Datalist?.filter((el) => el?.after_24hrs == "0" && el?.within_24hrs == "0");
    const DataNullValue = (Datalist?.length == 1 && Datalength?.length !== 0) || Datalist?.length == 0;
    const maxDataValue = Math?.max(Datalist?.map((item) => Math?.max(item?.within_24hrs, item?.after_24hrs)));
    const yAxisMax = Math.ceil(maxDataValue / 10) * 10;
    const LightTooltip = (props) => (
        <ToolTips
            title={props?.title}
            disablePortal
            placement="top"
            disableInteractive
            PopperProps={{
                sx: {
                    "& .MuiTooltip-tooltip": {
                        background: "#DAF9F4",
                        color: "#555555",
                        borderRadius: "5px",
                        marginTop: "0 !important",
                    },
                },
            }}
        >
            <div>{props?.img}</div>
        </ToolTips>
    );
    const [drilldownData, setDrilldownData] = useState(null);
    const onTimeProcessClick = (check, event, payload) => {
        const filterArray = event?.invdata?.filter((item) => (payload == 1 ? item?.is_ontime > 0 : item?.is_late > 0));
        if (filterArray && filterArray?.length > 0) {
            setDrilldownData(filterArray);
        }
    };
    const handleBarClick = (entry) => {
        if (entry && entry.activePayload[0].payload.invdata) {
            setDrilldownData(entry.activePayload[0].payload.invdata);
        }
    };
    const orgId = localStorage.getItem("orgId");
    useEffect(() => {
        if (orgId) {
            props?.setValidOpen(false);
            props?.setDateForm("");
            props?.setSelectedValue("");
            dispatch({ type: GET_TIME_FOR_TIMEPROCESSING_BYORG_RESET });
        }
    }, [orgId]);
    return (
        <Card className="h-full">
            <div
                className={`flex justify-between px-[20px] py-[24px] ${
                    drilldownData ? `mb-0` : `mb-6`
                }  border-b-[1px] border-${currentMode}-700 items-center`}
            >
                <div className="relative flex  items-center space-x-2 ">
                    <h4 className="font-interM text-lg">On Time Processing</h4>
                    <div className="cursor-pointer relative mt-[2%] ">
                        <LightTooltip
                            title={"Displays the invoice processed on time and delayed invoice in a graphical format."}
                            img={
                                <>
                                    <img src="/images/common/Info-icon.svg" />
                                </>
                            }
                        />
                    </div>
                </div>

                <Filter
                    getDataItems={getDataItems}
                    Title={Title}
                    setSelectedValue={props?.setSelectedValue}
                    setDateForm={props?.setDateForm}
                    setValidOpen={props?.setValidOpen}
                    drilldownData={drilldownData}
                    setDrilldownData={setDrilldownData}
                />
            </div>
            <div className={`${drilldownData ? `py-0` : `py-5`}  home overflow-y-auto h-[calc(100%_-_89px)]`}>
                {!requestComplete ? (
                    <Loader className={`w-full flex justify-center min-h-[22rem] items-center`} />
                ) : DataNullValue ? (
                    <div className="px-[30px] h-[20rem] grid place-items-center place-content-center">
                        {" "}
                        <img className="mb-3" src="/images/common/delivery.png" alt="searching" />
                        <h4 className="font-interSb block">No Data found</h4>
                    </div>
                ) : drilldownData ? (
                    <table className="w-full cursor-default rolemasterTable rounded-[0] lastBorder onTimeTbl text-left">
                        <thead className="sticky top-0 z-[5]">
                            <tr>
                                <th className="!rounded-tl-[0] text-center">#</th>
                                <th>Invoice Id</th>
                                <th>Invoice Number</th>
                                <th>Vendor Name</th>
                                <th>Invoice Date</th>
                                <th className="!rounded-tr-[0]">Invoice Elapsed Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {drilldownData?.map((el, i) => {
                                return (
                                    <tr>
                                        <td className="text-center">{i + 1}</td>
                                        <td className="truncate max-w-[80px]" title={el?.inv_seq}>
                                            <Link
                                                target="_blank"
                                                state={{ from: location.pathname }}
                                                to={`${"/invoice-view?"}${encryptId(
                                                    `{"inv":${+el?.invoice_id},"type":${7},"seq":"${+el?.inv_seq}","status":${+el?.ext_status}}`
                                                )}
                                                   `}
                                            >
                                                <u> {el?.inv_seq}</u>
                                            </Link>
                                        </td>
                                        <td className="truncate max-w-[150px]">
                                            <Link
                                                target="_blank"
                                                state={{ from: location.pathname }}
                                                to={`${"/invoice-view?"}${encryptId(
                                                    `{"inv":${+el?.invoice_id},"type":${7},"seq":"${+el?.inv_seq}","status":${+el?.ext_status}}`
                                                )}
                                                   `}
                                            >
                                                <u>{el?.invoice_no}</u>
                                            </Link>
                                        </td>
                                        <td title={el?.vendor} className="truncate max-w-[150px]">
                                            {el?.vendor}
                                        </td>
                                        <td>{el?.invoice_date}</td>
                                        <td className="">{el?.time_diff} hours</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                ) : (
                    <ResponsiveContainer width="100%" height={380}>
                        <BarChart
                            //onClick={handleBarClick}
                            data={modifiedData}
                            className={`cursor-pointer`}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <XAxis
                                dataKey={"invoice_month"}
                                stroke={currentMode === "dark" ? "#dfe1e28f" : "#E3E3E3"}
                                tick={<CustomizedAxisTick />}
                                interval={0}
                            />
                            <YAxis
                                tick={{ fill: textColor, fontSize: "13px" }}
                                // domain={[0, yAxisMax]}
                                stroke={currentMode === "dark" ? "#dfe1e28f" : "#E3E3E3"}
                            />
                            <Tooltip
                                cursor={{ fill: "transparent" }}
                                // formatter={(value) => value * 10}
                            />

                            <Bar
                                dataKey="Ontime Processed"
                                barSize={20}
                                fill="#3DCE66"
                                onClick={(event, payload) => onTimeProcessClick("On Time Invoices", event, 1)}
                                radius={[100, 100, 0, 0]}
                            />
                            <Bar
                                dataKey="Delayed Invoices"
                                barSize={20}
                                onClick={(event, payload) => onTimeProcessClick("Delayed Invoices", event, 2)}
                                radius={[100, 100, 0, 0]}
                                fill="#FC541F"
                            />
                        </BarChart>
                    </ResponsiveContainer>
                )}
            </div>
        </Card>
    );
};

export default OnTimeProcessing;
