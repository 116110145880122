import { useDraggable } from "@dnd-kit/core";
import { nanoid } from "nanoid";
import { useContext, useRef, useState } from "react";

import { fields } from "./FormElement";
import Card from "../../components/UI/Card";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import Input from "../../components/Input";

export function SidebarField(props) {
    const { field, overlay } = props;
    const { title } = field;

    let className = "sidebar-field";
    if (overlay) {
        className += " overlay";
    }

    return <div className={className}>{title}</div>;
}

function DraggableSidebarField(props) {
    const { field, currentMode, pic, ...rest } = props;

    const id = useRef(nanoid());

    const { attributes, listeners, setNodeRef } = useDraggable({
        id: id.current,
        data: {
            field,
            fromSidebar: true,
        },
    });

    return (
        <div
            ref={setNodeRef}
            className={`border flex items-center justify-between rounded-[5px] border-${currentMode}-1420 px-[15px] py-[6.5px] `}
            {...listeners}
            {...attributes}>
            <div className="flex space-x-[10px]">
                <img className="flex-1" src={pic} alt="pic" />
                <SidebarField field={field} {...rest} />
            </div>
            <img className="cursor-move" src="/images/vendor-onboard/drag-grab.svg" alt="drag-grab" />
        </div>
    );
}

export default function Sidebar(props) {
    const { fieldsRegKey } = props;
    const { currentMode } = useContext(ThemeContext);
    const [filterField, setFilterField] = useState([]);
    const [searchField, setSearchField] = useState("");

    const _handleChange = (e) => {
        setSearchField(e.target.value);
        if (e.target.value == "") {
            setFilterField([]);
        } else {
            const filteredFav = fields?.filter((el) => {
                return el?.title.toLowerCase().includes(searchField?.toLowerCase());
            });
            setFilterField(filteredFav);
        }
    };
    const resetInputField = () => {
        setSearchField("");
        setFilterField([]);
    };

    return (
        <Card
            key={fieldsRegKey}
            className={`h-full space-y-[20px] basis-[24%] px-[20px] py-[30px] xl:h-[calc(100vh_-_13rem)] h-[calc(100vh_-_18rem)] overflow-y-auto`}>
            <div className={`relative flex justify-between border-[1px] w-full border-${currentMode}-1420 py-2 px-[15px] rounded-[5px]`}>
                <Input placeholder="Search" value={searchField} onChange={_handleChange} className={`w-full bg-transparent`} />
                {searchField && searchField.length !== 0 ? (
                    <img onClick={resetInputField} className="cursor-pointer" src="/images/common/closeBlk.svg" alt="close" />
                ) : (
                    <img src="/images/vendor-onboard/search.svg" alt="search" />
                )}
            </div>
            {searchField == "" &&
                filterField?.length == 0 &&
                fields?.map((f) => <DraggableSidebarField key={f?.type} pic={f?.img} field={f} currentMode={currentMode} />)}
            {filterField?.length > 0
                ? filterField?.map((f) => <DraggableSidebarField key={f?.type} pic={f?.img} field={f} currentMode={currentMode} />)
                : searchField != "" && <p className="text-base text-center">No search results found!</p>}
        </Card>
    );
}
