import { createRoot } from "react-dom/client";
// import { TourProvider } from "@reactour/tour";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import reducer from "./redux/reducers/reducers";
import "./styles/global.scss";
import App from "./App";
import { BrowserRouter, useLocation, useNavigate } from "react-router-dom";
import { ThemeProvider } from "./Hooks/Themes/Themeprovider";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "./pages/Language/i18n.jsx";
import React from "react";
import Loader from "./components/Loader/index.jsx";

const store = createStore(reducer, applyMiddleware(thunk));

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <BrowserRouter>
        <Provider store={store}>
            <ThemeProvider>
                <DndProvider backend={HTML5Backend}>
                    <React.Suspense>
                        <App />
                    </React.Suspense>
                </DndProvider>
            </ThemeProvider>
        </Provider>
    </BrowserRouter>
);
