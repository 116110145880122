import { Autocomplete, Checkbox, Chip, ClickAwayListener, Fade, FormControl, MenuItem, Paper, Popper, Select, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Card from "../../components/UI/Card";
import Main from "../../components/UI/Main";
import Layout from "../../Hoc/Layout";
import { invoiceVendorList } from "../../redux/actions/invoice";
import { getReportsList, reportsCountTotal } from "../../redux/actions/report";
import DashboardTile from "../Home/DashboardTile";
import ReportChart from "./ReportChart";
import Table from "./Table";
import { decryptId } from "../../utils/helpers";
import { UserDropDown } from "../../redux/actions/user";
import { getCurrentUser } from "../../redux/actions/auth";
import Swal from "sweetalert2";
import AccrualTbl from "./AccrualTbl";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { getWorkFlowFilter } from "../../redux/actions/workflow-automation";
import Loader from "../../components/Loader/index.jsx";

const Reports = () => {
    const { currentMode } = useContext(ThemeContext);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [placement, setPlacement] = useState();
    const [invNumber, setInvNumber] = useState("");
    const [isSupplier, setSupplierValue] = useState([]);
    const [isStatus, setStatusValue] = useState([]);
    const [val, setVal] = useState([]);
    const [valStatus, setValStatus] = useState([]);
    const [isadult, setIsAdult] = useState([]);
    const [isAdultStatus, setIsAdultStatus] = useState([]);
    const [currentPageItem, changePageItem] = useState(10);
    const [currentPageNo, changePageNo] = useState(1);
    const [open, setOpen] = useState(false);
    const lastDateOfYear = `12/31/${new Date().getFullYear()}`;
    const [selected, setSelected] = useState({ status: [], supplier: [] });
    const profileData = useSelector((state) => state?.userListDropDownState);
    const { data: dataList } = profileData;
    const dataLists = dataList?.filter((el) => el?.isactivated !== false || el?.user_isactive !== false);
    const [user, setUser] = useState(0);

    const dispatch = useDispatch();
    const { data: workflowFilter, fetching } = useSelector((state) => state?.getWorkflowFilterState);
    const handleClick = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    const handleClickAway = () => {
        setOpen(false);
    };
    const handleUserChange = (value) => {
        setUser(value);
    };

    const handleValue = (newValue) => {
        setFromDate(newValue);
    };
    const handleToDate = (toDate) => {
        setToDate(toDate);
    };
    const AccessIcon = () => <img src="/images/invoice/calendar.svg" alt="calendar" className="convertWhite" />;

    //* For workflow filter
    const statusReset = (filterList) => {
        return filterList?.map((item) => {
            return {
                ...item,
                isActive: false,
                value: item?.id,
            };
        });
    };
    const status = workflowFilter?.datalist ? statusReset(workflowFilter?.datalist) : [];

    const orgId = localStorage.getItem("orgId");
    const { vebdorlist } = useSelector((state) => state?.InvoiceVendorListState);
    const supplier = vebdorlist?.map((item) => item?.vendor_name);
    const CurrentUser = getCurrentUser();
    const letuserId = CurrentUser?.role_id == 1 ? CurrentUser?.id : user;
    if (fromDate?.$d?.toString() == "Invalid Date") {
        setFromDate(null);
    } else if (toDate?.$d?.toString() == "Invalid Date") {
        setToDate(null);
    }
    const FromDate = fromDate ? moment(new Date(fromDate)).format("YYYY-MM-DD") : null;
    const ToDate = toDate ? moment(new Date(toDate)).format("YYYY-MM-DD") : null;
    const reqObj = {
        status_type: 1,
        status_id: isStatus?.length === 0 ? [0] : isStatus,
        organisation: +orgId,
        vendor_id: isSupplier?.length === 0 ? null : isSupplier,
        search_text: "",
        invoice_no: invNumber,
        invoice_date_from: FromDate,
        invoice_date_to: ToDate,
        order_column: "",
        order_by: 0,
        pg_no: 1,
        rec_cnt: 10,
        alloc_id: +letuserId,
        document_type: 1,
    };
    // if (fromDate && toDate) {
    //     reqObj.invoice_date_from = moment(new Date(fromDate)).format("YYYY-MM-DD");
    //     reqObj.invoice_date_to = moment(new Date(toDate)).format("YYYY-MM-DD");
    // }
    const reqObjectCount = {
        status_type: 1,
        status_id: isStatus?.length === 0 ? [0] : isStatus,
        search_text: "",
        invoice_date_from: FromDate,
        invoice_date_to: ToDate,
        organisation: +orgId,
        pg_no: 1,
        rec_cnt: 10,
        order_column: "",
        order_by: 0,
        vendor_id: isSupplier?.length === 0 ? null : isSupplier,
        invoice_no: invNumber,
        alloc_id: +letuserId,
        document_type: 1,
    };
    // if (fromDate && toDate) {
    //     reqObjectCount.invoice_date_from = moment(new Date(fromDate)).format("YYYY-MM-DD");
    //     reqObjectCount.invoice_date_to = moment(new Date(toDate)).format("YYYY-MM-DD");
    // }
    const handleSearchFilter = () => {
        if (Date.parse(fromDate) > Date.parse(toDate)) {
            Swal.fire({
                // title: "Invalid date range!",
                closeOnClickOutside: false,
                text: "Invalid date range!",
                showCloseButton: true,
                icon: "warning",
                button: "Ok",
            });
        } else {
            changePageNo(1);
            changePageItem(10);
            dispatch(getReportsList(reqObj));
            dispatch(reportsCountTotal(reqObjectCount));
        }
    };
    useEffect(() => {
        const data = {
            org_id: +orgId,
            status: 2,
        };
        dispatch(UserDropDown(data));
    }, [dispatch]);
    useEffect(() => {
        if (orgId) {
            dispatch(invoiceVendorList(orgId));

            dispatch(getWorkFlowFilter(orgId, 1));

            setFromDate(null);
            setToDate(null);
            setStatusValue([]);
            setSupplierValue(null);
            setInvNumber("");
            setUser(0);
        }
    }, [orgId]);
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "white" : `black`;
    const dropdownShadow = currentMode !== "dark" ? "0px 4px 20px rgba(238, 238, 238, 0.5)" : "";
    const dropdownBorder = currentMode !== "dark" ? "223, 225, 226, 0.56" : "";
    const UserDateFormat = localStorage.getItem("userDateFormat")?.toUpperCase();
    const DisableSearch =
        (fromDate == null || fromDate?.$d?.toString() === "Invalid Date" || fromDate == "") &&
        (toDate == null || toDate?.$d?.toString() === "Invalid Date" || toDate == "") &&
        invNumber == "" &&
        isStatus?.length === 0 &&
        (user?.length === 0 || user === 0) &&
        (isSupplier?.length === 0 || isSupplier == null);
    return (
        <>
            <Layout>
                <Main>
                    <div className={`flex justify-between items-center mb-6 text-${currentMode}-copy`}>
                        <div>
                            <h4 className="text-2xl font-interSb">Invoice Reports</h4>
                        </div>
                        <Link className="flex" to="/reports">
                            <img className="mr-2 convertWhite" src="/images/main/noun-back.svg" alt="noun-back" /> Back
                        </Link>
                    </div>
                    <Card className="p-[20px] mb-6">
                        {fetching ? (
                            <Loader />
                        ) : (
                            <div className="grid grid-cols-2 xl:grid-cols-4 gap-4">
                                <div className="datePic" title="Calendar">
                                    <span className="block mb-[10px]">Created From</span>

                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DesktopDatePicker
                                            selectsStart
                                            inputFormat={UserDateFormat?.toUpperCase()}
                                            disableFuture={true}
                                            className="border-0"
                                            slotProps={{
                                                textField: { fullWidth: true },
                                            }}
                                            components={{
                                                OpenPickerIcon: AccessIcon,
                                            }}
                                            value={fromDate}
                                            minDate={new Date(1999, 12)}
                                            maxDate={new Date(lastDateOfYear)}
                                            name="invoice_date_from"
                                            onChange={handleValue}
                                            renderInput={(params) => (
                                                <TextField
                                                    value={fromDate}
                                                    sx={{
                                                        "& .MuiInputBase-input": {
                                                            color: `${currentMode == "dark" ? "white" : "black"} !important`,
                                                        },
                                                    }}
                                                    fullWidth
                                                    placeholder={params?.inputProps?.placeholder?.replace("From")}
                                                    {...params}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        readOnly: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div className="datePic" title="Calendar">
                                    <span className="block mb-[10px]">Created To</span>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DesktopDatePicker
                                            selectsEnd
                                            className="border-0"
                                            disableFuture={true}
                                            inputFormat={UserDateFormat?.toUpperCase()}
                                            name="invoice_date_to"
                                            components={{
                                                OpenPickerIcon: AccessIcon,
                                            }}
                                            value={toDate}
                                            onChange={handleToDate}
                                            minDate={fromDate}
                                            maxDate={new Date(lastDateOfYear)}
                                            renderInput={(params) => (
                                                <TextField
                                                    value={toDate}
                                                    fullWidth
                                                    sx={{
                                                        "& .MuiInputBase-input": {
                                                            color: `${currentMode == "dark" ? "white" : "black"} !important`,
                                                        },
                                                    }}
                                                    placeholder={params?.inputProps?.placeholder?.replace("To")}
                                                    {...params}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        readOnly: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div>
                                    <span className="block mb-[10px]">Invoice Number</span>
                                    <Input
                                        type="text"
                                        value={invNumber}
                                        onChange={(e) => setInvNumber(e.target.value)}
                                        className={`border-[1px] w-full placeholder:text620 py-2 px-3 rounded-[8px] border-${currentMode}-840 bg-transparent`}
                                        placeholder="Invoice Number"
                                    />
                                </div>
                                <div className="filter">
                                    <span className="block mb-[10px]">Vendor</span>
                                    <Autocomplete
                                        multiple
                                        clearOnBlur={false}
                                        id="checkboxes-tags-demo"
                                        options={vebdorlist || []}
                                        limitTags={1}
                                        onChange={(event, value) => setSupplierValue(value?.map((el) => el?.vendor_name))}
                                        disableCloseOnSelect
                                        PaperComponent={({ children }) => (
                                            <Paper
                                                style={{
                                                    backgroundColor: dropdownColor,
                                                    color: dropdownText,
                                                    boxShadow: dropdownShadow,
                                                    border: dropdownBorder,
                                                }}
                                            >
                                                {children}
                                            </Paper>
                                        )}
                                        popupIcon={<img className="m-0 pointer-events-none opacity-50" src="/images/header/chevronDown.svg" />}
                                        sx={{
                                            // maxHeight: "40px",
                                            // overflowY: "auto",
                                            "& .MuiAutocomplete-popper": {
                                                backgroundColor: "white",
                                                boxShadow: "0px 4px 20px rgba(238, 238, 238, 0.5)",
                                                border: "1px solid rgba(223, 225, 226, 0.56)",
                                                borderRadius: "8px",
                                            },
                                            "& .MuiMenuItem-root:hover": {
                                                backgroundColor: "rgba(218, 249, 244, 0.3)",
                                            },
                                            "& .Mui-selected": {
                                                backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                            },
                                            "& .MuiAutocomplete-popupIndicator": {
                                                padding: 0,
                                                marginTop: "5px",
                                                marginRight: "10px",
                                            },
                                            "& .MuiAutocomplete-popupIndicatorOpen": {
                                                padding: 0,
                                                marginRight: "10px",
                                            },
                                            "& .MuiChip-label": {
                                                color: `${currentMode == "dark" ? "white" : "black"}`,
                                            },
                                        }}
                                        getOptionLabel={(status) => status?.vendor_name}
                                        isOptionEqualToValue={(option, value) => option?.vendor_name === value?.vendor_name}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    size="small"
                                                    sx={{
                                                        color: "rgba(85,85,85,0.7)",
                                                        borderRadius: "3px !important",
                                                        "&.Mui-checked": {
                                                            color: "#028C78",
                                                        },
                                                    }}
                                                    value={option?.org_id}
                                                    checked={selected}
                                                />
                                                {option?.vendor_name}
                                            </li>
                                        )}
                                        style={{ padding: 0 }}
                                        renderInput={(params) => (
                                            <TextField
                                                sx={{
                                                    "& .MuiInputBase-input": {
                                                        color: `${currentMode == "dark" ? "white" : "black"} !important`,
                                                    },
                                                }}
                                                {...params}
                                                fullWidth
                                                placeholder="Vendor"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: null,
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="filter">
                                    <span className="block mb-[10px]">Status</span>
                                    <Autocomplete
                                        multiple
                                        clearOnBlur={false}
                                        id="checkboxes-tags-demo"
                                        options={status || []}
                                        onChange={(event, value) => setStatusValue(value?.map((el) => el?.value))}
                                        limitTags={1}
                                        disableCloseOnSelect
                                        PaperComponent={({ children }) => (
                                            <Paper
                                                style={{
                                                    backgroundColor: dropdownColor,
                                                    color: dropdownText,
                                                    boxShadow: dropdownShadow,
                                                    border: dropdownBorder,
                                                }}
                                            >
                                                {children}
                                            </Paper>
                                        )}
                                        popupIcon={<img className="m-0 pointer-events-none" src="/images/header/chevronDown.svg" />}
                                        sx={{
                                            "& .MuiAutocomplete-popper": {
                                                backgroundColor: "white",
                                                boxShadow: dropdownShadow,
                                                border: "1px solid rgba(223, 225, 226, 0.56)",
                                                borderRadius: "8px",
                                            },
                                            "& .MuiMenuItem-root:hover": {
                                                backgroundColor: "rgba(218, 249, 244, 0.3)",
                                            },
                                            "& .Mui-selected": {
                                                backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                            },

                                            "& .MuiAutocomplete-popupIndicator": {
                                                padding: 0,
                                                marginTop: "5px",
                                                marginRight: "10px",
                                            },
                                            "& .MuiAutocomplete-popupIndicatorOpen": {
                                                padding: 0,
                                                marginRight: "10px",
                                            },
                                            "& .MuiChip-label": {
                                                color: `${currentMode == "dark" ? "white" : "black"}`,
                                            },
                                        }}
                                        getOptionLabel={(status) => status.name}
                                        isOptionEqualToValue={(option, value) => option?.value === value?.value}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    size="small"
                                                    sx={{
                                                        color: "rgba(85,85,85,0.7)",
                                                        borderRadius: "3px !important",
                                                        "&.Mui-checked": {
                                                            color: "#028C78",
                                                        },
                                                    }}
                                                    value={option?.id}
                                                    checked={selected}
                                                />
                                                {option?.name}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                sx={{
                                                    "& .MuiInputBase-input": {
                                                        color: `${currentMode == "dark" ? "white" : "black"} !important`,
                                                    },
                                                }}
                                                {...params}
                                                fullWidth
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: null,
                                                }}
                                                placeholder="Status"
                                            />
                                        )}
                                    />
                                </div>
                                {CurrentUser?.role_id == 2 && (
                                    <div>
                                        <span className="block mb-[10px]">Select User</span>
                                        <FormControl fullWidth sx={{ minWidth: "300px" }}>
                                            <Select
                                                value={user || "0"}
                                                name="UserList"
                                                onChange={(e) => handleUserChange(e.target.value)}
                                                inputProps={{
                                                    "aria-label": "Without label",
                                                }}
                                                IconComponent={() => (
                                                    <img
                                                        className="absolute opacity-50 right-[15px] pointer-events-none"
                                                        src={
                                                            currentMode !== "dark"
                                                                ? `/images/header/chevronDown.svg`
                                                                : `/images/header/chevronDown-white.svg`
                                                        }
                                                    />
                                                )}
                                                MenuProps={{
                                                    sx: {
                                                        "& .MuiMenu-paper": {
                                                            backgroundColor: dropdownColor,
                                                            color: dropdownText,
                                                        },
                                                        "& .MuiMenuItem-root:hover": {
                                                            backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                        },
                                                        "& .Mui-selected": {
                                                            backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                        },
                                                    },
                                                }}
                                                SelectDisplayProps={{
                                                    style: {
                                                        paddingTop: "11px",
                                                        paddingBottom: "11px",
                                                        paddingLeft: "15px",
                                                        color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,

                                                        paddingRight: "30px",
                                                        border: "1px solid rgba(85, 85, 85, 0.4)",
                                                        borderRadius: "8px",
                                                    },
                                                }}
                                            >
                                                <MenuItem key={0} name="UserList" value={0}>
                                                    {" "}
                                                    {"All Users"}
                                                </MenuItem>

                                                {dataLists &&
                                                    dataLists?.map((listelem, i) => {
                                                        return (
                                                            <MenuItem key={i + 1} name="UserList" value={listelem?.id}>
                                                                {listelem?.user_name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                )}
                                <div className="flex items-end">
                                    <Button
                                        onClick={handleSearchFilter}
                                        disabled={DisableSearch ? true : false}
                                        className="bg-light-20 btn-primary rounded-[8px] text-white"
                                    >
                                        Search
                                    </Button>
                                </div>
                            </div>
                        )}
                    </Card>
                    {/* This two items is given by ux but PO told should remove temproary  */}
                    {/* <DashboardTile report={true} />  */}
                    {/* <ReportChart /> */}
                    <Table
                        currentPageNo={currentPageNo}
                        DisableSearch={DisableSearch}
                        changePageNo={changePageNo}
                        currentPageItem={currentPageItem}
                        changePageItem={changePageItem}
                        fromDate={fromDate}
                        toDate={toDate}
                        isSupplier={isSupplier}
                        invNumber={invNumber}
                        isStatus={isStatus}
                        letuserId={letuserId}
                    />
                </Main>
            </Layout>
        </>
    );
};

export default Reports;
