import Card from "../../../components/UI/Card";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import Input from "../../../components/Input";
import { DatePicker, DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useContext, useEffect, useState, useMemo } from "react";
import { getCurrentUser } from "../../../redux/actions/auth";
import { Autocomplete, Checkbox, Select, ClickAwayListener, Fade, MenuItem, Paper, Popper, TextField } from "@mui/material";
import { NumericFormat } from "react-number-format";
import { formatNumber, showNumber, handleDecimal, CustomNumericFormat } from "../../../utils/helpers.js";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/Button";
import Loader from "../../../components/Loader/index.jsx";
import { useNavigate, useLocation } from "react-router-dom";
import { TdsApiAction } from "../../../redux/actions/miscellaneous-request.js";
import Swal from "sweetalert2";
import { renderToString } from "react-dom/server";
const PaymentDetails = (props) => {
    const dispatch = useDispatch();
    const editDisable = props?.editDisable;
    const setTaxcodeOpen = props?.setTaxcodeOpen;
    const taxCodeOpen = props?.taxCodeOpen;
    const vendorName = props?.vendorName;
    useEffect(() => {
        if (taxCodeOpen) {
            const reqObj = { org_id: orgId, vendor_name: vendorName, ref_date: value?.reference_date ? value?.reference_date : null, type: 1 };
            dispatch(TdsApiAction(reqObj));
        }
    }, [taxCodeOpen]);
    // TdsActionState;
    const location = useLocation();
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const AccessIcon = () => <img src="/images/invoice/calendar.svg" tabIndex={0} alt="calendar" className="convertWhite" />;
    const findScreenWidth = props?.findScreeSize;
    const CurrentUser = getCurrentUser();
    const UserDateFormat = localStorage.getItem("userDateFormat")?.toUpperCase();
    const [anchorEls, setAnchorEls] = useState(null);
    const reqObj = props?.reqObj;
    const setReqObj = props?.setReqObj;
    const setValue = props?.setValue;
    const value = props?.value;
    const currencyMisMatch = props?.currencyMisMatch;
    const setCurrencyMisMatch = props?.setCurrencyMisMatch;
    const orgId = localStorage.getItem("orgId");
    const orgData = useSelector((state) => state?.ListorganisationDropDownState);
    const getOrgCurrency = orgData.viewData?.find((el) => el?.id === +orgId)?.currency_code;
    const [getCurrencyByOrg, setCurrencyByOrg] = useState("");
    useEffect(() => {
        if (orgData && orgId) {
            setCurrencyByOrg(orgData.viewData?.find((el) => el?.id === +orgId)?.currency_symbol);
        }
    }, [orgId, orgData]);
    useEffect(() => {
        if (vendorName) {
            const reqObj = { org_id: orgId, vendor_name: vendorName, ref_date: value?.reference_date ? value?.reference_date : null, type: 1 };
            dispatch(TdsApiAction(reqObj));
        }
    }, [vendorName]);
    const POHeaderList = props?.createDataObject?.poHeadersArray?.find((el) => el?.fieldName === "currency")?.value;
    useEffect(() => {
        if (POHeaderList && getOrgCurrency && getOrgCurrency !== POHeaderList) {
            setCurrencyMisMatch(true);
        } else {
            setCurrencyMisMatch(false);
        }
    }, [props?.createReqComp, getOrgCurrency, props?.viewReq]);
    const numFormat = localStorage.getItem("userNumberFormat");
    const paymentAmt = props?.paymentAmt;
    const setpaymentAmt = props?.setpaymentAmt;
    useEffect(() => {
        if (reqObj) {
            // If exec_rate is empty, set it to "1"
            const updatedReqObj = {
                ...reqObj,
                gl_indicator: props?.glIndicData?.length <= 1 ? props?.glIndicData?.[0]?.value : reqObj?.gl_indicator,
            };
            setValue(updatedReqObj);
        }
    }, [reqObj]);
    const handleChange = (e, name, isClick, taxId) => {
        if (isClick) {
            const updatedValueState = {
                ...value,
                [name]: taxId,
            };
            setValue(updatedValueState);

            const updatedReqObjState = {
                ...reqObj,
                [name]: taxId,
            };
            props?.setReqObj(updatedReqObjState);

            if (taxId && updatedValueState.adv_amount) {
                const taxAmt = calculateTaxAmount(updatedValueState.adv_amount, taxId);
                const updatedValueStateWithTax = {
                    ...updatedValueState,
                    tax_amount: taxAmt,
                };
                setValue(updatedValueStateWithTax);

                const updatedReqObjStateWithTax = {
                    ...updatedReqObjState,
                    tax_amount: taxAmt,
                };
                props?.setReqObj(updatedReqObjStateWithTax);
            }
        } else if (e.target) {
            const { name: inputName, value: inputValue } = e.target;
            const updatedValueState = {
                ...value,
                [inputName]: inputValue,
            };
            setValue(updatedValueState);
            const updatedReqObjState = {
                ...reqObj,
                [inputName]: inputValue,
            };
            props?.setReqObj(updatedReqObjState);
            // if (inputName === "adv_amount" && formatNumber(inputValue) > props?.getAllowAdvanceVal) {
            const calcAmt =
                props?.getAllowAdvanceVal -
                formatNumber(numFormat == 2 ? +props?.MobilizationHistory?.[0]?.adv_till_date : props?.MobilizationHistory?.[0]?.adv_till_date);
            if (props?.getAllowAdvanceVal < 0 && inputName === "adv_amount" && inputValue) {
                Swal.fire({
                    text: "Unable to create request!",
                    icon: "warning",
                    allowOutsideClick: false,
                    // showCloseButton: true,
                    buttons: {
                        confirm: "Confirm",
                    },
                }).then((confirm) => {
                    if (confirm?.isConfirmed) {
                        const updatedValueStateWithTax = {
                            ...updatedValueState,
                            adv_amount: "",
                        };
                        setValue(updatedValueStateWithTax);
                        const updatedReqObjStateWithTax = {
                            ...updatedReqObjState,
                            adv_amount: "",
                        };
                        props?.setReqObj(updatedReqObjStateWithTax);
                    }
                });
            } else if (inputName === "adv_amount" && formatNumber(inputValue) > calcAmt && props?.getAllowAdvanceVal > 0) {
                const formattedValue =
                    location.pathname === "/adhoc-advance-request"
                        ? renderToString(<CustomNumericFormat value={props?.getAllowAdvanceVal?.toFixed(2)} formatNum={numFormat} />)
                        : renderToString(<CustomNumericFormat value={calcAmt?.toFixed(2)} formatNum={numFormat} />);
                Swal.fire({
                    text: `${
                        location.pathname === "/adhoc-advance-request"
                            ? `The advance amount cannot be greater than or equal to the base value of the PO ${formattedValue.replace(
                                  /<\/?span[^>]*>/g,
                                  ""
                              )}`
                            : `The advance amount should be less than or equal to ${formattedValue.replace(/<\/?span[^>]*>/g, "")}`
                    }`,
                    icon: "warning",
                    allowOutsideClick: false,
                    // showCloseButton: true,
                    buttons: {
                        confirm: "Confirm",
                    },
                }).then((confirm) => {
                    if (confirm?.isConfirmed) {
                        const updatedValueStateWithTax = {
                            ...updatedValueState,
                            adv_amount: location.pathname === "/adhoc-advance-request" ? props?.getAllowAdvanceVal?.toString() : calcAmt?.toString(),
                        };
                        setValue(updatedValueStateWithTax);

                        const updatedReqObjStateWithTax = {
                            ...updatedReqObjState,
                            adv_amount: location.pathname === "/adhoc-advance-request" ? props?.getAllowAdvanceVal?.toString() : calcAmt?.toString(),
                        };
                        props?.setReqObj(updatedReqObjStateWithTax);
                    }
                });
            } else if (inputName === "adv_amount" && value.tax_id) {
                const taxAmt = calculateTaxAmount(inputValue, value.tax_id);
                const updatedValueStateWithTax = {
                    ...updatedValueState,
                    tax_amount: taxAmt,
                };
                setValue(updatedValueStateWithTax);
                const updatedReqObjStateWithTax = {
                    ...updatedReqObjState,
                    tax_amount: taxAmt,
                };
                props?.setReqObj(updatedReqObjStateWithTax);
            }
        } else {
            const updatedValueState = {
                ...value,
                [name]: e,
            };
            setValue(updatedValueState);
            const updatedReqObjState = {
                ...reqObj,
                [name]: e,
            };
            props?.setReqObj(updatedReqObjState);
        }
    };
    useEffect(() => {
        let calPayAmt = 0;
        if (value?.adv_amount || value?.tax_amount || value) {
            const updateHistoryArray = (setHistoryFunction) => {
                setHistoryFunction((prevVal) => {
                    const updatedArray = prevVal.map((item, index) => {
                        if (index === 0) {
                            return {
                                ...item,
                                this_bill_amt: value?.adv_amount || "0.00",
                            };
                        }
                        return item;
                    });
                    return updatedArray;
                });
            };
            if (location.pathname === "/mobilization-request") {
                updateHistoryArray(props?.setMobilizationHistory);
            } else if (location.pathname === "/adhoc-advance-request") {
                updateHistoryArray(props?.setAdhocHistory);
            }
            if (value?.adv_amount?.length !== 0 && value?.tax_amount !== NaN) {
                const sumNumb = formatNumber(value?.adv_amount) - formatNumber(value?.tax_amount ? value?.tax_amount : "0");
                calPayAmt = isNaN(sumNumb) ? 0 : sumNumb;
            } else if (value?.adv_amount?.length !== 0 && !value?.tax_amount) {
                calPayAmt = value?.adv_amount;
            }
            if (value?.adv_amount && currencyMisMatch) {
                const taxDed = formatNumber(value?.adv_amount) - formatNumber(value?.tax_amount ? value?.tax_amount : "0.00");
                calPayAmt = taxDed / formatNumber(value?.exec_rate);
            }
            setpaymentAmt(+calPayAmt?.toFixed(2)?.toString());
        }
    }, [value?.adv_amount, value?.tax_id, value, location.pathname, value?.exec_rate]);
    const [taxListData, setTaxListData] = useState([]);
    const { data: listData, requestComplete, fetching } = useSelector((state) => state?.TdsActionState);
    useEffect(() => {
        if (listData?.length !== 0) {
            const updatedListData = listData.map((el) => {
                if (formatNumber(el?.threshold_limit) > 0) {
                    const calcPer = 100 - formatNumber(el?.exemption_percentage);
                    const findTaxPerc = (calcPer * formatNumber(el?.tax_percentage)) / 100;
                    return { ...el, actual_tax_percentage: findTaxPerc };
                }
                return el;
            });
            setTaxListData(updatedListData);
        }
    }, [listData]);

    const calculateTaxAmount = (advAmount, taxId) => {
        let taxAmount = 0;
        const taxData = taxListData?.find((ele) => +ele?.tax_id === +taxId);
        const taxPercentage = taxData && formatNumber(taxData?.threshold_limit) > 0 ? taxData?.actual_tax_percentage : taxData?.tax_percentage;
        const defaultTaxPercentage = taxData?.tax_percentage;
        const checkLimitExceed = formatNumber(advAmount) - formatNumber(taxData?.threshold_limit);
        if (taxData?.actual_tax_percentage && formatNumber(taxData?.threshold_limit) > 0 && checkLimitExceed > 0) {
            taxAmount = (checkLimitExceed * defaultTaxPercentage) / 100 + (formatNumber(taxData?.threshold_limit) * taxPercentage) / 100;
        } else {
            taxAmount = showNumber(handleDecimal((formatNumber(advAmount) * formatNumber(taxPercentage)) / 100)?.toString());
        }
        return taxAmount;
    };
    return (
        <>
            <div className="xl:flex xl:space-x-3  ">
                <Card
                    className={`border-[1px] shadow-md border-[rgba(223,225,226,0.56)] ${
                        currencyMisMatch ? "xl:h-[498px]" : "xl:h-[410px]"
                    } xl:w-[976px] 2xl:w-full ${currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"}`}
                >
                    <form className={`xl:flex w-full h-full`}>
                        <div className="m-[30px] xl:basis-3/4">
                            <div className={`flex mb-5 space-x-7 viewStyles ${taxCodeOpen ? "opacity-[.5] pointer-events-none" : ""}`}>
                                <div className="basis-1/2 datePic">
                                    <label htmlFor="" className="block text-sm pb-3">
                                        <span className="text-[red] mr-1">*</span>
                                        Request Date:
                                    </label>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DesktopDatePicker
                                            selectsEnd
                                            disabled={props?.DisableInput || editDisable}
                                            className="border-0"
                                            disableFuture={true}
                                            inputFormat={UserDateFormat?.toUpperCase()}
                                            name="request_date"
                                            components={{
                                                OpenPickerIcon: AccessIcon,
                                            }}
                                            value={value?.request_date ? value?.request_date : null}
                                            onChange={(e) => handleChange(e, "request_date")}
                                            maxDate={new Date()} // Set maxDate to the reference date
                                            minDate={value?.reference_date}
                                            renderInput={(params) => (
                                                <TextField
                                                    value={value?.request_date}
                                                    fullWidth
                                                    sx={{
                                                        "& .MuiInputBase-input": {
                                                            color: `${currentMode == "dark" ? "white" : "black"} !important`,
                                                        },
                                                    }}
                                                    placeholder={params?.inputProps?.placeholder?.replace("To")}
                                                    {...params}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        readOnly: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div className="basis-1/2">
                                    <label className="block text-sm pb-3">Reference Number:</label>
                                    <Input
                                        name="reference_no"
                                        onChange={handleChange}
                                        value={value?.reference_no}
                                        maxLength={50}
                                        disabled={props?.DisableInput}
                                        id="input2"
                                        readOnly={editDisable || location.pathname === "/adhoc-advance-request"}
                                        placeholder="Enter reference number"
                                        className={`border-[1px] border-${currentMode}-840 rounded-[8px] px-[20px] py-2 w-full bg-${currentMode}-810`}
                                    />
                                </div>
                            </div>
                            <div className={`flex mb-5 space-x-7 mt-[20px] viewStyles ${taxCodeOpen ? "opacity-[.5] pointer-events-none" : ""}`}>
                                <div className="basis-1/2 datePic">
                                    <label className="block text-sm pb-3">Reference Date:</label>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DesktopDatePicker
                                            selectsEnd
                                            className="border-0"
                                            disableFuture={true}
                                            disabled={props?.DisableInput || editDisable}
                                            readOnly={location.pathname === "/adhoc-advance-request"}
                                            inputFormat={UserDateFormat?.toUpperCase()}
                                            name="reference_date"
                                            components={{
                                                OpenPickerIcon: AccessIcon,
                                            }}
                                            value={value?.reference_date ? value?.reference_date : null}
                                            onChange={(e) => handleChange(e, "reference_date")}
                                            maxDate={value?.request_date}
                                            renderInput={(params) => (
                                                <TextField
                                                    value={value?.reference_date}
                                                    fullWidth
                                                    sx={{
                                                        "& .MuiInputBase-input": {
                                                            color: `${currentMode == "dark" ? "white" : "black"} !important`,
                                                        },
                                                    }}
                                                    placeholder={params?.inputProps?.placeholder?.replace("To")}
                                                    {...params}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        readOnly: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div className="basis-1/2">
                                    <label htmlFor="" className="block text-sm pb-3">
                                        <span className="text-[red] mr-1 ">*</span>
                                        Advance Amount:
                                    </label>
                                    <NumericFormat
                                        allowNegative={false}
                                        decimalScale={2}
                                        readOnly={editDisable}
                                        disabled={props?.DisableInput}
                                        displayType="input"
                                        maxlength={15}
                                        thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                                        decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                                        value={value?.adv_amount ? value?.adv_amount : ""}
                                        onChange={handleChange}
                                        name="adv_amount"
                                        placeholder="Enter amount"
                                        className={`border-[1px] border-${currentMode}-840 rounded-[8px] px-[20px] py-2 w-full bg-${currentMode}-810`}
                                    />
                                    {/* {errors && <p className="text-light-50 text-xs">{errors?.country}</p>} */}
                                </div>
                            </div>
                            <div className="flex mb-5 space-x-7 mt-[20px] viewStyles">
                                <div className="basis-1/2 datePic">
                                    <div>
                                        <span className="text-[14px] font-medium">
                                            <h3>Tax Code:</h3>
                                        </span>
                                        <div className="relative">
                                            <input
                                                placeholder="Select Tax Code"
                                                name="tax_id"
                                                disabled={props?.DisableInput || editDisable}
                                                value={taxListData?.find((ele) => ele?.tax_id === value?.tax_id)?.tax_code}
                                                onFocus={(e) => {
                                                    setTaxcodeOpen(true);
                                                    setAnchorEls(e.currentTarget);
                                                }}
                                                onBlur={() => {
                                                    setTimeout(() => {
                                                        setTaxcodeOpen(false);
                                                    }, 100);
                                                }}
                                                readOnly
                                                className="border-[rgba(85,85,85,0.5)] w-full mt-3 h-[40px] bg-transparent px-[10px] py-1 border-[1px] rounded-[5px] "
                                            />

                                            <img
                                                className="absolute right-3 mt-[-22px]"
                                                src={
                                                    currentMode === "light"
                                                        ? `/images/header/chevronDown.svg`
                                                        : `/images/header/chevronDown-white.svg`
                                                }
                                                alt="chevronDown"
                                            />
                                        </div>
                                        {taxCodeOpen ? (
                                            <Popper
                                                open={Boolean(taxCodeOpen)}
                                                anchorEl={anchorEls}
                                                disablePortal
                                                disabled={props?.DisableInput}
                                                className="popperTbl z-[22]"
                                                // onClickAway={() => handleClickAway()}
                                            >
                                                <div
                                                    className={`max-h-[340px] w-3/4 overflow-x-auto overflow-y-auto ${
                                                        currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                                                    } ml-[26px] bg-${currentMode}-card border rounded-[8px]`}
                                                >
                                                    <table
                                                        className={` max-h-[230px] overflow-y-auto bg-${currentMode}-card rolemasterTable rounded-[8px] text-left`}
                                                    >
                                                        <thead className="sticky top-0">
                                                            <tr className="">
                                                                <th>Section Code</th>
                                                                <th className="">Tax Code</th>
                                                                <th className="">Business Place</th>
                                                                <th>Condition Type</th>
                                                                <th>Tax Percentage (%)</th>
                                                                <th>Exemption Percentage (%)</th>
                                                                <th>Threshold Limit</th>
                                                            </tr>
                                                        </thead>{" "}
                                                        {fetching && !requestComplete ? (
                                                            <div className="left-[50%] translate-y-[5%] top-[50%] translate-x-[350%]">
                                                                <Loader className="flex h-[calc(100vh_-_17rem)]" />
                                                            </div>
                                                        ) : (
                                                            <tbody className="max-h-[100px]">
                                                                {taxListData?.length > 0 ? (
                                                                    taxListData?.map((item) => {
                                                                        return (
                                                                            <tr
                                                                                className={`cursor-pointer hover:bg-${currentMode}-960 `}
                                                                                onClick={(e) => handleChange(e, "tax_id", "click", item?.tax_id)}
                                                                            >
                                                                                <td className="">{item?.section_code}</td>
                                                                                <td className="flex">{item?.tax_code}</td>
                                                                                <td title={item?.business_place} className="pl-1 ml-[10px] truncate">
                                                                                    {item?.business_place}
                                                                                </td>
                                                                                <td>{item?.tax_type}</td>
                                                                                <td className="flex">{item?.tax_percentage}</td>
                                                                                <td>{item?.exemption_percentage}</td>
                                                                                <td>{item?.threshold_limit}</td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                ) : (
                                                                    <div className="left-[50%] translate-y-[-25%] top-[50%] translate-x-[350%]">
                                                                        <h4 className="font-interSb text-center mt-3">No data found</h4>
                                                                    </div>
                                                                )}
                                                            </tbody>
                                                        )}
                                                    </table>
                                                </div>
                                            </Popper>
                                        ) : (
                                            // <popperDropDownModal open={Boolean(taxCodeOpen)} anchorEl={anchorEl}/>
                                            ""
                                        )}
                                    </div>
                                </div>
                                <div className={`basis-1/2 ${taxCodeOpen ? "opacity-[.5] pointer-events-none" : ""}`}>
                                    <label className="block text-sm pb-3">Tax Amount:</label>
                                    <NumericFormat
                                        allowNegative={false}
                                        decimalScale={2}
                                        displayType="input"
                                        disabled={props?.DisableInput || editDisable}
                                        maxlength={15}
                                        thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                                        decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                                        value={value?.tax_amount}
                                        // onChange={handleChange}
                                        readOnly
                                        name="tax_amount"
                                        placeholder="Enter amount"
                                        className={`border-[1px] border-${currentMode}-840 rounded-[8px] px-[20px] py-2 w-full bg-${currentMode}-810`}
                                    />
                                </div>
                            </div>
                            {currencyMisMatch ? (
                                <div className={`flex mb-5 space-x-7 mt-[20px] viewStyles ${taxCodeOpen ? "opacity-[.5] pointer-events-none" : ""}`}>
                                    <div className="basis-1/2 datePic">
                                        <label className="block text-sm pb-3">Currency:</label>
                                        <Input
                                            name="currency"
                                            value={value?.currency}
                                            disabled={props?.DisableInput}
                                            maxLength={50}
                                            readOnly
                                            placeholder="Currency"
                                            className={`border-[1px] border-${currentMode}-840 rounded-[8px] px-[20px] py-2 w-full bg-${currentMode}-810`}
                                        />
                                    </div>
                                    <div className="basis-1/2">
                                        <label className="block text-sm pb-3">Exchange Rate:</label>
                                        <Input
                                            name="exec_rate"
                                            value={value?.exec_rate}
                                            disabled={props?.DisableInput || editDisable}
                                            maxLength={50}
                                            readOnly
                                            placeholder="Exchange rate"
                                            className={`border-[1px] border-${currentMode}-840 rounded-[8px] px-[20px] py-2 w-full bg-${currentMode}-810`}
                                        />
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                            <div className={`flex mb-5 space-x-7 mt-[20px] viewStyles ${taxCodeOpen ? "opacity-[.5] pointer-events-none" : ""}`}>
                                <div className="basis-1/2 datePic">
                                    <label className="block text-sm pb-3">G/L Indicator:</label>
                                    {props?.glIndicData && props?.glIndicData?.length > 1 ? (
                                        <Select
                                            onChange={handleChange}
                                            disabled={props?.DisableInput || editDisable}
                                            value={value?.gl_indicator || "0"} // Set the default value to "0" if value?.gl_indicator is falsy
                                            name="gl_indicator"
                                            id="input4"
                                            className={`w-full bg-${currentMode}-810 text-${currentMode}-copy`}
                                            MenuProps={{
                                                sx: {
                                                    "& .MuiMenu-paper": {
                                                        backgroundColor: dropdownColor,
                                                        color: dropdownText,
                                                    },
                                                    "& .MuiMenuItem-root:hover": {
                                                        backgroundColor: "rgba(218, 249, 244, 0.3)",
                                                    },
                                                    "& .Mui-selected": {
                                                        backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                                    },
                                                },
                                            }}
                                            SelectDisplayProps={{
                                                style: {
                                                    color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                                },
                                            }}
                                            IconComponent={() => (
                                                <img
                                                    className="absolute right-[15px] opacity-[.5] pointer-events-none"
                                                    src={
                                                        currentMode !== "dark"
                                                            ? `/images/header/chevronDown.svg`
                                                            : `/images/header/chevronDown-white.svg`
                                                    }
                                                    alt="chevronDown"
                                                />
                                            )}
                                        >
                                            <MenuItem value={"0"} disabled>
                                                {"Select"}
                                            </MenuItem>
                                            {props?.glIndicData?.map((el, index) => {
                                                return (
                                                    <MenuItem key={index} value={el?.value}>
                                                        {el?.value}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    ) : (
                                        <Input
                                            name="G/L indicator"
                                            value={value?.gl_indicator}
                                            maxLength={50}
                                            readOnly
                                            placeholder="Enter G/L indicator"
                                            className={`border-[1px] border-${currentMode}-840 rounded-[8px] px-[20px] py-2 w-full bg-${currentMode}-810`}
                                        />
                                    )}
                                </div>
                                <div className="basis-1/2"></div>
                            </div>
                            {findScreenWidth <= 1194 && (
                                <div className={` text-black mr-[20px] my-[30px] w-full h-[150px] bg-[#FCE7F3] rounded-lg text-center `}>
                                    <div className={`pt-[24px] ${taxCodeOpen ? "opacity-[.5] pointer-events-none" : ""}`}>
                                        <p className="text-[16px] mb-[13px] font-medium">Payment Amount</p>
                                        <p className="text-[29px] mb-[10px] font-interM">
                                            {getCurrencyByOrg}{" "}
                                            <NumericFormat
                                                value={paymentAmt}
                                                allowNegative={false}
                                                displayType="text"
                                                disabled={props?.DisableInput}
                                                decimalScale={2}
                                                thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                                                decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                                            />
                                        </p>
                                        <p className="text-[12px]">(Payable Amount = Advance Amount - Tax Amount)</p>
                                    </div>
                                </div>
                            )}
                        </div>
                        {findScreenWidth > 1194 && (
                            <div
                                className={`xl:basis-1/3 text-black mr-[20px] us:w-auto us:py-auto us:m-[30px] my-[30px] w-[266px] h-[348px] bg-[#FCE7F3] rounded-lg text-center ${
                                    taxCodeOpen ? "opacity-[.5] pointer-events-none" : ""
                                }`}
                            >
                                <div className="pt-[1px]">
                                    <p className="text-[16px] mt-[121px] mb-[13px] font-medium">Payment Amount</p>
                                    <p className="text-[29px] mb-[10px] font-interM">
                                        {getCurrencyByOrg}{" "}
                                        <NumericFormat
                                            value={paymentAmt}
                                            // allowNegative={false}
                                            displayType="text"
                                            decimalScale={2}
                                            disabled={props?.DisableInput}
                                            thousandSeparator={numFormat == "1" ? "," : numFormat == "2" ? "." : " "}
                                            decimalSeparator={numFormat == "1" ? "." : numFormat == "2" ? "," : "."}
                                        />
                                    </p>
                                    <p className="text-[12px]">
                                        (Payable Amount = <p>Advance Amount - Tax Amount)</p>
                                    </p>
                                </div>
                            </div>
                        )}
                    </form>
                </Card>
            </div>
        </>
    );
};

export default PaymentDetails;
