import { Autocomplete, Checkbox, MenuItem, Paper, Select, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useContext, useEffect, useMemo } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import Modal from "../../../components/UI/Modal/Modal";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import { DateFilterConfig, DummyOrg, Matcher, NumOperators, Operators } from "../../../utils/constants";
import { SelectDropdwnImg, showSwal } from "../../../utils/helpers";
import { validateFilter } from "../../../Hooks/validateschema";

export const FilterModal = (props) => {
    const dispatch = useDispatch();
    const { currentMode } = useContext(ThemeContext); //Dark and light mode Custom Hook fetch details!
    const { handleClose, className, mode, Items, areaName, addedFilter } = props;
    const DataType = Items?.data_type;
    const [mappedData, setMappedData] = useState(); //state for store the array value based on the data types...
    const params = new URLSearchParams(window.location.search); //get the url(router params or path) data
    let ReportType = params.get("type");
    // Handle form submission here
    const CheckType = () => {
        switch (areaName) {
            case "columns":
                return "UPDATE_COLUMNS";
            case "field":
                return "UPDATE_FIELD";
            case "label":
                return "UPDATE_LABEL";
            case "morefilter":
                return "UPDATE_MORE_FILTER";
            case "matrixcolumns":
                return "UPDATE_MATRIX_COLUMNS_FILTER";
            case "rows":
                return "UPDATE_ROWS_FILTER";
            default:
                break;
        }
    };

    const formik = useFormik({
        initialValues: {
            operator: "",
            val1: Items?.Filter?.[0]?.val1 || "",
            val2: Items?.Filter?.[0]?.val2 || "",
        },
        validationSchema: DataType != "dd" && validateFilter,
        onSubmit: (values) => {
            if (values?.operator == 0) {
                showSwal(`Filter selector must be filled.`);
                return;
            }
            if (values?.operator == "Between" && values?.val1 == "") {
                showSwal(`Filter value must be filled.`); //if scheduled type add mandatory val1 and operator not selected "Between"
            } else if (values?.operator == "Between" && (values?.val1 == "" || values?.val2 == "")) {
                showSwal(`Filter from and to must be filled.`); //if scheduled type add mandatory val1 and val2 and operator selected "Between"
            } else {
                dispatch({
                    type: CheckType(),
                    payload: values,
                    Id: Items?.id,
                    update: true,
                }); //action on modify the original reducer array note: after check validation and fill the values in filter modal...
                if (values?.operator != 0 && DataType != "dd") {
                    addedFilter(true); // check the Filter value not a empty and not "dd" type..
                } else if (values?.val1 != "") {
                    addedFilter(true); // check the Filter value1 not a empty..
                } else {
                    addedFilter(false); // else set the default value false..
                }
                handleClose();
            }
        },
    });
    const [fromDateId, setFromDateId] = useState(null);
    useMemo(() => {
        if (DataType === "s") {
            setMappedData(Matcher);
        } else if (DataType === "n" || DataType === "m" || DataType === "p") {
            setMappedData(NumOperators);
        } else if (DataType === "d") {
            setMappedData(Operators);
        } else if (DataType === "dd" || DataType === "b") {
            setMappedData(["="]);
        }
        formik?.setFieldValue("operator", Items?.Filter?.[0]?.operator || "0");
        formik?.setFieldValue("val1", Items?.Filter?.[0]?.val1 || "");
        formik?.setFieldValue("val2", Items?.Filter?.[0]?.val2 || "");
        setFromDateId(Items?.Filter?.[0]?.val1 || null);
    }, [Items]); // memorize when (Items) array changed on set the default value in formik and states...
    const { dropdown } = useSelector((state) => state?.getFilterDropdownSTate);

    const _handleClose = () => {
        handleClose();
        dispatch({ type: CheckType(), payload: { operator: "", val1: "", val2: "" }, Id: Items?.id, update: false });
        addedFilter(false); //reset the filter active classname (for UI condion based used)...
        dispatch({ type: "CHECK_FILTER_ADDED_RESET" });
        if (DataType == "dd" || DataType == "b") {
            formik?.setFieldValue("operator", "=");
        }
    }; // handleclose func filter modal
    useEffect(() => {
        if (DataType == "dd" || DataType == "b") {
            formik?.setFieldValue("operator", "=");
            formik?.setFieldValue("val1", Items?.Filter?.[0]?.val1 || []);
            if (DataType == "b") {
                formik?.setFieldValue("val1", Items?.Filter?.[0]?.val1 || 0);
            }
        }
    }, [DataType]); //This side effect is used for when data type is "dd" need to update inistial formik value "="...
    const { operator, val1, val2 } = formik?.values; //Object destructure from formik values..
    const handleChangeFromDate = (e) => {
        const selectedFromDateId = e.target.value;
        setFromDateId(selectedFromDateId);
        formik.handleChange(e);
    };
    function updateOptions(selectedLabel) {
        if (selectedLabel !== null) {
            const defaultOptions = DateFilterConfig.filter((option) => option?.id >= 10); // Selecting options with id 10 and above as default
            if (selectedLabel === "Today" || selectedLabel === "This Month First") {
                return defaultOptions;
            } else {
                const selectedIndex = DateFilterConfig?.findIndex((option) => option.label === selectedLabel);
                const updatedOptions = DateFilterConfig?.slice(0, selectedIndex + 1);
                const Sett = [...updatedOptions, ...defaultOptions];
                return [...new Set(Sett)];
            }
        } else {
            return DateFilterConfig;
        }
    }
    return (
        <Modal cartClose={handleClose} center="center" className={`${className}`}>
            <div
                className={`relative min-w-[460px] max-w-[460px] mx-auto bg-${mode}-card text-${mode}-copy border-${mode}-700 px-[30px] pb-[30px] rounded-[10px] ${
                    mode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                }`}>
                <div className={`flex items-center py-[16px] mb-[20px] mx-[-30px] justify-between px-[30px] border-b border-${mode}-1240`}>
                    <span className="font-interM text-base">Select {Items?.field_display_name} Filter</span>
                    <Button tabIndex="4" className="cursor-pointer" onClick={handleClose}>
                        <img src="/images/common/closeBlk.svg" alt="closeBlk" className="convertWhite" />
                    </Button>
                </div>
                <div className="pb-[30px]">
                    <span className="block mb-1">Filter</span>
                    <Select
                        name="operator"
                        value={operator || "0"}
                        fullWidth
                        onChange={formik?.handleChange}
                        IconComponent={() => SelectDropdwnImg(mode)}>
                        {DataType != "dd" && DataType != "b" && (
                            <MenuItem key={0} name="OrganisationList" value={0}>
                                {"Select "}
                            </MenuItem>
                        )}
                        {mappedData?.map((el, i) => (
                            <MenuItem key={i} value={el} className="truncate trunc-1 block">
                                {el}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
                {/* Render this for only if Filter dropdown selected not a empty value and if datatype dropdown(dd) or string(s) */}
                {operator != "" && operator != "Between" && (DataType == "n" || DataType == "m" || DataType == "s" || DataType == "p") && (
                    <>
                        {" "}
                        <div className="mb-5">
                            {" "}
                            <div>
                                <span className="block mb-1">Custom Value</span>
                                <Input
                                    className={`border py-[8px] w-full px-[15px] bg-${currentMode}-card border-${currentMode}-840 rounded-[8px]`}
                                    placeholder="Type a value"
                                    value={val1}
                                    onChange={formik?.handleChange}
                                    name="val1"
                                    type={DataType == "n" || DataType == "m" || DataType == "p" ? `number` : `text`}
                                />
                            </div>
                            {formik?.errors?.val1 && formik?.touched?.val1 ? <p className="text-[red] text-sm">{formik?.errors?.val1}</p> : null}
                        </div>
                    </>
                )}
                {/* Render this for only if Filter dropdown selected not a Between value and not a empty value and if datatype date(d) */}
                {operator != "" && operator != "0" && operator != "Between" && DataType == "d" && (
                    <div className="mb-5">
                        <span className="block mb-1">Date</span>
                        <Select
                            name="val1"
                            value={val1 || "0"}
                            fullWidth
                            onChange={formik?.handleChange}
                            IconComponent={() => SelectDropdwnImg(mode)}>
                            <MenuItem key={0} name="OrganisationList" value={0}>
                                {"Select "}
                            </MenuItem>
                            {DateFilterConfig?.map((el, i) => (
                                <MenuItem key={el?.id} value={el?.label} tabIndex={1} className="truncate trunc-1 block">
                                    {el?.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                )}

                {/* Render this for only if Filter dropdown selected Between value and if datatype date(d) */}
                {operator === "Between" && DataType === "d" && (
                    <div className="flex space-x-3">
                        <div className="basis-1/2 pb-[30px]">
                            <span className="block mb-1">From Date</span>
                            <Select
                                name="val1"
                                value={val1 || "0"}
                                fullWidth
                                onChange={handleChangeFromDate}
                                IconComponent={() => SelectDropdwnImg(mode)}>
                                <MenuItem key={0} name="OrganisationList" value={0}>
                                    {"Select "}
                                </MenuItem>
                                {DateFilterConfig?.map((el, i) => (
                                    <MenuItem key={el?.id} value={el?.label} tabIndex={1} className="truncate trunc-1 block">
                                        {el?.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        <div className="basis-1/2 pb-[30px]">
                            <span className="block mb-1">To Date</span>
                            <Select
                                name="val2"
                                value={val2 || "0"}
                                fullWidth
                                onChange={formik?.handleChange}
                                IconComponent={() => SelectDropdwnImg(mode)}>
                                <MenuItem key={0} name="OrganisationList" value={0}>
                                    {"Select "}
                                </MenuItem>
                                {updateOptions(fromDateId) // Filter options based on selected "From Date"
                                    ?.map((el, i) => (
                                        <MenuItem
                                            key={el?.id}
                                            disabled={el?.label == val1}
                                            value={el?.label}
                                            tabIndex={1}
                                            className="truncate trunc-1 block">
                                            {el?.label}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </div>
                    </div>
                )}
                {DataType == "b" && (
                    <Select
                        name="val1"
                        className="mb-5"
                        value={val1 || "0"}
                        fullWidth
                        onChange={formik?.handleChange}
                        IconComponent={() => SelectDropdwnImg(mode)}>
                        <MenuItem name="OrganisationList" value={0}>
                            {"Select "}
                        </MenuItem>
                        <MenuItem value="true">True</MenuItem>
                        <MenuItem value="false">False</MenuItem>
                    </Select>
                )}

                {DataType == "dd" && (
                    <div className="mb-5 filter">
                        <span className="block mb-1">{Items?.field_display_name}</span>
                        <Autocomplete
                            multiple
                            tabIndex={0}
                            name="val1"
                            clearOnBlur={false}
                            id="checkboxes-tags-demo"
                            options={dropdown || []}
                            onChange={(_, change) => {
                                formik.setFieldValue("val1", change);
                            }}
                            className="islabel"
                            value={val1 || []}
                            disableCloseOnSelect
                            PaperComponent={({ children }) => <Paper>{children}</Paper>}
                            popupIcon={<img className="m-0 pointer-events-none" src="/images/header/chevronDown.svg" />}
                            getOptionLabel={(status) => status?.value}
                            isOptionEqualToValue={(option, value) => option?.value === value?.value}
                            limitTags={1}
                            renderOption={(props, option, { selected }) => (
                                <li {...props} tabIndex={1}>
                                    <Checkbox
                                        size="small"
                                        sx={{
                                            color: "rgba(85,85,85,0.7)",
                                            borderRadius: "3px !important",
                                            "&.Mui-checked": {
                                                color: "#028C78",
                                            },
                                        }}
                                        value={option?.id}
                                        checked={selected}
                                    />
                                    <span title={option?.value} className="truncate max-w-[300px]">
                                        {option?.value}
                                    </span>
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    placeholder={`Select ${Items?.field_display_name}`}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: null,
                                    }}
                                />
                            )}
                        />
                    </div>
                )}

                <div className="flex justify-start items-center space-x-2">
                    <Button className="bg-light-20 px-[30px] py-[8px] text-base text-white rounded-[8px]" onClick={formik?.handleSubmit}>
                        Save
                    </Button>
                    <Button className="bg-light-240 px-[28px] text-light-40 text-base py-[8px] rounded-[8px]" onClick={_handleClose}>
                        Clear
                    </Button>
                    <Button className="bg-light-240 px-[28px] text-light-40 text-base py-[8px] rounded-[8px]" onClick={handleClose}>
                        Cancel
                    </Button>
                </div>
            </div>
        </Modal>
    );
};
