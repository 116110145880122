import Modal from "../../components/UI/Modal/Modal";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { useContext, useMemo, useState, useEffect } from "react";
import { FormControl, InputAdornment, ListSubheader, MenuItem, Select, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { ExceptionEmailGeneration, getExceptionCategoryList } from "../../redux/actions/exception-automation.js";
import { v4 as uuidv4 } from "uuid";
import Swal from "sweetalert2";
import Button from "../../components/Button";
import { Checkbox } from "@mui/material";
import _ from "lodash";
import { EMAIL_GENERATE_EXCEPTION_RESET } from "../../redux/types/types";
let findLength = 0;
const AddExceptionCategory = (props) => {
    const { currentMode } = useContext(ThemeContext);
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    const [category, setCategory] = useState(null);
    const [searchText, setSearchText] = useState("");
    const dispatch = useDispatch();
    const { data, requestComplete } = useSelector((state) => state?.exceptionCategoryListState);
    const [ExceptionsList, setExceptionList] = useState([]);
    const categoryArr = props?.categoryArr;
    const setCategoryArr = props?.setCategoryArr;

    const containsText = (text, searchText) => text?.toLowerCase()?.indexOf(searchText?.toLowerCase()) > -1;

    const displayedOptions = useMemo(
        () => ExceptionsList?.filter((option) => containsText(option?.exception_name, searchText)),
        [searchText, ExceptionsList]
    );
    const [acceptTerms, setAcceptTerms] = useState(false);
    useEffect(() => {
        const orgId = localStorage.getItem("orgId") || 0;
        if (orgId && props?.isOpened) {
            dispatch(getExceptionCategoryList(orgId));
        }
    }, [props?.isOpened, dispatch]);
    useEffect(() => {
        if (requestComplete && data && props?.statustype) {
            let updateArr = [];
            if (props?.statustype == 1) {
                const filteredArr = data?.filter((item) => item?.exception_type === 1);
                const exceptionIds = new Set(categoryArr.map((item) => item.exception_id));
                updateArr = filteredArr.filter((ele) => !exceptionIds.has(ele.id));
                setExceptionList(updateArr);
            } else if (props?.statustype == 2) {
                const filteredArr = data?.filter((item) => item?.exception_type === 2);
                const exceptionIds = new Set(props?.selectedId?.child_exception?.map((item) => item.exception_id));
                updateArr = filteredArr.filter((ele) => !exceptionIds.has(ele.id));
                setExceptionList(updateArr);
            }
        }
    }, [requestComplete, data, props?.statustype, categoryArr, props?.selectedId]);



    const handleCategoryChange = (value, name) => {
        const exceptionExistss = _.some(categoryArr, (exception) => {
            if (props.statustype === 1) {
                // Check for parent exceptions
                return _.toLower(exception.exception_name) === _.toLower(searchText);
            } else if (props.statustype === 2) {
                // Check for child exceptions under the selected parent
                const parentException = categoryArr.find((item) => item.outside_id === props.selectedId.outside_id);
                const childExceptions = parentException ? parentException.child_exception : [];

                // Check if the selected exception name exists in the child exceptions
                const isChildException = _.some(
                    childExceptions,
                    (child) => _.toLower(child.exception_name) === _.toLower(searchText)
                );
                // Also check if the parent exception name matches the selected exception name
                const isParentException =
                    parentException && _.toLower(parentException.exception_name) === _.toLower(searchText);
                return isChildException || isParentException;
            }
            return false;
        });
        if (exceptionExistss) {
            Swal.fire({
                allowOutsideClick: false,
                showCloseButton: true,
                text: "This exception category already exists within the selected category!",
                icon: "warning",
                button: "Okay",
            }).then((confirm) => {
                if (confirm) {
                    setCategory("-1")
                    return false;
                }
            });
        }
        else if (value === 0 && searchText?.trim() !== "") {
            const newCategory = { id: uuidv4(), exception_name: searchText, exception_type: props?.statustype };
            setExceptionList((prevList) => [...prevList, newCategory]);
            setCategory(newCategory.id);
        } else if (value !== 0 && searchText?.trim() == "") {
            setCategory(value);
        }

        else {
            Swal.fire({
                allowOutsideClick: false,
                showCloseButton: true,
                text: "Category cannot be created with empty space!",
                icon: "warning",
                button: "Okay",
            }).then((confirm) => {
                if (confirm) {
                    return false;
                }
            });
        }
    };

    const handleSubmit = () => {
        if (category) {
            const selectedException = ExceptionsList?.find((ele) => ele?.id === category);
            const initialObj = {
                exception_name: selectedException?.exception_name,
                exception_type: props?.statustype,
                exception_id: !isNaN(category) ? +category : 0,
                ismail_auto_generate: acceptTerms,
                id: 0,
                parent_id: null,
                ack_email_json: "",
                email_json: "",
                ...(props?.statustype == 2 && { inside_id: uuidv4() }),
                ...(props?.statustype == 1 && { outside_id: uuidv4() }),
                escalation_remainder: [],
                is_active: true,
                ...(props?.statustype == 1 && { child_exception: [] }),
            };
            const exceptionExists = _.some(categoryArr, (exception) => {
                if (props.statustype === 1) {
                    // Check for parent exceptions
                    return _.toLower(exception.exception_name) === _.toLower(selectedException?.exception_name);
                } else if (props.statustype === 2) {
                    // Check for child exceptions under the selected parent
                    const parentException = categoryArr.find((item) => item.outside_id === props.selectedId.outside_id);
                    const childExceptions = parentException ? parentException.child_exception : [];

                    // Check if the selected exception name exists in the child exceptions
                    const isChildException = _.some(
                        childExceptions,
                        (child) => _.toLower(child.exception_name) === _.toLower(selectedException?.exception_name)
                    );
                    // Also check if the parent exception name matches the selected exception name
                    const isParentException =
                        parentException && _.toLower(parentException.exception_name) === _.toLower(selectedException?.exception_name);
                    return isChildException || isParentException;
                }
                return false;
            });

            // Check if the exception name already exists in the data under the same parent

            if (exceptionExists) {
                Swal.fire({
                    allowOutsideClick: false,
                    showCloseButton: true,
                    text: "This exception category already exists within the selected category!",
                    icon: "warning",
                    button: "Okay",
                }).then((confirm) => {
                    if (confirm) {
                        setCategory("-1")
                        return false;
                    }
                });
            } else {
                const updatedArr = categoryArr?.map((el) => {
                    el.is_active = false;
                    if (el.child_exception && el.child_exception.length > 0) {
                        el.child_exception = el.child_exception.map((child) => {
                            return { ...child, is_active: false };
                        });
                    }
                    if (props?.statustype == 2 && el?.outside_id == props?.selectedId?.outside_id) {
                        if (el.child_exception && el.child_exception?.length > 0 && el.child_exception?.length <= 15) {
                            el.child_exception = [...el.child_exception, initialObj];
                        } else if (!el.child_exception || el.child_exception?.length == 0) {
                            el.child_exception = [initialObj];
                        }
                    }
                    return el;
                });

                if (props?.statustype == 1) {
                    findLength = [...updatedArr, initialObj];
                    setCategoryArr([...updatedArr, initialObj]);
                } else {
                    findLength = updatedArr;
                    setCategoryArr(updatedArr);
                }
                if (acceptTerms) {
                    dispatch(
                        ExceptionEmailGeneration({
                            exception: selectedException?.exception_name,
                            email_size: "small",
                            task: "0"
                        })
                    );
                } else {
                    dispatch({ type: EMAIL_GENERATE_EXCEPTION_RESET });
                }

                setCategory(null);
                props?.handleClose();
                props?.setSelectedId(initialObj);
                props?.setIsView(true);
                props?.setIsFilter(true);
                props?.setExpandMe(findLength?.length);
                const currentObjId = props?.statustype === 1 ? initialObj?.outside_id : initialObj?.inside_id;
                props?.setCurrentId(currentObjId);
                setAcceptTerms(false); // Reset the checkbox state here
            }
        } else {
            Swal.fire({
                allowOutsideClick: false,
                showCloseButton: true,
                text: "Please select the exception Category!",
                icon: "warning",
                button: "Okay",
            }).then((confirm) => {
                if (confirm) {
                    return false;
                }
            });
        }
    };

    const handleClose = () => {
        props?.handleClose();
        props?.setStatusType(1);
    };

    const ExceptionsMenu =
        searchText !== "" ? (displayedOptions?.length !== 0 ? displayedOptions : [{ id: 0, exception_name: searchText }]) : ExceptionsList;
    return (
        <>
            <Modal cartClose={props?.handleClose} center="center" overlap={true} className={`${props?.className}`}>
                <div
                    className={`max-w-[500px] h-[260px] mt-[60px] mx-auto relative bg-${currentMode}-card border-[1px] border-${currentMode}-700 text-${currentMode}-copy px-[90px] mx-auto  py-[48px] rounded-[10px] ${currentMode == "dark" ? "" : " shadow-[0px_4px_10px_rgba(140,144,151,0.5)]"
                        }`}
                >
                    <span onClick={handleClose} title="Close" className="cursor-pointer absolute right-[20px] top-[20px] convertWhite">
                        <img src="/images/common/closeBlk.svg" alt="closeBlk" />
                    </span>
                    <span className={`block text-sm pb-3`}>
                        <span className="text-[red] text-[14px] mr-1">*</span>
                        {props?.statustype == 1 ? "Exception Category:" : "Child Exception Category:"}
                    </span>
                    <div className="flex items-center space-x-3 mb-[1rem]">
                        <FormControl fullWidth>
                            <Select
                                labelId="search-select-label"
                                id="search-select"
                                value={category || "-1"}
                                label="Options"
                                onChange={(e) => handleCategoryChange(e?.target?.value, e?.target)}
                                onClose={() => setSearchText("")}
                                renderValue={(selected) => {
                                    if (selected === "-1") {
                                        return "Select your exception category";
                                    }
                                    const selectedOption = ExceptionsList.find((option) => option.id === selected);
                                    return selectedOption?.exception_name || "Select your exception category";
                                }}
                                className={`w-full bg-${currentMode}-810 text-${currentMode}-copy`}
                                MenuProps={{
                                    autoFocus: false,
                                    sx: {
                                        "& .MuiMenu-paper": {
                                            backgroundColor: dropdownColor,
                                            color: dropdownText,
                                            maxWidth: "318px !important"
                                        },
                                        "& .MuiMenuItem-root:hover": {
                                            backgroundColor: "rgba(218, 249, 244, 0.3)",
                                        },
                                        "& .Mui-selected": {
                                            backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                            color: "#BBBBBB !important",
                                        },
                                        "& .MuiSelect-select": {
                                            color: "#BBBBBB !important",
                                        },
                                        "& .css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper ": {
                                            maxWidth: "300px !important",
                                        },
                                        "& .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root ": {
                                            overflowX: "auto !important",
                                        },
                                        "& .css-1gnkrw1-MuiListSubheader-root ": {
                                            zIndex: "0 !important",
                                        },
                                    },
                                }}
                                SelectDisplayProps={{
                                    style: {
                                        color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                    },
                                }}
                                IconComponent={() => (
                                    <img
                                        className="absolute right-[15px] opacity-[.5] pointer-events-none"
                                        src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                        alt="chevronDown"
                                    />
                                )}
                            >
                                <ListSubheader
                                    title={searchText}
                                    sx={{
                                        backgroundColor: currentMode == "dark" ? "#1F1F1F" : "#fff",
                                    }}
                                >
                                    <TextField
                                        size="small"
                                        autoFocus
                                        placeholder="Add your exception category"
                                        className="border-l max-w-[340px] truncate"
                                        fullWidth
                                        InputProps={{
                                            style: {
                                                color: currentMode == "dark" ? `#ECECEC` : `#555555`,
                                                border: "1px solid #EDEEEF",
                                                height: "40px",
                                            },
                                            inputProps: {
                                                maxLength: 100,
                                            },
                                        }}
                                        value={searchText}
                                        title={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key !== "Escape") {
                                                e.stopPropagation();
                                            }
                                        }}
                                    />
                                </ListSubheader>
                                {ExceptionsMenu.map((option) => (
                                    <MenuItem key={option.id} value={option.id}
                                        title={option.exception_name}
                                        className="truncate trunc-1 block">
                                        {option.exception_name}

                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="flex items-center">
                        <Button className="bg-light-20 text-white h-[40px] rounded-[8px] w-[350px]" onClick={handleSubmit} title="Create">
                            Create
                        </Button>
                    </div>
                    <div className="font-interR text-xs mt-3">
                        <div className="flex form-group items-center form-check">
                            <Checkbox
                                name="ismail_auto_generate"
                                size="small"
                                tabIndex={0}
                                className="mr-2"
                                onChange={(e) => setAcceptTerms(e.target.checked)}
                                value={acceptTerms}
                                checked={acceptTerms}
                                sx={{
                                    padding: 0,
                                    color: "rgba(85,85,85,0.7)",
                                    "&.Mui-checked": {
                                        color: "#00B399",
                                    },
                                }}
                            />
                            Auto generate email
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default AddExceptionCategory;
