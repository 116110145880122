import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";

const StyledSwitch = styled(Switch)(({ theme }) => ({
    padding: 8,
    width: 48,
    height: 31,
    "& .MuiSwitch-switchBase": {
        padding: 5,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(17px)",
    },
    "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
        background: "#00B399",
        opacity: 1,
    },
    "& .MuiSwitch-track": {
        borderRadius: 22 / 2,
        background: "#D9D9D9",
        opacity: 1,

        "&::before, &::after": {
            content: '""',
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            width: 13,
            height: 13,
        },
    },
    "& .MuiSwitch-thumb": {
        boxShadow: "none",
        background: "white",
        width: 13,
        height: 13,
        margin: 4,
    },
}));
// Create a reusable component
export const SwitchToggle = (props) => {
    console.log(props, "prop");
    return <StyledSwitch {...props} />;
};
