import { Checkbox, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Button from "../../components/Button";
import Card from "../../components/UI/Card";
import Main from "../../components/UI/Main";
import Layout from "../../Hoc/Layout";
import Footer from "../../organisms/Footer";
import { useDispatch, useSelector } from "react-redux";
import { AddAccrualPosting, GetAccrualPostingDate, GetMonthClosingAction } from "../../redux/actions/accrual-accounting";
import MessageBox from "../../components/UI/Alert";
import SuccessMessageBox from "../../components/UI/Alert/sucess";
import Swal from "sweetalert2";
import { ADD_ACCRUAL_POSTING_RESET } from "../../redux/types/types";
import Loader from "../../components/Loader";
import { getCurrentUser } from "../../redux/actions/auth";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import dayjs from "dayjs";

const AccrualPost = (props) => {
    const { currentMode } = useContext(ThemeContext);
    const successMsg = "Posted Successfully";
    const dispatch = useDispatch();
    const CurrentUser = getCurrentUser();
    const date = new Date();
    const d = date.toLocaleDateString("en-GB");
    const [checkValue, setValue] = useState([]);
    const [checkDate, setCheckdate] = useState();
    const [postDate, setPostDate] = useState();
    const params = new URLSearchParams(window.location.search);
    const organisation_id = params.get("orgId");
    const dateValue = params.get("date");
    const orgName = params.get("organisation");
    const [newDate, setNewDate] = useState([]);
    const { data, requestComplete } = useSelector((state) => state?.addAccrualPostingState);
    const { data: dataList, requestComplete: reqComplete } = useSelector((state) => state?.GetAccrualPostingDateState);
    const { data: getdataList, requestComplete: getreqComplete, fetching: fetchingGet } = useSelector((state) => state?.getMonthEndClosingState);
    const checkChange = (e, index) => {
        const elem = e.target.value;
        const { checked } = e.target;
        if (!e.target.checked) {
            let selVal = index;
            delete newDate[selVal];
            const Remove = checkValue?.filter((el) => el !== index);
            setValue(Remove);
        } else {
            setValue([...checkValue, index]);
        }
        setCheckdate({
            ...checkDate,
            value: `field-${index}`,
            [`field-${index}`]: checked,
        });
    };
    const handleValue = (newValue, name) => {
        setNewDate({ ...newDate, [name]: newValue });
    };
    useEffect(() => {
        if (newDate) {
            setPostDate([newDate]);
        }
    }, [newDate]);
    const getDateList = postDate ? postDate[0] : [];
    var tifOptions = [];
    Object.keys(getDateList).forEach(function (key) {
        tifOptions.push(<option value={key}>{getDateList[key]}</option>);
    });
    const selectedArr = tifOptions.map((ele, i) => ele?.props?.children);
    const selectedDateList = selectedArr.map((ele, i) => ele?.$d && new Date(ele?.$d).toLocaleDateString("en-CA").split("T")[0]);
    const reqObj = {
        org_id: +organisation_id,
        date: JSON.stringify(selectedDateList),
    };

    const fullDateString = getdataList?.[0]?.month_closed;

    // Split the date string by the hyphen to separate the components
    const dateComponents = fullDateString?.split("-");

    // Extract the year and month
    const year = dateComponents?.[0]; // "2023"
    const month = dateComponents?.[1]; // "08"
    // Combine the year and month
    const yearMonth = `${year}-${month}`;

    const dateStrings = selectedDateList;

    // Use the map function to extract the year and month from each date string
    const extractedDates = dateStrings?.map((dateString) => {
        const [year, month] = dateString?.split("-");
        return `${year}-${month}`;
    });

    const isDatePresent = extractedDates?.includes(yearMonth);
    const handlePosting = () => {
        if (checkValue?.length !== 0 && selectedDateList[0] == undefined) {
            Swal.fire({
                text: `Date field is empty!`,
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else if (checkValue?.length !== 0 && checkValue?.length !== selectedDateList?.length) {
            Swal.fire({
                text: `Date field is empty!`,
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else if (isDatePresent) {
            Swal.fire({
                text: `The selected month has been closed!`,
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else {
            dispatch(AddAccrualPosting(reqObj));
        }
    };
    const { data: datas, requestComplete: reqComp } = useSelector((state) => state?.addAccrualPostingState);
    if (datas?.status && reqComp) {
        setTimeout(() => {
            window.location.href = "/accrual-secondary";
        }, 2000);
    }
    useEffect(() => {
        dispatch(GetAccrualPostingDate(organisation_id));
        dispatch(GetMonthClosingAction(organisation_id));
    }, []);
    if (requestComplete) {
        setTimeout(() => {
            dispatch({ type: ADD_ACCRUAL_POSTING_RESET });
        }, 2000);
    }

    const AccessIcon = () => <img src="/images/common/date-ico.svg" alt="date-ico" />;
    const targetDateStr = CurrentUser?.expiring_date;
    const targetDates = new Date(targetDateStr);
    const currentDate = new Date();
    const matchdate = targetDates < currentDate;

    const defaultDate = dayjs("2023-01-01");

    return (
        <>
            <Layout>
                {requestComplete && data?.status && <SuccessMessageBox success={successMsg} />}
                {requestComplete && !data?.status && <MessageBox error={data.message} />}
                <Main>
                    <div className={`flex justify-between items-center mb-4 `}>
                        <h4 className={`text-2xl font-interSb text-${currentMode}-copy`}>Accrual Accounting</h4>
                        <Link className={`flex text-${currentMode}-copy`} to="/accrual-secondary">
                            <img className="mr-2 convertWhite" src="/images/main/noun-back.svg" alt="noun-back" /> Back
                        </Link>
                    </div>
                    <Card
                        className={`h-[calc(100vh_-_22rem)] xl:h-[calc(100vh_-_18rem)] p-[30px] relative border-b-0 border-${currentMode}-700 rounded-t-[10px] rounded-b-[0] overflow-y-auto ${
                            currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                        } max-h-[-webkit-fill-available] relative`}
                    >
                        <div className="mb-3">
                            <h5 className="text-lg">
                                Organization: <span className="font-interM break-normal md:break-all max-w-[700px]">{orgName}</span>
                            </h5>
                        </div>
                        <table
                            className={`xl:w-[50%] w-full mb-6 ${
                                dataList?.length == 0 ? `h-[calc(100vh_-_24rem)]` : ``
                            }  relative text-center border-${currentMode}-700 mr-[30px] xl:mr-0  !border-[1px] rounded-[8px] rolemasterTable text-left`}
                        >
                            <thead className={`bg-${currentMode}-450`}>
                                <tr>
                                    <th>Select</th>
                                    <th>Month and Year</th>
                                    <th>Accrual Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <>
                                    {!reqComplete ? (
                                        <div className="h-[300px] absolute left-[35%] translate-x[-35%] flex items-center justify-center">
                                            <Loader className="flex items-center justify-center" />
                                        </div>
                                    ) : (
                                        <>
                                            {dataList?.length !== 0 ? (
                                                dataList?.map((ele, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                <Checkbox
                                                                    size="small"
                                                                    id={index + 1}
                                                                    onChange={(e) => checkChange(e, index, `field-${index}`)}
                                                                    name={`field-${index}`}
                                                                    // disabled={
                                                                    //   (index ? true : false)
                                                                    //     ? index - handleCheckBox
                                                                    //       ? true
                                                                    //       : false
                                                                    //     : false
                                                                    // }
                                                                    value={newDate[index] || null}
                                                                    sx={{
                                                                        color: "rgba(85,85,85,0.7)",
                                                                        "&.Mui-checked": {
                                                                            color: "#028C78",
                                                                        },
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>{ele?.missing_month}</td>
                                                            <td className={`text-center text-${currentMode}-copy`}>
                                                                <div className={`flex justify-center datePic w-[180px] mx-auto`} title="Calendar">
                                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                        <DesktopDatePicker
                                                                            className={`border-0`}
                                                                            components={{
                                                                                OpenPickerIcon: AccessIcon,
                                                                            }}
                                                                            sx={{
                                                                                ".MuiOutlinedInput-input": {
                                                                                    padding: "10px",
                                                                                },
                                                                            }}
                                                                            // views={["day"]}
                                                                            // value={!checkDate?.[`field-${index}`] ? null : newDate[index] || null}
                                                                            value={newDate[index] || dayjs(ele?.missing_month)}
                                                                            inputFormat={dateValue?.toUpperCase()}
                                                                            disabled={!checkDate?.[`field-${index}`]}
                                                                            disableFuture
                                                                            id={index + 1}
                                                                            name={`new-${index}`}
                                                                            minDate={ele?.approved_date}
                                                                            maxDate={ele?.month_end}
                                                                            onChange={(e) => handleValue(e, index)}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    sx={{
                                                                                        "& .MuiInputBase-input": {
                                                                                            color: `${
                                                                                                currentMode !== "dark" ? `#555555` : `#ECECEC`
                                                                                            }`,
                                                                                        },
                                                                                    }}
                                                                                    placeholder={params.inputProps?.placeholder?.replace("To")}
                                                                                    {...params}
                                                                                    inputProps={
                                                                                        newDate[index]
                                                                                            ? {
                                                                                                  ...params.inputProps,
                                                                                                  readOnly: true,
                                                                                              }
                                                                                            : {
                                                                                                  ...params,
                                                                                                  readOnly: true,
                                                                                              }
                                                                                    }
                                                                                />
                                                                            )}
                                                                        />
                                                                    </LocalizationProvider>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <div className="absolute  left-[50%] translate-y-[-50%] top-[50%] translate-x-[-50%]">
                                                    <img src="/images/common/delivery.png" alt="delivery" />
                                                    <h4 className="font-interSb text-center mt-3">No data found</h4>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </>
                            </tbody>
                        </table>
                    </Card>
                    <div
                        className={`bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700 py-[20px] space-x-2 px-[30px] sticky left-0 right-0 shadow-[0px_4px_20px_rgba(85,85,85,0.2)] border-[1px] border-[#dfe1e28f] rounded-b-[10px]`}
                    >
                        <Button
                            disabled={dataList?.length == 0 || checkValue?.length == 0}
                            onClick={handlePosting}
                            title="To Post Accrual"
                            className="bg-light-20 font-interSb text-base rounded-[8px] px-[35px] py-[8px] text-white"
                        >
                            Post
                        </Button>
                        <Link to={"/accrual-secondary"} title="Cancel">
                            <Button className="bg-light-240 ml-[10px] text-base font-interR px-[30px] py-[8px] rounded-[8px] text-light-40">
                                Cancel
                            </Button>
                        </Link>
                    </div>
                </Main>
                <Footer />
            </Layout>
        </>
    );
};

export default AccrualPost;
