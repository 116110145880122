import { Checkbox, ClickAwayListener, FormControlLabel, Popover, Popper } from "@mui/material";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import Card from "../../../components/UI/Card";
import Main from "../../../components/UI/Main";
import Layout from "../../../Hoc/Layout";
import Footer from "../../../organisms/Footer";
import SunEditor, { buttonList } from "suneditor-react";
import plugins from "suneditor/src/plugins";
import Swal from "sweetalert2";
import "suneditor/dist/css/suneditor.min.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { emailTemplateAddUpdate, getEmailTemplatePlaceHolderList, getEmailTemplateView } from "../../../redux/actions/emailtask";
import { ADD_UPDATE_EMAIL_TEMPLATE_RESET, VIEW_EMAIL_TEMPLATE_RESET } from "../../../redux/types/types";
import { Link } from "react-router-dom";
import Loader from "../../../components/Loader";
import MessageBox from "../../../components/UI/Alert";
import SuccessMessageBox from "../../../components/UI/Alert/sucess";
import { encryptId, decryptId } from "../../../utils/helpers";
import { Autocomplete, Chip, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useRef, useContext } from "react";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import { CKEditor } from "ckeditor4-react";

export const TemplateCreate = (props) => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    document.addEventListener("dragstart", function (event) {
        event.dataTransfer.setData("Text", "%" + event.target.innerHTML + "%");
    });

    const editorRef = useRef(null);
    const [popstate, setPopState] = useState({ id: null, anchorEl: false });
    const [placement, setPlacement] = useState();
    const viewEmail = useSelector((state) => state?.viewEmailTemplateState);
    const listData = viewEmail?.data?.datalist?.[0];
    const [content, setContent] = useState("");
    const [templateName, setTemplateName] = useState("");
    const [mailCc, setMailCc] = useState([]);

    const [mailBcc, setMailBcc] = useState([]);
    const [mailSubject, setMailSubject] = useState("");
    const [error1, setError] = useState(false);
    const { fetching, requestComplete, error, data } = useSelector((state) => state?.emailConfigAddUpdateState);

    const [focused, setFocused] = useState("");
    const [bccInput, setBccinput] = useState("");
    const params = new URLSearchParams(window.location.search);
    let viewId = params.get("id");
    let editId = params.get("editId");
    let cloneId = params.get("cloneId");
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const onFocus = (event) => {
        setFocused("template");
    };
    const location = useLocation();
    const onFocusSubject = () => setFocused("subject");
    const onFocusEditor = (e) => {
        setShowing(null);
        setFocused("editor");
    };

    const [cursorPosition, setCursorPosition] = useState({ container: "", offset: "" });

    useEffect(() => {
        if (listData != undefined) {
            setTemplateName(cloneId != null ? "" : listData?.template_name);
            const getCc = decryptId(listData?.email_cc)?.split(";");
            const getBcc = decryptId(listData?.email_bcc)?.split(";");
            setMailCc(getCc == "" || getCc == null ? [] : getCc);
            setMailBcc(getBcc == "" || getBcc == null ? [] : getBcc);
            setMailSubject(listData?.subject);
            setContent(listData?.email_message);
            if (editorRef.current) {
                editorRef.current.editor.setData(listData?.email_message);
            }

            dispatch({ type: VIEW_EMAIL_TEMPLATE_RESET });
        }
    }, [listData]);
    const succesMsg = editId !== null ? "Updated Successfully" : "Created Successfully";
    const errormsg = "Something went wrong please contact admin";
    const subjectRef = useRef(null);

    const handleChange = (e) => {
        setTemplateName(e.target.value);
    };
    const handleMailCc = (e) => {
        setMailCc(e.target.value);
    };
    const handleMailBcc = (e) => {
        setMailBcc(e.target.value);
    };
    const handleMailSubject = (e) => {
        setMailSubject(e.target.value);
    };
    const handleClickAway = (e) => {
        setShowing(false);
    };
    // const handleClick = (newPlacement, id) => (event) => {
    //     setAnchorEl(event.currentTarget);
    //     setPopState({ id, anchorEl: event.currentTarget });
    //     setPlacement(newPlacement);
    // };
    const formats = [
        ["undo", "redo"],
        ["font", "fontSize", "formatBlock"],
        ["paragraphStyle", "blockquote"],
        ["bold", "underline", "italic", "strike", "subscript", "superscript"],
        ["fontColor", "hiliteColor", "textStyle"],
        ["removeFormat"],
        "/", // Line break
        ["outdent", "indent"],
        ["align", "horizontalRule", "list", "lineHeight"],
        ["table", "link" /** ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin.
        /** ['imageGallery'] */ // You must add the "imageGalleryUrl".
        ["fullScreen", "showBlocks", "codeView"],
        ["print"],

        /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
    ];
    // const handleListClick = () => {
    //   const temp = content + "%Name%";
    //   setContent(temp);
    // };

    const [inputValue, setInputValue] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        if (viewId != null || editId != null || cloneId != null) {
            const parseId = viewId != null ? viewId : editId != null ? editId : cloneId;
            dispatch(getEmailTemplateView(parseId));
        } else if (viewId == null && editId == null && cloneId == null) {
            setTemplateName("");

            setMailCc([]);
            setMailBcc([]);
            setMailSubject("");
            setContent("");
            editorRef?.current?.editor?.setData("");
        }
        dispatch(getEmailTemplatePlaceHolderList());
    }, [dispatch]);

    // useEffect(() => {
    //   if (sunEditorRef.current) {
    //     const sunEditor = sunEditorRef.current.editor;
    //     sunEditor.onMouseDown = () => {
    //       sunEditor.focus();
    //     };
    //   }
    // }, []);

    const { data: placeHolData } = useSelector((state) => state?.emailTemplatePlaceholderListState);
    const invoicePlaceHolderList = placeHolData.filter((el) => el?.placeholder_group_name == "Invoice");
    const customerPlaceHolderList = placeHolData.filter((el) => el?.placeholder_group_name == "Customer");
    const organisationPlaceHolderList = placeHolData.filter((el) => el?.placeholder_group_name == "Organisation");

    const handleInvoiceClick = (name) => {
        const percent = "%" + name + "%";

        if (focused == "subject") {
            const textArea = subjectRef.current;
            const { selectionStart, selectionEnd } = textArea;
            const currentText = textArea.value;
            const newText = currentText.substring(0, selectionStart) + percent + currentText.substring(selectionEnd);
            setMailSubject(newText);
            textArea.focus();
        } else if (focused == "editor") {
            // if (editorRef.current) {
            //     const editorInstance = editorRef.current.editor;
            //     const index = editorInstance.getSelection().startOffset;
            //     editorInstance.insertText(index, "Your string here");
            // }
            const newContent = content + percent;
            const paragraphHtml = content;
            const targetText = cursorPosition?.container?.data?.slice(0, cursorPosition?.offset);

            const offset = cursorPosition?.offset; // 0-based index

            // Find the index of the paragraph containing the target text
            const regexPattern = new RegExp("<[^>]*>" + targetText);

            // Find the index of the target text within the HTML string using the regular expression
            var match = paragraphHtml.replace(/&nbsp;/g, " ")?.match(regexPattern);

            if (match !== null) {
                // Calculate the index where the string should be inserted
                const startIndex = match.index + match[0].indexOf(targetText) + offset;

                // Insert the string at the calculated index
                const updatedHtml = paragraphHtml.slice(0, startIndex) + percent + paragraphHtml.slice(startIndex);

                setContent(updatedHtml);
                editorRef?.current?.editor?.setData(updatedHtml);
            } else {
                setContent(newContent);
                editorRef?.current?.editor?.setData(newContent);
            }
            // If the target text is found
            // if (startIndex !== -1) {
            //     const targetParagraph = paragraphHtml.substring(startIndex);
            //     const insertPosition = offset;
            //     const updatedParagraph = targetParagraph.slice(0, insertPosition) + percent + targetParagraph.slice(insertPosition);
            //     const updatedHtml = paragraphHtml.substring(0, startIndex) + updatedParagraph;
            //     setContent(updatedHtml);
            //     editorRef?.current?.editor?.setData(updatedHtml);
            // } else {
            //     setContent(newContent);
            //     editorRef?.current?.editor?.setData(newContent);
            // }
        }
    };
    const [val, setVal] = useState(listData);
    const navigate = useNavigate();

    const handleSubmit = () => {
        // let parCc=[]
        // if(mailCc.length > 0){

        // }
        // const temp=mailCc.split(';')
        // const parseCc=temp.map((item)=>{
        //   return encryptId(item)
        // })

        if (templateName.trim().length == 0) {
            Swal.fire({
                text: `Template name is required.`,
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
            return false;
        }
        if (inputValue.length != 0) {
            if (regex.test(inputValue)) {
                setMailCc(mailCc?.concat(inputValue));
                setInputValue("");
            } else {
                Swal.fire({
                    text: `Invalid email address in Cc.`,
                    icon: "warning",
                    closeOnClickOutside: false,
                    showCloseButton: true,
                    buttons: {
                        confirm: true,
                    },
                });
                return false;
            }
        }
        if (bccInput.length != 0) {
            if (regex.test(bccInput)) {
                setMailBcc(mailBcc.concat(bccInput));
                setBccinput("");
            } else {
                Swal.fire({
                    text: `Invalid email address in Bcc.`,
                    icon: "warning",
                    closeOnClickOutside: false,
                    showCloseButton: true,
                    buttons: {
                        cancel: true,
                        confirm: true,
                    },
                });
                return false;
            }
        }
        //   const getCc = mailCc?.length > 0 ? encryptId(mailCc.join(";")) : null;
        const getCc =
            mailCc?.length > 0 || inputValue?.length > 0
                ? encryptId(inputValue?.length > 0 ? (mailCc?.length > 0 ? mailCc.concat(inputValue).join(";") : inputValue) : mailCc.join(";"))
                : null;
        // const getBcc =
        //     mailBcc?.length > 0 ? encryptId(mailBcc.join(";")) : null;
        const getBcc =
            mailBcc?.length > 0 || bccInput?.length > 0
                ? encryptId(bccInput?.length > 0 ? (mailBcc?.length > 0 ? mailBcc.concat(bccInput).join(";") : bccInput) : mailBcc.join(";"))
                : null;
        const modifiedContent = content.replace(/<\/?p>/g, "");
        let contentParse = content;
        if (content?.includes("<table>")) {
            const headContent = `<head><style>table, th, td {border: 1px solid black;border-collapse: collapse;}table{width:100%}</style></head>`;
            contentParse = headContent + content;
        }
        const reqObj = {
            json: [
                {
                    id: editId != null ? editId : 0,
                    name: templateName.trim(),
                    subject: mailSubject,
                    body: contentParse,
                    email_cc: getCc,
                    email_bcc: getBcc,
                },
            ],
        };
        dispatch(emailTemplateAddUpdate(reqObj));
    };
    if (requestComplete || (requestComplete && !data.status) || (error && !data?.status)) {
        setTimeout(function () {
            dispatch({ type: ADD_UPDATE_EMAIL_TEMPLATE_RESET });
        }, 2000);

        if (data?.status) {
            setTimeout(() => {
                dispatch({ type: VIEW_EMAIL_TEMPLATE_RESET });
                navigate(`/emailtemplate`);
            }, 1000);
        }
    }
    // if (location.pathname == "/emailtemplate/view") {
    //     var textarea = document?.getElementById("foobar");
    //     textarea?.focus();
    // }
    //CkEditor functions
    const handleEditorReady = (editor) => {
        editorRef.current = editor;
        const ckEditorInstance = editor.editor;
        // Attach event listener for selectionChange
        ckEditorInstance.on("blur", function (event) {
            const selection = event.editor.getSelection();
            const range = selection.getRanges()[0];
            const containerNode = range.startContainer.$;
            const cursorPosition = range.startOffset;
            const targetText = containerNode?.data;
            // var hello = getCaretPosition(editorElement);
            // console.log("Hello:", hello);
            setCursorPosition({ container: containerNode, offset: cursorPosition });
        });

        //  ckEditorInstance.on("keyup", handleSelectionChange);
    };
    function getCaretPosition(element) {
        var caretOffset = 0;
        var doc = element.ownerDocument || element.document;
        var win = doc.defaultView || doc.parentWindow;

        var sel;
        if (typeof win.getSelection != "undefined") {
            sel = win.getSelection();
            if (sel.rangeCount > 0) {
                var range = win.getSelection().getRangeAt(0);
                var preCaretRange = range.cloneRange();
                preCaretRange.selectNodeContents(element);
                preCaretRange.setEnd(range.endContainer, range.endOffset);
                caretOffset = preCaretRange.toString().length;
            }
        } else if ((sel = doc.selection) && sel.type != "Control") {
            var textRange = sel.createRange();
            var preCaretTextRange = doc.body.createTextRange();
            preCaretTextRange.moveToElementText(element);
            preCaretTextRange.setEndPoint("EndToEnd", textRange);
            caretOffset = preCaretTextRange.text.length;
        }

        return caretOffset;
    }
    // Function to calculate offset of a node within its parent
    const handleEditorChange = (event) => {
        const data = event.editor.getData();
        setContent(data);
    };
    const useStyles = makeStyles((theme) => ({
        clearIndicator: {
            color: "red", // Change the color value to the desired color
        },
    }));
    const classes = useStyles();

    const [showing, setShowing] = useState(null);

    const handleClick = (event) => {
        setShowing(true);
    };

    const handleClose = () => {
        setShowing(false);
    };

    // const open = Boolean(anchorEl);
    // const id = open ? 'simple-popover' : undefined;
    const handleDrop = (event) => {
        event.preventDefault();
        const data = event.dataTransfer.getData("text/plain");
        const inputElement = event.target;
        const currentValue = inputElement.value;
        // Append the dropped data to the existing value, separated by a space
        const newValue = currentValue ? `${currentValue} ${data}` : data;
        // Update the state with the new value
        setMailSubject(newValue);
    };
    return (
        <Layout>
            {requestComplete && data?.status && <SuccessMessageBox success={succesMsg} />}
            {requestComplete && !data?.status && <MessageBox error={data?.message} />}
            {error && !data?.status && <MessageBox error={errormsg} />}
            <Main>
                {/* <h4 className="text-2xl font-interSb mb-5">Email Template</h4> */}
                <div className={`flex justify-between items-center text-${currentMode}-copy`}>
                    <h4 className="text-2xl font-interSb mb-5">Email Template</h4>
                    <Link className="flex" to="/emailtemplate">
                        <img className="mr-2 convertWhite" src="/images/main/noun-back.svg" alt="noun-back" /> Back
                    </Link>
                </div>
                <Card
                    className={`border-b-0  p-[20px] rounded-t-[10px] rounded-b-[0] xl:h-[calc(100vh_-_18rem)] h-[calc(100vh_-_22.5rem)] w-full overflow-y-auto ${
                        currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                    } pb-0 relative`}>
                    {/* <h4 className="font-interSb mb-3">Invoice Notification - Default</h4> */}
                    {viewEmail?.fetching === true || requestComplete ? (
                        <Loader className="h-[calc(100vh_-_17rem)] " />
                    ) : (
                        <div>
                            <form action="" className="space-y-4">
                                <div className={`flex items-center`}>
                                    <label className={`basis-[15%]`} htmlFor="">
                                        Template Name
                                    </label>
                                    <Input
                                        id="foobar"
                                        className={`border-${currentMode}-840 basis-[85%] px-[15px] py-[8px] flex-1 border-[1px] rounded-[8px] w-full  ${
                                            currentMode == "dark" ? "disabled:text-white" : "disabled:text-light-40"
                                        } bg-${currentMode}-810 ${viewId !== null && "text-light-40"} border-${currentMode}-830`}
                                        placeholder="Template Name"
                                        value={templateName}
                                        onChange={(e) => handleChange(e)}
                                        disabled={viewId != null}
                                        maxLength={100}
                                        onFocus={onFocus}
                                        autoFocus
                                    />
                                </div>
                                <div className="flex items-center filter solo">
                                    <label className="basis-[15%]" htmlFor="">
                                        Cc
                                    </label>
                                    {/* <Input
                    className="border-[#DFE1E2] basis-[85%] px-[18px] py-[8px] flex-1 border-[1px] rounded-[8px] w-full"
                    onChange={(e) => handleMailCc(e)}
                    value={mailCc}
                    disabled={viewId != null}
                    maxLength={250}ƒ
                 
                  /> */}
                                    <Autocomplete
                                        multiple
                                        sx={{
                                            "& .MuiAutocomplete-root": {
                                                width: "85%",
                                            },
                                            "& .MuiChip-label": {
                                                color: `${currentMode == "dark" ? "white" : "black"}`,
                                            },
                                        }}
                                        id="tags-filled"
                                        options={[]}
                                        disabled={viewId != null}
                                        value={mailCc == undefined ? [] : mailCc}
                                        inputValue={inputValue}
                                        disablePortal
                                        // disableCloseOnSelect
                                        //disableClearable
                                        // autoHighlight={true}
                                        onInputChange={(e, newValue) => {
                                            setInputValue(newValue);
                                        }}
                                        freeSolo
                                        style={{ width: "85%", border: 0 }}
                                        limitTags={5}
                                        onChange={(e, newval, reason) => {
                                            const errorEmail = newval.find((email) => !regex.test(email));
                                            if (errorEmail) {
                                                // set value displayed in the textbox
                                                setInputValue(errorEmail);
                                                setError(true);
                                            } else {
                                                setError(false);
                                            }

                                            setMailCc(newval.filter((email) => regex.test(email)));

                                            //  (newval);
                                        }}
                                        renderTags={(value, getTagProps) =>
                                            value.length > 0 &&
                                            value?.map((option, index) => {
                                                return (
                                                    <Chip
                                                        key={index}
                                                        variant="outlined"
                                                        disable={false}
                                                        label={option}
                                                        {...getTagProps({
                                                            index,
                                                        })}
                                                        onDelete={() => {
                                                            setMailCc(mailCc.filter((e) => e !== option));
                                                        }}
                                                    />
                                                );
                                            })
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                sx={{
                                                    "& .MuiInputBase-input": {
                                                        color: `${currentMode == "dark" ? "white" : "black"} !important`,
                                                    },
                                                }}
                                                fullWidth
                                                {...params}
                                                onFocus={() => setFocused("cc")}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="flex items-center solo filter">
                                    <label className="basis-[15%]" htmlFor="">
                                        Bcc
                                    </label>
                                    {/* <Input
                    className="border-[#DFE1E2] basis-[85%] px-[18px] py-[8px] flex-1 border-[1px] rounded-[8px] w-full"
                    value={mailBcc}
                    onChange={(e) => handleMailBcc(e)}
                    disabled={viewId != null}
                    maxLength={250}
                  /> */}
                                    <Autocomplete
                                        multiple
                                        sx={{
                                            "& .MuiAutocomplete-root": {
                                                width: "85%",
                                            },
                                            "& .MuiChip-label": {
                                                color: `${currentMode == "dark" ? "white" : "black"}`,
                                            },
                                        }}
                                        id=""
                                        open={true}
                                        options={[]}
                                        inputValue={bccInput}
                                        onInputChange={(e, newValue) => {
                                            setBccinput(newValue);
                                        }}
                                        disabled={viewId != null}
                                        value={mailBcc == undefined ? [] : mailBcc}
                                        disableCloseOnSelect
                                        disablePortal
                                        autoHighlight={true}
                                        freeSolo
                                        style={{ width: "85%", border: 0 }}
                                        limitTags={5}
                                        onChange={(e, newval, reason) => {
                                            const errorEmail = newval.find((email) => !regex.test(email));
                                            if (errorEmail) {
                                                // set value displayed in the textbox
                                                setBccinput(errorEmail);
                                            }

                                            setMailBcc(newval.filter((email) => regex.test(email)));
                                        }}
                                        renderTags={(value, getTagProps) =>
                                            value &&
                                            value?.map((option, index) => {
                                                return (
                                                    <Chip
                                                        key={index}
                                                        variant="outlined"
                                                        label={option}
                                                        {...getTagProps({
                                                            index,
                                                        })}
                                                        onDelete={() => {
                                                            setMailBcc(mailBcc.filter((e) => e !== option));
                                                        }}
                                                    />
                                                );
                                            })
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                sx={{
                                                    "& .MuiInputBase-input": {
                                                        padding: "0",
                                                        color: `${currentMode == "dark" ? "white" : "black"} !important`,
                                                    },
                                                }}
                                                fullWidth
                                                {...params}
                                                onFocus={() => setFocused("Bcc")}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="flex items-center">
                                    <label className="basis-[15%]" htmlFor="">
                                        Subject
                                    </label>
                                    <input
                                        className={`border-${currentMode}-840 basis-[85%] px-[15px] py-[8px] flex-1 border-[1px] rounded-[8px] w-full border-[rgba(223,225,226,0.56)] ${
                                            currentMode == "dark" ? "disabled:text-white" : "disabled:text-light-40"
                                        }  bg-${currentMode}-810 ${viewId !== null && "text-light-40"}`}
                                        onChange={(e) => handleMailSubject(e)}
                                        onDragOver={(event) => event.preventDefault()}
                                        onDrop={handleDrop}
                                        value={mailSubject}
                                        disabled={viewId != null}
                                        maxLength={250}
                                        onFocus={onFocusSubject}
                                        ref={subjectRef}
                                    />
                                </div>
                            </form>
                            <div className={`allocate-textarea relative template my-5`}>
                                {/* <SunEditor
                                    setOptions={{
                                        plugins: plugins,
                                        buttonList: formats,
                                    }}
                                    height="700px"
                                    //onChange={handleEditorChange}
                                    setContents={content}
                                    onChange={setContent}
                                    onClick={onFocusEditor}
                                    readOnly={viewId != null}
                                    ref={editorRef}
                                /> */}
                                <div className="ckreply cktask">
                                    <CKEditor
                                        id={"editor1"}
                                        ref={editorRef}
                                        data={content}
                                        // onBeforeLoad={(CKEDITOR) => (CKEDITOR.disableAutoInline = true)}
                                        onChange={handleEditorChange}
                                        onClick={onFocusEditor}
                                        initData={listData?.email_message != undefined ? listData?.email_message : ""}
                                        onFocus={onFocusEditor}
                                        onInstanceReady={handleEditorReady}
                                        config={{
                                            removeButtons: "Image,Paste,About,PasteText,PasteFromWord,Anchor",
                                            removePlugins: "maximize",
                                            height: 325,
                                            readOnly: viewId != null,
                                        }}

                                        //style={{ height: "600px" }}
                                    />
                                </div>
                                {viewId === null ? (
                                    <div className="">
                                        <span
                                            onClick={handleClick}
                                            // aria-describedby={id}
                                            className="flex absolute cursor-pointer right-[25px] top-[10px]">
                                            Insert Placeholders{" "}
                                            <img className="ml-2 convertWhite" src="/images/common/chevron-grey.svg" alt="chevronDown" />
                                        </span>
                                        {showing && (
                                            <ClickAwayListener
                                                mouseEvent="onMouseDown"
                                                touchEvent="onTouchStart"
                                                onClickAway={(e) => handleClickAway(e)}>
                                                <div
                                                    className={`bg-${currentMode}-card absolute top-[40px] right-0 border-${currentMode}-1020 border-[1px]  ${
                                                        currentMode !== "dark" ? `shadow-[0px_4px_20px_rgba(85,85,85,0.2)]` : ``
                                                    }  rounded-[10px]`}>
                                                    <div className="grid grid-cols-1 gap-4">
                                                        <div className={`py-[20px]`}>
                                                            <span draggable="false" className="font-interSb mb-2 block pointer-events-none px-[30px]">
                                                                Invoice
                                                            </span>
                                                            <ul className="cursor-pointer">
                                                                {invoicePlaceHolderList?.length !== 0 ? (
                                                                    invoicePlaceHolderList?.map((el, i) => {
                                                                        return (
                                                                            <li
                                                                                onDragStart={(event) => {
                                                                                    event.dataTransfer.setData(
                                                                                        "text/plain",
                                                                                        el.placeholder_display_name
                                                                                    );
                                                                                }}
                                                                                onDragEnd={(event) => {
                                                                                    event.dataTransfer.clearData();
                                                                                }}
                                                                                className={`hover:bg-${currentMode}-960 px-[30px] userDrag py-2 w-full`}
                                                                                key={i}
                                                                                draggable={true}
                                                                                onClick={() => handleInvoiceClick(el?.placeholder_display_name)}>
                                                                                {el?.placeholder_display_name}
                                                                            </li>
                                                                        );
                                                                    })
                                                                ) : (
                                                                    <div className="px-[20px]">No data found</div>
                                                                )}
                                                            </ul>
                                                        </div>

                                                        {/* <div className="border-r-[1px] pr-[4rem]">
                        <span className="font-interSb mb-2 block">
                          Customer
                        </span>
                        {customerPlaceHolderList?.length !== 0
                          ? customerPlaceHolderList.map((el) => {
                              return (
                                <ul className="space-y-3">
                                  <li>{el?.placeholder_display_name}</li>
                                </ul>
                              );
                            })
                          : "no data found"}
                      </div>
                      <div>
                        <span className="font-interSb mb-2 block">
                          Organization
                        </span>
                        {organisationPlaceHolderList?.length !== 0
                          ? organisationPlaceHolderList.map((el) => {
                              return (
                                <ul className="space-y-3">
                                  <li onClick={""}>
                                    {el?.placeholder_display_name}
                                  </li>
                                </ul>
                              );
                            })
                          : "no data found"}
                      </div> */}
                                                    </div>
                                                </div>
                                            </ClickAwayListener>
                                        )}
                                    </div>
                                ) : (
                                    ``
                                )}
                            </div>
                        </div>
                    )}
                    {/* <FormControlLabel
            sx={{ marginBottom: 2 }}
            control={
              <Checkbox
                sx={{
                  color: "rgba(85,85,85,0.7)",
                  "&.Mui-checked": {
                    color: "#00B399",
                  },
                }}
                size="small"
              />
            }
            label="Set as Default"
          /> */}
                </Card>
                <div
                    className={`bg-${currentMode}-card text-${currentMode}-copy border-${currentMode}-700 py-[20px] space-x-2 px-[30px] sticky left-0 right-0 shadow-[0px_4px_20px_rgba(85,85,85,0.2)] border-[1px] border-[#dfe1e28f] rounded-b-[10px]`}>
                    <Button
                        type="submit"
                        onClick={handleSubmit}
                        disabled={
                            templateName?.length == 0 ||
                            mailSubject?.length == 0 ||
                            content == undefined ||
                            viewId != null ||
                            content == "<p><br></p>" ||
                            content == ""
                                ? true
                                : false
                        }
                        className="bg-light-20 text-base text-white rounded-[8px] py-2 px-8"
                        title={"Save"}>
                        {"Save"}
                    </Button>

                    <Link to="/emailtemplate" title="Cancel">
                        <Button className="bg-light-240 text-light-40 rounded-[8px] text-base py-2 px-7">Cancel</Button>
                    </Link>
                </div>
            </Main>
            <Footer />
        </Layout>
    );
};
