import { Accordion, AccordionDetails, Checkbox } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import CreateReminderModal from ".";
import { styled } from '@mui/material/styles';
import Button from "../../../components/Button";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import ReactDOM from "react-dom";
import EmailConfig from "../EmailConfigModal";
import { CheckBox } from "@mui/icons-material";
import Swal from "sweetalert2";
import { DELETE_ESCALATION_RESET, EMAIL_GENERATE_EXCEPTION_RESET } from "../../../redux/types/types";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { useDispatch } from "react-redux";
import { DeleteEscalationAct, viewExceptionCategoryByOrg } from "../../../redux/actions/exception-automation";
import { useSelector } from "react-redux";

export const EscalationReminder = (props) => {
    const { currentMode } = useContext(ThemeContext);
    const AccordionSummary1 = props?.AccordionSummary
    const dispatch = useDispatch();
    const [expandEscal, setExpandEscal] = useState("0");
    const [expandReminder, setExpandReminder] = useState("0");
    const [openEmailConfig, setOpenEmailConfig] = useState(false);
    const [openReminder, setOpenReminder] = useState(false);
    const setCategoryArr = props?.setCategoryArr;
    const statustype = props?.statustype;
    const currentId = props?.currentId;
    const [check, setCheck] = useState([]);
    const ViewArray = props?.selectedId?.escalation_remainder;
    const [reminderArr, setReminderArr] = useState(ViewArray ? ViewArray : []);
    const selectedId = props?.selectedId;
    const setSelectedId = props?.setSelectedId;
    const [index, setIndex] = useState(null);
    // const [selectedId, setSelectedId] = useState(null);
    const {
        data: postData,
        fetching: postFetching,
        requestComplete: postReqComp,
        error: postErr,
    } = useSelector((state) => state?.DeleteEscalationState);
    const handleChangeEscal = (panel) => (event, newExpanded) => {
        setExpandEscal(newExpanded ? panel : false);
    };
    const handleChangeReminder = (panel, ind) => (event, newExpanded) => {
        setReminderArr((prevReminders) => {
            return prevReminders.map((item, index) => {
                if (index === ind) {
                    return { ...item, isExpand: newExpanded ? panel : false };
                } else {
                    return { ...item, isExpand: false }; // Collapse other panels
                }
            });
        });
    };
    const handleChangeInput = (e, index, field) => {
        const { value } = e.target;
        const { checked } = e.target;

        setReminderArr((prevReminders) => {
            return prevReminders.map((item, i) => {
                if (i === index) {
                    return { ...item, [field]: field === "isfrequency" ? checked : +value };
                }
                return item;
            });
        });

        setCategoryArr((prevCheck) => {
            return prevCheck.map((ele) => {
                // Check and update the parent object
                if (props?.statustype == 1 && ele?.outside_id === props?.currentId) {
                    return {
                        ...ele,
                        escalation_remainder: ele.escalation_remainder.map((reminder, i) => {
                            if (i === index) {
                                return { ...reminder, [field]: field === "isfrequency" ? checked : +value };
                            }
                            return reminder;
                        }),
                    };
                }

                // Check and update the child exceptions
                if (ele?.child_exception) {
                    const updatedChildExceptions = ele.child_exception.map((child) => {
                        if (props?.statustype == 2 && child?.inside_id === props?.currentId) {
                            return {
                                ...child,
                                escalation_remainder: child.escalation_remainder.map((reminder, i) => {
                                    if (i === index) {
                                        return { ...reminder, [field]: field === "isfrequency" ? checked : +value };
                                    }
                                    return reminder;
                                }),
                            };
                        }
                        return child;
                    });

                    return {
                        ...ele,
                        child_exception: updatedChildExceptions,
                    };
                }

                return ele;
            });
        });
    };


    const OpenEmailConfig = (index) => {
        setOpenEmailConfig(true);
        setIndex(index);
        dispatch({ type: EMAIL_GENERATE_EXCEPTION_RESET })

    }

    // useEffect(() => {
    //     setSelectedId((prevVal) => ({
    //         ...prevVal,
    //         escalation_remainder: reminderArr
    //     }));

    // }, [reminderArr]);
    useEffect(() => {
        if (ViewArray) {
            setReminderArr(ViewArray);
        } else {
            setReminderArr([]);
        }
        if (!props?.isView) {
            reminderArr?.forEach(element => {
                element.isExpand = "0"
            });
            setExpandEscal('0')
        }
    }, [ViewArray, props?.isView])


    const OpenReminder = () => {
        if (reminderArr?.length >= 10) {
            Swal.fire({
                allowOutsideClick: false,
                showCancelButton: true,
                showCloseButton: true,
                text: "You have reached yor maximum escalations",
                icon: "warning",
                buttons: {
                    confirm: "Confirm",
                },
            });
        } else {
            setOpenReminder(true);
        }
    };
    const EndIconWrapper = styled('div')({
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
    });
    const DeleteException = (id, index) => {
        const reqBody = {
            "org_id": props?.orgId,
            "escalation_id": id
        }
        Swal.fire({
            text: "Are you sure you want to delete the details?",
            closeOnClickOutside: false,
            icon: "warning",
            showCloseButton: true,
            showCancelButton: true,
            buttons: {
                cancel: "Cancel",
                confirm: "Confirm",
            },
        }).then((confirm) => {
            if (confirm?.isConfirmed) {
                if (id == 0) {
                    setReminderArr((prevReminders) => {
                        return prevReminders.filter((_, i) => i !== index);
                    });
                    ViewArray?.splice(index, 1)
                    setCategoryArr((prevCheck) => {
                        return prevCheck.map((ele) => {
                            // Check and update the parent object
                            if (props?.statustype == 1 && ele?.outside_id === props?.currentId) {
                                return {
                                    ...ele,
                                    escalation_remainder: ele.escalation_remainder.filter((_, i) => i !== index),
                                };
                            }

                            // Check and update the child exceptions
                            if (ele?.child_exception) {
                                const updatedChildExceptions = ele.child_exception.map((child) => {
                                    if (props?.statustype == 2 && child?.inside_id === props?.currentId) {
                                        return {
                                            ...child,
                                            escalation_remainder: child.escalation_remainder.filter((_, i) => i !== index),
                                        };
                                    }
                                    return child;
                                });

                                return {
                                    ...ele,
                                    child_exception: updatedChildExceptions,
                                };
                            }

                            return ele;
                        });
                    });
                    Swal.fire({
                        text: `Deleted Successfully!`,
                        closeOnClickOutside: false,
                        icon: "success",
                        showCloseButton: true,
                        buttons: {
                            confirm: "Confirm",
                        },
                    })
                }
                else {
                    dispatch(DeleteEscalationAct(reqBody));
                }
            }

        });
    }
    if (postReqComp && postData?.status) {
        Swal.fire({
            text: `${postData?.message}`,
            closeOnClickOutside: false,
            icon: "success",
            showCloseButton: true,
            buttons: {
                confirm: "Confirm",
            },
        }).then((confirm) => {
            if (confirm) {
                window.location.reload();
                // dispatch(viewExceptionCategoryByOrg(props?.orgId));
                dispatch({ type: DELETE_ESCALATION_RESET });
            }

        });
    }
    const AccordionSummary = styled((props) => (

        <MuiAccordionSummary expandIcon={<img src="/images/header/chevronDown.svg" className={`convertWhite ${props?.expand == "1" ? 'mr-[10px]' : ''} `} alt="accordion-active" />} {...props} />
    ))(({ theme }) => ({
        padding: "11px 30px",
        margin: 0,
        flexDirection: "row-reverse",
        "& .MuiAccordionSummary-expandIconWrapper": {
            marginRight: 10,
            transform: "rotate(270deg)",
            filter: `${currentMode === "dark" ? "filter: brightness(0) invert(1)" : "brightness(0) invert(0)"}`,
        },
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(360deg)",
            filter: "none",
            minHeight: "0px",
            margin: "0px",
        },
        "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: 0,
        },
        "& .MuiAccordionSummary-content": {
            margin: 0,
        },
        "& .MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded": {
            minHeight: 0,
        },
    }));
    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: 0,
        border: 0,
    }));
    return (

        <div className={`${!props?.isView ? 'cursor-not-allowed  ' : ''} px-[20px]`}>
            <Accordion
                sx={{
                    "&.MuiPaper-root": {
                        boxShadow: "none", // Your desired border color
                        border: `1px solid ${currentMode == "dark" ? "#333" : "rgba(223, 225, 226, 0.56)"} !important`,
                    },
                }}
                disabled={!props?.isView}
                expanded={expandEscal === "1"}
                onChange={handleChangeEscal("1")}
            >
                <AccordionSummary1
                    sx={{
                        "&.Mui-expanded": {
                            minHeight: "49px" /* Set your desired min-height here */
                        },
                        "& .MuiAccordionSummary-content.Mui-expanded": {
                            margin: "0px",
                        }
                    }}
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    className={`${expandEscal == 0 ? `bg-${currentMode}-card` : `bg-${currentMode}-1420`} text-${currentMode}-copy  rounded-[8px] ${currentMode === "dark" ? "" : "shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)]"
                        } `}
                >
                    <img src="/images/Exception-Automation/reminder.svg" className="convertWhite"></img>{" "}
                    <span className="font-interM ml-3 ">Escalation and Reminder</span>
                </AccordionSummary1>

                <AccordionDetails className="px-[20px] py-[20px] space-y-4">
                    {postFetching && (
                        <div className="h-screen flex bg-[rgb(231,225,225,0.5)] absolute top-0 z-[12] left-0 right-0 justify-center items-center w-full">
                            {" "}
                            <img className="w-[7rem]" src="/images/gif/Loading-Gif.gif" alt="Loading-Gif" />
                        </div>
                    )}
                    {reminderArr?.map((item, index) => {
                        return (
                            <Accordion
                                sx={{
                                    "&.MuiPaper-root": {
                                        boxShadow: "none",
                                        border: "none !important",
                                    },
                                    "&.MuiAccordion-root:before": {
                                        display: "none !important"
                                    }
                                }}
                                expanded={item?.isExpand === "1"}
                                onChange={handleChangeReminder("1", index)}
                            >
                                <AccordionSummary
                                    expand={item?.isExpand}
                                    sx={{
                                        "&.Mui-expanded": {
                                            minHeight: "49px" /* Set your desired min-height here */
                                        },
                                        "& .MuiAccordionSummary-content.Mui-expanded": {
                                            margin: "0px",
                                        }
                                    }}
                                    aria-controls="panel1d-content"
                                    id="panel1d-header"
                                    className={`bg-${currentMode}-1420 text-${currentMode}-copy `}
                                >
                                    <span title={item?.reminder_name} className="font-interM truncate w-[350px]">{item?.reminder_name}</span>
                                    <EndIconWrapper onClick={() => DeleteException(item?.reminder_id, index)}>
                                        <img src="/images/Exception-Automation/trash.svg" alt="end-icon" />
                                    </EndIconWrapper>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="ml-[20px]">
                                        <div>
                                            <p className="mt-[20px]">
                                                If the recipient does not respond to an exception email, you can send the escalation and reminder email.
                                            </p>

                                            <div className="flex items-center mt-[20px]">
                                                <div>
                                                    <p className="font-interM text-sm text-[#000]"><span className="text-[red] mr-1">*</span>Set your escalation and reminder in days:</p>
                                                    <div className="flex justify-between items-center space-x-[20px]">
                                                        <div
                                                            className={`flex justify-between border border-${currentMode}-840 rounded-lg overflow-hidden w-[115px] h-[40px] mt-[10px] ${currentMode == "dark" ? "bg-[#353535]" : ""
                                                                }`}
                                                        >
                                                            <div className={`basis-[50%] border-r-2 py-[10px] px-[10px] border-${currentMode}-840`}>
                                                                <input
                                                                    type="tel"
                                                                    className=" w-full bg-transparent focus:outline-none text-center"
                                                                    value={item?.esc_from_date}
                                                                    maxLength={3}
                                                                    onChange={(e) => handleChangeInput(e, index, "esc_from_date")}
                                                                // name="time"
                                                                />
                                                            </div>
                                                            <div className="basis-[50%]">
                                                                <input
                                                                    type="text"
                                                                    className="w-full bg-transparent text-center mt-2 pointer-events-none focus:outline-none"
                                                                    value={"Days"}
                                                                    name="time"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <span> Reminder ends in</span>
                                                        </div>
                                                        <div>
                                                            <div
                                                                className={`flex justify-between border border-${currentMode}-840 rounded-lg overflow-hidden w-[115px] h-[40px] mt-[10px]  ${currentMode == "dark" ? "bg-[#353535]" : ""
                                                                    }`}
                                                            >
                                                                <div className={`basis-[50%] border-r-2 py-[10px] px-[10px] border-${currentMode}-840`}>
                                                                    <input
                                                                        type="text"
                                                                        value={item?.esc_to_date}
                                                                        maxLength={3}
                                                                        onChange={(e) => handleChangeInput(e, index, "esc_to_date")}
                                                                        className=" w-full bg-transparent focus:outline-none text-center"
                                                                        name="time"
                                                                    />
                                                                </div>
                                                                <div className="basis-[50%]">
                                                                    <input
                                                                        type="text"
                                                                        className=" w-full bg-transparent text-center mt-2 pointer-events-none focus:outline-none"
                                                                        value={"Days"}
                                                                        name="time"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <hr className={`mt-3 border-${currentMode}-1240`} />

                                        <div className="flex ">
                                            <Checkbox
                                                size="small"
                                                value={item?.isfrequency}
                                                checked={item?.isfrequency}
                                                onChange={(e) => handleChangeInput(e, index, "isfrequency")}
                                                sx={{
                                                    color: "rgba(85,85,85,0.7)",
                                                    "&.Mui-checked": {
                                                        color: "#028C78",
                                                    },
                                                }}
                                            />
                                            <p className="mt-[10px]">Select this checkbox to send email reminders at the set frequency.</p>
                                        </div>
                                        <div className="">
                                            <p className=" font-interM  font-medium text-base mt-[10px]">Set your escalation and reminder frequency in hours:</p>
                                            <div className="flex justify-between mb-[30px]">
                                                <div
                                                    className={`flex justify-between border border-${currentMode}-840 rounded-lg overflow-hidden w-[115px] mt-[10px] h-[40px] ${currentMode == "dark" ? "bg-[#353535]" : ""
                                                        }`}
                                                >
                                                    <div className={`basis-[50%] border-r-2 py-[10px] px-[10px] border-${currentMode}-840`}>
                                                        <input
                                                            type="text"
                                                            value={item?.esc_frequency ? item?.esc_frequency : ''}
                                                            disabled={!item?.isfrequency}
                                                            maxLength={3}
                                                            onChange={(e) => handleChangeInput(e, index, "esc_frequency")}
                                                            className=" w-full bg-transparent focus:outline-none text-center"
                                                            name="time"
                                                        // onKeyDown={(e) => {
                                                        //     if (e.key === '0') {
                                                        //         e.preventDefault();
                                                        //     }
                                                        // }}
                                                        />
                                                    </div>
                                                    <div className="basis-[50%]">
                                                        <input
                                                            type="text"
                                                            className=" w-full bg-transparent text-center mt-2 pointer-events-none  focus:outline-none"
                                                            value={"Hours"}
                                                        />
                                                    </div>
                                                </div>
                                                <div className=" mt-[20px]">
                                                    <Button className="text-[#028C78] mt-4 text-left" onClick={() => OpenEmailConfig(index)}>
                                                        {" "}
                                                        <span className="flex space-x-2">
                                                            <span className="text-[red] mr-1">*</span>
                                                            {" "}
                                                            {item?.email_to?.length > 0 ? (
                                                                <img src="/images/Exception-Automation/email-config-tick.svg"></img>
                                                            ) : (
                                                                "+"
                                                            )}{" "}
                                                            <p> Email Configuration</p>{" "}
                                                            {item?.email_to?.length > 0 ? (
                                                                <img src="/images/Exception-Automation/email-config-edit.svg"></img>
                                                            ) : (
                                                                ""
                                                            )}{" "}
                                                        </span>
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}

                    <Button className="text-[#028C78] mt-4 text-left mb-[20px] ml-[20px]" onClick={() => OpenReminder()}>
                        {" "}
                        + Add New Escalation and Reminder
                    </Button>
                </AccordionDetails>
            </Accordion >
            {
                ReactDOM.createPortal(
                    <CreateReminderModal
                        isOpened={openReminder}
                        setCategoryArr={setCategoryArr}
                        handleClose={() => setOpenReminder(false)}
                        setReminderArr={setReminderArr}
                        reminderArr={reminderArr}
                        selectedId={selectedId}
                        setSelectedId={setSelectedId}
                        statustype={statustype}
                        currentId={currentId}
                        className={openReminder && "Show"}
                    />,

                    document.getElementById("user-modal-root")
                )
            }
            {
                ReactDOM.createPortal(
                    <EmailConfig
                        isOpened={openEmailConfig}
                        UsersOptions={props?.UsersOptions}
                        FindSelectName={props?.FindSelectName}
                        setCategoryArr={setCategoryArr}
                        statustype={statustype}
                        currentId={currentId}
                        reminderArr={reminderArr}
                        handleClose={() => setOpenEmailConfig(false)}
                        className={openEmailConfig && "Show"}
                        selectedId={selectedId}
                        setSelectedId={setSelectedId}
                        index={index}
                        check={check}
                        setCheck={setCheck}
                    />,

                    document.getElementById("user-modal-root")
                )
            }
        </div >
    );
};
