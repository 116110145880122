import Card from "../../components/UI/Card";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import Button from "../../components/Button";
import ReactDOM from "react-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import Input from "../../components/Input";
import Swal from "sweetalert2";
import TogglePopup from "../../pages/organisations/Toggle";
import { useState, useEffect, useRef, useContext } from "react";
import { getDocumentFromS3ForAdvance } from "../../redux/actions/advance-request.js";
import { Tooltip } from "@mui/material";
import StatusIcon from "../../pages/AdavnceRequest/MobilizationRequest/StatusIcon";
import { GetUserActivity } from "../../redux/actions/user.js";
import { postChangeWorkflowStatus } from "../../redux/actions/workflow-automation";
import { POST_CHANGE_WORKFLOW_STATUS_RESET } from "../../redux/types/types.js";
let proccessed = ""
const StatusButton = (props) => {
    const { DisplayRole } = props;
    const dispatch = useDispatch();
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const location = useLocation();
    const [isHovered, setIsHovered] = useState({
        reject: false,
        currentStatus: false,
        nextStatus: false,
    });
    const currentStatusId = props?.currentStatusInfo?.[0]?.status_id;
    const nextStatusId = props?.nextStatusInfo?.[0]?.next_status;

    const statusDefaultList = [
        {
            name: "reject",
            masterId: 1,
            title: "Reject",
            clickAllow:
                DisplayRole?.actions?.find((ele) => ele?.id == 9)?.isallowed && ![3, 4, 5]?.includes(props?.currentStatusInfo?.[0]?.master_refid),
        },
        { name: "currentStatus", masterId: 2, title: "", clickAllow: true },
        { name: "nextStatus", masterId: 3, title: "", clickAllow: ![3, 4, 5]?.includes(props?.currentStatusInfo?.[0]?.master_refid) },
    ];
    const statusTabList = [
        { name: "resetPage", masterId: 4, title: "Reset Page", clickAllow: true },
        { name: "download", masterId: 5, title: "Download", clickAllow: true },
        { name: "activity", masterId: 6, title: "User Activity", clickAllow: true },
    ];
    const nextUserList = props?.nextUserList;
    const orgId = localStorage.getItem("orgId") || 0;
    const checkReject = !props?.RoleActAdvreject && location?.pathname == "/mobilization-request";
    const [selectedUserId, setSelectedUserId] = useState([]);
    const navigate = useNavigate();
    const userRoleId = JSON.parse(localStorage.getItem("user"));
    const { data, fetching: statusUpdateLoading, requestComplete, error } = useSelector((state) => state?.postWorkFlowStatusChangeState);
    useEffect(() => {
        if ((data && requestComplete) || (error && requestComplete)) {

            Swal.fire({
                text: `${error ? error : proccessed == "reject" ? "The request has been Rejected successfully" : "The status has been updated successfully."}`,
                icon: `${error ? "warning" : "success"}`,
                closeOnClickOutside: false,
                showCloseButton: true,
                // showCancelButton: error ? false : true,
                buttons: true,
                dangerMode: true,
            }).then((result) => {
                if (result.isConfirmed || result.isDismissed) {
                    proccessed = ""
                    dispatch({ type: POST_CHANGE_WORKFLOW_STATUS_RESET });
                    if (data?.status) {
                        navigate(location?.state?.from);
                    } else if (error) {
                        window.location.reload();
                    }
                }
            });
        }
    }, [requestComplete]);
    const statusReqObj = props?.statusReqObj;
    const postStatusUpdate = (process, statusId, Name, wfStatusUser) => {
        proccessed = process
        if (currentStatusId == nextStatusId && process != "reject") {
            Swal.fire({
                text: `The current status and the next status are the same. Please reconfigure the workflow.`,
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: true,
                dangerMode: true,
            }).then((result) => { });
            return;
        }
        setSelectedUserId(wfStatusUser);

        const updatedStatusReqObjArray = {
            ...statusReqObj, // Copying the existing object
            inv_id: props?.reqId,
            org_id: +orgId,
            type: 1,
            groupType: props?.isRetention ? 4 : props?.isMisc ? 3 : 2,
            status_id: process === "reject" ? null : statusId,
            masterref_id: process === "reject" ? 5 : null,
            user_id: props?.nextStatusInfo?.[0]?.round_robin || !wfStatusUser || wfStatusUser?.length == 0 ? null : wfStatusUser?.[0]?.id,
            // user_id: wfStatusUser && props?.nextStatusInfo?.[0]?.round_robin ? (wfStatusUser?.length === 1 ? wfStatusUser?.[0]?.id : null) : null,
            round_robin: props?.nextStatusInfo?.[0]?.round_robin,
            user_array: props?.nextStatusInfo?.[0]?.user_array,
        };
        if (wfStatusUser?.length > 1 && !props?.nextStatusInfo?.[0]?.round_robin && process != "reject") {
            props?.setSelectUserAnchor(true);
        } else {
            Swal.fire({
                text: `Are you sure you want to move to the ${process == "reject" ? "Reject" : Name} request?`,
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                showCancelButton: true,
                buttons: true,
                dangerMode: true,
            }).then((result) => {
                if (result.isConfirmed && process === "next") {
                    dispatch(postChangeWorkflowStatus({ upd_status: [updatedStatusReqObjArray] }));
                } else if (result.isConfirmed && process === "reject") {
                    dispatch(postChangeWorkflowStatus({ upd_status: [updatedStatusReqObjArray] }));
                }
            });
        }
    };
    const checkEdit = !props?.RoleActAdvEdit && location?.pathname == "/mobilization-request";
    const handleOptionChange = (e) => {
        switch (e) {
            case 4:
                window.location.reload();
                break;
            case 5:
                props?.handleDownload();
                break;
            case 6:
                props?.handleOpenActivity();
                break;
            default:
                break;
        }
    };
    return (
        <>
            {statusDefaultList?.map((el) => {
                return (
                    <Tooltip
                        title={
                            el?.name !== "reject" ? (
                                <span>
                                    {el?.name == "currentStatus" ? "Current Status:" : "Next Status:"}{" "}
                                    <span style={{ color: "#00B399" }}>
                                        {el?.name == "currentStatus"
                                            ? props?.currentStatusInfo?.[0]?.status_name
                                            : el?.name == "nextStatus"
                                                ? props?.nextStatusInfo?.[0]?.status_name
                                                : "" || ""}
                                    </span>
                                </span>
                            ) : (
                                el?.title
                            )
                        }
                        disablePortal
                        placement="top"
                        disableInteractive
                        PopperProps={{
                            sx: {
                                "& .MuiTooltip-tooltip": {
                                    fontSize: "0.9em",
                                    maxHeight: "120px",
                                    maxWidth: "250px",
                                    background: "#DAF9F4",
                                    color: "#555555",
                                    borderRadius: "5px",
                                    marginTop: "0 !important",
                                },
                                "& .MuiTooltip-arrow": {
                                    color: "#DAF9F4",
                                },
                            },
                        }}
                        arrow
                    >
                        <li
                            key={el?.name}
                            onMouseEnter={() => setIsHovered({ ...isHovered, [el?.name]: true })}
                            onMouseLeave={() => setIsHovered({ ...isHovered, [el?.name]: false })}
                            onClick={() =>
                                el?.name == "currentStatus" || (checkReject && el?.name == "reject") || (checkEdit && el?.name !== "reject")
                                    ? null
                                    : postStatusUpdate(
                                        el?.name == "reject" ? "reject" : el?.name == "nextStatus" && "next",
                                        props?.nextStatusInfo?.[0]?.next_status,
                                        props?.nextStatusInfo?.[0]?.status_name,
                                        nextUserList
                                        //   props?.currentStatusInfo?.[0]
                                    )
                            }
                            className={el?.clickAllow ? "cursor-pointer" : "pointer-events-none opacity-5"}
                        >
                            <StatusIcon
                                backgroundColour={
                                    el?.name === "reject" && isHovered?.reject
                                        ? "red"
                                        : isHovered?.[el.name]
                                            ? "#00B399"
                                            : currentMode == "dark"
                                                ? "white"
                                                : "black"
                                }
                                masterId={el?.masterId}
                            />
                        </li>
                    </Tooltip>
                );
            })}
            {props?.findScreeSize < 1180 && (
                <>
                    {statusTabList?.map((el) => {
                        return (
                            <Tooltip
                                title={el?.title}
                                disablePortal
                                placement="top"
                                disableInteractive
                                PopperProps={{
                                    sx: {
                                        "& .MuiTooltip-tooltip": {
                                            fontSize: "0.9em",
                                            maxHeight: "120px",
                                            maxWidth: "250px",
                                            background: "#DAF9F4",
                                            color: "#555555",
                                            borderRadius: "5px",
                                            marginTop: "0 !important",
                                        },
                                        "& .MuiTooltip-arrow": {
                                            color: "#DAF9F4",
                                        },
                                    },
                                }}
                                arrow
                            >
                                <li
                                    key={el?.name}
                                    onMouseEnter={() => setIsHovered({ ...isHovered, [el?.name]: true })}
                                    onMouseLeave={() => setIsHovered({ ...isHovered, [el?.name]: false })}
                                    onClick={() => handleOptionChange(el?.masterId)}
                                // className={el?.clickAllow ? "cursor-pointer" : "pointer-events-none opacity-5"}
                                >
                                    {" "}
                                    <StatusIcon
                                        backgroundColour={isHovered?.[el.name] ? "#00B399" : currentMode == "dark" ? "white" : "black"}
                                        masterId={el?.masterId}

                                    // onClick={props?.onFilterItems(el?.id, el?.name)}
                                    />
                                </li>
                            </Tooltip>
                        );
                    })}
                </>
            )}
            {props?.findScreeSize > 1180 && (
                <>
                    {" "}
                    <Button
                        className="flex md:hidden xl:flex cursor-pointer px-[10px] more-step"
                        onClick={(e) => props?.childCompRef.current.handleClick("bottom-end", 1, e)}
                    >
                        <img
                            title="More Options"
                            src={`${currentMode == "dark" ? "/images/mobilization/more-vertical-darkmode.svg" : "/images/mobilization/more-vertical.svg"
                                } `}
                        />
                    </Button>
                    <TogglePopup
                        lowOverlay={true}
                        invoice={true}
                        ref={props?.childCompRef}
                        menuList={props?.filterMenuList?.filter((el) => el)}
                        selectedItemChange={props?.onFilterItems}
                    />
                </>
            )}
        </>
    );
};
export default StatusButton;
