import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/Button";
import Modal from "../../../components/UI/Modal/Modal";
import { useContext, useEffect, useState } from "react";
import { decryptId } from "../../../utils/helpers";
import { UserRoleDelete } from "../../../redux/actions/user";
import { GET_ORGANISATION_RESET, USER_ROLEBYORG_VIEW_RESET, USER_ROLE_DELETE_RESET } from "../../../redux/types/types";
import SuccessMessageBox from "../../../components/UI/Alert/sucess";
import MessageBox from "../../../components/UI/Alert";
import { viewUserRoleByOrg } from "../../../redux/actions/role";
import moment from "moment";
import Swal from "sweetalert2";
import Loader from "../../../components/Loader";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";

const successMsg = "Users role deleted successfully";
const UserRoleView = (props) => {
    const { currentMode } = useContext(ThemeContext);
    const dispatch = useDispatch();
    const orgStatus = props?.orgStatus;

    const [roleId, setRoleId] = useState("");
    const [userData, setUserData] = useState();
    const [userRoleList, setUserRoleList] = useState(props.userRoleList);
    const { requestComplete, data, fetching } = useSelector((state) => state?.UserRoleDeleteState);
    const OrgId = window.localStorage.getItem("selectedOrgId");
    const reqObject = {
        org_id: +OrgId,
    };
    const { role, requestComplete: reqComp, fetching: fetch } = useSelector((state) => state?.ViewUserByRoleOrgState);
    //role.length > 0 ? setUserData([role]):""
    const [onDelete, setonDelete] = useState(false);
    const onDeleteRole = (roleId, isallocation, istask) => {
        setonDelete(true);
        if (isallocation == true) {
            Swal.fire({
                text: "The user cannot be removed since invoices have been assigned.",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else if (istask == true) {
            Swal.fire({
                text: "User cannot be removed since tasks are still pending!",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else {
            Swal.fire({
                text: "Are you sure you want to delete this user from the organization?",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                showCancelButton: true,
                buttons: {
                    cancel: "Cancel",
                    confirm: "Confirm",
                },
            }).then((confirm) => {
                if (confirm?.isConfirmed) {
                    const deleteObj = {
                        id: roleId,
                    };
                    dispatch(UserRoleDelete(deleteObj));
                    setRoleId(roleId);
                    // dispatch({ type: USER_ROLEBYORG_VIEW_RESET });
                }
            });
        }
    };

    if (role?.length == 0 && onDelete) {
        setTimeout(() => {
            props?.handleClose();
            window.location.reload();
            dispatch({ type: USER_ROLE_DELETE_RESET });
        }, 1000);
    }
    if (requestComplete) {
        // setTimeout(() => {
        //     dispatch({ type: USER_ROLE_DELETE_RESET });
        // }, 1000);

        // else {
        //     setTimeout(() => {
        //         const deletedRoleList = userRoleList?.filter(
        //             (item) => item.identity_id !== roleId
        //         );
        //         setUserRoleList([...deletedRoleList]);
        //     }, 1000);

        // }

        setTimeout(() => {
            dispatch({ type: USER_ROLE_DELETE_RESET });
            if (role?.length !== 0) {
                dispatch(viewUserRoleByOrg(reqObject));
            }
        }, 1000);
    }
    const DisplayRole = useSelector((state) => state?.DisplayRolePermissionStateAction);
    const { data: DataList } = DisplayRole;
    const RoleList = DataList?.role_info;
    const RoleMenu = RoleList?.menu;
    const RoleUserRole = RoleMenu?.find((elem) => elem?.menu_id === 5);
    const RoleUserRoleAction = RoleUserRole?.actions;
    const RoleActUserRoleDelete = RoleUserRoleAction?.find((element) => element.id === 12);
    const handleClose = () => {
        dispatch({ type: GET_ORGANISATION_RESET });
        dispatch({ type: USER_ROLE_DELETE_RESET });
        props?.handleClose();
        window.location.reload();
    };

    return (
        <>
            {requestComplete && data?.status && reqComp && <SuccessMessageBox success={successMsg} />}
            {requestComplete && !data?.status && <MessageBox error={data?.message} />}
            <Modal cartClose={props?.handleClose} className={`${props?.className}`}>
                <Link
                    onClick={requestComplete && data?.status ? handleClose : props?.handleClose}
                    className="p-[6.5px] absolute right-[670px] bg-light-10">
                    <img src="/images/common/whiteClose.svg" alt="whiteClose" />
                </Link>
                <div
                    className={`max-w-[670px] bg-${currentMode}-card ml-auto overflow-y-auto transition duration-150 ease-out flex-col flex h-lvh max-h-[-webkit-fill-available] `}>
                    <div className={`px-[30px] p-[24px] border-b-[1px] border-${currentMode}-700 relative`}>
                        <h4 className="font-interSb text-lg">Users and Roles</h4>
                    </div>
                    <div className="h-[calc(100vh_-_77px)] overflow-y-auto">
                        <table className={`table-auto  w-full roleTable`}>
                            <thead className={`text-left font-interM bg-${currentMode}-360 `}>
                                <tr>
                                    <th>User</th>
                                    <th>Role</th>
                                    <th>Date of Add</th>
                                    <th className={`${RoleActUserRoleDelete?.isallowed == false && "opacity-0"}`}>Action</th>
                                </tr>
                            </thead>
                            {/* {!reqComp ?  <Loader className="h-[calc(80vh_-_15rem)]"/> : */}
                            <tbody>
                                {reqComp && !role ? (
                                    <Loader className="h-full" />
                                ) : (
                                    <>
                                        {role?.map((elem) => (
                                            <tr key={elem?.id}>
                                                <td className={`border-${currentMode}-590`}>
                                                    <div className="flex items-center w-[15rem] breaks-word">
                                                        <img
                                                            src={
                                                                elem?.user_image
                                                                    ? `data:image;base64,${elem?.user_image}`
                                                                    : "/images/common/default-user-pic.png"
                                                            }
                                                            className="w-[30px] h-[30px] object-contain"
                                                            alt="user-1"
                                                        />{" "}
                                                        <div className="ml-4">
                                                            <span className="block truncate max-w-[100px]" title={elem?.username}>
                                                                {elem?.username}
                                                            </span>
                                                            <p className="block text-xs">{decryptId(elem?.emailaddress)}</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className={`border-${currentMode}-590`}>{elem?.role_name}</td>
                                                <td className={`border-${currentMode}-590`}>{elem?.created_date}</td>
                                                <td className={`border-${currentMode}-590`}>
                                                    {elem?.isadmin == false && (
                                                        <Button
                                                            disabled={orgStatus ? false : true}
                                                            onClick={() => onDeleteRole(elem?.identity_id, elem?.isallocation, elem?.istask)}
                                                            className={`${RoleActUserRoleDelete?.isallowed == false && "hidden"} `}>
                                                            <img src="/images/common/trash-ico.svg" alt="trash-ico" className="convertWhite" />
                                                        </Button>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                )}
                            </tbody>
                        </table>
                        {!reqComp ? <Loader className="h-full" /> : null}
                        {reqComp && role?.length === 0 ? (
                            <div className="px-[30px] h-full grid place-items-center place-content-center">
                                {" "}
                                <img className="mb-3" src="/images/common/delivery.png" alt="searching" />
                                <h4 className="font-interSb block">No Data found</h4>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default UserRoleView;
