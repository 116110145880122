import { Link } from "react-router-dom";
import Button from "../../components/Button";
import Card from "../../components/UI/Card";
import Main from "../../components/UI/Main";
import Layout from "../../Hoc/Layout";
import Footer from "../../organisms/Footer";
import { ActivateDeactivateRule, GetRuleList } from "../../redux/actions/rule-based";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useContext } from "react";
import Swal from "sweetalert2";
import { useState } from "react";
import { GET_VIEW_RULE_RESET, ADD_RULE_LIST_RESET, ACTIVATE_DEACTIVATE_RULE_RESET, GET_RULE_VIEW_RESET } from "../../redux/types/types";
import Loader from "../../components/Loader";
import MessageBox from "../../components/UI/Alert";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { RoleDisplay } from "../../utils/helpers";
import { MenuItem, Select } from "@mui/material";

const InvoiceRule = () => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const orgId = localStorage.getItem("orgId");
    const [state, setState] = useState(false);
    const dispatch = useDispatch();
    const RoleMenu = RoleDisplay();
    const RoleAdvance = RoleMenu?.find((elem) => elem?.menu_id === 21);
    const RoleAdvanceAction = RoleAdvance?.actions;
    const RoleActAdvance = RoleAdvanceAction?.find((element) => element.id === 3)?.isallowed;
    const RoleMisc = RoleMenu?.find((elem) => elem?.menu_id === 22);
    const RoleMiscAction = RoleMisc?.actions;
    const RoleActMisc = RoleMiscAction?.find((element) => element.id === 3)?.isallowed;
    const RoleReten = RoleMenu?.find((elem) => elem?.menu_id === 23);
    const RoleRetenAction = RoleReten?.actions;
    const RoleActReten = RoleRetenAction?.find((element) => element.id === 3)?.isallowed;
    const [documentGroup, setDcoumetGroup] = useState(1);
    const handleDocumentChange = (value) => {
        if (value !== 0) {
            setDcoumetGroup(value);
        }
    };
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#555555`;
    useEffect(() => {
        dispatch(GetRuleList(documentGroup));
        dispatch({ type: GET_RULE_VIEW_RESET });
        dispatch({ type: ADD_RULE_LIST_RESET });
    }, [dispatch, documentGroup]);
    const Rule = useSelector((state) => state?.GetRuleListState);
    const { data: RoleData, requestComplete, fetching } = Rule;
    const Status = useSelector((state) => state?.ActivateDeactivateState);

    const errormsg = Status?.error ? Status?.error?.message : "Something went wrong please contact admin";
    const { data } = useSelector((state) => state?.ActivateDeactivateState);

    const StatusChange = (id, status, alloacted) => {
        setState(status);
        if (alloacted == true && status == false) {
            Swal.fire({
                text: "The rule is already allocated; it cannot be inactive!",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    confirm: true,
                },
            });
        } else {
            Swal.fire({
                closeOnClickOutside: false,
                text: status == false ? "Are you sure you want to deactivate?" : "Are you sure you want to activate?",
                icon: "warning",
                showCloseButton: true,
                showCancelButton: true,
                buttons: {
                    cancel: "Cancel",
                    confirm: "Confirm",
                },
            }).then((confirm) => {
                if (confirm?.isConfirmed) {
                    dispatch(ActivateDeactivateRule(id, status));
                }
            });
        }
    };
    if (Status?.requestComplete && data?.status) {
        Swal.fire({
            closeOnClickOutside: false,
            text: state == true ? " Activated successfully!" : "Deactivated successfully!",
            icon: "success",
            button: "Ok",
            showCloseButton: true,
        }).then((confirm) => {
            if (confirm) {
                dispatch(GetRuleList(documentGroup));
                dispatch({ type: ACTIVATE_DEACTIVATE_RULE_RESET });
            }
        });
    }
    if (Status?.error?.status === false) {
        setTimeout(() => {
            dispatch({ type: ACTIVATE_DEACTIVATE_RULE_RESET });
        }, 3000);
    }
    const documentArray = [
        { id: 1, value: "Invoice" },
        { id: 2, value: "Advance Request" },
        { id: 3, value: "Miscellaneous Payment Request " },
        { id: 4, value: "Retention Release" },
    ];

    return (
        <>
            <Layout>
                <Main>
                    {Status?.error?.status === false && <MessageBox error={errormsg} />}
                    <div className={`lg:flex justify-between mb-[10px] space-y-4 xl:space-y-0 items-center text-${currentMode}-copy`}>
                        <div className="flex justify-between items-center mb-6">
                            <div>
                                <h4 className={`text-2xl font-interSb `}>Rule Master</h4>
                            </div>
                        </div>
                        <div className="flex flex-wrap xl:space-x-2 md:space-x-2">
                            <div className="mr-[10px] mt-2 font-inter text-base font-medium">
                                <span>Document Type:</span>
                            </div>
                            <div className="ml-[75rem]">
                                <Select
                                    // className="ml-[75rem]"
                                    value={documentGroup || "0"}
                                    name="documentGroup"
                                    onChange={(e) => handleDocumentChange(e.target.value)}
                                    inputProps={{
                                        "aria-label": "Without label",
                                    }}
                                    fullWidth
                                    IconComponent={() => (
                                        <img
                                            className="absolute right-[15px] opacity-50 pointer-events-none"
                                            src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                            alt="chevronDown"
                                        />
                                    )}
                                    MenuProps={{
                                        sx: {
                                            "& .MuiMenu-paper": {
                                                backgroundColor: dropdownColor,
                                                color: dropdownText,
                                            },
                                            "& .MuiMenuItem-root:hover": {
                                                backgroundColor: "rgba(218, 249, 244, 0.3)",
                                            },
                                            "& .Mui-selected": {
                                                backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                            },
                                        },
                                    }}
                                    SelectDisplayProps={{
                                        style: {
                                            paddingTop: "9px",
                                            paddingBottom: "9px",
                                            color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                            paddingRight: "30px",
                                            maxWidth: "253px",
                                            minWidth: "253px",
                                            border: `1px solid rgba(85,85,85,0.4)`,
                                            borderRadius: "8px",
                                        },
                                    }}>
                                    <MenuItem value={1} className="truncate trunc-1 block">
                                        {"Invoice"}
                                    </MenuItem>

                                    {RoleAdvance && (
                                        <MenuItem value={2} className="truncate trunc-1 block">
                                            {"Advance Request"}
                                        </MenuItem>
                                    )}
                                    {RoleActMisc && (
                                        <MenuItem value={3} className="truncate trunc-1 block">
                                            {"Miscellaneous Payment Request"}
                                        </MenuItem>
                                    )}
                                    {RoleActReten && (
                                        <MenuItem value={4} className="truncate trunc-1 block">
                                            {"Retention Release"}
                                        </MenuItem>
                                    )}
                                </Select>
                            </div>

                            <div className="flex space-x-2">
                                <Link to="/invoice-rulebased">
                                    <Button
                                        title="To create new Rule"
                                        className={`border-20 bg-light-20 px-5 py-2 rounded-[8px] font-interM text-sm flex items-center border-[1px] text-white border-${currentMode}-800 `}>
                                        <img className="mr-1" src="/images/common/plus.svg" alt="plus" /> New Rule
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <Card
                        className={`xl:h-[calc(100vh_-_14.5rem)] h-[calc(100vh_-_18rem)] ${
                            currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                        } overflow-y-auto mb-[5rem] xl:mb-0`}>
                        {fetching ? (
                            <Loader className="h-[calc(100vh_-_17rem)]" />
                        ) : (
                            <>
                                <table className="w-full rolemasterTable lastBorder mb-4 text-left">
                                    <thead className={`bg-${currentMode}-450`}>
                                        <tr>
                                            <th>Rule Name</th>
                                            <th>Description</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {RoleData &&
                                            RoleData?.map((elem, index) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td>
                                                                <p className="truncate w-[150px] xl:w-[300px] cursor-default" title={elem?.rule_name}>
                                                                    {elem?.rule_name}
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <p
                                                                    className="truncate w-[150px] xl:w-[300px] cursor-default"
                                                                    title={elem?.description}>
                                                                    {elem?.description}
                                                                </p>
                                                            </td>
                                                            <td>
                                                                <div className="flex space-x-4">
                                                                    <Link to={`${"/ViewRule?ruleid="}${elem?.id}`}>
                                                                        <img src="/images/common/eye.svg" title="To View Rule" alt="pencil-green" />
                                                                    </Link>
                                                                    {elem?.isactive == true && elem?.template_id == 0 && (
                                                                        <Link to={`${"/invoice-rulebased?ruleid="}${elem?.id}`}>
                                                                            <img
                                                                                src="/images/common/pencil-green.svg"
                                                                                alt="pencil-green"
                                                                                title=" To Edit Rule"
                                                                            />
                                                                        </Link>
                                                                    )}

                                                                    {elem?.isactive == true && (
                                                                        <Link to={`${"/invoice-rulebased?cloneid="}${elem?.id}`}>
                                                                            <img
                                                                                src="/images/common/copy-ico.svg"
                                                                                title=" To Clone Rule"
                                                                                alt="copy-ico"
                                                                            />
                                                                        </Link>
                                                                    )}
                                                                    {elem?.isactive == true && (
                                                                        <Button
                                                                            onClick={() =>
                                                                                StatusChange(
                                                                                    elem?.id,
                                                                                    elem?.isactive == true && false,
                                                                                    elem?.is_allocated
                                                                                )
                                                                            }>
                                                                            <img
                                                                                src="/images/common/active-ico.svg"
                                                                                alt="active-ico"
                                                                                title="To Inactivate Rule"
                                                                            />
                                                                        </Button>
                                                                    )}
                                                                    {elem?.isactive == false && (
                                                                        <Button
                                                                            onClick={() =>
                                                                                StatusChange(
                                                                                    elem?.id,
                                                                                    elem?.isactive == false && true,
                                                                                    elem?.is_allocated
                                                                                )
                                                                            }>
                                                                            <img
                                                                                src="/images/common/inactive-ico.svg"
                                                                                alt="active-ico"
                                                                                title="To Activate Rule"
                                                                            />
                                                                        </Button>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </>
                                                );
                                            })}
                                        {RoleData?.length == 0 && (
                                            <div className="absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]">
                                                <img src="/images/common/delivery.png" alt="delivery" />
                                                <h4 className="font-interSb text-center mt-3">No data found</h4>
                                            </div>
                                        )}
                                    </tbody>
                                </table>
                            </>
                        )}
                    </Card>
                </Main>
                <Footer />
            </Layout>
        </>
    );
};

export default InvoiceRule;
