import { Checkbox, ClickAwayListener, Fade, FormControlLabel, Popper } from "@mui/material";
import { forwardRef, useRef, useImperativeHandle, useState, useContext, useEffect } from "react";
import Button from "../../../components/Button";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import { useLocation } from "react-router-dom";

const TogglePopup = forwardRef((props, ref) => {
    const location = useLocation();
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const menuItems = props?.menuList;
    const [popstate, setPopState] = useState({
        value: null,
        anchorEl: null,
        id: null,
    });
    const [placement, setPlacement] = useState();
    useImperativeHandle(ref, () => ({
        handleClick(newPlacement, value, event, id = null) {
            setPopState({ value, anchorEl: event.currentTarget, id }, (prev) => placement !== newPlacement || !prev);
            setPlacement(newPlacement);
        },
    }));
    const handleClickAway = () => {
        setPopState({ id: false, anchorEl: false, value: "" });
    };
    useEffect(() => {
        if (props?.isOpen) {
            handleClickAway();
        }
    }, [props?.isOpen]);

    const [activeId, setActiveId] = useState(null);
    return (
        <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={props?.isChecked == undefined || props?.isChecked == true ? handleClickAway : undefined}
        >
            <Popper
                className={`${props?.className} bg-${currentMode}-card p-4 border-[1px] ${
                    props?.lowOverlay ? "z-[9]" : ""
                } z-[12] border-${currentMode}-600 ${
                    currentMode !== "dark" ? "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]" : ""
                } text-${currentMode}-copy rounded-[10px]`}
                open={1 === popstate.value}
                anchorEl={popstate.anchorEl}
                id={1}
                disablePortal={true}
                placement={placement}
            >
                <ul tabIndex={0}>
                    {menuItems?.length > 0 &&
                        menuItems?.map((item, i) => {
                            const empty = item !== false;
                            const isObj = typeof item !== "string";
                            return (
                                empty &&
                                isObj && (
                                    <li
                                        key={i}
                                        className={`transition ${
                                            item?.value == activeId && location?.pathname !== "/exception-automation" ? `bg-${currentMode}-1000` : ``
                                        }  ${
                                            item?.text == "Email" && localStorage?.getItem("mailSyncEmail") == "false" ? "hidden" : ""
                                        } duration-300 ease-in-out mx-[-17px]  py-1 px-[20px] hover:bg-${currentMode}-960`}
                                        onClick={(e) => {
                                            props.selectedItemChange(item?.value, popstate.id, e);
                                            setActiveId(item?.value);
                                            if (props?.isToggle !== true) {
                                                handleClickAway();
                                            }
                                        }}
                                    >
                                        <Button className={`flex ${1} items-center justify-between font-interR`}>
                                            {/* <Button
                            className={` mr-2 flex justify-center items-center rounded-[50%]`}
                          > */}
                                            {item?.checkbox ? (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            size="small"
                                                            onChange={props?.checkChange}
                                                            checked={item?.checked}
                                                            sx={{
                                                                padding: 0,
                                                                color: "#008785",
                                                                margin: "0 12px",
                                                                "&.Mui-checked": {
                                                                    color: "#008785",
                                                                },
                                                            }}
                                                        />
                                                    }
                                                    label={item?.text}
                                                />
                                            ) : item?.src ? (
                                                <span
                                                    className={`${props?.task && item?.value == 6 ? "notifyDot" : ""} ${
                                                        props?.invoice && item?.value == 9 ? "notifyDot" : ""
                                                    }`}
                                                >
                                                    <img className={`mr-2 w-[17px]`} src={item?.src} alt="tickWhite" />
                                                </span>
                                            ) : (
                                                ``
                                            )}

                                            {/* </Button> */}
                                            {!item?.checkbox ? item?.text : null}
                                        </Button>
                                    </li>
                                )
                            );
                        })}
                </ul>
            </Popper>
        </ClickAwayListener>
    );
});

export default TogglePopup;
