import React, { useCallback, useEffect, useRef, useState } from "react";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ReactFlow, { useNodesState, ReactFlowProvider, useEdgesState, addEdge, Handle, MarkerType, Position, applyNodeChanges } from "reactflow";

import Swal from "sweetalert2";
import Button from "../../../components/Button/index.jsx";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider.jsx";
import { getDocumentTypeList } from "../../../redux/actions/invoice.js";
import { getOrganisation } from "../../../redux/actions/organisation.js";
import { roleid, viewUserRoleByOrg } from "../../../redux/actions/role.js";
import { serverConfigTargetType, serverconfigView } from "../../../redux/actions/serverconfig.js";
import { UserDropDown } from "../../../redux/actions/user.js";
import { ORG_STATUS_UPDATE_RESET } from "../../../redux/types/types.js";
import CustomEdge from "../FloatingEdges/index.jsx";
import "reactflow/dist/base.css";
import { MenuItem, Select } from "@mui/material";
import { getCurrentUser } from "../../../redux/actions/auth.js";

// const nodeTypes = {
//   custom: CustomNode,
// };
const edgeTypes = {
    custom: CustomEdge,
};

const Flow = (props) => {
    const orgData = useSelector((state) => state?.ListorganisationDropDownState);
    const dataList = orgData?.viewData;
    const initEdges = [
        {
            id: "e1-2",
            source: "1",
            tabIndex: "-1",
            target: "2",
            type: "smoothstep",
        },
        {
            id: "e1-3",
            tabIndex: "-1",
            source: "1",
            target: "3",
            type: "smoothstep",
        },
        {
            id: "e1-4",
            tabIndex: "-1",
            source: "1",
            target: "4",
            type: "smoothstep",
        },
        {
            id: "e1-5",
            tabIndex: "-1",
            source: "2",
            target: "5",
            type: "smoothstep",
            markerEnd: {
                type: MarkerType.ArrowClosed,
            },
        },
        {
            id: "e1-6",
            tabIndex: "-1",
            source: "2",
            target: "6",
            type: "smoothstep",
            markerEnd: {
                type: MarkerType.ArrowClosed,
            },
        },
        {
            id: "e1-7",
            source: "3",
            tabIndex: "-1",
            target: "7",
            type: "smoothstep",
            markerEnd: {
                type: MarkerType.ArrowClosed,
            },
        },
        {
            id: "e1-8",
            source: "7",
            tabIndex: "-1",
            className: "isActive",
            target: "8",
            type: "smoothstep",
            markerEnd: {
                type: MarkerType.ArrowClosed,
            },
        },
        {
            id: "e1-9",
            tabIndex: "-1",
            source: "4",
            target: "9",
            type: "smoothstep",
            // className: "isActiv",
            markerEnd: {
                type: MarkerType.ArrowClosed,
            },
        },
    ];

    const isAddNew = props?.isAddNew;
    const orgId = props?.orgId;
    const orgList = props?.orgList;
    const orgName = props?.orgName;
    const orgStatus = props?.orgStatus;
    const emailCount = props?.emailCount;
    const userCount = props?.userCount;
    const folderCount = props?.folderCount;
    const targetConfigCount = props?.targetConfigCount;
    let targetConfigType = props?.targetConfigType;
    const isAddorEdit = props?.isAddorEdit;
    const isdemo = props?.isdeomo;
    const isdemoDeleted = props?.isdemoDeleted;

    const reqObj = {
        status: 2,
        search_text: "",
        // organsiation: orgId,
    };
    const { requestComplete, result } = useSelector((state) => state?.OrgStatusUpdateState);
    const [addNewOrg, setAddNewOrg] = useState("");
    const dispatch = useDispatch();
    const [isTarget, setTargetShow] = useState("");
    const [targetType, setTargetType] = useState(targetConfigType);
    const [targetSelect, setTargetSelect] = useState("");
    const nodeId = "7";
    const ref = useRef(null);
    const targethandleChange = (event) => {
        setTargetSelect(event.target.value);
        setTargetType(event.target.value);
        // handleDropdownChange(nodeId, event.target.value);
    };
    const { data: listData } = useSelector((state) => state?.getDocumentTypeListState);
    useEffect(() => {
        if (listData?.length == 0) {
            dispatch(getDocumentTypeList());
        }
    }, []);
    useEffect(() => {
        if (targetSelect !== "") {
            const targetReqObj = {
                orgid: orgId,
                targetvalue: targetSelect,
            };
            dispatch(serverConfigTargetType(targetReqObj));
        }
    }, [dispatch, targetSelect]);

    const organisationStatusUpdate = () => {
        Swal.fire({
            text: `Are you sure you want to ${orgStatus ? "inactivate" : "activate"} the organization?`,
            icon: "warning",
            closeOnClickOutside: false,
            showCancelButton: true,
            showCloseButton: true,
            buttons: {
                cancel: "Cancel",
                confirm: "Confirm",
            },
        }).then((confirm) => {
            if (confirm?.isConfirmed) {
                const statusVal = orgStatus ? 1 : 0;
                localStorage.setItem("UpdateOrgId", orgId);
                dispatch(props.statusChange(orgId, statusVal));
            }
        });
    };
    if (requestComplete && result?.status) {
        const localOrgId = localStorage.getItem("orgId");
        if (localOrgId == orgId) {
            localStorage.setItem("orgId", dataList?.[0]?.id);
        }
    }
    if (requestComplete && !result?.status) {
        Swal.fire({
            text: result?.message,
            icon: "warning",
            closeOnClickOutside: false,
            showCloseButton: true,
            button: "Ok",
        }).then((confirm) => {
            if (confirm?.isConfirmed || confirm?.isDismissed) {
                dispatch({ type: ORG_STATUS_UPDATE_RESET });
            }
        });
    }
    const showAddOrEdit = (value) => {
        props.orgEditOpen(value);
    };
    const showViewOrg = () => {
        props.orgView(true);
    };
    const showTreeOpen = (value) => {
        props?.setOrgActionType(value);
        props?.orgOpen(value);
    };
    const showTarget = (e) => {
        e.preventDefault();
        setTargetShow(e.target.value);
    };
    const handleViewEmailConfig = () => {
        props.emailView(true);
    };
    const reqObject = {
        org_id: +orgId,
    };
    const userReqObj = {
        order_by: 1,
        order_column: "",
        pg_no: 1,
        rec_cnt: 20,
        role_id: 0,
        search_text: "",
        status: 3,
        org_id: +orgId,
    };

    const handleAddUserRoleByOrg = () => {
        props?.UserOpen();
        dispatch(roleid());
        dispatch(UserDropDown(userReqObj));
        localStorage.setItem("UpdateOrgId", +orgId);
        //dispatch(viewUserRoleByOrg(reqObject))
    };
    const handleViewFolderConfig = () => {
        props?.folderView(true);
    };
    const handleViewUserRoleByOrg = () => {
        props?.userView(true);
        localStorage.setItem("UpdateOrgId", orgId);
    };
    const handleTargetServerConfig = () => {
        props?.ServerOpen(true);
        dispatch(serverconfigView({ id: orgId }));
        localStorage.setItem("UpdateOrgId", orgId);
        document.querySelector("[data-id='8']").classList.remove("isActive");
    };
    if (requestComplete && result.status) {
        Swal.fire({
            text: "The status has been updated successfully.",
            icon: "success",
            closeOnClickOutside: false,
            showCloseButton: true,
            button: "Ok",
        }).then((confirm) => {
            if (confirm.isConfirmed || confirm?.isDismissed) {
                dispatch({ type: ORG_STATUS_UPDATE_RESET });
                dispatch(getOrganisation(reqObj));
                window.location.reload();
            }
        });
    }
    useEffect(() => {
        if (document.querySelector(".target-node")) {
            document.querySelector(".target-node").classList.add("isActive");
        }
        if (targetType == "SAP") {
            document.querySelector("[data-testid='rf__edge-e1-8']")?.classList.remove("isActive");
        }
    });
    const targetDDl = ref?.current;
    if (targetDDl) {
        targetDDl.onclick = function (e) {
            if (targetType == "SAP" && targetType !== " ") {
                setTimeout(() => {
                    document.querySelector("[data-id='8']").classList.remove("isActive");
                }, 100);
            }
        };
        targetDDl.onchange = function (e) {
            targetDDl.value = e.target.value;
            if (e.target.value == "SAP") {
                setTargetType("SAP");
                document.querySelector(".target-node").classList.remove("isActive");
            } else if (e.target.value == "IQAI") {
                setTargetType("");
                document.querySelector(".target-node").classList.add("isActive");
            }
            e.preventDefault();
        };
        if (orgList == null) {
            document.querySelector("[data-id='8']").classList.add("isActive");
            document.querySelector("[data-testid='rf__edge-e1-8']")?.classList.add("isActive");
        }
    }
    const DisplayRole = useSelector((state) => state?.DisplayRolePermissionStateAction);
    const { data: DataList } = DisplayRole;
    const RoleList = DataList?.role_info;
    const RoleMenu = RoleList?.menu;
    const Roleorg = RoleMenu?.find((elem) => elem?.menu_id === 1);
    const RoleOrgAction = Roleorg?.actions;
    const RoleActOrgView = RoleOrgAction?.find((element) => element.id === 3);
    const RoleActOrgEdit = RoleOrgAction?.find((element) => element.id === 2);
    const RoleActOrgActivate = RoleOrgAction?.find((element) => element.id === 4);

    const RoleActOrgInactivate = RoleOrgAction?.find((element) => element.id === 5);
    const RoleEmail = RoleMenu?.find((elem) => elem?.menu_id === 2);
    const RoleEmailAction = RoleEmail?.actions;
    const RoleActEmailAdd = RoleEmailAction?.find((element) => element.id === 1);
    const RoleActEmailView = RoleEmailAction?.find((element) => element.id === 3);

    const RoleFolder = RoleMenu?.find((elem) => elem?.menu_id === 3);
    const RoleFolderAction = RoleFolder?.actions;
    const RoleActFolderAdd = RoleFolderAction?.find((element) => element.id === 1);
    const RoleActFolderView = RoleFolderAction?.find((element) => element.id === 3);

    const RoleUserRole = RoleMenu?.find((elem) => elem?.menu_id === 5);
    const RoleUserRoleAction = RoleUserRole?.actions;
    const RoleActUserRoleAdd = RoleUserRoleAction?.find((element) => element.id === 1);
    const RoleActUserRoleView = RoleUserRoleAction?.find((element) => element.id === 3);

    const RoleActUserRoleEdit = RoleUserRoleAction?.find((element) => element.id === 2);
    const ShowOrgEdit = orgStatus && RoleActOrgEdit?.isallowed === true;
    const ShowOrgActivate = !orgStatus && RoleActOrgActivate?.isallowed === true;
    const ShowOrgInactivate = orgStatus && RoleActOrgInactivate?.isallowed === true;
    const { currentMode } = useContext(ThemeContext);
    useEffect(() => {}, [currentMode]);
    // const handleDropdownChange = (nodeId, selectedValue) => {
    //     const nodeToUpdate = initNodes.find(
    //         (element) => element?.id === nodeId
    //     );
    //     console.log(nodeToUpdate?.data, "okokokok");

    //     if (nodeToUpdate) {
    //         // Update the node's data with the selected value
    //         nodeToUpdate.data.props.children.props.children[0].props.children.props.value =
    //             selectedValue;
    //         // Trigger a re-render of the chart by calling setState or useState hook
    //         // If you are using a state management system like Redux, update the state accordingly
    //         setNodes([...initNodes]);
    //         console.log(
    //             nodeToUpdate.data.props.children.props.children[0].props
    //                 .children.props.value,
    //             "pppllplll"
    //         );
    //         // setElements([...elements]);
    //     }
    //     // console.log(initNodes, nodeToUpdate?.data?.props?.children?.props?.children[0]?.props?.children?.props?.value = selectedValue);
    // };

    const folderOpen = () => {
        props?.FolderOpen();
    };
    const emailOpen = () => {
        props?.EmailOpen();
    };
    const currentUser = getCurrentUser();
    const ShowProffesionIcon = currentUser?.license_type !== 4;
    const initNodes = [
        {
            id: "1",
            position: { x: 0, y: 50 },
            //   isArrow
            tabIndex: "-1",
            className: "border-0 isArrow",
            data: {
                label: (
                    <div tabIndex={0} className={`Chartcard bg-white relative w-[250px] h-[60px] shadow-[0px_4px_20px_rgba(178,178,178,0.5)]`}>
                        {orgName !== "" && orgList !== null && (
                            <div div className="justify-end Options space-y-1">
                                <div className={`${RoleActOrgView?.isallowed == false ? "opacity-0 pointer-events-none" : ""}`}>
                                    <Button onClick={() => showViewOrg(1)} tabIndex={0} className={` bg-light-250 extent views rounded-full `}>
                                        <span>View</span>
                                    </Button>
                                </div>
                                <div className={`${RoleActOrgEdit?.isallowed == false ? "opacity-0 pointer-events-none" : ""}`}>
                                    {orgStatus && (
                                        <Button tabIndex={0} onClick={() => showAddOrEdit(1)} className={` bg-light-250 extent edits rounded-full `}>
                                            <span>Edit</span>
                                        </Button>
                                    )}
                                </div>
                                <div
                                    className={`${
                                        RoleActOrgActivate?.isallowed == false || (isdemo && isdemoDeleted) ? "opacity-0 pointer-events-none" : ""
                                    }`}>
                                    {!orgStatus && (
                                        <Button
                                            tabIndex={0}
                                            onClick={() => organisationStatusUpdate()}
                                            className={`bg-light-250 extent active rounded-full`}>
                                            <span>{"Activate"}</span>
                                        </Button>
                                    )}
                                </div>
                                <div className={`${RoleActOrgInactivate?.isallowed == false ? "opacity-0 pointer-events-none" : ""}`}>
                                    {orgStatus && (
                                        <Button
                                            tabIndex={0}
                                            onClick={() => organisationStatusUpdate()}
                                            className={`bg-light-250 extent inactive rounded-full`}>
                                            <span>{"Inactivate"}</span>
                                        </Button>
                                    )}
                                </div>
                            </div>
                        )}
                        <div className="flex second-step">
                            <div className="flex px-3 justify-center w-[60px] h-[58px] items-center rounded-l-[5px] py-[8px] bg-light-260">
                                <img
                                    style={{
                                        filter: "brightness(0) invert(1)",
                                    }}
                                    src="/images/sidebar/company.svg"
                                    className="w-[23px] h-[23px]"
                                    alt="emoji"
                                />
                            </div>
                            <div className="flex items-center">
                                <div className="flex flex-col pl-2 pr-2 py-[8px]">
                                    <h4 className={`text-[12px] font-interSb trunc-2 w-[9.5rem] leading-[18px]`}>
                                        {orgName === "" || orgList == null ? "Organization" : orgName}
                                    </h4>
                                    {orgName === "" || (orgList == null && ShowProffesionIcon) ? (
                                        <Link
                                            to="#!"
                                            onClick={() => showTreeOpen(0)}
                                            src={currentMode !== "dark" ? `/images/common/eyeOpen.svg` : `/images/common/eye-white.svg`}
                                            className="text-[11px] font-interM leading-[17px]">
                                            <u className={`${currentMode !== "dark" ? `text-[#028C78] ` : `text-[#028C78] `} text-[2px]]`}>
                                                Click to add
                                            </u>
                                        </Link>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="pr-2 absolute right-[10px] top-[50%] translate-y-[-50%]">
                                    <img className="w-[3px] h-[15px]" src="/images/common/green-dots.svg" alt="green-dots" />
                                </div>
                            </div>
                        </div>
                    </div>
                ),
            },
        },
        {
            id: "2",
            position: { x: -160, y: 200 },
            data: { label: "Invoice Source" },
            className: `bg-light-270 py-[1px] sourced text-[11px] border-[#9207FF] border-[1px] text-white px-3 rounded-[100px] shadow-[0px_4px_20px_rgba(178,178,178,0.5)] `,
        },
        {
            id: "3",
            position: { x: 75.2, y: 200 },
            className: `bg-light-280 py-[1px] sourced text-[11px] border-[#56B8FF] border-[1px] text-white px-3 rounded-[100px] shadow-[0px_4px_20px_rgba(178,178,178,0.5)]`,
            data: { label: "Invoice Target" },
        },
        {
            id: "4",
            position: { x: 300, y: 200 },
            className: `bg-light-290 py-[1px] sourced text-[11px] border-[#E72D11] border-[1px] text-white px-3 rounded-[100px]  shadow-[0px_4px_20px_rgba(178,178,178,0.5)]`,
            data: { label: "Permissions" },
        },
        {
            id: "5",
            position: { x: -250, y: 300 },
            className: "border-0 p-0",
            data: {
                label: (
                    <div tabIndex={0} className={`Chartcard bg-white relative shadow-[0px_4px_20px_rgba(178,178,178,0.5)]`}>
                        <div className="notific text-xs grid place-items-center">{orgList == null ? "" : emailCount}</div>
                        <div className="justify-end Options space-y-1">
                            <div className={`${RoleActEmailAdd?.isallowed == false ? "opacity-0 pointer-events-none" : ""}`}>
                                {orgStatus && (
                                    <Button
                                        tabIndex={0}
                                        className={`
                     bg-light-250 extent add rounded-full`}
                                        onClick={emailOpen}>
                                        <span>Add</span>
                                    </Button>
                                )}
                            </div>
                            <div className={`${RoleActEmailView?.isallowed == false ? "opacity-0 pointer-events-none" : ""}`}>
                                <Button
                                    tabIndex={0}
                                    className={`
                     bg-light-250 extent views rounded-full`}
                                    onClick={handleViewEmailConfig}>
                                    <span>View</span>
                                </Button>
                            </div>
                        </div>
                        <div className="flex items-center email-step">
                            <div className="flex px-[.6rem] justify-center items-center rounded-l-[5px] py-[10px] bg-light-300">
                                <img src="/images/common/envelope.svg" className="w-[14px] h-[14px]" alt="emoji" />
                            </div>
                            <div className="flex flex-col pl-2 py-2 min-w-[5.2rem]">
                                <h4 className={`text-[10px] font-interM leading-[17px]`}>Email</h4>
                            </div>
                            <div className="pr-2 absolute right-[10px] top-[50%] translate-y-[-50%]">
                                <img className="w-[3px] h-[10px]" src="/images/common/green-dots.svg" alt="green-dots" />
                            </div>
                        </div>
                        <Handle type="target" position={Position.Left} className="bg-transparent" />
                        <Handle type="source" position={Position.Right} className="bg-transparent" />
                    </div>
                ),
            },
        },
        {
            id: "6",
            position: { x: -90, y: 300 },
            className: "border-0 z-[-1]",
            data: {
                label: (
                    <div tabIndex={0} className="Chartcard bg-white shadow-[0px_4px_20px_rgba(178,178,178,0.5)]">
                        <div className="notific text-xs grid place-items-center">{orgList == null ? "" : folderCount}</div>
                        <div className="justify-end Options space-y-1">
                            <div className={`${RoleActFolderAdd?.isallowed == false ? "opacity-0 pointer-events-none" : ""}`}>
                                {orgStatus && (
                                    <Button tabIndex={0} onClick={folderOpen} className={`bg-light-250 extent add rounded-full`}>
                                        <span>Add</span>
                                    </Button>
                                )}
                            </div>
                            <div className={`${RoleActFolderView?.isallowed == false ? "opacity-0 pointer-events-none" : ""}`}>
                                <Button tabIndex={0} onClick={handleViewFolderConfig} className={` bg-light-250 extent views rounded-full`}>
                                    <span>View</span>
                                </Button>
                            </div>
                        </div>
                        <div className="flex items-center folder-step">
                            <div className="flex px-[.6rem] justify-center items-center rounded-l-[5px] py-[10px] bg-light-310">
                                <img src="/images/common/folder-plus.svg" className="w-[14px] h-[14px]" alt="emoji" />
                            </div>
                            <div className="flex flex-col pl-2 py-2 min-w-[5.2rem]">
                                <h4 className={`text-[10px] font-interM leading-[16px]`}>Folder</h4>
                            </div>
                            <div className="pr-2 absolute right-[10px] top-[50%] translate-y-[-50%]">
                                <img className="w-[3px] h-[10px]" src="/images/common/green-dots.svg" alt="green-dots" />
                            </div>
                        </div>
                    </div>
                ),
            },
        },
        {
            id: "7",
            position: { x: 65.6, y: 300 },
            className: `${"border-0 z-[-2]"} `,
            data: {
                label: (
                    <div tabIndex={0} className="Chartcard  bg-white border-[rgba(223,225,226,0.56)] shadow-[0px_4px_20px_rgba(178,178,178,0.5)]">
                        <div className="flex items-center integrate-step">
                            <div className="flex px-[.6rem] justify-center items-center rounded-l-[5px] py-[10px] bg-light-320">
                                <img src="/images/common/noun-integration.svg" className="w-[14px] h-[14px]" alt="emoji" />
                            </div>
                            <div className="flex flex-col pl-2 py-2 min-w-[5.2rem] caret-slate-50">
                                {/* <Select
                                    disabled={orgStatus ? false : true}
                                    ref={ref}
                                    value={targetType}
                                    onChange={(e) => targethandleChange(e)}
                                    name="orgInt"
                                    className="Grey text-[10px] font-interM leading-[15px]"
                                    id="targetSelection"
                                    IconComponent={() => (
                                        <img
                                            className="absolute right-[15px] pointer-events-none"
                                            src="/images/common/chevron-grey.svg"
                                        />
                                    )}
                                    SelectDisplayProps={{
                                        style: {
                                            padding: "0",
                                            fontSize: "10px",
                                            color: `${
                                                currentMode !== "dark"
                                                    ? `#555555`
                                                    : `#ECECEC`
                                            }`,
                                        },
                                    }}
                                >
                                    <MenuItem
                                        selected={
                                            targetConfigType === "IQAI"
                                                ? true
                                                : false
                                        }
                                        className="text-[10px]"
                                        value="IQAI"
                                    >
                                        Integrate
                                    </MenuItem>
                                    <MenuItem
                                        selected={
                                            targetConfigType === "SAP"
                                                ? true
                                                : false
                                        }
                                        className="text-[10px]"
                                        value="SAP"
                                    >
                                        SAP
                                    </MenuItem>
                                </Select> */}
                                <select
                                    disabled={orgStatus ? false : true}
                                    ref={ref}
                                    tabIndex={0}
                                    onChange={(e) => targethandleChange(e)}
                                    name="orgInt"
                                    className={`customDropdown Grey text-[10px] font-interM leading-[15px]`}
                                    id="targetSelection">
                                    <option selected={targetConfigType === "IQAI" ? true : false} value="IQAI">
                                        No
                                    </option>
                                    <option selected={targetConfigType === "SAP" ? true : false} value="SAP">
                                        Yes
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                ),
                dropdownValue: targetType,
            },
        },
        {
            id: "8",
            position: { x: 66.4, y: 400 },
            className: `${"border-0 target-node mytarget "}${!props?.isServerOpen ? "isActive" : ""} `,

            data: {
                label: (
                    <div
                        tabIndex={0}
                        onClick={() => handleTargetServerConfig()}
                        className={`Chartcard bg-white border-[rgba(223,225,226,0.56)] shadow-[0px_4px_20px] shadow-${currentMode}-750`}>
                        {orgList !== null && targetConfigCount === 1 && (
                            <div className="notific text-xs grid place-items-center">
                                <img src="/images/common/tickWhite.svg" alt="tickWhite" />
                            </div>
                        )}
                        <div className="flex items-center target-step">
                            <div className="flex px-[.5rem] justify-center items-center rounded-l-[5px] py-[10px] bg-light-330">
                                <img src="/images/common/erp.svg" className="w-[14px] h-[14px]" alt="emoji" />
                            </div>
                            <div className="flex flex-col pl-2 py-2 min-w-[5.2rem]">
                                <h4 className={`text-[10px] font-interM leading-[16px]`}>Target</h4>
                            </div>
                        </div>
                    </div>
                ),
            },
        },
        {
            id: "9",
            position: { x: 272, y: 300 },
            className: "border-0",
            data: {
                label: (
                    <div className="Chartcard bg-white shadow-[0px_4px_20px_rgba(178,178,178,0.5)]" tabIndex={0}>
                        <div className="notific text-xs grid place-items-center">{orgList == null ? "" : userCount}</div>
                        <div className="justify-end Options space-y-1">
                            <div className={`${RoleActUserRoleAdd?.isallowed == false ? "opacity-0 pointer-events-none" : ""}`}>
                                {orgStatus && (
                                    <Button className={`bg-light-250 extent add rounded-full`} tabIndex={0} onClick={handleAddUserRoleByOrg}>
                                        <span>Add</span>
                                    </Button>
                                )}
                            </div>
                            <div className={`${RoleActUserRoleView?.isallowed == false ? "opacity-0 pointer-events-none" : ""}`}>
                                <Button tabIndex={0} className={`bg-light-250 extent views rounded-full`} onClick={handleViewUserRoleByOrg}>
                                    <span>View</span>
                                </Button>
                            </div>
                        </div>
                        <div className="flex items-center users-step">
                            <div className="flex px-[.6rem] justify-center items-center rounded-l-[5px] py-[10px] bg-light-340">
                                <img src="/images/common/user-plus.svg" className="w-[14px] h-[14px]" alt="emoji" />
                            </div>
                            <div className="flex flex-col pl-2 py-2 min-w-[6.8rem]">
                                <h4 className={`text-[10px] font-interM leading-[16px]`}>Users & Roles</h4>
                            </div>
                            <div className="pr-2 absolute right-[9px] top-[50%] translate-y-[-50%]">
                                <img className="w-[3px] h-[10px]" src="/images/common/green-dots.svg" alt="green-dots" />
                            </div>
                        </div>
                    </div>
                ),
            },
        },
    ];

    initEdges.forEach((item) => {
        if (isAddNew) {
            item.className = "isActive";
        }
    });
    initNodes.forEach((item, index) => {
        if (item.id === "8") {
            if (targetConfigType === "SAP") {
                item.className = "";
            } else {
                item.className = "isActive";
            }
        } else if (index !== 0 && isAddNew) {
            item.className = item.className + " isActive";
        }
    });
    const [edges, setEdges, onEdgesChange] = useEdgesState(initEdges);
    const [nodes, setNodes, onNodesChange] = useNodesState(initNodes);
    // const onNodesChange = useCallback(
    //     (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    //     [setNodes]
    // );

    const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);

    if (addNewOrg !== orgName) {
        setEdges([...initEdges]);
        setNodes([...initNodes]);
        setAddNewOrg(orgName);
    }
    const Child = document.querySelectorAll(".Chartcard");
    const source = document.querySelectorAll(".sourced");
    if (localStorage.getItem("themeMode") === "dark") {
        Child?.forEach((element) => {
            // element?.classList?.remove("bg-white");
            element?.classList?.add(
                "shadow-inherit",
                // "border-light-600",
                "border"
            );
        });
        source?.forEach((ele) => {
            ele?.classList?.add("shadow-inherit");
        });
    } else {
        Child?.forEach((element) => {
            element?.classList?.remove(
                "shadow-inherit",
                // "border-light-600 ",
                "border"
            );
            // element?.classList?.add("bg-white");
        });
        source?.forEach((ele) => {
            ele?.classList?.remove("shadow-inherit");
        });
    }
    // dark mode onload background style changes dynamically
    useEffect(() => {
        if (localStorage.getItem("themeMode") === "dark") {
            Child?.forEach((element) => {
                // element?.classList?.remove("bg-white");
                element?.classList?.add(
                    "shadow-inherit",
                    // "border-light-600",
                    "border"
                );
            });
            source?.forEach((ele) => {
                ele?.classList?.add("shadow-inherit");
            });
        } else {
            Child?.forEach((element) => {
                element?.classList?.remove("shadow-inherit", "border");
            });
            source?.forEach((ele) => {
                ele?.classList?.remove("shadow-inherit");
            });
        }
    }, [localStorage.getItem("themeMode"), currentMode]);

    useEffect(() => {
        if (props?.handleClose && targetType == "SAP") {
            document.querySelector("[data-id='8']").classList.remove("isActive");
        }
        if (document.querySelector("[data-id='8']")) {
            if (!props?.isServerOpen && (targetType == "" || targetType == "IQAI")) {
                document.querySelector("[data-id='8']").classList.add("isActive");
            }
            if (targetType == "" || targetType == "IQAI") {
                document.querySelector("[data-id='8']").classList.add("isActive");
            } else {
                document.querySelector("[data-id='8']").classList.remove("isActive");
            }
        }

        if (targetType == "SAP") {
            document.querySelector("[data-testid='rf__edge-e1-8']")?.classList.remove("isActive");
        }
        if (targetType == "" || targetType == "IQAI") {
            document.querySelector("[data-testid='rf__edge-e1-8']")?.classList.add("isActive");
        }
    }, [targetType]);

    // const onNodeChange = (nodeId, newDropdownValue) => {
    //     // Find the node you want to update
    //     const updatedElements = initNodes?.map((element) => {
    //         if (element.id === nodeId) {
    //             return {
    //                 ...element,
    //                 data: { ...element.data, dropdownValue: newDropdownValue },
    //             };
    //         }
    //         return element;
    //     });

    //     setElements(updatedElements);
    // };

    return (
        <ReactFlowProvider>
            {
                //  orgName == ""  ?
                //   <div className="grid place-items-center text-center place-content-center h-[calc(100vh_-_37vh)]">
                //   <img
                //     className="mb-[2rem]"
                //     src="/images/common/delivery.png"
                //     alt="delivery"
                //   />
                //   <h5 className="font-interSb mb-1">No data found</h5>
                //   <p className="text-xs">
                //     Click add organisation button
                //     <br /> then add your organisation details
                //   </p>
                // </div> :
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    panOnDrag={false}
                    nodesDraggable={false}
                    zoomOnScroll={false}
                    zoomOnDoubleClick={false}
                    deleteKeyCode={null}
                    zoomOnPinch={false}
                    edgeTypes={edgeTypes}
                    fitView
                    className={`h-[calc(100vh_-_20rem)] overflow-visible`}
                />
            }
        </ReactFlowProvider>
    );
};
export default Flow;
