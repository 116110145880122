import { Link, NavLink } from "react-router-dom";
import Button from "../../../components/Button";
import Card from "../../../components/UI/Card";
import Main from "../../../components/UI/Main";
import Layout from "../../../Hoc/Layout";
import { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { emailTemplateStatusMode, getEmailTemplateList } from "../../../redux/actions/emailtask";
import Swal from "sweetalert2";
import Loader from "../../../components/Loader";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import { ACTIVATE_DEACTIVATE_EMAIL_TEMPLATE_RESET } from "../../../redux/types/types";
export const EmailTemplate = () => {
    const { currentMode, toggleMode } = useContext(ThemeContext);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getEmailTemplateList());
    }, []);
    const data = useSelector((state) => state?.emailTemplateListState);
    const listData = data?.data;
    const [state, setState] = useState(false);
    const StatusChange = (id, status) => {
        setState(status);
        Swal.fire({
            text: status == false ? "Are you sure you want to deactivate?" : "Are you sure you want to activate?",
            icon: "warning",
            closeOnClickOutside: false,
            showCancelButton: true,
            showCloseButton: true,
            icon: "warning",
            buttons: {
                cancel: "Cancel",
                confirm: "Confirm",
            },
        }).then((confirm) => {
            if (confirm?.isConfirmed) {
                const reqObj = {
                    id: id,
                    status: status,
                };
                dispatch(emailTemplateStatusMode(reqObj));
            }
        });
    };
    const { status, requestComplete } = useSelector((state) => state?.emailTemplateStatusUpdateState);
    if (requestComplete && status?.status) {
        Swal.fire({
            text: state == true ? "Activated successfully!" : "Deactivated successfully!",
            icon: "success",
            closeOnClickOutside: false,
            showCloseButton: true,
            button: "Ok",
        }).then((confirm) => {
            if (confirm) {
                dispatch({ type: ACTIVATE_DEACTIVATE_EMAIL_TEMPLATE_RESET });
                dispatch(getEmailTemplateList());
            }
        });
    }
    return (
        <>
            <Layout>
                <Main>
                    <div className={` text-${currentMode}-copy flex  mb-5 items-center justify-between`}>
                        <h4 className="text-2xl font-interSb">Email Template</h4>
                        <Link to={`/emailtemplate/view`}>
                            <Button
                                title="To Create Email Template"
                                className={`bg-light-20 rounded-[8px] px-3 py-2 font-interM text-sm flex items-center border-[0] text-white`}>
                                <img className="mr-1 " src="/images/common/plus.svg" alt="plus" />
                                {"  "}
                                Create Template
                            </Button>
                        </Link>
                    </div>
                    <Card
                        className={`xl:h-[calc(100vh_-_13rem)] h-[calc(100vh_-_18rem)] ${
                            currentMode === "dark" ? "" : "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]"
                        } overflow-y-auto`}>
                        {data?.fetching ? (
                            <Loader className="h-[calc(100vh_-_17rem)] " />
                        ) : (
                            <table className="w-full rolemasterTable accrual lastBorder text-left">
                                <thead className={`bg-${currentMode}-450`}>
                                    <tr>
                                        <th className="pl-[32px]">Email Template Name</th>
                                        <th>Subject</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listData?.length !== 0 ? (
                                        listData.map((el) => {
                                            return (
                                                <tr>
                                                    <td>{el?.template_name}</td>
                                                    <td>{el?.subject}</td>
                                                    <td>
                                                        <div className="flex space-x-2 xl:space-x-4">
                                                            <NavLink to={`${"/emailtemplate/view?id="}${el?.id}`}>
                                                                <img src="/images/common/eye.svg" title="To View Email Template" alt="pencil-green" />
                                                            </NavLink>
                                                            {el?.isactive && (
                                                                <Link to={`${"/emailtemplate/view?editId="}${el?.id}`}>
                                                                    <img
                                                                        src="/images/common/pencil-green.svg"
                                                                        alt="pencil-green"
                                                                        title=" To Edit Email Template"
                                                                    />
                                                                </Link>
                                                            )}
                                                            {el?.isactive && (
                                                                <Link to={`${"/emailtemplate/view?cloneId="}${el?.id}`}>
                                                                    <img
                                                                        src="/images/common/copy-ico.svg"
                                                                        title=" To Clone Email Template"
                                                                        alt="copy ico"
                                                                    />
                                                                </Link>
                                                            )}
                                                            <Button onClick={() => StatusChange(el?.id, el?.isactive == false && true)}>
                                                                {el?.isactive ? (
                                                                    <img
                                                                        src="/images/common/active-ico.svg"
                                                                        alt="active-ico"
                                                                        title="To Inactivate Email Template"
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        src="/images/common/inactive-ico.svg"
                                                                        alt="active-ico"
                                                                        title="To Activate Email Template"
                                                                    />
                                                                )}
                                                            </Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <div className="absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]">
                                            <img src="/images/common/delivery.png" alt="delivery" />
                                            <h4 className="font-interSb text-center mt-3">No data found</h4>
                                        </div>
                                    )}
                                </tbody>
                            </table>
                        )}
                    </Card>
                </Main>
            </Layout>
        </>
    );
};
