import { useEffect, useState, useMemo, createContext, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { getInvoiceItems } from "../../../redux/actions/invoice";
import { getProformaInvoiceItems } from "../../../redux/actions/advance-request.js";
import Loader from "../../../components/Loader";
import Content from "../Content";
import Sidebar from "../sidebar";
import Tour from "reactour";
import UseTour from "../../../Hooks/ProductTour";
import { decryptId } from "../../../utils/helpers";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import { ErrorBoundary } from "react-error-boundary";
import FournotFour from "../../404";
import { GET_INVOICE_CALC_VIEW_RESET, INVOICE_GET_RESET } from "../../../redux/types/types";
import ReactDOM from "react-dom";
import { InvoiceCalculationTable } from "../invoiceCalculation";
import _ from "lodash";

const ErrorFallback = ({ error }) => {
    return (
        <>
            <FournotFour />
        </>
    );
};
let playTimeout;
let updatedInvoiceList = [];

const InvoiceView = () => {
    const popref = useRef(null);
    const invoiceList = useSelector((state) => state?.getInvoiceItemsState);
    const requestDate = invoiceList?.data?.datalist?.[0]?.request_date || null;
    const dispatch = useDispatch();
    const [totalAmountVal, setTotalAmountVal] = useState(0);
    const [areArraysEqual, setAreArraysEqual] = useState(true);
    const [checkArraysEqual, setCheckArraysEqual] = useState(true);
    const [updateDocList, onSaveInvoiceDoc] = useState([]);
    const [invNo, getQueryStringVal] = useState("");
    const [pageTypeVal, getPageType] = useState("");
    const [clickedLineItem, lineItemClick] = useState(false);
    const [currentFieldSelectedType, setCurrentFieldType] = useState(null);
    const [isReset, resetPage] = useState(false);
    const [tblListData, setTableList] = useState([]);
    const [filteredLineItem, setFilteredLineItem] = useState([]);
    const [updtTblListData, setUpdtTblListData] = useState([]);
    const [isClick, setisClick] = useState(false);
    const [validateErr, setValidateErr] = useState([]);
    const [isDrag, setisDrag] = useState(false);
    const [cropValue, setCropValue] = useState([]);
    const [removedLineItem, setRemoveLineItem] = useState();
    const [urlstate, setUrlstate] = useState("");
    const [mandatoryLineItems, setMandatorLineItems] = useState([]);
    const [isClickedItem, onShowLineItem] = useState(false);
    const [checkViewInv, setCheckViewInv] = useState(true);
    const [dragObject, setdragObject] = useState({
        mandatory: false,
        label_name: "",
        maxlength: null,
        data_type: null,
    });
    const [lineitemData, setLineItemData] = useState([]);
    const [clicked, setClicked] = useState(false);
    let updatedArray = [];
    const onSaveDoc = (listItems, objInv, obj) => {
        const mergeByLabelName = (array, newItem) => {
            const index = _.findIndex(array, { label_name: newItem?.label_name });
            if (index !== -1) {
                array[index] = _.merge(array[index], newItem);
            } else {
                array.push(newItem);
            }
            return array;
        };
        // Filter out the object with the same id as objInv from updateDocList
        let filteredArray = updateDocList?.filter((obj) => obj?.id !== objInv?.id);
        // Add objInv to the filtered array if it's defined
        if (objInv !== undefined && objInv !== null) {
            filteredArray?.push(objInv);
        }
        // Update the updatedArray with the filteredArray
        updatedArray?.push(filteredArray);
        // Check if updateDocList is not empty
        if (updateDocList?.length !== 0) {
            let updateInvListArr = updatedArray?.flat();
            // Merge listItems and obj into updateInvListArr by label_name
            updateInvListArr = mergeByLabelName(updateInvListArr, listItems);
            updateInvListArr = mergeByLabelName(updateInvListArr, obj);
            const finalArr = [...new Set(updateInvListArr)];
            onSaveInvoiceDoc([...updateDocList, ...finalArr?.flat()]);
        } else {
            let filterArr = listItems;
            filterArr = mergeByLabelName(filterArr, obj);
            onSaveInvoiceDoc(filterArr);
        }
        // Handle removedLineItem if defined
        if (removedLineItem !== undefined) {
            let delFilterArr = [...listItems];
            delFilterArr = mergeByLabelName(delFilterArr, removedLineItem);
            onSaveInvoiceDoc([...updateDocList, ...delFilterArr]);
        }
    };
    const fieldTypeClick = (selectedFieldType) => {
        setCurrentFieldType(selectedFieldType);
    };
    const resetPageData = () => {
        let isReload = isReset ? false : true;
        resetPage(isReload);
        updatedInvoiceList = [];
        onSaveInvoiceDoc([]);
        setClicked(false);
    };

    const isLineItemClick = () => {
        lineItemClick(true);
    };
    const location = useLocation();
    const urlData = location?.search?.slice(1)?.replace(/\n\s+/g, "\n")?.trim();
    const urlObj = JSON?.parse(decryptId(urlData));
    let status;
    let invNumber = urlObj?.inv;
    const InvNo = urlObj?.inv;
    let pageType = urlObj?.type;
    let ret_id = urlObj?.ret_id || 0;
    status = urlObj?.status;
    // const [prevPath, setPrevPath] = useState(() => {
    //     const storedPath = sessionStorage.getItem("prevPath");
    //     return storedPath ? JSON.parse(storedPath) : null;
    // });
    const nonPOObj = { line_no: 1, cost_center: [], ledger_type: 0, isactive: true };
    const [findRefresh, setFindRefresh] = useState(false);
    useEffect(() => {
        const handleLoad = () => {
            setFindRefresh(true);
        };
        window.addEventListener("load", handleLoad);
        return () => {
            window.removeEventListener("load", handleLoad);
        };
    }, []);
    const loadEventOccurred = useRef(false);

    const handleLoad = (check) => {
        if (check === "reload") {
            const reqObj = {
                id: [InvNo],
                organisation: 0,
                checkViewInv: false,
            };
            if (location.pathname !== "/proforma-invoice-view") {
                dispatch(getInvoiceItems(reqObj));
            } else {
                dispatch(getProformaInvoiceItems(reqObj));
            }
        }
    };

    useEffect(() => {
        var refresh = false;
        if (location.search) {
            setUrlstate(urlData);
        }
        setNonPOItemList(nonPoData[0]?.cost_center == null ? [nonPOObj] : nonPoData[0]?.cost_center);
        getQueryStringVal(InvNo);
        getPageType(pageType);

        const loadEventHandler = () => {
            handleLoad("reload");
            refresh = true;
            // Set the flag to true when load event occurs
            loadEventOccurred.current = true;
            // Remove the event listener once it's triggered
            window.removeEventListener("load", loadEventHandler);
        };
        window.addEventListener("load", loadEventHandler);
        // Execute only if load event has not occurred
        if (!loadEventOccurred.current) {
            const reqObj = {
                id: [InvNo],
                organisation: 0,
                checkViewInv: checkViewInv,
            };
            if (location.pathname !== "/proforma-invoice-view") {
                dispatch(getInvoiceItems(reqObj));
            } else {
                dispatch(getProformaInvoiceItems(reqObj));
            }
        }
        return () => {
            window.removeEventListener("load", loadEventHandler);
        };
    }, [location.pathname, isReset, clickedLineItem]);

    const nonPoData = invoiceList?.data?.datalist || [];
    const [nonPOItemList, setNonPOItemList] = useState([]);
    useEffect(() => {
        setNonPOItemList(nonPoData[0]?.cost_center == null ? [nonPOObj] : nonPoData[0]?.cost_center);
    }, [invoiceList?.data?.datalist]);
    const [removeCostData, setRemoveCostData] = useState([]);
    const [AddCostData, setAddCostData] = useState([]);
    const { data, error, fetching, requestComplete, cancelData } = invoiceList;
    const { fetching: loading } = useSelector((state) => state?.updateInvoiceState);
    const { fetching: statusUpdateLoading } = useSelector((state) => state?.postWorkFlowStatusChangeState);
    if (error) {
        Swal.fire({
            title: error.message,
            text: "Something went wrong. Please contact the admin.",
            closeOnClickOutside: false,
            showCloseButton: true,
            icon: "error",
            button: "Ok",
        }).then((confirm) => {
            if (confirm) {
                dispatch({ type: INVOICE_GET_RESET });
            }
        });
    }
    const invoiceDataList = data?.datalist?.[0] || [];

    const exStatusId =
        invoiceDataList?.ext_status_id !== 3 &&
        invoiceDataList?.ext_status_id !== 4 &&
        invoiceDataList?.ext_status_id !== 5 &&
        invoiceDataList?.curr_status?.[0]?.master_refid !== 5;
    const InputDisable = !exStatusId || status == 7;
    const tourConfig = [
        {
            selector: ".show-step",
            content: "Selecting All radio button picked and unpicked invoice fileds are displayed",
            action: (node) => {
                node?.scrollIntoView({ behavior: "smooth" });
            },
        },
        {
            selector: ".show-picked",
            content: "Selecting Picked radio button only picked invoice fileds are displayed",
            action: (node) => {
                node?.scrollIntoView({ behavior: "smooth" });
            },
        },
        {
            selector: ".show-unpicked",
            content: "Selecting UnPicked radio button only unpicked invoice fileds are displayed",
            action: (node) => {
                node?.scrollIntoView({ behavior: "smooth" });
            },
        },
        {
            selector: ".labelitem-step",
            content: "Click on this Invoice.No, lable to edit the fields",
            action: (node) => {
                node?.scrollIntoView({ behavior: "smooth" });
            },
        },
        {
            selector: ".lineitem-step",
            content: "Click on this Line Items lable to edit the fields",
            action: (node) => {
                node?.scrollIntoView({ behavior: "smooth" });
            },
        },
        {
            selector: ".tooltip-step",
            content: "Click on this Invoice.No to edit the field",
            action: (node) => {
                node?.scrollIntoView({ behavior: "smooth" });
            },
        },
        {
            selector: ".save-step",
            content: "Click on this Save Document button to save the field changes",
            action: (node) => {
                node?.scrollIntoView({ behavior: "smooth" });
            },
        },
        {
            selector: ".remove-step",
            content: "Click on this Reject icon to reject the invoice",
            action: (node) => {
                node?.scrollIntoView({ behavior: "smooth" });
            },
        },
        {
            selector: ".valid-step",
            content: "Click on this Validate icon to validate the invoice",
            action: (node) => {
                node?.scrollIntoView({ behavior: "smooth" });
            },
        },
        {
            selector: ".more-step",
            content: "Click on this Kebab menu to view more actions",
            action: (node) => {
                node?.scrollIntoView({ behavior: "smooth" });
            },
        },
        {
            selector: ".nav-step",
            content: "Click on this Navbar to navigate and lens the invoice",
            action: (node) => {
                node?.scrollIntoView({ behavior: "smooth" });
            },
        },
    ];
    const { openTour, ProdTour } = UseTour(tourConfig);
    const [costListData, setCostListData] = useState([]);
    const [validateChanges, handleValidateChanges] = useState([]);
    const [NewLineItem, setNewLineItem] = useState([]);
    const [newCostArr, setNewCostArr] = useState([]);
    const [clickTaxOpen, setClickTaxOpen] = useState(false);
    const [updatedTaxData, setUpdatedTaxData] = useState([]);
    const [totalRetentionAmount, setTotRetentionAmount] = useState();
    const [totalTax, setTotalTax] = useState(0);
    const { data: erpValidateArr } = useSelector((state) => state?.threeWayMatchingByErpState);
    const [objectLength, setLineItemObjectLength] = useState(null);
    const [vendorObj, setVendorObj] = useState("");
    useEffect(() => {
        if ((totalRetentionAmount == undefined && nonPoData !== undefined) || erpValidateArr?.recovery_info !== undefined) {
            setTotRetentionAmount(
                erpValidateArr?.recovery_info !== undefined
                    ? !erpValidateArr?.recovery_info?.totalRetentionAmount
                        ? 0
                        : erpValidateArr?.recovery_info?.totalRetentionAmount?.toString()
                    : nonPoData[0]?.recovery_info?.totalRetentionAmount !== null && nonPoData[0]?.recovery_info?.totalRetentionAmount !== ""
                    ? nonPoData[0]?.recovery_info?.totalRetentionAmount
                    : "0"
            );
        }
    }, [nonPoData, erpValidateArr]);
    const DocInv = useSelector((state) => state?.getInvoiceDocumentState);
    const DocView = DocInv?.dataList;
    const [currentPlayObj, setCurrentPlayObj] = useState();
    const [timerValue, setTimerValue] = useState();
    const [currentIndex, setCurrentIndex] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const [manualPause, setManualPause] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const notConfirmedList = invoiceDataList?.invoice_items?.filter((el) => el?.label_value !== "" && el?.is_confirmed && el?.is_item == "0") || [];
    const playStart = () => {
        // Check if there are more objects in the array
        if (currentIndex < notConfirmedList.length && notConfirmedList.length > 0 && isPlaying && DocView) {
            // Do something with notConfirmedList[currentIndex]
            setCurrentPlayObj(notConfirmedList[currentIndex]);
            // Clear the previous timeout
            clearTimeout(playTimeout);
            // Update the index for the next iteration using the functional form of setState
            playTimeout = setTimeout(() => {
                setCurrentIndex((prevIndex) => (prevIndex !== null ? prevIndex + 1 : null));
            }, timerValue * 1000);
        } else {
            setIsCompleted(true);
            setIsPlaying(false);
            setCurrentIndex(null);
        }
    };
    useEffect(() => {
        // Start playing when isPlaying is true
        if (isPlaying) {
            playStart();
        }
    }, [isPlaying, currentIndex]);
    const handlePlay = () => {
        // Start playing
        if (currentIndex > 0 && DocView) {
            skipFocus();
        } else {
            setIsPlaying(true);
            setCurrentIndex(0);
            setIsCompleted(false);
        }
    };
    const handlePause = () => {
        // Pause playing
        setIsPlaying(false);
        setManualPause(true);
        setIsCompleted(false);
    };

    const handleStop = () => {
        // Stop playing and reset index
        setIsPlaying(false);
        setCurrentIndex(null);
    };
    const skipFocus = () => {
        setIsPlaying(true);
        setCurrentIndex((prevVal) => (isPlaying ? prevVal + 1 : prevVal));
    };
    const [openInvCalPop, setOpenInvCal] = useState(false);
    const [convertingArray, setConvertingArray] = useState([]); //for whole inv view calculation data state initialize here for sending edited data to payload...
    const [advData, setAdvData] = useState([]); //for whole inv view calculation advance recovery data state initialize here for sending edited data to payload...
    const [miscData, setMiscData] = useState([]); //for whole inv view calculation Misc debit data state initialize here for sending edited data to payload...
    const [creditData, setCreditData] = useState([]); //for whole inv view calculation credit note data state initialize here for sending edited data to payload...
    const [selectedFieldId, setFieldType] = useState();
    const [selectedFieldText, setFieldText] = useState();
    const [checkFreightByPo, setCheckFreightByPo] = useState(false);
    return (
        <>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <ProdTour />
                {(loading || statusUpdateLoading) && (
                    <div className="h-screen flex bg-[rgb(231,225,225,0.5)] absolute top-0 z-[12] left-0 right-0 justify-center items-center w-full">
                        {" "}
                        <img className="w-[7rem]" src="/images/gif/Loading-Gif.gif" alt="Loading-Gif" />
                    </div>
                )}
                <div className="flex justify-between">
                    {!fetching && requestComplete ? (
                        <>
                            <div className={`basis-[69.99%] xl:basis-[74.99%] ${openInvCalPop ? "m-auto" : "mx-auto"}`}>
                                {!openInvCalPop ? (
                                    <Content
                                        setCheckFreightByPo={setCheckFreightByPo}
                                        checkFreightByPo={checkFreightByPo}
                                        setFieldText={setFieldText}
                                        selectedFieldText={selectedFieldText}
                                        setFieldType={setFieldType}
                                        selectedFieldId={selectedFieldId}
                                        setIsFocused={setIsFocused}
                                        isFocused={isFocused}
                                        popref={popref}
                                        setTimerValue={setTimerValue}
                                        timerValue={timerValue}
                                        playStart={playStart}
                                        skipFocus={skipFocus}
                                        handlePlay={handlePlay}
                                        handlePause={handlePause}
                                        handleStop={handleStop}
                                        isPlaying={isPlaying}
                                        setIsPlaying={setIsPlaying}
                                        currentPlayObj={currentPlayObj}
                                        currentIndex={currentIndex}
                                        setCurrentIndex={setCurrentIndex}
                                        notConfirmedList={notConfirmedList}
                                        manualPause={manualPause}
                                        totalAmountVal={totalAmountVal}
                                        setTotalAmountVal={setTotalAmountVal}
                                        updatedTaxData={updatedTaxData}
                                        setUpdatedTaxData={setUpdatedTaxData}
                                        clickTaxOpen={clickTaxOpen}
                                        setClickTaxOpen={setClickTaxOpen}
                                        isCompleted={isCompleted}
                                        setIsCompleted={setIsCompleted}
                                        updateDocList={updateDocList}
                                        setAreArraysEqual={setAreArraysEqual}
                                        areArraysEqual={areArraysEqual}
                                        checkArraysEqual={checkArraysEqual}
                                        setCheckArraysEqual={setCheckArraysEqual}
                                        isClickedItem={isClickedItem}
                                        onShowLineItem={onShowLineItem}
                                        removeCostData={removeCostData}
                                        setRemoveCostData={setRemoveCostData}
                                        AddCostData={AddCostData}
                                        setAddCostData={setAddCostData}
                                        setNewLineItem={setNewLineItem}
                                        NewLineItem={NewLineItem}
                                        handleValidateChanges={handleValidateChanges}
                                        validateChanges={validateChanges}
                                        setValidateErr={setValidateErr}
                                        validateErr={validateErr}
                                        updtTblListData={updtTblListData}
                                        setUpdtTblListData={setUpdtTblListData}
                                        setRemoveLineItem={setRemoveLineItem}
                                        removeLineItem={removedLineItem}
                                        costListData={costListData}
                                        setCostListData={setCostListData}
                                        setFilteredLineItem={setFilteredLineItem}
                                        setMandatorLineItems={setMandatorLineItems}
                                        mandatoryLineItems={mandatoryLineItems}
                                        tblListData={tblListData}
                                        setisClick={setisClick}
                                        isClick={isClick}
                                        setdragObject={setdragObject}
                                        dragObject={dragObject}
                                        cropValue={cropValue}
                                        setisDrag={setisDrag}
                                        isDrag={isDrag}
                                        setCropValue={setCropValue}
                                        setTableList={setTableList}
                                        invoiceList={invoiceDataList}
                                        nonPOItemList={nonPOItemList}
                                        tourOpen={openTour}
                                        currentFieldSelectedType={currentFieldSelectedType}
                                        setCurrentFieldType={setCurrentFieldType}
                                        onSaveDoc={onSaveDoc}
                                        isClickedLineItem={clickedLineItem}
                                        pageType={pageTypeVal}
                                        lineitemData={lineitemData}
                                        setLineItemData={setLineItemData}
                                        totalRetentionAmount={totalRetentionAmount}
                                        setTotRetentionAmount={setTotRetentionAmount}
                                        totalTax={totalTax}
                                        setTotalTax={setTotalTax}
                                        objectLength={objectLength}
                                        setLineItemObjectLength={setLineItemObjectLength}
                                        invNo={invNo}
                                        setNonPOItemList={setNonPOItemList}
                                        cancelData={cancelData}
                                        onSaveInvoiceDoc={onSaveInvoiceDoc}
                                        setVendorObj={setVendorObj}
                                        vendorObj={vendorObj}
                                        updatedInvoiceList={updatedInvoiceList}
                                        requestDate={requestDate}
                                        ret_id={ret_id}
                                    />
                                ) : (
                                    <InvoiceCalculationTable
                                        isOpened={openInvCalPop}
                                        isEdit={InputDisable}
                                        lineItemtotalRetention={totalRetentionAmount}
                                        convertingArray={convertingArray}
                                        setConvertingArray={setConvertingArray}
                                        advData={advData}
                                        setAdvData={setAdvData}
                                        invId={invNo}
                                        miscData={miscData}
                                        setMiscData={setMiscData}
                                        creditData={creditData}
                                        setCreditData={setCreditData}
                                        listItems={invoiceDataList}
                                        className={openInvCalPop && "Show"}
                                        setClicked={setClicked}
                                        clicked={clicked}
                                        handleClose={() => setOpenInvCal(false)}
                                    />
                                )}
                            </div>
                            <div className="basis-[33.33%] xl:max-w-[490px]">
                                <Sidebar
                                    setCheckFreightByPo={setCheckFreightByPo}
                                    checkFreightByPo={checkFreightByPo}
                                    setCurrentFieldType={setCurrentFieldType}
                                    setFieldText={setFieldText}
                                    selectedFieldText={selectedFieldText}
                                    setFieldType={setFieldType}
                                    selectedFieldId={selectedFieldId}
                                    setVendorObj={setVendorObj}
                                    vendorObj={vendorObj}
                                    checkViewInv={checkViewInv}
                                    setCheckViewInv={setCheckViewInv}
                                    setOpenInvCal={setOpenInvCal}
                                    openInvCalPop={openInvCalPop}
                                    popref={popref}
                                    setIsFocused={setIsFocused}
                                    isFocused={isFocused}
                                    setTimerValue={setTimerValue}
                                    timerValue={timerValue}
                                    playStart={playStart}
                                    skipFocus={skipFocus}
                                    handlePlay={handlePlay}
                                    handlePause={handlePause}
                                    handleStop={handleStop}
                                    isPlaying={isPlaying}
                                    setIsPlaying={setIsPlaying}
                                    currentPlayObj={currentPlayObj}
                                    currentIndex={currentIndex}
                                    setCurrentIndex={setCurrentIndex}
                                    notConfirmedList={notConfirmedList}
                                    manualPause={manualPause}
                                    status={status}
                                    totalAmountVal={totalAmountVal}
                                    setTotalAmountVal={setTotalAmountVal}
                                    totalRetentionAmount={totalRetentionAmount}
                                    setTotRetentionAmount={setTotRetentionAmount}
                                    updatedTaxData={updatedTaxData}
                                    setUpdatedTaxData={setUpdatedTaxData}
                                    clickTaxOpen={clickTaxOpen}
                                    setClickTaxOpen={setClickTaxOpen}
                                    checkArraysEqual={checkArraysEqual}
                                    setCheckArraysEqual={setCheckArraysEqual}
                                    setAreArraysEqual={setAreArraysEqual}
                                    setNonPOItemList={setNonPOItemList}
                                    areArraysEqual={areArraysEqual}
                                    isClickedItem={isClickedItem}
                                    onShowLineItem={onShowLineItem}
                                    newCostArr={newCostArr}
                                    setNewCostArr={setNewCostArr}
                                    costListData={costListData}
                                    setCostListData={setCostListData}
                                    removeCostData={removeCostData}
                                    setRemoveCostData={setRemoveCostData}
                                    AddCostData={AddCostData}
                                    setAddCostData={setAddCostData}
                                    updtTblListData={updtTblListData}
                                    setUpdtTblListData={setUpdtTblListData}
                                    setNewLineItem={setNewLineItem}
                                    NewLineItem={NewLineItem}
                                    handleValidateChanges={handleValidateChanges}
                                    validateChanges={validateChanges}
                                    setValidateErr={setValidateErr}
                                    validateErr={validateErr}
                                    invoiceList={invoiceDataList}
                                    tourOpen={openTour}
                                    dragObject={dragObject}
                                    setisDrag={setisDrag}
                                    tblListData={filteredLineItem}
                                    isDrag={isDrag}
                                    setdragObject={setdragObject}
                                    setisClick={setisClick}
                                    setMandatorLineItems={setMandatorLineItems}
                                    mandatoryLineItems={mandatoryLineItems}
                                    cropValue={cropValue}
                                    setCropValue={setCropValue}
                                    isClick={isClick}
                                    nonPOItemList={nonPOItemList}
                                    fieldTypeClick={fieldTypeClick}
                                    isLineItemClick={isLineItemClick}
                                    onSaveDoc={onSaveDoc}
                                    pageType={pageTypeVal}
                                    resetPage={resetPageData}
                                    invoiceId={invNo}
                                    updateDocList={updateDocList}
                                    onSaveInvoiceDoc={onSaveInvoiceDoc}
                                    lineitemData={lineitemData}
                                    setLineItemData={setLineItemData}
                                    totalTax={totalTax}
                                    setTotalTax={setTotalTax}
                                    objectLength={objectLength}
                                    setLineItemObjectLength={setLineItemObjectLength}
                                    convertingArray={convertingArray}
                                    isInvCalPop={openInvCalPop}
                                    setConvertingArray={setConvertingArray}
                                    advData={advData}
                                    setAdvData={setAdvData}
                                    miscData={miscData}
                                    setMiscData={setMiscData}
                                    creditData={creditData}
                                    setCreditData={setCreditData}
                                    setClicked={setClicked}
                                    clicked={clicked}
                                    updatedInvoiceList={updatedInvoiceList}
                                    requestDate={requestDate}
                                />
                            </div>
                        </>
                    ) : (
                        <Loader className="h-screen max-h-[-webkit-fill-available]" />
                    )}
                </div>
            </ErrorBoundary>
        </>
    );
};

export default InvoiceView;
