import { Autocomplete, Checkbox, Chip, Paper, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { countryList } from "../../redux/actions/country";
import { GetCurrencyListExchangeRate } from "../../redux/actions/exchange-rate";


const MultiSelectComponent = (props) => {
    const { listData, defaultId, handleSelectUser, id, check, userIds, element: userData } = props;
    const userListdata = listData;
    const dropdownColor = props?.currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = props?.currentMode === "dark" ? "#ECECEC" : `#555555`;
    const dropdownShadow = props?.currentMode !== "dark" ? "0px 4px 20px rgba(238, 238, 238, 0.5)" : "";
    const dropdownBorder = props?.currentMode !== "dark" ? "223, 225, 226, 0.56" : "";
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(GetCurrencyListExchangeRate());
        dispatch(countryList());
    }, [])

    const useStyles = makeStyles((theme) => ({
        dropdown: {
            "& .MuiAutocomplete-inputRoot": {
                "& .MuiAutocomplete-input": {
                    color: "white", // Replace with the color you want
                    "&::placeholder": {
                        color: "gray", // Replace with the placeholder color you want
                    },
                },
            },
            "& .MuiAutocomplete-popupIndicator": {
                color: "black", // Replace with the dropdown icon color you want
            },
        },
    }));

    const classes = useStyles();
    const Currency = useSelector((state) => state?.currencyListState);
    const currencies = Currency?.data
    const GetFilter = currencies?.filter((el) => props?.defaultList?.includes(+el?.currency_id));


    return (
        <>
            <div className="flex max-w-[230px]  justify-center filter items-center">
                <Autocomplete
                    disabled={props?.documentGroup == 0}
                    className="selectDisabled  max-w-[230px]"
                    classes={{
                        paper: classes?.dropdown,
                    }}
                    sx={{
                        "& .MuiChip-label": {
                            color: `${props?.currentMode == "dark" ? "white" : "black"}`,
                        },
                    }}
                    PaperComponent={({ children }) => (
                        <Paper
                            style={{
                                backgroundColor: dropdownColor,
                                color: dropdownText,
                                boxShadow: dropdownShadow,
                                border: dropdownBorder,
                            }}>
                            <div className={`flex justify-between items-center  py-[10px] border-b-[1px] border-${props?.currentMode}-590`}>
                                <h4 className="font-interSb font-lg pl-[10px]">Select Currency </h4>
                                <span onClick={() => { }} className="cursor-pointer" tabIndex={0}>
                                    <img src="/images/common/closeBlk.svg" alt="closeBlk" className="convertWhite px-2" />
                                </span>
                            </div>
                            {children}
                        </Paper>
                    )}
                    popupIcon={<img className="m-0 opacity-50 pr-1 pointer-events-none" src="/images/header/chevronDown.svg" />}
                    multiple
                    name={id}
                    value={GetFilter?.length > 0 ? GetFilter : []}
                    id="checkboxes-tags-demo"
                    options={currencies == undefined ? [] : currencies}
                    disableCloseOnSelect
                    limitTags={1}
                    getOptionLabel={(option) => option?.currency_code + "-" + option?.currency_name}
                    onChange={props?.HandleSelect}
                    renderOption={(props, option, { selected }) => {
                        return (
                            <li {...props}>
                                <Checkbox
                                    style={{ marginRight: 8 }}
                                    sx={{
                                        color: "rgba(85,85,85,0.7)",
                                        borderRadius: "3px !important",
                                        "&.Mui-checked": {
                                            color: "#028C78",
                                        },
                                    }}
                                    //   onChange={handleCheckboxChange(option)}
                                    checked={selected}
                                />
                                {/* <img className="w-[15px] h-[15px]  object-cover rounded-full" alt="Natacha" src={`/images/common/profileImg.jpg`} /> */}
                                <span className={`max-w-[160px] truncate text-${props?.currentMode}-copy`} title={option?.currency_code + "-" + option?.currency_name}>
                                    {option?.currency_code} - {option?.currency_name}
                                </span>
                            </li>
                        );
                    }}
                    style={{ width: 500 }}
                    renderInput={(params) => (
                        <TextField
                            sx={{
                                "& .MuiInputBase-input": {
                                    color: `${props?.currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                },
                            }}
                            {...params}
                            placeholder={GetFilter?.length == 0 ? "Select Currency" : ""}
                            autoComplete="off"
                        />
                    )}
                />
            </div>
        </>
    );
};
export default MultiSelectComponent;
