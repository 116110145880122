import { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Input from "../../components/Input";
import Card from "../../components/UI/Card";
import Main from "../../components/UI/Main";
import Layout from "../../Hoc/Layout";
import Footer from "../../organisms/Footer";
import ReactDOM from "react-dom";
import RoleSwitchModal from "./RoleSwitchModal";
import { decryptId } from "../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { UserDropDown } from "../../redux/actions/user";
import RemoveModal from "./RemoveModal";
import { GetInvoiceIdConfig } from "../../redux/actions/invoice";
import AccountSync from "./AccountSync";
import { GetGeneralLedgerSyncList } from "../../redux/actions/account-sync.js";
import AccountDelete from "./AccountDelete";
import BackupData from "./BackupData";
import { getCurrentUser } from "../../redux/actions/auth";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import UpgradeModal from "./UpgradeModal";
import { listOrganisationDropDown } from "../../redux/actions/organisation.js";
const AdminSetting = () => {
    const { currentMode } = useContext(ThemeContext);
    const dispatch = useDispatch();
    const currentUser = getCurrentUser();
    const [role, setRole] = useState(false);
    const [remove, setRemove] = useState(false);
    const [syncOpen, setsyncOpen] = useState(false);
    const [accountDeleteOpen, setAccountDeleteOpen] = useState(false);
    const [backupOpen, setBackupOpen] = useState(false);
    const [upgradeOpen, setUpgradeOpen] = useState(false);
    const orgId = localStorage.getItem("orgId");
    const CurrentUser = getCurrentUser();
    const reqobj = {
        order_by: 1,
        order_column: "",
        pg_no: 1,
        rec_cnt: 20,
        role_id: 0,
        search_text: "",
        status: 1,
        org_id: +orgId,
    };
    useEffect(() => {
        dispatch(listOrganisationDropDown());
    }, []);
    const DispatchApi = () => {
        dispatch(UserDropDown(reqobj));
        setRole(true);
    };
    const syncHandlerOpen = () => {
        setsyncOpen(true);
        dispatch(GetGeneralLedgerSyncList());
    };
    const accOpen = () => {
        setAccountDeleteOpen(true);
    };
    const BackupFile = () => {
        setBackupOpen(true);
    };
    const params = new URLSearchParams(window.location.search);
    let type = params.get("type");
    let tourId = params.get("tourId");
    const targetDateStr = currentUser?.expiring_date;
    const targetDates = new Date(targetDateStr);
    const currentDate = new Date();
    const DisplayRole = useSelector((state) => state?.DisplayRolePermissionStateAction);
    const { data: DataList } = DisplayRole;
    const RoleList = DataList?.role_info;
    const RoleMenu = RoleList?.menu;
    const Roleorg = RoleMenu?.find((elem) => elem?.menu_id === 1);
    const RoleActOrg = Roleorg?.actions;
    const checkorg = RoleActOrg?.some((element) => element.isallowed === true);
    const RoleUser = RoleMenu?.find((elem) => elem?.menu_id === 6);
    const RoleActUser = RoleUser?.actions;
    const checkUser = RoleActUser?.some((element) => element.isallowed === true);
    const matchTrialdate = targetDates < currentDate;
    const showIcon =
        ((!matchTrialdate && currentUser?.license_type == 2) ||
            currentUser?.license_type == 1 ||
            currentUser?.license_type == 3 ||
            currentUser?.license_type == 4) &&
        currentUser?.role_id == 2;
    const ShowProffesionIcon = ((!matchTrialdate && currentUser?.license_type == 2) || currentUser?.license_type !== 4) && currentUser?.role_id == 2;
    const ShowBusinessIcon = ((!matchTrialdate && currentUser?.license_type == 2) || currentUser?.license_type !== 3) && currentUser?.role_id == 2;
    const RuleMasterIcon = (!matchTrialdate && currentUser?.license_type == 2) || currentUser?.role_id == 2;
    const sections = [
        {
            id: 6,
            label: "Organization Management",
            desc: "Effortlessly manage multiple organizations, hierarchies, and invoice workflows with Organization Management module.",
            img: "/images/common/organisation-new.svg",
            ref: useRef(null),
            name: "Organization Management",
        },
        {
            id: 1,
            label: "Users Management",
            desc: "Easily manage system users. Streamline access, permissions, and collaboration in just a few clicks.",
            img: "/images/settings/user-blk.svg",
            ref: useRef(null),
            name: "Users Management",
        },
        {
            id: 2,
            label: "Invoice Management",
            desc: "Revamp invoicing: customize layouts, track IDs, assign teams smartly, and optimize task allocation - all with ease.",
            img: "/images/settings/invoice-setting-ico.svg",
            ref: useRef(null),
            name: "Invoice Management",
        },
        {
            id: 3,
            label: "Task Management",
            desc: "Efficiently manage tasks: resolve issues, communicate seamlessly, track progress, and prioritize effectively using Task Management.",
            img: "/images/settings/task-setting-icon.svg",
            ref: useRef(null),
            name: "Task Management",
        },
        {
            id: 4,
            label: "Account Management",
            desc: "Simplify admin control: transfer rights, prep for deployment, ensure data security via backup config and user/org account management.",
            img: "/images/settings/account-setting-ico.svg",
            ref: useRef(null),
            name: "Account Management",
        },
        {
            id: 5,
            label: "ERP",
            desc: "Explore enhanced ERP integration: connect seamlessly via APIs, automate invoices, accruals, and master data sync for efficient data entry with MyiQ.Ai.",
            img: "/images/settings/erp-setting-ico.svg",
            ref: useRef(null),
            name: "ERP",
        },

        {
            id: 7,
            label: "Exception Automation",
            desc: "Exception Automation leverages AI & ML technology to detect, manage, and automate exceptions during the invoice processing workflow.",
            img: "/images/settings/Exception.svg",
            ref: useRef(null),
            name: "Exception Automation",
        }


    ];
    const ExcludeSection = sections?.filter((el) => el?.id == 4 || el?.id == 6 || el?.id == 1);
    const ExcludeTask = sections?.filter((el) => el?.id !== 3);
    const IncludeUser = sections?.filter((el) => el?.id == 1);
    const IncludeOrg = sections?.filter((el) => el?.id == 6);
    const IncludeOrgAndUser = sections?.filter((el) => el?.id == 6 || el?.id == 1);
    const SectionManage =
        currentUser?.role_id == 1 && (showIcon || (checkorg && checkUser))
            ? IncludeOrgAndUser
            : currentUser?.role_id == 1 && (showIcon || checkUser)
                ? IncludeUser
                : currentUser?.role_id == 1 && (showIcon || checkorg)
                    ? IncludeOrg
                    : showIcon
                        ? sections
                        : ExcludeSection;
    const location = useLocation();

    const contentRefs = useRef([]);
    const scrollToSection = (ref) => {
        if (ref.current) {
            ref.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "nearest",
            });
        }
    };

    return (
        <>
            <Layout>
                <Main>
                    <div className="mb-[25px]">
                        <h4 className={`text-2xl font-interSb text-${currentMode}-copy`}>Settings</h4>
                    </div>
                    <Card
                        className={`xl:h-[calc(100vh_-_14rem)] h-[calc(100vh_-_18rem)] flex ${currentMode === "dark" ? "" : "shadow-light-760 shadow-[0px_4px_20px_0px]"
                            }`}
                    >
                        <div
                            className={`xl:w-[25%] w-1/2 nailed border-r-[1px] ${currentMode === "dark" ? "" : "shadow-light-760 shadow-[0px_4px_20px_0px]"
                                }  xl:min-w-[23.5rem] border-560 overflow-y-auto xl:h-[calc(100vh_-_14rem)] h-[calc(100vh_-_18rem)] border-${currentMode}-590`}
                        >
                            {SectionManage?.map((el, i) => {
                                return (
                                    <a
                                        key={i}
                                        // to={`#section${el?.id}`}
                                        name={`#section${el?.name}`}
                                        href={`#section${el?.id}`}
                                        onClick={() => scrollToSection(el?.ref)}
                                        className={`${el?.label == "Organization Management" ? `Organisation_step` : "user_management_step "}${`#section${el?.id}` == location?.hash ? "selected" : ""
                                            } flex p-4 first:rounded-tl-[8px] leftSide transition duration-300 ease-in-out ${currentMode == "dark" ? "hover:bg-[#008785]" : "hover:bg-light-420"
                                            } `}
                                    >
                                        <div className="mr-3">
                                            <img
                                                src={el?.img}
                                                style={{
                                                    filter: `${(currentMode &&
                                                        (tourId == 0 || (type && !tourId)) &&
                                                        el?.label == "Organization Management") ||
                                                        (currentMode && tourId == 1 && el?.label == "Users Management")
                                                        ? `invert(36%) sepia(61%) saturate(7046%) hue-rotate(159deg) brightness(96%) contrast(98%)`
                                                        : ""
                                                        }`,
                                                }}
                                                className="convertWhite"
                                                alt="users-ico"
                                            />
                                        </div>
                                        <div className="flex-1">
                                            <span
                                                className={`font-interSb block mb-2 ${((tourId == 0 || (type && !tourId)) && el?.label == "Organization Management") ||
                                                    (tourId == 1 && el?.label == "Users Management")
                                                    ? "text-[#028C78]"
                                                    : ""
                                                    }`}
                                            >
                                                {el?.label}
                                            </span>
                                            <p>{el?.desc}</p>
                                        </div>
                                    </a>
                                );
                            })}
                        </div>
                        {/* <div className="bg-transparent mb-[40px] border-[rgba(236,236,236,0.88)] border-[1px] relative lg:flex justify-between py-[10px] px-[16px] xl:min-w-[250px] rounded-[8px]">
              <Input
                placeholder="Search..."
                className="border-0 bg-transparent w-full"
                disableUnderline={true}
                value={localStorage.getItem("searchId")}
                name="searchId"
                // onChange={(e) => handleSearchText(e.target.value)}
                // onClick={handleClick("bottom-end", 1)}
                autoComplete="off"
              /> */}
                        {/* {searchValue && searchValue.length !== 0 ? ( */}
                        {/* <img
                  onClick={resetInputField}
                  src="/images/common/closeBlk.svg"
                  alt="close"
                /> */}
                        {/* ) : ( */}
                        {/* <img src="/images/header/search.svg" alt="search" /> */}
                        {/* )} */}
                        {/* </div> */}
                        <div
                            className="xl:w-[75%] w-1/2 px-4 xl:px-[30px] py-[20px] xl:h-[calc(100vh_-_14rem)] h-[calc(100vh_-_18rem)] overflow-auto"
                            id="scroll-container"
                        >
                            {checkorg && (
                                <div id="section6" className="scroll-mt-[20px]">
                                    <span className="text-light-10 text-base font-interSb mb-3 block">Organization Management</span>

                                    <div className="grid grid-cols-1 xl:grid-cols-2 gap-y-[3rem] mb-5 gap-x-5">
                                        <Link
                                            to="/organisations"
                                            name="Organisation Management"
                                            className={`flex max-w-[26rem] p-4 rounded-[8px] transition duration-300 ease-in-out hover:bg-${currentMode}-1010 hover:border-[#333333] hover:shadow-[0px_4px_20px_rgba(85,85,85,0.2)]`}
                                        >
                                            <div className="mr-3">
                                                <img src="/images/common/organisation-new.svg" className="convertWhite" alt="org-ico" />
                                            </div>
                                            <div className="flex-1">
                                                <span className="font-interSb block mb-2">Organization</span>
                                                <p>Centrally manage all our Organizations setup.</p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            )}

                            <div id="section1" className="scroll-mt-[20px]">
                                {showIcon || checkUser ? (
                                    <span className="text-light-10 text-base font-interSb mb-3 block">Users Management</span>
                                ) : (
                                    ""
                                )}

                                <div className="grid grid-cols-1 xl:grid-cols-2 gap-y-[3rem] mb-5 gap-x-5">
                                    {checkUser && (
                                        <Link
                                            to="/users"
                                            name="Users"
                                            className={`flex max-w-[26rem] p-4 rounded-[8px] transition duration-300 ease-in-out hover:bg-${currentMode}-1010 hover:border-[#333333] hover:shadow-[0px_4px_20px_rgba(85,85,85,0.2)]`}
                                        >
                                            <div className="mr-3">
                                                <img src="/images/settings/user-blk.svg" className="convertWhite" alt="users-ico" />
                                            </div>
                                            <div className="flex-1">
                                                <span className="font-interSb block mb-2">Users</span>
                                                <p>Create or Invite users to manage their profiles and access.</p>
                                            </div>
                                        </Link>
                                    )}{" "}
                                    {showIcon && (
                                        <Link
                                            to="/role-master"
                                            name="User Roles & Permissions"
                                            className={`flex max-w-[26rem] p-4 rounded-[8px] transition duration-300 ease-in-out hover:bg-${currentMode}-1010 hover:border-[#333333] hover:shadow-[0px_4px_20px_rgba(85,85,85,0.2)]`}
                                        >
                                            <div className="mr-3">
                                                <img src="/images/common/user-permissions.svg" className="convertWhite" alt="users-ico" />
                                            </div>
                                            <div className="flex-1">
                                                <span className="font-interSb block mb-2">User Roles & Permissions</span>
                                                <p>
                                                    Empower your team with pre-defined roles or tailored user roles and permissions. Fine-tune access
                                                    control to enhance security and productivity effortlessly.
                                                </p>
                                            </div>
                                        </Link>
                                    )}
                                </div>
                            </div>
                            {currentUser?.role_id == 2 &&
                                ((!matchTrialdate && currentUser?.license_type == 2) ||
                                    currentUser?.license_type == 3 ||
                                    CurrentUser?.license_type == 4 ||
                                    currentUser?.license_type == 1) && (
                                    <div id="section2" className="scroll-mt-[20px]">
                                        <span className="text-light-10 text-base font-interSb mb-3 block">Invoice Management</span>
                                        <div className="grid grid-cols-1 xl:grid-cols-2 gap-y-[.5rem] mb-4 gap-x-5">
                                            {showIcon && (
                                                <Link
                                                    to="/invoice-layout"
                                                    name="Invoice Layout"
                                                    className={`flex max-w-[26rem] p-4 rounded-[8px] transition duration-300 ease-in-out hover:bg-${currentMode}-1010 hover:border-[#333333] hover:shadow-[0px_4px_20px_rgba(85,85,85,0.2)]`}
                                                >
                                                    <div className="mr-3 convertWhite">
                                                        <img src="/images/settings/noun-invoice.svg" alt="apps" />
                                                    </div>
                                                    <div className="flex-1 ">
                                                        <span className="font-interSb block mb-2">Invoice Layout</span>
                                                        <p>
                                                            Take control of your invoice presentation, customizable invoice layout. Arrange, edit, and
                                                            display content as per your preference, ensuring a personalized and efficient invoicing
                                                            experience.
                                                        </p>
                                                    </div>
                                                </Link>
                                            )}{" "}
                                            {showIcon && (
                                                <Link
                                                    to="/invoiceid-config"
                                                    name="ID Configuration"
                                                    className={`flex max-w-[26rem] p-4 rounded-[8px] transition duration-300 ease-in-out hover:bg-${currentMode}-1010 hover:border-[#333333] hover:shadow-[0px_4px_20px_rgba(85,85,85,0.2)]`}
                                                >
                                                    <div className="mr-3 convertWhite">
                                                        <img src="/images/settings/noun-setting.svg" alt="apps" />
                                                    </div>
                                                    <div className="flex-1">
                                                        <span className="font-interSb block mb-2">ID Configuration</span>
                                                        <p>
                                                            Simplify tracking and auditing with ID configuration feature. Effortlessly create
                                                            document-specific numbering for seamless organization and reliable reference throughout
                                                            your invoicing process.
                                                        </p>
                                                    </div>
                                                </Link>
                                            )}
                                            {RuleMasterIcon && (
                                                <Link
                                                    to={
                                                        currentUser?.license_type == 4 || currentUser?.license_type == 3 ? undefined : `/invoice-rule`
                                                    }
                                                    name="Rule Master"
                                                    onClick={
                                                        currentUser?.license_type == 3 || currentUser?.license_type == 4
                                                            ? () => setUpgradeOpen(true)
                                                            : null
                                                    }
                                                    className={`${currentUser?.license_type == 3 || currentUser?.license_type == 4 ? `opacity-[.5]` : ""
                                                        } flex max-w-[26rem] p-4 rounded-[8px] transition duration-300 ease-in-out hover:bg-${currentMode}-1010 hover:border-[#333333] hover:shadow-[0px_4px_20px_rgba(85,85,85,0.2)]`}
                                                >
                                                    <div className="mr-3 convertWhite">
                                                        <img src="/images/settings/noun-pdf.svg" alt="rulemaster" />
                                                    </div>
                                                    <div className="flex-1">
                                                        <span className="font-interSb block mb-2">Rule Master</span>
                                                        <p>
                                                            Effortlessly manage invoice assignments using Rule Master. Automate tasks by setting
                                                            custom filters based on value, vendor names, and more, streamlining workflow with
                                                            precision.
                                                        </p>
                                                    </div>
                                                </Link>
                                            )}
                                            {ShowProffesionIcon && (
                                                <Link
                                                    to={ShowProffesionIcon ? `/workflow-automation` : undefined}
                                                    onClick={ShowProffesionIcon ? null : () => setUpgradeOpen(true)}
                                                    name="Workflow Automation"
                                                    className={`${ShowProffesionIcon ? `` : `opacity-[.5]`
                                                        } flex max-w-[26rem] p-4 rounded-[8px] transition duration-300 ease-in-out hover:bg-${currentMode}-1010 hover:border-[#333333] hover:shadow-[0px_4px_20px_rgba(85,85,85,0.2)]`}
                                                >
                                                    <div className="mr-3 convertWhite">
                                                        <img src="/images/settings/network-connection.svg" alt="allocation" />
                                                    </div>
                                                    <div className="flex-1">
                                                        <span className="font-interSb block mb-2">Workflow Automation</span>
                                                        <p>
                                                            Efficiently distribute tasks for equitable workload using customizable methods like
                                                            round-robin.
                                                        </p>
                                                    </div>
                                                    {/* <div className="flex-1">
                                                        <span className="font-interSb block mb-2">Invoice Allocation</span>
                                                        <p>
                                                            Efficiently distribute tasks with Invoice Allocation feature. Seamlessly assign invoices
                                                            to users using customizable methods like round-robin, ensuring balanced workloads within
                                                            your team.
                                                        </p>
                                                    </div>{" "} */}
                                                </Link>
                                            )}
                                            {ShowProffesionIcon && (
                                                <Link
                                                    to={ShowProffesionIcon ? `/document-type` : undefined}
                                                    onClick={ShowProffesionIcon ? null : () => setUpgradeOpen(true)}
                                                    className={`${ShowProffesionIcon ? `` : `opacity-[.5]`
                                                        } flex max-w-[26rem] p-4 rounded-[8px] transition duration-300 ease-in-out hover:bg-${currentMode}-1010 hover:border-[#333333] hover:shadow-[0px_4px_20px_rgba(85,85,85,0.2)]`}
                                                >
                                                    <div className="mr-3 convertWhite">
                                                        <img src="/images/settings/document-type.svg" alt="document-type" />
                                                    </div>
                                                    <div className="flex-1">
                                                        <span className="font-interSb block mb-2">Document Type Setup</span>
                                                        <p>
                                                            The Document Type Setup involves configuring and managing distinct document types across
                                                            diverse business transactions, ensuring accurate classification, processing, and reporting
                                                            for organizations.
                                                        </p>
                                                    </div>
                                                    {/* <div className="flex-1">
                                                        <span className="font-interSb block mb-2">Invoice Allocation</span>
                                                        <p>
                                                            Efficiently distribute tasks with Invoice Allocation feature. Seamlessly assign invoices
                                                            to users using customizable methods like round-robin, ensuring balanced workloads within
                                                            your team.
                                                        </p>
                                                    </div>{" "} */}
                                                </Link>
                                            )}
                                        </div>
                                    </div>
                                )}

                            {showIcon && (
                                <div id="section3" className="scroll-mt-[20px]">
                                    <span className="text-light-10 text-base font-interSb mb-3 block">Task Management</span>
                                    <div className="grid grid-cols-1 xl:grid-cols-2 gap-y-[.5rem] mb-4 gap-x-5">
                                        <Link
                                            to={ShowProffesionIcon ? `/emailtemplate` : undefined}
                                            onClick={ShowProffesionIcon ? null : () => setUpgradeOpen(true)}
                                            className={`${ShowProffesionIcon ? `` : `opacity-[.5]`
                                                } flex max-w-[26rem] p-4 rounded-[8px] transition duration-300 ease-in-out hover:bg-${currentMode}-1010 hover:border-[#333333] hover:shadow-[0px_4px_20px_rgba(85,85,85,0.2)]`}
                                        >
                                            <div className="mr-3 convertWhite">
                                                <img src="/images/settings/email.svg" alt="envolope-open" />
                                            </div>
                                            <div className="flex-1">
                                                <span className="font-interSb block mb-2">Email Templates</span>
                                                <p>
                                                    Enhance communication efficiency using our customizable email templates. Streamline interactions
                                                    during invoice processing, ensuring consistent and effective correspondence within and outside
                                                    your organization.
                                                </p>
                                            </div>
                                        </Link>
                                        <Link
                                            to={ShowProffesionIcon ? `/taskid-config` : undefined}
                                            onClick={ShowProffesionIcon ? null : () => setUpgradeOpen(true)}
                                            name="Task Id Configuration"
                                            className={`${ShowProffesionIcon ? `` : `opacity-[.5]`
                                                } flex max-w-[26rem] p-4 rounded-[8px] transition duration-300 ease-in-out hover:bg-${currentMode}-1010 hover:border-[#333333] hover:shadow-[0px_4px_20px_rgba(85,85,85,0.2)]`}
                                        >
                                            <div className="mr-3 convertWhite">
                                                <img src="/images/settings/task-setting-icon.svg" alt="task-idconfig" />
                                            </div>
                                            <div className="flex-1">
                                                <span className="font-interSb block mb-2">Configure Task ID</span>
                                                <p>
                                                    Effortlessly organize tasks with Task ID configuration. Establish unique identifiers for
                                                    streamlined tracking and efficient task management throughout your processes.
                                                </p>
                                            </div>
                                        </Link>
                                        <Link
                                            to={ShowProffesionIcon ? `/priority-config` : undefined}
                                            onClick={ShowProffesionIcon ? null : () => setUpgradeOpen(true)}
                                            name="Configure Priorities"
                                            className={`${ShowProffesionIcon ? `` : `opacity-[.5]`
                                                } flex max-w-[26rem] p-4 rounded-[8px] transition duration-300 ease-in-out hover:bg-${currentMode}-1010 hover:border-[#333333] hover:shadow-[0px_4px_20px_rgba(85,85,85,0.2)]`}
                                        >
                                            <div className="mr-3 convertWhite ">
                                                <img src="/images/settings/noun-priority.svg" alt="priority-config" />
                                            </div>
                                            <div className="flex-1 ">
                                                <span className="font-interSb block mb-2">Configure Priorities</span>
                                                <p>
                                                    Optimize task workflow with our Priority Configuration feature. Seamlessly assign urgency levels,
                                                    ensuring efficient and focused resolution of invoice processing matters.
                                                </p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                // )
                            )}
                            {currentUser?.role_id == 2 && (
                                <div id="section4" className="scroll-mt-[20px]">
                                    <span className="text-light-10 text-base font-interSb mb-3 block">Account Management</span>

                                    <div className="grid grid-cols-1 xl:grid-cols-2 gap-y-[.5rem] mb-4 gap-x-5">
                                        {showIcon && (
                                            <Link
                                                onClick={DispatchApi}
                                                name="Transfer Ownership"
                                                className={`flex max-w-[26rem] p-4 rounded-[8px] transition duration-300 ease-in-out hover:bg-${currentMode}-1010 hover:border-[#333333] hover:shadow-[0px_4px_20px_rgba(85,85,85,0.2)]`}
                                            >
                                                <div className="mr-3 convertWhite">
                                                    <img src="/images/settings/noun-transfer-data.svg" alt="envolope-open" />
                                                </div>
                                                <div className="flex-1">
                                                    <span className="font-interSb block mb-2">Transfer Ownership</span>
                                                    <p>
                                                        Seamlessly delegate control with our Transfer Ownership feature. Effortlessly transition admin
                                                        rights to new users, maintaining a smooth operational transition.
                                                    </p>
                                                </div>
                                            </Link>
                                        )}
                                        {showIcon && (
                                            <Link
                                                onClick={() => setRemove(true)}
                                                name="Clean up Demo Data"
                                                className={`flex max-w-[26rem] p-4 rounded-[8px] transition duration-300 ease-in-out hover:bg-${currentMode}-1010 hover:border-[#333333] hover:shadow-[0px_4px_20px_rgba(85,85,85,0.2)]`}
                                            >
                                                <div className="mr-3 convertWhite">
                                                    <img src="/images/settings/database.svg " alt="db-icon" />
                                                </div>
                                                <div className="flex-1">
                                                    <span className="font-interSb block mb-2">Clean up Demo Data</span>
                                                    <p>
                                                        Prepare for a streamlined launch using our Clean Up Demo Data tool. Effortlessly remove trial
                                                        data, ensuring a fresh start for your application's go-live.
                                                    </p>
                                                </div>
                                            </Link>
                                        )}
                                        {currentUser?.role_id == 2 && (
                                            <Link
                                                Link
                                                onClick={BackupFile}
                                                name="Data Backup"
                                                className={`flex max-w-[26rem] p-4 rounded-[8px] transition duration-300 ease-in-out hover:bg-${currentMode}-1010 hover:border-[#333333] hover:shadow-[0px_4px_20px_rgba(85,85,85,0.2)]`}
                                            >
                                                <div className="mr-3 convertWhite">
                                                    <img src="/images/settings/backup-data.svg" alt="Backup" />
                                                </div>
                                                <div className="flex-1 ">
                                                    <span className="font-interSb block mb-2">Data Backup</span>
                                                    <p>
                                                        Secure your data with ease using our Data Backup solutions. Configure strategies and schedules
                                                        for regular backups, ensuring peace of mind and data resilience.
                                                    </p>
                                                </div>
                                            </Link>
                                        )}

                                        {currentUser?.role_id == 2 && currentUser?.license_type == 2 && (
                                            <Link
                                                onClick={accOpen}
                                                name="Delete Account"
                                                className={`flex max-w-[26rem] p-4 rounded-[8px] transition duration-300 ease-in-out hover:bg-${currentMode}-1010 hover:border-[#333333] hover:shadow-[0px_4px_20px_rgba(85,85,85,0.2)]`}
                                            >
                                                <div className="mr-3 convertWhite">
                                                    <img src="/images/settings/delete-account.svg" alt="AccDelete" />
                                                </div>
                                                <div className="flex-1">
                                                    <span className="font-interSb block mb-2">Delete Account</span>
                                                    <p>
                                                        Easily manage your organizational data with Account Deletion. Safely remove user accounts and
                                                        associated data, promoting data privacy and compliance effortlessly.
                                                    </p>
                                                </div>
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            )}
                            {showIcon && (
                                <div id="section5" className="scroll-mt-[20px]">
                                    <span className="text-light-10 text-base font-interSb mb-3 block">ERP</span>
                                    <div className="grid grid-cols-1 xl:grid-cols-2 gap-y-[.5rem] mb-4 gap-x-5">
                                        {
                                            // ShowProffesionIcon &&
                                            <Link
                                                to={ShowProffesionIcon ? `/accrual-accounting` : undefined}
                                                name="ERP"
                                                onClick={ShowProffesionIcon ? null : () => setUpgradeOpen(true)}
                                                className={`${ShowProffesionIcon ? `` : `opacity-[.5]`
                                                    } flex max-w-[26rem] p-4 rounded-[8px] transition duration-300 ease-in-out hover:bg-${currentMode}-1010 hover:border-[#333333] hover:shadow-[0px_4px_20px_rgba(85,85,85,0.2)]`}
                                            >
                                                <div className="mr-3 convertWhite">
                                                    <img src="/images/settings/account-setting-ico.svg" alt="accrual" />
                                                </div>
                                                <div className="flex-1">
                                                    <span className="font-interSb block mb-2">Accrual Automation</span>
                                                    <p>
                                                        Simplify month-end processes with Accrual Automation. Streamline financial procedures,
                                                        ensuring a seamless and efficient close to each accounting period.
                                                    </p>
                                                </div>
                                            </Link>
                                        }
                                        {showIcon && (
                                            <Link
                                                to="/account-sync"
                                                name="Master Data Sync"
                                                // onClick={syncHandlerOpen}
                                                className={`flex max-w-[26rem] p-4 rounded-[8px] transition duration-300 ease-in-out hover:bg-${currentMode}-1010 hover:border-[#333333] hover:shadow-[0px_4px_20px_rgba(85,85,85,0.2)]`}
                                            >
                                                <div className="mr-3 convertWhite">
                                                    <img src="/images/settings/noun-accounting-book.svg" alt="allocation" />
                                                </div>
                                                <div className="flex-1">
                                                    <span className="font-interSb block mb-2">Master Data Sync</span>
                                                    <p>
                                                        Simplify data entry with our Master Data Sync feature. Seamlessly integrate G/L accounts, cost
                                                        centers, project codes, and more from your ERP system, ensuring accuracy and efficiency.
                                                    </p>
                                                </div>
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            )}
                            {currentUser?.role_id == 2 &&
                                <div id="section7" className="scroll-mt-[20px]">
                                    <span className="text-light-10 text-base font-interSb mb-3 block">Exception Automation</span>
                                    <div className="grid grid-cols-1 xl:grid-cols-2 gap-y-[.5rem] mb-4 gap-x-5">
                                        <Link
                                            to={"/exception-automation"}
                                            onClick={ShowProffesionIcon ? null : () => setUpgradeOpen(true)}
                                            className={`${ShowProffesionIcon ? `` : `opacity-[.5]`
                                                } flex max-w-[26rem] p-4 rounded-[8px] transition duration-300 ease-in-out hover:bg-${currentMode}-1010 hover:border-[#333333] hover:shadow-[0px_4px_20px_rgba(85,85,85,0.2)]`}
                                        >
                                            <div className="mr-3 convertWhite">
                                                <img src="/images/settings/Exception.svg" alt="accrual" />
                                            </div>
                                            <div className="flex-1">
                                                <span className="font-interSb block mb-2"> Exception Automation</span>
                                                <p>
                                                    Exception Automation harnesses cutting-edge AI and ML technology to proactively identify, efficiently manage, and effectively automate exceptions, ensuring seamless and efficient invoice processing workflow.
                                                </p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            }

                            {/* <div className="grid grid-cols-1 xl:grid-cols-2 gap-y-[3rem] gap-x-5">
                                <Link className={`flex max-w-[26rem] p-4 rounded-[8px] transition duration-300 ease-in-out hover:bg-${currentMode}-1010 hover:border-[#333333] hover:shadow-[0px_4px_20px_rgba(85,85,85,0.2)]`}>
                                    <div className="mr-3">
                                        <img
                                            src="/images/sidebar/company.svg"
                                            alt="company"
                                        />
                                    </div>
                                    <div className="flex-1">
                                        <span className="font-interSb block mb-2">
                                            Organization
                                        </span>
                                        <p>
                                            Define agents' scope of work, type,
                                            language, and other details.
                                        </p>
                                    </div>
                                </Link>
                                <Link className={`flex max-w-[26rem] p-4 rounded-[8px] transition duration-300 ease-in-out hover:bg-${currentMode}-1010 hover:border-[#333333] hover:shadow-[0px_4px_20px_rgba(85,85,85,0.2)]`}>
                                    <div className="mr-3">
                                        <img
                                            src="/images/common/notess.svg"
                                            alt="notess"
                                        />
                                    </div>
                                    <div className="flex-1">
                                        <span className="font-interSb block mb-2">
                                            Task
                                        </span>
                                        <p>
                                            Define agents' scope of work, type,
                                            language, and other details.
                                        </p>
                                    </div>
                                </Link>
                            </div> */}
                        </div>
                    </Card>
                </Main>
                <Footer />
            </Layout>
            {ReactDOM.createPortal(
                <RoleSwitchModal isOpened={role} handleClose={() => setRole(false)} className={role && "Show"} />,
                document.getElementById("user-modal-root")
            )}
            {ReactDOM.createPortal(
                <RemoveModal isOpened={remove} handleClose={() => setRemove(false)} className={remove && "Show"} />,
                document.getElementById("user-modal-root")
            )}
            {ReactDOM.createPortal(
                <AccountSync
                    isOpened={syncOpen}
                    handleClose={() => setsyncOpen(false)}
                    className={syncOpen && "Show"}
                    handleOpen={() => setsyncOpen(true)}
                />,
                document.getElementById("user-modal-root")
            )}
            {ReactDOM.createPortal(
                <AccountDelete
                    isOpened={accountDeleteOpen}
                    handleClose={() => setAccountDeleteOpen(false)}
                    className={accountDeleteOpen && "Show"}
                    handleOpen={() => setAccountDeleteOpen(true)}
                />,
                document.getElementById("user-modal-root")
            )}
            {ReactDOM.createPortal(
                <BackupData
                    isOpened={backupOpen}
                    handleClose={() => setBackupOpen(false)}
                    className={backupOpen && "Show"}
                    handleOpen={() => setBackupOpen(true)}
                />,
                document.getElementById("user-modal-root")
            )}
            {ReactDOM.createPortal(
                <UpgradeModal
                    isOpened={upgradeOpen}
                    handleClose={() => setUpgradeOpen(false)}
                    className={upgradeOpen && "Show"}
                    handleOpen={() => setUpgradeOpen(true)}
                />,
                document.getElementById("user-modal-root")
            )}
        </>
    );
};

export default AdminSetting;
