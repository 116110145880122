import { type } from "@testing-library/user-event/dist/type";
import _, { set, values } from "lodash";
import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Button from "../../../components/Button";
import { getInboxChildView } from "../../../redux/actions/email-inbox";
import { INBOX_BODY_VIEW_RESET, INBOX_PREVIEW_GET_RESET } from "../../../redux/types/types";
import Loader from "../../../components/Loader";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";
import Swal from "sweetalert2";

export const FolderSidebar = (props) => {
    const { currentMode } = useContext(ThemeContext);
    const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
        boxShadow: "none !important",
        border: "0 !important",
        padding: 0,
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
    }));

    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary expandIcon={<img src="/images/common/accordion-active.svg" alt="accordion-active" />} {...props} />
    ))(({ theme }) => ({
        padding: "0 20px",
        boxShadow: "none !important",
        border: "0 !important",
        margin: 0,
        minHeight: 0,
        flexDirection: "row-reverse",
        "& .MuiAccordionSummary-expandIconWrapper": {
            marginRight: 10,
            transform: "rotate(270deg)",
            filter: `${currentMode === "dark" ? "filter: brightness(0) invert(1)" : "brightness(0) invert(0)"}`,
        },
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(360deg)",
            filter: "none",
        },
        "& .MuiAccordionSummary-content": {
            margin: 0,
        },
    }));

    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: 0,
        border: 0,
    }));
    const dispatch = useDispatch();
    const { setCompose, setOpenFolder } = props;
    const { setIsDraft } = props;
    const { replyReset } = props;
    const { compose } = props;
    const { isDraft } = props;
    const { emaibody } = props;
    const { replyEnable } = props;
    const [displayName, setDisplayName] = useState("Inbox");
    const [expandAcc, setExpandAcc] = useState(0);
    const { folderData: values, fetching, requestComplete } = useSelector((state) => state?.inboxFolderState);
    // const { value } =
    //     folderData !== undefined && folderData !== null && folderData;
    const confirmPopup = (id, value) => {
        const folderId = localStorage.getItem("folderId");
        if (folderId !== id) {
            dispatch({ type: INBOX_PREVIEW_GET_RESET });
            dispatch({ type: INBOX_BODY_VIEW_RESET });
        }
        setDisplayName(value);
        const Count = values?.filter((el) => el?.id == id).map((el) => el?.totalItemCount);
        localStorage.setItem("folderId", id);
        localStorage.setItem("count", Count);
        props?.setCompose(false);
        props?.setIsDraft(false);
    };
    const _handleClick = (id, value) => {
        if (isDraft || compose || replyEnable) {
            Swal.fire({
                text: "Changes in the mailbox will be removed.",
                icon: "warning",
                closeOnClickOutside: false,
                showCloseButton: true,
                buttons: {
                    cancel: "Cancel",
                    confirm: "Confirm",
                },
            }).then((confirm) => {
                if (confirm?.isConfirmed) {
                    {
                        confirmPopup(id, value);
                    }
                }
            });
        } else {
            confirmPopup(id, value);
        }
    };
    const { data, isLoading, isLoadingScroll, hasMore, pageSize } = useSelector((state) => state?.inboxPrevState);
    // dynamic image insert in email sidebar
    const dynamicImage = (name) => {
        switch (name) {
            case "INBOX":
            case "Inbox":
                return "/images/inbox/inbox.svg";
            case "DRAFT":
            case "Drafts":
                return "/images/inbox/file.svg";
            case "SENT":
            case "Sent Items":
                return "/images/inbox/send.svg";
            case "TRASH":
            case "Deleted Items":
                return "/images/inbox/trash-2.svg";
            case "Junk Email":
                return "/images/inbox/alert-octagon.svg";
            case "Archive":
                return "/images/inbox/archive.svg";
            case "Outbox":
                return "/images/common/outbox.svg";
            case "Conversation History":
                return "/images/inbox/history.svg";
            default:
                return "/images/inbox/new-folder.svg";
                break;
        }
    };
    // const handleChange = (panel) => {
    //     console.log("Inside handle;", panel, expandAcc?.includes(panel));
    //     // demoMe[index].expand_me=demoMe[index]?.expand_me == true ? null:true
    //     let valueParse = expandAcc;
    //     if (expandAcc?.includes(panel)) {
    //         valueParse = valueParse?.filter((item) => item != panel);
    //     } else {
    //         valueParse.push(panel);
    //     }
    //     setExpandAcc(valueParse);
    //     console.log("After op:", valueParse);
    // };
    const isExpanded = (index) => expandAcc.includes(index);
    const AccordCard = (props) => {
        const { data, index, summary } = props;
        return (
            <>
                <div>
                    <Accordion
                        expanded={expandAcc == index}
                        key={index}
                        onChange={() => {
                            if (expandAcc == index) {
                                setExpandAcc(0);
                            } else {
                                setExpandAcc(index);
                            }
                        }}>
                        <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                            expandIcon={<img src="/images/common/chevron-grey.svg" alt="checron-dwn" className="convertWhite" />}
                            onClick={() => _handleClick(summary?.id, summary?.displayName)}
                            className={`py-[6px] w-full items-center cursor-pointer relative flex ${
                                displayName === summary?.displayName ? `rounded-r-[8px] bg-${currentMode}-1000` : ""
                            }`}>
                            <button
                                disabled={isLoadingScroll || isLoading}
                                className={` w-full items-center text-${currentMode}-copy cursor-pointer relative  flex ${
                                    displayName === summary?.displayName ? `rounded-r-[8px] bg-${currentMode}-1000` : ""
                                }`}>
                                <img className={`inline-block mr-1 convertWhite`} src={dynamicImage(summary?.displayName)} />
                                {summary?.displayName}
                                {summary?.unreadItemCount > 0 && (
                                    <p className={`absolute text-light-10 text-xs font-interSb top-[50%] translate-y-[-50%] right-[0px]`}>
                                        {summary?.unreadItemCount}
                                    </p>
                                )}
                            </button>
                        </AccordionSummary>

                        <AccordionDetails>
                            {data?.map((value) => {
                                return (
                                    <>
                                        <button
                                            disabled={isLoadingScroll || isLoading}
                                            onClick={() => _handleClick(value?.id, value?.displayName)}
                                            className={`py-[6px] w-full text-${currentMode}-copy items-center cursor-pointer relative px-[40px] flex ${
                                                displayName === value?.displayName ? `rounded-r-[8px] bg-${currentMode}-1000` : ""
                                            }`}>
                                            <img className="inline-block mr-1 convertWhite" src={dynamicImage(value?.displayName)} />
                                            {value?.displayName}
                                            {value?.unreadItemCount > 0 && (
                                                <p
                                                    className={`absolute text-light-10 text-xs font-interSb top-[50%] translate-y-[-50%] right-[20px]`}>
                                                    {value?.unreadItemCount}
                                                </p>
                                            )}
                                        </button>
                                    </>
                                );
                            })}
                        </AccordionDetails>
                    </Accordion>
                </div>
            </>
        );
    };
    return (
        <>
            <div>
                <span onClick={() => setOpenFolder(false)} className="cursor-pointer lg:hidden p-[6.5px] absolute right-[0px] top-0 bg-light-10">
                    <img src="/images/common/whiteClose.svg" alt="whiteClose" />
                </span>
                <Button
                    onClick={() => {
                        if (!compose) {
                            setCompose(true);
                            replyReset();
                        }
                    }}
                    className={`border-[1px] px-5 w-full py-2 border-${currentMode}-830 mb-2 rounded-[8px] ${
                        currentMode !== "dark" ? "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]" : ""
                    } `}>
                    <img className="inline-block mr-1" src="/images/common/pencil-green.svg" alt="pencil-green" /> Compose
                </Button>
                {fetching ? (
                    <Loader className="h-[calc(100vh_-_30rem)]" />
                ) : (
                    <ul className="space-y-1 ml-[-20px]">
                        {values?.map((value, i) => (
                            <li key={i}>
                                {value?.childFolders?.length <= 0 ? (
                                    <button
                                        disabled={isLoadingScroll || isLoading}
                                        title={value?.displayName}
                                        onClick={() => _handleClick(value?.id, value?.displayName)}
                                        className={`py-[6px] w-full items-center cursor-pointer relative px-[20px] flex ${
                                            displayName?.toLocaleLowerCase() === value?.displayName?.toLocaleLowerCase()
                                                ? `rounded-r-[8px] bg-${currentMode}-1000`
                                                : ""
                                        } `}>
                                        <img
                                            className={`inline-block mr-1 ${value?.displayName === "Inbox" ? "" : "convertWhite"} `}
                                            src={dynamicImage(value?.displayName)}
                                        />
                                        <span className={`${value?.displayName?.length > 10 ? `truncate max-w-[145px]` : ``}`}>
                                            {value?.displayName}
                                        </span>
                                        {value?.displayName == "Drafts" ||
                                        value?.displayName?.toLowerCase() == "draft" ||
                                        value?.displayName == "Junk Email" ? (
                                            <p className="absolute text-light-10 text-xs font-interSb top-[50%] translate-y-[-50%] right-[20px]">
                                                {value?.totalItemCount == 0 ? "" : value?.totalItemCount}
                                            </p>
                                        ) : (
                                            value?.unreadItemCount > 0 && (
                                                <p className="absolute text-light-10 text-xs font-interSb top-[50%] translate-y-[-50%] right-[20px]">
                                                    {value?.unreadItemCount}
                                                </p>
                                            )
                                        )}
                                    </button>
                                ) : (
                                    <AccordCard key={i} data={value?.childFolders} index={i} summary={value} />
                                )}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            <Link to="/inbox-settings">
                <Button
                    className={`border-[1px] px-5 w-full py-2 border-${currentMode}-830 rounded-[8px] ${
                        currentMode !== "dark" ? "shadow-[0px_4px_20px_rgba(238,238,238,0.5)]" : ""
                    }`}>
                    <img className="inline-block mr-1" src="/images/main/settings-grn.svg" alt="pencil-green" /> Inbox Settings
                </Button>
            </Link>
        </>
    );
};
