import { useContext } from "react";
import { ThemeContext } from "../../../Hooks/Themes/Themeprovider";

const Card = (props) => {
    const { currentMode } = useContext(ThemeContext);
    return (
        <div
            className={`bg-${currentMode}-card ${!props?.isuser ? `max-h-[-webkit-fill-available]` : ``
                }  text-${currentMode}-copy border-[1px] border-${currentMode}-700 ${props?.className
                } rounded-[10px]`}
            ref={props?.ref}
            id={props?.id}
        >
            {props?.children}
        </div>
    );
};

export default Card;
